import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import Option from '@/redux/models/Option'

import { BookingHistory } from '../../redux/models/BookingHistory'
import { Customer } from '../../redux/models/Customer'
import CustomerPackage from '../../redux/models/CustomerPackage'
import { getCurrentStoreRole } from '../../redux/models/User'

interface useCustomerPackageHistoryProps {
  customerBookingHistories: BookingHistory[]
  customer: Customer
  customerPackages: CustomerPackage[]
}

interface CustomerHistoryPackageState extends BookingHistory {
  payChannel: {
    payChannelTypeId: number
    payChannelId: Number
    payChannel: string
    note: string
    quantity: number
    chargePercentage: number
    price: number
    remainingValue?: number
  }
}

interface customerEditPackageState {
  remainingValue: number
  expiryDay: number
  changedDate: Date
  userName: string
}

const useCustomerPackageHistory = ({
  customer,
  customerBookingHistories,
  customerPackages,
}: useCustomerPackageHistoryProps) => {
  const user = useSelector((state: any) => state.user)
  const role = getCurrentStoreRole(user)

  const wrapperRef = useRef(null)
  const [customerHistoryPackages, setCustomerHistoryPackages] = useState<
    CustomerHistoryPackageState[]
  >([])
  const [customerEditPackages, setCustomerEditPackages] = useState<
    customerEditPackageState[]
  >([])
  const [optionsCustomerPackages, setOptionsCustomerPackages] = useState<
    Option[]
  >([])
  const [defaultOptionCustomerPackage, setDefaultOptionCustomerPackage] =
    useState<Option>({ value: -1, label: 'ทั้งหมด' })
  const [openArrow, setOpenArrow] = useState(false)
  const [editPackage, setEditPackage] = useState(false)
  const [viewEditPackage, setViewEditPackage] = useState(false)
  const [currentPackage, setCurrentPackage] = useState({})
  const [bookOpenDetail, setBookOpenDetail] = useState(-1)

  useEffect(() => {
    callApiCustomerPackage(customer._id || -1, customerPackages)
  }, [customer, customerBookingHistories, customerPackages])

  const callApiCustomerPackage = async (
    customerId: number,
    customerPackages: CustomerPackage[]
  ) => {
    try {
      const optionsCustomerPackages = customerPackages.reduce(
        (acc, info) => {
          const findPackage = acc.find(v => v.value === info._id)
          if (!findPackage) acc.push({ value: info._id, label: info.name })
          return acc
        },
        [{ value: -1, label: 'ทั้งหมด' }]
      )
      setOptionsCustomerPackages(optionsCustomerPackages)

      const customerHistoryPackages = customerBookingHistories.reduce(
        (acc: CustomerHistoryPackageState[], info) => {
          const { payChannels } = info
          for (const payChannel of payChannels) {
            if (payChannel.payChannelTypeId === 6 && info.statusId !== 6) {
              const findCustomerPackage = customerPackages.find(
                d => d._id === payChannel.payChannelId
              )
              if (findCustomerPackage) {
                acc.push({ ...info, payChannel: payChannel })
              }
            }
          }

          return acc
        },
        []
      )
      setCustomerHistoryPackages(customerHistoryPackages)
    } catch (err) {
      console.log(err)
    }
  }

  const onSelectOptionClick = (value: Option) => {
    setDefaultOptionCustomerPackage(value)
    setOpenArrow(false)
  }

  const historyPackageFilter = (
    customerHistoryPackages: CustomerHistoryPackageState[],
    filter: Option
  ) => {
    if (filter.value === -1) {
      return customerHistoryPackages
    } else {
      const filterPackage = customerHistoryPackages.filter(
        b => b.payChannel.payChannelId === filter.value
      )

      return filterPackage
    }
  }

  const packageFilter = (
    customerPackages: CustomerPackage[],
    filter: Option
  ) => {
    if (filter.value === -1) {
      return customerPackages
    } else {
      const filterPackage = customerPackages.filter(p => p._id === filter.value)

      return filterPackage
    }
  }

  const onEditPackageClick = (_id: number, type: string) => {
    const findCustomerPackage = customerPackages.find(p => p._id === _id)
    if (findCustomerPackage) {
      setCurrentPackage(findCustomerPackage)
      if (type === 'view') {
        const edits = findCustomerPackage.edits.sort(
          (a, b) =>
            new Date(a.changedDate).getTime() -
            new Date(b.changedDate).getTime()
        )

        const customerEditPackages = []

        for (let i = 0; i < edits.length; i++) {
          if (i + 1 <= edits.length - 1) {
            customerEditPackages.push({
              remainingValue:
                edits[i + 1].remainingValue - edits[i].remainingValue,
              expiryDay: edits[i + 1].expiryDay - edits[i].expiryDay,
              changedDate: edits[i].changedDate,
              userName: edits[i].updateBy.userName,
            })
          } else if (i === edits.length - 1) {
            customerEditPackages.push({
              remainingValue:
                findCustomerPackage.remainingValue - edits[i].remainingValue,
              expiryDay: findCustomerPackage.expiryDay - edits[i].expiryDay,
              changedDate: edits[i].changedDate,
              userName: edits[i].updateBy.userName,
            })
          }
        }

        setCustomerEditPackages(customerEditPackages)
        setViewEditPackage(true)
      } else {
        setEditPackage(true)
      }
    }
  }

  const onCloseEditCustomerPackage = async () => {
    setEditPackage(false)
    await callApiCustomerPackage(customer._id || -1, customerPackages)
  }

  const onArrowClick = () => {
    setOpenArrow(!openArrow)
  }

  const onBookOpenClick = (index: number) => {
    setBookOpenDetail(bookOpenDetail === index ? -1 : index)
  }

  const handleClose = () => {
    setViewEditPackage(false)
  }

  return {
    role,
    wrapperRef,
    customerHistoryPackages,
    customerEditPackages,
    optionsCustomerPackages,
    defaultOptionCustomerPackage,
    openArrow,
    editPackage,
    viewEditPackage,
    currentPackage,
    bookOpenDetail,
    packageFilter,
    onEditPackageClick,
    onSelectOptionClick,
    historyPackageFilter,
    onCloseEditCustomerPackage,
    onArrowClick,
    onBookOpenClick,
    handleClose,
  }
}

export default useCustomerPackageHistory
