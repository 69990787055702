import React, { useEffect, useState } from 'react'

import logicRoomOrder from '@/components/booking/logicRoomOrder'
import logicStaffOrder from '@/components/booking/logicStaffOrder'
import { BookOrder, ServiceOrder, StaffOrder } from '@/redux/models/BookOrder'

import {
  AvailabilityCategory,
  IAvailabilityItem,
  RoomOrderState,
  RoomState,
  StaffOrderState,
} from './types'

import utils from '../../../utils'

interface TimeState {
  hour: string
  minute: string
}

interface useTableProps {
  storeOpenCloseTime: { openTime: TimeState; closeTime: TimeState }
  currentDate: Date
  staffOrders: StaffOrderState[]
  roomOrders: RoomOrderState[]
}

export interface ITableItemData {
  booking: BookOrder
  // order: {
  //   staffOrder?: StaffOrder
  //   serviceOrder: ServiceOrder
  // }
  orders: {
    staffOrder?: StaffOrder
    serviceOrders: ServiceOrder[]
  }
}

export interface ITableData {
  [staffId: number]: ITableItemData[]
}

const useTable = ({
  storeOpenCloseTime,
  currentDate,
  staffOrders,
  roomOrders,
}: useTableProps) => {
  const [times, setTimes] = useState<string[]>([])
  const [timeNow, setTimeNow] = useState<number>(
    new Date().getHours() * 60 + new Date().getMinutes()
  )
  const [staffActive, setStaffActive] = useState({ staffId: -1, type: '' })
  const [availabilityItems, setAvailabilityItems] = useState<
    IAvailabilityItem[]
  >([])
  const [categories, setCategories] = useState<AvailabilityCategory[]>([])

  const [activeTime, setActiveTime] = useState<number | null>(null)

  useEffect(() => {
    if (storeOpenCloseTime.openTime.hour) {
      let hour = []
      for (
        let i = +storeOpenCloseTime.openTime.hour;
        i <= +storeOpenCloseTime.closeTime.hour + 1;
        i++
      ) {
        if (i === parseInt(storeOpenCloseTime.openTime.hour)) {
          if (parseInt(storeOpenCloseTime.openTime.minute) < 30) {
            for (let j = 0; j < 2; j++) {
              if (j === 0) {
                hour.push(
                  utils.formatTime(i + '') + ':' + utils.formatTime(0 + '')
                )
              } else {
                hour.push(
                  utils.formatTime(i + '') + ':' + utils.formatTime(30 + '')
                )
              }
            }
          } else {
            hour.push(
              utils.formatTime(i + '') + ':' + utils.formatTime(30 + '')
            )
          }
        } else if (i === parseInt(storeOpenCloseTime.closeTime.hour)) {
          if (parseInt(storeOpenCloseTime.closeTime.minute) >= 30) {
            for (let j = 0; j < 2; j++) {
              if (j === 0) {
                hour.push(
                  utils.formatTime(i + '') + ':' + utils.formatTime(0 + '')
                )
              } else {
                hour.push(
                  utils.formatTime(i + '') + ':' + utils.formatTime(30 + '')
                )
              }
            }
          } else {
            hour.push(utils.formatTime(i + '') + ':' + utils.formatTime(0 + ''))
          }
        } else {
          for (let j = 0; j < 2; j++) {
            if (j === 0) {
              hour.push(
                utils.formatTime(i + '') + ':' + utils.formatTime(0 + '')
              )
            } else {
              hour.push(
                utils.formatTime(i + '') + ':' + utils.formatTime(30 + '')
              )
            }
          }
        }
      }
      setTimes(hour)
    } else {
      setTimes([])
    }
  }, [storeOpenCloseTime])

  const startStaffClick = (staffId: number) => {
    if (staffId === staffActive.staffId) {
      setStaffActive({ staffId: -1, type: '' })
    } else {
      setStaffActive({ staffId, type: 'start' })
    }
  }

  const createAvailabilityItems = (
    staffOrders: StaffOrderState[],
    roomOrders: RoomOrderState[],
    time: number
  ) => {
    const newStaff = logicStaffOrder.checkListStaffAvailability(
      staffOrders.filter(s => s.staffWorkingStatus !== -1),
      [],
      utils.convertTimeToHourMinute(time),
      0,
      0,
      60
    )

    const newRoom = logicRoomOrder.checkListRoomAvailability(
      roomOrders,
      [],
      utils.convertTimeToHourMinute(time),
      60
    )

    const itemRooms = newRoom
      .map(rt => ({
        ...rt,
        rooms: rt.rooms.filter((r: RoomState) => r.free),
      }))
      .map(item => ({
        id: item.roomTypeId,
        title: item.roomTypeName,
        category: 2,
        amount: item.rooms.length,
      }))
    let freeRoomsCount = 0
    newRoom.map(item => (freeRoomsCount += item.rooms.length))

    const itemStaffs = newStaff
      .filter(s => s.free)
      .map(s => ({
        id: s.staffId,
        title: s.staffName + '',
        category: 1,
        amount: 1,
      }))

    setAvailabilityItems(itemStaffs.concat(itemRooms))

    const categories = [
      {
        id: 1,
        title: 'Therapist',
        color: 'green',
        total: staffOrders.filter(s => s.staffWorkingStatus !== -1).length,
        pronoun: 'Pax',
        hideAmount: true,
        columns: 2,
      },
      {
        id: 2,
        title: 'Bed',
        color: 'green',
        total: freeRoomsCount,
        pronoun: 'Rooms',
      },
    ]
    setCategories(categories)
  }

  useEffect(() => {
    createAvailabilityItems(staffOrders, roomOrders, new Date().getTime())
  }, [staffOrders, roomOrders])

  const onSelectTimeClick = (e: any, time: string, index: number) => {
    // time in minutes
    const timeInMinutes = +time.split(':')[0] * 60 + +time.split(':')[1]
    if (activeTime === timeInMinutes) {
      setActiveTime(null)
      createAvailabilityItems(staffOrders, roomOrders, new Date().getTime())
    } else {
      setActiveTime(timeInMinutes)
      createAvailabilityItems(staffOrders, roomOrders, timeInMinutes)
    }
  }

  const onCurrentTimeChange = (time: null, now: number) => {
    createAvailabilityItems(staffOrders, roomOrders, now)
  }

  return {
    times,
    timeNow,
    staffActive,
    availabilityItems,
    categories,
    activeTime,
    startStaffClick,
    onSelectTimeClick,
    onCurrentTimeChange,
  }
}

export default useTable
