import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

import { PayChannel } from '@/redux/models/BookOrder'

import ArrowCalculator from '../../atoms/ArrowCalculator'
import TextLabel from '../../atoms/TextLabel'

import { BookingHistory } from '../../redux/models/BookingHistory'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
`

const FlexBox = styled.div<CSSProperties>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`

const Line = styled.div`
  border-top: 1px solid #e5eced;
  width: 80%;
`

const BoxCalculator = styled.div`
  display: flex;
  margin: 0 auto;
  width: 50%;
  min-width: 150px;
  flex-wrap: wrap;
`

const BoxInsideCalculator = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
`

const BoxBtnNumber = styled.div`
  flex: 1 0 32%;
  max-width: 32%;
  padding: 0.5rem;
  max-height: 50%;
`

const BoxBtn = styled.div`
  background: #ffffff;
  aspect-ratio: 1;
  border: 2px solid #e4e8eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  margin: auto;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: linear-gradient(142.09deg, #8c56d9 13.41%, #53389e 95.07%);
    color: white;
    & > svg {
      fill: white;
      & path {
        fill: white;
        color: white;
      }
    }
  }
`

const Point = styled.div`
  border-radius: 50%;
  width: 3px;
  height: 3px;
  background: black;
`

export interface CashProps {
  total: number
  discount: number
  commission: number
  charge: number
  totalTip: number
  bookingHistory: BookingHistory
  payChannels: PayChannel[]
  onAddNumberClick: (value: string) => void
}

const Cash = ({
  total,
  discount = 0,
  commission = 0,
  charge = 0,
  totalTip = 0,
  bookingHistory,
  payChannels,
  onAddNumberClick,
}: CashProps) => {
  const { t } = useTranslation()

  const [fromInput, setFromInput] = useState<string>('')
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, 'c']
  const [totalSplitPayment, setTotalSplitPayment] = useState(0)

  useEffect(() => {
    const totalSplitPayment = payChannels.reduce(
      (acc, info) => (acc += +info.price),
      0
    )
    setTotalSplitPayment(totalSplitPayment)
  }, [payChannels])

  const onShowClick = (total: number, discount: number, totalTip: number) => {
    setFromInput(String(total - discount + (totalTip || 0)))
  }

  const onNumberClick = (number: number | string) => {
    let _fromInput = fromInput === '0' ? '' : fromInput + ''
    if (number === 'c') {
      _fromInput = _fromInput.slice(0, -1)
    } else if (number === '.') {
      const inputSplit = _fromInput.split('.')
      if (inputSplit.length <= 1) {
        _fromInput += number + ''
      }
    } else {
      if (fromInput.length !== 0) {
        _fromInput += number + ''
      } else if (number !== 0) {
        _fromInput += number + ''
      }
    }
    setFromInput(_fromInput)

    onAddNumberClick(_fromInput)
  }

  const listNumber = numbers.map((number, index) => (
    <BoxBtnNumber key={index}>
      <BoxBtn onClick={() => onNumberClick(number)}>
        {number === 'c' ? (
          <ArrowCalculator size='25' />
        ) : number === '.' ? (
          <Point />
        ) : (
          number
        )}
      </BoxBtn>
    </BoxBtnNumber>
  ))

  return (
    <Container>
      <FlexBox
        flexDirection='column'
        alignItems='center'
        style={{ lineHeight: '0.85' }}
      >
        <TextLabel
          color='#98A1B2'
          fontSize='0.875rem'
          cursor='pointer'
          onClick={() => onShowClick(total, discount, bookingHistory.totalTip)}
        >
          {t('tapIfEqualGrandTotalPrice')}
        </TextLabel>
        <FlexBox alignItems='flex-end' gap='0.5rem'>
          <TextLabel fontSize='2rem' color='#8C56D9'>
            {`${
              fromInput
                ? (parseFloat(fromInput || '0').toFixed(2) + '').replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                  )
                : 0 || 0
            }`}
          </TextLabel>
          <TextLabel style={{ lineHeight: '2.5' }}>บาท</TextLabel>
        </FlexBox>
        <Line className='mt-2 mb-1'></Line>
        <FlexBox justifyContent='space-between' style={{ width: '80%' }}>
          <TextLabel color='#98A1B2'>{t('change')}</TextLabel>
          <TextLabel color='#FC6514'>
            {`${
              fromInput
                ? Number(
                    (parseInt(fromInput) || 0) -
                      (+total +
                        (+commission || 0) +
                        (+charge || 0) +
                        (+totalTip || 0) -
                        (+totalSplitPayment || 0) -
                        (+discount || 0))
                  ).toFixed(2)
                : '0.00'
            } บาท`}
          </TextLabel>
        </FlexBox>
      </FlexBox>
      <BoxCalculator>
        <BoxInsideCalculator>{listNumber}</BoxInsideCalculator>
      </BoxCalculator>
    </Container>
  )
}

export default Cash
