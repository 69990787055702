import { ChangeEvent, RefObject, useEffect, useRef } from 'react'

const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: Event) => void
) => {
  const fnRef = useRef<((event: Event) => void) | null>(null)

  useEffect(() => {
    const listener: EventListenerOrEventListenerObject = event => {
      if (
        !ref.current ||
        ref.current.contains(
          (event as unknown as ChangeEvent<HTMLInputElement>).target
        )
      ) {
        return
      }
      // handler(event)
      fnRef.current && fnRef.current(event)
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [])

  useEffect(() => {
    fnRef.current = handler
  }, [handler])
}

export default useOnClickOutside
