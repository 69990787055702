import React from 'react'

import SettingHeader from '../../molecules/SettingHeader'

const Head = ({ onCreateClick }) => {
  return (
    <SettingHeader
      name='โปรโมชั่น'
      menuName='สร้างโปรโมชั่น'
      onClick={onCreateClick}
    />
  )
}

export default Head
