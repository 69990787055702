import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import Option, { SelectorOption } from '@/redux/models/Option'
import utils from '@/utils'

import logicServiceCouple from '../logicServiceCouple'

//@ts-ignore
import api from '../../../api'
import { getOrderBooking } from '../../../redux/actions/bookingHistoryAction'
import BookingChannel from '../../../redux/models/BookingChannel'
import {
  BookingTypeOrder,
  BookOrder,
  getInitialServiceBookOrder,
} from '../../../redux/models/BookOrder'

interface UseReservationDetailProps {
  bookingOrderState: BookOrder
}

const useReservationDetail = ({
  bookingOrderState,
}: UseReservationDetailProps) => {
  const dispatch = useDispatch()

  const [openArrowBookingType, setOpenArrowBookingType] =
    useState<boolean>(false)
  const [openArrowBookingChannel, setOpenArrowBookingChannel] =
    useState<boolean>(false)

  const [optionBookingChannels, setOptionBookingChannels] = useState<Option[]>(
    []
  )

  const wrapperRefBookingType = useRef(null)

  const { bookingType, countCustomer, customers } = bookingOrderState

  useEffect(() => {
    callApiBookingChannel(bookingType)
  }, [bookingType])

  const callApiBookingChannel = async (bookingType: BookingTypeOrder) => {
    try {
      if (bookingType.bookingTypeId === 1) {
        setOptionBookingChannels([{ value: -1, label: 'walk-in' }])
        dispatch(
          getOrderBooking({
            bookingChannel: {
              bookingChannelId: -1,
              bookingChannel: 'walk-in',
              code: '',
              commission: 0,
            },
          })
        )
      } else {
        const { data } = await api.getBookingChannelByBookingTypeId(
          bookingType.bookingTypeId
        )
        const optionBookingChannels = data.reduce(
          (acc: SelectorOption[], info: BookingChannel) => {
            acc.push({
              value: info.bookingChannelId,
              label: info.bookingChannel,
            })
            return acc
          },
          []
        )
        if (optionBookingChannels.length) {
          dispatch(
            getOrderBooking({
              bookingChannel: {
                bookingChannelId: optionBookingChannels[0].value,
                bookingChannel: optionBookingChannels[0].label,
                code: '',
                commission: 0,
              },
            })
          )
        }
        setOptionBookingChannels(optionBookingChannels)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onArrowBookingChannelClick = (open: boolean) => {
    setOpenArrowBookingChannel(open)
  }

  const onArrowBookingTypeClick = (open: boolean) => {
    setOpenArrowBookingType(open)
  }

  const onSelectOption = (option: any, name: string) => {
    if (name === 'bookingType') {
      dispatch(
        getOrderBooking({
          bookingType: {
            bookingTypeId: option.value,
            bookingType: option.label,
          },
        })
      )
      setOpenArrowBookingType(false)
    } else {
      dispatch(
        getOrderBooking({
          bookingChannel: {
            bookingChannelId: option.value,
            bookingChannel: option.label,
            code: '',
            commission: 0,
          },
        })
      )
      setOpenArrowBookingChannel(false)
    }
  }

  const onCountCustomerClick = (type: string) => {
    let newArrCustomer = utils.deepClone(customers)
    if (type === 'add') {
      newArrCustomer.push({
        customer: countCustomer + 1,
        customerId: -1,
        name: '',
        firstName: '',
        lastName: '',
        countryCode: '+66',
        phoneNumber: '',
        email: '',
        address: '',
        taxIDNumber: '',
        gender: -1,
        age: { value: -1, label: 'ไม่ระบุ' },
        nationality: { value: -1, label: 'อื่นๆ' },
        services: [getInitialServiceBookOrder()],
        specialRequest: '',
      })
      dispatch(
        getOrderBooking({
          countCustomer: countCustomer + 1,
          customers: newArrCustomer,
          focusedCustomerIndex: countCustomer,
        })
      )
    } else {
      if (countCustomer > 1) {
        const price =
          newArrCustomer[newArrCustomer.length - 1].services.reduce(
            (acc, info) => {
              if (info.serviceId !== -1) acc += info.price
              return acc
            },
            0
          ) || 0

        const serviceCustomers = newArrCustomer[
          newArrCustomer.length - 1
        ].services
          .filter(s => s.serviceId !== -1 && s.serviceCustomer > 1)
          .map(s => ({
            serviceId: s.serviceId,
            serviceTime: s.time,
            serviceMapCustomerIndex: s.primaryCustomerIndex,
          }))

        if (serviceCustomers?.length) {
          for (const serviceCustomer of serviceCustomers) {
            newArrCustomer = logicServiceCouple.removeCustomerServiceCouple(
              newArrCustomer,
              serviceCustomer.serviceMapCustomerIndex.customerIndex,
              serviceCustomer.serviceId,
              serviceCustomer.serviceTime
            )
          }
        }

        newArrCustomer.pop()

        dispatch(
          getOrderBooking({
            countCustomer: countCustomer - 1,
            customers: newArrCustomer,
            focusedCustomerIndex: countCustomer - 2,
            total: bookingOrderState.total - price,
          })
        )
      }
    }
  }

  return {
    openArrowBookingType,
    openArrowBookingChannel,
    wrapperRefBookingType,
    optionBookingChannels,
    onSelectOption,
    onArrowBookingChannelClick,
    onArrowBookingTypeClick,
    onCountCustomerClick,
  }
}

export default useReservationDetail
