import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import StaffTypeDrag from './StaffTypeDrag'
import api from '../../api'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  width: calc(100%);
  /* margin-right: 1rem; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const BodyStaffTypeLeft = ({
  staffTypes,
  staffs,
  staffsNoneActive,
  onEditGroupClick: _onEditGroupClick,
  search,
  activeGroupEdit: _activeGroupEdit,
  onEditClick,
  onDeleteGroupClick: _onDeleteGroupClick,
  onDeleteClick: _onDeleteClick,
}) => {
  const [state, setState] = useState('')
  const [temp, setTemp] = useState({ check: false, id: 0 })
  const [activeGroupEdit, setActiveGroupEdit] = useState(-1)
  const [openEdit, setOpenEdit] = useState(-1)

  useEffect(() => {
    if (!_activeGroupEdit) {
      setActiveGroupEdit(-1)
    }
  }, [_activeGroupEdit])

  const convertArrayToObject = (array, key) => {
    const initialValue = {}
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      }
    }, initialValue)
  }

  useEffect(() => {
    if (staffTypes.length) {
      let columns = []
      let columnOrder = []
      let tasks = []

      for (let i = 0; i < staffTypes.length; i++) {
        const {
          staffType,
          _id: id,
          staffTypeId: _staffTypeId,
          baseSalary,
          baseCommission,
        } = staffTypes[i]
        columns.push({
          id: 'column-' + _staffTypeId,
          _id: id,
          title: staffType,
          baseSalary,
          baseCommission,
          open: true,
          staffTypeId: _staffTypeId,
          taskIds: [],
        })
        columnOrder.push('column-' + _staffTypeId)
        for (let j = 0; j < staffs.length; j++) {
          const { name, image, baseSalary, baseCommission, staffTypeId, _id } =
            staffs[j]
          if (staffTypeId === _staffTypeId) {
            columns[i].taskIds.push('task-' + _id)
            tasks.push({
              id: 'task-' + _id,
              _id: _id,
              content: name,
              image: image,
              baseSalary,
              baseCommission,
              staffTypeId,
              open: false,
            })
          }
        }
      }
      columns.push({
        id: 'column-' + (staffTypes.length + 1),
        _id: -2,
        title: 'พักงานหรือเลิกจ้าง',
        open: false,
        taskIds: [],
      })
      columnOrder.push('column-' + (staffTypes.length + 1))
      for (let i = 0; i < staffsNoneActive.length; i++) {
        const { name, image, baseSalary, baseCommission, _id } =
          staffsNoneActive[i]
        columns[staffTypes.length].taskIds.push('task-' + _id)
        tasks.push({
          id: 'task-' + _id,
          _id: _id,
          content: name,
          image: image,
          baseSalary,
          baseCommission,
          open: false,
        })
      }

      let _columns = convertArrayToObject(columns, 'id')
      let _tasks = convertArrayToObject(tasks, 'id')
      setState({ columnOrder, columns: _columns, tasks: _tasks })
    }
  }, [staffTypes, staffs, staffsNoneActive])

  const onDragEnd = result => {
    const { destination, source, draggableId, type } = result

    if (!destination) {
      setTemp(true)
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      setTemp(true)
      return
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(state.columnOrder)
      newColumnOrder.splice(source.index, 1)
      newColumnOrder.splice(destination.index, 0, draggableId)

      const newState = {
        ...state,
        columnOrder: newColumnOrder,
      }
      setTemp({ check: true, id: 3 })
      setState(newState)
      return
    }

    const start = state.columns[source.droppableId]
    const finish = state.columns[destination.droppableId]
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      }

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      }
      setState(newState)
      setTemp({ check: true, id: 4 })
      return
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice(source.index, 1)
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    }

    const finishTaskIds = Array.from(finish.taskIds)
    finishTaskIds.push(draggableId)
    // finishTaskIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    }

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }
    setState(newState)
    setTemp({ check: true, id: 5, draggableId })
    return
  }

  const objectToArray = object => {
    var convert = Object.keys(object).map(key => [key, object[key]])
    return convert
  }

  useEffect(() => {
    if (temp.check) {
      var _columns = objectToArray(state.columns)
      var _tasks = objectToArray(state.tasks)
      let _staffs = []

      if (temp.id === 4) {
        let _number = 0
        const newColumns = state.columnOrder.reduce((acc, info) => {
          for (let i = 0; i < _columns.length; i++) {
            const { taskIds, id } = _columns[i][1]
            if (id === info) {
              for (let j = 0; j < taskIds.length; j++) {
                acc.push(taskIds[j])
              }
            }
          }
          return acc
        }, [])

        for (let i = 0; i < newColumns.length; i++) {
          for (let j = 0; j < _tasks.length; j++) {
            if (_tasks[j][0] === newColumns[i]) {
              const { _id } = _tasks[j][1]
              const findStaff =
                staffs.find(s => s._id === parseInt(_id) || '') || ''
              if (findStaff) {
                _staffs.push({ ...findStaff, number: _number })
              }
              //   callApiUpdateService(_id, update)
              _number++
            }
          }
        }
        callApiUpdateStaffs(_staffs)
      } else if (temp.id === 5) {
        let _number = 0
        const [, staffIdMove] = temp.draggableId.split('task-')
        for (let i = 0; i < _columns.length; i++) {
          const {
            taskIds,
            baseSalary: baseSalaryType,
            baseCommission: baseCommissionType,
            staffTypeId,
          } = _columns[i][1]
          if (taskIds.length !== 0) {
            for (let j = 0; j < taskIds.length; j++) {
              for (let k = 0; k < _tasks.length; k++) {
                const {
                  _id: staffId,
                  baseSalary,
                  baseCommission,
                } = _tasks[k][1]
                if (_tasks[k][0] === taskIds[j]) {
                  let findStaff =
                    staffs.find(s => s._id === staffId || '') || ''
                  if (findStaff) {
                    if (parseInt(staffIdMove) === staffId) {
                      findStaff = {
                        ...findStaff,
                        baseSalary:
                          baseSalary || baseSalary === 0
                            ? baseSalaryType || 0
                            : 0,
                        baseCommission:
                          baseCommission || baseCommission === 0
                            ? baseCommissionType || 0
                            : 0,
                      }
                    }
                    _staffs.push({
                      ...findStaff,
                      number: _number,
                      staffTypeId: staffTypeId,
                    })
                  }
                  _number++
                }
              }
            }
          }
        }
        callApiUpdateStaffs(_staffs)
      }

      setTemp({ check: false, id: 0 })
    }
  }, [temp])

  const callApiUpdateStaffs = async staffs => {
    try {
      await api.updateAllStaff(staffs)
    } catch (err) {
      console.log(err)
    }
  }

  const onOpenColumnClick = columnId => {
    const currentColumn = state.columns[columnId]
    if (state.columns[columnId].taskIds.length !== 0) {
      const newColumn = { ...currentColumn, open: !currentColumn.open }
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [columnId]: newColumn,
        },
      }
      setState(newState)
    }
  }

  const onDropDownEditClick = (e, id) => {
    e.stopPropagation()
    if (id === openEdit) {
      setOpenEdit(-1)
    } else {
      setOpenEdit(id)
    }
  }
  const onEditGroupClick = () => {
    _onEditGroupClick(openEdit)
    setActiveGroupEdit(openEdit)
    setTimeout(() => {
      setOpenEdit(-1)
    }, 500)
  }
  const onDeleteGroupClick = () => {
    _onDeleteGroupClick(openEdit)
  }

  const onEditStaffClick = id => {
    onEditClick(id)
  }
  const onDeleteStaffClick = id => {
    _onDeleteClick(id)
  }

  const bySearch = (staffs, search) => {
    if (search) {
      return staffs.content.includes(search)
    } else return staffs
  }

  const filteredList = (staffs, search) => {
    return staffs.filter(staffs => bySearch(staffs, search))
  }

  return (
    <Container>
      <Scrollbar id='setting-bodyStaffLeft'>
        {state && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId='all-columns'
              direction='vertical'
              type='column'
            >
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {state.columnOrder.map((columnId, index) => {
                    const column = state.columns[columnId]
                    const tasks = column.taskIds.map(
                      taskId => state.tasks[taskId]
                    )
                    return (
                      <StaffTypeDrag
                        key={column.id}
                        column={column}
                        tasks={filteredList(tasks, search)}
                        index={index}
                        activeEdit={activeGroupEdit}
                        openEdit={openEdit}
                        onOpenColumnClick={onOpenColumnClick.bind(
                          this,
                          column.id
                        )}
                        onDropDownEditClick={e =>
                          onDropDownEditClick(e, column._id)
                        }
                        onEditGroupClick={onEditGroupClick}
                        onDeleteGroupClick={onDeleteGroupClick}
                        onEditStaffClick={onEditStaffClick}
                        onDeleteStaffClick={onDeleteStaffClick}
                      />
                    )
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Scrollbar>
    </Container>
  )
}

export default BodyStaffTypeLeft
