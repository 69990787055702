import React, { useEffect } from 'react'
import styled from 'styled-components'

import TableAgency from './TableAgencyPrice'
import TableTimePrepare from './TableTimePrepare'
import TableTimePrice from './TableTimePrice'
import useCardAddService from './useCardAddService'

//@ts-ignore
import SwitchApply from '../SwitchApply'
//@ts-ignore
import SwitchStatus from '../SwitchStatus'

import Box from '../../../atoms/Box'
import BoxAddNumber from '../../../atoms/BoxAddNumber'
//@ts-ignore
import Button2 from '../../../atoms/Button2'
//@ts-ignore
import ButtonSave from '../../../atoms/ButtonSave'
import CameraIcon from '../../../atoms/CameraIcon'
import InputText from '../../../atoms/InputText'
import InputTextArea from '../../../atoms/InputTextArea'
import SaveIcon from '../../../atoms/SaveIcon'
//@ts-ignore
import Scrollbar from '../../../atoms/Scrollbar'
import SuccessIcon2 from '../../../atoms/SuccessIcon2'
import TextLabel from '../../../atoms/TextLabel'
import UploadImageIcon from '../../../atoms/UploadImageIcon'

import { ProductGroup } from '../../../redux/models/ProductGroup'
import Service from '../../../redux/models/Service'
import SelectorServiceGroup from '../../selector/SelectServiceGroup'

// interface CardAddServiceStyled extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  overflow: hidden;
  display: flex;
`
const Card = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`
const BodyContainer = styled.div`
  margin-top: 1rem;
  display: grid;
  gap: 1rem;
`
const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 134px 1fr;
  gap: 1.25rem;
  flex: 0 0 auto;
`

const ImageBox = styled.div`
  border-radius: 16px;
  background: #e4e8eb;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
`

const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`
const InputContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 0.5rem;
`
const Image = styled.img`
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  object-fit: cover;
`
const FlexBox = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 0.5rem;
  align-items: center;
`
const BoxContainer = styled.div`
  width: 100%;
  background: #f9f7fd;
  border-radius: 8px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
`

interface CardAddProductProps {
  handleClose: (close: boolean) => void
  serviceGroup: ProductGroup[]
  currentEditId: number
  currentEditData: Service
  agencies: { _id: number; bookingChannel: string }[]
}

const CardAddService = ({
  handleClose,
  agencies,
  serviceGroup,
  currentEditId,
  currentEditData,
}: CardAddProductProps) => {
  const {
    service,
    image,
    success,
    checkName,
    onChange,
    onToggle,
    onToggleRoomType,
    onChangeImage,
    onAddTimePrice,
    onAddRoom,
    onRemoveTimePrice,
    onRemoveRoomType,
    onTimePriceChange,
    onOptionRoomTypeClick,
    onOptionSelectAllClick,
    onRoomClick,
    onDurationChange,
    onRoomDetailChange,
    onAddPrepareTime,
    onApplyChange,
    onSuspendChange,
    onSaveClick,
  } = useCardAddService({
    serviceGroup,
    currentEditId,
    currentEditData,
  })

  useEffect(() => {
    if (success) {
      if (success) {
        setTimeout(() => {
          handleClose(false)
        }, 1000)
      }
    }
  }, [success])

  return (
    <Container>
      <Card>
        <Scrollbar id='setting-cardAddService'>
          <TextLabel>
            {currentEditId === -1 ? 'สร้างบริการใหม่' : 'แก้ไขบริการ'}
          </TextLabel>
          <BodyContainer>
            <TopContainer>
              <ImageBox>
                <CameraIcon size='40' />
                <ImageUploadIcon>
                  <label htmlFor='upload-image'>
                    <UploadImageIcon
                      size='30'
                      colorCircle='#8C56D9'
                      colorImage='white'
                    />
                  </label>
                  <input
                    id='upload-image'
                    type='file'
                    onChange={onChangeImage}
                    style={{ display: 'none', visibility: 'hidden' }}
                  />
                </ImageUploadIcon>
                {image && <Image src={image} />}
              </ImageBox>
              <InputContainer>
                <div>
                  <TextLabel
                    fontWeight='600'
                    fontSize='0.875rem'
                    color={checkName ? '#DB2D1E' : ''}
                  >
                    ชื่อบริการ*
                  </TextLabel>
                  <InputText
                    fontSize='0.875rem'
                    padding='0.25rem 0.25rem 0.25rem 1rem'
                    error={checkName}
                    value={service.name}
                    onChange={e => onChange(e, 'name')}
                  />
                </div>
                <div>
                  <TextLabel fontWeight='600' fontSize='0.875rem'>
                    กลุ่มบริการ
                  </TextLabel>
                  <SelectorServiceGroup
                    name='serviceGroup'
                    option={service.serviceGroup}
                    open={service.serviceGroupOptionOpen}
                    onOptionClick={option => onChange(option, 'serviceGroup')}
                    onClick={onToggle}
                    error={false}
                    readOnly={false}
                  />
                </div>
              </InputContainer>
            </TopContainer>
            <div>
              <TextLabel fontWeight='600' fontSize='0.875rem'>
                คำอธิบายบริการ
              </TextLabel>
              <InputTextArea
                height='100px'
                fontSize='0.875rem'
                padding='0.25rem 0.25rem 0.25rem 1rem'
                value={service.description}
                onChange={e => onChange(e, 'description')}
              />
            </div>
            <Box header={'ข้อกำหนดของบริการ'} bodyPadding='0.5rem 1rem'>
              <BoxContainer>
                <GridBox>
                  <TextLabel>จำนวนพนักงาน</TextLabel>
                  <BoxAddNumber
                    count={service.staffCount}
                    onCountClick={type => onChange(type, 'staffCount')}
                  />
                </GridBox>
                <GridBox>
                  <TextLabel>จำนวนลูกค้า</TextLabel>
                  <BoxAddNumber
                    count={service.customerCount}
                    onCountClick={type => onChange(type, 'customerCount')}
                  />
                </GridBox>
              </BoxContainer>
            </Box>
            <TableTimePrice
              timePrices={service.timePrices}
              onAddTimePrice={onAddTimePrice}
              onRemoveTimePrice={index => onRemoveTimePrice(index)}
              onTimePriceChange={onTimePriceChange}
              onAddRoom={index => onAddRoom(index)}
              onRemoveRoomType={(indexTimePrice, indexRoomType) =>
                onRemoveRoomType(indexTimePrice, indexRoomType)
              }
              onClick={onToggleRoomType}
              onOptionRoomTypeClick={onOptionRoomTypeClick}
              onOptionSelectAllClick={onOptionSelectAllClick}
              onRoomClick={onRoomClick}
              onDurationChange={onDurationChange}
              onRoomDetailChange={onRoomDetailChange}
            />
            <TableAgency
              timePrices={service.timePrices}
              agencies={agencies}
              onTimePriceChange={onTimePriceChange}
            />
            <TableTimePrepare
              prepareTime={service.prepareTime}
              onAddPrepareTime={onAddPrepareTime}
            />
            <SwitchApply
              onApplyChange={onApplyChange}
              applyToAll={service.applyToAll}
            />
            <SwitchStatus
              onSuspendChange={onSuspendChange}
              status={!service.suspend}
            />
          </BodyContainer>
          <div className='pb-4'></div>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              background: 'white',
              zIndex: '2',
            }}
          >
            <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
              <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                <Button2
                  color='#98A1B2'
                  border='2px solid #98A1B2'
                  backgroundColor='white'
                  fontWeight='600'
                  onClick={() => handleClose(true)}
                >
                  ยกเลิก
                </Button2>
              </div>
              <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                <ButtonSave
                  border='2px solid #53389E'
                  color='#FFFFFF'
                  backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                  backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                  fontWeight='600'
                  icon={<SaveIcon size='15' />}
                  onClick={() => onSaveClick()}
                >
                  บันทึก
                </ButtonSave>
              </div>
            </div>
          </div>
        </Scrollbar>
      </Card>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddService
