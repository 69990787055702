import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Button2 from '../../atoms/Button2'
import TextLabel from '../../atoms/TextLabel'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import utils from '../../utils'
import PopupAddTip from '../popups/PopupAddTip'
import Scrollbar from '../../atoms/Scrollbar'
import InputText from '../../atoms/InputText'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  border: 1px solid #e4e8eb;
  gap: 0.5rem;
`
const BoxService = styled.div`
  width: calc(100% + 1rem);
  display: flex;
  background: white;
  border-radius: 8px;
  gap: 0.5rem;
  margin-left: -0.5rem;
`
const BoxServiceInside = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5rem;

  border-top: ${props => props.border || '1px solid #e4e8eb'};
  :last-child {
    border-bottom: ${props => props.border || '1px solid #e4e8eb'};
  }
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr;
  gap: 0.5rem;
  align-items: center;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const TextService = styled.div`
  font-weight: 500;
  font-size: 1rem;
  color: ${props => props.color || '#000000'};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
const IconStyle = styled.div`
  position: absolute;
  right: 1.8rem;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  height: 100%;
`
const DivInputStyle = styled.div`
  position: relative;
  padding: 0;
  height: fit-content;
`

const TableOrder = ({ heightCard, heightDiscount, products }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const bookingOrderState = useSelector(state => state.bookingHistoryReducers)
  const { orders, activeBtnPayChannelType } = bookingOrderState || {}
  const [openPopupTip, setOpenPopupTip] = useState(false)
  const [activeTipStaff, setActiveTipStaff] = useState({
    staffId: -1,
    staffImage: '',
    staffName: '',
    tip: '',
    orderIndex: -1,
    serviceIndex: -1,
    payChannelTypeTip: -1,
  })

  const onAddTipClick = (tip, payChannelType) => {
    const newArrOrder = [...orders]

    newArrOrder[activeTipStaff.orderIndex].services[
      activeTipStaff.serviceIndex
    ] = {
      ...newArrOrder[activeTipStaff.orderIndex].services[
        activeTipStaff.serviceIndex
      ],
      tip: parseInt(tip || 0),
      payChannelTypeTip:
        +tip === 0 || tip ? payChannelType.payChannelTypeId : -1,
    }
    const totalTip = newArrOrder.reduce((acc, info) => {
      const { services } = info

      for (const service of services) {
        acc += +service.tip || 0
      }

      return acc
    }, 0)

    dispatch(getOrderBooking({ orders: newArrOrder, totalTip }))

    setOpenPopupTip(false)
    setActiveTipStaff({
      staffId: -1,
      staffImage: '',
      staffName: '',
      tip: '',
      orderIndex: -1,
      serviceIndex: -1,
      payChannelTypeTip: -1,
    })
  }

  const onServiceDiscountChange = (value, orderIndex, serviceIndex) => {
    if (utils.checkNumber(value)) {
      const newArrOrder = [...orders]
      let newServiceDiscount = 0

      for (let i = 0; i < orders.length; i++) {
        const { services } = orders[i]
        for (let j = 0; j < services.length; j++) {
          const { unit, price, serviceDiscount } = services[j]
          if (j === serviceIndex && i === orderIndex) {
            if (unit === 1) {
              newArrOrder[orderIndex].services[serviceIndex].serviceDiscount =
                +value <= 100 ? +value : 100
              newServiceDiscount +=
                +price * ((+value <= 100 ? +value : 100) / 100)
            } else {
              newArrOrder[orderIndex].services[serviceIndex].serviceDiscount =
                +value <= price ? value : price
              newServiceDiscount += +value <= price ? +value : +price
            }
          } else {
            if (unit === 1) {
              newServiceDiscount += +price * (+serviceDiscount / 100)
            } else {
              newServiceDiscount += +serviceDiscount
            }
          }
        }
      }
      dispatch(
        getOrderBooking({
          orders: newArrOrder,
          serviceDiscount: newServiceDiscount,
        })
      )
    }
  }

  const onUnitChange = (value, orderIndex, serviceIndex) => {
    const newArrOrder = [...orders]
    let newServiceDiscount = 0

    newArrOrder[orderIndex].services[serviceIndex].unit = value

    for (const order of orders) {
      const { services } = order
      for (const service of services) {
        const { unit, serviceDiscount, price } = service
        if (unit === 1) {
          newServiceDiscount += +price * (+serviceDiscount / 100)
        } else {
          newServiceDiscount += +serviceDiscount
        }
      }
    }
    dispatch(
      getOrderBooking({
        orders: newArrOrder,
        serviceDiscount: newServiceDiscount,
      })
    )
  }

  return (
    <Container>
      <Scrollbar
        id='payment-table-order'
        maxHeight={`calc(100vh - ${heightCard}px - ${heightDiscount}px - 16.5rem - ${
          products.length !== 0 ? '10.5rem' : '0px'
        })`}
      >
        {orders.map(
          ({ services, name, firstName }, index) =>
            !!services.length && (
              <Box key={index}>
                <div style={{ display: 'flex', gap: '0.25rem' }}>
                  <TextLabel color='#8C56D9'>{`${t('customerNo.')}#${
                    index + 1
                  } `}</TextLabel>
                  <TextLabel color='#8C56D9' fontWeight='600'>{`${
                    name || firstName || ''
                  }`}</TextLabel>
                </div>
                {services.map(
                  (
                    {
                      _id,
                      serviceId,
                      service,
                      name,
                      image,
                      staffId,
                      staffImage,
                      staffName,
                      time,
                      price,
                      timePriceId,
                      tip,
                      payChannelTypeTip,
                      serviceDiscount,
                      unit,
                    },
                    serviceIndex
                  ) => (
                    <BoxService key={_id}>
                      <BoxServiceInside>
                        <GridBox>
                          <FlexBox flexDirection='column'>
                            <TextService fontWeight='500' fontSize='0.875rem'>
                              {service || name}
                            </TextService>
                            <FlexBox style={{ lineHeight: '1.05rem' }}>
                              <TextLabel
                                color='#98A1B2'
                                fontSize='0.75rem'
                              >{`${time} ${t('mins')}`}</TextLabel>
                            </FlexBox>
                          </FlexBox>
                          <FlexBox flexDirection='column' alignItems='center'>
                            <TextLabel
                              textDecoration={
                                serviceDiscount ? 'line-through' : ''
                              }
                              fontSize={serviceDiscount ? '0.85rem' : ''}
                            >{`${utils.formatNumber(
                              price || 0,
                              0
                            )}`}</TextLabel>
                            {serviceDiscount && (
                              <TextLabel color='#DB2D1E'>
                                {unit === 1
                                  ? price - +price * (+serviceDiscount / 100)
                                  : price - serviceDiscount}
                              </TextLabel>
                            )}
                          </FlexBox>
                          <FlexBox flexDirection='column'>
                            <TextLabel fontSize='0.85rem'>
                              {t('discount')}
                            </TextLabel>
                            <DivInputStyle>
                              <InputText
                                padding='0 0 0 0.1rem'
                                placeholder='0'
                                onChange={val =>
                                  onServiceDiscountChange(
                                    val.target.value,
                                    index,
                                    serviceIndex
                                  )
                                }
                                value={serviceDiscount}
                              />
                              <IconStyle>
                                <div
                                  style={{
                                    display: 'flex',
                                    backgroundColor: '#E4E8EB',
                                    borderRadius: '0px 2px 2px 0px',
                                    width: 'calc(100% + 1.75rem)',
                                    alignItems: 'center',
                                    padding: '0.25rem',
                                    height: 'calc(100% - 0.1rem)',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '50%',
                                      borderRadius: '4px',
                                      fontWeight: `${
                                        unit === 1 ? '700' : '500'
                                      }`,
                                      color: `${
                                        unit === 1 ? '#000' : '#475467'
                                      }`,
                                      backgroundColor: `${
                                        unit === 1 ? '#FFF' : ''
                                      }`,
                                      fontSize: '0.75rem',
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      onUnitChange(1, index, serviceIndex)
                                    }
                                  >
                                    %
                                  </div>
                                  <div
                                    style={{
                                      width: '50%',
                                      textAlign: 'center',
                                      borderRadius: '4px',
                                      fontWeight: `${
                                        unit === 2 ? '700' : '500'
                                      }`,
                                      color: `${
                                        unit === 2 ? '#000' : '#475467'
                                      }`,
                                      backgroundColor: `${
                                        unit === 2 ? '#FFF' : ''
                                      }`,
                                      fontSize: '0.75rem',
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      onUnitChange(2, index, serviceIndex)
                                    }
                                  >
                                    ฿
                                  </div>
                                </div>
                              </IconStyle>
                            </DivInputStyle>
                          </FlexBox>
                          <FlexBox flexDirection='column' alignItems='center'>
                            <TextLabel fontSize='0.85rem'>
                              {t('therapist')}
                            </TextLabel>
                            <TextLabel
                              fontSize={staffId === -1 ? '0.875rem' : ''}
                              color={staffId === -1 ? '#DB2D1E' : ''}
                            >
                              {staffId === -1
                                ? t('therapistUnassigned')
                                : staffName}
                            </TextLabel>
                          </FlexBox>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                            }}
                          >
                            {staffId !== -1 && (
                              <div
                                style={{ width: '100%', position: 'relative' }}
                              >
                                <Button2
                                  backgroundColor='linear-gradient(221.86deg, #8C56D9 2.71%, #53389E 97.79%)'
                                  margin='0'
                                  color='#FFF'
                                  onClick={() => {
                                    setOpenPopupTip(!openPopupTip)
                                    setActiveTipStaff({
                                      staffId,
                                      staffImage,
                                      staffName,
                                      time,
                                      tip,
                                      orderIndex: index,
                                      serviceIndex,
                                      payChannelTypeTip,
                                    })
                                  }}
                                >
                                  {t('tip')}
                                </Button2>
                                {tip ? (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      bottom: '70%',
                                      right: '-0.5rem',
                                      background: '#32D582',
                                      border: '2px solid #FFFFFF',
                                      borderRadius: '8px',
                                      fontSize: '0.75rem',
                                      padding: '0rem 0.5rem',
                                      color: '#FFF',
                                    }}
                                  >
                                    {tip}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                          </div>
                        </GridBox>
                      </BoxServiceInside>
                    </BoxService>
                  )
                )}
              </Box>
            )
        )}
        <div className='mt-4'></div>
      </Scrollbar>

      {openPopupTip && (
        <PopupAddTip
          handleClose={() => {
            setOpenPopupTip(false)
            setActiveTipStaff({
              staffId: -1,
              staffImage: '',
              staffName: '',
              tip: '',
              orderIndex: -1,
              serviceIndex: -1,
              payChannelTypeTip: -1,
            })
          }}
          onAddTipClick={onAddTipClick}
          activeTipStaff={activeTipStaff}
          activeBtnPayChannelType={activeBtnPayChannelType}
        />
      )}
    </Container>
  )
}

export default TableOrder
