import React from 'react'
import styled, { CSSProperties } from 'styled-components'

interface DateIconProps extends CSSProperties {
  disabled?: string
  size?: string
  onClick: () => void
}

const SVG = styled.svg<DateIconProps>`
  -webkit-tap-highlight-color: transparent;
  margin: ${props => props.margin || '0'};
  &:hover {
    user-select: none;
    cursor: ${props => (!props.disabled ? 'pointer' : '')};
  }
`

const DateIcon = ({
  size,
  color,
  margin,
  disabled,
  onClick,
}: DateIconProps) => (
  <SVG
    width={size || '29'}
    height={size || '29'}
    margin={margin}
    disabled={disabled}
    onClick={onClick}
    viewBox='0 0 29 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25.5556 28.8629H3.30728C1.48287 28.8629 0 27.38 0 25.5556V5.71234C0 3.88792 1.48287 2.40527 3.30728 2.40527H25.5556C27.38 2.40527 28.8629 3.88792 28.8629 5.71234V25.5556C28.8629 27.38 27.38 28.8629 25.5556 28.8629ZM3.30728 4.20921C2.47864 4.20921 1.80393 4.8837 1.80393 5.71234V25.5556C1.80393 26.3842 2.47864 27.0589 3.30728 27.0589H25.5556C26.3843 27.0589 27.059 26.3842 27.059 25.5556V5.71234C27.059 4.8837 26.3843 4.20921 25.5556 4.20921H3.30728Z'
      fill={color || '#4E4E72'}
    />

    <path
      d='M27.9609 11.425H0.901966C0.404079 11.425 0 11.0209 0 10.5231C0 10.0252 0.404079 9.62109 0.901966 9.62109H27.9609C28.4588 9.62109 28.8629 10.0252 28.8629 10.5231C28.8629 11.0209 28.4588 11.425 27.9609 11.425Z'
      fill={color || '#4E4E72'}
    />

    <path
      d='M6.91564 7.21573C6.41775 7.21573 6.01367 6.81165 6.01367 6.31376V0.901966C6.01367 0.404079 6.41775 0 6.91564 0C7.41352 0 7.8176 0.404079 7.8176 0.901966V6.31376C7.8176 6.81165 7.41352 7.21573 6.91564 7.21573Z'
      fill={color || '#4E4E72'}
    />

    <path
      d='M21.9479 7.21573C21.45 7.21573 21.0459 6.81165 21.0459 6.31376V0.901966C21.0459 0.404079 21.45 0 21.9479 0C22.4458 0 22.8498 0.404079 22.8498 0.901966V6.31376C22.8498 6.81165 22.4458 7.21573 21.9479 7.21573Z'
      fill={color || '#4E4E72'}
    />
  </SVG>
)

export default DateIcon
