import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import styled, { CSSProperties } from 'styled-components'

const Container = styled.div<Omit<Button2Props, 'icon' | 'iconBF'>>`
  background: ${props =>
    props.active
      ? props.backgroundColorActive ||
        'linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
      : props.backgroundColor || '#f4f6fa'};
  color: ${props => (props.active ? '#fff' : props.color || '#000')};
  opacity: ${props => props.opacity || ''};
  border: ${props => props.border || 'none'};
  border-radius: ${props => props.borderRadius || '5px'};
  padding: ${props => props.padding || '0.5rem 0'};
  outline: 0;
  margin: ${props => props.margin || '0.125rem 0'};
  width: ${props => props.width || '100%'};
  font-size: ${props => props.size || '1rem'};
  font-weight: ${props => props.fontWeight || '400'};
  text-align: center;
  cursor: ${props => props.cursor || 'pointer'};
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'center'};
  &:hover {
    background: ${props =>
      props.disable
        ? 'none'
        : props.backgroundColorHover ||
          'linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'};
    color: ${props => (props.disable ? '#000' : props.colorHover || '#fff')};
  }
  & > svg {
    margin-left: ${props => props.marginLeft || '0.5rem'};
    margin-right: ${props => props.marginRight || '0.5rem'};
  }
`

export interface Button2Props extends CSSProperties {
  icon?: ReactNode
  iconBF?: ReactNode
  onClick?: (e: any) => void
  colorHover?: string
  backgroundColorActive?: CSSProperties['backgroundColor']
  backgroundColorHover?: CSSProperties['backgroundColor']
  active?: boolean
  size?: CSSProperties['fontSize']
  disable?: boolean
  disabled?: boolean
}

const Button2 = ({
  icon,
  children,
  active: _active,
  onClick,
  backgroundColor,
  backgroundColorActive,
  backgroundColorHover,
  width,
  iconBF,
  border,
  color,
  margin,
  cursor,
  padding,
  fontWeight,
  size,
  borderRadius,
  marginLeft,
  marginRight,
  justifyContent,
  colorHover,
  opacity,
  disable: _disable,
}: PropsWithChildren<Button2Props>) => {
  const [active, setActive] = useState(false)
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    setDisable(!!_disable)
  }, [_disable])

  useEffect(() => {
    setActive(!!_active)
  }, [_active])

  const onMouseEnter = () => {
    setActive(true)
  }

  const onMouseLeave = () => {
    if (_active) return
    setActive(false)
  }

  return (
    <Container
      active={active}
      backgroundColor={backgroundColor}
      backgroundColorActive={backgroundColorActive}
      backgroundColorHover={backgroundColorHover}
      opacity={opacity}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      width={width}
      border={border}
      color={color}
      colorHover={colorHover}
      margin={margin}
      cursor={cursor}
      padding={padding}
      fontWeight={fontWeight}
      size={size}
      borderRadius={borderRadius}
      marginLeft={marginLeft}
      marginRight={marginRight}
      justifyContent={justifyContent}
    >
      {iconBF === 'after' ? (
        <>
          {children}
          {icon &&
            React.isValidElement(icon) &&
            React.cloneElement(icon as ReactElement<{ color: string }>, {
              color: disable
                ? icon.props.color
                : active
                ? '#fff'
                : icon.props.color,
            })}
        </>
      ) : (
        <>
          {icon &&
            React.isValidElement(icon) &&
            React.cloneElement(icon as ReactElement<{ color: string }>, {
              color: disable
                ? icon.props.color
                : active
                ? '#fff'
                : icon.props.color,
            })}
          {children}
        </>
      )}
    </Container>
  )
}

export default Button2
