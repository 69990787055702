import React from 'react'
import styled, { CSSProperties } from 'styled-components'

interface CameraIconProps extends CSSProperties {
  size?: string
}

const SVG = styled.svg<CameraIconProps>`
  -webkit-tap-highlight-color: transparent;
`

const CameraIcon = ({ size, color }: CameraIconProps) => (
  <SVG
    width={size || '62'}
    height={size || '62'}
    viewBox='0 0 62 62'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M52.9867 13.5625H44.6671L43.6984 10.6563C43.2315 9.24511 42.3312 8.01725 41.1258 7.14756C39.9204 6.27786 38.4713 5.81067 36.9849 5.81251H25.0151C23.5291 5.81137 22.0806 6.27887 20.8756 7.14849C19.6707 8.01812 18.7706 9.24558 18.3036 10.6563L17.3329 13.5625H9.01325C7.13727 13.5646 5.33872 14.3107 4.0122 15.6372C2.68569 16.9637 1.93955 18.7623 1.9375 20.6383V49.1195C1.9416 50.9941 2.68864 52.7907 4.01494 54.1155C5.34123 55.4404 7.13861 56.1855 9.01325 56.1875H52.9945C54.8691 56.1834 56.6657 55.4364 57.9905 54.1101C59.3154 52.7838 60.0605 50.9864 60.0625 49.1118V20.6305C60.0584 18.7559 59.3114 16.9593 57.9851 15.6345C56.6588 14.3096 54.8614 13.5646 52.9867 13.5625ZM56.1875 49.1118C56.1865 49.9603 55.8489 50.7739 55.2489 51.3739C54.6489 51.9739 53.8353 52.3115 52.9867 52.3125H9.01325C8.16467 52.3115 7.35115 51.9739 6.75111 51.3739C6.15108 50.7739 5.81353 49.9603 5.8125 49.1118V20.6305C5.81557 19.7833 6.15403 18.9717 6.75384 18.3734C7.35365 17.775 8.16601 17.4385 9.01325 17.4375H18.7298C19.1363 17.4372 19.5324 17.3091 19.862 17.0712C20.1916 16.8334 20.4381 16.4979 20.5666 16.1123L21.979 11.8749C22.1911 11.2376 22.5986 10.6833 23.1435 10.2906C23.6885 9.89795 24.3434 9.68693 25.0151 9.68751H36.9849C37.657 9.68677 38.3121 9.89795 38.8572 10.291C39.4022 10.6841 39.8095 11.239 40.021 11.8769L41.4334 16.1123C41.5619 16.4979 41.8084 16.8334 42.138 17.0712C42.4676 17.3091 42.8637 17.4372 43.2702 17.4375H52.9867C53.8353 17.4385 54.6489 17.7761 55.2489 18.3761C55.8489 18.9762 56.1865 19.7897 56.1875 20.6383V49.1118Z'
      fill={color || 'white'}
    />
    <path
      d='M31 19.375C28.126 19.375 25.3165 20.2272 22.9269 21.824C20.5372 23.4207 18.6747 25.6901 17.5749 28.3454C16.475 31.0006 16.1873 33.9224 16.748 36.7412C17.3087 39.5599 18.6926 42.1492 20.7249 44.1814C22.7571 46.2136 25.3463 47.5976 28.1651 48.1583C30.9839 48.719 33.9056 48.4312 36.5609 47.3314C39.2161 46.2315 41.4856 44.369 43.0823 41.9794C44.679 39.5897 45.5312 36.7803 45.5312 33.9062C45.5271 30.0536 43.9949 26.3599 41.2706 23.6356C38.5464 20.9114 34.8527 19.3791 31 19.375ZM31 44.5625C28.8924 44.5625 26.8321 43.9375 25.0797 42.7666C23.3273 41.5957 21.9615 39.9314 21.1549 37.9842C20.3484 36.037 20.1373 33.8944 20.5485 31.8273C20.9597 29.7602 21.9746 27.8614 23.4649 26.3711C24.9552 24.8808 26.854 23.8659 28.9211 23.4548C30.9882 23.0436 33.1308 23.2546 35.078 24.0612C37.0251 24.8677 38.6894 26.2335 39.8603 27.986C41.0313 29.7384 41.6562 31.7986 41.6562 33.9062C41.6532 36.7315 40.5295 39.4402 38.5317 41.438C36.5339 43.4357 33.8253 44.5594 31 44.5625Z'
      fill={color || 'white'}
    />
    <path
      d='M50.375 25.1875C51.4451 25.1875 52.3125 24.3201 52.3125 23.25C52.3125 22.1799 51.4451 21.3125 50.375 21.3125C49.3049 21.3125 48.4375 22.1799 48.4375 23.25C48.4375 24.3201 49.3049 25.1875 50.375 25.1875Z'
      fill={color || 'white'}
    />
  </SVG>
)

export default CameraIcon
