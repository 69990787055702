import React from 'react'
import { IconProps } from './IconPropsType'

const PayWageIcon = ({ size, color }: IconProps) => (
  <svg
    width={size || '17'}
    height={size || '21'}
    viewBox='0 0 17 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2817_2286)'>
      <path
        d='M14.696 9.26362C14.171 9.26362 13.696 9.06362 13.321 8.68862L11.646 7.01362C11.571 7.91362 10.821 8.63862 9.89604 8.63862H6.32104C5.62105 8.63862 4.99605 8.36362 4.49604 7.88862L0.696045 4.11362L4.22105 0.588623L5.49605 1.86362C5.74605 2.11362 6.07104 2.23862 6.42104 2.23862H11.196C11.946 2.23862 12.646 2.53862 13.171 3.06362L16.096 5.98862C16.846 6.73862 16.846 7.96362 16.096 8.71362C15.696 9.06362 15.221 9.26362 14.696 9.26362ZM9.89604 5.13862H11.871L14.371 7.63862C14.546 7.81362 14.821 7.81362 14.996 7.63862C15.071 7.56362 15.121 7.43862 15.121 7.33862C15.121 7.23862 15.071 7.11362 14.996 7.03862L12.096 4.11362C11.846 3.86362 11.521 3.73862 11.171 3.73862H6.39604C5.64604 3.73862 4.94604 3.43862 4.42104 2.91362L4.22105 2.71362L2.82104 4.11362L5.54604 6.83862C5.74604 7.03862 6.02104 7.16362 6.32104 7.16362H9.92105C10.071 7.16362 10.171 7.03862 10.171 6.91362C10.171 6.78862 10.046 6.66362 9.92105 6.66362H7.87105C7.44604 6.66362 7.12105 6.33862 7.12105 5.91362C7.12105 5.48862 7.44604 5.16362 7.87105 5.16362H9.89604V5.13862Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M13.4211 20.5887L12.1461 19.3137C11.8961 19.0637 11.5711 18.9387 11.2211 18.9387H6.47109C5.72109 18.9387 5.02109 18.6387 4.49609 18.1137L1.57109 15.2137C1.19609 14.8387 0.996094 14.3637 0.996094 13.8387C0.996094 13.3137 1.19609 12.8387 1.57109 12.4637C1.94609 12.0887 2.42109 11.8887 2.94609 11.8887C3.47109 11.8887 3.94609 12.0887 4.32109 12.4637L5.99609 14.1387C6.07109 13.2387 6.82109 12.5137 7.74609 12.5137H11.3461C12.0461 12.5137 12.6711 12.7887 13.1711 13.2637L16.9461 17.0637L13.4211 20.5887ZM2.94609 13.4137C2.82109 13.4137 2.72109 13.4637 2.64609 13.5387C2.57109 13.6137 2.52109 13.7387 2.52109 13.8387C2.52109 13.9637 2.57109 14.0637 2.64609 14.1387L5.57109 17.0637C5.82109 17.3137 6.14609 17.4387 6.49609 17.4387H11.2711C12.0211 17.4387 12.7211 17.7387 13.2461 18.2637L13.4461 18.4637L14.8461 17.0637L12.0961 14.3387C11.8961 14.1387 11.6211 14.0137 11.3211 14.0137H7.74609C7.59609 14.0137 7.49609 14.1387 7.49609 14.2637C7.49609 14.4137 7.62109 14.5137 7.74609 14.5137H9.79609C10.2211 14.5137 10.5461 14.8387 10.5461 15.2637C10.5461 15.6887 10.2211 16.0137 9.79609 16.0137H5.74609L3.24609 13.5137C3.17109 13.4387 3.04609 13.4137 2.94609 13.4137Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M9.17097 9.98857C9.17097 9.81357 9.07097 9.66357 8.84597 9.66357H8.49597V10.3136H8.84597C9.04597 10.3136 9.17097 10.1636 9.17097 9.98857Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M8.99607 10.5886H8.47107V11.2386H8.99607C9.22107 11.2386 9.32107 11.0886 9.32107 10.9136C9.32107 10.7136 9.19607 10.5886 8.99607 10.5886Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M8.74602 7.6886C7.19602 7.6886 5.92102 8.9636 5.92102 10.5136C5.92102 12.0636 7.19602 13.3386 8.74602 13.3386C10.296 13.3386 11.571 12.0636 11.571 10.5136C11.571 8.9386 10.321 7.6886 8.74602 7.6886ZM9.14602 11.5886H8.92102V11.8386C8.92102 11.9136 8.84602 11.9636 8.77102 11.9636C8.69602 11.9636 8.62102 11.9136 8.62102 11.8386V11.5886H8.22102C8.09602 11.5886 7.97102 11.4886 7.97102 11.3386V9.5136C7.97102 9.3886 8.07102 9.2636 8.22102 9.2636H8.62102V9.0136C8.62102 8.9386 8.67102 8.8886 8.77102 8.8886C8.84602 8.8886 8.92102 8.9386 8.92102 9.0136V9.2636H8.97102C9.39602 9.2636 9.64602 9.5136 9.64602 9.8886C9.64602 10.0886 9.54602 10.2636 9.39602 10.3636C9.64602 10.4386 9.79602 10.6386 9.79602 10.9386C9.79602 11.3136 9.59602 11.5886 9.14602 11.5886Z'
        fill={color || '#8C56D9'}
      />
    </g>
    <defs>
      <clipPath id='clip0_2817_2286'>
        <rect
          width='16.25'
          height='20'
          fill='white'
          transform='translate(0.696045 0.588623)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default PayWageIcon
