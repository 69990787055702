import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import api from '../../api'
import PopupAddPromotion from '../popups/PopupAddPromotion'
import BodyLeft from './BodyLeft'
import PopupConfirmDelete from '../popups/PopupConfirmDelete'

const Component = styled.div`
  display: flex;
  width: calc(100%);
  overflow: hidden;
`
const Left = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 1 100%'};
  max-width: ${props => props.width || '100%'};
  min-width: ${props => props.width || '100%'};
`

const Body = ({ create, handleClose: _handleClose }) => {
  const [promotions, setPromotions] = useState([])
  const [currentPromotion, setCurrentPromotion] = useState('')
  const [activeDeleteId, setActiveDeleteId] = useState(-1)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    callApiPromotion()
  }, [])

  const callApiPromotion = async () => {
    try {
      const { data } = await api.getPromotionByStoreId()
      setPromotions(data)
    } catch (err) {
      console.log(err)
    }
  }

  const handleClose = () => {
    _handleClose()
    setCurrentPromotion('')
    setActiveDeleteId(-1)
    setSuccess(false)
    callApiPromotion()
  }

  const onCancelClick = async () => {
    setCurrentPromotion('')
    await callApiPromotion()
  }

  const onSelectPromotion = _id => {
    setTimeout(() => {
      const findPromotion = promotions.find(c => c._id === _id || '') || ''
      setCurrentPromotion(findPromotion)
    }, 500)
  }

  const onDeleteClick = async (checkAll, checkId) => {
    try {
      const promotionRemove = promotions.reduce((acc, info) => {
        const { _id } = info

        if (checkAll) {
          acc.push(info)
        } else {
          const check = checkId.includes(_id)

          if (check) {
            acc.push(info)
          }
        }

        return acc
      }, [])

      const { success } = await api.removePromotionAll(promotionRemove)

      if (success) {
        await callApiPromotion()
        await onCancelClick()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onDeletePromotionClick = async id => {
    setActiveDeleteId(id)
  }

  const onConfirmDeleteClick = async () => {
    try {
      const { success } = await api.removePromotion(activeDeleteId)
      if (success) {
        // await callApiPromotion()
        // await onCancelClick()
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Component>
      <Left flex='1 1 100%' width='1 1 100%'>
        <BodyLeft
          promotions={promotions}
          onSelectPromotion={onSelectPromotion}
          onDeletePromotionClick={onDeletePromotionClick}
          onDeleteClick={onDeleteClick}
        />
      </Left>
      {/* <Right
        flex={openRight ? '1 1 50%' : '1 1 0%'}
        width={openRight ? '1 1 50%' : '1 1 0%'}
      >
        <BodyRight
          onCancelClick={onCancelClick}
          openRight={openRight}
          currentPromotion={currentPromotion}
        />
      </Right> */}
      {(create || currentPromotion) && (
        <PopupAddPromotion
          handleClose={handleClose}
          currentPromotion={currentPromotion}
        />
      )}
      {activeDeleteId !== -1 && (
        <PopupConfirmDelete
          handleClose={handleClose}
          onConfirmDeleteClick={onConfirmDeleteClick}
          text='ยืนยันการลบข้อมูล'
          success={success}
        />
      )}
    </Component>
  )
}

export default Body
