import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import AddDataIcon from './AddDataIcon'
import Button from './Button3'
import TextLabel from './TextLabel'

import useOnClickOutside from '../hook/useOnClickOutside'

const Container = styled.div`
  position: relative;
`

const BoxItemContainer = styled.div`
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e4e8eb;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  top: 3.25rem;
  transition: transform 1s ease-out, opacity 1s ease-out;
  z-index: 2;
`

const BoxItem = styled.div`
  padding: 0.5rem 1rem 0.25rem;
  cursor: pointer;
  :hover {
    background-color: #8c56d9;
    color: #ffffff;
  }
`

export interface BoxButtonProps {
  name: string
  menus: { name: string; onClick: () => void }[]
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

const BoxButton = ({ name, menus, onClick }: BoxButtonProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const wrapperRef = useRef(null)

  useOnClickOutside(wrapperRef, () => {
    setIsOpen(false)
  })

  const onDropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (menus?.length) {
      setIsOpen(v => !v)
    } else {
      onClick(e)
    }
  }

  return (
    <Container ref={wrapperRef}>
      <Button
        backgroundColor='linear-gradient(221.86deg, #8c56d9 2.71%, #53389e 97.79%)'
        color='#fff'
        padding='0.5rem 1rem'
        fontWeight={500}
        icon={<AddDataIcon size={16} />}
        withIconBackground={false}
        onClick={e => onDropClick(e)}
      >
        {name}
      </Button>
      {isOpen && (
        <BoxItemContainer>
          {menus.map(({ name, onClick }) => (
            <BoxItem key={name}>
              <TextLabel
                color='inherit'
                onClick={e => {
                  e.stopPropagation()
                  onClick()
                }}
              >
                {name}
              </TextLabel>
            </BoxItem>
          ))}
        </BoxItemContainer>
      )}
    </Container>
  )
}

export default BoxButton
