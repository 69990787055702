import React from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'

//@ts-ignore
import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'

//@ts-ignore
import api from '../../api'
import { Customer } from '../../redux/models/Customer'
import utils from '../../utils'

interface CustomerOverviewStyle extends CSSProperties {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`
const GridBox = styled.div<CustomerOverviewStyle>`
  display: grid;
  grid-template-columns: ${props => props.columns || '1fr 1fr 1fr 1fr 1fr 1fr'};
  gap: 0.25rem;
`
const Box = styled.div<CustomerOverviewStyle>`
  background: ${props => props.background || '#ffffff'};
  border-radius: 4px;
  margin-bottom: ${props => props.marginBottom || '0.5rem'};
  padding: ${props => props.padding || '1rem'};
  cursor: pointer;
`
const FlexBox = styled.div<CustomerOverviewStyle>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'unset'};
`
const BoxDownload = styled.button<CustomerOverviewStyle>`
  background: ${props => props.background || '#ffffff'};
  border-radius: 4px;
  margin-bottom: ${props => props.marginBottom || '0.5rem'};
  padding: ${props => props.padding || '1rem'};
`

interface CustomerOverviewProps {
  customers: Customer[]
  hasMoreCustomer: boolean
  onCustomerClick: (customer: Customer) => void
  fetchData: () => void
}

const CustomerOverview = ({
  customers,
  hasMoreCustomer,
  onCustomerClick,
  fetchData,
}: CustomerOverviewProps) => {
  const { t } = useTranslation()
  const store = useSelector((state: any) => state.store)

  const onDownloadClick = async () => {
    try {
      const data = await api.downloadCustomer()
      const blob = new Blob([new Uint8Array(data.data.data.data)], {
        type: 'text/csv;charset=utf8',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `customer_${store.name}-${store.branch}.xlsx`
      link.click()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <GridBox columns='1fr 1fr'>
        <FlexBox>
          <TextLabel fontWeight='600' fontSize='1.6rem'>
            {t('customerSearch')}
          </TextLabel>
        </FlexBox>
        <FlexBox justifyContent='flex-end'>
          <BoxDownload
            marginBottom='0'
            padding='0.5rem 1rem'
            style={{ borderRadius: '8px', border: '1px solid #E4E8EB' }}
            onClick={() => onDownloadClick()}
          >
            <TextLabel color='#202123' fontWeight='500'>
              ดาวน์โหลดข้อมูล
            </TextLabel>
          </BoxDownload>
        </FlexBox>
      </GridBox>

      <GridBox className='mb-2 mt-4' style={{ padding: '0 1rem' }}>
        <TextLabel color='#98A1B2'>{t('customer')}</TextLabel>
        <TextLabel color='#98A1B2'>{t('phoneNumber')}</TextLabel>
        <TextLabel color='#98A1B2'>{t('gender')}</TextLabel>
        <TextLabel color='#98A1B2'>{t('age')}</TextLabel>
        <TextLabel color='#98A1B2'>{t('nationality')}</TextLabel>
        <TextLabel color='#98A1B2'>{t('extraRequest')}</TextLabel>
      </GridBox>
      <Scrollbar id='customer'>
        <InfiniteScroll
          dataLength={customers.length}
          hasMore={hasMoreCustomer}
          next={fetchData}
          loader={<h4>Loading ... </h4>}
          scrollableTarget='customer'
        >
          {customers.map((customer, index) => (
            <Box key={index} onClick={() => onCustomerClick(customer)}>
              <GridBox>
                <TextLabel color='#98A1B2'>
                  {customer.name || customer.firstName || '-'}
                </TextLabel>
                <TextLabel color='#98A1B2'>
                  {utils.formatPhoneNumber(customer.phoneNumber + '') || '-'}
                </TextLabel>
                <TextLabel color='#98A1B2'>
                  {typeof customer.gender === 'number'
                    ? customer.gender
                    : customer.gender?.label || ''}
                </TextLabel>
                <TextLabel color='#98A1B2'>
                  {typeof customer.age === 'number'
                    ? customer.age
                    : customer.age?.label || ''}
                </TextLabel>
                <TextLabel color='#98A1B2'>
                  {typeof customer.nationality === 'number'
                    ? customer.nationality
                    : customer.nationality?.label || ''}
                </TextLabel>
                <TextLabel color='#98A1B2'>
                  {customer.specialRequest || '-'}
                </TextLabel>
              </GridBox>
            </Box>
          ))}
        </InfiniteScroll>
      </Scrollbar>
    </Container>
  )
}

export default CustomerOverview
