import React from 'react'

const OnlineIcon = ({ size, color }) => (
  <svg
    width={size || '21'}
    height={size || '24'}
    viewBox='0 0 21 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_860_22)'>
      <path
        d='M9.97333 17.1429C8.46095 17.1429 7.23809 15.92 7.23809 14.4076V7.30667C7.23809 5.79429 8.46095 4.57143 9.97333 4.57143H15.619V2.73524C15.619 1.22286 14.3962 0 12.8838 0H2.73524C1.22286 0 0 1.22286 0 2.73524V21.2648C0 22.7771 1.22286 24 2.73524 24H12.8838C14.3962 24 15.619 22.7771 15.619 21.2648V17.1429H9.97333ZM10.4457 20.9524H5.14286C4.61714 20.9524 4.19048 20.5257 4.19048 20C4.19048 19.4743 4.61714 19.0476 5.14286 19.0476H10.4495C10.9752 19.0476 11.4019 19.4743 11.4019 20C11.4019 20.5257 10.9752 20.9524 10.4495 20.9524H10.4457Z'
        fill={color || '#475467'}
      />
      <path
        d='M19.765 6.09375H9.57073C9.07168 6.09375 8.66406 6.49756 8.66406 7.00042V14.7147C8.66406 15.2138 9.06787 15.6214 9.57073 15.6214H19.7688C20.2679 15.6214 20.6755 15.2176 20.6755 14.7147V7.00042C20.6755 6.50137 20.2717 6.09375 19.7688 6.09375H19.765ZM16.9269 12.9699C16.6564 13.2785 16.1917 13.3014 15.8793 13.0385C15.5479 12.7604 15.1212 12.589 14.6526 12.589C14.1841 12.589 13.7536 12.7604 13.4183 13.0423C13.125 13.2899 12.6679 13.2709 12.405 12.9928C12.1155 12.6918 12.1231 12.2004 12.4355 11.9299C13.0298 11.4157 13.8031 11.1033 14.6526 11.1033C15.5022 11.1033 16.2755 11.4157 16.8736 11.9338C17.1783 12.2004 17.1974 12.669 16.9269 12.9738V12.9699ZM18.3174 10.9738C18.0507 11.2823 17.586 11.3128 17.2774 11.0461C16.5726 10.4366 15.6545 10.0671 14.6526 10.0671C13.6507 10.0671 12.6831 10.4595 11.9707 11.0995C11.6736 11.3661 11.2203 11.3471 10.9422 11.0614C10.6488 10.7528 10.6717 10.2652 10.9879 9.98327C11.9631 9.11089 13.2469 8.58137 14.6564 8.58137C16.066 8.58137 17.2774 9.08423 18.2412 9.9147C18.5574 10.1852 18.5955 10.6652 18.3212 10.9776L18.3174 10.9738Z'
        fill={color || '#475467'}
      />
    </g>
    <defs>
      <clipPath id='clip0_860_22'>
        <rect width='20.6705' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default OnlineIcon
