import React from 'react'

const UploadIcon = ({ size, color }) => (
  <svg
    width={size || '30'}
    height={size || '30'}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_670_522)'>
      <path
        d='M5.25585 12.1467C5.63763 12.1467 6.01941 12.001 6.31077 11.7147L13.0573 5.09377V20.8121C13.0573 21.646 13.7304 22.3192 14.5643 22.3192C15.3982 22.3192 16.0713 21.646 16.0713 20.8121V5.09879L22.8178 11.7197C23.1092 12.006 23.4909 12.1517 23.8727 12.1517C24.2545 12.1517 24.6514 12.001 24.9477 11.6996C25.5305 11.1068 25.5204 10.1524 24.9277 9.56966L15.6192 0.432016C15.6192 0.432016 15.569 0.396852 15.5489 0.376758C15.5037 0.336571 15.4585 0.296383 15.4082 0.261219C15.4082 0.261219 15.3982 0.261219 15.3932 0.251172C15.353 0.226055 15.3128 0.205961 15.2726 0.185867C15.2324 0.165774 15.1922 0.135633 15.147 0.120563C15.142 0.120563 15.137 0.120563 15.1319 0.115539C15.0867 0.0954454 15.0365 0.0853985 14.9913 0.0703282C14.9762 0.0703282 14.9611 0.0602813 14.9511 0.0552579C14.921 0.0502344 14.8958 0.0351641 14.8707 0.0301407C14.8707 0.0301407 14.8607 0.0301407 14.8556 0.0301407C14.8054 0.0200938 14.7602 0.0200938 14.71 0.0150703C14.6648 0.0150703 14.6195 0 14.5743 0H14.5593C14.5141 0 14.4688 0.0100469 14.4236 0.0150703C14.3734 0.0150703 14.3282 0.0200938 14.278 0.0301407C14.2729 0.0301407 14.2679 0.0301407 14.2629 0.0301407C14.2428 0.0301407 14.2277 0.045211 14.2076 0.045211C14.1825 0.0502344 14.1624 0.0602813 14.1423 0.0653048C14.0971 0.0803751 14.0469 0.090422 14.0017 0.110516C13.9966 0.110516 13.9916 0.110516 13.9866 0.115539C13.9464 0.13061 13.9062 0.155727 13.866 0.180844C13.8258 0.200938 13.7806 0.221032 13.7404 0.251172C13.7404 0.251172 13.7304 0.251172 13.7254 0.261219C13.6751 0.296383 13.6299 0.336571 13.5847 0.376758C13.5596 0.396852 13.5345 0.411922 13.5094 0.43704L4.20093 9.56464C3.60816 10.1474 3.59811 11.1018 4.18083 11.6946C4.47722 11.996 4.86402 12.1467 5.25585 12.1467Z'
        fill={color || '#475467'}
      />
      <path
        d='M28.1313 18.9453C27.2974 18.9453 26.6242 19.6185 26.6242 20.4523V23.9688C26.6242 25.6315 25.2729 26.9828 23.6102 26.9828H6.02813C4.36537 26.9828 3.01407 25.6315 3.01407 23.9688V20.4523C3.01407 19.6185 2.34092 18.9453 1.50703 18.9453C0.673141 18.9453 0 19.6185 0 20.4523V23.9688C0 27.2993 2.69759 29.9969 6.02813 29.9969H23.6102C26.9407 29.9969 29.6383 27.2993 29.6383 23.9688V20.4523C29.6383 19.6185 28.9652 18.9453 28.1313 18.9453Z'
        fill={color || '#475467'}
      />
    </g>
    <defs>
      <clipPath id='clip0_670_522'>
        <rect width='29.6383' height='30' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default UploadIcon
