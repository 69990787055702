import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { useDispatch, useSelector } from 'react-redux'
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import styled, { CSSProperties } from 'styled-components'

import { Row } from '@/components/atoms/Flex'
import { ChevronRight } from '@/components/atoms/icons/Chevron'
import utils from '@/utils'

import Arrow from '../../../../atoms/Arrow'
import TextLabel from '../../../../atoms/TextLabel'

import { getOrderBooking } from '../../../../redux/actions/bookingHistoryAction'
import { BookOrder, RoomOrder } from '../../../../redux/models/BookOrder'
import PopupConfirm from '../../../popups/PopupConfirm'
import logicRoomOrder from '../../logicRoomOrder'
import { RoomOrderState } from '../../types'

interface ShowRoomStyle extends CSSProperties {
  open?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`

const BoxContainerShow = styled(Row)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: flex-start;
  padding: 4px 8px;
  gap: 8px;
  background: #f8f9fb;
  border-radius: 8px;
`

const Box = styled.div<ShowRoomStyle>`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0.25rem'};
  position: ${props => props.position || 'unset'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
  border: ${props => props.border || 'unset'};
  background-color: ${props => props.backgroundColor || 'unset'};
  padding: ${props => props.padding || '0'};
  border-radius: ${props => props.borderRadius || '0'};
`
const FlexBox = styled(Row)``

const ContainerCircle = styled.div`
  display: flex;
`
const ContainerCircleStyle = styled.div<ShowRoomStyle>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: wrap;
  width: ${props => (props.open ? '100%' : '70%')};
  padding: ${props => props.padding || '0'};
  height: fit-content;
`

const BoxShowData = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid var(--border);
  border-radius: 4px;
  flex-grow: 1;
`

interface ShowRoomState {
  serviceRooms: RoomOrder[]
  serviceIndex: number
  roomOrders: RoomOrderState[]
  onOpenSelectClick: (index: number) => void
}

const ShowRoom = ({
  serviceRooms,
  serviceIndex,
  roomOrders,
  onOpenSelectClick,
}: ShowRoomState) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { customers, focusedCustomerIndex, time }: BookOrder = bookingOrderState

  const [openWarning, setOpenWarning] = useState<boolean>(false)
  const [arrayIndexMove, setArrayIndexMove] = useState({
    newIndex: -1,
    oldIndex: -1,
  })

  const DragHandle = SortableHandle(() => (
    <ContainerCircle>
      <ContainerCircleStyle>
        <RxDragHandleDots2 size='32' />
      </ContainerCircleStyle>
    </ContainerCircle>
  ))

  const SortableItem = SortableElement<{
    key: string
    index: number
    roomIndex: number
    value: RoomOrder
  }>(
    ({
      key,
      index,
      roomIndex,
      value,
    }: {
      key: string
      index: number
      roomIndex: number
      value: RoomOrder
    }) => {
      let startTime = Array.from({ length: serviceIndex }).reduce(
        (acc: number, info, index) => {
          if (customers[focusedCustomerIndex].services[index].serviceId !== -1)
            acc += customers[focusedCustomerIndex].services[index].time

          return acc
        },
        utils.convertTime(time)
      )

      let endTime = customers[focusedCustomerIndex].services[
        serviceIndex
      ].rooms.reduce((acc, info, index) => {
        if (index <= roomIndex) {
          acc += info.duration
        }
        if (index < roomIndex) {
          startTime += info.duration
        }
        return acc
      }, startTime)

      return (
        <BoxContainerShow>
          <FlexBox align='center'>
            <DragHandle />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextLabel fontSize='16px'>{`${value.detail || ''} ${
                value.duration
              } นาที`}</TextLabel>
              <TextLabel fontSize='14px'>{`${utils.formatTime(
                utils.convertTimeToHourMinute(startTime).hour
              )}:${utils.formatTime(
                utils.convertTimeToHourMinute(startTime).minute
              )}-${utils.formatTime(
                utils.convertTimeToHourMinute(endTime).hour
              )}:${utils.formatTime(
                utils.convertTimeToHourMinute(endTime).minute
              )}`}</TextLabel>
            </div>
          </FlexBox>
          <BoxShowData
            justify='space-between'
            onClick={() => onOpenSelectClick(roomIndex)}
          >
            <TextLabel>
              {value.roomId === -1
                ? t('selectRoom')
                : `${value.roomTypeName} (${value.roomName})`}
            </TextLabel>
            <ChevronRight />
          </BoxShowData>
        </BoxContainerShow>
      )
    }
  )

  const SortableList = SortableContainer<{
    items: RoomOrder[]
  }>(
    ({ items }: { items: RoomOrder[] }) => {
      return (
        <Container>
          {items.map((value, index) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                roomIndex={index}
                value={value}
                // loading={loading}
              />
            )
          })}
        </Container>
      )
    }
  )

  const onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }) => {
    const newArrCustomer = utils.deepClone(customers)
    if (oldIndex === newIndex) {
      return false
    }

    newArrCustomer[focusedCustomerIndex].services[serviceIndex].rooms =
      arrayMove(
        newArrCustomer[focusedCustomerIndex].services[serviceIndex].rooms,
        oldIndex,
        newIndex
      )

    const checkRoom = logicRoomOrder.checkRoomAvailability(
      bookingOrderState,
      roomOrders,
      newArrCustomer[focusedCustomerIndex].services[serviceIndex].rooms
    )
    if (checkRoom) {
      dispatch(getOrderBooking({ customers: newArrCustomer }))
    } else {
      setArrayIndexMove({ oldIndex, newIndex })
      setOpenWarning(true)
    }
  }

  const onConfirmClick = () => {
    const newArrCustomer = utils.deepClone(customers)
    newArrCustomer[focusedCustomerIndex].services[serviceIndex].rooms =
      arrayMove(
        newArrCustomer[focusedCustomerIndex].services[serviceIndex].rooms,
        arrayIndexMove.oldIndex,
        arrayIndexMove.newIndex
      )
    setOpenWarning(false)
    setArrayIndexMove({ oldIndex: -1, newIndex: -1 })
    dispatch(getOrderBooking({ customers: newArrCustomer }))
  }

  return (
    <>
      <SortableList
        items={serviceRooms}
        onSortEnd={onSortEnd}
        distance={1}
        axis='y'
        helperClass='SortableHelper'
      />
      {openWarning && (
        <PopupConfirm
          handleClose={() => setOpenWarning(false)}
          onConfirmClick={() => onConfirmClick()}
          text={t(`theRoomSelectedIsNotAvailable`) + ''}
        />
      )}
    </>
  )
}

export default ShowRoom
