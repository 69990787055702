import React from 'react'
import styled, { CSSProperties } from 'styled-components'

import { IconProps } from '../lib/icons/IconPropsType'

interface PayIconStyle extends CSSProperties {}

const SVG = styled.svg<PayIconStyle>`
  -webkit-tap-highlight-color: transparent;
  position: ${props => props.position || ''};
  &:hover {
    color: ${props => (props.color = '#fff')};
    user-select: none;
  }
`

const PayIcon = ({ size, color }: IconProps) => (
  <SVG
    width={size || '59'}
    height={size || '39'}
    viewBox='0 0 59 39'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.09372 0H52.4064C55.7652 0 58.5001 2.71383 58.5 6.04694V32.6532C58.5 35.9862 55.7651 38.7 52.4063 38.7H6.09372C2.73488 38.7 0 35.9862 0 32.6532V6.04694C0 2.71383 2.73488 0 6.09372 0ZM52.4064 36.2812C54.4222 36.2812 56.0626 34.6534 56.0626 32.6531H56.0625V6.04694C56.0625 4.0466 54.4221 2.41882 52.4063 2.41882H6.09372C4.07787 2.41882 2.43747 4.0466 2.43747 6.04694V32.6531C2.43747 34.6534 4.07787 36.2812 6.09372 36.2812H52.4064Z'
      fill={color || '#00B548'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.21879 7.2002H57.2811C57.9538 7.2002 58.4999 7.75463 58.5 8.43772V15.8627C58.5 16.5458 57.954 17.1002 57.2812 17.1002H1.21879C0.546037 17.1002 0 16.5458 0 15.8627V8.43772C0 7.75463 0.546037 7.2002 1.21879 7.2002ZM2.43746 14.6251H56.0623V9.67514H2.43746V14.6251Z'
      fill={color || '#00B548'}
    />
    <path
      d='M23.0787 24.2998H8.42166C7.74743 24.2998 7.2002 24.9047 7.2002 25.6499C7.2002 26.3951 7.74743 26.9998 8.42166 26.9998H23.0787C23.753 26.9998 24.3002 26.395 24.3002 25.6497C24.3002 24.9045 23.753 24.2998 23.0787 24.2998Z'
      fill={color || '#00B548'}
    />
    <path
      d='M23.0787 28.7998H8.42166C7.74743 28.7998 7.2002 29.4046 7.2002 30.1498C7.2002 30.895 7.74743 31.4998 8.42166 31.4998H23.0787C23.753 31.4998 24.3002 30.895 24.3002 30.1498C24.3001 29.4046 23.753 28.7998 23.0787 28.7998Z'
      fill={color || '#00B548'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.1129 21.5996H47.5879C49.6347 21.5996 51.3004 23.2654 51.3004 25.3121V27.7871C51.3004 29.834 49.6347 31.4996 47.5879 31.4996H45.1129C43.066 31.4996 41.4004 29.834 41.4004 27.7871V25.3121C41.4004 23.2653 43.066 21.5996 45.1129 21.5996ZM47.5879 29.0248C48.271 29.0248 48.8254 28.4703 48.8254 27.7872V25.3122C48.8254 24.6291 48.271 24.0747 47.5879 24.0747H45.1129C44.4298 24.0747 43.8754 24.6291 43.8754 25.3122V27.7872C43.8754 28.4703 44.4298 29.0248 45.1129 29.0248H47.5879Z'
      fill={color || '#00B548'}
    />
  </SVG>
)

export default PayIcon
