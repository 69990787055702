import React, { useState, useEffect } from 'react'

import Page from '../../lib/layout/Page/Page'
import SettingHeader from '../../molecules/SettingHeader'
import Body from './Body'
import api from '../../api'

const Main = () => {
  const [currentData, setCurrentData] = useState({
    _id: -1,
    nameThai: '',
    addressThai: '',
    nameEng: '',
    addressEng: '',
    phoneNumber: '',
    eMail: '',
    taxIDNumber: '',
    image: '',
  })

  useEffect(() => {
    callApiReceipt()
  }, [])

  const callApiReceipt = async () => {
    try {
      const { data } = await api.getReceiptByStoreId()
      if (data.length) {
        setCurrentData(data[0])
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Page>
      <SettingHeader name='ใบเสร็จ/ใบกำกับภาษี' />
      <Body currentData={currentData} />
    </Page>
  )
}

export default Main
