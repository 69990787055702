import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Button2 from '../../atoms/Button2'
import ButtonSave from '../../atoms/ButtonSave'
import InputText from '../../atoms/InputText'
import SaveIcon from '../../atoms/SaveIcon'
import TextLabel from '../../atoms/TextLabel'
import SwitchButton from '../../atoms/SwitchButton'
import SuccessIcon2 from '../../atoms/SuccessIcon2'

import api from '../../api'
import auth from '../../auth'

const Container = styled.div`
  display: flex;
  width: calc(100%);
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  padding: 0.5rem 1rem;
  position: relative;
`
const BoxStyle = styled.div`
  flex: ${props => props.flex || '1 0 50%'};
  width: ${props => props.width || '50%'};
`
const BoxSwitch = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
`
const BoxSwitchStyle = styled.div`
  width: 50%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`

const CardAddOccupation = ({ handleClose, currentOccupation = '' }) => {
  const [occupation, setOccupation] = useState('')
  const [status, setStatus] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (currentOccupation) {
      setOccupation(currentOccupation.occupation)
      setStatus(currentOccupation.status === 1)
    }
  }, [currentOccupation])

  const onSaveClick = async () => {
    try {
      if (currentOccupation) {
        await updateOccupation()
      } else {
        await createOccupation()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const createOccupation = async () => {
    try {
      const { success } = await api.createOccupation({
        occupation: occupation,
        status: status ? 1 : -1,
        brandId: auth.getBrandId(),
        storeId: auth.getStoreId(),
      })
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          handleClose()
          setSuccess(false)
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const updateOccupation = async () => {
    try {
      const { success } = await api.updateOccupation(currentOccupation._id, {
        occupation: occupation,
        status: status ? 1 : -1,
      })
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          handleClose()
          setSuccess(false)
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        <div className='mt-3'></div>
        <TextLabel fontWeight='600' fontSize='1.25rem'>
          อาชีพ
        </TextLabel>
        <div className='mb-2'></div>
        <InputText
          padding='0.25rem 0 0.25rem 1rem'
          placeholder='อาชีพ'
          value={occupation}
          onChange={val => setOccupation(val.target.value)}
        />
        <div className='mb-4'></div>
        <BoxSwitch>
          <BoxSwitchStyle flexDirection='column'>
            <TextLabel fontWeight='600'>สถานะ</TextLabel>
            <TextLabel color='#98A1B2' fontSize='0.8rem'>
              ไม่ใช้งาน/ใช้งาน
            </TextLabel>
          </BoxSwitchStyle>
          <BoxSwitchStyle alignItems='center' justifyContent='flex-end'>
            <SwitchButton
              name='stats-occupation'
              toggled={status}
              onChange={() => setStatus(!status)}
              backgroundColor={status ? '#8C56D9' : '#ABABAD'}
              width='18px'
              labelHeight='20px'
              labelWidth='40px'
            />
          </BoxSwitchStyle>
        </BoxSwitch>
        <div className='mt-2 pb-2' style={{ display: 'flex', width: '100%' }}>
          <BoxStyle className='mr-1' flex='1 1 50%' width='50%'>
            <Button2
              border='2px solid #98A1B2'
              backgroundColor='#FFFFFF'
              backgroundColorHover='#E5E7EC'
              color='#475467'
              fontWeight='bold'
              onClick={handleClose}
            >
              ยกเลิก
            </Button2>
          </BoxStyle>
          <BoxStyle className='ml-1' flex='1 1 50%' width='50%'>
            <ButtonSave
              // margin='0.125rem 0 0.125rem 1rem'
              backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
              color='#FFFFFF'
              fontWeight='bold'
              border='2px solid #53389E'
              icon={<SaveIcon size='17' color='#FFFFFF' />}
              onClick={() => onSaveClick()}
            >
              บันทึก
            </ButtonSave>
          </BoxStyle>
        </div>
      </Card>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddOccupation
