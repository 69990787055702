import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button2 from '../../atoms/Button2'
import { useSelector } from 'react-redux'

import Popup from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import api from '../../api'

const CardPin = styled.div`
  /* background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  border-radius: 24px; */
  padding: 3rem 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
`
const BoxPin = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`
const BoxPinStyle = styled.div`
  background: ${props => props.background || '#ffffff'};
  border: ${props => props.border || '1px solid #e4e8eb'};
  border-radius: 8px;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
  color: ${props => props.color || '#475467'};
  font-weight: 600;
  font-size: 3.375rem;
  width: 60px;
  height: 60px;
  /* aspect-ratio: 1; */
  vertical-align: middle;
  text-align: center;
`
const BoxNumber = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  grid-area: 1;

  /* grid-auto-flow: column; */
  /* justify-content: end; */
`
const BoxNumberStyle = styled.div`
  display: grid;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e8eb;
  border-radius: 50%;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  color: #000000;
  padding: 0.5rem 1.25rem;
  :hover {
    background-color: #8c56d9;
    color: #fff;
  }
  /* width: 56.98px;
  height: 56px; */
`

const PopupAddPin = ({ handleClose }) => {
  const user = useSelector(state => state.user) || {}
  const number = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', 'C']
  const [star, setStar] = useState([])
  const [pin, setPin] = useState([])
  const [checkPin, setCheckPin] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (pin.length === 4) {
      functionCheckPin()
    }
  }, [pin])

  const functionCheckPin = async () => {
    try {
      const userLogin = {
        phoneNumber: user.phoneNumber,
        password: pin.join(''),
        typeLogin: 'pin',
      }
      const { success } = await api.checkPassword(userLogin)
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      } else {
        setStar([])
        setPin([])
        setCheckPin(false)
      }
    } catch (err) {
      setStar([])
      setPin([])
      setCheckPin(false)
      console.log(err)
    }
  }

  const onNumberClick = index => {
    var newArrFirstPin = [...pin]
    var newArrStar = [...star]
    setCheckPin(true)

    if (index === 11) {
      newArrFirstPin.pop()
      newArrStar.pop()
    } else {
      newArrFirstPin.push(number[index])
      newArrStar.push('*')
    }

    setPin(newArrFirstPin)
    setStar(newArrStar)
  }

  return (
    <Popup width='calc(100%)' height='100%' left='0rem' top='0'>
      <PopupInsideBox width='auto' marginTop='0' padding='0' height='auto'>
        <CardPin>
          <TextLabel fontWeight='500' fontSize='1.25rem'>
            {`กรุณาใส่ pin`}
          </TextLabel>

          <BoxPin>
            {Array.from({ length: 4 }).map((_, index) => (
              <BoxPinStyle
                key={index}
                background={
                  star[index] === '*' && index !== star.length - 1
                    ? '#E4E8EB'
                    : ''
                }
                border={
                  index === star.length - 1
                    ? '1px solid #8c56d9'
                    : checkPin
                    ? ''
                    : '1px solid #DB2D1E'
                }
                color={checkPin ? '' : '#DB2D1E'}
                style={{ lineHeight: index === star.length - 1 ? '1.25' : '' }}
              >
                {index === star.length - 1 ? pin[index] : star[index]}
              </BoxPinStyle>
            ))}
          </BoxPin>
          {!checkPin && (
            <TextLabel color='#DB2D1E' fontWeight='600'>
              รหัสผ่านไม่ถูกต้อง
            </TextLabel>
          )}
          <BoxNumber>
            {Array.from({ length: 12 }).map((_, index) => {
              return index === 9 ? (
                <div key={index}></div>
              ) : (
                <BoxNumberStyle
                  key={index}
                  onClick={() => onNumberClick(index)}
                >
                  {/* {index === 11 ? (
                    <>
                      <ArrowCalculator size='25' />
                    </>
                  ) : (
                    number[index]
                  )} */}
                  {number[index]}
                </BoxNumberStyle>
              )
            })}
          </BoxNumber>
          <Button2
            color='#98A1B2'
            backgroundColor='white'
            border='2px solid #98A1B2'
            backgroundColorHover='#E5E7EC'
            onClick={() => handleClose(true)}
          >
            ยกเลิก
          </Button2>
        </CardPin>
        {success && <SuccessIcon2 position='absolute' />}
      </PopupInsideBox>
    </Popup>
  )
}

export default PopupAddPin
