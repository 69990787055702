import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { activeOpenState, RoomForServiceState } from './useCardCustomer'

import logicRoomOrder from '../../logicRoomOrder'
import { RoomOrderState } from '../../types'

interface useCardRoomProps {
  activeOpen: activeOpenState
  roomOrders: RoomOrderState[]
  roomsForService: RoomForServiceState[]
}

const useCardRoom = ({
  roomOrders,
  activeOpen,
  roomsForService,
}: useCardRoomProps) => {
  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { customers } = bookingOrderState

  const [activeRoom, setActiveRoom] = useState<number>(-1)
  const [rooms, setRooms] = useState<RoomForServiceState[]>([])
  const [moreRooms, setMoreRooms] = useState<RoomForServiceState[]>([])

  useEffect(() => {
    createRoom()
  }, [activeOpen, roomsForService, roomOrders])

  const createRoom = () => {
    const { newArrRoomForService, moreRooms } =
      logicRoomOrder.manageRoomForService(
        bookingOrderState,
        roomOrders,
        activeOpen.serviceIndex,
        activeOpen.roomIndex,
        roomsForService,
        customers[activeOpen.customerIndex].services[activeOpen.serviceIndex]
          .rooms[activeOpen.roomIndex].roomId,
        customers[activeOpen.customerIndex].services[activeOpen.serviceIndex]
          .rooms[activeOpen.roomIndex].roomTypeId
      )

    setActiveRoom(
      customers[activeOpen.customerIndex].services[activeOpen.serviceIndex]
        .rooms[activeOpen.roomIndex].roomId
    )
    setRooms(newArrRoomForService)
    setMoreRooms(moreRooms)
  }

  return { rooms, moreRooms, activeRoom }
}

export default useCardRoom
