import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Button2 from '../../atoms/Button2'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import api from '../../api'
import { clearOrderBooking } from '../../redux/actions/bookingHistoryAction'
import Receipt from './Receipt'
import { clearPayPackage } from '../../redux/actions/payPackageActions'
import { TransactionTypes } from '../../redux/models/BookingHistory'

import { HOME_URL } from '../../routes'
import { PackageTypes } from '../../redux/models/BrandPackage'

const PopupBillPayMent = ({ bookingHistory = '', receiptNumber = '', handleClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [receiptData, setReceiptData] = useState({
    nameThai: '',
    addressThai: '',
    nameEng: '',
    addressEng: '',
    phoneNumber: '',
    eMail: '',
    taxIDNumber: '',
    image: '',
    type: 0,
  })
  const [remainingCustomerPackages, setRemainingCustomerPackages] = useState([])
  const [services, setServices] = useState([])
  const [serviceCount, setServiceCount] = useState(1)
  const [products, setProducts] = useState([])
  const [productCount, setProductCount] = useState(1)
  const [packages, setPackages] = useState([])
  const [packageCount, setPackageCount] = useState(0)
  const [tip, setTip] = useState({})
  const [date, setDate] = useState(new Date())
  const [charge, setCharge] = useState(0)
  const [total, setTotal] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [packageDiscount, setPackageDiscount] = useState(0)
  const [customerData, setCustomerData] = useState({
    customerId: -1,
    name: '',
    phoneNumber: '',
    address: '',
    taxIDNumber: '',
  })

  useEffect(() => {
    callApiReceipt()
  }, [])

  const callApiReceipt = async () => {
    try {
      const { data } = await api.getReceiptByStoreId()
      if (data.length) {
        setReceiptData({
          nameThai: data[0].nameThai,
          addressThai: data[0].addressThai,
          nameEng: data[0].nameEng,
          addressEng: data[0].addressEng,
          phoneNumber: data[0].phoneNumber,
          eMail: data[0].eMail,
          taxIDNumber: data[0].taxIDNumber,
          image: data[0].image,
          type: data[0].type,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (bookingHistory) {
      setData()
    }
  }, [bookingHistory])

  const setData = async () => {
    try {
      let customerIds = []
      setDate(bookingHistory.date)
      if (bookingHistory.customerId !== -1) {
        const { data: customer } = await api.getCustomerById(
          bookingHistory.customerId
        )
        if (customer.length) {
          setCustomerData({
            customerId: customer[0]._id,
            name: customer[0].name || '',
            phoneNumber: customer[0].phoneNumber || '',
            address: customer[0].address || '',
            taxIDNumber: customer[0].taxIDNumber || '',
          })
        }
      }
      let serviceCount = 0
      const services = bookingHistory.customers.reduce((acc, info) => {
        const { orders, customerId } = info
        if (customerId !== -1) customerIds.push({ customerId, check: false })
        for (const order of orders) {
          const { serviceId, service, price, time } = order
          const findAccIndex = acc.findIndex(
            a => a.serviceId === serviceId && a.time === time
          )
          serviceCount++
          if (findAccIndex === -1) {
            acc.push({ serviceId, service, price, time, count: 1, sum: price })
          } else {
            acc[findAccIndex].count += 1
            acc[findAccIndex].sum += price
          }
        }
        return acc
      }, [])

      let packageCount = 0

      if (bookingHistory.transactionType === TransactionTypes.Package) {
        packageCount = bookingHistory.packageCount
      }

      let packageDiscount = 0
      let packageDiscountPercent = 0
      let customerPackageId = -1
      let customerPackageIds = []
      const charge = bookingHistory.payChannels
        .filter(p => p.payChannelTypeId === 6)
        .reduce((acc, info) => {
          const { chargePercentage = 0, price, payChannelId } = info
          packageDiscount += +price
          if (customerPackageId === -1) customerPackageId = payChannelId
          const findCustomer = customerPackageIds.find(c => c === payChannelId)
          if (!findCustomer) customerPackageIds.push(payChannelId)
          acc += chargePercentage
          return acc
        }, 0)

      const usePackage = bookingHistory.payChannels.filter(
        p => p.payChannelTypeId === 6
      )

      if (packageDiscount !== 0) {
        let remainingCustomerPackages = []
        let i = 0
        for (const customerId of customerIds) {
          const { data: customerPackage } =
            await api.getCustomerPackageByCustomerId(customerId.customerId)
          // console.log({ customerPackage })
          const findPackagePercent = usePackage.filter(u =>
            customerPackage.some(c => c._id === u.payChannelId && c.type === 4)
          )
          if (findPackagePercent.length) {
            packageDiscountPercent += findPackagePercent.reduce(
              (acc, info) => (acc += info.price),
              0
            )
          }

          const hasCommonValues = customerPackage
            .map(item => item._id)
            .some(value => customerPackageIds.includes(value))

          if (hasCommonValues) {
            const { data: customer } = await api.getCustomerById(
              customerId.customerId
            )
            if (i === 0) {
              setCustomerData({
                customerId: customer[0]._id,
                name: customer[0].name || '',
                phoneNumber: customer[0].phoneNumber || '',
                address: customer[0].address || '',
                taxIDNumber: customer[0].taxIDNumber || '',
              })
            }
            const remainingCustomerPackage = customerPackage.reduce(
              (acc, info) => {
                const { type, remainingValue, packageId, name, _id } = info
                if (type !== PackageTypes.Percent) {
                  const findPackageType = acc.findIndex(a => a.type === type)
                  const findPackageRemaining = usePackage.find(
                    p => p.payChannelId === _id
                  )

                  let newRemainingValue = 0

                  if (
                    findPackageRemaining &&
                    findPackageRemaining.remainingValue
                  ) {
                    newRemainingValue = findPackageRemaining.remainingValue
                  } else {
                    newRemainingValue = remainingValue
                  }

                  if (findPackageType === -1) {
                    acc.push({
                      remainingValue: newRemainingValue,
                      type: type,
                      packageId,
                      name,
                    })
                  } else {
                    if (type === 2) {
                      if (acc[findPackageType].packageId === packageId) {
                        acc[findPackageType].remainingValue += remainingValue
                      } else {
                        acc.push({
                          remainingValue: newRemainingValue,
                          type: type,
                          packageId,
                          name,
                        })
                      }
                    } else {
                      acc[findPackageType].remainingValue += newRemainingValue
                    }
                  }
                }
                return acc
              },
              []
            )
            remainingCustomerPackages.push({
              customerName: customer[0].name || customer[0].firstName,
              remainingCustomerPackages: remainingCustomerPackage,
            })

            i++
          }
        }
        setRemainingCustomerPackages(remainingCustomerPackages)
      }
      setPackageCount(packageCount)
      setServiceCount(serviceCount)
      setServices(services)
      setProducts(bookingHistory.products)
      setProductCount(bookingHistory.productCount)
      setPackages(bookingHistory.packages)
      setDiscount(
        bookingHistory.totalDiscount -
          (packageDiscount || 0) +
          (packageDiscountPercent || 0)
      )
      setPackageDiscount(packageDiscount - (packageDiscountPercent || 0))
      setCharge(charge)
      setTotal(bookingHistory.total)
      setTip(bookingHistory.totalTip || 0)
    } catch (err) {
      console.log(err)
    }
  }

  const onGoHomeClick = () => {
    dispatch(clearOrderBooking())
    dispatch(clearPayPackage())
    history.replace(HOME_URL)
  }

  return (
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='fit-content'
        height='auto'
        padding='2rem 1rem 1rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
      >
        <Receipt
          receiptData={receiptData}
          date={date}
          receiptNumber={receiptNumber}
          services={services}
          serviceCount={serviceCount}
          products={products}
          productCount={productCount}
          packages={packages}
          packageCount={packageCount}
          total={total}
          discount={discount}
          packageDiscount={packageDiscount}
          remainingCustomerPackages={remainingCustomerPackages}
          charge={charge}
          tip={tip}
          customerData={customerData}
        />
        <div
          className='mb-2 mt-2'
          style={{ display: 'flex', width: '100%' }}
        ></div>
        <Button2
          color='white'
          backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
          backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
          onClick={handleClose || onGoHomeClick}
        >
          กลับสู่หน้าหลัก
        </Button2>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupBillPayMent
