import {
  GET_USER,
  UPDATE_USER,
  UPDATE_USER_ID,
  UPDATE_USER_FIRST_NAME,
  UPDATE_USER_LAST_NAME,
  UPDATE_USER_EMAIL,
  UPDATE_USER_PHONENUMBER,
  UPDATE_USER_TYPE,
  UPDATE_LOGIN_TIME,
  UPDATE_PASSWORD,
  UPDATE_PIN,
  CLEAR_USER,
} from '../actions/dataUserAction'
//userReducers dataUserReducers
const initState = {
  _id: '',
  userId: '',
  lineUserId: '',
  image: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  loginTime: {
    startTime: { hour: '', minute: '' },
    endTime: { hour: '', minute: '' },
  },
  type: '',
  password: '',
  pin: '',
}

const dataUserReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        ...action.payload.user,
      }
    // case UPDATE_USER:
    //   return {
    //     ...state,
    //     ...action.payload, // { firstName: "stepboom" } dispatch(updateUser({firstName: "stepboom"}))
    //   }
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_USER_ID:
      return {
        ...state,
        userID: action.payload.userID,
      }
    case UPDATE_USER_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload.firstName,
      }
    case UPDATE_USER_LAST_NAME:
      return {
        ...state,
        lastName: action.payload.lastName,
      }
    case UPDATE_USER_EMAIL:
      return {
        ...state,
        email: action.payload.email,
      }
    case UPDATE_USER_PHONENUMBER:
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
      }
    case UPDATE_USER_TYPE:
      return {
        ...state,
        type: action.payload.type,
      }
    case UPDATE_LOGIN_TIME:
      return {
        ...state,
        loginTime: action.payload.loginTime,
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        password: action.payload.password,
      }
    case UPDATE_PIN: {
      return {
        ...state,
        pin: action.payload.pin,
      }
    }
    case CLEAR_USER:
      return { ...initState }

    default:
      return state
  }
}

export default dataUserReducers
