import styled, { CSSProperties } from 'styled-components'

interface PopupInsideBoxProps extends CSSProperties {}

const PopupInsideBox = styled.div<PopupInsideBoxProps>`
  position: relative;
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  flex-direction: column;
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth};
  margin: ${props => props.margin || '0 auto'};
  height: ${props => props.height || '95vh'};
  max-height: 95vh;
  margin-top: ${props => props.marginTop};
  background: #fff;
  border-radius: ${props => props.borderRadius || '10px'};
  padding: ${props => props.padding};
  border: 1px solid #999;
  box-shadow: ${props => props.boxShadow || 'none'};
  overflow: ${props => props.overflow || 'auto'};
  ::-webkit-scrollbar {
    display: none;
  }
  z-index: ${props => props.zIndex || '1000'};
`

export default PopupInsideBox
