import React from 'react'

interface PackageIconProps {
  size?: string
  color?: string
}

const PackageIcon = ({ size, color }: PackageIconProps) => (
  <svg
    width={size || '10'}
    height={size || '10'}
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.53903 6.25821V9.99917H0.262699C0.193161 9.99873 0.126596 9.97092 0.0774244 9.92174C0.028253 9.87257 0.000435984 9.80601 0 9.73647V0.26353C0.00021775 0.193848 0.0279384 0.12707 0.0771332 0.07772C0.126328 0.0283696 0.193018 0.000437912 0.262699 0H4.53903V3.77671L2.68518 3.00607C2.59293 2.95336 2.48783 2.92738 2.38166 2.93105C2.27548 2.93472 2.17242 2.96788 2.08403 3.02684C1.99565 3.08579 1.92543 3.16819 1.88125 3.26481C1.83707 3.36143 1.82067 3.46845 1.8339 3.57386V5.23901C1.82067 5.34442 1.83707 5.45144 1.88125 5.54806C1.92543 5.64468 1.99565 5.72708 2.08403 5.78603C2.17242 5.84498 2.27548 5.87815 2.38166 5.88182C2.48783 5.88549 2.59293 5.85951 2.68518 5.8068L4.10757 5.21656L3.00108 6.96899C2.93858 7.07223 2.91911 7.19588 2.94684 7.31333C2.97457 7.43078 3.0473 7.53265 3.14937 7.59704C3.25144 7.66142 3.37471 7.68316 3.49265 7.65759C3.61059 7.63202 3.71379 7.56118 3.78003 7.4603L4.53903 6.25821ZM9.99917 0.26353V9.73647C9.99895 9.80615 9.97123 9.87293 9.92204 9.92228C9.87284 9.97163 9.80615 9.99956 9.73647 10H5.46014V6.25821L6.21914 7.45947C6.28538 7.56035 6.38858 7.63119 6.50652 7.65676C6.62446 7.68233 6.74772 7.66059 6.8498 7.5962C6.95187 7.53182 7.0246 7.42995 7.05233 7.3125C7.08006 7.19504 7.06058 7.0714 6.99809 6.96816L5.8916 5.21407L7.31399 5.80514C7.40623 5.85785 7.51134 5.88383 7.61751 5.88016C7.72369 5.87649 7.82675 5.84332 7.91513 5.78437C8.00352 5.72542 8.07374 5.64301 8.11792 5.54639C8.1621 5.44977 8.17849 5.34276 8.16527 5.23734V3.57386C8.17849 3.46845 8.1621 3.36143 8.11792 3.26481C8.07374 3.16819 8.00352 3.08579 7.91513 3.02684C7.82675 2.96788 7.72369 2.93472 7.61751 2.93105C7.51134 2.92738 7.40623 2.95336 7.31399 3.00607L5.46014 3.77671V0H9.73647C9.80615 0.000437912 9.87284 0.0283696 9.92204 0.07772C9.97123 0.12707 9.99895 0.193848 9.99917 0.26353Z'
      fill={color || '#57557F'}
    />
  </svg>
)

export default PackageIcon
