import React from 'react'
import PopupBase from './PopupBase'
import CardViewEditCustomerPackage from '../customer/CardViewEditCustomerPackage'
import { useTranslation } from 'react-i18next'

const PopupViewEditCustomerPackage = ({
  handleClose,
  customerEditPackages,
  currentPackage,
}) => {
  const { t } = useTranslation()

  return (
    <PopupBase onClose={handleClose} topic={t('packageRevisionHistory')}>
      <CardViewEditCustomerPackage
        customerEditPackages={customerEditPackages}
        currentPackage={currentPackage}
      />
    </PopupBase>
  )
}

export default PopupViewEditCustomerPackage
