import React from 'react'

import Popup from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddStaff from '../settingStaff/CardAddStaff'

const PopupAddStaff = ({
  handleClose,
  staffTypes,
  staffs,
  services,
  currentDataEdit,
  currentIdEdit,
}) => {
  return (
    <Popup width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0'>
        <CardAddStaff
          handleClose={handleClose}
          staffTypes={staffTypes}
          staffs={staffs}
          services={services}
          currentDataEdit={currentDataEdit}
          currentIdEdit={currentIdEdit}
        />
      </PopupInsideBox>
    </Popup>
  )
}

export default PopupAddStaff
