import { GET_TYPE_SERVICE } from '../actions/typeOrderAction'

const initState = {
  typeService: null,
}

const typeOrderReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_TYPE_SERVICE:
      return {
        // ...state,
        typeService: action.payload.typeService,
      }

    default:
      return state
  }
}

export default typeOrderReducers
