import {
  CustomerOrder,
  getInitialServiceBookOrder,
} from '@/redux/models/BookOrder'
import utils from '@/utils'

const logicServiceCouple = {
  checkServiceCouple: (
    service: { serviceId: number; serviceCustomer: number },
    services: {
      serviceId: number
      customerIndex: number
      serviceIndex: number
    }[]
  ) => {
    const serviceIdCount: { [key: number]: number[] } = {}

    services.forEach((item, index) => {
      if (!serviceIdCount[item.serviceId]) {
        serviceIdCount[item.serviceId] = []
      }
      serviceIdCount[item.serviceId].push(index)
    })

    const mismatchedIndices: number[] = []

    const indices = serviceIdCount[service.serviceId] || []

    if (indices.length < service.serviceCustomer) {
      mismatchedIndices.push(...indices)
    } else if (indices.length > service.serviceCustomer) {
      mismatchedIndices.push(...indices.slice(service.serviceCustomer))
    }

    return mismatchedIndices
  },

  removeCustomerServiceCouple: (
    customers: CustomerOrder[],
    customerIndex: number,
    serviceId: number,
    serviceTime: number
  ) => {
    const newArrCustomer = utils.deepClone(customers)
    for (let i = 0; i < newArrCustomer.length; i++) {
      const { services } = newArrCustomer[i]
      let indexs: number[] = []
      for (let j = 0; j < services.length; j++) {
        if (
          services[j].serviceId === serviceId &&
          services[j].primaryCustomerIndex.customerIndex === customerIndex &&
          services[j].time === serviceTime &&
          services[j].primaryCustomerIndex.serviceIndex === j
        ) {
          if (j === 0) {
            newArrCustomer[i].services = [getInitialServiceBookOrder()]
          } else {
            indexs.push(j)
            // newArrCustomer[i].services.splice(j, 1)
          }
        }
      }
      for (const index of indexs) {
        newArrCustomer[i].services.splice(index, 1)
      }
    }
    return newArrCustomer
  },
  checkServiceCoupleV2: (customers: CustomerOrder[]) => {
    const newArrCustomer = utils.deepClone(customers)

    for (let i = 0; i < newArrCustomer.length; i++) {
      const { services } = newArrCustomer[i]
      // const filterService = services.filter(s => s.serviceCustomer > 1)

      const filterService = services
        .map((s, index) => ({ service: s, index })) // Attach the original index to each service
        .filter(({ service }) => service.serviceCustomer > 1) // Filter by serviceId

      if (filterService.length) {
        for (let j = 0; j < filterService.length; j++) {
          const {
            serviceRelatedCustomers,
            serviceCustomer,
            primaryCustomerIndex,
          } = filterService[j].service

          if (
            serviceRelatedCustomers.length < serviceCustomer - 1 &&
            primaryCustomerIndex.customerIndex === i
          ) {
            newArrCustomer[i].services[filterService[j].index].serviceError =
              true
          } else {
            newArrCustomer[i].services[filterService[j].index].serviceError =
              false
          }
        }
      }
    }

    return newArrCustomer
  },
}

export default logicServiceCouple
