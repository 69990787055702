import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import axios from 'axios'

import TextLabel from '../../atoms/TextLabel'
import api from '../../api'
import config from '../../config'
import CheckBox from '../../atoms/CheckBox'
import UploadIcon from '../../atoms/UploadIcon'
import utils from '../../utils'
import auth from '../../auth'
import Scrollbar from '../../atoms/Scrollbar'
import ImageSuccessIcon from '../../atoms/ImageSuccessIcon'
import CheckIcon from '../../atoms/CheckIcon'
import XIcon from '../../atoms/XIcon'
import PopupShownSlip from '../popups/PopupShowSlip'
import PopupAddAccount from '../popups/PopupAddAccount'
import InputText from '../../atoms/InputText'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  flex: 1;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: ${props => props.border || '1px solid #e4e8eb'};
  padding: ${props => props.padding || '0.5rem'};
  background-color: ${props => props.backgroundColor || '#FFF'};
  width: 100%;
`
const BoxLoadSlip = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.08);
  border-radius: 16px;
  margin-top: 0.5rem;
  padding: 1rem;
`
const BoxImage = styled.div`
  background: #f8f9fb;
  border-radius: 8px;
  padding: 1rem;
`
const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #e4e8eb;
`

const Transfer = ({
  bookingHistory,
  onSelectPayChannel: _onSelectPayChannel,
}) => {
  const { t } = useTranslation()

  const [account, setAccount] = useState([])
  const [payChannelLength, setPayChannelLength] = useState(0)
  const [activeCheck, setActiveCheck] = useState(0)
  const [linkImage, setLinkImage] = useState([])
  const [uploadPercent, setUploadPercent] = useState(0)
  const [fileImages, setFileImages] = useState([])
  const [images, setImages] = useState([])
  const [uploadSlipDone, setUploadSlipDone] = useState([])
  const [activeImage, setActiveImage] = useState(-1)
  const [isOpenPopupShowSlip, setIsOpenPopupShowSlip] = useState(false)
  const [isOpenPopupAddAccount, setIsOpenPopupOpenAccount] = useState(false)
  const [note, setNote] = useState('')

  useEffect(() => {
    callApiPayChannelByTypeId()
    callApiPayChannelAll()
  }, [])

  const callApiPayChannelByTypeId = async () => {
    try {
      let account = []
      const { data, success } = await api.getPayChannelByTypeId(3)
      if (success) {
        if (data.length !== 0) {
          for (const item of data) {
            const { payChannelId, account: _account, status } = item
            const { bankId, accountName, accountNumber, promptPay } = _account
            if (status === 1) {
              account.push({
                payChannelId,
                bank: config.BANK[parseInt(bankId) - 1],
                accountName,
                accountNumber,
                promptPay,
              })
            }
          }
        }
        account.push({
          payChannelId: -1,
          bank: '',
          accountName: '',
          accountNumber: '',
          promptPay: '',
        })

        setAccount(account)
        _onSelectPayChannel({
          payChannelId: account[0].payChannelId,
          payChannel: account[0].accountName,
          // note: account[0].accountNumber,
          payslip: [],
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  const callApiPayChannelAll = async () => {
    try {
      const { data, success } = await api.getPayChannel()
      if (success) {
        setPayChannelLength(data.length)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onAddAccountClick = () => {
    setIsOpenPopupOpenAccount(!isOpenPopupAddAccount)
    setActiveImage(-1)
  }
  const onSaveClick = async _create => {
    try {
      const create = { ..._create, payChannelId: payChannelLength }
      const { success } = await api.createPayChannel(create)
      if (success) {
        await callApiPayChannelByTypeId()
        await callApiPayChannelAll()
        setIsOpenPopupOpenAccount(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const CheckBankAccount = index => {
    _onSelectPayChannel({
      payChannelId: account[index].payChannelId,
      payChannel: account[index].accountName,
      // note: account[index].accountNumber,
      payslip: [],
    })
    setActiveCheck(index)
  }

  const onNoteChange = val => {
    setNote(val)
    _onSelectPayChannel({
      payChannelId: account[activeCheck].payChannelId,
      payChannel: account[activeCheck].accountName,
      note: val,
      payslip: [],
    })
  }

  const listAccount = account.map(
    ({ payChannelId, bank, accountName, accountNumber, promptPay }, index) => (
      <SwiperSlide key={index}>
        {payChannelId !== -1 ? (
          <Box>
            <FlexBox flexDirection='column' gap='0.5rem'>
              <FlexBox justifyContent='space-between'>
                <TextLabel>{bank}</TextLabel>
                <CheckBox
                  backgroundColor='linear-gradient(142.04deg, #8C56D9 -7.02%, #53389E 121.47%)'
                  heightCheckMark='18px'
                  widthCheckMark='18px'
                  left='6px'
                  top='2px'
                  marginBottom='0'
                  paddingLeft='20px'
                  onClick={() => CheckBankAccount(index)}
                  onChange={() => CheckBankAccount(index)}
                  borderCheckMark={
                    activeCheck === index ? '1px solid #53389E' : ''
                  }
                  checked={activeCheck === index}
                />
              </FlexBox>
              <FlexBox flexDirection='column'>
                <TextLabel fontSize='0.875rem' color='#98A1B2'>
                  {t('accountNameTransfer')}
                </TextLabel>
                <TextLabel>{accountName}</TextLabel>
              </FlexBox>
              <FlexBox gap='1rem'>
                <FlexBox flexDirection='column'>
                  <TextLabel fontSize='0.875rem' color='#98A1B2'>
                    {t('accountNumberTransfer')}
                  </TextLabel>
                  <TextLabel>{accountNumber}</TextLabel>
                </FlexBox>
                <FlexBox flexDirection='column'>
                  <TextLabel fontSize='0.875rem' color='#98A1B2'>
                    {t('promptpay')}
                  </TextLabel>
                  <TextLabel>{promptPay}</TextLabel>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </Box>
        ) : (
          <Box
            border='2px dashed #E4E8EB'
            backgroundColor='#F8F9FB'
            style={{ height: '100%' }}
            onClick={() => onAddAccountClick()}
          >
            <FlexBox
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              style={{ margin: 'auto' }}
            >
              <TextLabel fontWeight='500' fontSize='1.25rem'>
                +
              </TextLabel>
              <FlexBox gap='0.25rem'>
                <TextLabel fontWeight='500' color='#8C56D9'>
                  {t('clickToAddAccountNumber')}
                </TextLabel>
              </FlexBox>
            </FlexBox>
          </Box>
        )}
      </SwiperSlide>
    )
  )

  const onChangeImage = async event => {
    try {
      const newArrFileImages = [...fileImages]
      const newArrImages = [...images]
      const newArrUploadSlipDone = [...uploadSlipDone]
      const newArrLinkImage = [...linkImage]

      const files = [...event.target.files]
      for (let i = 0; i < files.length; i++) {
        newArrFileImages.push(files[i])
        if ('|png|jpeg|jpg'.indexOf(files[i].type.split('/')[1]) === -1) {
          alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
        } else if (utils.isFileExceedLimit(files[i])) {
          alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
        } else {
          const image = await utils.readImage(files[i])
          await newArrImages.push(image)
          setImages(newArrImages)
          const formData = new FormData()
          formData.append('image', files[i])
          await axios
            .request({
              method: 'post',
              url: `${config.API_POS}/uploadPaySlip/${
                auth.getStoreId() || sessionStorage.getItem('storeId') || ''
              }`,
              data: formData,
              headers: {
                Authorization: auth.getToken(),
              },
              onUploadProgress: p => {
                const { loaded, total } = p
                let percent = Math.floor((loaded * 100) / total)
                if (percent < 100) {
                  setUploadPercent(percent)
                } else {
                  setUploadPercent(percent)
                }
              },
            })
            .then(data => {
              setUploadPercent(0)
              newArrUploadSlipDone.push(100)
              setUploadSlipDone(newArrUploadSlipDone)
              newArrLinkImage.push(data.data.data)
            })
        }
      }
      setLinkImage(newArrLinkImage)
      setFileImages(newArrFileImages)
      setIsOpenPopupShowSlip(true)
      _onSelectPayChannel({
        payChannelId: account[activeCheck].payChannelId,
        payChannel: account[activeCheck].accountName,
        note: note,
        payslip: [newArrLinkImage],
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onCancelClick = (event, index) => {
    event.stopPropagation()
    const newArrFileImages = [...fileImages]
    const newArrImages = [...images]
    const newArrUploadSlipDone = [...uploadSlipDone]
    newArrFileImages.splice(index, 1)
    newArrImages.splice(index, 1)
    newArrUploadSlipDone.splice(index, 1)
    setFileImages(newArrFileImages)
    setImages(newArrImages)
    setUploadSlipDone(newArrUploadSlipDone)
  }

  const togglePopupShowSlip = () => {
    setIsOpenPopupShowSlip(!isOpenPopupShowSlip)
    setActiveImage(-1)
  }

  const onShowSlipClick = index => {
    setActiveImage(index)
    setIsOpenPopupShowSlip(!isOpenPopupShowSlip)
  }

  return (
    <Container>
      <TextLabel fontWeight='500'>{t('bankTransferAccount')}</TextLabel>
      <div className='mb-3' style={{ width: '100%', display: 'flex' }}>
        <Swiper slidesPerView={1.5} spaceBetween={10}>
          {listAccount}
        </Swiper>
      </div>
      <div className='mb-3'>
        <TextLabel fontWeight='500'>{t('transactionID')}</TextLabel>
        <InputText
          placeholder={t('transactionID')}
          value={note}
          onChange={val => onNoteChange(val.target.value)}
        />
      </div>
      <TextLabel fontWeight='500'>{t('uploadSlip')}</TextLabel>
      <Box
        border='2px dashed #E4E8EB'
        backgroundColor='#F8F9FB'
        style={{ height: '80px' }}
      >
        <FlexBox
          alignItems='center'
          justifyContent='center'
          style={{ margin: 'auto' }}
        >
          <UploadIcon size='18' />
          <label
            className='ml-3'
            for='upload-image'
            style={{ display: 'flex', color: '#475467', margin: '0' }}
          >
            {t('uploadSlip')}
            {/* <TextLabel paddingLeft='0.25rem' color='#8C56D9' fontWeight='600'>
              คลิก
            </TextLabel> */}
          </label>
          <input
            id='upload-image'
            type='file'
            multiple
            onChange={onChangeImage}
            style={{ display: 'none', visibility: 'none' }}
          />
        </FlexBox>
      </Box>

      <Scrollbar id='scrollbar-slip'>
        {images.map((_, index) => (
          <BoxLoadSlip key={index} onClick={() => onShowSlipClick(index)}>
            <BoxImage>
              <ImageSuccessIcon size='20' />
            </BoxImage>
            <div
              className='ml-3'
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <TextLabel>{`sliptpayment ${index + 1}`}</TextLabel>
                {uploadSlipDone[index] ? (
                  <CheckIcon />
                ) : (
                  <TextLabel color='#98A1B2'>{uploadPercent}%</TextLabel>
                )}
              </div>
              {!uploadSlipDone[index] ? (
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <div
                    class='progress mt-3'
                    style={{ height: '12px', width: '90%' }}
                  >
                    <div
                      class='progress-bar'
                      role='progressbar'
                      aria-valuenow={uploadPercent}
                      aria-valuemin='0'
                      aria-valuemax='100'
                      style={{
                        width: `${uploadPercent}%`,
                        background: '#8C56D9',
                        borderRadius: '8px',
                        height: '12px',
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '10%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Circle onClick={e => onCancelClick(e, index)}>
                      <XIcon size='10' />
                    </Circle>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </BoxLoadSlip>
        ))}
      </Scrollbar>
      {isOpenPopupAddAccount && (
        <PopupAddAccount handleClose={onAddAccountClick} onSave={onSaveClick} />
      )}
      {isOpenPopupShowSlip && (
        <PopupShownSlip
          handleClose={togglePopupShowSlip}
          images={activeImage === -1 ? images : [images[activeImage]]}
        />
      )}
    </Container>
  )
}

export default Transfer
