import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import TextLabel from '../../atoms/TextLabel'
import Button2 from '../../atoms/Button2'
import EmailIcon from '../../atoms/EMailIcon'
import api from '../../api'

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
`
const Box = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
`
const ImgLogo = styled.img`
  margin-top: 5rem;
  width: 191.5px;
  height: 124.5px;
`
const DivInputStyle = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  width: 50%;
`
const IconStyle = styled.div`
  position: absolute;
  bottom: ${props => props.bottom || '37px'};
  left: ${props => props.left || '15px'};
  width: 15px;
  height: 15px;
`
const Input = styled.input`
  background: #ffffff;
  border: ${props => props.border || '2px solid #8c56d9'};
  border-radius: 8px;
  height: 45px;
  width: ${props => props.width || '100%'};
  padding-left: 3rem;
  margin: ${props => props.margin || '1rem 0'};
  outline: none;
`

const ForgetPin = () => {
  const [email, setEmail] = useState('')
  const [reset, setReset] = useState(false)
  const [checkEmail, setCheckEmail] = useState(false)

  const history = useHistory()

  const onResetClick = async () => {
    const { success } = await api.forgetPin({ email })
    if (success) {
      setTimeout(() => {
        setReset(true)
        setCheckEmail(false)
      }, 500)
    } else {
      setCheckEmail(true)
    }
  }

  return (
    <Container>
      <Box>
        <ImgLogo src='/images/logo_login.png' />
        <div className='mt-3'>
          <TextLabel color='#8C56D9' fontWeight='600' fontSize='1.2rem'>
            {reset ? 'ตรวจสอบอีเมลของคุณ' : 'ลืมรหัส pin'}
          </TextLabel>
        </div>
        <div className='mt-1 mb-4'>
          <TextLabel fontWeight='400'>
            {reset
              ? 'เราได้ส่งคำแนะนำการกู้คืนรหัสผ่านไปยังอีเมลของคุณแล้ว'
              : 'รีเซ็ตรหัส pin ด้วยอีเมลที่ใช้งาน'}
          </TextLabel>
        </div>
        <DivInputStyle>
          <Input
            type='email'
            placeholder='อีเมล'
            value={email}
            onChange={val => setEmail(val.target.value)}
            border={checkEmail ? '2px solid #DB2D1E' : ''}
          />
          <IconStyle>
            <EmailIcon size='18' />
          </IconStyle>
        </DivInputStyle>
        <Button2
          width='50%'
          backgroundColor='linear-gradient(267.7deg, #8C56D9 -38.6%, #53389E 99.4%)'
          color='white'
          onClick={onResetClick.bind()}
        >
          {reset ? 'ส่งอีกครั้ง' : 'รีเซ็ตรหัส pin'}
        </Button2>
        <div className='mt-3' onClick={() => history.goBack()}>
          <TextLabel color='#8C56D9' fontWeight='500'>
            กลับไปหน้าล็อกอิน
          </TextLabel>
        </div>
      </Box>
    </Container>
  )
}

export default ForgetPin
