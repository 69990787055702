import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import PopupBase from '../../components/popups/PopupBase'
import CameraIcon from '../../atoms/CameraIcon'
import InputText from '../../atoms/InputText'
import SaveIcon from '../../atoms/SaveIcon'
import TextLabel from '../../atoms/TextLabel'
import UploadImageIcon from '../../atoms/UploadImageIcon'
import utils from '../../utils'
import api from '../../api'
import Button2 from '../../atoms/Button2'
import Calendar2 from '../datePicker/Calendar2'
import useDebouncedValue from '../../hook/useDebouncedValue'
import SearchBox from '../../molecules/SearchBox'

const Container = styled.div`
  width: 100%;
  padding: 0 1rem 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ImageBox = styled.div`
  position: relative;
  border-radius: 8px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#E4E8EB'};
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  flex: 0 0 auto;
`

const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  position: sticky;
  bottom: 1rem;
  z-index: 2;
  background: #fff;
`

const PopupAddStore = ({
  handleClose,
  currentStore = '',
  onUpdate,
  onSelectStore,
}) => {
  const [brands, setBrands] = useState([])
  const [q, setQ] = useState('')
  const query = useDebouncedValue(q)
  const [file, setFile] = useState()
  const [store, setStore] = useState({
    storeId: '',
    name: '',
    branchId: -1,
    branch: '',
    address: '',
    phoneNumber: '',
    email: '',
    facebook: '',
    lineId: '',
    imageStore: '',
    endDate: new Date(),
    status: 1,
    brandId: 0,
  })

  useEffect(() => {
    const init = async () => {
      const response = await api.listBrands(query)
      if (response.data) {
        setBrands(response.data)
      }
    }

    if (query) {
      init()
    } else {
      setBrands([])
    }
  }, [query])

  useEffect(() => {
    if (currentStore) {
      setStore({ ...currentStore })
    }
  }, [currentStore])

  const onChangeImage = async event => {
    const file = event.target.files[0]
    if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
      alert('กรุณาใช้ไฟล์รูปภาพเท่านั้น')
    } else if (utils.isFileExceedLimit(file)) {
      alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
    } else {
      const image = await utils.readImage(file)
      setStore(prev => ({ ...prev, imageStore: image.image }))
      setFile(file)
    }
  }

  const onDateChange = date => {
    setStore({ ...store, endDate: date })
  }

  const onSaveClick = async () => {
    try {
      const body = { ...store }
      if (file) {
        const response = await api.uploadImageStore(file)
        if (response.data) {
          body.imageStore = response.data
        } else {
          delete body.imageStore
        }
      }
      if (!body._id) {
        await callApiCreateStore(body)
      } else {
        await callApiUpdateStore(body)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateStore = async body => {
    try {
      const { success, data } = await api.createStore(body)
      if (success) {
        onSelectStore(data)
        handleClose()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateStore = async body => {
    try {
      const { success, data } = await api.updateStore(body._id, body)
      if (success) {
        onUpdate(data)
        handleClose()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onChange = e => {
    const { value } = e.target
    setQ(value)
  }

  const onSelect = option => {
    setStore(prev => ({ ...prev, brandId: option._id }))
  }

  const {
    _id,
    name,
    branch,
    imageStore,
    endDate,
    address,
    facebook,
    lineId,
    storeId,
    brandId,
  } = store

  return (
    <PopupBase maxWidth='600px'>
      <Container>
        <BodyContainer>
          <SearchBox
            options={brands}
            render={brand => `${brand.name} [ ${brand._id} ]`}
            onChange={onChange}
            onSelect={onSelect}
          />
          <Box>
            <ImageBox background={imageStore ? imageStore : ''}>
              {!imageStore && <CameraIcon size='30' />}
              <ImageUploadIcon>
                <label htmlFor='upload-image'>
                  <UploadImageIcon size='25' colorCircle='#8C56D9' />
                </label>
                <input
                  id='upload-image'
                  type='file'
                  onChange={onChangeImage}
                  style={{ display: 'none', visibility: 'none' }}
                />
              </ImageUploadIcon>
            </ImageBox>
            <Column>
              <TextLabel fontWeight='600'>
                Store ID:{' '}
                <TextLabel fontSize='0.875rem'>
                  {!_id ? 'กำลังสร้างร้าน' : _id}
                </TextLabel>
              </TextLabel>
              <TextLabel fontWeight='600'>
                Brand ID:{' '}
                <TextLabel fontSize='0.875rem'>
                  {brandId || 'สร้างให้อัตโนมัติ'}
                </TextLabel>
              </TextLabel>
            </Column>
            <Column>
              <TextLabel fontWeight='600'>Store Ref ID</TextLabel>
              <InputText
                borderRadius='4px'
                boxShadow='none'
                height='35px'
                padding='0 0 0 1rem'
                value={storeId}
                onChange={val =>
                  setStore({ ...store, storeId: val.target.value })
                }
              />
            </Column>
          </Box>
          <Box>
            <Column>
              <TextLabel fontWeight='600'>ชื่อร้าน</TextLabel>
              <InputText
                borderRadius='4px'
                boxShadow='none'
                height='35px'
                padding='0 0 0 1rem'
                value={name}
                onChange={val => setStore({ ...store, name: val.target.value })}
              />
            </Column>
            <Column>
              <TextLabel fontWeight='600'>ชื่อสาขา</TextLabel>
              <InputText
                borderRadius='4px'
                boxShadow='none'
                height='35px'
                padding='0 0 0 1rem'
                value={branch}
                onChange={val =>
                  setStore({ ...store, branch: val.target.value })
                }
              />
            </Column>
          </Box>
          <Box>
            <Column>
              <TextLabel fontWeight='600'>Facebook (Link)</TextLabel>
              <InputText
                borderRadius='4px'
                boxShadow='none'
                height='35px'
                padding='0 0 0 1rem'
                value={facebook}
                onChange={val =>
                  setStore({ ...store, facebook: val.target.value })
                }
              />
            </Column>
            <Column>
              <TextLabel fontWeight='600'>Line ID</TextLabel>
              <InputText
                borderRadius='4px'
                boxShadow='none'
                height='35px'
                padding='0 0 0 1rem'
                value={lineId}
                onChange={val =>
                  setStore({ ...store, lineId: val.target.value })
                }
              />
            </Column>
          </Box>
          <Box>
            <Column>
              <TextLabel fontWeight='600'>ที่อยู่ร้าน</TextLabel>
              <InputText
                borderRadius='4px'
                boxShadow='none'
                height='35px'
                padding='0 0 0 1rem'
                value={address}
                onChange={val =>
                  setStore({ ...store, address: val.target.value })
                }
              />
            </Column>
          </Box>
          <div className='pr-1 pl-1'>
            <Calendar2
              highlightColor='#8C56D9'
              date={endDate}
              fontwardable={true}
              selectedDate={endDate}
              ranged={false}
              onDateChange={onDateChange}
            />
          </div>
          <ButtonContainer>
            <Button2
              color='#98A1B2'
              border='2px solid #98A1B2'
              backgroundColor='white'
              backgroundColorHover='#E5E7EC'
              fontWeight='600'
              onClick={() => handleClose()}
            >
              ยกเลิก
            </Button2>
            <Button2
              border='2px solid #53389E'
              color='#FFFFFF'
              backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
              backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
              fontWeight='600'
              icon={<SaveIcon size='15' />}
              onClick={() => onSaveClick()}
            >
              บันทึก
            </Button2>
          </ButtonContainer>
        </BodyContainer>
      </Container>
    </PopupBase>
  )
}

export default PopupAddStore
