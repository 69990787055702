export const GET_CUSTOMER_SEARCH = 'GET_CUSTOMER_SEARCH'

export const getCustomerSearch = (data: any) => {
  return {
    type: GET_CUSTOMER_SEARCH,
    payload: { data },
  }
}

export const CLEAR_CUSTOMER_SEARCH = 'CLEAR_CUSTOMER_SEARCH'

export const clearCustomerSearch = () => {
  return {
    type: CLEAR_CUSTOMER_SEARCH,
    payload: { search: '' },
  }
}
