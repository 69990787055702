import { atom } from "jotai";
// @ts-ignore
import api from '@/api'
import { Time } from "@/redux/models/BookOrder";

interface IStoreTime {
    closeTime: Time
    openTime: Time
    restDay: boolean
    sameTime: boolean
    day: number
}

// Atoms
export const storeOpeningHoursAtom = atom<IStoreTime[]>([])

// Functions
export const fnFetchStoreOpeningHoursAtom = atom(
    (get) => get(storeOpeningHoursAtom)
    , async (get, set) => {
    const { data } = await api.getStoreDateTime()
    set(storeOpeningHoursAtom, data)
    return data
    }
)