import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { CSSProperties } from 'styled-components'

//@ts-ignore
import Button2 from '../../atoms/Button2'
import TextLabel from '../../atoms/TextLabel'

//@ts-ignore
import api from '../../api'
//@ts-ignore
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import { clearSearch } from '../../redux/actions/pageAction'
import { Customer } from '../../redux/models/Customer'
import { HOME_URL } from '../../routes'
import utils from '../../utils'

interface CustomerDataStyle extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  gap: 0.5rem;
  box-shadow: 0px 16px 24px rgba(152, 161, 178, 0.08);
`
const ImageBox = styled.div<CustomerDataStyle>`
  border-radius: ${props => props.borderRadius || '4px'};
  background: ${props =>
    props.background ? `url(${props.background})` : '#E4E8EB'};
  width: ${props => props.width || '50px'};
  height: ${props => props.height || '50px'};
  background-size: cover;
  aspect-ratio: 1;
`
const FlexBox = styled.div<CustomerDataStyle>`
  display: flex;
  width: 100%;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0'};
  justify-content: ${props => props.justifyContent || 'space-between'};
`
const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: none;
  }
  width: 100%;
`

interface CustomerDateProps {
  customer: Customer
  onEditCustomer: () => void
}

const CustomerData = ({ customer, onEditCustomer }: CustomerDateProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const onBookingClick = async () => {
    const { data: bookingCode } = await api.createBookingCode()

    let startTime = utils.convertTime({
      hour: new Date().getHours() + '',
      minute: new Date().getMinutes() + '',
    })

    dispatch(
      getOrderBooking({
        _id: -1,
        bookingCode: bookingCode,
        customerId: customer._id,
        name: customer.name,
        email: customer.email,
        countryCode: customer.countryCode,
        phoneNumber: customer.phoneNumber,
        specialRequest: customer.specialRequest,
        date: new Date(),
        time: {
          hour: new Date().getHours(),
          minute: new Date().getMinutes(),
        },
        startTime: {
          hour: new Date().getHours(),
          minute: new Date().getMinutes(),
        },
        endTime: {
          hour: Math.floor(Math.abs((startTime + 60) / 60)),
          minute: (startTime + 60) % 60,
        },
        orders: [
          {
            customer: 1,
            customerId: customer._id,
            name: customer.name,
            phoneNumber: customer.phoneNumber,
            gender:
              typeof customer.gender === 'number'
                ? customer.gender
                : customer.gender?.value || -1,
            age:
              typeof customer.age === 'number'
                ? { value: customer.age, label: '' }
                : customer.age,
            nationality: customer.nationality,
            services: [],
            specialRequest: customer.specialRequest,
          },
        ],
        bookingType: {
          bookingTypeId: 2,
          bookingType: 'Booking',
        },
      })
    )

    dispatch(clearSearch())
  }

  return (
    <Container>
      <FlexBox gap='0.5rem' justifyContent='none'>
        <ImageBox background={customer.image} />
        <FlexBox flexDirection='column' justifyContent='none'>
          <TextLabel fontWeight='500'>
            {customer.name || customer.firstName || '-'}
          </TextLabel>
          <TextLabel>
            {utils.formatPhoneNumber(customer.phoneNumber + '')}
          </TextLabel>
        </FlexBox>
      </FlexBox>
      <FlexBox>
        <TextLabel>{t('birthday')}</TextLabel>
        <TextLabel>
          {customer.birthDay
            ? utils.formatDate(customer.birthDay, 'DD/MM/YYYY')
            : 'ไม่ระบุ'}
        </TextLabel>
      </FlexBox>
      <FlexBox>
        <TextLabel>{t('gender')}</TextLabel>
        <TextLabel>
          {typeof customer.gender === 'object' && customer.gender !== null
            ? customer.gender.label
            : 'ไม่ระบุ'}
        </TextLabel>
      </FlexBox>
      <FlexBox>
        <TextLabel>{t('ageRange')}</TextLabel>
        {typeof customer.age === 'object' && customer.age !== null
          ? customer.age.label
          : 'ไม่ระบุ'}
      </FlexBox>
      <FlexBox>
        <TextLabel>{t('nationality')}</TextLabel>
        {typeof customer.nationality === 'object' &&
        customer.nationality !== null
          ? customer.nationality.label
          : 'ไม่ระบุ'}
      </FlexBox>
      <FlexBox gap='0.5rem' style={{ marginTop: 'auto' }}>
        <Button2
          backgroundColor='none'
          border='1px solid #8C56D9'
          color='#8C56D9'
          onClick={() => onEditCustomer()}
        >
          {t('edit')}
        </Button2>
        <LinkStyled
          to={{
            pathname: HOME_URL,
          }}
          onClick={() => onBookingClick()}
        >
          <Button2
            backgroundColor='linear-gradient(221.86deg, #8C56D9 2.71%, #53389E 97.79%)'
            color='#FFF'
          >
            {t('booked')}
          </Button2>
        </LinkStyled>
      </FlexBox>
    </Container>
  )
}

export default CustomerData
