import React from 'react'

const TherapistCommissionIcon = () => (
  <svg
    width='19'
    height='26'
    viewBox='0 0 19 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.7967 25.0031V16.4831C17.7964 15.3798 17.5788 14.2874 17.1563 13.2682C16.7338 12.249 16.1147 11.323 15.3343 10.5431L12.9943 8.20312V16.1075C12.9943 17.1755 11.7019 17.7119 10.9459 16.9559L9.39187 15.4031C8.79187 14.8031 7.35067 14.4431 6.38947 15.4031C5.42947 16.3631 5.78947 17.8031 6.38947 18.4031L12.9943 25.0031'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.2 1H10.6C11.2365 1 11.847 1.25286 12.2971 1.70294C12.7471 2.15303 13 2.76348 13 3.4V5.8M8.2 1C8.2 2.5996 9.16 5.8 13 5.8M8.2 1H5.8M13 5.8V13M5.8 1H3.4C2.76348 1 2.15303 1.25286 1.70294 1.70294C1.25286 2.15303 1 2.76348 1 3.4V5.8M5.8 1C5.8 2.5996 4.84 5.8 1 5.8M1 13V15.4C1 16.0365 1.25286 16.647 1.70294 17.0971C2.15303 17.5471 2.76348 17.8 3.4 17.8H5.8C5.8 16.2004 4.84 13 1 13ZM1 13V5.8'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.80312 9.40313C5.80312 10.0659 6.34038 10.6031 7.00312 10.6031C7.66587 10.6031 8.20312 10.0659 8.20312 9.40313C8.20312 8.74038 7.66587 8.20312 7.00312 8.20312C6.34038 8.20312 5.80312 8.74038 5.80312 9.40313Z'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default TherapistCommissionIcon
