import MultipleSelect from '@/components/atoms/select/MultipleSelect'
import FilterDate from '@/components/molecules/FilterDate'
import { useAlert } from '@/hook/useAlert'
import React from 'react'

type Props = {}

export default function SelectComponentPage({}: Props) {

    const handleDateChange = (startDate: Date, endDate: Date) => {
        console.log(startDate, endDate)
    }
    
    const [value, setValue] = React.useState<string[]>([])

  return (
    <div>
      <FilterDate 
      onlyRange
      onDateChange={handleDateChange} 
      date={{
        startDate: new Date(),
        endDate: new Date()
      }} />
      <MultipleSelect 
      selectAll
        items={[
            { value: 'Somchai', key: '1', subcategory: "test Q" },
            { value: 'Somchai', key: '2', subcategory: "test W" },
            { value: 'Somchai', key: '3', subcategory: "test Q" },
            { value: 'Somchai', key: '4', subcategory: "test W" },
            { value: 'Somchai', key: '5', subcategory: "test Q" },
        ]}
        value={value}
        onValueChange={(e) => {
            setValue(e)
        }}
      />
    </div>
  )
}