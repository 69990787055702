import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ReactToPrint from 'react-to-print'

import Button2 from '../../atoms/Button2'
import utils from '../../utils'
import { useSelector } from 'react-redux'

const Container = styled.div`
  height: 100%;
  max-width: 105mm;
  min-width: 105mm;
`
const Card = styled.div`
  padding: 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItem || 'center'};
  width: fit-content;
  @page {
    size: 85mm 276mm;
  }
  @media print {
    width: 105mm;
  }
`
const Text = styled.div`
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || '600'};
  text-align: ${props => props.textAlign || 'center'};
  width: ${props => props.width || '100%'};
  color: #000;
  word-wrap: ${props => props.wordWarp || 'unset'};
`
const ImageBox = styled.div`
  border-radius: 8px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#E4E8EB'};
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => props.gap};
  width: 100%;
`
const Line = styled.div`
  border-style: ${props => props.borderStyle || 'solid'};
  border-color: ${props => props.color || '#000'};
  border-width: 1px;
  width: 100%;
  height: 1px;
`
const Box = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-left: auto;
`
const BoxPackage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ReceiptSignContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
  gap: 1rem;
`

const ReceiptSign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  min-height: 64px;
  justify-content: space-between;
`
const ReceiptSignLine = styled.div`
  border-style: dashed;
  border-color: #000;
  border-width: 1px;
  width: 100%;
  height: 1px;
`

const Receipt = ({
  receiptData = {
    nameThai: '',
    addressThai: '',
    nameEng: '',
    addressEng: '',
    phoneNumber: '',
    eMail: '',
    taxIDNumber: '',
    image: '',
    type: 0,
  },
  date = new Date(),
  receiptNumber = '',
  services = [],
  serviceCount = 1,
  productCount = 0,
  products = [],
  packages = [],
  packageCount = 0,
  discount = 0,
  remainingCustomerPackages = [],
  packageDiscount = 0,
  charge = 0,
  total = 100,
  point = 0,
  tip = 0,
  customerData = {
    customerId: -1,
    name: '',
    phoneNumber: '',
    address: '',
    taxIDNumber: '',
  },
}) => {
  const _store = useSelector(state => state.store)

  let componentRef = useRef(null)
  const [logo, setLogo] = useState('')

  useEffect(() => {
    if (receiptData.image) {
      if (receiptData.image[0] === 'd') {
        setLogo(receiptData.image)
      } else {
        setLogo(`${receiptData.image}?date=${new Date().getTime()}`)
      }
    }
  }, [receiptData.image])

  return (
    <Container>
      <Card
        ref={el => (componentRef = el)}
        style={{ type: 'text/css', media: 'print' }}
      >
        {logo && (
          <ImageBox
            crossOrigin='anonymous'
            background={logo ? logo : ''}
          ></ImageBox>
        )}
        <Text>{`${receiptData.nameThai}`}</Text>
        <Text>{`${receiptData.addressThai}`}</Text>
        <Text>{`${
          receiptData.taxIDNumber
            ? `เลขผู้เสียภาษี: ${receiptData.taxIDNumber}`
            : ''
        }`}</Text>
        <Text>{`${
          receiptData.phoneNumber
            ? `เบอร์โทร: ${utils.formatPhoneNumber(receiptData.phoneNumber)}`
            : ''
        }`}</Text>
        <Line></Line>
        <GridBox columns='1fr 1fr'>
          <div>
            <Text>{`ใบกำกับภาษีแบบย่อ`}</Text>
            <Text>{`/ใบเสร็จรับเงิน`}</Text>
          </div>
          <Text>วันที่</Text>
        </GridBox>
        <GridBox columns='1fr 1fr'>
          <Text>{`${receiptNumber ? receiptNumber : 'R0123456789'}`}</Text>
          <Text>{`${utils.formatDate(date, 'DD/MM/YYYY')}`}</Text>
        </GridBox>
        <GridBox>
          <Text textAlign='start'>{`ชื่อ: ${customerData.name}`}</Text>
        </GridBox>
        <GridBox>
          <Text textAlign='start'>{`โทร: ${customerData.phoneNumber}`}</Text>
        </GridBox>
        <GridBox>
          <Text textAlign='start'>{`ที่อยู่: ${customerData.address}`}</Text>
        </GridBox>
        <GridBox>
          <Text textAlign='start'>{`เลขผู้เสียภาษี: ${
            customerData.taxIDNumber || ''
          }`}</Text>
        </GridBox>
        <div className='mt-3'></div>
        <GridBox columns='minmax(0, 0.5fr) minmax(0, 1fr) minmax(0, 1fr)'>
          <Text textAlign='start'>จำนวน</Text>
          <Text textAlign='start'>รายการ</Text>
          <Text textAlign='end'>ราคา</Text>
        </GridBox>
        {!services.length && !products.length && !packages.length && (
          <GridBox columns='0.5fr 1fr 1fr'>
            <Text textAlign='start' style={{ margin: 'auto' }}>
              1
            </Text>
            <Text textAlign='start'>นวดฝ่าเท้า</Text>
            <Text textAlign='end'>100.00</Text>
          </GridBox>
        )}
        {!!services.length && (
          <>
            {services.map(({ service, time, count, sum }) => (
              <GridBox columns='minmax(0, 0.5fr) minmax(0, 1fr) minmax(0, 1fr)'>
                <Text
                  textAlign='start'
                  // style={{ margin: 'auto' }}
                >{`${count}`}</Text>
                <Text
                  textAlign='start'
                  width='210px'
                  wordWarp='break-word'
                >{`${service} ${time}min`}</Text>
                <Text
                  textAlign='end'
                  // style={{ margin: 'auto' }}
                >{`${utils.formatNumber(sum || 0, 2)}`}</Text>
              </GridBox>
            ))}
          </>
        )}
        {!!products.length && (
          <>
            {products.map(({ product, price, quantity }) => (
              <GridBox columns='minmax(0, 0.5fr) minmax(0, 1fr) minmax(0, 1fr)'>
                <Text
                  textAlign='start'
                  // style={{ margin: 'auto' }}
                >{`${quantity}`}</Text>
                <Text
                  textAlign='start'
                  width='210px'
                  wordWarp='break-word'
                >{`${product}`}</Text>
                <Text textAlign='end'>{`${utils.formatNumber(
                  (price || 0) * (quantity || 0),
                  2
                )}`}</Text>
              </GridBox>
            ))}
          </>
        )}
        {!!packages.length && (
          <>
            {packages.map(({ name, price, quantity }) => (
              <GridBox columns='minmax(0, 0.5fr) minmax(0, 1fr) minmax(0, 1fr)'>
                <Text
                  textAlign='start'
                  // style={{ margin: 'auto' }}
                >{`${quantity}`}</Text>
                <Text
                  textAlign='start'
                  width='210px'
                  wordWarp='break-word'
                >{`${name}`}</Text>
                <Text textAlign='end'>{`${utils.formatNumber(
                  (price || 0) * (quantity || 0),
                  2
                )}`}</Text>
              </GridBox>
            ))}
          </>
        )}
        {tip !== 0 && !packages.length && (
          <GridBox columns='0.2fr 1fr 1fr'>
            <Text textAlign='start' style={{ margin: 'auto' }}></Text>
            <Text textAlign='start'>{`ทิปพนักงาน`}</Text>
            <Text textAlign='end'>{`${utils.formatNumber(tip || 0, 2)}`}</Text>
          </GridBox>
        )}
        <Line borderStyle='dashed'></Line>
        <Text textAlign='none'>{`รวม ${
          serviceCount + productCount + packageCount
        } รายการ`}</Text>
        <Box>
          <Text textAlign='end'>ราคาเต็ม</Text>
          <Text textAlign='end'>{`${
            total === 0 ? '0.00' : utils.formatNumber(total || 0, 2)
          }`}</Text>
        </Box>
        <Box>
          <Text textAlign='end'>ส่วนลด</Text>
          <Text textAlign='end'>{`${
            discount === 0 ? '0.00' : utils.formatNumber(discount || 0, 2)
          }`}</Text>
        </Box>
        <Box>
          <Text textAlign='end'>
            {_store._id === 64 ? 'ยอดใช้เมมเบอร์' : 'ยอดใช้แพ็คเกจ'}
          </Text>
          <Text textAlign='end'>{`${
            packageDiscount === 0
              ? '0.00'
              : utils.formatNumber(packageDiscount || 0, 2)
          }`}</Text>
        </Box>
        {charge !== 0 && (
          <Box>
            <Text textAlign='end'>ชาร์จ</Text>
            <Text textAlign='end'>{`${
              charge === 0 ? '0.00' : utils.formatNumber(charge || 0, 2)
            }`}</Text>
          </Box>
        )}
        {receiptData.type === 1 && (
          <Box>
            <Text textAlign='end'>Vat (7%)</Text>
            <Text textAlign='end'>{`${utils.formatNumber(
              ((total || 0) - (discount || 0) - (packageDiscount || 0)) *
                (7 / 107),
              2
            )}`}</Text>
          </Box>
        )}
        <Box>
          <Text textAlign='end'>ยอดชำระ</Text>
          <Text
            textAlign='end'
            style={{
              textDecorationLine: 'underline',
              textDecorationStyle: 'double',
            }}
          >
            {`${utils.formatNumber(
              total + +charge + tip - discount - packageDiscount,
              2
            )}`}
          </Text>
        </Box>
        <Line borderStyle='dashed'></Line>
        {packageDiscount !== 0 && (
          <BoxPackage>
            <Text textAlign='none'>
              {_store._id === 64 ? 'เมมเบอร์คงเหลือ' : `แพ็คเกจคงเหลือ`}
            </Text>
            {remainingCustomerPackages.map(
              ({ customerName, remainingCustomerPackages }, i) => (
                <div className='mb-2' key={i}>
                  <Text textAlign='none'>{`คุณ ${customerName}`}</Text>
                  {remainingCustomerPackages.map((r, i) => (
                    <Text key={i} textAlign='none'>{`${
                      r.type === 1
                        ? 'Point แทนเงินสด'
                        : r.type === 2
                        ? r.name
                        : r.type === 3
                        ? 'จำนวนนาทีคงเหลือ'
                        : ''
                    } :  ${
                      r.type !== 4
                        ? r.type === 1
                          ? utils.formatNumber(r.remainingValue, 2)
                          : r.remainingValue
                        : ''
                    } `}</Text>
                  ))}
                </div>
              )
            )}
          </BoxPackage>
        )}
        {/* Signature */}
        <ReceiptSignContainer>
          <ReceiptSign>
            <Text>ลูกค้า</Text>
            <ReceiptSignLine />
          </ReceiptSign>
          <ReceiptSign>
            <Text>ผู้รับเงิน</Text>
            <ReceiptSignLine />
          </ReceiptSign>
        </ReceiptSignContainer>
      </Card>
      <ReactToPrint
        trigger={() => (
          <Button2
            backgroundColor='#FFF'
            border='1px solid #53389E'
            color='#53389E'
          >
            พิมพ์ใบเสร็จ
          </Button2>
        )}
        content={() => componentRef}
      />
    </Container>
  )
}

export default Receipt
