import React from 'react'
import styled from 'styled-components'

import MultipleSelect, {
  DropdownPlaceholder,
  MultipleSelectProps,
  TriggerButton,
} from '@/components/atoms/select/MultipleSelect'

export interface FilterButtonProps extends MultipleSelectProps {
  title?: string
  triggerButton?: React.ReactNode
}

const StyledMultipleSelect = styled(MultipleSelect)`
  width: fit-content;
  ${DropdownPlaceholder} {
    color: var(--text-primary);
    font-size: 14px;
  }
  ${TriggerButton} {
    border-radius: 999px;
  }
`

export const FilterButton = (props: FilterButtonProps) => {
  const { title, triggerButton, ...rest } = props
  return (
    <StyledMultipleSelect
      applyable
      {...rest}
      placeholder={title || 'Filter'}
      title={triggerButton}
    />
  )
}
