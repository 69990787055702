import styled from 'styled-components'

// Define the types for Row component props
interface RowProps {
  noWrap?: boolean
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  align?: 'stretch' | 'flex-start' | 'center' | 'flex-end' | 'baseline'
  gap?: string // Gap between columns, e.g., '16px'
}

// Define the types for Col component props
interface ColProps {
  size?: number // Column size in percentage (e.g., 25 for 25%)
  smSize?: number // Small screen size (e.g., < 768px)
  xsSize?: number // Extra small screen size (e.g., < 576px)
  gap?: string // Internal padding or gap
  align?: 'stretch' | 'flex-start' | 'center' | 'flex-end' | 'baseline'
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around'
}

// Reusable Row Component
const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: ${({ noWrap }) => (noWrap ? 'nowrap' : 'wrap')};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'stretch'};
  gap: ${({ gap }) => gap || '0'};
`

// Reusable Col Component
const Col = styled.div<ColProps>`
  display: flex;
  flex-direction: column;
  flex: ${({ size }) => (size ? `0 0 ${size}%` : '1')};
  max-width: ${({ size }) => (size ? `${size}%` : '100%')};
  gap: ${({ gap }) => gap || '0'};
  align-items: ${({ align }) => align || 'stretch'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`

export { Row, Col }
