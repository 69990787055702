import React, { ReactNode } from 'react'
import styled from 'styled-components'

import Label from '../../atoms/Label'

import utils from '../../utils'

const Container = styled.div`
  background: #f8f9fb;
  border-radius: 10px;
  padding: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export interface DataBoxProps {
  name: string
  value: number
  icon?: ReactNode
  onClick?: () => void
}

const DataBox = ({ name, value, icon, onClick }: DataBoxProps) => {
  return (
    <Container onClick={onClick}>
      <Label color='#475467' size='0.875rem' weight={400} lineCap>
        {name}
      </Label>
      <Label color='#000' size='1rem' weight={500} lineCap>
        {utils.formatNumber(value || 0)}
      </Label>
      {icon}
    </Container>
  )
}

export default DataBox
