import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import utils from '@/utils'

import { getOrderBooking } from '../../../../redux/actions/bookingHistoryAction'
import {
  BookOrder,
  getInitialServiceBookOrder,
} from '../../../../redux/models/BookOrder'
import logicServiceCouple from '../../logicServiceCouple'

interface useCardOrderProps {
  bookingOrderState: BookOrder
}

const useCardOrder = ({ bookingOrderState }: useCardOrderProps) => {
  const dispatch = useDispatch()

  const itemRef = useRef<HTMLDivElement | null>(null)
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const { focusedCustomerIndex, customers, countCustomer } = bookingOrderState

  useEffect(() => {
    const currentScrollbar = scrollRef.current
    const currentItem = itemRef.current
    if (currentScrollbar && currentItem) {
      currentScrollbar.scrollTo({
        top: currentItem.offsetTop,
        behavior: 'smooth',
      })
    }
  }, [focusedCustomerIndex])

  const onRemoveCustomerClick = (customerIndex: number) => {
    let newArrCustomer = utils.deepClone(customers)

    const price =
      newArrCustomer[customerIndex].services.reduce((acc, info) => {
        if (info.serviceId !== -1) {
          acc += info.price
        }

        return acc
      }, 0) || 0

    const serviceCustomers = newArrCustomer[newArrCustomer.length - 1].services
      .filter(s => s.serviceId !== -1 && s.serviceCustomer > 1)
      .map(s => ({
        serviceId: s.serviceId,
        serviceTime: s.time,
        serviceMapCustomerIndex: s.primaryCustomerIndex,
      }))

    if (serviceCustomers?.length) {
      for (const serviceCustomer of serviceCustomers) {
        newArrCustomer = logicServiceCouple.removeCustomerServiceCouple(
          newArrCustomer,
          serviceCustomer.serviceMapCustomerIndex.customerIndex,
          serviceCustomer.serviceId,
          serviceCustomer.serviceTime
        )
      }
    }

    // const removeCustomer =
    newArrCustomer.splice(customerIndex, 1)

    newArrCustomer = logicServiceCouple.checkServiceCoupleV2(newArrCustomer)

    // const serviceCustomers = removeCustomer[0]?.services
    //   .filter(s => s.serviceId !== -1 && s.serviceCustomer > 1)
    //   .map(s => ({
    //     serviceId: s.serviceId,
    //     serviceCustomer: s.serviceCustomer,
    //   }))

    // if (serviceCustomers?.length) {
    //   let _services: {
    //     serviceId: number
    //     customerIndex: number
    //     serviceIndex: number
    //   }[] = []

    //   for (let index = 0; index < newArrCustomer.length; index++) {
    //     const { services } = newArrCustomer[index]
    //     const filterServices = serviceCustomers
    //       .filter(itemA =>
    //         services.some(itemB => itemB.serviceId === itemA.serviceId)
    //       )
    //       .map((s, i) => ({
    //         serviceId: s.serviceId,
    //         customerIndex: index,
    //         serviceIndex: i,
    //       }))

    //     _services = _services.concat(filterServices)
    //   }
    //   for (const serviceCustomer of serviceCustomers) {
    //     const index = logicServiceCouple.checkServiceCouple(
    //       serviceCustomer,
    //       _services
    //     )

    //     for (const i of index) {
    //       newArrCustomer[_services[i].customerIndex].services[
    //         _services[i].serviceIndex
    //       ].serviceError = true
    //     }
    //   }
    // }

    dispatch(
      getOrderBooking({
        countCustomer: countCustomer - 1,
        customers: newArrCustomer,
        focusedCustomerIndex: countCustomer - 2,
        total: bookingOrderState.total - price,
      })
    )
  }

  const onCustomerClick = (customerIndex: number) => {
    dispatch(getOrderBooking({ focusedCustomerIndex: customerIndex }))
  }

  const onAddService = (customerIndex: number) => {
    const newArrCustomer = [...customers]
    newArrCustomer[customerIndex].services.push({
      ...getInitialServiceBookOrder(),
      primaryCustomerIndex: {
        customerIndex,
        serviceIndex: newArrCustomer[customerIndex].services.length,
      },
    })
    dispatch(
      getOrderBooking({
        customers: newArrCustomer,
      })
    )
  }

  return {
    itemRef,
    scrollRef,
    onRemoveCustomerClick,
    onCustomerClick,
    onAddService,
  }
}

export default useCardOrder
