import React, { useState } from 'react'
import styled from 'styled-components'

import api from '../../api'
import Button2 from '../../atoms/Button2'
import InputText from '../../atoms/InputText'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import SaveIcon from '../../atoms/SaveIcon'
import TextLabel from '../../atoms/TextLabel'
import auth from '../../auth'
import utils from '../../utils'

const CloseRTL = styled.div`
  height: 15px;
  width: 1.5px;
  background-color: #23272a;
  transform: rotate(-45deg);
  z-index: 1;
  cursor: pointer;
`
const CloseLTR = styled.div`
  height: 15px;
  width: 1.5px;
  background-color: #23272a;
  transform: rotate(90deg);
  z-index: 2;
  cursor: pointer;
`
const UploadImage = styled.div`
  display: flex;
  width: 45%;
  background: #f8f9fb;
  border: 2px dashed #e4e8eb;
  border-radius: 16px;
  padding: ${props => props.padding || '3rem 1rem'};
  align-items: center;
  justify-content: center;
  margin: auto;
`
const PopupAddOnline = ({ handleClose, onSave }) => {
  const [fileImage, setFileImage] = useState('')
  const [image, setImage] = useState('')
  const [name, setName] = useState('')
  const [checkName, setCheckName] = useState(false)

  const onSaveClick = async () => {
    if (!name) {
      setCheckName(true)
      return false
    } else {
      setCheckName(false)
    }
    try {
      let create = {
        payChannelTypeId: 2,
        payChannelId: -1,
        payChannel: name,
        storeId: auth.getStoreId(),
      }
      if (fileImage) {
        const { data, success } = await api.uploadImagePayChannel({
          token: auth.getToken(),
          image: fileImage,
        })
        if (success) {
          create = { ...create, image: data }
        }
      }
      onSave(create)
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeImage = async event => {
    const file = event.target.files[0]
    setFileImage(file)
    if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
      alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
    } else if (utils.isFileExceedLimit(file)) {
      alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
    } else {
      const image = await utils.readImage(file)
      setImage(image.image)
    }
  }

  return (
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='30%'
        height='auto'
        padding='1rem 1rem 2rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
        alignItems='flex-start'
        borderRadius='8px'
      >
        <div
          className='mr-2'
          style={{ marginLeft: 'auto' }}
          onClick={handleClose}
        >
          <CloseRTL>
            <CloseLTR></CloseLTR>
          </CloseRTL>
        </div>
        <div className='mb-4' style={{ width: '100%', textAlign: 'center' }}>
          <TextLabel color='#8C56D9' fontWeight='600' fontSize='1.2rem'>
            เพิ่มออนไลน์วอลเล็ต
          </TextLabel>
        </div>
        <div style={{ width: '100%' }}>
          <UploadImage padding={image ? '1rem' : ''}>
            <label
              for='upload-image'
              style={{
                display: 'flex',
                color: '#475467',
                margin: '0',
                flexWrap: 'wrap',
              }}
            >
              {image ? (
                <img src={image} style={{ width: '100%' }} alt='online' />
              ) : (
                <>
                  อัพโหลดรูป
                  <TextLabel
                    paddingLeft='0.25rem'
                    color='#8C56D9'
                    fontWeight='600'
                  >
                    คลิก
                  </TextLabel>
                </>
              )}
            </label>
            <input
              id='upload-image'
              type='file'
              onChange={onChangeImage}
              style={{ display: 'none', visibility: 'none' }}
            />
          </UploadImage>
        </div>
        <div className='mt-3'></div>
        <TextLabel fontWeight='600'>ชื่อวอลเล็ต</TextLabel>
        <div className='mt-2'></div>
        <InputText
          padding='0.5rem 1rem'
          boxShadow='0px 4px 4px rgba(71, 84, 103, 0.05)'
          border={checkName ? '2px solid #DB2D1E' : ''}
          value={name}
          onChange={val => setName(val.target.value)}
        />
        <div className='mt-4'></div>
        <Button2
          backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
          backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
          color='white'
          icon={<SaveIcon size='18' />}
          onClick={() => onSaveClick()}
        >
          บันทึก
        </Button2>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddOnline
