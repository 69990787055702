import React from 'react'

const BahtIcon3 = ({ size, color }) => (
  <svg
    width={size || '23'}
    height={size || '24'}
    viewBox='0 0 23 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_1345_513)'>
      <path
        d='M21.2938 24.0023H1.55192C0.893851 24.0023 0.390625 23.499 0.390625 22.841C0.390625 22.1829 0.893851 21.6797 1.55192 21.6797H21.2938C21.9519 21.6797 22.4551 22.1829 22.4551 22.841C22.4551 23.499 21.9519 24.0023 21.2938 24.0023Z'
        fill={color || '#475467'}
      />
      <path
        d='M12.4259 10.9531H10.2969V13.5467H12.4259C13.2775 13.5467 13.742 12.9273 13.742 12.2305C13.742 11.4951 13.2775 10.9531 12.4259 10.9531Z'
        fill={color || '#475467'}
      />
      <path
        d='M13.0836 8.55832C13.0836 7.90025 12.6191 7.24219 11.7288 7.24219H10.2578V9.83574H11.7288C12.6191 9.83574 13.0836 9.33251 13.0836 8.55832Z'
        fill={color || '#475467'}
      />
      <path
        d='M11.4194 0C5.10968 0 0 5.10968 0 11.4194C0 14.7097 1.39355 17.6516 3.6 19.7419H19.2387C21.4452 17.6516 22.8387 14.7097 22.8387 11.4194C22.8387 5.10968 17.729 0 11.4194 0ZM13.0065 15.0581H12.1161V16.0258C12.1161 16.3355 11.8452 16.5677 11.5355 16.5677C11.2258 16.5677 10.9548 16.3355 10.9548 16.0258V15.0581H9.29032C8.74839 15.0581 8.32258 14.6323 8.32258 14.0903V6.73548C8.32258 6.19355 8.7871 5.76774 9.29032 5.76774H10.9548V4.8C10.9548 4.49032 11.1871 4.25806 11.5355 4.25806C11.8452 4.25806 12.1161 4.49032 12.1161 4.8V5.76774H12.3097C14.0516 5.76774 14.9806 6.73548 14.9806 8.28387C14.9806 9.09677 14.5935 9.75484 13.9742 10.2194C14.9419 10.5677 15.6 11.3806 15.6 12.5419C15.6774 13.8968 14.8258 15.0581 13.0065 15.0581Z'
        fill={color || '#475467'}
      />
    </g>
    <defs>
      <clipPath id='clip0_1345_513'>
        <rect width='22.8387' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default BahtIcon3
