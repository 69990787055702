import React from 'react'
import { useSelector } from 'react-redux'

import Option, { SelectorOption } from '@/redux/models/Option'

import SelectorBase from './SelectorBase'

interface SelectorNationalityProps {
  name?: string
  open: boolean
  onClick: (open: boolean) => void
  option?: Option
  onOptionClick: (option: SelectorOption, name: string) => void
  error?: boolean
  readOnly?: boolean
}

const SelectorNationality = ({
  name = 'nationality',
  open,
  onClick,
  option = {
    value: -1,
    label: 'สัญชาติ',
  },
  onOptionClick,
  error = false,
  readOnly = false,
}: SelectorNationalityProps) => {
  const { nationalityOptions } = useSelector((state: any) => state.brand)

  return (
    <SelectorBase
      name={name}
      option={option}
      options={nationalityOptions}
      open={open}
      onClick={onClick}
      onOptionClick={onOptionClick}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorNationality
