import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import api from '../../api'
import TextLabel from '../../atoms/TextLabel'
import InputText from '../../atoms/InputText'
import utils from '../../utils'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import PopupAddOnline from '../popups/PopupAddOnline'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  flex: 1;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns || 'repeat(5, 0.5fr)'};
  gap: ${props => props.gap || '0.5rem'};
  width: 100%;
`
const Box = styled.div`
  border: ${props => props.border || '1px solid #E4E8EB'};
  border-radius: 8px;
  padding: ${props => props.padding || '0.5rem'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor || '#FFF'};
`
const IconStyle = styled.div`
  position: absolute;
  right: 2.5rem;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
`
const DivInputStyle = styled.div`
  position: relative;
  padding: 0;
  height: fit-content;
  width: 50%;
`

const Online = ({
  bookingCode,
  total,
  discount,
  serviceDiscount,
  onSelectPayChannel: _onSelectPayChannel,
  onChargeChange: _onChargeChange,
  splitPayment = false,
}) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [payChannelLength, setPayChannelLength] = useState(0)
  const [activePayChannel, setActivePayChannel] = useState(0)
  const [unit, setUnit] = useState(1)
  const [onlineCharge, setOnlineCharge] = useState('')
  const [isOpenPopupAddCredit, setIsOpenPopupAddCredit] = useState(false)
  const [online, setOnline] = useState([
    { payChannelId: 5, payChannel: 'TrueMoney', icon: '/images/TrueMoney.png' },
    {
      payChannelId: 6,
      payChannel: 'WeChat',
      icon: '/images/WeChatPay2.png',
    },
    { payChannelId: 7, payChannel: 'AliPay', icon: '/images/Alipay2.png' },
    { payChannelId: 8, payChannel: 'AirPay', icon: '/images/Airpay.png' },
    { payChannelId: 9, payChannel: 'LinePay', icon: '/images/LinePay.png' },
    { payChannelId: 10, payChannel: 'PayPal', icon: '/images/PayPal.png' },
    { payChannelId: -1, payChannel: '', icon: '' },
  ])
  useEffect(() => {
    callApiPayChannelByTypeId()
    callApiPayChannelAll()
  }, [])

  const callApiPayChannelByTypeId = async () => {
    try {
      let _online = []
      const { data, success } = await api.getPayChannelByTypeId(4)
      if (success) {
        if (data.length !== 0) {
          for (const item of data) {
            const { payChannelId, payChannel, image, status } = item

            if (status !== -1) {
              const checkOnline =
                online.find(o => o.payChannelId === payChannelId) || ''
              if (!checkOnline) {
                _online.push({ payChannelId, payChannel, icon: image })
              } else {
                _online.push({
                  payChannelId: checkOnline.payChannelId,
                  payChannel: checkOnline.payChannel,
                  icon: checkOnline.icon,
                })
              }
            }
          }
        }

        _online.push({ payChannelId: -1, payChannel: '', icon: '' })
        _onSelectPayChannel({
          payChannelId: _online[0].payChannelId,
          payChannel: _online[0].payChannel,
        })

        setOnline(_online)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiPayChannelAll = async () => {
    try {
      const { data, success } = await api.getPayChannel()
      if (success) {
        setPayChannelLength(data.length)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onChargeChange = value => {
    if (utils.checkNumber(value)) {
      if (!value) {
        dispatch(getOrderBooking({ charge: 0 }))
      } else {
        if (unit === 1) {
          const percent = value / 100
          const _total = (total - discount - serviceDiscount || 0) * percent
          if (splitPayment) _onChargeChange(utils, value)
          else dispatch(getOrderBooking({ charge: _total }))
        } else {
          if (splitPayment) _onChargeChange(utils, value)
          else dispatch(getOrderBooking({ charge: parseInt(value) }))
        }
      }
      setOnlineCharge(value)
      if (splitPayment) _onChargeChange(utils, value)
    }
  }

  const onAddOnlineClick = () => {
    setIsOpenPopupAddCredit(!isOpenPopupAddCredit)
  }
  const onSaveClick = async _create => {
    try {
      const create = { ..._create, payChannelId: payChannelLength }
      const { success } = await api.createPayChannel(create)
      if (success) {
        await callApiPayChannelByTypeId()
        await callApiPayChannelAll()
        setIsOpenPopupAddCredit(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onUnitChange = unit => {
    if (unit === 1) {
      const percent = (onlineCharge || 0) / 100
      const _total = (total - discount - serviceDiscount || 0) * percent
      dispatch(getOrderBooking({ charge: _total }))
    } else {
      dispatch(getOrderBooking({ charge: parseInt(onlineCharge || 0) }))
    }
    setUnit(unit)
  }

  const onSelectPayChannel = (index, payChannelId) => {
    if (payChannelId !== -1) {
      // if (splitPayment) {
      _onSelectPayChannel({
        payChannelId: online[index].payChannelId,
        payChannel: online[index].payChannel,
      })
      // }
      setActivePayChannel(index)
    }
  }

  return (
    <Container>
      <TextLabel>{t('pleaseSelectTheE-WalletPaymentMethod')}</TextLabel>
      <GridBox>
        {online.map(({ payChannelId, payChannel, icon }, index) => (
          <Box
            key={index}
            border={
              payChannelId === -1
                ? '1px dashed #E4E8EB'
                : activePayChannel === index
                ? '2px solid #8c56d9'
                : ''
            }
            backgroundColor={payChannel ? '' : '#F8F9FB'}
            onClick={() => onSelectPayChannel(index, payChannelId)}
          >
            {icon ? (
              <img
                src={icon}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  borderRadius: '16px',
                }}
                alt='icon'
              />
            ) : (
              <>
                {payChannel ? (
                  payChannel
                ) : (
                  <>
                    <FlexBox
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      onClick={() => onAddOnlineClick()}
                    >
                      <TextLabel color='#475467'>+</TextLabel>
                      <TextLabel color='#475467'>
                        {t('othersE-wallet')}
                      </TextLabel>
                    </FlexBox>
                  </>
                )}
              </>
            )}
          </Box>
        ))}
      </GridBox>
      {/* <div className='mt-4 mb-3' style={{ display: 'flex' }}>
        {(activePayChannel === 0 || activePayChannel === 1) && (
          <CheckBox
            text='E-Wallet ThaiHand'
            colorText='#475467'
            backgroundColor='linear-gradient(142.04deg, #8C56D9 -7.02%, #53389E 121.47%)'
            onClick={() => setActiveEWallet(1)}
            onChange={() => setActiveEWallet(1)}
            checked={activeEWallet === 1}
          />
        )}
        <div className='ml-3'></div>
        <CheckBox
          text='E-Wallet ของร้าน'
          colorText='#475467'
          backgroundColor='linear-gradient(142.04deg, #8C56D9 -7.02%, #53389E 121.47%)'
          onClick={() => setActiveEWallet(2)}
          onChange={() => setActiveEWallet(2)}
          checked={activeEWallet === 2}
        />
      </div> */}
      <div className='mt-3'></div>
      <DivInputStyle>
        <InputText
          padding='0.4rem 0 0.4rem 0.5rem'
          placeholder={t('charge')}
          onChange={val => onChargeChange(val.target.value)}
          value={onlineCharge}
        />
        <IconStyle>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#E4E8EB',
              borderRadius: '0px 2px 2px 0px',
              width: 'calc(100% + 2.2rem)',
              alignItems: 'center',
              padding: '0.25rem',
            }}
          >
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                borderRadius: '4px',
                fontWeight: `${unit === 1 ? '700' : '500'}`,
                color: `${unit === 1 ? '#000' : '#475467'}`,
                backgroundColor: `${unit === 1 ? '#FFF' : ''}`,
              }}
              onClick={() => onUnitChange(1)}
            >
              %
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                borderRadius: '4px',
                fontWeight: `${unit === 2 ? '700' : '500'}`,
                color: `${unit === 2 ? '#000' : '#475467'}`,
                backgroundColor: `${unit === 2 ? '#FFF' : ''}`,
              }}
              onClick={() => onUnitChange(2)}
            >
              ฿
            </div>
          </div>
        </IconStyle>
      </DivInputStyle>
      {/* {activeEWallet === 1 && (
        <BoxQrCode className='mt-3'>
          <img src={qrCodeImage} style={{ width: '100%' }} />
        </BoxQrCode>
      )} */}
      {isOpenPopupAddCredit && (
        <PopupAddOnline handleClose={onAddOnlineClick} onSave={onSaveClick} />
      )}
    </Container>
  )
}

export default Online
