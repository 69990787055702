import React, { useState } from 'react'

import Body from './Body'
import Head from './Head'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  const [create, setCreate] = useState(false)

  const onCreateClick = () => {
    setCreate(!create)
  }

  const handleClose = () => {
    setCreate(false)
  }

  return (
    <Page>
      <Head onCreateClick={onCreateClick} />
      <Body create={create} handleClose={handleClose} />
    </Page>
  )
}

export default Main
