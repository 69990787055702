export const GET_BUTTON_SETTING_STAFF_TYPE_HEAD =
  'GET_BUTTON_SETTING_STAFF_TYPE_HEAD'

export const getButtonSettingStaffTypeHead = data => {
  return {
    type: GET_BUTTON_SETTING_STAFF_TYPE_HEAD,
    payload: { data },
  }
}

export const GET_BUTTON_SETTING_STAFF_HEAD = 'GET_BUTTON_SETTING_STAFF_HEAD'

export const getButtonSettingStaffHead = data => {
  return {
    type: GET_BUTTON_SETTING_STAFF_HEAD,
    payload: { data },
  }
}

export const CLEAR_BUTTON = 'CLEAR_BUTTON'

export const clearButton = data => {
  return {
    type: CLEAR_BUTTON,
    payload: { data },
  }
}
