import React from 'react'
import styled, { CSSProperties } from 'styled-components'

interface UploadImageIconProps extends CSSProperties {
  disabled?: string
  size?: string
  colorImage?: string
  colorCircle?: string
  // onClick: () => void
}

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  &:hover {
    user-select: none;
  }
`
const UploadImageIcon = ({
  size,
  colorImage,
  colorCircle,
}: // onClick,
UploadImageIconProps) => (
  <SVG
    // onClick={onClick}
    width={size || '144'}
    height={size || '145'}
    viewBox='0 0 144 145'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='71.8396' cy='72.4187' r='71.8006' fill='url(#paintLinear)' />
    <path
      d='M100.38 49.7836H89.5809L88.3234 46.0111C87.7173 44.1794 86.5487 42.5855 84.984 41.4566C83.4193 40.3277 81.5384 39.7213 79.6089 39.7236H64.0713C62.1424 39.7222 60.2622 40.329 58.6981 41.4578C57.134 42.5867 55.9657 44.18 55.3594 46.0111L54.0994 49.7836H43.3C40.8649 49.7863 38.5302 50.7548 36.8083 52.4767C35.0864 54.1986 34.1179 56.5332 34.1152 58.9684V95.9388C34.1206 98.3721 35.0903 100.704 36.8119 102.424C38.5335 104.144 40.8666 105.111 43.3 105.113H100.39C102.824 105.108 105.156 104.138 106.875 102.417C108.595 100.695 109.562 98.3621 109.565 95.9287V58.9583C109.56 56.5249 108.59 54.1929 106.868 52.4731C105.147 50.7534 102.814 49.7863 100.38 49.7836ZM104.535 95.9287C104.534 97.0302 104.096 98.0862 103.317 98.8651C102.538 99.644 101.482 100.082 100.38 100.083H43.3C42.1985 100.082 41.1425 99.644 40.3636 98.8651C39.5847 98.0862 39.1466 97.0302 39.1452 95.9287V58.9583C39.1492 57.8585 39.5885 56.8051 40.3671 56.0284C41.1457 55.2517 42.2002 54.8149 43.3 54.8136H55.9127C56.4403 54.8132 56.9544 54.6469 57.3823 54.3381C57.8102 54.0294 58.1302 53.5939 58.2969 53.0933L60.1303 47.5931C60.4056 46.7657 60.9345 46.0462 61.642 45.5365C62.3494 45.0268 63.1994 44.7529 64.0713 44.7536H79.6089C80.4813 44.7527 81.3317 45.0268 82.0392 45.537C82.7467 46.0472 83.2754 46.7676 83.5499 47.5956L85.3834 53.0933C85.5501 53.5939 85.87 54.0294 86.2979 54.3381C86.7258 54.6469 87.2399 54.8132 87.7676 54.8136H100.38C101.482 54.8149 102.538 55.2531 103.317 56.032C104.096 56.8109 104.534 57.8669 104.535 58.9684V95.9287Z'
      fill={colorImage || 'white'}
    />
    <path
      d='M71.839 57.3286C68.1084 57.3286 64.4615 58.4349 61.3596 60.5075C58.2577 62.5801 55.84 65.5261 54.4124 68.9727C52.9847 72.4194 52.6112 76.212 53.339 79.8709C54.0668 83.5299 55.8633 86.8909 58.5012 89.5288C61.1392 92.1668 64.5002 93.9633 68.1591 94.6911C71.8181 95.4189 75.6107 95.0453 79.0573 93.6177C82.504 92.19 85.4499 89.7724 87.5226 86.6705C89.5952 83.5686 90.7014 79.9217 90.7014 76.1911C90.6961 71.1901 88.7071 66.3954 85.1709 62.8592C81.6346 59.3229 76.84 57.3339 71.839 57.3286ZM71.839 90.0235C69.1032 90.0235 66.4288 89.2123 64.1541 87.6923C61.8794 86.1724 60.1064 84.0121 59.0595 81.4845C58.0125 78.957 57.7386 76.1757 58.2723 73.4925C58.8061 70.8092 60.1235 68.3445 62.058 66.41C63.9925 64.4755 66.4572 63.1581 69.1404 62.6244C71.8237 62.0907 74.6049 62.3646 77.1325 63.4115C79.66 64.4585 81.8203 66.2314 83.3403 68.5062C84.8602 70.7809 85.6715 73.4553 85.6715 76.1911C85.6675 79.8584 84.2088 83.3744 81.6156 85.9677C79.0224 88.5609 75.5064 90.0195 71.839 90.0235Z'
      fill={colorImage || 'white'}
    />
    <path
      d='M96.9896 64.8737C98.3786 64.8737 99.5046 63.7477 99.5046 62.3587C99.5046 60.9698 98.3786 59.8438 96.9896 59.8438C95.6006 59.8438 94.4746 60.9698 94.4746 62.3587C94.4746 63.7477 95.6006 64.8737 96.9896 64.8737Z'
      fill={colorImage || 'white'}
    />
    <defs>
      <linearGradient
        id='paintLinear'
        x1='-67.4033'
        y1='72.4187'
        x2='76.1979'
        y2='207.303'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={colorCircle || '#57557F'} />
        <stop offset='1' stopColor={colorCircle || '#8E759F'} />
      </linearGradient>
    </defs>
  </SVG>
)

export default UploadImageIcon
