import React from 'react'

const OpenIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 7.72414V14.3448C16 14.7838 15.8256 15.2048 15.5152 15.5152C15.2048 15.8256 14.7838 16 14.3448 16H1.65517C1.21619 16 0.795194 15.8256 0.484789 15.5152C0.174384 15.2048 0 14.7838 0 14.3448V1.65517C0 1.21619 0.174384 0.795194 0.484789 0.484789C0.795194 0.174384 1.21619 0 1.65517 0H8.27586C8.49535 0 8.70585 0.0871919 8.86105 0.242394C9.01626 0.397597 9.10345 0.608097 9.10345 0.827586C9.10345 1.04708 9.01626 1.25758 8.86105 1.41278C8.70585 1.56798 8.49535 1.65517 8.27586 1.65517H2.2069C2.0607 1.65561 1.92062 1.71388 1.81725 1.81725C1.71388 1.92062 1.65561 2.0607 1.65517 2.2069V13.7931C1.65561 13.9393 1.71388 14.0794 1.81725 14.1827C1.92062 14.2861 2.0607 14.3444 2.2069 14.3448H13.7931C13.9393 14.3444 14.0794 14.2861 14.1827 14.1827C14.2861 14.0794 14.3444 13.9393 14.3448 13.7931V7.72414C14.3448 7.50465 14.432 7.29415 14.5872 7.13895C14.7424 6.98374 14.9529 6.89655 15.1724 6.89655C15.3919 6.89655 15.6024 6.98374 15.7576 7.13895C15.9128 7.29415 16 7.50465 16 7.72414Z'
        fill='#475467'
      />
      <path
        d='M16.0001 0.551724V4.41379C16.0001 4.63328 15.9129 4.84378 15.7577 4.99898C15.6025 5.15419 15.392 5.24138 15.1725 5.24138C14.953 5.24138 14.7425 5.15419 14.5873 4.99898C14.4321 4.84378 14.3449 4.63328 14.3449 4.41379V2.82483L9.36833 7.80138C9.21145 7.94756 9.00395 8.02715 8.78955 8.02337C8.57515 8.01958 8.37059 7.93273 8.21896 7.7811C8.06733 7.62947 7.98047 7.42491 7.97669 7.21051C7.97291 6.99611 8.05249 6.78861 8.19868 6.63172L13.1752 1.65517H11.5863C11.3668 1.65517 11.1563 1.56798 11.0011 1.41278C10.8459 1.25758 10.7587 1.04708 10.7587 0.827586C10.7587 0.608097 10.8459 0.397597 11.0011 0.242394C11.1563 0.0871919 11.3668 0 11.5863 0H15.4483C15.5945 0.000436614 15.7346 0.0587047 15.838 0.162079C15.9414 0.265452 15.9996 0.405532 16.0001 0.551724Z'
        fill='#475467'
      />
    </svg>
  )
}

export default OpenIcon
