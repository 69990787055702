import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import api from '../../api'
import Button2 from '../../atoms/Button2'
import EyeCloseIcon from '../../atoms/EyeCloseIcon'
import EyeIcon from '../../atoms/EyeIcon'
import InputText from '../../atoms/InputText'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import SuccessIcon2 from '../../atoms/SuccessIcon2'

import { FORGET_PASSWORD_URL } from '../../routes'

const CardPassword = styled.div`
  /* background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  border-radius: 24px; */
  padding: 1rem 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const IconStyle = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
`
const DivInputStyle = styled.div`
  position: relative;
  width: 100%;
`
const Circle = styled.div`
  border-radius: 50%;
  background-color: #db2d1e;
  width: 15px;
  height: 15px;
  position: relative;
  ::before {
    display: block;
    position: absolute;
    content: '!';
    border-radius: 50%;
    color: white;
    font-weight: normal;
    left: 6px;
    top: -4px;
  }
`
const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: none;
  }
`

const PopupAddPassword = ({
  handleClose,
  currentIdEdit = -1,
  currentDataEdit = '',
  onPasswordChange: _onPasswordChange,
}) => {
  const [seePassword, setSeePassword] = useState(false)
  const [seeConfirmPassword, setSeeConfirmPassword] = useState(false)
  const [seeOldPassword, setSeeOldPassword] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')
  const [wrongOldPassword, setWrongOldPassword] = useState(false)
  const [checkVerifyPassword, setCheckVerifyPassword] = useState(true)
  const [checkPasswordLength, setCheckPasswordLength] = useState(true)
  const [success, setSuccess] = useState(false)

  const onSaveClick = async () => {
    try {
      if (password.length < 8) {
        setCheckPasswordLength(false)
      } else if (currentIdEdit !== -1) {
        await checkOldPassword()
      } else {
        if (!password && !verifyPassword) {
          return false
        } else if (password !== verifyPassword) {
          setCheckVerifyPassword(false)
        } else {
          _onPasswordChange(password, verifyPassword, '')
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const checkOldPassword = async () => {
    try {
      const userLogin = {
        phoneNumber: currentDataEdit.phoneNumber,
        password: oldPassword,
        typeLogin: 'password',
      }
      const { token, success } = await api.login(userLogin)
      if (success) {
        // onPasswordChange(oldPassword, password, verifyPassword, token)
        onPasswordChange(token)
      }
    } catch (err) {
      setWrongOldPassword(true)
      console.log(err)
    }
  }

  const onPasswordChange = async token => {
    try {
      const body = {
        currentPassword: oldPassword,
        newPassword: password,
        verifyPassword,
        token,
      }
      const { success } = await api.renewPassword(body)
      if (success) {
        setSuccess(success)
        setTimeout(() => {
          setSuccess(false)
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <PopupBox width='calc(100%)' height='100%' left='0rem' top='0'>
      <PopupInsideBox width='40%' marginTop='0' padding='0' height='auto'>
        <CardPassword>
          <TextLabel fontWeight='500' fontSize='1.25rem'>
            แก้ไขรหัสผ่าน
          </TextLabel>
          <Box>
            {currentIdEdit !== -1 && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextLabel fontWeight='500'>รหัสเดิม*</TextLabel>
                  <LinkStyled to={{ pathname: FORGET_PASSWORD_URL, state: {} }}>
                    <TextLabel color='#8C56D9' fontSize='0.75rem'>
                      ลืมรหัสผ่าน
                    </TextLabel>
                  </LinkStyled>
                </div>
                <DivInputStyle>
                  <InputText
                    type={seeOldPassword ? 'text' : 'password'}
                    padding='0.25rem 0 0.25rem 1rem'
                    border={wrongOldPassword ? '2px solid #DB2D1E' : ''}
                    onChange={val => {
                      setOldPassword(val.target.value)
                      setWrongOldPassword(false)
                    }}
                  />
                  <IconStyle onClick={() => setSeeOldPassword(!seeOldPassword)}>
                    {seeOldPassword ? (
                      <EyeCloseIcon size='20' />
                    ) : (
                      <EyeIcon size='20' />
                    )}
                  </IconStyle>
                </DivInputStyle>
                {wrongOldPassword && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <Circle></Circle>
                    <TextLabel color='#DB2D1E' fontWeight='500'>
                      รหัสผ่านเดิมไม่ถูกต้อง
                    </TextLabel>
                  </div>
                )}
                <div className='mt-3'></div>
              </div>
            )}

            <TextLabel fontWeight='500'>รหัสผ่านใหม่(อย่างน้อย8ตัว)*</TextLabel>
            <DivInputStyle>
              <InputText
                type={seePassword ? 'text' : 'password'}
                padding='0.25rem 0 0.25rem 1rem'
                border={
                  !checkVerifyPassword || !checkPasswordLength
                    ? '2px solid #DB2D1E'
                    : ''
                }
                onChange={val => {
                  setPassword(val.target.value)
                  setCheckVerifyPassword(true)
                }}
              />
              <IconStyle onClick={() => setSeePassword(!seePassword)}>
                {seePassword ? (
                  <EyeCloseIcon size='20' />
                ) : (
                  <EyeIcon size='20' />
                )}
              </IconStyle>
            </DivInputStyle>
            <div className='mt-3'></div>
            <TextLabel fontWeight='500'>ยืนยันรหัส*</TextLabel>
            <DivInputStyle>
              <InputText
                type={seeConfirmPassword ? 'text' : 'password'}
                padding='0.25rem 0 0.25rem 1rem'
                border={
                  !checkVerifyPassword || !checkPasswordLength
                    ? '2px solid #DB2D1E'
                    : ''
                }
                onChange={val => {
                  setVerifyPassword(val.target.value)
                  setCheckVerifyPassword(true)
                }}
              />
              <IconStyle
                onClick={() => setSeeConfirmPassword(!seeConfirmPassword)}
              >
                {seeConfirmPassword ? (
                  <EyeCloseIcon size='20' />
                ) : (
                  <EyeIcon size='20' />
                )}
              </IconStyle>
            </DivInputStyle>
            {!checkVerifyPassword && (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              >
                <Circle></Circle>
                <TextLabel color='#DB2D1E' fontWeight='500'>
                  รหัสผ่านไม่ตรงกัน
                </TextLabel>
              </div>
            )}
            {!checkPasswordLength && (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              >
                <Circle></Circle>
                <TextLabel color='#DB2D1E' fontWeight='500'>
                  รหัสมีความยาวไม่ถึง 8 ตัว
                </TextLabel>
              </div>
            )}
          </Box>
          <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
            <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
              <Button2
                color='#98A1B2'
                border='2px solid #98A1B2'
                backgroundColor='white'
                backgroundColorHover='#E5E7EC'
                fontWeight='600'
                onClick={() => handleClose()}
              >
                ยกเลิก
              </Button2>
            </div>
            <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
              <Button2
                border='2px solid #53389E'
                color='#FFFFFF'
                backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                fontWeight='600'
                onClick={() => onSaveClick()}
              >
                ยืนยัน
              </Button2>
            </div>
          </div>
        </CardPassword>
        {success && <SuccessIcon2 position='absolute' />}
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddPassword
