import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { RxDragHandleDots2 } from 'react-icons/rx'
import styled from 'styled-components'
import { IDndGroup } from './type'

const StyledContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
gap: 4px;
`

const StyledHandle = styled.div`
width: fit-content;
`

const StyledDragItem = styled.div`
padding: 12px 12px;
display: flex;
align-items: center;
gap: 0.5rem;
border: 2px solid var(--muted);
border-radius: var(--radius);
background-color: var(--popup);
`

export interface DragItemProps {
    items: any[]
    id: string
}

export const StyledSkeleton = styled.div`
padding: 12px 12px;
display: flex;
align-items: center;
gap: 0.5rem;
border: 2px solid var(--muted);
border-radius: var(--radius);
background-color: var(--popup);
`

const DragItem = ({ items }: DragItemProps) => {
  return (
    <StyledContainer>
      {items.map((item: any, i: number) => (
        <Draggable
          key={item.id}
          draggableId={`${item.id}`}
          index={item.index}
        >
          {provided => (
            <StyledDragItem
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
                <StyledHandle
                {...provided.dragHandleProps}
                >
                    <RxDragHandleDots2 size={32} color='gray' />
                </StyledHandle>
                {item.item}
            </StyledDragItem>
          )}
        </Draggable>
      ))}
    </StyledContainer>
  )
}

export default DragItem
