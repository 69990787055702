import Cookies from 'universal-cookie'

const isServer = ['production', 'staging', 'test'].includes(
  process.env.REACT_APP_STAGE || ''
)

const domain = {
  domain: isServer ? 'thaihandpos.com' : undefined,
  path: '/',
  secure: isServer,
  expires: new Date(Number(new Date()) + 7776000000),
}

const cookies = new Cookies()

const cookieService = {
  // Set cookie string by name
  set(cookieName: string, str: string) {
    cookies.set(cookieName, str, domain)
  },

  // Get cookie string by name
  get(cookieName: string) {
    return cookies.get(cookieName) || null
  },

  // Remove cookie by name
  remove(cookieName: string) {
    cookies.remove(cookieName, domain)
  },
}

export default cookieService
