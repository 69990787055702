import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Scrollbar from '../atoms/Scrollbar'
import InputText from '../atoms/InputText'
import SearchIcon from '../atoms/SearchIcon'

import useOnClickOutside from '../hook/useOnClickOutside'

const Container = styled.div`
  position: relative;
`

const OptionContainer = styled.div`
  display: inline-block;
  border: 2px solid #e4e8eb;
  border-radius: 8px;
  background: #fff;
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  z-index: 3;
  min-height: auto;
  max-height: 240px;
  width: 100%;
  position: absolute;
  top: 2.5rem;
`

const OptionItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #475467;
  background: ${props => props.backgroundColor || 'none'};
  cursor: pointer;
  transition: all 0.5s;
  :first-child {
    border-radius: 8px 8px 0 0;
  }
  :last-child {
    border-radius: 0 0 8px 8px;
  }
  &:only-child {
    border-radius: 8px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }
  :hover {
    cursor: pointer;
    background-color: #e4e8eb;
  }
  ::selection {
    color: #475467;
    background-color: #e4e8eb;
  }
`

const SearchBox = ({ options, render, onSelect, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const inputRef = useRef(null)

  useOnClickOutside(inputRef, () => {
    setIsOpen(false)
  })

  useEffect(() => {
    if (options.length) {
      setIsOpen(true)
    }
  }, [options])

  const onFocus = () => {
    setIsOpen(true)
  }

  return (
    <Container ref={inputRef}>
      <InputText
        icon={<SearchIcon size='15' />}
        placeholder='ชื่อแบรนด์'
        onFocus={onFocus}
        onChange={onChange}
      />
      {isOpen && !!options.length && (
        <OptionContainer>
          <Scrollbar paddingRight='0rem'>
            {options.map((option, idx) => (
              <OptionItemContainer
                key={idx}
                onClick={() => {
                  onSelect(option, idx)
                  setIsOpen(false)
                }}
              >
                {render(option)}
              </OptionItemContainer>
            ))}
          </Scrollbar>
        </OptionContainer>
      )}
    </Container>
  )
}

export default SearchBox
