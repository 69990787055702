import dayjs from 'dayjs'

// @ts-ignore
import api from './api'
import auth from './auth'
import User from './redux/models/User'

const utils = {
  readImage: (file: File): Promise<{ image: string; errorMessage: string }> => {
    return new Promise(resolve => {
      if ('|png|jpeg'.indexOf(file.type.split('/')[1]) === -1) {
        resolve({ image: '', errorMessage: 'Invalid File Type' })
      }
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        resolve({ image: fileReader.result as string, errorMessage: '' })
      }
      fileReader.readAsDataURL(file)
    })
  },

  isFileExceedLimit: (file: File, limit = 2) => {
    return file.size / 1024 / 1024 > limit
  },

  roundToFixed: (value: number, decimals = 1) => {
    return Number(
      `${Math.round(
        Number(`${value.toString().replace(/e.+/g, '')}e${decimals}`)
      )}e-${decimals}`
    ).toFixed(decimals)
  },

  formatNumber: (number: number, decimals = 0, withCurrency = false) => {
    const parts = utils.roundToFixed(number || 0, decimals).split('.')

    if (!number) {
      return 0
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `${parts.join('.')}${withCurrency ? '฿' : ''}`
  },

  formatTime: (time: string | number) => {
    const _time = time + ''
    return _time.padStart(2, '0')
  },

  formatPhoneNumber: (tel: string) => {
    if (!tel) {
      return ''
    }
    tel = tel.replace(/[^\d]/g, '')
    if (tel.length === 10) {
      return tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    } else if (tel.length === 9) {
      return tel.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3')
    }
    return tel
  },

  checkNumber: (value: string) => {
    // var reg = /^\d+$/
    var reg = /^[0-9]*\.?[0-9]*$/
    return reg.test(value)
  },

  formatBookingCode: (value: string) => {
    return value.replace(/(\d{5})/, '$1-')
  },

  cssVariablesFallback: (value: string, fallback: string) => {
    return window.CSS && window.CSS.supports('height', value) ? value : fallback
  },

  minHeight: (noFooter: boolean) => {
    const vh = utils.cssVariablesFallback('var(--vh)', '100vh')
    const header = utils.cssVariablesFallback('var(--top-bar-height)', '50px')
    const footer = noFooter
      ? '0px'
      : utils.cssVariablesFallback('var(--footer-min-height)', '205px')

    return `calc(${vh} - ${header} - ${footer})`
  },

  currentUser: (user: User) => {
    if (!user || !user.types.length) return {}

    if (user.types[0].typeId === 0) {
      return { storeId: auth.getStoreId(), type: 'Admin', typeId: 0 }
    } else {
      const findUserType = user.types.find(
        t => t.storeId === auth.getStoreId() || ''
      )
      if (findUserType) {
        return findUserType
      } else {
        return {}
      }
    }
  },

  getArrayInRange: (
    start: number,
    end: number,
    reverse = false,
    zero = false,
    inc = 1
  ) => {
    const arr = []
    const initial = !reverse ? start : end
    const condition = (i: number) => (!reverse ? i <= end : i >= start)
    const step = !reverse ? inc : -inc
    for (let i = initial; condition(i); i += step) {
      if (zero) {
        const j = i.toString()
        if (j.length === 1) arr.push(`0${j}`)
        else arr.push(j)
      } else arr.push(i)
    }
    return arr
  },

  getPrice: (
    timePrice: any,
    bookingTypeId: number,
    bookingChannelId: number
  ) => {
    return (
      (bookingTypeId === 3
        ? timePrice?.agencyPriceMap?.[`${bookingChannelId}`]
        : '') || timePrice.price
    )
  },

  isInRange: (val: string | number, min = 0, max?: number) => {
    if (!val) {
      return true
    }
    const num = typeof val === 'string' ? parseInt(val) : val
    if (max) {
      return min <= num && num <= max
    } else {
      return min <= num
    }
  },

  convertTime: (time: { hour: string | number; minute: string | number }) => {
    return +time.hour * 60 + +time.minute
  },

  convertTimeToHourMinute: (time: number) => {
    return { hour: Math.floor(time / 60) + '', minute: (time % 60) + '' }
  },

  getDateByStoreDateTimeV2: async (date = new Date()) => {
    try {
      const { data: storeDateTime } = await api.getStoreDateTimeByDate(
        new Date(date).getDay() === 0 ? 6 : new Date(date).getDay() - 1,
        new Date(date)
      )

      const {
        openTime: { hour: openHour, minute: openMinute },
        closeTime: { hour: closeHour, minute: closeMinute },
      } = storeDateTime[0]

      const baseDate = dayjs(date).format('YYYY-MM-DD')

      const openDate = dayjs(
        `${baseDate}T${openHour.padStart(2, '0')}:${openMinute.padStart(
          2,
          '0'
        )}:00+07:00`
      )
      let closeDate = dayjs(
        `${baseDate}T${closeHour.padStart(2, '0')}:${closeMinute.padStart(
          2,
          '0'
        )}:00+07:00`
      )

      if (dayjs(closeDate).isBefore(openDate)) {
        closeDate = dayjs(closeDate).add(1, 'd')
      }

      // ('2023-08-09 09:00')
      // ('2023-08-10 03:00')

      // ('2023-08-10 01:00')

      // ('2023-08-10 09:00')
      // ('2023-08-11 03:00')

      if (
        dayjs(date).isSameOrAfter(openDate) &&
        dayjs(date).isSameOrBefore(closeDate)
      ) {
        return date
      }

      return dayjs(date).subtract(1, 'd').toDate()
    } catch (err) {
      console.log(err)
    }
  },

  checkIsSameCurrentDate: async (currentDate: Date, date: Date) => {
    try {
      const { data, success } = await api.getStoreDateTimeByDateV2(
        new Date(date)
      )
      if (success) {
        if (dayjs(currentDate).isSame(new Date(data.openDateTime), 'date')) {
          return true
        } else {
          return false
        }
      }
    } catch (err) {
      console.log(err)
    }
  },

  formatDate: (date: string | Date, formatDate: string) => {
    return dayjs(date).format(formatDate)
  },

  deepClone: <T>(data: T): T => {
    try {
      if (!data) return data
      return JSON.parse(JSON.stringify(data))
    } catch (err) {
      console.log(err)
      return data
    }
  },
}

export default utils
