import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CalendarIcon } from '@/components/atoms/icons'
import { DownloadIcon } from '@/components/atoms/icons/DownloadIcon'

// @ts-ignore
import Calendar2 from '../datePicker/Calendar2'

import TimeIcon from '../../../atoms/TimeIcon'

// Types
type ManipulateType = 'days' | 'months' | 'years' | 'isoWeek'

interface FilterDateProps {
  date: { startDate: Date; endDate: Date }
  onDateChange: (startDate: Date, endDate: Date, type: number) => void
  onDownloadClick?: () => void
  className?: string
  onlyRange?: boolean
}

// Styled Components
const Container = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
`

const Box = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`

const BoxCalendarStyle = styled.div<{ $isActive: boolean }>`
  display: flex;
  background-color: ${props => (props.$isActive ? '#F3EEFB' : '#FFF')};
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 0.5rem;
  gap: 8px;
  font-weight: 500;
  color: ${props => (props.$isActive ? 'var(--primary)' : '#000')};
  align-items: center;
  cursor: pointer;
  path {
    color: ${props => (props.$isActive ? 'var(--primary)' : '#000')};
    fill: ${props => (props.$isActive ? 'var(--primary)' : '#000')};
  }
`

const BoxDay = styled.div<{ $isActive: boolean }>`
  display: flex;
  background-color: ${props => (props.$isActive ? '#F3EEFB' : '#FFF')};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: ${props => (props.$isActive ? 'var(--primary)' : '#000')};
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f3eefb;
    color: #53389e;
  }
`

const Line = styled.div`
  color: #d9d9d9;
  border: 1px solid #d9d9d9;
`

const ExportButton = styled.button`
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: linear-gradient(221.86deg, #8c56d9 2.71%, #53389e 97.79%);
  cursor: pointer;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  border: none;
`

const CalendarWrapper = styled.div`
  position: absolute;
  padding: 0.5rem 1rem;
  background: #fff;
  border-radius: 8px;
  top: 130%;
  left: 1px;
  width: 250px;
  z-index: 2;
  border: 1px solid #e4e8eb;
  box-shadow: 0px 16px 24px rgba(152, 161, 178, 0.08);
`

// Sub-components
const DateBox: React.FC<{
  date: Date
  isActive: boolean
  onClick: () => void
}> = ({ date, isActive, onClick }) => (
  <BoxCalendarStyle $isActive={isActive} onClick={onClick}>
    {dayjs(date).format('DD MMMM YYYY')}
    <CalendarIcon size={18} />
  </BoxCalendarStyle>
)

const CalendarPopup: React.FC<{
  date: Date
  onDateChange: (date: Date) => void
}> = ({ date, onDateChange }) => (
  <CalendarWrapper>
    <Calendar2
      date={date}
      selectedDate={date}
      onDateChange={onDateChange}
      highlightColor='#8C56D9'
      backwardable={true}
      fontwardable={true}
    />
  </CalendarWrapper>
)

const FilterButton: React.FC<{
  name: string
  type: ManipulateType
  isActive: boolean
  onClick: () => void
}> = ({ name, type, isActive, onClick }) => (
  <BoxDay $isActive={isActive} onClick={onClick}>
    <TimeIcon size='15' color='#000' />
    &nbsp; {name}
  </BoxDay>
)

// Main component
const FilterDate: React.FC<FilterDateProps> = ({
  date,
  onDateChange,
  onDownloadClick,
  className,
  onlyRange,
}) => {
  const [openCalendar, setOpenCalendar] = useState<number>(-1)
  const [selectedType, setSelectedType] = useState<ManipulateType | ''>('')
  const { t } = useTranslation()

  const { startDate, endDate } = date

  const filterDay: { name: string; type: ManipulateType }[] = [
    { name: t('thisN', { n: t('day') }), type: 'days' },
    { name: t('thisN', { n: t('week') }), type: 'isoWeek' },
    { name: t('thisN', { n: t('month') }), type: 'months' },
    { name: t('thisN', { n: t('year') }), type: 'years' },
  ]

  const handleOpenCalendar = (type: number) => {
    setOpenCalendar(prev => (prev === type ? -1 : type))
  }

  const handleDateChange = (newDate: Date) => {
    setSelectedType('')
    setOpenCalendar(-1)

    let newStartDate = startDate
    let newEndDate = endDate

    if (openCalendar === 1) {
      newStartDate = dayjs(newDate).isAfter(endDate) ? endDate : newDate
      newEndDate = dayjs(newDate).isAfter(endDate) ? newDate : endDate
    } else if (openCalendar === 2) {
      newStartDate = dayjs(newDate).isBefore(startDate) ? newDate : startDate
      newEndDate = dayjs(newDate).isBefore(startDate) ? startDate : newDate
    }

    onDateChange(newStartDate, newEndDate, 3)
  }

  const handleSelectType = (type: ManipulateType) => {
    setSelectedType(type)
    onDateChange(
      dayjs().startOf(type).toDate(),
      dayjs().endOf(type).toDate(),
      -1
    )
    setOpenCalendar(-1)
  }

  return (
    <Container className={className}>
      <Box>
        <div style={{ position: 'relative' }}>
          <DateBox
            date={startDate}
            isActive={openCalendar === 1}
            onClick={() => handleOpenCalendar(1)}
          />
          {openCalendar === 1 && (
            <CalendarPopup date={startDate} onDateChange={handleDateChange} />
          )}
        </div>
        -
        <div style={{ position: 'relative' }}>
          <DateBox
            date={endDate}
            isActive={openCalendar === 2}
            onClick={() => handleOpenCalendar(2)}
          />
          {openCalendar === 2 && (
            <CalendarPopup date={endDate} onDateChange={handleDateChange} />
          )}
        </div>
        {!onlyRange && (
          <>
            <Line />
            {filterDay.map(({ name, type }) => (
              <FilterButton
                key={type}
                name={name}
                type={type}
                isActive={selectedType === type}
                onClick={() => handleSelectType(type)}
              />
            ))}
          </>
        )}
      </Box>
      {onDownloadClick && (
        <ExportButton onClick={onDownloadClick}>
          <DownloadIcon />
          {t('downloadData')}
        </ExportButton>
      )}
    </Container>
  )
}

export default FilterDate
