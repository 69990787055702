import React from 'react'
import styled from 'styled-components'

import CustomerDetail from './CustomerDetail'
import CustomerOverview from './CustomerOverview'
import useBody from './useBody'

//@ts-ignore
import PopupAddCustomer from '../popups/PopupAddCustomer'

//@ts-ignore
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

interface BodyProps {
  search: string
}

const Body = ({ search }: BodyProps) => {
  const {
    customer,
    customers,
    customerBookingHistories,
    staffOrders,
    page,
    hasMoreCustomer,
    editCustomer,
    customerPackages,
    fetchData,
    onCustomerClick,
    handleClose,
    setEditCustomer,
    onGoBackClick,
    callApiCustomerPackage,
  } = useBody({ search })

  return (
    <Container>
      {customer._id !== -1 ? (
        <Scrollbar id='CustomerDetail'>
          <CustomerDetail
            customer={customer}
            customerBookingHistories={customerBookingHistories}
            customerPackages={customerPackages}
            updateCustomerPackage={callApiCustomerPackage}
            onGoBackClick={onGoBackClick}
            onEditCustomer={() => setEditCustomer(true)}
          />
        </Scrollbar>
      ) : (
        <CustomerOverview
          customers={customers}
          hasMoreCustomer={hasMoreCustomer}
          onCustomerClick={onCustomerClick}
          fetchData={fetchData}
        />
      )}
      {editCustomer && (
        <PopupAddCustomer
          handleClose={handleClose}
          currentCustomer={customer}
        />
      )}
    </Container>
  )
}

export default Body
