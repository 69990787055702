import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BahtIcon2 from '../../atoms/BahtIcon2'
import CreditIcon from '../../atoms/CreditIcon'
import OnlineIcon from '../../atoms/OnlineIcon'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import TransferIcon from '../../atoms/TransferIcon'
import InputText from '../../atoms/InputText'
import Button2 from '../../atoms/Button2'
import utils from '../../utils'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 1.25rem;
`
const IconStyle = styled.div`
  position: absolute;
  right: ${props => props.right || '0.5rem'};
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
`
const DivInputStyle = styled.div`
  position: relative;
  padding: 0;
  height: fit-content;
`

const PopupAddTip = ({
  handleClose,
  onAddTipClick,
  activeTipStaff = {
    staffId: -1,
    staffImage: '',
    staffName: '',
    tip: '',
  },
}) => {
  const { t } = useTranslation()
  const [tip, setTip] = useState('')
  const payChannelTypes = [
    {
      payChannelTypeId: 1,
      payChannelType: 'เงินสด',
      icon: <BahtIcon2 size='20' />,
    },
    {
      payChannelTypeId: 2,
      payChannelType: 'เครดิต',
      icon: <CreditIcon size='20' />,
    },
    {
      payChannelTypeId: 3,
      payChannelType: 'โอนเงิน',
      icon: <TransferIcon size='20' />,
    },
    {
      payChannelTypeId: 4,
      payChannelType: 'ออนไลน์',
      icon: <OnlineIcon size='20' />,
    },
  ]

  useEffect(() => {
    setTip(activeTipStaff.tip === 0 ? '' : activeTipStaff.tip || '')
  }, [activeTipStaff])

  const onTipChange = value => {
    if (utils.checkNumber(value)) {
      setTip(value)
    }
  }

  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='40%' marginTop='0' padding='0' height='auto'>
        <Container>
          <TextLabel fontSize='1.25rem' fontWeight='500'>
            {activeTipStaff.staffId === -1 ? 'ทิปรวม' : t('tip')}
          </TextLabel>
          {activeTipStaff.staffId !== -1 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <Image background={activeTipStaff.image}></Image> */}
              <TextLabel paddingLeft='0.5rem'>
                {activeTipStaff.staffName}
              </TextLabel>
            </div>
          )}

          <div className='mt-2'></div>

          <DivInputStyle className='mt-3'>
            <InputText
              placeholder={t('tip')}
              padding='0.75rem 0 0.75rem 0.75rem'
              background='#FFF'
              border='1px solid #E4E8EB'
              colorPlaceholder='#C8CDD7'
              fontSize='0.875rem'
              fontWeight='500'
              borderRadius='4px'
              value={tip}
              onChange={val => onTipChange(val.target.value)}
            />
            {/* <IconStyle right='5rem'>
              <div
                style={{ border: '1px solid #E4E8EB', height: '1.75rem' }}
              ></div>
            </IconStyle> */}
            {/* <IconStyle right='2rem'>
              <TextLabel>บาท</TextLabel>
            </IconStyle> */}
          </DivInputStyle>
          <div className='mb-2 mt-4' style={{ width: '100%', display: 'flex' }}>
            <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
              <Button2
                color='#98A1B2'
                border='2px solid #98A1B2'
                backgroundColor='white'
                backgroundColorHover='#E5E7EC'
                fontWeight='600'
                onClick={() => handleClose()}
              >
                {t('cancel')}
              </Button2>
            </div>
            <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
              <Button2
                border='2px solid #53389E'
                color='#FFFFFF'
                backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                fontWeight='600'
                onClick={() => onAddTipClick(tip, payChannelTypes[0])}
              >
                {t('confirm')}
              </Button2>
            </div>
          </div>
        </Container>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddTip
