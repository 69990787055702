import React from "react"
import styled from "styled-components"

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  &:hover {
    color: ${props => (props.color = "#fff")};
    user-select: none;
  }
`

const AgencyIcon = ({ size, color }) => (
  <SVG
    width={size || "29"}
    height={size || "33"}
    viewBox='0 0 29 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M28.05 15.6723C28.05 13.9775 27.2624 12.4591 26.0248 11.4261C25.6997 5.61628 20.6148 0.95 14.4961 0.95C8.38452 0.95 3.29969 5.61661 2.97461 11.4267C1.7373 12.4594 0.95 13.9778 0.95 15.6723C0.95 18.7808 3.58645 21.3057 6.82265 21.3057C7.25589 21.3057 7.61093 20.9685 7.61093 20.5475L7.61125 10.7978C7.61125 10.5957 7.52784 10.4031 7.37973 10.2611C7.23171 10.1191 7.03119 10.0396 6.82297 10.0396C6.06427 10.0396 5.33984 10.1802 4.67367 10.4325C5.47287 5.93343 9.60064 2.46602 14.5036 2.46602C19.3997 2.46602 23.5268 5.93341 24.326 10.4325C23.6599 10.1802 22.9357 10.0396 22.1773 10.0396C21.7441 10.0396 21.3891 10.3768 21.3891 10.7978V20.5475C21.3891 20.9685 21.7441 21.3057 22.1773 21.3057C22.937 21.3057 23.6627 21.1648 24.3296 20.9116C23.6811 24.5878 20.8111 27.6271 17.0025 28.5677C16.5407 27.7742 15.5932 27.2273 14.4964 27.2273C12.9547 27.2273 11.687 28.302 11.687 29.6387C11.687 30.9753 12.9547 32.05 14.4964 32.05C15.869 32.05 17.0145 31.1969 17.2523 30.067C22.159 28.9189 25.7599 24.7724 26.0276 19.9167C27.2636 18.8834 28.05 17.366 28.05 15.6723ZM6.03406 19.7201C4.03948 19.3639 2.52688 17.6829 2.52688 15.6723C2.52688 13.6618 4.03948 11.9814 6.03437 11.6252L6.03406 19.7201ZM14.4964 30.534C14.1525 30.534 13.8425 30.4308 13.6196 30.2665C13.3967 30.1023 13.2638 29.8796 13.2638 29.6387C13.2638 29.3977 13.3967 29.1751 13.6196 29.0108C13.8425 28.8465 14.1525 28.7433 14.4964 28.7433C14.8403 28.7433 15.1502 28.8465 15.3729 29.0108C15.5957 29.175 15.7286 29.3977 15.7286 29.6387C15.7286 29.8796 15.5957 30.1023 15.3729 30.2665C15.1502 30.4308 14.8403 30.534 14.4964 30.534ZM22.9659 11.6252C24.9605 11.9814 26.4731 13.6618 26.4731 15.6723C26.4731 17.6829 24.9605 19.3639 22.9659 19.7201V11.6252Z'
      fill={color || "#4E4E72"}
      stroke={color || "#4E4E72"}
      strokeWidth='0.1'
    />
  </SVG>
)

export default AgencyIcon
