import React from 'react'
import { IconBaseProps } from 'react-icons'

const ArrowCalculator = ({ size, color }: IconBaseProps) => (
  <svg
    width={size || '51'}
    height={size || '36'}
    viewBox='0 0 51 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_771_1325)'>
      <path
        d='M43.864 6.01607e-05H16.1522C15.2657 -0.00382933 14.3884 0.18092 13.5787 0.542042C12.769 0.903164 12.0454 1.4324 11.4559 2.09461L1.58859 13.1892C0.595754 14.302 0.032898 15.7328 0.00139749 17.2238C-0.030103 18.7148 0.471822 20.1681 1.41677 21.3219L11.554 33.7092C12.1391 34.4202 12.8733 34.9939 13.7046 35.3898C14.536 35.7857 15.4442 35.994 16.365 36.0001H43.864C44.6863 36.0011 45.5007 35.84 46.2606 35.5258C47.0204 35.2116 47.7109 34.7506 48.2923 34.1692C48.8737 33.5878 49.3347 32.8974 49.6489 32.1375C49.9631 31.3776 50.1242 30.5632 50.1231 29.741V6.25915C50.1242 5.43689 49.9631 4.6225 49.6489 3.86263C49.3347 3.10275 48.8737 2.41233 48.2923 1.8309C47.7109 1.24948 47.0204 0.788479 46.2606 0.474312C45.5007 0.160144 44.6863 -0.0010168 43.864 6.01607e-05ZM46.8504 29.741C46.8482 30.5323 46.5329 31.2907 45.9733 31.8503C45.4137 32.4098 44.6554 32.7252 43.864 32.7273H16.365C15.9231 32.7272 15.4869 32.6287 15.0878 32.4391C14.6887 32.2495 14.3369 31.9734 14.0577 31.631L3.95314 19.2437C3.49804 18.6955 3.2558 18.0017 3.27083 17.2893C3.28586 16.577 3.55715 15.894 4.03496 15.3655L13.9104 4.27097C14.1914 3.95446 14.5368 3.70166 14.9234 3.52951C15.31 3.35736 15.729 3.26983 16.1522 3.27279H43.864C44.6554 3.27495 45.4137 3.59027 45.9733 4.14986C46.5329 4.70944 46.8482 5.46778 46.8504 6.25915V29.741Z'
        fill={color || '#23272A'}
      />
      <path
        d='M34.5108 11.1205C34.3588 10.9684 34.1783 10.8477 33.9797 10.7654C33.781 10.683 33.5681 10.6406 33.353 10.6406C33.138 10.6406 32.9251 10.683 32.7264 10.7654C32.5278 10.8477 32.3473 10.9684 32.1953 11.1205L28.2353 15.0724L24.2835 11.1205C23.9727 10.8379 23.5652 10.6857 23.1452 10.6953C22.7253 10.7048 22.3251 10.8756 22.0275 11.1721C21.73 11.4686 21.5579 11.8681 21.5468 12.288C21.5357 12.708 21.6865 13.116 21.968 13.4278L25.9199 17.3878L21.968 21.3478C21.7406 21.5766 21.5858 21.8676 21.5233 22.1841C21.4607 22.5006 21.4931 22.8286 21.6164 23.1267C21.7396 23.4249 21.9483 23.68 22.2162 23.8599C22.484 24.0398 22.799 24.1365 23.1217 24.1378C23.3376 24.138 23.5514 24.0954 23.7508 24.0126C23.9502 23.9297 24.1313 23.8082 24.2835 23.6551L28.2353 19.7033L32.1953 23.6551C32.5008 23.9624 32.9157 24.136 33.349 24.1378C33.5649 24.138 33.7787 24.0954 33.9781 24.0126C34.1775 23.9297 34.3585 23.8082 34.5108 23.6551C34.8155 23.3485 34.9866 22.9338 34.9866 22.5015C34.9866 22.0692 34.8155 21.6544 34.5108 21.3478L30.5508 17.3878L34.5108 13.4278C34.8155 13.1212 34.9866 12.7065 34.9866 12.2742C34.9866 11.8419 34.8155 11.4271 34.5108 11.1205Z'
        fill={color || '#23272A'}
      />
    </g>
    <defs>
      <clipPath id='clip0_771_1325'>
        <rect width='50.1218' height='36' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default ArrowCalculator
