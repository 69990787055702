import React from 'react'

import CardAddService from './index'

//@ts-ignore
import PopupBox from '../../../atoms/Popup'
//@ts-ignore
import PopupInsideBox from '../../../atoms/PopupInside'

import { ProductGroup } from '../../../redux/models/ProductGroup'
import Service from '../../../redux/models/Service'

interface PopupAddServiceProps {
  handleClose: (close: boolean) => void
  serviceGroup: ProductGroup[]
  currentEditId: number
  currentEditData: Service
  agencies: { _id: number; bookingChannel: string }[]
}

const PopupAddService = ({
  handleClose,
  agencies,
  serviceGroup,
  currentEditId,
  currentEditData,
}: PopupAddServiceProps) => {
  return (
    // <div style={{ color: 'red' }}>{y}</div>
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='50%'
        height='auto'
        padding='2rem 1rem 1rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
      >
        <CardAddService
          handleClose={handleClose}
          agencies={agencies}
          serviceGroup={serviceGroup}
          currentEditId={currentEditId}
          currentEditData={currentEditData}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddService
