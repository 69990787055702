export const GET_SALARY = 'GET_SALARY'

export const getSalary = salary => {
  return {
    type: GET_SALARY,
    payload: { salary },
  }
}

export const UPDATE_SALARIES = 'UPDATE_SALARIES'

export const updateSalaries = salaries => {
  return {
    type: UPDATE_SALARIES,
    payload: { salaries },
  }
}

export const UPDATE_OPENS = 'UPDATE_OPENS'

export const updateOpens = opens => {
  return {
    type: UPDATE_OPENS,
    payload: { opens },
  }
}
