import React from 'react'

const LogoutIcon = ({ size, color = '' }) => {
  return (
    <svg
      width={size || '41'}
      height={size || '40'}
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_164_626)'>
        <path
          d='M31.3775 7.37961V8.06748C31.3775 8.60458 31.5848 9.10399 31.9428 9.50918C34.5718 12.4962 36.07 16.5009 35.7685 20.8449C35.2314 28.6093 28.7956 34.9038 21.0123 35.2712C12.0983 35.6953 4.71078 28.5622 4.71078 19.7424C4.71078 15.3985 6.51055 11.4691 9.39394 8.64228C9.81797 8.22767 10.0818 7.67172 10.0818 7.07808V6.44675C10.0818 4.58103 7.85802 3.62932 6.48228 4.9014C2.37392 8.71766 -0.16083 14.2112 -0.000641518 20.3078C0.282044 30.6823 8.593 39.3137 18.9581 39.9544C30.7367 40.6894 40.5176 31.3513 40.5176 19.733C40.5176 14.3902 38.4446 9.53745 35.0712 5.91907C33.752 4.50564 31.3775 5.43851 31.3775 7.37019V7.37961Z'
          fill={color || '#8C56D9'}
        />
        <path
          d='M23.0864 2.35571C23.0864 1.05469 22.0317 0 20.7307 0C19.4297 0 18.375 1.05469 18.375 2.35571V19.1378C18.375 20.4388 19.4297 21.4935 20.7307 21.4935C22.0317 21.4935 23.0864 20.4388 23.0864 19.1378V2.35571Z'
          fill={color || '#8C56D9'}
        />
      </g>
      <defs>
        <clipPath id='clip0_164_626'>
          <rect width='40.5183' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LogoutIcon
