import 'react-datepicker/dist/react-datepicker.css'

import ReactDatePicker, { registerLocale } from 'react-datepicker'
import styled from 'styled-components'
import th from 'date-fns/locale/th'
registerLocale('th', th)

const Container = styled.div`
  display: flex;
  .input {
    width: 100%;
    height: 40px;
    border: 1px solid ${props => props.borderColor || '#e5eced'};
    border-radius: 8px;
    padding: 0.25rem 1rem;
    background-color: ${props => props.backgroundColor || '#fff'};
    outline: none;
  }
  .input::placeholder {
    color: rgba(13, 51, 48, 0.5);
  }
  .popper {
    z-index: 4;
  }
  .react-datepicker-wrapper {
    width: ${props => props.width};
  }
  .react-datepicker {
    font-family: Opun;
    & select,
    option {
      color: #000;
    }
    & select {
      outline: none;
      border-radius: 6px;
      flex: 1;
    }
  }
  .react-datepicker__header {
    background: ${props =>
      props.primaryDark ||
      'linear-gradient(179.6deg,#8C56D9 0.05%,#53389E 99.66%)'};
    & * {
      color: #fff;
    }
  }
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-bottom-color: ${props =>
      props.primaryDark ||
      'linear-gradient(179.6deg,#8C56D9 0.05%,#53389E 99.66%)'};
  }
  .react-datepicker__header__dropdown {
    margin: 0.25rem 0.5rem 0;
    display: flex;
    gap: 0.5rem;
  }
  .react-datepicker__day--outside-month {
    color: #ccc;
  }
  .react-datepicker__day--today {
    background-color: pink;
  }
  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    display: flex;
    flex: 1;
    margin: 0;
  }
`

const DateSelector = ({
  width,
  name,
  date,
  placeholder,
  borderColor,
  onChange,
  backgroundColor,
  readOnly,
}) => {
  return (
    <Container
      width={width}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      <ReactDatePicker
        locale='th'
        selected={date ? new Date(date) : undefined}
        onChange={date => onChange(date, name)}
        className='input'
        popperClassName='popper'
        placeholderText={placeholder}
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        closeOnScroll
        dateFormat='dd/MM/yyyy'
        readOnly={readOnly}
      />
    </Container>
  )
}

export default DateSelector
