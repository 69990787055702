import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'

import { PlusCircle } from '@/components/atoms/icons/PlusIcon'
import typography from '@/styles/typography'

import CardCustomerClose from './CardCustomerClose'
import CardCustomerOpen from './CardCustomerOpen'
import CardStaff from './CardStaff'
import ShowProduct from './ShowProduct'
import useCardOrder from './useCardOrder'

import ClosePopup from '../../../../atoms/ClosePopup'
//@ts-ignore
import Scrollbar from '../../../../atoms/Scrollbar'
import TextLabel from '../../../../atoms/TextLabel'

import { BookOrder } from '../../../../redux/models/BookOrder'
import { RoomOrderState, StaffOrderState } from '../../types'

interface CardOrderStyle extends CSSProperties {
  active?: boolean
  error?: boolean
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Card = styled.div<CardOrderStyle>`
  background: #ffffff;
  border: ${props =>
    props.error
      ? '2px solid var(--error)'
      : props.active
      ? '2px solid var(--primary)'
      : props.border || '2px solid #e4e8eb'};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  border-radius: ${props => props.borderRadius || '0px 8px 8px 8px'};
  padding: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`

const BoxCustomer = styled.div<CardOrderStyle>`
  border-radius: 8px 8px 0 0;
  background-color: ${props =>
    props.error
      ? 'var(--error)'
      : props.active
      ? 'var(--primary)'
      : props.backgroundColor || '#E4E8EB'};
  color: ${props =>
    props.active || props.error ? '#FFFFFF' : props.color || '#000000'};
  width: fit-content;
  padding: 0.25rem 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`
const BoxAddService = styled.div`
  border: 1px dashed var(--error);
  border-radius: 4px;
  width: 100%;
  color: var(--error);
  text-align: center;
  padding: 0.25rem 0;
  font-weight: 600;
`

const BoxAddTreatment = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  isolation: isolate;
  background: #f4eefb;
  border: 1px dashed var(--primary);
  border-radius: 4px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  color: #8c56d9;
  ${typography.body1};
`

interface CardOrderProps {
  currentDate: Date
  staffOrders: StaffOrderState[]
  roomOrders: RoomOrderState[]
}

const CardOrder = ({
  currentDate,
  staffOrders,
  roomOrders,
}: CardOrderProps) => {
  const { t } = useTranslation()

  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const {
    customers,
    focusedCustomerIndex,
    instant,
    date,
    products,
  }: BookOrder = bookingOrderState

  const {
    itemRef,
    scrollRef,
    onRemoveCustomerClick,
    onCustomerClick,
    onAddService,
  } = useCardOrder({
    bookingOrderState,
  })

  return (
    <Container>
      <TextLabel>{t('treatmentSelected')}</TextLabel>
      <Scrollbar id='home-cardOrder' refProp={scrollRef}>
        {customers.map((customer, customerIndex) => (
          <Fragment key={customerIndex}>
            <BoxCustomer
              error={customer.services.some(s => s.serviceError)}
              active={focusedCustomerIndex === customerIndex}
              ref={ref => {
                focusedCustomerIndex === customerIndex &&
                  (itemRef.current = ref)
              }}
              onClick={() => {
                onCustomerClick(customerIndex)
                // setOpenStaff(false)
                // setOpenRoom(false)
                // setFocusOrderIndex(indexOrder)
              }}
            >
              {`${t('customerNo.')} ${customerIndex + 1}`}
              {customerIndex !== 0 && (
                <>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div
                    onClick={e => {
                      e.stopPropagation()
                      onRemoveCustomerClick(customerIndex)
                    }}
                  >
                    <ClosePopup
                      height='11px'
                      backgroundColor={
                        focusedCustomerIndex === customerIndex
                          ? '#FFFFFF'
                          : '#000000'
                      }
                    />
                  </div>
                  &nbsp;&nbsp;
                </>
              )}
            </BoxCustomer>
            <Card
              error={customer.services.some(s => s.serviceError)}
              active={focusedCustomerIndex === customerIndex}
            >
              {focusedCustomerIndex === customerIndex ? (
                <CardCustomerOpen
                  customerIndex={customerIndex}
                  services={customer.services}
                  staffOrders={staffOrders}
                  roomOrders={roomOrders}
                  currentDate={currentDate}
                  date={date}
                />
              ) : (
                <CardCustomerClose customer={customer} />
              )}
              {!instant && focusedCustomerIndex === customerIndex && (
                <BoxAddTreatment onClick={() => onAddService(customerIndex)}>
                  <PlusCircle />
                  {`${t('addTreatment')}`}
                </BoxAddTreatment>
              )}
            </Card>
          </Fragment>
        ))}
        <div className='mb-4'></div>
        {!!products.length && <ShowProduct />}
      </Scrollbar>
    </Container>
  )
}

export default CardOrder
