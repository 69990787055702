import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  margin: ${props => props.margin || '0 0'};
  &:hover {
    user-select: none;
  }
`

const TickIcon = ({ size, color }) => (
  <SVG
    width={size || '64'}
    height={size || '50'}
    viewBox='0 0 64 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.6492 50C19.8148 50.001 18.9885 49.8365 18.2178 49.5159C17.447 49.1953 16.747 48.7248 16.158 48.1317L1.99539 33.9115C1.37666 33.3262 0.881299 32.6221 0.538658 31.8411C0.196017 31.06 0.0130727 30.2179 0.000675342 29.3646C-0.011722 28.5112 0.146679 27.6641 0.466485 26.8733C0.786291 26.0826 1.26099 25.3644 1.86245 24.7612C2.46391 24.158 3.17988 23.6822 3.96793 23.362C4.75597 23.0417 5.60005 22.8835 6.45012 22.8968C7.30019 22.9101 8.13895 23.0946 8.91668 23.4393C9.69441 23.784 10.3953 24.282 10.9777 24.9037L20.6492 34.611L53.1777 1.84584C54.3709 0.659675 55.9834 -0.0039706 57.6627 1.78743e-05C59.342 0.00400635 60.9513 0.675303 62.1389 1.86712C63.3265 3.05894 63.9956 4.67431 64 6.36C64.0044 8.04569 63.3437 9.66454 62.1623 10.8626C60.981 12.0606 25.1414 48.1317 25.1414 48.1317C24.5521 48.7248 23.8519 49.1952 23.081 49.5158C22.3101 49.8364 21.4836 50.001 20.6492 50Z'
      fill={color || '#8C56D9'}
    />
  </SVG>
)

export default TickIcon
