import {
  CLEAR_EDIT_DETAIL_BOOKING,
  EDIT_DETAIL_BOOKING,
} from '../actions/bookingEditDetailAction'

const initState = {
  _id: '',
  customerId: '',
  name: '',
  email: '',
  countryCode: '',
  phoneNumber: '',
  specialRequest: '',
  customers: [],
  date: '',
  time: '',
  note: '',
  products: [],
  productCount: 0,
  bookingType: {
    bookingTypeId: -1,
    bookingType: '',
  },
  bookingChannel: {
    bookingChannelId: -1,
    bookingChannel: '',
    bookingChannelPayout: '',
  },
  statusId: '',
  payStatus: '',
  payChannel: '',
  bookingCode: '',
  discount: '',
  total: '',
  storeId: '',
}

const bookingEditDetailReducers = (state = initState, action) => {
  switch (action.type) {
    case EDIT_DETAIL_BOOKING:
      return { ...state, ...action.payload.data }
    case CLEAR_EDIT_DETAIL_BOOKING:
      return { ...initState }
    default:
      return state
  }
}
export default bookingEditDetailReducers
