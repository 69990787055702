import api from '../../api'

export const GET_SERVICE_GROUP = 'GET_SERVICE_GROUP'

export const getServiceGroup = data => {
  return {
    type: GET_SERVICE_GROUP,
    payload: { data },
  }
}

export const callApiServiceGroup = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getProductGroupByHeadGroup(1)
    dispatch(getServiceGroup(data))
  } catch (err) {
    console.log(err)
  }
}
