import React from 'react'
import styled from 'styled-components'

import SwitchButton from './SwitchButton'
import TextLabel from './TextLabel'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
`

export interface BoxStatusProps {
  title: string
  detail: string
  status: boolean | number
  id: string
  onChange: () => void
}

const BoxStatus = ({ title, detail, status, id, onChange }: BoxStatusProps) => {
  return (
    <Container>
      <FlexBox>
        <TextLabel fontWeight='600'>{title}</TextLabel>
        <TextLabel color='#98A1B2' fontSize='0.8rem'>
          {detail}
        </TextLabel>
      </FlexBox>
      <SwitchButton
        name={id}
        toggled={!!status || status === 1}
        onChange={onChange}
        backgroundColor={status || status === 1 ? '#8C56D9' : '#ABABAD'}
        width='18px'
        labelHeight='20px'
        labelWidth='40px'
      />
    </Container>
  )
}

export default BoxStatus
