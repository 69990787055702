import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  opacity: ${props => props.opacity || ''};
  &:hover {
    user-select: none;
    cursor: ${props => (!props.disabled ? 'pointer' : '')};
  }
`
const TemporaryIcon = ({ size, colorImage, colorCircle, opacity, onClick }) => (
  <SVG
    width={size || '48'}
    height={size || '48'}
    viewBox='0 0 48 48'
    opacity={opacity}
    onClick={onClick}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='24' cy='24' r='24' fill={colorCircle || '#FEC84A'} />
    <g clipPath='url(#clip0_682_2)'>
      <path
        d='M8 20.5307C8.17741 20.2006 8.45384 20.0975 8.82517 20.0975C10.9871 20.1099 13.1532 20.1057 15.3151 20.1057H15.6452L16.5735 18.0428H16.2352C14.8613 18.0428 13.4874 18.0428 12.1093 18.0428C12.0268 18.0428 11.9484 18.0428 11.8659 18.0428C11.5193 18.0181 11.2718 17.7623 11.2759 17.4363C11.2759 17.1104 11.5276 16.8463 11.8742 16.8381C12.3486 16.8257 12.8231 16.834 13.2976 16.834C14.5725 16.834 15.8432 16.834 17.1181 16.834C17.3161 16.834 17.4482 16.7762 17.5761 16.6194C19.6596 14.0036 22.3497 12.3987 25.6751 12.0727C30.4817 11.6024 34.3105 13.4178 37.0624 17.3868C38.5353 19.5116 39.1336 21.9211 39.0552 24.4998C38.9025 29.2981 35.5771 33.787 31.0304 35.326C26.0464 37.0134 20.8272 35.4745 17.5678 31.3445C17.4482 31.1919 17.3203 31.1424 17.1346 31.1465C14.3662 31.1506 11.5936 31.1465 8.82517 31.1547C8.45384 31.1547 8.17741 31.0516 8 30.7256V30.3626C8.17741 30.0325 8.44972 29.9294 8.82517 29.9335C11.3007 29.9459 13.7762 29.9417 16.2517 29.9417H16.6147C15.6699 28.2543 15.1583 26.4967 15.0511 24.6029H14.7334C14.1392 24.6029 13.541 24.6029 12.9469 24.6029C12.4188 24.6029 12.1382 24.3925 12.13 24.0047C12.1258 23.6045 12.4146 23.3899 12.9593 23.3899C13.3347 23.3899 13.706 23.3899 14.0815 23.3899H15.0263L15.311 21.327H14.9809C12.9304 21.327 10.8798 21.3187 8.82929 21.3352C8.45797 21.3352 8.18154 21.2321 8.00413 20.9062V20.5431L8 20.5307ZM16.2517 23.9675C16.2393 29.917 21.083 34.7772 27.0366 34.7855C32.9737 34.7937 37.8216 29.9706 37.8504 24.0212C37.8752 18.0758 33.0397 13.2073 27.0779 13.1867C21.1284 13.1661 16.2641 18.0098 16.2517 23.9634V23.9675Z'
        fill={colorImage || 'white'}
      />
      <path
        d='M8 27.0818C8.17741 26.7517 8.44972 26.6444 8.82104 26.6486C9.79887 26.6692 10.7767 26.6527 11.7545 26.6568C12.2084 26.6568 12.493 26.9044 12.4848 27.2757C12.4765 27.6388 12.2001 27.8657 11.7545 27.8657C10.7767 27.8657 9.79887 27.8533 8.82104 27.8739C8.44972 27.8822 8.17741 27.7708 8 27.4407V27.0776V27.0818Z'
        fill={colorImage || 'white'}
      />
      <path
        d='M27.0225 33.3953C21.8322 33.3747 17.6197 29.1251 17.6486 23.943C17.6816 18.7692 21.9271 14.565 27.0926 14.5939C32.2706 14.6228 36.4748 18.86 36.45 24.0297C36.4253 29.2076 32.188 33.416 27.0225 33.3953ZM18.8657 23.9802C18.8575 28.4815 22.5295 32.1658 27.0307 32.1782C31.532 32.1906 35.2205 28.5227 35.237 24.0173C35.2535 19.5036 31.5692 15.8069 27.0555 15.8028C22.5501 15.8028 18.8698 19.4748 18.8657 23.9761V23.9802Z'
        fill={colorImage || 'white'}
      />
      <path
        d='M30.5143 24.5995C29.7964 24.5995 29.0785 24.5913 28.3606 24.6078C28.2327 24.6078 28.0759 24.6821 27.9851 24.7769C27.6179 25.1689 27.0939 25.3133 26.6071 25.1235C26.1244 24.9337 25.8025 24.4551 25.8521 23.9312C25.8727 23.7207 25.8025 23.5928 25.6623 23.4608C25.3817 23.1968 25.1135 22.9203 24.8454 22.648C24.5359 22.3303 24.5112 21.9714 24.7752 21.7115C25.0393 21.4556 25.39 21.4845 25.7118 21.7981C26.0006 22.0828 26.277 22.3798 26.5741 22.648C26.6648 22.7305 26.8134 22.7842 26.933 22.7842C27.3539 22.7759 27.6963 22.9162 27.9892 23.2215C28.08 23.3123 28.2368 23.3865 28.3647 23.3865C29.8087 23.3989 31.2569 23.3865 32.7009 23.403C32.899 23.403 33.1548 23.5062 33.2744 23.6506C33.3817 23.7826 33.3941 24.0508 33.3528 24.2323C33.2909 24.4881 33.0558 24.6037 32.7876 24.6037C32.0284 24.6037 31.2693 24.6037 30.5143 24.6037V24.5995Z'
        fill={colorImage || 'white'}
      />
      <path
        d='M27.6542 18.2317C27.6542 18.5659 27.3695 18.8423 27.0353 18.834C26.7094 18.8258 26.4453 18.5535 26.4453 18.2317C26.4453 17.8975 26.7341 17.6169 27.0642 17.6252C27.386 17.6334 27.6542 17.9057 27.6542 18.2275V18.2317Z'
        fill={colorImage || 'white'}
      />
      <path
        d='M27.052 30.3573C26.7219 30.3573 26.4372 30.0726 26.4455 29.7384C26.4537 29.4125 26.7219 29.1484 27.0479 29.1484C27.3738 29.1484 27.6461 29.4125 27.6544 29.7343C27.6626 30.0685 27.3821 30.3532 27.052 30.3573Z'
        fill={colorImage || 'white'}
      />
      <path
        d='M21.2993 23.3908C21.6335 23.3991 21.9058 23.6879 21.8893 24.0221C21.8769 24.3439 21.5963 24.6079 21.2745 24.5997C20.9403 24.5914 20.6639 24.2985 20.6804 23.9684C20.6969 23.6466 20.9775 23.3826 21.2951 23.3908H21.2993Z'
        fill={colorImage || 'white'}
      />
    </g>
    <defs>
      <clipPath id='clip0_682_2'>
        <rect
          width='31.0593'
          height='24'
          fill='white'
          transform='translate(8 12)'
        />
      </clipPath>
    </defs>
  </SVG>
)

export default TemporaryIcon
