import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { fetchUser } from './loginUtil'
import useLogin from './useLogin'

// @ts-ignore
import PopupSelectBrand from '../popups/PopupSelectBrand'

// @ts-ignore
import Arrow from '../../atoms/Arrow'
// @ts-ignore
import TextLabel from '../../atoms/TextLabel'

import { SavedUsersData } from '../../lib/auth/loginApi'
import { LOGIN_URL } from '../../routes'

const buttonList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', null, '0', 'C']

const Container = styled.div`
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  /* padding: 4rem; */
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  margin: auto;
  gap: 4.5rem;
`

const CardCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const CardUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Circle = styled.div`
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 8px 40px rgba(71, 84, 103, 0.08);
  padding: 0.5rem 1rem;
`

const BoxImage = styled.div<any>`
  width: 132px;
  height: 132px;
  border-radius: 4px;
  aspect-ratio: 1;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  background-position: center;
  background-size: cover;
  box-shadow: 0px 16px 32px rgba(71, 84, 103, 0.08);
  border-radius: 16px;
`
const ImgLogo = styled.img`
  /* margin-top: 5rem; */
  width: 103.47px;
  height: 54px;
  object-fit: cover;
`
const CardPin = styled.div`
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 3rem 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
`
const BoxPin = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`
const BoxPinStyle = styled.div<any>`
  background: ${props => props.background || '#ffffff'};
  border: ${props => props.border || '1px solid #e4e8eb'};
  border-radius: 8px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  color: ${props => props.color || '#475467'};
  font-weight: 600;
  font-size: 3.375rem;
  width: 60px;
  height: 60px;
  aspect-ratio: 1;
  vertical-align: middle;
  text-align: center;
`
const BoxNumber = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  grid-area: 1;

  /* grid-auto-flow: column; */
  /* justify-content: end; */
`
const BoxNumberStyle = styled.div`
  display: grid;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e8eb;
  border-radius: 50%;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  color: #000000;
  padding: 0.5rem 1.25rem;
  :hover {
    background-color: #8c56d9;
    color: #fff;
  }
  /* width: 56.98px;
  height: 56px; */
`

const LoginUserPin = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const [currentUser, setCurrentUser] = React.useState<SavedUsersData | null>(
    null
  )
  const [pins, setPins] = React.useState<string[]>([])
  const { error, isSelectStoreOpen, onSelectStoreClose, loginPin } =
    useLogin('pin')

  useEffect(() => {
    const userId = new URLSearchParams(location.search).get('userId')
    if (!userId) {
      history.replace(LOGIN_URL)
      return
    }

    fetchUser(userId).then(user => {
      setCurrentUser(user)
    })
  }, [])

  const onNumberClick = (value: string) => {
    const newPins = [...pins]
    if (value === 'C') {
      newPins.pop()
    } else {
      if (pins.length < 4) {
        newPins.push(value)
      }
    }
    setPins(newPins)

    if (newPins.length === 4) {
      loginPin(currentUser?.phoneNumber || '', newPins.join(''), true)
    }
  }

  const onBackClick = () => {
    history.replace(LOGIN_URL)
  }

  return (
    <Container>
      <CardCircle>
        <Circle onClick={() => onBackClick()}>
          <div>
            <Arrow></Arrow>
          </div>
        </Circle>
      </CardCircle>
      <CardUser>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          <TextLabel color='#475467' fontWeight='600' fontSize='1.25rem'>
            {t('loginVia')}
          </TextLabel>
          <BoxImage background={currentUser?.image || ''}></BoxImage>
          <TextLabel>{currentUser?.firstName}</TextLabel>
        </div>
      </CardUser>
      <CardPin>
        <ImgLogo src='/images/logo_login.png' />
        <BoxPin>
          {Array.from({ length: 4 }).map((_, index) => (
            <BoxPinStyle
              key={index}
              background={
                !!pins[index] && index !== pins.length - 1 ? '#E4E8EB' : ''
              }
              border={
                index === pins.length - 1
                  ? '1px solid #8c56d9'
                  : error
                  ? '1px solid #DB2D1E'
                  : ''
              }
              color={error ? '#DB2D1E' : ''}
            >
              {pins[index] ? '*' : ''}
            </BoxPinStyle>
          ))}
        </BoxPin>
        {error && (
          <TextLabel color='#DB2D1E' fontWeight='600'>
            {t('PasswordIsIncorrect')}
          </TextLabel>
        )}
        <BoxNumber>
          {buttonList.map((value, index) => {
            return value ? (
              <BoxNumberStyle key={index} onClick={() => onNumberClick(value)}>
                {value}
              </BoxNumberStyle>
            ) : (
              <div key={index}></div>
            )
          })}
        </BoxNumber>
      </CardPin>
      {isSelectStoreOpen && (
        <PopupSelectBrand handleClose={onSelectStoreClose} />
      )}
    </Container>
  )
}

export default LoginUserPin
