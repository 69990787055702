import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import Popup from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import Button2 from '../../atoms/Button2'
import InputText from '../../atoms/InputText'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
  align-items: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${props => props.column || '1fr 1fr 1fr 1fr'};
  width: 100%;
  gap: 0.5rem;
  align-items: ${props => props.alignItems || 'unset'};
`

const PopupEditPayWage = ({
  activeData: _activeData,
  handleClose,
  updateStaffs,
}) => {
  const convertDate = date => {
    return `${dayjs(date).format('dddd')}, ${dayjs(date).format('DD MMM YYYY')}`
  }
  const [data, setData] = useState({ staffCommissions: [] })

  useEffect(() => {
    setData({
      ..._activeData,
      staffCommissions: _activeData.staffCommissions.map(item => ({ ...item })),
    })
  }, [_activeData])

  const onBaseCommissionChange = value => {
    setData(prev => ({ ...prev, baseCommission: value }))
  }
  const onCommissionChange = (value, index) => {
    const newArrStaffCommission = data.staffCommissions
    data.commission -= +data.staffCommissions[index].commissionFee
    data.staffCommissions[index].commissionFee = +value
    data.commission += +value
    setData(prev => ({
      ...prev,
      staffCommissions: newArrStaffCommission,
    }))
  }
  const onRequestRateChange = (value, index) => {
    const newArrStaffCommission = data.staffCommissions
    data.requestRate -= +data.staffCommissions[index].requestRate
    data.staffCommissions[index].requestRate = +value
    data.requestRate += +value
    setData(prev => ({
      ...prev,
      staffCommissions: newArrStaffCommission,
    }))
  }

  const onSaveClick = async () => {
    try {
      updateStaffs(data)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Popup width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0' height='auto'>
        <Card>
          <TextLabel fontSize='1.25rem' fontWeight='500'>
            แก้ไขค่ามือ
          </TextLabel>
          <TextLabel
            fontSize='1.05rem'
            fontWeight='500'
          >{`ชื่อ: ${data.staffName}`}</TextLabel>
          <TextLabel fontSize='1.05rem' fontWeight='500'>
            {convertDate(data.date)}
          </TextLabel>
          <GridBox>
            <TextLabel>ค่าประกันมือ</TextLabel>
            <InputText
              value={data.baseCommission}
              onChange={val => onBaseCommissionChange(val.target.value)}
            />
          </GridBox>
          {data.staffCommissions.map(
            (
              {
                service,
                serviceTime,
                commissionFee,
                requestStaff,
                requestRate,
              },
              index
            ) => (
              <GridBox column='1fr 1fr 1fr 1fr'>
                <TextLabel>{`${service} ${serviceTime} นาที`}</TextLabel>
                <InputText
                  value={commissionFee}
                  onChange={val => onCommissionChange(val.target.value, index)}
                />
                {!!requestStaff && (
                  <>
                    <TextLabel textAlign='center'>{`ค่ารีเควส`}</TextLabel>
                    <InputText
                      value={requestRate}
                      onChange={val =>
                        onRequestRateChange(val.target.value, index)
                      }
                    />
                  </>
                )}
              </GridBox>
            )
          )}
          <div
            className='mt-3'
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              background: 'white',
              zIndex: '2',
              gap: '0.5rem',
            }}
          >
            <GridBox column='1fr 1fr'>
              <TextLabel
                fontSize='1.25rem'
                fontWeight='500'
                color='#8C56D9'
              >{`รวมค่ามือ: ${
                +data.baseCommission > +data.commission + +data.requestRate
                  ? data.baseCommission
                  : data.commission + data.requestRate
              }`}</TextLabel>
            </GridBox>

            <GridBox column='1fr 1fr'>
              <Button2
                color='#98A1B2'
                border='2px solid #98A1B2'
                backgroundColor='white'
                fontWeight='600'
                onClick={handleClose}
              >
                ยกเลิก
              </Button2>
              <Button2
                border='2px solid #53389E'
                color='#FFFFFF'
                backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                fontWeight='600'
                onClick={() => onSaveClick()}
              >
                บันทึก
              </Button2>
            </GridBox>
          </div>
        </Card>
      </PopupInsideBox>
    </Popup>
  )
}

export default PopupEditPayWage
