const initState = {
  staffType: '',
  paymentCondition: 0,
  paymentMethod: 0,
  sameSalary: false,
  sameCommission: false,
  baseSalary: '',
  baseCommission: '',
  baseSalaryPerRound: 0,
  baseCommissionPerRound: 0,
  payDays: [0],
}
//typeStaffReducers
const dataStaffTypeReducers = (state = initState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default dataStaffTypeReducers
