import styled from 'styled-components'

import PopupBase from './PopupBase'
import Button2 from '../../atoms/Button2'

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex: 1;
  position: sticky;
  bottom: 0rem;
  background: #fff;
  z-index: 2;
`

const PopupConfirmBase = ({
  topic,
  maxWidth,
  children,
  onConfirm,
  handleClose,
}) => {
  return (
    <PopupBase topic={topic} maxWidth={maxWidth} handleClose={handleClose}>
      {children}
      <Container>
        <Button2
          backgroundColor='#FFFFFF'
          backgroundColorHover='#E5E7EC'
          border='1px solid #E4E8EB'
          onClick={() => handleClose()}
        >
          ยกเลิก
        </Button2>
        <Button2
          backgroundColor='linear-gradient(221.86deg, #8C56D9 2.71%, #53389E 97.79%)'
          border='1px solid #53389E'
          color='#FFFFFF'
          onClick={onConfirm}
        >
          ยืนยัน
        </Button2>
      </Container>
    </PopupBase>
  )
}

export default PopupConfirmBase
