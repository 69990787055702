import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export interface UseDebouncedValueOption {
  ms?: number
  skipEmpty?: boolean
}

const useDebouncedValue = <T>(value: T, options: UseDebouncedValueOption) => {
  const [internalValue, setInternalValue] = useState<T>('' as T)

  const { ms = 500, skipEmpty = false } = options || {}

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce(value => {
      setInternalValue(value)
    }, ms),
    [ms]
  )

  useEffect(() => {
    if (skipEmpty && !value) {
      setInternalValue(value)
      debounceCallback.cancel()
    } else {
      debounceCallback(value)
    }
  }, [value, debounceCallback, skipEmpty])

  useEffect(() => {
    return () => {
      debounceCallback.cancel()
    }
  }, [debounceCallback])

  return internalValue
}

export default useDebouncedValue
