//@ts-ignore
import { useEffect, useState } from 'react'

import Option from '@/redux/models/Option'

//@ts-ignore
import api from '../../../api'
//@ts-ignore
import auth from '../../../auth'
import Room from '../../../redux/models/Room'
import RoomType from '../../../redux/models/RoomType'

interface useCardAddRoomProps {
  currentIdEdit: number
  currentDataEdit: Room
  roomTypes: RoomType[]
}

interface RoomState extends Room {
  roomTypeOption: Option
  roomTypeOpen: boolean
}

const useCardAddRoom = ({
  currentIdEdit,
  currentDataEdit,
  roomTypes,
}: useCardAddRoomProps) => {
  const [success, setSuccess] = useState<boolean>(false)
  const [checkName, setCheckName] = useState<boolean>(false)
  const [checkCode, setCheckCode] = useState<boolean>(false)
  const [room, setRoom] = useState<RoomState>({
    _id: -1,
    image: '',
    roomName: '',
    roomCode: '',
    propertyType: -1,
    roomTypeId: -1,
    serviceSpecificRooms: [],
    prepareTime: { before: 0, after: 0 },
    statusRoom: 1,
    number: 1,
    status: 1,
    storeId: -1,
    roomTypeOption: { value: -1, label: '' },
    roomTypeOpen: false,
  })

  useEffect(() => {
    if (currentIdEdit !== -1) {
      // setRoom({...currentDataEdit})
      const findRoomType = roomTypes.find(
        t => t._id === currentDataEdit.roomTypeId
      )

      setRoom({
        ...currentDataEdit,
        roomTypeOption: findRoomType
          ? { value: findRoomType._id, label: findRoomType.roomTypeName }
          : { value: -1, label: '' },
        roomTypeOpen: false,
      })
    }
  }, [currentIdEdit, currentDataEdit, roomTypes])

  const onChange = (value: string | number, name: string) => {
    setRoom(prev => ({ ...prev, [name]: value }))
  }

  const onToggle = (value?: boolean) => {
    setRoom(prev => ({
      ...prev,
      roomTypeOpen: value === undefined ? !room.roomTypeOpen : value,
    }))
  }

  const onRoomTypeOptionChange = (option: any) => {
    setRoom(prev => ({ ...prev, roomTypeOption: option, roomTypeOpen: false }))
  }

  const onSaveClick = async () => {
    try {
      const { roomTypeOption, roomTypeOpen, ...rest } = room
      if (roomTypeOption.value === -1) {
        return false
      } else {
        if (!room.roomName || !room.roomCode) {
          if (!room.roomName) {
            setCheckName(false)
          }
          if (!room.roomCode) {
            setCheckCode(false)
          }
          return false
        }
      }

      const newRoom = {
        ...rest,
        roomTypeId: +roomTypeOption.value,
        storeId: auth.getStoreId(),
      }

      if (currentIdEdit === -1) {
        callApiCreateRoom(newRoom)
      } else {
        callApiUpdateRoom(newRoom)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateRoom = async (newRoom: Room) => {
    try {
      const { data, success } = await api.createRoom(newRoom)
      if (success) {
        setSuccess(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateRoom = async (newRoom: Room) => {
    try {
      const { data, success } = await api.updateRoom(currentIdEdit, newRoom)
      if (success) {
        setSuccess(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return {
    room,
    checkName,
    checkCode,
    success,
    onChange,
    onToggle,
    onRoomTypeOptionChange,
    onSaveClick,
  }
}

export default useCardAddRoom
