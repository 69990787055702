// @ts-nocheck
import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import ClosePopup from '../../../atoms/ClosePopup'
import Scrollbar from '../../../atoms/Scrollbar'
import TextLabel from '../../../atoms/TextLabel'
import utils from '../../../utils'
import { XIcon } from '@/components/atoms/icons/XIcon'

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px 32px;
`

const Box = styled.div<{
  flexDirection?: string
  alignItems?: string
  justifyContent?: string
}>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: 100%;
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`

const Circle = styled.div`
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background-color: #000000;
`

const BoxHistory = styled.div`
  background: #f8f9fb;
  border: 1px solid #e4e8eb;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
`

const Image = styled.div<{ background?: string }>`
  width: 45px;
  border-radius: 4px;
  aspect-ratio: 1;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  background-position: center;
  background-size: cover;
`

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 24px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--muted);
  border-radius: 999px;

  path {
    fill: var(--primary);
  }
`

// Define interfaces for the component props and state
interface BookingHistory {
  phoneNumber: string
  name: string
  date: string
  time: { hour: number; minute: number }
  total: string
  discount?: string
  customers: Customer[]
  customerCount: number
  staffs?: Staff[]
}

interface Customer {
  orders: Order[]
}

interface Order {
  staffId: string
  staffName: string
  service: string
  time: string
  price: string
  image?: string
}

interface Staff {
  staffId: string
  staffName: string
}

interface MinHistoryProps {
  bookingHistories: BookingHistory[]
  handleClose?: () => void
  marginTop?: string
}

const ModalBookingHistory: React.FC<MinHistoryProps> = ({
  bookingHistories: _bookingHistories,
  handleClose,
  marginTop,
}) => {
  const [bookingHistories, setBookingHistories] = useState<BookingHistory[]>([])

  useEffect(() => {
    const processedHistories = _bookingHistories.map(info => {
      const { customers } = info
      const staffs: Staff[] = []
      for (const customer of customers) {
        for (const order of customer.orders) {
          const findStaff = staffs.findIndex(s => s.staffId === order.staffId)
          if (findStaff === -1) {
            staffs.push({ staffId: order.staffId, staffName: order.staffName })
          }
        }
      }
      return { ...info, staffs }
    })
    setBookingHistories(processedHistories)
  }, [_bookingHistories])
  

  if(bookingHistories.length === 0) return null

  return (
    <Card>
      <TextLabel fontWeight='600' fontSize='1.05rem'>
        ประวัติการจอง
      </TextLabel>
      {bookingHistories.length > 0 && (
        <>
          <Box className='mt-3' alignItems='center'>
            <TextLabel fontWeight='600' color='#8C56D9'>
              {utils.formatPhoneNumber(_bookingHistories[0]?.phoneNumber)}
            </TextLabel>
            &nbsp;&nbsp;
            <Circle />
            &nbsp;&nbsp;
            <TextLabel color='#98A1B2' fontWeight='600' fontSize='0.875rem'>
              {_bookingHistories[0]?.name}
            </TextLabel>
          </Box>
          <Scrollbar id='miniHistory'>
            {bookingHistories.map(
              (
                {
                  date,
                  time,
                  total,
                  discount,
                  customers,
                  customerCount,
                  staffs,
                },
                index
              ) => (
                <BoxHistory className='mt-2' key={index}>
                  <Box justifyContent='space-between'>
                    <TextLabel
                      color='#8C56D9'
                      fontWeight='600'
                      fontSize='0.875rem'
                    >{`${dayjs(date).format('DD/MM/YYYY')}  ${utils.formatTime(
                      time.hour
                    )}.${utils.formatTime(time.minute)} น.`}</TextLabel>
                    <TextLabel fontWeight='600' fontSize='0.875rem'>{`${
                      parseFloat(total) - parseFloat(discount || '0')
                    } บาท`}</TextLabel>
                  </Box>
                  {customers.map(({ orders }, cIdx) => (
                    <Fragment key={cIdx}>
                      {orders.map(({ service, time, price, image }, sIdx) => (
                        <Box
                          className='mb-2'
                          flexDirection='row'
                          alignItems='center'
                          key={sIdx}
                        >
                          <div className='mr-2'>
                            <Image background={image} />
                          </div>
                          <Box flexDirection='column'>
                            <TextLabel>{service}</TextLabel>
                            <Box>
                              <TextLabel
                                color='#C8CDD7'
                                fontSize='0.875rem'
                                fontWeight='500'
                              >{`${time} นาที`}</TextLabel>
                              &nbsp;&nbsp;
                              <TextLabel
                                color='#475467'
                                fontSize='0.875rem'
                                fontWeight='500'
                              >{`${price} บาท`}</TextLabel>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Fragment>
                  ))}
                  <Box>
                    <TextLabel
                      color='#475467'
                      fontSize='0.875rem'
                      fontWeight='500'
                    >{`พนักงาน ${customerCount} คน : `}</TextLabel>
                    &nbsp;
                    <TextLabel
                      color='#475467'
                      fontSize='0.875rem'
                      fontWeight='500'
                    >
                      {staffs.map(o => o.staffName).join(', ')}
                    </TextLabel>
                  </Box>
                </BoxHistory>
              )
            )}
          </Scrollbar>
        </>
      )}
    </Card>
  )
}

export default ModalBookingHistory
