import React from 'react'
import styled from 'styled-components'

import InputBox from '../InputBox'

import BahtIcon from '../../../atoms/BahtIcon'
import Box from '../../../atoms/Box'
import TextLabel from '../../../atoms/TextLabel'
import TimeIcon2 from '../../../atoms/TimeIcon2'

import { TimePrice } from '../../../redux/models/Service'
//@ts-ignore
import utils from '../../../utils'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
`

const AgencyContainer = styled.div`
  width: 100%;
`

const BoxWrapper = styled.div`
  background: #f9f7fd;
  border-radius: 8px;
  padding: 0.5rem;
  display: grid;
  gap: 0.5rem;
`

const BoxContainer = styled.div`
  position: relative;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
`

interface TableAgencyPriceProps {
  timePrices: TimePrice[]
  agencies: { _id: number; bookingChannel: string }[]
  onTimePriceChange: (value: object, index: number, type: string) => void
}

const TableAgency = ({
  timePrices,
  agencies,
  onTimePriceChange,
}: TableAgencyPriceProps) => {
  return (
    <Box header=' ราคาเอเจนซี่'>
      <Container>
        {agencies.map(({ _id, bookingChannel }) => (
          <AgencyContainer key={_id}>
            <TextLabel fontSize='0.875rem' fontWeight={500}>
              {bookingChannel}
            </TextLabel>
            <BoxWrapper>
              {timePrices.map(
                (
                  { time = '', price = '', agencyPriceMap = {} },
                  index: number
                ) => (
                  <BoxContainer key={index}>
                    <InputBox
                      header={index === 0 ? 'เวลาบริการ' : ''}
                      placeholder='เวลาบริการ'
                      icon={<TimeIcon2 size='15' />}
                      value={time}
                      suffix='นาที'
                      readonly
                    />
                    <InputBox
                      header={index === 0 ? 'ราคาขาย' : ''}
                      placeholder='ราคาขาย'
                      icon={<BahtIcon size='17' />}
                      value={price}
                      suffix='บาท'
                      readonly
                    />
                    <InputBox
                      header={index === 0 ? 'ราคาเอเจนซี่' : ''}
                      placeholder='ราคาเอเจนซี่'
                      icon={<BahtIcon size='17' />}
                      value={agencyPriceMap[_id] || ''}
                      suffix='บาท'
                      onChange={e => {
                        const value = e.target.value
                        if (!utils.checkNumber(value)) return
                        onTimePriceChange(
                          {
                            // agencyPriceMap: {
                            // ...agencyPriceMap,
                            [_id]: +value,
                            // },
                          },
                          index,
                          'agency'
                        )
                      }}
                    />
                  </BoxContainer>
                )
              )}
            </BoxWrapper>
          </AgencyContainer>
        ))}
      </Container>
    </Box>
  )
}

export default TableAgency
