import React from 'react'
import styled from 'styled-components'

// @ts-ignore
import Search from '@/components/customer/Body'
import Header from '@/components/Header'
// @ts-ignore
import Notification from '@/components/notifications'

import usePage from './usePage'

// @ts-ignore
import SideNavBar from '../SideNavBar/SideNavBar'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
`

const MainContainer = styled.div<any>`
  width: ${props => props.width || '100%'};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
`

interface PageProps {
  children: React.ReactNode
}

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  flex-grow: 1;
  flex: 1;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 1rem;
`

const Page = ({ children }: PageProps) => {
  const { search, menuWidth, isNotificationOpen } = usePage()
  return (
    <Container>
      <SideNavBar />
      <MainContainer width={`calc(100% - ${menuWidth})`}>
        {<Header />}
        <StyledBody>
          {search ? <Search search={search} /> : children}
        </StyledBody>
        {isNotificationOpen && <Notification />}
      </MainContainer>
    </Container>
  )
}

export default Page
