import React, { useEffect } from 'react'
import styled, { CSSProperties } from 'styled-components'

import useCardAddRoomType from './useCardAddRoomType'

//@ts-ignore
import Button2 from '../../../atoms/Button2'
//@ts-ignore
import ButtonSave from '../../../atoms/ButtonSave'
import InputText from '../../../atoms/InputText'
import SaveIcon from '../../../atoms/SaveIcon'
//@ts-ignore
import Scrollbar from '../../../atoms/Scrollbar'
import SuccessIcon2 from '../../../atoms/SuccessIcon2'
import SwitchButton from '../../../atoms/SwitchButton'
import TextLabel from '../../../atoms/TextLabel'

import RoomType from '../../../redux/models/RoomType'

interface CardAddRoomTypeStyle extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  overflow: hidden;
  display: flex;
`
const Card = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Box = styled.div<CardAddRoomTypeStyle>`
  display: flex;
  width: 100%;
  margin-bottom: ${props => props.marginBottom || '2rem'};
`
const BoxStyle = styled.div<CardAddRoomTypeStyle>`
  width: 50%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const Line = styled.div`
  width: 100%;
  margin: 1rem 0;
  border-top: 1px solid #e4e8eb;
`

interface CardAddRoomTypeProps {
  handleClose: () => void
  currentIdEdit: number
  currentDataEdit: RoomType
}

const CardAddRoomType = ({
  handleClose,
  currentIdEdit,
  currentDataEdit,
}: CardAddRoomTypeProps) => {
  const {
    roomType,
    checkName,
    checkCode,
    success,
    onChange,
    onSwitchButtonChange,
    onSaveClick,
  } = useCardAddRoomType({
    currentIdEdit,
    currentDataEdit,
  })

  useEffect(() => {
    if (success) {
      if (success) {
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    }
  }, [success])

  return (
    <Container>
      <Card>
        <Scrollbar id='setting-roomType'>
          <Box className='mt-3' marginBottom='0'>
            <BoxStyle className='pr-2' flexDirection='column'>
              <TextLabel fontWeight='600' color={checkName ? '#DB2D1E' : ''}>
                ชื่อห้อง*
              </TextLabel>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                border={checkName ? '2px solid #DB2D1E' : ''}
                value={roomType.roomTypeName}
                onChange={val => onChange(val.target.value, 'roomTypeName')}
              />
            </BoxStyle>
            <BoxStyle className='pl-2' flexDirection='column'>
              <TextLabel fontWeight='600' color={checkCode ? '#DB2D1E' : ''}>
                รหัสย่อห้อง*
              </TextLabel>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                border={checkCode ? '2px solid #DB2D1E' : ''}
                value={roomType.roomTypeCode}
                onChange={val => onChange(val.target.value, 'roomTypeCode')}
              />
            </BoxStyle>
          </Box>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              zIndex: '2',
              background: '#FFFFFF',
            }}
          >
            <Line></Line>
            <Box marginBottom='0'>
              <BoxStyle flexDirection='column'>
                <TextLabel fontWeight='600'>ส่วนตัว</TextLabel>
                <TextLabel color='#98A1B2' fontSize='0.8rem'>
                  ไม่ส่วนตัว/ส่วนตัว
                </TextLabel>
              </BoxStyle>
              <BoxStyle alignItems='center' justifyContent='flex-end'>
                <SwitchButton
                  name='stats-room-type'
                  toggled={roomType.private === 1 ? true : false}
                  onChange={() => onSwitchButtonChange('private')}
                  backgroundColor={
                    roomType.private === 1 ? '#8C56D9' : '#ABABAD'
                  }
                  width='18px'
                  labelHeight='20px'
                  labelWidth='40px'
                />
              </BoxStyle>
            </Box>
            <Box>
              <BoxStyle flexDirection='column'>
                <TextLabel fontWeight='600'>สถานะ</TextLabel>
                <TextLabel color='#98A1B2' fontSize='0.8rem'>
                  ไม่ใช้งาน/ใช้งาน
                </TextLabel>
              </BoxStyle>
              <BoxStyle alignItems='center' justifyContent='flex-end'>
                <SwitchButton
                  name='status-room-type'
                  toggled={roomType.status === 1 ? true : false}
                  onChange={() => onSwitchButtonChange('status')}
                  backgroundColor={
                    roomType.status === 1 ? '#8C56D9' : '#ABABAD'
                  }
                  width='18px'
                  labelHeight='20px'
                  labelWidth='40px'
                />
              </BoxStyle>
            </Box>
            <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
              <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                <Button2
                  color='#98A1B2'
                  border='2px solid #98A1B2'
                  backgroundColor='white'
                  backgroundColorHover='#E5E7EC'
                  fontWeight='600'
                  onClick={handleClose}
                >
                  ยกเลิก
                </Button2>
              </div>
              <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                <ButtonSave
                  border='2px solid #53389E'
                  color='#FFFFFF'
                  backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                  backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                  fontWeight='600'
                  icon={<SaveIcon size='15' />}
                  onClick={() => onSaveClick()}
                >
                  บันทึก
                </ButtonSave>
              </div>
            </div>
          </div>
        </Scrollbar>
      </Card>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddRoomType
