import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BaseDropdown from './index'; // Assuming it's in the same folder
import { InputGroup, InputGroupProps } from '../input/InputGroup';

export interface SelectProps extends InputGroupProps {
  value: string;
  onValueChange: (value: string) => void;
  items: ISelectItem[];
  placeholder?: string;
  defaultValue?: string;
}

export interface ISelectItem {
  value: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
}

const TriggerButton = styled.button`
  width: 100%;
  padding: 8px 12px;
  background-color: white;
  border: 1px solid var(--input);
  box-shadow: var(--shadow-sm);
  border-radius: var(--radius-xs);
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GroupWrapper = styled.div`
  padding: 8px 0;
`;

export interface ItemWrapperProps {
  selected?: boolean;
}

const ItemWrapper = styled.div<ItemWrapperProps>`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${props => (props.selected ? 'var(--primary)' : 'var(--text-primary)')};

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Placeholder = styled.span`
  color: #aaa;
  font-size: 14px;
`;

const IconWrapper = styled.span`
  margin-right: 8px;
`;

const ChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    width="20"
    height="20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.707 13.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 11.586l3.293-3.293a1 1 0 111.414 1.414l-4 4z"
    />
  </svg>
);

const Select = (props: SelectProps) => {
  const { value, onValueChange, items, placeholder, defaultValue, ...rest } = props;
  const { label, description, error, required, id } = rest;

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    console.log('toggle');
  };
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (defaultValue) {
      onValueChange(defaultValue?.toString());
    }
  }, [defaultValue]);

  return (
    <InputGroup label={label} description={description} error={error} required={required} id={id}>
      <BaseDropdown
        trigger={
          <TriggerButton>
            {items?.find((item) => item.value === value)?.children || (
              <Placeholder>{placeholder || 'Select a Value'}</Placeholder>
            )}
            <ChevronDown />
          </TriggerButton>
        }
      >
        <GroupWrapper>
          {items?.map((item) => (
            <ItemWrapper
              selected={item.value === value}
              key={item.value}
              onClick={() => {
                onValueChange(item.value);
                handleClose();
              }}
            >
              {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
              {item.children}
            </ItemWrapper>
          ))}
        </GroupWrapper>
      </BaseDropdown>
    </InputGroup>
  );
};

export default Select;
