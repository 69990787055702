import React from 'react'

import CardAddProduct from './index'

//@ts-ignore
import PopupBox from '../../../atoms/Popup'
//@ts-ignore
import PopupInsideBox from '../../../atoms/PopupInside'

import { Product } from '../../../redux/models/Product'
import { ProductGroup } from '../../../redux/models/ProductGroup'

interface PopupAddProductProps {
  handleClose: (close: boolean) => void
  productGroup: ProductGroup[]
  currentEditId: number
  currentEditData: Product
}

const PopupAddProduct = ({
  handleClose,
  productGroup,
  currentEditId,
  currentEditData,
}: PopupAddProductProps) => {
  return (
    // <div style={{ color: 'red' }}>{y}</div>
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='fit-content'
        height='auto'
        padding='2rem 1rem 1rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
      >
        <CardAddProduct
          handleClose={handleClose}
          productGroup={productGroup}
          currentEditId={currentEditId}
          currentEditData={currentEditData}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddProduct
