import { IconProps } from './IconPropsType'
import React from 'react'

const HomeIcon = ({ size, color = '' }: IconProps) => {
  return (
    <svg
      width={size || '41'}
      height={size || '40'}
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_17_236)'>
        <path
          d='M20.0028 3.3338C20.5584 3.3338 21.0863 3.53938 21.4974 3.91721L35.955 17.2746C36.4106 17.6969 36.6718 18.297 36.6718 18.9304V33.8158C36.6718 35.3938 35.4105 36.6718 33.8658 36.6718H27.9983V30.6265C27.9983 27.2205 25.2257 24.4478 21.8197 24.4478H18.1914C14.7854 24.4478 12.0128 27.2205 12.0128 30.6265V36.6718H6.13974C4.58953 36.6718 3.3338 35.3883 3.3338 33.8158V18.9304C3.3338 18.3025 3.59494 17.6969 4.05056 17.2746L18.5081 3.91721C18.9137 3.53938 19.4471 3.3338 20.0028 3.3338ZM20.0028 0C18.6581 0 17.308 0.488957 16.2467 1.46687L1.78914 14.8298C0.65009 15.8855 0 17.3746 0 18.9304V33.8158C0 37.233 2.75038 40.0056 6.13974 40.0056H13.8853C14.6909 40.0056 15.341 39.3555 15.341 38.5498V30.6265C15.341 29.054 16.6134 27.7816 18.1859 27.7816H21.8141C23.3866 27.7816 24.659 29.054 24.659 30.6265V38.5498C24.659 39.3555 25.3091 40.0056 26.1147 40.0056H33.8603C37.2552 40.0056 40 37.233 40 33.8158V18.9304C40 17.3691 39.3499 15.88 38.2109 14.8298L23.7589 1.46687C22.6976 0.488957 21.353 0 20.0028 0Z'
          fill={color || '#8C56D9'}
        />
      </g>
      <defs>
        <clipPath id='clip0_17_236'>
          <rect width='40.0056' height='40' fill={color || '#8C56D9'} />
        </clipPath>
      </defs>
    </svg>

    // <svg
    //   width={size || '55'}
    //   height={size || '53'}
    //   viewBox='0 0 55 53'
    //   fill='none'
    //   xmlns='http://www.w3.org/2000/svg'
    // >
    //   <path
    //     fillRule='evenodd'
    //     clipRule='evenodd'
    //     d='M28.6097 0.538662L54.4922 23.9132C55.1144 24.4844 55.171 25.4555 54.628 26.0953C54.3225 26.438 53.904 26.6094 53.4854 26.6094C53.1234 26.6094 52.7614 26.4837 52.4673 26.221L47.8066 22.0053V31.5448V35.4291V48.1332C47.8066 50.8636 46.1098 53 43.9605 53H35.793C33.802 53 32.1844 51.3663 32.1844 49.3556V35.4977C32.1844 35.1892 31.9355 34.9379 31.6301 34.9379H23.3834C23.078 34.9379 22.8291 35.1892 22.8291 35.4977V49.3556C22.8291 51.3663 21.2114 53 19.2205 53H11.234H11.1435H11.053C8.92626 53 7.20679 51.2521 7.20679 49.1157V34.8807V22.6794V21.8568L2.54613 26.0724C2.25201 26.3352 1.89001 26.4609 1.52802 26.4609C1.10946 26.4609 0.690908 26.2895 0.385476 25.9468C-0.168827 25.307 -0.123577 24.3359 0.509912 23.7647L26.3925 0.390143C26.992 -0.146809 27.897 -0.12396 28.4626 0.424417C28.5192 0.45869 28.5644 0.492964 28.6097 0.538662ZM44.741 35.4177V31.5333V19.2406L27.4219 3.60043L10.2498 19.0921V22.6679V34.8693V49.1157C10.2498 49.5612 10.6118 49.9154 11.0417 49.9154H11.1322H11.2227H19.2091C19.5146 49.9154 19.7635 49.664 19.7635 49.3556V35.4977C19.7635 33.4869 21.3811 31.8532 23.3721 31.8532H31.6187C33.6097 31.8532 35.2274 33.4869 35.2274 35.4977V49.3327C35.2274 49.6412 35.4762 49.8925 35.7817 49.8925H43.9492C44.198 49.8925 44.741 49.2185 44.741 48.1103V35.4177Z'
    //     fill={`url(#${key})`}
    //   />
    //   <defs>
    //     <linearGradient
    //       id={key}
    //       x1='-25.8308'
    //       y1='26.5'
    //       x2='27.2558'
    //       y2='78.2459'
    //       gradientUnits='userSpaceOnUse'
    //     >
    //       <stop stopColor={color || '#57557F'} />

    //       <stop offset='1' stopColor={color || '#8E759F'} />
    //     </linearGradient>
    //   </defs>
    // </svg>
  )
}
export default HomeIcon
