import React from 'react'
import { IconProps } from './IconPropsType'

const Customer = ({ size, color }: IconProps) => (
  <svg
    width={size || '16'}
    height={size || '20'}
    viewBox='0 0 16 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_5524_3193)'>
      <path
        d='M6.99736 1.69972C8.41379 1.69972 9.57526 2.86119 9.57526 4.27762C9.57526 5.69405 8.41379 6.85552 6.99736 6.85552C5.58092 6.85552 4.41945 5.69405 4.41945 4.27762C4.41945 2.86119 5.5526 1.69972 6.99736 1.69972ZM6.99736 0C4.61775 0 2.69141 1.92635 2.69141 4.27762C2.69141 6.6289 4.61775 8.55524 6.96903 8.55524C9.3203 8.55524 11.2466 6.6289 11.2466 4.27762C11.2466 1.92635 9.34863 0 6.99736 0Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M8.81072 15.5525C8.81072 15.5241 8.81072 15.4958 8.81072 15.4675C8.21582 15.6375 7.62092 15.6941 6.99769 15.6941C5.128 15.6941 3.37163 14.9576 2.06851 13.6261C1.53027 13.0596 1.72857 12.408 1.81355 12.238C1.89854 12.0114 2.21015 11.4732 2.97503 11.4732H11.0204C11.7852 11.4732 12.0968 12.0114 12.1818 12.238C12.2668 12.408 12.4651 13.0596 11.9269 13.6261C11.4453 14.136 10.8787 14.5326 10.2838 14.8726L11.1053 15.5525L12.1818 14.6743C12.3801 14.5326 12.5501 14.4193 12.6917 14.4193C12.7201 14.4193 12.7767 14.4193 12.89 14.5043C12.975 14.561 13.0883 14.646 13.1733 14.7593C14.9297 12.8613 13.6266 9.77344 11.0204 9.77344H2.97503C0.340466 9.77344 -0.990979 12.9462 0.850381 14.8159C2.40845 16.4023 4.58976 17.3938 6.96936 17.3938C7.87588 17.3938 8.75406 17.2522 9.57559 16.9972C9.09401 16.5156 8.81072 16.0341 8.81072 15.5525Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M12.6908 14.4185C12.7192 14.4185 12.7758 14.4185 12.8891 14.5035C13.1441 14.6734 13.4274 15.07 13.399 15.5516C13.3424 16.4015 12.5209 17.308 11.1044 18.1012C9.68799 17.308 8.83813 16.4015 8.8098 15.5516C8.78148 15.07 9.06476 14.6734 9.31972 14.5035C9.43303 14.4185 9.51802 14.4185 9.51802 14.4185C9.63133 14.4185 9.82963 14.5035 10.0279 14.6734L11.1044 15.5516L12.1809 14.6734C12.3509 14.5318 12.5492 14.4185 12.6908 14.4185ZM12.6908 12.7188C12.0109 12.7188 11.4444 13.087 11.1044 13.342C10.7645 13.0587 10.1979 12.7188 9.51802 12.7188C9.17808 12.7188 8.8098 12.8037 8.4132 13.0587C6.77014 14.0785 5.75031 17.478 11.0761 19.9992H11.1611C16.4585 17.478 15.467 14.0785 13.824 13.0587C13.399 12.8321 13.0308 12.7188 12.6908 12.7188Z'
        fill={color || '#8C56D9'}
      />
    </g>
    <defs>
      <clipPath id='clip0_5524_3193'>
        <rect width='15.0992' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Customer
