import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'

import TextLabel from '../../atoms/TextLabel'
import api from '../../api'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  width: calc(100%);
  /* margin-right: 1rem; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const WhiteBox = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  border: ${props => props.border || '1px solid white'};
`
const BoxHead = styled.div`
  background: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const ContainerCircle = styled.div`
  display: flex;
  max-width: 100%;
`
const ContainerCircleStyle = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: wrap;
  width: ${props => (props.open ? '100%' : '70%')};
  padding: ${props => props.padding || '0'};
  height: fit-content;
  cursor: pointer;
`
const BoxCircle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 33%'};
  max-width: ${props => props.width || '33%'};
  margin: ${props => props.margin || '0.25rem 0 0 0'};
`
const Circle = styled.div`
  background: #98a1b2;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`
const Arrow = styled.div`
  border: solid #475467;
  border-width: 0 3px 3px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  /* margin-left: 5px; */
  transition: all 0.4s ease;
  transform: ${props => props.transform || 'rotate(45deg)'};
`
const BoxArrow = styled.div`
  background: #f8f9fb;
  border-radius: 8px;
  transform: translate(-50%);
  padding: 0.25rem 0.65rem;
`
const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.16);
  border-radius: 8px;
  padding: 0.5rem;
  top: 10px;
  right: 0px;
  z-index: 1;
`
const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`
const BoxEdit = styled.div`
  width: 25px;
  display: flex;
  justify-content: flex-end;
  cursor: ${props => props.cursor || 'none'};
  position: relative;
  /* width: ${props => props.width || '4%'}; */
`
const BoxStaff = styled.div`
  width: 100%;
  border-radius: ${props => props.borderRadius || '0px'};
  padding: 0.75rem 1rem;
  border-color: ${props => props.borderColor || '#e4e8eb'};
  border-style: solid;
  border-width: 2px;
  display: flex;
  align-items: center;
  &:first-child {
    border-radius: ${props => (props.active ? '8px' : '8px 8px 0px 0px')};
    border-width: ${props => (props.active ? '2px' : '2px 2px 0 2px')};
  }
  &:last-child {
    border-radius: ${props => (props.active ? '8px' : '0px 0px 8px 8px')};
    border-width: ${props => (props.active ? '2px' : '0 2px 2px 2px')};
  }
  &:only-child {
    border-radius: 8px;
    border-width: 2px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }
`
const Image = styled.img`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: 0.25rem;
  object-fit: cover;
`
const Image2 = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #e4e8eb;
  margin-left: 0.25rem;
`

const BodyRoomLeft = ({
  roomTypes,
  rooms,
  roomNoneActives,
  onEditClick,
  onEditTypeClick,
  onDeleteTypeClick,
  onDeleteClick,
  activeRoom,
}) => {
  const [state, setState] = useState([])
  const [openEditType, setOpenEditType] = useState(-1)
  const [openEdit, setOpenEdit] = useState(-1)
  const [activeEditId, setActiveEditId] = useState(-1)

  useEffect(() => {
    if (!activeRoom) {
      setOpenEdit(-1)
      setActiveEditId(-1)
      setOpenEditType(-1)
    }
  }, [activeRoom])

  useEffect(() => {
    if (roomTypes.length) {
      const aggregatedGroup = roomTypes.reduce((acc, currentGroupName) => {
        const { _id: roomTypeId, roomTypeName, roomTypeCode } = currentGroupName
        const filteredRooms = rooms.filter(n => n.roomTypeId === roomTypeId)
        // if (!filteredRooms.length) {
        //   return acc
        // }
        const currentGroupIdx = acc.findIndex(g => g.roomTypeId === roomTypeId)
        if (currentGroupIdx === -1) {
          acc.push({
            roomTypeId,
            roomTypeName,
            roomTypeCode,
            open: true,
            rooms: [...filteredRooms],
          })
        } else {
          acc[currentGroupIdx].rooms.push({
            ...filteredRooms,
          })
        }
        return acc
      }, [])
      setState(aggregatedGroup)
    }
  }, [roomTypes, rooms])

  const onArrowClick = (e, index) => {
    const newArrState = [...state]
    newArrState[index].open = !newArrState[index].open
    setState(newArrState)
  }

  const DragHandle = sortableHandle(() => (
    <ContainerCircle>
      <ContainerCircleStyle>
        {Array.from({ length: 9 }).map((_, index) => (
          <BoxCircle key={index}>
            <Circle></Circle>
          </BoxCircle>
        ))}
      </ContainerCircleStyle>
    </ContainerCircle>
  ))

  const SortableItem = SortableElement(
    ({ image, index, value, name, code, _id }) => (
      <BoxStaff
        onClick={e => onEditRoom(e, _id)}
        key={index}
        borderColor={activeEditId === _id ? '#8C56D9' : ''}
        active={activeEditId === _id}
      >
        <DragHandle />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {image ? <Image src={image} /> : <Image2 />}
            <TextLabel paddingLeft='0.5rem'>{`${name} (${code})`}</TextLabel>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BoxEdit onClick={e => onOpenEditClick(e, _id)}>
              {Array.from({ length: 3 }).map((_, index) => (
                <BoxCircle key={index}>
                  <Circle></Circle>
                </BoxCircle>
              ))}
              {openEdit === _id && (
                <DropDown>
                  <Option onClick={onEditRoomClick.bind(this)}>แก้ไข</Option>
                  <Option onClick={() => onDeleteRoomClick()}>ลบ</Option>
                </DropDown>
              )}
            </BoxEdit>
          </div>
        </div>
      </BoxStaff>
    )
  )
  const onOpenEditClick = (e, _id) => {
    e.stopPropagation()
    if (openEdit === _id) {
      setOpenEdit(-1)
    } else {
      setOpenEdit(_id)
    }
  }
  const onEditRoomClick = e => {
    onEditClick(openEdit)
    setActiveEditId(openEdit)
    setTimeout(() => {
      setOpenEdit(-1)
    }, 500)
  }
  const onEditRoom = (e, _id) => {
    e.stopPropagation()
    onEditClick(_id)
    setActiveEditId(_id)
  }
  const onDeleteRoomClick = () => {
    setActiveEditId(openEdit)
    onDeleteClick(openEdit)
  }

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Scrollbar id='setting-bodyRoomLeft1' maxHeight='210px'>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            name={value.roomName}
            image={value.image}
            code={value.roomCode}
            _id={value._id}
            value={value}
          />
        ))}
      </Scrollbar>
    )
  })

  const onSortEnd = async ({ oldIndex, newIndex }, roomTypeId) => {
    const newArrState = [...state]
    const findState = newArrState.findIndex(s => s.roomTypeId === roomTypeId)
    if (findState !== -1) {
      const { rooms } = newArrState[findState]
      newArrState[findState] = {
        ...newArrState[findState],
        rooms: arrayMove(rooms, oldIndex, newIndex),
      }
      await setState(newArrState)
      await callApiUpdateAllRoom(newArrState)
    }
  }

  const callApiUpdateAllRoom = async state => {
    try {
      let number = 1
      let _rooms = state.reduce((acc, info) => {
        const { rooms } = info
        for (const room of rooms) {
          acc.push({ ...room, number: number })
          number++
        }
        return acc
      }, [])
      const { success } = await api.updateAllRoom(_rooms)
      if (!success) {
        return false
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onOpenEditTypeClick = (e, id) => {
    e.stopPropagation()
    if (id === openEditType) {
      setOpenEditType(-1)
    } else {
      setOpenEditType(id)
    }
  }
  const onEditRoomTypeClick = () => {
    onEditTypeClick(openEditType)
  }
  const onDeleteRoomTypeClick = () => {
    onDeleteTypeClick(openEditType)
  }

  return (
    <Container>
      <Scrollbar id='setting-bodyRoomLeft2'>
        {state.map(
          ({ roomTypeId, roomTypeName, roomTypeCode, rooms, open }, index) => (
            <WhiteBox key={index} onClick={e => onArrowClick(e, index)}>
              <BoxHead>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextLabel fontSize='1.25rem' fontWeight='500'>
                    {`${roomTypeName} (${roomTypeCode})`}
                  </TextLabel>
                  &nbsp;&nbsp;
                  <TextLabel
                    fontSize='0.875rem'
                    fontWeight='500'
                    color='#98A1B2'
                  >{`(${rooms.length} ที่)`}</TextLabel>
                </div>
                {/* <Line></Line> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <BoxArrow onClick={e => onArrowClick(e, index)}>
                    <Arrow
                      onClick={e => onArrowClick(e, index)}
                      transform={open ? 'rotate(-135deg)' : ''}
                    ></Arrow>
                  </BoxArrow>
                  <BoxEdit onClick={e => onOpenEditTypeClick(e, roomTypeId)}>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <BoxCircle key={index}>
                        <Circle></Circle>
                      </BoxCircle>
                    ))}
                    {openEditType === roomTypeId && (
                      <DropDown>
                        <Option onClick={() => onEditRoomTypeClick()}>
                          แก้ไข
                        </Option>
                        {!rooms.length && (
                          <Option onClick={() => onDeleteRoomTypeClick()}>
                            ลบ
                          </Option>
                        )}
                      </DropDown>
                    )}
                  </BoxEdit>
                </div>
              </BoxHead>
              {open && (
                <SortableList
                  items={rooms}
                  onSortEnd={e => onSortEnd(e, roomTypeId)}
                  distance={1}
                  axis='xy'
                  helperClass='SortableHelper'
                  useDragHandle
                ></SortableList>
              )}
            </WhiteBox>
          )
        )}
        <div style={{ width: '100%' }}>
          <WhiteBox>
            <BoxHead>
              <TextLabel fontSize='1.25rem' fontWeight='500'>
                ห้องปิดปรับปรุง
              </TextLabel>
              {/* <Line background='#475467'></Line> */}
              <div>
                <BoxArrow
                // onClick={e => onArrowClick(e, index)}
                >
                  <Arrow transform={true ? 'rotate(-135deg)' : ''}></Arrow>
                </BoxArrow>
              </div>
            </BoxHead>
            <SortableList
              items={roomNoneActives}
              onSortEnd={e => onSortEnd(e, -1)}
              distance={1}
              axis='xy'
              helperClass='SortableHelper'
              useDragHandle
            ></SortableList>
          </WhiteBox>
        </div>
      </Scrollbar>
    </Container>
  )
}

export default BodyRoomLeft
