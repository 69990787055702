/* eslint-disable react-hooks/rules-of-hooks */
import { StyledButton } from '@/components/atoms/Button';
import React, { createContext, useState, useContext, ReactNode } from 'react';
import styled from 'styled-components';

// Types for Tab Context
interface TabContextType {
  activeTab: string;
  handleTabChange: (value: string) => void;
}

// Create Tab Context with default undefined values
const TabContext = createContext<TabContextType | undefined>(undefined);

// Styled Components
export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const TabListContainer = styled.div`
  display: flex;
`;

interface TabItemProps {
  isActive?: boolean;
}

export const TabItem = styled(StyledButton).attrs({
  size: 'medium',
})<TabItemProps>`
  margin-right: 5px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? 'var(--primary)' : 'var(--text-default)')};
  background: ${({ isActive }) => (isActive ? 'var(--popup)' : 'var(--popup)')};
  border: ${({ isActive }) => (isActive ? '1px solid var(--primary)' : '1px solid var(--popup)')};
`;

interface TabContentProps {
    isActive?: boolean;
}

export const TabContent = styled.div<TabContentProps>`
  margin-top: 16px;
  border: 2px dashed var(--muted);
  border-radius: var(--radius);
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  height: 100%;
  padding: 16px;
`;

export const StyledTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

// Main Tab Component
interface TabProps {
  active: string;
  onTabChange?: (value: string) => void;
  children: ReactNode;
  className?: string;
}

const Tab: React.FC<TabProps> & {
    List: React.FC<TabListProps>;
    Item: React.FC<TabItemProps>;
    Content: React.FC<TabContentProps>;
} = ({ active, onTabChange, children, className }) => {
  const [activeTab, setActiveTab] = useState(active);

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
    if (onTabChange) onTabChange(newTab);
  };

  return (
    <StyledTabWrapper className={className}>
      <TabContext.Provider value={{ activeTab, handleTabChange }}>
        <TabsContainer>{children}</TabsContainer>
      </TabContext.Provider>
    </StyledTabWrapper>
  );
};

// Tab.List Subcomponent
interface TabListProps {
  children: ReactNode;
}

Tab.List = ({ children }: TabListProps) => {
  return <TabListContainer>{children}</TabListContainer>;
};

// Tab.Item Subcomponent
interface TabItemProps {
  value: string;
  children: ReactNode;
  className?: string;
}

Tab.Item = ({ value, children, className }: TabItemProps) => {
  const context = useContext(TabContext);

  if (!context) {
    return null;
    // throw new Error('Tab.Item must be used within a Tab component');
  }

  const { activeTab, handleTabChange } = context;

  return (
    <TabItem 
    className={className}
    value={value}
    isActive={activeTab === value} onClick={() => handleTabChange(value)}>
      {children}
    </TabItem>
  );
};

// Tab.Content Subcomponent
interface TabContentProps {
  value: string;
  children: ReactNode;
  className?: string;
}

Tab.Content = ({ value, children, className }: TabContentProps) => {
  const context = useContext(TabContext);

  if (!context) {
    return null;
    // throw new Error('Tab.Content must be used within a Tab component');
  }

  const { activeTab } = context;

  return <TabContent
  className={className}
    value={value}
  isActive={activeTab === value}>{children}</TabContent>;
};

export default Tab;
