import React from 'react'
import styled from 'styled-components'

import { getChartColor } from './config'
import Gauge from './Gauge'

import Label from '../../atoms/Label'

import utils from '../../utils'

const ListContainer = styled.div`
  overflow-y: auto;
  padding: 1rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const ListItem = styled.div`
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e4e8eb;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const ListItemName = styled.div`
  display: flex;
  justify-content: space-between;
`

export interface ListViewDataItem {
  name: string
  value: number
}

export interface ListViewProps {
  data: ListViewDataItem[]
  withGauge?: boolean
}

const ListView = ({ data, withGauge = true }: ListViewProps) => {
  const totalValue = data.reduce((acc, entry) => (acc += entry.value || 0), 0)
  const sortedData = data.slice().sort((a, b) => b.value - a.value)

  return (
    <ListContainer>
      {sortedData.map(({ name, value }, index) => (
        <ListItem key={name}>
          <ListItemName>
            <Label size='0.875rem' weight={400} lineCap>
              {name}
            </Label>
            <Label size='1rem' weight={500} lineCap>
              ฿{utils.formatNumber(value || 0)}
            </Label>
          </ListItemName>
          {withGauge && (
            <Gauge
              background={getChartColor(index)}
              baseBackground='#F8F9FB'
              percentage={100 * (value / totalValue)}
            />
          )}
        </ListItem>
      ))}
    </ListContainer>
  )
}

export default ListView
