import { createDynamicIcon } from './Icon'

export const CheckBoxOutlinedDisableIcon = createDynamicIcon(`
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#A0AEC0"/>
</svg>

`)

export const CheckBoxOutlinedIcon = createDynamicIcon(`
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#8C56D9"/>
</svg>
    `)

export const CheckBoxCheckedIcon = createDynamicIcon(`
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" rx="4" fill="url(#paint0_linear_10734_9910)"/>
<path d="M11.7323 5.42969L6.59896 10.563L4.26562 8.22969" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_10734_9910" x1="1.11538" y1="-2.33455e-09" x2="1.22645" y2="16.0469" gradientUnits="userSpaceOnUse">
<stop stop-color="#8C56D9"/>
<stop offset="1" stop-color="#53389E"/>
</linearGradient>
</defs>
</svg>
`)

export const CCheckBoxCheckedDisabledIcon = createDynamicIcon(`
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.4">
<rect width="16" height="16" rx="4" fill="url(#paint0_linear_10734_9913)"/>
<path d="M11.7323 5.42969L6.59896 10.563L4.26562 8.22969" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<linearGradient id="paint0_linear_10734_9913" x1="1.11538" y1="-2.33455e-09" x2="1.22645" y2="16.0469" gradientUnits="userSpaceOnUse">
<stop stop-color="#8C56D9"/>
<stop offset="1" stop-color="#53389E"/>
</linearGradient>
</defs>
</svg>
    `)
