import { GET_DATA, CLEAR_DATA } from '../actions/closeCardAction'

const initState = {
  close: false,
}

const closeCardReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        close: action.payload.data,
      }
    case CLEAR_DATA:
      return {
        ...initState,
      }

    default:
      return state
  }
}

export default closeCardReducers
