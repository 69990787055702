import React from 'react'
import styled, { CSSProperties } from 'styled-components'

//@ts-ignore
import Button2 from '@/atoms/Button2'
//@ts-ignore
import ButtonSave from '../atoms/ButtonSave'
//@ts-ignore
import SaveIcon from '../atoms/SaveIcon'

interface ButtonSettingStyled extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  height: 100;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  position: sticky;
  bottom: 0rem;
  background: white;
  z-index: 2;
`
const Box = styled.div<ButtonSettingStyled>`
  display: flex;
  width: ${props => props.width || '100%'};
  gap: 0.5rem;
`

interface ButtonSettingSaveProps {
  handleClose: () => void
  onSaveClick: () => void
}

const ButtonSettingSave = ({
  handleClose,
  onSaveClick,
}: ButtonSettingSaveProps) => {
  return (
    <Container>
      <Box>
        <Box>
          <Button2
            color='#98A1B2'
            border='2px solid #98A1B2'
            backgroundColor='white'
            fontWeight='600'
            onClick={() => handleClose()}
          >
            ยกเลิก
          </Button2>
        </Box>
        <Box>
          <ButtonSave
            border='2px solid #53389E'
            color='#FFFFFF'
            backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
            backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
            fontWeight='600'
            icon={<SaveIcon size='15' />}
            onClick={() => onSaveClick()}
          >
            บันทึก
          </ButtonSave>
        </Box>
      </Box>
    </Container>
  )
}

export default ButtonSettingSave
