import { GET_STORE } from '../actions/storeAction'

const initState = {
  day: {
    type: Number,
  },
  openTime: {
    hour: { type: String, default: '' },
    minute: { type: String, default: '' },
  },
  closeTime: {
    hour: { type: String, default: '' },
    minute: { type: String, default: '' },
  },
  specialDate: { dateStart: { type: Date }, dateEnd: { type: Date } },
  restDay: {
    type: Boolean,
  },
}

const callApiStoreDateTimeReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_STORE:
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

export default callApiStoreDateTimeReducers
