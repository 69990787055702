import React from 'react'
import { useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'

import Button2 from '../../atoms/Button2'
import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'

//@ts-ignore
import api from '../../api'
import BrandPackage, { getPackageType } from '../../redux/models/BrandPackage'
import ReduxStore from '../../redux/models/ReduxStore'
import { getCurrentStoreRole, UserTypes } from '../../redux/models/User'
import utils from '../../utils'

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
`

type GridBoxProps = { columns: string } & CSSProperties

const GridBox = styled.div<GridBoxProps>`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  border-radius: 8px;
  padding: ${props => props.padding};
`

const Circle = styled.div`
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: gray;
`

export interface BodyProps {
  packages: BrandPackage[]
  onEditClick: (index: number) => void
}

const Body = ({ packages = [], onEditClick }: BodyProps) => {
  const user = useSelector((state: ReduxStore) => state.user)
  const role = getCurrentStoreRole(user)

  const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = event.target.files

      if (files?.length) {
        await api.uploadBrandPackageData(files[0])
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      {role === UserTypes.Admin && (
        <Button2 width='fit-content' backgroundColor='pink' padding='0.5rem'>
          <label htmlFor='upload-image'>ดาวโหลดข้อมูล</label>
          <input
            id='upload-image'
            type='file'
            onChange={onChangeFile}
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </Button2>
      )}
      <Scrollbar id='setting-package' gap='0.5rem'>
        <GridBox columns='2fr 1fr 1fr 1fr 1fr 1fr 0.5fr' padding='1rem'>
          <TextLabel color='#98A1B2'>ชื่อแพ็คเกจ</TextLabel>
          <TextLabel color='#98A1B2'>ประเภท</TextLabel>
          <TextLabel color='#98A1B2'>ราคาขาย</TextLabel>
          <TextLabel color='#98A1B2'>ระยะเวลาใช้งาน</TextLabel>
          <TextLabel color='#98A1B2'>จำนวนที่ขาย</TextLabel>
          <TextLabel color='#98A1B2'>สถานะ</TextLabel>
        </GridBox>
        {packages.map((p, index) => (
          <GridBox
            key={index}
            columns='2fr 1fr 1fr 1fr 1fr 1fr 0.5fr'
            backgroundColor='white'
            padding='1rem'
            onClick={() => onEditClick(index)}
          >
            <TextLabel>{p.name}</TextLabel>
            <TextLabel>{getPackageType(p.type)}</TextLabel>
            <TextLabel>{utils.formatNumber(p.price || 0)}</TextLabel>
            <TextLabel>{p.expiryDay}</TextLabel>
            <TextLabel>จำนวนที่ขาย</TextLabel>
            <TextLabel>{p.status === 1 ? 'active' : 'non-active'}</TextLabel>
            <div style={{ display: 'flex', gap: '0.25rem' }}>
              <Circle></Circle>
              <Circle></Circle>
              <Circle></Circle>
            </div>
          </GridBox>
        ))}
      </Scrollbar>
    </Container>
  )
}

export default Body
