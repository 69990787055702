import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Row } from '@/components/atoms/Flex'
import { Separator } from '@/components/atoms/Separator'
import useOnClickOutside from '@/hook/useOnClickOutside'

import BaseDropdown from './index'

import {
  CheckBoxCheckedIcon,
  CheckBoxOutlinedIcon,
} from '../icons/CheckBoxIcon'

export interface ISelectItem {
  value: string
  children?: React.ReactNode
  key: string
  icon?: React.ReactNode
  subcategory?: string
}

export interface MultipleSelectProps {
  value: string[]
  onValueChange: (values: string[]) => void
  items?: ISelectItem[]
  placeholder?: string
  searchable?: boolean
  selectAll?: boolean
  title?: React.ReactNode
  defaultValue?: string[]
  className?: string
  single?: boolean
  trigger?: React.ReactNode
  applyable?: boolean
  applyText?: string
}

export const TriggerButton = styled.button`
  width: 100%;
  padding: 8px 12px;
  background-color: white;
  border: 1px solid var(--input);
  box-shadow: var(--shadow-sm);
  border-radius: var(--radius);
  cursor: pointer;
  text-align: left;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`

const GroupWrapper = styled.div`
  min-width: 200px;
  border-radius: var(--radius-xs);
  max-height: 260px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const ItemWrapper = styled.div<{ selected: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 10px;
  border-radius: var(--radius-xs);
  color: ${props =>
    props.selected ? 'var(--primary)' : 'var(--text-primary)'};

  &:hover {
    background-color: var(--input);
  }
`

const SelectAllWrapper = styled(ItemWrapper)`
  &:hover {
    background-color: transparent;
    opacity: 0.8;
  }
`

export const DropdownPlaceholder = styled.span`
  color: #aaa;
`

const IconWrapper = styled.span`
  margin-right: 8px;
`

const Tag = styled.div`
  padding: 2px 4px;
  background-color: var(--primary);
  color: var(--text-contrast);
  border-radius: 4px;
  margin-right: 4px;
  display: inline-block;
  font-size: 12px;
`

const ChevronDown = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor'
    width='20'
    height='20'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.707 13.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 11.586l3.293-3.293a1 1 0 111.414 1.414l-4 4z'
    />
  </svg>
)

export const TitleText = styled.span`
  color: var(--text-secondary);
  font-size: 16px;
  padding: 8px 12px;
`

export const SelectItem = ({
  item,
  selectedValues,
  handleItemClick,
  single,
}: {
  item: ISelectItem
  selectedValues: string[]
  handleItemClick: (value: string) => void
  single: boolean
}) => {
  const isSelected = selectedValues.includes(item.key)

  return (
    <ItemWrapper
      key={item.key}
      selected={isSelected}
      onClick={e => {
        e.stopPropagation()
        handleItemClick(item.key)
      }}
    >
      {!single &&
        (isSelected ? (
          <CheckBoxCheckedIcon size={14} />
        ) : (
          <CheckBoxOutlinedIcon size={14} />
        ))}
      {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
      {item.children || item.value}
    </ItemWrapper>
  )
}

export const SearchInputWrapper = styled.div`
  padding: 8px 12px;
`

export const SearchInput = styled.input`
  padding: 8px 12px;
  border: 1px solid var(--input);
  border-radius: var(--radius-sm);
  outline: none;
  caret-height: 5px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  font-size: 14px;
  box-shadow: var(--shadow-sm);
  width: 100%;

  &:hover {
    border-color: var(--primary);
  }

  &:focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 2px var(--primary-light);
  }
`

const Checkbox = ({ checked, onChange, children, style }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        cursor: 'pointer',
        ...style,
      }}
      onClick={onChange}
    >
      {checked ? (
        <CheckBoxCheckedIcon size={14} />
      ) : (
        <CheckBoxOutlinedIcon size={14} />
      )}
      {children}
    </div>
  )
}

const ApplyButtonContainer = styled.div`
  width: 100%;
  bottom: 0;
  background-color: white;
  padding: 8px;
`

const ApplyButton = styled.button`
  height: 24px;
  background: var(--gradient);
  color: white;
  border: none;
  border-radius: var(--radius-sm);
  cursor: pointer;
  font-size: 12px;
  width: 100%;

  &:hover {
    background-color: var(--primary-dark);
  }
`

const SubcategoryWrapper = styled.div`
  
`

const SubcategoryTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 8px;
  font-size: 12px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 4px;
`

const MultipleSelect = ({
  value: selectedValues,
  onValueChange,
  items = [],
  placeholder = 'Select values',
  defaultValue,
  searchable,
  selectAll,
  title,
  className,
  single = false,
  trigger,
  applyable = false,
  applyText = 'Apply',
}: MultipleSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [initialized, setInitialized] = useState(false)
  const [tempSelectedValues, setTempSelectedValues] =
    useState<string[]>(selectedValues)
  const wrapperRef = useRef(null)

  useOnClickOutside(wrapperRef, () => {
    setIsOpen(false)
    if (applyable) {
      setTempSelectedValues(selectedValues)
    }
  })

  const handleToggle = () => {
    console.log(isOpen)
    setIsOpen(!isOpen)
  }
  const handleClose = () => {
    setIsOpen(false)
    setSearchQuery('')
    if (applyable) {
      setTempSelectedValues(selectedValues)
    }
  }

  const handleItemClick = (value: string) => {
    if (single) {
      if (applyable) {
        setTempSelectedValues(tempSelectedValues[0] === value ? [] : [value])
      } else {
        onValueChange(selectedValues[0] === value ? [] : [value])
        setIsOpen(false)
      }
    } else {
      const newValues = tempSelectedValues.includes(value)
        ? tempSelectedValues.filter(v => v !== value)
        : [...tempSelectedValues, value]

      if (applyable) {
        setTempSelectedValues(newValues)
      } else {
        onValueChange(newValues)
      }
    }
  }

  const handleSubcategorySelectAll = (subcategory: string) => {
    const subcategoryItems = items.filter(item => item.subcategory === subcategory)
    const subcategoryKeys = subcategoryItems.map(item => item.key)
    const allSelected = subcategoryKeys.every(key => tempSelectedValues.includes(key))

    let newValues: string[]
    if (allSelected) {
      newValues = tempSelectedValues.filter(value => !subcategoryKeys.includes(value))
    } else {
      newValues = [...new Set([...tempSelectedValues, ...subcategoryKeys])]
    }

    if (applyable) {
      setTempSelectedValues(newValues)
    } else {
      onValueChange(newValues)
    }
  }

  const handleApply = () => {
    onValueChange(tempSelectedValues)
    setIsOpen(false)
  }

  useEffect(() => {
    if (defaultValue && !initialized) {
      onValueChange(defaultValue)
      setTempSelectedValues(defaultValue)
      setInitialized(true)
    }
  }, [defaultValue, initialized, onValueChange])

  useEffect(() => {
    setTempSelectedValues(selectedValues)
  }, [selectedValues])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const filteredItems = useMemo(() => {
    if (!searchQuery) return items
    return items.filter(item =>
      item.value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [searchQuery, items])

  const groupedItems = useMemo(() => {
    const grouped: { [key: string]: ISelectItem[] } = {}
    filteredItems.forEach(item => {
      const subcategory = item.subcategory || 'default'
      if (!grouped[subcategory]) {
        grouped[subcategory] = []
      }
      grouped[subcategory].push(item)
    })
    return grouped
  }, [filteredItems])

  const displayValue = () => {
    if (selectedValues.length > 0) {
      if (single) {
        return (
          <DropdownPlaceholder>
            {items.find(item => item.key === selectedValues[0])?.children ||
              items.find(item => item.key === selectedValues[0])?.value}
          </DropdownPlaceholder>
        )
      }
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
          {selectedValues.map(val => {
            if (selectedValues.indexOf(val) > 1) return null
            const selected = items.find(item => item.key === val)
            return <Tag key={val}>{selected?.children || selected?.value}</Tag>
          })}
          {selectedValues.length > 2 && `+${selectedValues.length - 2}`}
        </div>
      )
    } else {
      return <DropdownPlaceholder>{placeholder}</DropdownPlaceholder>
    }
  }

  return (
    <BaseDropdown
      className={className}
      trigger={
        <TriggerButton>
          {trigger ? trigger : displayValue()}
          {!trigger && <ChevronDown />}
        </TriggerButton>
      }
    >
      <GroupWrapper ref={wrapperRef}>
        <ContentWrapper>
          {searchable && (
            <SearchInputWrapper>
              <SearchInput
                type='text'
                placeholder='Search...'
                onChange={handleSearch}
                onClick={e => e.stopPropagation()}
              />
            </SearchInputWrapper>
          )}
          <Row style={{ justifyContent: 'space-between'}}>
            {title && <TitleText>{title}</TitleText>}
            {!single && selectAll && (
              <SelectAllWrapper
                selected={tempSelectedValues.length === filteredItems.length}
                onClick={() => {
                  const newValues =
                    tempSelectedValues.length === filteredItems.length
                      ? []
                      : filteredItems.map(item => item.key)

                  if (applyable) {
                    setTempSelectedValues(newValues)
                  } else {
                    onValueChange(newValues)
                  }
                }}
              >
                <Checkbox
                  checked={tempSelectedValues.length === filteredItems.length}
                />
                Select All
              </SelectAllWrapper>
            )}
          </Row>
          {(selectAll || searchable) && <Separator />}
          <ContentContainer>
          {Object.entries(groupedItems).map(([subcategory, subcategoryItems]) => (
            <SubcategoryWrapper key={subcategory}>
              <SubcategoryTitle>
              {!single && subcategory !== 'default' && (
                  <Checkbox
                    checked={subcategoryItems.every(item => tempSelectedValues.includes(item.key))}
                    onChange={() => handleSubcategorySelectAll(subcategory)}
                  ></Checkbox>
                )}
                {subcategory !== 'default' && !single && (
                  " (All)"
                )}
              </SubcategoryTitle>
              {subcategoryItems.map(item => (
                <SelectItem
                  key={item.key}
                  item={item}
                  selectedValues={applyable ? tempSelectedValues : selectedValues}
                  handleItemClick={handleItemClick}
                  single={single}
                />
              ))}
            </SubcategoryWrapper>
          ))}
          </ContentContainer>
        </ContentWrapper>
        {applyable && (
          <ApplyButtonContainer>
            <ApplyButton onClick={handleApply}>{applyText}</ApplyButton>
          </ApplyButtonContainer>
        )}
      </GroupWrapper>
    </BaseDropdown>
  )
}

export default MultipleSelect