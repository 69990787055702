import React from 'react'

import Popup from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddBookingChannel from '../settingBook/CardAddBookingChannel'

const PopupAddBookChannel = ({
  handleClose,
  currentIdEdit,
  currentBookChannel,
}) => {
  return (
    <Popup width='calc(100%)' height='100%' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0' height='auto'>
        <CardAddBookingChannel
          handleClose={handleClose}
          currentIdEdit={currentIdEdit}
          currentBookChannel={currentBookChannel}
        />
      </PopupInsideBox>
    </Popup>
  )
}

export default PopupAddBookChannel
