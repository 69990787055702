import React from 'react'

import CardPackageDetail from '../customer/CardPackageDetail'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'

const PopupEditCustomerPackage = ({ handleClose, currentPackage }) => {
  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0' height='auto'>
        <CardPackageDetail
          handleClose={handleClose}
          currentPackage={currentPackage}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupEditCustomerPackage
