import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { DataTableValue } from '@/components/molecules/dataTable/DataTable'

export interface HeaderSearchHistoryType {
  key: string
  label: string
}
export interface RowSearchHistoryType {
  [key: string]: DataTableValue
}

export const headerSearchHistoryAtom = atomWithStorage(
  'jotai-searchHistory-headers',
  [
    {
      key: 'bookingCode',
      label: 'รหัสออร์เดอร์',
    },
    {
      key: 'dateTime',
      label: 'วัน-เวลา',
    },
    {
      key: 'transactionType',
      label: 'ประเภท',
    },
    {
      key: 'name',
      label: 'ชื่อ',
    },
    {
      key: 'phoneNumber',
      label: 'เบอร์โทรศัพท์',
    },
    {
      key: 'services',
      label: 'รายละเอียดบริการ',
    },
    {
      key: 'staffs',
      label: 'พนักงาน',
    },
    {
      key: 'bookingChannel',
      label: 'ช่องทางการจอง',
    },
    {
      key: 'payChannels',
      label: 'ช่องทางการชำระ',
    },
    {
      key: 'total',
      label: 'จำนวนเงิน',
    },
    {
      key: 'payStatus',
      label: 'สถานะการชำระเงิน',
    },
    {
      key: 'status',
      label: 'สถานะบริการ',
    },
    {
      key: 'createBy',
      label: 'สร้าง',
    },
    {
      key: 'editBy',
      label: 'แก้ไขล่าสุด',
    },
  ]
)

export const rowSearchHistoryAtom = atom<RowSearchHistoryType[]>([])

export const searchHistoryDateRangeAtom = atom<{
  startDate: Date
  endDate: Date
}>({
  startDate: new Date(),
  endDate: new Date(),
})

export const searchHistorySearchTermAtom = atom<string>('')

export const searchHistoryFilterAtom = atomWithStorage<any[]>(
  'jotai-searchHistory-filter',
  []
)

export const searchHistoryHeaderFilterAtom = atomWithStorage<
  HeaderSearchHistoryType[]
>('jotai-searchHistory-headerFilter', [])
