import React from 'react'
import styled from 'styled-components'

import TextLabel from '../../atoms/TextLabel'
import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'
import CameraIcon from '../../atoms/CameraIcon'
import UploadImageIcon from '../../atoms/UploadImageIcon'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import ButtonSave from '../../atoms/ButtonSave'
import SelectorNationality from '../selector/SelectorNationality'
import SelectorAge from '../selector/SelectorAge'
import InputTextArea from '../../atoms/InputTextArea'
import InputText from '../../atoms/InputText'
import SelectorOccupation from '../selector/SelectorOccupation'
import SelectorGender from '../selector/SelectorGender'
import useEditCustomer from './useEditCustomer'
import SelectorCountryCode from '../selector/SelectorCountryCode'
import DateSelector from '../datePicker/DateSelector'

const Container = styled.div`
  width: 100%;
  display: flex;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  padding: 1rem 1rem 0;
  position: relative;
`

const Box = styled.div`
  display: flex;
  width: 100%;
  align-items: ${props => props.alignItem || 'unset'};
  flex-direction: ${props => props.flexDirection || 'row'};
`

const BoxStyle = styled.div`
  flex: ${props => props.flex || '1 0 50%'};
  width: ${props => props.width || '50%'};
`

const ImageBox = styled.div`
  border-radius: 16px;
  background: #e4e8eb;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  margin-top: 1rem;
`

const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`

const Image = styled.img`
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  object-fit: cover;
`

const CardAddCustomer = ({ currentCustomer, handleClose }) => {
  const {
    customer,
    errors,
    success,
    genderOptionOpen,
    ageOptionOpen,
    nationalityOptionOpen,
    occupationOptionOpen,
    countryCodeOpen,
    onChange,
    onToggle,
    onImageChange,
    onSave,
  } = useEditCustomer(currentCustomer, handleClose)

  const {
    name,
    firstName,
    lastName,
    phoneNumber,
    birthDay,
    lineId,
    image,
    specialRequest,
    address,
    taxIDNumber,
    email,
    gender,
    age,
    nationality,
    occupation,
    countryCode,
  } = customer

  return (
    <Container>
      <Card>
        <Box className='mb-3' alignItem='flex-end'>
          <BoxStyle flex='1 0 20%' width='20%'>
            <ImageBox>
              <CameraIcon size='40' />
              <ImageUploadIcon>
                <label for='upload-image'>
                  <UploadImageIcon size='30' colorCircle='#8C56D9' />
                </label>
                <InputText
                  id='upload-image'
                  type='file'
                  onChange={onImageChange}
                  style={{ display: 'none', visibility: 'none' }}
                />
              </ImageUploadIcon>
              {image && <Image src={image} />}
            </ImageBox>
          </BoxStyle>
          <BoxStyle flexDirection='column'>
            <TextLabel fontWeight='600'>ชื่อเล่น*</TextLabel>
            <div className='mb-1'></div>
            <InputText
              borderColor={errors['name'] ? '#DB2D1E' : ''}
              width='100%'
              placeholder='ชื่อเล่น'
              value={name}
              onChange={val => onChange(val.target.value, 'name')}
            />
            <div className='mt-2' style={{ display: 'flex', gap: '0.5rem' }}>
              <div>
                <TextLabel fontWeight='600'>ชื่อจริง</TextLabel>
                <div className='mb-1' />
                <InputText
                  width='100%'
                  placeholder='ชื่อจริง'
                  value={firstName}
                  onChange={val => onChange(val.target.value, 'firstName')}
                />
              </div>
              <div>
                <TextLabel fontWeight='600'>นามสกุล</TextLabel>
                <div className='mb-1'></div>
                <InputText
                  width='100%'
                  placeholder='นามสกุล'
                  value={lastName}
                  onChange={val => onChange(val.target.value, 'lastName')}
                />
              </div>
            </div>
          </BoxStyle>
        </Box>
        <Box className='mb-3' flexDirection='column'>
          <TextLabel fontWeight='600'>เพศ</TextLabel>
          <SelectorGender
            name='gender'
            option={gender}
            open={genderOptionOpen}
            onOptionClick={onChange}
            onClick={val => onToggle('genderOptionOpen', val)}
          />
        </Box>
        <Box className='mb-3' flexDirection='column'>
          <TextLabel fontWeight='600'>อายุ</TextLabel>
          <SelectorAge
            name='age'
            option={age}
            open={ageOptionOpen}
            onOptionClick={onChange}
            onClick={val => onToggle('ageOptionOpen', val)}
          />
        </Box>
        <Box className='mb-3' flexDirection='column'>
          <TextLabel fontWeight='600'>สัญชาติ</TextLabel>
          <SelectorNationality
            name='nationality'
            option={nationality}
            open={nationalityOptionOpen}
            onOptionClick={onChange}
            onClick={val => onToggle('nationalityOptionOpen', val)}
          />
        </Box>
        <Box className='mb-3' flexDirection='column'>
          <TextLabel fontWeight='600'>วันเกิด</TextLabel>
          <DateSelector
            width='100%'
            name='birthDay'
            date={birthDay}
            placeholder={'วันเกิด'}
            onChange={onChange}
          />
        </Box>
        <Box className='mb-3' flexDirection='column'>
          <TextLabel fontWeight='600'>อาชีพ</TextLabel>
          <SelectorOccupation
            name='occupation'
            option={occupation}
            open={occupationOptionOpen}
            onOptionClick={onChange}
            onClick={val => onToggle('occupationOptionOpen', val)}
          />
        </Box>
        <Box className='mb-3' flexDirection='column'>
          <TextLabel fontWeight='600'>อีเมล</TextLabel>
          <div className='mb-1'></div>
          <InputText
            placeholder='อีเมล'
            value={email}
            onChange={val => onChange(val.target.value, 'email')}
          />
        </Box>
        <Box className='mb-3' flexDirection='column'>
          <TextLabel fontWeight='600'>เลขประจำตัวผู้เสียภาษี</TextLabel>
          <div className='mb-1'></div>
          <InputText
            placeholder='เลขประจำตัวผู้เสียภาษี'
            value={taxIDNumber}
            onChange={val => onChange(val.target.value, 'taxIDNumber')}
          />
        </Box>
        <Box className='mb-3' flexDirection='column'>
          <TextLabel fontWeight='600'>ที่อยู่</TextLabel>
          <div className='mb-1'></div>
          <InputTextArea
            placeholder='ที่อยู่'
            value={address}
            onChange={val => onChange(val.target.value, 'address')}
          />
        </Box>
        <Box>
          <BoxStyle>
            <TextLabel fontWeight='600'>เบอร์โทรศัพท์*</TextLabel>
          </BoxStyle>
          <BoxStyle>
            <TextLabel fontWeight='600'>Line ID</TextLabel>
          </BoxStyle>
        </Box>
        <Box className='mb-3'>
          <BoxStyle style={{ display: 'flex' }}>
            <SelectorCountryCode
              name='countryCode'
              option={countryCode}
              open={countryCodeOpen}
              error={errors['phoneNumber']}
              onOptionClick={onChange}
              onClick={val => onToggle('countryCodeOpen', val)}
            />
            <InputText
              borderColor={errors['phoneNumber'] ? '#DB2D1E' : ''}
              width='calc(100% - 100px)'
              borderWidth='1px 1px 1px 0'
              borderRadius='0 4px 4px 0'
              placeholder='xxx xxx xxxx'
              value={phoneNumber}
              onChange={val => onChange(val.target.value, 'phoneNumber')}
            />
          </BoxStyle>
          <BoxStyle>
            <InputText
              width='100%'
              placeholder='@line id'
              value={lineId}
              onChange={val => onChange(val.target.value, 'lineId')}
            />
          </BoxStyle>
        </Box>
        <Box flexDirection='column'>
          <TextLabel fontWeight='600'>ความต้องการเฉพาะ</TextLabel>
          <div className='mt-1'></div>
          <InputText
            placeholder='เช่น นวดหนัก'
            value={specialRequest}
            onChange={val => onChange(val.target.value, 'specialRequest')}
          />
        </Box>
        <div
          className='pt-3 pb-3'
          style={{
            background: '#fff',
            display: 'flex',
            width: '100%',
            position: 'sticky',
            bottom: 0,
          }}
        >
          <BoxStyle className='mr-1' flex='1 1 50%' width='50%'>
            <Button2
              border='2px solid #98A1B2'
              backgroundColor='#FFFFFF'
              backgroundColorHover='#E5E7EC'
              color='#475467'
              fontWeight='bold'
              onClick={handleClose}
            >
              ยกเลิก
            </Button2>
          </BoxStyle>
          <BoxStyle className='ml-1' flex='1 1 50%' width='50%'>
            <ButtonSave
              // margin='0.125rem 0 0.125rem 1rem'
              backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
              color='#FFFFFF'
              fontWeight='bold'
              border='2px solid #53389E'
              icon={<SaveIcon size='17' color='#FFFFFF' />}
              onClick={onSave}
            >
              บันทึก
            </ButtonSave>
          </BoxStyle>
        </div>
      </Card>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddCustomer
