import React from 'react'
import { useTranslation } from 'react-i18next'

import SettingHeader from '../../molecules/SettingHeader'

const Head = () => {
  const { t } = useTranslation()

  return <SettingHeader topic={t('finance')} name={t('cahier')} />
}

export default Head
