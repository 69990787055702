import React, { useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import InputText from '../../atoms/InputText'
import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'

import auth from '../../auth'
import utils from '../../utils'

const CloseRTL = styled.div`
  height: 15px;
  width: 1.5px;
  background-color: #23272a;
  transform: rotate(-45deg);
  z-index: 1;
  cursor: pointer;
`
const CloseLTR = styled.div`
  height: 15px;
  width: 1.5px;
  background-color: #23272a;
  transform: rotate(90deg);
  z-index: 2;
  cursor: pointer;
`

const PopupAddAccount = ({ handleClose, onSave }) => {
  const options = [
    { value: '1', label: 'ธนาคารกสิกรไทย' },
    { value: '2', label: 'ธนาคารไทยพานิช' },
    { value: '3', label: 'ธนาคารกรุงเทพ' },
    { value: '4', label: 'ธนาคารกรุงไทย' },
    { value: '5', label: 'ธนาคารออมสิน' },
    { value: '6', label: 'ธนาคารทหารไทย' },
    { value: '7', label: 'กรุงศรีอยุธยา' },
    { value: '8', label: 'เกียรตินาคิน' },
  ]
  const [optionsDefault, setOptionsDefault] = useState(options[0])
  const [accountName, setAccountName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [promptPay, setPromptPay] = useState('')
  const [checkAccountNumber, setCheckAccountNumber] = useState(false)

  const customStyles = {
    container: (provided, state, innerProps) => ({
      ...provided,
      width: '100%',
    }),
    option: (provided, state, innerProps) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#B28AB4' : '',
      width: '100%',
    }),
    control: provided => ({
      ...provided,
      height: '44.5px',
      border: '2px solid #e4e8eb',
      borderRadius: '8px',
      width: '100%',
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#B28AB4' : 'white',
      width: '100%',
    }),
  }

  const onChangeAccountNumber = value => {
    if (utils.checkNumber(value)) {
      setAccountNumber(value)
    } else if (!value) {
      setAccountNumber(value)
    }
  }

  const onChangePromptPay = value => {
    if (utils.checkNumber(value)) {
      setPromptPay(value)
    } else if (!value) {
      setPromptPay(value)
    }
  }

  const onSaveClick = async () => {
    if (!accountNumber) {
      setCheckAccountNumber(true)
      return false
    } else {
      setCheckAccountNumber(false)
    }
    try {
      const create = {
        payChannelTypeId: 3,
        payChannelId: 0,
        payChannel: accountName,
        account: {
          bank: optionsDefault.value,
          accountName: accountName,
          accountNumber: accountNumber,
          promptPay: promptPay,
        },
        storeId: auth.getStoreId(),
      }
      onSave(create)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='35%'
        height='auto'
        padding='1rem 1rem 2rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
        alignItems='flex-start'
        borderRadius='8px'
      >
        <div
          className='mr-2'
          style={{ marginLeft: 'auto' }}
          onClick={handleClose}
        >
          <CloseRTL>
            <CloseLTR></CloseLTR>
          </CloseRTL>
        </div>

        <div className='mb-3' style={{ width: '100%', textAlign: 'center' }}>
          <TextLabel color='#8C56D9' fontWeight='600' fontSize='1.2rem'>
            เพิ่มบัญชีธนาคาร
          </TextLabel>
        </div>

        <TextLabel fontWeight='600'>ธนาคาร</TextLabel>
        <div className='mt-2'></div>
        <Select
          value={optionsDefault}
          options={options}
          styles={customStyles}
          onChange={val => setOptionsDefault(val)}
        />
        <div className='mt-3'></div>
        <TextLabel fontWeight='600'>ชื่อบัญชี</TextLabel>
        <div className='mt-2'></div>
        <InputText
          boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
          padding='0.5rem 1rem'
          value={accountName}
          onChange={val => setAccountName(val.target.value)}
        />
        <div className='mt-3' style={{ display: 'flex', width: '100%' }}>
          <div className='mr-1' style={{ width: '50%' }}>
            <TextLabel fontWeight='600'>เลขที่บัญชี*</TextLabel>
          </div>
          <div className='ml-1' style={{ width: '50%' }}>
            <TextLabel fontWeight='600'>พร้อมเพย์</TextLabel>
          </div>
        </div>
        <div className='mt-2 mb-4' style={{ display: 'flex', width: '100%' }}>
          <div className='mr-1' style={{ width: '50%' }}>
            <InputText
              boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
              padding='0.5rem 1rem'
              border={checkAccountNumber ? '2px solid #DB2D1E' : ''}
              value={accountNumber}
              onChange={val => onChangeAccountNumber(val.target.value)}
            />
          </div>
          <div className='ml-1' style={{ width: '50%' }}>
            <InputText
              boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
              padding='0.5rem 1rem'
              value={promptPay}
              onChange={val => onChangePromptPay(val.target.value)}
            />
          </div>
        </div>
        <Button2
          backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
          backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
          color='white'
          icon={<SaveIcon size='18' />}
          onClick={onSaveClick.bind(this)}
        >
          บันทึก
        </Button2>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddAccount
