// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbar-show-slip {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin: 0 auto; */
  padding-right: 0.5rem;
}

.scrollbar-show-slip::-webkit-scrollbar {
  width: 5px;
  padding: auto;
}
.scrollbar-show-slip::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin: 0.5rem 0 0rem 0;
}

.scrollbar-show-slip::-webkit-scrollbar-thumb {
  background-color: #8c56d9;
  outline: #e4e8eb;
  border-radius: 10px;
  margin-bottom: 10px;
}

.scrollbar-history {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin: 0 auto; */
  padding-right: 0.5rem;
}

.scrollbar-history::-webkit-scrollbar {
  width: 5px;
  padding: auto;
}
.scrollbar-history::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin: 0.5rem 0 0rem 0;
}

.scrollbar-history::-webkit-scrollbar-thumb {
  background-color: #8c56d9;
  outline: #e4e8eb;
  border-radius: 10px;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/popups/Scrollbar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,aAAa;AACf;AACA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,aAAa;AACf;AACA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".scrollbar-show-slip {\n  max-height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  /* margin: 0 auto; */\n  padding-right: 0.5rem;\n}\n\n.scrollbar-show-slip::-webkit-scrollbar {\n  width: 5px;\n  padding: auto;\n}\n.scrollbar-show-slip::-webkit-scrollbar-track {\n  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);\n  border-radius: 10px;\n  margin: 0.5rem 0 0rem 0;\n}\n\n.scrollbar-show-slip::-webkit-scrollbar-thumb {\n  background-color: #8c56d9;\n  outline: #e4e8eb;\n  border-radius: 10px;\n  margin-bottom: 10px;\n}\n\n.scrollbar-history {\n  max-height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  /* margin: 0 auto; */\n  padding-right: 0.5rem;\n}\n\n.scrollbar-history::-webkit-scrollbar {\n  width: 5px;\n  padding: auto;\n}\n.scrollbar-history::-webkit-scrollbar-track {\n  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);\n  border-radius: 10px;\n  margin: 0.5rem 0 0rem 0;\n}\n\n.scrollbar-history::-webkit-scrollbar-thumb {\n  background-color: #8c56d9;\n  outline: #e4e8eb;\n  border-radius: 10px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
