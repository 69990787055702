import { Time } from "@/redux/models/BookOrder";

export const cn = (...classes: (string | undefined)[]) => classes.filter(Boolean).join(' ')

export const formatDateDistance = (from: Date, to: Date) => {

    const date1 = new Date(from).getTime();
    const date2 = new Date(to).getTime();

    const diffInMinutes = Math.abs(Math.round((date2 - date1) / (1000 * 60)));
    
    if (diffInMinutes < 60) {
      return `${diffInMinutes} min`;
    } else if (diffInMinutes < 1440) { // Less than 24 hours
      const hours = Math.floor(diffInMinutes / 60);
      const minutes = diffInMinutes % 60;
      
      if (minutes === 0) {
        return `${hours} hr`;
      } else {
        return `${hours} hr ${minutes} min`;
      }
    } else {
      const hours = Math.floor(diffInMinutes / 60);
      return `${hours} hr`;
    }
  };

export function customGetDay(date: Date) {
  const standardDay = date.getDay();
  return standardDay === 0 ? 6 : standardDay - 1;
}

export function isTimeBetween(checkTime: Time, openTime: Time, closeTime: Time): boolean {
  // Convert all times to minutes since midnight
  const checkMinutes = +checkTime.hour * 60 + +checkTime.minute;
  const openMinutes = +openTime.hour * 60 + +openTime.minute;
  let closeMinutes = +closeTime.hour * 60 + +closeTime.minute;

  // If close time is earlier than open time, assume it's on the next day
  if (closeMinutes <= openMinutes) {
      closeMinutes += 24 * 60; // Add 24 hours worth of minutes
  }

  // Normalize check time if it's after midnight and open time is before midnight
  let normalizedCheckMinutes = checkMinutes;
  if (checkMinutes < openMinutes && openMinutes > closeMinutes % (24 * 60)) {
      normalizedCheckMinutes += 24 * 60;
  }

  return normalizedCheckMinutes >= openMinutes && normalizedCheckMinutes < closeMinutes;
}