import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

//@ts-ignore
import Button2 from '../../../atoms/Button2'

interface BookingButtonStyle extends CSSProperties {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const FlexBox = styled.div<BookingButtonStyle>`
  display: flex;
  gap: 0.5rem;
`

interface BookingButtonProps {
  date: Date
  currentDate: Date
  onBookingClick: (transactionType: string, staffId?: number) => void
}

const BookingButton = ({
  date,
  currentDate,
  onBookingClick,
}: BookingButtonProps) => {
  const { t } = useTranslation()

  const checkDateIsBeforeCurrentDate = (date: Date, currentDate: Date) => {
    if (dayjs(date).isBefore(dayjs(currentDate), 'day')) {
      return true
    } else {
      return false
    }
  }

  return (
    <Container>
      <FlexBox>
        <Button2
          border='1px solid #8C56D9'
          borderRadius='4px'
          color='#8C56D9'
          fontWeight='500'
          margin='0'
          padding='0.5rem 0'
          onClick={() => onBookingClick('package')}
        >
          {t('buyPackage')}
        </Button2>
        <Button2
          border='1px solid #8C56D9'
          borderRadius='4px'
          color='#8C56D9'
          fontWeight='500'
          margin='0'
          padding='0.5rem 0'
          onClick={() => onBookingClick('product')}
        >
          Product
          {/* {t('buyPackage')} */}
        </Button2>
        <Button2
          backgroundColor={
            checkDateIsBeforeCurrentDate(date, currentDate)
              ? '#98A1B2'
              : 'linear-gradient(221.86deg, #8C56D9 2.71%, #53389E 97.79%)'
          }
          border={
            checkDateIsBeforeCurrentDate(date, currentDate)
              ? '1px solid #98A1B2'
              : '1px solid #8C56D9'
          }
          backgroundColorHover={
            checkDateIsBeforeCurrentDate(date, currentDate) ? '#98A1B2' : ''
          }
          borderRadius='4px'
          color={
            checkDateIsBeforeCurrentDate(date, currentDate)
              ? '#F9F7FD'
              : '#FFFFFF'
          }
          fontWeight='500'
          margin='0'
          padding='0.5rem'
          onClick={() => onBookingClick('book')}
        >
          Book
        </Button2>
        <Button2
          backgroundColor={
            checkDateIsBeforeCurrentDate(date, currentDate)
              ? '#98A1B2'
              : 'linear-gradient(221.86deg, #8C56D9 2.71%, #53389E 97.79%)'
          }
          border={
            checkDateIsBeforeCurrentDate(date, currentDate)
              ? '1px solid #98A1B2'
              : '1px solid #8C56D9'
          }
          backgroundColorHover={
            checkDateIsBeforeCurrentDate(date, currentDate) ? '#98A1B2' : ''
          }
          borderRadius='4px'
          color={
            checkDateIsBeforeCurrentDate(date, currentDate)
              ? '#F9F7FD'
              : '#FFFFFF'
          }
          fontWeight='500'
          margin='0'
          padding='0.5rem 0'
          //   onClick={() => onBookingClick('walkIn')}
        >
          Group
        </Button2>
      </FlexBox>
    </Container>
  )
}

export default BookingButton
