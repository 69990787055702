import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'

//@ts-ignore
import Calendar2 from '../datePicker/Calendar2'
//@ts-ignore
import Time3 from '../timePicker/Time3'

import Arrow from '../../atoms/Arrow'
import DateIcon5 from '../../atoms/DateIcon5'
import TextLabel from '../../atoms/TextLabel'
import TimeIcon from '../../atoms/TimeIcon'

import useOnClickOutside from '../../hook/useOnClickOutside'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import { BookOrder } from '../../redux/models/BookOrder'
import utils from '../../utils'

interface DateTimeStyle extends CSSProperties {}

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`

const Box = styled.div<DateTimeStyle>`
  background-color: rgb(255, 255, 255);
  padding: 0.35rem 1rem 0.35rem 0rem;
  background: #ffffff;
  border: 1px solid #f4f5f7;
  border-radius: 8px;
  cursor: pointer;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  min-width: ${props => props.minWidth || ''};
  border: ${props => props.border || '1px solid #8C56D9'};
`

const BoxData = styled.div<DateTimeStyle>`
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  position: absolute;
  padding: 0.5rem 1rem;
  top: ${props => props.top || '3rem'};
  z-index: 8;
  min-width: ${props => props.width || '313px'};
`

const BoxIcon = styled.div<DateTimeStyle>`
  background-color: ${props => props.backgroundColor || '#8C56D9'};
  height: calc(100% + 0.7rem);
  border-radius: 5px 0px 0px 5px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface TimeState {
  hour: string
  minute: string
}

interface DateTimeProps {
  bookingOrderState: BookOrder
  storeOpenCloseTime: { openTime: TimeState; closeTime: TimeState }
}

const DateTime = ({ bookingOrderState, storeOpenCloseTime }: DateTimeProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { date, time, endTime, serviceTime } = bookingOrderState

  const refCalendar = useRef<HTMLDivElement>(null)
  const refTime = useRef<HTMLDivElement>(null)

  const [openCalendar, setOpenCalendar] = useState(false)
  const [openTime, setOpenTime] = useState(false)

  useOnClickOutside(refCalendar, () => {
    setOpenCalendar(false)
  })

  useOnClickOutside(refTime, () => {
    setOpenTime(false)
  })

  const onDateChange = (date: Date) => {
    if (date) dispatch(getOrderBooking({ date }))
  }

  const showCalendar = () => {
    return (
      <Calendar2
        labelFontSize='1rem'
        ranged={false}
        selectedDate={date}
        onDateChange={onDateChange}
        highlightColor='#8C56D9'
        fontwardable={true}
        backwardable={true}
      />
    )
  }

  const timeActive = (hour: number, minute: number) => {
    dispatch(
      getOrderBooking({ time: { hour: hour + '', minute: minute + '' } })
    )
  }

  const showTime = () => {
    return (
      <Time3
        hourNow={time.hour}
        minuteNow={time.minute}
        start={parseInt(storeOpenCloseTime.openTime.hour)}
        close={parseInt(storeOpenCloseTime.closeTime.hour)}
        timeActive={timeActive}
      />
    )
  }

  return (
    <Container>
      <Box
        onClick={() => setOpenCalendar(!openCalendar)}
        ref={refCalendar}
        minWidth='263px'
      >
        <BoxIcon>
          <div>
            <DateIcon5 size='18' color='#FFF' />
          </div>
        </BoxIcon>

        <TextLabel fontWeight='500' fontSize='1.15rem'>
          {`${utils.formatDate(date, 'dddd')}, ${utils.formatDate(
            date,
            'DD MMMM YYYY'
          )}`}
        </TextLabel>
        <div>
          <Arrow transform='rotate(45deg)' />
        </div>
        {openCalendar && <BoxData>{showCalendar()}</BoxData>}
      </Box>
      <Box
        onClick={() => setOpenTime(!openTime)}
        ref={refTime}
        border='1px solid #32D582'
      >
        <BoxIcon backgroundColor='#32D582'>
          <TimeIcon size='18' color='#FFF' />
        </BoxIcon>

        <TextLabel fontWeight='500' fontSize='1.15rem'>
          {`${t('serviceTime')}`}
        </TextLabel>
        <TextLabel fontWeight='500' fontSize='1.15rem' color='#8C56D9'>
          {`${utils.formatTime(
            +time.hour > 23 ? +time.hour - 24 + '' : time.hour
          )}:${utils.formatTime(time.minute)}`}
        </TextLabel>

        <TextLabel fontWeight='500' fontSize='1.15rem'>
          {`- ${utils.formatTime(
            +endTime.hour > 23 ? +endTime.hour - 24 + '' : endTime.hour
          )}:${utils.formatTime(endTime.minute)} น.`}
        </TextLabel>
        <div>
          <Arrow transform='rotate(45deg)' />
        </div>
        {openTime && <BoxData width='auto'>{showTime()}</BoxData>}
      </Box>
    </Container>
  )
}

export default DateTime
