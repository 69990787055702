export const LOGIN_URL = '/login'
export const LOGIN_PIN_URL = '/login-user-pin'
export const FORGET_PASSWORD_URL = '/forget-password'
export const FORGET_PIN_URL = '/forget-pin'
export const HOME_URL = '/'
export const ADMIN_URL = '/admin'
export const SETTING_PRODUCT_URL = '/setting-product'
export const SETTING_STAFF_URL = '/setting-staff'
export const SETTING_BOOKING_TYPE_URL = '/setting-booking-type'
export const SETTING_ROLE_URL = '/setting-role'
export const SETTING_STORE_URL = '/setting-store'
export const GOOGLE_MAP_URL = '/GoogleMap'
export const PAYMENT_URL = '/pagePayment2'
export const PAY_WAGE_URL = '/payWage'
export const STAFF_URL = '/staff'
export const REPORT_URL = '/report'
export const CASHIER_URL = '/cashier'
export const TEST_URL = '/test'
export const SETTING_CUSTOMER_URL = '/setting-customer'
export const SETTING_PROMOTION_URL = '/setting-promotion'
export const RESET_PASSWORD_URL = '/reset-password'
export const RESET_PIN_URL = '/reset-pin'
export const SETTING_ROOM_URL = '/setting-room'
export const SEARCH_HISTORY_URL = '/search-history'
export const SETTING_PROFILE_URL = '/setting-profile'
export const SETTING_BILL_URL = '/setting-bill'
export const SETTING_PACKAGE_URL = '/setting-package'
export const CUSTOMERS_URL = '/customers'
export const NOTIFICATIONS_URL = '/notifications'
export const BOOKING_URL = '/book'

export const DISABLED_ALL_URLS = [
  HOME_URL,
  BOOKING_URL,
  CASHIER_URL,
  STAFF_URL,
  SETTING_STAFF_URL,
  SETTING_ROLE_URL,
  SETTING_BOOKING_TYPE_URL,
  SETTING_STORE_URL,
  SETTING_PROMOTION_URL,
  SETTING_ROOM_URL,
  SETTING_BILL_URL,
  SETTING_PRODUCT_URL,
  SETTING_PACKAGE_URL,
]
