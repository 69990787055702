import { GET_SERVICE_GROUP } from '../actions/dataServiceGroupAction'

const initState = {
  headGroup: '',
  Id: '',
  groupName: '',
  storeId: '',
}

const dataServiceGroupReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_SERVICE_GROUP:
      return {
        ...state,
        ...action.payload.serviceGroup,
      }
    default:
      return state
  }
}

export default dataServiceGroupReducers
