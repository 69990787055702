import { combineReducers } from 'redux'
import dataServiceReducers from './reducers/dataServiceReducers'
import typeOrderReducers from './reducers/typeOrderReducers'
import dataStaffReducers from './reducers/dataStaffReducers'
import dataStaffTypeReducers from './reducers/dataStaffTypeReducers'
import dataSalaryReducers from './reducers/dataSalaryReducers'
import dataTypeBookingReducers from './reducers/dataTypeBookingReducers'
import dataUserReducers from './reducers/dataUserReducers'
import dataStoreReducers from './reducers/dataStoreReducers'
import filterReducers from './reducers/filterReducers'
import dataStaffCostReducers from './reducers/dataStaffCostReducers'
import dataExportReducers from './reducers/dataExportReportReducers'
import dataPopupToBookReducers from './reducers/dataPopupToBookReducers'
import dataBookToPopupReducers from './reducers/dataBookToPopupReducers'
import dataCustomerReducers from './reducers/dataCustomerReducers'
import dataHistoryReducers from './reducers/dataHistoryReducers'
import dataCargoReducers from './reducers/dataCargoReducers'
import dataServiceGroupReducers from './reducers/dataServiceGroupReducers'
import closeCardReducers from './reducers/closeCardReducers'
import dataCommissionReducers from './reducers/dataCommissionReducers'
import bookingEditDetailReducers from './reducers/bookingEditDetailReducers'
import staffBreakTimeReducers from './reducers/staffBreakTimeReducers'
import incomeAndExpenseReducers from './reducers/incomeAndExpenseReducers'
import staffQueueReducers from './reducers/staffQueueReducers'
import buttonMenuSettingStaffReducers from './reducers/buttonMenuSettingStaffReducers'
import buttonSettingHeadStaffTypeReducers from './reducers/buttonSettingHeadStaffTypeReducers'
import bookingHistoryReducers from './reducers/bookingHistoryReducers'
import storeReducer from './reducers/storeReducer'
import callApiStoreDateTimeReducers from './reducers/callApiStoreDateTimeReducers'
import callApiServicesReducers from './reducers/callApiServicesReducers'
import callApiServiceGroupReducers from './reducers/callApiServiceGroupReducers'
import callApiBookingChannelReducers from './reducers/callApiBookingChannelReducers'
import callApiStaffTypeReducers from './reducers/callApiStaffTypeReducers'
import callApiStaffReducers from './reducers/callApiStaffReducers'
import callApiPayChannelReducers from './reducers/callApiPayChannelReducers'
import callApiBookingHistoryReducers from './reducers/callApiBookingHistoryReducers'
import searchCustomerReducers from './reducers/searchCustomerReducers'
import userReducer from './reducers/userReducer'
import pageReducer from './reducers/pageReducer'
import notificationReducers from './reducers/notificationReducers'
import payPackageReducers from './reducers/payPackageReducers'
import brandReducer from './reducers/brandReducer'

import staffSlice from './slices/staffSlice'

const rootReducer = combineReducers({
  dataServiceReducers,
  typeOrderReducers,
  dataStaffReducers,
  dataStaffTypeReducers,
  dataSalaryReducers,
  dataTypeBookingReducers,
  dataUserReducers,
  dataStoreReducers,
  filterReducers,
  dataStaffCostReducers,
  dataExportReducers,
  dataPopupToBookReducers,
  dataBookToPopupReducers,
  dataCustomerReducers,
  dataHistoryReducers,
  dataCargoReducers,
  dataServiceGroupReducers,
  closeCardReducers,
  dataCommissionReducers,
  bookingEditDetailReducers,
  staffBreakTimeReducers,
  incomeAndExpenseReducers,
  staffQueueReducers,
  buttonMenuSettingStaffReducers,
  buttonSettingHeadStaffTypeReducers,
  bookingHistoryReducers,
  callApiStoreDateTimeReducers,
  callApiServicesReducers,
  callApiServiceGroupReducers,
  callApiBookingChannelReducers,
  callApiStaffTypeReducers,
  callApiStaffReducers,
  callApiPayChannelReducers,
  callApiBookingHistoryReducers,
  searchCustomerReducers,
  page: pageReducer,
  notificationReducers,
  payPackageReducers,
  store: storeReducer,
  user: userReducer,
  brand: brandReducer,
  staff: staffSlice
})

export default rootReducer
