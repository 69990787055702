import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  &:hover {
    user-select: none;
    cursor: pointer;
  }
`

const EyeCloseIcon = ({ size, color, onClick }) => (
  <SVG
    width={size || '33'}
    height={size || '32'}
    viewBox='0 0 33 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_4001_5356)'>
      <path
        d='M16.5746 17.9558C16.3365 18.051 16.146 18.0987 15.8602 18.0987C14.8124 18.0987 13.9551 17.2414 13.9551 16.1935C13.9551 15.9554 14.0027 15.7173 14.098 15.4791L12.0023 13.3835C11.4308 14.1455 11.0974 15.1457 11.0974 16.1935C11.0974 18.8131 13.2407 20.9564 15.8602 20.9564C16.908 20.9564 17.8606 20.623 18.6703 20.0514L16.5746 17.9558Z'
        fill='#98A1B2'
      />
      <path
        d='M15.7651 9.28749C15.8127 9.28749 15.8603 9.28749 15.908 9.28749C21.0042 9.28749 25.8146 11.7641 28.8152 15.9078C28.9581 16.0983 28.9581 16.3364 28.8152 16.527C28.0055 17.67 27.0529 18.6702 25.9575 19.5275L28.0055 21.5755C29.1962 20.5753 30.244 19.4799 31.1013 18.2416C31.9586 17.0509 31.9586 15.4791 31.1013 14.2884C27.6721 9.57325 22.1472 6.52505 15.8603 6.52505C14.9078 6.52505 14.0028 6.62031 13.0979 6.71557L15.7651 9.28749Z'
        fill='#98A1B2'
      />
      <path
        d='M20.9089 22.29C19.2895 22.8139 17.6225 23.0996 15.9079 23.0996C10.8117 23.0996 6.00125 20.623 3.00068 16.4793C2.8578 16.2888 2.8578 16.0507 3.00068 15.8602C4.62004 13.6216 6.85856 11.8118 9.38285 10.6687L7.19196 8.57306C4.57241 9.90665 2.33389 11.8594 0.666905 14.1932C-0.190401 15.3839 -0.190401 16.9556 0.666905 18.1463C4.09613 22.8615 9.62099 25.9097 15.9079 25.9097C18.4798 25.9097 20.9089 25.3858 23.1474 24.4332L20.9089 22.29Z'
        fill='#98A1B2'
      />
      <path
        d='M31.1011 31.9108C30.7201 31.9108 30.3867 31.7679 30.1009 31.4822L1.04779 2.42903C0.476256 1.8575 0.476256 0.952562 1.04779 0.428653C1.61933 -0.142884 2.52426 -0.142884 3.04817 0.428653L32.1013 29.4818C32.6729 30.0533 32.6729 30.9583 32.1013 31.4822C31.8632 31.7679 31.4822 31.9108 31.1011 31.9108Z'
        fill='#98A1B2'
      />
    </g>
    <defs>
      <clipPath id='clip0_4001_5356'>
        <rect width='32.53' height='31.9108' fill='white' />
      </clipPath>
    </defs>
  </SVG>
)

export default EyeCloseIcon
