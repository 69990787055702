import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  CustomerOrder,
  getInitialServiceBookOrder,
  ServiceOrder,
  StaffOrder,
} from '@/redux/models/BookOrder'

//@ts-ignore
import api from '../../../../api'
import { getOrderBooking } from '../../../../redux/actions/bookingHistoryAction'
import Room from '../../../../redux/models/Room'
import RoomType from '../../../../redux/models/RoomType'
import Staff from '../../../../redux/models/Staff'
import utils from '../../../../utils'
import logicEndTime from '../../logicEndTime'
import logicRoomOrder from '../../logicRoomOrder'
import logicServiceCouple from '../../logicServiceCouple'
import logicStaffOrder from '../../logicStaffOrder'
import { RoomOrderState, StaffOrderState } from '../../types'

interface useCardCustomerProps {
  customerIndex: number
  staffOrders: StaffOrderState[]
  roomOrders: RoomOrderState[]
  currentDate: Date
}

export interface activeOpenState {
  customerIndex: number
  serviceIndex: number
  staffIndex: number
  roomIndex: number
  type: string
}

export interface RoomForServiceState {
  // order: number
  // duration: number
  // detail: number
  // roomTypes: roomTypeState[]
  roomTypeId: number
  roomTypeName: string
  roomTypeCode: string
  private: number
  order: number
  rooms: roomState[]
}

interface roomTypeState {
  roomTypeId: number
  roomTypeName: string
  roomTypeCode: string
  order: number
  rooms: roomState[]
}

export interface roomState {
  free: boolean
  roomId: number
  roomName: string
  roomCode: string
}

interface openWarning {
  type: string
  body: Record<string, unknown>
}

const useCardCustomer = ({
  customerIndex,
  staffOrders,
  roomOrders,
  currentDate,
}: useCardCustomerProps) => {
  const dispatch = useDispatch()

  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const {
    customers,
    date,
    serviceCount,
    focusedCustomerIndex,
    time,
    bookingType,
  } = bookingOrderState

  const [roomsForService, setRoomsForService] = useState<RoomForServiceState[]>(
    []
  )

  const [activeOpen, setActiveOpen] = useState<activeOpenState>({
    customerIndex: customerIndex,
    serviceIndex: -1,
    staffIndex: -1,
    roomIndex: -1,
    type: '',
  })
  const [openWarning, setOpenWarning] = useState<openWarning>({
    type: '',
    body: {},
  })
  const [activeOpenCustomer, setActiveOpenCustomer] = useState<{
    customerIndex: number
    serviceIndex: number
    listCustomer: number[]
  }>({
    customerIndex: -1,
    serviceIndex: -1,
    listCustomer: [],
  })

  const onDeleteService = (serviceIndex: number) => {
    let newArrCustomer = utils.deepClone(customers)
    let price = newArrCustomer[customerIndex].services[serviceIndex].price

    newArrCustomer = logicServiceCouple.removeCustomerServiceCouple(
      newArrCustomer,
      customerIndex,
      newArrCustomer[customerIndex].services[serviceIndex].serviceId,
      newArrCustomer[customerIndex].services[serviceIndex].time
    )

    const maxServiceTime = logicEndTime.checkServiceMaxTime(newArrCustomer)
    const endTime = utils.convertTime(bookingOrderState.time) + maxServiceTime

    dispatch(
      getOrderBooking({
        customers: newArrCustomer,
        serviceCount: serviceCount - 1,
        total: bookingOrderState.total - price,
        endTime: utils.convertTimeToHourMinute(endTime),
        serviceTime: maxServiceTime,
      })
    )
  }

  const onOpenSelectClick = async (
    serviceIndex: number,
    index: number,
    type: string
  ) => {
    try {
      if (type === 'roomIndex') {
        getRooms(serviceIndex, index)
      }

      setActiveOpen(prev => ({
        ...prev,
        customerIndex,
        serviceIndex,
        [type]: index,
        type: type,
      }))
    } catch (err) {
      console.log(err)
    }
  }

  const getRooms = async (serviceIndex: number, index: number) => {
    try {
      const newArrCustomer = [...customers]

      const serviceId =
        newArrCustomer[customerIndex].services[serviceIndex].serviceId
      const serviceTime =
        newArrCustomer[customerIndex].services[serviceIndex].time

      let { data: roomTypes }: { data: RoomType[] } =
        await api.getRoomTypeByServiceId(serviceId)

      roomTypes = roomTypes
        .map(roomType => ({
          ...roomType,
          serviceSpecificRoomTypes: roomType.serviceSpecificRoomTypes.filter(
            service => service.serviceTime === serviceTime
          ),
        }))
        .filter(roomType => roomType.serviceSpecificRoomTypes.length > 0)

      let { data: rooms }: { data: Room[] } = await api.getRoomByServiceId(
        serviceId
      )

      rooms = rooms
        .map(rooms => ({
          ...rooms,
          serviceSpecificRooms: rooms.serviceSpecificRooms.filter(
            service => service.serviceTime === serviceTime
          ),
        }))
        .filter(rooms => rooms.serviceSpecificRooms.length > 0)

      const roomServices = roomTypes.reduce(
        (acc: RoomForServiceState[], info) => {
          const {
            private: roomPrivate,
            serviceSpecificRoomTypes,
            _id,
            roomTypeName,
            roomTypeCode,
          } = info

          const { duration, order } = serviceSpecificRoomTypes[0]

          const filterRooms = rooms
            .filter(
              r =>
                r.roomTypeId === _id &&
                r.serviceSpecificRooms.map(s => s.duration === duration)
            )
            .map(item => ({
              roomId: item._id,
              roomName: item.roomName,
              roomCode: item.roomCode,
              free: true,
            }))

          acc.push({
            roomTypeId: _id,
            roomTypeName,
            roomTypeCode,
            private: roomPrivate,
            order,
            rooms: filterRooms,
          })

          return acc
        },
        []
      )

      setRoomsForService(roomServices)
    } catch (err) {
      console.log(err)
    }
  }

  const onToggle = () => {
    setActiveOpen({
      customerIndex: -1,
      serviceIndex: -1,
      staffIndex: -1,
      roomIndex: -1,
      type: '',
    })
  }

  const onSelectTimeClick = async (
    serviceIndex: number,
    timePriceIndex: number
  ) => {
    const newArrCustomer = utils.deepClone(customers)

    const rooms = await logicRoomOrder.getRoomTypesByService(
      newArrCustomer[customerIndex].services[serviceIndex].serviceId,
      newArrCustomer[customerIndex].services[serviceIndex].timePrices[
        timePriceIndex
      ]
    )

    const price = newArrCustomer[customerIndex].services[serviceIndex].price

    newArrCustomer[customerIndex].services[serviceIndex] = {
      ...newArrCustomer[customerIndex].services[serviceIndex],
      activeTimePriceIndex: timePriceIndex,
      price:
        newArrCustomer[customerIndex].services[serviceIndex].timePrices[
          timePriceIndex
        ].price,
      time: newArrCustomer[customerIndex].services[serviceIndex].timePrices[
        timePriceIndex
      ].time,
      rooms,
    }

    for (let i = 0; i < newArrCustomer.length; i++) {
      const { services } = newArrCustomer[i]
      if (i !== focusedCustomerIndex) {
        const findServiceIndex = services.findIndex(
          (s: ServiceOrder) =>
            s.primaryCustomerIndex.customerIndex === focusedCustomerIndex &&
            s.primaryCustomerIndex.serviceIndex === serviceIndex
        )

        if (findServiceIndex !== -1) {
          newArrCustomer[i].services[findServiceIndex] = {
            ...newArrCustomer[i].services[findServiceIndex],
            activeTimePriceIndex: timePriceIndex,
            time: newArrCustomer[i].services[findServiceIndex].timePrices[
              timePriceIndex
            ].time,
            rooms,
          }
        }
      }
    }

    const maxServiceTime = logicEndTime.checkServiceMaxTime(newArrCustomer)
    const endTime = utils.convertTime(bookingOrderState.time) + maxServiceTime

    dispatch(
      getOrderBooking({
        customers: newArrCustomer,
        total:
          bookingOrderState.total -
          price +
          newArrCustomer[customerIndex].services[serviceIndex].price,
        endTime: utils.convertTimeToHourMinute(endTime),
        serviceTime: maxServiceTime,
      })
    )
  }

  const onSelectStaff = async (
    staffId: number,
    free: boolean,
    staffWorkingStatus: number
  ) => {
    if (!free) {
      setOpenWarning({ type: 'staffUnavailable', body: {} })
      return false
    }

    const isSameDate = await utils.checkIsSameCurrentDate(currentDate, date)

    const findStaff = staffOrders.find(s => s.staffId === staffId)

    if (isSameDate && findStaff?.staffWorkingStatus === -1) {
      setOpenWarning({ type: 'staffNotCome', body: { _id: staffId } })
      return false
    }

    confirmSelectStaff(staffId)
  }

  const confirmSelectStaff = (_id: number) => {
    const newArrCustomer = [...customers]
    const findStaff = staffOrders.find(s => s.staffId === _id)

    newArrCustomer[customerIndex].services[activeOpen.serviceIndex].staffs[
      activeOpen.staffIndex
    ] = {
      ...newArrCustomer[customerIndex].services[activeOpen.serviceIndex].staffs[
        activeOpen.staffIndex
      ],
      staffTypeId: findStaff?.staffTypeId,
      staffId: _id,
      staffName: findStaff?.staffName,
      staffImage: findStaff?.staffImage,
    }

    dispatch(getOrderBooking({ customers: newArrCustomer }))
    onToggle()
    setOpenWarning({ type: '', body: {} })
  }

  const onConfirmClick = () => {
    if (openWarning.type === 'staffNotCome') {
      // confirmSelectStaff(openWarning.body._id)
    }
    // else if (openWarning.type === 'staffUnavailable') {
    //   confirmSelectStaff(openWarning._id)
    // }
  }

  const handleClose = () => {
    setOpenWarning({ type: '', body: {} })
  }

  const onSelectRoomClick = (
    order: number,
    roomTypeId: number,
    roomId: number
  ) => {
    onConfirmSelectRoom(order, roomTypeId, roomId)
  }

  const onConfirmSelectRoom = (
    order: number,
    roomTypeId: number,
    roomId: number
  ) => {
    const newArrCustomer = [...customers]

    const findRoomType = roomOrders.find(r => r.roomTypeId === roomTypeId)
    const findRoom = findRoomType?.rooms.find(r => r.roomId === roomId)

    // console.log({ findRoomType, findRoom })

    newArrCustomer[customerIndex].services[activeOpen.serviceIndex].rooms[
      activeOpen.roomIndex
    ] = {
      ...newArrCustomer[customerIndex].services[activeOpen.serviceIndex].rooms[
        activeOpen.roomIndex
      ],
      order,
      roomTypeId,
      roomTypeName: findRoomType?.roomTypeName,
      private: findRoomType?.private,
      roomId,
      roomName: findRoom?.roomName,
      roomCode: findRoom?.roomCode,
    }

    // getRooms(activeOpen.serviceIndex)

    dispatch(getOrderBooking({ customers: newArrCustomer }))
    onToggle()
    setOpenWarning({ type: '', body: {} })
  }

  const onCheckBoxClick = (
    serviceIndex: number,
    staffIndex: number,
    type: string
  ) => {
    const newArrCustomer = [...customers]

    if (type === 'request') {
      newArrCustomer[customerIndex].services[serviceIndex].staffs[staffIndex] =
        {
          ...newArrCustomer[customerIndex].services[serviceIndex].staffs[
            staffIndex
          ],
          requestStaff:
            !newArrCustomer[customerIndex].services[serviceIndex].staffs[
              staffIndex
            ].requestStaff,
        }
    } else if (type === 'queueFree') {
      newArrCustomer[customerIndex].services[serviceIndex].staffs[staffIndex] =
        {
          ...newArrCustomer[customerIndex].services[serviceIndex].staffs[
            staffIndex
          ],
          queueFree:
            !newArrCustomer[customerIndex].services[serviceIndex].staffs[
              staffIndex
            ].queueFree,
        }
    }

    dispatch(getOrderBooking({ customers: newArrCustomer }))
  }

  const onOpenSelectCustomer = (
    customerIndex: number,
    serviceIndex: number
  ) => {
    if (
      customerIndex === activeOpenCustomer.customerIndex &&
      serviceIndex === activeOpenCustomer.serviceIndex
    ) {
      setActiveOpenCustomer({
        customerIndex: -1,
        serviceIndex: -1,
        listCustomer: [],
      })
    } else {
      const newArrCustomer = utils.deepClone(customers)
      let indexFreeCustomerServices: number[] = []

      indexFreeCustomerServices =
        newArrCustomer[customerIndex].services[serviceIndex]
          .serviceMapCustomerIndex

      for (let i = 0; i < newArrCustomer.length; i++) {
        const { services } = newArrCustomer[i]
        if (i !== customerIndex) {
          const filterService = services.filter(
            (s: ServiceOrder) => s.serviceId === -1
          )

          if (filterService.length && !indexFreeCustomerServices.includes(i)) {
            indexFreeCustomerServices = indexFreeCustomerServices.concat(i)
          }
        }
      }

      setActiveOpenCustomer({
        customerIndex,
        serviceIndex,
        listCustomer: indexFreeCustomerServices.sort((a, b) => a - b),
      })
    }
  }

  const onSelectCustomer = (customerIndex: number) => {
    let newArrCustomer = utils.deepClone(customers)

    const findCustomerServiceIndex = newArrCustomer[
      activeOpenCustomer.customerIndex
    ].services[
      activeOpenCustomer.serviceIndex
    ].serviceMapCustomerIndex.findIndex((s: number) => s === customerIndex)

    if (findCustomerServiceIndex !== -1) {
      newArrCustomer[activeOpenCustomer.customerIndex].services[
        activeOpenCustomer.serviceIndex
      ].serviceMapCustomerIndex.splice(findCustomerServiceIndex, 1)

      const findCustomerServiceIndexRemove = newArrCustomer[
        customerIndex
      ].services.findIndex(
        (s: ServiceOrder) =>
          s.primaryCustomerIndex.customerIndex ===
            activeOpenCustomer.customerIndex &&
          s.primaryCustomerIndex.serviceIndex ===
            activeOpenCustomer.serviceIndex
      )

      newArrCustomer[customerIndex].services[findCustomerServiceIndexRemove] =
        getInitialServiceBookOrder()
    } else {
      if (
        newArrCustomer[activeOpenCustomer.customerIndex].services[
          activeOpenCustomer.serviceIndex
        ].serviceMapCustomerIndex.length !==
        newArrCustomer[activeOpenCustomer.customerIndex].services[
          activeOpenCustomer.serviceIndex
        ].serviceCustomer -
          1
      ) {
        newArrCustomer[activeOpenCustomer.customerIndex].services[
          activeOpenCustomer.serviceIndex
        ].serviceMapCustomerIndex.push(customerIndex)

        const findServiceIndex = newArrCustomer[
          customerIndex
        ].services.findIndex((s: ServiceOrder) => s.serviceId === -1)

        let staffs: StaffOrder[] = []
        let newArrStaffOrder: StaffOrderState[] = []

        staffs = []
        if (bookingType.bookingTypeId === 1) {
          newArrStaffOrder = checkStaffFree(
            newArrCustomer,
            findServiceIndex,
            newArrCustomer[activeOpenCustomer.customerIndex].services[
              activeOpenCustomer.serviceIndex
            ].serviceId,
            newArrCustomer[activeOpenCustomer.customerIndex].services[
              activeOpenCustomer.serviceIndex
            ].applyToAll,
            customerIndex,
            newArrCustomer[activeOpenCustomer.customerIndex].services[
              activeOpenCustomer.serviceIndex
            ].time
          )
        }

        for (
          let i = 0;
          i <
          (newArrCustomer[activeOpenCustomer.customerIndex].services[
            activeOpenCustomer.serviceIndex
          ].staffs.length || 1);
          i++
        ) {
          if (findServiceIndex !== 0) {
            for (const staff of newArrCustomer[customerIndex].services[0]
              .staffs) {
              if (staff.staffId !== -1) {
                const free = checkStaffByIdFree(
                  newArrCustomer,
                  findServiceIndex,
                  newArrCustomer[activeOpenCustomer.customerIndex].services[
                    activeOpenCustomer.serviceIndex
                  ].serviceId,
                  newArrCustomer[activeOpenCustomer.customerIndex].services[
                    activeOpenCustomer.serviceIndex
                  ].applyToAll,
                  customerIndex,
                  newArrCustomer[activeOpenCustomer.customerIndex].services[
                    activeOpenCustomer.serviceIndex
                  ].time,
                  staff.staffId
                )

                staffs.push({
                  staffTypeId: free ? staff.staffTypeId : -1,
                  staffId: free ? staff.staffId : -1,
                  staffName: free ? staff.staffName : '',
                  staffImage: free ? staff.staffImage : '',
                  requestStaff: false,
                  queueFree: false,
                  tip: 0,
                  duration:
                    newArrCustomer[activeOpenCustomer.customerIndex].services[
                      activeOpenCustomer.serviceIndex
                    ].time,
                })
              } else {
                staffs.push({
                  staffTypeId: -1,
                  staffId: -1,
                  staffName: '',
                  staffImage: '',
                  requestStaff: false,
                  queueFree: false,
                  tip: 0,
                  duration:
                    newArrCustomer[activeOpenCustomer.customerIndex].services[
                      activeOpenCustomer.serviceIndex
                    ].time,
                })
              }
            }
          } else {
            staffs.push({
              staffTypeId:
                i >= newArrStaffOrder.length
                  ? -1
                  : newArrStaffOrder[i].staffTypeId,
              staffId:
                i >= newArrStaffOrder.length ? -1 : newArrStaffOrder[i].staffId,
              staffName:
                i >= newArrStaffOrder.length
                  ? ''
                  : newArrStaffOrder[i].staffName,
              staffImage:
                i >= newArrStaffOrder.length
                  ? ''
                  : newArrStaffOrder[i].staffImage,
              requestStaff: false,
              queueFree: false,
              tip: 0,
              duration:
                newArrCustomer[activeOpenCustomer.customerIndex].services[
                  activeOpenCustomer.serviceIndex
                ].time,
            })
          }
        }

        newArrCustomer[customerIndex].services[findServiceIndex] = {
          ...newArrCustomer[activeOpenCustomer.customerIndex].services[
            activeOpenCustomer.serviceIndex
          ],
          staffs: staffs,
          price: 0,
          primaryCustomerIndex: {
            customerIndex: activeOpenCustomer.customerIndex,
            serviceIndex: activeOpenCustomer.serviceIndex,
          },
          serviceMapCustomerIndex: [activeOpenCustomer.customerIndex],
        }
      }
    }
    newArrCustomer = logicServiceCouple.checkServiceCoupleV2(newArrCustomer)

    dispatch(getOrderBooking({ customers: newArrCustomer }))
  }

  const checkStaffFree = (
    customers: CustomerOrder[],
    findServiceIndex: number,
    serviceId: number,
    applyToAll: boolean,
    focusedCustomerIndex: number,
    serviceTime: number
  ) => {
    let filteredStaffOrders: StaffOrderState[] = []
    filteredStaffOrders = [...staffOrders]
    if (!applyToAll) {
      filteredStaffOrders = logicStaffOrder.checkServiceSpecificStaff(
        staffOrders,
        serviceId
      )
    }

    const staffs = logicStaffOrder
      .checkListStaffAvailability(
        filteredStaffOrders,
        customers,
        time,
        findServiceIndex,
        focusedCustomerIndex,
        serviceTime
      )
      .filter(s => s.free && s.staffWorkingStatus === 1)

    return staffs
  }

  const checkStaffByIdFree = (
    customers: CustomerOrder[],
    findServiceIndex: number,
    serviceId: number,
    applyToAll: boolean,
    focusedCustomerIndex: number,
    serviceTime: number,
    staffId: number
  ) => {
    let filteredStaffOrders: StaffOrderState[] = []
    filteredStaffOrders = [...staffOrders]
    if (!applyToAll) {
      filteredStaffOrders = logicStaffOrder.checkServiceSpecificStaff(
        staffOrders,
        serviceId
      )
    }

    const free = logicStaffOrder.checkStaffAvailability(
      filteredStaffOrders,
      customers,
      time,
      findServiceIndex,
      focusedCustomerIndex,
      serviceTime,
      staffId
    )

    return free
  }

  return {
    activeOpen,
    openWarning,
    roomsForService,
    activeOpenCustomer,
    onDeleteService,
    onOpenSelectClick,
    onToggle,
    onSelectTimeClick,
    onSelectStaff,
    onConfirmClick,
    handleClose,
    onSelectRoomClick,
    onCheckBoxClick,
    onOpenSelectCustomer,
    onSelectCustomer,
  }
}

export default useCardCustomer
