import React from 'react'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddProductGroup from '../settingProduct/CardAddProductGroup'

const PopupAddProductGroup = ({
  handleClose,
  productGroup,
  currentEditId = -1,
  currentEditData = [],
}) => {
  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' height='50vh' marginTop='0' padding='0'>
        <CardAddProductGroup
          handleClose={handleClose}
          productGroup={productGroup}
          currentIdEdit={currentEditId}
          currentDataEdit={currentEditData}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddProductGroup
