import { getSavedUsers } from "../../lib/auth/loginApi"

export const fetchUser = async (userId: string) => {
    const savedUserIds = localStorage.getItem('id')
    const userIds = savedUserIds ? JSON.parse(savedUserIds) : []
    const { success, data } = await getSavedUsers(userIds.join(','))
    if (!success) {
        return null
    }

    const user = data?.find(user => user._id.toString() === userId)
    if (!user) {
        return null
    }

    return user
}

export const fetchUsers = async () => {
    const savedUserIds = localStorage.getItem('id')
    const userIds = savedUserIds ? JSON.parse(savedUserIds) : []
    const { success, data } = await getSavedUsers(userIds.join(','))
    if (!success) {
        return null
    }
    return data
}