import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'

import App from './App'
import './index.css'
import store from './redux/store'
import reportWebVitals from './reportWebVitals'
import { AlertProvider } from '@/hook/useAlert'
import { ToastProvider } from '@/components/atoms/Toast'

if (
  !!process.env.REACT_APP_STAGE &&
  process.env.REACT_APP_STAGE !== 'development'
) {
  Sentry.init({
    env: process.env.REACT_APP_STAGE,
    dsn: 'https://61a1f98d47623b117d91578f3621d774@o279111.ingest.sentry.io/4506712945917952',
    integrations: [],
  })
}

const history = createBrowserHistory()

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <Suspense fallback=''>
        <BrowserRouter history={history}>
          <AlertProvider>
            <App />
          </AlertProvider>
          <ToastProvider />
        </BrowserRouter>
      </Suspense>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
)

reportWebVitals()
