import React from "react";
import { Container } from "../layout/Container";
import { StyledInput } from "../atoms/StyledInput";

interface ComplexInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    preIcon?: React.ReactNode;
    postIcon?: React.ReactNode;
}

export const ComplexInput = (props: ComplexInputProps) => {
    const { preIcon, postIcon, ...rest } = props;
    return (
        <Container>
            {preIcon &&
                <div style={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    height: '100%',
                    width: '2.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {preIcon}
                </div>
            }
            <StyledInput {...rest}
                hasPostIcon={!!postIcon}
                hasPreIcon={!!preIcon}
            />
            {postIcon &&
                <div style={{
                    position: 'absolute',
                    right: '0',
                    top: '0',
                    height: '100%',
                    width: '2.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {postIcon}
                </div>
            }

        </Container>
    )
}