import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'

import TextLabel from '../../atoms/TextLabel'
import PromotionIcon from '../../atoms/PromotionIcon'
import PercentIcon from '../../atoms/PercentIcon'
import BahtIcon from '../../atoms/BahtIcon'
import api from '../../api'
import Button2 from '../../atoms/Button2'
import InputText from '../../atoms/InputText'
import utils from '../../utils'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import LongArrow from '../../atoms/LongArrow'
import TableOrder from './TableOrder'
import PopupTip from '../popups/PopupTip'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import PromotionPercentIcon from '../../atoms/PromotionPercentIcon'
import PromotionBathIcon from '../../atoms/PromotionBathIcon'
import { useRef } from 'react'
import TablePackage from './TablePackage'
import BoxDate from './BoxDate'
import TableProduct from './TableProduct'
import { useTranslation } from 'react-i18next'
import { PromotionUnits } from '../../redux/models/BookOrder'

const Container = styled.div`
  min-height: calc(100vh - 1.5rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  flex: 1;
  gap: 0.5rem;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => props.gap || '0.5rem'};
  width: 100%;
`
const Line = styled.div`
  border-top: ${props => props.borderTop || '1px solid #e5eced'};
  width: ${props => props.width || '100%'};
  margin-left: ${props => props.marginLeft || '0'};
  padding-bottom: ${props => props.paddingBottom || '0'};
`
const LeftSide = ({ bookingHistory = {}, requireCustomerDetail }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const history = useHistory()
  const refHeightDiscount = useRef(null)
  const [heightCard, setHeightCard] = useState(0)
  const [heightDiscount, setHeightDiscount] = useState(0)
  const bookingOrderState = useSelector(state => state.bookingHistoryReducers)
  const {
    _id,
    orders,
    products: _products,
    total,
    date,
    bookingCode,
    promotions: bookingPromotions,
    payChannels = [],
    packages,
    buyPackage,
    note,
    serviceDiscount,
  } = bookingOrderState || {}
  const [inputDiscount, setInputDiscount] = useState('')
  const [activeBtnDiscount, setActiveBtnDiscount] = useState(-1)
  const [promotions, setPromotions] = useState([])
  const [activePromotion, setActivePromotion] = useState(-1)
  const [services, setServices] = useState([])
  const [openTip, setOpenTip] = useState(false)
  const [staffs, setStaffs] = useState([])
  const [isOpenPopupDone, setIsOpenPopupDone] = useState(false)
  const [checkStaff, setCheckStaff] = useState(true)
  const [sumService, setSumService] = useState(0)
  const [sumProduct, setSumProduct] = useState(0)
  const buttonDiscount = [
    {
      name: `${t('promotion')}`,
      icon: <PromotionIcon size='15' color='#6B608A' />,
    },
    { name: `${t('discount')} %`, icon: <PercentIcon size='15' /> },
    {
      name: `${t('discount')} ฿`,
      icon: <BahtIcon size='15' color='#6B608A' />,
    },
  ]

  useEffect(() => {
    let staffs = []
    let checkStaff = true
    let sumService = 0
    const services = orders.reduce((acc, info, index) => {
      const { customer, services } = info
      for (let i = 0; i < services.length; i++) {
        if (services[i].staffId === -1) checkStaff = false
        const findStaff = staffs.find(s => s.staffId === services[i].staffId)
        if (!findStaff) {
          staffs.push({
            staffId: services[i].staffId,
            staffName: services[i].staffName,
            tip: services[i].tip || 0,
            payChannelTypeTip: services[i].payChannelTypeTip || -1,
            payChannelTip: services[i].payChannelTip || '',
            orderIndex: index,
            serviceIndex: i,
          })
        }
        sumService += services[i].price
        acc.push({ ...services[i], customerIndex: index, serviceIndex: i })
      }
      return acc
    }, [])

    setCheckStaff(checkStaff)

    setServices(services)
    setSumService(sumService)
    setStaffs(staffs)

    const sumProduct = _products.reduce(
      (acc, info) => (acc += info.price * info.quantity),
      0
    )
    setSumProduct(sumProduct)
  }, [orders])

  useEffect(() => {
    if (bookingPromotions.length) {
      const findIndexPromotion = promotions.findIndex(
        p => p._id === bookingPromotions[0].promotionId
      )
      setActivePromotion(findIndexPromotion)
      setActiveBtnDiscount(0)
    }
  }, [promotions])

  useEffect(() => {
    callApiPromotion()
  }, [])

  const callApiPromotion = async () => {
    try {
      const { data } = await api.getPromotionByDate(new Date())
      setPromotions(data)
    } catch (err) {
      console.log(err)
    }
  }

  const onDiscountClick = index => {
    if (payChannels.length) {
      return false
    }
    if (activeBtnDiscount === index) {
      setActiveBtnDiscount(-1)
    } else {
      setActiveBtnDiscount(index)
    }
    setActivePromotion(-1)
    setInputDiscount('')
    dispatch(getOrderBooking({ discount: 0 }))
  }
  const onSelectPromotion = async index => {
    if (payChannels.length) {
      return false
    }
    if (activePromotion === index) {
      setActivePromotion(-1)
      dispatch(
        getOrderBooking({
          discount: 0,
          promotions: [{ promotionId: promotions[index]._id }],
        })
      )
    } else {
      const { discount: promotionDiscount, unit: promotionUnit } =
        promotions[index]
      setActivePromotion(index)
      let _discount = 0
      if (promotionUnit === PromotionUnits.Amount) {
        _discount = promotionDiscount || 0
      } else if (promotionUnit === PromotionUnits.Percent) {
        _discount = (total || 0) * ((promotionDiscount || 0) / 100)
      }
      dispatch(
        getOrderBooking({
          discount: _discount,
          promotions: [{ promotionId: promotions[index]._id }],
        })
      )
    }
  }
  const showPromotion = promotions.map(({ name, unit, discount }, index) => (
    <SwiperSlide key={index}>
      <div style={{ width: '100%', objectFit: 'cover' }}>
        <div
          style={{
            background: 'white',
            maxWidth: 'calc(100%)',
            borderRadius: '8px',
            padding: '0.5rem 0.5rem',
            lineHeight: '2',
            textAlign: 'center',
            boxShadow: '0px 24px 40px rgba(107, 107, 107, 0.08)',
            border: activePromotion === index ? '2px solid #8C56D9' : '',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => onSelectPromotion(index)}
        >
          {unit === 1 ? (
            <PromotionBathIcon size='40' />
          ) : (
            <PromotionPercentIcon size='40' />
          )}
          <TextLabel>{`${discount} ${unit === 1 ? 'บาท' : '%'}`}</TextLabel>
          <TextLabel color='#475467'>{name}</TextLabel>
        </div>
      </div>
    </SwiperSlide>
  ))
  const onInputDiscountChange = value => {
    if (utils.checkNumber(value)) {
      if (value) {
        if (activeBtnDiscount === 1) {
          const percentage = +value <= 100 ? value : 100 || 0
          const discount = (total - serviceDiscount) * (percentage / 100)
          dispatch(getOrderBooking({ discount: discount }))
          setInputDiscount(percentage)
        } else if (activeBtnDiscount === 2) {
          const discount = Math.min(value, total - serviceDiscount)
          dispatch(
            getOrderBooking({
              discount,
            })
          )
          setInputDiscount(discount)
        }
      } else if (!value) {
        dispatch(
          getOrderBooking({
            discount: 0,
          })
        )
        setInputDiscount(value)
      }
    }
  }

  useEffect(() => {
    if (!inputDiscount && activePromotion === -1) {
      setActiveBtnDiscount(-1)
    }
  }, [payChannels])

  useEffect(() => {
    setHeightDiscount(refHeightDiscount.current.clientHeight)
  }, [refHeightDiscount, activeBtnDiscount])

  return (
    <Container>
      <BoxDate bookingCode={bookingCode} date={date} />
      <FlexBox gap='1rem' alignItems='center' onClick={() => history.goBack()}>
        <div>
          <LongArrow />
        </div>
        <TextLabel color='#6E798C' fontSize='1.15rem' fontWeight='500'>
          {t('editTreatment')}
        </TextLabel>
      </FlexBox>
      {!buyPackage && (
        <>
          <TextLabel fontWeight='500'>{`${t(
            'treatmentSummary'
          )} (${utils.formatNumber(sumService)})`}</TextLabel>
          <TableOrder
            services={services}
            products={_products}
            bookingId={_id}
            requireCustomerDetail={requireCustomerDetail}
            heightCard={heightCard}
            heightDiscount={heightDiscount}
          />
          {!!_products.length && (
            <TextLabel fontWeight='500'>{`${t(
              'productSummary'
            )} (${sumProduct})`}</TextLabel>
          )}

          <TableProduct products={_products} />
        </>
      )}
      {buyPackage && <TablePackage packages={packages} orders={orders} />}
      <div ref={refHeightDiscount}>
        <TextLabel fontWeight='500'>{t('promotion')}</TextLabel>
        <GridBox className='pt-2' columns='3fr 1fr'>
          <FlexBox gap='0.5rem'>
            {buttonDiscount.map(({ icon, name }, index) => (
              <div key={index}>
                <Button2
                  icon={icon}
                  backgroundColor='#FFF'
                  padding='0.25rem'
                  margin='0.25rem'
                  active={activeBtnDiscount === index}
                  disable={payChannels.length}
                  onClick={() => onDiscountClick(index)}
                >
                  {name}
                </Button2>
              </div>
            ))}
          </FlexBox>
          <InputText
            placeholder={`00.00 ${activeBtnDiscount === 1 ? '%' : 'บาท'}`}
            textAlign='right'
            readOnly={
              activeBtnDiscount === 0 ||
              activeBtnDiscount === -1 ||
              payChannels.length
            }
            value={inputDiscount}
            onChange={val => onInputDiscountChange(val.target.value)}
          />
        </GridBox>
        {activeBtnDiscount === 0 && (
          <div className='mt-3'>
            <Swiper slidesPerView={3.5} spaceBetween={5}>
              {showPromotion}
            </Swiper>
          </div>
        )}
        <Line className='mt-2 mb-2'></Line>
      </div>
      <FlexBox alignItems='center' gap='0.25rem'>
        <TextLabel>หมายเหตุ: </TextLabel>
        <InputText
          value={note}
          onChange={val =>
            dispatch(
              getOrderBooking({
                note: val.target.value,
              })
            )
          }
        />
      </FlexBox>

      {openTip && (
        <PopupTip
          staffs={staffs}
          orders={orders}
          handleClose={() => setOpenTip(false)}
        />
      )}
      {isOpenPopupDone && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default LeftSide
