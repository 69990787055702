import styled from "styled-components/macro";

interface StyledInputProps {
    hasPreIcon?: boolean;
    hasPostIcon?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
    padding: 0.5rem;
    padding-left: ${({ hasPreIcon }) => hasPreIcon ? '2.5rem' : '0.5rem'};
    padding-right: ${({ hasPostIcon }) => hasPostIcon ? '2.5rem' : '0.5rem'};
    background: white;
    border: none;
    border-radius: 3px;
    width: 100%;
    border: 1px solid rgb(228, 232, 235);

    ::placeholder {
    color: #D0D4DD;
    }
    :focus-visible {
        outline: none;
    }
`;

