import React, { ReactNode, useRef } from 'react'
import styled, { CSSProperties } from 'styled-components'

import CheckBox from '../../atoms/CheckBox'

import useOnClickOutside from '../../hook/useOnClickOutside'
import Option from '../../redux/models/Option'

const SelectWrapper = styled.div<Pick<CSSProperties, 'width'>>`
  width: ${props => props.width || '100%'};
  position: relative;
  user-select: none;
`

const Select = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

const SelectTrigger = styled.div<CSSProperties>`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #3b3b3b;
  background: ${props => props.background || '#ffffff'};
  cursor: pointer;
  border-width: ${props => props.borderWidth || '1px'};
  border-style: solid;
  border-color: ${props => props.borderColor || '#e4e8eb'};
  border-radius: ${props => props.borderRadius || '4px'};
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
`

interface OptionContainerProps {
  open: boolean
  height: CSSProperties['height']
}

const OptionContainer = styled.div<OptionContainerProps>`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: ${props => (props.open ? '1px solid #8C56D9' : '1px solid #e4e8eb')};
  margin-top: 0.5rem;
  border-radius: 8px;
  background: #fff;
  transition: all 0.5s;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  pointer-events: ${props => (props.open ? 'all' : 'none')};
  z-index: 3;
  overflow: auto;
  max-height: ${props => props.height};
`

const OptionItemContainer = styled.span<{ backgroundColor: string }>`
  position: relative;
  display: block;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #475467;
  /* line-height: 60px; */
  background: ${props => props.backgroundColor || 'none'};
  cursor: pointer;
  transition: all 0.5s;
  :first-child {
    border-radius: 8px 8px 0 0;
  }
  :last-child {
    border-radius: 0 0 8px 8px;
  }
  &:only-child {
    border-radius: 8px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }

  :hover {
    cursor: pointer;
    background-color: #e4e8eb;
  }
  ::selection {
    color: #475467;
    background-color: #e4e8eb;
  }
`

const Arrow = styled.div<{ open: boolean }>`
  position: relative;
  height: 10px;
  width: 2px;
  border-radius: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 5px;
  transition: all 0.4s ease;
  transform: ${props => (props.open ? 'rotate(-135deg)' : 'rotate(45deg)')};
  right: 1rem;
  bottom: ${props => (props.open ? '-4px' : '1px')};
`

export interface SelectorBaseProps<T> {
  name?: string
  option: string | Option<T>
  options: Option<T>[]
  open: boolean
  onClick: (value: boolean, index: number) => void
  onOptionClick: (option: Option<T>, name: string, index: number) => void
  error?: boolean
  readOnly?: boolean
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  borderWidth?: CSSProperties['borderWidth']
  borderRadius?: CSSProperties['borderRadius']
  renderOption?: (option: Option<T>) => ReactNode
}

const SelectorBase = <T,>({
  name = '',
  option,
  options = [],
  open,
  onClick,
  onOptionClick,
  error,
  readOnly = false,
  width,
  height,
  borderWidth,
  borderRadius,
  renderOption,
}: SelectorBaseProps<T>) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(wrapperRef, () => {
    onClick(false, -1)
  })

  return (
    <SelectWrapper width={width} ref={wrapperRef}>
      <Select>
        <SelectTrigger
          borderWidth={borderWidth}
          borderRadius={borderRadius}
          background={readOnly ? 'rgb(240, 242, 245)' : ''}
          borderColor={open ? '#8C56D9' : error ? '#DB2D1E' : ''}
          onClick={e => (readOnly ? e.preventDefault() : onClick(!open, -1))}
        >
          <div style={{ color: '#000000', fontWeight: '400' }}>
            {(option as Option<T>)?.label ?? option}
          </div>
          <Arrow open={open} />
        </SelectTrigger>
        <OptionContainer open={open} height={height}>
          {options.map((item, index) => (
            <OptionItemContainer
              key={index}
              onClick={() => onOptionClick(item, name, index)}
              backgroundColor={
                (item?.value || item) ===
                ((option as Option<T>)?.value || option)
                  ? '#E4E8EB'
                  : ''
              }
            >
              {renderOption ? renderOption(item) : item?.label ?? item}
            </OptionItemContainer>
          ))}
        </OptionContainer>
      </Select>
    </SelectWrapper>
  )
}

export default SelectorBase
