import React from 'react'

import { IconProps } from '../../../lib/icons/IconPropsType'

const BathIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size || '18'}
      height={size || '17'}
      viewBox='0 0 18 17'
      fill={color || '#98A1B2'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.92955 0.857792C2.64436 2.00601 0.124485 6.37055 1.2727 10.6558C2.42092 14.9409 6.78546 17.4608 11.0707 16.3126C15.3559 15.1644 17.8757 10.7998 16.7275 6.51465C15.5793 2.22945 11.2148 -0.290423 6.92955 0.857792ZM2.67768 10.2793C1.73652 6.76683 3.79356 3.20394 7.30602 2.26278C10.8185 1.32162 14.3814 3.37865 15.3225 6.89111C16.2637 10.4036 14.2067 13.9665 10.6942 14.9076C7.18174 15.8488 3.61884 13.7917 2.67768 10.2793ZM9.40746 4.48553C9.38359 4.11546 9.05991 3.75044 8.68984 3.7743C8.31977 3.79817 7.95474 4.12186 7.97861 4.49192C7.96483 4.7215 7.79173 4.91846 7.56216 4.90468L5.97399 4.87848C5.53367 4.92117 5.23889 5.22603 5.19251 5.61492C5.2352 6.05524 5.54007 6.35002 5.92896 6.3964C6.06946 6.35875 6.13971 6.33993 6.22878 6.39136C6.54742 6.45656 6.76321 6.69991 6.8385 6.98091C6.85733 7.05116 6.87615 7.12141 6.82472 7.21048L6.83752 10.0682C6.85634 10.1384 6.87516 10.2087 6.80491 10.2275C6.75853 10.6164 6.51518 10.8322 6.16393 10.9263C6.16393 10.9263 6.09368 10.9451 6.02344 10.9639C5.65337 10.9878 5.28834 11.3115 5.31221 11.6816C5.33608 12.0516 5.65976 12.4167 6.02983 12.3928L7.52893 12.3676C7.7585 12.3814 8.02572 12.5356 8.03076 12.8355C8.05463 13.2055 8.37831 13.5705 8.74838 13.5467C9.11845 13.5228 9.48348 13.1991 9.45961 12.8291C9.47339 12.5995 9.62767 12.3323 9.92749 12.3272L10.5271 12.3171C11.7264 12.297 12.7324 11.2745 12.7122 10.0752C12.7348 9.31624 12.3169 8.59997 11.6934 8.23999C11.8289 7.90252 11.9644 7.56505 11.9405 7.19498C11.9906 5.97688 11.0571 5.0223 9.83904 4.97223C9.60947 4.95845 9.4125 4.78535 9.40746 4.48553ZM8.33292 10.8722L8.28888 9.30282L9.85823 9.25878L9.99873 9.22113L10.5281 9.22987C10.9684 9.18718 11.2921 9.5522 11.3348 9.99252C11.3775 10.4328 11.0125 10.7565 10.5722 10.7992L8.33292 10.8722ZM8.28249 7.87397L8.25727 6.37487L9.84544 6.40108C10.2155 6.37721 10.5392 6.74224 10.5631 7.11231C10.5869 7.48238 10.2922 7.78724 9.92208 7.81111L8.28249 7.87397Z'
      />
    </svg>
  )
}

export default BathIcon
