import React from 'react'

import Option, { SelectorOption } from '@/redux/models/Option'

import SelectorBase from './SelectorBase'

interface SelectorBookingChannelProps {
  name: string
  open: boolean
  onClick: (open: boolean) => void
  option: Option
  options: Option[]
  onOptionClick: (option: SelectorOption, name: string) => void
  error: boolean
  readOnly: boolean
}

const SelectorBookingChannel = ({
  name = 'bookingChannel',
  open,
  onClick,
  option = {
    value: -1,
    label: '',
  },
  onOptionClick,
  error = false,
  readOnly = false,
  options,
}: SelectorBookingChannelProps) => {
  return (
    <SelectorBase
      name={name}
      option={option}
      options={options}
      open={open}
      onClick={open => onClick(open)}
      onOptionClick={(item, name) => onOptionClick(item, name)}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorBookingChannel
