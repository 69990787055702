import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: block;
  position: relative;
  height: auto;
  margin-right: ${props => props.marginRight || '0.5rem'};
  line-height: ${props => props.lineHight || '1'};
`
const Radio = styled.input.attrs({ type: 'radio' })`
  width: ${props => props.size || '15px'};
  height: ${props => props.size || '15px'};
  border: 1px solid #8c56d9;
  :checked {
    accent-color: #8c56d9;
  }
`

const RadioButton = ({ checked, onClick, size, marginRight, lineHight }) => {
  return (
    <Container marginRight={marginRight} lineHight={lineHight}>
      <Radio checked={checked} onClick={onClick} size={size} />
    </Container>
  )
}

export default RadioButton
