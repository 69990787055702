import { Link } from "react-router-dom";
import styled from "styled-components";

export const RouteLink = styled(Link)`
    text-decoration: none;
    color: unset;
    &:hover {
        text-decoration: none;
        color: unset;
        filter: brightness(110%);
    }
`