import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//@ts-ignore
import api from '../../api'
import config from '../../config'
import { BookingHistory } from '../../redux/models/BookingHistory'
import { Customer } from '../../redux/models/Customer'
import CustomerPackage from '../../redux/models/CustomerPackage'
import Nationality from '../../redux/models/Nationality'
import Occupation from '../../redux/models/Occupation'

interface useBodyProps {
  search: string
}

const useBody = ({ search }: useBodyProps) => {
  const [customers, setCustomers] = useState<Customer[]>([])
  const [customer, setCustomer] = useState<Customer>({
    _id: -1,
    name: '',
    firstName: '',
    lastName: '',
    image: '',
    gender: { value: -1, label: '' },
    nationality: { value: -1, label: '' },
    occupation: { value: -1, label: '' },
    age: { value: -1, label: '' },
    nationalityId: -1,
    occupationId: -1,
    email: '',
    countryCode: '',
    phoneNumber: '',
    address: '',
    taxIDNumber: '',
    birthDay: null,
    lineId: '',
    specialRequest: '',
    specialRequests: [],
    status: -1,
    storeId: -1,
    brandId: -1,
  })
  const [customerBookingHistories, setCustomerBookingHistories] = useState<
    BookingHistory[]
  >([])
  const [staffOrders, setStaffOrders] = useState([])
  const [page, setPage] = useState(1)
  const [hasMoreCustomer, setHasMoreCustomer] = useState(true)
  const [editCustomer, setEditCustomer] = useState(false)
  const [customerPackages, setCustomerPackages] = useState<CustomerPackage[]>(
    []
  )

  const { nationalities, occupations } = useSelector(
    (state: any) => state.brand
  )

  useEffect(() => {
    setHasMoreCustomer(true)
    setPage(1)
    callApiCustomer(search, 1)
  }, [search])

  const callApiCustomer = async (search = '', page: number) => {
    try {
      const { data } = await api.getSearchCustomerByNameAndPhoneNumber(
        search,
        page
      )

      if (!data.length || data.length < 20) {
        setHasMoreCustomer(false)
      }

      const _customers = data.reduce((acc: Customer[], info: Customer) => {
        const { age, nationalityId, gender } = info
        const findNationality =
          nationalities.find(
            (n: Nationality) => n._id === (nationalityId || -1)
          ) || ''
        const findOccupations =
          occupations.find(
            (o: Occupation) => o._id === (data[0].occupationId || -1)
          ) || ''

        const ageValue = typeof age === 'number' ? age : age?.value || -1
        const genderValue =
          typeof gender === 'number' ? gender : gender?.value || -1

        acc.push({
          ...info,
          nationality: {
            value: findNationality._id || -1,
            label: findNationality.nationality || 'ไม่ระบุ',
          },
          age: {
            value: ageValue,
            label: age === -1 ? 'ไม่ระบุ' : config.AGE[+ageValue - 1],
          },
          gender: {
            value: genderValue,
            label:
              gender === -1
                ? 'ไม่ระบุ'
                : gender === 1
                ? 'ชาย'
                : gender === 2
                ? 'หญิง'
                : 'อื่นๆ',
          },
          occupation: {
            value: findOccupations._id || -1,
            label: findOccupations.occupation || 'อาชีพ',
          },
        })

        return acc
      }, [])
      setCustomers(_customers)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCustomerMore = async (page: number) => {
    try {
      let newArrCustomer = [...customers]
      const { data } = await api.getSearchCustomerByNameAndPhoneNumber(
        search,
        page
      )

      if (!data.length || data.length < 20) {
        setHasMoreCustomer(false)
      }

      const _customers = data.reduce((acc: Customer[], info: Customer) => {
        const { age, nationalityId, gender } = info
        const findNationality =
          nationalities.find(
            (n: Nationality) => n._id === (nationalityId || -1)
          ) || ''
        const findOccupations =
          occupations.find(
            (o: Occupation) => o._id === (data[0].occupationId || -1)
          ) || ''

        const ageValue = typeof age === 'number' ? age : age?.value || -1
        const genderValue =
          typeof gender === 'number' ? gender : gender?.value || -1

        acc.push({
          ...info,
          nationality: {
            value: findNationality._id || -1,
            label: findNationality.nationality || 'อื่นๆ',
          },
          age: {
            value: ageValue,
            label: age === -1 ? 'ไม่ระบุ' : config.AGE[+ageValue - 1],
          },
          gender: {
            value: genderValue,
            label:
              gender === -1
                ? 'ไม่ระบุ'
                : gender === 1
                ? 'ชาย'
                : gender === 2
                ? 'หญิง'
                : 'อื่นๆ',
          },
          occupation: {
            value: findOccupations._id || -1,
            label: findOccupations.occupation || 'อาชีพ',
          },
        })

        return acc
      }, [])
      newArrCustomer = newArrCustomer.concat(_customers)
      setCustomers(newArrCustomer)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCustomerById = async () => {
    try {
      const { data } = await api.getCustomerById(customer._id)

      const findNationality =
        nationalities.find(
          (n: Nationality) => n._id === (data[0].nationalityId || -1)
        ) || ''

      const findOccupations =
        occupations.find(
          (o: Occupation) => o._id === (data[0].occupationId || -1)
        ) || ''

      setCustomer({
        ...data[0],
        nationality: {
          value: findNationality._id || -1,
          label: findNationality.nationality || 'สัญชาติ',
        },
        age: {
          value: data[0].age,
          label: data[0].age === -1 ? 'อายุ' : config.AGE[data[0].age - 1],
        },
        gender: {
          value: data[0].gender,
          label:
            data[0].gender === -1
              ? 'ไม่ระบุ'
              : data[0].gender === 1
              ? 'ชาย'
              : data[0].gender === 2
              ? 'หญิง'
              : 'อื่นๆ',
        },
        occupation: {
          value: findOccupations._id || -1,
          label: findOccupations.occupation || 'อาชีพ',
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const fetchData = async () => {
    try {
      await callApiCustomerMore(page + 1)
      setPage(page + 1)
    } catch (err) {
      console.log(err)
    }
  }

  const onCustomerClick = async (customer: Customer) => {
    try {
      const { data } = await api.getBookingHistoryByCustomerId(customer._id)

      const customerBookingHistory = data.reduce(
        (acc: BookingHistory[], info: BookingHistory) => {
          let { customers } = info

          const calculateOrderIndex = customers.findIndex(
            c => c.customerId === customer._id
          )

          if (calculateOrderIndex >= 0) {
            const temp = customers[0]
            customers[0] = customers[calculateOrderIndex]
            customers[calculateOrderIndex] = temp
          }
          acc.push({ ...info, customers })

          return acc
        },
        []
      )
      setCustomerBookingHistories(
        customerBookingHistory.sort(
          (a: BookingHistory, b: BookingHistory) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        )
      )
      setCustomer(customer)
      callApiCustomerPackage(customer._id || -1)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCustomerPackage = async (customerId: number) => {
    try {
      const { data } = await api.getCustomerPackageByCustomerId(customerId)
      setCustomerPackages(data)
    } catch (err) {
      console.log(err)
    }
  }

  const onGoBackClick = () => {
    setCustomer({
      _id: -1,
      name: '',
      firstName: '',
      lastName: '',
      image: '',
      gender: { value: -1, label: '' },
      nationality: { value: -1, label: '' },
      occupation: { value: -1, label: '' },
      age: { value: -1, label: '' },
      nationalityId: -1,
      occupationId: -1,
      email: '',
      countryCode: '',
      phoneNumber: '',
      address: '',
      taxIDNumber: '',
      birthDay: null,
      lineId: '',
      specialRequest: '',
      specialRequests: [],
      status: -1,
      storeId: -1,
      brandId: -1,
    })
    // setCustomerBookingHistory({})
    callApiCustomer(search, 1)
    setHasMoreCustomer(true)
    setPage(1)
  }

  const handleClose = async () => {
    setEditCustomer(false)
    await callApiCustomerById()
  }

  return {
    customer,
    customers,
    customerBookingHistories,
    staffOrders,
    page,
    hasMoreCustomer,
    editCustomer,
    customerPackages,
    fetchData,
    onCustomerClick,
    handleClose,
    setEditCustomer,
    onGoBackClick,
    callApiCustomerPackage,
  }
}

export default useBody
