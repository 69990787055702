import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'

import { activeOpenState, RoomForServiceState } from './useCardCustomer'
import useCardRoom from './useCardRoom'

import LongArrow from '../../../../atoms/LongArrow'
//@ts-ignore
import Scrollbar from '../../../../atoms/Scrollbar'
import TextLabel from '../../../../atoms/TextLabel'

import { RoomOrderState } from '../../types'

interface CardRoomStyle extends CSSProperties {}

const Container = styled.div<CardRoomStyle>`
  position: ${props => props.position || 'absolute'};
  background: #ffffff;
  width: 100%;
  left: 0px;
  height: 100%;
  z-index: 1;
  top: 0px;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
  gap: 0.5rem;
  /* transform: translate(101%);
  transition: transform 300ms ease-in-out; */
`
const BoxRoomType = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 0.25rem;
  :first-child {
    margin-top: 0;
  }
`
const BoxRoomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.25rem;
`
const BoxRoomStyle = styled.div`
  width: 100%;
`
const BoxRoom = styled.div<CardRoomStyle>`
  width: 100%;
  background: ${props => props.backgroundColor || '#ffffff'};
  border: ${props => props.border || '1px solid #e4e8eb'};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* aspect-ratio: 1; */
  padding: 0.25rem 0;
  color: ${props => props.color || '#475467'};
  cursor: pointer;
  :hover {
    /* background-color: #475467; */
    /* color: #ffffff; */
  }
`

interface CardRoom {
  activeOpen: activeOpenState
  roomOrders: RoomOrderState[]
  roomsForService: RoomForServiceState[]
  onToggle: () => void
  onSelectRoomClick: (order: number, roomTypeId: number, roomId: number) => void
}

const CardRoom = ({
  activeOpen,
  roomOrders,
  roomsForService,
  onToggle,
  onSelectRoomClick,
}: CardRoom) => {
  const { t } = useTranslation()

  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { customers, focusedCustomerIndex } = bookingOrderState

  const [seeMore, setSeeMore] = useState<boolean>(false)
  const [detail, setDetail] = useState<string>('')

  const { rooms, moreRooms, activeRoom } = useCardRoom({
    roomOrders,
    activeOpen,
    roomsForService,
  })

  useEffect(() => {
    setDetail(
      `${
        customers[focusedCustomerIndex].services[activeOpen.serviceIndex].rooms[
          activeOpen.roomIndex
        ].detail
      }${
        customers[focusedCustomerIndex].services[activeOpen.serviceIndex].rooms[
          activeOpen.roomIndex
        ].duration
      }`
    )
  }, [activeOpen])

  return (
    <Container>
      <LongArrow onClick={() => onToggle()} />
      <TextLabel fontWeight='500'>{`${t(
        'selectMassageRoom'
      )} (${detail} นาที)`}</TextLabel>
      <Scrollbar id='customer-cardRoom'>
        <div></div>
        {rooms.map(
          (
            { rooms, roomTypeName, roomTypeId, roomTypeCode, order },
            roomTypeIndex
          ) => (
            <>
              <BoxRoomType key={roomTypeIndex}>
                <TextLabel fontWeight='500'>{`ห้อง ${roomTypeName} (${roomTypeCode})`}</TextLabel>
                <BoxRoomContainer>
                  {rooms.map(
                    ({ roomName, roomCode, roomId, free }, roomIndex) => (
                      <BoxRoomStyle
                        key={roomIndex}
                        onClick={() =>
                          onSelectRoomClick(order, roomTypeId, roomId)
                        }
                      >
                        <BoxRoom
                          backgroundColor={
                            !free
                              ? '#F8F9FB'
                              : activeRoom === roomId
                              ? 'linear-gradient(221.86deg, #8C56D9 2.71%, #53389E 97.79%)'
                              : ''
                          }
                          color={
                            !free
                              ? '#D0D4DD'
                              : activeRoom === roomId
                              ? '#ffffff'
                              : ''
                          }
                        >
                          <TextLabel
                            fontSize='0.85rem'
                            color={
                              !free
                                ? '#E4E8EB'
                                : activeRoom === roomId
                                ? '#FFFFFF'
                                : ''
                            }
                          >
                            {roomName}
                          </TextLabel>
                          <TextLabel
                            fontSize='0.85rem'
                            color={
                              !free
                                ? '#E4E8EB'
                                : activeRoom === roomId
                                ? '#FFFFFF'
                                : ''
                            }
                          >{`(${roomCode})`}</TextLabel>
                        </BoxRoom>
                      </BoxRoomStyle>
                    )
                  )}
                </BoxRoomContainer>
              </BoxRoomType>
            </>
          )
        )}
        {seeMore &&
          moreRooms.map(
            (
              { rooms, roomTypeId, roomTypeName, roomTypeCode, order },
              roomTypeIndex
            ) => (
              <>
                <BoxRoomType key={roomTypeIndex}>
                  <TextLabel fontWeight='500'>{`ห้อง ${roomTypeName} (${roomTypeCode})`}</TextLabel>
                  <BoxRoomContainer>
                    {rooms.map(({ roomName, roomCode, roomId }, roomIndex) => (
                      <BoxRoomStyle
                        key={roomIndex}
                        onClick={() =>
                          onSelectRoomClick(order, roomTypeId, roomId)
                        }
                      >
                        <BoxRoom
                        // backgroundColor={
                        //   unAvailableRoom.some(u => u.roomId === _id)
                        //     ? '#F8F9FB'
                        //     : activeRoom === _id
                        //     ? 'linear-gradient(221.86deg, #8C56D9 2.71%, #53389E 97.79%)'
                        //     : ''
                        // }
                        // color={
                        //   unAvailableRoom.some(u => u.roomId === _id)
                        //     ? '#D0D4DD'
                        //     : activeRoom === _id
                        //     ? '#ffffff'
                        //     : ''
                        // }
                        >
                          <TextLabel
                            fontSize='0.85rem'
                            //   color={
                            //     unAvailableRoom.some(u => u.roomId === _id)
                            //       ? '#E4E8EB'
                            //       : activeRoom === _id
                            //       ? '#FFFFFF'
                            //       : ''
                            //   }
                          >
                            {roomName}
                          </TextLabel>
                          <TextLabel
                            fontSize='0.85rem'
                            // color={
                            //   unAvailableRoom.some(u => u.roomId === _id)
                            //     ? '#E4E8EB'
                            //     : activeRoom === _id
                            //     ? '#FFFFFF'
                            //     : ''
                            // }
                          >{`(${roomCode})`}</TextLabel>
                        </BoxRoom>
                      </BoxRoomStyle>
                    ))}
                  </BoxRoomContainer>
                </BoxRoomType>
              </>
            )
          )}
        <div onClick={() => setSeeMore(!seeMore)}>ดูห้องเพิ่ม</div>
      </Scrollbar>
    </Container>
  )
}

export default CardRoom
