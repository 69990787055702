import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Button2Props } from './Button2'

import ReduxStore from '../redux/models/ReduxStore'
import { getCurrentStoreRole, UserTypes } from '../redux/models/User'

const Container = styled.div<Omit<Button2Props, 'icon' | 'iconBF'>>`
  background: ${props =>
    props.active
      ? props.backgroundColorActive ||
        'linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
      : props.backgroundColor || '#f4f6fa'};
  color: ${props => (props.active ? '#fff' : props.color || '#000')};
  border: ${props => props.border || 'none'};
  border-radius: ${props => props.borderRadius || '5px'};
  padding: ${props => props.padding || '0.5rem 0'};
  /* font-family: 'Kanit', sans-serif; */
  outline: 0;
  margin: ${props => props.margin || '0.125rem 0'};
  width: ${props => props.width || '100%'};
  font-size: ${props => props.size || '1rem'};
  font-weight: ${props => props.fontWeight || '400'};
  text-align: center;
  cursor: ${props => props.cursor || 'pointer'};
  transition: all 0.2s linear;
  /* font-family: 'Kanit', sans-serif; */
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'center'};
  &:hover {
    background: ${props =>
      props.backgroundColorHover ||
      'linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'};
    color: #fff;
  }
  & > svg {
    margin-left: ${props => props.marginLeft || '0.5rem'};
    margin-right: ${props => props.marginRight || '0.5rem'};
  }
`

const ButtonSave = ({
  icon,
  children,
  active: _active,
  onClick,
  backgroundColor,
  backgroundColorActive,
  backgroundColorHover,
  width,
  iconBF,
  border,
  color,
  margin,
  cursor,
  padding,
  fontWeight,
  size,
  borderRadius,
  marginLeft,
  marginRight,
  justifyContent,
  disabled,
}: PropsWithChildren<Button2Props>) => {
  const user = useSelector((state: ReduxStore) => state.user)
  const role = getCurrentStoreRole(user)
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(!!_active)
  }, [_active])

  const onMouseEnter = () => {
    setActive(true)
  }

  const onMouseLeave = () => {
    if (_active) return
    setActive(false)
  }

  return (
    <Container
      active={active}
      backgroundColor={role === UserTypes.General ? '#F8F9FB' : backgroundColor}
      backgroundColorActive={
        role === UserTypes.General ? '#F8F9FB' : backgroundColorActive
      }
      backgroundColorHover={
        role === UserTypes.General ? '#F8F9FB' : backgroundColorHover
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={disabled ? undefined : onClick}
      width={width}
      border={role === UserTypes.General ? '2px solid #F8F9FB' : border}
      color={role === UserTypes.General ? '#98A1B2' : color}
      margin={margin}
      cursor={cursor}
      padding={padding}
      fontWeight={fontWeight}
      size={size}
      borderRadius={borderRadius}
      marginLeft={marginLeft}
      marginRight={marginRight}
      justifyContent={justifyContent}
    >
      {iconBF === 'after' ? (
        <>
          {children}
          {icon &&
            React.isValidElement(icon) &&
            React.cloneElement(icon as React.ReactElement<{ color: string }>, {
              color:
                role === UserTypes.General
                  ? '#98A1B2'
                  : active
                  ? '#fff'
                  : icon.props.color,
            })}
        </>
      ) : (
        <>
          {icon &&
            React.isValidElement(icon) &&
            React.cloneElement(icon as React.ReactElement<{ color: string }>, {
              color:
                role === UserTypes.General
                  ? '#98A1B2'
                  : active
                  ? '#fff'
                  : icon.props.color,
            })}
          {children}
        </>
      )}
    </Container>
  )
}

export default ButtonSave
