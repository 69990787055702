import React from 'react'

import { IconProps } from '../lib/icons/IconPropsType'

const CreditIcon = ({ size, color }: IconProps) => (
  <svg
    width={size || '27'}
    height={size || '20'}
    viewBox='0 0 27 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_858_58)'>
      <path
        d='M26.25 5.41667V2.55833C26.25 1.14583 25.1042 0 23.6917 0H2.55833C1.14583 0 0 1.14583 0 2.55833V5.41667H26.25Z'
        fill={color || '#475467'}
      />
      <path
        d='M0 8.33594V17.4443C0 18.8568 1.14583 20.0026 2.55833 20.0026H23.6917C25.1042 20.0026 26.25 18.8568 26.25 17.4443V8.33594H0ZM22.7083 17.9193C22.2708 17.9193 21.8833 17.7234 21.6167 17.4151C21.35 17.7193 20.9625 17.9193 20.525 17.9193C19.7208 17.9193 19.0667 17.2651 19.0667 16.4609C19.0667 15.6568 19.7208 15.0026 20.525 15.0026C20.9625 15.0026 21.35 15.1984 21.6167 15.5068C21.8833 15.2026 22.2708 15.0026 22.7083 15.0026C23.5125 15.0026 24.1667 15.6568 24.1667 16.4609C24.1667 17.2651 23.5125 17.9193 22.7083 17.9193Z'
        fill={color || '#475467'}
      />
    </g>
    <defs>
      <clipPath id='clip0_858_58'>
        <rect width='26.25' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default CreditIcon
