import {
  CLEAR_PAY_PACKAGE,
  GET_PAY_PACKAGE,
} from '../actions/payPackageActions'
import { PayPackage } from '../models/Payment'

const getInitialState = (): PayPackage => ({
  payCustomerPackages: [],
  splitPayCustomerPackages: [],
  packageDiscount: 0,
  splitPackageDiscount: 0,
})

interface PayPackageAction {
  type: string
  payload: {
    data: Partial<PayPackage>
  }
}

const payPackageReducers = (
  state = getInitialState(),
  action: PayPackageAction
) => {
  switch (action.type) {
    case GET_PAY_PACKAGE:
      return {
        ...state,
        ...action.payload.data,
      }
    case CLEAR_PAY_PACKAGE:
      return getInitialState()
    default:
      return state
  }
}

export default payPackageReducers
