import { Dayjs } from 'dayjs'
import React, { useRef } from 'react'
import styled from 'styled-components'

import Button from '@/components/atoms/Button'
import { ClockIcon } from '@/components/atoms/icons/ClockIcon'
import { XIcon } from '@/components/atoms/icons/XIcon'
import { Input } from '@/components/atoms/input'
import useOnClickOutside from '@/hook/useOnClickOutside'
import typography from '@/styles/typography'

export const StyledActionTimePopup = styled.div`
  background-color: var(--popup);
  padding: 10px;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow);
  z-index: 1000;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: default;
`

export interface TimeActionPopupProps
  extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean
  onClose?: () => void | Promise<void>
  actions?: React.ReactNode[]
  image?: string
  title?: string
  start?: Dayjs
  end?: Dayjs
}

const StyledCloseButton = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  padding: 4px;
  :hover {
    background-color: var(--primary-bg);
    border-radius: 50%;
  }
`

export const StyledTimeActionPopupTitle = styled.div`
  ${typography.body3}
  ${typography.semibold}
    display: flex;
  gap: 8px;
  align-items: center;
`

export const StyledTimeActionPopupImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

export const StyledTimeActionPopupContent = styled.div``

export const StyledTimeActionPopupRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 4px 0px;
`

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
`

export const TimeActionPopup = (props: TimeActionPopupProps) => {
  const {
    open = false,
    onClose = () => {},
    start,
    end,
    actions,
    image,
    title,
    ...rest
  } = props
  const ref = useRef(null)
  useOnClickOutside(ref, onClose)
  if (!open) return null
  return (
    <StyledActionTimePopup {...rest} ref={ref}>
      <StyledCloseButton onClick={onClose}>
        <XIcon size={10} />
      </StyledCloseButton>
      <StyledTimeActionPopupTitle>
        {props?.image && (
          <StyledTimeActionPopupImage src={props.image} alt='icon' />
        )}
        {props.title}
      </StyledTimeActionPopupTitle>
      <StyledTimeActionPopupContent>
        {props.children}
      </StyledTimeActionPopupContent>
      <StyledTimeActionPopupRow>
        <Input
          value={start?.format('HH:mm')}
          readOnly
          style={{
            width: '100px',
            minWidth: '0px',
          }}
          icon={{
            right: <ClockIcon size={16} onClick={onClose} />,
          }}
        />
        <span> - </span>
        <Input
          value={end?.format('HH:mm')}
          readOnly
          style={{
            width: '100px',
            minWidth: '0px',
          }}
          icon={{
            right: <ClockIcon size={16} onClick={onClose} />,
          }}
        />
      </StyledTimeActionPopupRow>
      <StyledGrid>
        {props.actions?.map((action, index) => {
          return action
        })}
      </StyledGrid>
    </StyledActionTimePopup>
  )
}
