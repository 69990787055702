import { SelectorOption } from './Option'

export default interface Nationality {
  _id: number
  nationality: string
  status: number
  brandId: number
  storeId: number
}

export const convertNationalitiesToOptions = (
  nationalities = [] as Nationality[]
) => {
  const options = nationalities.reduce((acc, info) => {
    const { status, _id, nationality } = info

    if (status) {
      acc.push({ value: _id, label: nationality })
    }

    return acc
  }, [] as SelectorOption[])

  return options
}
