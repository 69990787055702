import React from 'react'

const PromotionPercentIcon = ({ size, color = '' }) => (
  <svg
    width={size || '64'}
    height={size || '64'}
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='64' height='64' rx='32' fill={color || '#32D582'} />
    <path
      d='M49.583 32.645L48.4393 31.2122C47.4967 30.0308 47.1448 28.4912 47.4841 27.0144L47.8926 25.2297C48.6027 22.1254 46.2399 19.1655 43.0601 19.1655H41.2314C39.7232 19.1655 38.2968 18.4806 37.3541 17.2992L36.2104 15.8664C34.2246 13.3779 30.4416 13.3779 28.4558 15.8664L27.3121 17.2992C26.3695 18.4806 24.943 19.1655 23.4348 19.1655H21.6062C18.4201 19.1655 16.0636 22.1254 16.7737 25.2297L17.1821 27.0144C17.5152 28.4849 17.1633 30.0308 16.227 31.2122L15.0832 32.645C13.0975 35.1335 13.9395 38.8222 16.8114 40.2047L18.4578 40.9965C19.8152 41.6501 20.8018 42.8881 21.1411 44.3585L21.5496 46.1432C22.2597 49.2476 25.6657 50.8877 28.5375 49.5052L30.184 48.7134C31.5413 48.0599 33.1249 48.0599 34.4886 48.7134L36.135 49.5052C39.0006 50.8877 42.4128 49.2476 43.123 46.1432L43.5314 44.3585C43.8645 42.8881 44.8574 41.6501 46.2147 40.9965L47.8612 40.2047C50.7267 38.8222 51.5688 35.1335 49.5893 32.645H49.583ZM24.1638 28.2838C24.1638 26.7253 25.4332 25.4559 26.9916 25.4559C28.5501 25.4559 29.8195 26.7253 29.8195 28.2838C29.8195 29.8422 28.5501 31.1116 26.9916 31.1116C25.4332 31.1116 24.1638 29.8422 24.1638 28.2838ZM28.1039 39.4507C27.796 39.7586 27.3938 39.9094 26.9916 39.9094C26.5895 39.9094 26.1873 39.7586 25.8793 39.4507C25.2635 38.8348 25.2635 37.8419 25.8793 37.2261L36.5623 26.5431C37.1782 25.9272 38.1711 25.9272 38.7869 26.5431C39.4028 27.1589 39.4028 28.1518 38.7869 28.7677L28.1039 39.4507V39.4507ZM37.6746 40.5378C36.1162 40.5378 34.8468 39.2684 34.8468 37.71C34.8468 36.1515 36.1162 34.8821 37.6746 34.8821C39.2331 34.8821 40.5025 36.1515 40.5025 37.71C40.5025 39.2684 39.2331 40.5378 37.6746 40.5378Z'
      fill={color || 'white'}
    />
  </svg>
)

export default PromotionPercentIcon
