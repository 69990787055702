import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'

import SwitchButton from '../../atoms/SwitchButton'
import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'
import Time from '../timePicker/Time'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
  margin-right: 1rem;
`
const Box = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: 100%;
  height: fit-content;
`
const BoxStyle = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex: ${props => props.flex || '1 0 auto'};
  max-width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};
  justify-content: ${props => props.justifyContent || 'unset'};
  align-items: ${props => props.alignItems || 'unset'};
`
const BoxSwitch = styled.div`
  background: #f8f9fb;
  border: 2px solid #e4e8eb;
  border-radius: 8px;
  padding: 0.5rem;
  display: flex;
`
const Line = styled.div`
  border-bottom: 1px solid #e4e8eb;
  width: 100%;
  height: 1px;
  margin: 0.5rem 0;
`
const BoxDay = styled.div`
  background-color: ${props => (props.active ? '#8c56d9' : '#E4E8EB')};
  color: ${props => (props.active ? '#FFFFFF' : '#D0D4DD')};
  border-radius: 8px;
  width: 80px;
  padding: 0.75rem 0.5rem;
  text-align: center;
  height: fit-content;
  opacity: ${props => (props.opacity ? 'unset' : '0.7')};
`
const BoxTextTime = styled.div`
  background: #ffffff;
  border: 2px solid;
  border-color: ${props => (props.active ? '#8C56D9' : '#e4e8eb')};
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  border-radius: 8px;
  color: ${props => (props.active ? '#00000' : '#98a1b2')};
  padding: 0.75rem 0.5rem;
  text-align: center;
  width: 80px;
  font-weight: 500;
`
const BoxTime = styled.div`
  position: relative;
`

const OpenNorMal = ({
  dataDateTime: _dataDateTime,
  edit,
  onTimeChange: _onTimeChange,
  sameTime,
  onSameTimeChange,
  success,
  onRestDayChange,
}) => {
  const [dataDateTime, setDataDateTime] = useState([])
  const days = [
    'วันจันทร์',
    'วันอังคาร',
    'วันพุธ',
    'วันพฤหัส',
    'วันศุกร์',
    'วันเสาร์',
    'วันอาทิต',
  ]
  const [activeTime, setActiveTime] = useState({ type: '', index: -1 })
  const [hour, setHour] = useState('10')
  const [minute, setMinute] = useState('10')

  useEffect(() => {
    if (_dataDateTime.length) {
      setDataDateTime(_dataDateTime.sort((a, b) => a.day - b.day))
    }
  }, [_dataDateTime])

  useEffect(() => {
    if (success) {
      setActiveTime({ type: '', index: -1 })
    }
  }, [success])

  const onSameTimeClick = () => {
    if (!edit) {
      return false
    }
    onSameTimeChange()
    setActiveTime({ type: '', index: -1 })
  }

  const onDayClick = index => {
    if (!edit) {
      return false
    }
    const newArrDateTime = [...dataDateTime]
    newArrDateTime[index] = {
      ...newArrDateTime[index],
      restDay: !newArrDateTime[index].restDay,
    }

    setDataDateTime(newArrDateTime)
    onRestDayChange(newArrDateTime)
  }

  const onActiveTimeClick = (type, index = -1) => {
    if (!edit) {
      return false
    }
    if (type === activeTime.type && index === activeTime.index) {
      setActiveTime({ type: '', index: -1 })
    } else {
      setActiveTime({ type: type, index: index })
      if (type === 'open') {
        if (index === -1) {
          setHour(dataDateTime[0].openTime.hour)
          setMinute(dataDateTime[0].openTime.minute)
        } else {
          setHour(dataDateTime[index].openTime.hour)
          setMinute(dataDateTime[index].openTime.minute)
        }
      } else if (type === 'close') {
        if (index === -1) {
          setHour(dataDateTime[0].closeTime.hour)
          setMinute(dataDateTime[0].closeTime.minute)
        } else {
          setHour(dataDateTime[index].closeTime.hour)
          setMinute(dataDateTime[index].closeTime.minute)
        }
      }
    }
  }

  const onTimeChange = (hour, minute) => {
    if (!edit) {
      return false
    }
    _onTimeChange(hour, minute, activeTime.type, activeTime.index)
  }

  const ShowTime = () => {
    if (!edit) {
      return false
    }
    return (
      <div
        style={{
          background: '#FFFFFF',
          boxShadow: '0px 24px 40px rgba(107, 107, 107, 0.08)',
          borderRadius: '10px',
          position: 'absolute',
          width: '100%',
          zIndex: '5',
        }}
      >
        <Time hourNow={hour} minuteNow={minute} timeActive={onTimeChange} />
      </div>
    )
  }

  return (
    <Container>
      <Box>
        <BoxStyle flexDirection='column' flex='1 0 75%' width='75%'>
          <TextLabel fontWeight='600'>
            {`วันและเวลา เปิด/ปิด ร้านค้า`}
          </TextLabel>
          <TextLabel color='#98A1B2'>{`เปิด/ปิด เวลาเดียวกันทุกวัน`}</TextLabel>
        </BoxStyle>
        <BoxStyle flex='1 0 25%' width='25%' justifyContent='flex-end'>
          <BoxSwitch>
            <TextLabel paddingRight='0.5rem' fontWeight='600'>
              {sameTime ? 'เปิด' : 'ปิด'}
            </TextLabel>
            <SwitchButton
              name='stats-open'
              toggled={sameTime}
              onChange={() => onSameTimeClick()}
              backgroundColor={sameTime ? '#8C56D9' : '#E5ECED'}
              //   height='15px'
              width='18px'
              labelHeight='20px'
              labelWidth='40px'
            />
          </BoxSwitch>
        </BoxStyle>
      </Box>
      {!sameTime ? (
        <div className='mt-4'>
          <div>
            {!!dataDateTime.length && (
              <>
                {days.map((day, index) => (
                  <Fragment key={index}>
                    <Box>
                      <BoxStyle>
                        <BoxDay
                          active={!dataDateTime[index].restDay}
                          opacity={edit}
                          onClick={() => onDayClick(index)}
                        >
                          {day}
                        </BoxDay>
                      </BoxStyle>
                      <BoxStyle justifyContent='flex-end'>
                        <BoxTime
                          onClick={() => onActiveTimeClick('open', index)}
                        >
                          <BoxTextTime
                            active={
                              activeTime.type === 'open' &&
                              activeTime.index === index
                            }
                          >
                            {`${utils.formatTime(
                              dataDateTime[index].openTime.hour
                            )}:${utils.formatTime(
                              dataDateTime[index].openTime.minute
                            )}`}
                          </BoxTextTime>
                          {activeTime.type === 'open' &&
                            activeTime.index === index &&
                            ShowTime()}
                        </BoxTime>
                        <TextLabel
                          paddingRight='0.25rem'
                          paddingLeft='0.25rem'
                          color='#98A1B2'
                          style={{ margin: 'auto 0' }}
                        >
                          -
                        </TextLabel>
                        <BoxTime
                          onClick={() => onActiveTimeClick('close', index)}
                        >
                          <BoxTextTime
                            active={
                              activeTime.type === 'close' &&
                              activeTime.index === index
                            }
                          >
                            {`${utils.formatTime(
                              dataDateTime[index].closeTime.hour
                            )}:${utils.formatTime(
                              dataDateTime[index].closeTime.minute
                            )}`}
                          </BoxTextTime>

                          {activeTime.type === 'close' &&
                            activeTime.index === index &&
                            ShowTime()}
                        </BoxTime>
                        {/* {ShowTime()} */}
                      </BoxStyle>
                    </Box>
                    <Line></Line>
                  </Fragment>
                ))}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className='mt-4'>
          {dataDateTime.length && (
            <>
              <Box style={{ flexWrap: 'wrap' }}>
                {days.map((day, index) => (
                  <BoxStyle
                    className='mb-3'
                    key={index}
                    flex='1 0 25%'
                    width='25%'
                  >
                    <BoxDay
                      active={!dataDateTime[index].restDay}
                      opacity={edit}
                      onClick={() => onDayClick(index)}
                    >
                      {day}
                    </BoxDay>
                  </BoxStyle>
                ))}
              </Box>
              <Line></Line>
              <Box>
                <BoxStyle>
                  <BoxTime onClick={() => onActiveTimeClick('open', -1)}>
                    <BoxTextTime active={activeTime.type === 'open'}>
                      {`${utils.formatTime(
                        dataDateTime[0].openTime.hour
                      )}:${utils.formatTime(dataDateTime[0].openTime.minute)}`}
                    </BoxTextTime>
                    {activeTime.type === 'open' && ShowTime()}
                  </BoxTime>
                  <TextLabel
                    paddingRight='0.25rem'
                    paddingLeft='0.25rem'
                    color='#98A1B2'
                    style={{ margin: 'auto 0' }}
                  >
                    -
                  </TextLabel>
                  <BoxTime onClick={() => onActiveTimeClick('close', -1)}>
                    <BoxTextTime active={activeTime.type === 'close'}>
                      {`${utils.formatTime(
                        dataDateTime[0].closeTime.hour
                      )}:${utils.formatTime(dataDateTime[0].closeTime.minute)}`}
                    </BoxTextTime>
                    {activeTime.type === 'close' && ShowTime()}
                  </BoxTime>
                  {/* {ShowTime()} */}
                </BoxStyle>
              </Box>
            </>
          )}
        </div>
      )}
    </Container>
  )
}

export default OpenNorMal
