import React, { ReactNode } from 'react'
import styled from 'styled-components'

import Label from '../../atoms/Label'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export interface ChartHeaderProps {
  name: string
  value?: string
  color?: string
  valueColor?: string
  icon?: ReactNode
}

const ChartHeader = ({
  name,
  value,
  color,
  valueColor,
  icon,
}: ChartHeaderProps) => {
  return (
    <Container>
      {icon}
      <HeaderContainer>
        <Label size='1rem' weight={500} color={color || '#000'}>
          {name}
        </Label>
        <Label size='1rem' weight={500} color={valueColor} lineCap>
          {value}
        </Label>
      </HeaderContainer>
    </Container>
  )
}

export default ChartHeader
