import React from 'react'

interface BathIcon {
  size?: string | number
  color?: string
}

const BahtIcon = ({ size, color }: BathIcon) => (
  <svg
    width={size || '30'}
    height={size || '30'}
    viewBox='0 0 30 30'
    fill={color || '#8C56D9'}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M15 30C10.9934 30 7.22648 28.4398 4.39336 25.6066C1.56023 22.7735 0 19.0066 0 15C0 10.9934 1.56029 7.22654 4.39336 4.39336C7.22643 1.56018 10.9934 0 15 0C19.0066 0 22.7735 1.56023 25.6066 4.39336C28.4398 7.22648 30 10.9934 30 15C30 19.0066 28.4397 22.7735 25.6066 25.6066C22.7736 28.4398 19.0066 30 15 30V30ZM15 1.875C7.76285 1.875 1.875 7.76285 1.875 15C1.875 22.2371 7.76285 28.125 15 28.125C22.2371 28.125 28.125 22.2371 28.125 15C28.125 7.76285 22.2371 1.875 15 1.875Z' />
    <path d='M18.4139 15C19.194 14.3123 19.6875 13.3067 19.6875 12.1875C19.6875 10.617 18.7165 9.26965 17.3438 8.71213V7.5C17.3438 6.98227 16.924 6.5625 16.4062 6.5625C15.8885 6.5625 15.4688 6.98227 15.4688 7.5V8.4375H13.5938V7.5C13.5938 6.98227 13.174 6.5625 12.6562 6.5625C12.1385 6.5625 11.7188 6.98227 11.7188 7.5V8.4375H9.84375C9.32596 8.4375 8.90625 8.85727 8.90625 9.375C8.90625 9.89273 9.32596 10.3125 9.84375 10.3125H10.7812V15V19.6875H9.84375C9.32596 19.6875 8.90625 20.1073 8.90625 20.625C8.90625 21.1427 9.32596 21.5625 9.84375 21.5625H11.7188V22.5C11.7188 23.0177 12.1385 23.4375 12.6562 23.4375C13.174 23.4375 13.5938 23.0177 13.5938 22.5V21.5625H15.4688V22.5C15.4688 23.0177 15.8885 23.4375 16.4062 23.4375C16.924 23.4375 17.3438 23.0177 17.3438 22.5V21.2879C18.7165 20.7304 19.6875 19.383 19.6875 17.8125C19.6875 16.6933 19.194 15.6877 18.4139 15ZM12.6562 10.3125H15.9375C16.9714 10.3125 17.8125 11.1536 17.8125 12.1875C17.8125 13.2214 16.9714 14.0625 15.9375 14.0625H12.6562V10.3125ZM15.9375 19.6875H12.6562V15.9375H15.9375C16.9714 15.9375 17.8125 16.7786 17.8125 17.8125C17.8125 18.8464 16.9714 19.6875 15.9375 19.6875Z' />
  </svg>
)

export default BahtIcon
