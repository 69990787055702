import React from 'react'
import { useSelector } from 'react-redux'

import SelectorBase from './SelectorBase'

const SelectorOccupation = ({
  name = 'occupation',
  open,
  onClick,
  option = {
    value: -1,
    label: 'อาชีพ',
  },
  onOptionClick,
  error = false,
  readOnly = false,
}) => {
  const { occupationOptions } = useSelector(state => state.brand)

  return (
    <SelectorBase
      name={name}
      option={option}
      options={occupationOptions}
      open={open}
      onClick={onClick}
      onOptionClick={onOptionClick}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorOccupation
