import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const BaseContainer = styled.div<{ height?: string }>`
  height: ${props => props.height || '13px'};
`

const Container = styled(BaseContainer)<{ background?: string }>`
  width: 100%;
  background: ${props => props.background || '#dce0e3'};
  border-radius: 15px 0px 0px 15px;
  border-radius: 15px;
  position: relative;
  & > span {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
`

interface CoverProps {
  percentage?: number
  background?: string
}

const Cover = styled(BaseContainer)<CoverProps>`
  width: ${props => `${props.percentage}%`};
  background: ${props => props.background};
  border-radius: 15px;
  position: absolute;
  left: 0;
  transition: width 0.5s;
`

export interface GaugeProps {
  background: string
  baseBackground: string
  height?: string
  percentage: number
}

const Gauge = ({
  background,
  baseBackground,
  height,
  percentage,
  children,
}: PropsWithChildren<GaugeProps>) => {
  return (
    <Container background={baseBackground} height={height}>
      <Cover percentage={percentage} height={height} background={background} />
    </Container>
  )
}

export default Gauge
