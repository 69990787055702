import React from 'react'

import SelectorBase from './SelectorBase'

const options = ['+66']

const SelectorCountryCode = ({
  name = 'countryCode',
  open,
  onClick,
  option = '+66',
  onOptionClick,
  error = false,
  readOnly = false,
}) => {
  return (
    <SelectorBase
      name={name}
      option={option}
      options={options}
      open={open}
      onClick={onClick}
      onOptionClick={onOptionClick}
      error={error}
      readOnly={readOnly}
      width='85px'
      borderWidth='1px'
      borderRadius='4px 0 0 4px'
    />
  )
}

export default SelectorCountryCode
