import useApi from "@/hook/useApi"
import React from "react"
// @ts-ignore
import api from "@/api"
import DropZoneTab from "@/components/organisms/dnd"
import { StaffRow } from "../row/StaffRow"

export const StaffListTab = () => {
    const { data: _staffs,refetch: refetchStaffs } = useApi(api.getStaff)
    const { data: staffs } = _staffs || {} as any
    const { data: _staffTypes} = useApi(api.getStaffType)
    const { data: staffTypes } = _staffTypes || {} as any

    const handleDrop = async (draggedItem: any, array: any) => {
        const newStaffs = array.map((item: any) => ({
            ...item.metadata,
            staffTypeId: parseInt(item.group),
            number: item.index
        }))
        await api.updateAllStaff(newStaffs).then(() => {
            refetchStaffs()
        })
    }

    const items = staffs?.map((staff: any) => ({
        id: staff._id,
        index: staff.number || 0,
        group: staff.staffTypeId?.toString(),
        item: <StaffRow staff={staff} />,
        metadata: staff
    }))

    return (
        <div 
        style={{
            flex: 1,
            flexGrow: 1,
        }}
        >
            <DropZoneTab
                tabName="staff"
                items={items}
                groups={staffTypes?.map((staffType: any) => {
                    return {
                        id: staffType.staffTypeId?.toString(),
                        label: staffType.staffType,
                        actions: [
                            <span>Edit</span>
                            ,
                            'Delete'
                        ]
                    }
                })}
                onDrop={handleDrop}
            />
        </div>
    )
}