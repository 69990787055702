import React, { useState } from 'react'
import styled from 'styled-components'
import TextLabel from '../../atoms/TextLabel'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Box = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border: 2px solid #e4e8eb;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
`
const Arrow = styled.div`
  position: relative;
  height: 10px;
  width: 2px;
  border-radius: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 5px;
  transition: all 0.4s ease;
  transform: ${props => (props.open ? 'rotate(-135deg)' : 'rotate(45deg)')};
  right: 1rem;
  bottom: ${props => (props.open ? '-4px' : '1px')};
`
const BoxDetail = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f9fb;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  border-radius: 8px;
  padding: 1rem;
`

const ConnectLine = ({ lineId }) => {
  const [openArrow, setOpenArrow] = useState(false)

  const onArrowClick = () => {
    setOpenArrow(!openArrow)
  }

  return (
    <Container>
      <div className='mt-3 mb-2'>
        <TextLabel fontWeight='600'>
          สถานะเชื่อมต่อการแจ้งเตือนกับ Line
        </TextLabel>
      </div>
      <Box onClick={onArrowClick.bind(this)}>
        <TextLabel>เชื่อมต่อสำเร็จแล้ว</TextLabel>
        <Arrow open={openArrow}></Arrow>
      </Box>
      {openArrow && (
        <BoxDetail>
          <TextLabel>วิธีการเชื่อมต่อกับ Line</TextLabel>
          <TextLabel color='#98A1B2'>1. Add Line :@posstaff</TextLabel>
          <TextLabel color='#98A1B2'>
            {`2. พิมพ์รหัส ${lineId} ลงกล่องข้อความ`}
          </TextLabel>
        </BoxDetail>
      )}
    </Container>
  )
}

export default ConnectLine
