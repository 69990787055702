import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  opacity: ${props => props.opacity || ''};
  &:hover {
    user-select: none;
    cursor: ${props => (!props.disabled ? 'pointer' : '')};
  }
`
const SaleBanIcon = ({ size, colorImage, colorCircle, opacity, onClick }) => (
  <SVG
    width={size || '128'}
    height={size || '128'}
    opacity={opacity}
    onClick={onClick}
    viewBox='0 0 128 128'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <ellipse
      cx='63.9993'
      cy='63.7861'
      rx='63.5364'
      ry='63.7861'
      fill={colorCircle || '#EB5757'}
    />
    <path
      d='M64.5 101C84.0737 101 100 85.0751 100 65.5C100 56.0096 96.3093 47.0935 89.6076 40.3924C82.9051 33.6907 73.9904 30 64.5 30C44.9249 30 29 45.9249 29 65.5C29 74.9904 32.6907 83.9065 39.3924 90.6076C46.0935 97.3093 55.0096 101 64.5 101ZM64.5 95.0833C57.5866 95.0833 51.0394 92.7331 45.7706 88.4126L87.4126 46.7706C91.7331 52.0394 94.0833 58.5866 94.0833 65.5C94.0833 81.812 80.812 95.0833 64.5 95.0833ZM64.5 35.9167C71.4119 35.9167 77.9606 38.2669 83.2279 42.5874L41.5874 84.2294C37.2669 78.9606 34.9167 72.4134 34.9167 65.5C34.9167 49.188 48.188 35.9167 64.5 35.9167Z'
      fill={colorImage || 'white'}
    />
  </SVG>
)

export default SaleBanIcon
