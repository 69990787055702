import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  &:hover {
    user-select: none;
    cursor: ${props => (!props.disabled ? 'pointer' : '')};
  }
`
const RightIcon = ({ onClick, disabled }) => (
  <SVG
    disabled={disabled}
    onClick={onClick}
    width='8'
    height='19'
    viewBox='0 0 8 19'
    fill='none'
  >
    <path
      d='M1.01758 1.15625L6.99967 9.3125L1.01758 17.4688'
      stroke={`rgb(82, 82, 124,${disabled ? '0.5' : '1'})`}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVG>
)

export default RightIcon
