import {
  GET_SALARY,
  UPDATE_SALARIES,
  UPDATE_OPENS,
} from '../actions/dataSalaryAction'

const initState = {
  name: '',
  groupName: '',
  times: [],
  prices: [],
  salaries: [],
  opens: [],
}

const dataSalaryReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_SALARY:
      return {
        ...state,
        ...action.payload.salary,
      }
    case UPDATE_SALARIES:
      return {
        ...state,
        salaries: action.payload.salaries,
      }
    case UPDATE_OPENS:
      return {
        ...state,
        opens: action.payload.opens,
      }
    default:
      return state
  }
}

export default dataSalaryReducers
