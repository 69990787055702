import { BookingHistory } from '@/redux/models/BookingHistory'
import Staff from '@/redux/models/Staff'

import { StaffOrderState } from './types'

import { CustomerOrder } from '../../redux/models/BookOrder'
import utils from '../../utils'

const logicStaffOrder = {
  createStaffOrder: (
    bookingHistories: BookingHistory[],
    staffQueue: Staff[]
  ) => {
    let staffOrders: StaffOrderState[] = []

    for (const bookingHistory of bookingHistories) {
      const { customers, time, startTime, statusId, bookingCode, _id } =
        bookingHistory

      let bookStartTime = time
      if (statusId === 3 || statusId === 4) {
        bookStartTime = startTime
      }
      let bookEndTime = bookStartTime

      for (const customer of customers) {
        let bookEndTimeNumber = utils.convertTime(bookEndTime)

        const { orders } = customer
        for (let i = 0; i < orders.length; i++) {
          const { staffs, time: serviceTime } = orders[i]

          for (const staff of staffs) {
            const { staffId, staffTypeId, staffName, staffImage } = staff

            const findStaffIndex = staffOrders.findIndex(
              s => s.staffId === staffId
            )
            if (findStaffIndex === -1) {
              staffOrders.push({
                staffId,
                staffTypeId,
                staffName,
                staffImage,
                staffWorkingStatus: 1,
                serviceSpecificStaff: [],
                gender: -1,
                orders: [
                  {
                    bookingCode,
                    _id,
                    startTime: bookStartTime,
                    endTime: utils.convertTimeToHourMinute(
                      bookEndTimeNumber + serviceTime
                    ),
                    serviceTime: serviceTime,
                  },
                ],
                free: true,
              })
            } else {
              staffOrders[findStaffIndex].orders.push({
                bookingCode,
                _id,
                startTime: bookStartTime,
                endTime: utils.convertTimeToHourMinute(
                  bookEndTimeNumber + serviceTime
                ),
                serviceTime: serviceTime,
              })
            }
          }
          bookEndTimeNumber += serviceTime
          bookStartTime = utils.convertTimeToHourMinute(bookEndTimeNumber)
        }
      }
    }

    const newStaffs = staffQueue.reduce((acc: StaffOrderState[], info) => {
      const { _id, workingStatus, serviceSpecificStaff, gender } = info

      const findStaff = staffOrders.find(s => s.staffId === _id)

      if (findStaff) {
        acc.push({
          ...findStaff,
          staffWorkingStatus: workingStatus,
          serviceSpecificStaff,
          gender,
        })
      } else {
        acc.push({
          staffId: _id,
          staffTypeId: info.staffTypeId,
          staffName: info.name,
          staffImage: info.image,
          gender,
          staffWorkingStatus: workingStatus,
          orders: [],
          serviceSpecificStaff,
          free: true,
        })
      }

      return acc
    }, [])

    return newStaffs
  },

  checkServiceSpecificStaff: (
    staffOrders: StaffOrderState[],
    serviceId: number
  ) => {
    const filteredStaffOrders = staffOrders.filter(staffOrder => {
      return staffOrder.serviceSpecificStaff.some(
        service => service.serviceId === serviceId
      )
    })

    return filteredStaffOrders
  },

  checkListStaffAvailability: (
    staffOrders: StaffOrderState[],
    customers: CustomerOrder[],
    time: { hour: string | number; minute: string | number },
    serviceIndex: number,
    focusedCustomerIndex: number,
    _serviceTime: number
  ) => {
    var newArrStaffOrder: StaffOrderState[] = utils.deepClone(staffOrders)

    let startTime = utils.convertTime(time)
    let endTime = startTime
    for (let i = 0; i < customers.length; i++) {
      const { services } = customers[i]
      startTime = utils.convertTime(time)
      endTime = startTime

      for (const service of services) {
        const { staffs, time: serviceTime } = service

        if (
          i !==
          (serviceIndex === -1
            ? focusedCustomerIndex + 1
            : focusedCustomerIndex)
        ) {
          for (const staff of staffs) {
            const findStaffIndex = newArrStaffOrder.findIndex(
              s => s.staffId === staff.staffId
            )
            if (findStaffIndex !== -1) {
              newArrStaffOrder[findStaffIndex].orders.push({
                bookingCode: '',
                _id: -1,
                startTime: utils.convertTimeToHourMinute(startTime),
                endTime: utils.convertTimeToHourMinute(startTime + serviceTime),
                serviceTime,
              })
            }
          }
          startTime += serviceTime
          endTime += startTime + serviceTime
        }
      }
    }

    let bookingStartTime = utils.convertTime(time)
    let bookingEndTime = bookingStartTime

    if (
      customers[focusedCustomerIndex]?.services &&
      customers[focusedCustomerIndex]?.services.length
    ) {
      for (
        let i = 0;
        i < customers[focusedCustomerIndex].services.length;
        i++
      ) {
        if (i === (serviceIndex === -1 ? 0 : serviceIndex)) {
          bookingEndTime = bookingStartTime + _serviceTime
        } else {
          bookingStartTime += _serviceTime
        }
      }
    } else {
      bookingEndTime = bookingStartTime + _serviceTime
    }

    // console.log({
    //   bookingStartTime: utils.convertTimeToHourMinute(bookingStartTime),
    //   bookingEndTime: utils.convertTimeToHourMinute(bookingEndTime),
    // })

    for (let i = 0; i < newArrStaffOrder.length; i++) {
      const { orders } = newArrStaffOrder[i]
      for (const order of orders) {
        const bookStartTime = utils.convertTime(order.startTime)
        const bookEndTime = utils.convertTime(order.endTime)

        if (
          (bookStartTime > bookingStartTime && bookEndTime < bookingEndTime) ||
          (bookingStartTime < bookEndTime && bookEndTime < bookingEndTime) ||
          (bookStartTime < bookingEndTime && bookEndTime >= bookingEndTime)
        ) {
          newArrStaffOrder[i].free = false
          break
        }
      }
    }

    return newArrStaffOrder
  },
  checkStaffAvailability: (
    staffOrders: StaffOrderState[],
    customers: CustomerOrder[],
    time: { hour: string | number; minute: string | number },
    serviceIndex: number,
    focusedCustomerIndex: number,
    _serviceTime: number,
    _staffId: number
  ) => {
    var newArrStaffOrder: StaffOrderState[] = utils.deepClone(staffOrders)

    let startTime = utils.convertTime(time)
    let endTime = startTime
    for (let i = 0; i < customers.length; i++) {
      const { services } = customers[i]
      startTime = utils.convertTime(time)
      endTime = startTime

      for (const service of services) {
        const { staffs, time: serviceTime } = service

        if (
          i !==
          (serviceIndex === -1
            ? focusedCustomerIndex + 1
            : focusedCustomerIndex)
        ) {
          for (const staff of staffs) {
            const findStaffIndex = newArrStaffOrder.findIndex(
              s => s.staffId === staff.staffId
            )
            if (findStaffIndex !== -1) {
              newArrStaffOrder[findStaffIndex].orders.push({
                bookingCode: '',
                _id: -1,
                startTime: utils.convertTimeToHourMinute(startTime),
                endTime: utils.convertTimeToHourMinute(startTime + serviceTime),
                serviceTime,
              })
            }
          }
          startTime += serviceTime
          endTime += startTime + serviceTime
        }
      }
    }

    let bookingStartTime = utils.convertTime(time)
    let bookingEndTime = bookingStartTime

    if (
      customers[focusedCustomerIndex]?.services &&
      customers[focusedCustomerIndex]?.services.length
    ) {
      for (
        let i = 0;
        i < customers[focusedCustomerIndex].services.length;
        i++
      ) {
        if (i === (serviceIndex === -1 ? 0 : serviceIndex)) {
          bookingEndTime = bookingStartTime + _serviceTime
        } else {
          bookingStartTime += +_serviceTime
        }
      }
    } else {
      bookingEndTime = bookingStartTime + +_serviceTime
    }

    for (let i = 0; i < newArrStaffOrder.length; i++) {
      const { orders, staffId } = newArrStaffOrder[i]
      for (const order of orders) {
        const bookStartTime = utils.convertTime(order.startTime)
        const bookEndTime = utils.convertTime(order.endTime)

        if (
          ((bookStartTime > bookingStartTime && bookEndTime < bookingEndTime) ||
            (bookingStartTime < bookEndTime && bookEndTime < bookingEndTime) ||
            (bookStartTime < bookingEndTime &&
              bookEndTime >= bookingEndTime)) &&
          staffId === _staffId
        ) {
          // newArrStaffOrder[i].free = false
          return false
          break
        }
      }
    }

    return true
  },
}

export default logicStaffOrder
