import { createDynamicIcon } from './Icon'

export const ChevronLeft = createDynamicIcon(`
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0323 2.47034C17.1022 2.54001 17.1576 2.62277 17.1954 2.71389C17.2332 2.80501 17.2527 2.90269 17.2527 3.00134C17.2527 3.09999 17.2332 3.19767 17.1954 3.28879C17.1576 3.37991 17.1022 3.46267 17.0323 3.53234L8.56184 12.0013L17.0323 20.4703C17.1732 20.6112 17.2523 20.8022 17.2523 21.0013C17.2523 21.2005 17.1732 21.3915 17.0323 21.5323C16.8915 21.6732 16.7005 21.7523 16.5013 21.7523C16.3022 21.7523 16.1112 21.6732 15.9703 21.5323L6.97034 12.5323C6.9005 12.4627 6.84508 12.3799 6.80727 12.2888C6.76946 12.1977 6.75 12.1 6.75 12.0013C6.75 11.9027 6.76946 11.805 6.80727 11.7139C6.84508 11.6228 6.9005 11.54 6.97034 11.4703L15.9703 2.47034C16.04 2.4005 16.1228 2.34508 16.2139 2.30727C16.305 2.26946 16.4027 2.25 16.5013 2.25C16.6 2.25 16.6977 2.26946 16.7888 2.30727C16.8799 2.34508 16.9627 2.4005 17.0323 2.47034Z" fill="black"/>
</svg>
`)

export const ChevronRight = createDynamicIcon(`
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.97034 2.47034C7.04001 2.4005 7.12277 2.34508 7.21389 2.30727C7.30501 2.26946 7.40269 2.25 7.50134 2.25C7.59999 2.25 7.69767 2.26946 7.78879 2.30727C7.87991 2.34508 7.96267 2.4005 8.03234 2.47034L17.0323 11.4703C17.1022 11.54 17.1576 11.6228 17.1954 11.7139C17.2332 11.805 17.2527 11.9027 17.2527 12.0013C17.2527 12.1 17.2332 12.1977 17.1954 12.2888C17.1576 12.3799 17.1022 12.4627 17.0323 12.5323L8.03234 21.5323C7.89151 21.6732 7.7005 21.7523 7.50134 21.7523C7.30218 21.7523 7.11117 21.6732 6.97034 21.5323C6.82951 21.3915 6.75039 21.2005 6.75039 21.0013C6.75039 20.8022 6.82951 20.6112 6.97034 20.4703L15.4408 12.0013L6.97034 3.53234C6.9005 3.46267 6.84508 3.37991 6.80727 3.28879C6.76946 3.19767 6.75 3.09999 6.75 3.00134C6.75 2.90269 6.76946 2.80501 6.80727 2.71389C6.84508 2.62277 6.9005 2.54001 6.97034 2.47034Z" fill="black"/>
</svg>
`)

export const ChevronDown = createDynamicIcon(`
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.47034 6.97034C2.54001 6.9005 2.62277 6.84508 2.71389 6.80727C2.80501 6.76946 2.90269 6.75 3.00134 6.75C3.09999 6.75 3.19767 6.76946 3.28879 6.80727C3.37991 6.84508 3.46267 6.9005 3.53234 6.97034L12.0013 15.4408L20.4703 6.97034C20.5401 6.90061 20.6229 6.84529 20.714 6.80756C20.8051 6.76982 20.9027 6.75039 21.0013 6.75039C21.1 6.75039 21.1976 6.76982 21.2887 6.80756C21.3798 6.84529 21.4626 6.90061 21.5323 6.97034C21.6021 7.04007 21.6574 7.12286 21.6951 7.21396C21.7329 7.30507 21.7523 7.40272 21.7523 7.50134C21.7523 7.59996 21.7329 7.69761 21.6951 7.78872C21.6574 7.87982 21.6021 7.96261 21.5323 8.03234L12.5323 17.0323C12.4627 17.1022 12.3799 17.1576 12.2888 17.1954C12.1977 17.2332 12.1 17.2527 12.0013 17.2527C11.9027 17.2527 11.805 17.2332 11.7139 17.1954C11.6228 17.1576 11.54 17.1022 11.4703 17.0323L2.47034 8.03234C2.4005 7.96267 2.34508 7.87991 2.30727 7.78879C2.26946 7.69767 2.25 7.59999 2.25 7.50134C2.25 7.40269 2.26946 7.30501 2.30727 7.21389C2.34508 7.12277 2.4005 7.04001 2.47034 6.97034Z" fill="black"/>
</svg>
`)

export const ChevronUp = createDynamicIcon(`
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4699 6.97034C11.5396 6.9005 11.6224 6.84508 11.7135 6.80727C11.8046 6.76946 11.9023 6.75 12.0009 6.75C12.0996 6.75 12.1973 6.76946 12.2884 6.80727C12.3795 6.84508 12.4623 6.9005 12.5319 6.97034L21.5319 15.9703C21.6728 16.1112 21.7519 16.3022 21.7519 16.5013C21.7519 16.7005 21.6728 16.8915 21.5319 17.0323C21.3911 17.1732 21.2001 17.2523 21.0009 17.2523C20.8018 17.2523 20.6108 17.1732 20.4699 17.0323L12.0009 8.56184L3.53195 17.0323C3.39112 17.1732 3.20011 17.2523 3.00095 17.2523C2.80178 17.2523 2.61078 17.1732 2.46995 17.0323C2.32912 16.8915 2.25 16.7005 2.25 16.5013C2.25 16.3022 2.32912 16.1112 2.46995 15.9703L11.4699 6.97034Z" fill="black"/>
</svg>
`)
