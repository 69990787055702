import React from 'react'
import styled from 'styled-components'

import InputBox from '../InputBox'

import Box from '../../../atoms/Box'
import TimeIcon2 from '../../../atoms/TimeIcon2'

import { PrepareTime } from '../../../redux/models/Service'
//@ts-ignore
import utils from '../../../utils'

const Container = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
`

interface TableTimePrepare {
  prepareTime: PrepareTime
  onAddPrepareTime: (value: string, type: string) => void
}

const TableTimePrepare = ({
  prepareTime,
  onAddPrepareTime,
}: TableTimePrepare) => {
  return (
    <Box header='ช่วงเวลาเตรียมสถานที่ ก่อนและหลัง ให้บริการ'>
      <Container>
        <InputBox
          header='เวลาก่อนบริการ'
          icon={<TimeIcon2 size='15' />}
          value={prepareTime.before}
          suffix='นาที'
          onChange={e => {
            const value = e.target.value
            if (!utils.checkNumber(value)) return
            onAddPrepareTime(e.target.value, 'before')
          }}
        />
        <InputBox
          header='เวลาหลังบริการ'
          icon={<TimeIcon2 size='15' />}
          value={prepareTime.after}
          suffix='นาที'
          onChange={e => {
            const value = e.target.value
            if (!utils.checkNumber(value)) return
            onAddPrepareTime(e.target.value, 'after')
          }}
        />
      </Container>
    </Box>
  )
}

export default TableTimePrepare
