import {
  UPDATE_TIME_OPEN,
  UPDATE_DATE_CLOSE,
  GET_DATA_STORE,
  UPDATE_DATE_OPEN,
  UPDATE_DATA_STORE,
} from '../actions/dataStoreAction'

const initState = {
  nameEng: '',
  branchEng: '',
  nameThai: '',
  branchThai: '',
  address: '',
  phoneNumber: '',
  email: '',
  facebook: '',
  lineId: '',
  daysTimes: [
    {
      day: 'วันจันทร์',
      status: 'ร้านเปิด',
      timeOpen: { hour: '9', minute: '30' },
      timeClose: { hour: '22', minute: '00' },
    },
    {
      day: 'วันอังคาร',
      status: 'ร้านปิด',
      timeOpen: { hour: '', minute: '' },
      timeClose: { hour: '', minute: '' },
    },
    {
      day: 'วันพุธ',
      status: 'ร้านปิด',
      timeOpen: { hour: '', minute: '' },
      timeClose: { hour: '', minute: '' },
    },
    {
      day: 'วันพฤหัส',
      status: 'ร้านปิด',
      timeOpen: { hour: '', minute: '' },
      timeClose: { hour: '', minute: '' },
    },
    {
      day: 'วันศุกร์',
      status: 'ร้านปิด',
      timeOpen: { hour: '', minute: '' },
      timeClose: { hour: '', minute: '' },
    },
    {
      day: 'วันเสาร์',
      status: 'ร้านปิด',
      timeOpen: { hour: '', minute: '' },
      timeClose: { hour: '', minute: '' },
    },
    {
      day: 'วันอาทิต',
      status: 'ร้านปิด',
      timeOpen: { hour: '', minute: '' },
      timeClose: { hour: '', minute: '' },
    },
  ],
  dateStartClose: [],
  dateStartOpen: [],
}

const dataStoreReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_DATA_STORE:
      return {
        ...state,
        ...action.payload.store,
      }
    case UPDATE_DATA_STORE:
      return {
        ...state,
        ...action.payload.store,
      }
    case UPDATE_TIME_OPEN:
      return {
        ...state,
        daysTimes: action.payload.daysTimes,
      }
    case UPDATE_DATE_CLOSE:
      return {
        ...state,
        dateStartClose: action.payload.dateStartClose,
      }
    case UPDATE_DATE_OPEN:
      return {
        ...state,
        dateStartOpen: action.payload.dateStartOpen,
      }

    default:
      return state
  }
}

export default dataStoreReducers
