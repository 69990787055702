//@ts-ignore
import { useEffect, useState } from 'react'

import Option from '@/redux/models/Option'

//@ts-ignore
import api from '../../../api'
//@ts-ignore
import auth from '../../../auth'
import { ProductGroup } from '../../../redux/models/ProductGroup'
import Room from '../../../redux/models/Room'
import RoomType from '../../../redux/models/RoomType'
import Service, { TimePrice } from '../../../redux/models/Service'
//@ts-ignore
import utils from '../../../utils'

interface UseCardAddProductProps {
  serviceGroup: ProductGroup[]
  currentEditId: number
  currentEditData: Service
}

interface FileImage {
  name: string
  size: number
  type: string
}

interface TimePricesWithRoomType extends TimePrice {
  roomTypes: roomTypeState[]
}

interface roomTypeState {
  roomTypeOptions: RoomTypeOptions[]
  roomTypeOptionOpen: boolean
  roomOptionOpen: boolean
  duration: number
  detail: string
}

interface RoomTypeOptions {
  value: number
  label: string
  checkAll: boolean
  rooms: Option[]
}

interface ServiceState extends Service {
  timePrices: TimePricesWithRoomType[]
  serviceGroup: Option
  serviceGroupOptionOpen: boolean
}

const useCardAddService = ({
  serviceGroup,
  currentEditId,
  currentEditData,
}: UseCardAddProductProps) => {
  const [success, setSuccess] = useState<boolean>(false)
  const [checkName, setCheckName] = useState<boolean>(false)
  const [checkTimePrice, setCheckTimePrice] = useState<boolean>(false)
  const [image, setImage] = useState<string>('')
  const [fileImage, setFileImage] = useState<FileImage | null>(null)
  const [service, setService] = useState<ServiceState>({
    masterId: '',
    _id: -1,
    thaiHandServiceId: '',
    headGroupId: 1,
    groupId: 1,
    name: '',
    description: '',
    image: '',
    timePrices: [
      {
        timePriceId: 1,
        time: 0,
        originalPrice: 0,
        price: 0,
        cost: 0,
        agencyPriceMap: {},
        roomTypes: [
          {
            roomTypeOptions: [],
            roomTypeOptionOpen: false,
            roomOptionOpen: false,
            duration: 0,
            detail: '',
          },
        ],
        roomCount: 1,
      },
    ],
    choices: [
      {
        choiceId: -1,
        choice: '',
        price: 0,
        cost: 0,
      },
    ],
    prepareTime: { before: 0, after: 0 },
    recommend: false,
    temporary: false,
    suspend: false,
    number: 0,
    status: 1,
    applyToAll: true,
    customerCount: 1,
    staffCount: 1,
    storeId: -1,
    serviceGroup: {
      value: serviceGroup[0]._id || -1,
      label: serviceGroup[0].groupName || '',
    },
    serviceGroupOptionOpen: false,
  })

  useEffect(() => {
    if (currentEditId !== -1) {
      setData(currentEditData)
    }
  }, [currentEditId, currentEditData])

  const setData = async (currentEditData: Service) => {
    try {
      const { data: roomTypes }: { data: RoomType[] } =
        await api.getRoomTypeByServiceId(currentEditId)

      const { data: rooms }: { data: Room[] } = await api.getRoomByServiceId(
        currentEditId
      )

      const timePrices: TimePricesWithRoomType[] = []

      for (const timePrice of currentEditData.timePrices) {
        const findRoomTypes = roomTypes
          .map(roomType => ({
            ...roomType,
            serviceSpecificRoomTypes: roomType.serviceSpecificRoomTypes.filter(
              service => service.serviceTime === timePrice.time
            ),
          }))
          .filter(roomType => roomType.serviceSpecificRoomTypes.length > 0)

        const roomTypeOptions = findRoomTypes.reduce(
          (acc: any[], info: any) => {
            const { serviceSpecificRoomTypes, roomTypeName, _id } = info

            for (const serviceSpecificRoomType of serviceSpecificRoomTypes) {
              const { order, duration, detail } = serviceSpecificRoomType

              const findAccIndex = acc.findIndex(
                a => a.order === order && a.duration === duration
              )

              const findRooms = rooms
                .filter(room => room.roomTypeId === _id)
                .map(room => ({
                  ...room,
                  serviceSpecificRooms: room.serviceSpecificRooms.filter(
                    service =>
                      service.serviceTime === timePrice.time &&
                      service.duration === duration
                  ),
                }))
                .filter(room => room.serviceSpecificRooms.length > 0)
                .map(room => ({
                  value: room._id,
                  label: room.roomName,
                }))

              if (findAccIndex === -1) {
                acc.push({
                  order,
                  duration,
                  detail,
                  roomTypeOptionOpen: false,
                  roomOptionOpen: false,
                  roomTypeOptions: [
                    {
                      value: _id,
                      label: roomTypeName,
                      checkAll: false,
                      rooms: findRooms,
                    },
                  ],
                })
              } else {
                acc[findAccIndex].roomTypeOptions.push({
                  value: _id,
                  label: roomTypeName,
                  checkAll: false,
                  rooms: findRooms,
                })
              }
            }

            return acc
          },
          []
        )

        timePrices.push({
          timePriceId: timePrice.timePriceId,
          time: timePrice.time,
          originalPrice: timePrice.originalPrice,
          price: timePrice.price,
          cost: timePrice.cost,
          agencyPriceMap: timePrice.agencyPriceMap || {},
          roomTypes: roomTypeOptions,
          roomCount: roomTypeOptions.length,
        })
      }

      const findServiceGroup = serviceGroup.find(
        s => s._id === currentEditData.groupId
      )

      setService({
        ...currentEditData,
        timePrices: timePrices,
        serviceGroup: {
          value: findServiceGroup ? findServiceGroup._id : -1,
          label: findServiceGroup ? findServiceGroup.groupName : '',
        },
        serviceGroupOptionOpen: false,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeImage = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const files = e.target.files

    if (files && files.length > 0) {
      const file = files[0]
      if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
        alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
      } else if (utils.isFileExceedLimit(file)) {
        alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
      } else {
        setFileImage(file)
        const { image } = await utils.readImage(file)
        setImage(image)
      }
    }
  }

  const onChange = (e: any, name: string) => {
    if (name === 'productGroup') {
      setService(prev => ({
        ...prev,
        serviceGroupOptionOpen: false,
        serviceGroup: e,
      }))
    } else if (name === 'customerCount' || name === 'staffCount') {
      if (e === 'remove') {
        if (name === 'customerCount') {
          if (+service.customerCount > 1) {
            setService(prev => ({
              ...prev,
              customerCount: +prev.customerCount - 1,
            }))
          }
        } else {
          if (+service.staffCount > 1) {
            setService(prev => ({
              ...prev,
              staffCount: +prev.staffCount - 1,
            }))
          }
        }
      } else {
        if (name === 'customerCount') {
          setService(prev => ({
            ...prev,
            customerCount: +prev.customerCount + 1,
          }))
        } else {
          setService(prev => ({
            ...prev,
            staffCount: +prev.staffCount + 1,
          }))
        }
      }
    } else if (name === 'status') {
      setService({ ...service, status: service.status === 1 ? -1 : 1 })
    } else {
      const value: string = e.target.value
      setService({ ...service, [name]: value })
    }
  }

  const onToggle = (value?: boolean) => {
    setService(prev => ({
      ...prev,
      serviceGroupOptionOpen:
        value === undefined ? !service.serviceGroupOptionOpen : value,
    }))
  }

  const onAddTimePrice = () => {
    const newArrTimePrices = [...service.timePrices]
    const lastTimePrice = newArrTimePrices[newArrTimePrices.length - 1]
    const lastRoomTypes =
      lastTimePrice.roomTypes[lastTimePrice.roomTypes.length - 1]

    if (
      (lastTimePrice && !lastTimePrice.time && !lastTimePrice.price) ||
      (!lastRoomTypes.roomTypeOptions.length && !lastRoomTypes.duration)
    ) {
      return
    }

    let max = -1
    for (const timePrice of newArrTimePrices) {
      const { timePriceId } = timePrice
      if (timePriceId >= max) {
        max = timePriceId
      }
    }

    newArrTimePrices.push({
      timePriceId: max + 1,
      time: 0,
      originalPrice: 0,
      price: 0,
      cost: 0,
      agencyPriceMap: {},
      roomTypes: [
        {
          roomTypeOptions: [],
          roomTypeOptionOpen: false,
          roomOptionOpen: false,
          duration: 0,
          detail: '',
        },
      ],
      roomCount: 0,
    })
    // console.log({ newArrTimePrices })
    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  const onRemoveTimePrice = (index: number) => {
    const newArrTimePrices = [...service.timePrices]
    newArrTimePrices.splice(index, 1)
    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  const onTimePriceChange = (value: any, index: number, type: string) => {
    const newArrTimePrices = [...service.timePrices]
    if (type === 'time') {
      newArrTimePrices[index].time = +value
    } else if (type === 'price') {
      newArrTimePrices[index].price = +value
    } else {
      newArrTimePrices[index].agencyPriceMap = {
        ...newArrTimePrices[index].agencyPriceMap,
        ...value,
      }
    }
    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  const onAddRoom = (indexTimePrices: number) => {
    const newArrTimePrices = [...service.timePrices]

    const duration = newArrTimePrices[indexTimePrices].roomTypes.reduce(
      (acc, info) => acc + info.duration,
      0
    )

    if (duration < newArrTimePrices[indexTimePrices].time) {
      newArrTimePrices[indexTimePrices].roomTypes.push({
        roomTypeOptions: [],
        roomTypeOptionOpen: false,
        roomOptionOpen: false,
        duration: 0,
        detail: '',
      })
      setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
    }
  }

  const onRemoveRoomType = (indexTimePrice: number, indexRoomType: number) => {
    const newArrTimePrices = [...service.timePrices]
    newArrTimePrices[indexTimePrice].roomTypes.splice(indexRoomType, 1)
    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  const onOptionRoomTypeClick = (
    item: Option,
    indexTimePrice: number,
    indexRoomType: number
  ) => {
    const newArrTimePrices = [...service.timePrices]
    const findIndex = newArrTimePrices[indexTimePrice].roomTypes[
      indexRoomType
    ].roomTypeOptions.findIndex(r => r.value === item.value)

    if (findIndex === -1) {
      newArrTimePrices[indexTimePrice].roomTypes[
        indexRoomType
      ].roomTypeOptions.push({
        value: +item.value,
        label: item.label,
        checkAll: false,
        rooms: [],
      })
    } else {
      newArrTimePrices[indexTimePrice].roomTypes[
        indexRoomType
      ].roomTypeOptions.splice(findIndex, 1)
    }
    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  const onToggleRoomType = (
    value: boolean,
    indexTimePrice: number,
    indexRoomType: number,
    name: string
  ) => {
    const newArrTimePrices = [...service.timePrices]

    if (name === 'roomType') {
      newArrTimePrices[indexTimePrice].roomTypes[
        indexRoomType
      ].roomTypeOptionOpen =
        value === undefined
          ? !newArrTimePrices[indexTimePrice].roomTypes[indexRoomType]
              .roomTypeOptionOpen
          : value
    } else {
      newArrTimePrices[indexTimePrice].roomTypes[indexRoomType].roomOptionOpen =
        value === undefined
          ? !newArrTimePrices[indexTimePrice].roomTypes[indexRoomType]
              .roomOptionOpen
          : value
    }

    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  // const onToggleRoomType = useCallback(onToggleRoomTypeTEST, [service])

  const onOptionSelectAllClick = (
    options: Option[],
    indexTimePrices: number,
    indexRoomTypes: number,
    indexRoomTypeOptions: number
  ) => {
    const newArrTimePrices = [...service.timePrices]

    newArrTimePrices[indexTimePrices].roomTypes[indexRoomTypes].roomTypeOptions[
      indexRoomTypeOptions
    ].checkAll =
      !newArrTimePrices[indexTimePrices].roomTypes[indexRoomTypes]
        .roomTypeOptions[indexRoomTypeOptions].checkAll

    newArrTimePrices[indexTimePrices].roomTypes[indexRoomTypes].roomTypeOptions[
      indexRoomTypeOptions
    ].rooms = options

    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  const onRoomClick = (
    option: Option,
    checkAll: boolean,
    indexTimePrices: number,
    indexRoomTypes: number,
    indexRoomTypeOptions: number
  ) => {
    const newArrTimePrices = [...service.timePrices]
    newArrTimePrices[indexTimePrices].roomTypes[indexRoomTypes].roomTypeOptions[
      indexRoomTypeOptions
    ].checkAll = checkAll

    const findRoom = newArrTimePrices[indexTimePrices].roomTypes[
      indexRoomTypes
    ].roomTypeOptions[indexRoomTypeOptions].rooms.findIndex(
      r => r.value === option.value
    )

    if (findRoom !== -1) {
      newArrTimePrices[indexTimePrices].roomTypes[
        indexRoomTypes
      ].roomTypeOptions[indexRoomTypeOptions].rooms.splice(findRoom, 1)
    } else {
      newArrTimePrices[indexTimePrices].roomTypes[
        indexRoomTypes
      ].roomTypeOptions[indexRoomTypeOptions].rooms.push({
        value: option.value,
        label: option.label,
      })
    }

    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  const onDurationChange = (
    value: string,
    indexTimePrices: number,
    indexRoomTypes: number,
    name: string
  ) => {
    const newArrTimePrices = [...service.timePrices]

    const serviceTime = newArrTimePrices[indexTimePrices].time

    newArrTimePrices[indexTimePrices].roomTypes[indexRoomTypes].duration =
      +value

    const duration = newArrTimePrices[indexTimePrices].roomTypes.reduce(
      (acc, info, index) => {
        if (index !== indexRoomTypes) {
          acc += info.duration
        }
        return acc
      },
      0
    )

    if (duration + +value > serviceTime) {
      if (duration < serviceTime) {
        newArrTimePrices[indexTimePrices].roomTypes[indexRoomTypes].duration =
          +value - (duration + +value - serviceTime)
      } else {
        newArrTimePrices[indexTimePrices].roomTypes[indexRoomTypes].duration = 0
      }
    }

    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  const onRoomDetailChange = (
    value: string,
    indexTimePrices: number,
    indexRoomTypes: number
  ) => {
    const newArrTimePrices = [...service.timePrices]
    newArrTimePrices[indexTimePrices].roomTypes[indexRoomTypes].detail = value

    setService(prev => ({ ...prev, timePrices: newArrTimePrices }))
  }

  const onAddPrepareTime = (value: string, type: string) => {
    let prepareTime = service.prepareTime
    if (type === 'before') {
      prepareTime = { ...prepareTime, before: +value }
    } else {
      prepareTime = { ...prepareTime, after: +value }
    }
    setService(prev => ({ ...prev, prepareTime: prepareTime }))
  }

  const onApplyChange = () => {
    setService(prev => ({ ...prev, applyToAll: !service.applyToAll }))
  }

  const onSuspendChange = () => {
    setService(prev => ({ ...prev, suspend: !service.suspend }))
  }

  const onSaveClick = async () => {
    try {
      if (service.serviceGroup.value === -1) {
        if (!service.name || !service.timePrices.length) {
          if (!service.name) {
            setCheckName(true)
          }
          // if (
          //   !service.timePrices.length ||
          //   service.timePrices.some(tp => !tp.time || !tp.duration)
          // ) {
          //   // setCheckPrice(true)
          // }
          return false
        }
      } else {
        if (!service.name) {
          if (!service.name) {
            setCheckName(true)
          }
          return false
        }
      }

      let sortTime = [...service.timePrices]
      sortTime.sort((a, b) => a.time - b.time)

      const timePrices = sortTime.reduce(
        (acc: TimePrice[], info: TimePricesWithRoomType) => {
          const { roomTypes, ...rest } = info
          acc.push({ ...rest })
          return acc
        },
        []
      )

      let create = {
        name: service.name,
        groupId: service.serviceGroup.value,
        headGroupId: 1,
        description: service.description,
        timePrices: timePrices,
        choices: service.choices,
        prepareTime: service.prepareTime,
        recommend: service.recommend,
        temporary: service.temporary,
        suspend: service.suspend,
        applyToAll: service.applyToAll,
        image: '',
        status: service.status,
        customerCount: service.customerCount,
        staffCount: service.staffCount,
        // roomCount: service.timePrices[0].roomTypes.length,
        storeId: auth.getStoreId(),
      }
      if (fileImage) {
        const { data } = await api.uploadImageService({
          token: auth.getToken(),
          image: fileImage,
        })
        create = { ...create, image: data }
      }
      console.log({ create })
      if (currentEditId === -1) {
        await callApiCreateService(create)
      } else {
        await callApiUpdateService(create)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateService = async (create: any) => {
    try {
      const { data, success }: { data: Service; success: boolean } =
        await api.createService(create)
      if (success) await createRoomTypeAndRoom(data)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateService = async (create: any) => {
    try {
      const { data, success }: { data: Service; success: boolean } =
        await api.updateService(currentEditId, create)
      if (success) await createRoomTypeAndRoom(data)
    } catch (err) {
      console.log(err)
    }
  }

  const createRoomTypeAndRoom = async (_service: Service) => {
    try {
      let sortTimes = [...service.timePrices]
      sortTimes.sort((a, b) => a.time - b.time)

      let newArrRoomType: any[] = []

      const rooms = sortTimes.reduce(
        (acc: any[], info: TimePricesWithRoomType) => {
          const { roomTypes, time } = info

          for (let i = 0; i < roomTypes.length; i++) {
            const { roomTypeOptions, duration, detail } = roomTypes[i]
            for (const roomTypeOption of roomTypeOptions) {
              const { rooms } = roomTypeOption
              if (roomTypeOption.value) {
                newArrRoomType.push({
                  _id: roomTypeOption.value,
                  serviceId: _service._id,
                  serviceName: _service.name,
                  serviceTime: time,
                  duration,
                  detail,
                  order: i + 1,
                })
              }

              for (const room of rooms) {
                acc.push({
                  _id: room.value,
                  serviceId: _service._id,
                  serviceName: _service.name,
                  serviceTime: time,
                  duration,
                })
              }
            }
          }

          return acc
        },
        []
      )
      const { success: roomTypeSuccess } = await api.updateAllRoomType(
        newArrRoomType
      )
      const { success: roomSuccess } = await api.updateAllRoom(rooms)
      if (roomTypeSuccess && roomSuccess) {
        setSuccess(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return {
    service,
    image,
    // check,
    success,
    checkName,
    onChangeImage,
    onToggle,
    onToggleRoomType,
    onChange,
    onAddTimePrice,
    onAddRoom,
    onRemoveTimePrice,
    onTimePriceChange,
    onRemoveRoomType,
    onOptionRoomTypeClick,
    onOptionSelectAllClick,
    onRoomClick,
    onDurationChange,
    onRoomDetailChange,
    onAddPrepareTime,
    onApplyChange,
    onSuspendChange,
    onSaveClick,
  }
}

export default useCardAddService
