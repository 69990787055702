import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  box-shadow: 0px 4px 4px rgb(71 84 103 / 5%);
`
const SelectWrapper = styled.div`
  position: relative;
  user-select: none;
  width: 100%;
  height: 38px;
`
const Select = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`
const SelectTrigger = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.315rem 0 0.315rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #3b3b3b;
  background: #ffffff;
  cursor: pointer;
  border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: ${props => props.borderColor || '#e4e8eb'};
  border-radius: 8px 0 0 8px;
  /* box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05); */
`
const CustomOptions = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: ${props => (props.open ? '2px solid #8C56D9' : '2px solid #e4e8eb')};
  margin-top: 0.5rem;
  border-radius: 8px;
  background: #fff;
  transition: all 0.5s;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  pointer-events: ${props => (props.open ? 'all' : 'none')};
  z-index: 3;
  max-height: 115px;
  overflow-x: hidden;
  overflow-y: auto;
`
const CustomOption = styled.span`
  position: relative;
  display: block;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #475467;
  /* line-height: 60px; */
  background: ${props => props.backgroundColor || 'none'};
  cursor: pointer;
  transition: all 0.5s;
  :first-child {
    border-radius: 8px 8px 0 0;
  }
  :last-child {
    border-radius: 0 0 8px 8px;
  }
  &:only-child {
    border-radius: 8px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }

  :hover {
    cursor: pointer;
    background-color: #e4e8eb;
  }
  ::selection {
    color: #475467;
    background-color: #e4e8eb;
  }
`
const Arrow = styled.div`
  position: relative;
  height: 10px;
  width: 2px;
  border-radius: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 5px;
  transition: all 0.4s ease;
  transform: ${props => (props.open ? 'rotate(-135deg)' : 'rotate(45deg)')};
  right: 1rem;
  bottom: ${props => (props.open ? '-4px' : '1px')};
`

const InputText = styled.input`
  width: 100%;
  outline: none;
  ::placeholder {
    color: #d0d4dd;
    font-size: 0.9rem;
  }
  border: none;
  height: 100%;
  border-width: 2px 2px 2px 0;
  border-style: solid;
  border-color: ${props => props.borderColor || '#e4e8eb'};
  border-radius: 0 8px 8px 0;
  /* box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05); */
  :focus {
    border-color: #8c56d9;
  }
`

const InputPhoneNumber = ({
  options,
  defaultOption: _defaultOption,
  wrapperRef,
  openArrow,
  onArrowClick,
  onPhoneNumberChange,
  phoneNumber,
  checkPhoneNumber,
  onSelectOptionClick,
}) => {
  const [checkFocus, setCheckFocus] = useState(false)
  const [defaultOption, setDefaultOption] = useState(options[221])

  useEffect(() => {
    if (_defaultOption) {
      const findData = options.find(o => o.d === _defaultOption || '') || ''
      if (findData) {
        setDefaultOption(findData)
      }
    }
  }, [_defaultOption])

  return (
    <Container>
      <SelectWrapper ref={wrapperRef}>
        <Select>
          <SelectTrigger
            borderColor={
              openArrow || checkFocus
                ? '#8C56D9'
                : checkPhoneNumber
                ? '#DB2D1E'
                : ''
            }
            onClick={onArrowClick}
          >
            <div style={{ color: '#000000', fontWeight: '400' }}>
              &nbsp; {defaultOption.d}
            </div>
            <Arrow open={openArrow} onClick={onArrowClick}></Arrow>
          </SelectTrigger>
          <CustomOptions open={openArrow}>
            {options.map(({ d, value }, index) => (
              <CustomOption
                key={index}
                value={index}
                onClick={onSelectOptionClick.bind(this, d)}
                backgroundColor={d === defaultOption.d ? '#E4E8EB' : ''}
              >
                &nbsp; {d}
              </CustomOption>
            ))}
          </CustomOptions>
        </Select>
      </SelectWrapper>
      <InputText
        borderColor={openArrow ? '#8C56D9' : checkPhoneNumber ? '#DB2D1E' : ''}
        placeholder='0xxxxxxxxx'
        value={phoneNumber}
        onFocus={() => setCheckFocus(true)}
        onMouseOut={() => setCheckFocus(false)}
        onChange={val => onPhoneNumberChange(val.target.value)}
      />
    </Container>
  )
}

export default InputPhoneNumber
