import config from '../../config'
import { Enums } from '../../utilities/typing'

export default interface BrandPackage {
  _id: number
  packageCode: string
  name: string
  type: number
  price: number
  expiryDay: number
  totalValue: number
  branches: { storeId: number }[]
  services: {
    serviceMasterId: string
    serviceId: number
    timePrices: { timePriceId: number; time: number }[]
  }[]
  allService: boolean
  quantity: number
  status: number
  storeId: number
}

export interface BrandPackageGroup {
  _id: number
  groupName: string
}

export const PackageTypes = {
  Money: 1,
  Times: 2,
  Minutes: 3,
  Percent: 4,
}

export type PackageType = Enums<typeof PackageTypes>

export const getPackageType = (packageType: number) => {
  return config.PACKAGE_TYPES[packageType - 1]
}
