import { GET_SERVICES } from '../actions/callApiServicesAction'

const initState = {
  // {
  //   headGroupId: -1,
  //   groupId: -1,
  //   name: '',
  //   description: '',
  //   image: '',
  //   timePrices: [],
  //   choices: [],
  //   prepareTime: { before: -1, after: -1 },
  //   recommend: false,
  //   temporary: false,
  //   suspend: false,
  //   number: -1,
  //   status: 0,
  //   applyToAll: true,
  //   storeId: -1,
  // },
}
const callApiServicesReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

export default callApiServicesReducers
