//@ts-ignore
import { useEffect, useState } from 'react'

//@ts-ignore
import api from '../../../api'
//@ts-ignore
import auth from '../../../auth'
import RoomType from '../../../redux/models/RoomType'

interface useCardAddRoomTypeProps {
  currentIdEdit: number
  currentDataEdit: RoomType
}

const useCardAddRoomType = ({
  currentIdEdit,
  currentDataEdit,
}: useCardAddRoomTypeProps) => {
  const [success, setSuccess] = useState<boolean>(false)
  const [checkName, setCheckName] = useState<boolean>(false)
  const [checkCode, setCheckCode] = useState<boolean>(false)
  const [roomType, setRoomType] = useState<RoomType>({
    _id: -1,
    roomTypeName: '',
    roomTypeCode: '',
    serviceSpecificRoomTypes: [],
    private: -1,
    statusRoomType: 1,
    status: 1,
    storeId: -1,
  })

  useEffect(() => {
    if (currentIdEdit !== -1) {
      setRoomType(currentDataEdit)
    }
  }, [currentIdEdit, currentDataEdit])

  const onChange = (value: string, name: string) => {
    setRoomType(prev => ({ ...prev, [name]: value }))
  }

  const onSwitchButtonChange = (name: string) => {
    if (name === 'private') {
      setRoomType(prev => ({ ...prev, private: prev.private === 1 ? -1 : 1 }))
    } else {
      setRoomType(prev => ({ ...prev, status: prev.status === 1 ? -1 : 1 }))
    }
  }

  const onSaveClick = async () => {
    try {
      const newRoomType: RoomType = { ...roomType, storeId: auth.getStoreId() }
      if (currentIdEdit === -1) {
        callApiCreateRoomType(newRoomType)
      } else {
        callApiUpdateRoomType(newRoomType)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateRoomType = async (newRoomType: RoomType) => {
    try {
      const { data, success } = await api.createRoomType(newRoomType)
      setSuccess(success)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateRoomType = async (newRoomType: RoomType) => {
    try {
      const { data, success } = await api.updateRoomType(
        currentIdEdit,
        newRoomType
      )
      setSuccess(success)
    } catch (err) {
      console.log(err)
    }
  }

  return {
    roomType,
    checkName,
    checkCode,
    success,
    onChange,
    onSwitchButtonChange,
    onSaveClick,
  }
}

export default useCardAddRoomType
