import React from 'react'

const XIcon = ({ size, color }) => (
  <svg
    width={size || '16'}
    height={size || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.9794 1.02869C14.2953 0.344604 13.1844 0.344604 12.5003 1.02869L8.00402 5.52494L3.50777 1.02869C2.82368 0.344604 1.71277 0.344604 1.02869 1.02869C0.344604 1.71277 0.344604 2.82368 1.02869 3.50777L5.52494 8.00402L1.02869 12.5003C0.344604 13.1844 0.344604 14.2953 1.02869 14.9794C1.37366 15.3243 1.81802 15.4939 2.26823 15.4939C2.71844 15.4939 3.16865 15.3243 3.50777 14.9794L8.00402 10.4831L12.5003 14.9794C12.8452 15.3243 13.2896 15.4939 13.7398 15.4939C14.19 15.4939 14.6402 15.3243 14.9794 14.9794C15.6634 14.2953 15.6634 13.1844 14.9794 12.5003L10.4831 8.00402L14.9794 3.50777C15.6634 2.82368 15.6634 1.71277 14.9794 1.02869Z'
      fill={color || '#98A1B2'}
    />
  </svg>
)

export default XIcon
