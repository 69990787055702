import { GET_ORDER } from '../actions/dataPopupToBookAction'

const initState = { serviceData: [] }

const dataPopupToBookReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_ORDER:
      return {
        ...state,
        serviceData: action.payload.order,
      }
    default:
      return state
  }
}

export default dataPopupToBookReducers
