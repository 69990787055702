import React from "react"
import { IconProps } from "./IconPropsType"

const CashierIcon = ({ size, color }: IconProps) => (
  <svg
    width={size || '18'}
    height={size || '16'}
    viewBox='0 0 18 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.3733 12.7316L16.4336 6.10305C16.332 5.31575 15.7225 4.73162 14.9606 4.60464V0.566543C14.9606 0.388765 14.8336 0.261781 14.6558 0.261781H10.9479C10.7701 0.261781 10.6431 0.388765 10.6431 0.566543V4.57924H7.34152V3.3094H7.77326C7.95104 3.3094 8.10342 3.15702 8.10342 2.97924V0.337971C8.10342 0.160193 7.95104 0.0078125 7.77326 0.0078125H3.10025C2.92247 0.0078125 2.77009 0.160193 2.77009 0.337971V2.97924C2.77009 3.15702 2.92247 3.3094 3.10025 3.3094H3.53199V4.57924H3.07485C2.18596 4.57924 1.42406 5.26496 1.32247 6.15384L0.585962 12.757C0.535169 12.8078 0.484375 12.884 0.484375 12.9602V15.3983C0.484375 15.7284 0.662153 16.0078 0.916121 16.0078H17.0939C17.3225 16.0078 17.5256 15.7284 17.5256 15.3983V12.9602C17.5002 12.8586 17.4495 12.7824 17.3733 12.7316ZM4.03993 1.27765H6.83358V2.03956H4.03993V1.27765ZM4.80184 4.32527V3.56337H6.07168V4.32527H4.80184ZM11.9129 1.53162H13.6907V7.88083H11.9129V1.53162ZM2.59231 6.28083C2.61771 6.02686 2.82088 5.84908 3.07485 5.84908H10.6431V9.15067H14.9606V5.92527C15.0876 6.00146 15.1637 6.12845 15.1891 6.28083L16.0526 12.4523H1.9066L2.59231 6.28083ZM16.2304 14.738H1.75422V13.9761H16.2304V14.738Z'
      fill={color || '#475467'}
    />
    <path
      d='M6.67547 8.38715C6.85325 8.38715 7.00563 8.23477 6.98024 8.0316L6.85325 6.88874C6.87865 6.73636 6.72627 6.60938 6.57389 6.60938H3.75484C3.60246 6.60938 3.45008 6.73636 3.45008 6.88874L3.32309 8.0316C3.2977 8.20938 3.45008 8.38715 3.62785 8.38715H6.67547Z'
      fill={color || '#475467'}
    />
    <path
      d='M3.92004 9.14844C3.64067 9.14844 3.4375 9.37701 3.4375 9.63098C3.4375 9.88495 3.66607 10.1135 3.92004 10.1135C4.17401 10.1135 4.40258 9.88495 4.40258 9.63098C4.40258 9.37701 4.1994 9.14844 3.92004 9.14844Z'
      fill={color || '#475467'}
    />
    <path
      d='M5.20129 9.14844C4.92192 9.14844 4.71875 9.37701 4.71875 9.63098C4.71875 9.88495 4.94732 10.1135 5.20129 10.1135C5.48065 10.1135 5.68383 9.88495 5.68383 9.63098C5.68383 9.37701 5.48065 9.14844 5.20129 9.14844Z'
      fill={color || '#475467'}
    />
    <path
      d='M6.52941 9.14844C6.25005 9.14844 6.04688 9.37701 6.04688 9.63098C6.04688 9.88495 6.27545 10.1135 6.52941 10.1135C6.80878 10.1135 7.01195 9.88495 7.01195 9.63098C7.01195 9.37701 6.80878 9.14844 6.52941 9.14844Z'
      fill={color || '#475467'}
    />
    <path
      d='M3.92004 11.4338C4.18654 11.4338 4.40258 11.2178 4.40258 10.9513C4.40258 10.6848 4.18654 10.4688 3.92004 10.4688C3.65354 10.4688 3.4375 10.6848 3.4375 10.9513C3.4375 11.2178 3.65354 11.4338 3.92004 11.4338Z'
      fill={color || '#475467'}
    />
    <path
      d='M5.20129 11.4338C5.46779 11.4338 5.68383 11.2178 5.68383 10.9513C5.68383 10.6848 5.46779 10.4688 5.20129 10.4688C4.93479 10.4688 4.71875 10.6848 4.71875 10.9513C4.71875 11.2178 4.93479 11.4338 5.20129 11.4338Z'
      fill={color || '#475467'}
    />
    <path
      d='M6.52941 10.4453C6.25005 10.4453 6.04688 10.6739 6.04688 10.9279C6.04688 11.1818 6.27545 11.4104 6.52941 11.4104C6.80878 11.4104 7.01195 11.1818 7.01195 10.9279C7.01195 10.6739 6.80878 10.4453 6.52941 10.4453Z'
      fill={color || '#475467'}
    />
  </svg>
)

export default CashierIcon
