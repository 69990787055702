import { useState } from 'react'
import { useDispatch } from 'react-redux'

//@ts-ignore
import api from '../../api'
//@ts-ignore
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import { clearSearch } from '../../redux/actions/pageAction'
import { BookingHistory, Order } from '../../redux/models/BookingHistory'
import { Customer } from '../../redux/models/Customer'
import { TimePrice } from '../../redux/models/Service'
import utils from '../../utils'

interface useCustomerBookHistoryProps {
  customerBookingHistories: BookingHistory[]
  customer: Customer
}

const useCustomerBookHistory = ({
  customerBookingHistories,
  customer,
}: useCustomerBookHistoryProps) => {
  const dispatch = useDispatch()

  const [bookOpenDetail, setBookOpenDetail] = useState(-1)

  const onBookOpenClick = (index: number) => {
    setBookOpenDetail(index)
  }

  const onBookingClick = async (orders: Order[]) => {
    const { data: bookingCode } = await api.createBookingCode()

    let startTime = utils.convertTime({
      hour: new Date().getHours() + '',
      minute: new Date().getMinutes() + '',
    })

    let services = []
    let serviceTime = 0

    for (let i = 0; i < orders.length; i++) {
      const { serviceId, timePriceId, time } = orders[i]
      let service = await callApiServiceById(serviceId)

      const findActiveTimePrice = service.timePrices.findIndex(
        (t: TimePrice) => t.timePriceId === timePriceId
      )

      // services.push({
      //   ...service,
      //   activeTime: findActiveTimePrice,
      //   timePriceId: service.timePrices[findActiveTimePrice].timePriceId,
      //   price: service.timePrices[findActiveTimePrice].price,
      //   time: service.timePrices[findActiveTimePrice].time,
      //   staffTypeId: orders[i].staffTypeId,
      //   staffId: orders[i].staffId,
      //   staffName: orders[i].staffName,
      //   staffImage: orders[i].staffImage,
      //   queueFree: orders[i].queueFree,
      //   requestStaff: orders[i].requestStaff,
      // })
      serviceTime += time
    }

    dispatch(
      getOrderBooking({
        _id: -1,
        bookingCode: bookingCode,
        customerId: customer._id,
        name: customer.name,
        email: customer.email,
        countryCode: customer.countryCode,
        phoneNumber: customer.phoneNumber,
        specialRequest: customer.specialRequest,
        date: new Date(),
        time: {
          hour: new Date().getHours(),
          minute: new Date().getMinutes(),
        },
        startTime: {
          hour: new Date().getHours(),
          minute: new Date().getMinutes(),
        },
        endTime: {
          hour: Math.floor(Math.abs((startTime + 60) / 60)),
          minute: (startTime + 60) % 60,
        },
        orders: [
          {
            customer: 1,
            customerId: customer._id,
            name: customer.name,
            phoneNumber: customer.phoneNumber,
            gender:
              typeof customer.gender === 'number'
                ? customer.gender
                : customer.gender?.value || -1,
            age:
              typeof customer.age === 'number'
                ? { value: customer.age, label: '' }
                : customer.age,
            nationality: customer.nationality,
            specialRequest: customer.specialRequest,
            // services,
          },
        ],
        bookingType: {
          bookingTypeId: 2,
          bookingType: 'Booking',
        },
      })
    )

    dispatch(clearSearch())
  }

  const callApiServiceById = async (serviceId: number) => {
    try {
      const { data } = await api.getServiceById(serviceId)
      if (data.length) {
        return data[0]
      }
    } catch (err) {
      console.log(err)
    }
  }

  return { bookOpenDetail, onBookOpenClick, onBookingClick }
}

export default useCustomerBookHistory
