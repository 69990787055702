import config from '../../config'

export const TransactionTypes = {
  Service: 1,
  Product: 2,
  ServiceAndProduct: 3,
  Package: 4,
}

export const PaymentStatuses = {
  Unpaid: -1,
  Paid: 1,
}

export const Statuses = {
  Reserved: 1,
  Confirmed: 2,
  InService: 3,
  Finished: 4,
  Canceled: 6,
}

export const PaymentChannelTypes = {
  Cash: 1,
  Credit: 2,
  Transfer: 3,
  EWallet: 4,
  Agency: 5,
  Package: 6,
}

export const BookingTypes = {
  WalkIn: 1,
  Booking: 2,
  Agency: 3,
}

export const getStatusColor = (statusId: number) => {
  return config.STATUS_COLOR[statusId - 1]
}

export const getPaymentChannelTypeName = (payChannelTypeId: number) => {
  return config.PAY_TYPES[payChannelTypeId - 1]
}

export const getStatusKey = (statusId: number) => {
  return config.STATUS[statusId - 1]
}

export const getTransactionType = (transactionType: number) => {
  return config.TRANSACTION_TYPE[transactionType - 1]
}

export interface BookingHistory {
  _id: number
  customerId: number
  name: string
  firstName: string
  lastName: string
  email: string
  countryCode: string
  phoneNumber: string
  specialRequest: string
  customers: CustomerBookingHistory[]
  customerCount: number
  orderCount: number
  date: Date
  time: { hour: string; minute: string }
  startTime: { hour: string; minute: string }
  note: string
  products: {
    productId: number
    product: string
    productImage: string
    price: number
    quantity: number
  }[]
  productCount: number
  packages: {
    packageId: number
    name: string
    price: number
    quantity: number
  }[]
  packageCount: number
  statusId: number
  transactionType: number
  bookingType: {
    bookingTypeId: number
    bookingType: string
  }
  bookingChannel: {
    bookingChannelId: number
    bookingChannel: string
    code: string
    commissionPercentage: number
  }
  payStatus: number
  payDate?: Date
  payChannels: {
    payChannelTypeId: number
    payChannelId: Number
    payChannel: string
    note: string
    quantity: number
    chargePercentage: number
    price: number
  }[]
  promotions: { promotionId: number }[]
  discount: number
  serviceDiscount: number
  packageDiscount: number
  totalDiscount: number
  total: number
  tipBox: number
  totalTip: number
  payChannelTypeTip: number
  charge: number
  commission: number
  bookingCode: string
  payslip: string[]
  billCode: string
  receiptNumber: string
  storeId: number
  remark: string
  instant: boolean
  createBy?: { userId: number; userName: string }
  updateBy?: { userId: number; userName: string }
  instantId: number
  createdAt?: Date
  updatedAt?: Date
}

export interface Order {
  serviceMasterId: string
  serviceId: number
  service: string
  image: string
  time: number
  price: number
  cost: number
  timePriceId: number
  serviceCustomer: number
  choiceId: number[]
  choicePrice: number[]
  statusId: number
  serviceDiscount: number
  primaryCustomerIndex: {
    customerIndex: number
    serviceIndex: number
  }
  serviceRelatedCustomers: number[]
  staffs: StaffBookingHistory[]
  rooms: RoomBookingHistory[]
}

export interface CustomerBookingHistory {
  customerId: number
  name: string
  firstName: string
  lastName: string
  email: string
  countryCode: string
  phoneNumber: string
  specialRequest: string
  age: number
  gender: number
  nationalityId: number
  orders: Order[]
  orderCount: number
}

export interface StaffBookingHistory {
  staffTypeId: number
  staffId: number
  staffName: string
  staffImage: string
  tip: number
  queueFree: boolean
  requestStaff: boolean
  duration: number
}

export interface RoomBookingHistory {
  order: number
  roomTypeId: number
  roomTypeName: string
  roomTypeCode: string
  roomId: number
  roomName: string
  roomCode: string
  private: number
  duration: number
  detail: string
}

export interface ProductBookingHistory {
  productId: number
  product: string
  productImage: string
  price: number
  quantity: number
}

export const getInitialBookingHistory = (): BookingHistory => ({
  _id: -1,
  customerId: -1,
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  countryCode: '+66',
  phoneNumber: '',
  specialRequest: '',
  customers: [
    {
      customerId: -1,
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      countryCode: '+66',
      phoneNumber: '',
      specialRequest: '',
      age: -1,
      gender: -1,
      nationalityId: -1,
      orders: [],
      orderCount: 0,
    },
  ],
  customerCount: 1,
  orderCount: 0,
  date: new Date(),
  time: {
    hour: new Date().getHours() + '',
    minute: new Date().getMinutes() + '',
  },
  startTime: {
    hour: new Date().getHours() + '',
    minute: new Date().getMinutes() + '',
  },
  note: '',
  products: [],
  productCount: 0,
  packages: [],
  packageCount: 0,
  statusId: 1,
  transactionType: 1,
  bookingType: {
    bookingTypeId: -1,
    bookingType: '',
  },
  bookingChannel: {
    bookingChannelId: -1,
    bookingChannel: '',
    code: '',
    commissionPercentage: 0,
  },
  payStatus: -1,
  payDate: new Date(),
  payChannels: [],
  promotions: [],
  discount: 0,
  serviceDiscount: 0,
  packageDiscount: 0,
  totalDiscount: 0,
  total: 0,
  tipBox: 0,
  totalTip: 0,
  payChannelTypeTip: -1,
  charge: 0,
  commission: 0,
  bookingCode: '',
  payslip: [],
  billCode: '',
  receiptNumber: '',
  storeId: -1,
  remark: '',
  instant: false,
  createBy: { userId: -1, userName: '' },
  updateBy: { userId: -1, userName: '' },
  instantId: -1,
  createdAt: new Date(),
  updatedAt: new Date(),
})
