import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from '@/components/atoms/Button'
import DropdownButton from '@/components/atoms/DropdownButton'
import { Row } from '@/components/atoms/Flex'
import { DocumentIcon } from '@/components/atoms/icons/DocumentIcon'
import { PersonIcon } from '@/components/atoms/icons/PeopleIcon'
import { XCircleIcon } from '@/components/atoms/icons/XIcon'
import { StyledDropdownButtonContainer } from '@/components/atoms/select'
import { Separator } from '@/components/atoms/Separator'
import SchedulerItem from '@/components/organisms/scheduler/SchedulerItem'

import { ITableItemData } from '../useTable'
import { BookingHistory } from '@/redux/models/BookingHistory'
// @ts-ignore
import api from '@/api'
import Sheet from '@/components/molecules/SheetDrawer'
import { ModalBookingHistoryInfo } from '@/components/pages/searchHistory/ModalInfo'

export interface UnassignedBoxProps
  extends React.HTMLAttributes<HTMLDivElement> {
  data?: ITableItemData[]
}

export const StyledUnassignedBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 0px;
  gap: 2px;
  position: relative;
  height: 54px;
`

export interface UnassignedBoxItemProps {
  data: ITableItemData
}

export const StyledUnassignedBoxItem = styled.div`
  background-color: #f8f9fb;
  color: var(--primary);
  padding: 0px 6px;
  font-size: 10px;
  border-radius: var(--radius-xs);
  width: 100%;
  display: flex;
  justify-content: space-between;
  aign-items: center;
`

const StyledDropdownButton = styled(DropdownButton)`
  width: 100%;
  ${StyledDropdownButtonContainer} {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledDropdownButtonTrigger = styled(Button)`
  padding: 0px 6px;
  border-radius: var(--radius-xs);
  width: 100%;
  font-size: 12px;
  height: 18px;
`

const StyledSchedulerItem = styled(SchedulerItem)`
  box-shadow: none;
  border: none;
  width: 250px;
`

export const UnassignedBoxItem = (props: UnassignedBoxItemProps) => {
  const { data } = props
  const { booking, orders } = data
  const { t } = useTranslation()
  const [bookingHistory, setBookingHistory] =
    React.useState<BookingHistory | null>(null)
  const timeText = `${data?.booking.startTime?.hour
    ?.toString()
    ?.padStart(2, '0')}:${data?.booking.startTime?.minute
    ?.toString()
    ?.padStart(2, '0')} - ${data?.booking.endTime?.hour
    ?.toString()
    ?.padStart(2, '0')}:${data?.booking.endTime?.minute
    ?.toString()
    ?.padStart(2, '0')}`
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)

  const handleItemClick = async (e: React.MouseEvent) => {
    e.stopPropagation()
    const { data: b } = await api.getBookingHistoryById(data.booking?._id)
    setBookingHistory(b?.[0])
    setIsInfoOpen(true)
  }

  const staffCount = data.orders.serviceOrders.reduce((acc, service) => {
    // TODO: Not sure if this is customer or staff
    return acc + service.serviceCustomer
  }, 0)

  const startDateTime = dayjs(booking.date)
    .set('hour', +booking?.startTime?.hour)
    .set('minute', +booking?.startTime?.minute)
  const endDateTime = startDateTime.add(orders.serviceOrders[0].time, 'minute')
  return (
    <>
      <StyledDropdownButton>
        <StyledDropdownButton.Trigger>
          <StyledUnassignedBoxItem>
            <Row align='center'>{timeText}</Row>
            <Row align='center' gap='6px'>
              {/* TODO: Want some staff per service */}
              <PersonIcon size={12} />
              {staffCount}
            </Row>
          </StyledUnassignedBoxItem>
        </StyledDropdownButton.Trigger>
        <StyledDropdownButton.Content>
          <StyledSchedulerItem
            display
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
            booking={{
              id: booking._id,
              title:
                orders.serviceOrders?.length > 1
                  ? `${orders.serviceOrders.length} ${t('service')}`
                  : orders.serviceOrders[0].serviceName,
              start: startDateTime.toDate(),
              end: endDateTime.toDate(),
              customer: booking.customers
                ?.map(customer => customer.name)
                .join(', '),
              phoneNumber: booking.customers
                ?.map(customer => customer.phoneNumber)
                .join(', '),
              status: booking?.statusId,
              payStatus: booking?.payStatus,
              // TODO: Need to get room name
              room:
                orders.serviceOrders
                  ?.map(service =>
                    service.rooms
                      .map(
                        room =>
                          room?.roomTypeName &&
                          `${room.roomTypeName} (${room?.roomName})`
                      )
                      .join(', ')
                  )
                  .join(', ') || ' ',
            }}
          />
        </StyledDropdownButton.Content>
      </StyledDropdownButton>
    </>
  )
}

const UnassignedBox = (props: UnassignedBoxProps) => {
  const { data, ...rest } = props
  const { t } = useTranslation()
  if (!data?.length) return null
  return (
    <StyledUnassignedBoxContainer {...rest}>
      {data?.map((item, index) => {
        if (index > 1) return null
        return (
          <>
            <UnassignedBoxItem key={index} data={item} />
          </>
        )
      })}
      {data?.length > 2 && (
        <StyledDropdownButton>
          <StyledDropdownButton.Trigger>
            <StyledDropdownButtonTrigger>More</StyledDropdownButtonTrigger>
          </StyledDropdownButton.Trigger>
          <StyledDropdownButton.Content>
            {data?.map((item, index) => {
              if (index < 2) return null
              const { booking, orders } = item
              const startDateTime = dayjs(booking.date)
                .set('hour', +booking?.startTime?.hour)
                .set('minute', +booking?.startTime?.minute)
              const endDateTime = startDateTime.add(
                orders.serviceOrders[0].time,
                'minute'
              )
              // console.log('booking', orders.serviceOrders)
              return (
                <>
                  <StyledSchedulerItem
                    display
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    booking={{
                      id: booking._id,
                      title:
                        orders.serviceOrders?.length > 1
                          ? `${orders.serviceOrders.length} ${t('service')}`
                          : orders.serviceOrders[0].serviceName,
                      start: startDateTime.toDate(),
                      end: endDateTime.toDate(),
                      customer: booking.customers
                        ?.map(customer => customer.name)
                        .join(', '),
                      phoneNumber: booking.customers
                        ?.map(customer => customer.phoneNumber)
                        .join(', '),
                      status: booking?.statusId,
                      payStatus: booking?.payStatus,
                      // TODO: Need to get room name
                      room:
                        orders.serviceOrders
                          ?.map(service =>
                            service.rooms
                              .map(
                                room =>
                                  room?.roomTypeName &&
                                  `${room.roomTypeName} (${room?.roomName})`
                              )
                              .join(', ')
                          )
                          .join(', ') || ' ',
                    }}
                  />
                  {index < data.length - 1 && <Separator />}
                </>
              )
            })}
          </StyledDropdownButton.Content>
        </StyledDropdownButton>
      )}
    </StyledUnassignedBoxContainer>
  )
}

export default UnassignedBox
