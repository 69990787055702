import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import StaffQueue from '@/redux/models/StaffQueue'

import {
  RoomOrderState,
  RoomState,
  RoomTypeState,
  StaffOrderState,
} from './types'

//@ts-ignore
import api from '../../api'
import { BookingHistory } from '../../redux/models/BookingHistory'
import Staff from '../../redux/models/Staff'
import { HOME_URL } from '../../routes'
import utils from '../../utils'

const useBook = () => {
  const history = useHistory()

  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { date, time, bookingCode } = bookingOrderState

  const [storeOpenCloseTime, setStoreOpenCloseTime] = useState({
    openTime: { hour: '', minute: '' },
    closeTime: { hour: '', minute: '' },
  })
  const [staffOrders, setStaffOrders] = useState<StaffOrderState[]>([])
  const [roomOrders, setRoomOrders] = useState<RoomOrderState[]>([])

  const [bookingHistories, setBookingHistories] = useState<BookingHistory[]>([])

  useEffect(() => {
    if (!bookingCode) {
      history.replace(HOME_URL)
    }
  }, [bookingCode])

  useEffect(() => {
    callApiStoreDateTime(date)
    // callApiStaffOrders(date)
    callApiStaffQueueAndRoomType(date)
  }, [date, time])

  const callApiStoreDateTime = async (date: Date) => {
    try {
      const { data } = await api.getStoreDateTimeByDateRang(
        dayjs(date).get('day') === 0 ? 6 : dayjs(date).get('day') - 1,
        dayjs(date).subtract(1, 'day').toDate().getDay() === 0
          ? 6
          : dayjs(date).subtract(1, 'day').toDate().getDay() - 1
      )

      // setCurrentDate(data.openDateTime)

      // const { data: today } = await api.getStoreDateTimeByDate(
      //   dayjs(date).get('day') === 0 ? 6 : dayjs(date).get('day') - 1,
      //   dayjs(date).toDate()
      // )
      if (data.length === 2) {
        let storeOpenCloseTime = {
          openTime: {
            hour: data[0].openTime.hour,
            minute: data[0].openTime.minute,
          },
          closeTime: {
            hour:
              +data[0].closeTime.hour < +data[0].openTime.hour
                ? data[0].closeTime.hour
                : '24',
            minute: data[0].closeTime.minute,
          },
        }
        setStoreOpenCloseTime(storeOpenCloseTime)
      }

      // const { data: yesterday } = await api.getStoreDateTimeByDate(
      //   dayjs(date).subtract(1, 'day').toDate().getDay() === 0
      //     ? 6
      //     : dayjs(date).subtract(1, 'day').toDate().getDay() - 1,
      //   dayjs(date).subtract(1, 'day').toDate()
      // )

      // if (+yesterday[0].closeTime.hour < +yesterday[0].openTime.hour) {
      // }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiBookingHistories = async (
    date: Date,
    staffQueues: Staff[],
    roomTypes: RoomTypeState[]
  ) => {
    try {
      let { data: bookingHistories } = await api.getBookingHistoryByStaffOrders(
        new Date(date)
      )
      setBookingHistories(bookingHistories)
      await createStaffAndRoomOrders(staffQueues, roomTypes, bookingHistories)
    } catch (err) {
      console.log(err)
    }
  }

  const createStaffAndRoomOrders = async (
    staffQueues: Staff[],
    roomTypes: RoomTypeState[],
    bookingHistories: BookingHistory[]
  ) => {
    let staffOrders: StaffOrderState[] = []
    let roomOrders: RoomOrderState[] = []

    for (const bookingHistory of bookingHistories) {
      const { customers, time, startTime, statusId, bookingCode, _id } =
        bookingHistory

      let bookStartTime = time
      if (statusId === 3 || statusId === 4) {
        bookStartTime = startTime
      }
      let bookEndTime = bookStartTime
      let roomStartTime = bookStartTime

      for (const customer of customers) {
        let bookEndTimeNumber = utils.convertTime(bookEndTime)
        let roomEndTime = utils.convertTime(roomStartTime)

        const { orders } = customer
        for (let i = 0; i < orders.length; i++) {
          const { staffs, time: serviceTime, rooms } = orders[i]
          for (const room of rooms) {
            const findRoomTypeIndex = roomOrders.findIndex(
              r => r.roomTypeId === room.roomTypeId
            )

            // console.log(room.roomId, roomEndTime, +room.duration)

            if (findRoomTypeIndex === -1) {
              roomOrders.push({
                roomTypeId: room.roomTypeId,
                roomTypeName: room.roomTypeName,
                roomTypeCode: room.roomTypeCode,
                private: room.private,
                serviceSpecificRoomType: [],
                rooms: [
                  {
                    roomId: room.roomId,
                    roomName: room.roomName,
                    roomCode: room.roomCode,
                    serviceSpecificRoom: [],
                    orders: [
                      {
                        bookingCode,
                        _id,
                        startTime: roomStartTime,
                        endTime: utils.convertTimeToHourMinute(
                          roomEndTime + room.duration
                        ),
                        serviceTime: serviceTime,
                        duration: room.duration,
                      },
                    ],
                    free: true,
                  },
                ],
              })
            } else {
              const findRoomIndex = roomOrders[
                findRoomTypeIndex
              ].rooms.findIndex(r => r.roomId === room.roomId)

              if (findRoomIndex === -1) {
                roomOrders[findRoomTypeIndex].rooms.push({
                  roomId: room.roomId,
                  roomName: room.roomName,
                  roomCode: room.roomCode,
                  serviceSpecificRoom: [],
                  orders: [
                    {
                      bookingCode,
                      _id,
                      startTime: roomStartTime,
                      endTime: utils.convertTimeToHourMinute(
                        roomEndTime + room.duration
                      ),
                      serviceTime: serviceTime,
                      duration: room.duration,
                    },
                  ],
                  free: true,
                })
              } else {
                roomOrders[findRoomTypeIndex].rooms[findRoomIndex].orders.push({
                  bookingCode,
                  _id,
                  startTime: bookStartTime,
                  endTime: utils.convertTimeToHourMinute(
                    roomEndTime + room.duration
                  ),
                  serviceTime: serviceTime,
                  duration: room.duration,
                })
              }
            }
            roomStartTime = utils.convertTimeToHourMinute(
              utils.convertTime(roomStartTime) + room.duration
            )
            roomEndTime = utils.convertTime(roomStartTime)
          }

          for (const staff of staffs) {
            const { staffId, staffTypeId, staffName, staffImage } = staff

            const findStaffIndex = staffOrders.findIndex(
              s => s.staffId === staffId
            )
            if (findStaffIndex === -1) {
              staffOrders.push({
                staffId,
                staffTypeId,
                staffName,
                staffImage,
                staffWorkingStatus: 1,
                serviceSpecificStaff: [],
                gender: -1,
                orders: [
                  {
                    bookingCode,
                    _id,
                    startTime: bookStartTime,
                    endTime: utils.convertTimeToHourMinute(
                      bookEndTimeNumber + serviceTime
                    ),
                    serviceTime: serviceTime,
                  },
                ],
                free: true,
              })
            } else {
              staffOrders[findStaffIndex].orders.push({
                bookingCode,
                _id,
                startTime: bookStartTime,
                endTime: utils.convertTimeToHourMinute(
                  bookEndTimeNumber + serviceTime
                ),
                serviceTime: serviceTime,
              })
            }
          }
          bookEndTimeNumber += serviceTime
          bookStartTime = utils.convertTimeToHourMinute(bookEndTimeNumber)
        }
      }
    }

    const newStaffs = staffQueues.reduce((acc: StaffOrderState[], info) => {
      const { _id, workingStatus, serviceSpecificStaff, gender } = info

      const findStaff = staffOrders.find(s => s.staffId === _id)

      if (findStaff) {
        acc.push({
          ...findStaff,
          staffWorkingStatus: workingStatus,
          serviceSpecificStaff,
          gender,
        })
      } else {
        acc.push({
          staffId: _id,
          staffTypeId: info.staffTypeId,
          staffName: info.name,
          staffImage: info.image,
          staffWorkingStatus: workingStatus,
          gender,
          orders: [],
          serviceSpecificStaff,
          free: true,
        })
      }

      return acc
    }, [])
    setStaffOrders(newStaffs)

    const newRooms = roomTypes.reduce((acc: RoomOrderState[], info) => {
      const { _id, rooms, serviceSpecificRoomTypes } = info

      const findRoomTypeIndex = roomOrders.findIndex(
        rt => rt.roomTypeId === _id
      )

      if (findRoomTypeIndex !== -1) {
        const mergedRooms = utils.deepClone(roomOrders[findRoomTypeIndex].rooms)
        rooms.forEach(room => {
          const found = mergedRooms.find(m => m.roomId === room._id)
          if (!found) {
            mergedRooms.push({
              roomId: room._id,
              roomName: room.roomName,
              roomCode: room.roomCode,
              serviceSpecificRoom: room.serviceSpecificRooms,
              orders: [],
              free: true,
            })
          }
        })
        acc.push({
          ...roomOrders[findRoomTypeIndex],
          serviceSpecificRoomType: serviceSpecificRoomTypes,
          rooms: mergedRooms,
        })
      } else {
        acc.push({
          roomTypeId: _id,
          roomTypeName: info.roomTypeName,
          roomTypeCode: info.roomTypeCode,
          private: info.private,
          serviceSpecificRoomType: serviceSpecificRoomTypes,
          rooms: rooms.reduce((acc: RoomState[], info) => {
            acc.push({
              roomId: info._id,
              roomName: info.roomName,
              roomCode: info.roomCode,
              serviceSpecificRoom: info.serviceSpecificRooms,
              orders: [],
              free: true,
            })
            return acc
          }, []),
        })
      }

      return acc
    }, [])
    // console.log({ newRooms })
    setRoomOrders(newRooms)
  }

  const callApiStaffQueueAndRoomType = async (date: Date) => {
    try {
      let staffCome: Staff[] = []
      let staffNotCome: Staff[] = []
      const { data: staffsQueue } = await api.getStaffDataByQueue(
        dayjs(date).toDate()
      )

      const { data } = await api.getStoreDateTimeByDateV2(
        dayjs(new Date()).startOf('date').add(+time.hour, 'hour').toDate(),
        { withinDay: true }
      )

      const day =
        dayjs(date).startOf('date').add(+time.hour, 'hour').get('day') === 0
          ? 6
          : dayjs(date).startOf('date').add(+time.hour, 'hour').get('day') - 1

      if (staffsQueue.length) {
        const { queue }: StaffQueue = staffsQueue[0]

        if (!dayjs(data.openDateTime).isSame(staffsQueue[0].date, 'date')) {
          staffCome = queue
            .filter(q => q.staff !== undefined && q.staff.workingStatus === 1)
            .map(s => ({ ...s.staff! }))
        } else {
          staffCome = queue
            .filter(q => q.staff !== undefined && q.staff.workingStatus === 1)
            .map(s => ({ ...s.staff! }))

          staffNotCome = queue
            .filter(q => q.staff !== undefined && q.staff.workingStatus === -1)
            .map(s => ({ ...s.staff! }))
          staffCome = staffCome.concat(staffNotCome)
        }
      } else {
        const { data: staffs } = await api.getStaffByStatus(1)
        staffCome = staffs.filter((staff: Staff) =>
          staff.workingDay.some(
            workingDay =>
              workingDay.day === day && workingDay.workingStatus !== -1
          )
        )
      }

      const { data: roomTypes } = await api.getRoomTypeAndRoom()
      callApiBookingHistories(date, staffCome, roomTypes)
    } catch (err) {
      console.log(err)
    }
  }

  return {
    bookingOrderState,
    storeOpenCloseTime,
    staffOrders,
    roomOrders,
    bookingHistories,
  }
}

export default useBook
