import { GET_BUTTON_SETTING_STAFF } from '../actions/buttonSettingStaffAction'

const initState = {
  btn: 'พนักงาน',
}

const buttonMenuSettingStaffReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_BUTTON_SETTING_STAFF:
      return {
        ...state,
        btn: action.payload.data,
      }
    default:
      return state
  }
}

export default buttonMenuSettingStaffReducers
