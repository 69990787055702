import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { toast } from '@/components/atoms/Toast'
import { useAlert } from '@/hook/useAlert'

//@ts-ignore
import api from '../../../api'
import auth from '../../../auth'
import { clearOrderBooking } from '../../../redux/actions/bookingHistoryAction'
import {
  BookingHistory,
  CustomerBookingHistory,
  Order,
  ProductBookingHistory,
} from '../../../redux/models/BookingHistory'
import { BookOrder } from '../../../redux/models/BookOrder'

interface useCardSummaryProps {
  bookingOrderState: BookOrder
}

interface serviceState {
  serviceId: number
  serviceName: string
  serviceImage: string
  serviceCustomer: number
  time: number
  price: number
  count: number
}

interface productState {
  productId: number
  productImage: string
  price: number
  count: number
}

interface customerUpdateState {
  _id?: number
  name?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  email?: string
  address?: string
  taxIDNumber?: string
  gender?: number
  age?: number
  nationalityId?: number
  specialRequest?: string
}

const useCardSummary = ({ bookingOrderState }: useCardSummaryProps) => {
  const dispatch = useDispatch()

  const alert = useAlert()

  const [services, setServices] = useState<serviceState[]>([])
  const [products, setProducts] = useState<productState[]>([])
  const [packages, setPackages] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { customers, date, time, products: orderProducts } = bookingOrderState

  useEffect(() => {
    const services = customers.reduce((acc: serviceState[], info) => {
      const { services } = info
      for (const service of services) {
        if (service.serviceId !== -1) {
          const findServiceIndex = acc.findIndex(
            s => s.serviceId === service.serviceId && s.time === service.time
          )

          if (findServiceIndex === -1) {
            acc.push({
              serviceId: service.serviceId,
              serviceName: service.serviceName,
              serviceImage: service.serviceImage,
              serviceCustomer: service.serviceCustomer,
              time: service.time,
              price: service.price,
              count: 1,
            })
          } else {
            if (service.price !== 0 && service.serviceCustomer > 1)
              acc[findServiceIndex].count += 1
          }
        }
      }

      return acc
    }, [])

    setServices(services)
  }, [bookingOrderState])

  const onSaveClick = async () => {
    try {
      let bookingHistory: any = {
        ...bookingOrderState,
        date: dayjs(date)
          .startOf('date')
          .add(+time.hour, 'hour')
          .add(+time.minute, 'minute')
          .toDate(),
        time: time,
      }

      let total: number = 0
      let newArrCustomer: CustomerBookingHistory[] = []

      for (let i = 0; i < customers.length; i++) {
        const {
          services,
          customerId,
          name,
          firstName,
          lastName,
          phoneNumber,
          specialRequest,
          age,
          gender,
          nationality,
        } = customers[i]

        let orders: Order[] = []
        for (const service of services) {
          const { staffs, rooms } = service
          if (service.serviceId !== -1)
            orders.push({
              serviceMasterId: service.serviceMasterId,
              serviceId: service.serviceId,
              service: service.serviceName,
              image: service.serviceImage,
              time: service.time,
              price: service.price,
              cost: 0,
              timePriceId:
                service.timePrices[service.activeTimePriceIndex].timePriceId,
              serviceCustomer: service.serviceCustomer,
              primaryCustomerIndex: service.primaryCustomerIndex,
              serviceRelatedCustomers: service.serviceRelatedCustomers,
              staffs: staffs,
              rooms: rooms,
              serviceDiscount: 0,
              choiceId: [],
              choicePrice: [],
              statusId: 1,
            })
          total += service.price || 0
        }

        newArrCustomer.push({
          customerId,
          name,
          firstName,
          lastName,
          email: '',
          countryCode: '+66',
          phoneNumber,
          specialRequest,
          age: age.value,
          gender: gender,
          nationalityId: nationality.value,
          orderCount: services.length,
          orders,
        })
      }

      const newArrProduct: ProductBookingHistory[] = []

      for (const orderProduct of orderProducts) {
        newArrProduct.push({
          productId: orderProduct._id,
          product: orderProduct.name,
          productImage: orderProduct.image,
          price: orderProduct.price,
          quantity: orderProduct.quantity,
        })
        total += (orderProduct.price || 0) * (orderProduct.quantity || 0)
      }
      bookingHistory = {
        ...bookingHistory,
        customerId: newArrCustomer[0].customerId,
        name: newArrCustomer[0].name,
        firstName: newArrCustomer[0].firstName,
        lastName: newArrCustomer[0].lastName,
        email: newArrCustomer[0].email,
        countryCode: newArrCustomer[0].countryCode || '+66',
        phoneNumber: newArrCustomer[0].phoneNumber,
        specialRequest: newArrCustomer[0].specialRequest,
        total,
        customers: newArrCustomer,
        products: newArrProduct,
        statusId: 1,
      }

      if (
        bookingHistory.packageCount === 0 &&
        bookingHistory.serviceCount === 0 &&
        bookingHistory.productCount === 0
      ) {
        await toast.error('กรุณาทำรายการ')
        return false
      }

      if (bookingOrderState._id === -1) {
        delete bookingHistory._id
        await callApiCrateBookingHistory(bookingHistory)
      } else {
      }

      setIsLoading(true)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCrateBookingHistory = async (create: BookingHistory) => {
    try {
      const { success } = await api.createBookingHistoryV2(create)

      setIsLoading(true)
      if (success) {
        setIsLoading(false)
        dispatch(clearOrderBooking())
      }
    } catch (err) {
      console.log(err)
    }
  }

  return { services, products, onSaveClick }
}

export default useCardSummary
