import React from 'react'

const NotiIcon = ({ size = '', color = '' }) => (
  <svg
    width={size || '22'}
    height={size || '24'}
    viewBox='0 0 22 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3346_2791)'>
      <path
        d='M20.3967 12.3636C19.405 11.2397 18.843 9.81818 18.876 8.33058C18.876 8.06612 18.876 7.7686 18.843 7.47108C18.5124 3.50413 15.2727 0.297521 11.2727 0.0330579C11.0744 0 10.876 0 10.6777 0C6.14876 0 2.5124 3.66942 2.5124 8.16529C2.5124 8.23141 2.5124 8.29752 2.5124 8.36364C2.54545 9.85124 1.95041 11.2397 0.991736 12.3636C0.330579 13.0579 0 13.9504 0 15.0083C0 19.0083 4.79339 20.1653 10.6777 20.1653C16.562 20.1653 21.3554 19.0083 21.3554 15.0083C21.3554 13.9504 21.0248 13.0579 20.3967 12.3636ZM17.4876 17.2562C15.9669 17.8512 13.686 18.1818 10.6777 18.1818C7.66942 18.1818 5.38843 17.8843 3.86777 17.2562C2.21488 16.595 1.98347 15.7355 1.98347 15.0083C1.98347 14.4463 2.1157 14.0496 2.44628 13.686C3.80165 12.1653 4.52893 10.2479 4.49587 8.33058C4.49587 8.26446 4.49587 8.23141 4.49587 8.16529C4.49587 4.76033 7.27273 1.98347 10.6777 1.98347C10.8099 1.98347 10.9752 1.98347 11.1074 1.98347C14.1488 2.18182 16.595 4.6281 16.8264 7.63636C16.8595 7.86777 16.8595 8.06612 16.8595 8.29752C16.8264 10.2479 17.5537 12.1653 18.9091 13.686C19.2397 14.0496 19.3719 14.4463 19.3719 15.0083C19.3719 15.7355 19.1405 16.562 17.4876 17.2562Z'
        fill={color || 'black'}
      />
      <path
        d='M15.1073 21.7851C13.818 21.9504 12.2974 22.0165 10.6775 22.0165C8.95853 22.0165 7.43787 21.9174 6.08249 21.7521C5.55357 21.686 5.0577 22.0827 4.99158 22.6116C4.92547 23.1405 5.32216 23.6364 5.85109 23.7025C7.27258 23.8678 8.89241 23.967 10.6775 23.967C12.3965 23.967 13.9833 23.8678 15.3718 23.7356C15.9007 23.6694 16.2974 23.1736 16.2313 22.6446C16.1651 22.1157 15.6693 21.719 15.1073 21.7851Z'
        fill={color || 'black'}
      />
    </g>
    <defs>
      <clipPath id='clip0_3346_2791'>
        <rect width='21.3554' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default NotiIcon
