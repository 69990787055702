import React from 'react'

const CloseEyeIcon = ({ size, color, onClick }) => (
  <svg
    width={size || '64'}
    height={size || '51'}
    onClick={onClick}
    viewBox='0 0 64 51'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='4.02539'
      y='48.3809'
      width='70.9709'
      height='3.53663'
      rx='1.76832'
      transform='rotate(-42.9757 4.02539 48.3809)'
      fill='url(#CloseEye1:18702)'
    />
    <path
      d='M63.1452 24.7053C63.1238 24.6503 63.1004 24.5962 63.075 24.543C60.2683 18.7137 55.9001 13.7829 50.4434 10.2833C44.8367 6.68751 38.3368 4.78711 31.6468 4.78711C24.9568 4.78711 18.4566 6.6878 12.8499 10.2839C7.41306 13.7706 3.05717 18.6786 0.249275 24.4798C-0.0654103 25.0826 -0.0888071 25.8114 0.217105 26.4469C3.02354 32.2771 7.39171 37.2088 12.849 40.709C18.456 44.3053 24.9562 46.206 31.6468 46.206C38.3371 46.206 44.8373 44.3053 50.444 40.7092C55.9013 37.2094 60.2692 32.2779 63.0759 26.4484C63.3385 25.9023 63.3631 25.2703 63.1452 24.7053ZM31.6468 41.8188C20.3628 41.8188 9.88199 35.4464 4.64932 25.4961C9.88199 15.5458 20.3628 9.1737 31.6468 9.1737C42.9304 9.1737 53.4116 15.5464 58.6443 25.4964C53.4113 35.4459 42.9301 41.8188 31.6468 41.8188Z'
      fill='url(#CloseEye2:18702)'
    />
    <path
      d='M31.6464 15.8691C26.3383 15.8691 22.0195 20.1879 22.0195 25.496C22.0195 30.8041 26.3383 35.1229 31.6464 35.1229C36.9545 35.1229 41.2733 30.8041 41.2733 25.496C41.2733 20.1879 36.9545 15.8691 31.6464 15.8691ZM31.6464 30.736C28.7572 30.736 26.4064 28.3852 26.4064 25.496C26.4064 22.6068 28.7572 20.256 31.6464 20.256C34.5356 20.256 36.8864 22.6068 36.8864 25.496C36.8864 28.3852 34.5356 30.736 31.6464 30.736Z'
      fill='url(#CloseEye3:18702)'
    />
    <defs>
      <linearGradient
        id='CloseEye1:18702'
        x1='-29.3061'
        y1='50.1492'
        x2='-28.9312'
        y2='57.2164'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color || '#57557F'} />
        <stop offset='1' stopColor={color || '#8E759F'} />
        <stop offset='1' stopColor={color || '#8E759F'} />
      </linearGradient>
      <linearGradient
        id='CloseEye2:18702'
        x1='-29.7253'
        y1='25.4966'
        x2='9.20459'
        y2='81.3746'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color || '#57557F'} />
        <stop offset='1' stopColor={color || '#8E759F'} />
        <stop offset='1' stopColor={color || '#8E759F'} />
      </linearGradient>
      <linearGradient
        id='CloseEye3:18702'
        x1='12.977'
        y1='25.496'
        x2='32.2307'
        y2='43.5811'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color || '#57557F'} />
        <stop offset='1' stopColor={color || '#8E759F'} />
        <stop offset='1' stopColor={color || '#8E759F'} />
      </linearGradient>
    </defs>
  </svg>
)

export default CloseEyeIcon
