import React from 'react'
import styled from 'styled-components'

interface BreadCrumbProps extends React.HTMLAttributes<HTMLDivElement> {
  paths: {
    label?: string | null
    href?: string | null
    disabled?: boolean
  }[]
}

export const StyledBreadCrumb = styled.div.attrs<BreadCrumbProps>(props => ({
  paths: props.paths,
}))<BreadCrumbProps>`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  font-weight: 500;
  gap: 3px;
`

export const StyledBreadCrumbLink = styled.a`
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const StyledDotSeparator = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgb(152, 161, 178);
`

export const BreadCrumb: React.FC<BreadCrumbProps> = props => {
  return (
    <StyledBreadCrumb {...props}>
      {props.paths.map((path, index) => (
        <>
          <StyledBreadCrumbLink
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              cursor: path.disabled ? 'not-allowed' : 'pointer',
              color: index === 0 ? 'rgb(152, 161, 178)' : '#333',
            }}
          >
            {path.label}
          </StyledBreadCrumbLink>
          {index < props.paths.length - 1 && <StyledDotSeparator />}
        </>
      ))}
    </StyledBreadCrumb>
  )
}
