import React from 'react'

import Popup from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddStaffType from '../settingStaff/CardAddStaffType'

const PopupAddStaffType = ({
  handleClose,
  staffTypes,
  staffs,
  currentDataEdit,
  currentIdEdit,
}) => {
  return (
    <Popup width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0'>
        <CardAddStaffType
          handleClose={handleClose}
          staffTypes={staffTypes}
          staffs={staffs}
          currentDataEdit={currentDataEdit}
          currentIdEdit={currentIdEdit}
        />
      </PopupInsideBox>
    </Popup>
  )
}

export default PopupAddStaffType
