import { createDynamicIcon } from './Icon'

export const ClockHistoryIcon = createDynamicIcon(`
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.38625 0.76425C6.25772 0.754761 6.12888 0.750007 6 0.75V0C6.1474 7.18707e-05 6.29475 0.00557561 6.44175 0.0165L6.38625 0.76425ZM7.88925 1.10175C7.64883 1.00898 7.40192 0.934026 7.1505 0.8775L7.31475 0.1455C7.602 0.21 7.88475 0.2955 8.15925 0.402L7.88925 1.10175ZM8.91675 1.63425C8.80952 1.56274 8.69969 1.49519 8.5875 1.43175L8.95725 0.77925C9.21368 0.924552 9.45909 1.08849 9.6915 1.26975L9.23025 1.8615C9.12857 1.78217 9.024 1.70662 8.91675 1.635V1.63425ZM10.2922 2.97675C10.1439 2.76598 9.9802 2.56644 9.8025 2.37975L10.3455 1.86225C10.548 2.076 10.7355 2.30475 10.9057 2.54475L10.2922 2.97675ZM10.8502 3.99075C10.801 3.87184 10.7475 3.75477 10.6897 3.63975L11.3595 3.30225C11.4919 3.56556 11.6048 3.83831 11.697 4.11825L10.9845 4.353C10.9442 4.23065 10.8994 4.10982 10.8502 3.99075ZM11.2477 5.871C11.2417 5.61326 11.2166 5.35631 11.1727 5.10225L11.9115 4.97475C11.9617 5.26425 11.991 5.55825 11.9985 5.85225L11.2485 5.871H11.2477ZM11.1495 7.0245C11.1742 6.897 11.1945 6.77025 11.2102 6.642L11.955 6.73425C11.919 7.02687 11.8613 7.31641 11.7825 7.6005L11.0595 7.40025C11.094 7.2765 11.124 7.15125 11.1495 7.0245ZM10.4355 8.80875C10.5735 8.59125 10.695 8.36325 10.8 8.12775L11.4855 8.4315C11.3655 8.7015 11.2267 8.961 11.0692 9.21L10.4355 8.80875ZM9.7125 9.7125C9.804 9.621 9.89175 9.5265 9.975 9.429L10.5435 9.91875C10.4472 10.0304 10.3469 10.1384 10.2427 10.2428L9.7125 9.7125Z" fill="black"/>
<path d="M6.00234 0.75C5.139 0.750062 4.289 0.963035 3.52763 1.37005C2.76625 1.77707 2.117 2.36558 1.63739 3.08344C1.15777 3.8013 0.862588 4.62636 0.777989 5.48555C0.693389 6.34473 0.821981 7.21152 1.15238 8.00914C1.48277 8.80676 2.00477 9.51059 2.67214 10.0583C3.33951 10.606 4.13165 10.9807 4.97839 11.1491C5.82514 11.3175 6.70036 11.2746 7.52654 11.024C8.35271 10.7734 9.10434 10.3229 9.71484 9.7125L10.2451 10.2427C9.54739 10.9408 8.68828 11.456 7.74387 11.7427C6.79946 12.0294 5.79892 12.0788 4.83089 11.8864C3.86286 11.694 2.95724 11.2657 2.19425 10.6397C1.43127 10.0136 0.834494 9.209 0.456794 8.29717C0.079093 7.38533 -0.0678674 6.39441 0.0289334 5.4122C0.125734 4.42999 0.463308 3.48683 1.01174 2.66626C1.56018 1.8457 2.30254 1.17308 3.17306 0.708002C4.04358 0.242921 5.01538 -0.000261549 6.00234 2.11094e-07V0.75Z" fill="black"/>
<path d="M5.625 2.25C5.72446 2.25 5.81984 2.28951 5.89017 2.35984C5.96049 2.43016 6 2.52554 6 2.625V6.5325L8.436 7.9245C8.51984 7.97508 8.58058 8.05645 8.60522 8.15122C8.62987 8.24598 8.61647 8.34663 8.56789 8.43165C8.5193 8.51667 8.4394 8.57931 8.34524 8.60619C8.25108 8.63307 8.15014 8.62206 8.064 8.5755L5.439 7.0755C5.3816 7.04271 5.33389 6.99534 5.3007 6.93818C5.26751 6.88102 5.25002 6.8161 5.25 6.75V2.625C5.25 2.52554 5.28951 2.43016 5.35984 2.35984C5.43016 2.28951 5.52554 2.25 5.625 2.25Z" fill="black"/>
</svg>

    `)

export const ClockIcon = createDynamicIcon(`
<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 1.75C4 1.6837 3.97366 1.62011 3.92678 1.57322C3.87989 1.52634 3.8163 1.5 3.75 1.5C3.6837 1.5 3.62011 1.52634 3.57322 1.57322C3.52634 1.62011 3.5 1.6837 3.5 1.75V4.5C3.50001 4.54407 3.51168 4.58735 3.5338 4.62545C3.55593 4.66356 3.58774 4.69514 3.626 4.717L5.376 5.717C5.43343 5.74804 5.50072 5.75538 5.56349 5.73746C5.62626 5.71954 5.67954 5.67778 5.71192 5.6211C5.74431 5.56442 5.75325 5.49732 5.73682 5.43414C5.72039 5.37097 5.6799 5.31672 5.624 5.283L4 4.355V1.75Z" fill="#98A1B2"/>
<path d="M4 8C5.06087 8 6.07828 7.57857 6.82843 6.82843C7.57857 6.07828 8 5.06087 8 4C8 2.93913 7.57857 1.92172 6.82843 1.17157C6.07828 0.421427 5.06087 0 4 0C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4C0 5.06087 0.421427 6.07828 1.17157 6.82843C1.92172 7.57857 2.93913 8 4 8ZM7.5 4C7.5 4.92826 7.13125 5.8185 6.47487 6.47487C5.8185 7.13125 4.92826 7.5 4 7.5C3.07174 7.5 2.1815 7.13125 1.52513 6.47487C0.868749 5.8185 0.5 4.92826 0.5 4C0.5 3.07174 0.868749 2.1815 1.52513 1.52513C2.1815 0.868749 3.07174 0.5 4 0.5C4.92826 0.5 5.8185 0.868749 6.47487 1.52513C7.13125 2.1815 7.5 3.07174 7.5 4Z" fill="#98A1B2"/>
</svg>
        `)
