import {
  GET_CUSTOMER_SEARCH,
  CLEAR_CUSTOMER_SEARCH,
} from '../actions/searchCustomerAction'

const initState = { search: '' }

const searchHistoryReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_SEARCH:
      return {
        ...state,
        ...action.payload.data,
      }

    case CLEAR_CUSTOMER_SEARCH:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default searchHistoryReducers
