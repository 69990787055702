import { atom } from 'jotai'

// @ts-ignore
import api from '@/api'
import { BookingHistory } from '@/redux/models/BookingHistory'

export const scheduleDate = atom<Date>(new Date())
export const scheduleCurrentDate = atom<Date>(new Date())

export const searchScheduleAtom = atom('')
export const filterGenderScheduleAtom = atom<0 | 1 | 2 | null>(null)

export const schedulerBookingHistoriesAtom = atom<BookingHistory[]>([])

export const schedulerBookingHistoriesFnAtom = atom(
  get => get(schedulerBookingHistoriesAtom),
  async (get, set) => {
    const from = get(scheduleCurrentDate)
    const to = get(scheduleCurrentDate)
    const { data } = await api.getBookingHistoryByDate(from, to)
    set(schedulerBookingHistoriesAtom, data)
    return data
  }
)
