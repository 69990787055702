export const GET_DATA_STORE = 'GET_DATA_STORE'

export const getDataStore = store => {
  return {
    type: GET_DATA_STORE,
    payload: { store },
  }
}

export const UPDATE_DATA_STORE = 'UPDATE_DATA_STORE'

export const updateDataStore = store => {
  return {
    type: UPDATE_DATA_STORE,
    payload: { store },
  }
}

export const UPDATE_TIME_OPEN = 'UPDATE_TIME_OPEN'

export const updateTimeOpen = daysTimes => {
  return {
    type: UPDATE_TIME_OPEN,
    payload: { daysTimes },
  }
}

export const UPDATE_DATE_CLOSE = 'UPDATE_DATE_CLOSE'

export const updateDateClose = dateStartClose => {
  return {
    type: UPDATE_DATE_CLOSE,
    payload: { dateStartClose },
  }
}

export const UPDATE_DATE_OPEN = 'UPDATE_DATE_OPEN'

export const updateDateOpen = dateStartOpen => {
  return {
    type: UPDATE_DATE_OPEN,
    payload: { dateStartOpen },
  }
}
