import React, { useState } from 'react'
import styled from 'styled-components'

import Button2 from '../../atoms/Button2'
import InputText from '../../atoms/InputText'
import TextLabel from '../../atoms/TextLabel'
import TimeIcon2 from '../../atoms/TimeIcon2'
import BahtIcon from '../../atoms/BahtIcon'
import utils from '../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Box = styled.div`
  width: 100%;
  background: #ffffff;
  border: ${props => props.border || '2px solid #e4e8eb'};
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-grow: 3;
  margin: ${props => props.margin || '0'};
  align-items: center;
`
const BoxStyle = styled.div`
  flex: ${props => props.flex || '1 0 40%'};
  width: ${props => props.width || '40%'};
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'unset'};
  padding: ${props => props.padding || '0'};
`
const Line = styled.div`
  height: 90%;
  border-left: 1px solid #e4e8eb;
`
const BoxEdit = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 1 auto'};
  max-width: ${props => props.width || 'auto'};
  margin-left: ${props => props.marginLeft || '0'};
  cursor: ${props => props.cursor || 'none'};
  position: relative;
  justify-content: flex-end;
`

const TableServiceCommission = ({
  serviceCommissions,
  onCommissionChange,
  editStaffCommission,
  staffCommission,
  staffTypeSameCommission,
  defaultOptionStaffType,
}) => {
  const [indexEdits, setIndexEdits] = useState(-1)

  const onEditClick = index => {
    if (indexEdits === index) {
      setIndexEdits(-1)
    } else {
      setIndexEdits(index)
    }
  }

  const onEditServiceCommissionClick = (e, index) => {
    e.stopPropagation()
    setIndexEdits(index)
  }

  const aggregateGroup = () => {
    let aggregatedGroup = []
    if (editStaffCommission !== -1) {
      aggregatedGroup = serviceCommissions.reduce((acc, info) => {
        const { staffs } = info
        const findStaffTypeIndex = staffs.findIndex(
          s =>
            s.staffTypeId === defaultOptionStaffType &&
            s.staffId === editStaffCommission
        )

        if (findStaffTypeIndex !== -1) {
          acc.push({
            ...info,
            commissionFee: staffs[findStaffTypeIndex].commissionFee,
          })
        }
        return acc
      }, [])
    } else if (!staffTypeSameCommission) {
      aggregatedGroup = serviceCommissions.reduce((acc, info) => {
        const { staffTypes } = info
        const findStaffTypeIndex = staffTypes.findIndex(
          s => s.staffTypeId === defaultOptionStaffType
        )
        if (findStaffTypeIndex !== -1) {
          acc.push({
            ...info,
            commissionFee: staffTypes[findStaffTypeIndex].commissionFee,
          })
        }
        return acc
      }, [])
    } else {
      aggregatedGroup = serviceCommissions.reduce((acc, info) => {
        acc.push({ ...info })
        return acc
      }, [])
    }
    return aggregatedGroup
  }

  const aggregatedGroup = aggregateGroup()

  return (
    <Container>
      <div className='mt-3 mb-2'>
        <TextLabel fontWeight='600'>ค่ามือของพนักงาน</TextLabel>
      </div>
      {
        // serviceCommissions
        aggregatedGroup.map(
          ({ timePriceId, time, price, commissionFee }, index) => (
            <Box
              key={index}
              margin='0 0 0.5rem 0'
              border={indexEdits === index ? '2px solid #8C56D9' : ''}
            >
              <BoxStyle padding='0 0 0 0.75rem'>
                <TimeIcon2 size='15' />
                <TextLabel
                  paddingLeft='0.25rem'
                  fontSize='0.9rem'
                  fontWeight='500'
                >{`${time} นาที / ${utils.formatNumber(
                  price || 0
                )}`}</TextLabel>
              </BoxStyle>
              <Line></Line>
              <BoxStyle padding='0 0 0 1rem'>
                <BahtIcon size='17' />
                <InputText
                  boxShadow='none'
                  border='none'
                  height='100%'
                  padding='0 0 0 0.5rem'
                  colorPlaceholder='#D0D4DD'
                  fontSizePlaceholder='0.8rem'
                  placeholder='ค่ามือพนักงาน'
                  borderFocus='unset'
                  outLine='none'
                  value={
                    indexEdits !== index && commissionFee
                      ? `${utils.formatNumber(commissionFee || 0)}`
                      : commissionFee
                  }
                  onChange={val => onCommissionChange(val.target.value, index)}
                  readOnly={indexEdits !== index}
                />
              </BoxStyle>
              <BoxStyle flex='1 0 20%' width='20%' justifyContent='flex-end'>
                {indexEdits === index ? (
                  <Button2
                    padding='0.15rem 0'
                    size='0.9rem'
                    backgroundColor={
                      indexEdits === index
                        ? commissionFee
                          ? '#8C56D9'
                          : '#E4E8EB'
                        : '#53389E'
                    }
                    color={
                      indexEdits === index
                        ? commissionFee
                          ? '#FFFFFF'
                          : '#98A1B2'
                        : '#FFFFFF'
                    }
                    backgroundColorHover={
                      indexEdits === index
                        ? commissionFee
                          ? '#53389E'
                          : '#E4E8EB'
                        : '#8C56D9'
                    }
                    onClick={() => onEditClick(index)}
                  >
                    ยืนยัน
                  </Button2>
                ) : (
                  <BoxEdit>
                    <Button2
                      padding='0.15rem 0'
                      size='0.9rem'
                      onClick={e => onEditServiceCommissionClick(e, index)}
                    >
                      แก้ไข
                    </Button2>
                  </BoxEdit>
                )}
              </BoxStyle>
            </Box>
          )
        )
      }
    </Container>
  )
}

export default TableServiceCommission
