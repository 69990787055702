import { Col, Row } from '@/components/atoms/Flex'
import { CalendarIcon } from '@/components/atoms/icons'
import { ClockIcon } from '@/components/atoms/icons/ClockIcon'
import { PersonProfileIcon } from '@/components/atoms/icons/PeopleIcon'
import { bookHistoryToBookOrder } from '@/redux/actions/bookingHistoryAction'
import { BookingHistory, Order } from '@/redux/models/BookingHistory'
import Service from '@/redux/models/Service'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
// @ts-ignore
import api from '@/api'
import { useTranslation } from 'react-i18next'
import { Customer } from '@/redux/models/Customer'
import { Separator } from '@/components/atoms/Separator'
import ReactToPrint from 'react-to-print'
import Button from '@/components/atoms/Button'

export interface StaffSpaCardProps {
  orders: {
    order: Order
    customer: Customer
  }[]
  staffId: number
  booking: BookingHistory
}

const StyledGridInput = styled.div<{
  bold?: boolean
}>`
  display: grid;
  grid-template-columns: 1fr 7fr;
  gap: 8px;
  align-items: end;
  label {
    text-align: end;
    font-weight: bold;
    margin-bottom: 0;
  }
  span {
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  }
  input {
    border: none;
    --webkit-appearance: none;
    outline: none;
    border-bottom: 1px dashed #000;
    background: transparent;
  }
`

const StyledServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`

const StyledStaffSpaCardContainer = styled.div<{
  separator?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 317px;
  padding: 12px;
  border-bottom: ${props => (props.separator ? '2px dashed #999' : 'none')};
`

const StyledStaffSpaCardHeaderLabel = styled.div<{
  bold?: boolean
}>`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  display: flex;
  gap: 8px;
  self-align: center;
  align-items: center;
`

const StyledPrintSection = styled.div`
  width: 105mm;
  font-size: 14px;
  @media print {
    padding: 12px;
  }
`

const StaffSpaCard = (props: StaffSpaCardProps) => {
  const { orders, staffId, booking } = props

  const printRef = React.useRef(null)

  const { t } = useTranslation()

  const staff = useMemo(() => {
    return orders[0].order.staffs.find(staff => staff.staffId === staffId)
  }, [staffId, orders])

  const [services, setServices] = useState<Service[]>([])

  useEffect(() => {
    const fetchServices = async () => {
      const { data: _services } = await api.getServices()
      setServices(_services)
    }
    fetchServices()
  }, [orders])

  const bookOrder = useMemo(() => {
    return bookHistoryToBookOrder(booking, services)
  }, [booking, services])

  const timeString = useMemo(() => {
    const startTime = bookOrder?.startTime
    const endTime = bookOrder?.endTime
    return `${startTime?.hour.toString().padStart(2, '0')}:${startTime?.minute
      .toString()
      .padStart(2, '0')} - ${endTime?.hour
      .toString()
      .padStart(2, '0')}:${endTime?.minute.toString().padStart(2, '0')}`
  }, [bookOrder])

  if(!staff?.staffId || staff?.staffId === -1) return null

  return (
    <StyledStaffSpaCardContainer>
      <StyledPrintSection ref={printRef}>
        <Col align='center' gap='12px'>
          <Row>
            <StyledStaffSpaCardHeaderLabel bold>
              <span>{t("staff")}: </span>
              {staff?.staffName}
            </StyledStaffSpaCardHeaderLabel>
          </Row>
          <Row gap='16px'>
            <StyledStaffSpaCardHeaderLabel>
            <span>{t("date")}: </span>
              {dayjs(booking.date).format('DD/MM/YYYY')}
            </StyledStaffSpaCardHeaderLabel>
            <StyledStaffSpaCardHeaderLabel>
            <span>{t("time")}: </span>
              {timeString}
            </StyledStaffSpaCardHeaderLabel>
          </Row>
        </Col>
        <StyledServiceContainer>
          {orders.map((_order, index) => {
            const order = _order.order
            const customer = _order.customer
            const startTime = dayjs(booking?.date)
              .set('hour', +bookOrder?.startTime.hour)
              .set('minute', +bookOrder?.startTime.minute)
            let startOrderTime = dayjs(startTime)
            let endOrderTime = dayjs(startTime)
            for (let oIndex in orders) {
              const o = orders[oIndex]
              endOrderTime = endOrderTime.add(o.order.time, 'minute')
              if (oIndex === index.toString()) {
                break
              }
              startOrderTime = startOrderTime.add(o.order.time, 'minute')
            }
            const startEndString = `${startOrderTime.format(
              'HH:mm'
            )} - ${endOrderTime.format('HH:mm')}`
            return (
              <>
                <StyledGridInput bold>
                  <label>{t('service')} :</label>
                  <span>{order.service}</span>
                </StyledGridInput>
                <StyledGridInput bold>
                  <label>{t('customer')} :</label>
                  <span>{customer.name}</span>
                </StyledGridInput>
                <StyledGridInput>
                  <label>{t('room')} :</label>
                  <span>
                    {order.rooms?.map(room => room.roomName).join(', ')}
                  </span>
                </StyledGridInput>
                <StyledGridInput>
                  <label>{t('time')} :</label>
                  <span>
                    {startEndString} {`(${order?.time} ${t('minutes')})`}
                  </span>
                </StyledGridInput>
                <Separator />
              </>
            )
          })}
          <StyledGridInput>
            <label>{t('oil')} :</label>
            <input />
          </StyledGridInput>
          <StyledGridInput>
            <label>{t('focus')} :</label>
            <input />
          </StyledGridInput>
          <StyledGridInput>
            <label>{t('avoid')} :</label>
            <input />
          </StyledGridInput>
        </StyledServiceContainer>
      </StyledPrintSection>
      <ReactToPrint
        trigger={() => <Button variant='secondary'>{t('print')}</Button>}
        content={() => printRef.current}
      />
    </StyledStaffSpaCardContainer>
  )
}

export default StaffSpaCard
