import { GET_PAY_CHANNEL } from '../actions/callApiPayChannelAction'

const initState = {}
const callApiPayChannelReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_PAY_CHANNEL:
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

export default callApiPayChannelReducers
