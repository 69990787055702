import { css } from 'styled-components'

const h1 = css`
  font-size: 32px;
  line-height: 40px;
`

const h2 = css`
  font-size: 24px;
  line-height: 32px;
`

const h3 = css`
  font-size: 20px;
  line-height: 28px;
`

const h4 = css`
  font-size: 18px;
  line-height: 24px;
`

const body1 = css`
  font-size: 16px;
  line-height: 20px;
`

const body2 = css`
  font-size: 14px;
  line-height: 20px;
`

const body3 = css`
  font-size: 12px;
  line-height: 16px;
`

const body4 = css`
  font-size: 10px;
  line-height: 14px;
`

const body5 = css`
  font-size: 8px;
  line-height: 10px;
`

const bold = css`
  font-weight: 700;
`

const normal = css`
  font-weight: 400;
`

const medium = css`
  font-weight: 500;
`

const semibold = css`
  font-weight: 600;
`

const typography = {
  h1,
  h2,
  h3,
  h4,
  body1,
  body2,
  body3,
  body4,
  body5,
  bold,
  semibold,
  medium,
  normal,
}

export default typography
