import React from 'react'

const AgencyCommissionIcon = () => (
  <svg
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13 1V9M13 1L9.66667 4.33333M13 1L16.3333 4.33333M4.76444 9.00049C5.31773 9.48911 5.66667 10.2038 5.66667 11C5.66667 12.4728 4.47276 13.6667 3 13.6667C2.2038 13.6667 1.48911 13.3177 1.00049 12.7644M4.76444 9.00049C4.92192 9 5.08901 9 5.26667 9H7.66667M4.76444 9.00049C3.59803 9.00411 2.9586 9.03457 2.45603 9.29065C1.95425 9.54631 1.54631 9.95425 1.29065 10.456C1.03457 10.9587 1.00411 11.598 1.00049 12.7644M1.00049 12.7644C1 12.9219 1 13.0891 1 13.2667V20.7333C1 20.9109 1 21.0781 1.00049 21.2356M1.00049 21.2356C1.48911 20.6823 2.2038 20.3333 3 20.3333C4.47276 20.3333 5.66667 21.5272 5.66667 23C5.66667 23.7961 5.31773 24.5109 4.76444 24.9995M1.00049 21.2356C1.00411 22.402 1.03457 23.0415 1.29065 23.544C1.54631 24.0457 1.95425 24.4537 2.45603 24.7093C2.9586 24.9655 3.59803 24.9959 4.76444 24.9995M4.76444 24.9995C4.92192 25 5.08901 25 5.26667 25H20.7333C20.9109 25 21.0781 25 21.2356 24.9995M21.2356 24.9995C20.6823 24.5109 20.3333 23.7961 20.3333 23C20.3333 21.5272 21.5272 20.3333 23 20.3333C23.7964 20.3333 24.5113 20.6825 25 21.2361M21.2356 24.9995C22.402 24.9959 23.0415 24.9655 23.544 24.7093C24.0457 24.4537 24.4537 24.0457 24.7093 23.544C24.9653 23.0415 24.9964 22.4021 25 21.2361M25 21.2361C25.0005 21.0785 25 20.9112 25 20.7333V13.2667C25 13.0891 25 12.9219 24.9995 12.7644M24.9995 12.7644C24.5109 13.3177 23.7961 13.6667 23 13.6667C21.5272 13.6667 20.3333 12.4728 20.3333 11C20.3333 10.2038 20.6823 9.48911 21.2356 9.00049M24.9995 12.7644C24.9959 11.598 24.9655 10.9587 24.7093 10.456C24.4537 9.95425 24.0457 9.54631 23.544 9.29065C23.0415 9.03457 22.402 9.00411 21.2356 9.00049M21.2356 9.00049C21.0781 9 20.9109 9 20.7333 9H18.3333M15.6667 17C15.6667 18.4728 14.4728 19.6667 13 19.6667C11.5272 19.6667 10.3333 18.4728 10.3333 17C10.3333 15.5272 11.5272 14.3333 13 14.3333C14.4728 14.3333 15.6667 15.5272 15.6667 17Z'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default AgencyCommissionIcon
