export const GET_TYPE_SERVICE = 'GET_TYPE_SERVICE'

export const updateTypeService = typeService => {
  return {
    type: GET_TYPE_SERVICE,
    payload: {
      typeService: typeService,
    },
  }
}
