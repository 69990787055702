import React from 'react'

interface FemaleIconProps {
  size?: string
  color?: string
}

const FemaleIcon = ({ size, color }: FemaleIconProps) => (
  <svg
    width={size || '11'}
    height={size || '16'}
    viewBox='0 0 11 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.0822 5.0411C10.0822 2.25753 7.82466 0 5.0411 0C2.25753 0 0 2.25753 0 5.0411C0 7.56164 1.8411 9.64384 4.27397 10.0164V13.1507H3.50685C3.13425 13.1507 2.84932 13.4356 2.84932 13.8082C2.84932 14.1808 3.13425 14.4658 3.50685 14.4658H4.27397V15.3425C4.27397 15.7151 4.5589 16 4.93151 16C5.30411 16 5.58904 15.7151 5.58904 15.3425V14.4658H6.35616C6.72877 14.4658 7.0137 14.1808 7.0137 13.8082C7.0137 13.4356 6.72877 13.1507 6.35616 13.1507H5.58904V10.0603C8.10959 9.77534 10.0822 7.64931 10.0822 5.0411ZM1.31507 5.0411C1.31507 2.98082 2.98082 1.31507 5.0411 1.31507C7.10137 1.31507 8.76712 2.98082 8.76712 5.0411C8.76712 7.10137 7.10137 8.76712 5.0411 8.76712C2.98082 8.76712 1.31507 7.10137 1.31507 5.0411Z'
      fill={color || '#F9B3A2'}
    />
  </svg>
)

export default FemaleIcon
