import React from 'react'
import { IconProps } from './IconPropsType'

const QueueIcon = ({ size, color = '' }: IconProps) => (
  <svg
    width={size || '41'}
    height={size || '40'}
    viewBox='0 0 41 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_65_1532)'>
      <path
        d='M36.6839 9.03503C36.7355 9.03503 36.7742 9.07374 36.7742 9.12535V36.0415C36.7742 36.0931 36.7355 36.1318 36.6839 36.1318H3.96129C3.90968 36.1318 3.87097 36.0931 3.87097 36.0415V9.12535C3.87097 9.07374 3.90968 9.03503 3.96129 9.03503H36.6839ZM36.6839 5.16406H3.96129C1.77419 5.16406 0 6.93826 0 9.12535V36.0415C0 38.2286 1.77419 40.0028 3.96129 40.0028H36.6839C38.871 40.0028 40.6452 38.2286 40.6452 36.0415V9.12535C40.6452 6.93826 38.871 5.16406 36.6839 5.16406Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M13.5507 0H9.67969V10.9677H13.5507V0Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M30.9725 0H27.1016V10.9677H30.9725V0Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M30.8798 20H17.9766V23.871H30.8798V20Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M22.4892 27.7422H9.58594V31.6132H22.4892V27.7422Z'
        fill={color || '#8C56D9'}
      />
    </g>
    <defs>
      <clipPath id='clip0_65_1532'>
        <rect width='40.6452' height='40' fill='white' />
      </clipPath>
    </defs>
  </svg>

  // <svg
  //   width={width || '64'}
  //   height={height || '45'}
  //   viewBox='0 0 64 45'
  //   fill='none'
  //   xmlns='http://www.w3.org/2000/svg'
  // >
  //   <rect y='13' width='64' height='4' rx='2' fill='url(#paint0Linear)' />
  //   <rect width='25' height='4' rx='2' fill='url(#paint1Linear)' />
  //   <rect y='26' width='47' height='4' rx='2' fill='url(#paint2Linear)' />
  //   <rect y='41' width='59' height='4' rx='2' fill='url(#paint3Linear)' />
  //   <defs>
  //     <linearGradient
  //       id='paint0Linear'
  //       x1='-30.0576'
  //       y1='15'
  //       x2='-29.5266'
  //       y2='22.9804'
  //       gradientUnits='userSpaceOnUse'
  //     >
  //       <stop stopColor={color || '#57557F'} />
  //       <stop offset='1' stopColor={color || '#8E759F'} />
  //     </linearGradient>
  //     <linearGradient
  //       id='paint1Linear'
  //       x1='-11.7413'
  //       y1='2'
  //       x2='-10.4144'
  //       y2='9.78967'
  //       gradientUnits='userSpaceOnUse'
  //     >
  //       <stop stopColor={color || '#57557F'} />
  //       <stop offset='1' stopColor={color || '#8E759F'} />
  //     </linearGradient>
  //     <linearGradient
  //       id='paint2Linear'
  //       x1='-22.0736'
  //       y1='28'
  //       x2='-21.3532'
  //       y2='35.9504'
  //       gradientUnits='userSpaceOnUse'
  //     >
  //       <stop stopColor={color || '#57557F'} />
  //       <stop offset='1' stopColor={color || '#8E759F'} />
  //     </linearGradient>
  //     <linearGradient
  //       id='paint3Linear'
  //       x1='-27.7094'
  //       y1='43'
  //       x2='-27.1338'
  //       y2='50.9741'
  //       gradientUnits='userSpaceOnUse'
  //     >
  //       <stop stopColor={color || '#57557F'} />
  //       <stop offset='1' stopColor={color || '#8E759F'} />
  //     </linearGradient>
  //   </defs>
  // </svg>
)

export default QueueIcon
