import { DocumentIcon } from '@/components/atoms/icons/DocumentIcon'
import { XCircleIcon } from '@/components/atoms/icons/XIcon'
import SchedulerItem from '@/components/organisms/scheduler/SchedulerItem'
import React from 'react'

type Props = {}

export default function SchedulerComponentPage({}: Props) {
  return (
    <div>
        <h1>Scheduler Component</h1>
        <SchedulerItem
            booking={{
                id: 200,
                title: "Thaihand",
                start: new Date(),
                end: new Date(new Date().setHours(20, 32, 54)),
                customer: "John Doe",
                phoneNumber: "1234567890",
                status: 0,
                payStatus: 2,
                room: "Room 1"
            }}
        />
    </div>
  )
}