import { createDynamicIcon } from './Icon'

export const XCircleIcon = createDynamicIcon(`
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 11.25C4.60761 11.25 3.27226 10.6969 2.28769 9.71231C1.30312 8.72774 0.75 7.39239 0.75 6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6C11.25 7.39239 10.6969 8.72774 9.71231 9.71231C8.72774 10.6969 7.39239 11.25 6 11.25ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12Z" fill="#DB2D1E"/>
<path d="M3.48517 3.48517C3.52 3.45025 3.56139 3.42254 3.60694 3.40364C3.6525 3.38473 3.70134 3.375 3.75067 3.375C3.8 3.375 3.84884 3.38473 3.8944 3.40364C3.93995 3.42254 3.98134 3.45025 4.01617 3.48517L6.00067 5.47042L7.98517 3.48517C8.02004 3.4503 8.06143 3.42265 8.10698 3.40378C8.15254 3.38491 8.20136 3.3752 8.25067 3.3752C8.29998 3.3752 8.3488 3.38491 8.39436 3.40378C8.43991 3.42265 8.4813 3.4503 8.51617 3.48517C8.55104 3.52004 8.57869 3.56143 8.59756 3.60698C8.61643 3.65254 8.62614 3.70136 8.62614 3.75067C8.62614 3.79998 8.61643 3.8488 8.59756 3.89436C8.57869 3.93991 8.55104 3.9813 8.51617 4.01617L6.53092 6.00067L8.51617 7.98517C8.55104 8.02004 8.57869 8.06143 8.59756 8.10698C8.61643 8.15254 8.62614 8.20136 8.62614 8.25067C8.62614 8.29998 8.61643 8.3488 8.59756 8.39436C8.57869 8.43991 8.55104 8.4813 8.51617 8.51617C8.4813 8.55104 8.43991 8.57869 8.39436 8.59756C8.3488 8.61643 8.29998 8.62614 8.25067 8.62614C8.20136 8.62614 8.15254 8.61643 8.10698 8.59756C8.06143 8.57869 8.02004 8.55104 7.98517 8.51617L6.00067 6.53092L4.01617 8.51617C3.9813 8.55104 3.93991 8.57869 3.89436 8.59756C3.8488 8.61643 3.79998 8.62614 3.75067 8.62614C3.70136 8.62614 3.65254 8.61643 3.60698 8.59756C3.56143 8.57869 3.52004 8.55104 3.48517 8.51617C3.4503 8.4813 3.42265 8.43991 3.40378 8.39436C3.38491 8.3488 3.3752 8.29998 3.3752 8.25067C3.3752 8.20136 3.38491 8.15254 3.40378 8.10698C3.42265 8.06143 3.4503 8.02004 3.48517 7.98517L5.47042 6.00067L3.48517 4.01617C3.45025 3.98134 3.42254 3.93995 3.40364 3.8944C3.38473 3.84884 3.375 3.8 3.375 3.75067C3.375 3.70134 3.38473 3.6525 3.40364 3.60694C3.42254 3.56139 3.45025 3.52 3.48517 3.48517Z" fill="#DB2D1E"/>
</svg>

    `)

export const XIcon = createDynamicIcon(`
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.119141 2.38099L4.73891 7.00076L0.119141 11.6205L1.53138 13.0328L6.0242 8.53995L10.5233 13.0391L11.9355 11.6268L7.31578 7.00705L11.9355 2.38728L10.5233 0.975041L6.03049 5.46786L1.53138 0.96875L0.119141 2.38099Z" fill="#8C56D9"/>
</svg>

    `)
