import React from 'react'

interface ServiceIconProps {
  size?: string
  color?: string
}

const ServiceIcon = ({ size, color }: ServiceIconProps) => (
  <svg
    width={size || '63'}
    height={size || '63'}
    viewBox='0 0 63 63'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='1.85'
      y='1.85'
      width='25.3769'
      height='25.3769'
      rx='2.65'
      stroke={color || '#4E4E72'}
      strokeWidth='3.7'
    />
    <rect
      x='1.85'
      y='35.7729'
      width='25.3769'
      height='25.3769'
      rx='2.65'
      stroke={color || '#4E4E72'}
      strokeWidth='3.7'
    />
    <rect
      x='35.7738'
      y='1.85'
      width='25.3769'
      height='25.3769'
      rx='2.65'
      stroke={color || '#4E4E72'}
      strokeWidth='3.7'
    />
    <rect
      x='35.7738'
      y='35.7729'
      width='25.3769'
      height='25.3769'
      rx='2.65'
      stroke={color || '#4E4E72'}
      strokeWidth='3.7'
    />
  </svg>
)

export default ServiceIcon
