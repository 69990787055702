import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import InputText from '../../atoms/InputText'
import TextLabel from '../../atoms/TextLabel'
import UploadImageIcon from '../../atoms/UploadImageIcon'
import CameraIcon from '../../atoms/CameraIcon'
import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'
import PinIcon from '../../atoms/PinIcon'
import PopupAddPin from '../popups/PopupAddPin'
import PopupAddPassword from '../popups/PopupAddPassword'
import utils from '../../utils'
import api from '../../api'
import auth from '../../auth'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import Page from '../../lib/layout/Page/Page'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
  padding: 1rem 0.5rem 1rem 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 1rem;
  flex: 1;
  overflow: hidden;
  height: auto;
`
const Box = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 1rem;
`
const BoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => props.flex || '1 0 auto'};
  padding-left: ${props => props.paddingLeft || '0'};
  padding-right: ${props => props.paddingRight || '0'};
`
const ImageBox = styled.div`
  position: relative;
  border-radius: 8px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#E4E8EB'};
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
`
const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`
const BoxPassword = styled.div`
  background: #f3eefb;
  border: ${props => props.border || '2px solid #f3eefb'};
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  color: #8c56d9;
  width: 100%;
  padding: 0.25rem;
`
const Circle = styled.div`
  border-radius: 50%;
  background-color: #db2d1e;
  width: 15px;
  height: 15px;
  position: relative;
  ::before {
    display: block;
    position: absolute;
    content: '!';
    border-radius: 50%;
    color: white;
    font-weight: normal;
    left: 6px;
    top: -4px;
  }
`

const CardProfile = () => {
  const currentUser = useSelector(state => state.user)
  const [user, setUser] = useState({})
  const [openAddPassword, setOpenAddPassword] = useState(false)
  const [openAddPin, setOpenAddPin] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')
  const [currentPin, setCurrentPin] = useState('')
  const [pin, setPin] = useState('')
  const [verifyPin, setVerifyPin] = useState('')
  const [checkRequired, setCheckRequired] = useState([
    false,
    false,
    false,
    false,
    false,
  ])
  const [samePhoneNumber, setSamePhoneNumber] = useState(false)
  const [fileImage, setFileImage] = useState('')
  const [success, setSuccess] = useState(false)
  const [token, setToken] = useState('')

  useEffect(() => {
    setUser(currentUser)
  }, [currentUser])

  const onPinChange = (currentPin, pin, verifyPin, token) => {
    const newArrCheckRequired = [...checkRequired]
    newArrCheckRequired[4] = false
    setCheckRequired(newArrCheckRequired)
    setCurrentPin(currentPin)
    setPin(pin)
    setVerifyPin(verifyPin)
    setToken(token)
    setOpenAddPin(false)
  }
  const onPasswordChange = (
    currentPassword,
    password,
    verifyPassword,
    token
  ) => {
    const newArrCheckRequired = [...checkRequired]
    newArrCheckRequired[3] = false
    setCheckRequired(newArrCheckRequired)
    setCurrentPassword(currentPassword)
    setPassword(password)
    setVerifyPassword(verifyPassword)
    setToken(token)
    setOpenAddPassword(false)
  }

  const onChangeImage = async event => {
    const file = event.target.files[0]
    if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
      alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
    } else if (utils.isFileExceedLimit(file)) {
      alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
    } else {
      setFileImage(file)
      const image = await utils.readImage(file)
      setUser({ ...user, image: image.image })
    }
  }
  const onUserIdChange = value => {
    const newArrCheckRequired = [...checkRequired]
    const newUser = { ...user }
    newUser.userId = value
    newArrCheckRequired[0] = false
    setCheckRequired(newArrCheckRequired)
    setUser(newUser)
  }
  const onFirstNameChange = value => {
    const newUser = { ...user }
    newUser.firstName = value
    setUser(newUser)
  }
  const onLastNameChange = value => {
    const newUser = { ...user }
    newUser.lastName = value
    setUser(newUser)
  }
  const onEmailChange = value => {
    const newArrCheckRequired = [...checkRequired]
    const newUser = { ...user }
    newUser.email = value
    newArrCheckRequired[1] = false
    setCheckRequired(newArrCheckRequired)
    setUser(newUser)
  }
  const onPhoneNumberChange = value => {
    const newArrCheckRequired = [...checkRequired]
    const newUser = { ...user }
    if (value.length <= 10) {
      if (utils.checkNumber(value)) {
        newUser.phoneNumber = value
      } else if (!value) {
        newUser.phoneNumber = ''
      }
      newArrCheckRequired[2] = false
      setCheckRequired(newArrCheckRequired)
      setUser(newUser)
    }
  }

  const onSaveClick = async () => {
    try {
      const newArrCheckRequired = [...checkRequired]
      if (!user.userId) {
        newArrCheckRequired[0] = true
      }
      if (!user.email) {
        newArrCheckRequired[1] = true
      }
      if (!user.phoneNumber) {
        newArrCheckRequired[2] = true
      }
      if (!password && user._id === -1) {
        newArrCheckRequired[3] = true
      }
      if (!pin && user._id === -1) {
        newArrCheckRequired[4] = true
      }

      const { success: checkSamePhoneNumber } =
        await api.getUserCheckPhoneNumber(user.phoneNumber, user._id)
      if (!checkSamePhoneNumber) {
        setSamePhoneNumber(true)
        return false
      } else {
        let newUser = { ...user }
        if (fileImage) {
          const { data } = await api.uploadImageUser({
            token: auth.getToken(),
            image: fileImage,
          })
          newUser = { ...newUser, image: data }
        }
        let checkPinSuccess = true
        let checkPasswordSuccess = true
        if (pin) {
          const body = {
            currentPin: currentPin.join(''),
            newPin: pin.join(''),
            verifyPin: verifyPin.join(''),
            token,
          }
          const { success } = await api.renewPin(body)
          checkPinSuccess = success
        }
        if (password) {
          const body = {
            currentPassword,
            newPassword: password,
            verifyPassword,
            token,
          }
          const { success } = await api.renewPassword(body)
          checkPasswordSuccess = success
        }
        if (checkPinSuccess && checkPasswordSuccess) {
          const body = {
            ...newUser,
          }
          const { success } = await api.updateUser(
            user._id,
            auth.getStoreId(),
            body
          )
          if (success) {
            setSuccess(true)
            setTimeout(() => {
              setSuccess(false)
            }, 1000)
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Page>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextLabel fontWeight='600' fontSize='1.875rem'>
          ข้อมูลส่วนตัว
        </TextLabel>
      </div>
      <Card>
        <Box>
          <ImageBox background={user.image ? user.image : ''}>
            {!user.image && <CameraIcon size='30' />}
            <ImageUploadIcon>
              <label
                for='upload-image'
                //   onClick={e => (edit ? '' : e.preventDefault())}
              >
                <UploadImageIcon size='25' colorCircle='#8C56D9' />
              </label>
              <input
                id='upload-image'
                type='file'
                onChange={onChangeImage}
                style={{ display: 'none', visibility: 'none' }}
              />
            </ImageUploadIcon>
          </ImageBox>
          <BoxStyle flex='1 0 25%' paddingLeft='1rem'>
            <TextLabel fontWeight='600'>User Id*</TextLabel>
            <InputText
              borderRadius='4px'
              boxShadow='none'
              height='35px'
              padding='0 0 0 1rem'
              border={checkRequired[0] ? '2px solid #DB2D1E' : ''}
              value={user.userId}
              onChange={val => onUserIdChange(val.target.value)}
            />
          </BoxStyle>
          <BoxStyle flex='1 0 25%' paddingLeft='1rem'>
            <TextLabel fontWeight='600'>ชื่อจริง*</TextLabel>
            <InputText
              borderRadius='4px'
              boxShadow='none'
              height='35px'
              padding='0 0 0 1rem'
              value={user.firstName}
              onChange={val => onFirstNameChange(val.target.value)}
            />
          </BoxStyle>
          <BoxStyle flex='1 0 25%' paddingLeft='1rem'>
            <TextLabel fontWeight='600'>นามสกุล*</TextLabel>
            <InputText
              borderRadius='4px'
              boxShadow='none'
              height='35px'
              padding='0 0 0 1rem'
              value={user.lastName}
              onChange={val => onLastNameChange(val.target.value)}
            />
          </BoxStyle>
        </Box>
        <div
          className='mt-3 mb-3'
          style={{
            borderTop: '1px solid #E4E8EB',
            width: 'calc(100% + 2rem)',
            marginLeft: '-1rem',
            marginBottom: '0.5rem',
          }}
        ></div>
        <Box>
          <BoxStyle flex='1 0 50%' paddingRight='0.5rem'>
            <TextLabel fontWeight='600'>อีเมล*</TextLabel>
            <InputText
              borderRadius='4px'
              boxShadow='none'
              height='35px'
              padding='0 0 0 1rem'
              border={checkRequired[1] ? '2px solid #DB2D1E' : ''}
              value={user.email}
              type='email'
              onChange={val => onEmailChange(val.target.value)}
            />
          </BoxStyle>
          <BoxStyle flex='1 0 50%' paddingLeft='0.5rem'>
            <TextLabel fontWeight='600'>เบอร์โทรศัพท์*</TextLabel>
            <InputText
              borderRadius='4px'
              boxShadow='none'
              height='35px'
              padding='0 0 0 1rem'
              border={
                checkRequired[2] || samePhoneNumber ? '2px solid #DB2D1E' : ''
              }
              value={user.phoneNumber || ''}
              onChange={val => onPhoneNumberChange(val.target.value)}
            />
            {samePhoneNumber && (
              <div
                className='mt-1'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <Circle></Circle>
                <TextLabel color='#DB2D1E' fontWeight='500'>
                  เบอร์โทรนี้ถูกใช้งานแล้ว
                </TextLabel>
              </div>
            )}
          </BoxStyle>
        </Box>
        <Box>
          <BoxStyle flex='1 0 50%' paddingRight='0.5rem'>
            <TextLabel fontWeight='600'>รหัสผ่าน</TextLabel>
            <BoxPassword
              className='mt-2'
              onClick={() => setOpenAddPassword(!openAddPassword)}
            >
              <PinIcon size='28' color='#8C56D9' />
              &nbsp;&nbsp; เปลี่ยนรหัส
            </BoxPassword>
          </BoxStyle>
          <BoxStyle flex='1 0 50%' paddingLeft='0.5rem'>
            <TextLabel fontWeight='600'>รหัสผ่าน PIN</TextLabel>
            <BoxPassword
              className='mt-2'
              onClick={() => setOpenAddPin(!openAddPin)}
            >
              <PinIcon size='28' color='#8C56D9' />
              &nbsp;&nbsp; เปลี่ยนรหัส PIN
            </BoxPassword>
          </BoxStyle>
        </Box>
      </Card>
      <div
        className='mt-3'
        style={{
          width: 'calc(100% - 1rem)',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button2
          backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
          color='#FFF'
          padding='0.5rem 3rem'
          width='fit-content'
          borderRadius='8px'
          icon={<SaveIcon size='15' />}
          onClick={() => onSaveClick()}
        >
          บันทึก
        </Button2>
      </div>
      {openAddPin && (
        <PopupAddPin
          handleClose={() => setOpenAddPin(!openAddPin)}
          onPinChange={onPinChange}
          currentDataEdit={user}
          currentIdEdit={user._id}
        />
      )}
      {openAddPassword && (
        <PopupAddPassword
          handleClose={() => setOpenAddPassword(!openAddPassword)}
          currentIdEdit={user._id}
          currentDataEdit={user}
          onPasswordChange={onPasswordChange}
        />
      )}
      {success && <SuccessIcon2 position='absolute' />}
    </Page>
  )
}

export default CardProfile
