import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'

import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'
import RecommendIcon from '../../atoms/RecommendIcon'
import TemporaryIcon from '../../atoms/TemporaryIcon'
import SaleBanIcon from '../../atoms/SaleBanIcon'

const Container = styled.div`
  width: 100%;
  border-radius: 0px;
  width: 100%;
  padding: 0.75rem 1rem;
  border: ${props => props.border || '2px solid #e4e8eb'};
  &:first-child {
    border-radius: 8px 8px 0px 0px;
    border-width: 2px 2px 0 2px;
  }
  &:last-child {
    border-radius: 0px 0px 8px 8px;
    border-width: 0 2px 2px 2px;
  }
  &:only-child {
    border-radius: 8px;
    border-width: 2px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }
`
const Box = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 97%) minmax(0, 3%);
  width: 100%;
  align-items: center;
`
const ContainerCircle = styled.div`
  display: flex;
  max-width: ${props => props.width || '5.5%'};
`

const ContainerCircleStyle = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: wrap;
  width: 80%;
  padding: ${props => props.padding || '0'};
  height: fit-content;
`

const BoxCircle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 33%'};
  max-width: ${props => props.width || '33%'};
  margin: ${props => props.margin || '0.25rem 0 0 0'};
`

const Circle = styled.div`
  background: #e4e8eb;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`

const Image = styled.img`
  border-radius: 4px;
  width: 32px;
  height: 32px;
  object-fit: cover;
  aspect-ratio: 1;
`

const Image2 = styled.div`
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background: #e4e8eb;
  aspect-ratio: 1;
`

const BoxEdit = styled.div`
  display: flex;
  cursor: ${props => props.cursor || 'pointer'};
  position: relative;
  align-items: center;
  min-height: 100%;
`

const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.16);
  border-radius: 8px;
  padding: 0.5rem;
  top: 28px;
  right: 0px;
  z-index: 1;
`

const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`

const BoxData = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
`

const BoxDataStyle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 33%'};
  max-width: ${props => props.width || '33%'};
  min-width: ${props => props.width || '33%'};
  align-items: center;
  justify-content: ${props => props.justifyContent || 'unset'};
`
const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1.875rem;
`

const ServiceDrag = ({
  task,
  index,
  activeServiceEdit,
  onDropDownServiceEditClick,
  onEditServiceClick: _onEditServiceClick,
  onDeleteServiceClick: _onDeleteServiceClick,
}) => {
  const onEditServiceClick = (e, id) => {
    e.stopPropagation()
    _onEditServiceClick(id)
  }

  const onDeleteServiceClick = (e, id) => {
    e.stopPropagation()
    _onDeleteServiceClick(id)
  }

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => {
        return (
          <Container ref={provided.innerRef} {...provided.draggableProps}>
            <Box onClick={e => onEditServiceClick(e, task._id)}>
              <div style={{ display: 'flex', width: '100%' }}>
                <ContainerCircle {...provided.dragHandleProps}>
                  <ContainerCircleStyle>
                    {Array.from({ length: 9 }).map((_, index) => (
                      <BoxCircle key={index}>
                        <Circle></Circle>
                      </BoxCircle>
                    ))}
                  </ContainerCircleStyle>
                </ContainerCircle>
                {task.image ? <Image src={task.image} /> : <Image2></Image2>}
                <BoxData>
                  <BoxDataStyle flex='1 0 30%' width='30%'>
                    <TextLabel paddingLeft='0.5rem'>{task.content}</TextLabel>
                  </BoxDataStyle>
                  <BoxDataStyle flex='1 0 15%' width='15%'>
                    {task.recommend && (
                      <div className='pr-1'>
                        <RecommendIcon size='18' />
                      </div>
                    )}
                    {task.temporary && (
                      <div className='pr-1'>
                        <TemporaryIcon size='18' />
                      </div>
                    )}
                    {task.suspend && (
                      <div>
                        <SaleBanIcon size='18' />
                      </div>
                    )}
                  </BoxDataStyle>
                  <BoxDataStyle
                    flex='1 0 55%'
                    width='55%'
                    justifyContent='flex-end'
                  >
                    {task.headGroupId === 1 ? (
                      task.timePrices.map(({ time, price }, index) => (
                        <BoxText key={index}>
                          <TextLabel
                            fontWeight='500'
                            fontSize='0.8rem'
                            color='#98A1B2'
                          >{`${time} นาที`}</TextLabel>
                          <TextLabel fontWeight='500'>{`${utils.formatNumber(
                            price || 0
                          )}`}</TextLabel>
                        </BoxText>
                      ))
                    ) : (
                      <BoxText>
                        <TextLabel fontWeight='500'>{`${utils.formatNumber(
                          task.price || 0
                        )}`}</TextLabel>
                      </BoxText>
                    )}
                  </BoxDataStyle>
                </BoxData>
              </div>
              <BoxEdit onClick={e => onDropDownServiceEditClick(e, task._id)}>
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div className='mr-1' key={index}>
                      <Circle></Circle>
                    </div>
                  ))}
                </div>
                {activeServiceEdit === task._id && (
                  <DropDown
                  // onClick={onDropDownClick.bind(this)}
                  >
                    <Option onClick={e => onEditServiceClick(e, task._id)}>
                      แก้ไข
                    </Option>
                    <Option onClick={e => onDeleteServiceClick(e, task._id)}>
                      ลบ
                    </Option>
                  </DropDown>
                )}
              </BoxEdit>
            </Box>
          </Container>
        )
      }}
    </Draggable>
  )
}

export default ServiceDrag
