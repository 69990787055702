import React from 'react'
import styled from 'styled-components'

import Label from '../../atoms/Label'

const Container = styled.div`
  display: flex;
  gap: 0.25rem;
`

const LabelBox = styled.div<{ background?: string; opacity?: number | string }>`
  width: 12px;
  height: 12px;
  background: ${props => props.background};
  border-radius: 2px;
  opacity: ${props => props.opacity || 'none'};
`

export interface LegendItemProps {
  name: string
  color: string
  opacity: number | string
}

const LegendItem = ({ name, color, opacity }: LegendItemProps) => {
  return (
    <Container>
      <LabelBox background={color} opacity={opacity} />
      <Label size='0.75rem' lineCap>
        {name}
      </Label>
    </Container>
  )
}

export default LegendItem
