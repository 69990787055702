import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Body from './Body'

import Page from '../../lib/layout/Page/Page'
import { thunk_action_check_staff_queue } from '../../redux/actions/staffQueueAction'

const Main = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(thunk_action_check_staff_queue())
  }, [])

  return (
    <Page>
      <Body />
    </Page>
  )
}

export default Main
