import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'

import api from '../../api'
import Body from './Body'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  const [staffs, setStaffs] = useState([])
  const [staffTypes, setStaffTypes] = useState([])
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [activeMenu, setActiveMenu] = useState('ค่ามือค้างชำระ')

  useEffect(() => {
    callApiStaffType()
  }, [])

  const callApiStaffType = async () => {
    try {
      const { data } = await api.getStaffType()
      setStaffTypes(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (activeMenu === 'ค่ามือค้างชำระ') {
      callApiStaffCommissionOverdue()
    } else {
      callApiStaffCommissionOverdueAndDate()
    }
  }, [activeMenu, date])

  const callApiStaffCommissionOverdue = async () => {
    try {
      const { success, data } = await api.getStaffCommissionByOverdue()
      if (success) {
        setStaffs(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiStaffCommissionOverdueAndDate = async () => {
    try {
      const { success, data } = await api.getStaffCommissionByOverdueAndDate(
        date.startDate,
        date.endDate
      )
      if (success) {
        setStaffs(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onDateStartChange = _date => {
    if (_date) {
      if (dayjs(_date).isAfter(date.endDate, 'date')) {
        setDate({ startDate: _date, endDate: _date })
      } else if (dayjs(_date).isSameOrBefore(new Date(), 'date')) {
        setDate({ ...date, startDate: _date })
      }
    }
  }

  const onDateEndChange = _date => {
    if (_date) {
      if (
        dayjs(_date).isSameOrBefore(new Date(), 'date') &&
        dayjs(_date).isSameOrAfter(date.startDate, 'date')
      ) {
        setDate({ ...date, endDate: _date })
      } else {
        if ((dayjs(_date).isSameOrBefore(date.startDate), 'date')) {
          setDate({ startDate: _date, endDate: _date })
        }
      }
    }
  }

  const onMenuClick = menu => {
    setActiveMenu(menu)
    setDate({ startDate: new Date(), endDate: new Date() })
  }

  const updateStaffs = async success => {
    try {
      if (success)
        if (activeMenu === 'ค่ามือค้างชำระ') {
          await callApiStaffCommissionOverdue()
        } else {
          await callApiStaffCommissionOverdueAndDate()
        }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Page>
      <Body
        staffTypes={staffTypes}
        staffs={staffs}
        activeMenu={activeMenu}
        date={date}
        updateStaffs={updateStaffs}
        onDateEndChange={onDateEndChange}
        onDateStartChange={onDateStartChange}
        onMenuClick={onMenuClick}
      />
    </Page>
  )
}

export default Main
