import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import styled, { CSSProperties } from 'styled-components'

//@ts-ignore
import Button2 from '../../atoms/Button2'
import InputText from '../../atoms/InputText'
import TextLabel from '../../atoms/TextLabel'

//@ts-ignore
import api from '../../api'
import { BookingHistory } from '../../redux/models/BookingHistory'
//@ts-ignore
import utils from '../../utils'
import { Order } from '@/redux/models/BookingHistory'
import { room, SpaCardProps } from './types'
import { ITableData } from '@/components/home/Schedule/useTable'
import StaffSpaCard from './StaffSpaCard'
import { Customer } from '@/redux/models/Customer'

export interface SpaCardStyled extends CSSProperties {}

const Container = styled.div`
  height: 100%;
`
const Card = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 105mm;

  @page {
    size: 85mm 276mm;
  }
  @media print {
    body {
      width: 105mm;
      -webkit-print-color-adjust: exact;
      color: black;
    }
  }
`

const FlexBox = styled.div<SpaCardStyled>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  gap: 0.5rem;
  width: 100%;
  align-items: ${props => props.alignItems || 'flex-start'};
`

const TextSpace = styled.div`
  width: 100%;
  border-bottom: 2px dotted #000;
`

const SpaCard = ({ bookingHistory }: SpaCardProps) => {
  const componentRefs = useRef<(HTMLDivElement | null)[]>([])
  const [customers, setCustomers] = useState<any[]>([])

  // useEffect(() => {
  //   setData()
  // }, [bookingHistory])

  // const setData = async () => {
  //   try {
  //     let customers: BodyCustomer[] = []
  //     for (let i = 0; i < bookingHistory.customers.length; i++) {
  //       const { orders, name } = bookingHistory.customers[i]

  //       // const { data: findRoom } = await api.getRoomById(roomId || -1)

  //       const serviceTime = orders.reduce((a, i) => (a += i.time), 0)
  //       const _endTime =
  //         utils.convertTime(
  //           bookingHistory.statusId === 3 || bookingHistory.statusId === 4
  //             ? bookingHistory.startTime
  //             : bookingHistory.time
  //         ) + serviceTime

  //       let roomStartTime = utils.convertTime(
  //         bookingHistory.statusId === 3 || bookingHistory.statusId === 4
  //           ? bookingHistory.startTime
  //           : bookingHistory.time
  //       )

  //       let orderRooms: room[] = []

  //       for (const order of orders) {
  //         const { rooms, staffs } = order

  //         for (const room of rooms) {
  //           orderRooms.push({
  //             detail: room.detail || '',
  //             roomTypeName: room.roomTypeName,
  //             roomName: room.roomName,
  //             startTime: roomStartTime,
  //             endTime: roomStartTime + (room.duration || order.time),
  //           })

  //           roomStartTime += room.duration || order.time
  //         }

  //         for (const staff of staffs) {
  //           customers.push({
  //             customerName: name,
  //             rooms: orderRooms,
  //             staffName: staff.staffName,
  //             service: orders.map(o => o.service + ' ' + o.time).join(', '),
  //             services: orders,
  //             serviceTime: serviceTime,
  //             startTime:
  //               bookingHistory.statusId === 3 || bookingHistory.statusId === 4
  //                 ? bookingHistory.startTime
  //                 : bookingHistory.time,
  //             endTime: {
  //               hour: Math.floor(_endTime / 60) + '',
  //               minute: (_endTime % 60) + '',
  //             },
  //             oil: '',
  //             focus: '',
  //             avoid: '',
  //           })
  //         }
  //       }

  //       // customers.push({
  //       //   customerName: name,
  //       //   roomTypeName: roomTypeName ? roomTypeName : '',
  //       //   room: findRoom.length ? findRoom[0].roomName : '',
  //       //   staffName: orders[0].staffName,
  //       //   service: orders.map(o => o.service + ' ' + o.time).join(', '),
  //       //   serviceTime: serviceTime,
  //       //   startTime:
  //       //     bookingHistory.statusId === 3 || bookingHistory.statusId === 4
  //       //       ? bookingHistory.startTime
  //       //       : bookingHistory.time,
  //       //   endTime: {
  //       //     hour: Math.floor(_endTime / 60) + '',
  //       //     minute: (_endTime % 60) + '',
  //       //   },
  //       //   oil: '',
  //       //   focus: '',
  //       //   avoid: '',
  //       // })
  //     }
  //     setCustomers(customers)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  // const onChange = (name: string, value: string, index: number) => {
  //   const newArrCustomer = [...customers]
  //   newArrCustomer[index] = { ...newArrCustomer[index], [name]: value }

  //   setCustomers(newArrCustomer)
  // }

  const orderPerStaffs = useMemo(() => {
    let result = new Object() as {
      [key: number]: {
        order: Order;
        customer: Customer;
      }[]
    }
    for(let customer of bookingHistory.customers) {
      for(let order of customer.orders) {
        for(let staff of order.staffs) {
          if(result[staff.staffId]) {
            result[staff.staffId].push({
              order: order,
              customer: customer
            })
          } else {
            result[staff.staffId] = [{
              order: order,
              customer: customer
            }]
          }
        }
      }
    }
    return result
  }, [bookingHistory])

  return(
    <Container>
      {
        Object.keys(orderPerStaffs).map((staffId, index) => {
          const id = parseInt(staffId)
          return (
            <StaffSpaCard
              booking={bookingHistory}
              key={index}
              orders={orderPerStaffs[id]}
              staffId={id}
            />
          )
        })
      }
    </Container>
  )
}

export default SpaCard
