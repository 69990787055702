import { GET_STAFF } from '../actions/callApiStaffAction'

const initState = {}

const callApiStaffReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_STAFF:
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

export default callApiStaffReducers
