import { Row } from '@/components/atoms/Flex';
import React from 'react'
import DragItem from './Column';
import styled from 'styled-components';
import Button from '@/components/atoms/Button';
import { IDndGroup } from './type';
import DropdownButton from '@/components/atoms/DropdownButton';
import { FaChevronDown } from "react-icons/fa";
import { HiOutlineDotsHorizontal } from 'react-icons/hi';

export interface DNDGroupProps {
    group: string
    items: any[]
    groupMapping: IDndGroup[]
    className?: string
    isDragging?: any
    actions?: React.ReactNode[]
}

const StyledChevronDown = styled(FaChevronDown)<{
  transform: string
}>`
  transform: ${({ transform }) => transform ? transform : 'rotate(0deg)'};
  transition: transform 0.3s;
  width: 12px;
  height: 12px;
`;

export const StyledGroup = styled.div<{
  isDragging?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  background-color: var(--popup);
  padding: 1rem;
  border-radius: var(--radius);
  border: 2px dashed transparent;
  :hover {
    border: ${({ isDragging }) => isDragging ? '2px dashed var(--success)' : '2px dashed transparent'};
  }
`;

export const StyledDndGroupContent = styled.div`

`

export const DNDGroup = (props: DNDGroupProps) => {
    const { group, items, groupMapping, className, isDragging } = props
    const [isCollapsed, setIsCollapsed] = React.useState(false)
    
  return (
    <StyledGroup isDragging={isDragging}>
      <Row justify="space-between" onClick={e => {
            setIsCollapsed(!isCollapsed)
          }}>
        <Row gap='2px'>
        <h5>
          {groupMapping?.find(groupItem => groupItem.id === group)?.label}
        </h5>
        {/* Count */}
        <h6 style={{
            color: 'var(--secondary)'
        }}>{"(" + items.length + ")"}</h6>
        </Row>
        <Row gap='6px'>
          <Button variant="ghost" size='icon' onClick={e => {
            setIsCollapsed(!isCollapsed)
          }}>
            <StyledChevronDown transform={isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)'}/>
          </Button>
          {
            !!props.actions && (
              <DropdownButton>
                <DropdownButton.Trigger>
                  <Button variant="ghost" size="icon">
                    <HiOutlineDotsHorizontal size={12} color='var(--text)' />
                  </Button>
                </DropdownButton.Trigger>
                <DropdownButton.Content>
                {props.actions.map((action, index) => (
                  <DropdownButton.Item key={index}>
                    {action}
                  </DropdownButton.Item>
                ))}
                </DropdownButton.Content>
              </DropdownButton>
            )
          }
        </Row>
      </Row>
      <StyledDndGroupContent className={className}>
      {isCollapsed ? null : <DragItem items={items} id={group}/>}
      </StyledDndGroupContent>
    </StyledGroup>
  )
}
