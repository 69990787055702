import { GET_BRAND } from '../actions/brandAction'

const initState = {
  _id: -1,
  nationalities: [],
  occupations: [],
  nationalityOptions: [],
  occupationOptions: [],
  branches: [],
}

const brandReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_BRAND:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}

export default brandReducer
