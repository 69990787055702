import React, { useState } from 'react'

import Body from './Body'
import Head from './Head'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  const [activeMenu, setActiveMenu] = useState('บริการ')
  const [activeAddData, setActiveAddData] = useState(false)
  const [activeAddGroupData, setActiveAddGroupData] = useState(false)

  const onMenuClick = menu => {
    setActiveAddData(false)
    setActiveAddGroupData(false)
    setActiveMenu(menu)
  }

  const onAddData = () => {
    setActiveAddData(!activeAddData)
    setActiveAddGroupData(false)
  }

  const onAddGroupData = () => {
    setActiveAddGroupData(!activeAddGroupData)
    setActiveAddData(false)
  }

  const handleClose = () => {
    setActiveAddData(false)
    setActiveAddGroupData(false)
  }

  return (
    <Page>
      <Head
        activeMenu={activeMenu}
        onAddData={onAddData}
        onAddGroupData={onAddGroupData}
      />
      <Body
        onMenuClick={onMenuClick}
        activeAddData={activeAddData}
        activeAddGroupData={activeAddGroupData}
        handleClose={handleClose}
      />
    </Page>
  )
}

export default Main
