import { CustomerOrder } from '../../redux/models/BookOrder'

const logicEndTime = {
  checkServiceMaxTime: (customers: CustomerOrder[]) => {
    let max = 0
    let count = 0

    for (const customer of customers) {
      count = 0
      const { services } = customer
      for (const service of services) {
        if (service.serviceId !== -1) {
          count += service.time
        }
      }
      if (max < count) {
        max = count
      }
    }

    return max
  },
}

export default logicEndTime
