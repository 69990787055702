const config = {
  FRONT_URL:
    process.env.REACT_APP_FRONT_URL ||
    {
      development: 'http://localhost:3000',
      test: 'https://test.thaihandpos.com',
      staging: 'https://staging.thaihandpos.com',
      production: 'https://thaihandpos.com',
    }[process.env.REACT_APP_STAGE as string] ||
    'http://localhost:3000',
  API_POS:
    process.env.REACT_APP_API_POS ||
    {
      development: 'http://localhost:4000',
      test: 'https://test-api.thaihandpos.com',
      staging: 'https://staging-api.thaihandpos.com',
      production: 'https://api.thaihandpos.com',
    }[process.env.REACT_APP_STAGE as string] ||
    'http://localhost:4000',
  STATUS: ['booked', 'confirmed', 'ongoing', 'complete', 'arrived', 'cancel'],
  STATUS_COLOR: [
    '#6C63FF',
    '#3438CD',
    '#8C56D9',
    '#32D582',
    '#FC6514',
    '#FEC84A',
  ],
  STATUS_BACKGROUND_COLOR: [
    '#F0EFFF',
    '#EBEBFA',
    '#F3EEFB',
    '#EBFBF3',
    '#FFF0E7',
    '#FFF9ED',
  ],
  PAYOUT_COMMISSION_STATUS: [
    'ค่าจ้างคงที่',
    'จ่ายตามค่ามือ และ มีประกันมือ',
    'จ่ายตามค่ามือปกติ',
  ],
  PAYMENT_PERIOD: ['รายเดือน', 'ราย 2 อาทิต', 'ราย 1 อาทิต', 'ราย 1 วัน'],
  PAYMENT_METHOD: ['โอนเงิน', 'เงินสด'],
  STATUS_STAFF_WORKING: ['หยุดงาน', 'เริ่มงาน', 'เลิกงาน'],
  BANK: [
    'กสิกรไทย',
    'ไทยพานิช',
    'กรุงเทพ',
    'กรุงไทย',
    'ออมสิน',
    'ทหารไทย',
    'กรุงศรีอยุธยา',
    'เกียรตินาคิน',
  ],
  USER_TYPES: [
    'แอดมิน',
    'เจ้าของร้าน',
    'ผู้จัดการ',
    'แคชเชียร์',
    'พนักงานทั่วไป',
  ],
  PAY_TYPES: ['cash', 'creditCard', 'transfer', 'eWallet', 'agency', 'package'],
  AGE: ['น้อยกว่า 18', '18 - 30', '30 - 50', 'มากกว่า 50'],
  PACKAGE_TYPES: [
    'แทนเงินสด',
    'จำนวนครั้งบริการ',
    'ชั่วโมงบริการ',
    'ส่วนลด(%)',
  ],
  MAX_PHONE_NUMBER_LENGTH: 15,
  GENDER: ['ชาย', 'หญิง'],
  TRANSACTION_TYPE: ['service', 'product', 'productAndService', 'package'],
}

export default config
