import { SET_USER, CLEAR_USER, getInitialState } from '../actions/userAction'

const user = (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload.data,
      }
    case CLEAR_USER:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default user
