import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

import TextLabel from '../../atoms/TextLabel'

import CustomerPackage from '../../redux/models/CustomerPackage'
import utils from '../../utils'

interface CardViewEditCustomerPackageStyle extends CSSProperties {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Row = styled.div<CardViewEditCustomerPackageStyle>`
  display: grid;
  grid-template-columns: ${props => props.columns || '1fr 1fr 1fr 1fr 1fr'};
  gap: 0.5rem;
  padding: 0.5rem;
  justify-items: ${props => props.justifyItems || 'unset'};
`

const HeadItem = styled.div`
  text-align: center;
  font-weight: 400;
  color: #98a1b2;
  font-size: 0.875rem;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
interface customerEditPackageState {
  remainingValue: number
  expiryDay: number
  changedDate: Date
  userName: string
}

interface CardViewEditCustomerPackageProps {
  customerEditPackages: customerEditPackageState[]
  currentPackage: CustomerPackage
}

const CardViewEditCustomerPackage = ({
  customerEditPackages,
  currentPackage,
}: CardViewEditCustomerPackageProps) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Row>
        <HeadItem>{t('edit')}</HeadItem>
        <HeadItem>{t('remainingAmount')}</HeadItem>
        <HeadItem>{t('periodOfUsage')}</HeadItem>
        <HeadItem>{t('date')}</HeadItem>
        <HeadItem>{t('editedBy')}</HeadItem>
      </Row>
      {customerEditPackages.map(
        ({ remainingValue, expiryDay, changedDate, userName }, index) => (
          <Row key={index}>
            <RowItem>
              {remainingValue !== 0 && expiryDay !== 0
                ? 'ยอดคงเหลือ, วันหมดอายุ'
                : remainingValue !== 0
                ? 'ยอดคงเหลือ'
                : 'วันหมดอายุ'}
            </RowItem>
            <RowItem>{remainingValue === 0 ? '' : remainingValue}</RowItem>
            <RowItem>{expiryDay === 0 ? '' : expiryDay}</RowItem>
            <RowItem>{utils.formatDate(changedDate, 'DD/MM/YYYY')}</RowItem>
            <RowItem>{userName}</RowItem>
          </Row>
        )
      )}
      {currentPackage.type !== 4 && (
        <TextLabel paddingLeft='0.85rem' fontWeight='500'>{`${t(
          'currentRemainingAmount'
        )}: ${utils.formatNumber(currentPackage.remainingValue)}`}</TextLabel>
      )}
      <TextLabel paddingLeft='0.85rem' fontWeight='500'>{`${t(
        'currentPeriodOfUsage'
      )}: ${utils.formatNumber(currentPackage.expiryDay)}`}</TextLabel>
    </Container>
  )
}

export default CardViewEditCustomerPackage
