import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getOrderBooking } from '../../../redux/actions/bookingHistoryAction'
import { ProductOrder } from '../../../redux/models/BookOrder'
import { Product } from '../../../redux/models/Product'

const useCardMenuProduct = () => {
  const dispatch = useDispatch()

  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { products } = bookingOrderState
  const [activeProduct, setActiveProduct] = useState<number>(-1)

  const onProductClick = async (product: Product) => {
    try {
      const newArrProduct = [...products]
      const findProductIndex = newArrProduct.findIndex(
        (p: ProductOrder) => p._id === product._id
      )
      if (findProductIndex === -1) {
        newArrProduct.push({
          _id: product._id,
          name: product.name,
          image: product.image,
          price: product.price,
          quantity: 1,
        })
      } else {
        newArrProduct[findProductIndex].quantity += 1
      }

      setActiveProduct(product._id)

      dispatch(
        getOrderBooking({
          products: newArrProduct,
          productCount: bookingOrderState.productCount + 1,
          total: bookingOrderState.total + product.price,
        })
      )

      setTimeout(() => {
        setActiveProduct(-1)
      }, 500)
    } catch (err) {
      console.log(err)
    }
  }

  return { activeProduct, onProductClick }
}

export default useCardMenuProduct
