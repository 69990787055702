import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

//@ts-ignore
import Button2 from '../../atoms/Button2'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import SuccessIcon2 from '../../atoms/SuccessIcon2'

interface PopupConfirmStyle extends CSSProperties {}

const Text = styled.div`
  color: #8c56d9;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  white-space: pre-wrap;
`

interface PopupConfirmProps {
  handleClose: () => void
  onConfirmClick: () => void
  text?: string
  success?: boolean
  icon?: React.ReactNode
  selectStart?: () => void
}

const PopupConfirm = ({
  handleClose,
  onConfirmClick,
  text = '',
  success = false,
  icon = '',
  selectStart,
}: PopupConfirmProps) => {
  const { t } = useTranslation()

  return (
    <PopupBox width='100%' height='100%' left='0' top='0px'>
      <PopupInsideBox width='30%' height='auto' marginTop='0' padding='1rem'>
        {icon && <div className='mb-2'>{icon}</div>}
        <Text color='#8C56D9' style={{ textAlign: 'center' }}>
          {text}
        </Text>
        <div className='mt-2' style={{ display: 'flex', width: '100%' }}>
          <div className='pr-1' style={{ width: '50%' }}>
            <Button2
              onClick={handleClose}
              border='2px solid #E4E8EB'
              backgroundColorHover='#E5E7EC'
              color='#98A1B2'
            >
              {t('cancel')}
            </Button2>
          </div>
          <div className='pl-1' style={{ width: '50%' }}>
            <Button2
              color='white'
              backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
              onClick={() => onConfirmClick()}
            >
              {t('confirm')}
            </Button2>
          </div>
        </div>
        {success && <SuccessIcon2 position='absolute' />}
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupConfirm
