import { GET_STORE } from '../actions/storeAction'
import { getInitialStore, StoreState } from '../models/Store'

const initState: StoreState = {
  ...getInitialStore(),
  notifications: [],
  notificationCount: 0,
}

interface StoreAction {
  type: string
  payload: {
    data: Partial<StoreState>
  }
}

const storeReducer = (
  state: StoreState = initState,
  action: StoreAction
): StoreState => {
  switch (action.type) {
    case GET_STORE:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}

export default storeReducer
