import {
  CHANGE_SEARCH,
  SET_EXPAND,
  TOGGLE_EXPAND,
  TOGGLE_NOTIFICATION,
  CLEAR_SEARCH,
} from '../actions/pageAction'

const initState = {
  search: '',
  isNotificationOpen: false,
  isExpanded: false,
  menuWidth: '72px',
}

const getMenuWidth = isExpanded => (isExpanded ? '136px' : '72px')

const pageReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_EXPAND: {
      const isExpanded = action.payload
      return { ...state, isExpanded, menuWidth: getMenuWidth(isExpanded) }
    }
    case TOGGLE_EXPAND: {
      const isExpanded = !state.isExpanded
      return { ...state, isExpanded, menuWidth: getMenuWidth(isExpanded) }
    }
    case CHANGE_SEARCH: {
      return { ...state, search: action.payload }
    }
    case TOGGLE_NOTIFICATION: {
      return { ...state, isNotificationOpen: !state.isNotificationOpen }
    }
    case CLEAR_SEARCH: {
      return { ...state, search: '' }
    }
    default:
      return state
  }
}

export default pageReducer
