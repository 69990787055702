import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

// @ts-ignore
import Arrow from '../../../atoms/Arrow'
// @ts-ignore
import LogoutIcon from '../../../atoms/LogoutIcon'
// @ts-ignore
import TextLabel from '../../../atoms/TextLabel'

import CashierIcon from '../../../lib/icons/CashierIcon'
import CustomerIcon from '../../../lib/icons/CustomerIcon'
import HomeIcon from '../../../lib/icons/HomeIcon'
import PayWageIcon from '../../../lib/icons/PayWageIcon'
import QueueIcon from '../../../lib/icons/QueueIcon'
import ReportMenuIcon from '../../../lib/icons/ReportMenuIcon'
import SettingIcon from '../../../lib/icons/SettingIcon'
import StaffIcon2 from '../../../lib/icons/StaffIcon2'
import ThaihandLogo from '../../../lib/icons/ThaihandLogo'

// @ts-ignore
import auth from '../../../auth'
import usePage from '../../../lib/layout/Page/usePage'
// @ts-ignore
import { clearOrderBooking } from '../../../redux/actions/bookingHistoryAction'
import { clearSearch } from '../../../redux/actions/pageAction'
// @ts-ignore
import { clearPayPackage } from '../../../redux/actions/payPackageActions'
// @ts-ignore
import { getCurrentStoreRole, UserTypes } from '../../../redux/models/User'
import {
  CASHIER_URL,
  CUSTOMERS_URL,
  DISABLED_ALL_URLS,
  HOME_URL,
  LOGIN_URL,
  PAY_WAGE_URL,
  REPORT_URL,
  SEARCH_HISTORY_URL,
  SETTING_BILL_URL,
  SETTING_BOOKING_TYPE_URL,
  SETTING_CUSTOMER_URL,
  SETTING_PACKAGE_URL,
  SETTING_PRODUCT_URL,
  SETTING_PROMOTION_URL,
  SETTING_ROLE_URL,
  SETTING_ROOM_URL,
  SETTING_STAFF_URL,
  SETTING_STORE_URL,
  STAFF_URL,
} from '../../../routes'

const Container = styled.div<any>`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: ${props => props.width || '72px'};
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  padding: 1rem 0.75rem 0.75rem 0.75rem;
  position: relative;
  transition: all 0.25s ease-in-out;
`

export const NavItemContainer = styled.div<any>`
  background: ${props => props.background || 'white'};
  border: ${props => props.border || '1px solid #FFF'};
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  justify-content: ${props => (props.isExpanded ? 'unset' : 'center')};
  align-items: center;
  margin: ${props => props.margin || '0'};
  width: ${props => (props.isExpanded ? '112px' : '48px')};
  height: 48px;
  padding-left: ${props => (props.isExpanded ? '14px' : '0')};
  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background: ${props =>
          (props as any).backgroundHover ||
          'linear-gradient(221.86deg, #8c56d9 2.71%, #53389e 97.79%)'};
        color: #ffffff;
        & svg {
          fill: #ffffff;
          & path {
            fill: #ffffff;
            color: #ffffff;
          }
        }
        & span {
          color: #ffffff;
        }
      }
    `}
  cursor: pointer;
`

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Circle = styled.div`
  background: #ffffff;
  border: 1px solid #e4e8eb;
  transform: rotate(-180deg);
  position: absolute;
  border-radius: 50%;
  z-index: 20;
  width: 25px;
  height: 25px;
  top: 30px;
  right: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubNavContainer = styled.div`
  background: #f9f7fd;
  border-radius: 0px 0px 8px 8px;
  /* margin-top: -0.115rem; */
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  line-height: 2.2;
`

export const TextSub = styled(Link)<{ active?: boolean; disabled?: boolean }>`
  font-size: 0.875rem;
  color: ${props =>
    props.disabled ? '#98A1B2' : props.active ? '#8C56D9' : '#475467'};
  background-color: ${props => (props.active ? '#f3eefb' : 'none')};
  padding-left: 0.5rem;
  border-radius: 4px;
`

const StyledLink = styled(Link)`
  text-decoration: none !important;
`

interface SubNavItemProps {
  pathname: string
  name: string
}

const SubNavItem = ({ pathname, name }: SubNavItemProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { storeId } = useSelector((state: any) => state.user)

  const active = pathname === location.pathname
  const disabled = storeId === 0 && DISABLED_ALL_URLS.includes(pathname)

  return (
    <TextSub
      to={{ pathname }}
      active={active}
      disabled={disabled}
      onClick={e => {
        if (disabled) {
          return e.preventDefault()
        }
        dispatch(clearSearch())
      }}
    >
      {name}
    </TextSub>
  )
}

interface NavItemProps {
  name: string
  pathname: string
  icon?: React.ReactElement
  disabled?: boolean
  children?: React.ReactElement
  onClick?: (e: React.ChangeEvent) => void
}

const NavItem = ({
  name,
  pathname,
  icon,
  disabled: _disabled = false,
  children,
  onClick,
}: NavItemProps) => {
  const location = useLocation()
  const { isExpanded } = usePage()
  const { storeId } = useSelector((state: any) => state.user)

  const paths =
    children?.props?.children?.map(
      (v: React.ReactElement) => v.props.pathname
    ) || []
  const active =
    pathname === location.pathname || paths.includes(location.pathname)
  const disabled =
    _disabled || (storeId === 0 && DISABLED_ALL_URLS.includes(pathname))

  return (
    <>
      <StyledLink
        to={{ pathname }}
        onClick={e => disabled && e.preventDefault()}
      >
        <NavItemContainer
          onClick={onClick}
          border={active ? '1px solid #8C56D9' : '1px solid #FFF'}
          disabled={disabled}
          isExpanded={isExpanded}
        >
          {icon &&
            React.cloneElement(icon, {
              color: disabled ? '#98A1B2' : active ? '#8C56D9' : '#475467',
              size: 20,
            })}
          {isExpanded && (
            <TextLabel
              color={disabled ? '#98A1B2' : active ? '#8C56D9' : '#475467'}
              paddingLeft='0.45rem'
              fontSize='0.875rem'
              fontWeight={disabled ? '' : active ? '600' : ''}
            >
              {name}
            </TextLabel>
          )}
        </NavItemContainer>
      </StyledLink>
      {isExpanded && active && children}
    </>
  )
}

const SideNavBar = () => {
  const location = useLocation()
  const history = useHistory()
  const { menuWidth, isExpanded, onExpand } = usePage()

  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.user)

  const role = getCurrentStoreRole(user)

  const { i18n, t } = useTranslation()

  useEffect(() => {
    if (location.pathname === '/') {
      onExpand(false)
    } else if (location.pathname.startsWith('/setting')) {
      onExpand(true)
    }

    // if (location.pathname !== REPORT_URL) {
    //   i18n.changeLanguage('th')
    //   dayjs.locale('th')
    // }
  }, [])

  const onMenuClick = () => {
    dispatch(clearSearch())
    dispatch(clearOrderBooking())
    dispatch(clearPayPackage())
  }

  const onLogoutClick = () => {
    history.push(LOGIN_URL)
    auth.logout()
  }

  const onHomeClick = () => {
    onMenuClick()
  }

  return (
    <Container width={menuWidth}>
      <Circle
        onClick={() => {
          onExpand()
        }}
      >
        <div>
          <Arrow transform={isExpanded ? 'rotate(-45deg)' : ''}></Arrow>
        </div>
      </Circle>
      <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <NavItemContainer
          onClick={() => onHomeClick()}
          margin={isExpanded ? 'auto auto 1rem auto' : '0 0 1rem 0'}
        >
          <Link to={HOME_URL}>
            <ThaihandLogo size='50' />
          </Link>
        </NavItemContainer>
        <NavContainer>
          <NavItem
            name={t('home')}
            pathname={HOME_URL}
            icon={<HomeIcon />}
            onClick={onHomeClick}
          />
          <NavItem
            name={t('history')}
            pathname={SEARCH_HISTORY_URL}
            icon={<QueueIcon />}
            onClick={() => onMenuClick()}
          />
          <NavItem
            name={t('cashier')}
            pathname={CASHIER_URL}
            icon={<CashierIcon />}
            onClick={() => onMenuClick()}
          />
          <NavItem
            name={t('attendance')}
            pathname={STAFF_URL}
            icon={<StaffIcon2 />}
            onClick={() => onMenuClick()}
          />
          <NavItem
            name={t('wage')}
            pathname={PAY_WAGE_URL}
            icon={<PayWageIcon />}
            disabled={role === UserTypes.General}
            onClick={(e: any) =>
              role === UserTypes.General ? e.preventDefault() : onMenuClick()
            }
          />
          <NavItem
            name={t('report')}
            pathname={REPORT_URL}
            icon={<ReportMenuIcon />}
            onClick={() => onMenuClick()}
          />
          <NavItem
            name={t('customer')}
            pathname={CUSTOMERS_URL}
            icon={<CustomerIcon />}
            onClick={() => onMenuClick()}
          />
          <NavItem
            name={t('setting')}
            pathname={SETTING_STORE_URL}
            icon={<SettingIcon />}
            onClick={() => onMenuClick()}
          >
            <SubNavContainer>
              <SubNavItem name='ข้อมูลร้าน' pathname={SETTING_STORE_URL} />
              <SubNavItem name='บริการ' pathname={SETTING_PRODUCT_URL} />
              <SubNavItem name='โปรโมชั่น' pathname={SETTING_PROMOTION_URL} />
              <SubNavItem name='แพ็คเกจ' pathname={SETTING_PACKAGE_URL} />
              <SubNavItem name='พนักงาน' pathname={SETTING_STAFF_URL} />
              <SubNavItem name='ห้องนวด' pathname={SETTING_ROOM_URL} />
              <SubNavItem name='ลูกค้า' pathname={SETTING_CUSTOMER_URL} />
              <SubNavItem name='ใบเสร็จ' pathname={SETTING_BILL_URL} />
              <SubNavItem name='จอง/จ่าย' pathname={SETTING_BOOKING_TYPE_URL} />
              <SubNavItem name='สิทธิ์เข้าใช้' pathname={SETTING_ROLE_URL} />
            </SubNavContainer>
          </NavItem>
        </NavContainer>
      </div>
      <NavItemContainer
        border='none'
        isExpanded={isExpanded}
        onClick={() => onLogoutClick()}
      >
        <LogoutIcon size='20' />
        {isExpanded && (
          <TextLabel color='#8C56D9' paddingLeft='0.45rem' fontSize='0.875rem'>
            ออกจากระบบ
          </TextLabel>
        )}
      </NavItemContainer>
    </Container>
  )
}

export default SideNavBar
