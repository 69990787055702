import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'

import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react'

interface BaseDropdownProps {
  trigger: ReactNode
  children: ReactNode
  placement?: 'bottom-start' | 'bottom-end' | 'top-start' | 'top-end'
  closeOnClick?: boolean
  className?: string
}

export const StyledDropdownButtonContainer = styled.div`
  height: fit-content;
`

export const StyledDropdownButtonTrigger = styled.div`
  height: 100%;
`

const ContentWrapper = styled.div<{ isOpen: boolean }>`
  background-color: white;
  border: 1px solid var(--input);
  border-radius: var(--radius-xs);
  box-shadow: var(--shadow-sm);
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 1000; // Increased z-index to be above the overlay
  width: fit-content;
  overflow-y: auto;
  position: relative;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1000;
`

const BaseDropdown: React.FC<BaseDropdownProps> = ({
  trigger,
  children,
  placement = 'bottom-start',
  closeOnClick = true,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles, update } = useFloating({
    middleware: [offset(5), flip(), shift()],
    placement,
    whileElementsMounted: autoUpdate,
  })

  const handleToggle = (e: any) => {
    e?.stopPropagation()
    setIsOpen(prev => !prev)
  }
  const handleClose = (e: any) => {
    setIsOpen(false)
    e.stopPropagation()
  }

  return (
    <StyledDropdownButtonContainer className={className}>
      <StyledDropdownButtonTrigger
        ref={refs.setReference}
        onClick={handleToggle}
      >
        {trigger}
      </StyledDropdownButtonTrigger>
      {isOpen && (
        <FloatingPortal>
          <>
            <Overlay onClick={handleClose} />
            <ContentWrapper
              ref={refs.setFloating}
              isOpen={isOpen}
              style={floatingStyles}
              onClick={e => {
                e.stopPropagation() // Prevent clicks from closing immediately
                if (closeOnClick) {
                  handleClose(e)
                }
              }}
            >
              {children}
            </ContentWrapper>
          </>
        </FloatingPortal>
      )}
    </StyledDropdownButtonContainer>
  )
}

export default BaseDropdown
