import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import TextLabel from '../../atoms/TextLabel'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  padding: 0.5rem 1rem;
  flex: 1;
  overflow: hidden;
`

const Box = styled.div`
  display: flex;
  border-bottom: 1px solid #e5eced;
  justify-content: space-between;
`

const CardListService = ({ activeStaff }) => {
  const { t } = useTranslation()

  let countRequestStaff = 0
  let countHourRequestStaff = 0
  const listService = activeStaff.date.reduce((acc, info) => {
    const { staffCommissions, requestStaffCount, hourRequest } = info
    countRequestStaff += requestStaffCount
    countHourRequestStaff += hourRequest
    for (const staffCommission of staffCommissions) {
      const { serviceId, service, serviceTime } = staffCommission
      const accFindIndex = acc.findIndex(
        a => a.serviceId === serviceId && a.serviceTime === serviceTime
      )

      if (accFindIndex === -1) {
        acc.push({ serviceId, service, serviceTime, count: 1 })
      } else {
        acc[accFindIndex].count += 1
      }
    }

    return acc
  }, [])

  return (
    <Container>
      <Card>
        <TextLabel fontSize='1.05rem' fontWeight='500'>
          {t('priceByService')}
        </TextLabel>
        <Scrollbar>
          {!!listService.length &&
            listService.map(
              ({ serviceId, service, serviceTime, count }, idx) => (
                <Box key={idx}>
                  <TextLabel>{`${service} ${serviceTime} นาที`}</TextLabel>
                  <TextLabel>{`${count} ครั้ง`}</TextLabel>
                </Box>
              )
            )}
          {countRequestStaff !== 0 && (
            <Box>
              <TextLabel>{t('request')}</TextLabel>
              <TextLabel>{`${countRequestStaff} ครั้ง / ${countHourRequestStaff} นาที`}</TextLabel>
            </Box>
          )}
        </Scrollbar>
      </Card>
    </Container>
  )
}

export default CardListService
