import { CLEAR_SERVICE, GET_SERVICE } from '../actions/dataServiceAction'

const initState = {
  groupName: '',
  groupId: '',
  Id: '',
  name: '',
  image: '',
  description: '',
  time: [],
  price: [],
  choice: [{ options: '', price: '' }],
  prepareTime: { before: '', after: '' },
  recommend: false,
  temporary: false,
  suspend: false,
  storeId: '',
}

const dataServiceReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_SERVICE:
      return {
        // ...state,
        service: action.payload.service,
      }
    case CLEAR_SERVICE:
      return {
        ...initState,
      }

    default:
      return state
  }
}

export default dataServiceReducers
