import { GET_STAFF_COST } from '../actions/dataStaffCostAction'

const initState = {
  id: '',
  type: '',
  name: '',
  priceTotal: '0.00',
  salary: '',
  pay: '',
  work: '',
  active: false,
}

const dataStaffCostReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_STAFF_COST:
      return {
        ...state,
        ...action.payload.staff,
      }
    default:
      return state
  }
}

export default dataStaffCostReducers
