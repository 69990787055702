import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'

import api from '../../api'
import BottleIcon from '../../atoms/BottleIcon'
import ServiceIcon2 from '../../atoms/ServiceIcon2'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  width: calc(100% - 1rem);
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #e4e8eb;
`
const BoxMenu = styled.div`
  display: flex;
  width: 100%;
`
const BoxText = styled.div`
  border-bottom: ${props => (props.active ? '1px solid #8C56D9' : '')};
  color: ${props => (props.active ? '#8C56D9' : '#475467')};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: ${props => props.marginLeft || '0'};
  font-weight: 500;
`
const BoxImage = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 60%;
  margin: 0 auto;
`
const ImageStyle = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  aspect-ratio: 1;
  object-fit: cover;
`
const ImageStyle2 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #e4e8eb;
  aspect-ratio: 1;
`

const ContainerCircleStyle = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: wrap;
  padding: ${props => props.padding || '0.25rem 0 0.25rem 0.1rem'};
  height: fit-content;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
`
const BoxCircle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 33%'};
  max-width: ${props => props.width || '33%'};
  margin: ${props => props.margin || '0.25rem 0 0 0'};
`
const Circle = styled.div`
  background: #98a1b2;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`

const BodyArrangement = () => {
  const [activeMenu, setActiveMenu] = useState(0)
  const [services, setServices] = useState([])
  const [products, setProducts] = useState([])

  useEffect(() => {
    callApiServices()
    callApiProducts()
  }, [])

  const callApiServices = async () => {
    try {
      const { data, success } = await api.getServices()
      if (success) {
        setServices(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiProducts = async () => {
    try {
      const { data, success } = await api.getProduct()
      if (success) {
        setProducts(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (activeMenu === 0) {
      await setServices(arrayMove(services, oldIndex, newIndex))
      await callApiUpdateService(arrayMove(services, oldIndex, newIndex))
    } else {
      await setProducts(arrayMove(products, oldIndex, newIndex))
      await callApiUpdateProduct(arrayMove(products, oldIndex, newIndex))
    }
  }

  const callApiUpdateService = async services => {
    try {
      let _services = services.reduce((acc, info, i) => {
        acc.push({ ...info, number: i + 1 })
        return acc
      }, [])
      const { success } = await api.updateAllService(_services)
      if (success) {
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateProduct = async products => {
    try {
      let _products = products.reduce((acc, info, i) => {
        acc.push({ ...info, number: i + 1 })
        return acc
      }, [])
      const { success } = await api.updateAllProduct(_products)
      if (success) {
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Scrollbar id='setting-BodyArrangement'>
        <BoxImage>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              headGroupId={value.headGroupId}
              image={value.image}
              value={value}
            />
          ))}
        </BoxImage>
      </Scrollbar>
    )
  })

  const SortableItem = SortableElement(
    ({ image, index, value, headGroupId }) => (
      <div
        key={index}
        style={{
          flex: '1 0 33%',
          maxWidth: '33%',
          padding: '10px 10px',
        }}
      >
        <div
          style={{
            borderRadius: '8px',
            background: '#ffffff',
            padding: '0.5rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <div style={{ width: '100%', position: 'relative' }}>
            {image ? <ImageStyle src={image} /> : <ImageStyle2></ImageStyle2>}
            <div
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                cursor: 'pointer',
                background: '#FFFFFF',
                borderRadius: '8px',
                zIndex: '1',
              }}
            >
              <ContainerCircleStyle>
                {Array.from({ length: 9 }).map((_, index) => (
                  <BoxCircle key={index}>
                    <Circle></Circle>
                  </BoxCircle>
                ))}
              </ContainerCircleStyle>
            </div>
          </div>
          {value.name}
        </div>
      </div>
      // </DragHandle>
    )
  )

  return (
    <Container>
      <BoxMenu>
        <BoxText active={activeMenu === 0} onClick={() => setActiveMenu(0)}>
          <ServiceIcon2
            size='13'
            color={activeMenu === 0 ? '#8C56D9' : '#475467'}
          />
          <div className='ml-1'>บริการ</div>
        </BoxText>
        <BoxText
          marginLeft='0.8rem'
          active={activeMenu === 1}
          onClick={() => setActiveMenu(1)}
        >
          <BottleIcon
            size='13'
            color={activeMenu === 1 ? '#8C56D9' : '#475467'}
          />
          สินค้า
        </BoxText>
      </BoxMenu>
      <Line></Line>

      <SortableList
        items={activeMenu === 0 ? services : products}
        onSortEnd={onSortEnd}
        distance={1}
        axis='xy'
        // helperClass='SortableHelper'
        // useDragHandle
      ></SortableList>
    </Container>
  )
}

export default BodyArrangement
