import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`
const TimeBox = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1% 1fr;
`
const TimeButton = styled.div`
  border: 1px solid #f8f9fb;
  color: ${props => props.color || '#98A1B2'};
  background: ${props => props.backgroundColor || '#FFFFFF'};
  border-radius: 4px;
  text-align: center;
  padding: 0.125rem 0.75rem;
  cursor: pointer;
  :hover {
    background: linear-gradient(179.6deg, #8c56d9 0.05%, #53389e 99.66%);
    color: #ffffff;
  }
`
const Box = styled.div`
  border: 1px solid #98a1b2;
  border-radius: 4px;
  font-weight: 500px;
  text-align: center;
  padding: 0 0.5rem;
  width: fit-content;
  font-size: 1.25rem;
`

const Time3 = ({
  hourNow,
  minuteNow,
  timeActive,
  start,
  close,
  previewTime = true,
}) => {
  const [hours, setHours] = useState([])
  const minutes = Array.from({ length: 12 }).map((_, index) => index * 5)
  const [hourActiveIndex, setHourActiveIndex] = useState('')
  const [minuteActiveIndex, setMinuteActiveIndex] = useState('')

  useEffect(() => {
    let hours = []
    if (!hours.length) {
      let _close =
        parseInt(close) < parseInt(start) ? parseInt(close) + 24 : close

      for (let i = start; i <= _close; i++) {
        hours.push(i > 23 ? i - 24 : i)
      }
      const findHourIndex = hours.findIndex(
        h => h + '' === (hourNow === 24 ? 0 : hourNow) + ''
      )
      if (findHourIndex !== -1) {
        setHourActiveIndex(findHourIndex)
      }
      const minute = parseInt(minuteNow / 5) * 5
      const findMinuteIndex = minutes.findIndex(m => m === minute)
      if (findMinuteIndex !== -1) {
        setMinuteActiveIndex(findMinuteIndex)
      }
      setHours(hours)
    }
  }, [hourNow, minuteNow])

  return (
    <Container>
      {previewTime && (
        <Box>
          <TextLabel>{`${utils.formatTime(
            hours[hourActiveIndex]
          )} : ${utils.formatTime(minutes[minuteActiveIndex])} น.`}</TextLabel>
        </Box>
      )}
      <TimeBox>
        <div>
          <TextLabel>ชั่วโมง</TextLabel>
          <div
            className='mt-2'
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: '0.5rem',
            }}
          >
            {hours.map((h, index) => (
              <TimeButton
                backgroundColor={
                  hourActiveIndex === index
                    ? 'linear-gradient(179.6deg, #8c56d9 0.05%, #53389e 99.66%)'
                    : ''
                }
                color={hourActiveIndex === index ? '#fff' : ''}
                onClick={() => {
                  setHourActiveIndex(index)
                  timeActive(h, minutes[minuteActiveIndex], 'hour')
                }}
              >
                {utils.formatTime(h)}
              </TimeButton>
            ))}
          </div>
        </div>
        <div style={{ border: '1px solid #E4E8EB', width: '1px' }}></div>
        <div>
          <TextLabel>นาที</TextLabel>
          <div
            className='mt-2'
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: '0.5rem',
            }}
          >
            {minutes.map((m, index) => (
              <TimeButton
                backgroundColor={
                  minuteActiveIndex === index
                    ? 'linear-gradient(179.6deg, #8c56d9 0.05%, #53389e 99.66%)'
                    : ''
                }
                color={minuteActiveIndex === index ? '#fff' : ''}
                onClick={() => {
                  setMinuteActiveIndex(index)
                  timeActive(hours[hourActiveIndex], m, 'minute')
                }}
              >
                {utils.formatTime(m)}
              </TimeButton>
            ))}
          </div>
        </div>
      </TimeBox>
    </Container>
  )
}

export default Time3
