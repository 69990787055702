import {
  GET_TYPE_BOOKING,
  UPDATE_PAY,
  UPDATE_COMMISSION,
  UPDATE_BOOKING_CHANNEL,
  CLEAR_TYPE_BOOKING,
} from '../actions/dataTypeBookingAction'

const initState = {
  _id: '',
  bookingTypeId: '',
  bookingChannel: '',
  icon: '',
  payout: {},
  commission: '',
}

const dataTypeBookingReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_TYPE_BOOKING:
      return {
        ...state,
        ...action.payload.typeBooking,
      }

    case UPDATE_PAY:
      return {
        ...state,
        payout: action.payload.payout,
      }
    case UPDATE_COMMISSION:
      return {
        ...state,
        commission: action.payload.commission,
      }
    case UPDATE_BOOKING_CHANNEL:
      return {
        ...state,
        bookingChannel: action.payload.bookingChannel,
      }
    case CLEAR_TYPE_BOOKING:
      return ''

    default:
      return state
  }
}

export default dataTypeBookingReducers
