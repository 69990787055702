import styled from "styled-components";

export const StyledHref = styled.a`
    cursor: pointer;
    color: #8C56D9;
    &:hover {
        text-decoration: underline;
        color: #8C56D9;
        filter: brightness(135%);
    }
`
