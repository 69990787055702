import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//@ts-ignore
import auth from '../../auth'
import { LoginType, postSignIn, SignInRequest } from '../../lib/auth/loginApi'
//@ts-ignore
import { getBrand } from '../../redux/actions/brandAction'
import { getStore } from '../../redux/actions/storeAction'
//@ts-ignore
import { setUser } from '../../redux/actions/userAction'
import { ADMIN_URL, HOME_URL } from '../../routes'

interface LoginState {
  isSelectStoreOpen: boolean
  error: boolean
}

const saveToLocalStorage = (idToSave: number) => {
  const savedValue = localStorage.getItem('id')
  const parsedValue: number | number[] = savedValue
    ? JSON.parse(savedValue)
    : ''

  const loggedInIds: number[] = []
  if (Array.isArray(parsedValue)) {
    loggedInIds.push(...parsedValue)
  } else {
    loggedInIds.push(parsedValue)
  }

  if (!loggedInIds.includes(idToSave)) {
    loggedInIds.push(idToSave)
  }

  localStorage.setItem('id', JSON.stringify(loggedInIds))
}

const useLogin = (typeLogin: LoginType = 'password') => {
  const [state, setState] = useState<LoginState>({
    isSelectStoreOpen: false,
    error: false,
  })

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (auth.isLoggedIn()) {
      history.replace(HOME_URL)
    }
  }, [])

  const _login = async (params: SignInRequest, isRemember: boolean) => {
    try {
      const { user, success, token, stores, brands } = await postSignIn(params)

      if (success) {
        dispatch(setUser({ ...user, stores, brands, storeId: 0, brandId: 0 }))
        const storeId = stores.length > 0 ? stores[0]._id : 0
        const brandId = stores.length > 0 ? brands[0]._id : 0

        const saveState = isRemember || typeLogin === 'pin'

        auth.login(user._id, token, !saveState)
        auth.setStoreId(storeId)
        auth.setBrandId(brandId)

        dispatch(
          getStore({
            ...stores[0],
          })
        )
        dispatch(getBrand(brands.find(brand => brand.storeId === storeId)))

        const [{ typeId }] = user.types
        const isAdmin = typeId === 0

        if (isAdmin) {
          history.replace(ADMIN_URL)
          return
        }

        if (saveState) {
          saveToLocalStorage(user._id)
        }

        if (brands.length > 1) {
          setState(prev => ({ ...prev, isSelectStoreOpen: true }))
        } else {
          dispatch(setUser({ brandId, storeId }))
          return history.replace(HOME_URL)
        }
      } else {
        throw new Error()
      }
    } catch (err) {
      console.log(err)
      setState(prev => ({ ...prev, error: true, pins: [], password: '' }))
    }
  }

  const onSelectStoreClose = () => {
    setState(prev => ({ ...prev, isSelectStoreOpen: false }))
  }

  const loginPassword = (
    username: string,
    password: string,
    isRemember: boolean
  ) => {
    _login(
      {
        phoneNumber: username,
        password: password,
        typeLogin: 'password',
      },
      isRemember
    )
  }

  const loginPin = (username: string, pin: string, isRemember: boolean) => {
    _login(
      {
        phoneNumber: username,
        password: pin,
        typeLogin: 'pin',
      },
      isRemember
    )
  }

  return {
    ...state,
    loginPin,
    loginPassword,
    onSelectStoreClose,
  }
}

export default useLogin
