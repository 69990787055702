import 'swiper/swiper-bundle.min.css'

import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import Checkbox from '@/components/atoms/Checkbox'
import { Row } from '@/components/atoms/Flex'
import { ChevronDown, ChevronRight } from '@/components/atoms/icons/Chevron'
import { PeopleIcon } from '@/components/atoms/icons/PeopleIcon'
import { ServiceIcon } from '@/components/atoms/icons/ServiceIcon'
import typography from '@/styles/typography'

import CardRoom from './CardRoom'
import CardStaff from './CardStaff'
import ShowRoom from './ShowRoom'
import useCardCustomer from './useCardCustomer'

import DeleteIcon3 from '../../../../atoms/DeleteIcon3'
import StaffUnavailableIcon from '../../../../atoms/StaffUnavailableIcon'
import TextLabel from '../../../../atoms/TextLabel'
import CustomerForm from '../../../../molecules/CustomerForm'

import { ServiceOrder } from '../../../../redux/models/BookOrder'
import PopupConfirm from '../../../popups/PopupConfirm'
import { RoomOrderState, StaffOrderState } from '../../types'

interface CardCustomerOpenStyle extends CSSProperties {
  active?: boolean
  disable?: boolean
}

const GridBox = styled.div<CardCustomerOpenStyle>`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => props.gap || '0.5rem'};
`

const Box = styled.div<CardCustomerOpenStyle>`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0.25rem'};
  position: ${props => props.position || 'unset'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
  border: ${props => props.border || 'unset'};
  background-color: ${props => props.backgroundColor || 'unset'};
  padding: ${props => props.padding || '0'};
  border-radius: ${props => props.borderRadius || '0'};
`

const BoxServiceContainer = styled.div<{
  flexDirection: CSSProperties['flexDirection']
}>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  align-items: flex-end;
  padding: 12px;
  gap: 12px;
  border: 1px solid var(--border);
  border-radius: 8px;
  align-items: center;
`

const FlexBox = styled(Row)``

const BoxShowData = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid var(--border);
  border-radius: 4px;
  flex-grow: 1;
  position: relative;
`

const BoxSelectShowCustomer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 8px;
  width: 100%;
  top: 3rem;
  border: 1px solid var(--border);
  z-index: 2;
  left: 0px;
  border-radius: 4px;
  background: #fff;
`

const BoxOptionsShowCustomer = styled.div`
  display: flex;
  flex-direction: column;
`

const BoxOptionShowCustomer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const BoxServiceIcon = styled.div`
  background: #f0f2f5;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  padding: 0.85rem;
`

const BoxSelectService = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
`

const ImageBox = styled.div<CardCustomerOpenStyle>`
  border-radius: ${props => props.borderRadius || '8px'};
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  width: ${props => props.width || '60px'};
  height: ${props => props.height || '50px'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  padding: ${props => props.padding || '0'};
  border: ${props => props.border || 'none'};
`

const BoxTime = styled.div<CardCustomerOpenStyle>`
  background: ${props =>
    props.active
      ? 'linear-gradient(221.86deg, #8C56D9 2.71%, #53389E 97.79%)'
      : '#EEF1F5'};
  color: ${props => (props.active ? '#FFF' : '#000')};
  border-radius: 8px;
  padding: 0.25rem;
  text-align: center;
  font-size: 0.9rem;
  font-weight: ${props => (props.active ? '500' : '400')};
  opacity: ${props => props.opacity || ''};
`

const BoxCustomer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  gap: 2px;
  width: 38px;
  height: 24px;
  background: #f4eefb;
  border-radius: 2px;
  flex: none;
  flex-grow: 0;
  color: var(--primary);
  ${typography.body2}
`

interface CardCustomerOpenProps {
  customerIndex: number
  services: ServiceOrder[]
  staffOrders: StaffOrderState[]
  roomOrders: RoomOrderState[]
  currentDate: Date
  date: Date
}

const CardCustomerOpen = ({
  customerIndex,
  services,
  staffOrders,
  roomOrders,
  currentDate,
  date,
}: CardCustomerOpenProps) => {
  const { t } = useTranslation()

  const {
    activeOpen,
    openWarning,
    roomsForService,
    activeOpenCustomer,
    onDeleteService,
    onOpenSelectClick,
    onToggle,
    onSelectTimeClick,
    onSelectStaff,
    onConfirmClick,
    handleClose,
    onSelectRoomClick,
    onCheckBoxClick,
    onOpenSelectCustomer,
    onSelectCustomer,
  } = useCardCustomer({
    customerIndex,
    staffOrders,
    roomOrders,
    currentDate,
  })

  return (
    <>
      <CustomerForm index={customerIndex} />
      <GridBox>
        {services.map(
          (
            {
              serviceId,
              serviceImage,
              serviceName,
              timePrices,
              serviceError,
              activeTimePriceIndex,
              staffs,
              rooms: serviceRooms,
              primaryCustomerIndex,
              serviceRelatedCustomers,
              serviceCustomer,
            },
            serviceIndex
          ) => (
            <BoxServiceContainer
              key={serviceIndex}
              flexDirection={serviceId === -1 ? 'row' : 'column'}
            >
              {serviceId !== -1 && (
                <Box
                  flexDirection='column'
                  gap='12px'
                  style={{ overflow: 'hidden' }}
                  border={serviceError ? '1px solid #DB2D1E' : ''}
                >
                  <Box alignItems='center' gap='0.5rem'>
                    <ImageBox background={serviceImage} />
                    <Box flexDirection='column' style={{ width: '100%' }}>
                      <FlexBox justify='space-between'>
                        <FlexBox gap='2px'>
                          {serviceCustomer > 1 && (
                            <BoxCustomer>
                              <PeopleIcon />
                              {serviceCustomer}
                            </BoxCustomer>
                          )}
                          <TextLabel fontWeight='600'>{serviceName}</TextLabel>
                        </FlexBox>

                        {primaryCustomerIndex.customerIndex ===
                          customerIndex && (
                          <DeleteIcon3
                            size='15'
                            onClick={() => onDeleteService(serviceIndex)}
                          />
                        )}
                      </FlexBox>

                      <div
                        style={{
                          display: 'flex',
                          maxWidth: '255px',
                          minWidth: '80%',
                          overflow: 'hidden',
                        }}
                      >
                        <Swiper
                          slidesPerView={6}
                          spaceBetween={5}
                          pagination={{ clickable: true }}
                          style={{
                            margin: '0',
                            width: '100%',
                          }}
                        >
                          {timePrices.map(({ time }, timePriceIndex) => (
                            <SwiperSlide
                              key={timePriceIndex}
                              style={{
                                width: 'auto',
                                minWidth: '70px',
                                maxWidth: '100px',
                                overflow: 'hidden',
                              }}
                            >
                              <BoxTime
                                onClick={e =>
                                  primaryCustomerIndex.customerIndex !==
                                  customerIndex
                                    ? e.preventDefault()
                                    : onSelectTimeClick(
                                        serviceIndex,
                                        timePriceIndex
                                      )
                                }
                                active={activeTimePriceIndex === timePriceIndex}
                                opacity={
                                  primaryCustomerIndex.customerIndex !==
                                  customerIndex
                                    ? '0.4'
                                    : ''
                                }
                              >
                                {`${time} นาที`}
                              </BoxTime>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Box>
                  </Box>

                  {serviceCustomer > 1 && (
                    <GridBox gap='12px' columns='1fr 1fr'>
                      <FlexBox>
                        <PeopleIcon fill='#000' />
                        เลือกใช้บริการคู่กับลูกค้า
                      </FlexBox>

                      <BoxShowData justify='space-between'>
                        {`${t('customer')} ${serviceRelatedCustomers
                          .map(s => s + 1)
                          .join(', ')}`}
                        {activeOpenCustomer.customerIndex === customerIndex &&
                        activeOpenCustomer.serviceIndex === serviceIndex ? (
                          <ChevronDown
                            onClick={() =>
                              onOpenSelectCustomer(customerIndex, serviceIndex)
                            }
                          />
                        ) : (
                          <ChevronRight
                            onClick={() =>
                              onOpenSelectCustomer(customerIndex, serviceIndex)
                            }
                          />
                        )}
                        {activeOpenCustomer.customerIndex === customerIndex &&
                          activeOpenCustomer.serviceIndex === serviceIndex && (
                            <BoxSelectShowCustomer>
                              {activeOpenCustomer.listCustomer.map(
                                (customer, index) => (
                                  <BoxOptionsShowCustomer key={index}>
                                    <BoxOptionShowCustomer>
                                      <Checkbox
                                        checked={serviceRelatedCustomers.includes(
                                          customer
                                        )}
                                        onChange={() =>
                                          onSelectCustomer(customer)
                                        }
                                      />
                                      {`${t('customer')} ${customer + 1}`}
                                    </BoxOptionShowCustomer>
                                  </BoxOptionsShowCustomer>
                                )
                              )}
                            </BoxSelectShowCustomer>
                          )}
                      </BoxShowData>
                    </GridBox>
                  )}
                  <Box flexDirection='column' borderRadius='8px' gap='0.5rem'>
                    {staffs.map(
                      (
                        {
                          staffId,
                          staffName,
                          staffImage,
                          requestStaff,
                          queueFree,
                        },
                        staffIndex
                      ) => (
                        <GridBox key={staffIndex} columns='1fr 1fr'>
                          <FlexBox gap='17px'>
                            <FlexBox align='center' gap='5px'>
                              <Checkbox
                                onChange={() =>
                                  onCheckBoxClick(
                                    serviceIndex,
                                    staffIndex,
                                    'queueFree'
                                  )
                                }
                                checked={queueFree}
                              />
                              {t('request')}
                            </FlexBox>
                            <FlexBox align='center' gap='5px'>
                              <Checkbox
                                onChange={() =>
                                  onCheckBoxClick(
                                    serviceIndex,
                                    staffIndex,
                                    'request'
                                  )
                                }
                                checked={requestStaff}
                              />
                              {t('skipQueue')}
                            </FlexBox>
                          </FlexBox>
                          <FlexBox
                            onClick={() =>
                              onOpenSelectClick(
                                serviceIndex,
                                staffIndex,
                                'staffIndex'
                              )
                            }
                          >
                            <BoxShowData justify='space-between'>
                              <FlexBox align='center' gap='12px'>
                                {staffId !== -1 && (
                                  <ImageBox
                                    width='24px'
                                    height='24px'
                                    borderRadius='50%'
                                    background={staffImage}
                                  />
                                )}
                                <TextLabel>
                                  {staffId === -1
                                    ? t('pleaseSelectTherapist')
                                    : staffName}
                                </TextLabel>
                              </FlexBox>
                              <ChevronRight />
                            </BoxShowData>
                          </FlexBox>
                        </GridBox>
                      )
                    )}
                    <div className='mt-1'></div>
                    <ShowRoom
                      serviceIndex={serviceIndex}
                      serviceRooms={serviceRooms}
                      roomOrders={roomOrders}
                      onOpenSelectClick={(roomIndex: number) =>
                        onOpenSelectClick(serviceIndex, roomIndex, 'roomIndex')
                      }
                    />
                  </Box>
                </Box>
              )}

              {serviceId === -1 && (
                <>
                  {serviceIndex !== 0 && (
                    <DeleteIcon3
                      size='18'
                      onClick={() => onDeleteService(serviceIndex)}
                    />
                  )}
                  <BoxServiceIcon>
                    <ServiceIcon color='#A1A8B2' size='35' />
                  </BoxServiceIcon>
                  <BoxSelectService>
                    <TextLabel color='#000000' fontWeight='500'>
                      {t('service')}
                    </TextLabel>
                    <TextLabel color='#475467' fontWeight='500'>
                      {t('selectTreatmentFromYourLeft')}
                    </TextLabel>
                  </BoxSelectService>
                </>
              )}
            </BoxServiceContainer>
          )
        )}
      </GridBox>

      {activeOpen.type === 'staffIndex' && activeOpen.staffIndex !== -1 && (
        <CardStaff
          staffOrders={staffOrders}
          activeOpen={activeOpen}
          currentDate={currentDate}
          onToggle={onToggle}
          onSelectStaff={onSelectStaff}
        />
      )}
      {activeOpen.type === 'roomIndex' && activeOpen.roomIndex !== -1 && (
        <CardRoom
          activeOpen={activeOpen}
          roomOrders={roomOrders}
          roomsForService={roomsForService}
          onToggle={onToggle}
          onSelectRoomClick={onSelectRoomClick}
        />
      )}
      {openWarning.type === 'staffNotCome' && (
        <PopupConfirm
          handleClose={handleClose}
          onConfirmClick={onConfirmClick}
          text={t(`theTherapist'sShiftIsn'tStarted`) + ''}
          icon={<StaffUnavailableIcon />}
        />
      )}
      {openWarning.type === 'staffUnavailable' && (
        <PopupConfirm
          handleClose={handleClose}
          onConfirmClick={onConfirmClick}
          text={t(`theTherapistSelectedIsNotAvailable`) + ''}
        />
      )}
    </>
  )
}

export default CardCustomerOpen
