import { useEffect, useState } from 'react'

import {
  BookingHistory,
  TransactionTypes,
} from '../../redux/models/BookingHistory'
import { Customer } from '../../redux/models/Customer'
import CustomerPackage from '../../redux/models/CustomerPackage'

interface useCustomerRecordProps {
  customerBookingHistories: BookingHistory[]
  customerPackages: CustomerPackage[]
  customerData: Customer
}

interface ServiceState {
  _id: number
  time: number
  service: string
  count: number
}

interface StaffState {
  _id: number
  name: string
  count: number
}

const useCustomerRecord = ({
  customerBookingHistories,
  customerPackages,
  customerData,
}: useCustomerRecordProps) => {
  const [total, setTotal] = useState(0)
  const [inComeCount, setInComeCount] = useState(0)
  const [services, setServices] = useState<ServiceState[]>([])
  const [staffs, setStaffs] = useState<StaffState[]>([])
  const [totalService, setTotalService] = useState<number>(0)
  const [totalPackage, setTotalPackage] = useState<number>(0)

  useEffect(() => {
    let inComeCount = 0
    let services: ServiceState[] = []
    let staffs: StaffState[] = []
    let totalService: number = 0
    let totalPackageDiscount: number = 0
    let totalPackage: number = 0

    for (const customerBookingHistory of customerBookingHistories) {
      const {
        payStatus,
        customers,
        totalDiscount,
        discount,
        transactionType,
        customerCount,
        total: _total,
        bookingCode,
        statusId,
      } = customerBookingHistory

      if (
        statusId !== 6 &&
        payStatus === 1 &&
        transactionType !== TransactionTypes.Product
      ) {
        if (transactionType !== TransactionTypes.Package) {
          const customerDiscount = discount / customerCount
          let customerServiceDiscount: number = 0
          let customerServiceTotal: number = 0

          for (const customer of customers) {
            if (customer.customerId === customerData._id) {
              for (const order of customer.orders) {
                const findServiceIndex = services.findIndex(
                  s => s._id === +order.serviceId && s.time === order.time
                )

                if (findServiceIndex === -1) {
                  services.push({
                    _id: order.serviceId,
                    time: order.time,
                    service: order.service,
                    count: 1,
                  })
                } else {
                  services[findServiceIndex].count += 1
                }

                // const findStaffIndex = staffs.findIndex(
                //   s => s._id === +order.staffId
                // )

                // if (order.staffId !== -1) {
                //   if (findStaffIndex === -1) {
                //     staffs.push({
                //       _id: order.staffId,
                //       name: order.staffName,
                //       count: 1,
                //     })
                //   } else {
                //     staffs[findStaffIndex].count += 1
                //   }
                // }

                customerServiceDiscount += order.serviceDiscount || 0
                customerServiceTotal += order.price || 0
              }
            }
          }

          totalService +=
            (customerServiceTotal || 0) -
            (customerServiceDiscount || 0) -
            (customerDiscount || 0)

          inComeCount++
        } else if (transactionType === TransactionTypes.Package) {
          totalPackageDiscount += totalDiscount || 0
          totalPackage += (_total || 0) - (discount || 0)
          // const totalPackage = customerPackages.reduce((acc, info) => {
          //   acc += info.price

          //   return acc
          // }, 0)
        }
      }
    }
    setInComeCount(inComeCount)
    setTotal(totalService + ((totalPackage || 0) - (totalPackageDiscount || 0)))
    setTotalService(totalService)
    setTotalPackage((totalPackage || 0) - (totalPackageDiscount || 0))
    setServices(services.sort((a, b) => b.count - a.count))
    setStaffs(staffs.sort((a, b) => b.count - a.count))
  }, [customerBookingHistories, customerPackages])

  return {
    total,
    totalService,
    totalPackage,
    inComeCount,
    services,
    staffs,
  }
}
export default useCustomerRecord
