export const GET_FILTER = 'GET_FILTER'

export const getFilter = filters => {
  return {
    type: GET_FILTER,
    payload: { filters },
  }
}

export const GET_SEARCH = 'GET_SEARCH'

export const getSearch = search => {
  return {
    type: GET_SEARCH,
    payload: { search },
  }
}

export const GET_FILTER_DATE = 'GET_FILTER_DATE'

export const getFilterDate = date => {
  return {
    type: GET_FILTER_DATE,
    payload: { date },
  }
}

export const GET_FILTER_STATUS = 'GET_FILTER_STATUS'

export const getFilterStatus = status => {
  return {
    type: GET_FILTER_STATUS,
    payload: { status },
  }
}

export const GET_FILTER_TYPE_BOOK = 'GET_FILTER_TYPE_BOOK'

export const getFilterTypeBook = typeBook => {
  return {
    type: GET_FILTER_TYPE_BOOK,
    payload: { typeBook },
  }
}

export const GET_FILTER_PAY = 'GET_FILTER_PAY'

export const getFilterPay = pay => {
  return {
    type: GET_FILTER_PAY,
    payload: { pay },
  }
}

export const GET_FILTER_SERVICE = 'GET_FILTER_SERVICE'

export const getFilterService = service => {
  return {
    type: GET_FILTER_SERVICE,
    payload: { service },
  }
}

export const GET_FILTER_STAFF = 'GET_FILTER_STAFF'

export const getFilterStaff = staff => {
  return {
    type: GET_FILTER_STAFF,
    payload: { staff },
  }
}

export const UPDATE_FILTER = 'UPDATE_FILTER'

export const updateFilter = filters => {
  return {
    type: UPDATE_FILTER,
    payload: { filters },
  }
}

export const GET_IS_OPEN_PAY = 'GET_IS_OPEN_PAY'

export const getIsOpenPay = isOpenPay => {
  return {
    type: GET_IS_OPEN_PAY,
    payload: { isOpenPay },
  }
}

export const CLEAR_FILTER = 'CLEAR_FILTER'

export const clearFilter = filters => {
  return {
    type: CLEAR_FILTER,
    payload: { filters },
  }
}
