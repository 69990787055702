export const GET_USER = 'GET_USER'

export const getUser = user => {
  return {
    type: GET_USER,
    payload: { user },
  }
}

export const UPDATE_USER = 'UPDATE_USER'

export const updateUser = user => {
  return {
    type: UPDATE_USER,
    payload: { user },
  }
}

export const UPDATE_USER_ID = 'UPDATE_USER_ID'

export const updateUserId = userId => {
  return {
    type: UPDATE_USER_ID,
    payload: { userId },
  }
}

export const UPDATE_USER_FIRST_NAME = 'UPDATE_USER_FIRST_NAME'

export const updateUserFirstName = firstName => {
  return {
    type: UPDATE_USER_FIRST_NAME,
    payload: { firstName },
  }
}

export const UPDATE_USER_LAST_NAME = 'UPDATE_USER_LAST_NAME'

export const updateUserLastName = lastName => {
  return {
    type: UPDATE_USER_LAST_NAME,
    payload: { lastName },
  }
}

export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL'

export const updateUserEmail = email => {
  return {
    type: UPDATE_USER_EMAIL,
    payload: { email },
  }
}

export const UPDATE_USER_PHONENUMBER = 'UPDATE_USER_PHONENUMBER'

export const updateUserPhoneNumber = phoneNumber => {
  return {
    type: UPDATE_USER_PHONENUMBER,
    payload: { phoneNumber },
  }
}

export const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE'

export const updateUserType = type => {
  return {
    type: UPDATE_USER_TYPE,
    payload: { type },
  }
}

export const UPDATE_LOGIN_TIME = 'UPDATE_LOGIN_TIME'

export const updateLoginTime = loginTime => {
  return {
    type: UPDATE_LOGIN_TIME,
    payload: { loginTime },
  }
}

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'

export const updateUserPassword = password => {
  return {
    type: UPDATE_PASSWORD,
    payload: { password },
  }
}

export const UPDATE_PIN = 'UPDATE_PIN'

export const updateUserPin = pin => {
  return {
    type: UPDATE_PIN,
    payload: { pin },
  }
}

export const CLEAR_USER = 'CLEAR_USER'

export const clearUser = user => {
  return {
    type: CLEAR_USER,
    payload: { user },
  }
}
