import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import api from '../../api'

import BodyStaffTypeLeft from './BodyStaffTypeLeft'
import ButtonMenu from './ButtonMenu'
import BodyCommissionFeeLeft from './BodyCommissionFeeLeft'
import PopupAddStaff from '../popups/PopupAddStaff'
import PopupAddStaffType from '../popups/PopupAddStaffType'
import PopupConfirmDelete from '../popups/PopupConfirmDelete'
import PopupCommissionFee from '../popups/PopupCommissionFee'

const Container = styled.div`
  width: calc(100%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Body = ({
  onMenuClick: _onMenuClick,
  activeMenu,
  activeAddData,
  activeAddGroupData,
  handleClose: _handleClose,
}) => {
  const [staffTypes, setStaffTypes] = useState([])
  const [staffsByQueue, setStaffsByQueue] = useState([])
  const [staffsNoneActive, setStaffsNoneActive] = useState([])
  const [staffs, setStaffs] = useState([])
  const [services, setServices] = useState([])
  const [serviceSpecificStaff, setServiceSpecificStaff] = useState([])
  const [serviceGroup, setServiceGroup] = useState([])
  const [search, setSearch] = useState('')
  const [currentIdEdit, setCurrentIdEdit] = useState(-1)
  const [currentDataEdit, setCurrentDataEdit] = useState('')
  const [commissionFee, setCommissionFee] = useState([])
  const [commissionFees, setCommissionFees] = useState([])
  const [activeGroupEdit, setActiveGroupEdit] = useState(false)
  const [activeEdit, setActiveEdit] = useState(false)
  const [activeGroupDelete, setActiveGroupDelete] = useState(false)
  const [activeDelete, setActiveDelete] = useState(false)
  const [success, setSuccess] = useState(false)
  const [currentService, setCurrentService] = useState('')

  useEffect(() => {
    callApi()
  }, [])

  const onMenuClick = index => {
    setCurrentIdEdit(-1)
    setCurrentDataEdit('')
    callApi()
    _onMenuClick(index)
  }

  const callApi = async () => {
    await callApiStaffTypes()
    await callApiStaffs()
    await callApiStaffByStatus()
    await callApiStaffQueue()
    await callApiServices()
    await callApiServiceGroup()
    await callApiServiceSpecificStaff()
    await callApiCommissions()
  }

  const callApiStaffTypes = async () => {
    try {
      let { data } = await api.getStaffType()
      data = data.filter(d => d.status !== -1)
      setStaffTypes(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiStaffs = async () => {
    try {
      const { data } = await api.getStaff()
      setStaffs(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiStaffQueue = async () => {
    try {
      const { data } = await api.getStaffSortByQueue(new Date(), new Date())
      setStaffsByQueue(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiStaffByStatus = async () => {
    try {
      const { data } = await api.getStaffByStatus(-1)
      setStaffsNoneActive(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiServices = async () => {
    try {
      const { data } = await api.getServices()
      setServices(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiServiceSpecificStaff = async () => {
    try {
      const { data } = await api.getServiceSpecificStaff()
      setServiceSpecificStaff(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiServiceGroup = async () => {
    try {
      const { data, success } = await api.getProductGroupByHeadGroup(1)
      if (success) {
        setServiceGroup(data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const callApiCommission = async _id => {
    try {
      const { data } = await api.getCommissionByServiceId(_id)
      return data
    } catch (err) {
      console.log(err)
    }
  }
  const callApiCommissions = async () => {
    try {
      const { data } = await api.getCommission()
      setCommissionFees(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiDeleteStaffType = async () => {
    try {
      const { success } = await api.removeStaffType(currentIdEdit)
      if (success) {
        if (success) {
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
            handleClose()
          }, 1000)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const callApiDeleteStaff = async () => {
    try {
      const { success } = await api.removeStaff(currentIdEdit)
      if (success) {
        if (success) {
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
            handleClose()
          }, 1000)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleClose = async (close = false) => {
    setCurrentIdEdit(-1)
    setCurrentDataEdit('')
    setActiveGroupEdit(false)
    setActiveEdit(false)
    setActiveGroupDelete(false)
    setActiveDelete(false)
    if (!close) {
      await callApi()
    }
    _handleClose()
  }

  const onSearchChange = value => {
    setSearch(value)
  }

  const onEditGroupClick = async _id => {
    await callApi()
    let findData = ''
    if (activeMenu === 'พนักงาน') {
      findData = staffTypes.find(t => t._id === _id || '') || ''
      setCurrentService('')
    } else if (activeMenu === 'ค่ามือพนักงาน') {
      findData = services.find(s => s._id === _id || '') || ''
      if (findData) {
        const findGroupName = serviceGroup.find(g => g._id === findData.groupId)
        findData = {
          ...findData,
          groupName: findGroupName ? findGroupName.groupName : '',
        }
        const commission = await callApiCommission(_id)

        setCommissionFee(commission)
        setCurrentService(findData)

        // const newCommissionFee = { ...commissionFees[0] }
        // const {
        //   serviceCommissionFees,
        //   staffTypesCommissionFees,
        //   staffCommissionFees,
        // } = newCommissionFee

        // const { timePrices } = findData
        // if (serviceCommissionFees.length !== findData.timePrices.length) {
        //   const filterUnHasServiceCommission = timePrices.filter(t =>
        //     serviceCommissionFees.some(s => s.timePriceId !== t.timePriceId)
        //   )

        //   for (const _filterUnHasServiceCommission of filterUnHasServiceCommission) {
        //     newCommissionFee.serviceCommissionFees.push({
        //       timePriceId: _filterUnHasServiceCommission.timePriceId,
        //       commissionFee: 0,
        //     })

        //     for (let i = 0; i < staffTypesCommissionFees.length; i++) {
        //       staffTypesCommissionFees[i].serviceCommissionFees.push({
        //         timePriceId: _filterUnHasServiceCommission.timePriceId,
        //         commissionFee: 0,
        //         edit: false,
        //       })
        //     }

        //     for (let i = 0; i < staffCommissionFees.length; i++) {
        //       staffCommissionFees[i].serviceCommissionFees.push({
        //         timePriceId: _filterUnHasServiceCommission.timePriceId,
        //         commissionFee: 0,
        //         edit: false,
        //       })
        //     }
        //   }
        // }
        // setCommissionFee([newCommissionFee])
      }
    }

    if (findData) {
      setCurrentIdEdit(activeMenu === 1 ? findData.staffTypeId : _id)
      setCurrentDataEdit(findData)
      setActiveGroupEdit(true)
      setActiveEdit(false)
    }
  }
  const onEditClick = id => {
    const findData = staffs.find(s => s._id === id || '') || ''
    if (findData) {
      setCurrentIdEdit(id)
      setCurrentDataEdit(findData)
      setActiveGroupEdit(false)
      setActiveEdit(true)
    } else {
      const findStaffsNoneActive =
        staffsNoneActive.find(s => s._id === id || '') || ''
      if (findStaffsNoneActive) {
        setCurrentIdEdit(id)
        setCurrentDataEdit(findStaffsNoneActive)
        setActiveGroupEdit(false)
        setActiveEdit(true)
      }
    }
  }
  const onDeleteGroupClick = id => {
    setCurrentIdEdit(id)
    setActiveGroupDelete(true)
    setActiveDelete(false)
  }
  const onDeleteClick = id => {
    setCurrentIdEdit(id)
    setActiveDelete(true)
    setActiveGroupDelete(false)
  }

  const onConfirmDeleteClick = async () => {
    if (activeGroupDelete) {
      await callApiDeleteStaffType()
    } else if (activeDelete) {
      await callApiDeleteStaff()
    }
  }

  return (
    <Container>
      <div
        className='mb-3'
        style={{
          display: 'flex',
        }}
      >
        <ButtonMenu
          onMenuClick={onMenuClick}
          onSearchChange={onSearchChange}
          search={search}
        />
      </div>
      {activeMenu === 'พนักงาน' && (
        <BodyStaffTypeLeft
          staffTypes={staffTypes}
          staffs={staffs}
          staffsNoneActive={staffsNoneActive}
          search={search}
          onEditGroupClick={onEditGroupClick}
          activeGroupEdit={activeGroupEdit}
          onEditClick={onEditClick}
          onDeleteGroupClick={onDeleteGroupClick}
          onDeleteClick={onDeleteClick}
        />
      )}
      {activeMenu === 'ค่ามือพนักงาน' && (
        <BodyCommissionFeeLeft
          serviceGroup={serviceGroup}
          services={services}
          commissionFees={commissionFees}
          search={search}
          onEditGroupClick={onEditGroupClick}
        />
      )}
      {activeMenu === 'พนักงาน' && (activeAddData || activeEdit) && (
        <PopupAddStaff
          handleClose={handleClose}
          staffTypes={staffTypes}
          staffs={staffs}
          services={serviceSpecificStaff}
          currentDataEdit={currentDataEdit}
          currentIdEdit={currentIdEdit}
        />
      )}
      {activeMenu === 'พนักงาน' && (activeAddGroupData || activeGroupEdit) && (
        <PopupAddStaffType
          handleClose={handleClose}
          staffTypes={staffTypes}
          staffs={staffs}
          currentDataEdit={currentDataEdit}
          currentIdEdit={currentIdEdit}
        />
      )}
      {(activeDelete || activeGroupDelete) && (
        <PopupConfirmDelete
          text='ยืนยันการลบข้อมูล'
          handleClose={handleClose}
          onConfirmDeleteClick={onConfirmDeleteClick}
          success={success}
        />
      )}
      {activeGroupEdit && activeMenu === 'ค่ามือพนักงาน' && (
        <PopupCommissionFee
          handleClose={handleClose}
          staffTypes={staffTypes}
          staffs={staffs}
          currentService={currentService}
          currentIdEdit={currentIdEdit}
          currentDataEdit={currentDataEdit}
          commissionFees={commissionFee}
        />
      )}
    </Container>
  )
}

export default Body
