import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FiEye, FiEyeOff, FiLock, FiPhone } from 'react-icons/fi'
import { Swiper, SwiperSlide } from 'swiper/react'

import { fetchUsers } from './loginUtil'
import useLogin from './useLogin'
import { UserBox } from './UserBox'

// @ts-ignore
import PopupSelectBrand from '../popups/PopupSelectBrand'

// @ts-ignore
import TextLabel from '../../atoms/TextLabel'
import { StyledButton } from '../../lib/design-system/atoms/StyledButton'
import { StyledHref } from '../../lib/design-system/atoms/StyledHref'
import { StyledLink } from '../../lib/design-system/atoms/StyledLink'

import { SavedUsersData } from '../../lib/auth/loginApi'
import { ComplexCheckBox } from '../../lib/design-system/components/ComplexCheckBox'
import { ComplexInput } from '../../lib/design-system/components/ComplexInput'
import { Container } from '../../lib/design-system/layout/Container'
import { Layout } from '../../lib/design-system/layout/Layout'
import { FORGET_PASSWORD_URL } from '../../routes'

const Login = () => {
  const { t } = useTranslation()

  const [isShowPassword, setIsShowPassword] = React.useState(false)
  const [isRemember, setIsRemember] = React.useState(false)
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [users, setUsers] = React.useState<SavedUsersData[]>([])

  useEffect(() => {
    fetchUsers().then(data => {
      if (data) {
        setUsers(data)
      }
    })
  }, [])

  const { isSelectStoreOpen, error, loginPassword, onSelectStoreClose } =
    useLogin()

  return (
    <Layout>
      <Container
        maxWidth='500px'
        flexDirection='column'
        gap='2rem'
        px='1rem'
        py='1rem'
      >
        <Container maxWidth='200px'>
          <img src='/images/logo_login.png' alt='logo' width={'100%'} />
        </Container>

        <Container flexDirection='column' gap='1rem'>
          {error && (
            <TextLabel color='#DB2D1E' fontWeight='600'>
              {`${t('usernameOrPasswordIsIncorrectPleaseTryAgain')}!!!`}
            </TextLabel>
          )}
          <Container flexDirection='column' gap='0.5rem'>
            <ComplexInput
              name='username'
              placeholder={`${t('phoneNumber/Email/UserID')}`}
              preIcon={<FiPhone size='14' />}
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <ComplexInput
              name='password'
              placeholder={`${t('password')}`}
              type={isShowPassword ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              preIcon={<FiLock size={14} />}
              postIcon={
                isShowPassword ? (
                  <FiEyeOff
                    cursor={'pointer'}
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  />
                ) : (
                  <FiEye
                    cursor={'pointer'}
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  />
                )
              }
            />
          </Container>

          <Container justifyContent='space-between'>
            <ComplexCheckBox
              checked={isRemember}
              onChange={() => setIsRemember(!isRemember)}
              text={`${t('rememberMyLoginDetails')}`}
              elementId='remember-me'
            />
            <StyledLink to={{ pathname: FORGET_PASSWORD_URL }}>
              {`${t('forgetPassword')}?`}
            </StyledLink>
          </Container>

          <Container>
            <StyledButton
              onClick={() => loginPassword(username, password, isRemember)}
              w='100%'
            >
              {t('login')}
            </StyledButton>
          </Container>

          <Container justifyContent='space-between'>
            <StyledHref
              target='_blank'
              href='https://forms.gle/amGN8kXP1R71fnmK7'
            >
              {t('register')}
            </StyledHref>
            <StyledHref href='https://thaihandpos.com/info'>
              {t('moreInformation')}
            </StyledHref>
          </Container>
        </Container>

        {users.length > 0 && (
          <>
            <TextLabel>{t('orLoginVia')}</TextLabel>
            <div style={{ width: '100%' }}>
              <Swiper slidesPerView={4} spaceBetween={7} threshold={10}>
                {users.map((user, index) => (
                  <SwiperSlide key={index}>
                    <UserBox user={user} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        )}
      </Container>
      {isSelectStoreOpen && (
        <PopupSelectBrand handleClose={onSelectStoreClose} />
      )}
    </Layout>
  )
}

export default Login
