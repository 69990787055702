import React from 'react'
import { IconBaseProps } from 'react-icons'
import styled, { CSSProperties } from 'styled-components'

interface SVGProps {
  disabled?: boolean
}

const SVG = styled.svg<SVGProps>`
  user-select: none;
  cursor: ${props => (props.disabled ? '' : 'pointer')};
  -webkit-tap-highlight-color: transparent;
`

export interface CloseIconProps extends IconBaseProps {
  withCircle: boolean
  backgroundColor: CSSProperties['backgroundColor']
  disabled?: boolean
}

const CloseIcon = ({
  onClick,
  size,
  color,
  backgroundColor,
  disabled,
  withCircle,
}: CloseIconProps) => (
  <SVG
    width={size || '33'}
    height={size || '33'}
    viewBox='0 0 33 33'
    fill='none'
    onClick={onClick}
    disabled={disabled}
  >
    {withCircle && (
      <circle
        cx='16.5'
        cy='16.5'
        r='16.5'
        fill={backgroundColor || 'rgba(56,56,84,0.5)'}
      />
    )}
    <path
      d='M10.6465 10.6475L22.6404 22.6414'
      stroke={color || 'white'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.2908 10.9973L10.9971 22.291'
      stroke={color || 'white'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVG>
)

export default CloseIcon
