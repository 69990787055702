import React, { useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import CheckBox from '../../atoms/CheckBox'
import DeleteIcon from '../../atoms/DeleteIcon'
import PromotionBathIcon from '../../atoms/PromotionBathIcon'
import PromotionPercentIcon from '../../atoms/PromotionPercentIcon'
import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'

const Component = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-top: 1rem; */
`

const Table = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  padding: ${props => props.padding || '1rem 0rem 1rem 0rem'};
  overflow: hidden;
`

const Tr = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: 100%;
  padding: ${props => props.padding || 0};
  background: ${props => props.backgroundColor || ''};
  margin-top: 0.4rem;
  border: ${props => props.border || 'none'};
  border-radius: 8px;
`

const Th = styled.div`
  align-items: center;
  justify-content: ${props => props.justifyContent || 'center'};
  /* justify-content: flex-start; */
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex: ${props => props.flex || '1 1 auto'};
  margin: ${props => props.margin || '0'};
  max-width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};
  background: ${props => props.background || 'white'};
  padding: ${props => props.padding || '1rem 0'};
  font-weight: 500;
  color: ${props => props.color || '#00000'};
  :first-child {
    border-radius: 8px 0 0 8px;
  }
  :last-child {
    border-radius: 0 8px 8px 0;
  }
`

const Circle = styled.div`
  background: #98a1b2;
  border-radius: 50%;
  width: 4px;
  height: 4px;
`

const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgb(107 107 107 / 16%);
  border-radius: 8px;
  padding: 0.5rem;
  top: 42px;
  left: -15px;
  z-index: 5;
`

const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`

const BoxActive = styled.div`
  background: ${props => props.background || 'rgba(50, 213, 130, 0.16)'};
  color: ${props => props.color || '#32D582'};
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
`

const BodyLeft = ({
  promotions,
  onSelectPromotion,
  onDeletePromotionClick,
  onDeleteClick,
}) => {
  const [checkAll, setCheckAll] = useState(false)
  const [checkId, setCheckId] = useState([])
  const [promotionId, setPromotionsId] = useState('')

  const onCheckAllClick = () => {
    setCheckAll(!checkAll)
    setCheckId([])
  }

  const onCheckClick = _id => {
    const newArrCheckId = [...checkId]
    const findCheckIndex = newArrCheckId.findIndex(c => c === _id)
    if (findCheckIndex !== -1) {
      newArrCheckId.splice(findCheckIndex, 1)
    } else {
      newArrCheckId.push(_id)
      setCheckAll(false)
    }
    setCheckId(newArrCheckId)
  }

  const onPromotionClick = _id => {
    if (promotionId === _id) {
      setPromotionsId('')
    } else {
      setPromotionsId(_id)
    }
  }

  const onEditPromotionClick = id => {
    onSelectPromotion(id)
  }

  const onEditClick = (e, _id) => {
    onSelectPromotion(_id)
  }

  return (
    <Component>
      <Table>
        <Tr>
          <Th background='none' flex='1 1 5%' width='1 1 5%'>
            <CheckBox
              backgroundColor='#8C56D9'
              heightCheckMark='18px'
              widthCheckMark='18px'
              left='4.5px'
              top='1.5px'
              borderRadius='3px'
              paddingLeft='20px'
              marginTop='0px'
              marginBottom='0'
              onClick={onCheckAllClick.bind(this)}
              onChange={onCheckAllClick.bind(this)}
              checked={checkAll}
            />
          </Th>
          <Th
            background='none'
            flex='1 1 19%'
            width='1 1 19%'
            justifyContent='flex-start'
            color='#98A1B2'
          >
            ชื่อ
          </Th>
          <Th background='none' flex='1 1 19%' width='1 1 19%' color='#98A1B2'>
            ราคาลด
          </Th>
          {/* <Th background='none' flex='1 1 10%' width='1 1 10%' color='#98A1B2'>
            จำนวนสิทธิ์
          </Th>
          <Th background='none' flex='1 1 14%' width='14%' color='#98A1B2'>
            ช่วงเวลา
          </Th> */}
          <Th background='none' flex='1 1 19%' width='19%' color='#98A1B2'>
            วันที่
          </Th>
          <Th background='none' flex='1 1 19%' width='1 1 19%' color='#98A1B2'>
            สถานะ
          </Th>
          <Th
            background='none'
            flex='1 1 5%'
            width='1 1 5%'
            onClick={onDeleteClick.bind(this, checkAll, checkId)}
          >
            {checkAll || checkId.length > 1 ? (
              <div
                style={{
                  background: '#FFFFFF',
                  borderRadius: '8px',
                  padding: '0.5rem 0.65rem',
                  cursor: 'pointer',
                }}
              >
                <DeleteIcon color='#DB2D1E' size='20' />
              </div>
            ) : (
              ''
            )}
          </Th>
        </Tr>
        <Scrollbar id='setting-promotion-bodyLeft'>
          {promotions.map(
            (
              {
                _id,
                name,
                discount,
                quota,
                startTime,
                endTime,
                dateStart,
                dateEnd,
                active,
                unit,
              },
              index
            ) => (
              <Tr
                key={index}
                border={
                  checkAll
                    ? '1px solid #8C56D9'
                    : checkId.includes(_id)
                    ? '1px solid #8C56D9'
                    : ''
                }
                onClick={e => onEditClick(e, _id)}
              >
                <Th flex='1 1 5%' width='1 1 5%'>
                  <CheckBox
                    backgroundColor='#8C56D9'
                    heightCheckMark='18px'
                    widthCheckMark='18px'
                    left='4.5px'
                    top='1.5px'
                    borderRadius='3px'
                    paddingLeft='20px'
                    marginTop='0px'
                    marginBottom='0'
                    onClick={onCheckClick.bind(this, _id)}
                    onChange={onCheckClick.bind(this, _id)}
                    checked={checkAll ? true : checkId.includes(_id)}
                  />
                </Th>
                <Th flex='1 1 19%' width='1 1 19%' justifyContent='flex-start'>
                  {unit === 1 ? (
                    <PromotionBathIcon size='25' />
                  ) : (
                    <PromotionPercentIcon size='25' />
                  )}
                  <TextLabel paddingLeft='0.25rem'>{name}</TextLabel>
                </Th>
                <Th flex='1 1 19%' width='1 1 19%'>
                  {`${discount} ${unit === 1 ? 'บาท' : '%'}`}
                </Th>
                {/* <Th flex='1 1 10%' width='1 1 10%' color='#8C56D9'>
                  {quota}
                </Th>
                <Th flex='1 1 14%' width='14%'>
                  {`${utils.formatTime(startTime.hour)}:${utils.formatTime(
                    startTime.minute
                  )} - ${utils.formatTime(endTime.hour)}:${utils.formatTime(
                    endTime.minute
                  )} น.`}
                </Th> */}
                <Th flex='1 1 19%' width='19%'>
                  <div style={{ textAlign: 'center' }}>
                    <TextLabel>{`เริ่ม ${dayjs(dateStart).format(
                      'DD/MM/YYYY'
                    )}`}</TextLabel>
                    &nbsp;
                    <TextLabel>{`ถึง ${dayjs(dateEnd).format(
                      'DD/MM/YYYY'
                    )}`}</TextLabel>
                  </div>
                </Th>
                <Th
                  flex='1 1 19%'
                  width='1 1 19%'
                  color={active === 1 ? '#32D582' : '#DB2D1E'}
                >
                  <BoxActive
                    color={active === 1 ? '' : '#DB2D1E'}
                    background={active === 1 ? '' : 'rgba(219, 45, 30, 0.16)'}
                  >
                    {active === 1 ? 'เปิด' : 'ปิด'}
                  </BoxActive>
                </Th>
                <Th
                  flex='1 1 5%'
                  width='1 1 5%'
                  style={{ position: 'relative', cursor: 'pointer' }}
                  onClick={onPromotionClick.bind(this, _id)}
                >
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div className='mr-1' key={index}>
                      <Circle></Circle>
                    </div>
                  ))}
                  {_id === promotionId && (
                    <DropDown>
                      <Option onClick={onEditPromotionClick.bind(this, _id)}>
                        แก้ไข
                      </Option>
                      <Option onClick={() => onDeletePromotionClick(_id)}>
                        ลบ
                      </Option>
                    </DropDown>
                  )}
                </Th>
              </Tr>
            )
          )}
        </Scrollbar>
      </Table>
    </Component>
  )
}

export default BodyLeft
