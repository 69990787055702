import React, { useState } from 'react'

import Body from './Body'
import Head from './Head'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  const [activeBtnMenu, setActiveBtnMenu] = useState(0)
  const [activeAddData, setActiveAddData] = useState(false)
  const [activeAddGroupData, setActiveAddGroupData] = useState(false)

  const onButtonMenuClick = index => {
    setActiveBtnMenu(index)
  }

  const onAddData = () => {
    setActiveAddData(!activeAddData)
  }

  const onAddGroupData = () => {
    setActiveAddGroupData(!activeAddGroupData)
  }

  const handleClose = () => {
    setActiveAddData(false)
    setActiveAddGroupData(false)
  }

  return (
    <Page>
      <Head
        activeBtnMenu={activeBtnMenu}
        onAddData={onAddData}
        onAddGroupData={onAddGroupData}
      />
      <Body
        onButtonMenuClick={onButtonMenuClick}
        activeBtnMenu={activeBtnMenu}
        activeAddData={activeAddData}
        activeAddGroupData={activeAddGroupData}
        handleClose={handleClose}
      />
    </Page>
  )
}

export default Main
