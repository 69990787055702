import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import Schedule from './Schedule/index'
import useBody from './useBody'

const Container = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex: 1;
`

const Body = () => {
  const dispatch = useDispatch()

  const { bookingHistories } = useBody()

  return (
    <Container>
      <Schedule />
    </Container>
  )
}

export default Body
