import { DataTableValue, DataTableValueVariant } from "@/components/molecules/dataTable/DataTable";
import config from "@/config";

export interface BookingHistory {
    id: number;
    bookingCode: string;
    dateTime: string;
    phoneNumber: string;
    bookingChannel: DataTableValue
    payStatus: DataTableValue
    status: DataTableValue
    transactionType: DataTableValue
    staffs: DataTableValue
    createBy: string;
    editBy: string;
    [key: string]: any;
}

const bookingChannels = ['walk-in', 'online', 'phone'];
const payStatuses = ['ชำระ', 'ยังไม่ชำระ'];
const statuses = config.STATUS;
// ['booked', 'confirmed', 'ongoing', 'complete', 'arrived', 'cancel']
const STATUS_VARIANT = ['tertiary', 'secondary', 'primary', 'success', 'warning', 'danger'] as DataTableValueVariant[];
const transactionTypes = config.TRANSACTION_TYPE;
const staffs = ['', 'John', 'Emily', 'David', 'Emma', 'Michael', 'Sophia', 'Daniel', 'Olivia'];
const names = ['JINNY', 'SARAH', 'MIKE', 'LISA', 'TOM', 'ANNA', 'PAUL', 'EMMA', 'ALEX', 'KATE'];
const temp_services = ['Oil', 'Thai Massage', 'Foot Massage', 'Aroma Massage', 'Body Scrub', 'Body Mask', 'Facial Treatment', 'Manicure', 'Pedicure', 'Waxing', 'Hair Treatment', 'Hair Cut', 'Hair Color', 'Hair Perm', 'Hair Straightening', 'Hair Extension', 'Hair Styling', 'Makeup', 'Eyelash Extension', 'Eyebrow Extension', 'Eyebrow Tinting', 'Eyelash Tinting', 'Eyebrow Shaping', 'Eyelash Shaping', 'Eyebrow Waxing', 'Eyelash Waxing', 'Eyebrow Threading', 'Eyelash Threading', 'Eyebrow Lamination', 'Eyelash Lamination', 'Eyebrow Microblading', 'Eyelash Microblading', 'Eyebrow Microshading', 'Eyelash Microshading', 'Eyebrow Ombre', 'Eyelash Ombre', 'Eyebrow Henna', 'Eyelash Henna', 'Eyebrow Tattoo', 'Eyelash Tattoo', 'Eyebrow Removal', 'Eyelash Removal', 'Eyebrow Repair', 'Eyelash Repair', 'Eyebrow Refill', 'Eyelash Refill', 'Eyebrow Lift', 'Eyelash Lift', 'Eyebrow Curl', 'Eyelash Curl', 'Eyebrow Perm', 'Eyelash Perm', 'Eyebrow Tint Removal', 'Eyelash Tint Removal', 'Eyebrow Shaping Removal', 'Eyelash Shaping Removal', 'Eyebrow Waxing Removal', 'Eyelash Waxing Removal', 'Eyebrow Threading Removal', 'Eyelash Threading Removal', 'Eyebrow Lamination Removal', 'Eyelash Lamination Removal', 'Eyebrow Microblading Removal', 'Eyelash Microblading Removal', 'Eyebrow Microshading Removal', 'Eyelash Microshading Removal', 'Eyebrow Ombre Removal', 'Eyelash Ombre Removal', 'Eyebrow Henna Removal', 'Eyelash Henna Removal', 'Eyebrow Tattoo Removal', 'Eyelash Tattoo Removal', 'Eyebrow Repair Removal', 'Eyelash Repair Removal', 'Eyebrow Refill Removal', 'Eyelash Refill Removal']

export const createMockingBookingHistories = (count: number): BookingHistory[] => {
    const mockRows = [
      ] as BookingHistory[];
      
      for (let i = 2; i <= count; i++) {
        const date = new Date(2024, 1, 12 + Math.floor((i-1) / 10));
        const hours = Math.floor(Math.random() * 24);
        const minutes = Math.floor(Math.random() * 60);
        const dateTimeStr = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().slice(-2)} ${hours.toString().padStart(2, '0')}.${minutes.toString().padStart(2, '0')}`;
        
        const bookingChannel = bookingChannels[Math.floor(Math.random() * bookingChannels.length)];
        const payStatus = payStatuses[Math.floor(Math.random() * payStatuses.length)];
        const status = statuses[Math.floor(Math.random() * statuses.length)];
        const transactionType = transactionTypes[Math.floor(Math.random() * transactionTypes.length)];
        const staff = staffs[Math.floor(Math.random() * staffs.length)];
        const name = names[Math.floor(Math.random() * names.length)];

        // services for 1-5 items
        const serviceCount = Math.floor(Math.random() * 5) + 1;
        const services = [];
        for (let j = 0; j < serviceCount; j++) {
          services.push({
            key: j,
            value: temp_services[Math.floor(Math.random() * temp_services.length)]
          });
        }
      
        mockRows.push({
          bookingChannel: [{key: bookingChannels.indexOf(bookingChannel) + 1, value: bookingChannel}],
          bookingCode: `89422-${(843 + i).toString().padStart(3, '0')}`,
          createBy: `${name}, ${dateTimeStr}`,
          dateTime: dateTimeStr,
          editBy: `${name}, ${dateTimeStr}`,
          id: i,
          payStatus: [{key: payStatuses.indexOf(payStatus) + 1, value: payStatus, variant: payStatus === 'ชำระเงิน' ? 'success' : 'danger'}],
          status: [{key: statuses.indexOf(status) + 1, value: status, variant: STATUS_VARIANT[statuses.indexOf(status)]}],
          transactionType: [{key: transactionTypes.indexOf(transactionType) + 1, value: transactionType}],
          staffs: [{key: staffs.indexOf(staff) + 1, value: staff}],
          phoneNumber: `08${Math.floor(10000000 + Math.random() * 90000000)}`,
          services: services,
        });
      }

    return mockRows;
}