import React, { useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'
import Scrollbar from '../../atoms/Scrollbar'
import PopupEditPayWage from '../popups/PopupEditPayWage'
import api from '../../api'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  padding: 0.5rem 1rem;
  flex: 1;
  overflow: hidden;
`
const TextDate = styled.div`
  background: #98a1b2;
  border-radius: 12px;
  font-size: 0.75rem;
  color: #ffffff;
  text-align: center;
  width: fit-content;
  padding: 0 0.5rem;
  height: fit-content;
  /* margin-top: 1rem; */
`
const BoxCommission = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const BoxCommissionStyle = styled.div`
  border-radius: 4px;
  /* margin-top: ${props => props.marginTop || '0.75rem'}; */
  border: ${props => props.border || ''};
  border-bottom: ${props => props.borderBottom || '1px solid #E5ECED'};
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  align-items: center;
  justify-content: ${props => props.justifyContent || 'unset'};
  width: 100%;
  gap: 0.5rem;
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${props => props.column || '2fr 1fr 0.5fr'};
  width: 100%;
  gap: 0.5rem;
  align-items: ${props => props.alignItems || 'unset'};
`
const BoxEdit = styled.div`
  /* background-color: #f8f9fb; */
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  cursor: pointer;
  position: relative;
`
const Options = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0.5rem;
  position: absolute;
  box-shadow: 0px 18.0703px 30.1172px rgba(107, 107, 107, 0.16);
  top: 2.5rem;
  /* right: 0.25rem; */
`
const Circle = styled.div`
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: #d0d4dd;
  cursor: pointer;
`

const CardHistory = ({
  activeStaff,
  updateStaffs: _updateStaffs,
  currentUserType,
}) => {
  const { t } = useTranslation()

  const [activeDateIndex, setActiveDateIndex] = useState(-1)
  const [activeData, setActiveData] = useState({ dateIndex: -1 })

  const convertDate = date => {
    return `${dayjs(date).format('dddd')}, ${dayjs(date).format('DD MMM YYYY')}`
  }

  const onEditClick = e => {
    e.stopPropagation()
    setActiveData({
      staffId: activeStaff.staffId,
      staffName: activeStaff.staffName,
      dateIndex: activeDateIndex,
      ...activeStaff.date[activeDateIndex],
    })
  }

  const updateStaffs = async activeData => {
    try {
      const { success } = await api.updateAllOverdue(activeData)
      if (success) {
        _updateStaffs(success)
        setActiveDateIndex(-1)
        setActiveData({ dateIndex: -1 })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        <TextLabel fontSize='1.05rem' fontWeight='500'>
          {t('serviceHistory')}
        </TextLabel>
        <Scrollbar
          className='scrollbar-pay-wage-card-history'
          maxHeight='calc(100vh - 80px - 1rem - 20vh)'
        >
          {activeStaff.staffId !== -1 && (
            <>
              {activeStaff.date.map(
                (
                  {
                    date,
                    staffCommissions,
                    baseCommission,
                    commission,
                    requestRate,
                  },
                  index
                ) => (
                  <BoxCommission>
                    <GridBox
                      className='mt-4'
                      column={
                        currentUserType.typeId === 0 ||
                        currentUserType.typeId === 1
                          ? '2fr 1fr 0.5fr'
                          : '2fr 1fr'
                      }
                      alignItems='start'
                    >
                      <TextDate>{convertDate(date)}</TextDate>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextLabel
                          textAlign='end'
                          fontWeight='500'
                          fontSize='0.875rem'
                          color={
                            commission + requestRate > baseCommission
                              ? '#8C56D9'
                              : ''
                          }
                        >
                          {`${t('totalWage')} : ${commission + requestRate}`}
                        </TextLabel>
                        <TextLabel
                          textAlign='end'
                          fontWeight='500'
                          fontSize='0.875rem'
                          color={
                            commission + requestRate < baseCommission
                              ? '#8C56D9'
                              : ''
                          }
                        >
                          {`${t('minimumWagePerDay')} : ${baseCommission}`}
                        </TextLabel>
                      </div>
                      {(currentUserType.typeId === 0 ||
                        currentUserType.typeId === 1) && (
                        <BoxEdit
                          onClick={() =>
                            setActiveDateIndex(
                              activeDateIndex === index ? -1 : index
                            )
                          }
                        >
                          {Array.from({ length: 3 }).map(() => (
                            <Circle></Circle>
                          ))}
                          {activeDateIndex === index && (
                            <Options onClick={e => onEditClick(e)}>
                              {t('edit')}
                            </Options>
                          )}
                        </BoxEdit>
                      )}
                    </GridBox>
                    {staffCommissions.map(
                      ({
                        service,
                        serviceTime,
                        commissionFee,
                        requestRate,
                        requestStaff,
                      }) => (
                        <BoxCommissionStyle>
                          <GridBox
                            column={
                              currentUserType.typeId === 0 ||
                              currentUserType.typeId === 1
                                ? '2fr 1fr 0.5fr'
                                : '2fr 1fr'
                            }
                          >
                            <TextLabel>{`${service} ${serviceTime} นาที`}</TextLabel>
                            <TextLabel textAlign='end'>
                              {utils.formatNumber(commissionFee || 0, 2)}
                            </TextLabel>
                            {/* <BoxEdit>
                              {Array.from({ length: 3 }).map(() => (
                                <Circle></Circle>
                              ))}
                            </BoxEdit> */}
                          </GridBox>
                          {requestStaff && (
                            <GridBox
                              column={
                                currentUserType.typeId === 0 ||
                                currentUserType.typeId === 1
                                  ? '2fr 1fr 0.5fr'
                                  : '2fr 1fr'
                              }
                            >
                              <TextLabel>{`${t('requestFee')}`}</TextLabel>
                              <TextLabel textAlign='end'>
                                {utils.formatNumber(requestRate || 0, 2)}
                              </TextLabel>
                              {/* <BoxEdit>
                                {Array.from({ length: 3 }).map(() => (
                                  <Circle></Circle>
                                ))}
                              </BoxEdit> */}
                            </GridBox>
                          )}
                        </BoxCommissionStyle>
                      )
                    )}
                  </BoxCommission>
                )
              )}
            </>
          )}
        </Scrollbar>
      </Card>
      {activeDateIndex !== -1 && activeData.dateIndex !== -1 && (
        <PopupEditPayWage
          activeData={activeData}
          updateStaffs={updateStaffs}
          handleClose={() => {
            setActiveDateIndex(-1)
            setActiveData({ dateIndex: -1 })
          }}
        />
      )}
    </Container>
  )
}

export default CardHistory
