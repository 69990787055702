import React from "react"

const EMail = ({ size, color }) => (
  <svg
    width={size || 29}
    height={size || 22}
    viewBox='0 0 29 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M26.2812 0H2.71877C1.21955 0 0 1.23356 0 2.75002V19.25C0 20.7664 1.21955 22 2.71877 22H26.2812C27.7805 22 29 20.7664 29 19.25V2.75002C29 1.23356 27.7805 0 26.2812 0ZM26.2812 1.83332C26.4044 1.83332 26.5215 1.85916 26.6286 1.90429L14.5 12.537L2.37136 1.90429C2.47844 1.85922 2.59558 1.83332 2.71871 1.83332H26.2812ZM26.2812 20.1666H2.71877C2.21875 20.1666 1.81249 19.7558 1.81249 19.2499V3.84122L13.9061 14.4428C14.077 14.5923 14.2885 14.6666 14.5 14.6666C14.7115 14.6666 14.923 14.5924 15.0939 14.4428L27.1875 3.84122V19.25C27.1874 19.7558 26.7813 20.1666 26.2812 20.1666Z'
      fill={color || "#4E4E72"}
    />
  </svg>
)

export default EMail
