import { Notification } from './Notifications'

export const ReportTypes = Object.freeze({
  Default: 0,
  Yu: 1,
})

export type ReportType = (typeof ReportTypes)[keyof typeof ReportTypes]

export default interface Store {
  _id: number
  storeId: string
  thaiHandStoreId: string
  name: string
  branchId: number
  branch: string
  address: string
  latitude: string
  longitude: string
  phoneNumber: number
  email: string
  facebook: string
  lineId: string
  imageStore: string
  endDate: Date | undefined
  status: number
  brandId: number
  reportType: number
}

export const getInitialStore = (): Store => ({
  _id: -1,
  storeId: '',
  thaiHandStoreId: '',
  name: '',
  branchId: -1,
  branch: '',
  address: '',
  latitude: '',
  longitude: '',
  phoneNumber: -1,
  email: '',
  facebook: '',
  lineId: '',
  imageStore: '',
  endDate: undefined,
  status: -1,
  brandId: -1,
  reportType: ReportTypes.Default,
})

export interface StoreState extends Store {
  notifications: Notification[]
  notificationCount: 0
}
