import React from 'react'
import styled from 'styled-components'

//@ts-ignore
import Body from './Body'

import Page from '../../lib/layout/Page/Page'
import usePage from '../../lib/layout/Page/usePage'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Customer = () => {
  const { search } = usePage()

  return (
    <Page>
      <Container>
        <Body search={search} />
      </Container>
    </Page>
  )
}

export default Customer
