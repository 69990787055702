import React from 'react'

const ClockIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6ZM0 8C0 3.58172 3.58172 0 8 0C12.4182 0 16 3.58172 16 8C16 12.4182 12.4182 16 8 16C3.58172 16 0 12.4182 0 8ZM7.86272 3.80589C8.30456 3.80589 8.66272 4.16406 8.66272 4.60589V8.5796L11.4842 9.65224C11.8973 9.8092 12.1048 10.2713 11.9478 10.6842C11.7908 11.0973 11.3287 11.3048 10.9158 11.1478L7.57848 9.8792C7.268 9.76112 7.06272 9.46352 7.06272 9.13136V4.60589C7.06272 4.16406 7.42088 3.80589 7.86272 3.80589Z'
        fill='#8C56D9'
      />
    </svg>
  )
}

export default ClockIcon
