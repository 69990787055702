import React from 'react'

const DeleteIcon = ({ size, color, onClick }) => (
  <svg
    onClick={onClick}
    width={size || '30'}
    height={size || '36'}
    viewBox='0 0 30 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M26.4375 4.5H20.25V3.375C20.25 1.51404 18.736 0 16.875 0H12.375C10.514 0 9 1.51404 9 3.375V4.5H2.8125C1.26169 4.5 0 5.76169 0 7.3125V11.25C0 11.8713 0.503719 12.375 1.125 12.375H1.73981L2.71174 32.7855C2.79759 34.5881 4.27837 36 6.08287 36H23.1671C24.9717 36 26.4525 34.5881 26.5383 32.7855L27.5102 12.375H28.125C28.7463 12.375 29.25 11.8713 29.25 11.25V7.3125C29.25 5.76169 27.9883 4.5 26.4375 4.5ZM11.25 3.375C11.25 2.7547 11.7547 2.25 12.375 2.25H16.875C17.4953 2.25 18 2.7547 18 3.375V4.5H11.25V3.375ZM2.25 7.3125C2.25 7.00235 2.50235 6.75 2.8125 6.75H26.4375C26.7476 6.75 27 7.00235 27 7.3125V10.125C26.6533 10.125 3.6867 10.125 2.25 10.125V7.3125ZM24.2908 32.6785C24.2622 33.2793 23.7686 33.75 23.1671 33.75H6.08287C5.48135 33.75 4.98776 33.2793 4.95921 32.6785L3.99234 12.375H25.2577L24.2908 32.6785Z'
      fill={color || '#57557F'}
    />

    <path
      d='M14.625 31.5C15.2463 31.5 15.75 30.9963 15.75 30.375V15.75C15.75 15.1287 15.2463 14.625 14.625 14.625C14.0037 14.625 13.5 15.1287 13.5 15.75V30.375C13.5 30.9963 14.0036 31.5 14.625 31.5Z'
      fill={color || '#57557F'}
    />

    <path
      d='M20.25 31.5C20.8713 31.5 21.375 30.9963 21.375 30.375V15.75C21.375 15.1287 20.8713 14.625 20.25 14.625C19.6287 14.625 19.125 15.1287 19.125 15.75V30.375C19.125 30.9963 19.6286 31.5 20.25 31.5Z'
      fill={color || '#57557F'}
    />
    <path
      d='M8.99951 31.5C9.62079 31.5 10.1245 30.9963 10.1245 30.375V15.75C10.1245 15.1287 9.62079 14.625 8.99951 14.625C8.37823 14.625 7.87451 15.1287 7.87451 15.75V30.375C7.87451 30.9963 8.37816 31.5 8.99951 31.5Z'
      fill={color || '#57557F'}
    />

    <defs>
      <linearGradient
        id='paint0_linear'
        x1='-13.7373'
        y1='18'
        x2='21.055'
        y2='44.5528'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color || '#57557F'} />

        <stop offset='1' stopColor={color || '#8E759F'} />
      </linearGradient>
      <linearGradient
        id='paint1_linear'
        x1='12.4433'
        y1='23.0625'
        x2='16.613'
        y2='23.5847'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color || '#57557F'} />

        <stop offset='1' stopColor={color || '#8E759F'} />
      </linearGradient>
      <linearGradient
        id='paint2_linear'
        x1='18.0683'
        y1='23.0625'
        x2='22.238'
        y2='23.5847'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color || '#57557F'} />
        <stop offset='1' stopColor={color || '#8E759F'} />
      </linearGradient>
      <linearGradient
        id='paint3_linear'
        x1='6.8178'
        y1='23.0625'
        x2='10.9875'
        y2='23.5847'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color || '#57557F'} />

        <stop offset='1' stopColor={color || '#8E759F'} />
      </linearGradient>
    </defs>
  </svg>
)

export default DeleteIcon
