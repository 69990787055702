import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'
import TextLabel from '../../atoms/TextLabel'
import SelectorPayChannelType from './SelectorPayChannelType'
import useOnClickOutside from '../../hook/useOnClickOutside'
import CardAddCredit from './CardAddCredit'
import CardAddAccount from './CardAddAccount'
import CardAddOnline from './CardAddOnline'
import SwitchButton from '../../atoms/SwitchButton'
import utils from '../../utils'
import api from '../../api'
import auth from '../../auth'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import CardAddAgency from './CardAddAgency'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
`
const BoxSwitch = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
`
const BoxSwitchStyle = styled.div`
  width: 50%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`

const CardAddPayChannel = ({
  handleClose,
  currentIdEdit = -1,
  currentPayChannel,
  activeAdd = -1,
  payChannels,
}) => {
  const payChannelGroup = [
    // { payChannelTypeId: 1, payChannelType: 'เงินสด' },
    { payChannelTypeId: 2, payChannelType: 'บัตรเครดิต' },
    { payChannelTypeId: 3, payChannelType: 'เงินโอน' },
    { payChannelTypeId: 4, payChannelType: 'ออนไลน์' },
    // { payChannelTypeId: 5, payChannelType: 'เอเจนซี่' },
  ]
  const wrapperRef = useRef(null)
  const [fileImage, setFileImage] = useState('')
  const [defaultOption, setDefaultOption] = useState(payChannelGroup[0])
  const [openArrow, setOpenArrow] = useState(false)
  const [payChannel, setPayChannel] = useState({
    payChannel: '',
    image: '',
    account: { accountNumber: '', promptPay: '' },
  })
  const [status, setStatus] = useState(true)
  const [defaultBank, setDefaultBank] = useState({
    value: '1',
    label: 'ธนาคารกสิกรไทย',
  })
  const [checkName, setCheckName] = useState(false)
  const [checkAccountNumber, setCheckAccountNumber] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (currentIdEdit !== -1) {
      const payChannelGroupIndex = payChannelGroup.findIndex(
        p => p.payChannelTypeId === currentPayChannel.payChannelTypeId || ''
      )

      if (payChannelGroupIndex !== -1) {
        setDefaultOption(payChannelGroup[payChannelGroupIndex])
      }
      if (currentPayChannel.payChannelTypeId === 3) {
        setPayChannel({
          ...currentPayChannel,
        })
      } else if (currentPayChannel.payChannelTypeId === 5) {
        setPayChannel({
          ...currentPayChannel,
          account: { accountNumber: '', promptPay: '' },
        })
      } else {
        setPayChannel({
          ...currentPayChannel,
          account: { accountNumber: '', promptPay: '' },
        })
      }

      setStatus(currentPayChannel.status !== -1)
    }
  }, [currentIdEdit, currentPayChannel])

  useOnClickOutside(wrapperRef, () => {
    setOpenArrow(false)
  })

  const onArrowClick = () => {
    setOpenArrow(v => !v)
  }

  const onSelectDefaultOptionClick = option => {
    setDefaultOption(option)
  }

  const onChangeImage = async event => {
    const newPayChannel = { ...payChannel }
    const file = event.target.files[0]
    setFileImage(file)
    if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
      alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
    } else if (utils.isFileExceedLimit(file)) {
      alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
    } else {
      const image = await utils.readImage(file)
      newPayChannel.image = image.image
      setPayChannel(newPayChannel)
    }
  }

  const onNameChange = value => {
    const newPayChannel = { ...payChannel }
    newPayChannel.payChannel = value
    setPayChannel(newPayChannel)
  }

  const onAccountNumberChange = value => {
    const newPayChannel = { ...payChannel }
    if (utils.checkNumber(value)) {
      newPayChannel.account.accountNumber = value
    } else if (!value) {
      newPayChannel.account.accountNumber = value
    }
    setPayChannel(newPayChannel)
  }

  const onPromptPayChange = value => {
    const newPayChannel = { ...payChannel }
    if (utils.checkNumber(value)) {
      newPayChannel.account.promptPay = value
    } else if (!value) {
      newPayChannel.account.promptPay = value
    }
    setPayChannel(newPayChannel)
  }

  const onSelectDefaultBankClick = option => {
    setDefaultBank(option)
  }

  const onSaveClick = async () => {
    try {
      let body = {
        ...payChannel,
        status: status ? 1 : -1,
        payChannelTypeId: defaultOption.payChannelTypeId,
      }
      if (defaultOption.payChannelTypeId === 3) {
        if (!payChannel.account.accountNumber) {
          setCheckAccountNumber(true)
          return false
        } else {
          body = {
            ...body,
            account: {
              bankId: defaultBank.value,
              bank: defaultBank.label,
              accountName: payChannel.payChannel,
              accountNumber: payChannel.account.accountNumber,
              promptPay: payChannel.account.promptPay,
            },
          }
        }
      } else if (defaultOption.payChannelTypeId === 5) {
        body = {
          ...body,
        }
      } else {
        if (!payChannel.payChannel) {
          setCheckName(true)
          return false
        }
      }

      if (fileImage) {
        const { data, success } = await api.uploadImagePayChannel({
          token: auth.getToken(),
          image: fileImage,
        })
        if (success) {
          body = { ...body, image: data }
        }
      }

      if (currentIdEdit === -1) {
        await callApiCreatePayChannel({
          ...body,
          payChannelId: payChannels.length,
          storeId: auth.getStoreId(),
        })
      } else {
        await callApiUpdatePayChannel(body)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreatePayChannel = async body => {
    try {
      const { success } = await api.createPayChannel(body)
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const callApiUpdatePayChannel = async body => {
    try {
      const { success } = await api.updatePayChannel(payChannel._id, body)
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <TextLabel fontWeight='600' fontSize='1.2rem'>
        ช่องทางการจ่ายเงิน
      </TextLabel>
      <Scrollbar id='setting-card-addPayChannel'>
        <div className='mt-2'></div>
        <SelectorPayChannelType
          wrapperRef={wrapperRef}
          defaultOption={defaultOption}
          options={payChannelGroup}
          onArrowClick={onArrowClick}
          openArrow={openArrow}
          onSelectDefaultOptionClick={onSelectDefaultOptionClick}
        />
        <div className='mb-3'></div>
        {defaultOption.payChannelTypeId === 2 && (
          <CardAddCredit
            currentIdEdit={currentIdEdit}
            currentPayChannel={currentPayChannel}
            payChannel={payChannel}
            onNameChange={onNameChange}
            onChangeImage={onChangeImage}
            checkName={checkName}
          />
        )}
        {defaultOption.payChannelTypeId === 3 && (
          <CardAddAccount
            currentIdEdit={currentIdEdit}
            currentPayChannel={currentPayChannel}
            payChannel={payChannel}
            onNameChange={onNameChange}
            onAccountNumberChange={onAccountNumberChange}
            onPromptPayChange={onPromptPayChange}
            onSelectDefaultBankClick={onSelectDefaultBankClick}
            defaultBank={defaultBank}
            checkAccountNumber={checkAccountNumber}
          />
        )}
        {defaultOption.payChannelTypeId === 4 && (
          <CardAddOnline
            currentIdEdit={currentIdEdit}
            currentPayChannel={currentPayChannel}
            payChannel={payChannel}
            onNameChange={onNameChange}
            onChangeImage={onChangeImage}
            checkName={checkName}
          />
        )}
        {defaultOption.payChannelTypeId === 5 && (
          <CardAddAgency
            currentIdEdit={currentIdEdit}
            currentPayChannel={currentPayChannel}
            payChannel={payChannel}
            onNameChange={onNameChange}
            onChangeImage={onChangeImage}
            checkName={checkName}
          />
        )}
        <BoxSwitch className='mt-3'>
          <BoxSwitchStyle flexDirection='column'>
            <TextLabel fontWeight='600'>สถานะ</TextLabel>
            <TextLabel color='#98A1B2' fontSize='0.8rem'>
              ไม่ใช้งาน/ใช้งาน
            </TextLabel>
          </BoxSwitchStyle>
          <BoxSwitchStyle alignItems='center' justifyContent='flex-end'>
            <SwitchButton
              name='stats-payChannel'
              toggled={status}
              onChange={() => setStatus(!status)}
              backgroundColor={status ? '#8C56D9' : '#ABABAD'}
              // labelHeight='20px'
              // width='40%'
              // labelWidth='30%'
              width='18px'
              labelHeight='20px'
              labelWidth='40px'
            />
          </BoxSwitchStyle>
        </BoxSwitch>
        <div
          className='mt-0'
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            justifyContent: 'flex-end',
            position: 'sticky',
            bottom: '0rem',
            background: 'white',
            zIndex: '2',
          }}
        >
          <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
            <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
              <Button2
                color='#98A1B2'
                border='2px solid #98A1B2'
                backgroundColor='white'
                fontWeight='600'
                onClick={handleClose}
              >
                ยกเลิก
              </Button2>
            </div>
            <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
              <Button2
                border='2px solid #53389E'
                color='#FFFFFF'
                backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                fontWeight='600'
                icon={<SaveIcon size='15' />}
                onClick={() => onSaveClick()}
              >
                บันทึก
              </Button2>
            </div>
          </div>
        </div>
      </Scrollbar>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddPayChannel
