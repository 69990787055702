import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import api from '../../api'
import BodyCustomer from './BodyCustomer'
import PopupAddCustomer from '../popups/PopupAddCustomer'
import PopupAddNationality from '../popups/PopupAddNationality'
import PopupConfirmDelete from '../popups/PopupConfirmDelete'
import ButtonMenu from './ButtonMenu'
import BodyNationality from './BodyNationality'
import config from '../../config'
import PopupAddOccupation from '../popups/PopupAddOccupation'
import BodyOccupation from './BodyOccupation'
import { fetchBrandData } from '../../redux/actions/brandAction'
import auth from '../../auth'
import Button2 from '../../atoms/Button2'
import { UserTypes, getCurrentStoreRole } from '../../redux/models/User'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100%);
  overflow: hidden;
`

const Body = ({
  create,
  onCreateClick,
  handleClose: _handleClose,
  onMenuClick: _onMenuClick,
  activeMenu,
  activeAddData,
}) => {
  const [customers, setCustomers] = useState([])
  const [currentCustomer, setCurrentCustomer] = useState('')
  const [currentDeleteCustomer, setCurrentDeleteCustomer] = useState('')
  const [currentNationality, setCurrentNationality] = useState('')
  const [currentOccupation, setCurrentOccupation] = useState('')
  const [activeDelete, setActiveDelete] = useState(false)
  const [success, setSuccess] = useState(false)
  const [search, setSearch] = useState('')

  const user = useSelector(state => state.user)
  const role = getCurrentStoreRole(user)
  const { nationalities, occupations } = useSelector(state => state.brand)
  const dispatch = useDispatch()

  useEffect(() => {
    callApiCustomer()
  }, [])

  const callApiCustomer = async () => {
    try {
      const { data } = await api.getCustomerAll()
      setCustomers(data)
    } catch (err) {
      console.log(err)
    }
  }

  const onSelectCustomer = _id => {
    const findCustomer = customers.find(c => c._id === _id || '') || ''

    if (findCustomer) {
      const findNationality =
        nationalities.find(n => n._id === (findCustomer.nationalityId || -1)) ||
        ''
      const findOccupation =
        occupations.find(o => o._id === (findCustomer.occupationId || -1)) || ''
      setCurrentCustomer({
        ...findCustomer,
        nationality: {
          value: findNationality._id || -1,
          label: findNationality.nationality || 'สัญชาติ',
        },
        occupation: {
          value: findOccupation._id || -1,
          label: findOccupation.occupation || 'อาชีพ',
        },
        age: {
          value: findCustomer.age,
          label:
            findCustomer.age === -1 ? 'อายุ' : config.AGE[findCustomer.age - 1],
        },
        gender: {
          value: findCustomer.gender,
          label:
            findCustomer.gender === -1
              ? 'ไม่ระบุ'
              : findCustomer.gender === 1
              ? 'ชาย'
              : findCustomer.gender === 2
              ? 'หญิง'
              : 'อื่นๆ',
        },
      })
    }
  }

  const handleClose = () => {
    callApiCustomer()
    dispatch(fetchBrandData(auth.getBrandId()))
    setCurrentCustomer('')
    setCurrentNationality('')
    setCurrentOccupation('')
    onCreateClick(false)
    setActiveDelete(false)
    setSuccess(false)
    _handleClose()
  }

  const onDeleteClick = _id => {
    const findCustomer = customers.find(c => c._id === _id || '') || ''
    if (findCustomer) {
      setCurrentDeleteCustomer(findCustomer)
      setActiveDelete(true)
    }
  }

  const onConfirmDeleteClick = async () => {
    try {
      const { success } = await api.removeCustomer(currentDeleteCustomer._id)
      if (success) {
        await callApiCustomer()
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onMenuClick = index => {
    _onMenuClick(index)
  }

  const onSearchChange = value => {
    setSearch(value)
  }

  const onEditClick = (_id, type) => {
    if (type === 'nationality') {
      const findNationality = nationalities.find(n => n._id === _id)
      if (findNationality) {
        setCurrentNationality(findNationality)
      }
    } else if (type === 'occupation') {
      const findOccupation = occupations.find(o => o._id === _id)
      if (findOccupation) {
        setCurrentOccupation(findOccupation)
      }
    }
  }

  const onChangeFile = async event => {
    try {
      const file = event.target.files[0]
      const { success } = await api.uploadCustomerData(file)
      console.log({ success })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      {role === UserTypes.Admin && (
        <Button2 width='fit-content' backgroundColor='pink' padding='0.5rem'>
          <label for='upload-image'>ดาวโหลดข้อมูล</label>
          <input
            id='upload-image'
            type='file'
            onChange={onChangeFile}
            style={{ display: 'none', visibility: 'none' }}
          />
        </Button2>
      )}
      <ButtonMenu
        onMenuClick={onMenuClick}
        onSearchChange={onSearchChange}
        search={search}
      />
      {activeMenu === 0 && (
        <BodyCustomer
          customers={customers}
          onSelectCustomer={onSelectCustomer}
          search={search}
          onDeleteClick={onDeleteClick}
        />
      )}
      {activeMenu === 1 && (
        <BodyNationality
          nationalities={nationalities}
          onEditClick={onEditClick}
        />
      )}
      {activeMenu === 2 && (
        <BodyOccupation occupations={occupations} onEditClick={onEditClick} />
      )}
      {activeMenu === 0 && (activeAddData || currentCustomer) && (
        <PopupAddCustomer
          handleClose={handleClose}
          currentCustomer={currentCustomer}
        />
      )}
      {activeMenu === 1 && (activeAddData || currentNationality) && (
        <PopupAddNationality
          handleClose={handleClose}
          currentNationality={currentNationality}
        />
      )}
      {activeMenu === 2 && (activeAddData || currentOccupation) && (
        <PopupAddOccupation
          handleClose={handleClose}
          currentOccupation={currentOccupation}
        />
      )}
      {activeMenu === 0 && activeDelete && (
        <PopupConfirmDelete
          handleClose={handleClose}
          onConfirmDeleteClick={onConfirmDeleteClick}
          text='ยืนยันการลบลูกค้า'
          success={success}
        />
      )}
    </Container>
  )
}

export default Body
