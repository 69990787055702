import React from 'react'
import { IconProps } from './IconPropsType'

const ReportMenuIcon = ({ size, color = '' }: IconProps) => (
  <svg
    width={size || '20'}
    height={size || '21'}
    viewBox='0 0 20 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2318_2253)'>
      <path
        d='M14.4118 0.410645H5.58824C2.5 0.410645 0 2.91064 0 5.99888V14.8224C0 17.9106 2.5 20.4106 5.58824 20.4106H14.4118C17.5 20.4106 20 17.9106 20 14.8224V5.99888C20 2.91064 17.5 0.410645 14.4118 0.410645ZM18.2353 14.8224C18.2353 16.9401 16.5294 18.6459 14.4118 18.6459H5.58824C3.47059 18.6459 1.76471 16.9401 1.76471 14.8224V5.99888C1.76471 3.88123 3.47059 2.17535 5.58824 2.17535H14.4118C16.5294 2.17535 18.2353 3.88123 18.2353 5.99888V14.8224Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M5.58829 13.646C5.08829 13.646 4.70593 14.0283 4.70593 14.5283V15.9989C4.70593 16.4989 5.08829 16.8813 5.58829 16.8813C6.08829 16.8813 6.47064 16.4989 6.47064 15.9989V14.5283C6.47064 14.0283 6.08829 13.646 5.58829 13.646Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M10 11.5872C9.50003 11.5872 9.11768 11.9695 9.11768 12.4695V15.9989C9.11768 16.4989 9.50003 16.8813 10 16.8813C10.5 16.8813 10.8824 16.4989 10.8824 15.9989V12.4695C10.8824 11.9695 10.5 11.5872 10 11.5872Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M14.4118 10.1166C13.9118 10.1166 13.5294 10.4989 13.5294 10.9989V15.9989C13.5294 16.4989 13.9118 16.8813 14.4118 16.8813C14.9118 16.8813 15.2941 16.4989 15.2941 15.9989V10.9989C15.2941 10.4989 14.9118 10.1166 14.4118 10.1166Z'
        fill={color || '#8C56D9'}
      />
      <path
        d='M15 3.94006H12.353C11.853 3.94006 11.4706 4.32242 11.4706 4.82242C11.4706 5.32242 11.853 5.70477 12.353 5.70477H12.9118C10.0588 8.35183 4.73531 10.4401 4.67648 10.4695C4.23531 10.6459 4.00001 11.1459 4.17648 11.6165C4.32354 11.9695 4.64707 12.1754 5.00001 12.1754C5.11766 12.1754 5.2059 12.1459 5.32354 12.1165C5.58825 12.0283 11 9.88124 14.1177 6.99889V7.46948C14.1177 7.96948 14.5 8.35183 15 8.35183C15.5 8.35183 15.8824 7.96948 15.8824 7.46948V4.82242C15.8824 4.32242 15.5 3.94006 15 3.94006Z'
        fill={color || '#8C56D9'}
      />
    </g>
    <defs>
      <clipPath id='clip0_2318_2253'>
        <rect
          width='20'
          height='20'
          fill='white'
          transform='translate(0 0.410645)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default ReportMenuIcon
