import React from 'react'
import { IconBaseProps } from 'react-icons'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
`

const AddDataIcon = ({ size, color, onClick }: IconBaseProps) => {
  return (
    <SVG
      width={size || '32'}
      height={size || '32'}
      onClick={onClick}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.7'>
        <path
          d='M16 0C7.16444 0 0 7.16444 0 16C0 24.8356 7.16444 32 16 32C24.8356 32 32 24.8356 32 16C32 7.16444 24.8356 0 16 0ZM22.2267 17.3333H17.3333V22.2267C17.3333 22.9644 16.7378 23.56 16 23.56C15.6311 23.56 15.2978 23.4089 15.0578 23.1689C14.8178 22.9289 14.6667 22.5956 14.6667 22.2267V17.3333H9.77333C9.40444 17.3333 9.07111 17.1822 8.83111 16.9422C8.59111 16.7022 8.44 16.3689 8.44 16C8.44 15.2622 9.03556 14.6667 9.77333 14.6667H14.6667V9.77333C14.6667 9.03556 15.2622 8.44 16 8.44C16.7378 8.44 17.3333 9.03556 17.3333 9.77333V14.6667H22.2267C22.9644 14.6667 23.56 15.2622 23.56 16C23.56 16.7378 22.9644 17.3333 22.2267 17.3333Z'
          fill={color || 'white'}
        />
      </g>
    </SVG>
  )
}

export default AddDataIcon
