import { TimePrice } from './Service'

import { Enums } from '../../utilities/typing'

export interface Age {
  value: number
  label: string
}

export interface Nationality {
  value: number
  label: string
}

export const PromotionUnits = {
  Percent: 1,
  Amount: 2,
} as const

export type PromotionUnit = Enums<typeof PromotionUnits>

export interface ServiceOrder {
  time: number
  price: number
  serviceId: number
  serviceMasterId: string
  serviceDiscount: number
  unit: PromotionUnit
  serviceName: string
  serviceImage: string
  serviceError?: boolean
  activeTimePriceIndex: number
  serviceCustomer: number
  primaryCustomerIndex: primaryCustomerIndexOrder
  serviceRelatedCustomers: number[]
  timePrices: TimePrice[]
  applyToAll: boolean
  staffs: StaffOrder[]
  rooms: RoomOrder[]
}

interface primaryCustomerIndexOrder {
  customerIndex: number
  serviceIndex: number
}

export interface StaffOrder {
  staffTypeId: number
  staffId: number
  staffName: string
  staffImage: string
  requestStaff: boolean
  queueFree: boolean
  duration: number
  tip: number
}

export interface RoomOrder {
  order: number
  roomTypeId: number
  roomTypeName: string
  roomTypeCode: string
  roomId: number
  roomName: string
  roomCode: string
  private: number
  duration: number
  detail: string
}

export interface Order {
  customer: number
  customerId: number
  name: string
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  address: string
  taxIDNumber: string
  gender: number
  age: Age
  nationality: Nationality
  services: ServiceOrder[]
  specialRequest: string
}

export interface BookingType {
  bookingTypeId: number
  bookingType: string
}

export interface BookingTypeOrder {
  bookingTypeId: number
  bookingType: string
}

export interface BookingChannel {
  bookingChannelId: number
  bookingChannel: string
  code: string
  commissionPercentage: number
}

export interface BookingChannelOrder {
  bookingChannelId: number
  bookingChannel: string
  code: string
  commission: number
}

export interface Time {
  hour: string | number
  minute: string | number
}

export interface PayChannel {
  payChannelTypeId: number
  payChannelId: Number
  payChannel: string
  note: string
  quantity: number
  chargePercentage: number
  price: number
  remainingValue?: number
}

export interface CustomerOrder {
  customer: number
  customerId: number
  name: string
  firstName: string
  lastName: string
  countryCode: string
  phoneNumber: string
  email: string
  address: string
  taxIDNumber: string
  gender: number
  age: Age
  nationality: Nationality
  services: ServiceOrder[]
  specialRequest: string
}

export interface ProductOrder {
  _id: number
  name: string
  image: string
  price: number
  quantity: number
}

export interface BookOrder {
  _id: number
  customerId: number
  name: string
  firstName: string
  lastName: string
  email: string
  countryCode: string
  phoneNumber: string
  address: string
  taxIDNumber: string
  specialRequest: string
  note: string
  products: ProductOrder[]
  promotions: any[]
  packages: any[]
  date: Date
  time: Time
  startTime: Time
  endTime: Time
  bookingType: BookingType
  bookingChannel: BookingChannel
  bookingCode: string
  discount: number
  serviceDiscount: number
  packageDiscount: number
  charge: number
  commission: number
  tipBox: number
  totalTip: number
  payStatus: number
  payChannels: PayChannel[]
  edit: boolean
  requireCustomerDetail: boolean
  activeBtnPayChannelType: number
  reorder: boolean
  checkCustomerAndService: any[]
  customers: CustomerOrder[]
  countCustomer: number
  serviceCount: number
  productCount: number
  packageCount: number
  serviceTime: number
  total: number
  statusId: number
  instantId: number
  buyPackage: boolean
  instant: boolean
  buyProduct: boolean
  focusedCustomerIndex: number
  selectStaff: boolean
  transactionType: number
}

export const getInitialBookOrder = (): BookOrder => ({
  _id: -1,
  customerId: -1,
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  countryCode: '',
  phoneNumber: '',
  address: '',
  taxIDNumber: '',
  specialRequest: '',
  note: '',
  products: [],
  promotions: [],
  packages: [],
  date: new Date(),
  time: { hour: '', minute: '' },
  startTime: { hour: '', minute: '' },
  endTime: { hour: '', minute: '' },
  bookingType: {
    bookingTypeId: 1,
    bookingType: 'Walk-in',
  },
  bookingChannel: {
    bookingChannelId: -1,
    bookingChannel: 'walk-in',
    code: '',
    commissionPercentage: 0,
  },
  bookingCode: '',
  discount: 0,
  serviceDiscount: 0,
  packageDiscount: 0,
  charge: 0,
  commission: 0,
  total: 0,
  tipBox: 0,
  totalTip: 0,
  payStatus: -1,
  payChannels: [],
  edit: false,
  statusId: 1,
  requireCustomerDetail: true,
  activeBtnPayChannelType: 0,
  reorder: false,
  instant: false,
  instantId: -1,
  buyPackage: false,
  checkCustomerAndService: [],
  focusedCustomerIndex: 0,
  buyProduct: false,
  customers: [
    {
      customer: 1,
      customerId: -1,
      name: '',
      firstName: '',
      lastName: '',
      countryCode: '+66',
      phoneNumber: '',
      email: '',
      address: '',
      taxIDNumber: '',
      gender: -1,
      age: { value: -1, label: 'ไม่ระบุ' },
      nationality: { value: -1, label: 'อื่นๆ' },
      services: [
        {
          serviceMasterId: '',
          serviceId: -1,
          serviceName: '',
          serviceImage: '',
          serviceError: false,
          time: 0,
          price: 0,
          activeTimePriceIndex: 0,
          timePrices: [],
          serviceCustomer: 1,
          primaryCustomerIndex: {
            customerIndex: -1,
            serviceIndex: -1,
          },
          serviceRelatedCustomers: [],
          staffs: [],
          rooms: [],
          serviceDiscount: 0,
          applyToAll: true,
          unit: 1,
        },
      ],
      specialRequest: '',
    },
  ],
  serviceTime: 0,
  countCustomer: 1,
  serviceCount: 0,
  productCount: 0,
  packageCount: 0,
  selectStaff: false,
  transactionType: 1,
})

export const getInitialRoomBookOrder = (): RoomOrder => ({
  order: 1,
  roomTypeId: -1,
  roomTypeName: '',
  roomTypeCode: '',
  roomId: -1,
  roomName: '',
  roomCode: '',
  private: -1,
  duration: 0,
  detail: '',
})

export const getInitialServiceBookOrder = (): ServiceOrder => ({
  serviceMasterId: '',
  serviceId: -1,
  serviceName: '',
  serviceImage: '',
  serviceCustomer: 0,
  primaryCustomerIndex: {
    customerIndex: -1,
    serviceIndex: -1,
  },
  serviceRelatedCustomers: [],
  time: 0,
  price: 0,
  activeTimePriceIndex: 0,
  timePrices: [],
  applyToAll: false,
  staffs: [],
  rooms: [],
  serviceDiscount: 0,
  unit: 1,
})

export const getInitialCustomerBookOrder = (): CustomerOrder => ({
  customer: 1,
  customerId: -1,
  name: '',
  firstName: '',
  lastName: '',
  countryCode: '+66',
  phoneNumber: '',
  email: '',
  address: '',
  taxIDNumber: '',
  gender: -1,
  age: { value: -1, label: 'ไม่ระบุ' },
  nationality: { value: -1, label: 'อื่นๆ' },
  services: [
    {
      serviceMasterId: '',
      serviceId: -1,
      serviceName: '',
      serviceImage: '',
      serviceError: false,
      time: 0,
      price: 0,
      activeTimePriceIndex: 0,
      timePrices: [],
      serviceCustomer: 1,
      primaryCustomerIndex: {
        customerIndex: -1,
        serviceIndex: -1,
      },
      serviceRelatedCustomers: [],
      staffs: [],
      rooms: [],
      serviceDiscount: 0,
      applyToAll: true,
      unit: 1,
    },
  ],
  specialRequest: '',
})

export type PackageOrder = {
  remainingDuration: number
  remainingPrice: number
} & Pick<Order, 'customer' | 'name' | 'customerId'> &
  ServiceOrder
