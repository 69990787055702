import React from 'react'

const ImageSuccessIcon = ({ size, color }) => (
  <svg
    width={size || '32'}
    height={size || '32'}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M28.48 2.06452C29.28 2.06452 29.9355 2.71484 29.9355 3.52V22.2968L22.7355 29.9355H3.52C2.72 29.9355 2.06452 29.2852 2.06452 28.48V3.52C2.06452 2.72 2.71484 2.06452 3.52 2.06452H28.48ZM28.48 0H3.52C1.57419 0 0 1.57419 0 3.52V28.48C0 30.4258 1.57419 32 3.52 32H23.6284L32 23.1174V3.52C32 1.57419 30.4258 0 28.48 0Z'
      fill={color || '#8C56D9'}
    />
    <path
      d='M22.5948 31.2286C22.027 31.2286 21.5625 30.7641 21.5625 30.1963V24.7047C21.5625 22.7537 23.1522 21.1641 25.1031 21.1641H30.9044C31.4722 21.1641 31.9367 21.6286 31.9367 22.1963C31.9367 22.7641 31.4722 23.2286 30.9044 23.2286H25.1031C24.2877 23.2286 23.627 23.8892 23.627 24.7047V30.1963C23.627 30.7641 23.1625 31.2286 22.5948 31.2286Z'
      fill={color || '#8C56D9'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5078 14.9045C10.5078 11.8026 13.0214 9.28906 16.1233 9.28906C19.2252 9.28906 21.7388 11.8026 21.7388 14.9045C21.7388 18.0065 19.2252 20.52 16.1233 20.52C13.0214 20.52 10.5078 18.0065 10.5078 14.9045ZM15.0529 17.0844H15.0942C15.2748 17.0844 15.4503 17.0122 15.5845 16.8883L18.6916 13.967C18.9806 13.6986 18.9961 13.2444 18.7226 12.9554C18.4542 12.6664 18 12.6509 17.711 12.9244L15.1664 15.3193L14.5832 14.6018C14.3355 14.2973 13.8864 14.2509 13.5768 14.4986C13.2722 14.7464 13.2258 15.1954 13.4735 15.5051L14.5419 16.8212C14.671 16.976 14.8516 17.0689 15.0529 17.0844Z'
      fill={color || '#8C56D9'}
    />
  </svg>
)

export default ImageSuccessIcon
