import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import SelectorPackageType from '../selector/SelectorPackageType'
import SelectorService from '../selector/SelectorService'
import SelectorServiceTime from '../selector/SelectorServiceTime'

import AddDataIcon from '../../atoms/AddDataIcon'
import Box from '../../atoms/Box'
import BoxStatus from '../../atoms/BoxStatus'
import Button2 from '../../atoms/Button2'
import ButtonSave from '../../atoms/ButtonSave'
import CheckBox from '../../atoms/CheckBox'
import CloseIcon from '../../atoms/CloseIcon'
import InputText from '../../atoms/InputText'
import SaveIcon from '../../atoms/SaveIcon'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import TextLabel from '../../atoms/TextLabel'

// @ts-ignore
import api from '../../api'
import auth from '../../auth'
import config from '../../config'
import BrandPackage, {
  getPackageType,
  PackageTypes,
} from '../../redux/models/BrandPackage'
import Option from '../../redux/models/Option'
import ReduxStore from '../../redux/models/ReduxStore'
import Service, { TimePrice } from '../../redux/models/Service'
import utils from '../../utils'

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const GridBox = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
`

const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
`

const BoxStyle = styled.div`
  width: 100%;
  /* background: #f9f7fd; */
  border-radius: 8px;
  padding: 0.5rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 0.25fr;
  align-items: center;
`

const RemoveIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AddContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  cursor: pointer;
`

export interface CardAddPackageProps {
  onClose: () => void
  currentData: Partial<BrandPackage>
}

const CardAddPackage = ({
  onClose,
  currentData = { _id: -1 },
}: CardAddPackageProps) => {
  const store = useSelector((state: ReduxStore) => state.store)

  const [openArrowServiceIndex, setOpenArrowServiceIndex] = useState(-1)
  const [openArrowServiceTimeIndex, setOpenArrowServiceTimeIndex] = useState(-1)
  const [openArrowType, setOpenArrowType] = useState(false)
  const [defaultOptionType, setDefaultOptionType] = useState({
    value: 1,
    label: config.PACKAGE_TYPES[0],
  })
  const [packageData, setPackageData] = useState<Omit<BrandPackage, '_id'>>({
    name: '',
    type: PackageTypes.Money,
    price: 0,
    expiryDay: 0,
    totalValue: 0,
    branches: [],
    services: [{ serviceMasterId: '', serviceId: -1, timePrices: [] }],
    allService: true,
    status: 1,
    packageCode: '',
    quantity: 0,
    storeId: store._id,
  })
  const [success, setSuccess] = useState(false)
  const [checkData, setCheckData] = useState(true)

  const [services, setServices] = useState<Service[]>([])

  useEffect(() => {
    callApiService()
  }, [])

  const callApiService = async () => {
    try {
      const { data, success }: { success: boolean; data: Service[] } =
        await api.getServices()
      if (success) {
        const services = data.filter(d => d.status !== -1)
        setServices(services)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (currentData._id !== -1) {
      setPackageData(prev => ({ ...prev, ...currentData }))
      const type = currentData.type || PackageTypes.Money
      setDefaultOptionType({
        value: type,
        label: getPackageType(type),
      })
    }
  }, [currentData])

  const onArrowTypeClick = (val: boolean) => {
    setOpenArrowType(val)
  }

  const onSelectOptionTypeClick = (option: Option<number>) => {
    setDefaultOptionType(option)
    setOpenArrowType(false)
    setPackageData(prev => ({
      ...prev,
      type: option.value,
    }))
  }

  const onChangeValue = async (value: string, name: string) => {
    try {
      if (name === 'name') {
        setPackageData(prev => ({
          ...prev,
          [name]: value,
        }))
      } else if (utils.checkNumber(value)) {
        setPackageData(prev => ({
          ...prev,
          [name]: +value,
        }))
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onArrowServiceClick = (value: boolean, index: number) => {
    setOpenArrowServiceIndex(prev =>
      value ? index : index === prev ? -1 : prev
    )
  }

  const onSelectOptionServiceClick = (
    option: Option<Service>,
    name: string,
    index: number
  ) => {
    const newArrService = [...packageData.services]
    newArrService[index] = {
      serviceId: option.value._id,
      serviceMasterId: option.value.masterId,
      timePrices: [],
    }
    setPackageData(prev => ({ ...prev, services: newArrService }))
    setOpenArrowServiceIndex(-1)
  }

  const onServiceTimeClick = (value: boolean, index: number) => {
    setOpenArrowServiceTimeIndex(prev =>
      value ? index : index === prev ? -1 : prev
    )
  }

  const onSelectOptionServiceTimeClick = (
    val: Option<TimePrice>,
    index: number
  ) => {
    if (!packageData.services) return

    const newArrService = [...packageData.services]
    const findServiceTime = newArrService[index].timePrices.findIndex(
      ({ timePriceId }) => timePriceId === val.value.timePriceId
    )
    if (findServiceTime !== -1) {
      newArrService[index].timePrices.splice(findServiceTime, 1)
    } else {
      newArrService[index].timePrices.push({
        timePriceId: val.value.timePriceId,
        time: val.value.time,
      })
      newArrService[index].timePrices.sort((a, b) => a.time - b.time)
    }
    setPackageData(prev => ({ ...prev, services: newArrService }))
  }

  const onAddServiceClick = () => {
    const newArrService = [...packageData.services]
    newArrService.push({ serviceId: -1, serviceMasterId: '', timePrices: [] })
    setPackageData(prev => ({ ...prev, services: newArrService }))
  }

  const onDeleteServiceClick = (
    e: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    const newArrService = [...packageData.services]
    newArrService.splice(index, 1)
    setPackageData(prev => ({ ...prev, services: newArrService }))
  }

  const onSaveClick = async () => {
    try {
      let _success = false
      const {
        name,
        totalValue,
        expiryDay,
        allService,
        services = [],
        price,
      } = packageData
      if (
        !name ||
        !totalValue ||
        !expiryDay ||
        (allService ? false : !services?.length)
      ) {
        setCheckData(false)
        return
      } else {
        setCheckData(true)
      }
      if (currentData._id === -1) {
        const { success } = await api.createPackage({
          ...packageData,
          price: price || 0,
          services: allService ? [] : services,
          storeId: auth.getStoreId(),
          branches: [{ storeId: auth.getStoreId() }],
        })
        _success = success
      } else {
        const { success } = await api.updatePackage(currentData._id, {
          ...packageData,
          price: price || 0,
        })
        _success = success
      }
      if (_success) {
        setSuccess(_success)
        setTimeout(() => {
          onClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const serviceOptions = services.map(service => ({
    value: service,
    label: service.name,
  }))

  return (
    <Container>
      <TextLabel fontWeight='500' fontSize='1.25rem'>
        สร้างแพ็คเกจ
      </TextLabel>
      <TextLabel
        fontWeight='500'
        color={!checkData && !packageData.name ? '#FF5152' : ''}
      >
        ชื่อแพ็คเกจ*
      </TextLabel>
      <InputText
        placeholder='ชื่อแพ็คเกจ'
        value={packageData.name}
        onChange={val => onChangeValue(val.target.value, 'name')}
      />
      <TextLabel fontWeight='500'>ประเภทแพ็คเกจ*</TextLabel>
      <SelectorPackageType
        option={defaultOptionType}
        open={openArrowType}
        onOptionClick={onSelectOptionTypeClick}
        onClick={onArrowTypeClick}
      />
      <TextLabel
        fontWeight='500'
        color={!checkData && !packageData.services.length ? '#FF5152' : ''}
      >
        บริการ*
      </TextLabel>
      <GridBox>
        <div>
          <CheckBox
            text='ทุกบริการ'
            checked={packageData.allService}
            onClick={() =>
              setPackageData(prev => ({
                ...prev,
                allService: !packageData.allService,
              }))
            }
          />
        </div>
        <div>
          <CheckBox
            text='บางบริการ'
            checked={!packageData.allService}
            onClick={() =>
              setPackageData(prev => ({
                ...prev,
                allService: !packageData.allService,
              }))
            }
          />
        </div>
      </GridBox>
      {!packageData.allService && (
        <Box header='บริการ'>
          {packageData.services.map((s, index) => (
            <BoxContainer key={index}>
              <BoxStyle>
                <SelectorService
                  option={
                    serviceOptions.find(
                      ({ value }) => value._id === s.serviceId
                    )?.label || 'กรุณาเลือกเมนู'
                  }
                  options={serviceOptions}
                  open={index === openArrowServiceIndex}
                  index={index}
                  onOptionClick={onSelectOptionServiceClick}
                  onClick={onArrowServiceClick}
                  height='200px'
                />
                <SelectorServiceTime
                  option={
                    s.timePrices.map(({ time }) => time).join(', ') ||
                    'กรุณาเลือกเวลา'
                  }
                  options={(
                    services.find(({ _id }) => _id === s.serviceId)
                      ?.timePrices || []
                  ).map(item => ({
                    label: `${item.time}`,
                    value: item,
                  }))}
                  selectedOptions={s.timePrices}
                  open={index === openArrowServiceTimeIndex}
                  onClick={value => onServiceTimeClick(value, index)}
                  onOptionClick={val =>
                    onSelectOptionServiceTimeClick(val, index)
                  }
                  index={index}
                />

                {index !== 0 && (
                  <RemoveIconContainer
                    onClick={e => onDeleteServiceClick(e, index)}
                  >
                    <CloseIcon size={16} backgroundColor='#DC3C2F' withCircle />
                  </RemoveIconContainer>
                )}
              </BoxStyle>
            </BoxContainer>
          ))}
          <AddContainer onClick={onAddServiceClick}>
            <AddDataIcon size={16} color='#8C56D9' />
            <TextLabel fontSize='0.875rem' fontWeight={500} color='#8C56D9'>
              เพิ่มบริการ
            </TextLabel>
          </AddContainer>
        </Box>
      )}

      <TextLabel
        fontWeight='500'
        color={!checkData && !packageData.price ? '#FF5152' : ''}
      >
        ราคาขาย*
      </TextLabel>
      <InputText
        placeholder='ราคา(บาท)'
        value={packageData.price}
        onChange={val => onChangeValue(val.target.value, 'price')}
      />
      <TextLabel
        fontWeight='500'
        color={!checkData && !packageData.totalValue ? '#FF5152' : ''}
      >
        {defaultOptionType.value === 1
          ? 'มูลค่าแทนเงินสดที่ได้รับ*'
          : defaultOptionType.value === 2
          ? 'จำนวนครั้งที่ได้รับบริการ*'
          : defaultOptionType.value === 3
          ? 'ระยะเวลาที่ได้รับบริการ(นาที)*'
          : 'ส่วนลด(%)*'}
      </TextLabel>
      <InputText
        placeholder={
          defaultOptionType.value === 1
            ? 'ราคา(บาท)'
            : defaultOptionType.value === 2
            ? 'จำนวนที่ได้รับบริการ (ครั้ง)'
            : defaultOptionType.value === 3
            ? 'ระยะเวลาที่ได้รับบริการ (นาที)'
            : 'ส่วนลด(%)'
        }
        value={packageData.totalValue}
        onChange={val => onChangeValue(val.target.value, 'totalValue')}
      />
      <TextLabel
        fontWeight='500'
        color={!checkData && !packageData.expiryDay ? '#FF5152' : ''}
      >
        ระยะเวลาในการใช้งาน(วัน) จากวันที่ซื้อ*
      </TextLabel>
      <InputText
        placeholder='ระยะเวลาในการใช้งาน  (วัน)'
        value={packageData.expiryDay}
        onChange={val => onChangeValue(val.target.value, 'expiryDay')}
      />
      <BoxStatus
        title='สถานะ'
        detail='ไม่ใช้งาน/ใช้งาน'
        status={packageData.status === 1}
        onChange={() =>
          setPackageData(prev => ({
            ...prev,
            status: packageData.status === 1 ? -1 : 1,
          }))
        }
        id='setting-package-status'
      />
      <div
        className='pt-3'
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          justifyContent: 'flex-end',
          position: 'sticky',
          bottom: '0rem',
          background: 'white',
          zIndex: '2',
        }}
      >
        <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
          <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
            <Button2
              color='#98A1B2'
              border='2px solid #98A1B2'
              backgroundColor='white'
              fontWeight='600'
              onClick={onClose}
            >
              ยกเลิก
            </Button2>
          </div>
          <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
            <ButtonSave
              border='2px solid #53389E'
              color='#FFFFFF'
              backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
              backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
              fontWeight='600'
              icon={<SaveIcon size='15' />}
              onClick={() => onSaveClick()}
            >
              บันทึก
            </ButtonSave>
          </div>
        </div>
      </div>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddPackage
