export const COLORS: string[] = [
  '#8C56D9',
  '#32D582',
  '#EEE041',
  '#D95662',
  '#567AD9',
  '#56D9CD',
]

export const getChartColor = (index: number): string =>
  COLORS[index % COLORS.length]
