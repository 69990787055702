import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: #8C56D9;
    &:hover {
        text-decoration: underline;
        color: #8C56D9;
        filter: brightness(135%);
    }
`
