import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import PopupBase from './PopupBase'

import TextLabel from '../../atoms/TextLabel'
import Arrow from '../../atoms/Arrow'
import StoreIcon from '../../atoms/StoreIcon2'
import auth from '../../auth'

const Container = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

const BoxStoreItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BrandImage = styled.div`
  width: 100px;
  border-radius: 4px;
  aspect-ratio: 1;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Circle = styled.div`
  background: #8c56d9;
  box-shadow: 0px 4px 8px rgba(71, 84, 103, 0.24);
  border-radius: 50%;
  padding: 0 0.5rem;
`

const PopupSelectBrand = ({ handleClose }) => {
  const { brands } = useSelector(state => state.user)

  const onBrandClick = index => {
    const brand = brands[index]
    const { _id: brandId, branches } = brand
    const { _id: storeId } = branches[0]
    auth.setBrandId(brandId)
    auth.setStoreId(storeId)
    window.location.reload()
  }

  return (
    <PopupBase topic={'เลือกร้าน'} onClose={handleClose}>
      <Container>
        {brands.map(({ _id, name, image }, index) => (
          <BoxStoreItemContainer
            key={index}
            onClick={() => onBrandClick(index)}
          >
            <BrandImage className='mb-2' background={image || ''}>
              {!image && <StoreIcon />}
            </BrandImage>
            <TextLabel fontWeight='700'>{name}</TextLabel>
            {/* <TextLabel color='#475467' fontWeight='400' fontSize='0.85rem'>
                  {name}
                </TextLabel> */}
          </BoxStoreItemContainer>
        ))}
        <BoxStoreItemContainer>
          {brands.length > 4 && (
            <>
              <Circle className='mb-2'>
                <div>
                  <Arrow
                    border='solid #FFF'
                    transform='rotate(-45deg)'
                    margin='0'
                  />
                </div>
              </Circle>
              <TextLabel color='#475467' fontSize='0.75rem'>
                ดูเพิ่มเติม
              </TextLabel>
            </>
          )}
        </BoxStoreItemContainer>
      </Container>
    </PopupBase>
  )
}

export default PopupSelectBrand
