import React, { useState, Fragment } from 'react'
import styled from 'styled-components'

import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'

const Container = styled.div`
  width: calc(100%);
  /* margin-right: 1rem; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-height: calc(95px + 8px + 8px);
  border: ${props => props.border || '1px solid white'};

  :last-child {
    margin-bottom: 1rem;
  }
`
const Image = styled.img`
  border-radius: 8px;
  width: 60px;
  height: 60px;
  aspect-ratio: 1;
  object-fit: cover;
`
const Image2 = styled.div`
  border-radius: 8px;
  width: 60px;
  height: 60px;
  background: #e4e8eb;
`
const Box = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  align-items: ${props => props.alignItem || 'center'};
  width: ${props => props.width || 'auto'};
  margin: ${props => props.margin || '0'};
`
const Line = styled.div`
  height: 100%;
  border-left: 1px solid #e4e8eb;
`
const Circle = styled.div`
  background: #98a1b2;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`
const BoxTimePrice = styled.div`
  display: flex;
  flex-grow: 2;
  padding-left: 1rem;
`
const BoxTimePriceStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => props.flex || '1 0 50%'};
  max-width: ${props => props.width || '50%'};
  margin: ${props => props.margin || '0.25rem 0 0 0'};
`
const BoxEdit = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: ${props => props.cursor || 'none'};
  position: relative;
`
const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.16);
  border-radius: 8px;
  padding: 0.5rem;
  top: 10px;
  right: 0px;
`
const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`

const BodyCommissionLeft = ({
  serviceGroup,
  services,
  search,
  onEditGroupClick,
  commissionFees,
}) => {
  const [openEditId, setOpenEditId] = useState(-1)

  const bySearch = (services, search) => {
    if (search) {
      return services.name.includes(search)
    } else return services
  }

  const filteredList = (services, search) => {
    return services.filter(services => bySearch(services, search))
  }

  const aggregateGroup = list => {
    const aggregatedGroup = serviceGroup.reduce((acc, currentGroupName) => {
      const { groupName, _id } = currentGroupName
      const filteredServices = list.filter(n => n.groupId === _id)
      if (!filteredServices.length) {
        return acc
      } else {
        const currentGroupIdx = acc.findIndex(g => g.groupId === _id)
        if (currentGroupIdx === -1) {
          acc.push({
            groupId: _id,
            groupName,
            services: [...filteredServices],
          })
        } else {
          acc[currentGroupIdx].services.push({
            ...filteredServices,
          })
        }
      }

      return acc
    }, [])
    return aggregatedGroup
  }

  const aggregatedGroup = aggregateGroup(filteredList(services, search))

  const onOpenEditClick = (e, _id) => {
    e.stopPropagation()
    if (_id === openEditId) {
      setOpenEditId(-1)
    } else {
      setOpenEditId(_id)
    }
  }

  const onEditCommissionClick = e => {
    e.stopPropagation()
    onEditGroupClick(openEditId)
    setTimeout(() => {
      setOpenEditId(-1)
    }, 500)
  }
  const onEditBoxCommissionClick = id => {
    onEditGroupClick(id)
  }

  const checkCommission = (_id, timePriceId) => {
    const findCommissionFee =
      commissionFees.find(s => s.serviceId === _id || '') || ''
    if (findCommissionFee) {
      const { serviceCommissionFees } = findCommissionFee
      const commissionFee =
        serviceCommissionFees.find(t => t.timePriceId === timePriceId || '') ||
        ''
      return `${
        commissionFee
          ? utils.formatNumber(commissionFee.commissionFee || 0)
          : '-'
      }`
    } else {
      return '-'
    }
  }

  return (
    <Container>
      <Scrollbar id='setting-bodyCommissionFeeLeft'>
        {aggregatedGroup.map(({ services, groupName }, index) => (
          <Fragment key={index}>
            <TextLabel
              color='#000000'
              fontSize='1'
              fontWeight='500'
            >{`กลุ่มบริการ ${groupName}`}</TextLabel>
            {services.map((item, index) => (
              <Card
                key={index}
                onClick={() => onEditBoxCommissionClick(item._id)}
              >
                <Box flexDirection='row'>
                  {item.image ? <Image src={item.image} /> : <Image2></Image2>}
                  <Box alignItem='flex-start' margin='0 0 0 1rem'>
                    <TextLabel
                      color='#D0D4DD'
                      fontSize='0.9rem'
                    >{`กลุ่ม${groupName}`}</TextLabel>
                    <TextLabel color='#000000' fontWeight='500'>
                      {item.name}
                    </TextLabel>
                  </Box>
                </Box>
                <Box
                  flexDirection='row'
                  width='40%'
                  style={{ borderLeft: '1px solid #E4E8EB' }}
                >
                  <BoxTimePrice>
                    <BoxTimePriceStyle>
                      {item.timePrices.map(
                        ({ time, price, timePriceId }, index) => (
                          <BoxText key={index}>
                            <TextLabel
                              fontWeight='500'
                              fontSize='0.8rem'
                              color='#98A1B2'
                            >{`${time} นาที`}</TextLabel>
                            <TextLabel fontWeight='500'>{`≈${checkCommission(
                              item._id,
                              timePriceId
                            )}/${utils.formatNumber(price || 0)}`}</TextLabel>
                          </BoxText>
                        )
                      )}
                    </BoxTimePriceStyle>
                  </BoxTimePrice>
                  <Line></Line>
                  <BoxEdit onClick={e => onOpenEditClick(e, item._id)}>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div className='mr-1' key={index}>
                        <Circle></Circle>
                      </div>
                    ))}
                    {openEditId === item._id && (
                      <DropDown>
                        <Option onClick={e => onEditCommissionClick(e)}>
                          แก้ไข
                        </Option>
                        {/* <Option
                      // onClick={onDeleteCommissionClick.bind(this)}
                      >
                        ลบ
                      </Option> */}
                      </DropDown>
                    )}
                  </BoxEdit>
                </Box>
              </Card>
            ))}
          </Fragment>
        ))}
      </Scrollbar>
    </Container>
  )
}

export default BodyCommissionLeft
