import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'

import useReservationDetail from './useReservationDetail'

import InputText from '../../../atoms/InputText'
import TextLabel from '../../../atoms/TextLabel'

import { getOrderBooking } from '../../../redux/actions/bookingHistoryAction'
import SelectorBookingChannel from '../../selector/SelectorBookingChannel'
import SelectorBookingType from '../../selector/SelectorBookingType'

interface ReservationDetailStyle extends CSSProperties {}

const Container = styled.div<ReservationDetailStyle>`
  background-color: white;
  padding: 1rem;
  border: ${props => props.border || 'none'};
  box-shadow: ${props => props.boxShadow || 'none'};
  border-radius: 8px;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
const GridBox = styled.div<ReservationDetailStyle>`
  display: grid;
  grid-template-columns: ${props => props.columns};
  border-radius: 4px;
  gap: 0.5rem;
`
const Box = styled.div`
  display: flex;
  width: 100%;
`
const BoxCount = styled.div`
  border: 1px solid #e4e8eb;
  display: flex;
  align-items: center;
  justify-content: center;
`
const BoxCountItem = styled.div<ReservationDetailStyle>`
  text-align: center;
  width: 33%;
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || '500'};
  color: ${props => props.color || '#00000'};
  cursor: pointer;
  :first-child {
    border-right: 1px solid #e4e8eb;
  }
  :last-child {
    border-left: 1px solid #e4e8eb;
  }
`

const ReservationDetail = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { bookingType, bookingChannel, instant, countCustomer, note } =
    bookingOrderState

  const {
    openArrowBookingType,
    openArrowBookingChannel,
    optionBookingChannels,
    onArrowBookingChannelClick,
    onArrowBookingTypeClick,
    onSelectOption,
    onCountCustomerClick,
  } = useReservationDetail({ bookingOrderState })

  return (
    <Container>
      <TextLabel fontWeight='500'>{t('reservationDetails')}</TextLabel>
      <GridBox
        columns={bookingType.bookingTypeId === 3 ? '1fr 1fr 1fr' : '1fr 1fr'}
      >
        <Box>
          <TextLabel>{t('numberOfCustomers')}</TextLabel>
        </Box>
        <Box>
          <SelectorBookingType
            name='bookingType'
            option={{
              value: bookingType.bookingTypeId,
              label: bookingType.bookingType,
            }}
            open={openArrowBookingType}
            onClick={open => onArrowBookingTypeClick(open)}
            onOptionClick={(option, name) => onSelectOption(option, name)}
            readOnly={false}
            error={false}
          />
        </Box>
        {bookingType.bookingTypeId === 3 && (
          <Box>
            <TextLabel>{t('codeFromAgent')}</TextLabel>
          </Box>
        )}
      </GridBox>
      <GridBox
        columns={bookingType.bookingTypeId === 3 ? '1fr 1fr 1fr' : '1fr 1fr'}
      >
        <BoxCount>
          <BoxCountItem
            color={instant ? '#8592A3' : ''}
            fontWeight='600'
            fontSize='1.25rem'
            onClick={() => onCountCustomerClick('remove')}
          >
            -
          </BoxCountItem>
          <BoxCountItem
            color={instant ? '#8592A3' : '#8C56D9'}
            fontSize='1.25rem'
          >
            {countCustomer}
          </BoxCountItem>
          <BoxCountItem
            color={instant ? '#8592A3' : ''}
            fontWeight='600'
            fontSize='1.25rem'
            onClick={() => onCountCustomerClick('add')}
          >
            +
          </BoxCountItem>
        </BoxCount>
        <Box>
          <SelectorBookingChannel
            name='bookingChannel'
            option={{
              value: bookingChannel.bookingChannelId,
              label: bookingChannel.bookingChannel,
            }}
            open={openArrowBookingChannel}
            options={optionBookingChannels}
            onClick={open => onArrowBookingChannelClick(open)}
            onOptionClick={(option, name) => onSelectOption(option, name)}
            readOnly={false}
            error={false}
          />
        </Box>
        {bookingType.bookingTypeId === 3 && (
          <Box>
            <InputText
              placeholder={t('codeFromAgent') + ''}
              value={bookingChannel.code}
              onChange={val =>
                dispatch(
                  getOrderBooking({
                    bookingChannel: {
                      ...bookingChannel,
                      code: val.target.value,
                    },
                  })
                )
              }
              readOnly={instant}
            />
          </Box>
        )}
      </GridBox>
      <InputText
        placeholder={t('note') + ''}
        value={note}
        onChange={val => dispatch(getOrderBooking({ note: val.target.value }))}
      />
    </Container>
  )
}

export default ReservationDetail
