import { apiClient } from '../base/apiBase'

import Store from '../../redux/models/Store'

export type LoginType = 'password' | 'pin'

export interface SignInRequest {
  phoneNumber: string
  password: string
  typeLogin: LoginType
}

export interface SignInResponse {
  success: boolean
  user: User
  token: string
  hasPassword: boolean
  hashPin: boolean
  stores: Store[]
  brands: any[]
}

interface User {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  image: string
  status: number
  stores: number[]
  _id: number
  userId: string
  types: Type[]
  statuses: Status[]
  lineUserId: string
  createdAt: string
  updatedAt: string
  __v: number
  loginDate: string
  hasPassword: boolean
  hashPin: boolean
}

interface Type {
  _id: string
  storeId: number
  type: string
  typeId: number
}

interface Status {
  status: number
  _id: string
  storeId: number
}

export interface ResponseBase<T> {}

export interface ResCollection<T> {
  success: boolean
  data: T[]
}
export interface ResSingle<T> {
  success: boolean
  data: T
}

export interface SavedUsersData {
  firstName: string
  phoneNumber: string
  image: string
  stores: number[]
  _id: number
  statuses: SavedUsersStatus[]
  createdAt: string
  updatedAt: string
  __v: number
}

export interface SavedUsersStatus {
  status: number
  _id: string
  storeId: number
}

export const postSignIn = async (
  body: SignInRequest
): Promise<SignInResponse> => {
  const res = await apiClient.post(`/signin`, body)
  return res.data
}

export const getSavedUsers = async (
  ids: string[] = []
): Promise<ResCollection<SavedUsersData>> => {
  const res = await apiClient.get(`/users/saved-users`, { params: { ids } })
  return res.data
}
