import React, { useState, useEffect } from 'react'
import { useRef } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import BodyLeft from './BodyLeft'
import BodyRight from './BodyRight'
import api from '../../api'
import auth from '../../auth'
import LoaderModal from '../../molecules/LoaderModal'

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`

const Left = styled.div`
  display: flex;
  flex: 50%;
  max-width: 50%;
  min-width: 50%;
  /* margin-top: 1rem; */
`

const Right = styled.div`
  display: flex;
  flex: 50%;
  max-width: 50%;
  min-width: 50%;
`

const Body = () => {
  const Ref = useRef(null)
  const [height, setHeight] = useState(0)
  const [cashierData, setCashierData] = useState({})
  const [openCashier, setOpenCashier] = useState(false)
  const [cashierNumber, setCashierNumber] = useState(1)
  const [loading, setLoading] = useState(false)
  const user = useSelector(state => state.user)

  useEffect(() => {
    setHeight(Ref.current.offsetTop)
  }, [Ref])

  useEffect(() => {
    if (user.storeId) {
      callApiCashierByDate()
    }
  }, [user.storeId])

  const callApiCashierByDate = async () => {
    try {
      const { data, success } = await api.getCashierByDate(
        new Date(),
        new Date()
      )
      if (success) {
        if (data.length) {
          setLoading(false)
          let cashier = data.sort((a, b) => b.number - a.number)
          setOpenCashier(cashier[0].open)
          setCashierNumber(data[0].number + 1)
          setCashierData(data[0])
        } else {
          await callApiCashier()
        }
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCashier = async () => {
    try {
      const { data, success } = await api.getCashierByLastOpen()
      if (success) {
        if (data.length) {
          setLoading(false)
          setOpenCashier(data[0].open)
          setCashierData(data[0])
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onCreateSaveClick = async (type, data) => {
    try {
      if (type === 'cashier') {
        if (openCashier) {
          await callApiCloseCashier(cashierData._id, {
            closeTime: {
              hour: new Date().getHours(),
              minute: new Date().getMinutes(),
            },
            cashEnd: data,
          })
        } else {
          await callApiCreateCashier({
            date: new Date(),
            openTime: {
              hour: new Date().getHours(),
              minute: new Date().getMinutes(),
            },
            cashStart: data,
            total: data,
            // number: cashierNumber,
            storeId: auth.getStoreId(),
          })
        }
      } else if (type === 'ledger') {
        const newArrOtherTransaction = [...cashierData.otherTransactions]
        let totalSpacialRevenue = cashierData.totalSpacialRevenue
        let totalSpacialExpense = cashierData.totalSpacialExpense
        let total = cashierData.total
        newArrOtherTransaction.push({
          detail: data.detail,
          price: data.money,
          dateTime: new Date(),
          IncomeOrExpense: data.checkLedger === 0 ? 1 : -1,
          payChannelId: 0,
          createBy: auth.getId() || -1,
          updateBy: auth.getId() || -1,
        })
        if (data.checkLedger === 0) {
          totalSpacialRevenue += +data.money
          total += +data.money
          await callApiUpdateCashier(cashierData._id, {
            totalSpacialRevenue,
            total,
            otherTransactions: newArrOtherTransaction,
          })
        } else if (data.checkLedger === 1) {
          totalSpacialExpense += +data.money
          total -= +data.money
          await callApiUpdateCashier(cashierData._id, {
            totalSpacialExpense,
            total,
            otherTransactions: newArrOtherTransaction,
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onUpdateSaveClick = async (type, data, _id) => {
    try {
      const newArrOtherTransaction = [...cashierData.otherTransactions]
      const findOtherTransaction = newArrOtherTransaction.findIndex(
        o => o._id === _id
      )
      let totalSpacialRevenue = +cashierData.totalSpacialRevenue
      let totalSpacialExpense = +cashierData.totalSpacialExpense
      let total = +cashierData.cashStart

      if (newArrOtherTransaction[findOtherTransaction].IncomeOrExpense === 1) {
        totalSpacialRevenue -=
          +newArrOtherTransaction[findOtherTransaction].price
      } else {
        totalSpacialExpense -=
          +newArrOtherTransaction[findOtherTransaction].price
      }

      if (data.checkLedger === 0) {
        totalSpacialRevenue += +data.money
      } else {
        totalSpacialExpense += +data.money
      }

      newArrOtherTransaction[findOtherTransaction] = {
        ...newArrOtherTransaction[findOtherTransaction],
        dateTime: new Date(),
        price: data.money,
        IncomeOrExpense: data.checkLedger === 0 ? 1 : -1,
        detail: data.detail,
        updateBy: auth.getId() || -1,
      }

      const revenue = totalSpacialRevenue + +cashierData.totalRevenue
      const expense = totalSpacialExpense + +cashierData.totalExpense
      total = total + revenue - expense
      await callApiUpdateCashier(cashierData._id, {
        otherTransactions: newArrOtherTransaction,
        totalSpacialRevenue,
        totalSpacialExpense,
        total,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateCashier = async body => {
    try {
      setLoading(true)
      const { success } = await api.createCashier(body)
      if (success) {
        await callApiCashierByDate()
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCloseCashier = async (_id, body) => {
    try {
      setLoading(true)
      const { success } = await api.closeCashier(_id, body)
      if (success) {
        await callApiCashierByDate()
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateCashier = async (_id, body) => {
    try {
      const update = { ...body }
      const { success } = await api.updateCashier(cashierData._id, update)
      if (success) {
        await callApiCashierByDate()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Left>
        <BodyLeft
          cashierData={cashierData}
          onUpdateSaveClick={onUpdateSaveClick}
        />
      </Left>
      <Right ref={Ref} height={`calc(100vh - ${height}px - 6px)`}>
        <BodyRight
          cashierData={cashierData}
          openCashier={openCashier}
          cashierNumber={cashierNumber}
          onCreateSaveClick={onCreateSaveClick}
          loading={loading}
        />
      </Right>
      <LoaderModal isLoading={loading} />
    </Container>
  )
}

export default Body
