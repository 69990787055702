// @ts-nocheck

import React, { Children, PropsWithChildren, RefObject } from 'react'
import styled, { CSSProperties } from 'styled-components'

const Container = styled.div<PropsWithChildren<ScrollbarProps>>`
  display: flex;
  flex: ${props => (props.height ? '0 0 auto' : '1')};
  height: ${props => props.height};
  gap: ${props => props.gap};
  flex-direction: column;
  overflow-y: auto;
  overflow-x: ${props => (props.direction?.includes('x') ? 'auto' : 'hidden')};
  position: relative;
  padding-right: ${props => props.paddingRight || '1rem'};
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  padding-bottom: ${props =>
    Children.count(props.children) === 0 ? '1px' : ''};
  white-space: ${props => props.whiteSpace || 'unset'};
  ::-webkit-scrollbar {
    width: 4px;
    height: ${props => props.scrollbarHeight || '4px'};
    padding: auto;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: ${props => props.margin || '0'};
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8c56d9;
    outline: #f8f9fb;
    border-radius: 10px;
  }
  .infinite-scroll-component {
    height: 10px;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
`

export interface ScrollbarProps extends CSSProperties {
  id?: string
  scrollbarHeight?: string
  refProp?: RefObject<HTMLDivElement>
  direction?: string
}

const Scrollbar = ({
  id,
  height = '',
  scrollbarHeight,
  gap = '0rem',
  maxHeight,
  minHeight,
  paddingRight,
  children,
  refProp,
  direction,
  margin,
  whiteSpace,
}: PropsWithChildren<ScrollbarProps>) => {
  return (
    <Container
      id={id}
      height={height}
      scrollbarHeight={scrollbarHeight}
      gap={gap}
      maxHeight={maxHeight}
      minHeight={minHeight}
      paddingRight={paddingRight}
      ref={refProp}
      margin={margin}
      whiteSpace={whiteSpace}
      direction={direction}
    >
      {children}
    </Container>
  )
}

export default Scrollbar
