import React from 'react'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddUser from '../settingRole/CardAddUser'

const PopupAddUser = ({
  handleClose,
  currentIdEdit = -1,
  currentDataEdit = '',
  update,
}) => {
  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0'>
        <CardAddUser
          handleClose={handleClose}
          currentIdEdit={currentIdEdit}
          currentDataEdit={currentDataEdit}
          update={update}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddUser
