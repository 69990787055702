import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  sortableHandle,
} from 'react-sortable-hoc'
import styled from 'styled-components'
import { ClipLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'

import Button2 from '../../atoms/Button2'
import EditIcon from '../../lib/icons/EditIcon'
import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'

import api from '../../api'
import auth from '../../auth'
import { useSelector } from 'react-redux'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`

const ContainerStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Box = styled.div`
  flex: 1 0 33%;
  max-width: 33%;
  min-width: 33%;
`

const BoxStaff = styled.div`
  background: #ffffff;
  border: 1px solid #ece9f4;
  border-radius: 9px;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.5rem;
  margin-top: 0.25rem;
  min-height: 64px;
`

const Image = styled.div`
  width: 35px;
  border-radius: 50%;
  aspect-ratio: 1;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  background-position: center;
  background-size: cover;
`

const ContainerCircle = styled.div`
  display: flex;
`

const ContainerCircleStyle = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: wrap;
  width: ${props => (props.open ? '100%' : '70%')};
  padding: ${props => props.padding || '0'};
  height: fit-content;
`

const BoxCircle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 33%'};
  max-width: ${props => props.width || '33%'};
  margin: ${props => props.margin || '0.25rem 0 0 0'};
`

const Circle = styled.div`
  background: #98a1b2;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`

const BoxEdit = styled.div`
  display: flex;
  cursor: ${props => props.cursor || 'none'};
  position: relative;
  gap: 0.5rem;
  /* width: ${props => props.width || '4%'}; */
`

const DropDown = styled.div`
  background: #ffffff;
  border-radius: 8px;
  /* padding: 0.5rem; */
  position: absolute;
  right: 0px;
  top: 100%;
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.08));
  width: max-content;
  z-index: 5;
`

const Option = styled.div`
  min-width: 80px;
  min-height: 40px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: ${props => props.color || '#000000'};
  cursor: ${props => props.cursor || 'pointer'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e4e8eb;
  :last-child {
    border-bottom: none;
  }
`

const DragHandle = sortableHandle(() => (
  <ContainerCircle>
    <ContainerCircleStyle>
      {Array.from({ length: 9 }).map((_, index) => (
        <BoxCircle key={index}>
          <Circle></Circle>
        </BoxCircle>
      ))}
    </ContainerCircleStyle>
  </ContainerCircle>
))

const SortableItem = SortableElement(
  ({
    key: _key,
    index,
    _index,
    image,
    value,
    dateKey,
    currentRefIndex,
    loading,
    onStaffOpenEditClick,
    onStaffWorkStatusClick,
  }) => {
    const { t } = useTranslation()

    return (
      <BoxStaff key={index}>
        {(value.workingStatus !== -1 || dateKey !== 0) && <DragHandle />}
        {value.workingStatus === 1 && dateKey === 0 && (
          <div
            className='mr-2'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              lineHeight: '1',
            }}
          >
            <TextLabel color='#4E4E72' fontWeight='600'>
              {`#${_index + 1}`}
            </TextLabel>
          </div>
        )}
        <Image background={value.image} />
        <TextLabel fontWeight='500' paddingLeft='0.25rem'>
          {value.name}
        </TextLabel>
        <div className='mr-1' style={{ display: 'flex', marginLeft: 'auto' }}>
          {dateKey === 0 && (
            <BoxEdit>
              <Button2
                width='fit-content'
                onClick={() => onStaffOpenEditClick(value, dateKey)}
                size='0.875rem'
                border={
                  value.workingStatus === 1
                    ? ' 2px solid #32D582'
                    : '2px solid #98A1B2'
                }
                backgroundColor='#FFFFFF'
                padding='0.25rem 0.5rem'
                color={value.workingStatus === 1 ? '#00B548' : '#98A1B2'}
                colorHover={value.workingStatus === 1 ? '#00B548' : '#98A1B2'}
                backgroundColorHover='none'
              >
                {value.workingStatus === 1 ? t('working') : t('shiftEnds')}
              </Button2>
              <Button2
                width='fit-content'
                backgroundColor={
                  currentRefIndex.staffId === value._id ? '#EEF1F3' : '#FFF'
                }
                border='1px solid #E4E8EB'
                icon={<EditIcon size='15' color='#8C56D9' />}
                onClick={() => onStaffOpenEditClick(value, dateKey)}
              />
              {currentRefIndex.staffId === value._id && (
                <DropDown>
                  <Option
                    color={value.workingStatus === -1 ? '#32D582' : ''}
                    onClick={() =>
                      loading
                        ? {}
                        : onStaffWorkStatusClick(
                            value,
                            dateKey,
                            -1 * value.workingStatus
                          )
                    }
                  >
                    {loading ? (
                      <ClipLoader loading size={15} />
                    ) : value.workingStatus === -1 ? (
                      t('shiftStarts')
                    ) : (
                      t('shiftEnds')
                    )}
                  </Option>
                </DropDown>
              )}
            </BoxEdit>
          )}
        </div>
      </BoxStaff>
    )
  }
)

const SortableList = SortableContainer(
  ({
    items,
    dateKey,
    currentRefIndex,
    loading,
    onStaffOpenEditClick,
    onStaffWorkStatusClick,
  }) => {
    return (
      <div>
        {items.map((value, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              _index={index}
              value={value}
              dateKey={dateKey}
              currentRefIndex={currentRefIndex}
              loading={loading}
              onStaffOpenEditClick={onStaffOpenEditClick}
              onStaffWorkStatusClick={onStaffWorkStatusClick}
            />
          )
        })}
      </div>
    )
  }
)

const getInitialData = () => ({
  startedStaffs: [],
  notStartedStaffs: [],
  queueId: '',
  date: new Date(),
})

const Queue = () => {
  const [queueMap, setQueueMap] = useState({
    0: getInitialData(),
    1: getInitialData(),
    2: getInitialData(),
  })
  const [, setStaffMap] = useState({})
  const [currentRefIndex, setCurrentRefIndex] = useState({
    staffId: -1,
    staff: -1,
    date: -1,
  })
  const [loading, setLoading] = useState(false)
  const user = useSelector(state => state.user)

  useEffect(() => {
    if (user.storeId) {
      init()
    }
  }, [user.storeId])

  const init = async () => {
    const { success, data } = await api.getCurrentStaffQueue()

    if (!success) return

    const { staffQueues, staffs } = data

    const newQueueMap = { ...queueMap }
    const staffMap = staffs.reduce((acc, cur) => {
      acc[cur._id] = cur
      return acc
    }, {})

    staffQueues.forEach((staffQueue, dateKey) => {
      newQueueMap[dateKey] = initializeQueue(staffQueue, { staffMap, dateKey })
    })

    setQueueMap(newQueueMap)
    setStaffMap(staffMap)
  }

  const initializeQueue = (
    staffQueue,
    config = { staffMap: {}, dateKey: 0 }
  ) => {
    const { _id: queueId, date, queue } = staffQueue
    const { staffMap, dateKey } = config
    return {
      date,
      startedStaffs: queue
        .map(({ staffId }) => staffMap[staffId])
        .filter(({ workingStatus }) => workingStatus === 1 || dateKey !== 0),
      notStartedStaffs: queue
        .map(({ staffId }) => staffMap[staffId])
        .filter(({ workingStatus }) => workingStatus === -1 && dateKey === 0),
      queueId,
    }
  }

  const onStaffOpenEditClick = async (staff, date) => {
    if (currentRefIndex.staffId !== staff._id) {
      setCurrentRefIndex({ staffId: staff._id, staff, date })
    } else {
      setCurrentRefIndex({ staffId: -1, staff: -1, date: -1 })
    }
  }

  const onStaffWorkStatusClick = async (staff, dateKey, workingStatus) => {
    try {
      setLoading(true)
      const { queueId, date } = queueMap[dateKey]
      const { success, data } = await api.manageStaffQueue({
        queueId,
        timestamp: new Date(),
        date,
        staffId: staff._id,
        storeId: auth.getStoreId(),
        workingStatus,
      })
      if (success) {
        setStaffMap(prev => {
          const newStaffMap = {
            ...prev,
            [staff._id]: { ...staff, workingStatus },
          }
          setQueueMap(prev => ({
            ...prev,
            [dateKey]: initializeQueue(data, {
              dateKey,
              staffMap: newStaffMap,
            }),
          }))
          return newStaffMap
        })
        setCurrentRefIndex({ staffId: -1, staff: -1, date: -1 })
      } else {
        alert('เกิดปัญหากับการเเข้า/ออกพนักงาน')
      }
    } catch (err) {
      alert('เกิดปัญหากับการเเข้า/ออกพนักงาน')
    } finally {
      setLoading(false)
    }
  }

  const onSortEnd = async (dateKey, { oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return

    const { queueId, startedStaffs, notStartedStaffs } = queueMap[dateKey]
    const newStartedStaffs = arrayMove(startedStaffs, oldIndex, newIndex)

    setQueueMap(prev => ({
      ...prev,
      [dateKey]: { ...prev[dateKey], startedStaffs: newStartedStaffs },
    }))

    await callApiUpdateQueueStaff(
      queueId,
      newStartedStaffs
        .concat(notStartedStaffs)
        .map(({ _id }) => ({ staffId: _id }))
    )
  }

  const callApiUpdateQueueStaff = async (queueId, newQueue) => {
    try {
      await api.updateStaffQueue(queueId, {
        queue: newQueue,
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Scrollbar id='setting-queue'>
        <ContainerStyle>
          {Object.entries(queueMap).map(
            ([key, { date, startedStaffs, notStartedStaffs }]) => (
              <Box key={key}>
                <div className='mb-4' style={{ textAlign: 'center' }}>
                  <TextLabel>{dayjs(date).format('DD MMMM YY')}</TextLabel>
                </div>
                <SortableList
                  items={startedStaffs}
                  dateKey={+key}
                  currentRefIndex={currentRefIndex}
                  loading={loading}
                  onStaffOpenEditClick={onStaffOpenEditClick}
                  onStaffWorkStatusClick={onStaffWorkStatusClick}
                  onSortEnd={(...args) => onSortEnd(+key, ...args)}
                  distance={1}
                  axis='y'
                  helperClass='SortableHelper'
                />
                <div style={{ marginTop: '4rem' }}></div>
                <SortableList
                  items={notStartedStaffs}
                  dateKey={+key}
                  currentRefIndex={currentRefIndex}
                  loading={loading}
                  onStaffOpenEditClick={onStaffOpenEditClick}
                  onStaffWorkStatusClick={onStaffWorkStatusClick}
                  onSortEnd={onSortEnd}
                  distance={1}
                  axis='y'
                  helperClass='SortableHelper'
                  useDragHandle
                />
                <div style={{ marginBottom: '2rem' }} />
              </Box>
            )
          )}
        </ContainerStyle>
      </Scrollbar>
    </Container>
  )
}

export default Queue
