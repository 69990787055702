import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import auth from '../auth'
// @ts-ignore
import { fetchBrand } from '../redux/actions/brandAction'
// @ts-ignore
import { fetchStore } from '../redux/actions/storeAction'
// @ts-ignore
import { fetchUser } from '../redux/actions/userAction'
import ReduxStore from '../redux/models/ReduxStore'
import { LOGIN_URL, RESET_PASSWORD_URL } from '../routes'

const useApp = () => {
  const { i18n } = useTranslation()

  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useSelector((state: ReduxStore) => state.user)

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language') || 'th')
    dayjs.locale(localStorage.getItem('language') || 'th')
  }, [])

  useEffect(() => {
    if (window.location.pathname !== RESET_PASSWORD_URL && !auth.isLoggedIn()) {
      history.replace(LOGIN_URL)
      return
    }

    if (auth.isLoggedIn()) {
      dispatch(fetchBrand(currentUser.brandId))
      dispatch(fetchStore(currentUser.storeId))
      dispatch(fetchUser())
    }
  }, [currentUser.storeId, currentUser.brandId])

  useEffect(() => {
    if (currentUser._id && currentUser.status !== 1) {
      auth.logout()
      history.replace(LOGIN_URL)
    }
  }, [currentUser])
}

export default useApp
