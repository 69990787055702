import React, { ChangeEvent } from 'react'
import styled from 'styled-components'

import TextLabel from './TextLabel'

interface InputTextAreaProps {
  width?: string
  height?: string
  background?: string
  border?: string
  boxShadow?: string
  borderRadius?: string
  padding?: string
  borderFocus?: string
  maxLength?: number
  value?: string
  fontSize?: string
  fontWeight?: string
  color?: string
  resize?: string
  colorPlaceholder?: string
  fontSizePlaceholder?: string
  textAlign?: string
  [key: string]: any // Allow additional props to be passed
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const Container = styled.div<InputTextAreaProps>`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  background: ${props => props.background || '#ffffff'};
  border: ${props => props.border || '1px solid #e4e8eb'};
  box-shadow: ${props =>
    props.boxShadow || '0px 4px 4px rgba(71, 84, 103, 0.05)'};
  position: relative;
  border-radius: ${props => props.borderRadius || '4px'};
  padding: ${props => props.padding || '0.25rem 0.5rem'};
  :focus-within {
    outline: none;
    border: ${props => props.borderFocus || '1px solid #8c56d9'};
  }
  display: grid;
  gap: 0.25rem;
`

const StyledTextArea = styled.textarea<InputTextAreaProps>`
  width: 100%;
  background: transparent;
  border: none;
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || '400'};
  color: ${props => props.color || '#000000'};
  resize: ${props => props.resize || 'none'};
  ::placeholder {
    color: ${props => props.colorPlaceholder || '#D0D4DD'};
    font-size: ${props => props.fontSizePlaceholder || '1rem'};
  }
  :focus-visible {
    outline: none;
  }
`

const InputTextArea = (props: InputTextAreaProps) => {
  const { maxLength, value } = props

  return (
    <Container {...props}>
      <StyledTextArea {...props} />
      {maxLength && (
        <div>
          <TextLabel
            fontSize='0.75rem'
            fontWeight={500}
            color='#C8CDD7'
            textAlign='right'
          >
            {value?.length || 0}/{maxLength}
          </TextLabel>
        </div>
      )}
    </Container>
  )
}

export default InputTextArea
