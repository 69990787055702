import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button2 from '../../atoms/Button2'

import Popup from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import api from '../../api'

const CardPin = styled.div`
  /* background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  border-radius: 24px; */
  padding: 3rem 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
`
const BoxPin = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`
const BoxPinStyle = styled.div`
  background: ${props => props.background || '#ffffff'};
  border: ${props => props.border || '1px solid #e4e8eb'};
  border-radius: 8px;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
  color: ${props => props.color || '#475467'};
  font-weight: 600;
  font-size: 3.375rem;
  width: 60px;
  height: 60px;
  /* aspect-ratio: 1; */
  vertical-align: middle;
  text-align: center;
`
const BoxNumber = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  grid-area: 1;

  /* grid-auto-flow: column; */
  /* justify-content: end; */
`
const BoxNumberStyle = styled.div`
  display: grid;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e8eb;
  border-radius: 50%;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  color: #000000;
  padding: 0.5rem 1.25rem;
  :hover {
    background-color: #8c56d9;
    color: #fff;
  }
  /* width: 56.98px;
  height: 56px; */
`

const PopupAddPin = ({
  handleClose,
  onPinChange: _onPinChange,
  currentIdEdit = -1,
  currentDataEdit,
}) => {
  const number = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', 'C']
  const [star, setStar] = useState([])
  const [oldPin, setOldPin] = useState([])
  const [pin, setPin] = useState([])
  const [verifyPin, setVerifyPin] = useState([])
  const [confirmPin, setConfirmPin] = useState(false)
  const [checkPin, setCheckPin] = useState(true)
  const [success, setSuccess] = useState(false)
  const [newPin, setNewPin] = useState(true)
  const [wrongOldPassword, setWrongOldPassword] = useState(false)
  const [token, setToken] = useState('')

  useEffect(() => {
    if (currentIdEdit !== -1) {
      setNewPin(false)
    }
  }, [currentIdEdit])

  useEffect(() => {
    if (verifyPin.length === 4) {
      var checkPin = true
      for (let i = 0; i < verifyPin.length; i++) {
        if (verifyPin[i] !== pin[i]) {
          checkPin = false
        }
      }
      if (!checkPin) {
        setStar([])
        setPin([])
        setVerifyPin([])
        setConfirmPin(false)
      } else {
        if (currentIdEdit !== -1) {
          onPinChange(oldPin.join(''), pin.join(''), verifyPin.join(''), token)
        } else {
          setSuccess(true)
          setTimeout(() => {
            if (currentIdEdit === -1) {
              _onPinChange(
                oldPin.join(''),
                pin.join(''),
                verifyPin.join(''),
                token
              )
            }
          }, 1000)
        }
      }
      setCheckPin(checkPin)
    }
  }, [verifyPin])

  useEffect(() => {
    if (pin.length === 4) {
      setTimeout(() => {
        setStar([])
        setConfirmPin(true)
      }, 500)
    }
  }, [pin])

  useEffect(() => {
    if (oldPin.length === 4) {
      functionCheckPin()
    }
  }, [oldPin])

  const functionCheckPin = async () => {
    try {
      const userLogin = {
        phoneNumber: currentDataEdit.phoneNumber,
        password: oldPin.join(''),
        typeLogin: 'pin',
      }
      const { token, success } = await api.login(userLogin)
      if (success) {
        setToken(token)
        setTimeout(() => {
          setStar([])
          setNewPin(true)
          setWrongOldPassword(false)
        }, 500)
      }
    } catch (err) {
      setWrongOldPassword(true)
      setStar([])
      setOldPin([])
      console.log(err)
    }
  }

  const onNumberClick = index => {
    var newArrOld = [...oldPin]
    var newArrFirstPin = [...pin]
    var newArrSecondPin = [...verifyPin]
    var newArrStar = [...star]
    setCheckPin(true)
    setWrongOldPassword(false)

    if (!newPin) {
      if (index === 11) {
        newArrOld.pop()
        newArrStar.pop()
      } else {
        newArrOld.push(number[index])
        newArrStar.push('*')
      }
      setOldPin(newArrOld)
    } else {
      if (!confirmPin) {
        if (index === 11) {
          newArrFirstPin.pop()
          newArrStar.pop()
        } else {
          newArrFirstPin.push(number[index])
          newArrStar.push('*')
        }

        setPin(newArrFirstPin)
      } else {
        if (index === 11) {
          newArrSecondPin.pop()
          newArrStar.pop()
        } else {
          newArrSecondPin.push(number[index])
          newArrStar.push('*')
        }
        setVerifyPin(newArrSecondPin)
      }
    }

    setStar(newArrStar)
  }

  const onPinChange = async () => {
    try {
      const body = {
        currentPin: oldPin.join(''),
        newPin: pin.join(''),
        verifyPin: verifyPin.join(''),
        token,
      }
      const { success } = await api.renewPin(body)
      if (success) {
        setSuccess(success)
        setTimeout(() => {
          setSuccess(false)
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Popup width='calc(100%)' height='100%' left='0rem' top='0'>
      <PopupInsideBox width='auto' marginTop='0' padding='0' height='auto'>
        <CardPin>
          <TextLabel fontWeight='500' fontSize='1.25rem'>
            {!newPin
              ? 'กรุณาใส่รหัส PIN เดิม'
              : !confirmPin
              ? `กรุณาตั้งรหัส PIN`
              : `ยืนยันรหัส PIN`}
          </TextLabel>
          <BoxPin>
            {Array.from({ length: 4 }).map((_, index) => (
              <BoxPinStyle
                key={index}
                background={
                  star[index] === '*' && index !== star.length - 1
                    ? '#E4E8EB'
                    : ''
                }
                border={
                  index === star.length - 1
                    ? '1px solid #8c56d9'
                    : wrongOldPassword
                    ? '1px solid #DB2D1E'
                    : checkPin
                    ? ''
                    : '1px solid #DB2D1E'
                }
                color={wrongOldPassword ? '#DB2D1E' : checkPin ? '' : '#DB2D1E'}
                style={{ lineHeight: index === star.length - 1 ? '1.25' : '' }}
              >
                {index === star.length - 1
                  ? !newPin
                    ? oldPin[index]
                    : !confirmPin
                    ? pin[index]
                    : verifyPin[index]
                  : star[index]}
              </BoxPinStyle>
            ))}
          </BoxPin>
          {(!checkPin || wrongOldPassword) && (
            <TextLabel color='#DB2D1E' fontWeight='600'>
              รหัสผ่านไม่ถูกต้อง
            </TextLabel>
          )}
          <BoxNumber>
            {Array.from({ length: 12 }).map((_, index) => {
              return index === 9 ? (
                <div></div>
              ) : (
                <BoxNumberStyle
                  key={index}
                  onClick={() => onNumberClick(index)}
                >
                  {/* {index === 11 ? (
                    <>
                      <ArrowCalculator size='25' />
                    </>
                  ) : (
                    number[index]
                  )} */}
                  {number[index]}
                </BoxNumberStyle>
              )
            })}
          </BoxNumber>
          <Button2
            color='#98A1B2'
            backgroundColor='white'
            border='2px solid #98A1B2'
            backgroundColorHover='#E5E7EC'
            onClick={handleClose}
          >
            ยกเลิก
          </Button2>
        </CardPin>
        {success && <SuccessIcon2 position='absolute' />}
      </PopupInsideBox>
    </Popup>
  )
}

export default PopupAddPin
