import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import Button2 from '../../atoms/Button2'
import RadioButton from '../../atoms/RadioButton'
import BahtIcon2 from '../../atoms/BahtIcon2'
import CreditIcon from '../../atoms/CreditIcon'
import TransferIcon from '../../atoms/TransferIcon'
import OnlineIcon from '../../atoms/OnlineIcon'
import InputText from '../../atoms/InputText'
import utils from '../../utils'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'

const Card = styled.div`
  width: 100%;
  padding: 1rem 1rem 0.5rem 1.25rem;
  display: flex;
  flex-direction: column;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0.5rem'};
  justify-content: ${props => props.justifyContent || 'unset'};
  align-items: ${props => props.alignItem || 'unset'};
  padding: ${props => props.padding || '0'};
`
const Line = styled.div`
  border-top: 1px solid #e4e8eb;
  width: calc(100% + 2.25rem);
  margin-left: -1.25rem;
  padding-bottom: 0.5rem;
`

const PopupTip = ({ staffs, orders, handleClose }) => {
  const dispatch = useDispatch()
  const [selectStaffIndex, setSelectStaffIndex] = useState(0)
  const [selectStaff, setSelectStaff] = useState({})
  const [step, setStep] = useState(1)
  const [tip, setTip] = useState('')
  const payChannelTypes = [
    {
      payChannelTypeId: 1,
      payChannelType: 'เงินสด',
      icon: <BahtIcon2 size='20' color='#8C56D9' />,
    },
    {
      payChannelTypeId: 2,
      payChannelType: 'เครดิต',
      icon: <CreditIcon size='20' color='#8C56D9' />,
    },
    {
      payChannelTypeId: 3,
      payChannelType: 'โอนเงิน',
      icon: <TransferIcon size='20' color='#8C56D9' />,
    },
    {
      payChannelTypeId: 4,
      payChannelType: 'ออนไลน์',
      icon: <OnlineIcon size='18' color='#8C56D9' />,
    },
  ]

  useEffect(() => {
    setSelectStaff({
      staffName: staffs[0].staffName,
      staffId: staffs[0]._id,
      orderIndex: staffs[0].orderIndex,
      serviceIndex: staffs[0].serviceIndex,
    })
  }, [staffs])

  const onCancelClick = () => {
    if (step === 1) {
      handleClose()
    } else {
      setStep(step - 1)
    }
  }

  const onPriceChange = value => {
    if (utils.checkNumber(value)) {
      setTip(value)
    }
  }

  const onConfirmClick = () => {
    // if (step === 1 || step === 2) {
    //   setStep(step + 1)
    // } else {
    const newArrOrder = [...orders]
    newArrOrder[selectStaff.orderIndex].services[selectStaff.serviceIndex] = {
      ...newArrOrder[selectStaff.orderIndex].services[selectStaff.serviceIndex],
      tip,
      // payChannelTypeTip: activeBtnPayChannelType,
      // payChannel: payChannel.payChannel,
    }

    const totalTip = newArrOrder.reduce((acc, info) => {
      const { services } = info
      for (let i = 0; i < services.length; i++) {
        acc += +services[i].tip || 0
      }
      return acc
    }, 0)

    dispatch(getOrderBooking({ orders: newArrOrder, totalTip: totalTip }))
    handleClose()
    // }
  }

  return (
    <PopupBox width='calc(100%)' height='100%' left='0rem' top='0'>
      <PopupInsideBox width='40%' marginTop='0' padding='0' height='auto'>
        <Card>
          <FlexBox justifyContent='space-between' alignItem='center'>
            <TextLabel>{`ทิป หมอ${selectStaff.staffName}`}</TextLabel>
          </FlexBox>
          <InputText
            placeholder='0'
            value={tip}
            onChange={val => onPriceChange(val.target.value)}
          />
          <Line />
          {staffs.map(
            ({ staffName, staffId, orderIndex, serviceIndex }, index) => (
              <FlexBox
                className='mb-2'
                key={staffId}
                justifyContent='space-between'
                alignItem='center'
                onClick={() => {
                  setSelectStaffIndex(index)
                  setSelectStaff({
                    staffName,
                    staffId,
                    orderIndex,
                    serviceIndex,
                  })
                }}
              >
                <TextLabel
                  color={index === selectStaffIndex ? '#8c56d9' : ''}
                  fontWeight={index === selectStaffIndex ? '500' : ''}
                >
                  {staffName}
                </TextLabel>
                <RadioButton checked={index === selectStaffIndex} />
              </FlexBox>
            )
          )}

          {/* {step === 2 && (
            <>
              <FlexBox padding='0 0 0.25rem 0' alignItem='center'>
                <div onClick={() => setStep(1)}>
                  <LongArrow />
                </div>
                &nbsp;
                <TextLabel
                  fontSize='1.25rem'
                  fontWeight='500'
                  paddingRight='2rem'
                >
                  เลือกช่องทางการชำระเงิน
                </TextLabel>
              </FlexBox>
              {payChannelTypes.map(
                ({ payChannelTypeId, payChannelType, icon }) => (
                  <FlexBox
                    flexDirection='column'
                    gap='0rem'
                    padding='0.25rem 0'
                    onClick={() => {
                      setActiveBtnPayChannelType(payChannelTypeId)
                      setStep(3)
                    }}
                  >
                    <Line></Line>
                    <FlexBox justifyContent='space-between'>
                      <div>
                        {icon}
                        <TextLabel fontWeight='500' paddingLeft='0.5rem'>
                          {payChannelType}
                        </TextLabel>
                      </div>
                      <div>
                        <Arrow
                          transform='rotate(-45deg)'
                          border='solid #98A1B2'
                        />
                      </div>
                    </FlexBox>
                  </FlexBox>
                )
              )}
            </>
          )} */}

          {/* {step === 3 && (
            <>
              <FlexBox padding='0 0 0.25rem 0' alignItem='center'>
                <div onClick={() => setStep(2)}>
                  <LongArrow />
                </div>
                &nbsp;&nbsp;
                <TextLabel>{`ทิป หมอ${selectStaff.staffName}`}</TextLabel>
              </FlexBox>
              <InputText
                placeholder='0'
                value={tip}
                onChange={val => onPriceChange(val.target.value)}
              />
              <div className='mt-3' style={{ maxWidth: '100%' }}>
                {activeBtnPayChannelType === 2 && (
                  <Credit
                    splitPayment={true}
                    onSelectPayChannel={onSelectPayChannel}
                    onChargeChange={onChargeChange}
                  />
                )}
                {activeBtnPayChannelType === 3 && (
                  <Transfer
                    // bookingHistory={_bookingHistory}
                    onSelectPayChannel={onSelectPayChannel}
                  />
                )}
                {activeBtnPayChannelType === 4 && (
                  <Online
                    splitPayment={true}
                    onSelectPayChannel={onSelectPayChannel}
                    onChargeChange={onChargeChange}
                    // bookingCode={bookingCode}
                    // total={total}
                    // discount={discount}
                    // bookingHistory={_bookingHistory}
                  />
                )}
              </div>
            </>
          )} */}

          <Line />
          <FlexBox>
            <Button2
              backgroundColor='#FFF'
              border='1px solid #E4E8EB'
              onClick={() => onCancelClick()}
            >
              ยกเลิก
            </Button2>
            <Button2
              backgroundColor='linear-gradient(222deg, #8C56D9 2.71%, #53389E 97.79%)'
              color='#FFF'
              //   opacity={price ? '' : '0.5'}
              onClick={() => onConfirmClick()}
            >
              ยืนยัน
            </Button2>
          </FlexBox>
        </Card>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupTip
