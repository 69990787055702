import i18n from 'i18next'
import i18nBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import config from '../config'

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'th',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: '.',
    backend: {
      loadPath: `${config.FRONT_URL}/i18n/{{lng}}.json`,
    },
  })

export default i18n
