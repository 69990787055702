import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import Geocode from 'react-geocode'
import SearchBox from 'react-google-maps/lib/components/places/SearchBox'

const SearchContainer = styled.div`
  width: ${props => (props.withSearch ? '90%' : 'auto')};
  position: relative;
  top: ${props => (props.withSearch ? '40px' : '0.5rem')} !important;
  left: ${props => (props.withSearch ? '' : '0.5rem !important')};
  display: flex;
  & > svg {
    margin-right: 10px;
  }
  & > input {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  }
  z-index: 1000;
`

const Textfield = styled.input`
  width: 100%;
  height: 33px;
  color: #000;
  background-color: #fff;
  border-color: transparent;
  margin-top: 1rem;
  z-index: 1000;
`

const Map = ({
  latitude: _latitude,
  longitude: _longitude,
  onLocationChange,
}) => {
  const [mapPosition, setMapPosition] = useState({
    lat: parseFloat(_latitude) || 13.8869511,
    lng: parseFloat(_longitude) || 100.5141115,
  })
  const [markerPosition, setMarkerPosition] = useState({
    lat: parseFloat(_latitude) || 13.8869511,
    lng: parseFloat(_longitude) || 100.5141115,
  })
  const [address, setAddress] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  // const [bounds, setBounds] = useState('')
  const searchBoxRef = useRef(null)
  const searchRef = useRef(null)
  const [zoom, setZoom] = useState(13)
  const mapRef = useRef(null)

  const onMarkerDragEnd = e => {
    var newLat = e.latLng.lat()
    var newLng = e.latLng.lng()
    setMarkerPosition({ lat: newLat, lng: newLng })
    Geocode.fromLatLng(newLat, newLng).then(response => {})
  }

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces()
    const bounds = new window.google.maps.LatLngBounds()

    places.forEach(place => {
      if (place.geometry) {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }
      }
    })
    const nextMarkers = places
      .filter(place => place.geometry)
      .map(place => ({
        position: place.geometry.location,
      }))
    if (nextMarkers.length > 0) {
      mapRef.current.panTo(nextMarkers[0].position)
      onMapClick(
        places[0].geometry.location.lat(),
        places[0].geometry.location.lng(),
        places[0]
      )
      setZoom(13)
    }
  }

  const onMapClick = (lat, lng, place) => {
    setLatitude(lat)
    setLongitude(lng)
    setMapPosition(lat, lng)
    setAddress(place.formatted_address)
    setMarkerPosition({ lat: lat, lng: lng })
    onLocationChange(lat, lng)
  }

  return (
    <GoogleMap
      ref={mapRef}
      style={{ width: '80%' }}
      defaultZoom={15}
      defaultCenter={{ lat: mapPosition.lat, lng: mapPosition.lng }}
    >
      <Marker
        draggable={true}
        onDragEnd={onMarkerDragEnd.bind()}
        position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
      ></Marker>
      <SearchBox
        ref={searchBoxRef}
        controlPosition={window.google.maps.ControlPosition.TOP_CENTER}
        onPlacesChanged={onPlacesChanged}
      >
        <SearchContainer withSearch>
          <Textfield
            ref={searchRef}
            placeholder='ค้นหา'
            width='100%'
            height='33px'
            color='#000'
            backgroundColor='#fff'
            borderColor='transparent'
          />
        </SearchContainer>
      </SearchBox>
    </GoogleMap>
  )
}

const MapComponent = withScriptjs(withGoogleMap(Map))

const Main = ({ onLocationChange, longitude, latitude }) => {
  return (
    <div>
      <MapComponent
        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyAr3fveXCvzMK1HSaWluohdrSVQE740tBk&v=3.exp&libraries=geometry,drawing,places'
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `300px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        onLocationChange={onLocationChange}
        latitude={latitude}
        longitude={longitude}
      />
    </div>
  )
}

export default Main
