// AvailabilityDropdown.tsx
import React, { useMemo } from 'react'
import styled from 'styled-components'

import DropdownButton from '@/components/atoms/DropdownButton'
import { ChevronDown } from '@/components/atoms/icons/Chevron'
import { Separator } from '@/components/atoms/Separator'
import typography from '@/styles/typography'

import { AvailabilityCategory, IAvailabilityItem } from '../types'

export const AvailabilityDropdownContainer = styled.div`
  * {
    white-space: nowrap;
  }
`

export const AvailabilityDropdownTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 3px 10px;
`

const AvailabilityDropdownContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 194px;
`

export const AvailabilityDropdownCategoryContent = styled.div<{
  columns?: number
}>`
  display: grid;
  ${props => props?.columns && `grid-template-columns: repeat(${props.columns}, 1fr);`}
  gap: 4px;
`

export const AvailabilityDropdownCategory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const AvailabilityDropdownCategoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AvailabilityDropdownCategoryTitle = styled.div`
  display: flex;
  align-items: center;
  color: var(--text-tertiary);
  path {
    fill: var(--text-tertiary);
  }
`

export const AvailabilityDropdownCategoryAmountText = styled.div`
  color: var(--primary);
  ${typography.bold}
`

export interface AvailabilityDropdownProps
  extends React.HTMLAttributes<HTMLDivElement> {
  items?: IAvailabilityItem[]
  categories?: AvailabilityCategory[]
  onItemSelect?: (id: number | string) => void
}

export const AvailabilityDropdown: React.FC<AvailabilityDropdownProps> = ({
  items = [
    { id: 1, title: 'Item 1', category: 1, amount: 1 },
    { id: 2, title: 'Item 2', category: 1, amount: 1 },
    { id: 3, title: 'Item 3', category: 2, amount: 1 },
    { id: 4, title: 'Item 4', category: 2, amount: 1 },
  ],
  categories = [
    {
      id: 1,
      title: 'Category 1',
      color: 'red',
      total: 10,
      pronoun: 'Pax',
    },
    {
      id: 2,
      title: 'Category 2',
      color: 'green',
      total: 10,
      pronoun: 'Rooms',
    },
  ],
  onItemSelect,
  ...rest
}) => {
  const amountsByCategory = useMemo(() => {
    return items.reduce((acc, item) => {
      acc[item.category] = (acc[item.category] || 0) + item.amount
      return acc
    }, {} as Record<string | number, number>)
  }, [items])

  return (
    <AvailabilityDropdownContainer {...rest}>
      <DropdownButton>
        <DropdownButton.Trigger>
          <AvailabilityDropdownTrigger>
            <span>Avail</span>
            {categories.map((category, index) => (
              <div
                key={index}
                style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
              >
                <div
                  style={{
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    background: category.color,
                  }}
                />
                <div>{amountsByCategory[category.id] || 0}</div>
                <div>{category.pronoun}</div>
              </div>
            ))}
            <ChevronDown size={12} />
          </AvailabilityDropdownTrigger>
        </DropdownButton.Trigger>
        <DropdownButton.Content>
          <AvailabilityDropdownContent>
            {categories.length === 0 ? (
              <AvailabilityDropdownCategory>
                {items.map((item, index) => (
                  <div
                    key={index}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>{item.title}</div>
                    <div>{item.amount}</div>
                  </div>
                ))}
              </AvailabilityDropdownCategory>
            ) : (
              categories.map((category, index) => (
                <>
                  <AvailabilityDropdownCategory key={index}>
                    <AvailabilityDropdownCategoryHeader>
                      <AvailabilityDropdownCategoryTitle>
                        <span>{category.icon}</span>
                        <span>{category.title}</span>
                      </AvailabilityDropdownCategoryTitle>
                      <AvailabilityDropdownCategoryAmountText>
                        {amountsByCategory[category.id] || 0}
                        {category.total && ` / ${category.total}`}
                      </AvailabilityDropdownCategoryAmountText>
                    </AvailabilityDropdownCategoryHeader>
                    <AvailabilityDropdownCategoryContent columns={category?.columns}>
                    {items
                      .filter(item => item.category === category.id)
                      .map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>{item.title}</div>
                          {category?.hideAmount ? null : (<div>{item.amount}</div>)}
                        </div>
                      ))}
                      </AvailabilityDropdownCategoryContent>
                  </AvailabilityDropdownCategory>
                  {index < categories.length - 1 && <Separator />}
                </>
              ))
            )}
          </AvailabilityDropdownContent>
        </DropdownButton.Content>
      </DropdownButton>
    </AvailabilityDropdownContainer>
  )
}

export const StyledAvailabilityDropdown = styled(AvailabilityDropdown)`
  position: absolute;
  top: 10px;
  left: 60px;
  width: fit-content;
  background: var(--popup);
  border-radius: var(--radius-xs);
`
