import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

// @ts-ignore
import api from '@/api'
import SpaCard from '@/molecules/SpaCard'

import { CLOSE_OFFSET } from './constants'
import { DateSeparator, TimeBox, TimeIndicatorRow } from './Core/TimeIndicator'
import TableRoom from './TableRoom'
import TableStaff from './TableStaff'
import TableUnassigned from './TableUnassigned'
import { TableStaffProps } from './types'
import useTable from './useTable'

//@ts-ignore
import Scrollbar from '../../../atoms/Scrollbar'
import TextLabel from '../../../atoms/TextLabel'

import utils from '../../../utils'

interface TableStaffStyle extends CSSProperties {}

const Container = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
`
const ContainerTimeRow = styled.div<TableStaffStyle>`
  position: ${props => props.position || 'sticky'};
  top: ${props => props.top || '40px'};
  z-index: ${props => props.zIndex || '2'};
`

const StyickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 8;
  background: white;
  width: fit-content;
`

const TimeRow = styled.div`
  display: flex;
`
const ColumnHead = styled.div`
  width: 120px;
  text-align: center;
  border: 1px solid #e5eced;
  flex: 0 0 auto;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  &:first-child {
    position: sticky;
    left: 0;
    z-index: 5;
    background: white;
    margin-left: 0;
  }
`

const Table = ({
  storeOpenCloseTime,
  staffOrders,
  roomOrders,
  date,
  currentDate,
  startStaffLoading,
  tableActive,
  roomTypes,
  bookingPerStaff,
  unassignedStaffBooking,
  onStaffWorkStatusClick,
  onBookingClick,
}: TableStaffProps) => {
  const { t } = useTranslation()

  const {
    times,
    timeNow,
    staffActive,
    availabilityItems,
    categories,
    activeTime,
    startStaffClick,
    onSelectTimeClick,
    onCurrentTimeChange,
  } = useTable({
    storeOpenCloseTime,
    currentDate,
    staffOrders,
    roomOrders,
  })

  const storeStartInMinutes =
    +storeOpenCloseTime.openTime.hour * 60 + +storeOpenCloseTime.openTime.minute
  const storeEndInMinutes =
    (+storeOpenCloseTime.closeTime.hour + CLOSE_OFFSET) * 60 +
    +storeOpenCloseTime.closeTime.minute

  const showTime = (time: string) => {
    const [_hour, _minute] = time.split(':')
    const hour = +_hour
    const minute = +_minute
    if (hour > 23) {
      return `${utils.formatTime(hour - 24 + '')}:${utils.formatTime(
        minute + ''
      )}`
    } else {
      return time
    }
  }

  const [bookingHistory, setBookingHistory] = React.useState<any>(null)

  const callApiBookingHistoryById = async (id: number) => {
    const { data: bookingHistory } = await api.getBookingHistoryById(id)
    return bookingHistory
  }

  useEffect(() => {
    const fetchData = async () => {
      const bookingHistory = await callApiBookingHistoryById(1)
      setBookingHistory(bookingHistory)
    }
    fetchData()
  }, [])

  return (
    <Container>
      <Scrollbar id='home-schedule3' direction='xy' scrollbarHeight='15px'>
        <StyickyContainer>
          <TimeBox
            storeStart={storeStartInMinutes}
            storeEnd={storeEndInMinutes}
            start={60 * 24 - 0.5}
            end={60 * 24}
          >
            <DateSeparator />
          </TimeBox>
          <ContainerTimeRow top='0px' zIndex='2'>
            <div style={{ display: 'flex' }}>
              <TimeRow>
                <ColumnHead></ColumnHead>
                {times.map((time, index) => (
                  <ColumnHead
                    key={index}
                    onClick={e => onSelectTimeClick(e, time, index)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TextLabel
                    // color={index !== activeTime ? '#979797' : '#464B54'}
                    // fontWeight={index !== activeTime ? '400' : '500'}
                    >
                      {showTime(time)}
                    </TextLabel>
                  </ColumnHead>
                ))}
              </TimeRow>
            </div>
          </ContainerTimeRow>

          <TimeIndicatorRow
            date={date}
            times={times}
            time={timeNow}
            storeStart={storeOpenCloseTime.openTime}
            storeEnd={storeOpenCloseTime.closeTime}
            selectedTimeOutside={activeTime}
            availabilityItems={availabilityItems}
            categories={categories}
            onCurrentTimeChange={onCurrentTimeChange}
          />

          <TableUnassigned
            date={date}
            currentDate={currentDate}
            storeOpenCloseTime={storeOpenCloseTime}
            startStaffLoading={startStaffLoading}
            staffActive={staffActive}
            times={times}
            onStaffWorkStatusClick={onStaffWorkStatusClick}
            startStaffClick={startStaffClick}
            data={unassignedStaffBooking}
          />
        </StyickyContainer>
        {tableActive === 'staff' ? (
          <TableStaff
            date={date}
            currentDate={currentDate}
            storeOpenCloseTime={storeOpenCloseTime}
            staffOrders={staffOrders}
            startStaffLoading={startStaffLoading}
            staffActive={staffActive}
            times={times}
            onStaffWorkStatusClick={onStaffWorkStatusClick}
            startStaffClick={startStaffClick}
            data={bookingPerStaff}
            onBookingClick={onBookingClick}
            // bookOrders={bookOrders}
          />
        ) : (
          <TableRoom roomTypes={roomTypes} times={times} />
        )}
      </Scrollbar>
    </Container>
  )
}

export default Table
