import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import dayjs from 'dayjs'

import TextLabel from '../../atoms/TextLabel'
import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'
import Calendar2 from '../datePicker/Calendar2'
import Time from '../timePicker/Time'
import SwitchButton from '../../atoms/SwitchButton'
import DateIcon from '../../atoms/DateIcon'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import ButtonSave from '../../atoms/ButtonSave'

import api from '../../api'
import auth from '../../auth'
import utils from '../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100%);
  background: #ffffff;
  padding: 1rem 1rem;
`
const Input = styled.input`
  background: #ffffff;
  border: ${props => props.border || '2px solid #e4e8eb'};
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  border-radius: ${props => props.borderRadius || '8px'};
  padding: 0.5rem 0 0.5rem 1rem;
  width: ${props => props.width || 'auto'};
`
const InputArea = styled.textarea`
  background: #ffffff;
  border: 2px solid #e4e8eb;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  border-radius: 8px;
  padding: 0.5rem 0 0.5rem 1rem;
`
const Box = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 1 50%'};
  min-width: ${props => props.width || '50%'};
  max-width: ${props => props.width || '50%'};
  align-items: center;
`
const BoxText = styled.div`
  display: flex;
  background: #ffffff;
  border: 2px solid #e4e8eb;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  border-radius: 8px;
  width: 100%;
  padding: 0.5rem 0 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
`
const IconInBox = styled.div`
  position: absolute;
  right: ${props => props.right || '1rem'};
`
const Arrow = styled.div`
  border: solid #475467;
  border-width: 0 2px 2px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  transition: all 0.4s ease;
  transform: ${props => props.transform || 'rotate(45deg)'};
`

const CardAddPromotion = ({ handleClose, currentPromotion = '' }) => {
  const dateNow = new Date()
  const [dateStart, setDateStart] = useState(dateNow)
  const [dateEnd, setDateEnd] = useState(dateNow)
  const [openDate, setOpenDate] = useState(-1)
  const [openTime, setOpenTime] = useState(-1)
  const [name, setName] = useState('')
  const [discount, setDiscount] = useState(0)
  const [termsAndConditions, setTermsAndConditions] = useState('')
  const [quota, setQuota] = useState(0)
  const [startTime, setStartTime] = useState({
    hour: dateNow.getHours(),
    minute: dateNow.getMinutes(),
  })
  const [endTime, setEndTime] = useState({
    hour: dateNow.getHours(),
    minute: dateNow.getMinutes(),
  })
  const [selectTime, setSelectTime] = useState({
    hour: '',
    minute: '',
  })
  const [active, setActive] = useState(true)
  const [checkName, setCheckName] = useState(false)
  const [checkDiscount, setCheckDiscount] = useState(false)
  const [checkQuota, setCheckQuota] = useState(false)
  const optionsUnit = [
    { value: 1, label: '฿' },
    { value: 2, label: '%' },
  ]
  const [optionsUnitDefault, setOptionUnitDefault] = useState(optionsUnit[0])
  //   ฿
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (currentPromotion) {
      setName(currentPromotion.name)
      setDiscount(currentPromotion.discount)
      setTermsAndConditions(currentPromotion.termsAndConditions)
      setQuota(currentPromotion.quota)
      setStartTime(currentPromotion.startTime)
      setEndTime(currentPromotion.endTime)
      setDateStart(currentPromotion.dateStart)
      setDateEnd(currentPromotion.dateEnd)
      setActive(currentPromotion.active === 1)
      setOptionUnitDefault(optionsUnit[currentPromotion.unit - 1])
    }
  }, [currentPromotion])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#B28AB4' : '',
      cursor: 'pointer',
      outline: 'none',
    }),
    control: provided => ({
      ...provided,
      width: '80px',
      borderWidth: '2px 2px 2px 0px',
      borderRadius: '0 8px 8px 0',
      borderColor: '#e4e8eb',
      outline: 'none',
      padding: '0.13rem 0',
    }),
    input: provided => ({
      ...provided,
      color: 'black',
      outline: 'none',
    }),
    menu: provided => ({
      ...provided,
      outline: 'none',
      //   borderRadius: '8px 0 0 8px',
    }),
  }

  const onDateChange = date => {
    if (openDate === 1) {
      setDateStart(date)
    } else {
      setDateEnd(date)
    }
    setOpenDate(-1)
  }

  const ShowDate = (type, date) => {
    return (
      <div
        style={{
          width: '100%',
          border: '2px solid #E4E8EB',
          borderRadius: '8px',
          padding: '0.25rem 0.25rem',
        }}
      >
        <Calendar2
          selectedDate={date}
          selectedEndDate={date}
          ranged={false}
          onDateChange={onDateChange}
          highlightColor={'#8C56D9'}
          color={'#666666'}
          backwardable={true}
          fontwardable={true}
        />
      </div>
    )
  }
  const onClickOpenDate = type => {
    if (type === openDate) {
      setOpenDate(-1)
    } else {
      setOpenDate(type)
    }
  }

  const onClickOpenTime = type => {
    if (type === openTime) {
      setOpenTime(-1)
    } else {
      setOpenTime(type)
    }
  }

  const ShowTime = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          border: '2px solid #E4E8EB',
          borderRadius: '8px',
          padding: '0.25rem 0.25rem',
        }}
      >
        <Time
          hourNow={startTime.hour}
          minuteNow={startTime.minute}
          timeActive={timeActive}
        />
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className='mr-3' onClick={() => setOpenTime(-1)}>
            <TextLabel color='#ABABAD'>ยกเลิก</TextLabel>
          </div>
          <div onClick={onSelectTime.bind(this)}>
            <TextLabel color='#00B548' fontWeight='600'>
              ยืนยัน
            </TextLabel>
          </div>
        </div> */}
      </div>
    )
  }

  const timeActive = (hour, minute) => {
    // setSelectTime({ hour, minute })
    if (openTime === 1) {
      setStartTime({ hour: hour, minute: minute })
    } else if (openTime === 2) {
      setEndTime({ hour: hour, minute: minute })
    }
    // setOpenTime(-1)
  }

  const onChangeName = value => {
    setName(value)
    if (value) {
      setCheckName(false)
    }
  }
  const onChangeDiscount = value => {
    if (utils.checkNumber(value)) {
      setDiscount(value)
      setCheckDiscount(false)
    } else if (!value) {
      setDiscount(value)
    }
  }
  const onChangeQuota = value => {
    if (utils.checkNumber(value)) {
      setQuota(value)
      setCheckQuota(false)
    } else if (!value) {
      setQuota(value)
    }
  }

  const onSaveClick = async () => {
    try {
      if (name === '' || discount === '' || quota === '') {
        if (name === '') {
          setCheckName(true)
        }
        if (discount === '') {
          setCheckDiscount(true)
        }
        if (quota === '') {
          setCheckQuota(true)
        }

        return false
      }
      const create = {
        name,
        discount,
        unit: optionsUnitDefault.value,
        termsAndConditions,
        quota,
        startTime,
        endTime,
        dateStart,
        dateEnd,
        active: active ? 1 : -1,
        storeId: auth.getStoreId(),
      }
      if (currentPromotion) {
        const { success } = await api.updatePromotion(
          currentPromotion._id,
          create
        )
        if (success) {
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
            handleClose()
          }, 1000)
        }
      } else {
        const { success } = await api.createPromotion(create)
        if (success) {
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
            handleClose()
          }, 1000)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <div className='mt-1'></div>
      <TextLabel fontWeight='600'>ชื่อโปรโมชั่น*</TextLabel>
      <div className='mt-1'></div>
      <Input
        placeholder='ชื่อโปรโมชั่น'
        value={name}
        onChange={val => onChangeName(val.target.value)}
        border={checkName ? '2px solid #DB2D1E' : ''}
      />
      <div className='mb-2'></div>
      <TextLabel fontWeight='600'>ส่วนลด*</TextLabel>
      <div className='mt-1 mb-2' style={{ display: 'flex', width: '100%' }}>
        <Input
          placeholder='ส่วนลด'
          borderRadius='8px 0 0 8px'
          width='calc(100% - 80px)'
          value={discount}
          onChange={val => onChangeDiscount(val.target.value)}
          border={checkDiscount ? '2px solid #DB2D1E' : ''}
        />
        <Select
          closeMenuOnSelect={true}
          value={optionsUnitDefault}
          styles={customStyles}
          options={optionsUnit}
          onChange={val => setOptionUnitDefault(val)}
        />
      </div>
      {/* <TextLabel fontWeight='600'>ข้อกำหนดและเงื่อนไข</TextLabel>
      <div className='mt-1'></div>
      <InputArea
        placeholder='ข้อกำหนดและเงื่อนไข'
        value={termsAndConditions}
        onChange={val => setTermsAndConditions(val.target.value)}
      />
      <div className='mb-2'></div>
      <TextLabel fontWeight='600'>จำนวนสิทธิ์*</TextLabel>
      <div className='mb-1'></div>
      <Input
        placeholder='จำนวนสิทธิ์'
        value={quota}
        onChange={val => onChangeQuota(val.target.value)}
        border={checkQuota ? '2px solid #DB2D1E' : ''}
      />
      <div className='mb-2'></div>

      <TextLabel fontWeight='600'>ช่วงเวลาใช้โปรโมชั่น</TextLabel>
      <div className='mt-1' style={{ display: 'flex', alignItems: 'center' }}>
        <Box
          flex='1 1 50%'
          width='calc(50% -0.25rem)'
          onClick={onClickOpenTime.bind(this, 1)}
        >
          <BoxText>
            {`${utils.formatTime(startTime.hour)}:${utils.formatTime(
              startTime.minute
            )} น.`}
            <IconInBox>
              <Arrow transform={openTime === 1 ? 'rotate(-135deg)' : ''} />
            </IconInBox>
          </BoxText>
        </Box>
        <div className='ml-1 mr-1'>-</div>
        <Box
          flex='1 1 50%'
          width='calc(50% -0.25rem)'
          onClick={onClickOpenTime.bind(this, 2)}
        >
          <BoxText>
            {`${utils.formatTime(endTime.hour)}:${utils.formatTime(
              endTime.minute
            )} น.`}
            <IconInBox>
              <Arrow transform={openTime === 2 ? 'rotate(-135deg)' : ''} />
            </IconInBox>
          </BoxText>
        </Box>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Box flex='1 1 50%' width='calc(50% -0.25rem)'>
          {openTime === 1 && ShowTime()}
        </Box>
        <div className='ml-1 mr-1' style={{ visibility: 'hidden' }}>
          -
        </div>
        <Box flex='1 1 50%' width='calc(50% -0.25rem)'>
          {openTime === 2 && ShowTime()}
        </Box>
      </div> */}
      <TextLabel fontWeight='600'>วัน เวลา ที่สามารถใช้โปรโมชั่น</TextLabel>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Box
          flex='1 1 50%'
          width='calc(50% -0.25rem)'
          onClick={() => onClickOpenDate(1)}
        >
          <BoxText>
            {dayjs(dateStart).format('DD/MM/YYYY')}
            <IconInBox right='0.75rem'>
              <DateIcon size='17' />
            </IconInBox>
          </BoxText>
        </Box>
        <div className='ml-1 mr-1'>-</div>
        <Box
          flex='1 1 50%'
          width='calc(50% -0.25rem)'
          onClick={() => onClickOpenDate(2)}
        >
          <BoxText>
            {dayjs(dateEnd).format('DD/MM/YYYY')}
            <IconInBox right='0.75rem'>
              <DateIcon size='17' />
            </IconInBox>
          </BoxText>
        </Box>
      </div>
      <div style={{ display: 'flex' }}>
        <Box flex='1 1 50%' width='calc(50% -0.25rem)'>
          {openDate === 1 && ShowDate('start', dateStart)}
        </Box>
        <div className='ml-1 mr-1' style={{ visibility: 'hidden' }}>
          -
        </div>
        <Box flex='1 1 50%' width='calc(50% -0.25rem)'>
          {openDate === 2 && ShowDate('end', dateEnd)}
        </Box>
      </div>
      <div style={{ display: 'flex' }}>
        <Box flex='1 1 78%' width='78%'>
          <div>
            <TextLabel fontWeight='600'>สถานะ</TextLabel>
            <TextLabel fontWeight='500' color='#98A1B2'>
              ปิด/เปิด
            </TextLabel>
          </div>
        </Box>
        <Box flex='1 1 22%' width='22%' style={{ justifyContent: 'flex-end' }}>
          <SwitchButton
            toggled={active}
            onChange={e => setActive(e.target.checked)}
            backgroundColor={active ? '#8C56D9' : '#ABABAD'}
            labelHeight='25px'
            width='23px'
          />
        </Box>
      </div>
      <div className='mt-5' style={{ display: 'flex' }}>
        <Box flex='1 1 40%' width='40%'>
          <Button2
            border='2px solid #98A1B2'
            backgroundColor='#FFFFFF'
            backgroundColorHover='#E5E7EC'
            color='#475467'
            fontWeight='bold'
            onClick={() => handleClose()}
          >
            ยกเลิก
          </Button2>
        </Box>
        <Box flex='1 1 auto' width='auto'>
          <ButtonSave
            margin='0.125rem 0 0.125rem 1rem'
            backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
            color='#FFFFFF'
            fontWeight='bold'
            border='2px solid #53389E'
            icon={<SaveIcon size='17' color='#FFFFFF' />}
            onClick={() => onSaveClick()}
          >
            บันทึก
          </ButtonSave>
        </Box>
      </div>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddPromotion
