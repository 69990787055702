export const GET_TYPE_BOOKING = 'GET_TYPE_BOOKING'

export const getTypeBooking = typeBooking => {
  return {
    type: GET_TYPE_BOOKING,
    payload: { typeBooking },
  }
}

export const UPDATE_PAY = 'UPDATE_PAY'

export const updatePay = payout => {
  return {
    type: UPDATE_PAY,
    payload: { payout },
  }
}

export const UPDATE_COMMISSION = 'UPDATE_COMMISSION'

export const updateCommission = commission => {
  return {
    type: UPDATE_COMMISSION,
    payload: { commission },
  }
}

export const UPDATE_BOOKING_CHANNEL = 'UPDATE_BOOKING_CHANNEL'

export const updateBookingChannel = bookingChannel => {
  return {
    type: UPDATE_BOOKING_CHANNEL,
    payload: { bookingChannel },
  }
}

export const CLEAR_TYPE_BOOKING = 'CLEAR_TYPE_BOOKING'

export const clearTypeBooking = typeBooking => {
  return {
    type: CLEAR_TYPE_BOOKING,
    payload: { typeBooking },
  }
}
