import React from 'react'

import { IconProps } from '../../../lib/icons/IconPropsType'

const PieIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size || '18'}
      height={size || '17'}
      viewBox='0 0 20 21'
      fill={color || '#6C63FF'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M15.8685 13.2023C14.9982 16.4497 11.9281 18.8053 8.36112 18.5636C4.84514 18.3245 1.96843 15.523 1.64342 12.0151C1.31841 8.50716 3.42156 5.57136 6.42988 4.49696C6.7522 4.38146 6.96977 4.08063 6.96977 3.73682C6.96977 3.18619 6.4272 2.78597 5.90611 2.97131C2.3767 4.22299 -0.126658 7.64227 0.00495586 11.631C0.155372 16.1757 3.81908 19.9281 8.35843 20.1805C12.6856 20.4196 16.3842 17.5563 17.4291 13.6106C17.5634 13.1002 17.1739 12.6006 16.6474 12.6006C16.2795 12.6006 15.9625 12.8504 15.8685 13.205V13.2023Z' />
      <path d='M10.5369 1.80557C10.5369 1.80557 10.5477 1.80557 10.553 1.80557C14.3618 2.47707 17.3943 5.34573 18.278 9.11419C18.278 9.12494 18.2833 9.133 18.2672 9.15449C18.2457 9.18134 18.2243 9.18134 18.2135 9.18134H10.5396C10.5047 9.18134 10.4778 9.15448 10.4778 9.11957V1.87004C10.4778 1.82169 10.5262 1.80557 10.5396 1.80557H10.5369ZM10.5396 0.19397C9.63441 0.19397 8.86621 0.927249 8.86621 1.87004V9.11688C8.86621 10.0409 9.61561 10.7903 10.5396 10.7903H18.2135C19.2879 10.7903 20.0937 9.79107 19.8493 8.74621C18.8259 4.38951 15.2777 1.00246 10.8324 0.22083C10.733 0.202028 10.6336 0.19397 10.5369 0.19397H10.5396Z' />
    </svg>
  )
}

export default PieIcon
