import React, { ReactNode } from 'react'
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import styled from 'styled-components'

import ChartHeader from './ChartHeader'
import { COLORS } from './config'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const TopContainer = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 0.5px solid #e4e8eb;
  display: flex;
  justify-content: space-between;
`

const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export interface CustomizedAreaChartDataItem {
  name: string
  value: number
}

export interface CustomizedAreaChartProps {
  name: string
  icon?: ReactNode
  data?: CustomizedAreaChartDataItem[]
  dataKey: string
}

const exampleData: CustomizedAreaChartDataItem[] = [
  {
    name: '',
    value: 0,
  },
]

const CustomizedAreaChart = ({
  name,
  icon,
  data = exampleData,
  dataKey,
}: CustomizedAreaChartProps) => {
  return (
    <Container>
      <TopContainer>
        <ChartHeader name={name} icon={icon} />
      </TopContainer>
      <ChartContainer>
        <AreaChart
          width={380}
          height={225}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='_id' />
          <YAxis tickFormatter={val => `${val} Pax`} allowDecimals={false} />
          <Tooltip />
          <Area
            type='monotone'
            dataKey={dataKey || 'value'}
            stroke={COLORS[0]}
            fill={COLORS[0]}
          />
        </AreaChart>
      </ChartContainer>
    </Container>
  )
}

export default CustomizedAreaChart
