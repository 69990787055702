import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

// @ts-ignore
import PopupNotification from './notifications/PopupNotification'
// @ts-ignore
import PopupSelectBranch from './popups/PopupSelectBranch'
// import ScanIcon from '../atoms/ScanIcon'
// @ts-ignore
import PopupSelectBrand from './popups/PopupSelectBrand'
// @ts-ignore
import SelectorBox from './selector/SelectorBox'

// @ts-ignore
import api from '../api'
import InputText from '../atoms/InputText'
// @ts-ignore
import LanguageIcon from '../atoms/LanguageIcon'
// @ts-ignore
import NotiIcon from '../atoms/NotiIcon'
// @ts-ignore
import SearchIcon from '../atoms/SearchIcon'
// @ts-ignore
import TextLabel from '../atoms/TextLabel'
import auth from '../auth'
//@ts-ignore
import { getSocket } from '../hook/useSockets'
import usePage from '../lib/layout/Page/usePage'
// @ts-ignore
import MenuSelector from '../molecules/MenuSelector'
import { fetchNotification } from '../redux/actions/storeAction'
import { DISABLED_ALL_URLS, LOGIN_URL, SETTING_PROFILE_URL } from '../routes'

const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`

const Card = styled.div`
  background-color: #ffffff;
  border-bottom: 2px solid #e4e8eb;
  width: 100%;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
`

const StoreBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const IconStyle = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
`

const DivInputStyle = styled.div`
  position: relative;
  padding: 0;
  height: fit-content;
`

const ImageUser = styled.div<any>`
  aspect-ratio: 1;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  width: 40px;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  cursor: pointer;
`

const DropUser = styled.div<any>`
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  right: 0px;
  top: 50px;
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.08));
  width: max-content;
  z-index: 6;
`
const Options = styled.div`
  cursor: pointer;
  padding: 0.25rem 1rem;
  font-weight: 500;
  color: #000000;
  :hover {
    background: linear-gradient(221.86deg, #8c56d9 2.71%, #53389e 97.79%);
    color: #ffffff;
  }
`
const NotificationsAnimation = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`
const BoxNotifications = styled.div<any>`
  position: relative;
  animation: ${props => props.animation || 'shake 0.25s'};
  animation-iteration-count: infinite;
  animation-name: ${NotificationsAnimation};
  cursor: pointer;
`

const language = ['TH', 'EN']

const Header = () => {
  const dispatch = useDispatch()

  const history = useHistory()
  const _store = useSelector((state: any) => state.store)
  const user = useSelector((state: any) => state.user)
  const brand = useSelector((state: any) => state.brand)

  const { notificationCount, ...store } = _store || {}

  const [openDropDownProfile, setOpenDropDownProfile] = useState(false)
  const [openSelectProfile, setOpenSelectProfile] = useState(false)
  const [openDropDownLanguage, setOpenDropDownLanguage] = useState(false)
  const [languageActive, setLanguageActive] = useState(0)
  const [shake, setShake] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)
  const [openSelectBranch, setOpenSelectBranch] = useState(false)

  const { onChange, onToggle } = usePage()

  const { i18n, t } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    if (DISABLED_ALL_URLS.includes(location.pathname) && user.storeId === 0) {
      setOpenSelectBranch(true)
    }
  }, [location.pathname, user.storeId])

  useEffect(() => {
    if (auth.isLoggedIn()) {
      getSocket().on('instant', () => {
        dispatch(fetchNotification())
        setShake(true)
        setInterval(() => {
          setShake(false)
        }, 5000)
      })
      dispatch(fetchNotification())
      setLanguageActive(localStorage.getItem('language') === 'en' ? 1 : 0)
    }
  }, [])

  const onOpenSelectProfileClick = () => {
    setOpenDropDownProfile(false)
    setOpenSelectProfile(v => !v)
  }

  const onProfileClick = () => {
    return history.replace(SETTING_PROFILE_URL)
  }

  const onLogoutClick = () => {
    auth.logout()
    return history.replace(LOGIN_URL)
  }

  const toggleLang = (language: string) => {
    const currentLang = language
    const nextLang = currentLang === 'TH' ? 'th' : 'en'
    localStorage.setItem('language', nextLang)
    i18n.changeLanguage(nextLang)
    dayjs.locale(nextLang)
    setOpenDropDownLanguage(false)
  }

  return (
    <Container>
      <Card>
        <StoreBox>
          <TextLabel fontWeight='600'>{brand.name}</TextLabel>
          <SelectorBox
            value={user.storeId ? store.branch || 'ทุกสาขา' : 'ทุกสาขา'}
            onClick={() => setOpenSelectBranch(true)}
          />
        </StoreBox>
        <Box>
          <DivInputStyle>
            <InputText
              placeholder={`${t('name/phoneNumber')}`}
              fontSizePlaceholder='0.9rem'
              padding='0.5rem 0 0.5rem 1.5rem'
              background='#F8F9FB'
              border='1px solid #F8F9FB'
              colorPlaceholder='#C8CDD7'
              fontSize='0.875rem'
              fontWeight='500'
              onChange={onChange}
            />
            <IconStyle>
              <SearchIcon size='14' color='#C8CDD7' />
            </IconStyle>
          </DivInputStyle>
          {/* <ScanIcon color='#000000' size='25' /> */}
          <BoxNotifications
            onClick={onToggle}
            animation={shake ? 'shake 0.25s' : 'shake 0.s'}
          >
            {notificationCount !== 0 && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '1rem',
                  left: '0.5rem',
                  border: '1px solid #FFF',
                  borderRadius: '16px',
                  fontSize: '0.75rem',
                  color: '#FFF',
                  background: '#DB2D1E',
                  width: '23px',
                  textAlign: 'center',
                  // padding: '0 0.25rem',
                }}
              >
                {notificationCount}
              </div>
            )}
            <NotiIcon color='#000000' size='25' />
          </BoxNotifications>
          <div style={{ position: 'relative' }}>
            <ImageUser
              background={user.image || ''}
              onClick={() => setOpenDropDownProfile(v => !v)}
            />
            {openDropDownProfile && (
              <DropUser>
                <Options onClick={() => onOpenSelectProfileClick()}>
                  เลือกร้าน
                </Options>
                <Options onClick={() => onProfileClick()}>โปรไฟล์</Options>
                <Options onClick={() => onLogoutClick()}>ออกจากระบบ</Options>
              </DropUser>
            )}
          </div>
          {/* {location.pathname === REPORT_URL && ( */}
          <MenuSelector
            name='language'
            icon={<LanguageIcon />}
            option={language[languageActive]}
            options={language}
            isOpen={openDropDownLanguage}
            onToggle={value => setOpenDropDownLanguage(value)}
            onSelect={(option, _, index) => {
              toggleLang(option)
              setLanguageActive(index)
            }}
          />
          {/* )} */}
        </Box>
      </Card>
      {openSelectProfile && (
        <PopupSelectBrand handleClose={onOpenSelectProfileClick} />
      )}
      {false && <PopupNotification notificationCount={notificationCount} />}
      {openSelectBranch && (
        <PopupSelectBranch handleClose={() => setOpenSelectBranch(false)} />
      )}
    </Container>
  )
}

export default Header
