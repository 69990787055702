import React from 'react'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddServiceGroup from '../settingProduct/CardAddServiceGroup'

const PopupAddServiceGroup = ({
  handleClose,
  serviceGroup,
  currentEditId,
  currentEditData,
}) => {
  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' height='50vh' marginTop='0' padding='0'>
        <CardAddServiceGroup
          handleClose={handleClose}
          serviceGroup={serviceGroup}
          currentIdEdit={currentEditId}
          currentDataEdit={currentEditData}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddServiceGroup
