import React, { ChangeEvent, HTMLAttributes } from 'react'
import styled from 'styled-components'

import TextLabel from './TextLabel'

interface ContainerProps {
  width?: string
  height?: string
  background?: string
  error?: boolean
  border?: string
  borderBottom?: string
  boxShadow?: string
  borderRadius?: string
  padding?: string
  borderFocus?: string
  readOnly?: boolean
  justifyContent?: string
}

interface StyledInputProps {
  widthInput?: string
  fontSize?: string
  fontWeight?: string
  color?: string
  textAlign?: string
  colorPlaceholder?: string
  fontSizePlaceholder?: string
}

interface InputTextProps
  extends ContainerProps,
    StyledInputProps,
    HTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode
  children?: React.ReactNode
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: any) => void
  padding?: string
  refProp?: React.Ref<HTMLDivElement>
  maxLength?: number
  value?: any
  placeholder?: string
}

const Container = styled.div<ContainerProps>`
  width: ${props => props.width || '100%'};
  max-height: ${props => props.height || '36px'};
  background: ${props => props.background || '#ffffff'};
  border: ${props =>
    props.error ? '1px solid #db2d1e' : props.border || '1px solid #e4e8eb'};
  border-bottom: ${props => props.borderBottom || ''};
  box-shadow: ${props =>
    props.boxShadow || '0px 4px 4px rgba(71, 84, 103, 0.05)'};
  position: relative;
  border-radius: ${props => props.borderRadius || '4px'};
  padding: ${props => props.padding || '0.25rem 0.5rem'};
  :focus-within {
    outline: none;
    border: ${props =>
      props.error
        ? '1px solid #db2d1e'
        : props.borderFocus || '1px solid #8c56d9'};
  }
  pointer-events: ${props => (props.readOnly ? 'none' : '')};
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'unset'};
  gap: 0.25rem;
`

const Separator = styled.div`
  width: 1px;
  height: 20px;
  background: #e4e8eb;
  margin: 0 0.25rem;
`

const StyledInput = styled.input<StyledInputProps>`
  width: ${props => props.widthInput || '100%'};
  background: transparent;
  border: none;
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || '400'};
  color: ${props => props.color || '#000000'};
  text-align: ${props => props.textAlign || 'unset'};
  ::placeholder {
    color: ${props => props.colorPlaceholder || '#D0D4DD'};
    font-size: ${props => props.fontSizePlaceholder || '1rem'};
  }
  :focus-visible {
    outline: none;
  }
`

const InputText = (props: InputTextProps) => {
  const { icon, children, onChange, onClick, padding, refProp, ...restProps } =
    props
  const { maxLength, value } = restProps

  return (
    <Container ref={refProp} padding={padding} {...restProps}>
      {icon}
      {icon && <Separator />}
      <StyledInput {...restProps} onChange={onChange} onClick={onClick} />
      {children}
      {maxLength && (
        <div>
          <TextLabel fontSize='0.75rem' fontWeight={500} color='#C8CDD7'>
            {value?.length || 0}/{maxLength}
          </TextLabel>
        </div>
      )}
    </Container>
  )
}

export default InputText
