import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled, { css, CSSObject } from 'styled-components'

import Scrollbar from '@/atoms/Scrollbar'
import DropdownButton from '@/components/atoms/DropdownButton'
import { Row } from '@/components/atoms/Flex'
import { BoxIcon } from '@/components/atoms/icons/BoxIcon'
import { DotsHorizontalIcon } from '@/components/atoms/icons/DotsIcon'
import { HearthHandIcon } from '@/components/atoms/icons/HandIcon'
import { TagsIcon } from '@/components/atoms/icons/TagIcon'
import Tooltip from '@/components/atoms/Tooltip'
import { onPayClick } from '@/components/pages/searchHistory/ButtonPay'
// @ts-ignore
import PopupStart from '@/components/popups/PopupStart'
import config from '@/config'
import { schedulerBookingHistoriesAtom } from '@/jotai/SchedulerAtom'

import Modal from '../modal'
import ModalStartJob from '../ModalStartJob'

export const STATUS_VARIANT = [
  'tertiary',
  'secondary',
  'primary',
  'success',
  'warning',
  'danger',
] as DataTableValueVariant[]

export interface IDataTableHeader {
  label: string
  key: string
}

export type DataTableValueVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'success'
  | 'warning'
  | 'danger'

export type DataTableValue =
  | string
  | number
  | Date
  | Array<{
      key: string | number
      value: string | number | Date
      variant?: DataTableValueVariant
    }>
  | { key: string | number; value: string | number | Date }

export interface IDataTableData {
  [key: string]: DataTableValue
}

export interface IDataTableDataCellOptions {
  type?: 'service-badge' | 'list' | 'status'
  style?: React.CSSProperties
  className?: string
}

export interface IDataTableDataColumnOptions {
  [key: string]: IDataTableDataCellOptions
}

export interface DataTableProps {
  headers: IDataTableHeader[]
  data: IDataTableData[]
  columnOptions?: IDataTableDataColumnOptions
  hasMore?: boolean
  fetchData?: () => void | Promise<void>
  actions?: {
    key: string
    label: React.ReactNode
    icon?: React.ReactNode
  }[]
  onActionClick?: (key: string, id: string | number) => void | Promise<void>
  onItemClick?: (id: string | number) => void | Promise<void>
  idColumn?: string
}

export const StyledDataTableContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`

export const StyledDataTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 7px;
`

export const StyledHeader = styled.div`
  display: flex;
  background-color: transparent;
  padding: 12px 8px;
`

export const StyledHeaderCell = styled.div<{
  width?: string
  customStyle?: React.CSSProperties
}>`
  width: 94px;
  flex: 0 0 auto;
  color: #98a1b2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  padding: 0px 0px;
  @media (max-width: 767px) {
    width: 50px;
  }
  ${({ customStyle }) => customStyle && css(customStyle as CSSObject)}
`

export const StyledCell = styled.div<{
  width?: string
  customStyle?: React.CSSProperties
}>`
  width: 94px;
  flex: 0 0 auto;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  font-size: 12px;
  display: flex;
  vertical-align: middle;
  @media (max-width: 767px) {
    width: 50px;
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: fit-content;
  }
  ${({ customStyle }) => customStyle && css(customStyle as CSSObject)}
`

export const StyledActionCell = styled(StyledCell)`
  width: 40px;
`

export const StyledActionHeaderCell = styled(StyledHeaderCell)`
  width: 40px;
  flex: 0 0 auto;
`

export const StyledRow = styled.div`
  width: fit-content;
  display: flex;
  background: var(--popup);
  border-radius: 8px;
  padding: 12px 8px;
  border: 1pt solid #e4e8eb;
  margin-bottom: 7px;
`

export const StyledBadge = styled.span<{
  variant?: DataTableValueVariant
}>`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  border-radius: var(--radius-sm);
  min-width: 70px;
  text-align: center;
  justify-content: center;
  height: 32px;

  ${({ variant }) => {
    return css`
      color: var(--${variant});
      background-color: var(--${variant}-bg);
      path {
        fill: var(--${variant});
      }
    `
  }}
`

export const StyledIconWrapper = styled.span`
  background-color: var(--popup);
  padding: 4px;
  border-radius: 9999px;
`

export const StyledList = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  position: relative;
`

export const StyledListBadge = styled.span`
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--muted);
  width: fit-content;
  border-radius: var(--radius-xs);
  min-width: 20px;
  text-align: center;
  height: fit-content;
`

export const StyledWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export const StyledListItem = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 12px;
`

export const StyledTooltip = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const StyledDropdownItem = styled(DropdownButton.Item)`
  display: flex;
  gap: 8px;
  align-items: center;
  :hover {
    path {
      fill: var(--text-contrast);
    }
  }
`

export const DotsButton = styled(DotsHorizontalIcon)`
  cursor: pointer;
  :hover {
    path {
      fill: var(--primary);
    }
  }
`

export const DataTableCell: React.FC<{
  value: DataTableValue
  columnKey: string
  options: IDataTableDataCellOptions
  isHeader?: boolean
  className?: string
  id: string | number
}> = ({ value, columnKey, options, isHeader, className, id }) => {
  const [isPayStatusHover, setIsPayStatusHover] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [isPopupBookOpen, setIsPopupBookOpen] = useState(false)
  const [bookingHistories] = useAtom(schedulerBookingHistoriesAtom)
  const [selectStart, setSelectStart] = useState({})

  const renderCellContent = () => {
    if (isHeader) {
      return value as string
    }

    const cellOptions = options || {}
    let values = Array.isArray(value)
      ? value
      : [{ key: 0, value: value?.toString() as string }]

    switch (cellOptions.type) {
      case 'service-badge':
        return renderServiceBadge(values[0])
      case 'list':
        return renderList(values)
      case 'status':
        return renderStatus(values[0], columnKey)
      default:
        return <span>{values?.[0]?.value instanceof Date ? values[0].value.toISOString() : values[0].value}</span>
    }
  }

  const renderServiceBadge = (value: {
    key: string | number
    value: string | number | Date
  }) => {
    const type = config.TRANSACTION_TYPE[(value.key as number) - 1]
    let Icon = null
    let variant: DataTableValueVariant = 'primary'

    switch (type) {
      case 'service':
        Icon = <HearthHandIcon size={14} />
        variant = 'primary'
        break
      case 'product':
        Icon = <BoxIcon size={14} />
        variant = 'tertiary'
        break
      case 'productAndService':
      case 'package':
        Icon = <TagsIcon size={14} />
        variant = type === 'productAndService' ? 'success' : 'quaternary'
        break
    }

    return (
      <StyledBadge variant={variant}>
        <StyledIconWrapper>{Icon}</StyledIconWrapper>
        <span>{value.value?.toLocaleString()}</span>
      </StyledBadge>
    )
  }

  const renderList = (
    values: Array<{ key: string | number; value: string | number | Date }>
  ) => {
    const listText = values.map(value => value.value).join(', ')
    return (
      <StyledList>
        <Tooltip
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          text={listText}
        >
          <StyledTooltip />
        </Tooltip>
        <StyledListBadge>{values.length}</StyledListBadge>
        <StyledListItem>{listText}</StyledListItem>
      </StyledList>
    )
  }

  const renderStatus = (
    value: {
      key: string | number
      value: string | number | Date
      variant?: DataTableValueVariant
    },
    key: string
  ) => {
    let variant = value.variant || 'primary'

    if (key === 'payStatus' && value.value === 'ยังไม่ชำระเงิน') {
      return (
        <StyledBadge
          variant={isPayStatusHover ? 'primary' : 'danger'}
          style={{
            cursor: 'pointer',
            background: isPayStatusHover
              ? 'var(--gradient-red)'
              : 'var(--gradient-red)',
            color: isPayStatusHover
              ? 'var(--text-contrast)'
              : 'var(--text-contrast)',
          }}
          onMouseOver={() => setIsPayStatusHover(true)}
          onMouseLeave={() => setIsPayStatusHover(false)}
          onClick={
            isPayStatusHover
              ? (e: any) => {
                  onPayClick(e, id?.toString(), dispatch, history)
                }
              : undefined
          }
        >
          {t('due')}
        </StyledBadge>
      )
    }

    if (key === 'payStatus' && value.value === 'จ่ายแล้ว') {
      return <StyledBadge variant='success'>{t('paid')}</StyledBadge>
    }

    if (key === 'status') {
      if (value?.key === 'booked' || value?.key === 'confirmed') {
        return (
          <StyledBadge
            style={{
              background: 'var(--gradient)',
              color: 'var(--text-contrast)',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsPopupBookOpen(true)
            }}
            variant='primary'
          >
            {value.value?.toLocaleString()}
            {isPopupBookOpen && (
              <Modal
                isOpen={isPopupBookOpen}
                onOpenChange={e => {
                  setIsPopupBookOpen(e)
                }}
              >
                <Modal.Content>
                  <ModalStartJob
                    bookingId={id}
                    onClose={() => {
                      setIsPopupBookOpen(false)
                    }}
                  />
                </Modal.Content>
              </Modal>
            )}
          </StyledBadge>
        )
      }
    }

    return (
      <StyledBadge variant={variant}>
        <span>{value.value?.toLocaleString()}</span>
      </StyledBadge>
    )
  }

  const StyledCellComponent = isHeader ? StyledHeaderCell : StyledCell

  return (
    <StyledCellComponent customStyle={options?.style} className={className}>
      {renderCellContent()}
    </StyledCellComponent>
  )
}

export const RowItem: React.FC<{
  row: IDataTableData
  headers: IDataTableHeader[]
  columnOptions: IDataTableDataColumnOptions
  actions?: {
    key: string
    label: React.ReactNode
    icon?: React.ReactNode
  }[]
  onActionClick?: (key: string, id: string | number) => void | Promise<void>
  onItemClick?: (id: string | number) => void | Promise<void>
  idColumn?: string
  rowIndex: number
}> = ({
  row,
  headers,
  columnOptions,
  actions,
  onActionClick,
  idColumn,
  rowIndex,
  onItemClick,
}) => {
  const columnWidth = `${100 / headers.length}%`

  return (
    <StyledRow onClick={async e => {
      onItemClick && await onItemClick?.(row[idColumn || 'id'] as string)
    }}>
      {actions && (
        <StyledActionCell width={columnWidth}>
          <Row gap='4px'>
            <DropdownButton>
              <DropdownButton.Trigger>
                <DotsButton />
              </DropdownButton.Trigger>
              <DropdownButton.Content>
                {actions.map(action => (
                  <StyledDropdownItem
                    key={action.key}
                    onClick={() => {
                      if (idColumn) {
                        onActionClick?.(action.key, row[idColumn] as string)
                      } else {
                        onActionClick?.(action.key, rowIndex)
                      }
                    }}
                  >
                    {action?.icon}
                    {action.label}
                  </StyledDropdownItem>
                ))}
              </DropdownButton.Content>
            </DropdownButton>
          </Row>
        </StyledActionCell>
      )}
      {headers.map(header => (
        <DataTableCell
          id={(row[idColumn || 'id'] as number) || -999999}
          key={`${rowIndex}-${header.key}`}
          value={row[header.key]}
          columnKey={header.key}
          options={columnOptions?.[header.key]}
        />
      ))}
    </StyledRow>
  )
}

export const DataTable: React.FC<DataTableProps> = ({
  headers,
  data,
  columnOptions = {},
  hasMore,
  fetchData,
  actions,
  onActionClick,
  onItemClick,
  idColumn,
}) => {
  const columnWidth = `${100 / headers.length}%`

  return (
    <StyledDataTableContainer>
      <StyledDataTable>
        <StyledHeader>
          {actions && (
            <StyledActionHeaderCell
              width={columnWidth}
            ></StyledActionHeaderCell>
          )}
          {headers.map(header => (
            <DataTableCell
              id={-999999}
              key={header.key}
              value={header.label}
              columnKey={header.key}
              options={columnOptions?.[header.key]}
              isHeader
            />
          ))}
        </StyledHeader>

        <Scrollbar direction='xy' id='scrollable'>
          <InfiniteScroll
            style={{ overflow: '' }}
            dataLength={data.length}
            hasMore={hasMore || false}
            next={fetchData as any}
            loader={
              <Row
                justify='center'
                style={{
                  fontSize: '1.5rem',
                }}
              >
                Loading ...
              </Row>
            }
            scrollableTarget='scrollable'
          >
            {data.map((row, rowIndex) => (
              <RowItem
                key={rowIndex}
                row={row}
                headers={headers}
                columnOptions={columnOptions}
                actions={actions}
                onActionClick={onActionClick}
                idColumn={idColumn}
                rowIndex={rowIndex}
                onItemClick={onItemClick}
              />
            ))}
          </InfiniteScroll>
        </Scrollbar>
      </StyledDataTable>
    </StyledDataTableContainer>
  )
}

export default DataTable
