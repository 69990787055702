import React from 'react'

const BahtIcon2 = ({ size, color }) => (
  <svg
    width={size || '34'}
    height={size || '20'}
    viewBox='0 0 34 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_860_74)'>
      <path
        d='M30.5263 2.63158V17.3684H2.63158V2.63158H30.5263ZM31.4684 0H1.68947C0.757895 0 0 0.757895 0 1.68947V18.3053C0 19.2368 0.757895 19.9947 1.68947 19.9947H31.4684C32.4 19.9947 33.1579 19.2368 33.1579 18.3053V1.68947C33.1579 0.757895 32.4 0 31.4684 0Z'
        fill={color || '#475467'}
      />
      <path
        d='M3.15789 6.31579C4.90195 6.31579 6.31579 4.90195 6.31579 3.15789C6.31579 1.41384 4.90195 0 3.15789 0C1.41384 0 0 1.41384 0 3.15789C0 4.90195 1.41384 6.31579 3.15789 6.31579Z'
        fill={color || '#475467'}
      />
      <path
        d='M3.15789 20.0033C4.90195 20.0033 6.31579 18.5895 6.31579 16.8454C6.31579 15.1013 4.90195 13.6875 3.15789 13.6875C1.41384 13.6875 0 15.1013 0 16.8454C0 18.5895 1.41384 20.0033 3.15789 20.0033Z'
        fill={color || '#475467'}
      />
      <path
        d='M30.0016 6.31579C31.7457 6.31579 33.1595 4.90195 33.1595 3.15789C33.1595 1.41384 31.7457 0 30.0016 0C28.2576 0 26.8438 1.41384 26.8438 3.15789C26.8438 4.90195 28.2576 6.31579 30.0016 6.31579Z'
        fill={color || '#475467'}
      />
      <path
        d='M16.315 14.7393C18.9311 14.7393 21.0518 12.6186 21.0518 10.0025C21.0518 7.38638 18.9311 5.26562 16.315 5.26562C13.6989 5.26562 11.5781 7.38638 11.5781 10.0025C11.5781 12.6186 13.6989 14.7393 16.315 14.7393Z'
        fill={color || '#475467'}
      />
      <path
        d='M30.0016 20.0033C31.7457 20.0033 33.1595 18.5895 33.1595 16.8454C33.1595 15.1013 31.7457 13.6875 30.0016 13.6875C28.2576 13.6875 26.8438 15.1013 26.8438 16.8454C26.8438 18.5895 28.2576 20.0033 30.0016 20.0033Z'
        fill={color || '#475467'}
      />
      <path
        d='M7.92878 8.94531H5.76036C5.48713 8.94531 5.26562 9.16681 5.26562 9.44005V10.5558C5.26562 10.8291 5.48713 11.0506 5.76036 11.0506H7.92878C8.20202 11.0506 8.42352 10.8291 8.42352 10.5558V9.44005C8.42352 9.16681 8.20202 8.94531 7.92878 8.94531Z'
        fill={color || '#475467'}
      />
      <path
        d='M26.8741 8.94531H24.7057C24.4324 8.94531 24.2109 9.16681 24.2109 9.44005V10.5558C24.2109 10.8291 24.4324 11.0506 24.7057 11.0506H26.8741C27.1473 11.0506 27.3688 10.8291 27.3688 10.5558V9.44005C27.3688 9.16681 27.1473 8.94531 26.8741 8.94531Z'
        fill={color || '#475467'}
      />
    </g>
    <defs>
      <clipPath id='clip0_860_74'>
        <rect width='33.1579' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default BahtIcon2
