import styled, { CSSProperties } from 'styled-components'

interface LongArrow extends CSSProperties {
  size?: string | number
  transformAfter?: string
}

const LongArrow = styled.div<LongArrow>`
  display: block;
  margin: 1px;
  width: ${props => props.size || '6px'};
  height: ${props => props.size || '6px'};
  border-top: ${props => props.border || '2px solid #000'};
  border-left: ${props => props.border || '2px solid #000'};
  transform: ${props => props.transform || 'rotate(-45deg)'};
  ::after {
    content: '';
    display: block;
    width: 1.5px;
    height: 10px;
    background-color: ${props => props.background || 'black'};
    transform: ${props =>
      props.transformAfter || 'rotate(-45deg) translate(3px, 2px)'};
    left: 0;
    top: 0;
  }
`

export default LongArrow
