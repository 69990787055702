import { useEffect, useState } from 'react'

import { getDefaultCustomer } from '../../redux/models/Customer'
import config from '../../config'
import utils from '../../utils'
import auth from '../../auth'
import api from '../../api'

const getDefaultEditCustomer = () => ({
  ...getDefaultCustomer(),
  gender: { value: -1, label: 'ไม่ระบุ' },
})

const useEditCustomer = (currentCustomer, handleClose) => {
  const [customer, setCustomer] = useState(getDefaultEditCustomer())
  const [formState, setFormState] = useState({
    genderOptionOpen: false,
    ageOptionOpen: false,
    nationalityOptionOpen: false,
    occupationOptionOpen: false,
    countryCodeOpen: false,
    imageFile: null,
    errors: {},
  })

  useEffect(() => {
    if (currentCustomer) {
      setCustomer(currentCustomer)
    }
  }, [currentCustomer])

  const onImageChange = async e => {
    const file = e.target.files[0]
    if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
      alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
    } else if (utils.isFileExceedLimit(file)) {
      alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
    } else {
      const { image } = await utils.readImage(file)
      setCustomer(prev => ({ ...prev, image }))
      setFormState(prev => ({ ...prev, imageFile: file }))
    }
  }

  const onChange = async (value, name) => {
    if (
      name === 'phoneNumber' &&
      value.length > config.MAX_PHONE_NUMBER_LENGTH &&
      !utils.checkNumber(value)
    ) {
      return
    }

    setCustomer(prev => ({
      ...prev,
      [name]: value,
    }))
    setFormState(prev => ({
      ...prev,
      genderOptionOpen: false,
      ageOptionOpen: false,
      nationalityOptionOpen: false,
      occupationOptionOpen: false,
      countryCodeOpen: false,
      ...(value &&
        value.length && { errors: { ...prev.errors, [name]: false } }),
    }))
  }

  const onToggle = (key, value) => {
    setFormState(prev => ({
      ...prev,
      [key]: value !== undefined ? value : !prev[key],
    }))
  }

  const onClear = () => {
    setCustomer(getDefaultEditCustomer())
  }

  const onSave = async () => {
    try {
      const {
        name,
        firstName,
        lastName,
        birthDay,
        gender,
        age,
        nationality,
        occupation,
        email,
        address,
        countryCode,
        phoneNumber,
        taxIDNumber,
        lineId,
        specialRequest,
      } = customer
      const { imageFile } = formState

      if (name === '' && phoneNumber === '') {
        if (name === '') {
          setFormState(prev => ({
            ...prev,
            errors: { ...prev.errors, name: true },
          }))
        }
        if (phoneNumber === '') {
          setFormState(prev => ({
            ...prev,
            errors: { ...prev.errors, phoneNumber: true },
          }))
        }
        return false
      }

      let body = {
        name,
        firstName,
        lastName,
        birthDay,
        gender: gender.value,
        age: age.value,
        nationalityId: nationality.value,
        occupationId: occupation.value,
        email,
        address,
        countryCode,
        phoneNumber,
        taxIDNumber,
        lineId,
        specialRequest,
        storeId: auth.getStoreId(),
        brandId: auth.getBrandId(),
      }

      if (imageFile) {
        const { data } = await api.uploadImageService({
          token: auth.getToken(),
          image: imageFile,
        })
        body = { ...body, image: data }
      }
      let success = false
      if (currentCustomer) {
        const response = await api.updateCustomer(currentCustomer._id, body)
        success = response.success
      } else {
        const response = await api.createCustomer(body)
        success = response.success
      }

      if (success) {
        setFormState(prev => ({ ...prev, success: true }))
        setTimeout(() => {
          handleClose()
          setFormState(prev => ({ ...prev, success: false }))
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return {
    customer: customer,
    ...formState,
    onImageChange,
    onChange,
    onToggle,
    onClear,
    onSave,
  }
}

export default useEditCustomer
