import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { CSSProperties } from 'styled-components'

import useCustomerBookHistory from './useCustomerBookHistory'

import Arrow from '../../atoms/Arrow'
//@ts-ignore
import Button2 from '../../atoms/Button2'
import PackageIcon from '../../atoms/PackageIcon'
//@ts-ignore
import Scrollbar from '../../atoms/Scrollbar'

import auth from '../../auth'
import {
  BookingHistory,
  BookingTypes,
  getPaymentChannelTypeName,
  getTransactionType,
  TransactionTypes,
} from '../../redux/models/BookingHistory'
import { Customer } from '../../redux/models/Customer'
import { HOME_URL } from '../../routes'
import utils from '../../utils'

interface CustomerBookHistoryStyle extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const BoxOrder = styled.div<CustomerBookHistoryStyle>`
  border-radius: 8px;
  background-color: ${props => props.backgroundColor || '#FFF'};
`

const Row = styled.div<CustomerBookHistoryStyle>`
  display: grid;
  grid-template-columns: ${props =>
    props.columns || 'repeat(7, minmax(0, 1fr)) 0.5fr'};
  gap: 0.5rem;
  padding: 0.5rem;
  justify-items: ${props => props.justifyItems || 'unset'};
`

const HeadItem = styled.div`
  text-align: center;
  font-weight: 400;
  color: #98a1b2;
  font-size: 0.875rem;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RowItem = styled.div<CustomerBookHistoryStyle>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  justify-content: center;
  align-items: center;
`
const Text = styled.div<CustomerBookHistoryStyle>`
  font-size: ${props => props.fontSize || '0.875rem'};
  font-weight: ${props => props.fontWeight || '400'};
  white-space: ${props => props.whiteSpace || 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.color || '#000000'};
  text-align: center;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: none;
  }
  width: 100%;
`

const BoxDetail = styled.div`
  background-color: #f8f9fb;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem;
`

interface CustomerBookHistoryProps {
  customerBookingHistories: BookingHistory[]
  customer: Customer
}

const CustomerBookHistory = ({
  customerBookingHistories,
  customer,
}: CustomerBookHistoryProps) => {
  const { t } = useTranslation()

  const { bookOpenDetail, onBookOpenClick, onBookingClick } =
    useCustomerBookHistory({
      customerBookingHistories,
      customer,
    })

  // const store

  return (
    <Container>
      <Row>
        <HeadItem>{t('orderNo.')}</HeadItem>
        <HeadItem>{t('dateTime')}</HeadItem>
        <HeadItem>{t('type')}</HeadItem>
        <HeadItem>{t('details')}</HeadItem>
        <HeadItem>{t('therapist')}</HeadItem>
        <HeadItem>{`${t('bookingChannel')}\n${t('paymentChannel')}`}</HeadItem>
        <HeadItem />
        <HeadItem />
      </Row>
      <Scrollbar id='customer-customerBookHistory' gap='0.5rem'>
        <Box>
          {customerBookingHistories.map(
            (
              {
                customers,
                date,
                bookingCode,
                time,
                bookingChannel,
                bookingType,
                payChannels,
                transactionType,
                packages,
                storeId,
              },
              index
            ) => (
              <BoxOrder key={index}>
                <Row>
                  <RowItem>{utils.formatBookingCode(bookingCode)}</RowItem>
                  <RowItem>{`${utils.formatDate(
                    date,
                    'DD/MM/YY'
                  )} ${utils.formatTime(time.hour)}:${utils.formatTime(
                    time.minute
                  )}`}</RowItem>
                  <RowItem flexDirection='row'>
                    {transactionType === TransactionTypes.Package && (
                      <PackageIcon />
                    )}
                    &nbsp;
                    {`${t(getTransactionType(transactionType))}`}
                  </RowItem>
                  <RowItem>
                    {transactionType === TransactionTypes.Package ? (
                      <Text style={{ maxWidth: '140px' }}>
                        {packages.map(p => p.name).join(', ')}
                      </Text>
                    ) : (
                      <>
                        <Text style={{ maxWidth: '140px' }}>
                          {customers[0].orders.map(o => o.service).join(', ')}
                        </Text>
                        <Text style={{ maxWidth: '140px' }}>
                          {customers[0].orders
                            .map(o => o.time + ` ${t('mins')}`)
                            .join(', ')}
                        </Text>
                      </>
                    )}
                  </RowItem>
                  <RowItem>
                    {/* {customers[0].orders.map(o => o.staffName).join(', ')} */}
                  </RowItem>
                  <RowItem>
                    <Text>{`${
                      !bookingType
                        ? ''
                        : bookingType.bookingTypeId === BookingTypes.WalkIn
                        ? bookingType.bookingType
                        : bookingChannel.bookingChannel
                    }`}</Text>
                    <Text>
                      {payChannels
                        .map(p =>
                          t(getPaymentChannelTypeName(p.payChannelTypeId))
                        )
                        .join(' ,')}
                    </Text>
                  </RowItem>
                  <RowItem>
                    {storeId === auth.getStoreId() &&
                      transactionType !== TransactionTypes.Package && (
                        <LinkStyled
                          to={{
                            pathname: HOME_URL,
                          }}
                          // onClick={() => onBookingClick(customers[0].orders)}
                        >
                          <Button2 backgroundColor='#E8DDF7' color='#8C56D9'>
                            {t('rebookThisOrder')}
                          </Button2>
                        </LinkStyled>
                      )}
                  </RowItem>
                  <RowItem>
                    {(customers.length > 1 ||
                      customers[0].orders.length > 1) && (
                      <div
                        onClick={() =>
                          onBookOpenClick(bookOpenDetail === index ? -1 : index)
                        }
                      >
                        <Arrow
                          transform={
                            bookOpenDetail === index
                              ? 'rotate(-135deg)'
                              : 'rotate(45deg)'
                          }
                        />
                      </div>
                    )}
                  </RowItem>
                </Row>
                {bookOpenDetail === index && (
                  <BoxDetail>
                    {customers.map(({ name, firstName, orders }, index) => (
                      <Row
                        key={index}
                        columns='1fr 1fr 2fr'
                        justifyItems='start'
                      >
                        <RowItem>
                          <Text fontWeight='500' fontSize='1rem'>
                            {name || firstName || ''}
                          </Text>
                        </RowItem>
                        <RowItem>
                          {orders.map(({ service, time, price }, index) => (
                            <div key={index}>
                              <Text
                                color='#475467'
                                fontWeight='500'
                                fontSize='1rem'
                              >
                                {service}
                              </Text>
                              <Text color='#475467'>{`${time}${t(
                                'mins'
                              )} ${price}`}</Text>
                            </div>
                          ))}
                        </RowItem>
                        <RowItem>
                          {/* {orders.map(({ staffName }, index) => (
                            <Text key={index} color='#475467' fontSize='1rem'>
                              {staffName}
                            </Text>
                          ))} */}
                        </RowItem>
                      </Row>
                    ))}
                  </BoxDetail>
                )}
              </BoxOrder>
            )
          )}
        </Box>
      </Scrollbar>
    </Container>
  )
}

export default CustomerBookHistory
