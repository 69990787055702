import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import Label from '../../../atoms/Label'
import LeftIcon from '../../../atoms/LeftIcon'
import RightIcon from '../../../atoms/RightIcon'
import CalendarDateItem2 from './CalendarDateItem2'

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

const Table = styled.table`
  width: 100%;
  border-top: 2px solid #52527c;
`

const Th = styled.th`
  padding-top: 8px;
  text-align: center;
`

const Calendar2 = ({
  date,
  selectedDate: start,
  selectedEndDate: stop,
  ranged,
  highlightColor,
  rangeColor,
  color,
  backwardable = false,
  fontwardable = false,
  openCloseTimes = [],
  onDateChange,
  onMonthChange,
  labelFontSize = '',
}) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [currentMonth, setCurrentMonth] = useState(start || new Date())
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedEndDate, setSelectedEndDate] = useState('')

  const dates = Array.from({ length: 7 }).map((_, idx) =>
    dayjs().set('day', idx).format('dd')
  )

  useEffect(() => {
    if (date && dayjs(date).isValid()) {
      setCurrentDate(dayjs(date))
      setCurrentMonth(dayjs(date))
      setSelectedDate(dayjs(date).toDate())
    }
    if (
      start &&
      dayjs(start).isValid() &&
      !dayjs(start).isSame(selectedDate, 'date')
    ) {
      setSelectedDate(dayjs(start).toDate())
      if (!dayjs(start).isSame(currentMonth)) {
        setCurrentMonth(dayjs(start).toDate())
      }
    }
    if (
      ranged &&
      stop &&
      dayjs(stop).isValid() &&
      !dayjs(stop).isSame(selectedEndDate, 'date')
    ) {
      setSelectedEndDate(dayjs(stop).toDate())
    }
  }, [date, start, stop])

  const onDateClick = (date, event) => {
    event.stopPropagation()
    let start = selectedDate
    let stop = selectedEndDate

    if (ranged) {
      if (!start && !stop) {
        start = date
      } else if (start && !stop) {
        if (dayjs(date).isSame(start, 'day')) {
          start = ''
          stop = ''
        } else if (dayjs(date).isBefore(start, 'day')) {
          stop = start
          start = date
        } else {
          stop = date
        }
      } else if (
        dayjs(start).isSame(stop, 'date') &&
        dayjs(date).isSame(start, 'day')
      ) {
        start = ''
        stop = ''
      } else if (dayjs(date).isSame(start, 'day')) {
        start = stop
        stop = ''
      } else if (dayjs(date).isSame(stop, 'day')) {
        stop = ''
      } else if (dayjs(date).isBefore(start, 'day')) {
        start = date
      } else if (dayjs(date).isAfter(stop, 'day')) {
        stop = date
      } else if (
        dayjs(date).diff(start, 'day') - dayjs(stop).diff(date, 'day') <=
        0
      ) {
        start = date
      } else {
        stop = date
      }
    } else {
      if (!start) {
        start = date
      } else if (dayjs(date).isSame(start, 'day')) {
        start = ''
      } else {
        start = date
      }
    }

    onDateChange(start, stop)
    setSelectedDate(start)
    setSelectedEndDate(stop)
  }

  const changeMonth = type => {
    let _currentMonth = dayjs(currentMonth)
    if (type === 'next') {
      if (fontwardable || !_currentMonth.isSame(dayjs(), 'month')) {
        _currentMonth = _currentMonth.add(1, 'month')
        if (onMonthChange) onMonthChange(_currentMonth)
        setCurrentMonth(_currentMonth)
      }
    } else if (backwardable || !_currentMonth.isSame(dayjs(), 'month')) {
      _currentMonth = _currentMonth.subtract(1, 'month')
      if (onMonthChange) onMonthChange(_currentMonth)
      setCurrentMonth(_currentMonth)
    }
  }

  const generateDate = () => {
    const results = []
    const startDate = dayjs(currentMonth).startOf('month')
    const start = startDate.day() !== 7 ? startDate.day() : 0
    const count = startDate.daysInMonth()
    const lastMonth = dayjs(currentMonth)
      .subtract(1, 'month')
      .endOf('month')
      .toDate()
    const nextMonth = dayjs(currentMonth)
      .add(1, 'month')
      .startOf('month')
      .toDate()
    for (let i = 0; i < 6; i++) {
      results.push([])
      for (let j = 0; j < 7; j++) {
        if (i === 0) {
          results[i].push(
            j >= start
              ? dayjs(startDate)
                  .add(j - start + i * 7, 'day')
                  .toDate()
              : dayjs(lastMonth)
                  .subtract(start - j - 1, 'day')
                  .toDate()
          )
        } else {
          results[i].push(
            j - start + i * 7 <= count
              ? dayjs(startDate)
                  .add(j - start + i * 7, 'day')
                  .toDate()
              : dayjs(nextMonth)
                  .add(j + i * 7 - count - start, 'day')
                  .toDate()
          )
        }
      }
    }
    return results
  }

  return (
    <div>
      <CalendarHeader>
        <LeftIcon
          disabled={!backwardable && dayjs().isSame(currentMonth, 'month')}
          onClick={changeMonth.bind(this, 'prev')}
        />
        <Label weight='bold' size={labelFontSize || '24px'}>
          {dayjs(currentMonth).format('MMMM YYYY')}
        </Label>
        <RightIcon
          disabled={!fontwardable && dayjs().isSame(currentMonth, 'month')}
          onClick={changeMonth.bind(this, 'next')}
        />
      </CalendarHeader>
      <Table cellSpacing='0' cellPadding='0'>
        <thead>
          <tr>
            {dates.map((date, idx) => {
              return (
                <Th key={idx}>
                  <Label size='16px' weight='bold'>
                    {date}
                  </Label>
                </Th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {generateDate().map((row, idx) => {
            return (
              <tr key={idx}>
                {row.map((date, idx2) => (
                  <td key={idx2}>
                    <CalendarDateItem2
                      highlightColor={highlightColor}
                      rangeColor={rangeColor}
                      color={color}
                      onClick={
                        date !== '' ? onDateClick.bind(this, date) : () => {}
                      }
                      date={date}
                      selectedDate={selectedDate}
                      selectedEndDate={selectedEndDate}
                      currentDate={currentDate}
                      currentMonth={currentMonth}
                      openCloseTimes={openCloseTimes}
                    />
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default Calendar2
