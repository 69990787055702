import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'

import { PaymentChannelTypes } from '@/redux/models/BookingHistory'

import CheckBox from '../../atoms/CheckBox'
import InputText from '../../atoms/InputText'
import TextLabel from '../../atoms/TextLabel'

import { getPayPackage } from '../../redux/actions/payPackageActions'
import { Order, PackageOrder, PayChannel } from '../../redux/models/BookOrder'
import { PackageType, PackageTypes } from '../../redux/models/BrandPackage'
import {
  calculatePackageDiscount,
  CustomerPackageUsageGroup,
  filterOrdersUsableByPackage,
  isPackageUsableByServices,
} from '../../redux/models/CustomerPackage'
import ReduxStore from '../../redux/models/ReduxStore'
import utils from '../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
`

const FlexBox = styled.div<CSSProperties>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || ''};
`

const Box = styled.div<{ active: boolean }>`
  background-color: #ffffff;
  border: ${props =>
    props.active ? '1px solid #8C56D9' : '1px solid #E4E8EB'};
  border-radius: 8px;
  padding: 0.25rem;
  display: flex;
  width: 100%;
`

const BoxCount = styled.div`
  border: 1px solid #e4e8eb;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BoxCountItem = styled.div<CSSProperties>`
  text-align: center;
  width: 33%;
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || '500'};
  color: ${props => props.color || '#00000'};
  cursor: pointer;
  :first-child {
    border-right: 1px solid #e4e8eb;
  }
  :last-child {
    border-left: 1px solid #e4e8eb;
  }
`

export interface PackageProps {
  orders: Order[]
  splitPayment?: boolean
  onSelectPackage: (payChannels: PayChannel[], discount: number) => void
  customerPackages: CustomerPackageUsageGroup[]
}

const Package = ({
  orders: baseOrders,
  splitPayment = false,
  onSelectPackage,
  customerPackages,
}: PackageProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [customerPackageUsageGroups, setCustomerPackageUsageGroups] = useState<
    CustomerPackageUsageGroup[]
  >([])
  const [orders, setOrders] = useState<PackageOrder[]>([])
  const [interimValue, setInterimValue] = useState('')

  const { discount = 0 } = useSelector(
    (state: ReduxStore) => state.bookingHistoryReducers
  )

  useEffect(() => {
    const orders = baseOrders.reduce((acc, info) => {
      const { services, customer, name, customerId } = info
      for (const service of services) {
        acc.push({
          customer,
          name,
          customerId,
          ...service,
          remainingDuration: service.time,
          remainingPrice: service.price,
        })
      }
      return acc
    }, [] as PackageOrder[])
    setOrders(orders)
    setCustomerPackageUsageGroups(prev =>
      calculatePackageDiscount(
        prev.length ? prev : customerPackages,
        orders,
        discount
      )
    )
  }, [baseOrders, customerPackages, discount])

  useEffect(() => {
    const payChannels: PayChannel[] = []
    let discount = customerPackageUsageGroups.reduce((acc, info) => {
      const { packages } = info
      for (const currentPackage of packages) {
        const { discount, select, quantity, type, remainingValue } =
          currentPackage
        if (select && quantity !== 0) {
          acc += +discount
          payChannels.push({
            payChannelTypeId: PaymentChannelTypes.Package,
            payChannelId: currentPackage._id,
            payChannel: currentPackage.name,
            note: String(quantity),
            quantity: quantity,
            chargePercentage: 0,
            price: discount,
            remainingValue:
              type === PackageTypes.Percent ? 0 : remainingValue - quantity,
          })
        }
      }
      return acc
    }, 0)
    if (!splitPayment) {
      dispatch(
        getPayPackage({
          packageDiscount: discount,
          payCustomerPackages: payChannels,
        })
      )
    } else {
      onSelectPackage(payChannels, discount)
    }
  }, [customerPackageUsageGroups])

  const onSelectClick = (
    customerIndex: number,
    packageIndex: number,
    type: PackageType
  ) => {
    const newCustomerPackageUsageGroups = [...customerPackageUsageGroups]
    const currentCustomer = newCustomerPackageUsageGroups[customerIndex]
    const currentPackage = currentCustomer.packages[packageIndex]

    if (currentPackage.select) {
      currentPackage.quantity = 0
      currentPackage.discount = 0
      currentPackage.select = false
      setCustomerPackageUsageGroups(
        calculatePackageDiscount(
          newCustomerPackageUsageGroups,
          orders,
          discount
        )
      )
    } else {
      currentPackage.select = currentPackage.allService
        ? true
        : isPackageUsableByServices(currentPackage.services, orders)
      if (currentPackage.type === PackageTypes.Percent) {
        currentPackage.quantity = 1
        setCustomerPackageUsageGroups(
          calculatePackageDiscount(
            newCustomerPackageUsageGroups,
            orders,
            discount
          )
        )
        return
      }
      setCustomerPackageUsageGroups(newCustomerPackageUsageGroups)
    }
  }

  const onBlur = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
    updateFunc: (value: number) => void
  ) => {
    updateFunc && updateFunc(+interimValue)
    setInterimValue('')
  }

  const onQuantityCommit = (
    value: number,
    customerIndex: number,
    packageIndex: number
  ) => {
    const newCustomerPackageUsageGroups = [...customerPackageUsageGroups]
    const currentPackage =
      newCustomerPackageUsageGroups[customerIndex].packages[packageIndex]

    currentPackage.quantity = Math.min(currentPackage.remainingValue, +value)
    setCustomerPackageUsageGroups(
      calculatePackageDiscount(newCustomerPackageUsageGroups, orders, discount)
    )
  }

  const onQuantityChange = (
    value: number | string,
    customerIndex: number,
    packageIndex: number,
    type: PackageType
  ) => {
    const newCustomerPackageUsageGroups = [...customerPackageUsageGroups]
    const currentPackage =
      newCustomerPackageUsageGroups[customerIndex].packages[packageIndex]

    if (type === PackageTypes.Money || type === PackageTypes.Minutes) {
      currentPackage.quantity = value as any
      setInterimValue(value as string)
    }
  }

  const onAddQuantityClick = (
    customerIndex: number,
    packageIndex: number,
    type: string
  ) => {
    const newCustomerPackageUsageGroups = [...customerPackageUsageGroups]

    const currentPackage =
      newCustomerPackageUsageGroups[customerIndex].packages[packageIndex]

    const filteredOrders = currentPackage.allService
      ? orders
      : filterOrdersUsableByPackage(currentPackage, orders)
    if (type === 'add') {
      currentPackage.quantity +=
        currentPackage.quantity < filteredOrders.length ? 1 : 0
    } else {
      currentPackage.quantity -= currentPackage.quantity !== 0 ? 1 : 0
    }

    setCustomerPackageUsageGroups(
      calculatePackageDiscount(newCustomerPackageUsageGroups, orders, discount)
    )
  }

  return (
    <Container>
      <TextLabel fontWeight='500'>{t('selectPackage')}</TextLabel>
      {customerPackageUsageGroups.map(
        ({ name, firstName, packages, customer }, customerIndex) => (
          <FlexBox key={customerIndex} flexDirection='column' gap='0.5rem'>
            <TextLabel color='#8C56D9' fontWeight='500'>
              {`${t('customerNo.')}#${customer}  ${name || firstName}`}
            </TextLabel>
            <GridBox>
              <TextLabel textAlign='center'>{''}</TextLabel>
              <TextLabel textAlign='center' color='#98A1B2'>
                {t('packageName')}
              </TextLabel>
              <TextLabel textAlign='center' color='#98A1B2'>
                {t('remaining')}
              </TextLabel>
              <TextLabel textAlign='center' color='#98A1B2'>
                {t('validUntil')}
              </TextLabel>
              <TextLabel textAlign='center' color='#98A1B2'>
                {t('amount')}
              </TextLabel>
            </GridBox>
            {packages.map(
              (
                {
                  name,
                  type,
                  remainingValue,
                  expiryDay,
                  buyDate,
                  quantity,
                  select,
                },
                packageIndex
              ) => (
                <Box key={packageIndex} active={select}>
                  <GridBox>
                    <CheckBox
                      marginBottom='0'
                      paddingLeft='0'
                      checked={select}
                      onClick={() =>
                        onSelectClick(customerIndex, packageIndex, type)
                      }
                    />
                    <TextLabel textAlign='center'>{name}</TextLabel>
                    <TextLabel textAlign='center'>{`${
                      type === PackageTypes.Percent
                        ? ''
                        : type === PackageTypes.Money
                        ? utils.formatNumber(remainingValue || 0, 2)
                        : utils.formatNumber(remainingValue || 0)
                    } ${
                      type === PackageTypes.Times
                        ? 'ครั้ง'
                        : type === PackageTypes.Minutes
                        ? 'นาที'
                        : ''
                    }`}</TextLabel>
                    <TextLabel textAlign='center'>
                      {dayjs(buyDate).add(expiryDay, 'day').format('DD/MM/YY')}
                    </TextLabel>
                    {(type === PackageTypes.Money ||
                      type === PackageTypes.Minutes) && (
                      <InputText
                        padding='0'
                        textAlign='center'
                        color={select ? '#8C56D9' : '#98A1B2'}
                        placeholder={
                          type === PackageTypes.Money ? '0 บาท' : '0 นาที'
                        }
                        readOnly={!select}
                        value={quantity !== 0 || select ? quantity : ''}
                        onChange={e =>
                          utils.checkNumber(e.target.value)
                            ? onQuantityChange(
                                e.target.value,
                                customerIndex,
                                packageIndex,
                                type
                              )
                            : ''
                        }
                        onFocus={e => setInterimValue(e.target.value)}
                        onBlur={e =>
                          onBlur(e, value =>
                            onQuantityCommit(value, customerIndex, packageIndex)
                          )
                        }
                        onKeyUp={e => {
                          if (e.key === 'Enter') {
                            ;(e.target as HTMLInputElement).blur()
                          }
                        }}
                      />
                    )}
                    {type === PackageTypes.Times && (
                      <BoxCount>
                        <BoxCountItem
                          color={select ? '#8C56D9' : '#98A1B2'}
                          fontWeight={select ? '900' : ''}
                          onClick={e =>
                            select
                              ? onAddQuantityClick(
                                  customerIndex,
                                  packageIndex,
                                  'remove'
                                )
                              : e.preventDefault()
                          }
                        >
                          -
                        </BoxCountItem>
                        <BoxCountItem color={select ? '#8C56D9' : '#98A1B2'}>
                          {quantity}
                        </BoxCountItem>
                        <BoxCountItem
                          color={select ? '#8C56D9' : '#98A1B2'}
                          fontWeight={select ? '900' : ''}
                          onClick={e =>
                            select
                              ? onAddQuantityClick(
                                  customerIndex,
                                  packageIndex,
                                  'add'
                                )
                              : e.preventDefault()
                          }
                        >
                          +
                        </BoxCountItem>
                      </BoxCount>
                    )}
                  </GridBox>
                </Box>
              )
            )}
          </FlexBox>
        )
      )}
    </Container>
  )
}

export default Package
