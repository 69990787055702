import api from '../../api'

export const GET_PAY_CHANNEL = 'GET_PAY_CHANNEL'

export const getPayChannels = data => {
  return {
    type: GET_PAY_CHANNEL,
    payload: { data },
  }
}

export const callApiPayChannels = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getPayChannelByStatus()
    dispatch(getPayChannels(data))
  } catch (err) {
    console.log(err)
  }
}
