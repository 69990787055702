import SearchBox from '@/components/molecules/SearchBox'
import { useAlert } from '@/hook/useAlert'
import React from 'react'

type Props = {}

export default function InputComponentPage({}: Props) {
  const alert = useAlert()
  return (
    <div>
      <SearchBox
        placeholder='Search'
        onChange={value => {
          console.log(value)
          alert('Search')
        }}
        onSelect={(option, idx) => {
          console.log(option, idx)
        }}
        options={[]}
        render={option => <div>{option.value}</div>}
      />
    </div>
  )
}