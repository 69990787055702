import React, { MouseEvent, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'

import Option from '@/redux/models/Option'

import SelectorCheckBoxRoom from './SelectorCheckBoxRoom'

import InputBox from '../InputBox'

//@ts-ignore
import AddDataIcon from '../../../atoms/AddDataIcon'
import BahtIcon from '../../../atoms/BahtIcon'
import Box from '../../../atoms/Box'
import CloseIcon from '../../../atoms/CloseIcon'
import InputText from '../../../atoms/InputText'
import TextLabel from '../../../atoms/TextLabel'
import TimeIcon2 from '../../../atoms/TimeIcon2'

import { TimePrice } from '../../../redux/models/Service'
//@ts-ignore
import utils from '../../../utils'
//@ts-ignore
import PopupConfirmDelete from '../../popups/PopupConfirmDelete'
import SelectorCheckBoxRoomType from '../../selector/SelectorCheckBoxRoomType'

interface TableTimePriceStyleProps extends CSSProperties {
  columns?: string
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
`
const BoxContainer = styled.div<TableTimePriceStyleProps>`
  width: 100%;
  background: #f9f7fd;
  border-radius: 8px;
  padding: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  /* display: grid;
  gap: 1rem;
  grid-template-columns: ${props => props.columns || '1fr 1fr'}; */
`

const BoxStyle = styled.div<TableTimePriceStyleProps>`
  display: grid;
  gap: 1rem;
  grid-template-columns: ${props => props.columns || '1fr 1fr'};
  padding: 0.5rem;
`

const RemoveIconContainer = styled.div<TableTimePriceStyleProps>`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
`

const AddContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  cursor: pointer;
  width: fit-content;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
`

const BoxRoomContainer = styled.div<TableTimePriceStyleProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* display: grid;
  gap: 1rem;
  grid-template-columns: ${props => props.columns || '1fr 1fr'}; */
`

interface RoomTypeOptions {
  value: number
  label: string
  checkAll: boolean
  rooms: Option[]
}

interface TimePricesState extends TimePrice {
  roomTypes: {
    roomTypeOptions: RoomTypeOptions[]
    roomTypeOptionOpen: boolean
    roomOptionOpen: boolean
    duration: number
    detail: string
  }[]
}

interface TableTimePriceState {
  timePrices: TimePricesState[]
  onClick: (
    open: boolean,
    indexTimePrice: number,
    indexRoomType: number,
    name: string
  ) => void
  onAddTimePrice: () => void
  onRemoveTimePrice: (index: number) => void
  onTimePriceChange: (value: string, index: number, type: string) => void
  onAddRoom: (index: number) => void
  onRemoveRoomType: (indexTimePrice: number, indexRoomType: number) => void
  onOptionRoomTypeClick: (
    item: Option,
    indexTimePrices: number,
    indexRoomTypes: number
  ) => void
  onOptionSelectAllClick: (
    item: Option[],
    indexTimePrices: number,
    indexRoomTypes: number,
    indexRoomTypeOptions: number
  ) => void
  onRoomClick: (
    option: Option,
    checkAll: boolean,
    indexTimePrices: number,
    indexRoomTypes: number,
    indexRoomTypeOptions: number
  ) => void
  onDurationChange: (
    value: string,
    indexTimePrices: number,
    indexRoomTypes: number,
    name: string
  ) => void
  onRoomDetailChange: (
    value: string,
    indexTimePrices: number,
    indexRoomTypes: number
  ) => void
}

const TableTimePrice = ({
  timePrices,
  onClick,
  onAddTimePrice,
  onRemoveTimePrice,
  onTimePriceChange,
  onAddRoom,
  onRemoveRoomType,
  onOptionRoomTypeClick,
  onOptionSelectAllClick,
  onRoomClick,
  onDurationChange,
  onRoomDetailChange,
}: TableTimePriceState) => {
  const [edit, setEdit] = useState({
    indexTimePrice: -1,
    indexRoomType: -1,
    name: '',
  })
  const [activeDelete, setActiveDelete] = useState(false)

  const onDeleteClick = (
    e: MouseEvent<HTMLDivElement>,
    indexTimePrice: number,
    indexRoomType: number,
    name: string
  ) => {
    e.stopPropagation()
    setEdit({
      indexTimePrice:
        edit.indexTimePrice === indexTimePrice ? -1 : indexTimePrice,
      indexRoomType: edit.indexRoomType === indexRoomType ? -1 : indexRoomType,
      name,
    })
    setActiveDelete(v => !v)
  }

  const confirmDelete = () => {
    if (edit.name === 'timePrice') onRemoveTimePrice(edit.indexTimePrice)
    else onRemoveRoomType(edit.indexTimePrice, edit.indexRoomType)

    setEdit({
      indexTimePrice: -1,
      indexRoomType: -1,
      name: '',
    })
    setActiveDelete(v => !v)
  }

  return (
    <Box
      header='ช่วงเวลา และราคาของบริการ*'
      //   color={checkPrice ? '#DB2D1E' : ''}
    >
      <Container>
        {timePrices.map(
          (
            { time = '', originalPrice = '', price = '', roomTypes },
            indexTimePrices
          ) => (
            <BoxContainer key={indexTimePrices}>
              <BoxStyle>
                <InputBox
                  header='เวลาบริการ'
                  icon={<TimeIcon2 size='15' />}
                  value={time}
                  suffix='นาที'
                  // error={checkPrice}
                  onChange={e => {
                    const value = e.target.value
                    if (!utils.checkNumber(value)) return
                    onTimePriceChange(value, indexTimePrices, 'time')
                  }}
                />
                <InputBox
                  header='ราคาขาย'
                  icon={<BahtIcon size='17' />}
                  value={price}
                  suffix='บาท'
                  // error={checkPrice}
                  onChange={e => {
                    const value = e.target.value
                    if (!utils.checkNumber(value)) return
                    onTimePriceChange(value, indexTimePrices, 'price')
                  }}
                />

                {indexTimePrices !== 0 && (
                  <RemoveIconContainer
                    onClick={e =>
                      onDeleteClick(e, indexTimePrices, -1, 'timePrice')
                    }
                  >
                    <CloseIcon size='16' backgroundColor='#DC3C2F' withCircle />
                  </RemoveIconContainer>
                )}
              </BoxStyle>
              {roomTypes.map(
                (
                  {
                    roomTypeOptions,
                    roomOptionOpen,
                    roomTypeOptionOpen,
                    duration,
                    detail,
                  },
                  indexRoomTypes
                ) => (
                  <BoxRoomContainer key={indexRoomTypes}>
                    <BoxStyle columns='1fr 1fr 1fr 1fr'>
                      <FlexBox>
                        <TextLabel>ชื่อบริการ</TextLabel>
                        <InputText
                          value={detail}
                          onChange={e =>
                            onRoomDetailChange(
                              e.target.value,
                              indexTimePrices,
                              indexRoomTypes
                            )
                          }
                        />
                      </FlexBox>
                      <FlexBox>
                        <TextLabel>ห้อง</TextLabel>
                        <SelectorCheckBoxRoomType
                          open={roomTypeOptionOpen}
                          onClick={open =>
                            onClick(
                              open,
                              indexTimePrices,
                              indexRoomTypes,
                              'roomType'
                            )
                          }
                          option={roomTypeOptions}
                          onOptionClick={item =>
                            onOptionRoomTypeClick(
                              item,
                              indexTimePrices,
                              indexRoomTypes
                            )
                          }
                        />
                      </FlexBox>
                      <FlexBox>
                        <TextLabel>เตียง</TextLabel>
                        <SelectorCheckBoxRoom
                          roomTypes={roomTypeOptions}
                          open={roomOptionOpen}
                          onClick={open =>
                            onClick(
                              open,
                              indexTimePrices,
                              indexRoomTypes,
                              'room'
                            )
                          }
                          onOptionSelectAllClick={(
                            item,
                            indexRoomTypeOptions
                          ) =>
                            onOptionSelectAllClick(
                              item,
                              indexTimePrices,
                              indexRoomTypes,
                              indexRoomTypeOptions
                            )
                          }
                          onRoomClick={(
                            option,
                            checkAll,
                            indexRoomTypeOptions
                          ) =>
                            onRoomClick(
                              option,
                              checkAll,
                              indexTimePrices,
                              indexRoomTypes,
                              indexRoomTypeOptions
                            )
                          }
                        />
                      </FlexBox>

                      <InputBox
                        header='เวลาใช้งาน'
                        icon={<BahtIcon size='17' />}
                        value={duration}
                        suffix='บาท'
                        // error={checkPrice}
                        onChange={e => {
                          const value = e.target.value
                          if (!utils.checkNumber(value)) return
                          onDurationChange(
                            value,
                            indexTimePrices,
                            indexRoomTypes,
                            'duration'
                          )
                        }}
                        // onChange={e => {
                        //   const value = e.target.value
                        //   if (!utils.checkNumber(value)) return
                        //   onTimePriceChange({ price: value }, index)
                        // }}
                      />
                    </BoxStyle>
                    {indexRoomTypes !== 0 && (
                      <RemoveIconContainer
                        onClick={e =>
                          onDeleteClick(
                            e,
                            indexTimePrices,
                            indexRoomTypes,
                            'roomType'
                          )
                        }
                      >
                        <CloseIcon
                          size='16'
                          backgroundColor='#DC3C2F'
                          withCircle
                        />
                      </RemoveIconContainer>
                    )}
                  </BoxRoomContainer>
                )
              )}
              <AddContainer onClick={() => onAddRoom(indexTimePrices)}>
                {/* <AddDataIcon size='16' color='#8C56D9' /> */}
                <TextLabel fontSize='0.875rem' fontWeight={500} color='#8C56D9'>
                  เพิ่มห้อง
                </TextLabel>
              </AddContainer>
            </BoxContainer>
          )
        )}
        <AddContainer onClick={onAddTimePrice}>
          <AddDataIcon size='16' color='#8C56D9' />
          <TextLabel fontSize='0.875rem' fontWeight={500} color='#8C56D9'>
            เพิ่มช่วงเวลา
          </TextLabel>
        </AddContainer>
        {activeDelete && (
          <PopupConfirmDelete
            text={
              edit.name === 'timePrice'
                ? `ยืนยันการลบ ช่วงเวลา และราคาของบริการ`
                : `ยืนยันการลบ ห้อง`
            }
            onConfirmDeleteClick={confirmDelete}
            handleClose={onDeleteClick}
          />
        )}
      </Container>
    </Box>
  )
}

export default TableTimePrice
