import React from 'react'

const PinIcon = ({ size, color }) => (
  <svg
    width={size || '82'}
    height={size || '32'}
    viewBox='0 0 82 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M80.2302 0H1.76978C0.792568 0 0 0.792568 0 1.76978V29.5227C0 30.4999 0.792568 31.2924 1.76978 31.2924H80.2302C81.2074 31.2924 82 30.4999 82 29.5227V1.76978C82 0.792273 81.2074 0 80.2302 0ZM78.4604 27.7526H3.53957V3.53957H78.4604V27.7526Z'
      fill={color || '#464B54'}
    />
    <path
      d='M15.1024 20.0122L18.56 16.7366L17.5892 21.4071H20.8645L19.8336 16.7974L23.3514 20.0122L25.0495 17.0401L20.6825 15.6452L25.0495 14.2498L23.4729 11.3992L19.8941 14.5229L20.8645 9.88281H17.5892L18.6204 14.4928L14.9815 11.3992L13.4043 14.2498L17.7712 15.5845L13.4043 17.0401L15.1024 20.0122Z'
      fill={color || '#464B54'}
    />
    <path
      d='M31.538 20.0122L34.9952 16.7366L34.0248 21.4071H37.2998L36.2692 16.7974L39.7866 20.0122L41.485 17.0401L37.1181 15.6452L41.485 14.2498L39.9081 11.3992L36.3293 14.5229L37.2998 9.88281H34.0248L35.056 14.4928L31.4167 11.3992L29.8398 14.2498L34.2068 15.5845L29.8398 17.0401L31.538 20.0122Z'
      fill={color || '#464B54'}
    />
    <path
      d='M48.2687 20.0122L51.726 16.7366L50.7556 21.4071H54.0305L52.9996 16.7974L56.5174 20.0122L58.2158 17.0401L53.8488 15.6452L58.2158 14.2498L56.6389 11.3992L53.0601 14.5229L54.0305 9.88281H50.7556L51.7868 14.4928L48.1475 11.3992L46.5703 14.2498L50.9373 15.5845L46.5703 17.0401L48.2687 20.0122Z'
      fill={color || '#464B54'}
    />
    <path
      d='M66.5441 20.8273H68.6089V18.5115H69.774V16.904H68.6089V10.3555H66.2636L62.4141 16.727V18.5115H66.5439V20.8273H66.5441ZM63.9337 16.904L66.5589 12.4055H66.5884C66.5884 12.7299 66.5441 14.367 66.5441 16.904H63.9337Z'
      fill={color || '#464B54'}
    />
  </svg>
)

export default PinIcon
