import { Dispatch } from 'redux'

import auth from '../../auth'
import {
  createStaffQueue,
  getStaffLastCreateQueue,
  getStaffQueueByDate,
} from '../../lib/schedule/scheduleApi'
import { getStaff } from '../../lib/staff/staffApi'

export const thunk_action_check_staff_queue =
  () => async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      let staffQueue = await getStaffQueueByDate(
        new Date().toString(),
        new Date().toString()
      )
      if (staffQueue.data.length === 0) {
        try {
          const { data } = await getStaffLastCreateQueue()
          if (data.length) {
            const create = {
              date: new Date(),
              queue: data[0].queue,
              storeId: auth.getStoreId(),
            }
            await createStaffQueue(create)
          } else {
            const { data } = await getStaff()
            if (data.length) {
              const queue = data.reduce((acc: any, info: any) => {
                const { _id } = info
                acc.push({ staffId: _id })
                return acc
              }, [])
              let create = {
                date: new Date(),
                queue: queue,
                storeId: auth.getStoreId(),
              }
              await createStaffQueue(create)
            }
          }
        } catch (err) {
          console.log(err)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
