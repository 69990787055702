import React from 'react';
import styled from 'styled-components';

interface SwitchProps {
  value: boolean;
  onValueChange: (checked: boolean) => void;
  disabled?: boolean;
}

const SwitchWrapper = styled.label<{ disabled: boolean, checked: boolean }>`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  background-color: ${({ checked }) => (checked ? 'var(--primary)' : 'var(--background)')};
  border-radius: 50px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.2s ease;
`;

const SwitchInput = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider = styled.span<{ checked: boolean; disabled: boolean }>`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: ${({ checked }) => (checked ? 'var(--background)' : 'var(--primary)')};
  border-radius: 50%;
  transition: transform 0.2s ease;
  transform: ${({ checked }) => (checked ? 'translateX(26px)' : 'translateX(0)')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const Switch = ({ value, onValueChange, disabled = false }: SwitchProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
        onValueChange(event.target.checked);
    }
  };

  return (
    <SwitchWrapper disabled={disabled} checked={value}>
      <SwitchInput checked={value} onChange={handleChange} />
      <SwitchSlider checked={value} disabled={disabled} />
    </SwitchWrapper>
  );
};

export default Switch;
