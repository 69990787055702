import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  cursor: pointer;
`

interface DeleteIcon3Props {
  size?: string
  color?: string
  onClick?: () => void
}

const DeleteIcon3 = ({ size, color, onClick }: DeleteIcon3Props) => (
  <SVG
    width={size || '32'}
    height={size || '36'}
    onClick={onClick}
    viewBox='0 0 32 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_1243_205)'>
      <path
        d='M26.37 9.12819V29.9169C26.37 31.5952 25.0061 32.9592 23.3278 32.9592H8.62357C6.94526 32.9592 5.58132 31.5952 5.58132 29.9169V9.12819H26.37ZM29.4123 6.08594H2.53906V29.9169C2.53906 33.2786 5.26188 36.0014 8.62357 36.0014H23.3278C26.6895 36.0014 29.4123 33.2786 29.4123 29.9169V6.08594Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M30.4225 9.12819H1.52113C0.679437 9.12819 0 8.44875 0 7.60706C0 6.76537 0.679437 6.08594 1.52113 6.08594H30.4225C31.2642 6.08594 31.9437 6.76537 31.9437 7.60706C31.9437 8.44875 31.2642 9.12819 30.4225 9.12819Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M11.6618 28.3925C10.8201 28.3925 10.1406 27.7131 10.1406 26.8714V15.7164C10.1406 14.8747 10.8201 14.1953 11.6618 14.1953C12.5034 14.1953 13.1829 14.8747 13.1829 15.7164V26.8714C13.1829 27.7131 12.5034 28.3925 11.6618 28.3925Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M21.8011 3.04225H10.6461C9.80444 3.04225 9.125 2.36282 9.125 1.52113C9.125 0.679437 9.80444 0 10.6461 0H21.8011C22.6427 0 23.3222 0.679437 23.3222 1.52113C23.3222 2.36282 22.6427 3.04225 21.8011 3.04225Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M20.7868 28.3925C19.9451 28.3925 19.2656 27.7131 19.2656 26.8714V15.7164C19.2656 14.8747 19.9451 14.1953 20.7868 14.1953C21.6284 14.1953 22.3079 14.8747 22.3079 15.7164V26.8714C22.3079 27.7131 21.6284 28.3925 20.7868 28.3925Z'
        fill={color || '#DB2D1E'}
      />
    </g>
    <defs>
      <clipPath id='clip0_1243_205'>
        <rect width='31.9437' height='36' fill='white' />
      </clipPath>
    </defs>
  </SVG>
)

export default DeleteIcon3
