import React from 'react'
import styled, { CSSProperties } from 'styled-components'

import { RoomsState } from './useSchedule'

import TextLabel from '../../../atoms/TextLabel'

interface TableRoomStyle extends CSSProperties {
  isOdd?: boolean
}

const TimeRow = styled.div`
  display: flex;
`

const Column = styled.div<TableRoomStyle>`
  width: ${props => props.width || '120px'};
  text-align: center;
  border-right: ${props => props.borderRight || '1px solid #e5eced'};
  border-bottom: 1px solid #e5eced;
  background: ${props => props.background || '#FFF'};
  background-image: ${props => props.backgroundImage || ''};
  flex: 0 0 auto;
  z-index: 2;
  height: ${props => props.height || '70px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:first-child {
    position: sticky;
    left: 0;
    z-index: 4;
    background: white;
  }
`

const TextRoomType = styled.div<TableRoomStyle>`
  min-width: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  transform-origin: 50% 50%;
  white-space: nowrap;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: #8c56d9;

  /* Ensure :nth-child works correctly */
  background-color: ${props => (props.isOdd ? '#e1d9f2' : '#f1ecfa')};
`

interface TableRoomProps {
  roomTypes: RoomsState[]
  times: string[]
}

const TableRoom = ({ roomTypes, times }: TableRoomProps) => {
  return (
    <div>
      {roomTypes.map(({ rooms, roomTypeName }, index) => (
        <div key={index} style={{ display: 'flex' }}>
          <TextRoomType isOdd={index % 2 === 0}>{roomTypeName}</TextRoomType>
          <div>
            {rooms.map(({ roomName }, roomIndex) => (
              <div key={roomIndex} style={{ display: 'flex' }}>
                <TimeRow>
                  <Column width='90px'>
                    <TextLabel>{roomName}</TextLabel>
                  </Column>
                  {times.map((time, timeIndex) => (
                    <Column
                      style={{ cursor: 'pointer' }}
                      key={time}
                      // onClick={() =>
                      //   onTimeClick(timeIndex, time, _id, name, workingStatus)
                      // }
                    ></Column>
                  ))}
                </TimeRow>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default TableRoom
