import React from 'react'
import styled from 'styled-components'

import CameraIcon from '../../atoms/CameraIcon'
import InputText from '../../atoms/InputText'
import TextLabel from '../../atoms/TextLabel'
import UploadImageIcon from '../../atoms/UploadImageIcon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ImageBox = styled.div`
  display: flex;
  width: 45%;
  background: #f8f9fb;
  border: 2px dashed #e4e8eb;
  border-radius: 16px;
  padding: ${props => props.padding || '3rem 1rem'};
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
`
const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`
const Image = styled.img`
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* aspect-ratio: 1; */
  position: relative;
  object-fit: cover;
`

const CardAddCredit = ({
  onNameChange,
  payChannel,
  onChangeImage,
  checkName,
}) => {
  // const onSaveClick = async () => {
  //   if (!name) {
  //     setCheckName(true)
  //     return false
  //   } else {
  //     setCheckName(false)
  //   }
  //   try {
  //     let create = {
  //       payChannelTypeId: 2,
  //       payChannelId: -1,
  //       payChannel: name,
  //       storeId: auth.getStoreId(),
  //     }
  //     if (fileImage) {
  //       const { data, success } = await api.uploadImagePayChannel({
  //         token: auth.getToken(),
  //         image: fileImage,
  //       })
  //       if (success) {
  //         create = { ...create, image: data }
  //       }
  //     }
  //     onSave(create)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  // const onChangeImage = async event => {
  //   const file = event.target.files[0]
  //   setFileImage(file)
  //   if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
  //     alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
  //   } else if (utils.isFileExceedLimit(file)) {
  //     alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
  //   } else {
  //     const image = await utils.readImage(file)
  //     setImage(image.image)
  //   }
  // }

  return (
    <Container>
      <div className='mb-4' style={{ width: '100%' }}>
        <TextLabel fontWeight='500'>เพิ่มบัตรเครดิต</TextLabel>
      </div>
      <div style={{ width: '100%' }}>
        <ImageBox padding={payChannel.image ? '0rem' : ''}>
          <CameraIcon size='40' />
          <ImageUploadIcon>
            <label for='upload-image'>
              <UploadImageIcon size='30' colorCircle='#8C56D9' />
            </label>
            <input
              id='upload-image'
              type='file'
              onChange={onChangeImage}
              style={{ display: 'none', visibility: 'none' }}
            />
          </ImageUploadIcon>
          {payChannel.image && <Image src={payChannel.image} />}
        </ImageBox>
      </div>
      <div className='mt-3'></div>
      <TextLabel fontWeight='600'>ชื่อบัตรเครดิต*</TextLabel>
      <div className='mt-2'></div>
      <InputText
        padding='0.5rem 1rem'
        boxShadow='0px 4px 4px rgba(71, 84, 103, 0.05)'
        border={checkName ? '2px solid #DB2D1E' : ''}
        value={payChannel.payChannel}
        onChange={val => onNameChange(val.target.value)}
      />
    </Container>
  )
}

export default CardAddCredit
