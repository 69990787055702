import React from "react"
import styled from "styled-components"

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  &:hover {
    color: ${props => (props.color = "#fff")};
    user-select: none;
  }
`

const BackIcon = ({ size, color }) => (
  <SVG
    width={size || "35"}
    height={size || "31"}
    viewBox='0 0 35 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.7218 0C12.3166 0 6.13013 5.29683 4.73977 12.2978L1.83572 8.66742L0 10.1365L4.70101 16.0127C4.93015 16.2983 5.27099 16.4534 5.61887 16.4534C5.79633 16.4534 5.97611 16.4134 6.14421 16.33L13.1957 12.8043L12.1438 10.7017L6.9622 13.292C7.92354 7.10548 13.2697 2.35047 19.7218 2.35047C26.8497 2.35047 32.6495 8.15028 32.6495 15.2782C32.6495 22.4061 26.8497 28.2059 19.7218 28.2059V30.5564C28.1459 30.5564 35 23.7023 35 15.2782C35 6.85405 28.1459 0 19.7218 0Z'
      fill={color || "#4E4E72"}
    />
  </SVG>
)

export default BackIcon
