import React, { useEffect } from 'react'
import styled, { CSSProperties } from 'styled-components'

import useCardAddRoom from './useCardAddRoom'

//@ts-ignore
import Button2 from '../../../atoms/Button2'
//@ts-ignore
import ButtonSave from '../../../atoms/ButtonSave'
import InputText from '../../../atoms/InputText'
import SaveIcon from '../../../atoms/SaveIcon'
//@ts-ignore
import Scrollbar from '../../../atoms/Scrollbar'
import SuccessIcon2 from '../../../atoms/SuccessIcon2'
import SwitchButton from '../../../atoms/SwitchButton'
import TextLabel from '../../../atoms/TextLabel'

import Room from '../../../redux/models/Room'
import RoomType from '../../../redux/models/RoomType'
import SelectorRoomType from '../../selector/SelectorRoomType'

interface CardAddRoomStyle extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  overflow: hidden;
  display: flex;
`
const Card = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Box = styled.div<CardAddRoomStyle>`
  display: flex;
  width: 100%;
  align-items: ${props => props.alignItems || 'unset'};
  margin-bottom: ${props => props.marginBottom || '0'};
`
const BoxStyle = styled.div<CardAddRoomStyle>`
  flex: ${props => props.flex || '1 0 50%'};
  display: flex;
  width: ${props => props.width || '50%'};
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const Line = styled.div`
  width: 100%;
  margin: 1rem 0;
  border-top: 1px solid #e4e8eb;
`

interface CardAddRoomProps {
  handleClose: () => void
  currentIdEdit: number
  currentDataEdit: Room
  roomTypes: RoomType[]
}

const CardAddRoom = ({
  handleClose,
  currentIdEdit,
  currentDataEdit,
  roomTypes,
}: CardAddRoomProps) => {
  const {
    room,
    checkName,
    checkCode,
    success,
    onChange,
    onToggle,
    onRoomTypeOptionChange,
    onSaveClick,
  } = useCardAddRoom({
    currentIdEdit,
    currentDataEdit,
    roomTypes,
  })

  useEffect(() => {
    if (success) {
      if (success) {
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    }
  }, [success])

  return (
    <Container>
      <Card>
        <Scrollbar id='setting-room'>
          <Box>
            <BoxStyle>
              <div className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>ห้องนวด</TextLabel>
              </div>
              <SelectorRoomType
                name='roomTypeOption'
                open={room.roomTypeOpen}
                onClick={onToggle}
                option={room.roomTypeOption}
                onOptionClick={option => onRoomTypeOptionChange(option)}
                error={false}
                readOnly={false}
              />
            </BoxStyle>
          </Box>
          <Box>
            <BoxStyle className='pr-2' flexDirection='column'>
              <div className='mt-3 mb-2'>
                <TextLabel fontWeight='600' color={checkName ? '#DB2D1E' : ''}>
                  ชื่อเตียง*
                </TextLabel>
              </div>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                border={checkName ? '2px solid #DB2D1E' : ''}
                value={room.roomName}
                onChange={val => onChange(val.target.value, 'roomName')}
              />
            </BoxStyle>
            <BoxStyle className='pl-2' flexDirection='column'>
              <div className='mt-3 mb-2'>
                <TextLabel fontWeight='600' color={checkCode ? '#DB2D1E' : ''}>
                  รหัสย่อเตียง*
                </TextLabel>
              </div>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                border={checkCode ? '2px solid #DB2D1E' : ''}
                value={room.roomCode}
                onChange={val => onChange(val.target.value, 'roomCode')}
              />
            </BoxStyle>
          </Box>
          <Line />
          <Box marginBottom='0.5rem'>
            <BoxStyle flexDirection='column'>
              <TextLabel fontWeight='600'>สถานะ</TextLabel>
              <TextLabel color='#98A1B2' fontSize='0.8rem'>
                ไม่ใช้งาน/ใช้งาน
              </TextLabel>
            </BoxStyle>
            <BoxStyle alignItems='center' justifyContent='flex-end'>
              <SwitchButton
                name='stats-room-type'
                toggled={room.status === 1 ? true : false}
                onChange={() => onChange(room.status === 1 ? -1 : 1, 'status')}
                backgroundColor={room.status === 1 ? '#8C56D9' : '#ABABAD'}
                width='18px'
                labelHeight='20px'
                labelWidth='40px'
              />
            </BoxStyle>
          </Box>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              zIndex: '2',
              background: '#FFFFFF',
            }}
          >
            <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
              <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                <Button2
                  color='#98A1B2'
                  border='2px solid #98A1B2'
                  backgroundColor='white'
                  backgroundColorHover='#E5E7EC'
                  fontWeight='600'
                  onClick={handleClose}
                >
                  ยกเลิก
                </Button2>
              </div>
              <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                <ButtonSave
                  border='2px solid #53389E'
                  color='#FFFFFF'
                  backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                  backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                  fontWeight='600'
                  icon={<SaveIcon size='15' />}
                  onClick={() => onSaveClick()}
                >
                  บันทึก
                </ButtonSave>
              </div>
            </div>
          </div>
        </Scrollbar>
      </Card>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddRoom
