// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxTime {
  height: 120px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  /* font-family: 'Kanit', sans-serif; */
  font-size: 1.5rem;
  color: #8c56d9;
  /* left: 25%; */
  margin: 0;
  padding: 0;
}

.swiper-slide-timer {
  text-align: center;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.25;
  transition: opacity 0.3s ease;
  cursor: default;
  font-weight: bold;
  -webkit-tap-highlight-color: transparent;
  color: #a3a9b3;
}

.swiper-slide-timer-active {
  opacity: 1;
  font-size: 1.7rem;
  align-items: center;
  cursor: pointer;
}

.vizor {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/timePicker/Time.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;EAEX,aAAa;EACb,uBAAuB;EACvB,iBAAiB;EACjB,sCAAsC;EACtC,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,6BAA6B;EAC7B,eAAe;EACf,iBAAiB;EACjB,wCAAwC;EACxC,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".boxTime {\n  height: 120px;\n  position: relative;\n  width: 100%;\n  display: -webkit-flex;\n  display: flex;\n  justify-content: center;\n  flex-wrap: nowrap;\n  /* font-family: 'Kanit', sans-serif; */\n  font-size: 1.5rem;\n  color: #8c56d9;\n  /* left: 25%; */\n  margin: 0;\n  padding: 0;\n}\n\n.swiper-slide-timer {\n  text-align: center;\n  font-size: 1.5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0.25;\n  transition: opacity 0.3s ease;\n  cursor: default;\n  font-weight: bold;\n  -webkit-tap-highlight-color: transparent;\n  color: #a3a9b3;\n}\n\n.swiper-slide-timer-active {\n  opacity: 1;\n  font-size: 1.7rem;\n  align-items: center;\n  cursor: pointer;\n}\n\n.vizor {\n  border-top: 1px solid #ccc;\n  border-bottom: 1px solid #ccc;\n  height: 50px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  font-size: 1.5rem;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
