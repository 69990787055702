import { PayPackage } from '../models/Payment'

export const GET_PAY_PACKAGE = 'GET_PAY_PACKAGE'

export const getPayPackage = (data: Partial<PayPackage>) => {
  return {
    type: GET_PAY_PACKAGE,
    payload: { data },
  }
}

export const CLEAR_PAY_PACKAGE = 'CLEAR_PAY_PACKAGE'

export const clearPayPackage = () => {
  return {
    type: CLEAR_PAY_PACKAGE,
  }
}
