export const GET_DATA = 'GET_DATA'

export const getData = data => {
  return {
    type: GET_DATA,
    payload: { data },
  }
}

export const CLEAR_DATA = 'GET_DATA'

export const clearData = data => {
  return {
    type: CLEAR_DATA,
    payload: { data },
  }
}
