import React from 'react'

const PasswordLockIcon = ({ size, color }) => (
  <svg
    width={size || '56'}
    height={size || '56'}
    viewBox='0 0 56 56'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M28.001 1.64722C19.7657 1.64722 13.1775 8.23545 13.1775 16.4707V23.059C10.3775 23.059 8.23633 25.2002 8.23633 28.0002V49.4119C8.23633 52.2119 10.3775 54.3531 13.1775 54.3531H42.8246C45.6246 54.3531 47.7657 52.2119 47.7657 49.4119V28.0002C47.7657 25.2002 45.6246 23.059 42.8246 23.059V16.4707C42.8246 8.23545 36.2363 1.64722 28.001 1.64722ZM44.4716 28.0002V49.4119C44.4716 50.4002 43.8128 51.059 42.8246 51.059H13.1775C12.1893 51.059 11.5304 50.4002 11.5304 49.4119V28.0002C11.5304 27.0119 12.1893 26.3531 13.1775 26.3531H14.8246H41.1775H42.8246C43.8128 26.3531 44.4716 27.0119 44.4716 28.0002ZM16.4716 23.059V16.4707C16.4716 10.0472 21.5775 4.94133 28.001 4.94133C34.4246 4.94133 39.5304 10.0472 39.5304 16.4707V23.059H16.4716Z'
      fill={color || '#4E4E72'}
    />
    <path
      d='M28.0017 31.2944C25.2017 31.2944 23.0605 33.4356 23.0605 36.2356C23.0605 38.3768 24.3782 40.1886 26.3547 40.8474V44.4709C26.3547 45.4591 27.0135 46.118 28.0017 46.118C28.99 46.118 29.6488 45.4591 29.6488 44.4709V40.8474C31.6253 40.1886 32.9429 38.3768 32.9429 36.2356C32.9429 33.4356 30.8017 31.2944 28.0017 31.2944ZM28.0017 37.8827C27.0135 37.8827 26.3547 37.2238 26.3547 36.2356C26.3547 35.2474 27.0135 34.5886 28.0017 34.5886C28.99 34.5886 29.6488 35.2474 29.6488 36.2356C29.6488 37.2238 28.99 37.8827 28.0017 37.8827Z'
      fill={color || '#4E4E72'}
    />
  </svg>
)

export default PasswordLockIcon
