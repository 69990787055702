import React from 'react'

const DateIcon6 = ({ size, color }) => (
  <svg
    width={size || '21'}
    height={size || '20'}
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_7065_5031)'>
      <path
        d='M10.6294 16.0842H2.11646C2.09387 16.0842 2.07693 16.0673 2.07693 16.0447V4.2649C2.07693 4.24231 2.09387 4.22537 2.11646 4.22537H16.4374C16.46 4.22537 16.4769 4.24231 16.4769 4.2649V9.62678C17.0783 9.69454 17.6459 9.86396 18.171 10.1124V4.2649C18.171 3.30772 17.3946 2.53125 16.4374 2.53125H2.11646C1.15928 2.53125 0.382812 3.30772 0.382812 4.2649V16.0447C0.382812 17.0018 1.15928 17.7783 2.11646 17.7783H11.3183C10.9993 17.2644 10.7621 16.6941 10.6294 16.0842Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M6.31131 0.273438H4.61719V3.98638H6.31131V0.273438Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M13.9363 0.273438H12.2422V3.98638H13.9363V0.273438Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M6.31241 11.5664H4.33594V13.5429H6.31241V11.5664Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M10.2655 11.5664H8.28906V13.5429H10.2655V11.5664Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M6.27335 7.05078H4.29688V9.02725H6.27335V7.05078Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M10.2265 7.05078H8.25V9.02725H10.2265V7.05078Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M14.1796 7.05078H12.2031V9.02725H14.1796V7.05078Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M15.8731 12.1316C17.4289 12.1316 18.6966 13.3994 18.6966 14.9551C18.6966 16.5109 17.4289 17.7787 15.8731 17.7787C14.3174 17.7787 13.0496 16.5109 13.0496 14.9551C13.0496 13.3994 14.3174 12.1316 15.8731 12.1316ZM15.8731 10.4375C13.3771 10.4375 11.3555 12.4591 11.3555 14.9551C11.3555 17.4511 13.3771 19.4728 15.8731 19.4728C18.3691 19.4728 20.3908 17.4511 20.3908 14.9551C20.3908 12.4591 18.3691 10.4375 15.8731 10.4375Z'
        fill={color || '#DB2D1E'}
      />
      <path
        d='M17.6408 15.8033H15.3086V13.3242H16.438V14.6739H17.6408V15.8033Z'
        fill={color || '#DB2D1E'}
      />
    </g>
    <defs>
      <clipPath id='clip0_7065_5031'>
        <rect
          width='20.0075'
          height='19.2'
          fill='white'
          transform='translate(0.382812 0.273438)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default DateIcon6
