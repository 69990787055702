import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import StaffQueue from '@/redux/models/StaffQueue'

//@ts-ignore
import api from '../../../api'
import Staff from '../../../redux/models/Staff'

const useCardBook = () => {
  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { date, time } = bookingOrderState

  const [currentDate, setCurrentDate] = useState<Date>(new Date())

  useEffect(() => {
    callApiStaffQueues(date)
  }, [date])

  const callApiStaffQueues = async (date: Date) => {
    try {
      let staffCome: Staff[] = []
      let staffNotCome: Staff[] = []
      const { data: staffsQueue } = await api.getStaffDataByQueue(
        dayjs(date).toDate()
      )

      const { data, success } = await api.getStoreDateTimeByDateV2(
        dayjs(new Date()).startOf('date').add(+time.hour, 'hour').toDate(),
        { withinDay: true }
      )
      if (success) setCurrentDate(data.openDateTime)

      const day =
        dayjs(date).startOf('date').add(+time.hour, 'hour').get('day') === 0
          ? 6
          : dayjs(date).startOf('date').add(+time.hour, 'hour').get('day') - 1

      if (staffsQueue.length) {
        const { queue }: StaffQueue = staffsQueue[0]

        if (!dayjs(data.openDateTime).isSame(staffsQueue[0].date, 'date')) {
          staffCome = queue
            .filter(q => q.staff !== undefined && q.staff.workingStatus === 1)
            .map(s => ({ ...s.staff! }))
        } else {
          staffCome = queue
            .filter(q => q.staff !== undefined && q.staff.workingStatus === 1)
            .map(s => ({ ...s.staff! }))

          staffNotCome = queue
            .filter(q => q.staff !== undefined && q.staff.workingStatus === -1)
            .map(s => ({ ...s.staff! }))
          staffCome = staffCome.concat(staffNotCome)
        }
      } else {
        const { data: staffs } = await api.getStaffByStatus(1)
        staffCome = staffs.filter((staff: Staff) =>
          staff.workingDay.some(
            workingDay =>
              workingDay.day === day && workingDay.workingStatus !== -1
          )
        )
      }
      // setStaffQueues(staffCome)
    } catch (err) {
      console.log(err)
    }
  }

  // const callApiStaffOrders = async () => {
  //   try {
  //     const staffOrders = await api.getBookingHistoryByStaffOrders()
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  return { currentDate }
}

export default useCardBook
