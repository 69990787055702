import React from 'react'

const StoreIcon2 = ({ size, color }) => (
  <svg
    width={size || '43'}
    height={size || '40'}
    viewBox='0 0 43 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.5802 6.08696L41.2903 11.0145C43.5793 14.2095 42.1417 18.4245 38.9135 19.8194V34.4928C38.9715 37.5072 36.4788 40 33.4063 40H10.2759C7.20339 40 4.76861 37.5072 4.76861 34.4928V19.915C1.36297 18.6276 -0.242044 14.2863 2.10194 11.0145L5.63817 6.31884L8.42078 2.31884C8.76861 1.7971 9.1744 1.3913 9.63817 1.04348C10.5657 0.347826 11.6092 0 12.7686 0H30.8556C32.6527 0 34.3338 0.927536 35.2614 2.37681L37.5802 6.08696ZM8.24687 20.1165V34.4928C8.24687 35.6522 9.1744 36.5217 10.2759 36.5217H33.4063C34.5657 36.5217 35.4353 35.5942 35.4353 34.4928V20.1716C34.243 19.9271 33.1405 19.3144 32.3048 18.4348L28.8266 14.7246C27.957 17.913 25.0585 20.2899 21.5802 20.2899C18.2179 20.2899 15.3773 18.087 14.3918 15.0145L11.1454 18.4348C10.3258 19.2544 9.33227 19.8423 8.24687 20.1165Z'
      fill={color || '#C8CDD7'}
    />
  </svg>
)

export default StoreIcon2
