import React from 'react'
import styled from 'styled-components'

import Option, { SelectorOption } from '@/redux/models/Option'

import SelectorBase from './SelectorBase'

interface SelectorBookingTypeProps {
  name: string
  open: boolean
  onClick: (open: boolean) => void
  option: Option
  onOptionClick: (option: SelectorOption, name: string) => void
  error: boolean
  readOnly: boolean
}

const SelectorBookingType = ({
  name = 'bookingType',
  open,
  onClick,
  option = {
    value: -1,
    label: '',
  },
  onOptionClick,
  error = false,
  readOnly = false,
}: SelectorBookingTypeProps) => {
  const optionBookingTypes = [
    { value: 1, label: 'walk-in' },
    { value: 2, label: 'booking' },
    { value: 3, label: 'agency' },
  ]

  return (
    <SelectorBase
      name={name}
      option={option}
      options={optionBookingTypes}
      open={open}
      onClick={open => onClick(open)}
      onOptionClick={(item, name) => onOptionClick(item, name)}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorBookingType
