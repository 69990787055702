import React, { useEffect, useRef, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'

//@ts-ignore
import CheckBox from '../../atoms/CheckBox'

//@ts-ignore
import api from '../../api'
import useOnClickOutside from '../../hook/useOnClickOutside'
import RoomType from '../../redux/models/RoomType'

interface SelectorBaseStyle extends CSSProperties {
  open?: boolean
}

const SelectWrapper = styled.div<SelectorBaseStyle>`
  width: ${props => props.width || '100%'};
  position: relative;
  user-select: none;
`

const Select = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

const SelectTrigger = styled.div<SelectorBaseStyle>`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #3b3b3b;
  background: ${props => props.background || '#ffffff'};
  cursor: pointer;
  border-width: ${props => props.borderWidth || '1px'};
  border-style: solid;
  border-color: ${props => props.borderColor || '#e4e8eb'};
  border-radius: ${props => props.borderRadius || '4px'};
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
`

const Options = styled.div<SelectorBaseStyle>`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: ${props => (props.open ? '1px solid #8C56D9' : '1px solid #e4e8eb')};
  margin-top: 0.5rem;
  border-radius: 8px;
  background: #fff;
  transition: all 0.5s;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  pointer-events: ${props => (props.open ? 'all' : 'none')};
  z-index: 3;
  overflow: auto;
  max-height: ${props => props.height};
`

const Option = styled.span<SelectorBaseStyle>`
  position: relative;
  display: block;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #475467;
  /* line-height: 60px; */
  background: ${props => props.backgroundColor || 'none'};
  cursor: pointer;
  transition: all 0.5s;
  :first-child {
    border-radius: 8px 8px 0 0;
  }
  :last-child {
    border-radius: 0 0 8px 8px;
  }
  &:only-child {
    border-radius: 8px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }

  :hover {
    cursor: pointer;
    background-color: #e4e8eb;
  }
  ::selection {
    color: #475467;
    background-color: #e4e8eb;
  }
`

const Arrow = styled.div<SelectorBaseStyle>`
  position: relative;
  height: 10px;
  width: 2px;
  border-radius: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 5px;
  transition: all 0.4s ease;
  transform: ${props => (props.open ? 'rotate(-135deg)' : 'rotate(45deg)')};
  right: 1rem;
  bottom: ${props => (props.open ? '-4px' : '1px')};
`

interface Option {
  label: string
  value: number
}

interface Options extends Option {
  label: string
  value: number
}

interface SelectorBaseProps {
  option: Option[]
  options?: Options[]
  open: boolean
  onClick: (open: boolean) => void
  onOptionClick: (item: Option) => void
}

const SelectorCheckBoxRoomType = ({
  open,
  option,
  onClick,
  onOptionClick,
}: SelectorBaseProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [options, setOptions] = useState<Options[]>([])

  useOnClickOutside(wrapperRef, () => {
    onClick(false)
  })

  useEffect(() => {
    callApiRoomType()
  }, [])

  const callApiRoomType = async () => {
    try {
      const { data } = await api.getRoomTypeByStoreId()
      const options = data.reduce((acc: any[], info: RoomType) => {
        acc.push({ value: info._id, label: info.roomTypeName })
        return acc
      }, [])
      setOptions(options)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <SelectWrapper ref={wrapperRef}>
      <Select>
        <SelectTrigger onClick={() => onClick(!open)}>
          <div style={{ color: '#000000', fontWeight: '400' }}>
            {option.length === 0
              ? 'ห้อง'
              : option.length > 1
              ? `${option.length} ห้อง`
              : option.map(o => o.label).join(', ')}
          </div>
          <Arrow open={open} />
        </SelectTrigger>
        <Options open={open} height='200px'>
          {options.map((item, index) => (
            <Option key={index}>
              <CheckBox
                checked={
                  option.find(d => d.value === item.value) ? true : false
                }
                onClick={() => onOptionClick(item)}
                text={typeof item === 'string' ? item : item?.label}
              />
            </Option>
          ))}
        </Options>
      </Select>
    </SelectWrapper>
  )
}

export default SelectorCheckBoxRoomType
