import styled, { CSSProperties } from 'styled-components'

export interface LabelProps extends CSSProperties {
  size?: string
  weight?: CSSProperties['fontWeight']
  error?: boolean
  center?: boolean
  lineCap?: boolean
  ellipsis?: boolean
  hover?: boolean
  colorHover?: CSSProperties['color']
}

const Label = styled.span<LabelProps>`
  font-family: ${props => props.font};
  font-style: normal;
  font-size: ${props => props.size};
  font-weight: ${props => props.weight || 400};
  line-height: ${props =>
    props.lineCap ? props.size : props.lineHeight || 'normal'};
  color: ${props => (props.error ? '#DC4444' : props.color || '#52527C')};
  text-align: ${props => props.center && 'center'};
  white-space: ${props => props.whiteSpace};
  ${props =>
    props.ellipsis
      ? `display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;`
      : ''}
  text-decoration: ${props => props.textDecoration};
  opacity: ${props => props.opacity};
  &:hover {
    color: ${props => (props.hover ? props.colorHover : props.color)};
    cursor: ${props => (props.hover ? 'pointer' : 'inherit')};
  }
`

export default Label
