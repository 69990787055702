import React from 'react'

import { CheckBoxCheckedIcon, CheckBoxOutlinedIcon } from './icons/CheckBoxIcon'

const Checkbox = ({ checked, onChange, children, style, className }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        cursor: 'pointer',
        ...style,
      }}
      className={className}
      onClick={onChange}
    >
      {checked ? (
        <CheckBoxCheckedIcon size={14} />
      ) : (
        <CheckBoxOutlinedIcon size={14} />
      )}
      {children}
    </div>
  )
}

export default Checkbox
