import { useDispatch, useSelector } from 'react-redux'

// @ts-ignore
import useDebouncedValue from '../../../hook/useDebouncedValue'
import {
  changeSearch,
  setExpand,
  toggleExpand,
  toggleNotification,
} from '../../../redux/actions/pageAction'

const usePage = () => {
  const { search, menuWidth, isExpanded, isNotificationOpen } = useSelector(
    (state: any) => state.page
  )

  const debouncedSearch = useDebouncedValue(search, { skipEmpty: true })
  const dispatch = useDispatch()

  const onExpand = (value?: boolean) => {
    if (value === undefined) {
      dispatch(toggleExpand())
    } else {
      dispatch(setExpand(value))
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSearch(e.target.value))
  }

  const onToggle = () => {
    dispatch(toggleNotification())
  }

  return {
    search: debouncedSearch,
    menuWidth,
    isExpanded,
    isNotificationOpen,
    onExpand,
    onChange,
    onToggle,
  }
}

export default usePage
