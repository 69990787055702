import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

import useNotification from './useNotification'

//@ts-ignore
import Button2 from '../../atoms/Button2'
import ClosePopup from '../../atoms/ClosePopup'
import TextLabel from '../../atoms/TextLabel'

//@ts-ignore
import utils from '../../utils'

interface notificationStyled extends CSSProperties {
  size?: string
}

const Container = styled.div`
  display: flex;
  z-index: 10;
  height: 100vh;
  width: 35%;
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: #fff;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.08);
`
const Card = styled.div`
  background: #ffffff;
  width: 100%;
  box-shadow: 0px 24px 16px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 1.25rem 1rem;
`

const Box = styled.div`
  border-radius: 4px;
  border: 1px solid #e4e8eb;
  background: #fff;
  padding: 1rem;
  height: fit-content;
  width: 100%;
`
const FlexBox = styled.div<notificationStyled>`
  display: flex;
  width: ${props => props.width || '100%'};
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent || 'unset'};
  align-items: ${props => props.alignItems || 'unset'};
  gap: ${props => props.gap || '0'};
`
const Line = styled.div`
  border-bottom: 2px solid #f1edf9;
  height: 1px;
  width: 100%;
  padding: 0.25rem 0;
`
const Circle = styled.div<notificationStyled>`
  background-color: ${props => props.backgroundColor || '#000'};
  width: ${props => props.size || '5px'};
  height: ${props => props.size || '5px'};
  border-radius: 50%;
`

const Notification = () => {
  const { t } = useTranslation()

  const { onEditBookingClick, bookingHistories, onToggle } = useNotification()

  return (
    <Container>
      <Card>
        <div
          className='mb-2'
          style={{
            width: 'fit-content',
            marginLeft: 'auto',
            padding: '0.5rem 0.25rem 0.5rem 1rem',
          }}
          onClick={onToggle}
        >
          <ClosePopup></ClosePopup>
        </div>
        <TextLabel fontWeight='600' fontSize='1.25rem'>
          {t('notification')}
        </TextLabel>
        <div className='mb-2'></div>
        {bookingHistories.map(
          (
            { _id, orders, date, time, bookingChannel, notificationStatus },
            index: number
          ) => (
            <Box className='mb-2' key={index}>
              <FlexBox justifyContent='space-between'>
                <FlexBox alignItems='center' gap='0.5rem' width='fit-content'>
                  <Circle
                    backgroundColor={notificationStatus === 1 ? 'red' : 'gray'}
                    // backgroundColor='red'
                    size='8px'
                  ></Circle>
                  <TextLabel fontWeight='500'>
                    {bookingChannel.bookingChannel}
                  </TextLabel>
                </FlexBox>
                <TextLabel color='#475467' fontSize='0.875rem'>{`${dayjs(
                  date
                ).format('DD MMM YYYY')},${utils.formatTime(
                  time.hour + ''
                )}:${utils.formatTime(time.minute + '')}`}</TextLabel>
              </FlexBox>
              {/* {orders.map(
                ({ services, name, email, phoneNumber }, index: number) => (
                  <Box key={index}>
                    <FlexBox justifyContent='space-between'>
                      <TextLabel
                        color='#8C56D9'
                        fontSize='0.875rem'
                        fontWeight='500'
                      >{`${t('customerNo.')} ${index + 1}`}</TextLabel>
                      <TextLabel fontSize='0.875rem' fontWeight='500'>{`${
                        services.length
                      } ${t('service')}`}</TextLabel>
                    </FlexBox>
                    <Line />
                    {services.map(
                      (
                        { service, startTime, endTime, time, price },
                        index: number
                      ) => (
                        <FlexBox
                          className='mt-2'
                          flexDirection='column'
                          style={{ lineHeight: 1 }}
                          key={index}
                        >
                          <TextLabel fontWeight='500'>{service}</TextLabel>
                          <FlexBox alignItems='center' gap='0.25rem'>
                            <TextLabel
                              color='#475467'
                              fontSize='0.875rem'
                            >{`${utils.formatTime(
                              startTime.hour + ''
                            )}:${utils.formatTime(
                              startTime.minute + ''
                            )} - ${utils.formatTime(
                              endTime.hour + ''
                            )}:${utils.formatTime(
                              endTime.minute + ''
                            )}`}</TextLabel>
                            <Circle></Circle>
                            <TextLabel
                              color='#475467'
                              fontSize='0.875rem'
                            >{`${time}นาที`}</TextLabel>
                          </FlexBox>
                        </FlexBox>
                      )
                    )}
                    <FlexBox className='mt-2' flexDirection='column'>
                      <FlexBox gap='0.5rem'>
                        <TextLabel>{name}</TextLabel>
                        <TextLabel>
                          {utils.formatPhoneNumber(phoneNumber)}
                        </TextLabel>
                      </FlexBox>
                      <TextLabel>{email}</TextLabel>
                    </FlexBox>
                  </Box>
                )
              )} */}

              <Button2
                backgroundColor='linear-gradient(222deg, #8C56D9 0%, #53389E 100%)'
                color='#FFF'
                width='fit-content'
                padding='0.5rem'
                size='0.875rem'
                onClick={() => onEditBookingClick(_id)}
              >
                {t('selectTherapist')}
              </Button2>
            </Box>
          )
        )}
      </Card>
    </Container>
  )
}

export default Notification
