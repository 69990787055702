import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import BodyStore from './BodyStore'
import api from '../../api'
import Button2 from '../../atoms/Button2'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Body = () => {
  const [stores, setStores] = useState([])

  useEffect(() => {
    callApiStore()
  }, [])

  const callApiStore = async () => {
    try {
      const { data } = await api.getStoreAll()
      setStores(data)
    } catch (err) {
      console.log(err)
    }
  }

  const onUpdate = data => {
    if (data._id) {
      setStores(prevStores => {
        const newStores = [...prevStores]
        const idx = newStores.findIndex(s => s._id === data._id)
        if (idx >= 0) {
          newStores[idx] = data
        }
        return newStores
      })
    }
  }

  const downloadAdmin = async () => {
    try {
      const data = await api.downloadAdminReport()
      const blob = new Blob([new Uint8Array(data.data.data.data)], {
        type: 'text/csv;charset=utf8',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `report_admin.xlsx`
      link.click()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <div className='pr-4 pt-2' style={{ marginLeft: 'auto' }}>
        <Button2
          onClick={() => downloadAdmin()}
          width='fit-content'
          backgroundColor='pink'
          padding='0.5rem'
        >
          ดึงข้อมูลร้านนะจ้ะ
        </Button2>
      </div>
      <BodyStore stores={stores} onUpdate={onUpdate} />
    </Container>
  )
}

export default Body
