import axios from 'axios'

import { getApiEndpoint } from './configBase'

import auth from '../../auth'

export const apiClient = axios.create({ baseURL: getApiEndpoint() })

apiClient.interceptors.request.use(function (config) {
  const storeId = auth.getStoreId()
  const brandId = auth.getBrandId()
  const token = auth.getToken()

  config.headers.Authorization = token

  if (!config.params?.storeId) {
    config.params = { ...config.params, storeId }
  }
  if (!config.params?.brandId) {
    config.params = { ...config.params, brandId }
  }

  if (config.data) {
    if (Array.isArray(config.data)) {
      config.data = config.data.map(item => ({
        ...item,
        brandId: item.brandId ?? brandId,
      }))
    } else {
      config.data = { ...config.data, brandId: config.data.brandId ?? brandId }
    }
  }

  return config
})
