import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import BahtIcon2 from '../../atoms/BahtIcon2'
import CreditIcon from '../../atoms/CreditIcon'
import TransferIcon from '../../atoms/TransferIcon'
import OnlineIcon from '../../atoms/OnlineIcon'
import ClosePopup from '../../atoms/ClosePopup'
import Arrow from '../../atoms/Arrow'
import LongArrow from '../../atoms/LongArrow'
import InputText from '../../atoms/InputText'
import Credit from '../payment/Credit'
import Transfer from '../payment/Transfer'
import Online from '../payment/Online'
import utils from '../../utils'
import Button2 from '../../atoms/Button2'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import api from '../../api'
import PopupWaringCashier from './PopupWaringCashier'
import AgencyIcon from '../../atoms/AgencyIcon'
import Package from '../payment/Package'
import { getPayPackage } from '../../redux/actions/payPackageActions'
import PackageIcon from '../../atoms/PackageIcon'
import { useTranslation } from 'react-i18next'

const Card = styled.div`
  width: 100%;
  padding: 1rem 1rem 0.5rem 1.25rem;
  display: flex;
  flex-direction: column;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0.5rem'};
  justify-content: ${props => props.justifyContent || 'unset'};
  align-items: ${props => props.alignItem || 'unset'};
  padding: ${props => props.padding || '0'};
`
const Line = styled.div`
  border-top: 1px solid #e4e8eb;
  width: calc(100% + 2.25rem);
  margin-left: -1.25rem;
  padding-bottom: 0.5rem;
`

const PopupSelectPayChannel = ({
  handleClose,
  payChannels = [],
  total = 0,
  discount = 0,
  serviceDiscount = 0,
  packageDiscount: _packageDiscount = 0,
  splitPackageDiscount = 0,
  totalTip = 0,
  bookingHistory,
  customerPackages: _customerPackages,
}) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const bookingOrderState = useSelector(state => state.bookingHistoryReducers)
  const { _id, orders, commission, promotions, buyPackage } =
    bookingOrderState || {}
  const [activeBtnPayChannelType, setActiveBtnPayChannelType] = useState(-1)
  const [payChannel, setPayChannel] = useState({})
  const [price, setPrice] = useState('')
  const [currentTotal, setCurrentTotal] = useState(total)
  const [charge, setCharge] = useState('')
  const [isOpenPopupWarning, setIsOpenPopupWarning] = useState(false)
  const [customerPackages, setCustomerPackages] = useState([])
  const [packageDiscount, setPackageDiscount] = useState(0)
  const payChannelTypes = [
    {
      payChannelTypeId: 1,
      payChannelType: t('cash'),
      icon: <BahtIcon2 size='20' color='#8C56D9' />,
    },
    {
      payChannelTypeId: 2,
      payChannelType: t('creditCard'),
      icon: <CreditIcon size='20' color='#8C56D9' />,
    },
    {
      payChannelTypeId: 3,
      payChannelType: t('transfer'),
      icon: <TransferIcon size='20' color='#8C56D9' />,
    },
    {
      payChannelTypeId: 4,
      payChannelType: t('eWallet'),
      icon: <OnlineIcon size='18' color='#8C56D9' />,
    },
    {
      payChannelTypeId: 6,
      payChannelType: t('package'),
      icon: <PackageIcon size='18' color='#8C56D9' />,
    },
  ]
  useEffect(() => {
    let _total =
      total -
      discount -
      serviceDiscount +
      totalTip -
      splitPackageDiscount -
      _packageDiscount
    if (payChannels.length) {
      for (const payChannel of payChannels) {
        const { price } = payChannel
        _total -= price
      }
    }
    setCurrentTotal(_total)
  }, [
    payChannels,
    total,
    discount,
    serviceDiscount,
    totalTip,
    splitPackageDiscount,
    _packageDiscount,
  ])

  const onPriceChange = value => {
    if (utils.checkNumber(value)) {
      setPrice(value)
    }
  }
  const onSelectPayChannel = payChannel => {
    setPayChannel({ ...payChannel })
  }

  const onChargeChange = (unit, value) => {
    let newPayChannel = { ...payChannel }
    if (unit === 1) {
      const percent = value / 100
      const _total = +price * +percent
      setCharge(_total)
      newPayChannel = {
        ...newPayChannel,
        creditCharge: `ชาร์จ ${value}${unit === 1 ? '%' : '฿'} ${_total} บาท`,
      }
    } else {
      setCharge(value)
      newPayChannel = {
        ...newPayChannel,
        creditCharge: `ชาร์จ ${value} บาท`,
      }
    }
    setPayChannel(newPayChannel)
  }

  const onSelectPackage = (customerPackages, packageDiscount) => {
    setCustomerPackages(customerPackages)
    setPackageDiscount(packageDiscount)
    setPrice(packageDiscount)
  }

  const onPayClick = () => {
    if (price) {
      let _price = price
      const newPayChannel = [...payChannels]
      if (activeBtnPayChannelType !== 6) {
        newPayChannel.push({
          payChannelTypeId: activeBtnPayChannelType,
          payChannelId:
            activeBtnPayChannelType === 1 ? 0 : payChannel.payChannelId,
          payChannel:
            activeBtnPayChannelType === 1 ? 'เงินสด' : payChannel.payChannel,
          note: payChannel.note || '',
          creditCharge: payChannel.creditCharge || 0,
          creditNumber: payChannel.creditNumber || '',
          charge: charge || 0,
          price: _price,
          remaining: currentTotal - price,
        })
        dispatch(
          getOrderBooking({
            payChannels: newPayChannel,
          })
        )
      } else if (activeBtnPayChannelType === 6) {
        dispatch(
          getPayPackage({
            splitPayCustomerPackages: customerPackages,
            splitPackageDiscount: packageDiscount,
          })
        )
      }
      handleClose()
    }
  }

  const onCreatePrice = () => {
    let total = price || ''
    if (charge) {
      total = parseInt(total) + parseInt(charge)
    } else {
      total = price || ''
    }
    return total
  }

  const onActiveBtnPayChannelTypeClick = async payChannelTypeId => {
    if (payChannelTypeId === 1) {
      const checkOpenCashier = await callApiCheckOpenCashier()
      if (checkOpenCashier) {
        setActiveBtnPayChannelType(payChannelTypeId)
      }
      return checkOpenCashier
    } else {
      setActiveBtnPayChannelType(payChannelTypeId)
    }
  }

  const callApiCheckOpenCashier = async () => {
    try {
      let _date = dayjs(new Date())
        .startOf('day')
        .add(+new Date().getHours(), 'hour')
        .add(+new Date().getMinutes(), 'minute')
        .toDate()

      const { data, success } = await api.getStoreDateTimeByDateV2(
        new Date(_date)
      )

      let bookingDate = dayjs(bookingHistory.date)
        .startOf('day')
        .add(+bookingHistory.time.hour, 'hour')
        .add(+bookingHistory.time.minute, 'minute')
        .toDate()

      let checkDate = false
      let currentDate = new Date()
      if (success) {
        const { openDateTime } = data

        currentDate = new Date(dayjs(openDateTime).toDate())
        checkDate = await utils.checkIsSameCurrentDate(
          new Date(bookingDate),
          currentDate
        )
      }

      let selectDate = new Date()

      if (checkDate) {
        selectDate = new Date(dayjs(currentDate))
      } else {
        if (dayjs(bookingHistory.date).isBefore(currentDate, 'date')) {
          selectDate = new Date(dayjs(bookingDate))
        } else if (dayjs(bookingHistory.date).isAfter(currentDate, 'date')) {
          selectDate = new Date(dayjs(currentDate))
        } else {
          // selectDate = new Date(dayjs(bookingDate))
          selectDate = new Date(dayjs(bookingDate))
        }
      }

      const { data: _cashier, success: cashierSuccess } =
        await api.getCashierByDate(new Date(selectDate), new Date(selectDate))
      if (cashierSuccess && _cashier.length) {
        let cashier = _cashier.sort((a, b) => b.number - a.number)
        if (dayjs(selectDate).isBefore(currentDate, 'date')) {
          return true
        } else if (cashier[0].open) {
          return true
        } else {
          await setIsOpenPopupWarning(true)
          return false
        }
      } else {
        await setIsOpenPopupWarning(true)
        return false
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <PopupBox width='calc(100%)' height='100%' left='0rem' top='0'>
      <PopupInsideBox
        width={activeBtnPayChannelType === -1 ? 'auto' : '40%'}
        marginTop='0'
        padding='0'
        height='auto'
      >
        <Card>
          {activeBtnPayChannelType === -1 ? (
            <>
              <FlexBox padding='0 0 0.25rem 0'>
                <TextLabel
                  fontSize='1.25rem'
                  fontWeight='500'
                  paddingRight='2rem'
                >
                  {t('selectPaymentMethod')}
                </TextLabel>
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                <div
                  className='mr-2'
                  style={{ marginLeft: 'auto' }}
                  onClick={handleClose}
                >
                  <ClosePopup />
                </div>
              </FlexBox>
              {payChannelTypes.map(
                ({ payChannelTypeId, payChannelType, icon }) => {
                  let show = true
                  let customerPackageCount = 0
                  _customerPackages.map(
                    c => (customerPackageCount += c.packages.length)
                  )
                  if (buyPackage && payChannelTypeId === 6) {
                    show = false
                  } else if (
                    payChannelTypeId === 6 &&
                    customerPackageCount === 0
                  ) {
                    show = false
                  }
                  if (show)
                    return (
                      <FlexBox
                        flexDirection='column'
                        gap='0rem'
                        padding='0.25rem 0'
                        onClick={() =>
                          onActiveBtnPayChannelTypeClick(payChannelTypeId)
                        }
                      >
                        <Line></Line>
                        <FlexBox justifyContent='space-between'>
                          <div>
                            {icon}
                            <TextLabel fontWeight='500' paddingLeft='0.5rem'>
                              {payChannelType}
                            </TextLabel>
                          </div>
                          <div>
                            <Arrow
                              transform='rotate(-45deg)'
                              border='solid #98A1B2'
                            />
                          </div>
                        </FlexBox>
                      </FlexBox>
                    )
                }
              )}
            </>
          ) : (
            <>
              {activeBtnPayChannelType !== 6 && (
                <>
                  <FlexBox padding='0 0 0.25rem 0' alignItem='center'>
                    <div onClick={() => setActiveBtnPayChannelType(-1)}>
                      <LongArrow />
                    </div>
                    <TextLabel
                      fontSize='1.25rem'
                      fontWeight='500'
                      paddingLeft='0.5rem'
                    >
                      {`${t('payInInstallment')} #${payChannels.length + 1}`}
                    </TextLabel>
                  </FlexBox>
                  <TextLabel fontSize='0.875rem'>{`${t(
                    'splitInstallmentNo.'
                  )} ${payChannels.length + 1}`}</TextLabel>
                  <InputText
                    placeholder='0'
                    value={onCreatePrice()}
                    onChange={val => onPriceChange(val.target.value)}
                  />
                </>
              )}
              <FlexBox>
                <TextLabel fontSize='0.875rem'>{t('remaining')}</TextLabel>
                <TextLabel fontSize='0.875rem' color='#8C56D9' fontWeight='500'>
                  {`฿ ${currentTotal - price}`}
                </TextLabel>
                <TextLabel fontSize='0.875rem'>{t('fromTotal')}</TextLabel>
                <TextLabel
                  fontSize='0.875rem'
                  color='#8C56D9'
                  fontWeight='500'
                >{`฿${
                  total - discount - serviceDiscount + totalTip
                }`}</TextLabel>
              </FlexBox>
              <div className='mt-3' style={{ maxWidth: '100%' }}>
                {activeBtnPayChannelType === 2 && (
                  <Credit
                    splitPayment={true}
                    onSelectPayChannel={onSelectPayChannel}
                    onChargeChange={onChargeChange}
                    charge={charge}
                  />
                )}
                {activeBtnPayChannelType === 3 && (
                  <Transfer onSelectPayChannel={onSelectPayChannel} />
                )}
                {activeBtnPayChannelType === 4 && (
                  <Online
                    splitPayment={true}
                    onSelectPayChannel={onSelectPayChannel}
                    onChargeChange={onChargeChange}
                    total={total}
                    discount={discount}
                    serviceDiscount={serviceDiscount}
                    bookingCode={bookingHistory.bookingCode}
                  />
                )}
                {activeBtnPayChannelType === 6 && (
                  <Package
                    orders={orders}
                    splitPayment={true}
                    onSelectPackage={onSelectPackage}
                    customerPackages={_customerPackages}
                  />
                )}
              </div>
              <div className='mt-3 mb-2'>
                <Line></Line>
              </div>
              <FlexBox>
                <Button2
                  backgroundColor='#FFF'
                  border='1px solid #E4E8EB'
                  onClick={handleClose}
                >
                  {t('cancel')}
                </Button2>
                <Button2
                  backgroundColor='linear-gradient(222deg, #8C56D9 2.71%, #53389E 97.79%)'
                  color='#FFF'
                  opacity={price ? '' : '0.5'}
                  onClick={() => onPayClick()}
                >
                  {t('confirm')}
                </Button2>
              </FlexBox>
            </>
          )}
        </Card>
        {isOpenPopupWarning && (
          <PopupWaringCashier
            handleClose={() => setIsOpenPopupWarning(false)}
          />
        )}
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupSelectPayChannel
