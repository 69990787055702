import React, { useState, Fragment } from 'react'
import styled from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import TextLabel from '../../atoms/TextLabel'
import ServiceDrag from './ServiceDrag'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const WhiteBox = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  border: ${props => props.border || '1px solid white'};
`

const ContainerCircle = styled.div`
  display: flex;
  max-width: ${props => props.width || '5.5%'};
`

const ContainerCircleStyle = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: wrap;
  width: 80%;
  padding: ${props => props.padding || '0'};
  height: fit-content;
`

const BoxCircle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 33%'};
  max-width: ${props => props.width || '33%'};
  margin: ${props => props.margin || '0.25rem 0 0 0'};
`

const Circle = styled.div`
  background: #e4e8eb;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`

const Arrow = styled.div`
  border: solid #475467;
  border-width: 0 3px 3px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  /* margin-left: 5px; */
  transition: all 0.4s ease;
  transform: ${props => props.transform || 'rotate(45deg)'};
`

const BoxArrow = styled.div`
  background: #f8f9fb;
  border-radius: 8px;
  transform: translate(-50%);
  padding: 0.25rem 0.65rem;
`

const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.16);
  border-radius: 8px;
  padding: 0.5rem;
  top: 10px;
  right: 0px;
  z-index: 1;
`

const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`

const BoxEdit = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 1 auto'};
  max-width: ${props => props.width || 'auto'};
  margin-left: ${props => props.marginLeft || '0'};
  cursor: ${props => props.cursor || 'none'};
  position: relative;
`

const ServiceGroupDrag = ({
  column,
  tasks,
  index,
  type,
  activeServiceGroupEdit,
  onOpenColumnClick,
  openServiceGroupEdit,
  onDropDownServiceGroupEditClick,
  onEditGroupClick,
  onEditServiceClick: _onEditServiceClick,
  onDeleteServiceClick,
  onDeleteGroupClick,
}) => {
  const [activeServiceEdit, setActiveServiceEdit] = useState(-1)
  const onDropDownClick = e => {
    // e.stopPropagation()
  }
  const onEditServiceGroupClick = e => {
    e.stopPropagation()
    onEditGroupClick()
  }
  const onDeleteServiceGroupClick = e => {
    e.stopPropagation()
    onDeleteGroupClick()
  }

  const onDropDownServiceEditClick = (e, id) => {
    e.stopPropagation()
    if (id === activeServiceEdit) {
      setActiveServiceEdit(-1)
    } else {
      setActiveServiceEdit(id)
    }
  }

  const onEditServiceClick = id => {
    setActiveServiceEdit(-1)
    _onEditServiceClick(id)
  }

  return (
    <Draggable draggableId={column.id} index={index}>
      {provided => (
        <Container {...provided.draggableProps} ref={provided.innerRef}>
          <WhiteBox
            border={
              activeServiceGroupEdit === column._id ? '1px solid #8C56D9' : ''
            }
          >
            {/* <Line background={column.taskIds.length ? '' : '#D0D4DD'}></Line> */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: `${column.open ? 'flex-start' : 'center'}`,
              }}
              onClick={e => onOpenColumnClick(e)}
            >
              <ContainerCircle {...provided.dragHandleProps}>
                <ContainerCircleStyle>
                  {Array.from({ length: 9 }).map((_, index) => (
                    <BoxCircle key={index}>
                      <Circle></Circle>
                    </BoxCircle>
                  ))}
                </ContainerCircleStyle>
              </ContainerCircle>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  className='ml-2'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <TextLabel fontWeight='500' fontSize='1.25rem'>
                    {column.title}
                  </TextLabel>
                  <TextLabel
                    fontSize='0.875rem'
                    fontWeight='500'
                    color='#98A1B2'
                    paddingLeft='0.5rem'
                  >{`(${column.taskIds.length} ${
                    type === 'service' ? 'บริการ' : 'สินค้า'
                  })`}</TextLabel>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {column.taskIds.length !== 0 && (
                    <BoxArrow onClick={e => onOpenColumnClick(e)}>
                      <Arrow
                        transform={column.open ? 'rotate(-135deg)' : ''}
                      ></Arrow>
                    </BoxArrow>
                  )}
                  <BoxEdit
                    onClick={e =>
                      onDropDownServiceGroupEditClick(e, column._id)
                    }
                  >
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div className='mr-1' key={index}>
                        <Circle></Circle>
                      </div>
                    ))}
                    {openServiceGroupEdit === column._id && (
                      <DropDown onClick={onDropDownClick.bind(this)}>
                        <Option onClick={onEditServiceGroupClick.bind(this)}>
                          แก้ไข
                        </Option>
                        {!column.taskIds.length && (
                          <Option
                            onClick={onDeleteServiceGroupClick.bind(this)}
                          >
                            ลบ
                          </Option>
                        )}
                      </DropDown>
                    )}
                  </BoxEdit>
                </div>
              </div>
            </div>
            <Droppable droppableId={column.id} type='TASK'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ width: '100%' }}
                >
                  <Scrollbar id='setting-serviceGroupDrag' maxHeight='210px'>
                    {tasks.map((task, index) => {
                      return (
                        <Fragment key={index}>
                          {column.open && (
                            <ServiceDrag
                              key={task.id}
                              task={task}
                              index={index}
                              //   open={column.open}
                              activeServiceEdit={activeServiceEdit}
                              onDropDownServiceEditClick={e =>
                                onDropDownServiceEditClick(e, task._id)
                              }
                              onEditServiceClick={onEditServiceClick}
                              onDeleteServiceClick={onDeleteServiceClick}
                            />
                          )}
                        </Fragment>
                      )
                    })}
                  </Scrollbar>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </WhiteBox>
        </Container>
      )}
    </Draggable>
  )
}

export default ServiceGroupDrag
