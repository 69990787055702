import api from '../../api'

export const GET_SERVICES = 'GET_SERVICES'

export const getServices = data => {
  return {
    type: GET_SERVICES,
    payload: { data },
  }
}

export const callApiServices = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getServices()
    dispatch(getServices(data))
  } catch (err) {
    console.log(err)
  }
}
