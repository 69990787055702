import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import TextLabel from '../../atoms/TextLabel'
import useOnClickOutside from '../../hook/useOnClickOutside'
import Selector from './Selector'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const OptionsStaffType = ({
  staffTypes,
  onSelectOptionStaffTypeClick: _onSelectOptionStaffTypeClick,
  defaultOptionStaffType: _defaultOptionStaffType,
}) => {
  const optionsStaffType = staffTypes.reduce(
    (a, s) => a.concat({ value: s.staffTypeId, label: s.staffType }),
    []
  )
  const [defaultOptionStaffType, setDefaultOptionStaffType] = useState(
    optionsStaffType[0]
  )
  const [openStaffType, setOpenStaffType] = useState(false)
  const wrapperRefStaffType = useRef(null)

  useEffect(() => {
    if (_defaultOptionStaffType) {
      const findStaffType =
        optionsStaffType.find(g => g.value === _defaultOptionStaffType || '') ||
        ''
      if (findStaffType) {
        setDefaultOptionStaffType(findStaffType)
      }
    }
  }, [_defaultOptionStaffType])

  const onSelectOptionStaffTypeClick = value => {
    // if (findStaffType) {
    _onSelectOptionStaffTypeClick(value)
    setOpenStaffType(false)
    // }
  }
  useOnClickOutside(wrapperRefStaffType, () => {
    setOpenStaffType(false)
  })
  const onArrowStaffTypeClick = () => {
    setOpenStaffType(!openStaffType)
  }

  return (
    <Container>
      <div className='mt-3 mb-2'>
        <TextLabel fontWeight='600'>ประเภทพนักงาน</TextLabel>
      </div>

      <Selector
        options={optionsStaffType}
        defaultOption={defaultOptionStaffType}
        openArrow={openStaffType}
        onSelectOptionClick={onSelectOptionStaffTypeClick}
        onArrowClick={onArrowStaffTypeClick}
        wrapperRef={wrapperRefStaffType}
      />
    </Container>
  )
}

export default OptionsStaffType
