import { apiClient } from '../base/apiBase'

import auth from '../../auth'

export const getStaffQueueByDate = async (
  dateStart: string,
  dateEnd: string
) => {
  const res = await apiClient.get(
    `/staffQueue/getByDate/${dateStart}/${dateEnd}/${auth.getStoreId()}`
  )
  return res.data
}

export const getStaffLastCreateQueue = async () => {
  const res = await apiClient.get(
    `/staffQueue/getLastCreate/${auth.getStoreId()}`
  )
  return res.data
}

export const createStaffQueue = async (body: any) => {
  const res = await apiClient.post(`/staffQueue/create`, body)
  return res.data
}
