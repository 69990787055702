import api from '../../api'

export const GET_STAFF = 'GET_STAFF'

export const getStaffs = data => {
  return {
    type: GET_STAFF,
    payload: { data },
  }
}

export const callApiStaffs = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getStaff()
    dispatch(getStaffs(data))
  } catch (err) {
    console.log(err)
  }
}
