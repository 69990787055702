import React from 'react'
import styled from 'styled-components'
import Button2 from '../../atoms/Button2'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'

const Card = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
`

const PopupRole = ({ handleClose, role }) => {
  return (
    <PopupBox width='calc(100%)' height='100%' left='0rem' top='0'>
      <PopupInsideBox width='35%' marginTop='0' padding='0' height='auto'>
        <Card>
          <TextLabel
            fontSize='1.25rem'
            fontWeight='500'
            style={{ textAlign: 'center' }}
          >
            {role.type}
          </TextLabel>
          <TextLabel>- ดูข้อมูลร้าน</TextLabel>
          <TextLabel color={role.typeId === 4 ? '#E4E8EB' : ''}>
            - แก้ไขข้อมูลร้าน
          </TextLabel>
          <TextLabel color={role.typeId !== 1 ? '#E4E8EB' : ''}>
            - ให้สิทธิ์
          </TextLabel>
          <Button2 onClick={() => handleClose()}>ปิด</Button2>
        </Card>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupRole
