import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import './Time.css'
import 'swiper/swiper.min.css'

const BoxTime = styled.div`
  height: 120px;
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  /* font-family: 'Kanit', sans-serif; */
  font-size: 1.5rem;
  color: #8c56d9;
  /* left: 25%; */
  margin: 0;
  padding: 0;
`
const SwiperSlideTimer = styled.div`
  text-align: center;
  font-size: ${props => (props.active ? '1.25rem' : '1rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.active ? '1' : '0.25')};
  transition: opacity 0.3s ease;
  cursor: ${props => (props.active ? 'pointer' : 'default')};
  font-weight: ${props => (props.active ? '500' : '400')};
  -webkit-tap-highlight-color: transparent;
  color: ${props => (props.active ? '#8c56d9' : '#a3a9b3')};
`

const Time = ({
  hourNow,
  minuteNow,
  storeTimeIndex,
  timeActive,
  storeOpenTime = '',
  storeCloseTime = '',
  fontSizeActive = '',
  fontSize = '',
}) => {
  const [loading, setLoading] = useState(true)
  const [hourActive, setHourActive] = useState(hourNow)
  const [minuteActive, setMinuteActive] = useState(minuteNow)
  const [hours, setHours] = useState(
    Array.from({ length: 24 }).map((_, index) => index)
  )
  const minutes = Array.from({ length: 12 }).map((_, index) => index * 5)

  useEffect(() => {
    if (storeOpenTime) {
      const { hour: openHour } = storeOpenTime
      const { hour: closeHour } = storeCloseTime
      const hour = []
      for (let i = parseInt(openHour); i <= parseInt(closeHour); i++) {
        hour.push(i)
      }
      // setHours(hour)
    }
  }, [storeOpenTime, storeCloseTime])

  useEffect(() => {
    setHourActive(parseInt(hourNow))
    setMinuteActive(parseInt(minuteNow))
    setLoading(false)
  }, [])

  useEffect(() => {
    timeActive(hourActive, minuteActive)
  }, [hourActive, minuteActive])

  const listHours = hours.map((hour, index) => {
    return (
      <SwiperSlide key={hour}>
        {({ isActive }) => (
          <SwiperSlideTimer active={isActive}>
            {/* {isActive ? setHourActive(hour) : null} */}
            {(hour + '').padStart(2, '0')}
          </SwiperSlideTimer>
        )}
      </SwiperSlide>
    )
  })

  const listMinutes = minutes.map(minute => {
    return (
      <SwiperSlide key={minute}>
        {({ isActive }) => (
          <SwiperSlideTimer active={isActive}>
            {/* {isActive ? setMinuteActive(minute) : null} */}
            {(minute + '').padStart(2, '0')}
          </SwiperSlideTimer>
        )}
      </SwiperSlide>
    )
  })

  return (
    <div className=''>
      {!loading && (
        <BoxTime>
          <Swiper
            className='swiper-container hours'
            watchSlidesVisibility={true}
            slidesPerView={5}
            spaceBetween={1}
            direction={'vertical'}
            loop={true}
            centeredSlides={true}
            slideToClickedSlide={true}
            onSwiper={swiper => swiper.slideToLoop(hourActive)}
            onRealIndexChange={swiper => {
              setHourActive(swiper.realIndex)
            }}
          >
            <div className='swiper-wrapper'>{listHours}</div>
          </Swiper>
          <Swiper
            className='swiper-container minutes'
            watchSlidesVisibility={true}
            slidesPerView={5}
            spaceBetween={1}
            direction={'vertical'}
            loop={true}
            centeredSlides={true}
            slideToClickedSlide={true}
            onSwiper={swiper => swiper.slideToLoop(minuteActive / 5)}
            onRealIndexChange={swiper => {
              setMinuteActive(swiper.realIndex * 5)
            }}
          >
            {listMinutes}
          </Swiper>
        </BoxTime>
      )}
      {/* <div className='vizor'></div> */}
    </div>
  )
}

export default Time
