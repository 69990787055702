import api from '../../api'

export const GET_BOOKING_CHANNEL = 'GET_BOOKING_CHANNEL'

export const getBookingChannel = data => {
  return {
    type: GET_BOOKING_CHANNEL,
    payload: { data },
  }
}

export const callApiBookingChannel = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getBookingChannel()
    dispatch(getBookingChannel(data))
  } catch (err) {
    console.log(err)
  }
}
