import React from 'react'

interface DateIconProps {
  size?: string
  color?: string
}

const DateIcon5 = ({ size, color }: DateIconProps) => (
  <svg
    width={size || '17'}
    height={size || '16'}
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.1301 1.54217H11.5663V0H10.0241V1.54217H5.59036V0H4.04819V1.54217H2.08193C0.925301 1.54217 0 2.46747 0 3.6241V13.9373C0 15.0747 0.925301 16 2.08193 16H14.1301C15.2675 16 16.1928 15.0747 16.1928 13.9373V3.6241C16.1928 2.46747 15.2675 1.54217 14.1301 1.54217ZM14.1301 14.4578H2.08193C1.77349 14.4578 1.54217 14.2265 1.54217 13.9373V5.20482H14.6506V13.9373C14.6506 14.2265 14.4193 14.4578 14.1301 14.4578Z'
      fill={color || 'black'}
    />
    <path
      d='M5.78316 7.51807H4.04822V9.25301H5.78316V7.51807Z'
      fill={color || 'black'}
    />
    <path
      d='M8.86745 7.51807H7.13251V9.25301H8.86745V7.51807Z'
      fill={color || 'black'}
    />
    <path
      d='M11.9518 7.51807H10.2169V9.25301H11.9518V7.51807Z'
      fill={color || 'black'}
    />
    <path
      d='M5.78316 10.6024H4.04822V12.3374H5.78316V10.6024Z'
      fill={color || 'black'}
    />
    <path
      d='M8.86745 10.6024H7.13251V12.3374H8.86745V10.6024Z'
      fill={color || 'black'}
    />
    <path
      d='M11.9518 10.6024H10.2169V12.3374H11.9518V10.6024Z'
      fill={color || 'black'}
    />
  </svg>
)

export default DateIcon5
