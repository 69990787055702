import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import TextLabel from '../../atoms/TextLabel'

import ThaiHandText from '../../atoms/ThaiHandText'
import PopupUser from '../popups/PopupUser'
import { HOME_URL } from '../../routes'

const Container = styled.div`
  display: flex;
  align-items: center;
  background: white;
  height: 80px;
  position: relative;
`
const Box = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.5rem;
`
const Circle = styled.div`
  background: #eb5757;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0.125rem 1rem 0 1rem;
`
const Text = styled.div`
  font-size: 2rem;
  color: #605b85;
  border-style: solid;
  border-width: 0 0 4px 0;
  line-height: 1.7rem;
  width: fit-content;
  border-image: linear-gradient(
      90deg,
      #f8b41c 17.1%,
      #ff6363 35.93%,
      #b58cb6 55.07%,
      #53537d 76.65%
    )
    2;
`
const BoxUser = styled.div`
  position: absolute;
  right: 5rem;
`
const Image = styled.img`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: gray;
  object-fit: cover;
`

const Head = () => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)

  const user = useSelector(state => state.user)

  const onUserClick = () => {
    setIsOpen(v => !v)
  }

  const onGoHomeClick = () => {
    history.replace(HOME_URL)
  }

  return (
    <Container>
      <Box>
        <ThaiHandText width='150' onClick={() => onGoHomeClick()} />
        <Circle />
        <Text>ADMIN</Text>
        <BoxUser>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={onUserClick}
          >
            <Image src={user.image || ''} />
            <TextLabel>{user.firstName || ''}</TextLabel>
          </div>
        </BoxUser>
      </Box>
      {isOpen && <PopupUser handleClose={onUserClick} left='0px' user={user} />}
    </Container>
  )
}

export default Head
