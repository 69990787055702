import React, { useState, useEffect } from 'react'

import DataStore from './DataStore'
import DataTimeOpenClose from './DataTimeOpenClose'
import Head from './Head'
import api from '../../api'
import Button3 from '../../atoms/Button3'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  const [dataStore, setDataStore] = useState('')
  const [activeButton, setActiveButton] = useState('ข้อมูลร้าน')

  useEffect(() => {
    callApiStore()
  }, [])

  const callApiStore = async () => {
    try {
      const { data } = await api.getStore()
      setDataStore(data)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Page>
      <Head />
      <div style={{ display: 'flex', width: 'calc(100% - 0.5rem)' }}>
        <Button3
          padding='0.5rem 0.5rem'
          width='fit-content'
          backgroundColor='#FFFFFF'
          color='#475467'
          active={activeButton === 'ข้อมูลร้าน'}
          onClick={() => setActiveButton('ข้อมูลร้าน')}
        >
          ข้อมูลร้าน
        </Button3>
        <div style={{ marginRight: '0.5rem' }}></div>
        <Button3
          padding='0.5rem 0.5rem'
          width='fit-content'
          backgroundColor='#FFFFFF'
          color='#475467'
          active={activeButton === 'เวลาเปิด/ปิดร้าน'}
          onClick={() => setActiveButton('เวลาเปิด/ปิดร้าน')}
        >
          เวลา เปิด/ปิด ร้าน
        </Button3>
      </div>
      {activeButton === 'ข้อมูลร้าน' ? (
        <DataStore dataStore={dataStore} />
      ) : (
        <DataTimeOpenClose />
      )}
    </Page>
  )
}

export default Main
