import { useEffect, useState } from 'react'

//@ts-ignore
import api from '../../../api'
import auth from '../../../auth'
import { Product } from '../../../redux/models/Product'
import { ProductGroup } from '../../../redux/models/ProductGroup'
//@ts-ignore
import utils from '../../../utils'

interface UseCardAddProductProps {
  productGroup: ProductGroup[]
  currentEditId: number
  currentEditData: Product
}

interface ProductState extends Omit<Product, '_id'> {
  productGroup: { value: number; label: string }
  productGroupOptionOpen: boolean
  addQuantity: number
  removeQuantity: number
}

interface FileImage {
  name: string
  size: number
  type: string
}

const useCardAddProduct = ({
  productGroup,
  currentEditId,
  currentEditData,
}: UseCardAddProductProps) => {
  const [success, setSuccess] = useState<Boolean>(false)
  const [image, setImage] = useState<string>('')
  const [fileImage, setFileImage] = useState<FileImage | null>(null)
  const [product, setProduct] = useState<ProductState>({
    headGroupId: 2,
    groupId: -1,
    name: '',
    description: '',
    image: '',
    price: 0,
    cost: 0,
    inStock: true,
    number: 0,
    remainingValue: 0,
    status: 1,
    storeId: -1,
    productGroup: {
      value: productGroup[0]._id || -1,
      label: productGroup[0].groupName || '',
    },
    productGroupOptionOpen: false,
    addQuantity: 0,
    removeQuantity: 0,
  })
  const [check, setCheck] = useState({ name: false, price: false })

  useEffect(() => {
    if (currentEditId !== -1) {
      const findProductGroup = productGroup.find(
        g => g._id === currentEditData.groupId
      )

      setProduct({
        ...currentEditData,
        productGroup: {
          value: findProductGroup ? findProductGroup._id : -1,
          label: findProductGroup ? findProductGroup.groupName : '',
        },
        productGroupOptionOpen: false,
        addQuantity: 0,
        removeQuantity: 0,
      })
      if (currentEditData.image) setImage(currentEditData?.image)
    }
  }, [productGroup, currentEditId, currentEditData])

  const onChangeImage = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const files = e.target.files

    if (files && files.length > 0) {
      const file = files[0]
      if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
        alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
      } else if (utils.isFileExceedLimit(file)) {
        alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
      } else {
        setFileImage(file)
        const { image } = await utils.readImage(file)
        setImage(image)
      }
    }
  }

  const onToggle = (value?: boolean) => {
    setProduct(prev => ({
      ...prev,
      productGroupOptionOpen:
        value === undefined ? !product.productGroupOptionOpen : value,
    }))
  }

  const onChange = (e: any, name: string) => {
    if (name === 'productGroup') {
      setProduct(prev => ({
        ...prev,
        productGroupOptionOpen: false,
        productGroup: e,
      }))
    } else if (name === 'status') {
      setProduct({ ...product, status: product.status === 1 ? -1 : 1 })
    } else {
      const value: string = e.target.value
      if (
        name === 'price' ||
        name === 'cost' ||
        name === 'addQuantity' ||
        name === 'removeQuantity'
      ) {
        if (utils.checkNumber(value)) {
          setProduct(prev => ({
            ...prev,
            [name]: value,
          }))
        }
      } else {
        setProduct(prev => ({
          ...prev,
          [name]: value,
        }))
      }
    }
  }

  const onSaveClick = async () => {
    try {
      if (!product.name || !product.price) {
        if (!product.name) {
          setCheck(prev => ({
            ...prev,
            name: true,
          }))
        }
        if (!product.price) {
          setCheck(prev => ({
            ...prev,
            price: true,
          }))
        }

        return
      } else {
        setCheck({ name: false, price: false })
      }

      let newProduct: Omit<Product, '_id'> = {
        headGroupId: 2,
        groupId: product.productGroup.value,
        name: product.name,
        description: '',
        image: '',
        price: product.price,
        cost: product.cost,
        inStock: true,
        number: product.number || 0,
        remainingValue:
          (product.remainingValue || 0) +
          ((product.addQuantity || 0) - (product.removeQuantity || 0)),
        status: 1,
        storeId: auth.getStoreId(),
      }

      if (fileImage) {
        const { data } = await api.uploadImageProduct({
          token: auth.getToken(),
          image: fileImage,
        })
        newProduct = { ...newProduct, image: data }
      }

      if (currentEditId === -1) {
        await callApiCreateProduct(newProduct)
      } else {
        await callApiUpdateProduct(newProduct)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateProduct = async (create: Omit<Product, '_id'>) => {
    try {
      const { success, data } = await api.createProduct(create)
      if (success) {
        const stockSuccess = await callApiCreateStockProduct(data._id)
        if (stockSuccess && success) {
          setSuccess(success)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateProduct = async (update: Omit<Product, '_id'>) => {
    try {
      const { success } = await api.updateProduct(currentEditId, update)
      if (success) {
        const stockSuccess = await callApiCreateStockProduct(currentEditId)
        if (stockSuccess && success) {
          setSuccess(success)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateStockProduct = async (productId: number) => {
    try {
      const { success: stockSuccess } = await api.createStockProduct({
        productId: productId,
        quantity: (product.addQuantity || 0) - (product.removeQuantity || 0),
        currentQuantity: 0,
        newQuantity: 0,
        date: new Date(),
        refBookingId: -1,
        type:
          (product.addQuantity || 0) - (product.removeQuantity || 0) >= 0
            ? 1
            : 2,
        storeId: auth.getStoreId(),
      })

      return stockSuccess
    } catch (err) {
      console.log(err)
    }
  }

  return {
    product,
    image,
    check,
    success,
    onChangeImage,
    onToggle,
    onChange,
    onSaveClick,
  }
}

export default useCardAddProduct
