import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled, { CSSProperties } from 'styled-components'

import Option from '@/redux/models/Option'

//@ts-ignore
import PopupConfirmCustomer from './PopupConfirmCustomer'
import useCustomerForm from './useCustomerForm'

import CloseIcon from '../../atoms/ClosePopup'
import FemaleIcon from '../../atoms/FemaleIcon'
import InputText from '../../atoms/InputText'
import LgbtIcon from '../../atoms/LgbtIcon'
import MaleIcon from '../../atoms/MaleIcon'
//@ts-ignore
import Scrollbar from '../../atoms/Scrollbar'
import SearchIcon from '../../atoms/SearchIcon'
import TextLabel from '../../atoms/TextLabel'
//@ts-ignore
import TickIcon from '../../atoms/TickIcon'

import EditIcon from '../../lib/icons/EditIcon'

//@ts-ignore
import DateSelector from '../../components/datePicker/DateSelector'
import SelectorAge from '../../components/selector/SelectorAge'
import SelectorNationality from '../../components/selector/SelectorNationality'
import useOnClickOutside from '../../hook/useOnClickOutside'
import { Genders } from '../../redux/models/Customer'
import utils from '../../utils'

interface CustomerFormStyle extends CSSProperties {
  active?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const GridBox = styled.div<CustomerFormStyle>`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => props.gap || '0.5rem'};
`

const Box = styled.div<CustomerFormStyle>`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0.25rem'};
  position: ${props => props.position || 'unset'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
  border: ${props => props.border || 'unset'};
  background-color: ${props => props.backgroundColor || 'unset'};
  padding: ${props => props.padding || '0'};
  border-radius: ${props => props.borderRadius || '0'};
`

const BoxButton = styled.div<CustomerFormStyle>`
  background: ${props => props.background || '#ffffff'};
  border: ${props =>
    props.active ? props.border || '2px solid #8C56D9' : '1px solid #e4e8eb'};
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${props => props.boxShadow || ''};
  color: ${props => props.color || '#FFFFFF'};
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
`

const BoxOptions = styled.div<CustomerFormStyle>`
  display: inline-block;
  border: 2px solid #e4e8eb;
  border-radius: 8px;
  background: #fff;
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  z-index: 3;
  min-height: ${props => props.height || '120px'};
  max-height: 120px;
  width: 100%;
  position: absolute;
  top: 2.5rem;
`

const BoxOption = styled.div<CustomerFormStyle>`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #475467;
  background: ${props => props.backgroundColor || 'none'};
  cursor: pointer;
  transition: all 0.5s;
  :first-child {
    border-radius: 8px 8px 0 0;
  }
  :last-child {
    border-radius: 0 0 8px 8px;
  }
  &:only-child {
    border-radius: 8px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }
  :hover {
    cursor: pointer;
    background-color: #e4e8eb;
  }
  ::selection {
    color: #475467;
    background-color: #e4e8eb;
  }
`

interface CustomerFormProps {
  index?: number
  centerHeader?: boolean
  showBirthday?: boolean
  showAddress?: boolean
  showTaxIDNumber?: boolean
  showEmail?: boolean
}

const CustomerForm = ({
  index = 0,
  centerHeader = false,
  showBirthday = false,
  showAddress = false,
  showTaxIDNumber = false,
  showEmail = false,
}: CustomerFormProps) => {
  const { t } = useTranslation()

  const {
    searchTerm,
    optionCustomers,
    editData,
    foundCustomer,
    customerOptionOpen,
    hasMoreCustomer,
    ageOptionOpen,
    nationalityOptionOpen,
    confirmCustomerPopupOpen,
    customer,
    orders,
    onCustomerSelect,
    onSearchChange,
    onChange,
    onOptionClick,
    onToggle,
    onClear,
    onConfirmCustomer,
    loadMoreCustomers,
  } = useCustomerForm(index)

  const inputRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(inputRef, () => {
    onToggle('customerOptionOpen', false)
  })

  return (
    <Container>
      <GridBox columns='1fr 2fr'>
        <TextLabel
          fontWeight='500'
          color={orders[0].check ? 'red' : ''}
          // textAlign={centerHeader ? 'center' : ''}
        >
          {t('customerDetails')}
        </TextLabel>
        <Box flexDirection='column' position='relative' ref={inputRef}>
          <InputText
            icon={<SearchIcon size='15' />}
            placeholder={t('name/phoneNumber') + ''}
            onChange={e => onSearchChange(e.target.value)}
            onFocus={() =>
              !!optionCustomers.length && onToggle('customerOptionOpen', true)
            }
            value={searchTerm}
          />
          {customerOptionOpen && (
            <BoxOptions>
              <Scrollbar
                id='home-cardCustomer1'
                height={
                  optionCustomers.length === 1
                    ? '60px'
                    : optionCustomers.length === 2
                    ? '90px'
                    : '120px'
                }
              >
                <InfiniteScroll
                  dataLength={optionCustomers.length}
                  hasMore={hasMoreCustomer}
                  next={loadMoreCustomers}
                  loader={<h4>Loading ... </h4>}
                  scrollableTarget='home-cardCustomer2'
                >
                  <TextLabel
                    color='#98A1B2'
                    fontSize='0.875rem'
                    paddingLeft='0.25rem'
                  >
                    {t('repeatedCustomer')}
                  </TextLabel>
                  {optionCustomers.map((customer, indexOption) => (
                    <BoxOption
                      key={indexOption}
                      onClick={() => onCustomerSelect(customer)}
                    >
                      {`${utils.formatPhoneNumber(
                        customer.phoneNumber || ''
                      )} ${customer.name || customer.firstName}`}
                    </BoxOption>
                  ))}
                </InfiniteScroll>
              </Scrollbar>
            </BoxOptions>
          )}
        </Box>
      </GridBox>
      <GridBox
        columns={customer._id !== -1 ? '1fr 1fr 0.25fr 0.25fr' : '1fr 1fr'}
      >
        <InputText
          placeholder={t('phoneNumber') + ''}
          value={customer.phoneNumber}
          readOnly={customer._id !== -1 && !editData}
          background={customer._id === -1 || editData ? '' : '#F4F5F7'}
          onChange={val =>
            utils.checkNumber(val.target.value)
              ? onChange(val.target.value, 'phoneNumber')
              : ''
          }
        />
        <InputText
          placeholder={t('nickname') + ''}
          value={customer.name}
          readOnly={customer._id !== -1 && !editData}
          background={customer._id === -1 || editData ? '' : '#F4F5F7'}
          onChange={val => onChange(val.target.value, 'name')}
        />
        {customer._id !== -1 && (
          <>
            <Box
              style={{ cursor: 'pointer' }}
              onClick={() => onToggle('editData')}
            >
              <BoxButton>
                {editData ? (
                  <TickIcon color='#32D582' size='18' />
                ) : (
                  <EditIcon color='#8C56D9' size='18' />
                )}
              </BoxButton>
            </Box>
            <Box style={{ cursor: 'pointer' }} onClick={() => onClear()}>
              <BoxButton>
                <CloseIcon backgroundColor='#DB2D1E' />
              </BoxButton>
            </Box>
          </>
        )}
      </GridBox>
      <GridBox columns='1fr 1fr'>
        <InputText
          placeholder={t('firstName') + ''}
          value={customer.firstName}
          readOnly={customer._id !== -1 && !editData}
          background={customer._id === -1 || editData ? '' : '#F4F5F7'}
          onChange={val => onChange(val.target.value, 'firstName')}
        />
        <InputText
          placeholder={t('lastName') + ''}
          value={customer.lastName}
          readOnly={customer._id !== -1 && !editData}
          background={customer._id === -1 || editData ? '' : '#F4F5F7'}
          onChange={val => onChange(val.target.value, 'lastName')}
        />
      </GridBox>
      <GridBox columns='1fr 1fr'>
        {showTaxIDNumber && (
          <InputText
            placeholder={t('taxpayerIdentificationNo') + ''}
            value={customer.taxIDNumber}
            readOnly={customer._id !== -1 && !editData}
            background={customer._id === -1 || editData ? '' : '#F4F5F7'}
            onChange={val => onChange(val.target.value, 'taxIDNumber')}
          />
        )}
        {showEmail && (
          <InputText
            placeholder={t('email') + ''}
            value={customer.email}
            readOnly={customer._id !== -1 && !editData}
            background={customer._id === -1 || editData ? '' : '#F4F5F7'}
            onChange={val => onChange(val.target.value, 'email')}
          />
        )}
      </GridBox>
      {showBirthday && (
        <DateSelector
          width='100%'
          name='birthDay'
          date={customer.birthDay}
          placeholder={t('birthday')}
          readOnly={customer._id !== -1 && !editData}
          backgroundColor={customer._id !== -1 && !editData ? '#F4F5F7' : ''}
          onChange={onChange}
        />
      )}
      {showAddress && (
        <InputText
          placeholder={t('address') + ''}
          value={customer.address}
          readOnly={customer._id !== -1 && !editData}
          background={customer._id === -1 || editData ? '' : '#F4F5F7'}
          onChange={val => onChange(val.target.value, 'address')}
        />
      )}

      <GridBox columns='1fr 1fr 1fr'>
        <Box>
          {[
            { id: Genders.Male, icon: <MaleIcon /> },
            { id: Genders.Female, icon: <FemaleIcon /> },
            { id: Genders.Other, icon: <LgbtIcon /> },
          ].map(({ id, icon }) => (
            <BoxButton
              key={id}
              background={
                customer._id === -1 ? '' : editData ? '' : 'rgb(240, 242, 245)'
              }
              active={customer.gender === id}
              onClick={e =>
                customer._id === -1 || editData
                  ? onChange(customer.gender === id ? -1 : id, 'gender')
                  : e.preventDefault()
              }
            >
              {icon}
            </BoxButton>
          ))}
        </Box>
        <SelectorAge
          name='age'
          open={ageOptionOpen}
          option={customer.age as Option}
          readOnly={customer._id === -1 ? false : !editData}
          onOptionClick={onOptionClick}
          onClick={value => onToggle('ageOptionOpen', value)}
        />
        <SelectorNationality
          name='nationality'
          option={customer.nationality}
          open={nationalityOptionOpen}
          readOnly={customer._id === -1 ? false : !editData}
          onOptionClick={onOptionClick}
          onClick={value => onToggle('nationalityOptionOpen', value)}
        />
      </GridBox>
      <InputText
        placeholder={t('extraRequest') + ''}
        value={customer.specialRequest}
        onChange={val => onChange(val.target.value, 'specialRequest')}
      />
      {confirmCustomerPopupOpen && (
        <PopupConfirmCustomer
          customer={foundCustomer}
          onConfirm={onConfirmCustomer}
          handleClose={() => onToggle('confirmCustomerPopupOpen', false)}
        />
      )}
    </Container>
  )
}

export default CustomerForm
