import React from 'react'
import styled from 'styled-components'

import SpaCard from './'

import ClosePopup from '../../atoms/ClosePopup'
//@ts-ignore
import PopupBox from '../../atoms/Popup'
//@ts-ignore
import PopupInsideBox from '../../atoms/PopupInside'

import { BookingHistory } from '../../redux/models/BookingHistory'

const CloseBox = styled.div`
  margin-left: auto;
  width: fit-content;
`

export interface PopupSpaCardProps {
  bookingHistory: BookingHistory
  handleClose: () => void
}

const PopupSpaCard = ({ bookingHistory, handleClose }: PopupSpaCardProps) => {
  return (
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='fit-content'
        height='auto'
        padding='2rem 1rem 1rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
      >
        <CloseBox className='pl-2 pr-2' onClick={() => handleClose()}>
          <ClosePopup />
        </CloseBox>
        <SpaCard bookingHistory={bookingHistory} />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupSpaCard
