import dayjs from 'dayjs'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { CSSProperties } from 'styled-components'

import useCardSummary from './useCardSummary'

//@ts-ignore
import Button2 from '../../../atoms/Button2'
import CreditIcon from '../../../atoms/CreditIcon'
import PayIcon from '../../../atoms/PayIcon'
import SaveIcon from '../../../atoms/SaveIcon'
//@ts-ignore
import Scrollbar from '../../../atoms/Scrollbar'
import TextLabel from '../../../atoms/TextLabel'

import { BookOrder } from '../../../redux/models/BookOrder'
import utils from '../../../utils'

interface CardSummaryStyle extends CSSProperties {
  backgroundColorHover?: string
}

const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 1.5rem 0.75rem;
  background: #ffffff;
  flex: 1;
  overflow: hidden;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
`
const Box = styled.div<CardSummaryStyle>`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const BoxStyle = styled.div<CardSummaryStyle>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex: ${props => props.flex || '1 0 auto'};
  max-width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const Line = styled.div`
  border-bottom: 1px solid #e5eced;
  margin: 0.5rem 0;
  width: 100%;
  height: 1px;
`
const CouponContainer = styled.div`
  width: 100%;
  display: flex;
`
const CouponCard = styled.div<CardSummaryStyle>`
  background: ${props => props.background || '#F8F9FB'};
  border-radius: 4px;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  width: 100%;
`
const Circle = styled.div<CardSummaryStyle>`
  background: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  /* top: 50%; */
  transform: translateY(-50%);
  left: ${props => props.left || ''};
  right: ${props => props.right || ''};
`
const LineCoupon = styled.div`
  border-bottom: 1px dashed #e5eced;
  width: calc(100% - 2rem);
  position: absolute;
  /* top: 50%; */
  transform: translateY(-50%);
`
const ImageBox = styled.div<CardSummaryStyle>`
  position: relative;
  border-radius: 8px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  padding: ${props => props.padding || '0'};
  border: ${props => props.border || 'none'};
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  width: 100%;
  color: #fff;
  &:hover {
    text-decoration: none;
  }
`
const BoxStatus = styled.div`
  border: 1px solid #e4e8eb;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  bottom: 110%;
`
const BoxStatusStyled = styled.div<CardSummaryStyle>`
  border-bottom: 1px solid #e4e8eb;
  color: ${props => props.color || '#32D582'};
  padding: 0.5rem;

  :last-child {
    border-bottom: none;
  }
  :hover {
    background-color: ${props => props.backgroundColorHover || '#32d582'};
  }
`

const BoxSticky = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  position: sticky;
  bottom: 0rem;
  background: white;
  z-index: 2;
`

const BoxSave = styled.div<CardSummaryStyle>`
  display: flex;
  width: ${props => props.width || '100%'};
  position: relative;
`

const GridBox = styled.div<CardSummaryStyle>`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => props.gap || '0.5rem'};
`

interface CArdSummaryProps {
  bookingOrderState: BookOrder
}

const CArdSummary = ({ bookingOrderState }: CArdSummaryProps) => {
  const { t } = useTranslation()

  const { date, time, bookingCode, total, products } = bookingOrderState

  const { services, onSaveClick } = useCardSummary({ bookingOrderState })

  return (
    <Container>
      <Box>
        <TextLabel fontWeight='500' fontSize='1.2rem'>
          {t('reservationDetails')}
        </TextLabel>
      </Box>
      <Box justifyContent='space-between' borderBottom='none'>
        <BoxStyle>
          <TextLabel color='#98A1B2' fontSize='0.875rem'>{`${dayjs(date).format(
            'DD MMMM YYYY'
          )} ${utils.formatTime(time.hour + '')}.${utils.formatTime(
            time.minute + ''
          )}น.`}</TextLabel>
        </BoxStyle>
        <BoxStyle>
          <TextLabel
            color='#475467'
            fontSize='0.875rem'
          >{`#Order : ${utils.formatBookingCode(bookingCode)}`}</TextLabel>
        </BoxStyle>
      </Box>
      <Line />
      <Scrollbar id='home-cardBill'>
        <TextLabel color='#98A1B2' fontWeight='500'>
          {t('service')}
        </TextLabel>
        {services.map(
          ({ serviceName, serviceImage, price, count, time }, index) => (
            <Fragment key={index}>
              <Box flexDirection='row'>
                <BoxStyle flex='1 0 25%' width='25%' alignItems='center'>
                  <ImageBox background={serviceImage} />
                </BoxStyle>
                <BoxStyle
                  flexDirection='column'
                  flex='1 0 75%'
                  width='75%'
                  justifyContent='center'
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TextLabel fontWeight='600' fontSize='0.875rem'>
                      {`${serviceName} X ${count}`}
                    </TextLabel>
                    <TextLabel
                      fontWeight='600'
                      color='#8C56D9'
                      fontSize='0.875rem'
                    >{`${utils.formatNumber(
                      (price || 1) * (count || 1) || 0,
                      2
                    )}`}</TextLabel>
                  </div>

                  <TextLabel
                    fontWeight='600'
                    color='#C8CDD7'
                    fontSize='0.875rem'
                  >
                    {`${time} ${t('mins')}`}
                  </TextLabel>
                </BoxStyle>
              </Box>
              <Line></Line>
            </Fragment>
          )
        )}

        {products.map(({ name, price, image, quantity }, index) => (
          <Fragment key={index}>
            <Box flexDirection='row'>
              <BoxStyle flex='1 0 25%' width='25%' alignItems='center'>
                <ImageBox background={image} />
              </BoxStyle>
              <BoxStyle
                flexDirection='column'
                flex='1 0 75%'
                width='75%'
                justifyContent='center'
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextLabel fontWeight='600' fontSize='0.875rem'>
                    {`${name} X ${quantity}`}
                  </TextLabel>
                  <TextLabel
                    fontWeight='600'
                    color='#8C56D9'
                    fontSize='0.875rem'
                  >{`${utils.formatNumber(
                    (price || 1) * (quantity || 1) || 0,
                    2
                  )}`}</TextLabel>
                </div>
              </BoxStyle>
            </Box>
            <Line></Line>
          </Fragment>
        ))}
        <BoxSticky>
          <GridBox columns='1fr 1fr'>
            <TextLabel fontWeight='500' color='#8C56D9' fontSize='1.25rem'>
              ราคารวม
            </TextLabel>
            <TextLabel
              textAlign='end'
              fontWeight='500'
              color='#8C56D9'
              fontSize='1.25rem'
            >
              {utils.formatNumber(total, 2)}
            </TextLabel>
          </GridBox>
          <GridBox columns='1fr 1fr'>
            <Button2
              border='1px solid #8C56D9'
              color='#8C56D9'
              backgroundColor='#FFF'
              icon={<SaveIcon color='#8C56D9' size='15' />}
              onClick={() => onSaveClick()}
            >
              {t('save')}
            </Button2>
            <Button2
              color='#FFF'
              backgroundColor='linear-gradient(221.86deg, #8C56D9 2.71%, #53389E 97.79%)'
              icon={<CreditIcon color='#FFFFFF' size='18' />}
            >
              ชำระเงิน
            </Button2>
          </GridBox>
        </BoxSticky>
      </Scrollbar>
    </Container>
  )
}

export default CArdSummary
