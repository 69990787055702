import React, { useEffect } from 'react'
import styled, { CSSProperties } from 'styled-components'

import useCardAddProduct from './useCardAddProduct'

//@ts-ignore
import BoxStatus from '../../../atoms/BoxStatus'
import CameraIcon from '../../../atoms/CameraIcon'
import InputText from '../../../atoms/InputText'
//@ts-ignore
import Scrollbar from '../../../atoms/Scrollbar'
//@ts-ignore
import SuccessIcon2 from '../../../atoms/SuccessIcon2'
import TextLabel from '../../../atoms/TextLabel'
import UploadImageIcon from '../../../atoms/UploadImageIcon'
import ButtonSettingSave from '../../../molecules/ButtonSettingSave'

import { Product } from '../../../redux/models/Product'
import { ProductGroup } from '../../../redux/models/ProductGroup'
import SelectorProductGroup from '../../selector/SelectorProductGroup'

interface CardAddProductStyled extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`
const Card = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

const ImageBox = styled.div`
  border-radius: 16px;
  background: #e4e8eb;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  margin: 0 auto;
`
const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`
const Image = styled.img`
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  object-fit: cover;
`
const FlexBox = styled.div<CardAddProductStyled>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  gap: ${props => props.gap || '0'};
`
const GridBox = styled.div<CardAddProductStyled>`
  display: grid;
  grid-template-columns: ${props => props.columns || '1fr 1fr'};
  width: 100%;
  gap: 0.5rem;
`
const Box = styled.div<CardAddProductStyled>`
  border-radius: 8px;
  border-color: ${props => props.borderColor || '#000'};
  border-width: ${props => props.borderWidth || '1px'};
  border-style: solid;
  color: ${props => props.color || '#00ff11'};
  display: flex;
  align-items: center;
`

interface CardAddProductProps {
  handleClose: (close: boolean) => void
  productGroup: ProductGroup[]
  currentEditId: number
  currentEditData: Product
}

const CardAddProduct = ({
  handleClose,
  productGroup,
  currentEditId,
  currentEditData,
}: CardAddProductProps) => {
  const {
    product,
    image,
    check,
    success,
    onChangeImage,
    onToggle,
    onChange,
    onSaveClick,
  } = useCardAddProduct({
    productGroup,
    currentEditId,
    currentEditData,
  })

  useEffect(() => {
    if (success) {
      if (success) {
        setTimeout(() => {
          handleClose(false)
        }, 1000)
      }
    }
  }, [success])

  return (
    <Container>
      <Card>
        <Scrollbar id='setting-cardAddProduct' gap='0.5rem'>
          <div className='pt-2'>
            <ImageBox>
              <CameraIcon size='40' />
              <ImageUploadIcon>
                <label htmlFor='upload-image'>
                  <UploadImageIcon
                    size='30'
                    colorCircle='#8C56D9'
                    colorImage='white'
                  />
                </label>
                <input
                  id='upload-image'
                  type='file'
                  onChange={e => onChangeImage(e)}
                  style={{ display: 'none', visibility: 'hidden' }}
                />
              </ImageUploadIcon>
              {image && <Image src={image} />}
            </ImageBox>
          </div>
          <GridBox>
            <FlexBox>
              <TextLabel>ชื่อสินค้า*</TextLabel>
              <InputText
                placeholder='ชื่อสินค้า'
                onChange={e => onChange(e, 'name')}
                value={product.name}
                error={check.name}
              />
            </FlexBox>
            <FlexBox>
              <TextLabel>กลุ่มสินค้า*</TextLabel>
              <SelectorProductGroup
                name='productGroup'
                option={product.productGroup}
                open={product.productGroupOptionOpen}
                onOptionClick={option => onChange(option, 'productGroup')}
                onClick={onToggle}
                error={false}
                readOnly={false}
              />
            </FlexBox>
          </GridBox>
          <GridBox>
            <FlexBox>
              <TextLabel>ราคา*</TextLabel>
              <InputText
                placeholder='ราคา'
                onChange={e => onChange(e, 'price')}
                value={+product.price}
                error={check.price}
              />
            </FlexBox>
            <FlexBox>
              <TextLabel>ต้นทุนสินค้า</TextLabel>
              <InputText
                placeholder='ต้นทุนสินค้า'
                onChange={e => onChange(e, 'cost')}
                value={+product.cost}
              />
            </FlexBox>
          </GridBox>
          <GridBox>
            <FlexBox>
              <TextLabel>เพิ่มจำนวนสินค้า</TextLabel>
              <InputText
                placeholder='เพิ่มจำนวนสินค้า'
                onChange={e => onChange(e, 'addQuantity')}
                value={+product.addQuantity}
                color='green'
              />
            </FlexBox>
            <FlexBox>
              <TextLabel>ลดจำนวนสินค้า</TextLabel>
              <InputText
                placeholder='ลดจำนวนสินค้า'
                onChange={e => onChange(e, 'removeQuantity')}
                value={+product.removeQuantity}
                color='red'
              />
            </FlexBox>
          </GridBox>
          <FlexBox flexDirection='row' gap='0.5rem'>
            <TextLabel>จำนวนสินค้าคงเหลือ :</TextLabel>
            <FlexBox flexDirection='row' gap='0.25rem'>
              <TextLabel>{`${
                (product.remainingValue || 0) +
                (+product.addQuantity || 0) -
                (+product.removeQuantity || 0)
              }`}</TextLabel>
              <TextLabel
                color={
                  product.addQuantity - product.removeQuantity >= 0
                    ? 'green'
                    : 'red'
                }
              >{` ${
                product.addQuantity || 0 || (product.removeQuantity || 0) !== 0
                  ? `(${
                      product.addQuantity - product.removeQuantity >= 0
                        ? '+'
                        : ''
                    }${product.addQuantity - product.removeQuantity})`
                  : ''
              }`}</TextLabel>
            </FlexBox>
          </FlexBox>

          {/* {currentEditId !== -1 && (
            <FlexBox flexDirection='row' gap='0.5rem'>
              <TextLabel fontWeight='500'>จำนวนสินค้าคงเหลือ :</TextLabel>
              <TextLabel>
                {(currentEditData.remainingValue || 0) +
                  (product.addQuantity || 0) -
                  (product.removeQuantity || 0)}
              </TextLabel>
            </FlexBox>
          )} */}
          <BoxStatus
            title='สถานะ'
            detail='ไม่ใช้งาน/ใช้งาน'
            status={product.status === 1}
            onChange={() => onChange('', 'status')}
            id='stats-product'
          />
          <ButtonSettingSave
            handleClose={() => handleClose(true)}
            onSaveClick={onSaveClick}
          />
        </Scrollbar>
      </Card>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddProduct
