import { useCallback, useRef, useState, useEffect } from 'react'

type FieldValues = Record<string, any>

type RegisterOptions = {
  required?: boolean | string
  pattern?: RegExp
  minLength?: number
  maxLength?: number
  validate?: (value: any) => boolean | string
}

type FieldErrors = Record<string, string>

type UseFormReturn = {
  register: (
    name: string,
    options?: RegisterOptions
  ) => {
    name: string
    value: any
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onValueChange: (value: any) => void
    onBlur: () => void
    required: boolean
    error: string | null
  }
  handleSubmit: (
    onSubmit: (data: FieldValues) => void
  ) => (e: React.FormEvent) => void
  formState: {
    errors: FieldErrors
    isSubmitting: boolean
    isDirty: boolean
    isValid: boolean
  }
  getValues: () => FieldValues
  setValue: (name: string, value: any, shouldValidate?: boolean) => void
  values: FieldValues
  trigger: (name?: string) => Promise<boolean>
  resetForm: () => void
}

const useForm = (persistKey?: string): UseFormReturn => {
  const [values, setValues] = useState<FieldValues>(() => {
    if (persistKey) {
      const savedValues = localStorage.getItem(`form_${persistKey}`)
      return savedValues ? JSON.parse(savedValues) : {}
    }
    return {}
  })
  const [errors, setErrors] = useState<FieldErrors>({})
  const [touchedFields, setTouchedFields] = useState<Set<string>>(new Set())
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const fieldsRef = useRef<Record<string, RegisterOptions>>({})

  useEffect(() => {
    if (persistKey) {
      localStorage.setItem(`form_${persistKey}`, JSON.stringify(values))
    }
  }, [persistKey, values])

  const validateField = (
    name: string,
    value: any,
    options?: RegisterOptions
  ): string | null => {
    if (options?.required) {
      const isEmptyValue = value === '' || value === null || value === undefined
      if (isEmptyValue) {
        return typeof options.required === 'string'
          ? options.required
          : 'This field is required'
      }
    }
    if (options?.pattern && !options.pattern.test(value)) {
      return 'Invalid format'
    }
    if (options?.minLength && value.length < options.minLength) {
      return `Minimum length is ${options.minLength}`
    }
    if (options?.maxLength && value.length > options.maxLength) {
      return `Maximum length is ${options.maxLength}`
    }
    if (options?.validate) {
      const validateResult = options.validate(value)
      if (typeof validateResult === 'string') {
        return validateResult
      } else if (!validateResult) {
        return 'Invalid value'
      }
    }
    return null
  }

  const validateForm = useCallback(() => {
    const formErrors: FieldErrors = {}
    let isFormValid = true

    Object.keys(fieldsRef.current).forEach(name => {
      const error = validateField(name, values[name], fieldsRef.current[name])
      if (error) {
        formErrors[name] = error
        isFormValid = false
      }
    })

    setErrors(formErrors)
    setIsValid(isFormValid)
    return isFormValid
  }, [values])

  const register = useCallback(
    (name: string, options?: RegisterOptions) => {
      fieldsRef.current[name] = options || {}

      const handleChanges = (value: any) => {
        setValues(prev => ({ ...prev, [name]: value }))
        setIsDirty(true)
        setTouchedFields(prev => new Set(prev).add(name))
        const error = validateField(name, value, options)
        setErrors(prev => ({ ...prev, [name]: error } as FieldErrors))
      }

      return {
        name,
        value: values[name] || '',
        onChange: (e: { target: { value: any } }) =>
          handleChanges(e.target.value),
        onValueChange: handleChanges,
        onBlur: () => {
          setTouchedFields(prev => new Set(prev).add(name))
        },
        required: !!options?.required,
        error: errors[name] || null,
      }
    },
    [errors, values]
  )

  const handleSubmit = useCallback(
    (onSubmit: (data: FieldValues) => void) => async (e: React.FormEvent) => {
      e.preventDefault()
      setIsSubmitting(true)

      const isValid = validateForm()

      if (isValid) {
        await onSubmit(values)
      }

      setIsSubmitting(false)
    },
    [values, validateForm]
  )

  const getValues = useCallback(() => values, [values])

  const setValue = useCallback(
    (name: string, value: any, shouldValidate: boolean = true) => {
      setValues(prev => ({ ...prev, [name]: value }))
      setIsDirty(true)
      if (shouldValidate) {
        const error = validateField(name, value, fieldsRef.current[name])
        setErrors(prev => ({ ...prev, [name]: error } as FieldErrors))
      }
    },
    []
  )

  const trigger = useCallback(
    async (name?: string): Promise<boolean> => {
      if (name) {
        const error = validateField(name, values[name], fieldsRef.current[name])
        setErrors(prev => ({ ...prev, [name]: error } as FieldErrors))
        return !error
      } else {
        return validateForm()
      }
    },
    [values, validateForm]
  )

  const resetForm = useCallback(() => {
    setValues({})
    setErrors({})
    setTouchedFields(new Set())
    setIsDirty(false)
    setIsValid(true)
    if (persistKey) {
      localStorage.removeItem(`form_${persistKey}`)
    }
  }, [persistKey])

  return {
    register,
    values,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
      isDirty,
      isValid,
    },
    getValues,
    setValue,
    trigger,
    resetForm,
  }
}

export default useForm