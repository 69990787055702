import React from 'react'
import styled, { CSSProperties } from 'styled-components'

interface TimeIconStyle extends CSSProperties {}

const SVG = styled.svg<TimeIconStyle>`
  -webkit-tap-highlight-color: transparent;
  margin: ${props => props.margin || '0 0'};
  &:hover {
    user-select: none;
  }
`

interface TimeIconProps {
  size?: string
  color?: string
  margin?: string
}

const TimeIcon = ({ size, color, margin }: TimeIconProps) => (
  <SVG
    width={size || '29'}
    height={size || '29'}
    margin={margin}
    viewBox='0 0 29 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25.9485 7.63055C25.3479 7.92113 25.0965 8.64389 25.3871 9.24422C26.181 10.8841 26.5833 12.6518 26.5833 14.5C26.5833 21.1626 21.1626 26.5833 14.5 26.5833C7.83735 26.5833 2.41667 21.1626 2.41667 14.5C2.41667 7.83735 7.83735 2.41667 14.5 2.41667C17.2609 2.41667 19.8546 3.3182 22.0013 5.02361C22.522 5.43927 23.2834 5.35224 23.6991 4.82979C24.1147 4.30764 24.0277 3.54712 23.505 3.13205C20.9635 1.11216 17.7654 0 14.5 0C6.50512 0 0 6.50512 0 14.5C0 22.4949 6.50512 29 14.5 29C22.4949 29 29 22.4949 29 14.5C29 12.2845 28.5162 10.1614 27.5622 8.19195C27.2722 7.59014 26.547 7.33939 25.9485 7.63055Z'
      fill={color || '#4E4E72'}
    />

    <path
      d='M14.4998 4.83374C13.8328 4.83374 13.2915 5.37507 13.2915 6.04207V14.5004C13.2915 15.1674 13.8328 15.7087 14.4998 15.7087H20.5415C21.2085 15.7087 21.7498 15.1674 21.7498 14.5004C21.7498 13.8334 21.2085 13.2921 20.5415 13.2921H15.7082V6.04207C15.7082 5.37507 15.1668 4.83374 14.4998 4.83374Z'
      fill={color || '#4E4E72'}
    />
  </SVG>
)

export default TimeIcon
