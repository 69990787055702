export const GET_SERVICE = 'GET_SERVICE'

export const updateService = service => {
  return {
    type: GET_SERVICE,
    payload: {
      service: service,
    },
  }
}

export const CLEAR_SERVICE = 'CLEAR_SERVICE'

export const clearService = service => {
  return {
    type: CLEAR_SERVICE,
    payload: { service },
  }
}
