// import Home from '@/pages/home/Main'
// import Cashier from '@/pages/cashier/Main'
// import SearchHistory from '@/pages/searchHistory/Main'
// import Staffs from '@/pages/staffs/Main'
// import PayWage from '@/pages/payWage/Main'
import SearchHistory from '@/components/pages/searchHistory/searchHistory'
// Refactor Page
import SettingsStaffs from '@/components/pages/settings/staffs/page'
import CashierIcon from '@/lib/icons/CashierIcon'
import HomeIcon from '@/lib/icons/HomeIcon'
import PayWageIcon from '@/lib/icons/PayWageIcon'
import QueueIcon from '@/lib/icons/QueueIcon'
import StaffIcon2 from '@/lib/icons/StaffIcon2'

import SettingIcon from './icons/SettingIcon'

export interface Route {
  id: string
  path: string
  icon?: any
  parent?: string
  permissions?: UserTypes[]
}

export enum UserTypes {
  Admin = 0,
  Owner = 1,
  Manager = 2,
  Cashier = 3,
  General = 4,
}

export interface SidebarRoute extends Route {
  group?: boolean
}

export const routes = [] as Route[]

export const sidebarRoutes = [
  {
    id: 'home',
    path: '/',
    // component: Home,
    icon: HomeIcon,
  },
  {
    id: 'searchHistory',
    path: '/search-history',
    component: SearchHistory,
    icon: QueueIcon,
  },
  {
    id: 'cashier',
    path: '/cashier',
    // component: Cashier,
    icon: CashierIcon,
  },
  {
    id: 'attendance',
    path: '/staff',
    // component: Staffs,
    icon: StaffIcon2,
  },
  {
    id: 'wage',
    path: '/payWage',
    permissions: [
      UserTypes.Owner,
      UserTypes.Manager,
      UserTypes.Admin,
      UserTypes.Cashier,
    ],
    // component: PayWage,
    icon: PayWageIcon,
  },
  {
    id: 'report',
    path: '/report',
    // component: null,
    icon: QueueIcon,
  },
  {
    id: 'setting',
    path: '/settings/store',
    group: true,
    icon: SettingIcon,
  },
  {
    id: 'setting-store',
    path: '/setting-store',
    parent: 'setting',
  },
  {
    id: 'setting-staff',
    path: '/settings/staff',
    parent: 'setting',
    component: SettingsStaffs,
  },
  {
    id: 'setting-promotion',
    path: '/setting-promotion',
    parent: 'setting',
  },
] as SidebarRoute[]
