import React, { useState } from 'react'
import styled from 'styled-components'

import InputText from '../../atoms/InputText'
import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'
import Button2 from '../../atoms/Button2'
import PopupAddStore from '../popups/PopupAddStore'
import auth from '../../auth'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/actions/userAction'
import dayjs from 'dayjs'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
`

const StoreWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 1rem;
`

const StoreContainer = styled.div`
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  background: #ffffff;
`

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0.25rem'};
  padding: ${props => props.padding || '0'};
  overflow: hidden;
  align-items: ${props => props.alignItems || 'unset'};
  margin-top: 1rem;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => props.gap || '0.5rem'};
  width: 100%;
`

const ImageBox = styled.div`
  border-radius: 8px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#E4E8EB'};
  width: 50px;
  height: 50px;
  background-size: cover;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  gap: ${props => props.gap || '0.5rem'};
`

const BodyStore = ({ stores, onUpdate }) => {
  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [currentStore, setCurrentStore] = useState('')

  const history = useHistory()
  const dispatch = useDispatch()

  const filteredList = (stores, search) => {
    return stores.filter(store => {
      if (search) {
        const regExp = new RegExp(search, 'ig')
        return regExp.test(store.name) || regExp.test(store.branch)
      }
      return true
    })
  }

  const onEditStoreClick = _id => {
    const findStore = stores.find(s => s._id === _id)
    if (findStore) {
      setCurrentStore(findStore)
      setIsOpen(true)
    }
  }

  const onSelectStore = data => {
    auth.setStoreId(data._id)
    auth.setBrandId(data.brandId)
    setCurrentStore('')
    setIsOpen(false)
    dispatch(setUser({ storeId: data._id, brandId: data.brandId }))
    history.push('/')
  }

  return (
    <Container>
      <GridBox columns='1fr 1fr'>
        <InputText
          placeholder='ค้นหาร้าน'
          value={search}
          onChange={val => setSearch(val.target.value)}
        />
        <Button2
          backgroundColor='#00B548'
          color='#FFF'
          onClick={() => setIsOpen(true)}
        >
          สร้างร้านเพิ่ม
        </Button2>
      </GridBox>
      <Box>
        <Scrollbar id='admin-bodyStore'>
          <StoreWrapper>
            {filteredList(stores, search).map(
              ({ _id, name, imageStore, branch, brandId, endDate }, index) => (
                <StoreContainer key={index}>
                  <FlexBox alignItems='center'>
                    <ImageBox background={imageStore} />
                    <FlexBox flexDirection='column' gap='0rem'>
                      <TextLabel fontWeight='500'>
                        {name} สาขา {branch || '-'}
                      </TextLabel>
                      <TextLabel fontSize='0.75rem'>
                        Store ID: {_id} | Brand ID: {brandId || '-'} | หมดอายุ:{' '}
                        {endDate ? dayjs(endDate).format('DD/MM/YYYY') : '-'}
                      </TextLabel>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox>
                    <Button2 onClick={() => onSelectStore({ _id, brandId })}>
                      ดูร้าน
                    </Button2>
                    <Button2 onClick={() => onEditStoreClick(_id)}>
                      ⚙️ แก้ไข
                    </Button2>
                  </FlexBox>
                </StoreContainer>
              )
            )}
          </StoreWrapper>
        </Scrollbar>
      </Box>
      {isOpen && (
        <PopupAddStore
          handleClose={() => {
            setIsOpen(false)
            setCurrentStore('')
          }}
          currentStore={currentStore}
          onUpdate={onUpdate}
          onSelectStore={onSelectStore}
        />
      )}
    </Container>
  )
}

export default BodyStore
