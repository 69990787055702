export const GET_CARGO = 'GET_CARGO'

export const getCargo = cargo => {
  return {
    type: GET_CARGO,
    payload: { cargo },
  }
}

export const UPDATE_CARGO = 'UPDATE_CARGO'

export const updateCargo = cargo => {
  return {
    type: UPDATE_CARGO,
    payload: { cargo },
  }
}
