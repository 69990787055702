import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import InputText from '@/atoms/InputText'
import SearchIcon from '@/atoms/SearchIcon'
import Button from '@/components/atoms/Button'
import { Row } from '@/components/atoms/Flex'
import { ClockHistoryIcon } from '@/components/atoms/icons/ClockIcon'
import { EyeIcon } from '@/components/atoms/icons/EyeIcon'
import { FilterIcon } from '@/components/atoms/icons/FilterIcon'
import { PrintIcon } from '@/components/atoms/icons/PrintIcon'
import { XCircleIcon } from '@/components/atoms/icons/XIcon'
import { toast } from '@/components/atoms/Toast'
import DataTable from '@/components/molecules/dataTable/DataTable'
import { FilterButton } from '@/components/molecules/filterButton/FilterButton'
import FilterDate from '@/components/molecules/FilterDate/index'
import SheetDrawer from '@/components/molecules/SheetDrawer'
import { useConfirm } from '@/hook/useAlert'
import useForm from '@/hook/useForm'
import PopupSpaCard from '@/molecules/SpaCard/PopupSpaCard'

import ModalBookingHistory from './ModalBookingHistories'
import { ModalBookingHistoryInfo } from './ModalInfo'
import ModalMiniDetail from './ModalMiniDetail'
import useSearchHistory from './useSearchHistory'

//@ts-ignore
import PopupBillPayMent from '../../../molecules/Receipt/PopupBillPayMent'

import MiniDetail from '../../../components/home/MiniDetail'
// @ts-ignore
import MiniHistory from '../../../components/home/MiniHistory'

const defaultHeaders = [
  {
    key: 'bookingCode',
    label: 'รหัสออร์เดอร์',
  },
  {
    key: 'dateTime',
    label: 'วัน-เวลา',
  },
  {
    key: 'transactionType',
    label: 'ประเภท',
  },
  {
    key: 'name',
    label: 'ชื่อ',
  },
  {
    key: 'phoneNumber',
    label: 'เบอร์โทรศัพท์',
  },
  {
    key: 'services',
    label: 'รายละเอียดบริการ',
  },
  {
    key: 'staffs',
    label: 'พนักงาน',
  },
  {
    key: 'bookingChannel',
    label: 'ช่องทางการจอง',
  },
  {
    key: 'payChannels',
    label: 'ช่องทางการชำระ',
  },
  {
    key: 'total',
    label: 'จำนวนเงิน',
  },
  {
    key: 'payStatus',
    label: 'สถานะการชำระเงิน',
  },
  {
    key: 'status',
    label: 'สถานะบริการ',
  },
  {
    key: 'createBy',
    label: 'สร้าง',
  },
  {
    key: 'editBy',
    label: 'แก้ไขล่าสุด',
  },
]

const SearchHistory = () => {
  const { register, values, setValue } = useForm('filterForm')
  const { t } = useTranslation()
  const {
    search,
    date,
    transactionTypes,
    staffs,
    bookingChannels,
    bookingStatus,
    headers,
    headerFilter,
    hasMore,
    rows,
    openPrint,
    onDateChange,
    onChange,
    onDownloadClick,
    loadMore,
    cancelBooking,
    onPrintClick,
    handleClose,
    popupBookingHistory,
    openPopupBookingHistory,
    closePopupBookingHistory,
    popupBookingInfo,
    openPopupBookingInfo,
    closePopupBookingInfo,
  } = useSearchHistory({ values, mock: false })

  const confirm = useConfirm()

  const handleActionClick = async (key: string, id: string | number) => {
    switch (key) {
      case 'print-spa-card':
        await onPrintClick(key, id)
        break
      case 'print-receipt':
        await onPrintClick(key, id)
        break
      case 'info':
        openPopupBookingInfo(id as number)
        break
      case 'history':
        openPopupBookingHistory(id as number)
        break
      case 'cancel':
        try {
          const booking = rows.find((row: any) => row.id === id)
          const res = await confirm({
            title: 'ยืนยันการยกเลิก',
            message: `คุณต้องการยกเลิกการจอง ${booking?.bookingCode} ใช่หรือไม่`,
            variant: 'primary',
            type: 'error',
          })
          if (res) {
            await cancelBooking(id)
          }
        } catch (err: any) {
          toast.error(err.message)
        }
        break
    }
  }

  useEffect(() => {
    if (!values?.headers) {
      setValue(
        'headers',
        defaultHeaders.map((header: any) => header.key)
      )
    }
  }, [values])

  return (
    <>
      <Row justify='flex-end' gap='8px'>
        <FilterDate
          date={{ startDate: date.startDate, endDate: date.endDate }}
          onDateChange={onDateChange}
          onlyRange
        />
        <Button onClick={() => onDownloadClick()}>Export</Button>
      </Row>
      <Row>
        <Row
          justify='space-between'
          style={{
            flex: 1,
          }}
        >
          {/* <Input placeholder='Search' {...register('search')} /> */}
          <InputText
            placeholder='Search'
            icon={<SearchIcon size='15' />}
            value={search}
            onChange={val => onChange(val.target.value)}
          />
        </Row>
        <Row
          gap='4px'
          style={{
            flex: 5,
            justifyContent: 'flex-end',
          }}
        >
          <FilterButton
            title={t('transactionTypes') as string}
            items={transactionTypes.map((transactionType: any) => ({
              value: transactionType.value,
              key: transactionType.key,
            }))}
            {...register('transactionTypes')}
          />
          <FilterButton
            title={t('staffs') as string}
            items={staffs.map((staff: any) => ({
              value: staff.value,
              key: staff.key,
            }))}
            {...register('staffs')}
          />
          <FilterButton
            title={t('bookingChannels') as string}
            items={bookingChannels.map((bookingChannel: any) => ({
              value: bookingChannel.value,
              key: bookingChannel.key,
            }))}
            {...register('bookingChannels')}
          />
          <FilterButton
            title={t('bookingStatus') as string}
            items={bookingStatus.map((bookingStatus: any) => ({
              value: bookingStatus.value,
              key: bookingStatus.key,
            }))}
            {...register('bookingStatus')}
          />
          <FilterButton
            single
            title={t('payStatus') as string}
            items={[
              { key: '1', value: 'ชำระเงิน' },
              { key: '-1', value: 'ยังไม่ชำระ' },
            ]}
            {...register('payStatus')}
          />
          <FilterButton
            trigger={<FilterIcon />}
            title='headers'
            items={defaultHeaders.map((header: any) => ({
              value: header.label,
              key: header.key,
            }))}
            {...register('headers')}
          />
        </Row>
      </Row>
      <DataTable
        headers={defaultHeaders?.filter((header: any) =>
          values?.headers?.includes(header.key)
        )}
        onItemClick={id => openPopupBookingInfo(parseInt(id?.toString()))}
        data={rows}
        fetchData={loadMore}
        hasMore={hasMore}
        columnOptions={{
          transactionType: {
            type: 'service-badge',
            style: {
              minWidth: '150px',
            },
          },
          services: {
            type: 'list',
          },
          staffs: {
            type: 'list',
          },
          status: {
            type: 'status',
          },
          payStatus: {
            type: 'status',
          },
          createBy: {
            style: {
              minWidth: '150px',
            },
          },
          editBy: {
            style: {
              minWidth: '150px',
            },
          },
        }}
        actions={[
          {
            key: 'print-spa-card',
            label: t('printSpaCard'),
            icon: <PrintIcon />,
          },
          {
            key: 'print-receipt',
            label: t('printReceipt'),
            icon: <PrintIcon />,
          },
          { key: 'info', label: t('readMore'), icon: <EyeIcon /> },
          {
            key: 'history',
            label: t('viewBookingHistory'),
            icon: <ClockHistoryIcon />,
          },
          { key: 'cancel', label: t('cancel'), icon: <XCircleIcon /> },
        ]}
        idColumn='id'
        onActionClick={handleActionClick}
      />

      {openPrint.type === 'print-spa-card' && (
        <PopupSpaCard
          bookingHistory={openPrint?.bookingHistory}
          handleClose={() => handleClose()}
        />
      )}
      {openPrint.type === 'print-receipt' && (
        <PopupBillPayMent
          bookingHistory={openPrint?.bookingHistory}
          receiptNumber={openPrint?.bookingHistory.receiptNumber}
          handleClose={() => handleClose()}
        />
      )}
      <SheetDrawer isOpen={!!popupBookingHistory} onOpenChange={isOpen => !isOpen && closePopupBookingHistory()}>
        <SheetDrawer.Content>
        <ModalBookingHistory bookingHistories={popupBookingHistory?.bookingHistories as any || []} />
        </SheetDrawer.Content>
    </SheetDrawer>
    <SheetDrawer isOpen={!!popupBookingInfo} onOpenChange={isOpen => !isOpen && closePopupBookingInfo()}>
        <SheetDrawer.Content>
          <ModalBookingHistoryInfo info={popupBookingInfo?.information} />
        </SheetDrawer.Content>
        {/* <ModalMiniDetail bookingHistory={popupBookingInfo?.information} handleClose={closePopupBookingHistory} /> */}
      </SheetDrawer>
    </>
  )
}

export default SearchHistory
