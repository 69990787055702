import React from 'react'

// @ts-ignore
import Body from './Body'
// @ts-ignore
import Head from './Head'

import Page from '../../lib/layout/Page/Page'

const Main = () => {
  return (
    <Page>
      <Head />
      <Body />
    </Page>
  )
}

export default Main
