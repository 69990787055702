import React from 'react'

const CheckIcon = ({ size, color }) => (
  <svg
    width={size || '21'}
    height={size || '16'}
    viewBox='0 0 21 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.74348 15.9991C5.68853 15.9991 5.64142 15.9991 5.58647 15.9991C4.9427 15.9598 4.35389 15.6458 3.94564 15.1512L0.514828 10.9117C-0.285957 9.92253 -0.136791 8.47012 0.852414 7.66149C1.84162 6.85285 3.29402 7.00987 4.10266 7.99907L5.97901 10.3072L16.138 0.627141C17.0644 -0.244302 18.5246 -0.205048 19.4039 0.729202C20.2754 1.6556 20.2361 3.12371 19.3019 3.99515L7.32935 15.3789C6.89756 15.7871 6.3323 16.0069 5.74348 16.0069V15.9991Z'
      fill={color || '#32D582'}
    />
  </svg>
)

export default CheckIcon
