import styled, { CSSProperties } from 'styled-components'

interface TextLabelProps extends CSSProperties {
  lineCap?: string
}

const TextLabel = styled.span<TextLabelProps>`
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || 400};
  color: ${props => props.color || '#000000'};
  padding-right: ${props => props.paddingRight || 0};
  padding-left: ${props => props.paddingLeft || 0};
  cursor: ${props => props.cursor || ''};
  text-align: ${props => props.textAlign};
  line-height: ${props => (props.lineCap ? props.fontSize || '1rem' : '')};
  text-decoration: ${props => props.textDecoration || 'unset'};
  white-space: ${props => props.whiteSpace || ''};
`

export default TextLabel
