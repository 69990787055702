import { createDynamicIcon } from "./Icon";

export const CalendarIcon = createDynamicIcon(`
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.8305 2.70831C10.8457 2.70831 10.8571 2.71993 10.8571 2.73541V10.8113C10.8571 10.8268 10.8457 10.8384 10.8305 10.8384H1.16952C1.15429 10.8384 1.14286 10.8268 1.14286 10.8113V2.73541C1.14286 2.71993 1.15429 2.70831 1.16952 2.70831H10.8305ZM10.8305 1.54688H1.16952C0.523809 1.54688 0 2.0792 0 2.73541V10.8113C0 11.4675 0.523809 11.9998 1.16952 11.9998H10.8305C11.4762 11.9998 12 11.4675 12 10.8113V2.73541C12 2.0792 11.4762 1.54688 10.8305 1.54688Z" fill="#8C56D9"/>
<path d="M4.00028 0H2.85742V3.29074H4.00028V0Z" fill="#8C56D9"/>
<path d="M9.14481 0H8.00195V3.29074H9.14481V0Z" fill="#8C56D9"/>
<path d="M9.11421 6H5.30469V7.16144H9.11421V6Z" fill="#8C56D9"/>
<path d="M6.64155 8.32031H2.83203V9.48175H6.64155V8.32031Z" fill="#8C56D9"/>
</svg>
`)