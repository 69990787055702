import React, { useState } from 'react'

import Head from './Head'
import Body from './Body'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  const [create, setCreate] = useState(false)
  const [activeMenu, setActiveMenu] = useState(0)
  const [activeAddData, setActiveAddData] = useState(false)

  const onCreateClick = () => {
    setCreate(true)
  }

  const handleClose = () => {
    setCreate(false)
    setActiveAddData(false)
  }

  const onMenuClick = index => {
    setActiveMenu(index)
    setActiveAddData(false)
  }

  const onAddData = () => {
    setActiveAddData(!activeAddData)
  }

  return (
    <Page>
      <Head activeMenu={activeMenu} onAddData={onAddData} />
      <Body
        create={create}
        onCreateClick={onCreateClick}
        handleClose={handleClose}
        onMenuClick={onMenuClick}
        activeAddData={activeAddData}
        activeMenu={activeMenu}
      />
    </Page>
  )
}

export default Main
