import {
  GET_BUTTON_SETTING_STAFF_TYPE_HEAD,
  GET_BUTTON_SETTING_STAFF_HEAD,
  CLEAR_BUTTON,
} from '../actions/buttonSettingHeadStaffTypeAction'

const initState = {
  ButtonHeadStaffType: false,
  ButtonHeadStaff: false,
}

const buttonSettingHeadStaffTypeReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_BUTTON_SETTING_STAFF_TYPE_HEAD:
      return {
        ...state,
        ButtonHeadStaffType: action.payload.data,
      }
    case GET_BUTTON_SETTING_STAFF_HEAD:
      return {
        ...state,
        ButtonHeadStaff: action.payload.data,
      }
    case CLEAR_BUTTON:
      return {
        ...initState,
      }
    default:
      return state
  }
}

export default buttonSettingHeadStaffTypeReducers
