import React from 'react'

const BottleIcon = ({ size, color }) => (
  <svg
    width={size || '18'}
    height={size || '40'}
    viewBox='0 0 18 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2318_3450)'>
      <path
        d='M17.5255 9.55224L13.4658 5.01492V2.62687C13.4658 1.19403 12.2718 0 10.8389 0H7.67474C6.3016 0 5.10757 1.19403 5.10757 2.62687V5.55224L1.10757 9.55224H0.928467V9.73134V12.3582V36.597C0.928467 38.4478 2.48071 40 4.39115 40H5.10757H13.4658H14.2419C16.1523 40 17.7046 38.4478 17.7046 36.5373V12.2985V9.67164V9.55224H17.5255ZM10.4807 2.98507V4.1791H8.09265V2.98507H10.4807ZM14.2419 37.0149H13.4658H5.10757H4.39115C4.09265 37.0149 3.91354 36.8358 3.91354 36.597V29.8507H6.60011V28.0597H3.91354V26.2687H6.60011V24.4776H3.91354V22.6866H6.60011V20.8955H3.91354V12.2985V10.9254L7.19712 7.64179L7.67474 7.16418H11.3762L14.6598 10.8657V12.3582V20.8955H8.09265V22.6866H14.6598V24.4776H8.09265V26.2687H14.6598V28.0597H8.09265V29.8507H14.6598V36.597C14.6598 36.8358 14.4807 37.0149 14.2419 37.0149Z'
        fill='url(#paint0_linear_2318_3450)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_2318_3450'
        x1='16.7435'
        y1='2.05771e-06'
        x2='-10.9435'
        y2='12.9622'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color || '#8C56D9'} />
        <stop offset='1' stopColor={color || '#53389E'} />
      </linearGradient>
      <clipPath id='clip0_2318_3450'>
        <rect
          width='16.7164'
          height='40'
          fill='white'
          transform='translate(0.928467)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default BottleIcon
