// @ts-ignore
import api from '@/api'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Staff {
  id: number
  name: string
  // Add other properties as needed
}

interface StaffState {
  contents: Staff[]
  isLoading: boolean
  error: string | null
}

const initialState: StaffState = {
  contents: [{ id: 1, name: 'John Doe' }],
  isLoading: false,
  error: null,
}

// Actions
export const getStaffs = createAsyncThunk<
  Staff[],
  void,
  { rejectValue: string }
>('staff/get', async (_, { rejectWithValue }) => {
  try {
    const response = await api.getStaffs()
    return response.data?.data || []
  } catch (error) {
    return rejectWithValue((error as Error).message)
  }
})

export const createStaff = createAsyncThunk<
  Staff,
  any,
  { rejectValue: string }
>('staff/create', async (data, { rejectWithValue }) => {
  try {
    const response = await api.createStaff(data)
    return response.data?.data || []
  } catch (error) {
    return rejectWithValue((error as Error).message)
  }
})

// Reducer
export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getStaffs.pending, state => {
        state.isLoading = true
        state.error = null
      })
      .addCase(getStaffs.fulfilled, (state, action: PayloadAction<Staff[]>) => {
        state.isLoading = false
        state.contents = action.payload
        state.error = null
      })
      .addCase(getStaffs.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload ?? 'An error occurred'
      })

    builder
      .addCase(createStaff.pending, state => {
        state.isLoading = true
        state.error = null
      })
      .addCase(createStaff.fulfilled, (state, action: PayloadAction<Staff>) => {
        state.isLoading = false
        state.contents.push(action.payload)
        state.error = null
      })
      .addCase(createStaff.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload ?? 'An error occurred'
      })
  },
})

export default staffSlice.reducer
