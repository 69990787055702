import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Button2 from '../../atoms/Button2'
import InputText from '../../atoms/InputText'
import SaveIcon from '../../atoms/SaveIcon'
import TextLabel from '../../atoms/TextLabel'
import PayDays from './PayDays'
import BaseCommission from './BaseCommission'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import ButtonSave from '../../atoms/ButtonSave'
import Scrollbar from '../../atoms/Scrollbar'
import BaseRequest from './BaseRequest'

import api from '../../api'
import auth from '../../auth'
import utils from '../../utils'

const Container = styled.div`
  width: calc(100%);
  display: flex;
  flex-direction: column;
`
const Card = styled.div`
  width: 100%;
  max-height: calc(100vh - 6.5rem);
  min-height: calc(100vh - 6.5rem);
  background: #ffffff;
  padding: 1rem 1.5rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

const CardAddStaffType = ({
  staffTypes,
  staffs: _staffs,
  handleClose,
  currentIdEdit = -1,
  currentDataEdit = '',
}) => {
  const [name, setName] = useState('')
  const [baseCommission, setBaseCommission] = useState('')
  const [sameCommission, setSameCommission] = useState(false)
  const [baseRequestRate, setBaseRequestRate] = useState('')
  const [sameRequestRate, setSameRequestRate] = useState(false)
  const [baseSalary, setBaseSalary] = useState('')
  const [sameSalary, setSameSalary] = useState(false)
  const [staffs, setStaffs] = useState([])
  const optionPaymentCondition = [
    { value: 0, label: 'รายวัน' },
    { value: 1, label: 'รายสัปดาห์' },
    { value: 2, label: 'ราย 2 สัปดาห์' },
    { value: 3, label: 'รายเดือน' },
    { value: 4, label: 'ระบุวันเอง' },
  ]
  const [defaultOptionPaymentCondition, setDefaultOptionPaymentCondition] =
    useState(optionPaymentCondition[0])
  const [payDays, setPayDays] = useState([0])
  const [
    defaultOptionBaseCommissionPerRound,
    setDefaultOptionBaseCommissionPerRound,
  ] = useState(2)
  const [defaultOptionBaseSalaryPerRound, setDefaultOptionBaseSalaryPerRound] =
    useState({ value: 0, label: 'รายวัน' })
  const optionPaymentMethod = [
    { value: 0, label: 'เงินสดใน cashier' },
    { value: 1, label: 'เงินสด' },
    { value: 2, label: 'เงินโอน' },
  ]
  const [defaultOptionPaymentMethod, setDefaultOptionPaymentMethod] = useState(
    optionPaymentMethod[0]
  )
  const [check, setCheck] = useState([false, false, false, false])
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentIdEdit !== -1) {
      const filterStaffs =
        _staffs.filter(s => s.staffTypeId === currentDataEdit.staffTypeId) || []
      setStaffs(filterStaffs)
      if (currentDataEdit) {
        setName(currentDataEdit.staffType)
        setBaseCommission(currentDataEdit.baseCommission)
        setBaseSalary(currentDataEdit.baseSalary)
        setBaseRequestRate(currentDataEdit.baseRequestRate)
        setSameSalary(currentDataEdit.sameSalary)
        setSameCommission(currentDataEdit.sameCommission)
        setSameRequestRate(currentDataEdit.sameRequestRate)
        const findPaymentCondition = optionPaymentCondition.findIndex(
          o => o.value === currentDataEdit.paymentCondition
        )
        setDefaultOptionPaymentCondition(
          findPaymentCondition !== -1
            ? optionPaymentCondition[findPaymentCondition]
            : optionPaymentCondition[0]
        )
        setPayDays(currentDataEdit.payDays)
        const findPaymentMethod = optionPaymentMethod.findIndex(
          o => o.value === currentDataEdit.paymentMethod
        )
        setDefaultOptionBaseCommissionPerRound(
          currentDataEdit.baseCommissionPerRound || 2
        )
        setDefaultOptionBaseSalaryPerRound(
          currentDataEdit.baseSalaryPerRound || { value: 0, label: 'รายวัน' }
        )

        setDefaultOptionPaymentMethod(
          findPaymentMethod !== -1
            ? optionPaymentMethod[findPaymentMethod]
            : optionPaymentMethod[0]
        )
      }
    }
  }, [currentIdEdit, currentDataEdit])

  const onNameChange = value => {
    if (value.length <= 10) {
      setName(value)
    }
  }

  const onCommissionChange = value => {
    const newArrStaff = [...staffs]
    if (utils.checkNumber(value)) {
      setBaseCommission(value)
      for (let i = 0; i < newArrStaff.length; i++) {
        if (newArrStaff[i].sameBaseCommission) {
          newArrStaff[i] = { ...newArrStaff[i], baseCommission: value }
        }
        if (newArrStaff[i].baseCommission === baseCommission) {
          newArrStaff[i] = {
            ...newArrStaff[i],
            baseCommission: value,
            sameBaseCommission: true,
          }
        }
      }
    } else if (!value) {
      setBaseCommission('')
      for (let i = 0; i < newArrStaff.length; i++) {
        if (newArrStaff[i].sameBaseCommission) {
          newArrStaff[i] = { ...newArrStaff[i], baseCommission: 0 }
        }
        if (newArrStaff[i].baseCommission === baseCommission) {
          newArrStaff[i] = {
            ...newArrStaff[i],
            baseCommission: value,
            sameBaseCommission: true,
          }
        }
      }
    }

    setStaffs(newArrStaff)
  }

  const onSelectPayDays = _paydays => {
    const newArrPaydays = [...payDays]

    if (defaultOptionPaymentCondition.value === 4) {
      const checkActiveDayIndex = newArrPaydays.findIndex(a => a === _paydays)
      if (checkActiveDayIndex !== -1) {
        if (newArrPaydays.length !== 1) {
          newArrPaydays.splice(checkActiveDayIndex, 1)
        }
      } else {
        newArrPaydays.push(_paydays)
      }
    } else {
      newArrPaydays[0] = _paydays
    }
    setPayDays(newArrPaydays)
  }

  const onSelectOptionCommissionClick = option => {
    setDefaultOptionBaseCommissionPerRound(option)
  }
  const onSameCommissionChange = () => {
    if (!sameCommission && baseCommission) {
      const _staffs = staffs.reduce((acc, info) => {
        const { baseCommission: _baseCommission } = info
        if (_baseCommission === 0 || !_baseCommission) {
          acc.push({ ...info, baseCommission: baseCommission })
        } else {
          acc.push({ ...info })
        }
        return acc
      }, [])
      setStaffs(_staffs)
    }
    setSameCommission(!sameCommission)
  }
  const onBaseCommissionChange = (value, index) => {
    const newArrStaff = [...staffs]
    if (utils.checkNumber(value)) {
      newArrStaff[index] = {
        ...newArrStaff[index],
        baseCommission: value,
        sameBaseCommission: false,
      }
    } else if (!value) {
      newArrStaff[index] = {
        ...newArrStaff[index],
        baseCommission: 0,
        sameBaseCommission: false,
      }
    }
    setStaffs(newArrStaff)
  }

  const onSameRequestRateChange = () => {
    if (!sameRequestRate && baseRequestRate) {
      const _staffs = staffs.reduce((acc, info) => {
        const { baseRequestStaff: _baseRequestStaff } = info
        if (_baseRequestStaff === 0 || !_baseRequestStaff) {
          acc.push({ ...info, baseRequestStaff: baseRequestRate })
        } else {
          acc.push({ ...info })
        }
        return acc
      }, [])
      setStaffs(_staffs)
    }
    setSameRequestRate(!sameRequestRate)
  }
  const onBaseRequestRateChange = (value, index) => {
    const newArrStaff = [...staffs]
    if (utils.checkNumber(value)) {
      newArrStaff[index] = {
        ...newArrStaff[index],
        baseRequestRate: value,
        sameRequestRate: false,
      }
    } else if (!value) {
      newArrStaff[index] = {
        ...newArrStaff[index],
        baseRequestRate: 0,
        sameRequestRate: false,
      }
    }
    setStaffs(newArrStaff)
  }
  const onRequestRateChange = value => {
    const newArrStaff = [...staffs]
    if (utils.checkNumber(value)) {
      setBaseRequestRate(value)
      for (let i = 0; i < newArrStaff.length; i++) {
        if (newArrStaff[i].sameRequestRate) {
          newArrStaff[i] = { ...newArrStaff[i], baseRequestRate: value }
        }
        if (newArrStaff[i].baseRequestRate === baseRequestRate) {
          newArrStaff[i] = {
            ...newArrStaff[i],
            baseRequestRate: value,
            sameRequestRate: true,
          }
        }
      }
    } else if (!value) {
      setBaseRequestRate('')
      for (let i = 0; i < newArrStaff.length; i++) {
        if (newArrStaff[i].sameRequestRate) {
          newArrStaff[i] = { ...newArrStaff[i], baseRequestRate: value }
        }
        if (newArrStaff[i].baseRequestRate === baseRequestRate) {
          newArrStaff[i] = {
            ...newArrStaff[i],
            baseRequestRate: value,
            sameRequestRate: true,
          }
        }
      }
    }

    setStaffs(newArrStaff)
  }

  const onSaveClick = async () => {
    if (!name) {
      let newArrCheck = [...check]
      if (!name) {
        newArrCheck[0] = true
      } else {
        newArrCheck[0] = false
      }
      setCheck(newArrCheck)
      return false
    } else {
      setCheck([false, false, false])
    }
    try {
      if (currentIdEdit === -1) {
        const create = {
          staffTypeId: staffTypes.length + 1,
          staffType: name,
          paymentCondition: defaultOptionPaymentCondition.value || 0,
          paymentMethod: defaultOptionPaymentMethod.value || 0,
          sameSalary: sameSalary,
          sameCommission: sameCommission,
          sameRequestRate: sameRequestRate,
          baseSalary: baseSalary,
          baseCommission: baseCommission,
          baseSalaryPerRound: defaultOptionBaseSalaryPerRound.value || 0,
          baseCommissionPerRound:
            defaultOptionBaseCommissionPerRound.value || 0,
          baseRequestRate: baseRequestRate,
          payDays: payDays,
          updatedPayWage: new Date(),
          status: 0,
          storeId: auth.getStoreId(),
        }
        await createStaffType(create)
      } else {
        const update = {
          ...currentDataEdit,
          staffType: name,
          paymentCondition: defaultOptionPaymentCondition.value || 0,
          paymentMethod: defaultOptionPaymentMethod.value || 0,
          sameSalary: sameSalary,
          sameCommission: sameCommission,
          sameRequestRate: sameRequestRate,
          baseSalary: baseSalary,
          baseCommission: baseCommission,
          baseRequestRate: baseRequestRate,
          baseSalaryPerRound: defaultOptionBaseSalaryPerRound.value || 0,
          baseCommissionPerRound:
            defaultOptionBaseCommissionPerRound.value || 0,
          // updatedPayWage:
          //   defaultOptionPaymentCondition.value ===
          //   currentDataEdit.paymentCondition
          //     ? currentDataEdit.paymentCondition
          //     : new Date(),
          payDays: payDays,
        }

        await updateStaffType(update)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const createStaffType = async create => {
    try {
      const { success } = await api.createStaffType(create)
      if (success) {
        await updateStaffs()
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const updateStaffType = async update => {
    try {
      const { success } = await api.updateStaffType(currentIdEdit, update)
      if (success) {
        await updateStaffs()
      }
    } catch (err) {
      console.log(err)
    }
  }
  const updateStaffs = async () => {
    try {
      let newArrStaff = [...staffs]
      if (sameCommission) {
        for (let i = 0; i < newArrStaff.length; i++) {
          newArrStaff[i] = { ...newArrStaff[i], baseCommission: baseCommission }
        }
      } else if (sameSalary) {
        for (let i = 0; i < newArrStaff.length; i++) {
          newArrStaff[i] = { ...newArrStaff[i], baseSalary: baseSalary }
        }
      } else if (sameRequestRate) {
        for (let i = 0; i < newArrStaff.length; i++) {
          newArrStaff[i] = {
            ...newArrStaff[i],
            baseRequestRate: baseRequestRate,
          }
        }
      }
      const { success } = await api.updateAllStaff(newArrStaff)
      if (success) {
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        {/* <div
          className='mb-2'
          style={{ width: 'fit-content', marginLeft: 'auto' }}
          onClick={handleClose}
        >
          <ClosePopup height='20px'></ClosePopup>
        </div> */}
        <Scrollbar id='setting-cardAddStaffType'>
          <div
            className='mb-2'
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <TextLabel fontWeight='600' color={check[0] ? '#DB2D1E' : ''}>
              ประเภทพนักงาน*
            </TextLabel>
            <div style={{ display: 'flex' }}>
              <TextLabel
                color={name.length === 10 ? '#DB2D1E' : '#E4E8EB'}
              >{`${name.length}`}</TextLabel>
              <TextLabel color='#E4E8EB'>/10</TextLabel>
            </div>
          </div>

          <InputText
            boxShadow='0px 4px 4px rgba(71, 84, 103, 0.05)'
            padding='0.25rem 0 0.25rem 1rem'
            border={check[0] ? '2px solid #DB2D1E' : ''}
            value={name}
            onChange={val => onNameChange(val.target.value)}
          />
          {defaultOptionPaymentCondition.value !== 0 && (
            <PayDays
              defaultOptionPaymentCondition={defaultOptionPaymentCondition}
              payDays={payDays}
              onSelectPayDays={onSelectPayDays}
            />
          )}
          <BaseRequest
            check={check[3]}
            staffs={staffs}
            onSameRequestRateChange={onSameRequestRateChange}
            sameRequestRate={sameRequestRate}
            baseRequestRate={baseRequestRate}
            onRequestRateChange={onRequestRateChange}
            onBaseRequestRateChange={onBaseRequestRateChange}
          />
          <BaseCommission
            defaultOptionPaymentCondition={defaultOptionPaymentCondition}
            defaultOptionBaseCommissionPerRound={
              defaultOptionBaseCommissionPerRound
            }
            onCommissionChange={onCommissionChange}
            commission={baseCommission}
            staffs={staffs}
            sameCommission={sameCommission}
            onSelectOptionCommissionClick={onSelectOptionCommissionClick}
            onSameCommissionChange={onSameCommissionChange}
            check={check[1]}
            onBaseCommissionChange={onBaseCommissionChange}
          />
          {/* <BaseSalary
            onSalaryChange={onSalaryChange}
            salary={baseSalary}
            staffs={staffs}
            sameSalary={sameSalary}
            defaultOptionBaseSalaryPerRound={defaultOptionBaseSalaryPerRound}
            onSelectOptionSalaryClick={onSelectOptionSalaryClick}
            onSameSalaryChange={onSameSalaryChange}
            check={check[2]}
            onBaseSalaryChange={onBaseSalaryChange}
          /> */}

          {/* <div className='mt-3 mb-2'>
            <TextLabel fontWeight='600'>วิธีการจ่ายเงิน</TextLabel>
          </div>
          <Selector
            wrapperRef={wrapperRefMethod}
            options={optionPaymentMethod}
            openArrow={openArrowPaymentMethod}
            onArrowClick={onArrowPaymentMethodClick}
            defaultOption={defaultOptionPaymentMethod}
            onSelectOptionClick={onSelectOptionPaymentMethodClick}
          /> */}
          <div className='mb-4'></div>

          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              zIndex: '2',
              background: '#FFFFFF',
            }}
          >
            <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
              <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                <Button2
                  color='#98A1B2'
                  border='2px solid #98A1B2'
                  backgroundColor='white'
                  backgroundColorHover='#E5E7EC'
                  fontWeight='600'
                  onClick={() => handleClose(true)}
                >
                  ยกเลิก
                </Button2>
              </div>
              <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                <ButtonSave
                  border='2px solid #53389E'
                  color='#FFFFFF'
                  backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                  backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                  fontWeight='600'
                  icon={<SaveIcon size='15' />}
                  onClick={() => onSaveClick()}
                >
                  บันทึก
                </ButtonSave>
              </div>
            </div>
          </div>
        </Scrollbar>
      </Card>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddStaffType
