import React, { useState } from 'react'
import Button2 from '../../atoms/Button2'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import TextLabel from '../../atoms/TextLabel'
import CancelRemark from './CancelRemark'
import CheckBox from '../../atoms/CheckBox'
import InputTextArea from '../../atoms/InputTextArea'

const PopupConfirmDelete = ({
  handleClose,
  onConfirmDeleteClick,
  text = '',
  textColor = '#8C56D9',
  textCancel = 'ยกเลิก',
  textCancelColor = '#98A1B2',
  textConfirm = 'ยืนยัน',
  textConfirmColor = 'white',
  backgroundColorConfirm = 'linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)',
  success = false,
  remark = false,
  remarks = [],
  checkPay = false,
}) => {
  const [remarkText, setRemarkText] = useState('')
  const [remarkTextIndex, setRemarkTextIndex] = useState(0)
  const [checked, setChecked] = useState(false)

  // useEffect(() => {
  //   setRemarkText(remarks[0])
  // }, [remarks])

  const onChangeRemark = index => {
    setRemarkTextIndex(index)
    setRemarkText(remarks[index])
  }

  const onChangeCheckBox = () => {
    setChecked(!checked)
  }

  return (
    <PopupBox width='100%' height='100%' left='0' top='0px'>
      <PopupInsideBox width='30%' height='auto' marginTop='0' padding='1rem'>
        <TextLabel
          color={`${textColor}`}
          fontSize='1.2rem'
          fontWeight='600'
          style={{ textAlign: 'center' }}
        >
          {text}
        </TextLabel>
        {remark && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <div>
              <CancelRemark
                gap='0.5rem'
                remarks={remarks}
                onChangeRemark={onChangeRemark}
              />
              {remarkTextIndex === 3 && (
                <InputTextArea
                  placeholder='หมายเหตุ'
                  onChange={val => setRemarkText(val.target.value)}
                />
              )}
            </div>
            {checkPay && (
              <CheckBox
                text='หักค่ามือพนักงาน'
                onClick={onChangeCheckBox}
                checked={checked}
              />
            )}
          </div>
        )}
        <div className='mt-2' style={{ display: 'flex', width: '100%' }}>
          <div className='pr-1' style={{ width: '50%' }}>
            <Button2
              onClick={() => handleClose(true)}
              border='2px solid #E4E8EB'
              backgroundColorHover='#E5E7EC'
              color={`${textCancelColor}`}
            >
              {textCancel}
            </Button2>
          </div>
          <div className='pl-1' style={{ width: '50%' }}>
            <Button2
              color={`${textConfirmColor}`}
              backgroundColor={`${backgroundColorConfirm}`}
              onClick={() => onConfirmDeleteClick(remarkText, checked)}
              border='2px solid #E4E8EB'
            >
              {textConfirm}
            </Button2>
          </div>
        </div>
        {success && <SuccessIcon2 position='absolute' />}
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupConfirmDelete
