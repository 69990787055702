import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import Button2 from '../../atoms/Button2'
import TextLabel from '../../atoms/TextLabel'
import PopupCashier from '../popups/PopupCashier'
import PopupAddLedger from '../popups/PopupAddLedger'
import utils from '../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  padding: 1.25rem 1.5rem 1.25rem 2rem;
  flex: 1;
  overflow: auto;
`

const BoxDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const BoxDetailInside = styled.div`
  display: flex;
  width: 100%;
  border-bottom: ${props => props.borderBottom || '1px solid #e4e8eb'};
  padding: ${props => props.padding || '1rem 0'};
  justify-content: space-between;
`

const BodyRight = ({
  cashierData,
  openCashier,
  onCreateSaveClick,
  loading,
}) => {
  const { t } = useTranslation()

  const [isOpenPopupAddLedger, setIsOpenPopupAddLedger] = useState(false)
  const [isOpenPopupCashier, setIsOpenPopupCashier] = useState(false)

  useEffect(() => {
    setIsOpenPopupAddLedger(false)
    setIsOpenPopupCashier(false)
  }, [cashierData])

  const onAddLedgerClick = () => {
    setIsOpenPopupAddLedger(!isOpenPopupAddLedger)
  }

  const onOpenCashierClick = () => {
    setIsOpenPopupCashier(!isOpenPopupCashier)
  }

  return (
    <Container>
      <Card>
        <TextLabel fontWeight='600'>{t('storeCashManagementSystem')}</TextLabel>
        {openCashier && (
          <BoxDetail>
            <BoxDetailInside>
              <TextLabel color='#98A1B2'>{t('cashierOpeningShift')}</TextLabel>
              <TextLabel fontWeight='600'>
                {dayjs(cashierData.date).format('DD/MM/YYYY HH:mm')}
              </TextLabel>
            </BoxDetailInside>
            <BoxDetailInside>
              <TextLabel color='#98A1B2'>{t('openingAmount')}</TextLabel>
              <TextLabel fontWeight='600'>{`${utils.formatNumber(
                +cashierData.cashStart
              )}`}</TextLabel>
            </BoxDetailInside>
            <BoxDetailInside borderBottom='none' padding='1rem 0'>
              <TextLabel color='#98A1B2'>{t('income')}</TextLabel>
              <TextLabel
                fontWeight='600'
                color='#32D582'
              >{`${utils.formatNumber(
                cashierData.totalRevenue || 0
              )}`}</TextLabel>
            </BoxDetailInside>
            <BoxDetailInside padding='0 0 1rem 0'>
              <TextLabel color='#98A1B2'>{t('additionalIncome')}</TextLabel>
              <TextLabel
                fontWeight='600'
                color='#32D582'
              >{`${utils.formatNumber(
                cashierData.totalSpacialRevenue || 0
              )}`}</TextLabel>
            </BoxDetailInside>
            <BoxDetailInside padding='1rem 0'>
              <TextLabel color='#98A1B2'>{t('additionalExpense')}</TextLabel>
              <TextLabel
                fontWeight='600'
                color='#DB2D1E'
              >{`- ${utils.formatNumber(
                cashierData.totalSpacialExpense || 0
              )}`}</TextLabel>
            </BoxDetailInside>
            <BoxDetailInside borderBottom='none' padding='1rem 0'>
              <TextLabel fontWeight='600'>{t('totalCashSummary')}</TextLabel>
              <TextLabel fontWeight='600'>{`${utils.formatNumber(
                cashierData.total || 0
              )}`}</TextLabel>
            </BoxDetailInside>
          </BoxDetail>
        )}
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            justifyContent: 'flex-end',
            position: 'sticky',
            bottom: '0rem',
            background: '#FFFFFF',
          }}
        >
          <div style={{ width: '100%', display: 'flex', gap: '0.5rem' }}>
            {openCashier && (
              <div style={{ display: 'flex', width: '50%' }}>
                <Button2
                  border='2px solid #53389E'
                  color='#8C56D9'
                  backgroundColor='#FFFFFF'
                  backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                  fontWeight='600'
                  onClick={() => onAddLedgerClick()}
                >
                  {t('addIncome/Expenses')}
                </Button2>
              </div>
            )}
            <div
              className='ml-1'
              style={{ display: 'flex', width: openCashier ? '50%' : '100%' }}
            >
              <Button2
                backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                color='white'
                fontWeight='600'
                disable={loading}
                onClick={() => onOpenCashierClick()}
              >
                {openCashier ? t('closeCashierShift') : t('openCashierShift')}
              </Button2>
            </div>
          </div>
        </div>
      </Card>
      {isOpenPopupAddLedger && (
        <PopupAddLedger
          handleClose={onAddLedgerClick}
          onSave={onCreateSaveClick}
        />
      )}
      {isOpenPopupCashier && (
        <PopupCashier
          handleClose={onOpenCashierClick}
          openCashier={openCashier}
          cashierData={cashierData}
          onSave={onCreateSaveClick}
          loading={loading}
        />
      )}
    </Container>
  )
}

export default BodyRight
