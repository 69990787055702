import Scrollbar from '@/atoms/Scrollbar'
import { Row } from '@/components/atoms/Flex'
import { TwoColumnGrid } from '@/components/atoms/Grid'
import { CalendarIcon } from '@/components/atoms/icons'
import { PersonIcon } from '@/components/atoms/icons/PeopleIcon'
import { Separator } from '@/components/atoms/Separator'
import {
  DataTableCell,
  IDataTableDataCellOptions,
  StyledBadge,
} from '@/components/molecules/dataTable/DataTable'
// @ts-ignore
import api from "@/api"
import { SheetTitle } from '@/components/molecules/SheetDrawer'
import { RowSearchHistoryType } from '@/jotai/SearchHistoryAtom'
import { BookingHistory, PaymentChannelTypes } from '@/redux/models/BookingHistory'
import typography from '@/styles/typography'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CenterDotIcon } from '@/components/atoms/icons/DotsIcon'
import { CashStackIcon } from '@/components/atoms/icons/CashIcon'
import Button from '@/components/atoms/Button'
import { bookHistoryToBookOrder, getOrderBooking } from '@/redux/actions/bookingHistoryAction'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BOOKING_URL } from '@/routes'
import { BookOrder } from '@/redux/models/BookOrder'
import dayjs from 'dayjs'
import config from '@/config'
// @ts-ignore
import PopupBillPayment from '@/molecules/Receipt/PopupBillPayMent'
import { capitalize } from 'lodash'
import Modal from '@/components/molecules/modal'
import DropdownButton from '@/components/atoms/DropdownButton'
import SpaCard from '@/molecules/SpaCard'

export interface ModalBookingHistoryInfoProps {
  info?: BookingHistory
}

const StyledSheetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  min-height: 100%;
`

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const FieldLabel = styled.div`
  font-size: 0.75rem;
  color: #98a1b2;
`

const CustomerField = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    ${typography?.body2}
    color: var(--muted-contrast);
`

const StyledDataTableCell = styled(DataTableCell)`
  padding: 0px;
  width: 100%;
  ${StyledBadge} {
    height: 32px;
  }
`

const StyledBookingCodeRow = styled(Row)`
  gap: 8px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  path {
    fill: var(--primary);
  }
`

const StyledPrice = styled.div`
    color: var(--primary);
    font-weight: 500;
`

const ActionSection = styled.div`
  display: flex;
  gap: 12px;
  position: absolute;
  width: 100%;
  bottom: 0px;
  button {
    flex: 1;
  }
`

interface FieldProps {
  label: string
  value: any
  options?: IDataTableDataCellOptions
  className?: string
}

const StyledIconWrapper = styled.div`
  background: color-mix(in srgb, var(--primary) 5%, var(--primary) 5%);
  border-radius: var(--radius-xs);
  width: 20px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  path {
    fill: var(--primary);
  }
`

interface ICustomer {
  name: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  specialRequest: string
  orderCount: number
  _id: string
  customerId: number
  age: number
  gender: number
  nationalityId: number
  orders: IBookingHistory[]
}

interface IBookingHistory {
  queueFree: boolean
  requestStaff: boolean
  choiceId: any[]
  choicePrice: any[]
  payChannelTypeTip: number
  serviceDiscount: number
  _id: string
  service?: string
  serviceId: number
  image: string
  time: number
  price: number
  timePriceId: number
  cost: number
  staffs: Staff[]
  rooms: any[]
}

interface Staff {
  staffTypeId: number
  staffId: number
  queueFree: boolean
  requestStaff: boolean
  _id: string
  staffName: string
  staffImage: string
  tip: number
}

const StyledCustomerDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const CustomerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const StyledCustomerRowTitle = styled(Row)`
  ${typography.body2}
  ${typography.semibold}
`

const StyledCustomerRowTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`

const StyledCustomerRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid var(--border);
    border-radius: var(--radius-sm);
    padding: 10px 8px;
`

const mockCutomers = [
  {
    name: 'Test',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '423432',
    specialRequest: '',
    orderCount: 1,
    _id: '66e95403f74618183b707b74',
    customerId: -1,
    age: -1,
    gender: -1,
    nationalityId: -1,
    orders: [
      {
        queueFree: false,
        requestStaff: false,
        choiceId: [],
        choicePrice: [],
        payChannelTypeTip: -1,
        serviceDiscount: 0,
        _id: '66e95403f74618183b707b75',
        serviceId: 3,
        image:
          'https://thaihand-pos.s3.ap-southeast-1.amazonaws.com/services/eba1a6788438491a0c083d0067625e75.jpg',
        time: 60,
        price: 400,
        timePriceId: 1,
        cost: 0,
        staffs: [
          {
            staffTypeId: 2,
            staffId: 4,
            queueFree: false,
            requestStaff: false,
            _id: '66e95403f74618183b707b76',
            staffName: 'หมอ D',
            staffImage:
              'https://thaihand-pos.s3.ap-southeast-1.amazonaws.com/staffs/41c36d7d904c3a1cdb11ddd4455523c0.jpg',
            tip: 0,
          },
        ],
        rooms: [],
      },
    ],
  },
  {
    name: 'Somchai',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '1234124',
    specialRequest: '',
    orderCount: 1,
    _id: '66e95403f74618183b707b77',
    customerId: -1,
    age: -1,
    gender: -1,
    nationalityId: -1,
    orders: [
      {
        queueFree: false,
        requestStaff: false,
        choiceId: [],
        choicePrice: [],
        payChannelTypeTip: -1,
        serviceDiscount: 0,
        _id: '66e95403f74618183b707b78',
        service: 'Foot Massage',
        serviceId: 2,
        image:
          'https://thaihand-pos.s3.ap-southeast-1.amazonaws.com/services/567fc86a298ff4e09faeda1023c0631c.jpg',
        time: 60,
        price: 199,
        timePriceId: 1,
        cost: 0,
        staffs: [
          {
            staffTypeId: 3,
            staffId: 2,
            queueFree: false,
            requestStaff: false,
            _id: '66e95403f74618183b707b79',
            staffName: 'หมอ B',
            staffImage:
              'https://thaihand-pos.s3.ap-southeast-1.amazonaws.com/staffs/ba939ca4f19a314185fcee6f11a94f00.jpg',
            tip: 0,
          },
        ],
        rooms: [],
      },
    ],
  },
] as ICustomer[]

interface CustomerRowProps {
  customer: ICustomer
  index: number
  services?: any[]
}

const CustomerRow = (props: CustomerRowProps) => {
    const price = useMemo(() => {
    return props.customer.orders.reduce((acc, order) => {
        return acc + order.price
    }, 0)
    }, [props.customer.orders])
  return (
    <StyledCustomerRow>
      <StyledCustomerRowTitleWrapper>
        <StyledCustomerRowTitle>
          {props?.index + 1}. {props.customer.name} {props.customer.phoneNumber}
        </StyledCustomerRowTitle>
        <StyledPrice>
            {price?.toLocaleString()}
        </StyledPrice>
      </StyledCustomerRowTitleWrapper>
        <StyledCustomerDetailWrapper>
            {
                props.customer.orders.map((order, index) => {
                    return (
                        <CustomerField key={index}>
                            <span>
                            {order?.service}
                            </span>
                            <CenterDotIcon size={4}/>
                            <span>
                                {order?.time} นาที
                            </span>
                            <CenterDotIcon size={4}/>
                            {
                                order?.staffs.map((staff, index) => {
                                    return (
                                        <span key={index}>
                                            {staff?.staffName}
                                        </span>
                                    )
                                })
                            }
                        </CustomerField>
                    )
                })
            }
        </StyledCustomerDetailWrapper>
    </StyledCustomerRow>
  )
}

export const ModalBookingHistoryInfo = (
  props: ModalBookingHistoryInfoProps
) => {
  const { t } = useTranslation()
  const { info } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const [isOpenBillPayment, setIsOpenBillPayment] = useState(false)
  const [isOpenSpaCard, setIsOpenSpaCard] = useState(false)

  const Field = ({ label, value, options = {}, className }: FieldProps) => {
    return (
      <FieldContainer className={className}>
        <FieldLabel>{label}</FieldLabel>
        {
            Array.isArray(value) ? (
                value.map((item, index) => {
                    return (
                        <StyledDataTableCell
                            id={item.key}
                            columnKey={label}
                            key={index}
                            value={[item]}
                            options={options}
                        />
                    )
                })
            ) : (
                <StyledDataTableCell
                    id={value}
                    columnKey={label}
                    value={value}
                    options={options}
                />
            )
        }
      </FieldContainer>
    )
  }

  const payChannels = useMemo(() => {
    const result = info?.payChannels?.reduce((acc: any, payChannel: {
        payChannelTypeId: number
        payChannelId: Number
        payChannel: string
        note: string
        quantity: number
        chargePercentage: number
        price: number
      }) => {
        if(!acc) {
            acc = []
        }

        const index = acc.findIndex((item: any) => item.payChannelTypeId === payChannel.payChannelTypeId)
        if(index > -1) {
            acc[index].price += payChannel.price
        } else {
            acc.push({
                payChannelTypeId: payChannel.payChannelTypeId,
                payChannelId: payChannel.payChannelId,
                payChannel: payChannel.payChannel,
                price: payChannel.price
            })
        }

    }, [])
    return result
  },[info?.payChannels])

  const handleEdit = async () => {
    const { data } = await api.getBookingHistoryById(info?._id)
    const { data: services } = await api.getServices()
    const bookOrder = bookHistoryToBookOrder(data[0], services)
    dispatch(getOrderBooking(bookOrder))
    history.push(BOOKING_URL)
  }

  return (
    <StyledSheetContent
      style={{
        gap: '12px',
      }}
    >
      <SheetTitle>{t('details')}</SheetTitle>
      <StyledBookingCodeRow>
        <StyledIconWrapper>
          <CalendarIcon size={10} />
        </StyledIconWrapper>
        <span>#{info?.bookingCode}</span>
      </StyledBookingCodeRow>
      <TwoColumnGrid gap='12px'>
        <Field label={t('customer')} value={info?.customers || ''} />
        <Field label={t('phoneNumber')} value={info?.phoneNumber || ''} />
        <Field label={t('bookingChannels')} value={info?.bookingChannel?.bookingChannel || ''} />
        <Field label={t('orderNo.')} value={info?.bookingCode || ''} />
        <Field
          label={t('transactionType')}
          value={[{
            key: info?.transactionType,
            value: capitalize(config.TRANSACTION_TYPE[info?.transactionType ? info?.transactionType - 1 : 0] || '')
          }]}
          options={{
            type: 'service-badge',
          }}
        />
        <Field
          label={t('serviceStatus')}
          value={[{
            key: info?.statusId,
            value: capitalize(config.STATUS[info?.statusId ? info?.statusId - 1 : 0] || ''),
          }]}
          options={{
            type: 'status',
          }}
        />
        <Field label={t('create')} value={dayjs(info?.createdAt).format("") || ''} />
        <Field label={t('editBy')} value={info?.updatedAt || ''} />
      </TwoColumnGrid>
      <Separator />
      <Row gap='4px'>
        <StyledIconWrapper>
          <PersonIcon size={12} />
        </StyledIconWrapper>
        <span>{t('customer')}</span>
      </Row>
      <Scrollbar direction='y' maxHeight={'293px'} height={"fit-content"}>
        <CustomerWrapper>
          {info?.customers?.map((customer: any, index: number) => {
            return <CustomerRow customer={customer} key={index} index={index} />
          })}
        </CustomerWrapper>
      </Scrollbar>
      <Separator/>
      <StyledBookingCodeRow>
        <StyledIconWrapper>
          <CashStackIcon size={10} />
        </StyledIconWrapper>
        <span>{t('due')}</span>
      </StyledBookingCodeRow>
      <TwoColumnGrid gap='12px'>
        <Field
          label={t('paymentChannel')}
          value={
            payChannels?.map((payChannel: any) => {
                return {
                    key: payChannel.payChannelTypeId,
                    value: payChannel.payChannelTypeId,
                }
            })
          }
        />
        <Field
          label={t('moneyAmount')}
          value={
            payChannels?.map((payChannel: any) => {
                return {
                    key: payChannel.payChannelId,
                    value: payChannel.price,
                }
            })
          }
        />
        <Field
          label={t('promotion')}
          value={
            info?.promotions?.map((promotion) => {
                return {
                    key: promotion.promotionId,
                    value: promotion.promotionId,
                }
            }) || ''
          }
        />
        <Field
          label={t('discount')}
          value={
            info?.promotions?.map((promotion: any) => {
                return {
                    key: promotion.promotionId,
                    value: promotion.amount,
                }
            })
          }
        />
        <Field
          label={t('payStatus')}
          value={[
            {
                key: info?.payStatus === 1 ? 'ชำระเงินแล้ว' : 'ยังไม่ชำระเงิน',
                value: info?.payStatus === 1 ? 'ชำระเงินแล้ว' : 'ยังไม่ชำระเงิน',
                variant: info?.payStatus === 1 ? 'success' : 'danger'
            }
          ]}
          options={{
            type: 'status'
          }}
        />
        {/* <Field
          label={t('grandTotal')}
          value={
            info?.total - (info?.discount + info?.serviceDiscount)
          }
        /> */}
      </TwoColumnGrid>
      <ActionSection>
        <Button variant="secondary" onClick={handleEdit}>
          {t('edit')}
        </Button>
        <DropdownButton className='flex-1'>
          <DropdownButton.Trigger>
          <Button className='w-full'>
          {t('print')}
        </Button>
          </DropdownButton.Trigger>
          <DropdownButton.Content>
            <DropdownButton.Item onClick={() => setIsOpenBillPayment(true)} style={{
              padding: '8px 16px',
              fontSize: '0.875rem',
            }}>
              {t('printReceipt')}
            </DropdownButton.Item>
            {
              info?.customers.some((customer) => customer.orders.some(order => order.staffs.some( staff => staff?.staffId !== -1))) && (
                <DropdownButton.Item onClick={() => setIsOpenSpaCard(true)} style={{
                  padding: '8px 16px',
                  fontSize: '0.875rem',
                }}>
                  {t('printSpaCard')}
                </DropdownButton.Item>
              )
            }
          </DropdownButton.Content>
        </DropdownButton>
      </ActionSection>
{
  <Modal isOpen={isOpenBillPayment}>
    <Modal.Content>
    <PopupBillPayment
     bookingHistory={info}
      receiptNumber={info?.receiptNumber}
      handleClose={() => setIsOpenBillPayment(false)}
    />
    </Modal.Content>
  </Modal>
}
{
  <Modal isOpen={isOpenSpaCard} onOpenChange={e => setIsOpenSpaCard(e)}>
    <Modal.Content>
    {!!info && <SpaCard bookingHistory={info}/>}
    </Modal.Content>
  </Modal>
}
    </StyledSheetContent>
  )
}
