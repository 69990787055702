import { GET_BOOKING_CHANNEL } from '../actions/callApiBookingChannelAction'

const initState = {}

const callApiBookingChannelReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_BOOKING_CHANNEL:
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

export default callApiBookingChannelReducers
