import { GET_BREAK_TIME } from '../actions/staffBreakTimeAction'

const initState = {
  _id: '',
  breakTime: [],
}

const staffBreakTimeReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_BREAK_TIME:
      return { ...state, breakTime: action.payload.breakTime }
    default:
      return state
  }
}

export default staffBreakTimeReducers
