import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useDispatch, useSelector } from 'react-redux'

import CheckBox from '../../atoms/CheckBox'
import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Box = styled.div`
  border: 2px solid #8c56d9;
  border-radius: 8px;
  padding: 0.5rem;
  width: 50%;
`

const Agency = ({ bookingHistory }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [hasCommission, setHasCommission] = useState(true)

  const bookingOrderState = useSelector(state => state.bookingHistoryReducers)
  const { total } = bookingOrderState || {}

  const {
    bookingChannel: { bookingChannel, commissionPercentage = 0 },
    bookingType: { bookingTypeId },
  } = bookingHistory || {}

  useEffect(() => {
    dispatch(
      getOrderBooking({
        commission: hasCommission
          ? utils.roundToFixed((commissionPercentage / 100) * total, 2)
          : 0,
      })
    )
  }, [hasCommission])

  return (
    <Container>
      {bookingTypeId === 3 && (
        <>
          <Box>{bookingChannel}</Box>
          <CheckBox
            heightCheckMark='18px'
            widthCheckMark='18px'
            left='5px'
            top='2px'
            width='3px'
            height='8px'
            marginBottom='0px'
            paddingLeft='25px'
            backgroundColor='linear-gradient(142.04deg, #8C56D9 -7.02%, #53389E 121.47%)'
            onClick={() => setHasCommission(v => !v)}
            checked={hasCommission}
            text={t('agencyCommissionFee')}
          />
          {hasCommission && (
            <TextLabel>
              {t('agencyCommissionFee')} ({commissionPercentage}
              %): {utils.roundToFixed(
                (commissionPercentage / 100) * total,
                2
              )}{' '}
              บาท
            </TextLabel>
          )}
        </>
      )}
    </Container>
  )
}

export default Agency
