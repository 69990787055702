import React from 'react'

import PopupBase, { PopupBaseProps } from './PopupBase'

import CardAddPackage from '../settingPackage/CardAddPackage'

import BrandPackage from '../../redux/models/BrandPackage'
import { RequiredBy } from '../../utilities/typing'

export interface PopupAddPackageProps
  extends RequiredBy<PopupBaseProps, 'onClose'> {
  currentData: Partial<BrandPackage>
}

const PopupAddPackage = ({ onClose, currentData }: PopupAddPackageProps) => {
  return (
    <PopupBase>
      <CardAddPackage onClose={onClose} currentData={currentData} />
    </PopupBase>
  )
}

export default PopupAddPackage
