import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  &:hover {
    user-select: none;
    cursor: ${props => (!props.disabled ? 'pointer' : '')};
  }
`
const LeftIcon = ({ width, height, onClick, disabled }) => (
  <SVG
    width={width | '8'}
    height={height || '19'}
    viewBox='0 0 8 19'
    fill='none'
    onClick={onClick}
    disabled={disabled}
  >
    <path
      d='M6.98242 1.15625L1.00033 9.3125L6.98242 17.4688'
      stroke={`rgb(82, 82, 124,${disabled ? '0.5' : '1'})`}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVG>
)

export default LeftIcon
