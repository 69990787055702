import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outlined' | 'ghost'
  size?: 'small' | 'medium' | 'large' | 'icon'

  // TODO: Delete these when refactoring is done
  color?: string
  border?: string
  backgroundColor?: string
  fontWeight?: string
  backgroundColorHover?: string
  icon?: React.ReactNode
  width?: string
  padding?: string
  margin?: string
  disable?: boolean
}

export const StyledButton = styled.button.attrs<ButtonProps>(props => ({
  variant: props.variant || 'primary',
  size: props.size || 'medium',
}))<ButtonProps & {
  isLoading?: boolean
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background: ${props => {
      switch (props.variant) {
        case 'primary':
          // linear-gradient(221.86deg, rgb(140, 86, 217) 2.71%, rgb(83, 56, 158) 97.79%)
          return 'linear-gradient(221.86deg, rgb(140, 86, 217) 2.71%, rgb(83, 56, 158) 97.79%)'
        case 'secondary':
          return 'var(--popup)'
        case 'outlined':
          return 'transparent'
        case 'ghost':
          return 'transparent'
        default:
          return 'linear-gradient(221.86deg, rgb(140, 86, 217) 2.71%, rgb(83, 56, 158) 97.79%)'
      }
    }};
    color: ${props => {
      switch (props.variant) {
        case 'primary':
          return 'var(--text-contrast)'
        case 'secondary':
          return 'var(--primary)'
        case 'outlined':
          return 'var(--text)'
        case 'ghost':
          return 'var(--text)'
        default:
          return 'var(--text-contrast)'
      }
    }};
    padding: ${props => {
      switch (props.size) {
        case 'icon':
          return '0.5rem'
        case 'small':
          return '0.2rem 0.5rem'
        case 'medium':
          return '0.5rem 1rem'
        case 'large':
          return '1rem 2rem'
        default:
          return '0.2rem 0.5rem'
      }
    }};
    border: ${props => {
      switch (props.variant) {
        case 'primary':
          return 'none'
        case 'secondary':
          return '1px solid var(--primary)'
        case 'outlined':
          return '1px solid var(--text-primary)'
        case 'ghost':
          return 'none'
        default:
          return 'none'
      }
    }};

    aspect-ratio: ${props => {
      switch (props.size) {
        case 'icon':
          return '1/1'
        case 'small':
          return '3/1'
        case 'medium':
          return '3/1'
        case 'large':
          return '3/1'
        default:
          return '3/1'
      }
    }};
    opacity: ${props => props.isLoading ? 0.5 : 1};
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s;
    ${props => {
      switch (props.size) {
        case 'icon':
          return `
            width: 2rem;
            height: 2rem;
          `
        case 'small':
          return `
            font-size: 14px;
            height: 32px;
          `
        case 'medium':
          return `
            font-size: 16px;
            height: 42px;
          `
        case 'large':
          return `
            font-size: 18px;
            height: 40px;
          `
        default:
          return `
            font-size: 16px;
          `
      }
    }}
    &:hover {
        background: ${props => {
          switch (props.variant) {
            case 'primary':
              return 'linear-gradient(266.17deg, rgb(141, 103, 205) 1.44%, rgb(110, 87, 174) 100%)'
            case 'secondary':
              return 'var(--primary)'
            case 'outlined':
              return 'transparent'
            case 'ghost':
              return 'var(--muted)'
            default:
              return 'linear-gradient(266.17deg, rgb(141, 103, 205) 1.44%, rgb(110, 87, 174) 100%)'
          }
        }};
        color: ${props => {
          switch (props.variant) {
            case 'primary':
              return 'var(--text-contrast)'
            case 'secondary':
              return 'var(--text-contrast)'
            case 'outlined':
              return 'var(--text)'
            case 'ghost':
              return 'var(--text)'
            default:
              return 'var(--text-contrast)'
          }
        }}
      
`

export const StyledSpinner = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Button: React.FC<ButtonProps> = props => {
  const { children, variant, size, onClick, ...rest } = props

  const [isLoading, setIsLoading] = React.useState(false)

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return
    try {
        setIsLoading(true)
        onClick && (await onClick(e))
    } catch (e) {
      console.error(e)
    } finally {
        setIsLoading(false)
    }
  }

  return (
    <StyledButton isLoading={isLoading} variant={variant} size={size} onClick={handleClick} {...rest}>
      {isLoading ? <StyledSpinner /> : null} {children}
    </StyledButton>
  )
}

export default Button
