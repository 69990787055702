import React from 'react'

import SettingHeader from '../../molecules/SettingHeader'

const Head = ({ activeMenu, onAddData }) => {
  return (
    <SettingHeader
      name='ลูกค้า'
      menuName={
        activeMenu === 0
          ? 'สร้างลูกค้า'
          : activeMenu === 1
          ? 'สร้างสัญชาติ'
          : 'สร้างอาชีพ'
      }
      onClick={onAddData}
    />
  )
}

export default Head
