import React from 'react'

import SelectorBase, { SelectorBaseProps } from './SelectorBase'

import config from '../../config'
import Option from '../../redux/models/Option'
import { PartialBy } from '../../utilities/typing'

const options = config.PACKAGE_TYPES.reduce(
  (acc, info, index) => acc.concat({ value: index + 1, label: info }),
  [] as Option[]
)

export interface SelectorPackageTypeProps
  extends PartialBy<SelectorBaseProps<number>, 'options'> {}

const SelectorPackageType = ({
  name = 'packageType',
  open,
  onClick,
  option = {
    value: -1,
    label: 'อื่น ๆ',
  },
  onOptionClick,
  error = false,
  readOnly = false,
}: SelectorPackageTypeProps) => {
  return (
    <SelectorBase
      name={name}
      option={option}
      options={options}
      open={open}
      onClick={onClick}
      onOptionClick={onOptionClick}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorPackageType
