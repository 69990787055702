import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import TextLabel from '../../atoms/TextLabel'
import CheckBox from '../../atoms/CheckBox'
import InputText from '../../atoms/InputText'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const BoxStaff = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.8;
  background: #f8f9fb;
  border-radius: 8px;
  min-width: 100px;
  padding: 0.5rem 0.25rem;
  border: 1px solid #f8f9fb;
  :hover {
    border: 1px solid #e4e8eb;
  }
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: start;
  text-align: center;
`
const ImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`
const InputBox = styled.input`
  background-color: ${props => props.backgroundColor || '#ece9f4'};
  border-radius: 8px;
  text-align: center;
  border: ${props => props.border || 'none'};
  /* height: ${props => props.height || '55%'}; */
  width: 80%;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  font-size: 0.9rem;
  outline: none;
  margin-top: 0.5rem;
  :focus {
    color: #8c56d9;
    border: 2px solid #8c56d9;
  }
`

const BaseRequest = ({
  check,
  staffs,
  sameRequestRate,
  onSameRequestRateChange,
  baseRequestRate,
  onBaseRequestRateChange,
  onRequestRateChange,
}) => {
  const listStaff = staffs.map(
    ({ name, baseRequestRate, image, staffTypeId, _id }, index) => {
      return (
        <SwiperSlide key={index}>
          <BoxStaff>
            <ImageContainer></ImageContainer>
            <ImageBox>
              {image !== '' ? (
                <img
                  src={image}
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <div
                  style={{
                    background: '#E4E8EB',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                  }}
                ></div>
              )}
            </ImageBox>
            <TextLabel fontWeight='bold'>{name}</TextLabel>
            <InputBox
              backgroundColor='white'
              border='2px solid #E4E8EB'
              value={baseRequestRate}
              onChange={val => onBaseRequestRateChange(val.target.value, index)}
            />
          </BoxStaff>
        </SwiperSlide>
      )
    }
  )

  return (
    <Container>
      <div
        className='mt-3 mb-2'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <TextLabel fontWeight='600' color={check ? '#DB2D1E' : ''}>
          {`เงินค่ารีเควสพนักงาน`}
        </TextLabel>
        {staffs.length ? (
          <div style={{ display: 'flex' }}>
            <CheckBox
              text='พนักงานแต่ละคนอาจไม่เท่ากัน'
              colorText='#475467'
              fontSize='0.8rem'
              lineHeight='2'
              widthCheckMark='20px'
              heightCheckMark='20px'
              left='6px'
              top='2px'
              width='4px'
              checked={!sameRequestRate}
              onChange={onSameRequestRateChange}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <InputText
        children='บาท/ครั้ง'
        widthInput='84%'
        value={baseRequestRate}
        onChange={val => onRequestRateChange(val.target.value)}
      />
      {!sameRequestRate && (
        <div className='mt-3'>
          <TextLabel fontSize='1.2rem' fontWeight='bold'>
            รายละเอียดเงินค่ารีเควสพนักงาน
          </TextLabel>
          <Swiper slidesPerView={3.5} spaceBetween={30} autoHeight={true}>
            {listStaff}
          </Swiper>
        </div>
      )}
    </Container>
  )
}

export default BaseRequest
