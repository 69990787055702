import Button from '@/components/atoms/Button'
import SheetDrawer from '@/components/molecules/SheetDrawer'
import React from 'react'

type Props = {}

export default function SheetComponentPage({}: Props) {
    const [open, setOpen] = React.useState<boolean>(false)
  return (
    <div>
      <SheetDrawer isOpen={open} onOpenChange={e => setOpen(e)}>
        <SheetDrawer.Trigger>
          <Button>Open</Button>
        </SheetDrawer.Trigger>
        <SheetDrawer.Content>
          <div>Content</div>
        </SheetDrawer.Content>
      </SheetDrawer>
    </div>
  )
}