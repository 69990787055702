import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import CheckBox from '../../atoms/CheckBox'
import TextLabel from '../../atoms/TextLabel'
import InputText from '../../atoms/InputText'
import useOnClickOutside from '../../hook/useOnClickOutside'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Box = styled.div`
  width: 100%;
  display: flex;
`
const BoxInside = styled.div`
  width: 50%;
  flex: 1 0 50%;
`
const BoxStaff = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.8;
  background: #f8f9fb;
  border-radius: 8px;
  min-width: 100px;
  padding: 0.5rem 0.25rem;
  border: 1px solid #f8f9fb;
  :hover {
    border: 1px solid #e4e8eb;
  }
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: start;
  text-align: center;
`
const ImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`
const DivInput = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`
const InputBox = styled.input`
  background-color: ${props => props.backgroundColor || '#ece9f4'};
  border-radius: 8px;
  text-align: center;
  border: ${props => props.border || 'none'};
  /* height: ${props => props.height || '55%'}; */
  width: 80%;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  font-size: 0.9rem;
  outline: none;
  margin-top: 0.5rem;
  :focus {
    color: #8c56d9;
    border: 2px solid #8c56d9;
  }
`
const IconStyle = styled.div`
  position: absolute;
  right: 1rem;
  width: fit-content;
`

const BaseCommission = ({
  onCommissionChange,
  commission,
  defaultOptionPaymentCondition,
  staffs: _staffs = [],
  sameCommission,
  defaultOptionBaseCommissionPerRound:
    _defaultOptionBaseCommissionPerRound = '',
  onSelectOptionCommissionClick: _onSelectOptionCommissionClick,
  onSameCommissionChange,
  check,
  onBaseCommissionChange,
}) => {
  const [staffs, setStaffs] = useState([])

  useEffect(() => {
    setStaffs(_staffs)
  }, [_staffs])
  const wrapperRef = useRef(null)
  const optionBaseCommissionPerRound = [
    { value: 0, label: 'รายวัน' },
    { value: 1, label: 'ต่อรอบตัดค่ามือ' },
  ]
  const [
    defaultOptionBaseCommissionPerRound,
    setDefaultOptionBaseCommissionPerRound,
  ] = useState(optionBaseCommissionPerRound[0])
  const [openArrowCommission, setOpenArrowCommission] = useState(false)

  useEffect(() => {
    if (_defaultOptionBaseCommissionPerRound) {
      if (
        !!_defaultOptionBaseCommissionPerRound.value ||
        _defaultOptionBaseCommissionPerRound.value === 0
      ) {
        setDefaultOptionBaseCommissionPerRound(
          _defaultOptionBaseCommissionPerRound
        )
      } else {
        const findIndexOption = optionBaseCommissionPerRound.findIndex(
          p => p.value === _defaultOptionBaseCommissionPerRound
        )
        if (findIndexOption !== -1) {
          setDefaultOptionBaseCommissionPerRound(
            optionBaseCommissionPerRound[findIndexOption]
          )
        }
      }
    }
  }, [_defaultOptionBaseCommissionPerRound])

  // useEffect(() => {
  //   if (
  //     defaultOptionPaymentCondition.value === 3 ||
  //     defaultOptionPaymentCondition === 4
  //   ) {
  //   }
  // }, [defaultOptionPaymentCondition])

  useOnClickOutside(wrapperRef, () => {
    setOpenArrowCommission(false)
  })
  const onArrowCommissionClick = () => {
    setOpenArrowCommission(!openArrowCommission)
  }

  const onSelectOptionCommissionClick = value => {
    const findIndexOption = optionBaseCommissionPerRound.findIndex(
      p => p.value === value
    )
    if (findIndexOption !== -1) {
      _onSelectOptionCommissionClick(
        optionBaseCommissionPerRound[findIndexOption]
      )
    }
    setOpenArrowCommission(false)
  }

  // const onBaseCommissionChange = (value, index) => {
  //   const newArrStaff = [...staffs]
  //   if (utils.checkNumber(value)) {
  //     newArrStaff[index] = { ...newArrStaff[index], baseCommission: value }
  //   } else if (!value) {
  //     newArrStaff[index] = { ...newArrStaff[index], baseCommission: 0 }
  //   }
  //   setStaffs(newArrStaff)
  // }

  const listStaff = staffs.map(
    ({ name, baseCommission, image, staffTypeId, _id }, index) => {
      return (
        <SwiperSlide key={index}>
          <BoxStaff>
            <ImageContainer></ImageContainer>
            <ImageBox>
              {image !== '' ? (
                <img
                  src={image}
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                  alt='staff-profile'
                />
              ) : (
                <div
                  style={{
                    background: '#E4E8EB',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                  }}
                ></div>
              )}
            </ImageBox>
            <TextLabel fontWeight='bold'>{name}</TextLabel>
            <InputBox
              backgroundColor='white'
              border='2px solid #E4E8EB'
              value={baseCommission}
              onChange={val => onBaseCommissionChange(val.target.value, index)}
            />
          </BoxStaff>
        </SwiperSlide>
      )
    }
  )

  return (
    <Container>
      <div
        className='mt-3 mb-2'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <TextLabel fontWeight='600' color={check ? '#DB2D1E' : ''}>
          เงินประกันมือ
        </TextLabel>
        {staffs.length ? (
          <div style={{ display: 'flex' }}>
            <CheckBox
              text='พนักงานแต่ละคนอาจไม่เท่ากัน'
              colorText='#475467'
              fontSize='0.8rem'
              lineHeight='2'
              widthCheckMark='20px'
              heightCheckMark='20px'
              left='6px'
              top='2px'
              width='4px'
              checked={!sameCommission}
              onChange={onSameCommissionChange}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <Box>
        <BoxInside className='pr-1'>
          <DivInput>
            <InputText
              padding='0.25rem 0 0.25rem 1rem'
              border={check ? '2px solid #DB2D1E' : ''}
              value={commission ? commission : ''}
              onChange={val => onCommissionChange(val.target.value)}
            />
            <IconStyle>
              <TextLabel color='#475467'>บาท</TextLabel>
            </IconStyle>
          </DivInput>
        </BoxInside>
        {/* <BoxInside className='pl-1'>
          <Selector
            wrapperRef={wrapperRef}
            options={optionBaseCommissionPerRound}
            openArrow={openArrowCommission}
            onArrowClick={onArrowCommissionClick}
            defaultOption={defaultOptionBaseCommissionPerRound}
            onSelectOptionClick={onSelectOptionCommissionClick}
          />
        </BoxInside> */}
      </Box>

      {!sameCommission && (
        <div className='mt-3'>
          <TextLabel fontSize='1.2rem' fontWeight='bold'>
            รายละเอียดเงินประกันพนักงาน
          </TextLabel>
          <Swiper slidesPerView={3.5} spaceBetween={30} autoHeight={true}>
            {listStaff}
          </Swiper>
        </div>
      )}
    </Container>
  )
}

export default BaseCommission
