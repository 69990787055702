import { useAtom } from 'jotai'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { schedulerBookingHistoriesFnAtom } from '@/jotai/SchedulerAtom'
import { fnFetchStoreOpeningHoursAtom } from '@/jotai/storeAtom'
import { customGetDay, isTimeBetween } from '@/lib/utils'
import { BookingHistory } from '@/redux/models/BookingHistory'
import { Time } from '@/redux/models/BookOrder'

import Button from '../atoms/Button'
import { Row } from '../atoms/Flex'
import { TriangleDown, TriangleUp } from '../atoms/icons/Triangle'

// @ts-ignore
import api from '@/api'

export interface ModalStartJobProps {
  bookingId: number | string
  defaultTime?: {
    hour: number
    minute: number
  }
  onClose?: (e: any) => void
}

const ModalStartJobContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ModalStartJobTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const StyledActionRow = styled.div`
  display: flex;
  gap: 12px;
  button {
    flex: 1;
  }
`

const StyledNumberInput = styled.input`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  isolation: isolate;

  width: 156.5px;
  height: 60px;

  font-size: 20px;

  background: #ffffff;

  border: 1px solid #e2e8f0;
  border-radius: 4px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
`

const InputArrows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Arrow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  gap: 4px;

  width: 32px;
  height: 26px;

  background: #e4e8eb;

  box-shadow: 0px 8px 40px rgba(71, 84, 103, 0.08);
  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 1;
`

const ModalStartJob = (props: ModalStartJobProps) => {
  const { bookingId } = props
  const [scheduleBookingHistories, fetchScheduleBookingHistories] = useAtom(
    schedulerBookingHistoriesFnAtom
  )
  const store = useSelector((state: any) => state.store)
  const [storeOpeningHours, fetchStoreOpeningHours] = useAtom(
    fnFetchStoreOpeningHoursAtom
  )
  const [openingHour, setOpeningHour] = useState<Time>({ hour: 0, minute: 0 })
  const [closingHour, setClosingHour] = useState<Time>({ hour: 0, minute: 0 })

  const { t } = useTranslation()

  useEffect(() => {
    fetchStoreOpeningHours()
  }, [store])

  const booking = useMemo(() => {
    return scheduleBookingHistories.find(
      (booking: BookingHistory) => booking._id === bookingId
    )
  }, [bookingId, scheduleBookingHistories])

  const bookingDay = useMemo(() => {
    return booking?.date && customGetDay(new Date(booking?.date))
  }, [booking])

  useEffect(() => {
    if (storeOpeningHours) {
      const hours = storeOpeningHours.find(hour => hour.day === bookingDay)
      if (hours) {
        setOpeningHour(hours.openTime)
        setClosingHour(hours.closeTime)
      }
    }
  }, [bookingDay, storeOpeningHours])

  const [time, setTime] = React.useState({
    hour: new Date().getHours(),
    minute: new Date().getMinutes(),
  })

  const handleStartClick = async () => {
    await api.startBookingHistory({
      _id: bookingId,
      hour: time.hour,
      minute: time.minute,
    })
    fetchScheduleBookingHistories()
  }

  const changeTime = (type: 'hour' | 'minute', _value: number) => {
    if (type === 'hour') {
      const value = _value < 0 ? 23 : _value > 23 ? 0 : _value
      const isBetween = isTimeBetween(
        {
          hour: value,
          minute: time.minute,
        },
        openingHour,
        closingHour
      )
      if (isBetween) {
        setTime({
          ...time,
          hour: value,
        })
      } else {
        setTime({
          ...time,
          hour:
            value === +openingHour.hour - 1
              ? +closingHour.hour
              : +openingHour.hour,
        })
      }
    } else {
      const value = _value < 0 ? 59 : _value > 59 ? 0 : _value
      const isBetween = isTimeBetween(
        {
          hour: time.hour,
          minute: value,
        },
        openingHour,
        closingHour
      )
      if (isBetween) {
        setTime({
          ...time,
          minute: value,
        })
      } else {
        setTime({
          ...time,
          minute:
            value === +openingHour.minute - 1
              ? +closingHour.minute
              : +openingHour.minute,
        })
      }
    }
  }

  const handleArrowClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const id = e.currentTarget.id
    const type = id.split('-')[0]
    const direction = id.split('-')[1]
    const value = direction === 'inc' ? 1 : -1
    if (type === 'hour') {
      changeTime('hour', time.hour + value)
    } else {
      changeTime('minute', time.minute + value)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id
    const type = id.split('-')[0]
    const value = +e.currentTarget.value
    if (type === 'hour') {
      changeTime('hour', value)
    } else {
      changeTime('minute', value)
    }
  }

  return (
    <ModalStartJobContainer>
      <ModalStartJobTitle>{t('serviceStartingTime')}</ModalStartJobTitle>
      <Row align='center' gap='12px'>
        <StyledNumberInput
          id='hour'
          type='number'
          value={time.hour.toString().padStart(2, '0')}
          onChange={handleInputChange}
        />
        <InputArrows>
          <Arrow id='hour-inc' onClick={handleArrowClick}>
            <TriangleUp />
          </Arrow>
          <Arrow id='hour-dec' onClick={handleArrowClick}>
            <TriangleDown />
          </Arrow>
        </InputArrows>
        <span>:</span>
        <StyledNumberInput
          id='minute'
          type='number'
          value={time.minute.toString().padStart(2, '0')}
          onChange={handleInputChange}
        />
        <InputArrows>
          <Arrow id='minute-inc' onClick={handleArrowClick}>
            <TriangleUp />
          </Arrow>
          <Arrow id='minute-dec' onClick={handleArrowClick}>
            <TriangleDown />
          </Arrow>
        </InputArrows>
      </Row>
      <StyledActionRow>
        <Button variant='secondary' onClick={props?.onClose}>
          {t('cancel')}
        </Button>
        <Button onClick={handleStartClick}>{t('start')}</Button>
      </StyledActionRow>
    </ModalStartJobContainer>
  )
}

export default ModalStartJob
