import React from 'react'

const ServiceIcon2 = ({ size, color }) => (
  <svg
    width={size || '23'}
    height={size || '24'}
    viewBox='0 0 23 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_57_731)'>
      <path
        d='M22.9947 23.2681C22.9947 22.8493 22.6896 22.4911 22.2758 22.4256L18.5428 21.8339C18.1858 21.7771 17.8548 21.6103 17.5954 21.3583L14.5245 18.3628C14.0329 17.8835 13.3226 17.6933 12.6543 17.8514C8.13323 18.915 4.58924 19.1077 1.13297 17.9737C0.617862 17.8045 0.0718738 18.1578 0.0607564 18.6988L0.000228344 21.4782C-0.0121243 22.0884 0.478277 22.5899 1.0885 22.5899H12.6531L12.0923 22.1551C11.8501 21.9673 11.8057 21.6177 11.9934 21.3756C12.1812 21.1335 12.5308 21.089 12.7729 21.2768L14.6418 22.727C14.7691 22.7851 14.8889 22.8604 14.9964 22.9531L15.3694 23.273C15.9129 23.7399 16.6084 23.9969 17.3248 23.9969H22.2659C22.6686 23.9969 22.9947 23.6708 22.9947 23.2681Z'
        fill={color || '#53389E'}
      />
      <path
        d='M8.38838 14.9037L8.60332 14.963L12.3314 15.9846C12.6315 16.0674 12.9576 15.929 13.1022 15.64C13.2652 15.3114 13.1318 14.9124 12.8032 14.7493L9.62982 13.1707L6.67259 9.73663C6.64295 9.70204 6.6096 9.66992 6.57624 9.64027C6.40825 9.45251 6.19949 9.29811 5.95985 9.19558C5.4188 8.96335 4.79252 9.21658 4.56523 9.7601L2.0354 15.8104C1.80317 16.3675 2.07864 17.0148 2.65921 17.1791C3.94018 17.541 5.02845 17.6226 6.42678 17.5163C7.04564 17.4694 7.49158 16.9148 7.44587 16.2959L7.24082 13.5697L8.1895 14.6715L8.38962 14.9037H8.38838Z'
        fill={color || '#53389E'}
      />
      <path
        d='M5.79213 0.140301C5.07321 -0.283396 3.95282 0.284827 3.28948 1.41016C2.62614 2.53549 2.67185 3.79052 3.39077 4.21421C3.69465 4.39333 4.07017 4.39333 4.45187 4.25127C4.42346 4.43903 4.40863 4.63174 4.40863 4.82814C4.40863 6.93798 6.11948 8.64883 8.22931 8.64883C10.3391 8.64883 12.05 6.93922 12.05 4.82814C12.05 2.71707 10.3391 1.00746 8.22931 1.00746C7.54868 1.00746 6.91128 1.18657 6.35788 1.49786C6.39247 0.895049 6.20224 0.382413 5.79213 0.141536V0.140301Z'
        fill={color || '#53389E'}
      />
      <path
        d='M15.4766 16.0586C15.4766 18.093 17.1256 19.7421 19.1601 19.7421C21.1946 19.7421 22.8437 18.093 22.8437 16.0586C22.8437 14.0241 21.1946 12.375 19.1601 12.375C17.1256 12.375 15.4766 14.0241 15.4766 16.0586Z'
        fill={color || '#53389E'}
      />
    </g>
    <defs>
      <clipPath id='clip0_57_731'>
        <rect width='22.9945' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default ServiceIcon2
