import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Button2 from '../../atoms/Button2'
import CameraIcon from '../../atoms/CameraIcon'
import InputText from '../../atoms/InputText'
import SaveIcon from '../../atoms/SaveIcon'
import EditIcon from '../../lib/icons/EditIcon'
import TextLabel from '../../atoms/TextLabel'
import UploadImageIcon from '../../atoms/UploadImageIcon'
import utils from '../../utils'
import GoogleMap from '../googleMap/Main'
import api from '../../api'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import auth from '../../auth'
import ButtonSave from '../../atoms/ButtonSave'
import Scrollbar from '../../atoms/Scrollbar'
import config from '../../config'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 1rem;
  flex: 1;
  overflow: hidden;
`
const BoxContainer = styled.div`
  display: grid;
  gap: 1rem;
`
const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`

const BoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => props.flex || '1 0 auto'};
  padding-left: ${props => props.paddingLeft || '0'};
  padding-right: ${props => props.paddingRight || '0'};
`

const ImageBox = styled.div`
  position: relative;
  border-radius: 8px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#E4E8EB'};
  width: 100px;
  height: 100px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
`

const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`

const DataStore = ({ dataStore: _dataStore = '' }) => {
  const [image, setImage] = useState('')
  const [fileImage, setFileImage] = useState('')
  const [edit, setEdit] = useState(false)
  const [dataStore, setDataStore] = useState('')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (_dataStore) {
      setDataStore(_dataStore)
      if (_dataStore.imageStore) {
        setImage(_dataStore.imageStore)
      }
    }
  }, [_dataStore])

  const onChangeImage = async event => {
    const file = event.target.files[0]
    if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
      alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
    } else if (utils.isFileExceedLimit(file)) {
      alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
    } else {
      setFileImage(file)
      const image = await utils.readImage(file)
      setImage(image.image)
    }
  }

  const onStoreIdChange = value => {
    let newDataStore = { ...dataStore }
    newDataStore = { ...newDataStore, storeId: value }
    setDataStore(newDataStore)
  }
  const onNameChange = value => {
    let newDataStore = { ...dataStore }
    newDataStore = { ...newDataStore, name: value }
    setDataStore(newDataStore)
  }
  const onBranchChange = value => {
    let newDataStore = { ...dataStore }
    newDataStore = { ...newDataStore, branch: value }
    setDataStore(newDataStore)
  }
  const onEmailChange = value => {
    let newDataStore = { ...dataStore }
    newDataStore = { ...newDataStore, email: value }
    setDataStore(newDataStore)
  }
  const onPhoneNumberChange = value => {
    let newDataStore = { ...dataStore }
    if (
      utils.checkNumber(value) &&
      value.length <= config.MAX_PHONE_NUMBER_LENGTH
    ) {
      newDataStore = { ...newDataStore, phoneNumber: value }
      setDataStore(newDataStore)
    } else if (!value) {
      newDataStore = { ...newDataStore, phoneNumber: value }
      setDataStore(newDataStore)
    }
  }
  const onFacebookChange = value => {
    let newDataStore = { ...dataStore }
    newDataStore = { ...newDataStore, facebook: value }
    setDataStore(newDataStore)
  }
  const onLineIdChange = value => {
    let newDataStore = { ...dataStore }
    newDataStore = { ...newDataStore, lineId: value }
    setDataStore(newDataStore)
  }
  const onAddressChange = value => {
    let newDataStore = { ...dataStore }
    newDataStore = { ...newDataStore, address: value }
    setDataStore(newDataStore)
  }
  const onLocationChange = (lat, lng) => {
    let newDataStore = { ...dataStore }
    newDataStore = { ...newDataStore, latitude: lat + '', longitude: lng + '' }
    setDataStore(newDataStore)
  }

  const onCancelClick = async () => {
    setDataStore(_dataStore)
    setEdit(false)
  }

  const onSaveClick = async () => {
    try {
      let update = {
        storeId: dataStore.storeId || '',
        name: dataStore.name || '',
        branch: dataStore.branch || '',
        email: dataStore.email || '',
        phoneNumber: dataStore.phoneNumber || '',
        address: dataStore.address || '',
        latitude: dataStore.latitude || '',
        longitude: dataStore.longitude || '',
        imageStore: image,
      }
      if (fileImage) {
        const { data } = await api.uploadImageStaff({
          token: auth.getToken(),
          image: fileImage,
        })
        update = { ...update, imageStore: image ? data : '' }
      }
      const { success } = await api.updateStore(dataStore._id, update)

      if (success) {
        setSuccess(true)
        setEdit(false)
        setTimeout(() => {
          setSuccess(false)
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        <Scrollbar id='setting-dataStore'>
          <BoxContainer>
            <Box>
              <ImageBox background={image ? image : ''}>
                {!image && <CameraIcon size='30' />}
                <ImageUploadIcon>
                  <label
                    for='upload-image'
                    onClick={e => (edit ? '' : e.preventDefault())}
                  >
                    <UploadImageIcon size='25' colorCircle='#8C56D9' />
                  </label>
                  <input
                    id='upload-image'
                    type='file'
                    onChange={onChangeImage}
                    style={{ display: 'none', visibility: 'none' }}
                  />
                </ImageUploadIcon>
              </ImageBox>
              <BoxStyle flex='1 0 25%' paddingLeft='1rem'>
                <TextLabel fontWeight='600'>Store ID</TextLabel>
                <InputText
                  borderRadius='4px'
                  boxShadow='none'
                  height='35px'
                  padding='0 0 0 1rem'
                  background={edit ? '#FFFFFF' : '#F8F9FB'}
                  color={edit ? '#000000' : '#98A1B2'}
                  value={dataStore.storeId}
                  onChange={val => onStoreIdChange(val.target.value)}
                  readOnly={!edit}
                  maxLength={4}
                />
              </BoxStyle>
              <BoxStyle flex='1 0 25%' paddingLeft='1rem'>
                <TextLabel fontWeight='600'>ชื่อร้าน</TextLabel>
                <InputText
                  borderRadius='4px'
                  boxShadow='none'
                  height='35px'
                  padding='0 0 0 1rem'
                  background={edit ? '#FFFFFF' : '#F8F9FB'}
                  color={edit ? '#000000' : '#98A1B2'}
                  value={dataStore.name}
                  onChange={val => onNameChange(val.target.value)}
                  readOnly={!edit}
                />
              </BoxStyle>
              <BoxStyle flex='1 0 25%' paddingLeft='1rem'>
                <TextLabel fontWeight='600'>ชื่อสาขา</TextLabel>
                <InputText
                  borderRadius='4px'
                  boxShadow='none'
                  height='35px'
                  padding='0 0 0 1rem'
                  background={edit ? '#FFFFFF' : '#F8F9FB'}
                  color={edit ? '#000000' : '#98A1B2'}
                  value={dataStore.branch}
                  onChange={val => onBranchChange(val.target.value)}
                  readOnly={!edit}
                />
              </BoxStyle>
            </Box>
            <Box>
              <BoxStyle flex='1 0 50%' paddingRight='0.5rem'>
                <TextLabel fontWeight='600'>อีเมล</TextLabel>
                <InputText
                  borderRadius='4px'
                  boxShadow='none'
                  height='35px'
                  padding='0 0 0 1rem'
                  background={edit ? '#FFFFFF' : '#F8F9FB'}
                  color={edit ? '#000000' : '#98A1B2'}
                  value={dataStore.email}
                  type='email'
                  onChange={val => onEmailChange(val.target.value)}
                  readOnly={!edit}
                />
              </BoxStyle>
              <BoxStyle flex='1 0 50%' paddingLeft='0.5rem'>
                <TextLabel fontWeight='600'>เบอร์โทรศัพท์</TextLabel>
                <InputText
                  borderRadius='4px'
                  boxShadow='none'
                  height='35px'
                  padding='0 0 0 1rem'
                  background={edit ? '#FFFFFF' : '#F8F9FB'}
                  color={edit ? '#000000' : '#98A1B2'}
                  value={dataStore.phoneNumber || ''}
                  onChange={val => onPhoneNumberChange(val.target.value)}
                  readOnly={!edit}
                />
              </BoxStyle>
            </Box>
            <Box>
              <BoxStyle flex='1 0 50%' paddingRight='0.5rem'>
                <TextLabel fontWeight='600'>Facebook (Link)</TextLabel>
                <InputText
                  borderRadius='4px'
                  boxShadow='none'
                  height='35px'
                  padding='0 0 0 1rem'
                  background={edit ? '#FFFFFF' : '#F8F9FB'}
                  color={edit ? '#000000' : '#98A1B2'}
                  value={dataStore.facebook}
                  onChange={val => onFacebookChange(val.target.value)}
                  readOnly={!edit}
                />
              </BoxStyle>
              <BoxStyle flex='1 0 50%' paddingLeft='0.5rem'>
                <TextLabel fontWeight='600'>Line ID</TextLabel>
                <InputText
                  borderRadius='4px'
                  boxShadow='none'
                  height='35px'
                  padding='0 0 0 1rem'
                  background={edit ? '#FFFFFF' : '#F8F9FB'}
                  color={edit ? '#000000' : '#98A1B2'}
                  value={dataStore.lineId}
                  onChange={val => onLineIdChange(val.target.value)}
                  readOnly={!edit}
                />
              </BoxStyle>
            </Box>
            <Box>
              <BoxStyle>
                <TextLabel fontWeight='600'>ที่อยู่ร้าน</TextLabel>
                <InputText
                  borderRadius='4px'
                  boxShadow='none'
                  height='35px'
                  padding='0 0 0 1rem'
                  background={edit ? '#FFFFFF' : '#F8F9FB'}
                  color={edit ? '#000000' : '#98A1B2'}
                  value={dataStore.address}
                  onChange={val => onAddressChange(val.target.value)}
                  readOnly={!edit}
                />
              </BoxStyle>
            </Box>
            <Box>
              <BoxStyle>
                <GoogleMap
                  latitude={dataStore.latitude || ''}
                  longitude={dataStore.longitude || ''}
                  onLocationChange={onLocationChange}
                />
              </BoxStyle>
            </Box>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                justifyContent: 'flex-end',
                position: 'sticky',
                bottom: '0rem',
                zIndex: '2',
                background: '#FFFFFF',
              }}
            >
              {!edit ? (
                <Button2
                  backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                  color='#FFFFFF'
                  icon={<EditIcon color='#FFFFFF' size='15' />}
                  onClick={() => setEdit(true)}
                >
                  แก้ไขข้อมูลร้าน
                </Button2>
              ) : (
                <div
                  className='mb-2'
                  style={{ width: '100%', display: 'flex' }}
                >
                  <div
                    className='mr-1'
                    style={{ display: 'flex', width: '50%' }}
                  >
                    <Button2
                      color='#98A1B2'
                      border='2px solid #98A1B2'
                      backgroundColor='white'
                      backgroundColorHover='#E5E7EC'
                      fontWeight='600'
                      onClick={() => onCancelClick()}
                    >
                      ยกเลิก
                    </Button2>
                  </div>
                  <div
                    className='ml-1'
                    style={{ display: 'flex', width: '50%' }}
                  >
                    <ButtonSave
                      border='2px solid #53389E'
                      color='#FFFFFF'
                      backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                      backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                      fontWeight='600'
                      icon={<SaveIcon size='15' />}
                      onClick={() => onSaveClick()}
                    >
                      บันทึก
                    </ButtonSave>
                  </div>
                </div>
              )}
            </div>
          </BoxContainer>
          {success && (
            <SuccessIcon2 position='absolute' height='calc(100% - 1rem)' />
          )}
        </Scrollbar>
      </Card>
    </Container>
  )
}

export default DataStore
