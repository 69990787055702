import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'
import PopupAddPayChannel from '../popups/PopupAddPayChannel'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const BoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`

const BoxGroupStyle = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  border-color: ${props => props.borderColor || '#FFF'};
  border-style: solid;
  border-width: 2px;
`

const Box = styled.div`
  width: 100%;
  border-radius: ${props => props.borderRadius || '0px'};
  padding: 0.75rem 1rem;
  border-color: ${props => props.borderColor || '#e4e8eb'};
  border-style: solid;
  border-width: 2px;
  display: flex;
  align-items: center;
  &:first-child {
    border-radius: ${props => (props.active ? '8px' : '8px 8px 0px 0px')};
    border-width: ${props => (props.active ? '2px' : '2px 2px 0 2px')};
  }
  &:last-child {
    border-radius: ${props => (props.active ? '8px' : '0px 0px 8px 8px')};
    border-width: ${props => (props.active ? '2px' : '0 2px 2px 2px')};
    margin-bottom: 1rem;
  }
  &:only-child {
    border-radius: 8px;
    border-width: 2px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }
`

const BoxData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BoxDataStyle = styled.div`
  display: flex;
  align-items: center;
`

const BoxArrow = styled.div`
  background: #f8f9fb;
  border-radius: 8px;
  transform: translate(-50%);
  padding: 0.25rem 0.65rem;
`

const Arrow = styled.div`
  border: solid #475467;
  border-width: 0 3px 3px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  /* margin-left: 5px; */
  transition: all 0.4s ease;
  transform: ${props => props.transform || 'rotate(45deg)'};
`

const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.16);
  border-radius: 8px;
  padding: 0.5rem;
  top: 10px;
  right: 0px;
  z-index: 1;
`

const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`

const BoxEdit = styled.div`
  width: 25px;
  display: flex;
  justify-content: flex-end;
  cursor: ${props => props.cursor || 'none'};
  position: relative;
  /* width: ${props => props.width || '4%'}; */
`

const BoxCircle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 33%'};
  max-width: ${props => props.width || '33%'};
  margin: ${props => props.margin || '0.25rem 0 0 0'};
`

const Circle = styled.div`
  background: #98a1b2;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`

const BodyPayChannel = ({ payChannels: _payChannels = [], update }) => {
  const payChannelGroup = [
    { payChannelTypeId: 1, payChannelType: 'เงินสด' },
    { payChannelTypeId: 2, payChannelType: 'บัตรเครดิต' },
    { payChannelTypeId: 3, payChannelType: 'เงินโอน' },
    { payChannelTypeId: 4, payChannelType: 'ออนไลน์' },
    { payChannelTypeId: 5, payChannelType: 'เอเจนซี่' },
    {
      payChannelTypeId: -1,
      payChannelType: 'ช่องทางการจ่ายที่ถูกปิดการใช้งาน',
    },
  ]
  const [payChannels, setPayChannels] = useState([])
  const [activeEdit, setActiveEdit] = useState(-1)
  const [currentIdEdit, setCurrentIdEdit] = useState(-1)
  const [currentPayChannel, setCurrentPayChannel] = useState('')
  const [activeAdd, setActiveAdd] = useState(-1)

  useEffect(() => {
    let payChannels = []
    let payDisabled = _payChannels.filter(p => p.status === -1)
    for (let i = 0; i < payChannelGroup.length; i++) {
      const { payChannelTypeId, payChannelType } = payChannelGroup[i]

      payChannels.push({
        payChannelTypeId: payChannelTypeId,
        payChannelType: payChannelType,
        payChannels: [],
        open: true,
      })

      for (let j = 0; j < _payChannels.length; j++) {
        const { payChannelTypeId: _payChannelTypeId, status } = _payChannels[j]
        if (_payChannelTypeId === payChannelTypeId && status !== -1) {
          payChannels[i].payChannels.push(_payChannels[j])
        }
      }
    }
    payChannels[payChannels.length - 1].payChannels = [...payDisabled]

    setPayChannels(payChannels)
  }, [_payChannels])

  const onArrowClick = index => {
    const newArrPayChannel = [...payChannels]
    newArrPayChannel[index].open = !newArrPayChannel[index].open
    setPayChannels(newArrPayChannel)
  }
  const onOpenEditClick = (e, _id) => {
    e.stopPropagation()
    if (_id === activeEdit) {
      setActiveEdit(-1)
    } else {
      setActiveEdit(_id)
    }
  }
  const onEditPayChannelClick = (e, _id) => {
    e.stopPropagation()
    const findPayChannelTypeIndex = payChannels.findIndex(c =>
      c.payChannels.find(b => b._id === _id)
    )
    const findBookingChannel = payChannels[
      findPayChannelTypeIndex
    ].payChannels.find(b => b._id === _id)
    if ([1, 5].includes(findBookingChannel.payChannelTypeId)) {
      return false
    } else if (findBookingChannel) {
      setActiveAdd(findBookingChannel.payChannelTypeId)
      setCurrentIdEdit(_id)
      setCurrentPayChannel(findBookingChannel)
    }
    setActiveEdit(-1)
  }

  const onDeletePayChannelClick = (e, _id) => {
    e.stopPropagation()
  }

  const handleClose = async () => {
    update()
    setCurrentIdEdit(-1)
    setActiveEdit(-1)
    setCurrentPayChannel('')
    setActiveAdd(-1)
  }

  return (
    <Container>
      <Scrollbar id='setting-body-payChannel'>
        <BoxGroup>
          {payChannels.map(
            (
              { payChannelTypeId, payChannelType, payChannels, open },
              index
            ) => (
              <BoxGroupStyle key={index}>
                <BoxData>
                  <BoxDataStyle>
                    <TextLabel fontWeight='500' fontSize='1.25rem'>
                      {payChannelType}
                    </TextLabel>
                    &nbsp;&nbsp; &nbsp;
                    <TextLabel
                      color='#98A1B2'
                      fontWeight='500'
                      fontSize='0.75rem'
                    >{`(${payChannels.length} ช่องทาง)`}</TextLabel>
                  </BoxDataStyle>
                  {payChannels.length !== 0 && (
                    <BoxDataStyle>
                      <BoxArrow onClick={() => onArrowClick(index)}>
                        <Arrow
                          transform={open ? 'rotate(-135deg)' : 'rotate(45deg)'}
                          padding='2.5px'
                        />
                      </BoxArrow>
                    </BoxDataStyle>
                  )}
                </BoxData>
                {open && (
                  <div className='mt-2'>
                    <div>
                      {payChannels.map(({ _id, payChannel }) => (
                        <Box
                          key={_id}
                          borderColor={activeEdit === _id ? '#8C56D9' : ''}
                          onClick={e => onEditPayChannelClick(e, _id)}
                        >
                          <BoxData>
                            <BoxDataStyle>
                              <TextLabel>{payChannel}</TextLabel>
                            </BoxDataStyle>
                            <BoxDataStyle>
                              {![1, 5].includes(payChannelTypeId) && (
                                <BoxEdit onClick={e => onOpenEditClick(e, _id)}>
                                  {Array.from({ length: 3 }).map((_, index) => (
                                    <BoxCircle key={index}>
                                      <Circle></Circle>
                                    </BoxCircle>
                                  ))}
                                  {activeEdit === _id && (
                                    <DropDown>
                                      <Option
                                        onClick={e =>
                                          onEditPayChannelClick(e, _id)
                                        }
                                      >
                                        แก้ไข
                                      </Option>
                                      <Option
                                        onClick={e =>
                                          onDeletePayChannelClick(e, _id)
                                        }
                                      >
                                        ลบ
                                      </Option>
                                    </DropDown>
                                  )}
                                </BoxEdit>
                              )}
                            </BoxDataStyle>
                          </BoxData>
                        </Box>
                      ))}
                    </div>
                    {/* {payChannelTypeId !== 1 && payChannelTypeId !== -1 && (
                      <Button2
                        width='fit-content'
                        padding='0.5rem'
                        borderRadius='8px'
                        backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                        color='#FFF'
                        size='0.85rem'
                        onClick={() => onAddPayChannelClick(payChannelTypeId)}
                      >{`เพิ่ม${payChannelType}`}</Button2>
                    )} */}
                  </div>
                )}
              </BoxGroupStyle>
            )
          )}
        </BoxGroup>
      </Scrollbar>
      {(activeAdd !== -1 || currentIdEdit !== -1) && (
        <PopupAddPayChannel
          handleClose={handleClose}
          activeAdd={activeAdd}
          currentIdEdit={currentIdEdit}
          currentPayChannel={currentPayChannel}
          payChannels={_payChannels}
        />
      )}
    </Container>
  )
}

export default BodyPayChannel
