import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// @ts-ignore
import TextLabel from '../../atoms/TextLabel'

import DateIcon2 from '../../lib/icons/DateIcon2'

// @ts-ignore
import utils from '../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Line = styled.div`
  border-top: 1px solid #e5eced;
  width: 100%;
`
const BoxIcon = styled.div`
  background-color: #8c56d9;
  padding: 0.75rem 0.85rem;
  border-radius: 8px;
`
const FlexBox = styled.div<any>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: 100%;
  gap: ${props => props.gap || '0'};
`

const BoxDate = ({ bookingCode, date = new Date() }: any) => {
  const { t } = useTranslation()

  return (
    <Container>
      <FlexBox gap='0.5rem'>
        <BoxIcon>
          <DateIcon2 size='20' color='#FFFFFF' />
        </BoxIcon>
        <FlexBox flexDirection='column'>
          <TextLabel fontWeight='500'>{`${
            dayjs(new Date()).isSame(date, 'date') ? `(${t('today')})` : ''
          }${utils.formatDate(date, 'dddd')}, ${utils.formatDate(
            date,
            'DD MMMM YYYY'
          )}`}</TextLabel>
          <TextLabel color='#98A1B2' fontWeight='500'>
            {`${bookingCode && `[${utils.formatBookingCode(bookingCode)}]`}`}
          </TextLabel>
        </FlexBox>
      </FlexBox>
      <Line className='mt-2 mb-2'></Line>
    </Container>
  )
}

export default BoxDate
