import { SelectorOption } from './Option'

export default interface Occupation {
  _id: number
  occupation: string
  status: number
  brandId: number
  storeId: number
}

export const convertOccupationsToOptions = (occupations = []) => {
  const options = occupations.reduce((acc, info) => {
    const { status, _id, occupation } = info

    if (status) {
      acc.push({ value: _id, label: occupation })
    }

    return acc
  }, [] as SelectorOption[])

  options.push({ value: -1, label: 'ไม่ระบุ' })

  return options
}
