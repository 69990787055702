export const SET_EXPAND = 'SET_EXPAND'
export const TOGGLE_EXPAND = 'TOGGLE_EXPAND'
export const CHANGE_SEARCH = 'CHANGE_SEARCH'
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'

export const setExpand = (data: any) => {
  return {
    type: SET_EXPAND,
    payload: data,
  }
}

export const toggleExpand = () => {
  return {
    type: TOGGLE_EXPAND,
  }
}

export const changeSearch = (value: string) => {
  return {
    type: CHANGE_SEARCH,
    payload: value,
  }
}

export const toggleNotification = () => {
  return {
    type: TOGGLE_NOTIFICATION,
  }
}

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH,
  }
}
