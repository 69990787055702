import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'

import Button from '@/components/atoms/Button'
import { Row } from '@/components/atoms/Flex'
import {
  FemaleIcon,
  LGBTQPlusIcon,
  MaleIcon,
} from '@/components/atoms/icons/Gender'
import useDebouncedValue from '@/hook/useDebouncedValue'
import {
  filterGenderScheduleAtom,
  searchScheduleAtom,
} from '@/jotai/SchedulerAtom'

import useSchedule from './useSchedule'

import InputText from '../../../atoms/InputText'
import SearchIcon from '../../../atoms/SearchIcon'
import SwitchButton from '../../../atoms/SwitchButton'
import TextLabel from '../../../atoms/TextLabel'

interface FilterStyle extends CSSProperties {}

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 50%) minmax(0, 50%);
  width: 100%;
  gap: 0.5rem;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
`
const Card = styled.div`
  display: flex;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
`

interface FilterProps {
  onSwitchTable: () => void
  tableActive: string
}

const Filter = ({ tableActive, onSwitchTable }: FilterProps) => {
  const [search, setSearch] = useState('')
  const [searchValue, setSearchValue] = useAtom(searchScheduleAtom)
  const [genderValue, setGenderValue] = useAtom(filterGenderScheduleAtom)

  const searchTerm = useDebouncedValue(search, {
    ms: 500,
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    setSearchValue(searchTerm)
  }, [searchTerm])

  return (
    <Container>
      <GridBox>
        <GridBox>
          <InputText
            icon={<SearchIcon size='18' color='#C8CDD7' />}
            onChange={handleChange}
          />
          <Row gap='6px'>
            <Button
              variant={genderValue === 0 ? 'primary' : 'ghost'}
              size='icon'
              onClick={e => {
                if (genderValue === 0) {
                  setGenderValue(null)
                } else {
                  setGenderValue(0)
                }
              }}
            >
              <FemaleIcon size='18' />
            </Button>
            <Button
              variant={genderValue === 1 ? 'primary' : 'ghost'}
              size='icon'
              onClick={e => {
                if (genderValue === 1) {
                  setGenderValue(null)
                } else {
                  setGenderValue(1)
                }
              }}
            >
              <MaleIcon size='18' />
            </Button>
            <Button
              variant={genderValue === 2 ? 'primary' : 'ghost'}
              size='icon'
              onClick={e => {
                if (genderValue === 2) {
                  setGenderValue(null)
                } else {
                  setGenderValue(2)
                }
              }}
            >
              <LGBTQPlusIcon size='18' />
            </Button>
          </Row>
        </GridBox>
        <FlexBox>
          <Card>
            <TextLabel>หมอ</TextLabel>
            <SwitchButton
              name='Schedule'
              toggled={tableActive === 'room'}
              onChange={onSwitchTable}
              backgroundColor='red'
              title=''
              labelWidth='50px'
              labelHeight='20px'
              width=''
              height=''
              color=''
            />
            <TextLabel>ห้อง</TextLabel>
          </Card>
        </FlexBox>
      </GridBox>
    </Container>
  )
}

export default Filter
