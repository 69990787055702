import React from 'react'
import styled from 'styled-components'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import './Scrollbar.css'

const CloseRTL = styled.div`
  height: 15px;
  width: 1.5px;
  background-color: #23272a;
  transform: rotate(-45deg);
  z-index: 1;
  cursor: pointer;
`
const CloseLTR = styled.div`
  height: 15px;
  width: 1.5px;
  background-color: #23272a;
  transform: rotate(90deg);
  z-index: 2;
  cursor: pointer;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
  object-fit: cover;
`

const PopupShownSlip = ({ handleClose, images = [] }) => {
  return (
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='28%'
        height='70%'
        padding='1rem 1rem 2rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
        alignItems='flex-start'
        borderRadius='8px'
      >
        <div
          className='mr-2'
          style={{ marginLeft: 'auto' }}
          onClick={handleClose}
        >
          <CloseRTL>
            <CloseLTR></CloseLTR>
          </CloseRTL>
        </div>
        <div className='scrollbar-show-slip' style={{ width: '100%' }}>
          {images.map(({ image }, index) => (
            <Image key={index} src={image} />
          ))}
        </div>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupShownSlip
