import React, { useEffect, useState } from 'react'

import { SelectorOption } from '@/redux/models/Option'

import SelectorBase from './SelectorBase'

//@ts-ignore
import api from '../../api'
import Option from '../../redux/models/Option'
import { ProductGroup } from '../../redux/models/ProductGroup'

interface SelectorProductGroupProps {
  name: string
  open: boolean
  onClick: () => void
  option: Option
  onOptionClick: (option: SelectorOption) => void
  error: boolean
  readOnly: boolean
}

const SelectorProductGroup = ({
  name = 'productGroup',
  open,
  onClick,
  option = {
    value: -1,
    label: '',
  },
  onOptionClick,
  error = false,
  readOnly = false,
}: SelectorProductGroupProps) => {
  const [productGroup, setProductGroup] = useState<Option[]>([
    { value: -1, label: '' },
  ])

  useEffect(() => {
    callApiProductGroup()
  }, [])

  const callApiProductGroup = async () => {
    try {
      const { data, success } = await api.getProductGroupByHeadGroup(2)
      if (success) {
        if (data.length) {
          const productGroup = data.reduce(
            (acc: Option[], info: ProductGroup) => {
              acc.push({ value: info._id, label: info.groupName })
              return acc
            },
            []
          )
          setProductGroup(productGroup)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <SelectorBase
      name={name}
      option={
        option.label ? option : productGroup.length ? productGroup[0] : option
      }
      options={productGroup}
      open={open}
      onClick={onClick}
      onOptionClick={onOptionClick}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorProductGroup
