import React from 'react'

const PromotionBathIcon = ({ size, color = '' }) => (
  <svg
    width={size || '64'}
    height={size || '64'}
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='64' height='64' rx='32' fill={color || '#FF5152'} />
    <g clipPath='url(#clip0_663_3)'>
      <path
        d='M35.4069 28.4318C35.4069 26.9669 34.4234 25.6328 32.4353 25.6328H32.3516V31.2622H32.4353C34.2978 31.2622 35.4069 30.0432 35.4069 28.4318Z'
        fill={color || 'white'}
      />
      <path
        d='M30.9399 32.5391H29.2344V38.1789H30.9399V32.5391Z'
        fill='white'
      />
      <path
        d='M30.9399 25.6328H29.2344V31.2465H30.9399V25.6328Z'
        fill='white'
      />
      <path
        d='M33.6647 32.5391H32.3516V38.1789H33.6647C34.0437 38.2028 34.4234 38.1467 34.7792 38.0141C35.135 37.8815 35.4589 37.6756 35.7298 37.4096C36.0007 37.1436 36.2126 36.8235 36.3517 36.4702C36.4908 36.1169 36.5539 35.7383 36.537 35.359C36.537 33.6953 35.4802 32.5391 33.6647 32.5391Z'
        fill='white'
      />
      <path
        d='M31.9948 14C28.434 14 24.9532 15.056 21.9925 17.0344C19.0319 19.0127 16.7245 21.8247 15.3621 25.1145C13.9996 28.4044 13.6434 32.0244 14.3385 35.5167C15.0336 39.009 16.7487 42.2167 19.2669 44.7342C21.7851 47.2517 24.9934 48.9659 28.4859 49.66C31.9784 50.354 35.5983 49.9967 38.8877 48.6334C42.1772 47.27 44.9885 44.9617 46.966 42.0005C48.9435 39.0393 49.9985 35.5582 49.9974 31.9974C49.9974 29.6335 49.5317 27.2928 48.627 25.1089C47.7222 22.925 46.396 20.9407 44.7243 19.2695C43.0525 17.5982 41.0679 16.2726 38.8837 15.3685C36.6996 14.4643 34.3587 13.9993 31.9948 14V14ZM34.2706 39.9131H32.3506V42.1C32.3506 42.2873 32.2762 42.467 32.1437 42.5994C32.0112 42.7319 31.8316 42.8063 31.6443 42.8063C31.457 42.8063 31.2773 42.7319 31.1449 42.5994C31.0124 42.467 30.938 42.2873 30.938 42.1V39.9131H28.1494C28.004 39.9131 27.8601 39.8844 27.7258 39.8286C27.5915 39.7728 27.4696 39.691 27.367 39.5879C27.2644 39.4849 27.1832 39.3625 27.1281 39.228C27.0729 39.0935 27.0448 38.9494 27.0455 38.804V24.9868C27.0448 24.8446 27.0722 24.7036 27.126 24.5719C27.1798 24.4403 27.2589 24.3205 27.359 24.2195C27.4591 24.1184 27.5781 24.0381 27.7092 23.983C27.8404 23.9279 27.9811 23.8992 28.1233 23.8986H30.938V22.4703C30.938 22.283 31.0124 22.1033 31.1449 21.9709C31.2773 21.8384 31.457 21.764 31.6443 21.764C31.8316 21.764 32.0112 21.8384 32.1437 21.9709C32.2762 22.1033 32.3506 22.283 32.3506 22.4703V23.8986H33.0359C35.9814 23.8986 37.5667 25.6878 37.5667 28.1311C37.5863 28.8312 37.4199 29.5241 37.0844 30.139C36.749 30.7538 36.2564 31.2687 35.6571 31.6312C36.5571 31.8378 37.3556 32.3548 37.9124 33.0915C38.4692 33.8281 38.7488 34.7374 38.702 35.6596C38.702 37.925 37.2946 39.9131 34.2706 39.9131Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_663_3'>
        <rect
          width='36'
          height='36'
          fill='white'
          transform='translate(14 14)'
        />
      </clipPath>
    </defs>
  </svg>
)
export default PromotionBathIcon
