import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import TextLabel from '../../atoms/TextLabel'
import Button3 from '../../atoms/Button3'
import ExpensesIcon from '../../atoms/ExpensesIcon'
import RevenueIcon from '../../atoms/RevenueIcon'
import utils from '../../utils'
import BahtIcon3 from '../../atoms/BahtIcon3'
import Scrollbar from '../../atoms/Scrollbar'
import PopupAddLedger from '../popups/PopupAddLedger'
import { useSelector } from 'react-redux'
import { UserTypes, getCurrentStoreRole } from '../../redux/models/User'
import api from '../../api'
import auth from '../../auth'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
  margin-right: 1rem;
  overflow: hidden;
`
const BoxBtn = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
`
const BoxBtnInside = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
`
const Btn = styled.div`
  flex: 1 0 auto;
  width: auto;
  margin-right: 0.5rem;
`
const BoxDetail = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 0.5rem;
`
const BoxDetailInside = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const BoxText = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex: ${props => props.flex || '1 0 auto'};
  max-width: ${props => props.width || 'auto'};
  margin: ${props => props.margin || 'auto 0'};
  gap: 0.25rem;
`
const Circle = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: ${props => props.backgroundColor || '#32D582'};
  display: flex;
  align-items: center;
  justify-content: center;
`
const BoxPrice = styled.div`
  width: auto;
  background: ${props => props.backgroundColor || 'rgba(50, 213, 130, 0.16)'};
  color: ${props => props.color || '#32D582'};
  padding: 0 1rem;
  font-weight: 600;
  border-radius: 4px;
`

const BodyLeft = ({ cashierData, onUpdateSaveClick }) => {
  const { t } = useTranslation()

  const user = useSelector(state => state.user)
  const role = getCurrentStoreRole(user)

  const [otherTransactions, setOtherTransactions] = useState([])
  const [transactions, setTransactions] = useState([])
  const [users, setUsers] = useState([])
  const [activeBtnLedger, setActiveBtnLedger] = useState(0)
  const [isOpenPopupAddLedger, setIsOpenPopupAddLedger] = useState(false)
  const [activeEdit, setActiveEdit] = useState({})
  const btnLedger = [t('all'), t('expenses'), t('income')]
  const btnLedgerIcon = [
    <BahtIcon3 size='20' key={0} />,
    <ExpensesIcon size='20' key={1} />,
    <RevenueIcon size='20' key={2} />,
  ]

  useEffect(() => {
    const callApiUser = async () => {
      try {
        const { data, success } = await api.getUserByStoreId(auth.getStoreId())
        if (success) {
          setUsers(data)
        }
      } catch (err) {
        console.log(err)
      }
    }
    callApiUser()
  }, [])

  useEffect(() => {
    if (cashierData) {
      if (cashierData.open) {
        setOtherTransactions(cashierData.otherTransactions)
        setTransactions(cashierData.transactions)
      } else {
        setOtherTransactions([])
        setTransactions([])
      }
    } else {
      setOtherTransactions([])
    }
    setIsOpenPopupAddLedger(false)
    setActiveEdit({})
  }, [cashierData])

  const onBtnClick = index => {
    if (cashierData.open) {
      if (index === 0) {
        setOtherTransactions(cashierData.otherTransactions)
        setTransactions(cashierData.transactions)
      } else if (index === 1) {
        const _otherTransactions = cashierData.otherTransactions.filter(
          o => o.IncomeOrExpense === -1
        )
        const _transactions = cashierData.transactions.filter(
          o => o.IncomeOrExpense === -1
        )
        setOtherTransactions(_otherTransactions)
        setTransactions(_transactions)
      } else if (index === 2) {
        const _otherTransactions = cashierData.otherTransactions.filter(
          o => o.IncomeOrExpense === 1
        )
        const _transactions = cashierData.transactions.filter(
          o => o.IncomeOrExpense === 1
        )
        setOtherTransactions(_otherTransactions)
        setTransactions(_transactions)
      }
    }
    setActiveBtnLedger(index)
  }

  const listButton = btnLedger.map((btn, index) => (
    <Btn key={index}>
      <Button3
        backgroundColor='#FFFFFF'
        backgroundColorActive='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
        active={activeBtnLedger === index}
        icon={btnLedgerIcon[index]}
        colorIcon='#8C56D9'
        padding='0.5rem'
        onClick={() => onBtnClick(index)}
      >
        {btn}
      </Button3>
    </Btn>
  ))

  const searchUser = (userId, _userId) => {
    const userName = users.find(u => u._id === userId || '') || ''
    const _userName = users.find(u => u._id === _userId || '') || ''
    return `${t('editedBy')} ${_userName.firstName || userName.firstName || ''}`
  }

  const onEditLedgerClick = _id => {
    if ([UserTypes.Cashier, UserTypes.General].includes(role)) {
      return false
    }
    const findOtherTransaction = otherTransactions.find(o => o._id === _id)
    if (findOtherTransaction && findOtherTransaction.type !== 3) {
      setActiveEdit(findOtherTransaction)
      setIsOpenPopupAddLedger(true)
    }
  }

  const listOtherTransactions = otherTransactions.map(
    (
      {
        price,
        detail,
        dateTime,
        createBy,
        IncomeOrExpense,
        _id,
        updateBy,
        type,
      },
      index
    ) => (
      <BoxDetail
        className='mr-2'
        key={index}
        onClick={() => onEditLedgerClick(_id)}
      >
        <BoxDetailInside>
          <BoxText flex='1 0 11%' width='11%'>
            {IncomeOrExpense === 1 ? (
              <Circle>
                <RevenueIcon size='25' color='white' />
              </Circle>
            ) : (
              <Circle backgroundColor='#FF5152'>
                <ExpensesIcon size='25' color='white' />
              </Circle>
            )}
          </BoxText>
          <BoxText
            flex='1 0 29%'
            width='29%'
            flexDirection='column'
            style={{ lineHeight: '1.3' }}
          >
            <TextLabel fontWeight='500'>
              {type === 3 ? 'tip พนักงาน' : detail}
            </TextLabel>
            <TextLabel color='#98A1B2' fontSize='0.9rem'>
              {IncomeOrExpense === 1
                ? t('additionalIncome')
                : t('additionalExpense')}
            </TextLabel>
          </BoxText>
          <BoxText
            flex='1 0 29%'
            width='29%'
            flexDirection='column'
            style={{ lineHeight: '1.3' }}
          >
            <TextLabel>{dayjs(dateTime).format('HH:mm:ss')}</TextLabel>
            <TextLabel color='#98A1B2'>
              {dayjs(dateTime).format('DD/MM/YYYY')}
            </TextLabel>
          </BoxText>
          <BoxText
            flex='1 0 29%'
            width='29%'
            flexDirection='column'
            style={{ lineHeight: '1.3' }}
          >
            <div style={{ display: 'flex' }}>
              <BoxPrice
                color={IncomeOrExpense === -1 ? '#FF5152' : ''}
                backgroundColor={
                  IncomeOrExpense === -1 ? 'rgba(255, 81, 82, 0.16)' : ''
                }
              >
                {`${IncomeOrExpense === 1 ? `+` : `-`}  ${utils.formatNumber(
                  price || 0
                )}`}
              </BoxPrice>
            </div>
            <TextLabel>{searchUser(createBy, updateBy)}</TextLabel>
          </BoxText>
        </BoxDetailInside>
      </BoxDetail>
    )
  )

  const listTransactions = transactions.map(
    ({ price, detail, dateTime, createBy, IncomeOrExpense }, index) => (
      <BoxDetail className='mr-2' key={index}>
        <BoxDetailInside>
          <BoxText flex='1 0 11%' width='11%'>
            {IncomeOrExpense === 1 ? (
              <Circle>
                <RevenueIcon size='25' color='white' />
              </Circle>
            ) : (
              <Circle backgroundColor='#FF5152'>
                <ExpensesIcon size='25' color='white' />
              </Circle>
            )}
          </BoxText>
          <BoxText
            flex='1 0 29%'
            width='29%'
            flexDirection='column'
            style={{ lineHeight: '1.3' }}
          >
            <TextLabel fontWeight='500'>{`${t('treatmentRevenue')}`}</TextLabel>
            <TextLabel color='#98A1B2' fontSize='0.9rem'>
              {IncomeOrExpense === 1 ? t('income') : t('expenses')}
            </TextLabel>
          </BoxText>
          <BoxText
            flex='1 0 29%'
            width='29%'
            flexDirection='column'
            style={{ lineHeight: '1.3' }}
          >
            <TextLabel>{dayjs(dateTime).format('HH:mm:ss')}</TextLabel>
            <TextLabel color='#98A1B2'>
              {dayjs(dateTime).format('DD/MM/YYYY')}
            </TextLabel>
          </BoxText>
          <BoxText
            flex='1 0 29%'
            width='29%'
            flexDirection='column'
            style={{ lineHeight: '1.3' }}
          >
            <div style={{ display: 'flex' }}>
              <BoxPrice
                color={IncomeOrExpense === -1 ? '#FF5152' : ''}
                backgroundColor={
                  IncomeOrExpense === -1 ? 'rgba(255, 81, 82, 0.16)' : ''
                }
              >
                {`${IncomeOrExpense === 1 ? `+` : `-`}  ${utils.formatNumber(
                  price || 0
                )}`}
              </BoxPrice>
            </div>
            <TextLabel>{searchUser(createBy)}</TextLabel>
          </BoxText>
        </BoxDetailInside>
      </BoxDetail>
    )
  )

  const onSaveClick = async (type, data) => {
    try {
      onUpdateSaveClick(type, data, activeEdit._id)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <BoxBtn>
        <BoxBtnInside>{listButton}</BoxBtnInside>
      </BoxBtn>
      <Scrollbar id='cashier-bodyLeft'>
        {listOtherTransactions}
        {listTransactions}
      </Scrollbar>
      {isOpenPopupAddLedger && (
        <PopupAddLedger
          handleClose={() => setIsOpenPopupAddLedger(false)}
          onSave={onSaveClick}
          activeEdit={activeEdit}
        />
      )}
    </Container>
  )
}

export default BodyLeft
