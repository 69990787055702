import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
`
const Card = styled.div`
  width: 100%;
  background: #f8f9fb;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
`
const Box = styled.div`
  background: ${props =>
    props.active
      ? 'linear-gradient(148.45deg, #8C56D9 -2.71%, #53389E 98.6%), #E4E8EB'
      : '#E4E8EB'};
  color: ${props => (props.active ? '#FFFFFF' : '#000000')};
  border-radius: 8px;
  padding: ${props => props.padding || '0'};
  flex: ${props => props.flex || '1 0 auto'};
  width: ${props => props.width || 'auto'};
  text-align: center;
  font-size: 0.8rem;
  font-weight: ${props => props.fontWeight || '500'};
  margin-right: 0.25rem;
  cursor: pointer;
  :hover {
    background: linear-gradient(266.17deg, #8d67cd 1.44%, #6e57ae 100%);
    color: #ffffff;
  }
`
const ContainerMonth = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  flex-wrap: ${props => props.flexWarp || 'none'};
  padding: ${props => props.padding || '0'};
  border-radius: 0 0 8px 8px;
  background: #f8f9fb;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  /* margin-left: 1rem; */
`
const ContainerMonthStyle = styled.div`
  display: flex;
  flex-grow: ${props => props.flexGrow || '10'};
  /* width: 100%; */
`
const BoxMoth = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  padding: ${props => props.padding || '0'};
`

const BoxMonthStyle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 10%'};
  max-width: ${props => props.width || '10%'};
  padding: ${props => props.padding || '0 0.25rem 0.25rem 0'};
`
const ButtonMonth = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${props => props.padding || '0'};
  margin: ${props => props.margin || '0'};
  background: ${props =>
    props.active
      ? 'linear-gradient(179.6deg, #8C56D9 0.05%, #53389E 99.66%)'
      : '#FFFFFF'};
  color: ${props => (props.active ? '#FFFFFF' : '#000000')};
  border-radius: 8px;
  font-size: 0.8rem;
  padding: 0.2rem 0.85rem;
  font-weight: 500;
  cursor: pointer;
`

const PayDays = ({
  defaultOptionPaymentCondition,
  payDays,
  onSelectPayDays,
}) => {
  const days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์']
  const month = Array.from({ length: 31 }).map((_, index) => index + 1)

  return (
    <Container>
      <Card>
        {(defaultOptionPaymentCondition.value === 1 ||
          defaultOptionPaymentCondition.value === 2) && (
          <>
            {days.map((day, index) => (
              <Box
                key={index}
                flex='1 0 14%'
                width='14%'
                padding='4% 1%'
                onClick={onSelectPayDays.bind(this, index)}
                active={!payDays.length ? false : payDays.includes(index)}
              >
                {day}
              </Box>
            ))}
          </>
        )}
        {(defaultOptionPaymentCondition.value === 3 ||
          defaultOptionPaymentCondition.value === 4) && (
          <ContainerMonth flexWarp='wrap'>
            <ContainerMonthStyle>
              <BoxMoth padding='0.125rem'>
                {month.map((date, index) => (
                  <BoxMonthStyle key={index}>
                    <ButtonMonth
                      active={!payDays.length ? false : payDays.includes(index)}
                      onClick={() => onSelectPayDays(index)}
                    >
                      {date}
                    </ButtonMonth>
                  </BoxMonthStyle>
                ))}
              </BoxMoth>
            </ContainerMonthStyle>
          </ContainerMonth>
        )}
      </Card>
    </Container>
  )
}

export default PayDays
