import React, { useEffect, useState } from 'react'

import Option, { SelectorOption } from '@/redux/models/Option'

import SelectorBase from './SelectorBase'

//@ts-ignore
import api from '../../api'
import RoomType from '../../redux/models/RoomType'

interface SelectorRoomTypeProps {
  name: string
  open: boolean
  onClick: () => void
  option: Option
  onOptionClick: (option: SelectorOption, name: string) => void
  error: boolean
  readOnly: boolean
}

const SelectorRoomType = ({
  name = 'roomType',
  open,
  onClick,
  option = {
    value: -1,
    label: '',
  },
  onOptionClick,
  error = false,
  readOnly = false,
}: SelectorRoomTypeProps) => {
  const [roomTypes, setRoomTypes] = useState<Option[]>([
    { value: -1, label: '' },
  ])

  useEffect(() => {
    callApiRoomType()
  }, [])

  const callApiRoomType = async () => {
    try {
      const { data, success } = await api.getRoomTypeByStoreId()
      if (success) {
        if (data.length) {
          const roomTypes = data.reduce((acc: Option[], info: RoomType) => {
            acc.push({ value: info._id, label: info.roomTypeName })
            return acc
          }, [])
          setRoomTypes(roomTypes)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <SelectorBase
      name={name}
      option={option.label ? option : roomTypes.length ? roomTypes[0] : option}
      options={roomTypes}
      open={open}
      onClick={onClick}
      onOptionClick={(item, name) => onOptionClick(item, name)}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorRoomType
