import 'swiper/swiper-bundle.min.css'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import CardMenuProduct from './CardMenuProduct'
import CardMenuService from './CardMenuService'
import useCardMenu from './useCardMenu'

import { StaffOrderState } from '../types'

//@ts-ignore
import Button2 from '../../../atoms/Button2'
import InputText from '../../../atoms/InputText'
//@ts-ignore
import Scrollbar from '../../../atoms/Scrollbar'
import SearchIcon from '../../../atoms/SearchIcon'

interface CardMenuStyle extends CSSProperties {
  active?: boolean
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 8px;
  flex: 1;
  overflow: hidden;
  gap: 0.5rem;
`
const BoxMenu = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`
const BoxProductGroup = styled.div<CardMenuStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  background: ${props =>
    props.active
      ? 'linear-gradient(179.6deg, #8C56D9 0.05%, #53389E 99.66%)'
      : '#f8f9fb'};
  color: ${props => (props.active ? '#FFFFFF' : '#00000')};
  border-radius: 8px;
  cursor: pointer;
`

interface CardMenuProps {
  staffOrders: StaffOrderState[]
}

const CardMenu = ({ staffOrders }: CardMenuProps) => {
  const { t } = useTranslation()
  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { instant, buyProduct, buyPackage } = bookingOrderState

  const {
    serviceGroups,
    productGroups,
    packageGroup,
    activeMenu,
    activeGroup,
    search,
    onGroupClick,
    onSearchChange,
    onActiveMenuClick,
  } = useCardMenu()
  return (
    <Container>
      <BoxMenu>
        {!buyProduct && !buyPackage && (
          <Button2
            backgroundColor='#FFFFFF'
            border={
              activeMenu === 'service'
                ? '1px solid #8C56D9'
                : '1px solid #E4E8EB'
            }
            color={activeMenu === 'service' ? '#8C56D9' : ''}
            fontWeight='500'
            onClick={(e: any) => {
              instant ? e.preventDefault() : onActiveMenuClick('service')
            }}
          >
            {t('service')}
          </Button2>
        )}
        {!buyPackage && (
          <Button2
            backgroundColor='#FFFFFF'
            border={
              activeMenu === 'product'
                ? '1px solid #8C56D9'
                : '1px solid #E4E8EB'
            }
            color={activeMenu === 'product' ? '#8C56D9' : ''}
            fontWeight='500'
            onClick={(e: any) => {
              instant ? e.preventDefault() : onActiveMenuClick('product')
            }}
          >
            {t('product')}
          </Button2>
        )}
      </BoxMenu>

      <InputText
        icon={<SearchIcon size='15' color='#E4E8EB' />}
        placeholder={
          activeMenu === 'service'
            ? t('serviceSearch') + ''
            : activeMenu === 'product'
            ? t('productSearch') + ''
            : t('searchPackage') + ''
        }
        value={search}
        onChange={val => onSearchChange(val.target.value)}
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <Swiper
          slidesPerView={2}
          spaceBetween={5}
          pagination={{ clickable: true }}
        >
          <SwiperSlide style={{ width: '200px' }}>
            <BoxProductGroup
              active={activeGroup === -1}
              onClick={() => onGroupClick(-1)}
            >
              {t('all')}
            </BoxProductGroup>
          </SwiperSlide>

          {(activeMenu === 'service'
            ? serviceGroups
            : activeMenu === 'product'
            ? productGroups
            : packageGroup
          )
            // : packageGroup
            .map(({ _id, groupName }, idx) => (
              <SwiperSlide key={idx}>
                <BoxProductGroup
                  active={_id === activeGroup}
                  onClick={() => onGroupClick(_id)}
                >
                  {groupName}
                </BoxProductGroup>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <Scrollbar id='cardMenu' paddingRight='0' gap='8px'>
        {activeMenu === 'service' && (
          <CardMenuService
            serviceGroups={serviceGroups}
            search={search}
            activeGroup={activeGroup}
            staffOrders={staffOrders}
          />
        )}
        {activeMenu === 'product' && (
          <CardMenuProduct
            productGroups={productGroups}
            search={search}
            activeGroup={activeGroup}
          />
        )}
      </Scrollbar>
    </Container>
  )
}

export default CardMenu
