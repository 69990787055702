import dayjs from 'dayjs'
import { Dispatch } from 'redux'

import logicEndTime from '@/components/booking/logicEndTime'
import utils from '@/utils'

import {
  BookingHistory,
  CustomerBookingHistory,
  Order,
  ProductBookingHistory,
  TransactionTypes
} from '../models/BookingHistory'
import {
  BookOrder,
  CustomerOrder,
  ProductOrder,
  RoomOrder,
  ServiceOrder,
  StaffOrder
} from '../models/BookOrder'
import Service, { TimePrice } from '../models/Service'

export const GET_ORDER_BOOKING = 'GET_ORDER_BOOKING'
export const CLEAR_ORDER_BOOKING = 'CLEAR_ORDER_BOOKING'
export const BookHistory_TO_BookOrder = 'BookHistory_TO_BookOrder'

export interface GetOrderBookingAction {
  type: typeof GET_ORDER_BOOKING
  payload: {
    data: any
  }
}

export interface ClearOrderBookingAction {
  type: typeof CLEAR_ORDER_BOOKING
}

export interface BookHistoryToBookOrderAction {
  type: typeof BookHistory_TO_BookOrder
}

export const getOrderBooking = (data: any): GetOrderBookingAction => {
  return {
    type: GET_ORDER_BOOKING,
    payload: { data },
  }
}

export type OrderBookingActionTypes =
  | GetOrderBookingAction
  | ClearOrderBookingAction
  | BookHistoryToBookOrderAction

export const getOrderBookingFromSearch = (data: any) => {
  return async (
    dispatch: Dispatch<OrderBookingActionTypes>,
    getState: () => any
  ) => {
    await dispatch(getOrderBooking(data))
  }
}

export const clearOrderBooking = (): ClearOrderBookingAction => {
  return {
    type: CLEAR_ORDER_BOOKING,
  }
}

export const bookHistoryToBookOrder = (
  bookingHistory: BookingHistory,
  services: Service[]
): BookOrder => {
  const { customers } = bookingHistory
  const newArrCustomer: CustomerOrder[] = []

  for (let i = 0; i < customers.length; i++) {
    const { orders } = customers[i]
    const newArrService: ServiceOrder[] = []

    for (const order of orders) {
      const { staffs, rooms } = order
      const newArrStaff: StaffOrder[] = []

      for (const staff of staffs) {
        newArrStaff.push({
          staffTypeId: staff.staffTypeId,
          staffId: staff.staffId,
          staffName: staff.staffName,
          staffImage: staff.staffImage,
          requestStaff: staff.requestStaff,
          queueFree: staff.queueFree,
          duration: staff.duration,
          tip: staff.tip,
        })
      }

      const newArrRoom: RoomOrder[] = []

      for (const room of rooms) {
        newArrRoom.push({
          order: room.order,
          roomTypeId: room.roomTypeId,
          roomTypeName: room.roomTypeName,
          roomTypeCode: room.roomTypeCode,
          roomId: room.roomId,
          roomName: room.roomName,
          roomCode: room.roomCode,
          private: room.private,
          duration: room.duration,
          detail: room.detail,
        })
      }

      const findService = services.find(
        (s: Service) => s._id === order.serviceId
      )

      newArrService.push({
        time: order.time,
        price: order.price,
        serviceId: order.serviceId,
        serviceMasterId: order.serviceMasterId,
        serviceDiscount: order.serviceDiscount,
        unit: 1,
        serviceName: order.service,
        serviceImage: order.image,
        serviceError: false,
        activeTimePriceIndex:
          findService?.timePrices.findIndex(
            (t: TimePrice) => t.time === order.time
          ) || 0,
        serviceCustomer: order.serviceCustomer,
        primaryCustomerIndex: order.primaryCustomerIndex,
        serviceRelatedCustomers: order.serviceRelatedCustomers,
        timePrices: findService?.timePrices || [],
        applyToAll: findService?.applyToAll || false,
        staffs: newArrStaff,
        rooms: newArrRoom,
      })
    }

    newArrCustomer.push({
      customer: i,
      customerId: customers[i].customerId,
      name: customers[i].name,
      firstName: customers[i].firstName,
      lastName: customers[i].lastName,
      countryCode: customers[i].countryCode,
      phoneNumber: customers[i].phoneNumber,
      email: customers[i].email,
      address: '',
      taxIDNumber: '',
      gender: customers[i].gender,
      age: { value: customers[i].age, label: '' },
      nationality: { value: customers[i].nationalityId, label: '' },
      services: newArrService,
      specialRequest: customers[i].specialRequest,
    })
  }

  const newArrProduct: ProductOrder[] = []

  for (const product of bookingHistory.products) {
    newArrProduct.push({
      _id: product.productId,
      name: product.product,
      image: product.productImage,
      price: product.price,
      quantity: product.quantity,
    })
  }

  const maxServiceTime = logicEndTime.checkServiceMaxTime(newArrCustomer)

  let startTime =
    bookingHistory.statusId === 4 || bookingHistory.statusId === 3
      ? bookingHistory.startTime
      : bookingHistory.time

  let endTime = utils.convertTimeToHourMinute(
    utils.convertTime(startTime) + maxServiceTime
  )

  return {
    _id: bookingHistory._id,
    customerId: bookingHistory.customers[0].customerId,
    name: bookingHistory.customers[0].name,
    firstName: bookingHistory.customers[0].firstName,
    lastName: bookingHistory.customers[0].lastName,
    email: bookingHistory.customers[0].email,
    countryCode: bookingHistory.customers[0].countryCode,
    phoneNumber: bookingHistory.customers[0].phoneNumber,
    address: '',
    taxIDNumber: '',
    specialRequest: bookingHistory.customers[0].specialRequest,
    note: bookingHistory.note,
    products: newArrProduct,
    promotions: [],
    packages: [],
    customers: newArrCustomer,
    date: bookingHistory.date,
    time: bookingHistory.time,
    startTime: startTime,
    endTime: endTime,
    bookingType: bookingHistory.bookingType,
    bookingChannel: bookingHistory.bookingChannel,
    bookingCode: bookingHistory.bookingCode,
    discount: bookingHistory.discount,
    serviceDiscount: bookingHistory.serviceDiscount,
    packageDiscount: bookingHistory.packageDiscount,
    charge: bookingHistory.charge,
    commission: bookingHistory.commission,
    total: bookingHistory.total,
    tipBox: bookingHistory.tipBox,
    totalTip: bookingHistory.totalTip,
    payStatus: bookingHistory.payStatus,
    payChannels: [],
    edit: false,
    statusId: bookingHistory.statusId,
    requireCustomerDetail: true,
    activeBtnPayChannelType: 0,
    reorder: false,
    instant: false,
    instantId: -1,
    buyPackage: bookingHistory.transactionType === TransactionTypes.Package,
    checkCustomerAndService: [],
    focusedCustomerIndex: 0,
    buyProduct: bookingHistory.transactionType === TransactionTypes.Product,
    serviceTime: maxServiceTime,
    countCustomer: newArrCustomer.length,
    serviceCount: bookingHistory.orderCount,
    productCount: newArrProduct.length,
    packageCount: bookingHistory.packageCount,
    selectStaff: false,
    transactionType: bookingHistory.transactionType,
  }
}

export const bookOrderToBookHistory = (bookOrder: BookOrder) => {
  const { customers, time, date, products } = bookOrder

  let total: number = 0
  let newArrCustomer: CustomerBookingHistory[] = []

  for (let i = 0; i < customers.length; i++) {
    const {
      services,
      customerId,
      name,
      firstName,
      lastName,
      phoneNumber,
      specialRequest,
      age,
      gender,
      nationality,
    } = customers[i]

    let orders: Order[] = []
    for (const service of services) {
      const { staffs, rooms } = service
      if (service.serviceId !== -1)
        orders.push({
          serviceMasterId: service.serviceMasterId,
          serviceId: service.serviceId,
          service: service.serviceName,
          image: service.serviceImage,
          time: service.time,
          price: service.price,
          cost: 0,
          timePriceId:
            service.timePrices[service.activeTimePriceIndex].timePriceId,
          serviceCustomer: service.serviceCustomer,
          primaryCustomerIndex: service.primaryCustomerIndex,
          serviceRelatedCustomers: service.serviceRelatedCustomers,
          staffs: staffs,
          rooms: rooms,
          serviceDiscount: 0,
          choiceId: [],
          choicePrice: [],
          statusId: 1,
        })
      total += service.price || 0
    }

    newArrCustomer.push({
      customerId,
      name,
      firstName,
      lastName,
      email: '',
      countryCode: '+66',
      phoneNumber,
      specialRequest,
      age: age.value,
      gender: gender,
      nationalityId: nationality.value,
      orderCount: services.length,
      orders,
    })
  }

  const newArrProduct: ProductBookingHistory[] = []

  for (const orderProduct of products) {
    newArrProduct.push({
      productId: orderProduct._id,
      product: orderProduct.name,
      productImage: orderProduct.image,
      price: orderProduct.price,
      quantity: orderProduct.quantity,
    })
    total += (orderProduct.price || 0) * (orderProduct.quantity || 0)
  }

  // return {
  //   date: dayjs(date)
  //     .startOf('date')
  //     .add(+time.hour, 'hour')
  //     .add(+time.minute, 'minute')
  //     .toDate(),
  //   time: { hour: time.hour + '', minute: time.minute + '' },
  //   customerId: bookOrder.customerId,
  //   name: bookOrder.name,
  //   firstName: bookOrder.firstName,
  //   lastName: bookOrder.lastName,
  //   email: bookOrder.email,
  //   countryCode: bookOrder.countryCode,
  //   phoneNumber: bookOrder.phoneNumber,
  //   specialRequest: bookOrder.specialRequest,
  //   total,
  //   customers: newArrCustomer,
  //   products: newArrProduct,
  //   customerCount: bookOrder.countCustomer,
  //   orderCount: bookOrder.serviceCount,
  //   statusId: 1,
  //   transactionType:
  //     bookOrder.serviceCount > 1 && bookOrder.productCount > 1
  //       ? 3
  //       : bookOrder.transactionType,
  //   totalDiscount: 0,
  //   payChannelTypeTip: -1,
  //   billCode: '',
  //   receiptNumber: '',
  //   storeId: -1,
  //   remark: bookOrder.note,
  //   note: bookOrder.note,
  //   payslip: [],
  // }

  // customerId: number
  // name: string
  // firstName: string
  // lastName: string
  // email: string
  // countryCode: string
  // phoneNumber: string
  // specialRequest: string
  // customers: [
  //   {
  //     customerId: number
  //     name: string
  //     firstName: string
  //     lastName: string
  //     email: string
  //     countryCode: string
  //     phoneNumber: string
  //     specialRequest: string
  //     age: number
  //     gender: number
  //     nationalityId: number
  //     orders: [
  //       {
  //         serviceMasterId: string
  //         serviceId: number
  //         service: string
  //         image: string
  //         time: number
  //         price: number
  //         cost: number
  //         timePriceId: number
  //         serviceCustomer: number
  //         staffTypeId: number
  //         staffId: number
  //         staffName: string
  //         staffImage: string
  //         primaryCustomerIndex: {
  //           customerIndex: number
  //           serviceIndex: number
  //         }
  //         serviceRelatedCustomers: number[]
  //         staffs: [
  //           {
  //             staffTypeId: number
  //             staffId: number
  //             staffName: string
  //             staffImage: string
  //             tip: number
  //             queueFree: boolean
  //             requestStaff: boolean
  //             duration: number
  //           }
  //         ]
  //         rooms: [
  //           {
  //             order: number
  //             roomTypeId: number
  //             roomTypeName: string
  //             roomTypeCode: string
  //             roomId: number
  //             roomName: string
  //             roomCode: string
  //             private: number
  //             duration: number
  //             detail: string
  //           }
  //         ]
  //         queueFree: boolean
  //         requestStaff: boolean
  //         choiceId: [number]
  //         choicePrice: [number]
  //         statusId: number
  //         tip: number
  //         serviceDiscount: number
  //       }
  //     ]
  //     roomTypeId: number
  //     roomTypeName: string
  //     roomId: number
  //     room: string
  //     private: number
  //     orderCount: number
  //   }
  // ]
  // customerCount: number
  // orderCount: number
  // date: Date
  // time: { hour: string; minute: string }
  // startTime: { hour: string; minute: string }
  // note: string
  // products: [
  //   {
  //     productId: number
  //     product: string
  //     productImage: string
  //     price: number
  //     quantity: number
  //   }
  // ]
  // productCount: number
  // packages: [
  //   {
  //     packageId: number
  //     name: string
  //     price: number
  //     quantity: number
  //   }
  // ]
  // packageCount: number
  // statusId: number // เริ่มจาก 0
  // transactionType: number // รูปการซื้อเป็นแบบไหน 1=Service 2=Product 3=ServiceAndProduct 4=Package
  // bookingType: {
  //   bookingTypeId: number
  //   bookingType: string
  // }
  // bookingChannel: {
  //   bookingChannelId: number
  //   bookingChannel: string
  //   code: string
  //   commissionPercentage: number
  // }
  // payStatus: number
  // payChannel: {
  //   payChannelTypeId: number // 1 เงินสด 2 บัตรเครดิต 3 โอนเงิน 4 e-wlllet 5 เอเจนซี่
  //   payChannelId: number
  //   payChannel: string
  //   note: string
  //   chargePercentage: number
  // }
  // payChannels: [
  //   {
  //     payChannelTypeId: number // 1 เงินสด 2 บัตรเครดิต 3 โอนเงิน 4 e-wlllet 5 เอเจนซี่ 6 แพ็คเกจ
  //     payChannelId: number // กรณีใช้package จะเป็น customerPackageId
  //     payChannel: string // package name
  //     note: string
  //     quantity: number // จำนวนแพ็คเกจที่ถูกใช้
  //     chargePercentage: number
  //     price: number
  //     remainingValue: number // จำนวนแพ็คเกจที่เหลือ
  //   }
  // ]
  // promotions: [{ promotionId: number }]
  // discount: number
  // serviceDiscount: number
  // packageDiscount: number
  // totalDiscount: number
  // total: number
  // tipBox: number
  // totalTip: number
  // payChannelTypeTip: number
  // charge: number
  // commission: number
  // bookingCode: string
  // payslip: [string]
  // billCode: string
  // receiptNumber: string
  // storeId: number
  // remark: string
  // instant: boolean
  // createBy: { userId: number; userName: string }
  // updateBy: { userId: number; userName: string }
  // createdAt: Date
  // updatedAt: Date
}
