import {
  GET_DATA,
  GET_ORDER_FROM_POPUP,
} from '../actions/dataBookToPopupAction'

const initState = {
  customerInformation: [],
  service: [],
}

const dataBookToPopupReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        customerInformation: action.payload.data,
      }
    case GET_ORDER_FROM_POPUP:
      return {
        ...state,
        service: action.payload.service,
      }
    default:
      return state
  }
}

export default dataBookToPopupReducers
