import React, { useEffect, useState } from "react";
import { StyledInputGroup } from "../input/InputGroup";
import styled from "styled-components";
import { ISelectItem } from "./Select";

export const StyledGridSelector = styled(StyledInputGroup)`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
    gap: 0.5rem;
    width: 100%;
`;

export interface GridSelectorItemProps {
    selected: boolean
    items?: ISelectItem[]
}

export const StyledGridSelectorItem = styled.div<GridSelectorItemProps>`
    cursor: pointer;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    background-color: ${props => props.selected ? 'var(--primary)' : 'var(--input)'};
    color: ${props => props.selected ? 'white' : 'black'};
`;

export interface GridSelectorProps {
    items?: any[]
    onValueChange?: (item: any) => void | Promise<void>
    value?: Array<string>
    defaultValue?: Array<string>
}

export const GridSelector = (props: GridSelectorProps) => {
    const { items, defaultValue = [], value, onValueChange } = props
    const [selected, setSelected] = React.useState<Array<string>>(props?.value || [])
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (defaultValue && !initialized) {
            setSelected(defaultValue)
            setInitialized(true)
            console.log('default value', defaultValue)
            onValueChange && onValueChange(defaultValue)
        }
        if(value){
            setSelected(value || [])
        }
    }, [defaultValue, initialized, value])


    return (
        <StyledGridSelector>
            {props?.items?.map((item, index) => (
                <StyledGridSelectorItem selected={selected.includes(item?.value)} key={index} onClick={e => {
                    // Multiple selection
                    let newArray = selected
                    if (selected.includes(item?.value)) {
                        newArray = newArray.filter((value) => value !== item?.value)
                    } else {
                        newArray.push(item?.value)
                    }
                    setSelected(newArray)
                    props.onValueChange && props.onValueChange(newArray)
                }}>
                    {item?.children || null}
                </StyledGridSelectorItem>
            ))}
        </StyledGridSelector>
    )
}