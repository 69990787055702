import styled from 'styled-components'

interface GridProps {
  columns?: number | string
  gap?: string
  rowGap?: string
  columnGap?: string
}

interface GridItemProps {
  colSpan?: number | string
  rowSpan?: number | string
  justifySelf?: string
  alignSelf?: string
}

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${props =>
    typeof props.columns === 'number'
      ? `repeat(${props.columns}, minmax(0,1fr))`
      : props.columns || 'none'};
  gap: ${props => props.gap || '0'};
  row-gap: ${props => props.rowGap || props.gap || '0'};
  column-gap: ${props => props.columnGap || props.gap || '0'};
`

export const GridItem = styled.div<GridItemProps>`
  grid-column: ${props =>
    typeof props.colSpan === 'number'
      ? `span ${props.colSpan}`
      : props.colSpan || 'auto'};
  grid-row: ${props =>
    typeof props.rowSpan === 'number'
      ? `span ${props.rowSpan}`
      : props.rowSpan || 'auto'};
  justify-self: ${props => props.justifySelf || 'auto'};
  align-self: ${props => props.alignSelf || 'auto'};
`

// Responsive grid components
export const ResponsiveGrid = styled(Grid)`
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

// Helper components for common layouts
export const TwoColumnGrid = styled(Grid).attrs({ columns: 2 })``
export const ThreeColumnGrid = styled(Grid).attrs({ columns: 3 })``
export const FourColumnGrid = styled(Grid).attrs({ columns: 4 })``

// Auto-fit grid for responsive card layouts
export const AutoFitGrid = styled(Grid).attrs({
  columns: 'repeat(auto-fit, minmax(250px, 1fr))',
})``
