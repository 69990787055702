import React, { MouseEventHandler } from 'react'
import styled, { CSSProperties } from 'styled-components'

import TextLabel from './TextLabel'

const ContainerCheckbox = styled.label<CSSProperties>`
  display: block;
  position: relative;
  padding-left: ${props => props.paddingLeft || '33px'};
  margin-bottom: ${props => props.marginBottom || '10px'};
  margin-top: ${props => props.marginTop || '0'};
  line-height: ${props => props.lineHeight || ''};
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  span:after {
    left: ${props => props.left || '9px'};
    top: ${props => props.top || '5px'};
    width: ${props => props.width || '5px'};
    height: ${props => props.height || '10px'};
    border: ${props => props.border || 'solid white'};
    border-width: ${props => props.borderWidth || '0 2px 2px 0'};
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
const InputCheck = styled.input.attrs({ type: 'checkbox' })<CSSProperties>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span:after {
    display: block;
  }
  &:checked ~ span {
    background: ${props => props.backgroundColor || '#57557f'};
  }
`
const CheckMark = styled.span<CSSProperties & CheckMarkProps>`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: ${props => props.heightCheckMark || '25px'};
  width: ${props => props.widthCheckMark || '25px'};
  background-color: #ffff;
  border: ${props => props.borderCheckMark || '2px solid #C4C4C4'};
  border-radius: ${props => props.borderRadius || '5px'};
  &:hover {
    background-color: #ccc;
  }
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`

interface CheckMarkProps {
  heightCheckMark?: CSSProperties['height']
  widthCheckMark?: CSSProperties['width']
  borderCheckMark?: CSSProperties['border']
}

export interface CheckBoxProps extends CSSProperties, CheckMarkProps {
  onClick: MouseEventHandler<HTMLInputElement>
  checked: boolean
  onChange?: () => void
  text?: string
  colorText?: CSSProperties['color']
  textFont?: boolean
}

const CheckBox = ({
  onClick,
  checked,
  left,
  top,
  width,
  height,
  borderWidth,
  heightCheckMark,
  widthCheckMark,
  borderCheckMark,
  borderRadius,
  paddingLeft,
  onChange,
  text,
  backgroundColor,
  border,
  lineHeight,
  fontWeight,
  marginBottom,
  marginTop,
  colorText,
  fontSize,
  textFont = false,
}: CheckBoxProps) => {
  return (
    <ContainerCheckbox
      left={left}
      top={top}
      width={width}
      height={height}
      borderWidth={borderWidth}
      paddingLeft={paddingLeft}
      border={border}
      lineHeight={lineHeight}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <TextLabel fontWeight={fontWeight} color={colorText} fontSize={fontSize}>
        {text}
      </TextLabel>
      <InputCheck
        onClick={onClick}
        checked={checked}
        onChange={onChange}
        backgroundColor={backgroundColor}
      />
      <CheckMark
        heightCheckMark={heightCheckMark}
        widthCheckMark={widthCheckMark}
        borderRadius={borderRadius}
        borderCheckMark={borderCheckMark}
      ></CheckMark>
    </ContainerCheckbox>
  )
}

export default CheckBox
