import React, { ChangeEvent, useState } from 'react'

import InputText from '../../atoms/InputText'
import TextLabel from '../../atoms/TextLabel'

interface InputBoxProps {
  header?: string
  placeholder?: string
  icon?: React.ReactNode
  value?: string | number
  suffix?: string
  error?: boolean
  readonly?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const InputBox = ({
  header,
  placeholder,
  icon,
  value,
  suffix,
  error,
  readonly = false,
  onChange,
}: InputBoxProps) => {
  const [isFocused, setIsFocused] = useState(false)

  const onFocus = () => {
    if (readonly) return
    setIsFocused(true)
  }

  const onBlur = () => {
    if (readonly) return
    setIsFocused(false)
  }

  return (
    <div>
      {Headers && (
        <TextLabel fontSize='0.875rem' fontWeight={500}>
          {header}
        </TextLabel>
      )}
      <InputText
        icon={icon}
        value={
          isFocused
            ? value
            : value
            ? `${value}${suffix ? ` ${suffix}` : ''}`
            : ''
        }
        placeholder={placeholder || header}
        fontSize='0.875rem'
        fontSizePlaceholder='0.875rem'
        fontWeight='500'
        error={error && !value}
        readOnly={readonly}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  )
}

export default InputBox
