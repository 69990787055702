import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import api from '../../api'
import TextLabel from '../../atoms/TextLabel'
import Scrollbar from '../../atoms/Scrollbar'
import InputText from '../../atoms/InputText'
import { useDispatch } from 'react-redux'
import utils from '../../utils'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import PopupAddCredit from '../popups/PopupAddCredit'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  flex: 1;
`
const IconStyle = styled.div`
  position: absolute;
  right: 2.5rem;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
`
const DivInputStyle = styled.div`
  position: relative;
  padding: 0;
  height: fit-content;
  width: 50%;
`

const Credit = ({
  onSelectPayChannel: _onSelectPayChannel,
  onChargeChange: _onChargeChange,
  total,
  discount,
  splitPayment = false,
  charge,
}) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [payChannelLength, setPayChannelLength] = useState(0)
  const [activePayChannel, setActivePayChannel] = useState(0)
  const [creditCards, setCreditCards] = useState([])
  const [unit, setUnit] = useState(1)
  const [creditCharge, setCreditCharge] = useState('')
  const [isOpenPopupAddCredit, setIsOpenPopupAddCredit] = useState(false)
  // const [charge, setCharge] = useState(0)

  useEffect(() => {
    callApiPayChannelByTypeId()
    callApiPayChannelAll()
  }, [])

  const callApiPayChannelByTypeId = async () => {
    try {
      let creditCards = []
      const { data, success } = await api.getPayChannelByTypeId(2)
      if (success) {
        if (data.length !== 0) {
          for (const item of data) {
            const { payChannelId, payChannel, image, status } = item
            if (status !== -1)
              creditCards.push({ payChannelId, payChannel, icon: image })
          }
        }
        creditCards.push({ payChannelId: -1, payChannel: '', icon: '' })
        setCreditCards(creditCards)
        _onSelectPayChannel({
          payChannelId: creditCards[0].payChannelId,
          payChannel: creditCards[0].payChannel,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiPayChannelAll = async () => {
    try {
      const { data, success } = await api.getPayChannel()
      if (success) {
        setPayChannelLength(data.length)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onSelectPayChannel = (index, payChannelId) => {
    if (payChannelId === -1) {
      setIsOpenPopupAddCredit(true)
    } else {
      // if (splitPayment) {
      _onSelectPayChannel({
        payChannelId: creditCards[index].payChannelId,
        payChannel: creditCards[index].payChannel,
      })
      // }
      setActivePayChannel(index)
    }
  }

  const onChargeChange = value => {
    if (utils.checkNumber(value)) {
      if (!value) {
        dispatch(getOrderBooking({ charge: 0 }))
      } else {
        if (unit === 1) {
          const percent = value / 100
          const _total = (total - discount || 0) * percent
          // if (splitPayment)
          // setCharge(_total)
          _onChargeChange(unit, value)
          //  else {
          //   dispatch(getOrderBooking({ charge: _total }))
          // }
        } else {
          // if (splitPayment)
          // setCharge(value)
          _onChargeChange(unit, value)

          // else dispatch(getOrderBooking({ charge: parseInt(value) }))
        }
      }
      setCreditCharge(value)
      // if (splitPayment)
      _onChargeChange(unit, value)
    }
  }
  const onUnitChange = unit => {
    if (unit === 1) {
      const percent = (creditCharge || 0) / 100
      const _total = (total - discount || 0) * percent
      dispatch(getOrderBooking({ charge: _total }))
    } else {
      dispatch(getOrderBooking({ charge: parseInt(creditCharge || 0) }))
    }
    setUnit(unit)
  }
  // const onCreditNumberChange = value => {
  //   var reg = /^\d+$/
  //   if (reg.test(value) && value.length <= 4) {
  //     _onSelectPayChannel({
  //       payChannelId: creditCards[activePayChannel].payChannelId,
  //       payChannel: creditCards[activePayChannel].payChannel,
  //       creditNumber: value,
  //     })
  //   } else if (!value) {
  //     _onSelectPayChannel({
  //       payChannelId: creditCards[activePayChannel].payChannelId,
  //       payChannel: creditCards[activePayChannel].payChannel,
  //       creditNumber: value,
  //     })
  //   }
  // }

  const onSaveCreditClick = async _create => {
    try {
      const create = { ..._create, payChannelId: payChannelLength }
      const { data, success } = await api.createPayChannel(create)
      if (success) {
        await callApiPayChannelByTypeId()
        await callApiPayChannelAll()
        setIsOpenPopupAddCredit(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Scrollbar id='payment-credit' maxHeight='auto'>
        {/* <GridBox>
          {creditCards.map(({ payChannelId, payChannel, icon }, index) => (
            <Box
              key={index}
              border={
                payChannelId === -1
                  ? '2px dashed #E4E8EB'
                  : activePayChannel === index
                  ? '2px solid #8c56d9'
                  : ''
              }
              backgroundColor={payChannel ? '' : '#F8F9FB'}
              onClick={() => onSelectPayChannel(index, payChannelId)}
            >
              {icon ? (
                <img
                  src={icon}
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    borderRadius: '16px',
                  }}
                />
              ) : (
                <>
                  {payChannel ? (
                    payChannel
                  ) : (
                    <>
                      <FlexBox
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <TextLabel color='#475467'>+</TextLabel>
                        <TextLabel color='#475467'>บัตรชนิดอื่น</TextLabel>
                      </FlexBox>
                    </>
                  )}
                </>
              )}
            </Box>
          ))}
        </GridBox> */}
        {/* <div className='mt-3'></div> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextLabel fontWeight='600'>{t('creditCardFee')}</TextLabel>
          <TextLabel paddingLeft='0.25rem' fontSize='1rem'>
            {creditCharge ? `(${utils.formatNumber(charge || 0, 2)}฿)` : ''}
          </TextLabel>
        </div>
        <DivInputStyle>
          <InputText
            padding='0.4rem 0 0.4rem 0.5rem'
            placeholder={t('creditCardFee')}
            onChange={val => onChargeChange(val.target.value)}
            value={creditCharge}
          />
          <IconStyle>
            <div
              style={{
                display: 'flex',
                backgroundColor: '#E4E8EB',
                borderRadius: '0px 2px 2px 0px',
                width: 'calc(100% + 2.2rem)',
                alignItems: 'center',
                padding: '0.25rem',
              }}
            >
              <div
                style={{
                  width: '50%',
                  textAlign: 'center',
                  borderRadius: '4px',
                  fontWeight: `${unit === 1 ? '700' : '500'}`,
                  color: `${unit === 1 ? '#000' : '#475467'}`,
                  backgroundColor: `${unit === 1 ? '#FFF' : ''}`,
                }}
                onClick={() => onUnitChange(1)}
              >
                %
              </div>
              <div
                style={{
                  width: '50%',
                  textAlign: 'center',
                  borderRadius: '4px',
                  fontWeight: `${unit === 2 ? '700' : '500'}`,
                  color: `${unit === 2 ? '#000' : '#475467'}`,
                  backgroundColor: `${unit === 2 ? '#FFF' : ''}`,
                }}
                onClick={() => onUnitChange(2)}
              >
                ฿
              </div>
            </div>
          </IconStyle>
        </DivInputStyle>
        {/* <div className='mt-3'></div>
        <TextLabel fontWeight='500'>ระบุเลขบัตรเครดิต 4 ตัวหลัง</TextLabel>
        <GridBox columns='1fr 1fr 1fr 1fr'>
          {Array.from({ length: 4 }).map((_, index) => (
            <Box
              key={index}
              padding='0.15rem'
              backgroundColor={index !== 3 ? 'rgb(248, 249, 251)' : ''}
            >
              {index !== 3 ? (
                <TextLabel color='#98A1B2'>XXXX</TextLabel>
              ) : (
                <InputText
                  border='none'
                  borderFocus='none'
                  boxShadow='0'
                  value={creditNumber}
                  onChange={val => onCreditNumberChange(val.target.value)}
                />
              )}
            </Box>
          ))}
        </GridBox> */}
      </Scrollbar>
      {isOpenPopupAddCredit && (
        <PopupAddCredit
          handleClose={() => setIsOpenPopupAddCredit(false)}
          onSave={onSaveCreditClick}
        />
      )}
    </Container>
  )
}

export default Credit
