import React from "react";
import { Container } from "../layout/Container";

interface ComplexCheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    text: string;
    elementId: string;
}

export const ComplexCheckBox = (props: ComplexCheckBoxProps) => {
    const { text, onChange, checked, elementId, ...rest } = props;
    return (
        <Container
            gap='0.5rem'
            w="auto"
            {...rest}
        >
            <input
                type='checkbox'
                onChange={onChange}
                checked={checked}
                id={elementId}
            />
            <label htmlFor={elementId} style={{ margin: 0 }}>{text}</label>
        </Container>
    )
}