import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import CameraIcon from '../../atoms/CameraIcon'
import InputText from '../../atoms/InputText'
import TextLabel from '../../atoms/TextLabel'
import UploadImageIcon from '../../atoms/UploadImageIcon'
import utils from '../../utils'
import Selector from './Selector'
import countries from '../../countries.json'
import InputPhoneNumber from './InputPhoneNumber'
import useOnClickOutside from '../../hook/useOnClickOutside'
import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'
import ServiceSpecificStaff from './ServiceSpecificStaff'
import SwitchButton from '../../atoms/SwitchButton'
import ConnectLine from './ConnectLine'
import OptionsStaffType from './OptionsStaffType'
import api from '../../api'
import auth from '../../auth'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import ButtonSave from '../../atoms/ButtonSave'
import Scrollbar from '../../atoms/Scrollbar'
import config from '../../config'
import BoxStatus from '../../atoms/BoxStatus'

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`
const Card = styled.div`
  width: 100%;
  /* max-height: calc(100vh - 6.5rem);
  min-height: calc(100vh - 6.5rem); */
  background: #ffffff;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
const ImageBox = styled.div`
  border-radius: 16px;
  background: #e4e8eb;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  margin-top: 1rem;
  /* margin: 0 auto; */
`
const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`
const Image = styled.img`
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  object-fit: cover;
`
const Box = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: 100%;
  align-items: ${props => props.alignItem || 'unset'};
`
const BoxStyle = styled.div`
  flex: ${props => props.flex || '1 0 50%'};
  width: ${props => props.width || '50%'};
`
const BoxText = styled.div`
  display: flex;
  justify-content: space-between;
`
const Line = styled.div`
  width: 100%;
  margin: 1rem 0;
  border-top: 1px solid #e4e8eb;
`
const BoxSwitch = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
`
const BoxSwitchStyle = styled.div`
  width: 50%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const BoxDay = styled.div`
  flex: ${props => props.flex || '1 0 12.5%'};
  max-width: ${props => props.width || '12.5%'};
  min-width: ${props => props.width || '12.5%'};
  padding: 0.5rem 0rem;
  gap: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${props => (props.active ? '#E4E8EB' : '#8C56D9')};
  color: ${props => (props.active ? '#D0D4DD' : '#FFF')};
`

const CardAddStaff = ({
  handleClose,
  staffTypes,
  staffs,
  currentIdEdit = -1,
  currentDataEdit = '',
  services: _services,
}) => {
  const [image, setImage] = useState('')
  const [fileImage, setFileImage] = useState('')
  const [name, setName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [services, setServices] = useState([])
  const [status, setStatus] = useState(true)
  const [checkName, setCheckName] = useState(false)
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const days = ['จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.', 'อา.']
  const [workingDay, setWorkingDay] = useState([
    { day: 0, workingStatus: 1 },
    { day: 1, workingStatus: 1 },
    { day: 2, workingStatus: 1 },
    { day: 3, workingStatus: 1 },
    { day: 4, workingStatus: 1 },
    { day: 5, workingStatus: 1 },
    { day: 6, workingStatus: 1 },
  ])

  const optionsCountryCode = countries.map(c => c)
  const [defaultOptionCountryCode, setDefaultOptionCountryCode] = useState('')
  const [openCountryCode, setOpenCountryCode] = useState(false)
  const wrapperRefCountryCode = useRef(null)

  const optionsGender = [
    { value: 0, label: 'หญิง' },
    { value: 1, label: 'ชาย' },
    { value: 2, label: 'อื่นๆ' },
  ]
  const [defaultOptionGender, setDefaultOptionGender] = useState(
    optionsGender[0]
  )
  const [openGender, setOpenGender] = useState(false)
  const wrapperRefGender = useRef(null)

  const [defaultOptionStaffType, setDefaultOptionStaffType] = useState('')

  useEffect(() => {
    checkService()
  }, [_services])

  useEffect(() => {
    if (currentIdEdit !== -1) {
      setImage(currentDataEdit.image)
      setName(currentDataEdit.name)
      setFirstName(currentDataEdit.firstName)
      setLastName(currentDataEdit.lastName)
      setEmail(currentDataEdit.email)
      setDefaultOptionCountryCode(currentDataEdit.countryCode)
      setPhoneNumber(currentDataEdit.phoneNumber)
      const findGender =
        optionsGender.find(g => g.value === currentDataEdit.gender || '') || ''
      if (findGender) {
        setDefaultOptionGender(findGender)
      }
      setDefaultOptionStaffType(currentDataEdit.staffTypeId)
      setStatus(currentDataEdit.status === 1)
      setWorkingDay(currentDataEdit.workingDay || [])
      checkService()
    }
  }, [currentIdEdit, currentDataEdit])

  const checkService = () => {
    let services = _services.reduce(
      (a, s) => a.concat({ value: s._id, label: s.name, checked: true }),
      []
    )
    if (currentIdEdit !== -1) {
      services = services.reduce((acc, info) => {
        const finService = currentDataEdit.serviceSpecificStaff.findIndex(
          s => s.serviceId === info.value
        )
        if (finService !== -1) {
          acc.push({ ...info, checked: true })
        } else {
          acc.push({ ...info, checked: false })
        }

        return acc
      }, [])
    }
    setServices(services)
  }

  const onChangeImage = async event => {
    const file = event.target.files[0]
    if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
      alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
    } else if (utils.isFileExceedLimit(file)) {
      alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
    } else {
      setFileImage(file)
      const image = await utils.readImage(file)
      setImage(image.image)
    }
  }

  useOnClickOutside(wrapperRefCountryCode, () => {
    setOpenCountryCode(false)
  })
  const onArrowCountryCodeClick = () => {
    setOpenCountryCode(!openCountryCode)
  }
  const onPhoneNumberChange = value => {
    if (value.length <= config.MAX_PHONE_NUMBER_LENGTH) {
      if (utils.checkNumber(value)) {
        setPhoneNumber(value)
      } else if (!value) {
        setPhoneNumber('')
      }
    }
  }

  useOnClickOutside(wrapperRefGender, () => {
    setOpenGender(false)
  })
  const onArrowGenderClick = () => {
    setOpenGender(!openGender)
  }
  const onSelectOptionGenderClick = value => {
    const findGender = optionsGender.find(g => g.value === value || '') || ''
    if (findGender) {
      setDefaultOptionGender(findGender)
      setOpenGender(false)
    }
  }

  const onSelectOptionCountryCodeClick = value => {
    setDefaultOptionCountryCode(value)
    setOpenCountryCode(false)
  }

  const onSelectOptionStaffTypeClick = value => {
    setDefaultOptionStaffType(value)
  }

  const onServiceCheckChange = index => {
    const newArrService = [...services]
    newArrService[index] = {
      ...newArrService[index],
      checked: !newArrService[index].checked,
    }
    setServices(newArrService)
  }

  const onStatusChange = () => {
    setStatus(!status)
  }

  const onWorkingDayClick = index => {
    const newArrWorkingDay = [...workingDay]
    newArrWorkingDay[index].workingStatus =
      newArrWorkingDay[index].workingStatus === 1 ? -1 : 1
    setWorkingDay(newArrWorkingDay)
  }

  const onSaveClick = async () => {
    if (!name) {
      if (!name) {
        setCheckName(true)
      }
      return false
    } else {
      setCheckName(false)
      setCheckPhoneNumber(false)
    }
    try {
      setLoading(true)
      const serviceSpecificStaff = services.reduce((a, s) => {
        if (s.checked) {
          a.push({ serviceId: s.value, service: s.label })
        }
        return a
      }, [])
      let _image = image
      if (fileImage) {
        const { data } = await api.uploadImageStaff({
          token: auth.getToken(),
          image: fileImage,
        })
        _image = data
      }
      // _create = { ...create, image: data }
      if (currentIdEdit === -1) {
        const findStaffType = staffTypes.find(
          s => s.staffTypeId === (defaultOptionStaffType || 1)
        )
        let baseSalary = findStaffType.baseSalary
        let baseCommission = findStaffType.baseCommission
        let baseRequestRate = findStaffType.baseRequestRate
        let create = {
          name,
          firstName,
          lastName,
          email,
          countryCode: defaultOptionCountryCode || '+66',
          phoneNumber,
          baseSalary: baseSalary,
          baseCommission: baseCommission,
          baseRequestRate: baseRequestRate,
          workingDay: workingDay,
          gender: defaultOptionGender.value,
          staffTypeId: defaultOptionStaffType || 1,
          serviceSpecificStaff,
          status: status ? 1 : -1,
          number: staffs.length,
          storeId: auth.getStoreId(),
        }
        create = { ...create, image: _image }
        await createStaff(create)
      } else {
        const findStaffType = staffTypes.find(
          s => s.staffTypeId === (defaultOptionStaffType || 1)
        )
        let baseSalary = findStaffType.baseSalary
        let baseCommission = findStaffType.baseCommission
        let update = {
          ...currentDataEdit,
          name,
          firstName,
          lastName,
          email,
          countryCode: defaultOptionCountryCode || '+66',
          phoneNumber,
          workingDay: workingDay,
          baseCommission:
            currentDataEdit.baseCommission ||
            currentDataEdit.baseCommission === 0
              ? baseCommission
              : currentDataEdit.baseCommission,
          baseSalary:
            currentDataEdit.baseSalary || currentDataEdit.baseSalary === 0
              ? baseSalary
              : currentDataEdit.baseSalary,
          gender: defaultOptionGender.value,
          staffTypeId: defaultOptionStaffType || 1,
          serviceSpecificStaff,
          status: status ? 1 : -1,
        }
        update = { ...update, image: _image }

        await updateStaff(update)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }
  const createStaff = async create => {
    try {
      const { data, success } = await api.createStaff(create)
      if (success) {
        await checkStaffQueue(data._id, data, 'create')
        await addCommissionFees(data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const updateStaff = async update => {
    try {
      const { data, success } = await api.updateStaff(currentIdEdit, update)
      if (success) {
        setSuccess(true)
        if (update.status === 1) {
          await checkStaffQueue(data._id._id, update, 'update')
        }
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const checkStaffQueue = async (_id, staff, type) => {
    try {
      const { data } = await api.getStaffQueueByDateGreater(new Date())
      if (data.length && type === 'create') {
        const newArrStaffQueue = data.reduce((acc, info) => {
          const { queue } = info
          queue.push({ staffId: _id })
          acc.push({ ...info, queue })
          return acc
        }, [])
        await updateStaffQueue(newArrStaffQueue)
      } else if (type === 'create') {
        const create = {
          date: new Date(),
          queue: [{ staffId: _id }],
          storeId: auth.getStoreId(),
        }
        const { success } = await api.createStaffQueue(create)
        if (success) {
          setLoading(false)
          setSuccess(true)
          setTimeout(() => {
            handleClose()
          }, 1000)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const updateStaffQueue = async update => {
    try {
      const { success } = await api.updateAllStaffQueue(update)
      if (success) {
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const addCommissionFees = async staff => {
    try {
      const { data } = await api.getCommission()
      const commissionFees = data.reduce((acc, info) => {
        const {
          commissionType,
          staffTypesCommissionFees,
          serviceCommissionFees,
          staffCommissionFees,
        } = info

        const newArrStaffCommissionFees = [...staffCommissionFees]
        if (commissionType === 0) {
          newArrStaffCommissionFees.push({
            staffTypeId: staff.staffTypeId,
            staffId: staff._id,
            image: '',
            name: staff.name,
            serviceCommissionFees,
            choiceCommissionFees: [],
          })
        } else {
          const filterStaffTypeCommission = staffTypesCommissionFees.find(
            s => s.staffTypeId === staff.staffTypeId
          )
          if (filterStaffTypeCommission) {
            newArrStaffCommissionFees.push({
              staffTypeId: staff.staffTypeId,
              staffId: staff._id,
              image: '',
              serviceCommissionFees:
                filterStaffTypeCommission.serviceCommissionFees,
              choiceCommissionFees: [],
            })
          }
        }

        acc.push({ ...info, staffCommissionFees: newArrStaffCommissionFees })

        return acc
      }, [])
      await api.updateAllCommission(commissionFees)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        {/* <div
          className='mb-2'
          style={{ width: 'fit-content', marginLeft: 'auto' }}
          onClick={() => handleClose()}
        >
          <ClosePopup height='20px'></ClosePopup>
        </div> */}
        <Scrollbar id='setting-cardAddStaff'>
          <Box alignItem='flex-end'>
            <BoxStyle flex='1 0 20%' width='20%'>
              <ImageBox>
                <CameraIcon size='40' />
                <ImageUploadIcon>
                  <label for='upload-image'>
                    <UploadImageIcon size='30' colorCircle='#8C56D9' />
                  </label>
                  <input
                    id='upload-image'
                    type='file'
                    onChange={onChangeImage}
                    style={{ display: 'none', visibility: 'none' }}
                  />
                </ImageUploadIcon>
                {image && <Image src={image} />}
              </ImageBox>
            </BoxStyle>
            <BoxStyle>
              <BoxText className='mt-3 mb-2'>
                <TextLabel fontWeight='600' color={checkName ? '#DB2D1E' : ''}>
                  ชื่อเล่น*
                </TextLabel>
                <div style={{ display: 'flex' }}>
                  <TextLabel
                    color={name.length === 10 ? '#DB2D1E' : '#E4E8EB'}
                  >{`${name.length}`}</TextLabel>
                  <TextLabel color='#E4E8EB'>/10</TextLabel>
                </div>
              </BoxText>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                border={checkName ? '2px solid #DB2D1E' : ''}
                value={name}
                onChange={val => setName(val.target.value)}
              />
            </BoxStyle>
          </Box>
          <Box>
            <BoxStyle className='pr-1'>
              <BoxText className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>ชื่อจริง</TextLabel>
              </BoxText>
            </BoxStyle>
            <BoxStyle className='pl-1'>
              <BoxText className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>นามสกุล</TextLabel>
              </BoxText>
            </BoxStyle>
          </Box>
          <Box>
            <BoxStyle className='pr-1'>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                value={firstName}
                onChange={val => setFirstName(val.target.value)}
              />
            </BoxStyle>
            <BoxStyle className='pl-1'>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                value={lastName}
                onChange={val => setLastName(val.target.value)}
              />
            </BoxStyle>
          </Box>
          <Box>
            <BoxStyle className='pr-1'>
              <BoxText className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>อีเมล</TextLabel>
              </BoxText>
            </BoxStyle>
            <BoxStyle className='pl-1'>
              <BoxText className='mt-3 mb-2'>
                <TextLabel
                  fontWeight='600'
                  color={checkPhoneNumber ? '#DB2D1E' : ''}
                >
                  เบอร์โทร
                </TextLabel>
              </BoxText>
            </BoxStyle>
          </Box>
          <Box>
            <BoxStyle className='pr-1'>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                value={email}
                onChange={val => setEmail(val.target.value)}
              />
            </BoxStyle>
            <BoxStyle className='pl-1'>
              <InputPhoneNumber
                options={optionsCountryCode}
                defaultOption={defaultOptionCountryCode}
                wrapperRef={wrapperRefCountryCode}
                openArrow={openCountryCode}
                onArrowClick={onArrowCountryCodeClick}
                onPhoneNumberChange={onPhoneNumberChange}
                phoneNumber={phoneNumber}
                checkPhoneNumber={checkPhoneNumber}
                onSelectOptionClick={onSelectOptionCountryCodeClick}
              />
            </BoxStyle>
          </Box>
          <BoxText className='mt-3 mb-2'>
            <TextLabel fontWeight='600'>เพศของพนักงาน</TextLabel>
          </BoxText>
          <Selector
            options={optionsGender}
            defaultOption={defaultOptionGender}
            openArrow={openGender}
            onSelectOptionClick={onSelectOptionGenderClick}
            onArrowClick={onArrowGenderClick}
            wrapperRef={wrapperRefGender}
          />
          <OptionsStaffType
            staffTypes={staffTypes}
            defaultOptionStaffType={defaultOptionStaffType}
            onSelectOptionStaffTypeClick={onSelectOptionStaffTypeClick}
          />

          <BoxText className='mt-3 mb-2'>
            <TextLabel fontWeight='600'>วันเข้าทำงาน</TextLabel>
          </BoxText>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              gap: '0.5rem',
            }}
          >
            {workingDay.map(({ workingStatus }, index) => (
              <BoxDay
                key={index}
                active={workingStatus === -1}
                onClick={() => onWorkingDayClick(index)}
              >
                {days[index]}
              </BoxDay>
            ))}
          </div>

          <BoxText className='mt-3 mb-2'>
            <TextLabel fontWeight='600'>
              ประเภทบริการเฉพาะที่พนักงานสามารถทำได้
            </TextLabel>
          </BoxText>
          <ServiceSpecificStaff
            services={services}
            onServiceCheckChange={onServiceCheckChange}
          />
          {currentIdEdit !== -1 && (
            <ConnectLine lineId={currentDataEdit.lineStaffId} />
          )}
          <Line></Line>
          <BoxStatus
            title='สถานะ'
            detail='ไม่ใช้งาน/ใช้งาน'
            status={status}
            onChange={() => onStatusChange()}
            id='stats-staff'
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              zIndex: '2',
              background: '#FFFFFF',
            }}
          >
            <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
              <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                <Button2
                  color='#98A1B2'
                  border='2px solid #98A1B2'
                  backgroundColor='white'
                  backgroundColorHover='#E5E7EC'
                  fontWeight='600'
                  onClick={() => handleClose(true)}
                >
                  ยกเลิก
                </Button2>
              </div>
              <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                <ButtonSave
                  border='2px solid #53389E'
                  color='#FFFFFF'
                  backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                  backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                  fontWeight='600'
                  icon={<SaveIcon size='15' />}
                  disabled={loading}
                  onClick={() => onSaveClick()}
                >
                  บันทึก
                </ButtonSave>
              </div>
            </div>
          </div>
        </Scrollbar>
      </Card>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddStaff
