import { GET_INSTANT_BOOKING } from '../actions/notificationAction'

const initState = {
  bookingHistories: [],
}

const notificationReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_INSTANT_BOOKING:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}

export default notificationReducers
