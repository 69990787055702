import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import BrandPackage, {
  BrandPackageGroup,
  PackageTypes,
} from '@/redux/models/BrandPackage'

//@ts-ignore
import api from '../../../api'
import { Product } from '../../../redux/models/Product'
import Service from '../../../redux/models/Service'

export interface ServiceState {
  _id: number
  groupName: string
  number: number
  status: number
  storeId: number
  services: Service[]
}

export interface ProductState {
  _id: number
  groupName: string
  number: number
  status: number
  storeId: number
  products: Product[]
}

export interface PackageState {
  _id: number
  groupName: string
  packages: BrandPackageGroup[]
}

const useCardMenu = () => {
  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { instant, buyProduct, buyPackage } = bookingOrderState

  const [activeMenu, setActiveMenu] = useState<string>('service')
  const [activeGroup, setActiveGroup] = useState<number>(-1)

  const [serviceGroups, setServiceGroups] = useState<ServiceState[]>([])
  const [productGroups, setProductGroups] = useState<ProductState[]>([])
  const [packageGroup, setPackageGroup] = useState<PackageState[]>([])

  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    callApiServices()
    callApiProducts()
    callApiPackages()
  }, [])

  useEffect(() => {
    if (buyProduct) {
      setActiveMenu('product')
    } else if (buyPackage) {
      setActiveMenu('package')
    }
  }, [buyProduct, buyPackage])

  const callApiServices = async () => {
    try {
      const { data, success } = await api.getProductGroupAndService()
      if (success) setServiceGroups(data)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiProducts = async () => {
    try {
      const { data, success } = await api.getProductGroupAndProduct()
      if (success) setProductGroups(data)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiPackages = async () => {
    try {
      const { data, success } = await api.getPackageGroupAndPackage()
      if (success) {
        setPackageGroup(
          data.map((d: PackageState) => ({
            _id: d._id,
            groupName:
              d._id === PackageTypes.Money
                ? 'เงินสด'
                : d._id === PackageTypes.Minutes
                ? 'จำนวนชั่วโมง'
                : d._id === PackageTypes.Times
                ? 'จำนวนครั้ง'
                : 'percent',
            packages: d.packages,
          }))
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onGroupClick = (_id: number) => {
    setActiveGroup(_id)
  }

  const onSearchChange = (value: string) => {
    setSearch(value)
  }

  const onActiveMenuClick = (menu: string) => {
    setActiveGroup(-1)
    setActiveMenu(menu)
  }

  return {
    serviceGroups,
    productGroups,
    packageGroup,
    activeMenu,
    activeGroup,
    search,
    onGroupClick,
    onSearchChange,
    onActiveMenuClick,
  }
}

export default useCardMenu
