import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import api from '../../api'
import PopupAddProduct from './CardAddProduct/PopupAddProduct'
import PopupAddProductGroup from '../popups/PopupAddProductGroup'
import PopupAddServiceGroup from '../popups/PopupAddServiceGroup'
import PopupAddService from './CardAddService/PopupAddService'
import ButtonMenu from './ButtonMenu'
import Products from './Products'
import Services from './Services'
import PopupConfirmDelete from '../popups/PopupConfirmDelete'
import BodyArrangement from './BodyArrangement'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const Body = ({
  onMenuClick: _onMenuClick,
  activeAddData,
  activeAddGroupData,
  handleClose: _handleClose,
}) => {
  const [serviceGroup, setServiceGroup] = useState([])
  const [services, setServices] = useState([])
  const [productGroup, setProductGroup] = useState([])
  const [products, setProducts] = useState([])
  const [agencies, setAgencies] = useState([])
  const [currentEditId, setCurrentEditId] = useState(-1)
  const [currentEditData, setCurrentEditData] = useState('')
  const [activeMenu, setActiveMenu] = useState('บริการ')
  const [search, setSearch] = useState('')
  const [activeGroupEdit, setActiveGroupEdit] = useState(false)
  const [activeEdit, setActiveEdit] = useState(false)
  const [activeGroupDelete, setActiveGroupDelete] = useState(false)
  const [activeDelete, setActiveDelete] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    callApi()
  }, [])

  const callApi = async () => {
    await callApiProductGroup()
    await callApiProducts()
    await callApiServiceGroup()
    await callApiServices()
    await callApiBookingChannel()
  }

  const callApiProductGroup = async () => {
    try {
      const { data, success } = await api.getProductGroupByHeadGroup(2)
      if (success) {
        setProductGroup(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiServiceGroup = async () => {
    try {
      const { data, success } = await api.getProductGroupByHeadGroup(1)
      if (success) {
        setServiceGroup(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiProducts = async () => {
    try {
      const { data, success } = await api.getProduct()
      if (success) {
        setProducts(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiServices = async () => {
    try {
      const { data, success } = await api.getServices()
      if (success) {
        setServices(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiBookingChannel = async () => {
    try {
      const { data } = await api.getBookingChannelByBookingTypeId(3)
      setAgencies(data)
    } catch (err) {
      console.log(err)
    }
  }

  const onMenuClick = menu => {
    setActiveMenu(menu)
    _onMenuClick(menu)
  }
  const onSearchChange = value => {
    setSearch(value)
  }

  const handleClose = (close = false) => {
    if (!close) {
      callApi()
    }
    setActiveGroupEdit(false)
    setActiveEdit(false)
    setActiveDelete(false)
    setActiveGroupDelete(false)
    setCurrentEditId(-1)
    setCurrentEditData('')
    _handleClose()
  }

  const onEditGroupClick = id => {
    let findData = ''
    if (activeMenu === 'บริการ') {
      findData = serviceGroup.find(s => s._id === id || '') || ''
    } else if (activeMenu === 'สินค้า') {
      findData = productGroup.find(p => p._id === id || '') || ''
    }
    if (findData) {
      setCurrentEditId(id)
      setCurrentEditData(findData)
      setActiveGroupEdit(!activeGroupEdit)
    }
  }

  const onEditClick = id => {
    let findData = ''
    if (activeMenu === 'บริการ') {
      findData = services.find(s => s._id === id)
    } else if (activeMenu === 'สินค้า') {
      findData = products.find(p => p._id === id)
    }
    if (findData) {
      setCurrentEditId(id)
      setCurrentEditData(findData)
      setActiveEdit(!activeEdit)
    }
  }

  const update = () => {
    callApi()
  }

  const onDeleteClick = (id, type) => {
    setCurrentEditId(id)
    if (type === 'group') {
      setActiveGroupDelete(!activeGroupDelete)
    } else {
      setActiveDelete(!activeDelete)
    }
  }
  const onConfirmDeleteClick = async () => {
    if (
      activeGroupDelete &&
      (activeMenu === 'บริการ' || activeMenu === 'สินค้า')
    ) {
      await callApiDeleteProductGroup()
    } else if (activeDelete && activeMenu === 'บริการ') {
      await callApiDeleteService()
    } else if (activeDelete && activeMenu === 'สินค้า') {
      await callApiDeleteProduct()
    }
  }
  const callApiDeleteProductGroup = async () => {
    try {
      const { success } = await api.removeProductGroup(
        currentEditId,
        activeMenu === 'บริการ' ? 1 : 2
      )
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const callApiDeleteService = async () => {
    try {
      const { success } = await api.removeService(currentEditId)
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          handleClose()
          setSuccess(false)
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const callApiDeleteProduct = async () => {
    try {
      const { success } = await api.removeProduct(currentEditId)
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          handleClose()
          setSuccess(false)
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <ButtonMenu
        onMenuClick={onMenuClick}
        activeMenu={activeMenu}
        onSearchChange={onSearchChange}
        search={search}
      />
      {activeMenu === 'บริการ' && (
        <Services
          serviceGroup={serviceGroup}
          services={services}
          onEditGroupClick={onEditGroupClick}
          activeGroupEdit={activeGroupEdit}
          onEditClick={onEditClick}
          activeEdit={activeEdit}
          update={update}
          onDeleteClick={onDeleteClick}
          search={search}
        />
      )}
      {activeMenu === 'สินค้า' && (
        <Products
          productGroup={productGroup}
          products={products}
          onEditGroupClick={onEditGroupClick}
          activeGroupEdit={activeGroupEdit}
          onEditClick={onEditClick}
          activeEdit={activeEdit}
          update={update}
          onDeleteClick={onDeleteClick}
          search={search}
        />
      )}
      {activeMenu === 'จัดเรียง' && <BodyArrangement />}
      {(activeAddGroupData || activeGroupEdit) && activeMenu === 'บริการ' && (
        <PopupAddServiceGroup
          handleClose={handleClose}
          serviceGroup={serviceGroup}
          currentEditId={currentEditId}
          currentEditData={currentEditData}
        />
      )}
      {(activeAddData || activeEdit) && activeMenu === 'บริการ' && (
        <PopupAddService
          handleClose={handleClose}
          serviceGroup={serviceGroup}
          services={services}
          agencies={agencies}
          currentEditId={currentEditId}
          currentEditData={currentEditData}
        />
      )}
      {(activeAddGroupData || activeGroupEdit) && activeMenu === 'สินค้า' && (
        <PopupAddProductGroup
          handleClose={handleClose}
          productGroup={productGroup}
          currentEditId={currentEditId}
          currentEditData={currentEditData}
        />
      )}
      {(activeAddData || activeEdit) && activeMenu === 'สินค้า' && (
        <PopupAddProduct
          handleClose={handleClose}
          productGroup={productGroup}
          currentEditId={currentEditId}
          currentEditData={currentEditData}
        />
      )}
      {(activeDelete || activeGroupDelete) && (
        <PopupConfirmDelete
          text='ยืนยันการลบข้อมูล'
          handleClose={handleClose}
          onConfirmDeleteClick={onConfirmDeleteClick}
          success={success}
        />
      )}
    </Container>
  )
}

export default Body
