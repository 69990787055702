import React from 'react'
import { useHistory } from 'react-router-dom'

import Button2 from '../../atoms/Button2'
import ClosePopup from '../../atoms/ClosePopup'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'

import { CASHIER_URL } from '../../routes'
import { useTranslation } from 'react-i18next'

const PopupWaringCashier = ({ handleClose }) => {
  const { t } = useTranslation()

  const history = useHistory()

  const onOpenCashierClick = () => {
    return history.replace(CASHIER_URL)
  }

  return (
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='25%'
        height='auto'
        padding='1rem 1rem 2rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
        alignItems='flex-start'
        borderRadius='8px'
      >
        <div
          className='mr-2'
          style={{ marginLeft: 'auto' }}
          onClick={handleClose}
        >
          <ClosePopup />
        </div>

        <div className='mb-3 mt-2' style={{ width: '40%', margin: '0 auto' }}>
          <img src='/images/Cashier.png' style={{ width: '100%' }} />
        </div>

        <div className='mb-2' style={{ margin: '0 auto' }}>
          <TextLabel color='#8C56D9' fontWeight='600' fontSize='1.2rem'>
            {t('paymentFailed')}
          </TextLabel>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            lineHeight: '2',
          }}
        >
          <TextLabel>{t(`cashierShiftHasn'tStartedYet`)}</TextLabel>
          <TextLabel>{t('pleaseStartCashierShiftFirst')}</TextLabel>
        </div>
        <div className='mt-3' style={{ width: '100%' }}>
          <Button2
            backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
            backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
            color='white'
            fontWeight='600'
            onClick={onOpenCashierClick.bind(this)}
          >
            {t('openCashierShift')}
          </Button2>
        </div>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupWaringCashier
