import { createDynamicIcon } from "./Icon";

export const TriangleUp = createDynamicIcon(`
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.83398 9.9974H13.1673L8.50065 4.66406L3.83398 9.9974Z" fill="black"/>
</svg>
    `)

export const TriangleDown = createDynamicIcon(`
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.49674 11.3333L13.1634 6H3.83008L8.49674 11.3333Z" fill="black"/>
</svg>

    `)