import { GET_SERVICE_GROUP } from '../actions/callApiServiceGroupAction'

const initState = {}
const callApiServiceGroupReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_SERVICE_GROUP:
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

export default callApiServiceGroupReducers
