import React from 'react'

const ImageBackground = ({ size, color = '' }) => (
  <svg
    width={size || '40'}
    height={size || '40'}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.6406 10C23.3969 10 25.6406 12.2438 25.6406 15C25.6406 17.7563 23.3969 20 20.6406 20C17.8844 20 15.6406 17.7563 15.6406 15C15.6406 12.2438 17.8844 10 20.6406 10ZM20.6406 6.25C15.8094 6.25 11.8906 10.1688 11.8906 15C11.8906 19.8313 15.8094 23.75 20.6406 23.75C25.4719 23.75 29.3906 19.8313 29.3906 15C29.3906 10.1688 25.4719 6.25 20.6406 6.25Z'
      fill={color || '#E4E8EB'}
    />
    <path
      d='M12.8468 28.8609C15.2718 30.0172 17.9281 30.6234 20.6406 30.6234C23.0781 30.6234 25.4906 30.1297 27.7218 29.1797C29.8468 30.4922 31.8156 32.3359 33.6031 34.6672C33.9218 35.0797 33.8406 35.4672 33.7156 35.7234C33.5468 36.0672 33.2593 36.2422 32.8718 36.2422H7.15932C6.66557 36.2422 6.41557 35.9484 6.29057 35.6984C6.17182 35.4609 6.09057 35.0984 6.37807 34.7172C8.29682 32.1922 10.5093 30.1922 12.8406 28.8547L12.8468 28.8609ZM12.9218 24.6172C9.34682 26.1734 6.10307 28.8797 3.39057 32.4484C1.04057 35.5359 3.27182 39.9922 7.15307 39.9922H32.8656C36.7843 39.9922 38.9593 35.4984 36.5781 32.3859C34.0593 29.0922 31.0781 26.5359 27.8093 24.9484C25.6968 26.1672 23.2531 26.8672 20.6406 26.8672C17.8031 26.8672 15.1593 26.0422 12.9281 24.6234L12.9218 24.6172Z'
      fill={color || '#E4E8EB'}
    />
    <path
      d='M33.9313 3.75C35.2125 3.75 36.25 4.79375 36.25 6.06875V33.9313C36.25 35.2125 35.2062 36.25 33.9313 36.25H6.06875C4.7875 36.25 3.75 35.2062 3.75 33.9313V6.06875C3.75 4.7875 4.79375 3.75 6.06875 3.75H33.9313ZM33.9313 0H6.06875C2.71875 0 0 2.71875 0 6.06875V33.9313C0 37.2812 2.71875 40 6.06875 40H33.9313C37.2812 40 40 37.2812 40 33.9313V6.06875C40 2.71875 37.2812 0 33.9313 0V0Z'
      fill={color || '#E4E8EB'}
    />
  </svg>
)

export default ImageBackground
