import React from 'react'
import styled, { CSSProperties } from 'styled-components'

interface ChevronDownIconProps extends CSSProperties {
  flipped?: boolean
}

const SVG = styled.svg<ChevronDownIconProps>`
  transform: ${props => (props.flipped ? 'rotate(180deg)' : '')};
  transition: all 0.25s ease;
`

const ChevronDownIcon = ({ color, flipped }: ChevronDownIconProps) => (
  <SVG
    width='9'
    height='6'
    viewBox='0 0 9 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    flipped={flipped}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.5 3.78238L1.05356 0.335937L4.60525e-08 1.3895L3.97322 5.36272C4.26415 5.65365 4.73585 5.65365 5.02678 5.36272L9 1.3895L7.94644 0.335937L4.5 3.78238Z'
      fill={color || '#98A1B2'}
    />
  </SVG>
)

export default ChevronDownIcon
