import React, { useState } from 'react'

import Body from './Body'
import Head from './Head'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  const [activeMenu, setActiveMenu] = useState('การจอง')
  const [activeAddData, setActiveAddData] = useState(false)

  const onAddData = () => {
    setActiveAddData(true)
  }

  const onMenuClick = menu => {
    setActiveMenu(menu)
  }

  const handleClose = () => {
    setActiveAddData(false)
  }

  return (
    <Page>
      <Head activeMenu={activeMenu} onAddData={onAddData} />
      <div className='mt-3'></div>
      <Body
        activeAddData={activeAddData}
        activeMenu={activeMenu}
        onMenuClick={onMenuClick}
        handleClose={handleClose}
      />
    </Page>
  )
}

export default Main
