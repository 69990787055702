import React from 'react'
import styled, { CSSProperties } from 'styled-components'

import CardBook from './CardBook'
import CardMenu from './CardMenu'
import DateTime from './DateTime'
import useBook from './useBook'

import Page from '../../lib/layout/Page/Page'

interface BookStyle extends CSSProperties {}

const Container = styled.div`
  width: calc(70% - 1rem);
  display: grid;
  overflow: hidden;
`
const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
`
const Box = styled.div<BookStyle>`
  width: 100%;
  display: flex;
  overflow: ${props => props.overflow || 'hidden'};
`
const BoxStyle = styled.div<BookStyle>`
  display: flex;
  flex: ${props => props.flex || '1 0 auto'};
  max-width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};
`

const Book = () => {
  const { bookingOrderState, storeOpenCloseTime, staffOrders, roomOrders } =
    useBook()

  const { buyPackage } = bookingOrderState

  return (
    <Page>
      <Container>
        <BoxContainer>
          {!buyPackage && (
            <DateTime
              bookingOrderState={bookingOrderState}
              storeOpenCloseTime={storeOpenCloseTime}
            />
          )}
          <Box style={{ flex: '1' }}>
            <BoxStyle className='pr-1' flex='1 0 35%' width='35%'>
              <CardMenu staffOrders={staffOrders} />
              {/* <CardMenu onSelectMenu={onSelectMenu} /> */}
            </BoxStyle>
            <BoxStyle className='pl-2 pr-2' flex='1 0 65%' width='65%'>
              {bookingOrderState.buyPackage ? (
                ''
              ) : (
                <CardBook staffOrders={staffOrders} roomOrders={roomOrders} />
              )}
              {/* {buyPackage ? (
                <CardPackageDetail activeMenu={activeMenu} />
              ) : (
                <CardBooking
                  activeMenu={activeMenu}
                  staffId={staffId}
                  openTime={openTime}
                  date={bookingDate}
                  currentDate={currentDate}
                  storeOpenTime={storeOpenTime}
                  storeCloseTime={storeCloseTime}
                />
              )} */}
            </BoxStyle>
          </Box>
        </BoxContainer>
      </Container>
    </Page>
  )
}

export default Book
