import Button from '@/components/atoms/Button'
import { PageHeader } from '@/components/organisms/PageHeader'
import React, { useCallback, useMemo } from 'react'
import { FiPlusCircle } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import DropdownButton from '@/components/atoms/DropdownButton'
import { CreateStaffModal } from '@/components/organisms/modals/CreateStaffModal'
import Tab, {
  StyledTabWrapper,
  TabContent,
  TabsContainer,
} from '@/components/molecules/Tab'
import StaffIcon2 from '@/lib/icons/StaffIcon2'
import PayWageIcon from '@/lib/icons/PayWageIcon'
import { StaffListTab } from './tabs/StaffList'
import styled from 'styled-components'
import { StyledDndGroupContent } from '@/components/organisms/dnd/Group'
import Modal from '@/components/molecules/modal'
import { CreateStaffForm } from './forms/CreateStaffForm'
import { use } from 'i18next'

const StyledTab = styled(Tab)`
  ${TabContent} {
    overflow-y: auto;
    padding-bottom: 1rem;
  }
  ${StyledDndGroupContent} {
    overflow-y: auto;
    max-height: 314px;
  }
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`

interface PageProps {}

const Page = (props: PageProps) => {
  const { t } = useTranslation()
  
  return (
    <StyledContainer>
      <PageHeader>
        <DropdownButton>
          <DropdownButton.Trigger>
            <Button
              style={{
                minWidth: '124px',
              }}
            >
              <FiPlusCircle />
              {t('create')}
            </Button>
          </DropdownButton.Trigger>
          <DropdownButton.Content>
            <DropdownButton.Item>
              <Modal>
                <Modal.Trigger>{t('staff')}</Modal.Trigger>
                <Modal.Content>
                  <CreateStaffForm />
                </Modal.Content>
              </Modal>
            </DropdownButton.Item>
            <DropdownButton.Item>
              <Modal>
                <Modal.Trigger>{t('staffType')}</Modal.Trigger>
                <Modal.Content>
                  <CreateStaffForm />
                </Modal.Content>
              </Modal>
            </DropdownButton.Item>
          </DropdownButton.Content>
        </DropdownButton>
      </PageHeader>
      <StyledTab active='staff'>
        <Tab.List>
          <Tab.Item value='staff'>
            <StaffIcon2 />
            {t('Staff List')}
          </Tab.Item>
          <Tab.Item value='pay-wage'>
            <PayWageIcon />
            {t('Pay Wage')}
          </Tab.Item>
        </Tab.List>
        <Tab.Content value='staff'>
          <StaffListTab />
        </Tab.Content>
        <Tab.Content value='pay-wage'>
          <h2>{t('Pay Wage')}</h2>
        </Tab.Content>
      </StyledTab>
    </StyledContainer>
  )
}

export default Page
