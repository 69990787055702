import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'
import SwitchButton from '../../atoms/SwitchButton'
import TextLabel from '../../atoms/TextLabel'
import OptionsStaffType from './OptionsStaffType'
import TableServiceCommission from './TableServiceCommission'
import utils from '../../utils'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import ButtonSave from '../../atoms/ButtonSave'
import Scrollbar from '../../atoms/Scrollbar'

import api from '../../api'
import auth from '../../auth'

const Container = styled.div`
  width: calc(100%);
  display: flex;
  flex-direction: column;
`
const Card = styled.div`
  width: 100%;
  max-height: calc(100vh - 6.5rem);
  min-height: calc(100vh - 6.5rem);
  background: #ffffff;
  padding: 1rem 1.5rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
const BoxSwitch = styled.div`
  display: flex;
  width: 100%;
  /* margin-bottom: 2rem; */
`
const BoxSwitchStyle = styled.div`
  width: 50%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const Line = styled.div`
  width: 100%;
  margin: ${props => props.margin || '1rem 0'};
  border-top: 1px solid #e4e8eb;
`
const Image = styled.img`
  border-radius: ${props => props.borderRadius || '8px'};
  width: ${props => props.width || '60px'};
  height: ${props => props.height || '60px'};
  aspect-ratio: 1;
  object-fit: cover;
`
const Image2 = styled.div`
  border-radius: ${props => props.borderRadius || '8px'};
  width: ${props => props.width || '60px'};
  height: ${props => props.height || '60px'};
  background: #e4e8eb;
`
const BoxStaff = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  line-height: 1.8;
  background: #f8f9fb;
  border-radius: 8px;
  min-width: 100px;
  padding: 0.5rem 0.25rem;
  border: ${props => props.border || '2px solid #f8f9fb'};
  max-height: 121.88px;
  min-height: 121.88px;
`

const CarsAddCommissionFee = ({
  staffTypes: _staffTypes,
  staffs: _staffs,
  currentService,
  currentIdEdit,
  currentDataEdit,
  commissionFees,
  handleClose,
}) => {
  const [staffTypeSameCommission, setStaffTypeSameCommission] = useState(true)
  const [staffSameCommission, setStaffSameCommission] = useState(true)
  const [defaultOptionStaffType, setDefaultOptionStaffType] = useState(1)
  const [staffs, setStaffs] = useState([])
  const [serviceCommissions, setServiceCommissions] = useState([])
  const [editStaffCommission, setEditStaffCommission] = useState(-1)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (currentIdEdit !== -1) {
      let staffs = _staffs.reduce(
        (acc, info) =>
          acc.concat({
            staffId: info._id,
            staffTypeId: info.staffTypeId,
            name: info.name,
            image: info.image,
            commissionFee: 0,
          }),
        []
      )
      const tempStaff = [
        {
          staffId: -1,
          staffTypeId: -1,
          name: '',
          image: '',
          commissionFee: 0,
        },
      ]
      staffs = tempStaff.concat(staffs)

      const commissionType = _staffTypes.reduce(
        (acc, info) =>
          acc.concat({
            staffTypeId: info.staffTypeId,
            staffType: info.staffType,
            commissionFee: 0,
          }),
        []
      )
      if (commissionFees.length) {
        const {
          serviceCommissionFees,
          staffTypesCommissionFees,
          staffCommissionFees,
          commissionType: sameCommission,
        } = commissionFees[0]

        if (sameCommission === 1) {
          setStaffTypeSameCommission(false)
        } else if (sameCommission === 2) {
          setStaffTypeSameCommission(false)
          setStaffSameCommission(false)
        }

        const serviceCommissions = currentService.timePrices.reduce(
          (acc, info) => {
            const { timePriceId } = info
            const findTimePrice = serviceCommissionFees.find(
              s => s.timePriceId === timePriceId
            )

            const _commissionType = commissionType.reduce((acc, info) => {
              const { staffTypeId } = info
              const findStaffTypeIndex = staffTypesCommissionFees.findIndex(
                t => t.staffTypeId === staffTypeId
              )
              if (findStaffTypeIndex !== -1) {
                const findServiceCommission = staffTypesCommissionFees[
                  findStaffTypeIndex
                ].serviceCommissionFees.find(s => s.timePriceId === timePriceId)
                acc.push({
                  ...info,
                  commissionFee: findServiceCommission
                    ? findServiceCommission.commissionFee
                    : 0,
                })
              } else {
                acc.push({ ...info })
              }
              return acc
            }, [])

            const _commissionStaff = staffs.reduce((acc, info) => {
              const { staffId } = info
              const findStaffIndex = staffCommissionFees.findIndex(
                t => t.staffId === staffId
              )
              if (findStaffIndex !== -1) {
                const findServiceCommission = staffCommissionFees[
                  findStaffIndex
                ].serviceCommissionFees.find(s => s.timePriceId === timePriceId)
                acc.push({
                  ...info,
                  commissionFee: findServiceCommission
                    ? findServiceCommission.commissionFee
                    : 0,
                })
              } else {
                acc.push({ ...info })
              }
              return acc
            }, [])

            acc.push({
              timePriceId: info.timePriceId,
              price: info.price,
              time: info.time,
              commissionFee: findTimePrice ? findTimePrice.commissionFee : 0,
              staffTypes: _commissionType,
              staffs: _commissionStaff,
            })

            return acc
          },
          []
        )
        setServiceCommissions(serviceCommissions)
      } else {
        const serviceCommissions = currentService.timePrices.reduce(
          (acc, info) => {
            acc.push({
              timePriceId: info.timePriceId,
              price: info.price,
              time: info.time,
              commissionFee: 0,
              staffTypes: commissionType,
              staffs: staffs,
            })
            return acc
          },
          []
        )
        setServiceCommissions(serviceCommissions)
      }
      setStaffs(staffs)
    }
  }, [currentIdEdit, currentDataEdit, commissionFees])

  const onSelectOptionStaffTypeClick = value => {
    setDefaultOptionStaffType(value)
  }

  const onServiceCommissionChange = (value, index) => {
    const newArrServiceCommission = [...serviceCommissions]
    if (utils.checkNumber(value) || !value) {
      if (staffTypeSameCommission && staffSameCommission) {
        newArrServiceCommission[index].commissionFee = value
        newArrServiceCommission[index].staffTypes = newArrServiceCommission[
          index
        ].staffTypes.reduce(
          (acc, info) => acc.concat({ ...info, commissionFee: value }),
          []
        )
        newArrServiceCommission[index].staffs = newArrServiceCommission[
          index
        ].staffs.reduce(
          (acc, info) => acc.concat({ ...info, commissionFee: value }),
          []
        )
      } else if (!staffTypeSameCommission && staffSameCommission) {
        const findStaffTypeIndex = _staffTypes.findIndex(
          t => t.staffTypeId === defaultOptionStaffType
        )
        newArrServiceCommission[index].staffTypes[
          findStaffTypeIndex
        ].commissionFee = value
        newArrServiceCommission[index].staffs = newArrServiceCommission[
          index
        ].staffs.reduce((acc, info) => {
          if (info.staffTypeId === defaultOptionStaffType) {
            acc.push({ ...info, commissionFee: value })
          } else {
            acc.push({ ...info })
          }
          return acc
        }, [])
      } else if (!staffSameCommission) {
        // if (editStaffCommission === -1) {
        //   const findStaffIndex = newArrServiceCommission[
        //     index
        //   ].staffs.findIndex(s => s.staffId === editStaffCommission)

        //   newArrServiceCommission[index].staffs[findStaffIndex].commissionFee =
        //     value
        // } else {
        const findStaffIndex = newArrServiceCommission[index].staffs.findIndex(
          s => s.staffId === editStaffCommission
        )

        newArrServiceCommission[index].staffs[findStaffIndex].commissionFee =
          value
        // }
      }
    }
    setServiceCommissions(newArrServiceCommission)
  }

  const onEditStaffCommission = (staffId, index) => {
    setEditStaffCommission(staffId)
  }

  const listStaff = staffs.map(
    ({ staffId, image, name, staffTypeId }, index) => {
      return (
        staffTypeId === defaultOptionStaffType && (
          <SwiperSlide key={index}>
            <BoxStaff
              border={
                editStaffCommission === staffId ? '2px solid #8C56D9' : ''
              }
              onClick={() => onEditStaffCommission(staffId, index)}
            >
              {staffId !== -1 && (
                <>
                  {image ? (
                    <Image
                      borderRadius='50%'
                      width='40px'
                      height='40px'
                      src={image}
                    />
                  ) : (
                    <Image2
                      borderRadius='50%'
                      width='40px'
                      height='40px'
                    ></Image2>
                  )}
                </>
              )}
              <TextLabel fontSize='0.8rem' style={{ textAlign: 'center' }}>
                {staffId === -1 ? 'ค่ามือมาตราฐานที่ร้านกำหนด' : name}
              </TextLabel>
              <Button2
                backgroundColor={
                  editStaffCommission === staffId ? '#8C56D9' : '#53389E'
                }
                backgroundColorHover={
                  editStaffCommission === staffId ? '#53389E' : '#8C56D9'
                }
                color='#FFFFFF'
                padding='0.25rem 0'
                size='0.8rem'
                width='80%'
              >
                {editStaffCommission === staffId ? 'ยืนยัน' : 'แก้ไข'}
              </Button2>
            </BoxStaff>
          </SwiperSlide>
        )
      )
    }
  )

  const onStaffTypeSameCommissionChange = () => {
    setStaffTypeSameCommission(!staffTypeSameCommission)
  }
  const onStaffSameCommissionChange = () => {
    setStaffSameCommission(!staffSameCommission)
  }

  const onSaveClick = async () => {
    try {
      let sameCommission = 0
      if (!staffTypeSameCommission && !staffSameCommission) {
        sameCommission = 2
      } else if (!staffTypeSameCommission) {
        sameCommission = 1
      }
      const staffTypesCommissionFees = _staffTypes.reduce((acc, info) => {
        const { staffTypeId } = info
        let commissionFeeType = []
        for (const serviceCommission of serviceCommissions) {
          const { staffTypes, timePriceId } = serviceCommission
          const findStaffType = staffTypes.find(
            s => s.staffTypeId === staffTypeId
          )
          if (findStaffType)
            commissionFeeType.push({
              timePriceId,
              commissionFee: +findStaffType.commissionFee,
              edit: false,
            })
        }
        acc.push({
          staffTypeId,
          serviceCommissionFees: commissionFeeType,
          choiceCommissionFees: [],
        })
        return acc
      }, [])
      let staffCommissionFees = staffs.reduce((acc, info) => {
        const { staffId, name, image, staffTypeId } = info
        let commissionFeeStaff = []
        for (const serviceCommission of serviceCommissions) {
          const { staffs, timePriceId } = serviceCommission
          const findStaffStaff = staffs.find(s => s.staffId === staffId)
          if (findStaffStaff)
            commissionFeeStaff.push({
              timePriceId,
              commissionFee: +findStaffStaff.commissionFee,
              edit: false,
            })
        }
        acc.push({
          staffTypeId: staffTypeId,
          staffId: staffId,
          image: image,
          name: name,
          serviceCommissionFees: commissionFeeStaff,
          choiceCommissionFees: [],
        })
        return acc
      }, [])
      const serviceCommissionFees = serviceCommissions.reduce(
        (acc, info) =>
          acc.concat({
            timePriceId: info.timePriceId,
            commissionFee: info.commissionFee,
          }),
        []
      )
      const body = {
        serviceId: currentService._id,
        commissionType: sameCommission,
        choiceCommissionFees: [],
        staffTypesCommissionFees: staffTypesCommissionFees,
        serviceCommissionFees: serviceCommissionFees,
        staffCommissionFees: staffCommissionFees,
        storeId: auth.getStoreId(),
      }
      if (commissionFees.length) {
        callApiUpdateCommission({ ...body, _id: commissionFees[0]._id })
      } else {
        callApiCreateCommission(body)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateCommission = async body => {
    try {
      const { success } = await api.createCommission(body)
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const callApiUpdateCommission = async body => {
    try {
      const { success } = await api.updateCommission(currentIdEdit, body)
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        <Scrollbar id='setting-cardAddCommissionFee'>
          <div style={{ display: 'flex' }}>
            {currentDataEdit.image ? (
              <Image src={currentDataEdit.image} />
            ) : (
              <Image2></Image2>
            )}
            <div
              className='ml-2'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <TextLabel color='#D0D4DD'>{`กลุ่ม ${currentDataEdit.groupName}`}</TextLabel>
              <TextLabel>{`${currentDataEdit.name}`}</TextLabel>
            </div>
          </div>
          <Line margin='1rem 0 0 0'></Line>
          {!staffTypeSameCommission && (
            <OptionsStaffType
              staffTypes={_staffTypes}
              defaultOptionStaffType={defaultOptionStaffType}
              onSelectOptionStaffTypeClick={onSelectOptionStaffTypeClick}
            />
          )}
          {!staffSameCommission && !staffTypeSameCommission && (
            <div>
              <div className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>รายละเอียดค่ามือพนักงาน</TextLabel>
              </div>

              <Swiper slidesPerView={3.5} spaceBetween={30} autoHeight={true}>
                {listStaff}
              </Swiper>
            </div>
          )}
          <TableServiceCommission
            serviceCommissions={serviceCommissions}
            onCommissionChange={onServiceCommissionChange}
            editStaffCommission={editStaffCommission}
            staffTypeSameCommission={staffTypeSameCommission}
            defaultOptionStaffType={defaultOptionStaffType}
            staffCommission={staffs}
          />
          <Line></Line>
          <BoxSwitch>
            <BoxSwitchStyle flexDirection='column'>
              <TextLabel fontWeight='600'>ค่ามือแต่ละประเภทพนักงาน</TextLabel>
              <TextLabel color='#98A1B2' fontSize='0.8rem'>
                ไม่เท่ากัน/เท่ากัน
              </TextLabel>
            </BoxSwitchStyle>
            <BoxSwitchStyle alignItems='center' justifyContent='flex-end'>
              <SwitchButton
                name='commissionFee-staffType'
                toggled={staffTypeSameCommission}
                onChange={() => onStaffTypeSameCommissionChange()}
                backgroundColor={
                  staffTypeSameCommission ? '#8C56D9' : '#ABABAD'
                }
                width='18px'
                labelHeight='20px'
                labelWidth='40px'
              />
            </BoxSwitchStyle>
          </BoxSwitch>
          {!staffTypeSameCommission && (
            <>
              <Line></Line>
              <BoxSwitch>
                <BoxSwitchStyle flexDirection='column'>
                  <TextLabel fontWeight='600'>ค่ามือแต่ละคน</TextLabel>
                  <TextLabel color='#98A1B2' fontSize='0.8rem'>
                    ไม่เท่ากัน/เท่ากัน
                  </TextLabel>
                </BoxSwitchStyle>
                <BoxSwitchStyle alignItems='center' justifyContent='flex-end'>
                  <SwitchButton
                    name='commissionFee-staff'
                    toggled={staffSameCommission}
                    onChange={() => onStaffSameCommissionChange()}
                    backgroundColor={
                      staffSameCommission ? '#8C56D9' : '#ABABAD'
                    }
                    width='18px'
                    labelHeight='20px'
                    labelWidth='40px'
                  />
                </BoxSwitchStyle>
              </BoxSwitch>
            </>
          )}
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              zIndex: '2',
              background: '#FFFFFF',
            }}
          >
            <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
              <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                <Button2
                  color='#98A1B2'
                  border='2px solid #98A1B2'
                  backgroundColor='white'
                  backgroundColorHover='#E5E7EC'
                  fontWeight='600'
                  onClick={() => handleClose(true)}
                >
                  ยกเลิก
                </Button2>
              </div>
              <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                <ButtonSave
                  border='2px solid #53389E'
                  color='#FFFFFF'
                  backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                  backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                  fontWeight='600'
                  icon={<SaveIcon size='15' />}
                  onClick={() => onSaveClick()}
                >
                  บันทึก
                </ButtonSave>
              </div>
            </div>
          </div>
        </Scrollbar>
      </Card>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CarsAddCommissionFee
