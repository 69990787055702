import styled from "styled-components";

interface StyledButtonProps {
    w?: string
}

export const StyledButton = styled.button<StyledButtonProps>`
    background: linear-gradient(267.7deg, rgb(140, 86, 217) -38.6%, rgb(83, 56, 158) 99.4%);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    width: ${(props) => props.w || 'auto'};
    &:hover {
        background-color: #6E3CB9;
    }
`