import openSocket, { Socket } from 'socket.io-client'

import auth from '../auth'
import config from '../config'

const URL = config.API_POS

let socket: Socket | null = null

export const getSocket = () => {
  if (socket) return socket
  socket = openSocket(URL, {
    transports: ['websocket'],
    auth: {
      token: auth.getToken() || sessionStorage.getItem('token') || '',
    },
    query: {
      storeId: auth.getStoreId(),
    },
  })
  return socket
}
