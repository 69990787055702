import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { History } from 'history';
// @ts-ignore
import { getOrderBookingFromSearch } from '../../../redux/actions/bookingHistoryAction';
// @ts-ignore
import { getPayPackage } from '../../../redux/actions/payPackageActions';
// @ts-ignore
import { PAYMENT_URL } from '../../../routes';
// @ts-ignore
import api from '../../../api';
// @ts-ignore
import utils from '../../../utils';
// @ts-ignore
import { Statuses, PaymentStatuses, PaymentChannelTypes } from '../../../redux/models/BookingHistory';

const ButtonPayStyled = styled.div<{
    fontWeight?: string;
}>`
  background: #ffffff;
  border: 1px solid rgba(219, 45, 30, 0.2);
  box-shadow: 0px 4px 8px rgba(152, 161, 178, 0.16);
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  color: #db2d1e;
  font-size: 0.875rem;
  font-weight: ${props => props.fontWeight || '400'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  :hover {
    background-color: rgba(219, 45, 30, 0.21);
    color: #fff;
  }
`;

interface ButtonPayProps {
  _id: string;
  className?: string;
}

const callApiServiceById = async (serviceId: string) => {
  try {
    const { data } = await api.getServiceById(serviceId);
    if (data.length) {
      return data[0];
    }
  } catch (err) {
    console.log(err);
  }
};

export const onPayClick = async (
  e: React.MouseEvent<HTMLElement>,
  _id: string,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  history: History
) => {
  try {
    e.stopPropagation();
    const { data } = await api.getBookingHistoryById(_id);
    const findBookingHistory = data[0] || {};

    if (findBookingHistory) {
      let serviceTime = 0;

      let startTime = utils.convertTime(findBookingHistory.time);

      if (
        findBookingHistory.statusId === Statuses.InService ||
        findBookingHistory.statusId === Statuses.Finished
      ) {
        startTime = utils.convertTime(findBookingHistory.startTime);
      }

      let orders = await Promise.all(findBookingHistory.customers.map(async (info: any, index: number) => {
        const {
          orders,
          roomTypeId,
          roomTypeName,
          roomId,
          roomName,
          age,
          gender,
          nationalityId,
          customerId,
          name,
          firstName,
          lastName,
          email,
          countryCode,
          phoneNumber,
          specialRequest,
        } = info;
        let _orders = await Promise.all(orders.map(async (order: any) => {
          const { serviceId, timePriceId, time } = order;
          let service = await callApiServiceById(serviceId);
          const findActiveTimePrice = service.timePrices.findIndex(
            (t: any) => t.timePriceId === timePriceId
          );
          return {
            ...service,
            activeTime: findActiveTimePrice,
            timePriceId: service.timePrices[findActiveTimePrice].timePriceId,
            cost: service.timePrices[findActiveTimePrice].cost,
            price: service.timePrices[findActiveTimePrice].price,
            time: service.timePrices[findActiveTimePrice].time,
            staffTypeId: order.staffTypeId,
            staffId: order.staffId,
            staffName: order.staffName,
            staffImage: order.staffImage,
            queueStaff: order.queueStaff,
            requestStaff: order.requestStaff,
            tip: order.tip || 0,
            unit: 2,
            serviceDiscount: order.serviceDiscount || 0,
            payChannelTypeTip: order.payChannelTypeTip || -1,
            payChannelTip: order.payChannelTip || '',
          };
        }));
        serviceTime += orders.reduce((acc: number, order: any) => acc + order.time, 0);
        return {
          customer: index + 1,
          services: _orders,
          roomTypeId,
          roomTypeName,
          roomId,
          roomName,
          customerId,
          name,
          firstName,
          lastName,
          specialRequest,
          email,
          countryCode,
          phoneNumber,
          age: { value: age },
          gender,
          nationality: { value: nationalityId },
        };
      }));

      let remaining = findBookingHistory.total - findBookingHistory.discount;
      const splitPayCustomerPackages: any[] = [];
      let splitPackageDiscount = 0;
      const payChannels = findBookingHistory.payChannels.reduce(
        (acc: any[], info: any) => {
          const {
            payChannelTypeId,
            payChannelId,
            payChannel,
            note,
            chargePercentage,
            price,
            quantity,
          } = info;

          remaining = remaining - price;
          if (payChannelTypeId !== PaymentChannelTypes.Package) {
            acc.push({
              payChannelTypeId: payChannelTypeId,
              payChannelId: payChannelId,
              payChannel: payChannel,
              note: note,
              creditCharge: chargePercentage,
              charge: chargePercentage,
              price: price,
              remaining,
            });
          } else {
            splitPayCustomerPackages.push({
              payChannelTypeId: payChannelTypeId,
              payChannelId: payChannelId,
              payChannel: payChannel,
              note: note,
              creditCharge: chargePercentage,
              charge: chargePercentage,
              price: price,
              quantity,
              remaining,
            });
            splitPackageDiscount += price;
          }

          return acc;
        },
        []
      );
      dispatch(
        getPayPackage({
          splitPayCustomerPackages,
          splitPackageDiscount,
        })
      );

      dispatch(
        getOrderBookingFromSearch({
            _id: findBookingHistory._id,
            customerId: findBookingHistory.customerId,
            name: findBookingHistory.name,
            firstName: findBookingHistory.firstName,
            lastName: findBookingHistory.lastName,
            email: findBookingHistory.email,
            countryCode: findBookingHistory.countryCode,
            phoneNumber: findBookingHistory.phoneNumber,
            specialRequest: findBookingHistory.specialRequest,
            note: findBookingHistory.note,
            orders: orders,
            products: findBookingHistory.products || [],
            discount: findBookingHistory.discount,
            serviceDiscount: findBookingHistory.serviceDiscount,
            packageDiscount: findBookingHistory.packageDiscount,
            totalDiscount: findBookingHistory.totalDiscount,
            promotions: findBookingHistory.promotions,
            date: findBookingHistory.date,
            time: findBookingHistory.time,
            startTime:
              findBookingHistory.statusId === Statuses.InService ||
              findBookingHistory.statusId === Statuses.Finished
                ? findBookingHistory.startTime
                : findBookingHistory.time,
            endTime: {
              hour: Math.floor(Math.abs((startTime + serviceTime) / 60)),
              minute: (startTime + serviceTime) % 60,
            },
            bookingType: findBookingHistory.bookingType,
            bookingChannel: findBookingHistory.bookingChannel,
            bookingCode: findBookingHistory.bookingCode,
            statusId: findBookingHistory.statusId,
            total: findBookingHistory.total,
            edit: true,
            payChannels,
          })
      );
      history.push(PAYMENT_URL);
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

const ButtonPay: React.FC<ButtonPayProps> = ({ _id, className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onPayClick(e, _id, dispatch, history);
  };

  return (
    <ButtonPayStyled onClick={handleClick} className={className}>
      {t('due')}
    </ButtonPayStyled>
  );
};

export default ButtonPay;