export const GET_STAFF = 'GET_STAFF'

export const getStaff = staff => {
  return {
    type: GET_STAFF,
    payload: { staff },
  }
}

export const CLEAR_STAFF = 'CLEAR_STAFF'

export const clearStaff = staff => {
  return {
    type: CLEAR_STAFF,
    payload: { staff },
  }
}

export const GET_TEMP = 'GET_TEMP'

export const getTemp = temp => {
  return {
    type: GET_TEMP,
    payload: { temp },
  }
}
