import { useAtom } from 'jotai'
import { capitalize } from 'lodash'
import React, { HTMLAttributes, ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

// @ts-ignore
import api from '@/api'
import DropdownButton from '@/components/atoms/DropdownButton'
import { ClockIcon } from '@/components/atoms/icons/ClockIcon'
import { DocumentIcon } from '@/components/atoms/icons/DocumentIcon'
import {
  CenterDotIcon,
  DotsHorizontalIcon,
} from '@/components/atoms/icons/DotsIcon'
import { IconWrapper } from '@/components/atoms/icons/Icon'
import { PenIcon } from '@/components/atoms/icons/PenIcon'
import { PersonIcon } from '@/components/atoms/icons/PeopleIcon'
import { PinIcon } from '@/components/atoms/icons/PinIcon'
import { XCircleIcon } from '@/components/atoms/icons/XIcon'
import { toast } from '@/components/atoms/Toast'
import CardStaff from '@/components/booking/CardBook/CardOrder/CardStaff'
import {
  DataTableCell,
  STATUS_VARIANT,
} from '@/components/molecules/dataTable/DataTable'
import Modal from '@/components/molecules/modal'
import SheetDrawer from '@/components/molecules/SheetDrawer'
import { ModalBookingHistoryInfo } from '@/components/pages/searchHistory/ModalInfo'
import config from '@/config'
import { useConfirm } from '@/hook/useAlert'
import { schedulerBookingHistoriesFnAtom } from '@/jotai/SchedulerAtom'
import { formatDateDistance } from '@/lib/utils'
import SpaCard from '@/molecules/SpaCard'
import PopupSpaCard from '@/molecules/SpaCard/PopupSpaCard'
import {
  bookHistoryToBookOrder,
  getOrderBooking,
} from '@/redux/actions/bookingHistoryAction'
import { BookingHistory } from '@/redux/models/BookingHistory'
import typography from '@/styles/typography'
import { TVariant } from '@/types/config'
import utils from '@/utils'

export interface SchedulerItemAction {
  key: string
  label: string
  icon?: ReactNode
  variant?: TVariant
}
export interface SchedulerItemProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'primary' | 'success' | 'error'
  booking?: {
    id: number
    title: string
    start: Date
    end: Date
    customer: string
    phoneNumber: string
    status: number
    payStatus: number
    room?: string
  }
  actions?: {
    key: string
    label: string
    icon?: ReactNode
    variant?: TVariant
  }[]
  display?: boolean
  onActionClick?: (key: string | null, id?: number) => void | Promise<void>
}

const SchedulerItemTitle = styled.div`
  ${typography.body2}
  ${typography.semibold}
    display: flex;
  gap: 8px;
`

const SchedulerItemField = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--muted-contrast);
  path {
    fill: var(--muted-contrast);
  }
  div {
    min-width: 8px;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledSchedulerItemContainer = styled.div`
  background-color: var(--popup);
  box-sizing: border-box;
  border-left: 2px solid var(--success);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius);
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 98px;
  cursor: pointer;
  ${typography.body3}
`

const StyledDataCell = styled(DataTableCell)`
  padding: 0px;
  width: auto;
`

const BadgeRow = styled.div`
  display: flex;
  gap: 8px;
`

const StyledModalContentWrapper = styled.div`
  padding: 12px;
`

const ModalTitle = styled.div`
  ${typography.body1}
  ${typography.semibold}
`

const SchedulerItem = (props: SchedulerItemProps) => {
  const { onClick, actions: _actions, booking, display, ...rest } = props

  const { t } = useTranslation()
  const confirm = useConfirm()
  const [actions, setActions] = React.useState<SchedulerItemAction[]>(
    _actions || [
      {
        key: 'print-spa-card',
        label: t('printSpaCard'),
        icon: <DocumentIcon />,
        variant: 'primary',
      },
      {
        key: 'cancel',
        label: t('cancel'),
        icon: <XCircleIcon fill='var(--danger)' />,
        variant: 'error',
      },
    ]
  )

  useEffect(() => {
    if (props?.booking?.payStatus === -1 && !_actions) {
      let _actions = []
      if(config.STATUS[props?.booking?.status] === 'booked' || config.STATUS[props?.booking?.status] === 'confirmed') {
        _actions.push({
          key: 'print-spa-card',
          label: t('printSpaCard'),
          icon: <DocumentIcon />,
        })
        // TODO: Implement change room and change staff
        // _actions.push({
        //   key: 'change-room',
        //   label: t('changeRoom'),
        //   icon: <PenIcon />,
        // })
        // _actions.push({
        //   key: 'change-staff',
        //   label: t('changeTherapist'),
        //   icon: <PenIcon />,
        // })
      }
      _actions.push({
        key: 'edit',
        label: t('edit'),
        icon: <PenIcon />,
      })
      _actions.push({
        key: 'cancel',
        label: t('cancel'),
        icon: <XCircleIcon fill='red' />,
      })
      setActions(_actions)
    }
  }, [_actions, props?.booking, t])

  const dispatch = useDispatch()
  const history = useHistory()

  const [bookingHistory, setBookingHistory] =
    React.useState<BookingHistory | null>(null)

  const [bookingHistories, fetchBookingHistory] = useAtom(schedulerBookingHistoriesFnAtom)

  const [isInfoOpen, setIsInfoOpen] = React.useState(false)
  const [isPrintSpaOpen, setIsPrintSpaOpen] = React.useState(false)
  const [isChangeStaffOpen, setIsChangeStaffOpen] = React.useState(false)
  const [isChangeRoomOpen, setIsChangeRoomOpen] = React.useState(false)

  const timeString = useMemo(() => {
    if (!props.booking?.start || !props.booking?.end) return ''
    const periodText = `${utils.formatTime(
      props.booking?.start?.getHours()
    )}.${utils.formatTime(
      props.booking?.start?.getMinutes()
    )} - ${utils.formatTime(props.booking?.end?.getHours())}.${utils.formatTime(
      props.booking?.end?.getMinutes()
    )}`
    const text = formatDateDistance(props.booking?.start, props.booking?.end)
    return (
      <>
        {periodText} <CenterDotIcon size={2} /> {text}
      </>
    )
  }, [props.booking?.start, props.booking?.end])

  const handleActionClick = async (e: React.MouseEvent) => {
    const key = e.currentTarget.getAttribute('data-key')
    let builtInAction = false
    switch (key) {
      case 'print-spa-card':
        builtInAction = true
        const { data: b } = await api.getBookingHistoryById(props.booking?.id)
        setBookingHistory(b?.[0])
        setIsPrintSpaOpen(true)
        break
      case 'edit':
        builtInAction = true
        const { data: b2 } = await api.getBookingHistoryById(props.booking?.id)
        const { data: services } = await api.getServices()
        dispatch(getOrderBooking(bookHistoryToBookOrder(b2?.[0], services)))
        history.push('/book')
        break
      case 'change-staff':
        builtInAction = true
        setIsChangeStaffOpen(true)
        break
      case 'change-room':
        builtInAction = true
        setIsChangeRoomOpen(true)
        break
      case 'cancel':
        builtInAction = true
        const res = await confirm({
          title: 'ยกเลิกการจอง ?',
          message: `แน่ใจหรือไม่ที่จะยกเลิก ${props.booking?.title}?`,
          variant: 'error',
        })
        if (res) {
          try {
            await api.updateBookingHistory(props.booking?.id, {
              statusId: config.STATUS.indexOf('cancel') + 1,
            })
            await fetchBookingHistory()
            toast.success('ยกเลิกการจองสำเร็จ')
          } catch {
            toast.error('ไม่สามารถยกเลิกการจองได้')
          }
        }
        break
    }
    if (!builtInAction) {
      props.onActionClick?.(key, props.booking?.id)
    }
  }

  const handleItemClick = async (e: React.MouseEvent) => {
    e.stopPropagation()
    const { data: b } = await api.getBookingHistoryById(props.booking?.id)
    setBookingHistory(b?.[0])
    setIsInfoOpen(true)
  }

  return (
    <>
      <StyledSchedulerItemContainer onClick={handleItemClick} {...rest}>
        <Modal
          isOpen={isPrintSpaOpen}
          onOpenChange={isOpen => setIsPrintSpaOpen(isOpen)}
        >
          <Modal.Content>
            <StyledModalContentWrapper>
              <ModalTitle>{t('printSpaCard')}</ModalTitle>
              {bookingHistory && <SpaCard bookingHistory={bookingHistory} />}
            </StyledModalContentWrapper>
          </Modal.Content>
        </Modal>
        <SchedulerItemTitle>
          <DropdownButton>
            <DropdownButton.Trigger>
              <DotsHorizontalIcon />
            </DropdownButton.Trigger>
            <DropdownButton.Content>
              {actions?.map(action => (
                <DropdownButton.Item
                  key={action.key}
                  data-key={action.key}
                  onClick={handleActionClick}
                  variant={action.variant}
                >
                  <IconWrapper
                    size={24}
                    style={{
                      padding: '6px 0px 6px 6px',
                    }}
                  >
                    {action.icon}
                  </IconWrapper>
                  {action.label}
                </DropdownButton.Item>
              ))}
            </DropdownButton.Content>
          </DropdownButton>
          {props.booking?.title}
        </SchedulerItemTitle>
        <SchedulerItemField>
          <PersonIcon size={10} />
          {props.booking?.customer} {props.booking?.phoneNumber}
        </SchedulerItemField>
        <SchedulerItemField>
          <ClockIcon size={10} />
          {timeString}
        </SchedulerItemField>
        {props.booking?.room && (
          <SchedulerItemField>
            <PinIcon size={10} />
            {props.booking?.room}
          </SchedulerItemField>
        )}
        {
          !display && (
            <BadgeRow>
          {props.booking?.status !== undefined && (
            <StyledDataCell
              id={props.booking?.id || ''}
              columnKey='status'
              value={[
                {
                  key: config.STATUS[
                    props.booking?.status < 0 ? 0 : props.booking?.status
                  ],
                  value: capitalize(
                    config.STATUS[
                      props.booking?.status < 0 ? 0 : props.booking?.status
                    ]
                  ),
                  variant:
                    STATUS_VARIANT[
                      props.booking?.status < 0 ? 0 : props.booking?.status
                    ],
                },
              ]}
              options={{
                type: 'status',
              }}
            />
          )}
          {props.booking?.payStatus !== undefined && (
            <StyledDataCell
              id={props.booking?.id || ''}
              columnKey='payStatus'
              value={[
                {
                  key:
                    props.booking?.payStatus === -1
                      ? 'ยังไม่ชำระเงิน'
                      : 'ชำระเงินแล้ว',
                  value:
                    props.booking?.payStatus === -1
                      ? 'ยังไม่ชำระเงิน'
                      : 'ชำระเงินแล้ว',
                  variant:
                    props.booking?.payStatus === -1 ? 'danger' : 'success',
                },
              ]}
              options={{
                type: 'status',
              }}
            />
          )}
        </BadgeRow>
          )
        }
        {props.children}
      </StyledSchedulerItemContainer>
      <SheetDrawer
        isOpen={!!isInfoOpen}
        onOpenChange={isOpen => setIsInfoOpen(isOpen)}
      >
        <SheetDrawer.Content>
          {bookingHistory && <ModalBookingHistoryInfo info={bookingHistory} />}
        </SheetDrawer.Content>
        {/* <ModalMiniDetail bookingHistory={popupBookingInfo?.information} handleClose={closePopupBookingHistory} /> */}
      </SheetDrawer>
      {/* {isChangeStaffOpen && (<CardStaff
          staffOrders={staffOrders}
          activeOpen={activeOpen}
          currentDate={currentDate}
          onToggle={onToggle}
          onSelectStaff={onSelectStaff}
        />)} */}
    </>
  )
}

export default SchedulerItem
