import React, { useState } from 'react'
import styled from 'styled-components'

import TextLabel from '../../atoms/TextLabel'
import PopupAddRole from '../popups/PopupAddRole'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const BoxUserType = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`

const BoxUserTypeStyle = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-color: ${props => props.borderColor || '#FFF'};
  border-style: solid;
  border-width: 2px;
`

const BodyRole = () => {
  const roles = ['เจ้าของ', 'ผู้จัดการ', 'พนักงานทั่วไป']
  const [edit, setEdit] = useState(-1)

  const handelClose = () => {
    setEdit(-1)
  }

  return (
    <Container>
      <Scrollbar id='setting-BodyRole'>
        <BoxUserType>
          {roles.map((role, index) => (
            <BoxUserTypeStyle key={index}>
              <TextLabel fontWeight='500' fontSize='1.25rem'>
                {role}
              </TextLabel>
              {/* <Button onClick={() => setEdit(index)}>
              {<EditIcon size='15' color='#98a1b2' />}
              &nbsp;&nbsp; แก้ไข
            </Button> */}
            </BoxUserTypeStyle>
          ))}
        </BoxUserType>
      </Scrollbar>
      {edit !== -1 && <PopupAddRole handelClose={handelClose} />}
    </Container>
  )
}

export default BodyRole
