import React from 'react'
import styled, { CSSProperties } from 'styled-components'

import { PeopleIcon } from '@/components/atoms/icons/PeopleIcon'
import typography from '@/styles/typography'

import { ServiceState } from './useCardMenu'
import useCardMenuService from './useCardMenuService'

import { StaffOrderState } from '../types'

import TextLabel from '../../../atoms/TextLabel'

import Service from '../../../redux/models/Service'

interface CardMenuServiceStyle extends CSSProperties {
  active?: boolean
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const Box = styled.div`
  display: flex;
  flex-grow: 1;
`
const BoxStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const BoxService = styled.div`
  flex: 1 0 100%;
  max-width: 100%;
  padding: 8px;
  cursor: pointer;
`

const BoxServiceStyle = styled.div<CardMenuServiceStyle>`
  border: ${props => props.border || '1px solid var(--border)'};
  border-radius: 8px;
  background: ${props => props.background || '#FFFFFF'};
  width: 100%;
  height: 72px;
  padding: 8px;
  gap: 10px;
  display: flex;
  align-items: center;
  :hover {
    border: 1px solid #53389e;
  }
  position: relative;
`

const BoxButton = styled.div`
  position: absolute;
  color: #ffffff;
  padding: 0.25rem 1rem;
  background: linear-gradient(221.86deg, #8c56d9 2.71%, #53389e 97.79%);
  border-radius: 4px;
  width: initial;
  font-size: 0.75rem;
`

const Text = styled.div<CardMenuServiceStyle>`
  white-space: wrap;
  width: ${props => props.width || '100%'};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 0.85rem;
  color: ${props => props.color || '#475467'};
  padding-left: 0.5rem;
`

const ImageBox = styled.div<CardMenuServiceStyle>`
  position: relative;
  border-radius: 4px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  min-width: 56px;
  max-width: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
`

const BoxCustomer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  gap: 2px;
  width: 38px;
  height: 24px;
  background: #f4eefb;
  border-radius: 2px;
  flex: none;
  flex-grow: 0;
  color: var(--primary);
  ${typography.body2}
  position: absolute;
  right: 8px;
  top: 8px;
`

interface CardMenuServiceProps {
  serviceGroups: ServiceState[]
  search: string
  activeGroup: number
  staffOrders: StaffOrderState[]
}

const CardMenuService = ({
  serviceGroups,
  search,
  activeGroup,
  staffOrders,
}: CardMenuServiceProps) => {
  const { activeService, onServiceClick } = useCardMenuService({
    staffOrders,
  })

  const bySearch = (services: Service, search: string) => {
    if (search !== '') {
      return services.name.toLowerCase().includes(search.toLowerCase())
    } else {
      return services
    }
  }

  const filteredList = (services: Service[], search: string) => {
    return services.filter(services => bySearch(services, search))
  }

  const filteredGroup = (services: ServiceState[], activeGroup: number) => {
    if (activeGroup !== -1) {
      return services.filter(services => services._id === activeGroup)
    } else {
      return services
    }
  }

  return (
    <Container>
      {filteredGroup(serviceGroups, activeGroup).map(
        ({ groupName, services }, index) => (
          <div key={index}>
            <TextLabel fontWeight='500'>{groupName}</TextLabel>
            <Box>
              <BoxStyled>
                {filteredList(services, search).map((service, index) => (
                  <BoxService
                    key={index}
                    onClick={() => onServiceClick(service)}
                  >
                    <BoxServiceStyle
                      border={
                        activeService === service._id ? '1px solid #53389E' : ''
                      }
                    >
                      <ImageBox background={service.image}>
                        {activeService === service._id && (
                          <BoxButton>เลือก</BoxButton>
                        )}
                      </ImageBox>
                      {(service.customerCount || 1) > 1 && (
                        <BoxCustomer>
                          <PeopleIcon />
                          {service.customerCount}
                        </BoxCustomer>
                      )}
                      <Text
                        color={
                          activeService === service._id ? '#475467' : '#98A1B2'
                        }
                      >
                        {service.name}
                      </Text>
                    </BoxServiceStyle>
                  </BoxService>
                ))}
              </BoxStyled>
            </Box>
          </div>
        )
      )}
    </Container>
  )
}

export default CardMenuService
