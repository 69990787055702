import React from 'react'
import styled from 'styled-components'

import PopupInsideBox from '../../atoms/PopupInside'
import PopupBox from '../../atoms/Popup'
import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'

const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
`

const PopupAddRole = ({ handleClose }) => {
  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0' height='auto'>
        <Card>
          <div
            style={{
              width: '100%',
              // height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              zIndex: '2',
              background: '#FFFFFF',
            }}
          >
            <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
              <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                <Button2
                  color='#98A1B2'
                  border='2px solid #98A1B2'
                  backgroundColor='white'
                  backgroundColorHover='#E5E7EC'
                  fontWeight='600'
                  onClick={() => handleClose()}
                >
                  ยกเลิก
                </Button2>
              </div>
              <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                <Button2
                  border='2px solid #53389E'
                  color='#FFFFFF'
                  backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                  backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                  fontWeight='600'
                  icon={<SaveIcon size='15' />}
                  // onClick={() => onSaveClick()}
                >
                  บันทึก
                </Button2>
              </div>
            </div>
          </div>
        </Card>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddRole
