import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import api from '../../api'
import Button2 from '../../atoms/Button2'
import EMail from '../../atoms/EMailIcon'
import PasswordLockIcon from '../../atoms/PasswordLockIcon'
import TextLabel from '../../atoms/TextLabel'

import { LOGIN_URL } from '../../routes'

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
`
const Box = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
`
const ImgLogo = styled.img`
  margin-top: 5rem;
  width: 191.5px;
  height: 124.5px;
`
const DivInputStyle = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  width: 50%;
`
const IconStyle = styled.div`
  position: absolute;
  bottom: ${props => props.bottom || '37px'};
  left: ${props => props.left || '15px'};
  width: 15px;
  height: 15px;
`
const Input = styled.input`
  background: #ffffff;
  border: 2px solid #8c56d9;
  border-radius: 8px;
  height: 45px;
  width: ${props => props.width || '100%'};
  padding-left: 3rem;
  margin: ${props => props.margin || '1rem 0'};
  outline: none;
`

const ResetPassword = () => {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    const url_string = window.location.href
    const url = new URL(url_string)
    const email = url.searchParams.get('email')
    setEmail(email)
  }, [])

  const onResetPasswordClick = async () => {
    try {
      if (password !== verifyPassword) {
        return false
      }

      const url_string = window.location.href
      const url = new URL(url_string)
      const token = url.searchParams.get('token')
      const body = { password, verifyPassword, token }
      const { success } = await api.resetPassword(body)
      if (success) {
        return history.replace(LOGIN_URL)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Box>
        <ImgLogo src='/images/logo_login.png' />
        <div className='mt-3'>
          <TextLabel color='#8C56D9' fontWeight='600' fontSize='1.2rem'>
            รีเซ็ตรหัสผ่าน
          </TextLabel>
        </div>
        <DivInputStyle>
          <Input type='email' placeholder='อีเมล' value={email} readOnly />
          <IconStyle>
            <EMail size='18' />
          </IconStyle>
        </DivInputStyle>
        <DivInputStyle>
          <Input
            placeholder='รหัสผ่านใหม่'
            value={password}
            onChange={val => setPassword(val.target.value)}
          />
          <IconStyle>
            <PasswordLockIcon size='18' />
          </IconStyle>
        </DivInputStyle>
        <DivInputStyle>
          <Input
            placeholder='ยืนยันรหัสผ่านใหม่'
            value={verifyPassword}
            onChange={val => setVerifyPassword(val.target.value)}
          />
          <IconStyle>
            <PasswordLockIcon size='18' />
          </IconStyle>
        </DivInputStyle>
        <Button2
          width='50%'
          backgroundColor='linear-gradient(267.7deg, #8C56D9 -38.6%, #53389E 99.4%)'
          color='white'
          onClick={() => onResetPasswordClick()}
        >
          รีเซ็ต
        </Button2>
        <div className='mt-3'>
          <TextLabel color='#8C56D9' fontWeight='500'>
            กลับไปหน้าล็อกอิน
          </TextLabel>
        </div>
      </Box>
    </Container>
  )
}

export default ResetPassword
