import React from 'react'

import { Genders } from '../../redux/models/Customer'
import SelectorBase from './SelectorBase'
import { useTranslation } from 'react-i18next'

const options = [
  { value: Genders.NotSpecified, label: 'ไม่ระบุ' },
  { value: Genders.Male, label: 'ชาย' },
  { value: Genders.Female, label: 'หญิง' },
  { value: Genders.Other, label: 'อื่นๆ' },
]

const SelectorGender = ({
  name = 'gender',
  open,
  onClick,
  option: _option,
  onOptionClick,
  error = false,
  readOnly = false,
}) => {
  const { t } = useTranslation()

  const option = _option || { value: -1, label: t('notSpecified') }

  return (
    <SelectorBase
      name={name}
      option={option}
      options={options}
      open={open}
      onClick={onClick}
      onOptionClick={onOptionClick}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorGender
