import { GET_HISTORY } from '../actions/dataHistoryAction'

const initState = {
  search: '',
  filterList: '',
  customerInformationBook: '',
  serviceData: '',
  customerOptionsDefault: '',
  headCustomer: '',
  subCustomer: '',
  cargoData: '',
}

const dataHistoryReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_HISTORY:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}

export default dataHistoryReducers
