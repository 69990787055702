import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'
import InputTextArea from '../../atoms/InputTextArea'
import InputText from '../../atoms/InputText'
import ButtonSave from '../../atoms/ButtonSave'
import Button2 from '../../atoms/Button2'
import EditIcon from '../../lib/icons/EditIcon'
import SaveIcon from '../../atoms/SaveIcon'
import utils from '../../utils'
import api from '../../api'
import auth from '../../auth'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import Receipt from '../../molecules/Receipt/Receipt'
import RadioButton from '../../atoms/RadioButton'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100vh;
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 1rem;
  flex: 1;
  overflow: hidden;
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => props.gap};
  width: 100%;
`
const BoxContainer = styled.div`
  display: grid;
  gap: 1rem;
`
const Box = styled.div`
  width: ${props => props.width || '100%'};
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  gap: 0.5rem;
`
const ImageBox = styled.div`
  border-radius: 8px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#E4E8EB'};
  width: 150px;
  height: 150px;
  background-size: cover;
`
const CardBill = styled.div`
  /* width: 303px; */
  height: 100%;
  background-color: #fff;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
`

const Body = ({ currentData }) => {
  let componentRef = useRef(null)
  const [edit, setEdit] = useState(false)
  const [image, setImage] = useState('')
  const [fileImage, setFileImage] = useState('')
  const [bill, setBill] = useState({
    nameThai: '',
    addressThai: '',
    nameEng: '',
    addressEng: '',
    phoneNumber: '',
    eMail: '',
    taxIDNumber: '',
    type: 0,
  })
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (currentData._id !== -1) {
      setBill({ ...currentData })
      setImage(currentData.image)
    }
  }, [currentData])

  const onChangeImage = async event => {
    try {
      const file = event.target.files[0]
      if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
        alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
      } else if (utils.isFileExceedLimit(file)) {
        alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
      } else {
        setFileImage(file)
        const image = await utils.readImage(file)

        setImage(image.image)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onSaveClick = async () => {
    try {
      let dataReceipt = { ...bill, image: image, storeId: auth.getStoreId() }
      if (fileImage) {
        const { data, success } = await api.uploadImageReceipt({
          token: auth.getToken(),
          image: fileImage,
        })
        if (success) {
          dataReceipt = { ...dataReceipt, image: data }
        }
      }
      if (currentData._id === -1) {
        await callApiCreateReceipt(dataReceipt)
      } else {
        await callApiUpdateReceipt(dataReceipt)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateReceipt = async create => {
    try {
      const { success } = await api.createReceipt(create)
      if (success) {
        setSuccess(true)
        setEdit(false)
        setTimeout(() => {
          setSuccess(false)
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateReceipt = async update => {
    try {
      const { success } = await api.updateReceipt(currentData._id, update)
      if (success) {
        setSuccess(true)
        setEdit(false)
        setTimeout(() => {
          setSuccess(false)
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onCancelClick = () => {
    setBill({ ...currentData })
    setImage(currentData.image)
    setEdit(false)
  }

  return (
    <Container>
      <Card>
        <Scrollbar id='setting-bill'>
          <BoxContainer>
            <GridBox columns='1fr auto' gap='0.5rem'>
              <Box>
                <TextLabel fontWeight='600' fontSize='1.25rem'>
                  ข้อมูลใบเสร็จ
                </TextLabel>
                <GridBox columns='0.25fr 1fr'>
                  <TextLabel fontWeight='500'>{`ชื่อ(ภาษาไทย) :`}</TextLabel>
                  <InputTextArea
                    resize='auto'
                    background={edit ? '#FFFFFF' : '#F8F9FB'}
                    color={edit ? '#000000' : '#98A1B2'}
                    readOnly={!edit}
                    value={bill.nameThai}
                    onChange={val =>
                      setBill({ ...bill, nameThai: val.target.value })
                    }
                  />
                </GridBox>
                <GridBox columns='0.25fr 1fr'>
                  <TextLabel fontWeight='500'>{`ที่อยู่(ภาษาไทย) :`}</TextLabel>
                  <InputTextArea
                    resize='auto'
                    background={edit ? '#FFFFFF' : '#F8F9FB'}
                    color={edit ? '#000000' : '#98A1B2'}
                    readOnly={!edit}
                    value={bill.addressThai}
                    onChange={val =>
                      setBill({ ...bill, addressThai: val.target.value })
                    }
                  />
                </GridBox>
                <GridBox columns='0.25fr 1fr'>
                  <TextLabel fontWeight='500'>{`ชื่อ(ภาษาอังกฤษ) :`}</TextLabel>
                  <InputTextArea
                    resize='auto'
                    background={edit ? '#FFFFFF' : '#F8F9FB'}
                    color={edit ? '#000000' : '#98A1B2'}
                    readOnly={!edit}
                    value={bill.nameEng}
                    onChange={val =>
                      setBill({ ...bill, nameEng: val.target.value })
                    }
                  />
                </GridBox>
                <GridBox columns='0.25fr 1fr'>
                  <TextLabel fontWeight='500'>{`ที่อยู่(ภาษาอังกฤษ) :`}</TextLabel>
                  <InputTextArea
                    resize='auto'
                    background={edit ? '#FFFFFF' : '#F8F9FB'}
                    color={edit ? '#000000' : '#98A1B2'}
                    readOnly={!edit}
                    value={bill.addressEng}
                    onChange={val =>
                      setBill({ ...bill, addressEng: val.target.value })
                    }
                  />
                </GridBox>
                <GridBox columns='0.25fr 1fr'>
                  <TextLabel fontWeight='500'>{`เบอร์โทรศัพท์ :`}</TextLabel>
                  <InputText
                    background={edit ? '#FFFFFF' : '#F8F9FB'}
                    color={edit ? '#000000' : '#98A1B2'}
                    readOnly={!edit}
                    value={bill.phoneNumber}
                    onChange={val =>
                      setBill({ ...bill, phoneNumber: val.target.value })
                    }
                  />
                </GridBox>
                <GridBox columns='0.25fr 1fr'>
                  <TextLabel fontWeight='500'>{`อีเมล:`}</TextLabel>
                  <InputText
                    background={edit ? '#FFFFFF' : '#F8F9FB'}
                    color={edit ? '#000000' : '#98A1B2'}
                    readOnly={!edit}
                    value={bill.eMail}
                    onChange={val =>
                      setBill({ ...bill, eMail: val.target.value })
                    }
                  />
                </GridBox>
                <GridBox columns='0.25fr 1fr'>
                  <TextLabel fontWeight='500'>{`เลขประจำตัวผู้เสียภาษี :`}</TextLabel>
                  <InputText
                    background={edit ? '#FFFFFF' : '#F8F9FB'}
                    color={edit ? '#000000' : '#98A1B2'}
                    readOnly={!edit}
                    value={bill.taxIDNumber}
                    onChange={val =>
                      setBill({ ...bill, taxIDNumber: val.target.value })
                    }
                  />
                </GridBox>
                <GridBox columns='0.25fr 1fr'>
                  <TextLabel fontWeight='500'>{`โลโก้`}</TextLabel>
                  <input
                    id='upload-image'
                    type='file'
                    onChange={onChangeImage}
                    disabled={!edit ? 'disabled' : ''}
                  />
                </GridBox>
                {image && (
                  <GridBox columns='0.25fr 1fr'>
                    <TextLabel fontWeight='500'></TextLabel>
                    <ImageBox background={image ? image : ''}></ImageBox>
                  </GridBox>
                )}
                <Box width='fit-content' flexDirection='row'>
                  <Box flexDirection='row'>
                    <RadioButton
                      onClick={e => {
                        !edit
                          ? e.preventDefault()
                          : setBill({ ...bill, type: 0 })
                      }}
                      checked={bill.type === 0}
                    />
                    <TextLabel
                      onClick={e => {
                        !edit
                          ? e.preventDefault()
                          : setBill({ ...bill, type: 0 })
                      }}
                    >
                      ใบเสร็จรับเงิน
                    </TextLabel>
                  </Box>
                  <Box flexDirection='row'>
                    <RadioButton
                      onClick={e => {
                        !edit
                          ? e.preventDefault()
                          : setBill({ ...bill, type: 1 })
                      }}
                      checked={bill.type === 1}
                    />
                    <TextLabel
                      onClick={e => {
                        !edit
                          ? e.preventDefault()
                          : setBill({ ...bill, type: 1 })
                      }}
                    >
                      ใบกำกับภาษีแบบย่อ
                    </TextLabel>
                  </Box>
                </Box>
              </Box>

              <CardBill>
                <TextLabel fontWeight='600' fontSize='1.25rem'>
                  ตัวอย่างใบเสร็จ
                </TextLabel>
                {/* <ReactToPrint
                  content={componentRef.current}
                  trigger={() => <button>print</button>}
                /> */}
                <div style={{ height: '100%', width: '400px' }}>
                  <Receipt
                    receiptData={{ ...bill, image }}
                    componentRef={componentRef}
                  />
                </div>
              </CardBill>
            </GridBox>
          </BoxContainer>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              zIndex: '2',
              background: '#FFFFFF',
            }}
          >
            {!edit ? (
              <Button2
                backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                color='#FFFFFF'
                icon={<EditIcon color='#FFFFFF' size='15' />}
                onClick={() => setEdit(true)}
              >
                แก้ไขข้อมูลใบเสร็จ
              </Button2>
            ) : (
              <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
                <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                  <Button2
                    color='#98A1B2'
                    border='2px solid #98A1B2'
                    backgroundColor='white'
                    backgroundColorHover='#E5E7EC'
                    fontWeight='600'
                    onClick={() => onCancelClick()}
                  >
                    ยกเลิก
                  </Button2>
                </div>
                <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                  <ButtonSave
                    border='2px solid #53389E'
                    color='#FFFFFF'
                    backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                    backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                    fontWeight='600'
                    icon={<SaveIcon size='15' />}
                    onClick={() => onSaveClick()}
                  >
                    บันทึก
                  </ButtonSave>
                </div>
              </div>
            )}
          </div>
          {success && (
            <SuccessIcon2 position='absolute' height='calc(100% - 1rem)' />
          )}
        </Scrollbar>
      </Card>
    </Container>
  )
}

export default Body
