import React from 'react'

import SettingHeader from '../../molecules/SettingHeader'

const Head = ({ activeMenu, onAddData }) => {
  return (
    <SettingHeader
      name='ช่องทางการจอง/จ่าย'
      menuName={
        activeMenu === 'การจอง'
          ? 'สร้างช่องทางการจอง'
          : 'สร้างช่องทางการจ่ายเงิน'
      }
      onClick={onAddData}
    />
  )
}

export default Head
