import React from 'react'

import Queue from './Queue'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  return (
    <Page>
      <Queue />
    </Page>
  )
}

export default Main
