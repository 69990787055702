import React, { useEffect, useState } from 'react'

import Option, { SelectorOption } from '@/redux/models/Option'

import SelectorBase from './SelectorBase'

//@ts-ignore
import api from '../../api'
import { ProductGroup } from '../../redux/models/ProductGroup'

interface SelectorProductGroupProps {
  name: string
  open: boolean
  onClick: () => void
  option: Option
  onOptionClick: (option: SelectorOption, name: string) => void
  error: boolean
  readOnly: boolean
}

const SelectorServiceGroup = ({
  name = 'productGroup',
  open,
  onClick,
  option = {
    value: -1,
    label: '',
  },
  onOptionClick,
  error = false,
  readOnly = false,
}: SelectorProductGroupProps) => {
  const [serviceGroup, setServiceGroup] = useState<Option[]>([
    { value: -1, label: '' },
  ])

  useEffect(() => {
    callApiProductGroup()
  }, [])

  const callApiProductGroup = async () => {
    try {
      const { data, success } = await api.getProductGroupByHeadGroup(1)
      if (success) {
        if (data.length) {
          const serviceGroup = data.reduce(
            (acc: Option[], info: ProductGroup) => {
              acc.push({ value: info._id, label: info.groupName })
              return acc
            },
            []
          )
          setServiceGroup(serviceGroup)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <SelectorBase
      name={name}
      option={
        option.label ? option : serviceGroup.length ? serviceGroup[0] : option
      }
      options={serviceGroup}
      open={open}
      onClick={onClick}
      onOptionClick={(item, name) => onOptionClick(item, name)}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorServiceGroup
