import React, { useState } from 'react'
import styled from 'styled-components'

import Button2 from '../../atoms/Button2'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import LeftSidePopup from './LeftSidePopup'
import EyeIcon from '../../atoms/EyeIcon'
import CloseEyeIcon from '../../atoms/CloseEyeIcon'
import SaveIcon from '../../atoms/SaveIcon'
import BackIcon from '../../atoms/BackIcon'
import api from '../../api'

const CloseIcon = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 15px;
  width: 25px;
  height: 25px;
`
const RightBox = styled.div`
  position: absolute;
  width: 65%;
  top: 10%;
  right: 0;
  padding-left: 5%;
`
const Input = styled.input`
  background: #f4f6fa;
  outline: none;
  width: 90%;
  border-radius: 5px;
  border: none;
  padding-left: 0.5rem;
  height: 3rem;
  border: ${props => props.border || '1px solid #c6ced5'};
  ::placeholder {
    color: #c6ced5;
  }
`
const DivInputStyle = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
`
const IconStyle = styled.div`
  position: absolute;
  bottom: ${props => props.bottom || '34px'};
  left: calc(100% - 10% - 50px);
  top: 25%;
  width: 15px;
  height: 15px;
`

const PopupChangePassUser = ({ handleClose, user }) => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')
  const [inputType, setInputType] = useState([true, true, true])
  const [checkPass, setCheckPass] = useState(true)
  const [checkConfirmPass, setCheckConfirmPass] = useState(true)

  const onForgetPassClick = () => {}

  const onSeePassClick = index => {
    const newArrInputType = [...inputType]
    newArrInputType[index] = !newArrInputType[index]
    setInputType(newArrInputType)
  }

  const onSaveClick = async () => {
    try {
      const userLogin = {
        phoneNumber: user.phoneNumber,
        password: currentPassword,
        typeLogin: 'password',
      }

      const data = await api.login(userLogin)

      if (data.success) {
        setCheckPass(true)
        if (checkConfirmPass) {
          const update = {
            currentPassword,
            newPassword,
            verifyPassword,
            // token: auth.getToken(),
            token: data.token,
          }
          const { success: _success } = await api.renewPassword(update)
          if (_success) {
            handleClose()
          }
        }
      } else {
        setCheckPass(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeConfirmPass = value => {
    setVerifyPassword(value)

    if (value !== newPassword) {
      setCheckConfirmPass(false)
    } else {
      setCheckConfirmPass(true)
    }
  }

  return (
    <PopupBox
      width='calc(100%)'
      height='100%'
      left='0px'
      top='0px'
      zIndex='1000'
    >
      <PopupInsideBox width='70%' height='60%' marginTop='50px' zIndex='100'>
        <CloseIcon>
          <img
            src='images/btn_close_popup.png'
            style={{ width: '30px' }}
            alt='close-btn'
            onClick={() => handleClose('close')}
          />
        </CloseIcon>
        <LeftSidePopup
          img='/images/ChangeDateTime.png'
          title='เปลี่ยนรหัสผ่าน ของท่าน'
          detail='xxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxx'
        ></LeftSidePopup>
        <RightBox>
          {!checkPass && <TextLabel>รหัสผ่านผิด</TextLabel>}
          <TextLabel fontWeight='bold'>รหัสผ่านเดิม</TextLabel>
          <DivInputStyle>
            <IconStyle>
              {inputType[0] ? (
                <EyeIcon size='18' onClick={onSeePassClick.bind(this, 0)} />
              ) : (
                <CloseEyeIcon
                  size='18'
                  onClick={onSeePassClick.bind(this, 0)}
                />
              )}
            </IconStyle>
            <Input
              type={inputType[0] ? 'password' : 'text'}
              placeholder='พิมพ์รหัสผ่านเดิม'
              value={currentPassword}
              onChange={val => setCurrentPassword(val.target.value)}
            />
          </DivInputStyle>
          <TextLabel fontWeight='bold'>รหัสผ่านใหม่</TextLabel>
          <DivInputStyle>
            <IconStyle>
              {inputType[1] ? (
                <EyeIcon size='18' onClick={onSeePassClick.bind(this, 1)} />
              ) : (
                <CloseEyeIcon
                  size='18'
                  onClick={onSeePassClick.bind(this, 1)}
                />
              )}
            </IconStyle>
            <Input
              type={inputType[1] ? 'password' : 'text'}
              placeholder='พิมพ์รหัสผ่านใหม่'
              value={newPassword}
              onChange={val => setNewPassword(val.target.value)}
            />
          </DivInputStyle>
          <TextLabel fontWeight='bold'>รหัสผ่านใหม่ อีกครั้ง</TextLabel>
          <DivInputStyle>
            <IconStyle>
              {inputType[2] ? (
                <EyeIcon size='18' onClick={onSeePassClick.bind(this, 2)} />
              ) : (
                <CloseEyeIcon
                  size='18'
                  onClick={onSeePassClick.bind(this, 2)}
                />
              )}
            </IconStyle>
            <Input
              border={checkConfirmPass ? '' : '1px solid #EB5757'}
              type={inputType[2] ? 'password' : 'text'}
              placeholder='พิมพ์รหัสผ่านใหม่ อีกครั้ง'
              value={verifyPassword}
              onChange={val => onChangeConfirmPass(val.target.value)}
            />
          </DivInputStyle>
          {!checkConfirmPass && (
            <TextLabel color='#EB5757'>กรุณาใส่รหัสให้ตรงกัน</TextLabel>
          )}
          <div
            className='mt-1'
            style={{ width: '90%', position: 'relative' }}
            onClick={onForgetPassClick.bind()}
          >
            <div style={{ width: 'auto', position: 'absolute', right: '0%' }}>
              <TextLabel color='#5F5983'>ลืมรหัสผ่าน?</TextLabel>
            </div>
          </div>
          <div className='mt-5' style={{ display: 'flex', width: '90%' }}>
            <Button2
              border='1px solid #6B608A'
              backgroundColor='white'
              color='#6B608A'
              margin='0.125rem 0.25rem 0.125rem 0'
              icon={<BackIcon size='20' />}
            >
              ไม่แก้ไข
            </Button2>
            <Button2
              backgroundColor='linear-gradient(247.84deg, #57557F 29.15%, #8E759F 97.59%)'
              color='white'
              margin='0.125rem 0 0.125rem 0.25rem'
              onClick={onSaveClick.bind(this)}
              icon={<SaveIcon size='20' color='white' />}
            >
              ยืนยันการแก้ไข
            </Button2>
          </div>
        </RightBox>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupChangePassUser
