import React from 'react'

const CashierIcon = () => (
  <svg
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.3334 11.6667V6.33336M6.33334 6.33336H14.3334C14.687 6.33336 15.0261 6.19288 15.2762 5.94283C15.5262 5.69278 15.6667 5.35364 15.6667 5.00002V2.33334C15.6667 1.97972 15.5262 1.64058 15.2762 1.39053C15.0261 1.14048 14.687 1 14.3334 1H6.33334C5.97972 1 5.64058 1.14048 5.39053 1.39053C5.14048 1.64058 5 1.97972 5 2.33334V5.00002C5 5.35364 5.14048 5.69278 5.39053 5.94283C5.64058 6.19288 5.97972 6.33336 6.33334 6.33336Z'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M25.0001 19.6641V23.6641C25.0001 24.0177 24.8596 24.3569 24.6096 24.6069C24.3595 24.857 24.0204 24.9975 23.6668 24.9975H2.33334C1.97972 24.9975 1.64058 24.857 1.39053 24.6069C1.14048 24.3569 1 24.0177 1 23.6641V19.6641H25.0001ZM23.6668 19.6641V12.9974C23.6668 12.6438 23.5263 12.3046 23.2762 12.0546C23.0262 11.8045 22.6871 11.6641 22.3334 11.6641H3.66668C3.31305 11.6641 2.97392 11.8045 2.72387 12.0546C2.47382 12.3046 2.33334 12.6438 2.33334 12.9974V19.6641H23.6668Z'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default CashierIcon
