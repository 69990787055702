import React from 'react'

const TransferIcon = ({ size, color }) => (
  <svg
    width={size || '34'}
    height={size || '24'}
    viewBox='0 0 34 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_860_12)'>
      <path
        d='M18.8139 15.8557H2.40522V2.40522H27.8713V9.02609C28.7374 9.20348 29.5461 9.53739 30.2765 9.98087V1.54435C30.2765 0.693913 29.5878 0 28.7322 0H1.54435C0.688696 0 0 0.688696 0 1.54435V16.7165C0 17.567 0.688696 18.2609 1.54435 18.2609H19.0122C18.8661 17.6765 18.7826 17.0661 18.7826 16.4348C18.7826 16.2417 18.7983 16.0487 18.8139 15.8557Z'
        fill={color || '#475467'}
      />
      <path
        d='M30.2752 9.97327C29.5447 9.5298 28.736 9.19588 27.8699 9.01849C27.3795 8.91936 26.8682 8.86719 26.3465 8.86719C22.3656 8.86719 19.1047 11.9454 18.8126 15.8533C18.7969 16.0463 18.7812 16.2341 18.7812 16.4324C18.7812 17.0637 18.8647 17.6741 19.0108 18.2585C19.8299 21.5507 22.7986 23.9976 26.3465 23.9976C30.5256 23.9976 33.9117 20.6115 33.9117 16.4324C33.9117 13.6933 32.4508 11.3037 30.2752 9.97327ZM23.216 14.0846H27.3273L26.4456 13.2028C26.1378 12.895 26.1378 12.3994 26.4456 12.0968C26.7534 11.7889 27.2491 11.7889 27.5517 12.0968L29.7691 14.3141C29.9934 14.5385 30.0612 14.8724 29.9412 15.1646C29.8212 15.4568 29.5343 15.6498 29.216 15.6498H23.216C22.783 15.6498 22.4334 15.3002 22.4334 14.8672C22.4334 14.4341 22.783 14.0846 23.216 14.0846ZM29.216 19.1715H25.1047L25.9865 20.0533C26.2943 20.3611 26.2943 20.8568 25.9865 21.1594C25.8352 21.3107 25.6317 21.3889 25.4334 21.3889C25.2352 21.3889 25.0317 21.3107 24.8804 21.1594L22.663 18.942C22.4386 18.7176 22.3708 18.3837 22.4908 18.0915C22.6108 17.7994 22.8978 17.6063 23.216 17.6063H29.216C29.6491 17.6063 29.9986 17.9559 29.9986 18.3889C29.9986 18.822 29.6491 19.1715 29.216 19.1715Z'
        fill={color || '#475467'}
      />
      <path
        d='M2.88522 5.77043C4.47868 5.77043 5.77043 4.47868 5.77043 2.88522C5.77043 1.29176 4.47868 0 2.88522 0C1.29176 0 0 1.29176 0 2.88522C0 4.47868 1.29176 5.77043 2.88522 5.77043Z'
        fill={color || '#475467'}
      />
      <path
        d='M2.88522 18.2626C4.47868 18.2626 5.77043 16.9709 5.77043 15.3774C5.77043 13.7839 4.47868 12.4922 2.88522 12.4922C1.29176 12.4922 0 13.7839 0 15.3774C0 16.9709 1.29176 18.2626 2.88522 18.2626Z'
        fill={color || '#475467'}
      />
      <path
        d='M27.393 5.77043C28.9865 5.77043 30.2782 4.47868 30.2782 2.88522C30.2782 1.29176 28.9865 0 27.393 0C25.7996 0 24.5078 1.29176 24.5078 2.88522C24.5078 4.47868 25.7996 5.77043 27.393 5.77043Z'
        fill={color || '#475467'}
      />
      <path
        d='M14.8955 13.4551C17.2843 13.4551 19.2207 11.5187 19.2207 9.1299C19.2207 6.74115 17.2843 4.80469 14.8955 4.80469C12.5068 4.80469 10.5703 6.74115 10.5703 9.1299C10.5703 11.5187 12.5068 13.4551 14.8955 13.4551Z'
        fill={color || '#475467'}
      />
      <path
        d='M7.19947 8.17188H5.29512C5.02426 8.17188 4.80469 8.39145 4.80469 8.66231V9.60144C4.80469 9.8723 5.02426 10.0919 5.29512 10.0919H7.19947C7.47033 10.0919 7.6899 9.8723 7.6899 9.60144V8.66231C7.6899 8.39145 7.47033 8.17188 7.19947 8.17188Z'
        fill={color || '#475467'}
      />
    </g>
    <defs>
      <clipPath id='clip0_860_12'>
        <rect width='33.913' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default TransferIcon
