import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import ClosePopup from '../../atoms/ClosePopup'
import TextLabel from '../../atoms/TextLabel'
import InputText from '../../atoms/InputText'
import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'
import CheckBox from '../../atoms/CheckBox'
import utils from '../../utils'
import { useTranslation } from 'react-i18next'

const Icon = styled.div`
  position: absolute;
  right: 1rem;
  transform: translateY(-50%);
  top: 50%;
`

const PopupAddLedger = ({ handleClose, onSave, activeEdit = {} }) => {
  const { t } = useTranslation()

  const ledgers = [t('income'), t('expenses')]
  const [checkLedger, setCheckLedger] = useState(0)
  const [checkDetail, setCheckDetail] = useState(false)
  const [checkMoney, setCheckMoney] = useState(false)
  const [money, setMoney] = useState('')
  const [detail, setDetail] = useState('')

  useEffect(() => {
    if (activeEdit._id) {
      setMoney(activeEdit.price)
      setCheckLedger(activeEdit.IncomeOrExpense === 1 ? 0 : 1)
      setDetail(activeEdit.detail)
    }
  }, [activeEdit])

  const onChangeMoney = value => {
    if (utils.checkNumber(value)) {
      setMoney(value)
    } else if (!value) {
      setMoney(value)
    }
  }

  const onSaveClick = async () => {
    if (!money || !detail) {
      if (!money) {
        setCheckMoney(true)
      }
      if (!detail) {
        setCheckDetail(true)
      }
      return false
    } else {
      onSave('ledger', { money, checkLedger, detail })
    }
  }

  return (
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='35%'
        height='auto'
        padding='1rem 1rem 2rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
        alignItems='flex-start'
        borderRadius='8px'
      >
        <div
          className='mr-2'
          style={{ marginLeft: 'auto' }}
          onClick={handleClose}
        >
          <ClosePopup />
        </div>
        <div className='mb-3' style={{ margin: '0 auto' }}>
          <TextLabel color='#8C56D9' fontWeight='600' fontSize='1.2rem'>
            {t('addIncome/Expenses')}
          </TextLabel>
        </div>
        <div className='mb-3' style={{ width: '100%', position: 'relative' }}>
          <InputText
            background='#F8F9FB'
            fontSize='1.5rem'
            fontWeight='500'
            padding='1rem'
            color={checkLedger === 0 ? '#8C56D9' : '#DB2D1E'}
            border={checkMoney ? '2px solid #DB2D1E' : ''}
            placeholder={t('price')}
            value={money}
            onChange={val => onChangeMoney(val.target.value)}
          />
          {/* <Icon>บาท</Icon> */}
        </div>
        <TextLabel fontWeight='600'>{t('type')}</TextLabel>
        <div className='mt-2 mb-3' style={{ display: 'flex' }}>
          {ledgers.map((gender, index) => (
            <div className='mr-3'>
              <CheckBox
                borderRadius='50%'
                border='none'
                heightCheckMark='15px'
                widthCheckMark='15px'
                backgroundColor='#8C56D9'
                text={gender}
                lineHeight='1'
                paddingLeft='25px'
                onChange={() => setCheckLedger(index)}
                onClick={() => setCheckLedger(index)}
                checked={checkLedger === index}
                fontWeight='600'
              />
            </div>
          ))}
        </div>
        <TextLabel fontWeight='600'>{`${t('details')}*`}</TextLabel>
        <div className='mt-2 mb-5' style={{ width: '100%' }}>
          <InputText
            padding='0.5rem 0.7rem'
            boxShadow='0px 4px 4px rgba(71, 84, 103, 0.05)'
            border={checkDetail ? '2px solid #DB2D1E' : ''}
            value={detail}
            onChange={val => setDetail(val.target.value)}
          />
        </div>
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ display: 'flex', width: '50%' }}>
            <Button2
              backgroundColor='#FFFFFF'
              backgroundColorHover='#E5E7EC'
              border='2px solid #98A1B2'
              color='#475467'
              fontWeight='600'
              margin='0.125rem 0.25rem 0.125rem 0'
              onClick={handleClose}
            >
              {t('cancel')}
            </Button2>
          </div>
          <div style={{ display: 'flex', width: '50%' }}>
            <Button2
              border='2px solid #53389E'
              color='white'
              backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
              backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
              fontWeight='600'
              margin='0.125rem 0 0.125rem 0.25rem'
              icon={<SaveIcon size='18' color='white' />}
              onClick={() => onSaveClick()}
            >
              {t('save')}
            </Button2>
          </div>
        </div>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddLedger
