import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

import useCustomerRecord from './useCustomerRecord'

//@ts-ignore
import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'

import { BookingHistory } from '../../redux/models/BookingHistory'
import { Customer } from '../../redux/models/Customer'
import CustomerPackage from '../../redux/models/CustomerPackage'
import utils from '../../utils'

interface CustomerRecordStyle extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  gap: 0.5rem;
  box-shadow: 0px 16px 24px rgba(152, 161, 178, 0.08);
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 50%) minmax(0, 50%);
  gap: 0.5rem;
`
const FlexBox = styled.div<CustomerRecordStyle>`
  display: flex;
  width: ${props => props.width || '100%'};
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0'};
  justify-content: ${props => props.justifyContent || 'unset'};
  align-items: ${props => props.alignItems || 'unset'};
`
const Box = styled.div<CustomerRecordStyle>`
  background: ${props => props.backgroundColor || '#FFF'};
  border-radius: 8px;
  padding: ${props => props.padding || '0'};
  gap: ${props => props.gap || '0'};
  box-shadow: ${props => props.boxShadow || 'none'};
`

interface CustomerRecordProps {
  customerBookingHistories: BookingHistory[]
  customer: Customer
  customerPackages: CustomerPackage[]
}

const CustomerRecord = ({
  customerBookingHistories,
  customer: customerData,
  customerPackages,
}: CustomerRecordProps) => {
  const { t } = useTranslation()

  const { total, totalService, totalPackage, inComeCount, services, staffs } =
    useCustomerRecord({
      customerBookingHistories,
      customerPackages,
      customerData,
    })

  return (
    <Container>
      <GridBox>
        <FlexBox flexDirection='column' gap='0.25rem'>
          <TextLabel fontWeight='500'>{t('serviceHistory')}</TextLabel>
          <GridBox>
            <Box
              backgroundColor='linear-gradient(179.6deg, #8C56D9 0.05%, #53389E 99.66%)'
              padding='0.5rem'
            >
              <FlexBox flexDirection='column'>
                <TextLabel color='#FFF'>{t('totalServicePurchase')}</TextLabel>
                <TextLabel color='#FFF'>
                  {utils.formatNumber(totalService, 2)}
                </TextLabel>
              </FlexBox>
              <FlexBox flexDirection='column'>
                <TextLabel color='#FFF'>
                  {t('totalMembershipPurchase')}
                </TextLabel>
                <TextLabel color='#FFF'>
                  {utils.formatNumber(totalPackage, 2)}
                </TextLabel>
              </FlexBox>
            </Box>
            <Box
              backgroundColor='#FFF'
              padding='0.5rem'
              boxShadow='0px 8px 24px rgba(152, 161, 178, 0.08)'
            >
              <FlexBox flexDirection='column'>
                <TextLabel>{t('numberOfTimesVisiting')}</TextLabel>
                <TextLabel fontWeight='500'>{`${utils.formatNumber(
                  inComeCount
                )} ${t('time')}`}</TextLabel>
              </FlexBox>
            </Box>
          </GridBox>
          <TextLabel fontWeight='500'>{t('repeatingTreatment')}</TextLabel>
          <Scrollbar id='scrollbar-customerService' maxHeight='120px'>
            <FlexBox flexDirection='column' gap='0.5rem'>
              {services.map(({ service, time, count }, index) => (
                <Box key={index} backgroundColor='#F2F2F2' padding='0.25rem'>
                  <FlexBox justifyContent='space-between' alignItems='center'>
                    <FlexBox flexDirection='column' width='fit-content'>
                      <TextLabel>{service}</TextLabel>
                      <TextLabel>{`${time} ${t('mins')}`}</TextLabel>
                    </FlexBox>
                    <TextLabel>{`${count} ${t('time')}`}</TextLabel>
                  </FlexBox>
                </Box>
              ))}
            </FlexBox>
          </Scrollbar>
        </FlexBox>
        <FlexBox flexDirection='column'>
          <TextLabel fontWeight='500'>{t('repeatingTherapist')}</TextLabel>
          <Scrollbar id='scrollbar-customerService' maxHeight='150px'>
            <FlexBox flexDirection='column'>
              {staffs.map(({ name, count }, index) => (
                <Box key={index} padding='0.125rem'>
                  <FlexBox justifyContent='space-between'>
                    <TextLabel>{name}</TextLabel>
                    <TextLabel>{`${count} ${t('time')}`}</TextLabel>
                  </FlexBox>
                </Box>
              ))}
            </FlexBox>
          </Scrollbar>
          <div className='mt-4'></div>
          <TextLabel fontWeight='500'>{t('extraRequest')}</TextLabel>
          {!!customerData.specialRequests && (
            <Scrollbar id='scrollbar-extraRequest' maxHeight='70px'>
              {customerData.specialRequests.map(
                ({ date, specialRequest }, index) => (
                  <FlexBox key={index}>
                    {`${utils.formatDate(
                      date,
                      'DD/MM/YYYY'
                    )} : ${specialRequest}`}
                  </FlexBox>
                )
              )}
            </Scrollbar>
          )}

          {/* <TextLabel>{`${t('extraRequest')}: ${ 
            _customer.specialRequest
          }`}</TextLabel> */}
        </FlexBox>
      </GridBox>
    </Container>
  )
}

export default CustomerRecord
