import React, { useState, useRef, useEffect, ReactNode } from 'react'
import styled from 'styled-components'
import { TVariant } from '@/types/config'
import typography from '@/styles/typography'
import BaseDropdown from './select'

export const StyledContent = styled.div<{ minWidth: number }>`
  background-color: var(--popup);
  border-radius: var(--radius-xs);
  box-shadow: var(--shadow);
  max-height: 260px;
  overflow-y: auto;
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: ${props => props.minWidth}px;
`

export const StyledItem = styled.div<{ variant?: TVariant }>`
  ${typography.body4}
  cursor: pointer;
  display: flex;
  gap: 8px;
  min-width: 100px;
  padding: 0px 2px;
  align-items: center;
  border-radius: var(--radius-xs);

  path {
    ${props => {
      switch (props.variant) {
        case 'error':
          return 'fill: var(--error);'
        default:
          return 'fill: var(--text-primary);'
      }
    }}
  }
  
  &:hover {
    background-color: ${props => 
      props.variant === 'error' ? 'var(--error-bg)' : 'var(--primary-bg)'
    };
  }

  ${props => {
    if (props.variant === 'error') {
      return 'color: var(--error);'
    }
    return `
      background-color: var(--popup);
      color: var(--text-primary);
    `
  }}
`

export const StyledTrigger = styled.div`
  cursor: pointer;
`

export const StyledDropdownContainer = styled.div`
  position: relative;
`

interface DropdownButtonProps {
  children: ReactNode
  className?: string
}

interface TriggerProps {
  children: ReactNode
  className?: string
  onClick?: (e: React.MouseEvent) => void
}

interface ContentProps {
  children: ReactNode
}

interface ItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  className?: string
  onClick?: (e: React.MouseEvent) => void
  variant?: TVariant
}

const DropdownButton: React.FC<DropdownButtonProps> & {
  Trigger: React.FC<TriggerProps>
  Content: React.FC<ContentProps>
  Item: React.FC<ItemProps>
} = ({ children, className }) => {
  const [triggerWidth, setTriggerWidth] = useState(0)
  const triggerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (triggerRef.current) {
      setTriggerWidth(triggerRef.current.offsetWidth)
    }
  }, [])

  return (
    <StyledDropdownContainer className={className}>
      <BaseDropdown
        trigger={React.Children.map(children, child => {
          if (React.isValidElement(child) && child.type === DropdownButton.Trigger) {
            return React.cloneElement(child, {
              className: child.props.className,
              ref: triggerRef,
            } as any)
          }
        })}
      >
        <StyledContent minWidth={triggerWidth}>
          {React.Children.map(children, child => {
            if (React.isValidElement(child) && child.type === DropdownButton.Content) {
              return child
            }
          })}
        </StyledContent>
      </BaseDropdown>
    </StyledDropdownContainer>
  )
}

const Trigger = React.forwardRef<HTMLDivElement, TriggerProps>(
  ({ children, onClick, ...props }, ref) => (
    <StyledTrigger ref={ref} onClick={onClick} {...props}>
      {children}
    </StyledTrigger>
  )
)

const Content: React.FC<ContentProps> = ({ children }) => <>{children}</>

const Item: React.FC<ItemProps> = ({ children, onClick, variant, ...rest }) => (
  <StyledItem variant={variant} onClick={onClick} {...rest}>
    {children}
  </StyledItem>
)

DropdownButton.Trigger = Trigger
DropdownButton.Content = Content
DropdownButton.Item = Item

export default DropdownButton