import React from 'react'

interface MaleIcon {
  size?: string
  color?: string
}

const MaleIcon = ({ size, color }: MaleIcon) => (
  <svg
    width={size || '13'}
    height={size || '13'}
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.8908 0.777985C12.8908 0.756374 12.8908 0.756374 12.8908 0.734764C12.8908 0.713153 12.8908 0.691542 12.8908 0.669931C12.8908 0.648321 12.8692 0.62671 12.8692 0.605099C12.8692 0.583489 12.8476 0.561878 12.8476 0.540267C12.8476 0.518657 12.8259 0.497046 12.8259 0.475435C12.8259 0.453825 12.8043 0.432214 12.7827 0.410603C12.7611 0.410603 12.7611 0.410603 12.7395 0.388992C12.7179 0.367382 12.7179 0.367382 12.6963 0.345771L12.6747 0.32416C12.5666 0.194496 12.3937 0.0864428 12.1992 0.0864428L9.36824 0C9.00085 0 8.71992 0.280939 8.69831 0.62671C8.69831 0.994092 8.97924 1.27503 9.32502 1.29664L10.7945 1.33986L8.09321 4.0412C7.18556 3.30644 6.08341 2.93905 4.98127 2.93905C3.70623 2.93905 2.4312 3.41449 1.45872 4.38697C-0.486241 6.33193 -0.486241 9.46548 1.45872 11.4104C2.4312 12.3829 3.70623 12.8584 4.98127 12.8584C6.2563 12.8584 7.53133 12.3829 8.50381 11.4104C10.2543 9.65998 10.4272 6.91542 9.00085 4.97046L11.659 2.31234L11.7022 3.6306C11.7022 3.97637 12.0047 4.25731 12.3505 4.25731H12.3721C12.7395 4.25731 13.0204 3.95476 12.9988 3.58737L12.8908 0.777985ZM7.57455 10.5028C6.88301 11.1943 5.95375 11.5833 4.98127 11.5833C4.00878 11.5833 3.07952 11.1943 2.38798 10.5028C1.69644 9.81125 1.30745 8.88199 1.30745 7.90951C1.30745 6.93703 1.69644 6.00777 2.38798 5.31623C3.07952 4.62469 4.00878 4.2357 4.98127 4.2357C5.95375 4.2357 6.88301 4.62469 7.57455 5.31623C8.26609 6.00777 8.65508 6.93703 8.65508 7.90951C8.65508 8.88199 8.26609 9.81125 7.57455 10.5028Z'
      fill={color || '#2E90FB'}
    />
  </svg>
)

export default MaleIcon
