import React from 'react'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddOccupation from '../settingCustomer/CardAddOccupation'

const PopupAddOccupation = ({ currentOccupation, handleClose }) => {
  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0' height='auto'>
        <CardAddOccupation
          handleClose={handleClose}
          currentOccupation={currentOccupation}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddOccupation
