import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

type Direction = 'top' | 'right' | 'bottom' | 'left';

// Updated styled components
const StyledSheetOverlay = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 1000;
  opacity: ${props => props.$isOpen ? 1 : 0};
  visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
`;

const StyledSheetContainer = styled.div<{ $isOpen: boolean; $direction: Direction }>`
  background-color: var(--popup);
  position: absolute;
  overflow: auto;
  transition: transform 0.3s ease-out;

  ${props => {
    switch (props.$direction) {
      case 'top':
        return `
          top: 0;
          left: 0;
          right: 0;
          max-height: 95vh;
          border-bottom-left-radius: var(--radius);
          border-bottom-right-radius: var(--radius);
          transform: translateY(${props.$isOpen ? '0' : '-100%'});
        `;
      case 'right':
        return `
          top: 0;
          right: 0;
          bottom: 0;
          max-width: 95vw;
          border-top-left-radius: var(--radius);
          border-bottom-left-radius: var(--radius);
          transform: translateX(${props.$isOpen ? '0' : '100%'});
        `;
      case 'bottom':
        return `
          bottom: 0;
          left: 0;
          right: 0;
          max-height: 95vh;
          border-top-left-radius: var(--radius);
          border-top-right-radius: var(--radius);
          transform: translateY(${props.$isOpen ? '0' : '100%'});
        `;
      case 'left':
        return `
          top: 0;
          left: 0;
          bottom: 0;
          max-width: 95vw;
          border-top-right-radius: var(--radius);
          border-bottom-right-radius: var(--radius);
          transform: translateX(${props.$isOpen ? '0' : '-100%'});
        `;
    }
  }}
`;

// Other styled components remain the same
const StyledSheetContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  min-width: 514px;
  height: 100%;
`;

const StyledCloseButtonWrapper = styled.div`
  cursor: pointer;
`;

const StyledCloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  padding: 0px 10px;
  width: max-content;
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
`;

export const SheetTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
`;

type SheetContextType = {
  isOpen: boolean;
  direction: Direction;
  openSheet: () => void;
  closeSheet: () => void;
};

type SheetProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  direction?: Direction;
  onOpenChange?: (isOpen: boolean) => void;
};

interface SheetTriggerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

type SheetContentProps = {
  children: React.ReactNode;
};

// Context
const SheetContext = React.createContext<SheetContextType | undefined>(undefined);

// Custom hook for using SheetContext
const useSheetContext = () => {
  const context = React.useContext(SheetContext);
  if (!context) {
    throw new Error('useSheetContext must be used within a Sheet component');
  }
  return context;
};

const Sheet: React.FC<SheetProps> & {
  Trigger: React.FC<SheetTriggerProps>;
  Content: React.FC<SheetContentProps>;
  CloseButton: React.FC;
} = ({ children, isOpen: externalIsOpen, direction = 'right', onOpenChange }) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);

  const isControlled = externalIsOpen !== undefined;
  const isOpen = isControlled ? externalIsOpen : internalIsOpen;

  const openSheet = useCallback(() => {
    if (isControlled) {
      onOpenChange?.(true);
    } else {
      setInternalIsOpen(true);
    }
  }, [isControlled, onOpenChange]);

  const closeSheet = useCallback(() => {
    if (isControlled) {
      onOpenChange?.(false);
    } else {
      setInternalIsOpen(false);
    }
  }, [isControlled, onOpenChange]);

  const contextValue = { isOpen, direction, openSheet, closeSheet };

  return (
    <SheetContext.Provider value={contextValue}>
      {children}
    </SheetContext.Provider>
  );
};

const SheetTrigger: React.FC<SheetTriggerProps> = ({ children, onClick, ...rest }) => {
  const { openSheet } = useSheetContext();

  return (
    <div
      {...rest}
      onClick={async (e) => {
        e.stopPropagation();
        openSheet();
        onClick && (await onClick(e));
      }}
    >
      {children}
    </div>
  );
};

const SheetContent: React.FC<SheetContentProps> = ({ children }) => {
  const { isOpen, direction, closeSheet } = useSheetContext();

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeSheet();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isOpen, closeSheet]);

  return ReactDOM.createPortal(
    <StyledSheetOverlay $isOpen={isOpen} onClick={closeSheet}>
      <StyledSheetContainer $isOpen={isOpen} $direction={direction} onClick={(e) => e.stopPropagation()}>
        <StyledCloseButton onClick={closeSheet}>&times;</StyledCloseButton>
        <StyledSheetContentWrapper>
          {children}
        </StyledSheetContentWrapper>
      </StyledSheetContainer>
    </StyledSheetOverlay>,
    document.body
  );
};

type SheetCloseButtonProps = {
  children?: React.ReactNode;
  className?: string;
};

const SheetCloseButton: React.FC<SheetCloseButtonProps> = ({ children, className }) => {
  const { closeSheet } = useSheetContext();
  return (
    <StyledCloseButtonWrapper onClick={closeSheet} className={className}>
      {children || '×'}
    </StyledCloseButtonWrapper>
  );
};

Sheet.Trigger = SheetTrigger;
Sheet.Content = SheetContent;
Sheet.CloseButton = SheetCloseButton;

export default Sheet;