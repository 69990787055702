import Button from '@/components/atoms/Button';
import DropdownButton from '@/components/atoms/DropdownButton';
import { Row } from '@/components/atoms/Flex';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { HiOutlineDotsHorizontal, HiOutlineDotsVertical } from "react-icons/hi";
import styled from 'styled-components';

export interface StaffRowProps {
    staff: {
        _id: string
        staffTypeId: string
        staffType: string
        name: string
        email: string
        phone: string
        image: string
    }
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
`;

const StyledImage = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

const StyledActionZone = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const StaffRow = (props: StaffRowProps) => {
    const {t} = useTranslation()
    return (
        <StyledContainer>
            <div style={{
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'center'
            }}>
            <StyledImage src={props.staff.image} alt={props.staff.name} />
            {props.staff.name}
            </div>
            <StyledActionZone>
                <DropdownButton>
                    <DropdownButton.Trigger>
                    <Button variant="ghost" size="icon">
                    <HiOutlineDotsHorizontal />
                </Button>
                    </DropdownButton.Trigger>
                    <DropdownButton.Content>
                    <DropdownButton.Item>
                        {t('edit')}
                    </DropdownButton.Item>
                    <DropdownButton.Item style={{
                        color: 'var(--danger)'
                    }}>
                        {t('delete')}
                    </DropdownButton.Item>
                    </DropdownButton.Content>
                </DropdownButton>
            </StyledActionZone>
        </StyledContainer>
    )
}