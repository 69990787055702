import styled, { CSSProperties } from 'styled-components'

interface PopupBoxProps extends CSSProperties {}

const PopupBox = styled.div<PopupBoxProps>`
  background: #00000050;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100vh'};
  padding: 0 1rem;
  margin: auto;
  top: ${props => props.top || 0};
  left: ${props => props.left || 0};
  position: fixed;
  z-index: ${props => props.zIndex || '10000'};
  display: flex;
  align-items: center;
`

export default PopupBox
