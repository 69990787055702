import React, { ReactNode, useState } from 'react'
import { Cell, Pie, PieChart } from 'recharts'
import styled from 'styled-components'

import ChartHeader from './ChartHeader'
import { getChartColor } from './config'
import BathIcon from './icons/BahtIcon'
import PieIcon from './icons/PieIcon'
import LegendItem from './LegendItem'
import ListView from './ListView'

const RADIAN = Math.PI / 180

interface CustomizedLabelProps {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: CustomizedLabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  if (percent < 0.1) return null

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const TopContainer = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 0.5px solid #e4e8eb;
  display: flex;
  justify-content: space-between;
`

const OptionContainer = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
`

const BoxSelector = styled.div<{ isSelected?: boolean }>`
  width: 32px;
  height: 32px;
  background: ${props => (props.isSelected ? '#8C56D9' : 'transparent')};
  box-shadow: 0px 0px 0px 1px
    ${props => (props.isSelected ? 'transparent' : '#E4E8EB')} inset;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 160px;
  overflow: auto;
  padding: 0.5rem;
  margin: auto 0.5rem auto 0;
`

const ViewTypes = Object.freeze({
  Normal: 0,
  Price: 1,
})

type ViewType = (typeof ViewTypes)[keyof typeof ViewTypes]

export interface CustomizedPieChartDataItem {
  name: string
  value: number
  prevValue?: number
  count: number
  type?: string
  _id?: number
}

export interface CustomizedPieChartFilter {
  service?: number
  gender?: number
  age?: number
  nationalityId?: number
}

export interface CustomizedPieChartProps {
  name: string
  icon?: ReactNode
  data: CustomizedPieChartDataItem[]
  onFilterClick: (entry: CustomizedPieChartDataItem) => void
  filterData?: CustomizedPieChartFilter
}

const exampleData: CustomizedPieChartDataItem[] = [
  { name: '', value: 0, count: 0 },
]

const CustomizedPieChart = ({
  name = '',
  icon,
  data = exampleData,
  onFilterClick,
  filterData = {},
}: CustomizedPieChartProps) => {
  const [viewType, setViewType] = useState<ViewType>(ViewTypes.Normal)

  const { service, gender, age, nationalityId } = filterData
  const sortedData = data.slice().sort((a, b) => b.value - a.value)

  return (
    <Container>
      <TopContainer>
        <ChartHeader name={name} icon={icon} />
        <OptionContainer>
          <BoxSelector
            isSelected={viewType === ViewTypes.Normal}
            onClick={() => setViewType(ViewTypes.Normal)}
          >
            <PieIcon
              color={viewType === ViewTypes.Normal ? '#fff' : '#98A1B2'}
            />
          </BoxSelector>
          <BoxSelector
            isSelected={viewType === ViewTypes.Price}
            onClick={() => setViewType(ViewTypes.Price)}
          >
            <BathIcon
              color={viewType === ViewTypes.Price ? '#fff' : '#98A1B2'}
            />
          </BoxSelector>
        </OptionContainer>
      </TopContainer>
      {viewType === ViewTypes.Normal && (
        <ChartContainer>
          <PieChart width={200} height={200}>
            <Pie
              data={sortedData}
              cx='50%'
              cy='50%'
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill='#8884d8'
              dataKey='count'
              isAnimationActive={true}
              animationDuration={250}
            >
              {sortedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getChartColor(index)}
                  fillOpacity={
                    service && entry.type === 'service' && entry._id !== service
                      ? 0.1
                      : age && entry.type === 'age' && entry._id !== age
                      ? 0.1
                      : gender &&
                        entry.type === 'gender' &&
                        entry._id !== gender
                      ? 0.1
                      : nationalityId &&
                        entry.type === 'nationality' &&
                        entry._id !== nationalityId
                      ? 0.1
                      : 'none'
                  }
                  onClick={() => onFilterClick(entry)}
                />
              ))}
            </Pie>
          </PieChart>
          <LabelContainer>
            {sortedData.map(
              ({ name, count, _id, type }, index) =>
                count > 0 && (
                  <LegendItem
                    key={index}
                    name={name}
                    color={getChartColor(index)}
                    opacity={
                      service && type === 'service' && _id !== service
                        ? 0.1
                        : age && type === 'age' && _id !== age
                        ? 0.1
                        : gender && type === 'gender' && _id !== gender
                        ? 0.1
                        : nationalityId &&
                          type === 'nationality' &&
                          _id !== nationalityId
                        ? 0.1
                        : 'none'
                    }
                  />
                )
            )}
          </LabelContainer>
        </ChartContainer>
      )}
      {viewType === ViewTypes.Price && <ListView data={sortedData} />}
    </Container>
  )
}

export default CustomizedPieChart
