import React, { PropsWithChildren, useRef } from 'react'
import styled from 'styled-components'

import useOnClickOutside from '../../hook/useOnClickOutside'

const Container = styled.div`
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  min-width: 200px;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px 0px;
  background: #fff;
`

export interface PopoverProps {
  isOpen: boolean
  onToggle: () => void
}

const Popover = ({
  isOpen,
  onToggle,
  children,
}: PropsWithChildren<PopoverProps>) => {
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => {
    onToggle()
  })

  if (!isOpen) {
    return null
  }

  return <Container ref={ref}>{children}</Container>
}

export default Popover
