import React from 'react'

const PromotionIcon = ({ size, color = '#ffff' }) => {
  const key = `Promotion_Icon_${color.substr(1)}`
  return (
    <svg
      width={size || '59'}
      height={size || '59'}
      viewBox='0 0 59 59'
      fill={`url(#${key})`}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.4562 39.7572L31.6045 15.3122C31.9063 14.4066 31.4169 13.4277 30.5112 13.1258C29.6057 12.824 28.6267 13.3134 28.3248 14.219L20.1765 38.664C19.8747 39.5696 20.3641 40.5485 21.2698 40.8505C21.451 40.9108 21.6354 40.9395 21.8166 40.9395C22.5402 40.9396 23.2147 40.4816 23.4562 39.7572Z'
        fill={`url(#${key}1)`}
      />
      <path
        d='M35.6685 32.1733C38.5278 32.1733 40.854 29.8471 40.854 26.9878C40.854 24.1285 38.5278 21.8022 35.6685 21.8022C32.8091 21.8022 30.4829 24.1285 30.4829 26.9878C30.4829 29.8471 32.8091 32.1733 35.6685 32.1733ZM35.6685 25.2593C36.6216 25.2593 37.397 26.0347 37.397 26.9878C37.397 27.9409 36.6216 28.7163 35.6685 28.7163C34.7154 28.7163 33.9399 27.9409 33.9399 26.9878C33.9399 26.0347 34.7154 25.2593 35.6685 25.2593Z'
        fill={`url(#${key}2)`}
      />
      <path
        d='M16.1128 21.8022C13.2535 21.8022 10.9272 24.1285 10.9272 26.9878C10.9272 29.8471 13.2535 32.1733 16.1128 32.1733C18.9721 32.1733 21.2983 29.8471 21.2983 26.9878C21.2983 24.1285 18.9722 21.8022 16.1128 21.8022ZM16.1128 28.7163C15.1597 28.7163 14.3843 27.9409 14.3843 26.9878C14.3843 26.0347 15.1597 25.2593 16.1128 25.2593C17.0659 25.2593 17.8413 26.0347 17.8413 26.9878C17.8413 27.9409 17.066 28.7163 16.1128 28.7163Z'
        fill={`url(#${key}3)`}
      />
      <path
        d='M58.6543 21.4336L52.6267 13.3968L52.8619 11.7509C52.9388 11.2123 52.7577 10.6688 52.373 10.2842L48.7062 6.61742L52.3728 2.95066C53.0479 2.27573 53.0479 1.18124 52.3728 0.506196C51.6979 -0.168732 50.6034 -0.168732 49.9284 0.506196L46.2617 4.17295L42.595 0.506196C42.2103 0.121428 41.6669 -0.0590288 41.1283 0.0172563L24.0168 2.46172C23.6465 2.51462 23.3035 2.6862 23.039 2.95066L1.8534 24.1363C-0.6178 26.6074 -0.6178 30.6283 1.8534 33.0994L19.7796 51.0257C20.1463 51.3924 20.5478 51.7034 20.9726 51.9612V52.6621C20.9726 56.1568 23.8158 59 27.3105 59H52.6621C56.1568 59 59 56.1568 59 52.6621V22.4707C59 22.0967 58.8786 21.7327 58.6543 21.4336ZM4.29787 30.655C3.17456 29.5317 3.17456 27.704 4.29787 26.5807L25.076 5.8026L40.7615 3.56187L43.8171 6.61753L41.3727 9.062L40.1504 7.83971C39.4755 7.16478 38.381 7.16478 37.7059 7.83971C37.0309 8.51464 37.0309 9.60913 37.7059 10.2842L42.595 15.1732C42.9325 15.5107 43.3749 15.6796 43.8171 15.6796C44.2594 15.6796 44.7019 15.5107 45.0393 15.1732C45.7144 14.4983 45.7144 13.4038 45.0393 12.7288L43.817 11.5065L46.2615 9.062L49.3172 12.1177L47.0763 27.8031L26.2983 48.5813C25.7542 49.1254 25.0307 49.425 24.2612 49.425C23.4916 49.425 22.7682 49.1253 22.2241 48.5813L4.29787 30.655ZM55.5429 52.6621C55.5429 54.2506 54.2506 55.5429 52.6621 55.5429H27.3105C25.7936 55.5429 24.5477 54.3643 24.4383 52.8748C26.0017 52.8315 27.5522 52.2164 28.7426 51.0257L49.9283 29.8401C50.1927 29.5757 50.3643 29.2326 50.4172 28.8624L51.9352 18.2366L55.5429 23.0468V52.6621Z'
        fill={`url(#${key}4)`}
      />
      <defs>
        <linearGradient
          id={`${key}1`}
          x1='25.8905'
          y1='13.0366'
          x2='25.8905'
          y2='40.9395'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={color} />
          <stop offset='1' stopColor={color} />
        </linearGradient>
        <linearGradient
          id={`${key}2`}
          x1='35.6685'
          y1='21.8022'
          x2='35.6685'
          y2='32.1733'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={color} />
          <stop offset='1' stopColor={color} />
        </linearGradient>
        <linearGradient
          id={`${key}3`}
          x1='16.1128'
          y1='21.8022'
          x2='16.1128'
          y2='32.1733'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={color} />
          <stop offset='1' stopColor={color} />
        </linearGradient>
        <linearGradient
          id={`${key}4`}
          x1='29.5'
          y1='0'
          x2='29.5'
          y2='59'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={color} />
          <stop offset='1' stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default PromotionIcon
