import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import Button2 from '../../atoms/Button2'
import SaveIcon from '../../atoms/SaveIcon'
import TextLabel from '../../atoms/TextLabel'
import SelectorBookChannelType from './SelectorBookChannelType'
import useOnClickOutside from '../../hook/useOnClickOutside'
import InputText from '../../atoms/InputText'
import SwitchButton from '../../atoms/SwitchButton'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import api from '../../api'
import auth from '../../auth'
import utils from '../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
`
const BoxSwitch = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
`
const BoxSwitchStyle = styled.div`
  width: 50%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`

const ContainerMonth = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  flex-wrap: ${props => props.flexWarp || 'none'};
  padding: ${props => props.padding || '0'};
  border-radius: 0 0 8px 8px;
  background: #f8f9fb;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  /* margin-left: 1rem; */
`
const ContainerMonthStyle = styled.div`
  display: flex;
  flex-grow: ${props => props.flexGrow || '10'};
  /* width: 100%; */
`
const BoxMoth = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  padding: ${props => props.padding || '0'};
`

const BoxMonthStyle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 10%'};
  max-width: ${props => props.width || '10%'};
  padding: ${props => props.padding || '0 0.25rem 0.25rem 0'};
`
const ButtonMonth = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${props => props.padding || '0'};
  margin: ${props => props.margin || '0'};
  background: ${props =>
    props.active
      ? 'linear-gradient(179.6deg, #8C56D9 0.05%, #53389E 99.66%)'
      : '#FFFFFF'};
  color: ${props => (props.active ? '#FFFFFF' : '#000000')};
  border-radius: 8px;
  font-size: 0.8rem;
  padding: 0.2rem 0.85rem;
  font-weight: 500;
  cursor: pointer;
`

const bookingChannelGroup = [
  // { bookingTypeId: 1, bookingType: 'ลูกค้า walk-in' },
  { bookingTypeId: 2, bookingType: 'Booking' },
  { bookingTypeId: 3, bookingType: 'Agency' },
]

const CardAddBookingChannel = ({
  handleClose,
  currentBookChannel = {},
  currentIdEdit = -1,
}) => {
  const [defaultOption, setDefaultOption] = useState(bookingChannelGroup[0])
  const [openArrow, setOpenArrow] = useState(false)
  const [bookingChannel, setBookingChannel] = useState({
    bookingChannel: '',
    payDays: [],
    commissionPercentage: 0,
    status: 0,
  })
  const [bookingChannels, setBookingChannels] = useState([])
  const [success, setSuccess] = useState(false)

  const wrapperRef = useRef(null)

  const dates = Array.from({ length: 31 }).map((_, index) => index + 1)

  useEffect(() => {
    callApiBookingChannel()
  }, [])

  const callApiBookingChannel = async () => {
    try {
      const { data } = await api.getBookingChannel()
      setBookingChannels(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (currentIdEdit !== -1 && currentBookChannel) {
      const findBookingChannelGroup = bookingChannelGroup.findIndex(
        b => b.bookingTypeId === currentBookChannel.bookingTypeId || ''
      )
      if (findBookingChannelGroup !== -1) {
        setDefaultOption(bookingChannelGroup[findBookingChannelGroup])
      }
      setBookingChannel(currentBookChannel)
    }
  }, [currentIdEdit, currentBookChannel])

  useOnClickOutside(wrapperRef, () => {
    setOpenArrow(false)
  })

  const onArrowClick = () => {
    setOpenArrow(v => !v)
  }

  const onCommissionPercentageChange = value => {
    if (utils.checkNumber(value) && utils.isInRange(value, 0, 100)) {
      setBookingChannel(v => ({ ...v, commissionPercentage: value }))
    }
  }

  const onSelectPayDays = index => {
    const newPayDays = [...bookingChannel.payDays]
    const findIndex = newPayDays.findIndex(p => p === index)
    if (findIndex === -1) {
      newPayDays.push(index)
    } else {
      newPayDays.splice(findIndex, 1)
    }
    setBookingChannel(v => ({ ...v, payDays: newPayDays }))
  }

  const onSelectDefaultOptionClick = option => {
    setDefaultOption(option)
  }

  const onStatusChange = () => {
    setBookingChannel(v => ({ ...v, status: v.status === 1 ? 0 : 1 }))
  }

  const onSaveClick = async () => {
    try {
      if (currentIdEdit !== -1) {
        const newBookChannel = {
          ...bookingChannel,
          bookingTypeId: defaultOption.bookingTypeId,
        }
        const { success } = await api.updateBookingChannel(
          newBookChannel._id,
          newBookChannel
        )
        if (success) {
          setSuccess(true)
          setTimeout(() => {
            handleClose()
          }, 1000)
        }
      } else {
        const { success } = await api.createBookingChannel({
          ...bookingChannel,
          bookingChannelId: bookingChannels.length,
          bookingTypeId: defaultOption.bookingTypeId,
          storeId: auth.getStoreId(),
        })
        if (success) {
          setSuccess(true)
          setTimeout(() => {
            handleClose()
          }, 1000)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const {
    bookingChannel: bookingChannelName,
    commissionPercentage,
    payDays,
    status,
  } = bookingChannel

  return (
    <Container>
      <div className='mb-3'>
        <TextLabel fontWeight='500' fontSize='1.25rem'>
          ช่องทางการจอง
        </TextLabel>
      </div>
      <TextLabel fontWeight='500'>ประเภทการจอง</TextLabel>
      <div className='mt-2'></div>
      <SelectorBookChannelType
        wrapperRef={wrapperRef}
        defaultOption={defaultOption}
        options={bookingChannelGroup}
        openArrow={openArrow}
        onArrowClick={onArrowClick}
        onSelectDefaultOptionClick={onSelectDefaultOptionClick}
      />
      <div className='mt-3'>
        <TextLabel fontWeight='500'>ช่องทางการจอง</TextLabel>
      </div>
      <div className='mt-2'>
        <InputText
          padding='0.25rem 0.25rem 0.25rem 1rem'
          value={bookingChannelName}
          onChange={e => {
            const data = e.target.value
            setBookingChannel(v => ({ ...v, bookingChannel: data }))
          }}
          required={true}
        />
      </div>
      {defaultOption.bookingTypeId === 3 && (
        <>
          <div className='mt-3' />
          <TextLabel fontWeight='600'>ค่าคอมมิชชั่น(%)</TextLabel>
          <div className='mt-2' />
          <InputText
            padding='0.5rem 1rem'
            boxShadow='0px 4px 4px rgba(71, 84, 103, 0.05)'
            value={commissionPercentage || ''}
            onChange={e => onCommissionPercentageChange(e.target.value)}
          />
          <div className='mt-3'></div>
          <TextLabel fontWeight='600'>วันที่จ่าย</TextLabel>
          <ContainerMonth flexWarp='wrap'>
            <ContainerMonthStyle>
              <BoxMoth padding='0.125rem'>
                {dates.map((date, index) => (
                  <BoxMonthStyle key={index}>
                    <ButtonMonth
                      active={payDays?.includes(index)}
                      onClick={() => onSelectPayDays(index)}
                    >
                      {date}
                    </ButtonMonth>
                  </BoxMonthStyle>
                ))}
              </BoxMoth>
            </ContainerMonthStyle>
          </ContainerMonth>
        </>
      )}
      <BoxSwitch className='mt-3'>
        <BoxSwitchStyle flexDirection='column'>
          <TextLabel fontWeight='600'>สถานะ</TextLabel>
          <TextLabel color='#98A1B2' fontSize='0.8rem'>
            ไม่ใช้งาน/ใช้งาน
          </TextLabel>
        </BoxSwitchStyle>
        <BoxSwitchStyle alignItems='center' justifyContent='flex-end'>
          <SwitchButton
            name='stats-bookChannel'
            toggled={status === 1}
            onChange={() => onStatusChange()}
            backgroundColor={status ? '#8C56D9' : '#ABABAD'}
            // labelHeight='20px'
            // width='40%'
            // labelWidth='30%'
            width='18px'
            labelHeight='20px'
            labelWidth='40px'
          />
        </BoxSwitchStyle>
      </BoxSwitch>
      <div
        className='mt-0'
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          justifyContent: 'flex-end',
          position: 'sticky',
          bottom: '0rem',
          background: 'white',
          zIndex: '2',
        }}
      >
        <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
          <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
            <Button2
              color='#98A1B2'
              border='2px solid #98A1B2'
              backgroundColor='white'
              fontWeight='600'
              onClick={handleClose}
            >
              ยกเลิก
            </Button2>
          </div>
          <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
            <Button2
              border='2px solid #53389E'
              color='#FFFFFF'
              backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
              backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
              fontWeight='600'
              icon={<SaveIcon size='15' />}
              onClick={() => onSaveClick()}
            >
              บันทึก
            </Button2>
          </div>
        </div>
      </div>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddBookingChannel
