import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import api from '../../api'
import PopupAddRoom from './CardAddRoom/PopupAddRoom'
import PopupAddRoomType from './CardAddRoomType/PopupAddRoomType'
import BodyRoomLeft from './BodyRoomLeft'
import PopupConfirmDelete from '../popups/PopupConfirmDelete'

const Container = styled.div`
  width: calc(100%);
  display: flex;
  overflow: hidden;
`
const LeftSide = styled.div`
  display: flex;
  width: 100%;
`

const Body = ({
  onButtonMenuClick: _onButtonMenuClick,
  activeBtnMenu,
  activeAddData,
  activeAddGroupData,
  handleClose: _handleClose,
}) => {
  const [services, setServices] = useState([])
  const [roomTypes, setRoomTypes] = useState([])
  const [rooms, setRooms] = useState([])
  const [roomNoneActives, setRoomNoneActives] = useState([])
  const [currentIdEdit, setCurrentIdEdit] = useState(-1)
  const [currentDataEdit, setCurrentDataEdit] = useState('')
  const [activeEditType, setActiveEditType] = useState(false)
  const [activeEdit, setActiveEdit] = useState(false)
  const [activeDeleteType, setActiveDeleteType] = useState(false)
  const [activeDelete, setActiveDelete] = useState(false)
  const [success, setSuccess] = useState(false)
  const [activeRoom, setActiveRoom] = useState(true)

  useEffect(() => {
    callApi()
  }, [])

  const onButtonMenuClick = index => {
    setCurrentIdEdit(-1)
    setCurrentDataEdit('')
    callApi()
    _onButtonMenuClick(index)
  }

  const callApi = async () => {
    await callApiServices()
    await callApiRoomType()
    await callApiRoom()
    await callApiRoomByStatusRoom()
  }

  const callApiServices = async () => {
    try {
      const { data } = await api.getServices()
      setServices(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiRoomType = async () => {
    try {
      const { data } = await api.getRoomTypeByStoreId()
      setRoomTypes(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiRoom = async () => {
    try {
      const { data } = await api.getRoomByStatusRoom(1)
      setRooms(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiRoomByStatusRoom = async () => {
    try {
      const { data } = await api.getRoomByStatusRoom(-1)
      setRoomNoneActives(data)
    } catch (err) {
      console.log(err)
    }
  }

  const handleClose = () => {
    setCurrentIdEdit(-1)
    setCurrentDataEdit('')
    setActiveEditType(false)
    setActiveEdit(false)
    setActiveDeleteType(false)
    setActiveDelete(false)
    setSuccess(false)
    callApi()
    setActiveRoom(false)
    _handleClose()
  }

  const onEditClick = async _id => {
    await callApi()

    let findData = ''
    if (activeBtnMenu === 0) {
      findData = rooms.find(r => r._id === _id || '') || ''
      if (!findData) {
        findData = roomNoneActives.find(r => r._id === _id || '') || ''
      }
    } else {
      findData = roomTypes.find(t => t._id === _id || '') || ''
    }
    if (findData) {
      setCurrentIdEdit(_id)
      setCurrentDataEdit(findData)
      setActiveEdit(!activeEdit)
    }
    setActiveRoom(true)
  }
  const onDeleteClick = _id => {
    setActiveRoom(true)
    setActiveDelete(!activeDelete)
    setCurrentIdEdit(_id)
  }

  const onEditTypeClick = roomTypeId => {
    const findData = roomTypes.find(t => t._id === roomTypeId || '') || ''
    if (findData) {
      setActiveEditType(true)
      setCurrentIdEdit(roomTypeId)
      setCurrentDataEdit(findData)
    }
  }
  const onDeleteTypeClick = roomTypeId => {
    setActiveDeleteType(!activeDeleteType)
    setCurrentIdEdit(roomTypeId)
  }
  const onConfirmDeleteClick = async () => {
    try {
      if (activeDeleteType) {
        const { success } = await api.removeRoomType(currentIdEdit)
        if (success) {
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
            handleClose()
          }, 1000)
        }
      } else if (activeDelete) {
        const { success } = await api.removeRoom(currentIdEdit)
        if (success) {
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
            handleClose()
          }, 1000)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <LeftSide>
        {/* <div
          className='mb-3'
          style={{
            display: 'flex',
          }}
        >
          <ButtonMenu
            onButtonMenuClick={onButtonMenuClick}
            openRight={openRight}
          />
        </div> */}
        {activeBtnMenu === 0 && (
          <BodyRoomLeft
            roomTypes={roomTypes}
            rooms={rooms}
            roomNoneActives={roomNoneActives}
            onEditClick={onEditClick}
            onEditTypeClick={onEditTypeClick}
            onDeleteTypeClick={onDeleteTypeClick}
            onDeleteClick={onDeleteClick}
            activeRoom={activeRoom}
          />
        )}
        {/* {activeBtnMenu === 1 && (
          <BodyRoomTypeLeft
            roomTypes={roomTypes}
            rooms={rooms}
            roomNoneActives={roomNoneActives}
            onEditClick={onEditClick}
          />
        )} */}
      </LeftSide>
      {/* <RightSide>
        {activeBtnMenu === 0 && (
          <BodyRoomRight
            roomTypes={roomTypes}
            rooms={rooms}
            currentIdEdit={currentIdEdit}
            currentDataEdit={currentDataEdit}
            handleClose={handleClose}
          />
        )}
        {activeBtnMenu === 1 && (
          <BodyRoomTypeRight
            services={services}
            currentIdEdit={currentIdEdit}
            currentDataEdit={currentDataEdit}
            handleClose={handleClose}
          />
        )}
      </RightSide> */}
      {(activeAddGroupData || activeEditType) && (
        <PopupAddRoomType
          handleClose={handleClose}
          currentIdEdit={currentIdEdit}
          currentDataEdit={currentDataEdit}
        />
      )}
      {(activeAddData || activeEdit) && (
        <PopupAddRoom
          handleClose={handleClose}
          currentIdEdit={currentIdEdit}
          currentDataEdit={currentDataEdit}
          roomTypes={roomTypes}
        />
      )}
      {(activeDeleteType || activeDelete) && (
        <PopupConfirmDelete
          handleClose={handleClose}
          onConfirmDeleteClick={onConfirmDeleteClick}
          text='ยืนยันการลบข้อมูล'
          success={success}
        />
      )}
    </Container>
  )
}

export default Body
