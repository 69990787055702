import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import TextLabel from '../../atoms/TextLabel'

const Container = styled.div`
  width: 100%;
  border-radius: 0px;
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border: ${props => props.border || '2px solid #e4e8eb'};
  &:first-child {
    border-radius: 8px 8px 0px 0px;
    border-width: 2px 2px 0 2px;
  }
  &:last-child {
    border-radius: 0px 0px 8px 8px;
    border-width: 0 2px 2px 2px;
  }
  &:only-child {
    border-radius: 8px;
    border-width: 2px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }
`
const Box = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
const ContainerCircle = styled.div`
  display: flex;
  max-width: ${props => props.width || '5.5%'};
`
const ContainerCircleStyle = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: wrap;
  width: ${props => (props.open ? '100%' : '70%')};
  padding: ${props => props.padding || '0'};
  height: fit-content;
`
const BoxCircle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 33%'};
  max-width: ${props => props.width || '33%'};
  margin: ${props => props.margin || '0.25rem 0 0 0'};
`
const Circle = styled.div`
  background: #98a1b2;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`
const Image = styled.img`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: 0.5rem;
  object-fit: cover;
`
const Image2 = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #e4e8eb;
  margin-left: 0.5rem;
`
const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.16);
  border-radius: 8px;
  padding: 0.5rem;
  top: 10px;
  right: 0px;
  z-index: 1;
`
const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`
const BoxEdit = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 1 auto'};
  max-width: ${props => props.width || 'auto'};
  margin-left: ${props => props.marginLeft || '0'};
  cursor: ${props => props.cursor || 'none'};
  position: relative;
`

const StaffDrag = ({
  task,
  index,
  activeStaffEdit,
  onDropDownStaffEditClick,
  onEditStaffClick: _onEditStaffClick,
  onDeleteStaffClick: _onDeleteStaffClick,
}) => {
  const onEditStaffClick = (e, id) => {
    e.stopPropagation()
    _onEditStaffClick(id)
  }
  const onDeleteStaffClick = (e, id) => {
    e.stopPropagation()
    _onDeleteStaffClick(id)
  }

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => {
        return (
          <Container ref={provided.innerRef} {...provided.draggableProps}>
            <Box onClick={e => onEditStaffClick(e, task._id)}>
              <div style={{ display: 'flex', width: '100%' }}>
                <ContainerCircle {...provided.dragHandleProps}>
                  <ContainerCircleStyle>
                    {Array.from({ length: 9 }).map((_, index) => (
                      <BoxCircle key={index}>
                        <Circle></Circle>
                      </BoxCircle>
                    ))}
                  </ContainerCircleStyle>
                </ContainerCircle>
                {task.image ? <Image src={task.image} /> : <Image2></Image2>}
                <TextLabel paddingLeft='0.5rem'>{task.content}</TextLabel>
              </div>
              <BoxEdit onClick={e => onDropDownStaffEditClick(e, task._id)}>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div className='mr-1' key={index}>
                    <Circle></Circle>
                  </div>
                ))}
                {activeStaffEdit === task._id && (
                  <DropDown
                  // onClick={onDropDownClick.bind(this)}
                  >
                    <Option onClick={e => onEditStaffClick(e, task._id)}>
                      แก้ไข
                    </Option>
                    <Option onClick={e => onDeleteStaffClick(e, task._id)}>
                      ลบ
                    </Option>
                  </DropDown>
                )}
              </BoxEdit>
            </Box>
          </Container>
        )
      }}
    </Draggable>
  )
}

export default StaffDrag
