import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { css, keyframes } from 'styled-components'

// Types
type ToastType = 'default' | 'success' | 'error' | 'info' | 'warning'
type ToastPosition = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'

interface ToastProps {
  id: string
  message: string
  type: ToastType
  position: ToastPosition
  duration: number
}

interface ToastOptions {
  position?: ToastPosition
  duration?: number
}

type ToastFunction = {
  (message: string, options?: ToastOptions): void
  success: (message: string, options?: ToastOptions) => void
  error: (message: string, options?: ToastOptions) => void
  info: (message: string, options?: ToastOptions) => void
  warning: (message: string, options?: ToastOptions) => void
}

// Global state
let globalToasts: ToastProps[] = []
let setGlobalToasts: React.Dispatch<React.SetStateAction<ToastProps[]>> | null =
  null

// Animations
const slideIn = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
`

const slideOut = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(120%); }
`

// Styled components
const ToastContainer = styled.div<{ $position: ToastPosition }>`
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  ${({ $position }) => {
    switch ($position) {
      case 'top-right':
        return css`
          top: 20px;
          right: 20px;
        `
      case 'top-left':
        return css`
          top: 20px;
          left: 20px;
        `
      case 'bottom-right':
        return css`
          bottom: 20px;
          right: 20px;
        `
      case 'bottom-left':
        return css`
          bottom: 20px;
          left: 20px;
        `
    }
  }}

  &:hover > div {
    transform: translateY(0);
    opacity: 1 !important;
    margin-top: 10px !important;
  }
`

interface StyledToastProps {
  $type: ToastType
  $isExiting: boolean
  $index: number
  $position: ToastPosition | undefined
}

const StyledToast = styled.div<StyledToastProps>`
  display: flex;
  align-items: center;
  width: 300px;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: ${slideIn} 0.3s ease-out;
  cursor: pointer;
  margin-top: ${props => (props.$index === 0 ? '0' : '-40px')};
  transform: translateY(${props => props.$index * 10}px);
  opacity: ${props => 1 - props.$index * 0.1};
  z-index: ${props => 9999 - props.$index};
  transition: all 0.3s ease;

  ${props =>
    props.$isExiting &&
    css`
      animation: ${slideOut} 0.3s ease-in forwards;
    `}

  ${props => {
    switch (props.$type) {
      case 'default':
        return css`
          background-color: white;
          color: #333;
          border: 1px solid #e0e0e0;
        `
      case 'success':
        return css`
          background-color: #4caf50;
          color: white;
        `
      case 'error':
        return css`
          background-color: #f44336;
          color: white;
        `
      case 'info':
        return css`
          background-color: #2196f3;
          color: white;
        `
      case 'warning':
        return css`
          background-color: #ff9800;
          color: white;
        `
    }
  }}

  &:first-child {
    margin-top: 0;
  }
`

const IconContainer = styled.div`
  margin-right: 12px;
`

const ContentContainer = styled.div`
  flex-grow: 1;
`

const CloseButton = styled.button<{ $type: ToastType }>`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  margin-left: 12px;
  color: ${props => (props.$type === 'default' ? '#333' : 'white')};
`

// Toast component
const Toast: React.FC<
  ToastProps & { onDismiss: () => void; index: number }
> = ({ id, message, type, duration, onDismiss, index }) => {
  const [isExiting, setIsExiting] = useState(false)

  const handleDismiss = useCallback(() => {
    setIsExiting(true)
  }, [])

  useEffect(() => {
    const timer = setTimeout(handleDismiss, duration)
    return () => clearTimeout(timer)
  }, [duration, handleDismiss])

  useEffect(() => {
    if (isExiting) {
      setTimeout(() => {
        onDismiss()
        globalToasts = globalToasts.filter(toast => toast.id !== id)
        setGlobalToasts?.(globalToasts)
      }, 3000)
    }
  }, [id, isExiting, onDismiss])

  const getPosition = () => {
    const element = document.getElementById('toast-container')
    if (!element) return
    const position = element.getAttribute('data-toast-position')
    if (!position) return
    return position as ToastPosition
  }

  return (
    <StyledToast
      $type={type}
      $isExiting={isExiting}
      $index={index}
      onClick={e => {
        e.stopPropagation()
        handleDismiss()
      }}
      $position={getPosition()}
      style={{
        opacity: isExiting ? 0 : 1,
      }}
    >
      <IconContainer>
        {type === 'success' && '✓'}
        {type === 'error' && '✗'}
        {type === 'info' && 'ℹ'}
        {type === 'warning' && '⚠'}
      </IconContainer>
      <ContentContainer>{message}</ContentContainer>
      <CloseButton
        $type={type}
        onClick={e => {
          e.stopPropagation()
          handleDismiss()
        }}
      >
        ×
      </CloseButton>
    </StyledToast>
  )
}

// ToastProvider component
export const ToastProvider: React.FC = () => {
  const [localToasts, setLocalToasts] = useState<ToastProps[]>([])

  useEffect(() => {
    setGlobalToasts = setLocalToasts
    return () => {
      setGlobalToasts = null
    }
  }, [])

  useEffect(() => {
    setLocalToasts(globalToasts)
  }, [globalToasts])

  const removeToast = useCallback((id: string) => {
    setLocalToasts(prev => prev.filter(toast => toast.id !== id))
  }, [])

  const groupedToasts = localToasts.reduce((acc, toast) => {
    if (!acc[toast.position]) acc[toast.position] = []
    acc[toast.position].push(toast)
    return acc
  }, {} as Record<ToastPosition, ToastProps[]>)

  return (
    <>
      {(Object.entries(groupedToasts) as [ToastPosition, ToastProps[]][]).map(
        ([position, toasts]) => (
          <ToastContainer
            id='toast-container'
            key={position}
            $position={position}
            data-toast-position={position}
          >
            {toasts.map((toast, index) => (
              <Toast
                key={toast.id}
                {...toast}
                onDismiss={() => removeToast(toast.id)}
                index={index}
              />
            ))}
          </ToastContainer>
        )
      )}
    </>
  )
}

// Toast function
const createToast = (
  message: string,
  type: ToastType,
  options?: ToastOptions
) => {
  const id = Math.random().toString(36).substr(2, 9)
  const newToast: ToastProps = {
    id,
    message,
    type,
    position: options?.position || 'top-right',
    duration: options?.duration || 5000,
  }
  globalToasts = [newToast, ...globalToasts]
  setGlobalToasts?.(globalToasts)
}

export const toast: ToastFunction = Object.assign(
  (message: string, options?: ToastOptions) =>
    createToast(message, 'default', options),
  {
    success: (message: string, options?: ToastOptions) =>
      createToast(message, 'success', options),
    error: (message: string, options?: ToastOptions) =>
      createToast(message, 'error', options),
    info: (message: string, options?: ToastOptions) =>
      createToast(message, 'info', options),
    warning: (message: string, options?: ToastOptions) =>
      createToast(message, 'warning', options),
  }
)
