import dayjs from 'dayjs'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

import DateIcon5 from '../../../atoms/DateIcon5'
import TextLabel from '../../../atoms/TextLabel'

import useOnClickOutside from '../../../hook/useOnClickOutside'
//@ts-ignore
import utils from '../../../utils'
//@ts-ignore
import Calendar from '../../datePicker/Calendar2'

interface BoxDateStyle extends CSSProperties {}

const Container = styled.div`
  display: flex;
`
const Button = styled.div<BoxDateStyle>`
  background: #ffffff;
  border: 1px solid #e4e8eb;
  border-radius: 4px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => props.color};
  background: ${props => props.background};
  :hover {
    box-shadow: '0px 4px 4px rgba(71, 84, 103, 0.05)';
  }
`
const Box = styled.div<BoxDateStyle>`
  background: #ffffff;
  border-radius: 4px;
  margin: ${props => props.margin || '0'};
  padding: 0 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: ${props => props.width || 'auto'};
  justify-content: ${props => props.justifyContent || 'unset'};
  height: 100%;
`
const Arrow = styled.div<BoxDateStyle>`
  border: ${props => props.border || 'solid #000000'};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transition: all 0.4s ease;
  transform: ${props => props.transform || 'rotate(-225deg)'};
  cursor: pointer;
`
const BoxCalendar = styled.div`
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  position: absolute;
  padding: 0.5rem 1rem;
  top: 3.5rem;
  z-index: 10;
  width: 263px;
`

interface BoxDateProps {
  date: Date
  currentDate: Date
  _onDateChange: (date: Date) => void
  onCurrentDateClick: (date: Date) => void
}

const BoxDate = ({
  date = new Date(),
  currentDate = new Date(),
  _onDateChange,
  onCurrentDateClick,
}: BoxDateProps) => {
  const { t } = useTranslation()
  const [showDate, setShowDate] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => {
    setShowDate(false)
  })

  const onDateChange = (date: Date) => {
    _onDateChange(date)
  }

  const ShowCalendar = () => {
    return (
      <Calendar
        labelFontSize='1rem'
        ranged={false}
        currentDate={currentDate}
        selectedDate={date}
        onDateChange={onDateChange}
        highlightColor='#475467'
        fontwardable={true}
        backwardable={true}
      />
    )
  }

  const onNextDateClick = (type: string) => {
    if (type === 'go') {
      _onDateChange(dayjs(date).add(1, 'day').toDate())
    } else {
      _onDateChange(dayjs(date).subtract(1, 'day').toDate())
    }
  }

  return (
    <Container>
      <Button
        onClick={() => onCurrentDateClick(new Date())}
        color='#8C56D9'
        background='#EDE9F8'
      >
        {t('today')}
      </Button>
      <Box margin='0 0.5rem 0 0.5rem' onClick={() => onNextDateClick('back')}>
        <Arrow />
      </Box>
      <div style={{ position: 'relative' }} ref={ref}>
        <Box
          margin='0 0.5rem 0 0'
          width='263px'
          justifyContent='space-between'
          onClick={() => setShowDate(!showDate)}
        >
          <TextLabel paddingRight='1rem' color='#475467'>
            {`${utils.formatDate(date, 'dddd')}, ${utils.formatDate(
              date,
              'DD MMMM YYYY'
            )}`}
          </TextLabel>
          <DateIcon5 color='#E4E8EB' />
        </Box>
        {showDate && <BoxCalendar>{ShowCalendar()}</BoxCalendar>}
      </div>
      <Box onClick={() => onNextDateClick('go')}>
        <Arrow transform='rotate(-45deg)'></Arrow>
      </Box>
    </Container>
  )
}

export default BoxDate
