import React from 'react'
import styled from 'styled-components'

import TextLabel from '../../atoms/TextLabel'
import dayjs from 'dayjs'
import Arrow from '../../atoms/Arrow'
import utils from '../../utils'
import { useDispatch } from 'react-redux'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => props.gap || '0.5rem'};
  padding: ${props => props.padding || '0'};
  width: 100%;
`
const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0.25rem'};
  position: ${props => props.position || 'unset'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
  background-color: ${props => props.backgroundColor || ''};
  padding: ${props => props.padding || '0.5rem'};
  border-radius: 8px;
`

const TablePackage = ({ packages, orders }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const onOpenDetailClick = index => {
    const newArrPackage = [...packages]
    newArrPackage[index].open = !newArrPackage[index].open
    dispatch(getOrderBooking({ packages: newArrPackage }))
  }

  return (
    <Container>
      <TextLabel color='#000' fontWeight='500'>
        {t('customerInformation')}
      </TextLabel>
      <Box backgroundColor='#ffffff' flexDirection='column'>
        <TextLabel>{orders[0].name}</TextLabel>
        <TextLabel>{utils.formatPhoneNumber(orders[0].phoneNumber)}</TextLabel>
      </Box>
      <TextLabel color='#000' fontWeight='500'>
        {t('packageDetails')}
      </TextLabel>
      <Box>
        <GridBox columns='repeat(3, minmax(0, 1fr)) minmax(0, 0.25fr)'>
          <TextLabel color='#98A1B2' textAlign='center'>
            {t('packageName')}
          </TextLabel>
          <TextLabel color='#98A1B2' textAlign='center'>
            {t('price')}
          </TextLabel>
          <TextLabel color='#98A1B2' textAlign='center'>
            {t('validUntil')}
          </TextLabel>
          <TextLabel>{``}</TextLabel>
        </GridBox>
      </Box>
      <Box flexDirection='column' padding='0'>
        {packages.map(
          (
            { name, price, quantity, expiryDay, open, allService, services },
            index
          ) => (
            <Box backgroundColor='#ffffff' flexDirection='column'>
              <GridBox columns='repeat(3, minmax(0, 1fr)) minmax(0, 0.25fr)'>
                <TextLabel textAlign='center'>{`${name}`}</TextLabel>
                <TextLabel textAlign='center'>
                  {`${utils.formatNumber(price || 0)}`}
                </TextLabel>
                <TextLabel textAlign='center'>
                  {`${dayjs(new Date())
                    .add(expiryDay, 'day')
                    .format('DD/MM/YY')}`}
                </TextLabel>
                <div onClick={() => onOpenDetailClick(index)}>
                  <Arrow
                    transform={open ? 'rotate(-135deg)' : 'rotate(45deg)'}
                  />
                </div>
              </GridBox>
              {open && (
                <div>
                  <TextLabel>{t('packageDetails')}</TextLabel>
                  <Box>
                    <TextLabel>{t('service')}</TextLabel>
                    {allService ? (
                      <TextLabel>{t('allTreatments')}</TextLabel>
                    ) : (
                      services.map(({ name, timePrices }) => (
                        <div>
                          <TextLabel>{name} </TextLabel>
                          <TextLabel>
                            {timePrices.map(({ time }) => time).join(', ')}
                          </TextLabel>
                        </div>
                      ))
                    )}
                  </Box>
                  <Box>
                    <TextLabel>{`${t('expiryPeriod')}:`}</TextLabel>
                    <TextLabel>{`${expiryDay} วัน`}</TextLabel>
                  </Box>
                </div>
              )}
            </Box>
          )
        )}
      </Box>
    </Container>
  )
}

export default TablePackage
