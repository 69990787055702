const initState = {
  _id: 0,
  date: new Date(),
  otherTransactions: [
    {
      detail: '',
      price: 0,
      dateTime: new Date(),
      type: 0,
      payChannelId: -1,
      createBy: -1,
      status: 0,
    },
  ],
  transactions: [
    {
      detail: '',
      price: 0,
      dateTime: new Date(),
      type: 0,
      payChannelId: -1,
      createBy: -1,
      status: 0,
    },
  ],
  storeId: { type: Number, require: 'storeId' },
}

const incomeAndExpenseReducers = (state = initState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default incomeAndExpenseReducers
