import React from 'react'

const DownloadIcon = () => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.54143 11.319C7.56787 11.3455 7.62076 11.3983 7.67366 11.4248C7.7001 11.4248 7.7001 11.4512 7.72655 11.4512C7.753 11.4777 7.77944 11.4777 7.80589 11.5041C7.83233 11.5041 7.85878 11.5306 7.88523 11.5306C7.91167 11.5306 7.93812 11.557 7.96457 11.557C8.01746 11.557 8.07035 11.5835 8.12324 11.5835C8.17614 11.5835 8.22903 11.5835 8.28192 11.557C8.30837 11.557 8.33481 11.5306 8.36126 11.5306C8.38771 11.5306 8.41415 11.5041 8.4406 11.5041C8.46705 11.5041 8.49349 11.4777 8.51994 11.4512C8.54638 11.4512 8.57283 11.4248 8.57283 11.4248C8.62572 11.3983 8.65217 11.3719 8.70506 11.319L12.3018 7.72231C12.6191 7.40496 12.6191 6.90248 12.3018 6.61157C11.9844 6.29422 11.4819 6.29422 11.191 6.61157L8.94308 8.8595V0.793388C8.94308 0.343802 8.59928 0 8.14969 0C7.7001 0 7.3563 0.343802 7.3563 0.793388V8.83306L5.16126 6.63802C4.8439 6.32066 4.34143 6.32066 4.05052 6.63802C3.73316 6.95537 3.73316 7.45785 4.05052 7.74876L7.54143 11.319Z'
        fill='white'
      />
      <path
        d='M13.5405 3.83594H13.276C12.8529 3.83594 12.4562 4.15329 12.4298 4.57643C12.4033 5.02602 12.7736 5.42271 13.2231 5.42271H13.5405C14.2545 5.42271 14.8099 6.00453 14.8099 6.69214V12.431C14.8099 13.5417 13.9107 14.4144 12.8264 14.4144H3.57025C2.48595 14.4144 1.58678 13.5153 1.58678 12.431V6.69214C1.58678 5.97809 2.1686 5.42271 2.8562 5.42271C3.27934 5.42271 3.67603 5.10536 3.70248 4.68222C3.72893 4.23263 3.35868 3.83594 2.90909 3.83594H2.8562C1.26942 3.83594 0 5.10536 0 6.69214V12.431C0 14.4144 1.61322 16.0012 3.57025 16.0012H12.8264C14.7835 16.0012 16.3967 14.388 16.3967 12.431V6.69214C16.3967 5.10536 15.1273 3.83594 13.5405 3.83594Z'
        fill='white'
      />
    </svg>
  )
}
export default DownloadIcon
