import React, { ReactNode, useState } from 'react'
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts'
import styled from 'styled-components'

import ChartHeader from './ChartHeader'

// import LegendItem from './LegendItem'
import MenuSelector from '../../molecules/MenuSelector'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const TopContainer = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 0.5px solid #e4e8eb;
  display: flex;
  justify-content: space-between;
`

const LegendContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  gap: 0.25rem;
`

const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

// const RenderLegend = ({ payload }) => {
//   const { t } = useTranslation()

//   return (
//     <LegendContainer>
//       {payload.map((entry, index) => (
//         <LegendItem
//           name={entry.dataKey === 'value' ? t('today') : t('yesterday')}
//           color={entry.dataKey === 'value' ? '#53389E' : '#8C56D9'}
//           key={index}
//         />
//       ))}
//     </LegendContainer>
//   )
// }

export interface CustomizedBarChartDataItem {
  name: string
  value: number
  prevValue?: number
}

export interface CustomizedBarChartProps<T> {
  name: string
  yAxisName: string
  icon: ReactNode
  data: CustomizedBarChartDataItem[] | CustomizedBarChartDataItem[][]
  dataKey: string
  comparingDataKey?: string
  options?: string[]
}

const exampleData: CustomizedBarChartDataItem[] = [
  {
    name: '',
    value: 0,
    prevValue: 0,
  },
]

const CustomizedBarChart = <T,>({
  name,
  yAxisName,
  icon,
  data = exampleData,
  dataKey,
  comparingDataKey,
  options = [] as string[],
}: CustomizedBarChartProps<T>) => {
  const [dataIndex, setDataIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const currentData = options.length
    ? (data as CustomizedBarChartDataItem[][])[dataIndex]
    : (data as CustomizedBarChartDataItem[])
  const option = options[dataIndex]

  const onSelect = (opt: typeof option, name: string, index: number) => {
    setDataIndex(index)
  }

  const onToggle = (value: boolean) => {
    setIsOpen(value)
  }

  return (
    <Container>
      <TopContainer>
        <ChartHeader name={name} icon={icon} />
        {!!options.length && (
          <MenuSelector
            name='option'
            option={option}
            options={options}
            isOpen={isOpen}
            transparent
            onSelect={onSelect}
            onToggle={onToggle}
          />
        )}
      </TopContainer>
      <ChartContainer>
        <BarChart
          width={380}
          height={225}
          data={currentData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey='name' />
          <YAxis
            label={{
              value: yAxisName,
              style: { textAnchor: 'middle' },
              angle: -90,
              position: 'left',
              offset: 0,
            }}
          />
          <Tooltip />
          {/* <Legend content={RenderLegend} /> */}
          <Bar dataKey={dataKey || 'value'} fill='#8C56D9' barSize={15} />
          {comparingDataKey && (
            <Bar dataKey={comparingDataKey} fill='#8C56D9' barSize={15} />
          )}
        </BarChart>
      </ChartContainer>
    </Container>
  )
}

export default CustomizedBarChart
