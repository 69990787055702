import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import ButtonMenu from './ButtonMenu'
import BodyBookChannel from './BodyBookChannel'
import BodyPayChannel from './BodyPayChannel'
import PopupAddBookChannel from '../popups/PopupAddBookChannel'
import PopupAddPayChannel from '../popups/PopupAddPayChannel'
import api from '../../api'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const Body = ({
  onMenuClick: _onMenuClick,
  activeAddData,
  handleClose: _handleClose,
}) => {
  const [activeMenu, setActiveMenu] = useState('การจอง')
  const [bookingChannels, setBookingChannels] = useState([])
  const [payChannels, setPayChannels] = useState([])

  useEffect(() => {
    callApiBookingChannel()
    callApiPayChannels()
  }, [])

  const callApiBookingChannel = async () => {
    try {
      const { data } = await api.getBookingChannel()
      setBookingChannels(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiPayChannels = async () => {
    try {
      const { data } = await api.getPayChannel()
      setPayChannels(data)
    } catch (err) {
      console.log(err)
    }
  }

  const onMenuClick = menu => {
    setActiveMenu(menu)
    _onMenuClick(menu)
  }
  const handleClose = () => {
    update()
    _handleClose()
  }

  const update = async () => {
    await callApiBookingChannel()
    await callApiPayChannels()
  }

  return (
    <Container>
      <ButtonMenu onMenuClick={onMenuClick} activeMenu={activeMenu} />
      {activeMenu === 'การจอง' && (
        <BodyBookChannel bookingChannels={bookingChannels} update={update} />
      )}
      {activeMenu === 'การจ่ายเงิน' && (
        <BodyPayChannel payChannels={payChannels} update={update} />
      )}
      {activeAddData && activeMenu === 'การจอง' && (
        <PopupAddBookChannel handleClose={handleClose} />
      )}
      {activeAddData && activeMenu === 'การจ่ายเงิน' && (
        <PopupAddPayChannel
          payChannels={payChannels}
          handleClose={handleClose}
        />
      )}
    </Container>
  )
}

export default Body
