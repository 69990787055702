import React from 'react'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddCustomer from '../settingCustomer/CardAddCustomer'

const PopupAddCustomer = ({ handleClose, currentCustomer }) => {
  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0' height='90%'>
        <CardAddCustomer
          handleClose={handleClose}
          currentCustomer={currentCustomer}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddCustomer
