import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'
import ServiceGroupDrag from './ServiceGroupDrag'
import api from '../../api'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  overflow: hidden;
`

const Products = ({
  productGroup,
  products,
  onEditGroupClick: _onEditGroupClick,
  onEditClick,
  activeGroupEdit,
  update,
  onDeleteClick,
  search,
}) => {
  const [state, setState] = useState('')
  const [temp, setTemp] = useState({ check: false, id: 0 })
  const [activeProductGroupEdit, setActiveProductGroupEdit] = useState(false)
  const [openProductGroupEditId, setOpenProductGroupEditId] = useState(-1)

  useEffect(() => {
    if (!activeGroupEdit) {
      setActiveProductGroupEdit(-1)
    }
  }, [activeGroupEdit])

  const convertArrayToObject = (array, key) => {
    const initialValue = {}
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      }
    }, initialValue)
  }

  useEffect(() => {
    if (productGroup.length) {
      let columns = []
      let columnOrder = []
      let tasks = []

      for (let i = 0; i < productGroup.length; i++) {
        const { headGroup, groupName, _id: id } = productGroup[i]
        columns.push({
          id: 'column-' + id,
          _id: id,
          title: groupName,
          headGroup: headGroup,
          open: true,
          taskIds: [],
        })
        columnOrder.push('column-' + id)
        for (let j = 0; j < products.length; j++) {
          const { groupId, headGroupId, name, image, price, _id } = products[j]
          if (groupId === id) {
            columns[i].taskIds.push('task-' + _id)
            tasks.push({
              id: 'task-' + _id,
              _id: _id,
              content: name,
              image: image,
              headGroupId: headGroupId,
              price: price,
            })
          }
        }
      }

      columns = convertArrayToObject(columns, 'id')
      tasks = convertArrayToObject(tasks, 'id')
      setState({ columnOrder, columns, tasks })
    }
  }, [productGroup, products])

  const onDragEnd = result => {
    const { destination, source, draggableId, type } = result

    if (!destination) {
      setTemp(true)
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      setTemp(true)
      return
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(state.columnOrder)
      newColumnOrder.splice(source.index, 1)
      newColumnOrder.splice(destination.index, 0, draggableId)

      const newState = {
        ...state,
        columnOrder: newColumnOrder,
      }
      setTemp({ check: true, id: 3 })
      setState(newState)
      return
    }

    const start = state.columns[source.droppableId]
    const finish = state.columns[destination.droppableId]
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      }

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      }
      setState(newState)
      setTemp({ check: true, id: 4 })
      return
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice(source.index, 1)
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    }

    const finishTaskIds = Array.from(finish.taskIds)
    finishTaskIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    }

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }
    setState(newState)
    setTemp({ check: true, id: 5 })
    return
  }

  useEffect(() => {
    if (temp.check) {
      var _columns = objectToArray(state.columns)

      var _tasks = objectToArray(state.tasks)

      if (temp.id === 3) {
        let number = 1
        for (let i = 0; i < state.columnOrder.length; i++) {
          for (let j = 0; j < _columns.length; j++) {
            const { _id, taskIds } = _columns[j][1]
            if (_columns[j][0] === state.columnOrder[i]) {
              let update = { number: i + 1 }
              callApiUpdateProductGroup(_id, update)
              for (let k = 0; k < taskIds.length; k++) {
                let update = { number: number }
                const id = taskIds[k].replace('task-', '')
                callApiUpdateProduct(id, update)
                number++
              }
            }
          }
        }
      } else if (temp.id === 4) {
        let _number = 1

        const newColumns = state.columnOrder.reduce((acc, info) => {
          for (let i = 0; i < _columns.length; i++) {
            const { taskIds, id } = _columns[i][1]
            if (id === info) {
              for (let j = 0; j < taskIds.length; j++) {
                acc.push(taskIds[j])
              }
            }
          }
          return acc
        }, [])

        for (let i = 0; i < newColumns.length; i++) {
          for (let j = 0; j < _tasks.length; j++) {
            if (_tasks[j][0] === newColumns[i]) {
              const { _id } = _tasks[j][1]
              const update = { number: _number }
              callApiUpdateProduct(_id, update)

              _number++
            }
          }
        }
      } else if (temp.id === 5) {
        for (let i = 0; i < _columns.length; i++) {
          const { _id, title, taskIds } = _columns[i][1]
          if (taskIds.length !== 0) {
            for (let j = 0; j < taskIds.length; j++) {
              for (let k = 0; k < _tasks.length; k++) {
                const { _id: serviceId } = _tasks[k][1]
                if (_tasks[k][0] === taskIds[j]) {
                  let update = { groupId: _id, groupName: title }
                  callApiUpdateProduct(serviceId, update)
                }
              }
            }
          }
        }
      }

      setTemp({ check: false, id: 0 })
    }
  }, [temp])

  const callApiUpdateProduct = async (id, body) => {
    try {
      const { success } = await api.updateProduct(id, body)
      if (success) {
        update()
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateProductGroup = async (id, body) => {
    try {
      const { success } = await api.updateProductGroup(id, body)
      if (success) {
        update()
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }

  const objectToArray = object => {
    var convert = Object.keys(object).map(key => [key, object[key]])
    return convert
  }

  const onOpenColumnClick = columnId => {
    const currentColumn = state.columns[columnId]
    if (state.columns[columnId].taskIds.length !== 0) {
      const newColumn = { ...currentColumn, open: !currentColumn.open }
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [columnId]: newColumn,
        },
      }
      setState(newState)
    }
  }
  const onDropDownProductGroupEditClick = (e, id) => {
    e.stopPropagation()
    if (id === openProductGroupEditId) {
      setOpenProductGroupEditId(-1)
    } else {
      setOpenProductGroupEditId(id)
    }
  }

  const onEditGroupClick = () => {
    _onEditGroupClick(openProductGroupEditId)
    setActiveProductGroupEdit(openProductGroupEditId)
    setTimeout(() => {
      setOpenProductGroupEditId(-1)
    }, 500)
  }
  const onDeleteGroupClick = () => {
    onDeleteClick(openProductGroupEditId, 'group')
  }
  const onEditProductClick = id => {
    onEditClick(id)
  }
  const onDeleteProductClick = id => {
    onDeleteClick(id, 'sub')
  }

  const bySearch = (products, search) => {
    if (search) {
      return products.content.includes(search)
    } else return products
  }

  const filteredList = (products, search) => {
    return products.filter(products => bySearch(products, search))
  }

  return (
    <Container>
      <TextLabel fontWeight='500'>กลุ่มสินค้า</TextLabel>
      <Scrollbar id='setting-product'>
        {state && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId='all-columns'
              direction='vertical'
              type='column'
            >
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ display: 'grid', gap: '1rem' }}
                >
                  {state.columnOrder.map((columnId, index) => {
                    const column = state.columns[columnId]
                    const tasks = column.taskIds.map(
                      taskId => state.tasks[taskId]
                    )
                    return (
                      <ServiceGroupDrag
                        key={column.id}
                        column={column}
                        tasks={filteredList(tasks, search)}
                        index={index}
                        type='product'
                        activeServiceGroupEdit={activeProductGroupEdit}
                        openServiceGroupEdit={openProductGroupEditId}
                        onOpenColumnClick={onOpenColumnClick.bind(
                          this,
                          column.id
                        )}
                        onDropDownServiceGroupEditClick={e =>
                          onDropDownProductGroupEditClick(e, column._id)
                        }
                        onEditGroupClick={onEditGroupClick}
                        onDeleteGroupClick={onDeleteGroupClick}
                        onEditServiceClick={onEditProductClick}
                        onDeleteServiceClick={onDeleteProductClick}
                      />
                    )
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Scrollbar>
    </Container>
  )
}

export default Products
