import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

// @ts-ignore
import api from '@/api'
import Button from '@/components/atoms/Button'
import { Col, Row } from '@/components/atoms/Flex'
import { Input } from '@/components/atoms/input'
import { InputGroup, Label } from '@/components/atoms/input/InputGroup'
import { GridSelector } from '@/components/atoms/select/GridSelect'
import MultipleSelect from '@/components/atoms/select/MultipleSelect'
import Select from '@/components/atoms/select/Select'
import { Separator } from '@/components/atoms/Separator'
import Switch from '@/components/atoms/Switch'
import { toast } from '@/components/atoms/Toast'
import Modal from '@/components/molecules/modal'
import useApi from '@/hook/useApi'
import useForm from '@/hook/useForm'
import { getStaffs } from '@/redux/slices/staffSlice'

export const StyledDayGridItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`

const ActionBar = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  margin-top: 16px;
  display: flex;
  gap: 8px;
  background-color: var(--popup);
  padding: 16px;
  box-shadow: var(--shadow);
  display: flex;
  border-top: 1px solid var(--input);
`

const Description = styled.span`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`

const StyledCloseButton = styled(Button)`
  flex: 1;
`

export const CreateStaffForm = () => {
  const { register, handleSubmit, values } = useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    contents: staffs,
    isLoading,
    error,
  } = useSelector((state: any) => state.staff)
  const { data: _staffTypes } = useApi(api.getStaffType)
  const { data: staffTypes } = _staffTypes || ({} as any)
  const { data: _services } = useApi(api.getServices)
  const { data: services } = _services || ({} as any)

  const onSubmit = async (data: any) => {
    const result = await api.createStaff(data)
    if (result) {
      dispatch(getStaffs())
      toast.success(t('staff.create.success'))
    } else {
      toast.error(t('staff.create.error'))
    }
  }

  return (
    <div>
      <div
        style={{
          padding: '20px',
        }}
      >
        <Row align='flex-end' gap='6px'>
          <Input type='image' {...register('image')} />
          <Input
            style={{
              flex: 2,
            }}
            label={t('nickname')}
            {...register('name', { required: true })}
          />
        </Row>
        <Row gap='6px'>
          <Input label={t('email')} {...register('email')} />
          <Input label={t('phoneNumber')} {...register('phoneNumber')} />
        </Row>
        <Select
          label={t('gender')}
          defaultValue={'0'}
          items={[
            { value: '0', children: t('male') },
            { value: '1', children: t('female') },
            { value: '2', children: t('other') },
          ]}
          {...register('gender', { required: true })}
        />
        <Select
          label={t('staffType')}
          defaultValue={'1'}
          items={staffTypes?.map((staffType: any) => ({
            value: staffType.staffTypeId?.toString(),
            children: staffType.staffType,
          }))}
          {...register('staffTypeId', { required: true })}
        />
        <InputGroup label={t('workingDays')} {...register('workingDays')}>
          <GridSelector
            defaultValue={['0', '1', '2', '3', '4', '5', '6']}
            items={Array.from({ length: 7 }).map((_, index) => ({
              value: index.toString(),
              children: (
                <StyledDayGridItem>{t(`day.${index}`)}</StyledDayGridItem>
              ),
            }))}
            {...register('workingDays', { required: true })}
          />
        </InputGroup>
        <InputGroup label={t('services')} {...register('services')}>
          <MultipleSelect
            defaultValue={services?.map((service: any) =>
              service._id?.toString()
            )}
            items={services?.map((service: any) => ({
              value: service._id?.toString(),
              children: service.name,
            }))}
            {...register('services')}
          />
        </InputGroup>
        <Separator
          style={{
            marginBottom: '20px',
          }}
        />
        <Row align='center'>
          <Col>
            <Label>{t('status')}</Label>
            <Description>{t('status.hint')}</Description>
          </Col>
          <Switch {...register('status')} />
        </Row>
      </div>
      <ActionBar>
        <StyledCloseButton>
          <Button
            style={{
              flex: 1,
              width: '100%',
            }}
            variant='outlined'
          >
            {t('cancel')}
          </Button>
        </StyledCloseButton>
        <Button
          style={{
            flex: 1,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {t('create')}
        </Button>
      </ActionBar>
    </div>
  )
}
