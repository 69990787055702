import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Button2 from '../../atoms/Button2'
import InputText from '../../atoms/InputText'
import SaveIcon from '../../atoms/SaveIcon'
import TextLabel from '../../atoms/TextLabel'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import ButtonSave from '../../atoms/ButtonSave'

import api from '../../api'
import auth from '../../auth'

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`
const Card = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const CardAddServiceGroup = ({
  handleClose,
  currentIdEdit = -1,
  currentDataEdit = '',
  serviceGroup,
}) => {
  const [groupName, setGroupName] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentIdEdit !== -1) {
      if (currentDataEdit) {
        setGroupName(currentDataEdit.groupName)
      }
    }
  }, [currentIdEdit])

  const onGroupNameChange = value => {
    // if (value.length <= 10) {
    setGroupName(value)
    // }
  }

  const onSaveClick = async () => {
    try {
      await setLoading(true)
      if (currentIdEdit !== -1) {
        await callApiUpdateProductGroup()
      } else {
        await callApiCreateProductGroup()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateProductGroup = async () => {
    try {
      const create = {
        groupName,
        headGroupId: 1,
        headGroup: 'บริการ',
        status: 0,
        number: serviceGroup.length,
        storeId: auth.getStoreId(),
      }
      const { success } = await api.createProductGroup(create)
      if (success) {
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateProductGroup = async () => {
    try {
      const { success } = await api.updateProductGroup(currentIdEdit, {
        ...currentDataEdit,
        groupName,
        headGroupId: 1,
        headGroup: 'บริการ',
      })
      if (success) {
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        {/* <div
          className='mb-2'
          style={{ width: 'fit-content', marginLeft: 'auto' }}
          onClick={() => handleClose()}
        >
          <CloseIcon height='20px'></CloseIcon>
        </div> */}
        <div className='mb-2'>
          <TextLabel fontWeight='600' fontSize='1.25rem'>
            สร้างกลุ่มบริการ
          </TextLabel>
        </div>

        {/* <ContainerBtn>
          <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
            <BtnMenu
              active={activeGroup === 0}
              onClick={() => setActiveGroup(0)}
            >
              <ServiceIcon2
                color={activeGroup === 0 ? '#FFFFFF' : '#8C56D9'}
                size='35'
              />
              บริการ
            </BtnMenu>
          </div>
          <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
            <BtnMenu
              active={activeGroup === 1}
              onClick={() => setActiveGroup(1)}
            >
              <BottleIcon
                color={activeGroup === 1 ? '#FFFFFF' : '#8C56D9'}
                size='35'
              />
              สินค้า
            </BtnMenu>
          </div>
        </ContainerBtn> */}
        <div
          className='mt-3 mb-2'
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <TextLabel fontWeight='600'>ชื่อกลุ่มบริการ</TextLabel>
          <div style={{ display: 'flex' }}>
            <TextLabel
              color={groupName.length === 10 ? '#DB2D1E' : '#E4E8EB'}
            >{`${groupName.length}`}</TextLabel>
            <TextLabel color='#E4E8EB'>/10</TextLabel>
          </div>
        </div>

        <InputText
          boxShadow='0px 4px 4px rgba(71, 84, 103, 0.05)'
          padding='0.25rem 0 0.25rem 1rem'
          value={groupName}
          onChange={val => onGroupNameChange(val.target.value)}
        />
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            justifyContent: 'flex-end',
            position: 'sticky',
            bottom: '2rem',
          }}
        >
          <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
            <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
              <Button2
                color='#98A1B2'
                border='2px solid #98A1B2'
                backgroundColor='white'
                fontWeight='600'
                onClick={() => handleClose(true)}
              >
                ยกเลิก
              </Button2>
            </div>
            <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
              <ButtonSave
                border='2px solid #53389E'
                color='#FFFFFF'
                backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                fontWeight='600'
                icon={<SaveIcon size='15' />}
                onClick={() => onSaveClick()}
              >
                บันทึก
              </ButtonSave>
            </div>
          </div>
        </div>
      </Card>
      {/* {loading && (
        <div class='spinner-border m-5' role='status'>
          <span class='sr-only'>Loading...</span>
        </div>
      )} */}
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddServiceGroup
