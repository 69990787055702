import { GET_CARGO, UPDATE_CARGO } from '../actions/dataCargoAction'

const initState = {
  cargo: [],
}

const dataCargoReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_CARGO:
      return {
        ...state,
        ...action.payload.cargo,
      }
    case UPDATE_CARGO:
      return {
        ...state,
        ...action.payload.cargo,
      }

    default:
      return state
  }
}

export default dataCargoReducers
