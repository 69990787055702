import React, { useEffect, useState } from 'react';
import { FemaleIcon, LGBTQPlusIcon, MaleIcon } from '@/components/atoms/icons/Gender';
import { PeopleIcon } from '@/components/atoms/icons/PeopleIcon';
import { ServiceIcon } from '@/components/atoms/icons/ServiceIcon';
import { TherapistIcon } from '@/components/atoms/icons/TherapistIcon';
import Tooltip from '@/components/atoms/Tooltip';
import { CustomerOrder } from '@/redux/models/BookOrder';
import { Genders } from '@/redux/models/Customer';
import utils from '@/utils';
import { useTranslation } from 'react-i18next';

interface CardCustomerCloseProps {
  customer: CustomerOrder;
}

const CardCustomerClose = ({ customer }: CardCustomerCloseProps) => {
  const [staffs, setStaffs] = useState<{ staffId: number; staffName: string }[]>([]);
  const [rooms, setRooms] = useState<{ roomId: number; roomName: string; roomTypeName: string }[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const newRooms: { roomId: number; roomName: string; roomTypeName: string }[] = [];

    const staffs = customer.services.reduce((acc: { staffId: number; staffName: string }[], info) => {
      const { staffs, rooms } = info;
      for (const staff of staffs) {
        const findStaff = acc.find(s => s.staffId === staff.staffId);
        if (!findStaff) {
          acc.push({
            staffId: staff.staffId,
            staffName: staff.staffId === -1 ? 'ยังไม่เลือกพนักงาน' : staff.staffName,
          });
        }
      }

      for (const room of rooms) {
        const findRoom = newRooms.find(r => r.roomId === room.roomId);
        if (!findRoom) {
          newRooms.push({
            roomId: room.roomId,
            roomName: room.roomId === -1 ? 'ยังไม่เลือกห้อง' : room.roomName,
            roomTypeName: room.roomTypeName,
          });
        }
      }

      return acc;
    }, []);

    setStaffs(staffs);
    setRooms(newRooms);
  }, [customer]);

  return (
    <div className="w-full flex flex-col gap-2.5 text-secondary ">
      <div className="grid grid-cols-3">
        <div className="flex items-center gap-2 text-sm">
          {customer.gender === Genders.Male ? (
            <MaleIcon />
          ) : customer.gender === Genders.Female ? (
            <FemaleIcon />
          ) : customer.gender === Genders.Other ? (
            <LGBTQPlusIcon />
          ) : null}
          {customer.name || customer.firstName}
        </div>
        <div className="text-sm">{customer.age ? `${t("age")} ${customer.age.label} ${t("year")}` : ''}</div>
        <div className="text-sm">{customer.nationality ? `${t("nationality")} ${customer.nationality.label}` : ''}</div>
      </div>

      {customer.services.map(({ serviceName, time, price, serviceCustomer }, index) => (
        <div key={index} className="grid grid-cols-3">
          <div className="flex items-center gap-2 text-sm whitespace-nowrap overflow-hidden">
            <ServiceIcon fill={index !== 0 ? '#FFF' : ''} />
            <div className="flex items-center gap-2 truncate pr-2">
              {serviceName}
              {serviceCustomer > 1 && (
                <div className="flex items-center justify-center px-1.5 py-0.5 min-w-4 min-h-4 bg-purple-100 rounded text-xs text-purple-700">
                  <PeopleIcon size={12} />
                  {serviceCustomer}
                </div>
              )}
            </div>
          </div>
          <div className="text-sm">{`${time} นาที`}</div>
          <div className="text-sm text-purple-700">{utils.formatNumber(price)}</div>
        </div>
      ))}

      <div className="grid grid-cols-3">
        <div className="flex items-center gap-2 text-sm">
          <TherapistIcon size={15} />
          <Tooltip text={staffs.map(s => s.staffName).join(', ')}>
            <div className="flex items-center">
              <div className="flex items-center justify-center px-1.5 py-0.5 min-w-4 min-h-4 bg-gray-100 rounded text-xs text-gray-600 mr-1">
                {staffs.length}
              </div>
              {staffs.map(s => s.staffName).join(', ')}
            </div>
          </Tooltip>
        </div>
        <div className="text-sm">
          <Tooltip text={rooms.map(r => r.roomName).join(', ')}>
            <div className="flex items-center">
              <div className="flex items-center justify-center px-1.5 py-0.5 min-w-4 min-h-4 bg-gray-100 rounded text-xs text-gray-600 mr-1">
                {rooms.length}
              </div>
              {rooms.map(r => r.roomName).join(', ')}
            </div>
          </Tooltip>
        </div>
        <div className="text-sm">
          {/* TODO: Special Remark */}
        </div>
      </div>
    </div>
  );
};

export default CardCustomerClose;