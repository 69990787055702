import React from 'react'

const CommissionFeeIcon = ({ size, color = '' }) => (
  <svg
    width={size || '30'}
    height={size || '19'}
    viewBox='0 0 30 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_124_727)'>
      <path
        d='M25.893 9.19503C25.893 9.19503 25.1574 12.0987 20.3179 13.0666C18.5321 13.4247 16.2237 16.672 15.1348 18.3562C14.7863 18.8934 14.0023 18.903 13.6394 18.3755C13.0635 17.5286 12.5021 16.1833 13.0586 14.5185C13.4361 13.3812 15.2945 11.0679 16.0882 10.1097C16.3205 9.829 16.4463 9.47572 16.4463 9.10792V7.86901C16.4463 6.62042 15.7058 5.48798 14.5589 4.98951L13.2667 4.42813C12.9812 4.30231 12.6666 4.26843 12.3618 4.32166L7.44483 5.20245C7.03348 5.27504 6.66084 5.48798 6.3995 5.81223C5.72681 6.63978 4.20238 8.22713 2.41176 8.22713C1.64712 8.22713 1.07606 8.08195 0.655029 7.88353C-0.0902526 7.53509 -0.225758 6.53331 0.384018 5.98161L2.96831 3.62962C4.18786 2.52137 5.67842 1.75673 7.28997 1.41797L13.0828 0.198419C13.712 0.0677529 14.3508 0 14.9896 0H25.893C28.1579 0 29.9969 1.83901 29.9969 4.10389V5.09114C29.9969 7.35602 28.1579 9.19503 25.893 9.19503Z'
        fill={color || '#475467'}
      />
      <path
        d='M14.7536 7.61586C14.7536 7.94978 14.5165 8.23531 14.1922 8.29823L9.67214 9.18869C9.67214 9.18869 7.25239 12.5763 4.83264 12.0924C4.30998 11.9859 3.89862 11.8843 3.57922 11.7827C2.94524 11.5842 2.75166 10.7809 3.22109 10.3115C3.32272 10.2098 3.44371 10.1372 3.57922 10.0937C4.19383 9.89526 6.10059 9.17417 7.3879 7.52391C7.59115 7.26257 7.89604 7.09319 8.22029 7.03512L12.3822 6.31403C12.5081 6.29468 12.6387 6.30436 12.7549 6.35275L14.3084 6.95769C14.5745 7.06416 14.7536 7.32065 14.7536 7.60618V7.61586Z'
        fill={color || '#475467'}
      />
    </g>
    <defs>
      <clipPath id='clip0_124_727'>
        <rect width='30' height='18.7675' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default CommissionFeeIcon
