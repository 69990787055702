import React from 'react'
import { IconProps } from './IconPropsType'

const StaffIcon2 = ({ size, color = '' }: IconProps) => {
  return (
    <svg
      width={size || '18'}
      height={size || '21'}
      viewBox='0 0 18 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2318_2234)'>
        <path
          d='M9.01633 2.36162C10.645 2.36162 11.9805 3.69713 11.9805 5.32579C11.9805 6.95446 10.645 8.28996 9.01633 8.28996C7.38766 8.28996 6.05216 6.95446 6.05216 5.32579C6.05216 3.69713 7.35509 2.36162 9.01633 2.36162ZM9.01633 0.407227C6.28017 0.407227 4.06519 2.62221 4.06519 5.32579C4.06519 8.02938 6.28017 10.2444 8.98375 10.2444C11.6873 10.2444 13.9023 8.02938 13.9023 5.32579C13.9023 2.62221 11.7199 0.407227 9.01633 0.407227Z'
          fill={color || '#8C56D9'}
        />
        <path
          d='M13.6417 13.5994C14.5212 13.5994 14.8795 14.2183 14.9772 14.4789C15.0749 14.6743 15.3029 15.4235 14.684 16.075C13.1857 17.6059 11.1661 18.4528 9.01627 18.4528C6.86644 18.4528 4.84689 17.6059 3.34852 16.075C2.72963 15.4235 2.95764 14.6743 3.05536 14.4789C3.15308 14.2183 3.51139 13.5994 4.39086 13.5994H13.6417ZM13.6417 11.645H4.39086C1.36155 11.645 -0.169397 15.2932 1.94787 17.4431C3.7394 19.2672 6.24754 20.4072 8.9837 20.4072C11.7524 20.4072 14.2606 19.2672 16.0195 17.4431C18.1694 15.2932 16.6384 11.645 13.6417 11.645Z'
          fill={color || '#8C56D9'}
        />
        <path
          d='M9.01634 7.24756C9.92839 7.24756 10.645 6.49838 10.645 5.6189H7.3551C7.3551 6.53095 8.10429 7.24756 9.01634 7.24756Z'
          fill={color || '#8C56D9'}
        />
      </g>
      <defs>
        <clipPath id='clip0_2318_2234'>
          <rect
            width='16.0586'
            height='20'
            fill='white'
            transform='translate(0.970703 0.407227)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StaffIcon2
