import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import logicEndTime from '../logicEndTime'
import logicRoomOrder from '../logicRoomOrder'
import logicServiceCouple from '../logicServiceCouple'
import logicStaffOrder from '../logicStaffOrder'
import { StaffOrderState } from '../types'

//@ts-ignore
import api from '../../../api'
import { getOrderBooking } from '../../../redux/actions/bookingHistoryAction'
import {
  BookOrder,
  CustomerOrder,
  getInitialRoomBookOrder,
  StaffOrder,
} from '../../../redux/models/BookOrder'
import RoomType from '../../../redux/models/RoomType'
import Service from '../../../redux/models/Service'
import utils from '../../../utils'

interface useCardMenuServiceProps {
  staffOrders: StaffOrderState[]
}

const useCardMenuService = ({ staffOrders }: useCardMenuServiceProps) => {
  const dispatch = useDispatch()
  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const [activeService, setActiveService] = useState<number>(-1)

  const {
    focusedCustomerIndex,
    customers,
    bookingType,
    time,
    serviceCount,
    selectStaff,
  }: BookOrder = bookingOrderState

  const onServiceClick = async (service: Service) => {
    try {
      const newArrCustomer = utils.deepClone(customers)
      let primaryCustomerIndex = 0
      let serviceCustomer = service.customerCount || 1
      let serviceTime = service.timePrices[0].time

      let rooms = await logicRoomOrder.getRoomTypesByService(
        service._id,
        service.timePrices[0]
      )
      if (!rooms.length) {
        rooms = [
          {
            ...getInitialRoomBookOrder(),
            duration: service.timePrices[0].time,
          },
        ]
      }

      let staffs: StaffOrder[] = []
      let newArrStaffOrder: StaffOrderState[] = []

      const findServiceIndex = newArrCustomer[
        focusedCustomerIndex
      ].services.findIndex(s => s.serviceId === -1)

      let indexFreeCustomerServices: {
        customerIndex: number
        serviceIndex: number
      }[] = []
      for (let i = 0; i < newArrCustomer.length; i++) {
        const { services } = newArrCustomer[i]
        if (i !== focusedCustomerIndex) {
          const filterService = services
            .map((s, index) => ({ service: s, index })) // Attach the original index to each service
            .filter(({ service }) => service.serviceId === -1) // Filter by serviceId
            .map(({ service, index }) => ({
              customerIndex: i,
              serviceIndex: index, // Keep the original index
            }))
          // const filterService = services
          //   .filter(s => s.serviceId === -1)
          //   .map((s, index) => ({
          //     customerIndex: i,
          //     serviceIndex: index,
          //   }))
          indexFreeCustomerServices =
            indexFreeCustomerServices.concat(filterService)
        }
      }

      if (findServiceIndex !== -1) {
        indexFreeCustomerServices.splice(0, 0, {
          customerIndex: focusedCustomerIndex,
          serviceIndex: findServiceIndex,
        })
        primaryCustomerIndex = focusedCustomerIndex
      } else {
        if (indexFreeCustomerServices.length) {
          primaryCustomerIndex = indexFreeCustomerServices[0].customerIndex
        } else {
          primaryCustomerIndex = newArrCustomer.length
        }
      }

      for (let i = 0; i < serviceCustomer; i++) {
        if (i >= indexFreeCustomerServices.length) {
          newArrCustomer.push({
            customer: newArrCustomer.length + 1,
            customerId: -1,
            name: '',
            firstName: '',
            lastName: '',
            countryCode: '+66',
            phoneNumber: '',
            email: '',
            address: '',
            taxIDNumber: '',
            gender: -1,
            age: { value: -1, label: 'ไม่ระบุ' },
            nationality: { value: -1, label: 'อื่นๆ' },
            specialRequest: '',
            services: [
              {
                serviceMasterId: service.masterId,
                serviceId: service._id,
                serviceName: service.name,
                serviceImage: service.image,
                serviceCustomer: service.customerCount,
                primaryCustomerIndex: {
                  customerIndex: primaryCustomerIndex,
                  serviceIndex: indexFreeCustomerServices.length
                    ? indexFreeCustomerServices[0].serviceIndex
                    : 0,
                },
                serviceRelatedCustomers: [],
                time: serviceTime,
                price: 0,
                activeTimePriceIndex: 0,
                timePrices: service.timePrices,
                applyToAll: service.applyToAll,
                staffs: [],
                rooms: rooms,
                serviceDiscount: 0,
                unit: 1,
              },
            ],
          })

          indexFreeCustomerServices.push({
            customerIndex: newArrCustomer.length - 1,
            serviceIndex: 0,
          })
        }
      }

      for (let i = 0; i < indexFreeCustomerServices.length; i++) {
        if (i === serviceCustomer) {
          break
        }

        staffs = []

        for (let j = 0; j < (service.staffCount || 1); j++) {
          if (indexFreeCustomerServices[i].serviceIndex !== 0) {
            for (const staff of newArrCustomer[
              indexFreeCustomerServices[i].customerIndex
            ].services[0].staffs) {
              if (staff.staffId !== -1) {
                const free = checkStaffByIdFree(
                  newArrCustomer,
                  indexFreeCustomerServices[i].serviceIndex,
                  service._id,
                  service.applyToAll,
                  indexFreeCustomerServices[i].customerIndex,
                  serviceTime,
                  staff.staffId
                )

                staffs.push({
                  staffTypeId: free ? staff.staffTypeId : -1,
                  staffId: free ? staff.staffId : -1,
                  staffName: free ? staff.staffName : '',
                  staffImage: free ? staff.staffImage : '',
                  requestStaff: false,
                  queueFree: false,
                  tip: 0,
                  duration: serviceTime,
                })
              } else {
                staffs.push({
                  staffTypeId: -1,
                  staffId: -1,
                  staffName: '',
                  staffImage: '',
                  requestStaff: false,
                  queueFree: false,
                  tip: 0,
                  duration: serviceTime,
                })
              }
            }
          } else {
            if (bookingType.bookingTypeId === 1) {
              newArrStaffOrder = checkStaffFree(
                newArrCustomer,
                indexFreeCustomerServices[i].serviceIndex,
                service._id,
                service.applyToAll,
                indexFreeCustomerServices[i].customerIndex,
                serviceTime
              )
            }

            if (
              selectStaff &&
              indexFreeCustomerServices[i].customerIndex === 0 &&
              j === 0
            ) {
              const findStaff = staffOrders.find(
                s =>
                  s.staffId ===
                  newArrCustomer[indexFreeCustomerServices[i].customerIndex]
                    .services[0].staffs[0].staffId
              )
              staffs.push({
                staffTypeId: findStaff?.staffTypeId || -1,
                staffId:
                  newArrCustomer[indexFreeCustomerServices[i].customerIndex]
                    .services[0].staffs[0].staffId,
                staffName: findStaff?.staffName || '',
                staffImage: findStaff?.staffImage || '',
                requestStaff: false,
                queueFree: false,
                tip: 0,
                duration: serviceTime,
              })
              continue
            }

            staffs.push({
              staffTypeId:
                j >= newArrStaffOrder.length
                  ? -1
                  : newArrStaffOrder[j].staffTypeId,
              staffId:
                j >= newArrStaffOrder.length ? -1 : newArrStaffOrder[j].staffId,
              staffName:
                j >= newArrStaffOrder.length
                  ? ''
                  : newArrStaffOrder[j].staffName,
              staffImage:
                j >= newArrStaffOrder.length
                  ? ''
                  : newArrStaffOrder[j].staffImage,
              requestStaff: false,
              queueFree: false,
              tip: 0,
              duration: serviceTime,
            })
          }
        }

        newArrCustomer[indexFreeCustomerServices[i].customerIndex].services[
          indexFreeCustomerServices[i].serviceIndex
        ] = {
          serviceMasterId: service.masterId,
          serviceId: service._id,
          serviceName: service.name,
          serviceImage: service.image,
          serviceCustomer: service.customerCount,
          primaryCustomerIndex: {
            customerIndex: primaryCustomerIndex,
            serviceIndex: indexFreeCustomerServices[0].serviceIndex,
          },
          serviceRelatedCustomers:
            i === 0
              ? indexFreeCustomerServices
                  .filter(
                    s =>
                      s.customerIndex !==
                      indexFreeCustomerServices[0].customerIndex
                  )
                  .map(s => s.customerIndex)
              : [indexFreeCustomerServices[0].customerIndex],
          time: serviceTime,
          price:
            primaryCustomerIndex === indexFreeCustomerServices[i].customerIndex
              ? service.timePrices[0].price
              : 0,
          activeTimePriceIndex: 0,
          timePrices: service.timePrices,
          applyToAll: service.applyToAll,
          staffs: staffs,
          rooms: rooms,
          serviceDiscount: 0,
          unit: 1,
        }
      }

      const maxServiceTime = logicEndTime.checkServiceMaxTime(newArrCustomer)
      const endTime = utils.convertTime(time) + maxServiceTime
      dispatch(
        getOrderBooking({
          endTime: utils.convertTimeToHourMinute(endTime),
          serviceTime: maxServiceTime || 0,
          customers: newArrCustomer,
          countCustomer: newArrCustomer.length,
          serviceCount: serviceCount + 1,
          total: bookingOrderState.total + service.timePrices[0].price,
          focusedCustomerIndex: primaryCustomerIndex,
        })
      )
      setActiveService(service._id)
      setTimeout(() => {
        setActiveService(-1)
      }, 500)
    } catch (err) {
      console.log(err)
    }
  }

  const checkStaffFree = (
    customers: CustomerOrder[],
    findServiceIndex: number,
    serviceId: number,
    applyToAll: boolean,
    focusedCustomerIndex: number,
    serviceTime: number
  ) => {
    let filteredStaffOrders: StaffOrderState[] = []
    filteredStaffOrders = [...staffOrders]
    if (!applyToAll) {
      filteredStaffOrders = logicStaffOrder.checkServiceSpecificStaff(
        staffOrders,
        serviceId
      )
    }

    const staffs = logicStaffOrder
      .checkListStaffAvailability(
        filteredStaffOrders,
        customers,
        time,
        findServiceIndex,
        focusedCustomerIndex,
        serviceTime
      )
      .filter(s => s.free && s.staffWorkingStatus === 1)

    return staffs
  }

  const checkStaffByIdFree = (
    customers: CustomerOrder[],
    findServiceIndex: number,
    serviceId: number,
    applyToAll: boolean,
    focusedCustomerIndex: number,
    serviceTime: number,
    staffId: number
  ) => {
    let filteredStaffOrders: StaffOrderState[] = []
    filteredStaffOrders = [...staffOrders]
    if (!applyToAll) {
      filteredStaffOrders = logicStaffOrder.checkServiceSpecificStaff(
        staffOrders,
        serviceId
      )
    }

    const free = logicStaffOrder.checkStaffAvailability(
      filteredStaffOrders,
      customers,
      time,
      findServiceIndex,
      focusedCustomerIndex,
      serviceTime,
      staffId
    )

    return free
  }

  return { activeService, onServiceClick }
}

export default useCardMenuService
