import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import CameraIcon from '../../atoms/CameraIcon'
import InputText from '../../atoms/InputText'
import TextLabel from '../../atoms/TextLabel'
import UploadImageIcon from '../../atoms/UploadImageIcon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ImageBox = styled.div`
  display: flex;
  width: 45%;
  background: #f8f9fb;
  border: 2px dashed #e4e8eb;
  border-radius: 16px;
  padding: ${props => props.padding || '3rem 1rem'};
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
`
const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`
const Image = styled.img`
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* aspect-ratio: 1; */
  position: relative;
  object-fit: cover;
`

const CardAddOnline = ({
  onNameChange,
  payChannel,
  onChangeImage,
  checkName,
}) => {
  const [image, setImage] = useState('')
  const types = [
    { payChannelId: 5, payChannel: 'TrueMoney', icon: '/images/TrueMoney.png' },
    {
      payChannelId: 6,
      payChannel: 'WeChat',
      icon: '/images/WeChatPay2.png',
    },
    { payChannelId: 7, payChannel: 'AliPay', icon: '/images/Alipay2.png' },
    { payChannelId: 8, payChannel: 'AirPay', icon: '/images/Airpay.png' },
    { payChannelId: 9, payChannel: 'LinePay', icon: '/images/LinePay.png' },
    { payChannelId: 10, payChannel: 'PayPal', icon: '/images/PayPal.png' },
  ]

  useEffect(() => {
    if (payChannel) {
      const findIndex = types.findIndex(
        t => t.payChannelId === payChannel.payChannelId
      )
      if (findIndex !== -1) {
        setImage(types[findIndex].icon)
      } else {
        setImage(payChannel.image || '')
      }
    }
  }, [payChannel])

  return (
    <Container>
      <div className='mb-4' style={{ width: '100%' }}>
        <TextLabel fontWeight='600' fontSize='1.2rem'>
          เพิ่มออนไลน์วอลเล็ต
        </TextLabel>
      </div>
      <div style={{ width: '100%' }}>
        <ImageBox padding={image ? '0rem' : ''}>
          <CameraIcon size='40' />
          <ImageUploadIcon>
            <label for='upload-image'>
              <UploadImageIcon size='30' colorCircle='#8C56D9' />
            </label>
            <input
              id='upload-image'
              type='file'
              onChange={onChangeImage}
              style={{ display: 'none', visibility: 'none' }}
            />
          </ImageUploadIcon>
          {image && <Image src={image} />}
        </ImageBox>
      </div>
      <div className='mt-3'></div>
      <TextLabel fontWeight='600'>ชื่อวอลเล็ต*</TextLabel>
      <div className='mt-2'></div>
      <InputText
        padding='0.5rem 1rem'
        boxShadow='0px 4px 4px rgba(71, 84, 103, 0.05)'
        border={checkName ? '2px solid #DB2D1E' : ''}
        value={payChannel.payChannel}
        onChange={val => onNameChange(val.target.value)}
      />
    </Container>
  )
}

export default CardAddOnline
