import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import SettingHeader from '../../molecules/SettingHeader'

import auth from '../../auth'

const Head = ({ onCreateClick: _onCreateClick }) => {
  const currentUser = useSelector(state => state.user)
  const [currentUserType, setCurrentUserType] = useState({})

  useEffect(() => {
    if (currentUser._id !== 0) {
      if (currentUser.types[0].typeId === 0) {
        setCurrentUserType({
          storeId: auth.getStoreId(),
          type: 'Admin',
          typeId: 0,
        })
      } else {
        const findUserType =
          currentUser.types.find(
            t => t.storeId === parseInt(auth.getStoreId())
          ) || ''
        setCurrentUserType(findUserType)
      }
    }
  }, [currentUser])

  const onCreateClick = () => {
    if (currentUserType.typeId === 1 || currentUserType.typeId === 0) {
      _onCreateClick()
    }
  }

  return (
    <SettingHeader
      name='สิทธิ์เข้าใช้'
      menuName='สร้างผู้มีสิทธิ์เพิ่ม'
      onClick={onCreateClick}
    />
  )
  // background={
  //   currentUserType.typeId !== 1 && currentUserType.typeId !== 0
  //     ? '#F8F9FB'
  //     : ''
  // }
  // color={
  //   currentUserType.typeId !== 1 && currentUserType.typeId !== 0
  //     ? '#98A1B2'
  //     : ''
  // }
}

export default Head
