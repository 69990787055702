import React from 'react'
import { IconProps } from './IconPropsType'

const DateIcon2 = ({ size, color }: IconProps) => (
  <svg
    width={size || '57'}
    height={size || '57'}
    viewBox='0 0 57 57'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M8.61249 57H48.3858C52.5553 57 55.9436 53.6116 55.9436 49.4422V11.7799C55.9436 7.61051 52.5553 4.22214 48.3858 4.22214H45.3881V2.11107C45.3881 0.949947 44.438 0 43.2768 0C42.1157 0 41.1658 0.949947 41.1658 2.11107V4.22214H15.8325V2.11107C15.8325 0.949947 14.8824 0 13.7213 0C12.5602 0 11.6102 0.949947 11.6102 2.11107V4.22214H8.61249C4.44305 4.22214 1.05469 7.61051 1.05469 11.7799V49.4422C1.05469 53.6116 4.44305 57 8.61249 57ZM5.27701 11.7799C5.27701 9.94333 6.77588 8.44446 8.61249 8.44446H11.6102V10.5555C11.6102 11.7167 12.5602 12.6666 13.7213 12.6666C14.8824 12.6666 15.8324 11.7167 15.8324 10.5555V8.44446H41.1658V10.5555C41.1658 11.7167 42.1157 12.6666 43.2768 12.6666C44.438 12.6666 45.3879 11.7167 45.3879 10.5555V8.44446H48.3856C50.2223 8.44446 51.7211 9.94333 51.7211 11.7799V49.4422C51.7211 51.2788 50.2223 52.7777 48.3856 52.7777H8.61249C6.77588 52.7777 5.27701 51.2788 5.27701 49.4422V11.7799Z'
        fill={color || '#ABABAD'}
      />
      <path
        d='M16.3596 24.2779C17.8169 24.2779 18.9984 23.0965 18.9984 21.6391C18.9984 20.1817 17.8169 19.0002 16.3596 19.0002C14.9022 19.0002 13.7207 20.1817 13.7207 21.6391C13.7207 23.0965 14.9022 24.2779 16.3596 24.2779Z'
        fill={color || '#ABABAD'}
      />
      <path
        d='M27.9728 24.2779C29.4302 24.2779 30.6117 23.0965 30.6117 21.6391C30.6117 20.1817 29.4302 19.0002 27.9728 19.0002C26.5154 19.0002 25.334 20.1817 25.334 21.6391C25.334 23.0965 26.5154 24.2779 27.9728 24.2779Z'
        fill={color || '#ABABAD'}
      />
      <path
        d='M39.5822 24.2779C41.0396 24.2779 42.2211 23.0965 42.2211 21.6391C42.2211 20.1817 41.0396 19.0002 39.5822 19.0002C38.1248 19.0002 36.9434 20.1817 36.9434 21.6391C36.9434 23.0965 38.1248 24.2779 39.5822 24.2779Z'
        fill={color || '#ABABAD'}
      />
      <path
        d='M16.3596 34.8329C17.8169 34.8329 18.9984 33.6514 18.9984 32.194C18.9984 30.7366 17.8169 29.5552 16.3596 29.5552C14.9022 29.5552 13.7207 30.7366 13.7207 32.194C13.7207 33.6514 14.9022 34.8329 16.3596 34.8329Z'
        fill={color || '#ABABAD'}
      />
      <path
        d='M27.9728 34.8329C29.4302 34.8329 30.6117 33.6514 30.6117 32.194C30.6117 30.7366 29.4302 29.5552 27.9728 29.5552C26.5154 29.5552 25.334 30.7366 25.334 32.194C25.334 33.6514 26.5154 34.8329 27.9728 34.8329Z'
        fill={color || '#ABABAD'}
      />
      <path
        d='M39.5822 34.8329C41.0396 34.8329 42.2211 33.6514 42.2211 32.194C42.2211 30.7366 41.0396 29.5552 39.5822 29.5552C38.1248 29.5552 36.9434 30.7366 36.9434 32.194C36.9434 33.6514 38.1248 34.8329 39.5822 34.8329Z'
        fill={color || '#ABABAD'}
      />
      <path
        d='M16.3596 45.389C17.8169 45.389 18.9984 44.2076 18.9984 42.7502C18.9984 41.2928 17.8169 40.1113 16.3596 40.1113C14.9022 40.1113 13.7207 41.2928 13.7207 42.7502C13.7207 44.2076 14.9022 45.389 16.3596 45.389Z'
        fill={color || '#ABABAD'}
      />
      <path
        d='M27.9728 45.389C29.4302 45.389 30.6117 44.2076 30.6117 42.7502C30.6117 41.2928 29.4302 40.1113 27.9728 40.1113C26.5154 40.1113 25.334 41.2928 25.334 42.7502C25.334 44.2076 26.5154 45.389 27.9728 45.389Z'
        fill={color || '#ABABAD'}
      />
      <path
        d='M39.5822 45.389C41.0396 45.389 42.2211 44.2076 42.2211 42.7502C42.2211 41.2928 41.0396 40.1113 39.5822 40.1113C38.1248 40.1113 36.9434 41.2928 36.9434 42.7502C36.9434 44.2076 38.1248 45.389 39.5822 45.389Z'
        fill={color || '#ABABAD'}
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='57' height='57' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default DateIcon2
