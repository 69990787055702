import React, { useRef } from 'react'
import styled from 'styled-components'

// @ts-ignore
import ChevronDownIcon from '../atoms/ChevronDownIcon'
import TextLabel from '../atoms/TextLabel'
// @ts-ignore
import useOnClickOutside from '../hook/useOnClickOutside'

const Container = styled.div`
  position: relative;
  cursor: pointer;
`

const BoxContainer = styled.div<{ transparent?: boolean }>`
  background: ${props => (props.transparent ? '' : '#ffffff')};
  border: ${props => (props.transparent ? '' : '1px solid #f8f9fb')};
  box-shadow: ${props =>
    props.transparent ? '' : '0px 8px 40px rgba(71, 84, 103, 0.08)'};
  border-radius: 8px;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const OptionContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  position: absolute;
  right: 0px;
  top: calc(100% + 0.25rem);
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.08));
  width: max-content;
  z-index: 6;
`

const OptionItemContainer = styled.div`
  padding: 0.25rem 1rem;
  font-weight: 500;
  color: #000000;
  :hover {
    background: linear-gradient(221.86deg, #8c56d9 2.71%, #53389e 97.79%);
    color: #ffffff;
  }
`

interface MenuSelectorProps<T> {
  name: string
  isOpen: boolean
  icon?: React.ReactNode
  option: string
  options: string[]
  transparent?: boolean
  onSelect: (option: string, name: string, index: number) => void
  onToggle: (value: boolean) => void
}

const MenuSelector = <T,>({
  name,
  isOpen,
  icon,
  option,
  options,
  transparent,
  onSelect,
  onToggle,
}: MenuSelectorProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => {
    onToggle(false)
  })

  return (
    <Container ref={ref}>
      <BoxContainer transparent={transparent} onClick={() => onToggle(!isOpen)}>
        {icon}
        <TextLabel fontWeight='500'>{option}</TextLabel>
        <ChevronDownIcon flipped={isOpen} />
      </BoxContainer>
      {isOpen && (
        <OptionContainer>
          {options.map((option, index) => (
            <OptionItemContainer
              key={index}
              onClick={() => {
                onSelect(option, name, index)
                onToggle(false)
              }}
            >
              {option}
            </OptionItemContainer>
          ))}
        </OptionContainer>
      )}
    </Container>
  )
}

export default MenuSelector
