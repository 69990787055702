import { GET_DATA_CUSTOMER } from '../actions/dataCustomerAction'

const initState = {
  customerInformation: [],
}

const dataCustomerReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_DATA_CUSTOMER:
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

export default dataCustomerReducers
