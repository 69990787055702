import { GET_STAFF, CLEAR_STAFF } from '../actions/dataStaffAction'

const initState = {
  _id: '',
  staffTypeId: '',
  image: '',
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  tel: '',
  gender: '',
  quantityService: '',
  lineStaffId: '',
  temp: false,
  workingDay: [],
}
const dataStaffReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_STAFF:
      return {
        ...state,
        ...action.payload.staff,
      }

    case CLEAR_STAFF:
      return {
        ...initState,
      }

    default:
      return state
  }
}

export default dataStaffReducers
