import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import api from '../../api'
import Button2 from '../../atoms/Button2'
import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'
import PopupConfirmPin from '../popups/PopupConfirmPin'
import dayjs from 'dayjs'

const Container = styled.div`
  width: 100%;
  display: flex;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  padding: 1rem 1rem;
  height: 100%;
`

const CouponContainer = styled.div`
  width: 100%;
  display: flex;
`

const CouponCard = styled.div`
  background: ${props => props.background || '#F8F9FB'};
  border-radius: 4px;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  width: 100%;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 0.5rem;
`

const CardSummary = ({
  date,
  activeStaff,
  updateStaffs,
  currentUserType,
  activeMenu,
}) => {
  const { t } = useTranslation()

  const [isOpenPin, setIsOpenPin] = useState(false)

  const togglePin = async (close = false) => {
    try {
      if (close) {
        setIsOpenPin(false)
      } else {
        if (activeStaff.staffId === -1) {
          if (activeMenu === 'ประวัติค่ามือ') {
            const { success } = await api.clearAllOverdueByDate(
              date.startDate,
              date.endDate
            )
            if (success) {
              setIsOpenPin(false)
              updateStaffs(success)
            }
          } else {
            const { success } = await api.clearAllOverdue()
            if (success) {
              setIsOpenPin(false)
              updateStaffs(success)
            }
          }
        } else {
          if (activeMenu === 'ประวัติค่ามือ') {
            const { success } = await api.clearOverdueByStaffByDate(
              date.startDate,
              date.endDate,
              activeStaff,
              activeStaff.staffId
            )
            if (success) {
              setIsOpenPin(false)
              updateStaffs(success)
            }
          } else {
            const { success } = await api.clearOverdueByStaff(
              activeStaff,
              activeStaff.staffId
            )
            if (success) {
              setIsOpenPin(false)
              updateStaffs(success)
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        <CouponContainer>
          <CouponCard>
            <div>
              <GridBox>
                <TextLabel>{t('therapistCommission')}</TextLabel>
                <TextLabel textAlign='end'>
                  {utils.formatNumber(activeStaff.totalCommission || 0)}
                </TextLabel>
              </GridBox>
              <GridBox>
                <TextLabel>{t('requestFee')}</TextLabel>
                <TextLabel textAlign='end'>
                  {utils.formatNumber(activeStaff.totalRequest || 0)}
                </TextLabel>
              </GridBox>
              <GridBox>
                <TextLabel>{t('minimumWagePerDay')}</TextLabel>
                <TextLabel textAlign='end'>
                  {utils.formatNumber(activeStaff.totalBaseCommission || 0)}
                </TextLabel>
              </GridBox>
              <GridBox>
                <TextLabel fontSize='1.05rem' color='#8C56D9' fontWeight='500'>
                  {t('wageToBePaid')}
                </TextLabel>
                <TextLabel
                  textAlign='end'
                  fontSize='1.05rem'
                  color='#8C56D9'
                  fontWeight='500'
                >
                  {utils.formatNumber(activeStaff.total || 0)}
                </TextLabel>
              </GridBox>
            </div>
          </CouponCard>
        </CouponContainer>
        {currentUserType.typeId !== 4 && (
          <>
            <div className='mt-3'></div>
            <Button2
              backgroundColor='#FFFFFF'
              border='1px solid #E4E8EB'
              color='#8C56D9'
              onClick={() => setIsOpenPin(!isOpenPin)}
            >
              {t('resetPayWage')}
            </Button2>
          </>
        )}
      </Card>
      {isOpenPin && <PopupConfirmPin handleClose={togglePin} />}
    </Container>
  )
}

export default CardSummary
