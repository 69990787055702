import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css, CSSProperties } from 'styled-components'

import ChartHeader from './ChartHeader'
import CustomizedAreaChart from './CustomizedAreaChart'
import CustomizedBarChart from './CustomizedBarChart'
import CustomizedPieChart from './CustomizedPieChart'
import DataBox from './DataBox'
import DataItem from './DataItem'
import FilterDate from './FilterDate'
import AgencyCommissionIcon from './icons/AgencyCommissionIcon'
import CashierIcon from './icons/CashierIcon'
import CircleStarIcon from './icons/CircleStarIcon'
import ClockIcon from './icons/ClockIcon'
import DiscountIcon from './icons/DiscountIcon'
import IncomeIcon from './icons/IncomeIcon'
import OpenIcon from './icons/OpenIcon'
import TherapistCommissionIcon from './icons/TherapistCommissionIcon'
import ListView from './ListView'
import Popover from './Popover'
import useReport from './useReport'

import Label from '../../atoms/Label'
import LoaderModal from '../../molecules/LoaderModal'

import Page from '../../lib/layout/Page/Page'
import ReduxStore from '../../redux/models/ReduxStore'
import { ReportTypes } from '../../redux/models/Store'
import { getCurrentStoreRole, UserTypes } from '../../redux/models/User'
import utils from '../../utils'

const BodyContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const SmallChartContainer = styled.div`
  margin-right: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`

const ChartContainer = styled.div`
  margin-right: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 300px;
  gap: 1.5rem;
`

const ChartBox = styled.div<{ background?: string }>`
  background: ${props => props.background || '#fff'};
  border: 1px solid #e4e8eb;
  box-shadow: 0px 16px 24px rgba(152, 161, 178, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  &:last-child {
    flex: 1;
  }
`

const DataContainer = styled.div<{ gap?: string; padding?: string }>`
  width: 100%;
  padding: ${props => props.padding || '0.75rem 1rem'};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap || '0.5rem'};
`

interface GridBoxProps {
  columns?: CSSProperties['gridTemplateColumns']
  withBorder?: boolean
}

const GridBox = styled.div<GridBoxProps>`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => (props.withBorder ? '' : '0.5rem')};
  & > :last-child:nth-child(2n + 1) {
    grid-column: span 2;
  }
  ${props =>
    props.withBorder &&
    css<GridBoxProps>`
      & > * {
        background: unset;
        border-radius: unset;
        border-top: ${props => (props.withBorder ? '1px solid #E4E8EB' : '')};
        border-right: ${props => (props.withBorder ? '1px solid #E4E8EB' : '')};
        padding: 0.75rem;
      }
      & > :nth-child(2n),
      & > :last-child {
        border-right: unset;
      }
    `}
`

const TopContainer = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 0.5px solid #e4e8eb;
  display: flex;
  justify-content: space-between;
`

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

const IncomeBox = styled.div`
  background: #32d582;
  border-radius: 4px;
  margin: 0 -0.5rem;
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const IconBox = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OthersContainer = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Divider = styled.div`
  background: #d9d9d9;
  height: 1px;
`

const Main = () => {
  const { t } = useTranslation()

  const user = useSelector((state: ReduxStore) => state.user)
  const store = useSelector((state: ReduxStore) => state.store)
  const { storeId } = user
  const role = getCurrentStoreRole(user)

  const { reportType } = store

  const {
    startDate,
    endDate,
    totalIncome,
    totalBill,
    totalCustomer,
    averageIncome,
    serviceIncome,
    packageIncome,
    productIncome,
    totalTip,
    totalCharge,
    specialRevenue,
    totalCommissionFee,
    totalCommission,
    totalDiscount,
    productDiscount,
    serviceDiscount,
    packageDiscount,
    specialExpense,
    cashPayment,
    transferPayment,
    creditPayment,
    eWalletPayment,
    agencyPayment,
    packagePayment,
    filterData,
    serviceSummary = [],
    daySummary = [],
    monthSummary = [],
    hourSummary = [],
    genderSummary = [],
    nationalitySummary = [],
    ageSummary = [],
    bookingTypeSummary = [],
    packageSummary = [],
    branchSummary = [],
    agencySummary = [],
    isLoading,
    isShown,
    onDateChange,
    onDownloadClick,
    onFilterClick = () => {},
    onToggle,
  } = useReport()

  const renderPayChannels = () => (
    <div>
      <DataContainer padding='0 0.5rem'>
        <ChartHeader name={t('paymentChannel')} />
      </DataContainer>
      {reportType === ReportTypes.Yu && (
        <DataContainer padding='0.5rem 1rem'>
          <IncomeBox>
            <Label color='#fff' lineCap>
              {t('totalAccountIncome')}
            </Label>
            <Label size='1.25rem' color='#fff' weight='bold' lineCap>
              {utils.formatNumber(
                cashPayment +
                  transferPayment +
                  creditPayment +
                  eWalletPayment +
                  agencyPayment
              )}
            </Label>
          </IncomeBox>
        </DataContainer>
      )}
      <GridBox columns='1fr 1fr' withBorder>
        <DataBox name={t('cash')} value={cashPayment} />
        <DataBox name={t('transfer')} value={transferPayment} />
        <DataBox name={t('creditCard')} value={creditPayment} />
        <DataBox name={t('eWallet')} value={eWalletPayment} />
        <DataBox
          name={t('agency')}
          value={agencyPayment}
          icon={
            !!agencySummary.length && (
              <>
                <IconContainer>
                  <OpenIcon />
                </IconContainer>
                {isShown && (
                  <Popover isOpen={isShown} onToggle={onToggle}>
                    <GridBox columns='1fr 1fr'>
                      {agencySummary.map(v => (
                        <DataBox key={v.name} name={v.name} value={v.value} />
                      ))}
                    </GridBox>
                  </Popover>
                )}
              </>
            )
          }
          onClick={onToggle}
        />
      </GridBox>
    </div>
  )

  if (role === UserTypes.General) {
    return (
      <Page>
        <FilterDate date={{ startDate, endDate }} onDateChange={onDateChange} />
        <BodyContainer>
          <SmallChartContainer>
            <ChartBox>{renderPayChannels()}</ChartBox>
          </SmallChartContainer>
        </BodyContainer>
      </Page>
    )
  }

  return (
    <Page>
      <FilterDate
        date={{ startDate, endDate }}
        onDateChange={onDateChange}
        onDownloadClick={onDownloadClick}
      />
      <BodyContainer>
        <SmallChartContainer>
          <ChartBox>
            <DataContainer gap='0.75rem'>
              {reportType === ReportTypes.Yu ? (
                <IncomeBox>
                  <Label color='#fff' lineCap>
                    {t('incomeFromService')}
                  </Label>
                  <Label size='1.25rem' color='#fff' weight='bold' lineCap>
                    {utils.formatNumber(
                      totalIncome - packageIncome + packagePayment
                    )}
                  </Label>
                </IncomeBox>
              ) : (
                <IncomeBox>
                  <IconBox>
                    <IncomeIcon />
                    <Label color='#fff' lineCap>
                      {t('totalIncome')}
                    </Label>
                  </IconBox>
                  <Label size='1.25rem' color='#fff' weight='bold' lineCap>
                    {utils.formatNumber(totalIncome)}
                  </Label>
                </IncomeBox>
              )}
              {reportType === ReportTypes.Yu ? (
                <>
                  <SummaryRow>
                    <Label color='#000' lineCap>
                      {[t('cash'), t('transfer'), t('creditCard')].join(', ')}
                    </Label>
                    <Label size='1.25rem' color='#32D582' weight='bold' lineCap>
                      {utils.formatNumber(totalIncome - packageIncome)}
                    </Label>
                  </SummaryRow>
                  <SummaryRow>
                    <Label color='#000' lineCap>
                      {t('amountUsedFromPackage')}
                    </Label>
                    <Label size='1.25rem' color='#32D582' weight='bold' lineCap>
                      {utils.formatNumber(packagePayment)}
                    </Label>
                  </SummaryRow>
                </>
              ) : (
                <>
                  <SummaryRow>
                    <Label color='#000' lineCap>
                      {t('serviceIncome')}
                    </Label>
                    <Label size='1.25rem' color='#32D582' weight='bold' lineCap>
                      {utils.formatNumber(
                        serviceIncome - serviceDiscount + packagePayment
                      )}
                    </Label>
                  </SummaryRow>
                  <SummaryRow>
                    <div />
                    <Label size='0.875rem' lineCap>
                      {`( ${t('amountUsedFromPackage')} `}
                      <Label color='#000' weight='bold' lineCap>
                        {utils.formatNumber(packagePayment)}
                      </Label>
                      {' )'}
                    </Label>
                  </SummaryRow>
                </>
              )}
              {reportType === ReportTypes.Yu && <Divider />}
              <SummaryRow>
                <Label color='#000' lineCap>
                  {t('packageIncome')}
                </Label>
                <Label
                  size='1.25rem'
                  color={reportType === ReportTypes.Yu ? '#000' : '#32D582'}
                  weight='bold'
                  lineCap
                >
                  {utils.formatNumber(packageIncome - packageDiscount)}
                </Label>
              </SummaryRow>
              <SummaryRow>
                <Label color='#000' lineCap>
                  {t('productIncome')}
                </Label>
                <Label
                  size='1.25rem'
                  color={reportType === ReportTypes.Yu ? '#000' : '#32D582'}
                  weight='bold'
                  lineCap
                >
                  {utils.formatNumber(productIncome - productDiscount)}
                </Label>
              </SummaryRow>
              <SummaryRow>
                <Label color='#000' lineCap>
                  {t('cashIncome')}
                </Label>
                <Label
                  size='1.25rem'
                  color={reportType === ReportTypes.Yu ? '#000' : '#32D582'}
                  weight='bold'
                  lineCap
                >
                  {utils.formatNumber(specialRevenue)}
                </Label>
              </SummaryRow>
              <SummaryRow>
                <Label color='#000'>{t('otherTransactions')}</Label>
              </SummaryRow>
              <OthersContainer>
                <SummaryRow>
                  <Label size='0.875rem' color='#475467' lineCap>
                    {t('tip')}
                  </Label>
                  <Label size='1rem' color='#000' weight='bold' lineCap>
                    {utils.formatNumber(totalTip)}
                  </Label>
                </SummaryRow>
                <SummaryRow>
                  <Label size='0.875rem' color='#475467' lineCap>
                    {t('charge')}
                  </Label>
                  <Label size='1rem' color='#000' weight='bold' lineCap>
                    {utils.formatNumber(totalCharge)}
                  </Label>
                </SummaryRow>
              </OthersContainer>
            </DataContainer>
          </ChartBox>
          <ChartBox>
            <DataContainer>
              <GridBox columns='1fr'>
                <DataItem
                  name={t('totalBills')}
                  value={`${utils.formatNumber(
                    totalBill
                  )} (${utils.formatNumber(totalCustomer)} ${t(
                    'peopleCount'
                  )})`}
                />
                <DataItem
                  name={t('avgPricePerBill')}
                  value={`${utils.formatNumber(averageIncome || 0)}`}
                />
              </GridBox>
            </DataContainer>
            {renderPayChannels()}
          </ChartBox>
          <GridBox>
            <ChartBox>
              <DataContainer>
                <ChartHeader
                  name={t('therapistCommission')}
                  icon={<TherapistCommissionIcon />}
                  value={`${utils.formatNumber(totalCommissionFee)}`}
                  valueColor='#FC6514'
                />
              </DataContainer>
            </ChartBox>
            <ChartBox>
              <DataContainer>
                <ChartHeader
                  name={t('discount')}
                  icon={<DiscountIcon />}
                  value={`${utils.formatNumber(
                    totalDiscount - packagePayment
                  )}`}
                  valueColor='#FC6514'
                />
              </DataContainer>
            </ChartBox>
            <ChartBox>
              <DataContainer>
                <ChartHeader
                  name={t('agencyCommission')}
                  icon={<AgencyCommissionIcon />}
                  value={`${utils.formatNumber(totalCommission)}`}
                  valueColor='#FC6514'
                />
              </DataContainer>
            </ChartBox>
            <ChartBox>
              <DataContainer>
                <ChartHeader
                  name={t('cashExpense')}
                  icon={<CashierIcon />}
                  value={`${utils.formatNumber(specialExpense)}`}
                  valueColor='#FC6514'
                />
              </DataContainer>
            </ChartBox>
          </GridBox>
        </SmallChartContainer>
        <ChartContainer>
          <ChartBox>
            <CustomizedPieChart
              name={t('popularService') as string}
              icon={<CircleStarIcon />}
              data={serviceSummary}
              onFilterClick={onFilterClick}
              filterData={filterData}
            />
          </ChartBox>
          <ChartBox>
            <CustomizedAreaChart
              name={t('customersByTime')}
              icon={<ClockIcon />}
              data={hourSummary}
              dataKey='count'
            />
          </ChartBox>
          <ChartBox>
            <CustomizedBarChart
              name={t('salesByDayOfWeek')}
              icon={<CircleStarIcon />}
              yAxisName={t('averageIncome')}
              data={[daySummary, monthSummary]}
              dataKey='value'
              options={[t('day'), t('month')]}
            />
          </ChartBox>
          <ChartBox>
            {storeId === 0 ? (
              <>
                <TopContainer>
                  <ChartHeader
                    name={t('revenueByBranch')}
                    icon={<CircleStarIcon />}
                  />
                </TopContainer>
                <ListView data={branchSummary} />
              </>
            ) : (
              <CustomizedBarChart
                name={t('billsByDayOfWeek')}
                icon={<CircleStarIcon />}
                yAxisName={t('averageTC')}
                data={daySummary}
                dataKey='count'
              />
            )}
          </ChartBox>
        </ChartContainer>
        <SmallChartContainer>
          <ChartBox>
            <CustomizedPieChart
              name={t('gender') as string}
              data={genderSummary}
              onFilterClick={onFilterClick}
              filterData={filterData}
            />
          </ChartBox>
          <ChartBox>
            <CustomizedPieChart
              name={t('age') as string}
              data={ageSummary}
              onFilterClick={onFilterClick}
              filterData={filterData}
            />
          </ChartBox>
          <ChartBox>
            <CustomizedPieChart
              name={t('nationality') as string}
              data={nationalitySummary}
              onFilterClick={onFilterClick}
              filterData={filterData}
            />
          </ChartBox>
        </SmallChartContainer>
        {storeId !== 0 && (
          <SmallChartContainer>
            <ChartBox>
              <CustomizedPieChart
                name={t('bookingChannel') as string}
                data={bookingTypeSummary}
                onFilterClick={onFilterClick}
                filterData={filterData}
              />
            </ChartBox>
            <ChartBox>
              <CustomizedPieChart
                name={t('package') as string}
                data={packageSummary}
                onFilterClick={onFilterClick}
                filterData={filterData}
              />
            </ChartBox>
          </SmallChartContainer>
        )}
      </BodyContainer>
      <LoaderModal isLoading={isLoading} />
    </Page>
  )
}

export default Main
