export const GET_DATA = 'GET_DATA'

export const getData = data => {
  return {
    type: GET_DATA,
    payload: { data },
  }
}

export const GET_ORDER_FROM_POPUP = 'GET_ORDER_FROM_POPUP'

export const getOrderFromPopup = service => {
  return {
    type: GET_ORDER_FROM_POPUP,
    payload: { service },
  }
}
