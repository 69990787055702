import styled from "styled-components"
import { SavedUsersData } from "../../lib/auth/loginApi"
import { LOGIN_PIN_URL } from "../../routes"
import React from "react"
import { StyledSpan } from "../../lib/design-system/atoms/StyledSpan"
import { RouteLink } from "../../lib/design-system/atoms/RouteLink"
import { Container } from "../../lib/design-system/layout/Container"


const UserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const UserImageContainer = styled.div`
  border-radius: 10px;
  width: 117px;
  height: 117px;
  background: gray;
  cursor: pointer;
  object-fit: cover;
  overflow: hidden;
`

const UserNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0;
`

interface UserBoxProps {
  user: SavedUsersData
}


export const UserBox = ({ user }: UserBoxProps) => {
  return (
    <RouteLink
      to={{ pathname: LOGIN_PIN_URL, search: `?userId=${user._id}` }}
    >
      <Container display="block">
        <UserImageContainer>
          {user.image && <UserImage src={user.image} />}
        </UserImageContainer>
        <UserNameContainer>
          <StyledSpan size="m">{`${user.firstName}`}</StyledSpan>
        </UserNameContainer>
      </Container>
    </RouteLink>
  )
}
