import api from '../../api'
import auth from '../../auth'

export const SET_USER = 'SET_USER'

export const getInitialState = () => ({
  _id: 0,
  lineUserId: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  image: '',
  types: [],
  storeId: '',
  brandId: '',
  stores: [],
  brands: [],
  error: false,
})

const createSetUser = data => {
  return {
    type: SET_USER,
    payload: { data },
  }
}

export const CLEAR_USER = 'CLEAR_USER'

export const clearUser = () => {
  return {
    type: CLEAR_USER,
    payload: getInitialState(),
  }
}

export const fetchUser = () => async (dispatch, getState) => {
  try {
    const { data, success, stores, brands } = await api.getUser(auth.getId())
    if (success) {
      const brandId = auth.getBrandId()
      if (!brandId) {
        const currentBrandId = brands?.[0]?._id
        if (currentBrandId) {
          auth.setBrandId(currentBrandId)
          auth.setStoreId(brands?.[0]?.branches?.[0]?._id)
          window.location.reload()
        }
      }
      const newData = {
        ...data,
        stores,
        brands,
        storeId: auth.getStoreId(),
        brandId,
      }
      dispatch(createSetUser(newData))
    }
  } catch (err) {
    console.log(err)
  }
}

export const setUser = user => dispatch => {
  dispatch(createSetUser(user))
}
