import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

// Styled components remain the same
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background-color: var(--popup);
  border-radius: var(--radius);
  position: relative;
  max-height: 95vh;
  overflow: auto;
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
`;

export const CloseButton = styled.div`
  position: sticky;
  top: 10px;
  font-size: 28px;
  padding: 0px 10px;
  width: max-content;
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
  height: 0px;
`;

// Types
type ModalContextType = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

type ModalProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

interface ModalTriggerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

type ModalContentProps = {
  children: React.ReactNode;
};

// Context
const ModalContext = React.createContext<ModalContextType | undefined>(undefined);

// Custom hook for using ModalContext
const useModalContext = () => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error('useModalContext must be used within a Modal component');
  }
  return context;
};

// Main Modal component
const Modal: React.FC<ModalProps> & {
  Trigger: React.FC<ModalTriggerProps>;
  Content: React.FC<ModalContentProps>;
  CloseButton: React.FC;
} = ({ children, isOpen: externalIsOpen, onOpenChange }) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);

  const isControlled = externalIsOpen !== undefined;
  const isOpen = isControlled ? externalIsOpen : internalIsOpen;

  const openModal = useCallback(() => {
    if (isControlled) {
      onOpenChange?.(true);
    } else {
      setInternalIsOpen(true);
    }
  }, [isControlled, onOpenChange]);

  const closeModal = useCallback(() => {
    if (isControlled) {
      onOpenChange?.(false);
    } else {
      setInternalIsOpen(false);
    }
  }, [isControlled, onOpenChange]);

  const contextValue = { isOpen, openModal, closeModal };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

// Modal Trigger component
const ModalTrigger: React.FC<ModalTriggerProps> = ({ children, onClick, ...rest }) => {
  const { openModal } = useModalContext();

  return (
    <div
      {...rest}
      onClick={async (e) => {
        e.stopPropagation();
        openModal();
        onClick && (await onClick(e));
      }}
    >
      {children}
    </div>
  );
};

// Modal Content component
const ModalContent: React.FC<ModalContentProps> = ({ children }) => {
  const { isOpen, closeModal } = useModalContext();

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isOpen, closeModal]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalOverlay onClick={closeModal}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        {children}
      </ModalContainer>
    </ModalOverlay>,
    document.body
  );
};

type ModalCloseButtonProps = {
  children?: React.ReactNode;
  className?: string;
};

// Modal CloseButton component
const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({ children, className }) => {
  const { closeModal } = useModalContext();
  return (
    <CloseButtonWrapper onClick={closeModal} className={className}>
      {children || '×'}
    </CloseButtonWrapper>
  );
};

// Attach Trigger, Content, and CloseButton to Modal
Modal.Trigger = ModalTrigger;
Modal.Content = ModalContent;
Modal.CloseButton = ModalCloseButton;

export default Modal;