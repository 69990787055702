import React from 'react'
import styled from 'styled-components'

import DateIcon from '../../lib/icons/DateIcon2'

const SelectWrapper = styled.div`
  position: relative;
  user-select: none;
  width: 100%;
`
const Select = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`
const SelectTrigger = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #3b3b3b;
  background: #ffffff;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => (props.open ? '#8C56D9' : '#e4e8eb')};
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
`
const CustomOptions = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: ${props => (props.open ? '2px solid #8C56D9' : '2px solid #e4e8eb')};
  margin-top: 0.5rem;
  border-radius: 8px;
  background: #fff;
  transition: all 0.5s;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  pointer-events: ${props => (props.open ? 'all' : 'none')};
  z-index: 3;
`
const CustomOption = styled.span`
  position: relative;
  display: block;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #475467;
  /* line-height: 60px; */
  background: ${props => props.backgroundColor || 'none'};
  cursor: pointer;
  transition: all 0.5s;
  :first-child {
    border-radius: 8px 8px 0 0;
  }
  :last-child {
    border-radius: 0 0 8px 8px;
  }
  &:only-child {
    border-radius: 8px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }

  :hover {
    cursor: pointer;
    background-color: #e4e8eb;
  }
  ::selection {
    color: #475467;
    background-color: #e4e8eb;
  }
`
const Arrow = styled.div`
  position: relative;
  height: 10px;
  width: 2px;
  border-radius: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 5px;
  transition: all 0.4s ease;
  transform: ${props => (props.open ? 'rotate(-135deg)' : 'rotate(45deg)')};
  right: 1rem;
  bottom: ${props => (props.open ? '-4px' : '1px')};
`

const Selector = ({
  options,
  openArrow,
  onArrowClick,
  defaultOption,
  onSelectOptionClick,
  icon = '',
  wrapperRef,
}) => {
  return (
    <SelectWrapper ref={wrapperRef}>
      <Select>
        <SelectTrigger open={openArrow} onClick={onArrowClick}>
          <div style={{ color: '#000000', fontWeight: '400' }}>
            <DateIcon size='13' color='#000000' />
            &nbsp; {defaultOption.label}
          </div>
          <Arrow open={openArrow} onClick={onArrowClick}></Arrow>
        </SelectTrigger>
        <CustomOptions open={openArrow}>
          {options.map(({ label, value }, index) => (
            <CustomOption
              key={index}
              value={index}
              onClick={() => onSelectOptionClick(value)}
              backgroundColor={value === defaultOption.value ? '#E4E8EB' : ''}
            >
              <DateIcon size='13' color='#475467' />
              &nbsp; {label}
            </CustomOption>
          ))}
        </CustomOptions>
      </Select>
    </SelectWrapper>
  )
}

export default Selector
