import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import styled, { CSSProperties } from 'styled-components'

// @ts-ignore
import api from '@/api'
import Button from '@/components/atoms/Button'
import SchedulerItem from '@/components/organisms/scheduler/SchedulerItem'
import { TimeActionPopup } from '@/components/organisms/scheduler/TimeActionPopup'
import SpaCard from '@/molecules/SpaCard'
import Service from '@/redux/models/Service'

import {
  CLOSE_OFFSET,
  DEFAULT_BOOKING_TIME,
  PIXEL_PER_MINUTE,
} from './constants'
import { TimeBox } from './Core/TimeIndicator'
import { StaffOrderState } from './types'
import { ITableData } from './useTable'

//@ts-ignore
import TextLabel from '../../../atoms/TextLabel'

import Staff from '../../../redux/models/Staff'

interface TableStaffStyle extends CSSProperties {}

const BoxPrepareTime = styled.div`
  width: 100%;
  height: 100%;
  background: var(--muted);
  border-radius: 12px;
  opacity: 0.5;
`

const TimeRow = styled.div`
  display: flex;
  position: relative;
`

const StyledActionTimePopup = styled.div`
  position: absolute;
  top: 30%;
  left: 10px;
  width: 220px;
`

const StyledSelectTime = styled.div<{
  active: boolean
  duration: number
}>`
  left: 0;
  position: absolute;
  display: ${props => (props.active ? 'block' : 'none')};
  background: #c5abec;
  width: ${props => props.duration * PIXEL_PER_MINUTE}px;
  height: 100%;
  z-index: 1;
`

const StyledTimeHitbox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const Column = styled.div<TableStaffStyle>`
  position: relative;
  width: 120px;
  text-align: center;
  border-right: ${props => props.borderRight || '1px solid #e5eced'};
  border-bottom: 1px solid #e5eced;
  background: ${props => props.background || '#FFF'};
  background-image: ${props => props.backgroundImage || ''};
  flex: 0 0 auto;
  height: ${props => props.height || '100px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:first-child {
    position: sticky;
    left: 0;
    z-index: 7;
    background: white;
  }
`

const ImageBox = styled.div<TableStaffStyle>`
  border-radius: ${props => props.borderRadius || '50%'};
  background: ${props =>
    props.background ? `url(${props.background})` : '#d9d9d9'};
  width: ${props => props.width || '40px'};
  height: ${props => props.height || '40px'};
  background-size: cover;
`
const ImageBorder = styled.div<TableStaffStyle>`
  position: relative;
  background: #fff;
  border: ${props => props.border || '2px solid #32D582'};
  border-radius: 50%;
  padding: 0.15rem;
  opacity: ${props => props.opacity || 'unset'};
  cursor: pointer;
  position: relative;
`
const DropDown = styled.div`
  background: #ffffff;
  border-radius: 8px;
  /* padding: 0.5rem; */
  position: absolute;
  right: -15px;
  top: 100%;
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.08));
  width: max-content;
  z-index: 2;
`
const Option = styled.div<TableStaffStyle>`
  min-width: 60px;
  min-height: 20px;
  padding: 0;
  font-weight: 500;
  color: ${props => props.color || '#000000'};
  cursor: ${props => props.cursor || 'pointer'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e4e8eb;
  :last-child {
    border-bottom: none;
  }
`

interface TimeState {
  hour: string
  minute: string
}

interface TableStaffProps {
  storeOpenCloseTime: { openTime: TimeState; closeTime: TimeState }
  staffOrders: StaffOrderState[]
  date: Date
  currentDate: Date
  startStaffLoading: boolean
  staffActive: { staffId: number; type: string }
  onStaffWorkStatusClick: (_id: number, workingStatus: number) => void
  startStaffClick: (_id: number) => void
  times: string[]
  onBookingClick: (
    transactionType: string,
    staffId?: number,
    time?: string
  ) => void
  // bookOrders?: BookOrder[]
  data?: ITableData
}

const TableStaff = ({
  staffOrders,
  date,
  currentDate,
  startStaffLoading,
  staffActive,
  times,
  onStaffWorkStatusClick,
  startStaffClick,
  storeOpenCloseTime,
  // bookOrders = [],
  onBookingClick,
  data,
}: TableStaffProps) => {
  const { t } = useTranslation()

  const [bookingMenu, setBookingMenu] = React.useState<{
    staffId: number
    time: string
    duration: number
  } | null>(null)

  // const bookingPerDate = useMemo(() => {
  //   // order between start and end store time
  //   const startStoreTime = dayjs(date)
  //     .hour(+storeOpenCloseTime.openTime.hour)
  //     .minute(+storeOpenCloseTime.openTime.minute)
  //   const endStoreTime = dayjs(date)
  //     .hour(+storeOpenCloseTime.closeTime.hour + CLOSE_OFFSET)
  //     .minute(+storeOpenCloseTime.closeTime.minute)

  //   const orders = bookOrders.filter(order => {
  //     const orderTime = dayjs(order.date)
  //     return (
  //       orderTime.isAfter(startStoreTime) && orderTime.isBefore(endStoreTime)
  //     )
  //   })

  //   return orders
  // }, [date, bookOrders, storeOpenCloseTime])

  // const bookingPerStaff: IData = useMemo(() => {
  //   let result = {} as IData
  //   for(let bookingIndex in bookingPerDate) {
  //     const booking = bookingPerDate[bookingIndex]
  //     for(let orderIndex in booking.orders) {
  //       const order = booking.orders[orderIndex]
  //       for(let serviceIndex in order.services) {
  //         const service = order.services[serviceIndex]
  //         for(let staffIndex in service.staffs){
  //           const staff = service.staffs[staffIndex]
  //           if(!result[staff.staffId]) {
  //             result[staff.staffId] = []
  //           }
  //           result[staff.staffId].push({
  //             booking,
  //             order: {
  //               staffOrder: staff,
  //               serviceOrder: service
  //             }
  //           })
  //       }
  //     }
  //   }
  // }
  //   return result
  // }, [bookingPerDate])

  const storeStartInMinutes =
    +storeOpenCloseTime.openTime.hour * 60 + +storeOpenCloseTime.openTime.minute
  const storeEndInMinutes =
    (+storeOpenCloseTime.closeTime.hour + CLOSE_OFFSET) * 60 +
    +storeOpenCloseTime.closeTime.minute

  const handleTimeClick = (time: string, staffId: number) => {
    if (bookingMenu) {
      setBookingMenu(null)
      return
    }
    setBookingMenu({ staffId, time, duration: DEFAULT_BOOKING_TIME })
  }

  const [services, setServices] = React.useState<Service[]>([])

  const callApiServices = async () => {
    const { data: services } = await api.getServices()
    return services
  }

  useEffect(() => {
    const fetchData = async () => {
      const services = await callApiServices()
      setServices(services)
    }
    fetchData()
  }, [])

  return (
    <div>
      {staffOrders.map(
        ({ staffName, staffImage, staffId, staffWorkingStatus }, index) => {
          return (
            <div key={index} style={{ display: 'flex' }}>
              <TimeRow key={index}>
                <Column>
                  <ImageBorder
                    border={
                      dayjs(date).isSame(dayjs(currentDate), 'date')
                        ? staffWorkingStatus === 1
                          ? ''
                          : '2px solid #FF5152'
                        : 'none'
                    }
                    opacity={
                      dayjs(date).isSame(dayjs(currentDate), 'date')
                        ? staffWorkingStatus === 1
                          ? ''
                          : staffActive.type === 'start' &&
                            staffActive.staffId === staffId
                          ? ''
                          : '0.5'
                        : ''
                    }
                    onClick={e => {
                      dayjs(currentDate).isSame(date, 'date')
                        ? startStaffClick(staffId)
                        : e.preventDefault()
                    }}
                  >
                    <ImageBox background={staffImage} />
                    {staffActive.type === 'start' &&
                      staffActive.staffId === staffId && (
                        <DropDown>
                          <Option
                            color={staffWorkingStatus === -1 ? '#32D582' : ''}
                            onClick={() => {
                              if (!startStaffLoading) {
                                onStaffWorkStatusClick(
                                  staffId,
                                  -1 * staffWorkingStatus
                                )
                                startStaffClick(staffId)
                              }
                            }}
                          >
                            {startStaffLoading ? (
                              <ClipLoader loading size={15} />
                            ) : staffWorkingStatus === -1 ? (
                              t('shiftStarts')
                            ) : (
                              t('shiftEnds')
                            )}
                          </Option>
                        </DropDown>
                      )}
                  </ImageBorder>
                  <TextLabel fontSize='0.75rem'>{staffName}</TextLabel>
                </Column>
                {/* {bookingPrepareTime(_id, workingStatus)}
                        {boxBooking &&
                          boxBookingTime.staffId === _id &&
                          highlightBooking(_id, name, image)}
                        {bookingDetail(_id, workingStatus)} */}
                {times.map((time, index) => {
                  const isSelected =
                    bookingMenu?.staffId === staffId &&
                    bookingMenu?.time === time
                  const startDateTime = dayjs(date)
                    .hour(+time.split(':')[0])
                    .minute(+time.split(':')[1])
                  const endDateTime = startDateTime.add(
                    DEFAULT_BOOKING_TIME,
                    'minute'
                  )

                  return (
                    <Column
                      style={{ cursor: 'pointer' }}
                      key={time}
                      background={
                        dayjs(date).isSame(dayjs(currentDate), 'date')
                          ? staffWorkingStatus === -1
                            ? '#F8F9FB'
                            : ''
                          : ''
                      }
                      backgroundImage={
                        dayjs(date).isSame(dayjs(currentDate), 'date')
                          ? staffWorkingStatus === -1
                            ? 'repeating-linear-gradient(-45deg,transparent,transparent 0.8rem,#E4E8EB 1rem,#E4E8EB 1rem)'
                            : ''
                          : ''
                      }
                    >
                      <StyledSelectTime
                        active={isSelected}
                        duration={DEFAULT_BOOKING_TIME}
                      />
                      <StyledTimeHitbox
                        onClick={() => {
                          handleTimeClick(time, staffId)
                        }}
                      />
                      <StyledActionTimePopup>
                        <TimeActionPopup
                          onClose={() => setBookingMenu(null)}
                          start={startDateTime}
                          end={endDateTime}
                          open={isSelected}
                          title={staffName}
                          image={staffImage}
                          actions={[
                            <Button
                              onClick={() => {
                                onBookingClick('book', staffId, time)
                              }}
                            >
                              {t('book')}
                            </Button>,
                          ]}
                        />
                      </StyledActionTimePopup>
                    </Column>
                  )
                })}
                {data?.[staffId]?.map?.((d, index) => {
                  const booking = d.booking
                  const orders = d.orders
                  const serviceOrders = orders.serviceOrders
                  const staffOrder = orders.staffOrder
                  if (!data[staffId]) return null
                  const duration = staffOrder?.duration || 0
                  const startPrepareTime =
                    services.find(s => s._id === serviceOrders[0].serviceId)
                      ?.prepareTime.before || 0
                  const endPrepareTime =
                    services.find(
                      s =>
                        s._id ===
                        serviceOrders[serviceOrders?.length - 1].serviceId
                    )?.prepareTime.after || 0
                  const startDateTime = dayjs(booking.date)
                    .set('hour', +booking?.startTime?.hour)
                    .set('minute', +booking?.startTime?.minute)
                  const endDateTime = startDateTime.add(duration, 'minute')
                  return (
                    <TimeBox
                      key={index}
                      storeStart={storeStartInMinutes}
                      storeEnd={storeEndInMinutes}
                      start={
                        startDateTime.hour() * 60 +
                        startDateTime.minute() -
                        startPrepareTime
                      }
                      end={
                        endDateTime.hour() * 60 +
                        endDateTime.minute() +
                        endPrepareTime
                      }
                    >
                      <BoxPrepareTime />
                    </TimeBox>
                  )
                })}
                {data?.[staffId]?.map?.((d, index) => {
                  const booking = d.booking
                  const orders = d.orders
                  const serviceOrders = orders.serviceOrders
                  const staffOrder = orders.staffOrder
                  if (!data[staffId]) return null
                  const duration = staffOrder?.duration || 0
                  const startDateTime = dayjs(booking.date)
                    .set('hour', +booking?.startTime?.hour)
                    .set('minute', +booking?.startTime?.minute)
                  const endDateTime = startDateTime.add(duration, 'minute')
                  return (
                    <>
                      <TimeBox
                        key={index}
                        storeStart={storeStartInMinutes}
                        storeEnd={storeEndInMinutes}
                        start={
                          startDateTime.hour() * 60 + startDateTime.minute()
                        }
                        end={endDateTime.hour() * 60 + endDateTime.minute()}
                      >
                        <SchedulerItem
                          booking={{
                            id: booking._id,
                            title:
                              serviceOrders?.length > 1
                                ? `${serviceOrders?.length} ${t('service')}`
                                : serviceOrders?.[0]?.serviceName,
                            start: startDateTime.toDate(),
                            end: endDateTime.toDate(),
                            customer: booking.customers
                              ?.map(customer => customer.name && customer.name)
                              .join(', '),
                            phoneNumber: booking.customers
                              ?.map(customer => customer.phoneNumber)
                              .join(', '),
                            status: booking?.statusId,
                            payStatus: booking?.payStatus,
                            room:
                              serviceOrders
                                ?.map(service =>
                                  service.rooms
                                    .map(
                                      room =>
                                        room?.roomTypeName &&
                                        `${room.roomTypeName} (${room?.roomName})`
                                    )
                                    .join(', ')
                                )
                                .join(', ') || ' ',
                          }}
                        />
                      </TimeBox>
                    </>
                  )
                })}
              </TimeRow>
            </div>
          )
        }
      )}
    </div>
  )
}

export default TableStaff
