import React from 'react'
import styled from 'styled-components'

import TextLabel from '../../atoms/TextLabel'
import ChevronDownIcon from '../../atoms/ChevronDownIcon'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  color: #3b3b3b;
  background: #ffffff;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.borderColor || '#e4e8eb'};
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
`

const SelectorBox = ({ value = '', onClick }) => {
  return (
    <Container onClick={e => onClick && onClick(e)}>
      <TextLabel>{value}</TextLabel>
      <ChevronDownIcon />
    </Container>
  )
}

export default SelectorBox
