import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import Label from '../../../atoms/Label'

const Container = styled.div`
  position: relative;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
`

const RangeBlock = styled.div`
  width: calc(50%);
  height: 28px;
  background: ${props => props.rangeColor};
  position: absolute;
  left: ${props => props.left || 0};
  z-index: -1;
`

const DateContainer = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
  margin: 5px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.clickable ? 'pointer' : '')};
  color: ${props => props.color};
`

const DateStatus = styled.div`
  width: 5px;
  height: 5px;
  background: ${props => props.background};
  border-radius: 50%;
  opacity: ${props => props.opacity};
  position: absolute;
  left: 50%;
  bottom: ${props => props.bottom};
  transform: translateX(-50%);
  transition: all 0.2s ease;
`

const checkStatus = (date, openCloseTimes) => {
  const temp = openCloseTimes || []
  const idx = temp.findIndex(t => {
    return t.specialTime ? dayjs(t.specialTime).isSame(date, 'day') : false
  })
  if (idx !== -1) {
    return temp[idx].restDay ? 'close' : 'open'
  }
  return ''
}

const getType = (d, start, stop) => {
  if (
    dayjs(d).isBefore(start, 'date') ||
    !stop ||
    dayjs(d).isAfter(stop, 'date') ||
    dayjs(start).isSame(stop, 'date')
  ) {
    return -1
  } else if (
    dayjs(d).isAfter(start, 'date') &&
    dayjs(d).isBefore(stop, 'date')
  ) {
    return 1
  } else if (dayjs(d).isSame(start, 'date')) {
    return 0
  } else if (dayjs(d).isSame(stop, 'date')) {
    return 2
  }
}

const CalendarDateItem2 = ({
  date,
  selectedDate,
  selectedEndDate,
  currentMonth,
  onClick,
  highlightColor,
  rangeColor,
  color,
  openCloseTimes,
  disabled,
}) => {
  const status = date !== '' ? checkStatus(date, openCloseTimes) : ''
  const type = getType(date, selectedDate, selectedEndDate)
  const selected =
    date !== ''
      ? dayjs(date).isSame(selectedDate, 'date') ||
        dayjs(date).isSame(selectedEndDate, 'date')
      : false
  const opacity =
    currentMonth && !dayjs(currentMonth).isSame(date, 'month') ? 0.25 : 1
  const clickable = date !== '' && !disabled

  return (
    <Container>
      {(type === 1 || type === 2) && <RangeBlock rangeColor={rangeColor} />}
      {(type === 1 || type === 0) && (
        <RangeBlock rangeColor={rangeColor} left='50%' />
      )}
      <DateContainer
        onClick={clickable ? onClick : undefined}
        backgroundColor={
          disabled
            ? 'transparent'
            : selected
            ? highlightColor
            : dayjs().isSame(date, 'date')
            ? 'rgba(181,140,182,0.15)'
            : 'transparent'
        }
        clickable={clickable}
      >
        <Label
          color={
            disabled
              ? '#C2BCBC'
              : selected
              ? type === -1
                ? highlightColor
                  ? '#FFFFFF'
                  : '#52527C'
                : '#FFFFFF'
              : color || ''
          }
          hover={clickable}
          size={disabled ? '12px' : '16px'}
          weight='200'
          opacity={opacity}
          center
        >
          {disabled ? 'ร้านปิด' : dayjs(date).get('date')}
        </Label>
      </DateContainer>
      <DateStatus
        background={
          status === 'open' ? '#FFD76B' : status === 'close' ? '#FF6363' : ''
        }
        opacity={opacity}
        bottom={selected ? '-7px' : '-2px'}
      />
    </Container>
  )
}

export default CalendarDateItem2
