import React, { useEffect, useState } from 'react'

import Head from './Head'
import Body from './Body'
import PopupAddUser from '../popups/PopupAddUser'
import Page from '../../lib/layout/Page/Page'
import api from '../../api'
import auth from '../../auth'

const Main = () => {
  const [activeAddUser, setActiveAddUser] = useState(false)
  const [activeMenu, setActiveMenu] = useState('ผู้มีสิทธิ์')
  const [users, setUsers] = useState([])

  useEffect(() => {
    callApiUsers()
  }, [])

  const callApiUsers = async () => {
    try {
      const { data } = await api.getUserByStoreId(auth.getStoreId())
      setUsers(data)
    } catch (err) {
      console.log(err)
    }
  }

  const onCreateClick = () => {
    setActiveAddUser(true)
  }

  const handleClose = () => {
    setActiveAddUser(false)
  }

  const onMenuClick = menu => {
    setActiveMenu(menu)
  }

  const update = async () => {
    callApiUsers()
  }

  return (
    <Page>
      <Head onCreateClick={onCreateClick} />
      <div className='mt-3' />
      <Body
        onMenuClick={onMenuClick}
        activeMenu={activeMenu}
        users={users}
        update={update}
      />
      {activeAddUser && (
        <PopupAddUser handleClose={handleClose} update={update} />
      )}
    </Page>
  )
}

export default Main
