import React from 'react'

import SettingHeader from '../../molecules/SettingHeader'

const Head = ({ activeMenu, onAddData, onAddGroupData }) => {
  const menus = [
    {
      name: 'พนักงาน',
      onClick: onAddData,
    },
    {
      name: 'ประเภทพนักงาน',
      onClick: onAddGroupData,
    },
  ]

  return (
    <SettingHeader
      name='พนักงาน'
      menuName='สร้างพนักงาน'
      menus={menus}
      showMenu={activeMenu !== 'ค่ามือพนักงาน'}
    />
  )
}

export default Head
