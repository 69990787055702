import React from 'react'

const ThaiHandText = ({ width, height, color, onClick }) => (
  <svg
    width={width || '379'}
    height={height || '60'}
    onClick={onClick}
    viewBox='0 0 379 60'
    fill='#4E4E72'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M96.932 54.2485C96.932 55.2663 97.3323 56.2423 98.0444 56.962C98.7565 57.6817 99.7222 58.086 100.729 58.086V36.7806C100.763 31.7623 99.1764 26.8696 96.2096 22.8453C93.2429 18.821 89.0581 15.8847 84.2903 14.4821C79.5226 13.0794 74.4323 13.287 69.7919 15.0733C65.1515 16.8597 61.2142 20.1272 58.5779 24.38V1.91309C57.5728 1.91308 56.6088 2.31583 55.897 3.03304C55.1852 3.75024 54.7842 4.7234 54.7812 5.73919V58.1201C55.7883 58.1201 56.754 57.7158 57.466 56.9961C58.1781 56.2765 58.5779 55.3004 58.5779 54.2827V36.7806C58.5759 34.172 59.0956 31.5898 60.1057 29.1892C61.1159 26.7886 62.5958 24.6191 64.4564 22.8111C66.317 21.003 68.5198 19.5936 70.9329 18.6676C73.346 17.7416 75.9196 17.3181 78.4988 17.4224C83.5082 17.6977 88.2198 19.9143 91.6532 23.6111C95.0865 27.308 96.9771 32.2003 96.932 37.2702V54.2485Z' />
    <path d='M42.9848 59.9989C42.9947 59.0276 42.6677 58.0834 42.0605 57.3304C41.4533 56.5773 40.6041 56.063 39.6607 55.8768C35.2159 55.0908 31.1885 52.7442 28.2883 49.2506C25.388 45.757 23.8013 41.3409 23.8078 36.7805V3.8261H33.4862C33.4862 2.81136 33.0876 1.83818 32.3776 1.12065C31.6677 0.403115 30.7046 0 29.7005 0H0C0 1.01474 0.398598 1.98793 1.10858 2.70546C1.81856 3.42299 2.78161 3.8261 3.78567 3.8261H20.0221V36.7805C20.028 42.9346 22.4486 48.8351 26.7533 53.1877C31.0581 57.5404 36.8955 59.9899 42.9848 59.9989V59.9989Z' />
    <path d='M148.616 49.5341V54.2484C148.614 54.7518 148.711 55.2505 148.901 55.7161C149.09 56.1816 149.369 56.6047 149.72 56.9612C150.072 57.3177 150.49 57.6005 150.95 57.7935C151.41 57.9865 151.903 58.0859 152.401 58.0859V36.7805C152.406 31.1994 150.424 25.8029 146.817 21.5771C143.21 17.3514 138.218 14.5786 132.755 13.7656C127.292 12.9525 121.721 14.1536 117.062 17.1492C112.402 20.1448 108.965 24.7349 107.378 30.0805C105.791 35.4262 106.161 41.1705 108.419 46.2635C110.677 51.3565 114.674 55.4581 119.678 57.8187C124.682 60.1793 130.359 60.6413 135.672 59.1201C140.986 57.599 145.58 54.1964 148.616 49.5341V49.5341ZM129.461 56.1728C125.673 56.1728 121.969 55.0375 118.819 52.9104C115.669 50.7833 113.214 47.7599 111.765 44.2227C110.315 40.6855 109.936 36.7931 110.675 33.038C111.414 29.2829 113.238 25.8336 115.917 23.1264C118.595 20.4191 122.009 18.5754 125.724 17.8284C129.44 17.0815 133.291 17.4648 136.792 18.93C140.292 20.3952 143.283 22.8764 145.388 26.0598C147.493 29.2433 148.616 32.986 148.616 36.8146C148.616 39.3597 148.119 41.8797 147.154 44.2306C146.19 46.5815 144.776 48.717 142.993 50.515C141.211 52.3131 139.096 53.7383 136.768 54.7091C134.44 55.6799 131.946 56.1773 129.428 56.1728H129.461Z' />
    <path d='M163.727 54.2485C163.725 54.7519 163.822 55.2507 164.012 55.7162C164.201 56.1817 164.48 56.6048 164.831 56.9613C165.183 57.3178 165.601 57.6007 166.061 57.7937C166.521 57.9866 167.014 58.086 167.512 58.086V21.8179H163.727V54.2485Z' />
    <path d='M163.727 10.8752C163.725 11.3786 163.822 11.8773 164.012 12.3428C164.201 12.8084 164.48 13.2315 164.831 13.588C165.183 13.9445 165.601 14.2273 166.061 14.4203C166.521 14.6133 167.014 14.7126 167.512 14.7126V10.2603H163.727V10.8752Z' />
    <path d='M220.986 37.2588V54.2485C220.984 54.7519 221.081 55.2506 221.271 55.7161C221.46 56.1816 221.739 56.6048 222.091 56.9613C222.442 57.3177 222.86 57.6006 223.32 57.7936C223.78 57.9866 224.273 58.0859 224.771 58.0859V36.7806C224.806 31.7622 223.219 26.8696 220.252 22.8453C217.286 18.821 213.101 15.8847 208.333 14.4821C203.565 13.0794 198.475 13.287 193.834 15.0733C189.194 16.8596 185.257 20.1271 182.621 24.3799V1.69447e-05H178.26C177.762 -0.00148129 177.268 0.0963894 176.808 0.287997C176.347 0.479605 175.929 0.761197 175.576 1.11663C175.223 1.47207 174.943 1.89434 174.752 2.35929C174.561 2.82423 174.463 3.32271 174.463 3.82612H178.834V58.0859C179.84 58.0829 180.802 57.6773 181.512 56.958C182.222 56.2386 182.621 55.2643 182.621 54.2485V36.7806C182.619 34.1719 183.138 31.5897 184.149 29.1892C185.159 26.7886 186.638 24.6191 188.499 22.811C190.359 21.003 192.563 19.5936 194.976 18.6676C197.389 17.7416 199.962 17.318 202.541 17.4224C207.55 17.6948 212.263 19.9086 215.699 23.6032C219.134 27.2979 221.028 32.1889 220.986 37.2588Z' />
    <path d='M273.087 49.5341V54.2484C273.085 54.7518 273.182 55.2505 273.372 55.7161C273.561 56.1816 273.84 56.6047 274.191 56.9612C274.543 57.3177 274.961 57.6005 275.421 57.7935C275.881 57.9865 276.374 58.0859 276.872 58.0859V36.7805C276.877 31.1994 274.895 25.8029 271.288 21.5771C267.68 17.3514 262.689 14.5786 257.225 13.7656C251.762 12.9525 246.192 14.1536 241.533 17.1492C236.873 20.1448 233.435 24.7349 231.849 30.0805C230.262 35.4262 230.632 41.1705 232.89 46.2635C235.148 51.3565 239.145 55.4581 244.149 57.8187C249.153 60.1793 254.83 60.6413 260.144 59.1201C265.457 57.599 270.051 54.1964 273.087 49.5341ZM253.932 56.1728C250.144 56.1728 246.44 55.0375 243.29 52.9104C240.14 50.7833 237.685 47.7599 236.236 44.2227C234.786 40.6855 234.407 36.7931 235.146 33.038C235.885 29.2829 237.709 25.8336 240.388 23.1264C243.067 20.4191 246.479 18.5754 250.195 17.8284C253.911 17.0815 257.762 17.4648 261.262 18.93C264.762 20.3952 267.754 22.8764 269.858 26.0598C271.963 29.2433 273.087 32.986 273.087 36.8146C273.087 39.3597 272.59 41.8797 271.625 44.2306C270.661 46.5815 269.247 48.717 267.465 50.515C265.682 52.3131 263.567 53.7383 261.239 54.7091C258.911 55.6799 256.416 56.1773 253.898 56.1728H253.932Z' />
    <path d='M306.404 13.5284C293.548 13.3006 283.035 24.2779 283.035 37.2592V58.0408C283.533 58.0408 284.027 57.9415 284.487 57.7485C284.947 57.5555 285.365 57.2726 285.717 56.9161C286.068 56.5597 286.347 56.1365 286.536 55.671C286.726 55.2055 286.823 54.7067 286.821 54.2033V36.781C286.827 34.1746 287.352 31.5962 288.365 29.1992C289.378 26.8023 290.858 24.6361 292.717 22.8296C294.576 21.0231 296.776 19.6134 299.185 18.6845C301.595 17.7556 304.165 17.3265 306.742 17.4228C311.753 17.6953 316.468 19.9107 319.903 23.6078C323.339 27.3049 325.231 32.1988 325.186 37.2706V54.2602C325.185 54.7636 325.282 55.2624 325.471 55.7279C325.661 56.1935 325.94 56.6166 326.291 56.9731C326.643 57.3296 327.061 57.6124 327.521 57.8054C327.981 57.9984 328.474 58.0977 328.972 58.0977V36.781C328.976 30.6893 326.614 24.8395 322.393 20.4908C318.172 16.142 312.43 13.6418 306.404 13.5284V13.5284Z' />
    <path d='M375.28 9.18922V36.7803C375.531 41.9144 373.754 46.939 370.339 50.7488C366.925 54.5585 362.153 56.8413 357.073 57.095C351.993 57.3487 347.021 55.5525 343.251 52.1015C339.481 48.6505 337.223 43.8275 336.971 38.6934H341.997C342.494 38.6949 342.987 38.5969 343.447 38.4052C343.907 38.2134 344.325 37.9317 344.677 37.5761C345.029 37.2205 345.308 36.7981 345.497 36.3332C345.687 35.8683 345.784 35.3701 345.783 34.8672H336.971C336.971 34.6737 336.972 34.4801 337.028 34.2865C337.618 29.7929 339.742 25.6484 343.033 22.5692C346.325 19.49 350.577 17.6695 355.056 17.4221C356.641 17.3405 358.23 17.4552 359.788 17.7637C360.727 17.9665 361.706 17.8289 362.555 17.375C363.404 16.9212 364.068 16.18 364.43 15.2814V15.1675C359.861 13.352 354.836 13.0638 350.093 14.345C345.35 15.6262 341.137 18.4095 338.073 22.2867C335.009 26.164 333.255 30.9313 333.068 35.8897C332.88 40.8481 334.269 45.7367 337.031 49.839C339.794 53.9412 343.784 57.0412 348.417 58.6844C353.049 60.3276 358.081 60.4277 362.774 58.9699C367.467 57.5121 371.575 54.5732 374.495 50.584C377.415 46.5948 378.993 41.7651 378.999 36.8031V5.37451C378.009 5.39238 377.064 5.80182 376.369 6.51497C375.674 7.22811 375.283 8.18818 375.28 9.18922V9.18922Z' />
  </svg>
)

export default ThaiHandText
