import React from 'react'
import styled, { CSSProperties } from 'styled-components'

import { ProductState } from './useCardMenu'
import useCardMenuProduct from './useCardMenuProduct'

import TextLabel from '../../../atoms/TextLabel'

import { Product } from '../../../redux/models/Product'

interface CardMenuProductStyle extends CSSProperties {
  active?: boolean
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const Box = styled.div`
  display: flex;
  flex-grow: 2;
`
const BoxStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
`
const ImageBox = styled.div<CardMenuProductStyle>`
  position: relative;
  border-radius: 4px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  padding: ${props => props.padding || '0'};
  border: ${props => props.border || 'none'};
`
const BoxService = styled.div`
  flex: 1 0 50%;
  max-width: 50%;
  padding: 5px 10px 5px 0;
`
const BoxButton = styled.div`
  position: absolute;
  color: #ffffff;
  padding: 0.25rem 1rem;
  background: linear-gradient(221.86deg, #8c56d9 2.71%, #53389e 97.79%);
  border-radius: 4px;
  width: initial;
  font-size: 0.75rem;
`

interface CardMenuProductProps {
  productGroups: ProductState[]
  activeGroup: number
  search: string
}

const CardMenuProduct = ({
  productGroups,
  activeGroup,
  search,
}: CardMenuProductProps) => {
  const { activeProduct, onProductClick } = useCardMenuProduct()

  const filteredGroup = (
    productGroups: ProductState[],
    activeGroup: number
  ) => {
    if (activeGroup !== -1) {
      return productGroups.filter(
        productGroups => productGroups._id === activeGroup
      )
    } else {
      return productGroups
    }
  }

  const bySearch = (products: Product, search: string) => {
    if (search !== '') {
      return products.name.includes(search)
    } else {
      return products
    }
  }

  const filteredList = (products: Product[], search: string) => {
    return products.filter(products => bySearch(products, search))
  }

  return (
    <Container>
      {filteredGroup(productGroups, activeGroup).map(
        ({ groupName, products, _id: productGroupId }, index) => (
          <div className='mt-2' key={index}>
            <TextLabel fontWeight='500'>{groupName}</TextLabel>
            <Box>
              <BoxStyled>
                {filteredList(products, search).map((product, index) => (
                  <BoxService
                    key={index}
                    onClick={() => onProductClick(product)}
                  >
                    <ImageBox
                      background={product.image}
                      padding={!product.image ? '1.75rem 0' : ''}
                      border={
                        activeProduct === product._id ? '1px solid #475467' : ''
                      }
                    >
                      {activeProduct === product._id && (
                        <BoxButton>เลือก</BoxButton>
                      )}
                    </ImageBox>
                    <TextLabel
                      //   color={activeProduct === _id ? '#475467' : '#98A1B2'}
                      fontSize='0.75rem'
                      fontWeight='500'
                    >
                      {product.name}
                    </TextLabel>
                  </BoxService>
                ))}
              </BoxStyled>
            </Box>
          </div>
        )
      )}
    </Container>
  )
}

export default CardMenuProduct
