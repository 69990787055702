import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
`

const StaffIcon = ({ size, color = '' }) => (
  <SVG
    width={size || '38'}
    height={size || '40'}
    viewBox='0 0 38 40'
    fill={color || '#475467'}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_43_273)'>
      <path
        d='M19.4167 4C23.09 4 26.0833 6.99333 26.0833 10.6667C26.0833 14.34 23.09 17.3333 19.4167 17.3333C15.7433 17.3333 12.75 14.34 12.75 10.6667C12.75 6.99333 15.7433 4 19.4167 4ZM19.4167 0C13.53 0 8.75 4.77333 8.75 10.6667C8.75 16.56 13.5233 21.3333 19.4167 21.3333C25.31 21.3333 30.0833 16.56 30.0833 10.6667C30.0833 4.77333 25.31 0 19.4167 0Z'
        fill={color || '#475467'}
      />
      <path
        d='M11.0971 28.1204C13.6838 29.3537 16.5171 30.0004 19.4105 30.0004C22.0105 30.0004 24.5838 29.4737 26.9638 28.4604C29.2305 29.8604 31.3305 31.8271 33.2371 34.3137C33.5771 34.7537 33.4905 35.1671 33.3571 35.4404C33.1771 35.8071 32.8705 35.9937 32.4571 35.9937H5.03712C4.51046 35.9937 4.24379 35.6804 4.11046 35.4137C3.98379 35.1604 3.89712 34.7737 4.20379 34.3671C6.25046 31.6737 8.61046 29.5404 11.0971 28.1137V28.1204ZM11.1838 23.5938C7.37046 25.2537 3.91046 28.1404 1.01712 31.9471C-1.48954 35.2404 0.890457 39.9937 5.03046 39.9937H32.4571C36.6371 39.9937 38.9571 35.2004 36.4171 31.8804C33.7305 28.3671 30.5505 25.6404 27.0638 23.9471C24.8105 25.2471 22.2038 25.9938 19.4171 25.9938C16.3905 25.9938 13.5705 25.1138 11.1905 23.6004L11.1838 23.5938Z'
        fill={color || '#475467'}
      />
    </g>
    <defs>
      <clipPath id='clip0_43_273'>
        <rect width='37.4733' height='40' fill='white' />
      </clipPath>
    </defs>
  </SVG>
)

export default StaffIcon
