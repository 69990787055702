import { createDynamicIcon } from "./Icon";

export const FilterIcon = createDynamicIcon(`
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.796875 3.19844C0.796875 2.75661 1.15505 2.39844 1.59688 2.39844L8.79688 2.39844C9.2387 2.39844 9.59688 2.75661 9.59688 3.19844C9.59688 3.64027 9.2387 3.99844 8.79688 3.99844L1.59688 3.99844C1.15505 3.99844 0.796875 3.64027 0.796875 3.19844Z" fill="#8C56D9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.7969 3.19844C12.7969 2.75661 13.155 2.39844 13.5969 2.39844L14.3969 2.39844C14.8387 2.39844 15.1969 2.75661 15.1969 3.19844C15.1969 3.64027 14.8387 3.99844 14.3969 3.99844L13.5969 3.99844C13.155 3.99844 12.7969 3.64027 12.7969 3.19844Z" fill="#8C56D9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.796875 12.8C0.796875 12.3582 1.15505 12 1.59688 12L9.59688 12C10.0387 12 10.3969 12.3582 10.3969 12.8C10.3969 13.2418 10.0387 13.6 9.59688 13.6L1.59688 13.6C1.15505 13.6 0.796875 13.2418 0.796875 12.8Z" fill="#8C56D9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.7969 12.8C12.7969 12.3582 13.155 12 13.5969 12H14.3969C14.8387 12 15.1969 12.3582 15.1969 12.8C15.1969 13.2418 14.8387 13.6 14.3969 13.6H13.5969C13.155 13.6 12.7969 13.2418 12.7969 12.8Z" fill="#8C56D9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.39844 7.99531C6.39844 7.55348 6.75661 7.19531 7.19844 7.19531L14.3984 7.19531C14.8403 7.19531 15.1984 7.55348 15.1984 7.99531C15.1984 8.43714 14.8403 8.79531 14.3984 8.79531L7.19844 8.79531C6.75661 8.79531 6.39844 8.43714 6.39844 7.99531Z" fill="#8C56D9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.796875 7.99531C0.796875 7.55348 1.15505 7.19531 1.59688 7.19531L3.19688 7.19531C3.6387 7.19531 3.99688 7.55348 3.99688 7.99531C3.99688 8.43714 3.6387 8.79531 3.19688 8.79531H1.59688C1.15505 8.79531 0.796875 8.43714 0.796875 7.99531Z" fill="#8C56D9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.19844 9.19531C5.86118 9.19531 6.39844 8.65805 6.39844 7.99531C6.39844 7.33257 5.86118 6.79531 5.19844 6.79531C4.5357 6.79531 3.99844 7.33257 3.99844 7.99531C3.99844 8.65805 4.5357 9.19531 5.19844 9.19531ZM5.19844 10.7953C6.74483 10.7953 7.99844 9.54171 7.99844 7.99531C7.99844 6.44892 6.74483 5.19531 5.19844 5.19531C3.65204 5.19531 2.39844 6.44892 2.39844 7.99531C2.39844 9.54171 3.65204 10.7953 5.19844 10.7953Z" fill="#8C56D9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1984 4.39844C11.8612 4.39844 12.3984 3.86118 12.3984 3.19844C12.3984 2.5357 11.8612 1.99844 11.1984 1.99844C10.5357 1.99844 9.99844 2.5357 9.99844 3.19844C9.99844 3.86118 10.5357 4.39844 11.1984 4.39844ZM11.1984 5.99844C12.7448 5.99844 13.9984 4.74483 13.9984 3.19844C13.9984 1.65204 12.7448 0.398438 11.1984 0.398438C9.65204 0.398438 8.39844 1.65204 8.39844 3.19844C8.39844 4.74483 9.65204 5.99844 11.1984 5.99844Z" fill="#8C56D9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5969 14C12.2596 14 12.7969 13.4627 12.7969 12.8C12.7969 12.1373 12.2596 11.6 11.5969 11.6C10.9341 11.6 10.3969 12.1373 10.3969 12.8C10.3969 13.4627 10.9341 14 11.5969 14ZM11.5969 15.6C13.1433 15.6 14.3969 14.3464 14.3969 12.8C14.3969 11.2536 13.1433 10 11.5969 10C10.0505 10 8.79688 11.2536 8.79688 12.8C8.79688 14.3464 10.0505 15.6 11.5969 15.6Z" fill="#8C56D9"/>
</svg>

    `)