import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

import useCustomerPackageHistory from './useCustomerPackageHistory'

//@ts-ignore
import PopupEditCustomerPackage from '../popups/PopupEditCustomerPackage'
//@ts-ignore
import PopupViewEditCustomerPackage from '../popups/PopupViewEditCustomerPackage'
//@ts-ignore
import SelectorCustomerPackage from '../selector/SelectorCustomerPackage'

//@ts-ignore
import Scrollbar from '../../atoms/Scrollbar'
import TextLabel from '../../atoms/TextLabel'

import { BookingHistory } from '../../redux/models/BookingHistory'
import { Customer } from '../../redux/models/Customer'
import CustomerPackage from '../../redux/models/CustomerPackage'
import { UserType, UserTypes } from '../../redux/models/User'
import utils from '../../utils'

interface CustomerPackageHistoryStyle extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  overflow: hidden;
`
const BoxOrder = styled.div<CustomerPackageHistoryStyle>`
  border-radius: 8px;
  background-color: ${props => props.backgroundColor || '#FFF'};
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`

const Row = styled.div<CustomerPackageHistoryStyle>`
  display: grid;
  grid-template-columns: ${props => props.columns || '1fr 1fr 1fr 1fr 1fr'};
  gap: 0.5rem;
  padding: 0.5rem;
  justify-items: ${props => props.justifyItems || 'unset'};
`

const HeadItem = styled.div`
  text-align: center;
  font-weight: 400;
  color: #98a1b2;
  font-size: 0.875rem;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BoxDetail = styled.div`
  background-color: #f8f9fb;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem;
`

interface CustomerPackageHistoryProps {
  customerBookingHistories: BookingHistory[]
  customer: Customer
  customerPackages: CustomerPackage[]
  updateCustomerPackage: (customerId: number) => void
}

const CustomerPackageHistory = ({
  customerBookingHistories,
  customer,
  customerPackages,
  updateCustomerPackage,
}: CustomerPackageHistoryProps) => {
  const { t } = useTranslation()

  const {
    role,
    wrapperRef,
    customerHistoryPackages,
    customerEditPackages,
    optionsCustomerPackages,
    defaultOptionCustomerPackage,
    openArrow,
    editPackage,
    viewEditPackage,
    currentPackage,
    bookOpenDetail,
    packageFilter,
    onEditPackageClick,
    onSelectOptionClick,
    historyPackageFilter,
    onCloseEditCustomerPackage,
    onArrowClick,
    onBookOpenClick,
    handleClose,
  } = useCustomerPackageHistory({
    customerBookingHistories,
    customer,
    customerPackages,
  })

  return (
    <Container>
      <FlexBox>
        <TextLabel fontWeight='500'>{t('remainingPackage')}</TextLabel>
        <SelectorCustomerPackage
          options={optionsCustomerPackages}
          defaultOption={defaultOptionCustomerPackage}
          wrapperRef={wrapperRef}
          openArrow={openArrow}
          onArrowClick={onArrowClick}
          onSelectOptionClick={onSelectOptionClick}
        />
      </FlexBox>
      <Scrollbar
        id='customer-customerPackage'
        maxHeight='300px'
        height='auto'
        gap='0.5rem'
      >
        <Row columns='1fr 1fr 1fr 1fr 1fr 1fr'>
          <HeadItem>{t('packageName')}</HeadItem>
          <HeadItem>{t('remainingAmount')}</HeadItem>
          <HeadItem>{t('expiryDate')}</HeadItem>
          <HeadItem>{t('status')}</HeadItem>
          <HeadItem />
          <HeadItem />
        </Row>
        <Box>
          {packageFilter(customerPackages, defaultOptionCustomerPackage).map(
            (
              {
                name,
                expiryDay,
                totalValue,
                remainingValue,
                type,
                buyDate,
                _id,
                status,
              },
              index
            ) => (
              <BoxOrder key={index}>
                <Row columns='1fr 1fr 1fr 1fr 1fr 1fr'>
                  <RowItem>{name}</RowItem>
                  <RowItem>
                    {type !== 4 && (
                      <>
                        <TextLabel>{`${remainingValue}/${totalValue}`}</TextLabel>
                      </>
                    )}
                  </RowItem>
                  <RowItem>
                    <TextLabel>
                      {utils.formatDate(
                        dayjs(buyDate).add(expiryDay, 'day').toISOString(),
                        'DD/MM/YYYY'
                      )}
                    </TextLabel>
                  </RowItem>
                  <RowItem>
                    <TextLabel>{`${
                      status === 1
                        ? dayjs(new Date()).isBefore(
                            dayjs(buyDate).add(expiryDay, 'day')
                          )
                          ? 'ใช้งาน'
                          : 'หมดอายุ'
                        : 'เลิกใช้งาน'
                    }`}</TextLabel>
                  </RowItem>
                  <RowItem onClick={() => onEditPackageClick(_id, 'view')}>
                    <TextLabel color='#8C56D9' cursor='pointer'>
                      {t('revisionHistory')}
                    </TextLabel>
                  </RowItem>
                  <RowItem
                    onClick={e =>
                      (
                        [UserTypes.Admin, UserTypes.Owner] as UserType[]
                      ).includes(role)
                        ? onEditPackageClick(_id, 'edit')
                        : e.preventDefault()
                    }
                  >
                    {(
                      [UserTypes.Admin, UserTypes.Owner] as UserType[]
                    ).includes(role) && (
                      <TextLabel color='#8C56D9' cursor='pointer'>
                        {t('edit')}
                      </TextLabel>
                    )}
                  </RowItem>
                </Row>
              </BoxOrder>
            )
          )}
        </Box>
      </Scrollbar>
      <Row>
        <HeadItem>{t('orderNo.')}</HeadItem>
        <HeadItem>{t('dateTime')}</HeadItem>
        <HeadItem>{t('package')}</HeadItem>
        <HeadItem>{t('amountUsed')}</HeadItem>
        <HeadItem />
      </Row>
      <Scrollbar id='customer-customerPackageHistory' gap='0.5rem'>
        <Box>
          {historyPackageFilter(
            customerHistoryPackages,
            defaultOptionCustomerPackage
          ).map(({ bookingCode, date, time, payChannel, customers }, index) => (
            <BoxOrder key={index}>
              <Row>
                <RowItem>{utils.formatBookingCode(bookingCode)}</RowItem>
                <RowItem>{`${utils.formatDate(
                  date,
                  'DD/MM/YY'
                )} ${utils.formatTime(time.hour)}:${utils.formatTime(
                  time.minute
                )}`}</RowItem>
                <RowItem>{payChannel.payChannel}</RowItem>
                <RowItem>{payChannel.quantity}</RowItem>
                <RowItem
                  onClick={() =>
                    onBookOpenClick(bookOpenDetail === index ? -1 : index)
                  }
                  style={{ cursor: 'pointer' }}
                >
                  {t('readMore')}
                </RowItem>
              </Row>
              {bookOpenDetail === index && (
                <BoxDetail>
                  {customers.map(({ name, firstName, orders }, index) => (
                    <Row key={index} columns='1fr 1fr 2fr' justifyItems='start'>
                      <RowItem>
                        <TextLabel fontWeight='500' fontSize='1rem'>
                          {name || firstName || ''}
                        </TextLabel>
                      </RowItem>
                      <RowItem>
                        {orders.map(({ service, time, price }, index) => (
                          <div key={index}>
                            <TextLabel
                              color='#475467'
                              fontWeight='500'
                              fontSize='1rem'
                            >
                              {service}
                            </TextLabel>
                            <TextLabel color='#475467'>{` ${time} นาที มูลค่า ${price}`}</TextLabel>
                          </div>
                        ))}
                      </RowItem>
                      <RowItem>
                        {/* {orders.map(({ staffName }, index) => (
                          <TextLabel
                            key={index}
                            color='#475467'
                            fontSize='1rem'
                          >
                            {staffName}
                          </TextLabel>
                        ))} */}
                      </RowItem>
                    </Row>
                  ))}
                </BoxDetail>
              )}
            </BoxOrder>
          ))}
        </Box>
      </Scrollbar>
      {editPackage && (
        <PopupEditCustomerPackage
          handleClose={() => {
            onCloseEditCustomerPackage()
            updateCustomerPackage(customer._id || -1)
          }}
          currentPackage={currentPackage}
        />
      )}
      {viewEditPackage && (
        <PopupViewEditCustomerPackage
          handleClose={() => handleClose()}
          customerEditPackages={customerEditPackages}
          currentPackage={currentPackage}
        />
      )}
    </Container>
  )
}

export default CustomerPackageHistory
