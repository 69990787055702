import React from 'react'
import styled from 'styled-components'

import TextLabel from './TextLabel'

const BoxContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e4e8eb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
  width: fit-content;
`

const BoxLine = styled.div`
  padding: 0.25rem;
  font-weight: 500;
  font-size: 1.25rem;
`

interface BoxAddNumberProps {
  count: number
  onCountClick: (type: string) => void
}

const BoxAddNumber = ({ count, onCountClick }: BoxAddNumberProps) => {
  return (
    <BoxContainer>
      <TextLabel
        fontWeight='600'
        fontSize='1.25rem'
        style={{ cursor: 'pointer' }}
        onClick={() => onCountClick('remove')}
      >
        -
      </TextLabel>
      <BoxLine>{count}</BoxLine>
      <TextLabel
        fontWeight='600'
        fontSize='1.25rem'
        style={{ cursor: 'pointer' }}
        onClick={() => onCountClick('add')}
      >
        +
      </TextLabel>
    </BoxContainer>
  )
}

export default BoxAddNumber
