import React from 'react'
import styled from 'styled-components'

import BoxSelect from '../atoms/BoxSelect'
import TextLabel from '../atoms/TextLabel'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
`

const Circle = styled.div`
  border-radius: 50%;
  background-color: #98a1b2;
  width: 4px;
  height: 4px;
`

export interface SettingHeaderProps {
  topic?: string
  name: string
  menuName: string
  menus?: { name: string; onClick: () => void }[]
  showMenu?: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

const SettingHeader = ({
  topic = 'ตั้งค่า',
  name,
  menuName,
  menus = [],
  showMenu = true,
  onClick,
}: SettingHeaderProps) => {
  return (
    <Container>
      <Box>
        <HeaderContainer>
          <TextLabel color='#98A1B2' fontWeight='500'>
            {topic}
          </TextLabel>
          &nbsp;<Circle></Circle>&nbsp;
          <TextLabel color='#475467' fontWeight='500'>
            {name}
          </TextLabel>
        </HeaderContainer>
        <TextLabel fontWeight='600' fontSize='1.875rem'>
          {name}
        </TextLabel>
      </Box>
      {showMenu && (onClick || !!menus?.length) && (
        <BoxSelect name={menuName} menus={menus} onClick={onClick} />
      )}
    </Container>
  )
}

export default SettingHeader
