import React from 'react'
import { useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'

import useShowProduct from './useShowProduct'

import DeleteIcon3 from '../../../../atoms/DeleteIcon3'
import TextLabel from '../../../../atoms/TextLabel'

import { BookOrder } from '../../../../redux/models/BookOrder'
import utils from '../../../../utils'

interface ShowProductStyle extends CSSProperties {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
`
const GridBox = styled.div<ShowProductStyle>`
  display: grid;
  grid-template-columns: minmax(0, 0.25fr) repeat(5, minmax(0, 1fr));
  gap: 0.5rem;
  padding: ${props => props.padding || ''};
  align-items: center;
  width: 100%;
`
const Box = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 0.5rem;
  width: 100%;
`
const ImageBox = styled.div<ShowProductStyle>`
  border-radius: 8px;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
`
const BoxCount = styled.div`
  border: 1px solid #e4e8eb;
  display: flex;
  align-items: center;
  justify-content: center;
`
const BoxCountItem = styled.div<ShowProductStyle>`
  text-align: center;
  width: 33%;
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || '500'};
  color: ${props => props.color || '#00000'};
  cursor: pointer;
  :first-child {
    border-right: 1px solid #e4e8eb;
  }
  :last-child {
    border-left: 1px solid #e4e8eb;
  }
`

const ShowProduct = () => {
  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { products }: BookOrder = bookingOrderState

  const { onAddProductClick, onRemoveProductClick } = useShowProduct()

  return (
    <Container>
      <TextLabel color='#8C56D9' fontWeight='500'>
        สินค้า
      </TextLabel>
      <GridBox padding='0.5rem'>
        <TextLabel></TextLabel>
        <TextLabel fontWeight='500'>รูป</TextLabel>
        <TextLabel fontWeight='500'>สินค้า</TextLabel>
        <TextLabel textAlign='center' fontWeight='500'>
          ราคา
        </TextLabel>
        <TextLabel textAlign='center' fontWeight='500'>
          จำนวน
        </TextLabel>
        <TextLabel fontWeight='500'>ราคารวม</TextLabel>
      </GridBox>
      {products.map(({ name, image, price, quantity }, index) => (
        <Box key={index}>
          <GridBox>
            <div>
              <DeleteIcon3
                size='18'
                onClick={() => onRemoveProductClick(index)}
              />
            </div>
            <ImageBox background={image} />
            <TextLabel>{name}</TextLabel>
            <TextLabel textAlign='center'>{price}</TextLabel>
            <TextLabel>
              <BoxCount>
                <BoxCountItem
                  onClick={() => onAddProductClick('remove', index)}
                >
                  -
                </BoxCountItem>
                <BoxCountItem color='#8C56D9' fontSize='1rem'>
                  {quantity}
                </BoxCountItem>
                <BoxCountItem onClick={() => onAddProductClick('add', index)}>
                  +
                </BoxCountItem>
              </BoxCount>
            </TextLabel>
            <TextLabel textAlign='center'>
              {utils.formatNumber((quantity || 0) * (price || 0), 2)}
            </TextLabel>
          </GridBox>
        </Box>
      ))}
    </Container>
  )
}

export default ShowProduct
