import cookieService from './cookieService'

const setItem = (key: string, value: string, isSession: boolean = false) => {
  if (!!window.sessionStorage.getItem('token') || isSession) {
    window.sessionStorage.setItem(key, value)
  } else {
    cookieService.set(key, value)
  }
}

const getItem = (key: string) => {
  return cookieService.get(key) || sessionStorage.getItem(key) || ''
}

const clearItem = (key: string) => {
  cookieService.remove(key)
  window.sessionStorage.removeItem(key)
}

const auth = {
  login(id: number, token: string, isSession: boolean) {
    if (id) setItem('id', String(id), isSession)
    if (token) setItem('token', token, isSession)
  },
  setStoreId(storeId: number) {
    if (storeId !== undefined) {
      setItem('storeId', String(storeId))
    }
  },
  setBrandId(brandId: number) {
    if (brandId) {
      setItem('brandId', String(brandId))
    }
  },
  getStoreId() {
    return +getItem('storeId')
  },
  getId() {
    return +getItem('id')
  },
  getToken() {
    return getItem('token')
  },
  getBrandId() {
    return +getItem('brandId')
  },
  logout() {
    clearItem('token')
    clearItem('storeId')
    clearItem('brandId')
    clearItem('id')
  },
  isLoggedIn() {
    if (getItem('token')) {
      return true
    }
    return false
  },
}

export default auth
