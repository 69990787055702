import api from '../../api'

export const GET_BOOKING_HISTORY = 'GET_BOOKING_HISTORY'

export const getBookingHistory = data => {
  return {
    type: GET_BOOKING_HISTORY,
    payload: { data },
  }
}

export const callApiBookingHistoryByDate =
  (startDate, endDate) => async (dispatch, getState) => {
    try {
      const { data } = await api.getBookingHistoryByDate(
        new Date(startDate),
        new Date(endDate),
        { allDay: true }
      )
      if (data.length) {
        dispatch(getBookingHistory(data))
      } else {
        dispatch(getBookingHistory({}))
      }
    } catch (err) {
      console.log(err)
    }
  }
