import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

import { Time } from '@/redux/models/BookOrder'

import {
  CLOSE_OFFSET,
  DEFAULT_BOOKING_TIME,
  PIXEL_PER_MINUTE,
} from './constants'
import { TimeBox } from './Core/TimeIndicator'
import UnassignedBox from './Core/UnassignedBox'
import { ITableItemData } from './useTable'

import Staff from '../../../redux/models/Staff'

interface GroupedBookings {
  [key: string]: ITableItemData[]
}

function formatTime(time: Time): string {
  return `${time.hour?.toString().padStart(2, '0')}:${time.minute
    ?.toString()
    .padStart(2, '0')}`
}

function parseCustomTime(timeString: string): number {
  const [hours, minutes] = timeString.split(':').map(Number)
  return hours * 60 + minutes
}

function groupBookingsIntoCustomTimeslots(
  bookings: ITableItemData[],
  timeslots: string[]
): GroupedBookings {
  const grouped: GroupedBookings = {}

  // Initialize all timeslots with empty arrays
  timeslots.forEach(slot => {
    grouped[slot] = []
  })

  bookings.forEach(booking => {
    const startTime = formatTime(booking.booking.startTime)
    const startMinutes = parseCustomTime(startTime)

    timeslots.forEach((slot, index) => {
      const slotMinutes = parseCustomTime(slot)
      const nextSlotMinutes =
        index < timeslots.length - 1
          ? parseCustomTime(timeslots[index + 1])
          : slotMinutes + 30

      if (startMinutes < nextSlotMinutes && startMinutes >= slotMinutes) {
        grouped[slot].push(booking)
      }
    })
  })

  return grouped
}

interface TableStaffStyle extends CSSProperties {}

const TimeRow = styled.div`
  display: flex;
  position: relative;
`

const StyledSelectTime = styled.div<{
  active: boolean
  duration: number
}>`
  left: 0;
  position: absolute;
  display: ${props => (props.active ? 'block' : 'none')};
  background: #c5abec;
  width: ${props => props.duration * PIXEL_PER_MINUTE}px;
  height: 100%;
  z-index: 1;
`

const StyledTimeHitbox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const StyledTimeContainer = styled.div`
  background: #000;
  display: flex;
`

const Column = styled.div<TableStaffStyle>`
  position: relative;
  width: 120px;
  text-align: center;
  border-right: ${props => props.borderRight || '1px solid #e5eced'};
  border-bottom: 1px solid #e5eced;
  background: ${props => props.background || '#FFF'};
  background-image: ${props => props.backgroundImage || ''};
  flex: 0 0 auto;
  height: ${props => props.height || '54px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ColumnHead = styled.div`
  width: 120px;
  text-align: center;
  border-right: 1px solid #e5eced;
  border-bottom: 1px solid #e5eced;
  background: #fff;
  flex: 0 0 auto;
  height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  left: 0;
  z-index: 4;
`

const StyledBackdropContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  padding-left: 120px;
  z-index: 2;
  height: 100%;
  width: 100%;
`

const StyledBackdrop = styled.div`
  background: #ede9f8;
  border: 1px solid #d0beef;
  border-radius: var(--radius-xs);
  height: 100%;
`

interface TimeState {
  hour: string
  minute: string
}

interface TableStaffProps {
  storeOpenCloseTime: { openTime: TimeState; closeTime: TimeState }
  date: Date
  currentDate: Date
  startStaffLoading: boolean
  staffActive: { staffId: number; type: string }
  onStaffWorkStatusClick: (_id: number, workingStatus: number) => void
  startStaffClick: (_id: number) => void
  times: string[]
  // bookOrders?: BookOrder[]

  data?: ITableItemData[]
}

const TableUnassigned = ({
  date,
  currentDate,
  startStaffLoading,
  staffActive,
  times,
  onStaffWorkStatusClick,
  startStaffClick,
  storeOpenCloseTime,
  // bookOrders = [],
  data,
}: TableStaffProps) => {
  const { t } = useTranslation()
  // console.log(times)
  const [bookingMenu, setBookingMenu] = React.useState<{
    staffId: number
    time: string
    duration: number
  } | null>(null)

  const [groupedBookings, setGroupedBookings] = React.useState<GroupedBookings>(
    {}
  )

  React.useEffect(() => {
    setGroupedBookings(groupBookingsIntoCustomTimeslots(data || [], times))
  }, [data, times])

  // console.log('groupedBookings', groupedBookings)

  const storeStartInMinutes =
    +storeOpenCloseTime.openTime.hour * 60 + +storeOpenCloseTime.openTime.minute
  const storeEndInMinutes =
    (+storeOpenCloseTime.closeTime.hour + CLOSE_OFFSET) * 60 +
    +storeOpenCloseTime.closeTime.minute

  const handleTimeClick = (time: string) => {}

  return (
      <TimeRow>
        <ColumnHead
          style={{
            fontWeight: 500,
            fontSize: '14px'
          }}
        >
          {t('unassignedBooking')}
        </ColumnHead>
        <StyledBackdropContainer>
          <StyledBackdrop />
        </StyledBackdropContainer>
        <StyledTimeContainer>
          {times.map((time, index) => {
            const startDateTime = dayjs(date)
              .hour(+time.split(':')[0])
              .minute(+time.split(':')[1])
            const endDateTime = startDateTime.add(
              DEFAULT_BOOKING_TIME,
              'minute'
            )

            return (
              <Column style={{ cursor: 'pointer' }} key={time}>
                <StyledSelectTime
                  active={false}
                  duration={DEFAULT_BOOKING_TIME}
                />
                <StyledTimeHitbox
                  onClick={() => {
                    handleTimeClick(time)
                  }}
                />
              </Column>
            )
          })}
        </StyledTimeContainer>
        {Object.entries(groupedBookings).map(([time, bookings]) => {
          const onlyStartTime = time.split(' - ')[0]
          const StartTimeInMinutes =
            +onlyStartTime.split(':')[0] * 60 + +onlyStartTime.split(':')[1]

          return (
            <TimeBox
              key={time}
              start={StartTimeInMinutes}
              end={StartTimeInMinutes + 30}
              storeStart={storeStartInMinutes}
              storeEnd={storeEndInMinutes}
              zIndex={3}
            >
              <UnassignedBox data={bookings} />
            </TimeBox>
          )
        })}
      </TimeRow>
  )
}

export default TableUnassigned
