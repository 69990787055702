import React from 'react'
import styled, { CSSProperties } from 'styled-components'

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`

const SwitchLabel = styled.label<
  CSSProperties & {
    labelWidth: CSSProperties['width']
    labelHeight: CSSProperties['height']
  }
>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: ${props => props.labelWidth || '50%'};
  height: ${props => props.labelHeight || '30px'};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  background: ${props => props.backgroundColor || '#ABABAD'};
`

const Button = styled.span<CSSProperties>`
  content: '';
  position: absolute;
  top: 1px;
  left: 2px;
  width: ${props => props.width || '28px'};
  height: ${props => props.height || '94%'};
  border-radius: 45px;
  transition: 0.2s;
  background: white;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  ${SwitchLabel}:active & {
    width: 45px;
  }
`

const Text = styled.div<CSSProperties>`
  font-size: 0.8rem;
  font-weight: bold;
  position: absolute;
  width: calc(100% - 30px);
  display: flex;
  justify-content: center;
  left: ${props => props.left || '0px'};
  right: ${props => props.right || '0px'};
  color: ${props => props.color || '#000000'};
`

export interface SwitchButtonProps extends CSSProperties {
  name: string
  toggled: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  title?: string
  labelWidth: CSSProperties['width']
  labelHeight: CSSProperties['width']
}

const SwitchButton = ({
  name,
  toggled,
  onChange,
  backgroundColor,
  title = '',
  labelWidth,
  labelHeight,
  width,
  height,
  color,
}: SwitchButtonProps) => {
  const id = `${name}-switch`
  return (
    <>
      <SwitchInput
        className='switch-checkbox'
        id={id}
        type='checkbox'
        checked={toggled}
        onChange={onChange}
      />
      <SwitchLabel
        className='switch-label'
        htmlFor={id}
        backgroundColor={backgroundColor}
        labelWidth={labelWidth}
        labelHeight={labelHeight}
      >
        <Text
          right={toggled ? 'none' : ''}
          left={toggled ? '' : 'none'}
          color={color}
        >
          {title}
        </Text>
        <Button className='switch-button' width={width} height={height} />
      </SwitchLabel>
    </>
  )
}

export default SwitchButton
