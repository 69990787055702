import React from 'react'

import SelectorBase, { SelectorBaseProps } from './SelectorBase'

import CheckBox from '../../atoms/CheckBox'

import { TimePrice } from '../../redux/models/Service'

export interface SelectorServiceProps extends SelectorBaseProps<TimePrice> {
  selectedOptions: Pick<TimePrice, 'time' | 'timePriceId'>[]
  index: number
}

const SelectorServiceTime = ({
  name = 'serviceTime',
  open,
  option,
  options,
  selectedOptions,
  onClick,
  onOptionClick,
  error = false,
  readOnly = false,
  index,
}: SelectorServiceProps) => {
  return (
    <SelectorBase
      option={option}
      options={options}
      open={open}
      onClick={onClick}
      onOptionClick={() => {}}
      error={error}
      readOnly={readOnly}
      renderOption={option => (
        <CheckBox
          text={String(option.value.time)}
          onClick={e => {
            e.stopPropagation()
            onOptionClick(option, name, index)
          }}
          checked={
            selectedOptions.findIndex(
              selectedOption =>
                selectedOption.timePriceId === option.value.timePriceId
            ) !== -1
          }
        />
      )}
    />
  )
}

export default SelectorServiceTime
