import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import TextLabel from '../../atoms/TextLabel'
import Scrollbar from '../../atoms/Scrollbar'

import auth from '../../auth'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const BoxUserType = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`

const BoxUserTypeStyle = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  border-color: ${props => props.borderColor || '#FFF'};
  border-style: solid;
  border-width: 2px;
`

const BoxUser = styled.div`
  width: 100%;
  border-radius: ${props => props.borderRadius || '0px'};
  padding: 0.75rem 1rem;
  border-color: ${props => props.borderColor || '#e4e8eb'};
  border-style: solid;
  border-width: 2px;
  display: flex;
  align-items: center;
  &:first-child {
    border-radius: ${props => (props.active ? '8px' : '8px 8px 0px 0px')};
    border-width: ${props => (props.active ? '2px' : '2px 2px 0 2px')};
  }
  &:last-child {
    border-radius: ${props => (props.active ? '8px' : '0px 0px 8px 8px')};
    border-width: ${props => (props.active ? '2px' : '0 2px 2px 2px')};
    margin-bottom: 1rem;
  }
  &:only-child {
    border-radius: 8px;
    border-width: 2px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }
`

const BoxData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BoxDataStyle = styled.div`
  display: flex;
  align-items: center;
`

const BoxArrow = styled.div`
  background: #f8f9fb;
  border-radius: 8px;
  transform: translate(-50%);
  padding: 0.25rem 0.65rem;
`

const Arrow = styled.div`
  border: solid #475467;
  border-width: 0 3px 3px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  /* margin-left: 5px; */
  transition: all 0.4s ease;
  transform: ${props => props.transform || 'rotate(45deg)'};
`

const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.16);
  border-radius: 8px;
  padding: 0.5rem;
  top: 10px;
  right: 0px;
  z-index: 1;
`

const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`

const BoxEdit = styled.div`
  width: 25px;
  display: flex;
  justify-content: flex-end;
  cursor: ${props => props.cursor || 'none'};
  position: relative;
  /* width: ${props => props.width || '4%'}; */
`

const BoxCircle = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 33%'};
  max-width: ${props => props.width || '33%'};
  margin: ${props => props.margin || '0.25rem 0 0 0'};
`

const Circle = styled.div`
  background: #98a1b2;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`

const BodyUser = ({ users: _users, onEditClick, search }) => {
  const [users, setUsers] = useState([])
  const userTypes = [
    { typeId: 1, type: 'เจ้าของ' },
    { typeId: 2, type: 'ผู้จัดการ' },
    { typeId: 4, type: 'พนักงานทั่วไป' },
  ]
  const [editIdClick, setEditIdClick] = useState(-1)

  useEffect(() => {
    let users = userTypes.reduce((acc, info, index) => {
      const { typeId, type } = info
      acc.push({ typeId, type, open: true, users: [] })

      for (let i = 0; i < _users.length; i++) {
        const { types, statuses } = _users[i]
        const userType = types.find(t => t.storeId === +auth.getStoreId()) || ''
        const userStatus =
          statuses.find(s => s.storeId === +auth.getStoreId()) || ''
        if (
          typeId === userType.typeId &&
          userType &&
          userStatus &&
          userStatus.status !== -1
        ) {
          acc[index].users.push(_users[i])
        }
      }

      return acc
    }, [])

    const userDisable = _users.reduce((acc, info) => {
      const { statuses } = info
      const userStatus =
        statuses.find(s => s.storeId === auth.getStoreId()) || ''
      if (userStatus && userStatus.status === -1) {
        acc.push(info)
      }

      return acc
    }, [])
    users.push({
      typeId: -1,
      type: 'พักงานหรือเลิกใช้งาน',
      open: true,
      users: [...userDisable],
    })
    for (let i = 0; i < _users.length; i++) {
      const { status } = _users[i]
      if (status === -1) {
        users[users.length - 1].users.push(_users[i])
      }
    }
    setUsers(users)
  }, [_users])

  const onArrowClick = index => {
    const newArrUser = [...users]
    newArrUser[index].open = !newArrUser[index].open
    setUsers(newArrUser)
  }

  const onOpenEditUserClick = (e, _id) => {
    e.stopPropagation()
    if (editIdClick === _id) {
      setEditIdClick(-1)
    } else {
      setEditIdClick(_id)
    }
  }
  const onEditUserClick = _id => {
    onEditClick(_id, 'ผู้มีสิทธิ์')
    setEditIdClick(-1)
  }

  const bySearch = (users, search) => {
    if (search) {
      return (
        users.firstName.includes(search) ||
        users.email.includes(search) ||
        users.phoneNumber.includes(search)
      )
    } else return users
  }
  const filteredList = (users, search) => {
    return users.filter(users => bySearch(users, search))
  }

  return (
    <Container>
      <Scrollbar id='setting-bodyUser'>
        <BoxUserType>
          {users.map(({ typeId, type, open, users }, index) => (
            <BoxUserTypeStyle
              key={typeId}
              // borderColor={open ? '#8C56D9' : ''}
              // onClick={() => onArrowClick(index)}
            >
              <BoxData>
                <BoxDataStyle>
                  <TextLabel fontWeight='500' fontSize='1.25rem'>
                    {type}
                  </TextLabel>
                  &nbsp;&nbsp; &nbsp;
                  <TextLabel
                    color='#98A1B2'
                    fontWeight='500'
                    fontSize='0.75rem'
                  >{`(${users.length} คน)`}</TextLabel>
                </BoxDataStyle>
                {users.length !== 0 && (
                  <BoxDataStyle>
                    <BoxArrow onClick={() => onArrowClick(index)}>
                      <Arrow
                        transform={open ? 'rotate(-135deg)' : 'rotate(45deg)'}
                        padding='2.5px'
                      />
                    </BoxArrow>
                  </BoxDataStyle>
                )}
              </BoxData>
              {open && (
                <div className='mt-2'>
                  {filteredList(users, search).map(
                    ({ _id, firstName, lastName }) => (
                      <BoxUser
                        key={_id}
                        borderColor={editIdClick === _id ? '#8C56D9' : ''}
                        onClick={() => onEditUserClick(_id)}
                      >
                        <BoxData>
                          <BoxDataStyle>
                            <TextLabel>{firstName}</TextLabel>
                          </BoxDataStyle>
                          <BoxDataStyle>
                            <BoxEdit onClick={e => onOpenEditUserClick(e, _id)}>
                              {Array.from({ length: 3 }).map((_, index) => (
                                <BoxCircle key={index}>
                                  <Circle></Circle>
                                </BoxCircle>
                              ))}
                              {editIdClick === _id && (
                                <DropDown>
                                  <Option onClick={() => onEditUserClick(_id)}>
                                    แก้ไข
                                  </Option>
                                  <Option
                                  // onClick={() => onDeleteRoomTypeClick()}
                                  >
                                    ลบ
                                  </Option>
                                </DropDown>
                              )}
                            </BoxEdit>
                          </BoxDataStyle>
                        </BoxData>
                      </BoxUser>
                    )
                  )}
                </div>
              )}
            </BoxUserTypeStyle>
          ))}
        </BoxUserType>
      </Scrollbar>
    </Container>
  )
}

export default BodyUser
