import React from 'react'

import Option, { SelectorOption } from '@/redux/models/Option'

import SelectorBase from './SelectorBase'

import { AgeRanges } from '../../redux/models/Customer'

const options: Option[] = [
  { value: AgeRanges.Young, label: 'น้อยกว่า 18' },
  { value: AgeRanges.YoungAdult, label: '18 - 30' },
  { value: AgeRanges.Adult, label: '30 - 50' },
  { value: AgeRanges.Elder, label: 'มากกว่า 50' },
]

interface SelectorAgeProps {
  name?: string
  open: boolean
  onClick: (open: boolean) => void
  option?: Option
  onOptionClick: (option: SelectorOption, name: string) => void
  error?: boolean
  readOnly?: boolean
}

const SelectorAge = ({
  name = 'age',
  open,
  onClick,
  option = {
    value: -1,
    label: 'อายุ',
  },
  onOptionClick,
  error = false,
  readOnly = false,
}: SelectorAgeProps) => {
  return (
    <SelectorBase
      name={name}
      option={option}
      options={options}
      open={open}
      onClick={open => onClick(open)}
      onOptionClick={(item, name) => onOptionClick(item, name)}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default SelectorAge
