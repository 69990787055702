import React, { useEffect, useRef, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'

import Option from '@/redux/models/Option'

//@ts-ignore
import CheckBox from '../../../atoms/CheckBox'

//@ts-ignore
import api from '../../../api'
import useOnClickOutside from '../../../hook/useOnClickOutside'

interface SelectorBaseStyle extends CSSProperties {
  open?: boolean
}

const SelectWrapper = styled.div<SelectorBaseStyle>`
  width: ${props => props.width || '100%'};
  position: relative;
  user-select: none;
`

const Select = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

const SelectTrigger = styled.div<SelectorBaseStyle>`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #3b3b3b;
  background: ${props => props.background || '#ffffff'};
  cursor: pointer;
  border-width: ${props => props.borderWidth || '1px'};
  border-style: solid;
  border-color: ${props => props.borderColor || '#e4e8eb'};
  border-radius: ${props => props.borderRadius || '4px'};
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
`

const Options = styled.div<SelectorBaseStyle>`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: ${props => (props.open ? '1px solid #8C56D9' : '1px solid #e4e8eb')};
  margin-top: 0.5rem;
  border-radius: 8px;
  background: #fff;
  transition: all 0.5s;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  pointer-events: ${props => (props.open ? 'all' : 'none')};
  z-index: 3;
  overflow: auto;
  max-height: ${props => props.height};
`

const DivOption = styled.span<SelectorBaseStyle>`
  position: relative;
  display: block;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #475467;
  /* line-height: 60px; */
  background: ${props => props.backgroundColor || 'none'};
  cursor: pointer;
  transition: all 0.5s;
  :first-child {
    border-radius: 8px 8px 0 0;
  }
  :last-child {
    border-radius: 0 0 8px 8px;
  }
  &:only-child {
    border-radius: 8px;
  }
  &:first-child:nth-last-child(2) {
    border-width: 2px;
  }

  :hover {
    cursor: pointer;
    background-color: #e4e8eb;
  }
  ::selection {
    color: #475467;
    background-color: #e4e8eb;
  }
`

const Arrow = styled.div<SelectorBaseStyle>`
  position: relative;
  height: 10px;
  width: 2px;
  border-radius: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0 0px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 5px;
  transition: all 0.4s ease;
  transform: ${props => (props.open ? 'rotate(-135deg)' : 'rotate(45deg)')};
  right: 1rem;
  bottom: ${props => (props.open ? '-4px' : '1px')};
`

interface OptionState extends Option {
  check: boolean
}

interface SelectorBaseProps {
  open: boolean
  onClick: (open: boolean) => void
  onOptionSelectAllClick: (item: Option[], index: number) => void
  roomTypes: RoomTypeOptions[]
  onRoomClick: (
    option: Option,
    checkAll: boolean,
    roomTypeIndex: number
  ) => void
}

interface OptionsState {
  roomType: string
  checkAll: boolean
  options: OptionState[]
}

interface RoomTypeOptions {
  value: number
  label: string
  checkAll: boolean
  rooms: Option[]
}

const SelectorCheckBoxRoom = ({
  roomTypes,
  open,
  onClick,
  onOptionSelectAllClick,
  onRoomClick,
}: SelectorBaseProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [options, setOptions] = useState<OptionsState[]>([])

  useOnClickOutside(wrapperRef, () => {
    onClick(false)
  })

  useEffect(() => {
    if (open) callApiRooms(roomTypes)
  }, [open])

  const callApiRooms = async (roomTypes: RoomTypeOptions[]) => {
    try {
      let newArrRoom: any[] = []
      for (let i = 0; i < roomTypes.length; i++) {
        const { data } = await api.getRoomByRoomTypeId(roomTypes[i].value)
        newArrRoom.push({
          roomType: roomTypes[i].label,
          checkAll: roomTypes[i].checkAll
            ? true
            : roomTypes[i].rooms.length === data.length
            ? true
            : false,
          options: [],
        })

        for (const item of data) {
          const findRoom = roomTypes[i].rooms.find(r => r.value === item._id)
          newArrRoom[i].options.push({
            value: item._id,
            label: item.roomName,
            check: findRoom ? true : false,
          })
        }
      }
      setOptions(newArrRoom)
    } catch (err) {
      console.log(err)
    }
  }

  const onOptionSelectAllRoomTypeClick = (
    rooms: OptionState[],
    index: number
  ) => {
    const newArrOption = [...options]
    let _options: Option[] = []

    newArrOption[index].checkAll = !newArrOption[index].checkAll

    for (let i = 0; i < rooms.length; i++) {
      if (newArrOption[index].checkAll) {
        _options.push({ value: rooms[i].value, label: rooms[i].label })
      }
      newArrOption[index].options[i].check = newArrOption[index].checkAll
        ? true
        : false
    }
    setOptions(newArrOption)
    onOptionSelectAllClick(_options, index)
  }

  const onOptionClick = (
    option: Option,
    roomTypeIndex: number,
    roomIndex: number
  ) => {
    const newArrOption = [...options]
    newArrOption[roomTypeIndex].options[roomIndex].check =
      !newArrOption[roomTypeIndex].options[roomIndex].check

    if (!newArrOption[roomTypeIndex].options[roomIndex].check) {
      newArrOption[roomTypeIndex].checkAll = false
    }

    let checkAll = newArrOption[roomTypeIndex].options.find(
      a => a.check === false
    )

    if (checkAll) {
      newArrOption[roomTypeIndex].checkAll = false
    } else {
      newArrOption[roomTypeIndex].checkAll = true
    }

    onRoomClick(option, newArrOption[roomTypeIndex].checkAll, roomTypeIndex)
    setOptions(newArrOption)
  }

  return (
    <SelectWrapper ref={wrapperRef}>
      <Select>
        <SelectTrigger
          //   borderWidth={borderWidth}
          //   borderRadius={borderRadius}
          //   background={readOnly ? 'rgb(240, 242, 245)' : ''}
          //   borderColor={open ? '#8C56D9' : error ? '#DB2D1E' : ''}
          onClick={e => onClick(!open)}
        >
          <div style={{ color: '#000000', fontWeight: '400' }}>
            เตียง
            {/* {option.map(o => o.label).join(', ')} */}
            {/* {typeof option === 'string' ? option : option?.label} */}
          </div>
          <Arrow open={open} />
        </SelectTrigger>
        <Options open={open} height='200px'>
          {options.map((item, roomTypeIndex) => (
            <DivOption key={roomTypeIndex}>
              <CheckBox
                checked={item.checkAll}
                onClick={() =>
                  onOptionSelectAllRoomTypeClick(item.options, roomTypeIndex)
                }
                text={`${item.roomType}(ทั้งหมด)`}
              />
              {item.options.map((option, roomIndex) => (
                <CheckBox
                  key={roomIndex}
                  checked={item.checkAll || option.check ? true : false}
                  onClick={() =>
                    onOptionClick(option, roomTypeIndex, roomIndex)
                  }
                  text={option.label}
                />
              ))}
            </DivOption>
          ))}
        </Options>
      </Select>
    </SelectWrapper>
  )
}

export default SelectorCheckBoxRoom
