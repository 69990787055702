import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  &:hover {
    user-select: none;
    cursor: pointer;
  }
`

const EyeIcon = ({ size, color, onClick }) => (
  <SVG
    width={size || '64'}
    height={size || '42'}
    onClick={onClick}
    viewBox='0 0 64 42'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M63.4889 20.0452C63.4676 19.9902 63.4442 19.9361 63.4188 19.8828C60.612 14.0536 56.2439 9.12269 50.7872 5.62313C45.1804 2.02735 38.6805 0.126953 31.9905 0.126953C25.3005 0.126953 18.8003 2.02764 13.1936 5.62372C7.75681 9.11041 3.40092 14.0185 0.593025 19.8197C0.27834 20.4224 0.254943 21.1512 0.560855 21.7868C3.36729 27.6169 7.73546 32.5486 13.1927 36.0488C18.7998 39.6452 25.3 41.5459 31.9905 41.5459C38.6808 41.5459 45.181 39.6452 50.7877 36.0491C56.245 32.5492 60.6129 27.6178 63.4196 21.7882C63.6823 21.2422 63.7068 20.6102 63.4889 20.0452ZM31.9905 37.1587C20.7066 37.1587 10.2257 30.7863 4.99307 20.836C10.2257 10.8856 20.7066 4.51354 31.9905 4.51354C43.2742 4.51354 53.7553 10.8862 58.988 20.8363C53.755 30.7857 43.2739 37.1587 31.9905 37.1587Z'
      fill={color || '#6E798C'}
    />
    <path
      d='M31.9902 11.209C26.682 11.209 22.3633 15.5277 22.3633 20.8359C22.3633 26.144 26.682 30.4627 31.9902 30.4627C37.2983 30.4627 41.617 26.144 41.617 20.8359C41.617 15.5277 37.2983 11.209 31.9902 11.209ZM31.9902 26.0758C29.101 26.0758 26.7502 23.7251 26.7502 20.8359C26.7502 17.9467 29.101 15.5959 31.9902 15.5959C34.8794 15.5959 37.2301 17.9467 37.2301 20.8359C37.2301 23.7251 34.8794 26.0758 31.9902 26.0758Z'
      fill={color || '#6E798C'}
    />
  </SVG>
)

export default EyeIcon
