import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  -webkit-tap-highlight-color: transparent;
  opacity: ${props => props.opacity || ''};
  &:hover {
    user-select: none;
    cursor: ${props => (!props.disabled ? 'pointer' : '')};
  }
`
const RecommendIcon = ({ size, colorImage, colorCircle, opacity, onClick }) => (
  <SVG
    width={size || '48'}
    height={size || '48'}
    opacity={opacity}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
  >
    <circle cx='24' cy='24' r='24' fill={colorCircle || '#8C56D9'} />
    <g clipPath='url(#clip0_682_3)'>
      <path
        d='M36.4127 23.2712C36.0079 22.1156 35.1392 21.2234 33.8343 20.6181C33.795 20.6024 33.7556 20.5866 33.7163 20.5709C33.1307 20.3862 31.6214 20.3194 30.4343 20.2918C30.9099 18.5703 31.4759 15.5909 30.4147 13.6964C29.9234 12.8159 29.1255 12.246 28.1114 12.0416C26.433 11.7075 25.8041 13.4409 25.1359 15.2804C24.4206 17.2417 23.4615 19.8791 21.1857 21.923C20.2542 20.6888 18.7763 19.887 17.1097 19.887C14.2876 19.887 12 22.1746 12 24.9967V30.8926C12 33.7147 14.2876 36.0023 17.1097 36.0023C18.6702 36.0023 20.0655 35.2987 21.001 34.1982C21.622 34.8978 22.7462 35.6092 24.7743 35.6092C25.2106 35.6092 25.6548 35.625 26.0989 35.6407C26.5431 35.6564 27.0069 35.6721 27.4746 35.6721C29.943 35.6721 32.6472 35.228 34.2981 31.9224C35.9528 28.6128 37.2538 25.6806 36.4088 23.2712H36.4127ZM17.1097 34.037C15.3764 34.037 13.9653 32.6259 13.9653 30.8926V24.9967C13.9653 23.2633 15.3764 21.8523 17.1097 21.8523C18.8431 21.8523 20.2542 23.2633 20.2542 24.9967V30.8926C20.2542 32.6259 18.8431 34.037 17.1097 34.037ZM32.5411 31.0419C31.1379 33.8483 28.8346 33.7697 26.1657 33.6754C25.698 33.6597 25.2303 33.6439 24.7743 33.6439C22.6007 33.6439 22.2352 32.5866 22.1959 32.4451C22.1684 32.3311 22.1212 32.225 22.0622 32.1346C22.1605 31.7376 22.2195 31.321 22.2195 30.8926V24.9967C22.2195 24.5683 22.1605 24.1556 22.0622 23.7586C25.0219 21.3334 26.2011 18.1104 26.9833 15.9564C27.2427 15.245 27.5886 14.2859 27.8284 13.999C28.2254 14.1012 28.5005 14.3135 28.697 14.6593C29.4792 16.0547 28.7914 19.2424 28.1743 20.9247C28.0642 21.2273 28.1074 21.5614 28.2922 21.8248C28.4769 22.0881 28.7756 22.2453 29.0979 22.2453C30.6702 22.2453 32.5922 22.32 33.0796 22.434C33.8539 22.8074 34.3413 23.2948 34.5575 23.9197C35.1942 25.7396 33.6613 28.8054 32.5411 31.0419Z'
        fill={colorImage || 'white'}
      />
    </g>
    <defs>
      <clipPath id='clip0_682_3'>
        <rect
          width='24.6761'
          height='24'
          fill={colorImage || 'white'}
          transform='translate(12 12)'
        />
      </clipPath>
    </defs>
  </SVG>
)

export default RecommendIcon
