import { BookingHistory } from '@/redux/models/BookingHistory'
import { TimePrice } from '@/redux/models/Service'

import { RoomForServiceState } from './CardBook/CardOrder/useCardCustomer'
import { RoomOrderState, RoomTypeState } from './types'

import { RoomState } from '../home/Schedule/types'

//@ts-ignore
import api from '../../api'
import {
  BookOrder,
  CustomerOrder,
  RoomOrder,
} from '../../redux/models/BookOrder'
import RoomType from '../../redux/models/RoomType'
import utils from '../../utils'

const logicRoomOrder = {
  createRoomOrders: (
    bookingHistories: BookingHistory[],
    roomTypes: RoomTypeState[]
  ) => {
    let roomOrders: RoomOrderState[] = []

    for (const bookingHistory of bookingHistories) {
      const { customers, time, startTime, statusId, bookingCode, _id } =
        bookingHistory

      let bookStartTime = time
      if (statusId === 3 || statusId === 4) {
        bookStartTime = startTime
      }
      let bookEndTime = bookStartTime
      let roomStartTime = bookStartTime

      for (const customer of customers) {
        let bookEndTimeNumber = utils.convertTime(bookEndTime)
        let roomEndTime = utils.convertTime(roomStartTime)

        const { orders } = customer
        for (let i = 0; i < orders.length; i++) {
          const { time: serviceTime, rooms } = orders[i]
          for (const room of rooms) {
            const findRoomTypeIndex = roomOrders.findIndex(
              r => r.roomTypeId === room.roomTypeId
            )

            if (findRoomTypeIndex === -1) {
              roomOrders.push({
                roomTypeId: room.roomTypeId,
                roomTypeName: room.roomTypeName,
                roomTypeCode: room.roomTypeCode,
                private: room.private,
                serviceSpecificRoomType: [],
                rooms: [
                  {
                    roomId: room.roomId,
                    roomName: room.roomName,
                    roomCode: room.roomCode,
                    serviceSpecificRoom: [],
                    orders: [
                      {
                        bookingCode,
                        _id,
                        startTime: roomStartTime,
                        endTime: utils.convertTimeToHourMinute(
                          roomEndTime + room.duration
                        ),
                        serviceTime: serviceTime,
                        duration: room.duration,
                      },
                    ],
                    free: true,
                  },
                ],
              })
            } else {
              const findRoomIndex = roomOrders[
                findRoomTypeIndex
              ].rooms.findIndex(r => r.roomId === room.roomId)

              if (findRoomIndex === -1) {
                roomOrders[findRoomTypeIndex].rooms.push({
                  roomId: room.roomId,
                  roomName: room.roomName,
                  roomCode: room.roomCode,
                  serviceSpecificRoom: [],
                  orders: [
                    {
                      bookingCode,
                      _id,
                      startTime: roomStartTime,
                      endTime: utils.convertTimeToHourMinute(
                        roomEndTime + room.duration
                      ),
                      serviceTime: serviceTime,
                      duration: room.duration,
                    },
                  ],
                  free: true,
                })
              } else {
                roomOrders[findRoomTypeIndex].rooms[findRoomIndex].orders.push({
                  bookingCode,
                  _id,
                  startTime: bookStartTime,
                  endTime: utils.convertTimeToHourMinute(
                    roomEndTime + room.duration
                  ),
                  serviceTime: serviceTime,
                  duration: room.duration,
                })
              }
            }
            roomStartTime = utils.convertTimeToHourMinute(
              utils.convertTime(roomStartTime) + room.duration
            )
            roomEndTime = utils.convertTime(roomStartTime)
          }
          bookEndTimeNumber += serviceTime
          bookStartTime = utils.convertTimeToHourMinute(bookEndTimeNumber)
        }
      }
    }

    const newRooms = roomTypes.reduce((acc: RoomOrderState[], info) => {
      const { _id, rooms, serviceSpecificRoomTypes } = info

      const findRoomTypeIndex = roomOrders.findIndex(
        rt => rt.roomTypeId === _id
      )

      if (findRoomTypeIndex !== -1) {
        const mergedRooms = utils.deepClone(roomOrders[findRoomTypeIndex].rooms)
        rooms.forEach(room => {
          const found = mergedRooms.find(m => m.roomId === room._id)
          if (!found) {
            mergedRooms.push({
              roomId: room._id,
              roomName: room.roomName,
              roomCode: room.roomCode,
              serviceSpecificRoom: room.serviceSpecificRooms,
              orders: [],
              free: true,
            })
          }
        })
        acc.push({
          ...roomOrders[findRoomTypeIndex],
          serviceSpecificRoomType: serviceSpecificRoomTypes,
          rooms: mergedRooms,
        })
      } else {
        acc.push({
          roomTypeId: _id,
          roomTypeName: info.roomTypeName,
          roomTypeCode: info.roomTypeCode,
          private: info.private,
          serviceSpecificRoomType: serviceSpecificRoomTypes,
          rooms: rooms.reduce((acc: RoomState[], info) => {
            acc.push({
              roomId: info._id,
              roomName: info.roomName,
              roomCode: info.roomCode,
              serviceSpecificRoom: info.serviceSpecificRooms,
              orders: [],
              free: true,
            })
            return acc
          }, []),
        })
      }

      return acc
    }, [])

    return newRooms
  },
  getRoomTypesByService: async (
    serviceId: number,
    serviceTimePrice: TimePrice
  ) => {
    try {
      const rooms: RoomOrder[] = []

      let { data: roomTypes }: { data: RoomType[] } =
        await api.getRoomTypeByServiceId(serviceId)

      roomTypes = roomTypes
        .map(roomType => ({
          ...roomType,
          serviceSpecificRoomTypes: roomType.serviceSpecificRoomTypes.filter(
            s => s.serviceTime === serviceTimePrice.time
          ),
        }))
        .filter(roomType => roomType.serviceSpecificRoomTypes.length > 0)

      const _roomTypes = roomTypes.reduce((acc: any[], info) => {
        const { serviceSpecificRoomTypes } = info

        for (const serviceSpecificRoomType of serviceSpecificRoomTypes) {
          const findOrder = acc.findIndex(
            a => a.order === serviceSpecificRoomType.order
          )
          if (findOrder === -1) {
            acc.push({
              ...info,
              order: serviceSpecificRoomType.order || 1,
              detail: serviceSpecificRoomType.detail || '',
              duration:
                serviceSpecificRoomType.duration || serviceTimePrice.time,
            })
          }
        }

        return acc
      }, [])

      for (let i = 0; i < (serviceTimePrice.roomCount || 1); i++) {
        let duration = serviceTimePrice.time
        let detail = ''
        const findRoom = _roomTypes.find((t: any) => t.order === i + 1)
        if (findRoom) {
          duration = findRoom.duration
          detail = findRoom.detail
        }
        rooms.push({
          order: i + 1,
          roomTypeId: -1,
          roomTypeName: '',
          roomTypeCode: '',
          roomId: -1,
          roomName: '',
          roomCode: '',
          private: -1,
          duration,
          detail,
        })
      }

      return rooms
    } catch (err) {
      console.log(err)
      return []
    }
  },
  getRoomTypesAndRoomsByService: () => {},

  manageRoomForService: (
    bookingOrderState: BookOrder,
    roomOrders: RoomOrderState[],
    serviceIndex: number,
    roomIndex: number,
    roomsForService: RoomForServiceState[],
    activeRoomId: number,
    activeRoomTypeId: number
  ) => {
    var newArrRoomOrder: RoomOrderState[] = utils.deepClone(roomOrders)

    const { customers, focusedCustomerIndex, time } = bookingOrderState

    let startTime = utils.convertTime(time)
    let endTime = startTime

    for (let i = 0; i < customers.length; i++) {
      for (const service of customers[i].services) {
        const { rooms } = service

        if (i !== focusedCustomerIndex) {
          for (const room of rooms) {
            if (room.roomId !== -1 && room.roomTypeId !== -1) {
              const findRoomTypeIndex = newArrRoomOrder.findIndex(
                rt => rt.roomTypeId === room.roomTypeId
              )

              if (findRoomTypeIndex !== -1) {
                const findRoomIndex = newArrRoomOrder[
                  findRoomTypeIndex
                ].rooms.findIndex(rt => rt.roomId === room.roomId)

                if (findRoomIndex !== -1) {
                  newArrRoomOrder[findRoomTypeIndex].rooms[
                    findRoomIndex
                  ].orders.push({
                    bookingCode: '',
                    _id: -1,
                    startTime: utils.convertTimeToHourMinute(startTime),
                    endTime: utils.convertTimeToHourMinute(
                      startTime + room.duration
                    ),
                    serviceTime: room.duration,
                    duration: room.duration,
                  })
                }
              }
              endTime += room.duration
            }
          }
        }
      }
    }

    let bookingStartTime = utils.convertTime(time)
    let bookingEndTime = bookingStartTime

    for (let i = 0; i < customers[focusedCustomerIndex].services.length; i++) {
      for (
        let j = 0;
        j < customers[focusedCustomerIndex].services[i].rooms.length;
        j++
      ) {
        if (j === roomIndex) {
          bookingEndTime =
            bookingStartTime +
            customers[focusedCustomerIndex].services[i].rooms[j].duration

          break
        } else {
          bookingStartTime +=
            customers[focusedCustomerIndex].services[i].rooms[j].duration
        }
      }
    }

    for (let i = 0; i < newArrRoomOrder.length; i++) {
      const { rooms } = newArrRoomOrder[i]
      for (let j = 0; j < rooms.length; j++) {
        for (const order of rooms[j].orders) {
          const bookStartTime = utils.convertTime(order.startTime)
          const bookEndTime = utils.convertTime(order.endTime)
          if (
            (bookStartTime > bookingStartTime &&
              bookEndTime < bookingEndTime) ||
            (bookingStartTime < bookEndTime && bookEndTime < bookingEndTime) ||
            (bookStartTime < bookingEndTime && bookEndTime >= bookingEndTime)
          ) {
            newArrRoomOrder[i].rooms[j].free = false
            break
          }
        }
      }
    }

    // console.log({ newArrRoomOrder, roomsForService })

    const roomOrder =
      customers[focusedCustomerIndex].services[serviceIndex].rooms[roomIndex]
        .order

    const newArrRoomForService = roomsForService
      .filter(r => r.order === roomOrder)
      .reduce((acc: RoomForServiceState[], info) => {
        const { roomTypeId, rooms } = info
        const findRoomType = newArrRoomOrder.find(
          r => r.roomTypeId === roomTypeId
        )
        if (findRoomType) {
          const result = findRoomType.rooms
            .filter(itemA => rooms.some(itemB => itemA.roomId === itemB.roomId))
            .map(item => ({
              free: item.free,
              roomId: item.roomId,
              roomName: item.roomName,
              roomCode: item.roomCode,
            }))

          acc.push({ ...info, rooms: result })
        }

        return acc
      }, [])

    const moreRooms = roomOrders.reduce((acc: RoomForServiceState[], info) => {
      const {
        roomTypeId,
        roomTypeCode,
        roomTypeName,
        private: roomPrivate,
        rooms,
      } = info

      const findRoom = newArrRoomForService.find(
        r => r.roomTypeId === roomTypeId
      )

      const findRoomActive = rooms.find(r => r.roomId === activeRoomId)

      if (findRoomActive) {
        const findRoomForService = newArrRoomForService.find(
          rt => rt.roomTypeId === activeRoomTypeId
        )

        const findRoom = findRoomForService?.rooms.find(
          r => r.roomId === activeRoomId
        )
        if (!findRoom)
          newArrRoomForService.push({
            order: roomOrder,
            ...info,
          })
      } else {
        if (!findRoom) {
          acc.push({ order: roomOrder, ...info })
        } else {
          const result = rooms.filter(
            itemA =>
              !findRoom.rooms.some(itemB => itemA.roomId === itemB.roomId)
          )
          if (result.length)
            acc.push({
              order: roomOrder,
              roomTypeId,
              roomTypeCode,
              roomTypeName,
              private: roomPrivate,
              rooms: result,
            })
        }
      }

      return acc
    }, [])
    // console.log({ newArrRoomForService, moreRooms })
    return { newArrRoomForService, moreRooms }
  },

  checkRoomAvailability: (
    bookingOrderState: BookOrder,
    roomOrders: RoomOrderState[],
    bookingRooms: RoomOrder[]
  ): boolean => {
    const { customers, focusedCustomerIndex, time } = bookingOrderState
    var newArrRoomOrder: RoomOrderState[] = utils.deepClone(roomOrders)

    let startTime = utils.convertTime(time)
    let endTime = startTime

    // merge customer rooms orders in roomOrders
    for (let i = 0; i < customers.length; i++) {
      for (const service of customers[i].services) {
        const { rooms } = service

        if (i !== focusedCustomerIndex) {
          for (const room of rooms) {
            if (room.roomId !== -1 && room.roomTypeId !== -1) {
              const findRoomTypeIndex = newArrRoomOrder.findIndex(
                rt => rt.roomTypeId === room.roomTypeId
              )

              if (findRoomTypeIndex !== -1) {
                const findRoomIndex = newArrRoomOrder[
                  findRoomTypeIndex
                ].rooms.findIndex(rt => rt.roomId === room.roomId)

                if (findRoomIndex !== -1) {
                  newArrRoomOrder[findRoomTypeIndex].rooms[
                    findRoomIndex
                  ].orders.push({
                    bookingCode: '',
                    _id: -1,
                    startTime: utils.convertTimeToHourMinute(startTime),
                    endTime: utils.convertTimeToHourMinute(
                      startTime + room.duration
                    ),
                    serviceTime: room.duration,
                    duration: room.duration,
                  })
                }
              }
              startTime += room.duration
              endTime += startTime + room.duration
            }
          }
        }
      }
    }

    let bookingStartTime = utils.convertTime(time)
    let bookingEndTime = bookingStartTime
    let bookingRoomIndex = 0
    for (const bookingRoom of bookingRooms) {
      // check start and end time room index
      for (
        let i = 0;
        i < customers[focusedCustomerIndex].services.length;
        i++
      ) {
        for (
          let j = 0;
          j < customers[focusedCustomerIndex].services[i].rooms.length;
          j++
        ) {
          if (j === bookingRoomIndex) {
            bookingEndTime =
              bookingStartTime +
              customers[focusedCustomerIndex].services[i].rooms[j].duration

            break
          } else {
            bookingStartTime +=
              customers[focusedCustomerIndex].services[i].rooms[j].duration
          }
        }
      }

      // console.log({
      //   bookingStartTime: utils.convertTimeToHourMinute(bookingStartTime),
      //   bookingEndTime: utils.convertTimeToHourMinute(bookingEndTime),
      // })

      // check room index is free
      for (let i = 0; i < newArrRoomOrder.length; i++) {
        const { rooms } = newArrRoomOrder[i]
        for (let j = 0; j < rooms.length; j++) {
          for (const order of rooms[j].orders) {
            const bookStartTime = utils.convertTime(order.startTime)
            const bookEndTime = utils.convertTime(order.endTime)
            if (
              (bookStartTime > bookingStartTime &&
                bookEndTime < bookingEndTime) ||
              (bookingStartTime < bookEndTime &&
                bookEndTime < bookingEndTime) ||
              (bookStartTime < bookingEndTime && bookEndTime >= bookingEndTime)
            ) {
              if (
                rooms[j].roomId === bookingRooms[bookingRoomIndex].roomId &&
                bookingRooms[bookingRoomIndex].roomId !== -1
              ) {
                newArrRoomOrder[i].rooms[j].free = false
                return false
              }
            }
          }
        }
      }
      bookingRoomIndex++
    }

    return true
  },
  checkListRoomAvailability: (
    roomOrders: RoomOrderState[],
    customers: CustomerOrder[],
    time: { hour: string | number; minute: string | number },
    serviceTime: number
  ) => {
    var newArrRoomOrder: RoomOrderState[] = utils.deepClone(roomOrders)

    let startTime = utils.convertTime(time)
    let endTime = startTime

    for (let i = 0; i < customers.length; i++) {
      for (const service of customers[i].services) {
        const { rooms } = service

        for (const room of rooms) {
          if (room.roomId !== -1 && room.roomTypeId !== -1) {
            const findRoomTypeIndex = newArrRoomOrder.findIndex(
              rt => rt.roomTypeId === room.roomTypeId
            )

            if (findRoomTypeIndex !== -1) {
              const findRoomIndex = newArrRoomOrder[
                findRoomTypeIndex
              ].rooms.findIndex(r => r.roomId === room.roomId)

              if (findRoomIndex !== -1) {
                newArrRoomOrder[findRoomTypeIndex].rooms[
                  findRoomIndex
                ].orders.push({
                  bookingCode: '',
                  _id: -1,
                  startTime: utils.convertTimeToHourMinute(startTime),
                  endTime: utils.convertTimeToHourMinute(
                    startTime + room.duration
                  ),
                  serviceTime: room.duration,
                  duration: room.duration,
                })
              }
            }
            startTime += room.duration
            endTime += startTime + room.duration
          }
        }
      }
    }

    let bookingStartTime = utils.convertTime(time)
    let bookingEndTime = bookingStartTime + serviceTime

    for (let i = 0; i < newArrRoomOrder.length; i++) {
      const { rooms } = newArrRoomOrder[i]
      for (let j = 0; j < rooms.length; j++) {
        for (const order of rooms[j].orders) {
          const bookStartTime = utils.convertTime(order.startTime)
          const bookEndTime = utils.convertTime(order.endTime)
          if (
            (bookStartTime > bookingStartTime &&
              bookEndTime < bookingEndTime) ||
            (bookingStartTime < bookEndTime && bookEndTime < bookingEndTime) ||
            (bookStartTime < bookingEndTime && bookEndTime >= bookingEndTime)
          ) {
            newArrRoomOrder[i].rooms[j].free = false
          }
        }
      }
    }

    return newArrRoomOrder
  },
}

export default logicRoomOrder
