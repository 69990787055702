import { useCallback, useEffect, useState, useRef } from 'react'

type ApiState<T> = {
  data: T | null
  loading: boolean
  error: string | null
  refetch: () => Promise<void>
}

function useApi<T, P extends any[]>(
  apiFunc: (...params: P) => Promise<T>,
  ...initialParams: P
): ApiState<T> {
  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const paramsRef = useRef<P>(initialParams)

  const fetchData = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const result = await apiFunc(...paramsRef.current)
      setData(result)
    } catch (err: any) {
      setError(err.message || 'Error fetching data')
    } finally {
      setLoading(false)
    }
  }, [apiFunc])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const refetch = useCallback((...newParams: P) => {
    paramsRef.current = newParams.length > 0 ? newParams : initialParams
    return fetchData()
  }, [fetchData, ...initialParams])

  return { data, loading, error, refetch }
}

export default useApi