import api from '../../api'

export const GET_STAFF_TYPE = 'GET_STAFF_TYPE'

export const getStaffTypes = data => {
  return {
    type: GET_STAFF_TYPE,
    payload: { data },
  }
}

export const callApiStaffTypes = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getStaffType()
    dispatch(getStaffTypes(data))
  } catch (err) {
    console.log(err)
  }
}
