import Option from './Option'

import config from '../../config'

export interface Customer {
  _id?: number
  name?: string
  firstName?: string
  lastName?: string
  image?: string
  gender?: number | Option
  nationality?: Option
  occupation?: Option
  age?: number | Option
  nationalityId?: number
  occupationId?: number
  email?: string
  countryCode?: string
  phoneNumber?: string
  address?: string
  taxIDNumber?: string
  birthDay?: Date | null
  lineId?: string
  specialRequest?: string
  specialRequests?: SpecialRequest[]
  status?: number
  storeId?: number
  brandId?: number
}

interface SpecialRequest {
  date: Date
  specialRequest: string
}

export const getDefaultCustomer = (): Customer => ({
  _id: -1,
  phoneNumber: '',
  name: '',
  firstName: '',
  lastName: '',
  nationality: {
    value: -1,
    label: 'สัญชาติ',
  },
  age: {
    value: -1,
    label: 'อายุ',
  },
  occupation: {
    value: -1,
    label: 'อาชีพ',
  },
  gender: -1,
  specialRequest: '',
  specialRequests: [],
  image: '',
  birthDay: undefined,
  countryCode: '+66',
  address: '',
  taxIDNumber: '',
})

export const Genders = {
  NotSpecified: -1,
  Male: 1,
  Female: 2,
  Other: 3,
}

export const AgeRanges = {
  NotSpecified: -1,
  Young: 1,
  YoungAdult: 2,
  Adult: 3,
  Elder: 4,
}

export const getGender = (gender: number) => {
  if (gender === -1) {
    return 'ไม่ระบุ'
  } else {
    return config.GENDER[gender - 1]
  }
}
