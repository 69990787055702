import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'

// @ts-ignore
import api from '@/api'
import {
  DataTableValue,
  DataTableValueVariant,
} from '@/components/molecules/dataTable/DataTable'
import config from '@/config'
import useDebouncedValue from '@/hook/useDebouncedValue'
import {
  HeaderSearchHistoryType,
  rowSearchHistoryAtom,
  RowSearchHistoryType,
  searchHistoryDateRangeAtom,
  searchHistoryFilterAtom,
  searchHistoryHeaderFilterAtom,
  searchHistorySearchTermAtom,
} from '@/jotai/SearchHistoryAtom'
import BookingChannel from '@/redux/models/BookingChannel'
import {
  BookingHistory,
  getInitialBookingHistory,
  TransactionTypes,
} from '@/redux/models/BookingHistory'
import Staff from '@/redux/models/Staff'
import utils from '@/utils'

import { createMockingBookingHistories } from './utils'

interface TypeState {
  key: number
  value: string | object
}

interface TypeProps {
  values: Record<string, any>
  mock?: boolean
}

interface openPrintSate {
  type: string
  bookingHistory: BookingHistory
}

interface PopupBookingHistory {
  bookingHistories: BookingHistory[]
  open: boolean
}

const StatusVariant: DataTableValueVariant[] = [
  'primary',
  'secondary',
  'tertiary',
  'success',
  'danger',
]

const useSearchHistory = ({ values, mock = false }: TypeProps) => {
  const [bookingHistories, setBookingHistories] = useState<BookingHistory[]>([])

  // Popup History
  const [popupBookingHistory, setPopupBookingHistory] = useState<{
    bookingHistories?: BookingHistory[]
    open: boolean
  } | null>(null)

  const openPopupBookingHistory = async (id: number) => {
    const { data } = await api.getBookingHistoryById(id)
    setPopupBookingHistory({ bookingHistories: data, open: true })
  }

  const closePopupBookingHistory = () => {
    setPopupBookingHistory(null)
  }

  // Popup Infomation
  const [popupBookingInfo, setPopupBookingInfo] = useState<{
    information?: BookingHistory
    open: boolean
    row?: RowSearchHistoryType
  } | null>(null)

  const openPopupBookingInfo = async (id: number) => {
    const { data } = await api.getBookingHistoryById(id)
    const _row = rows.find(row => row.id === id)
    setPopupBookingInfo({
      information: data?.[0] || undefined,
      open: true,
      row: _row,
    })
  }

  const closePopupBookingInfo = () => {
    setPopupBookingInfo(null)
  }

  const [date, setDate] = useAtom(searchHistoryDateRangeAtom)
  const [staffs, setStaffs] = useState<DataTableValue[]>([])
  const [bookingChannels, setBookingChannels] = useState<DataTableValue[]>([])
  const [headers, setHeaders] = useState<HeaderSearchHistoryType[]>([])
  const [headerFilter, setHeaderFilter] = useAtom(searchHistoryHeaderFilterAtom)
  const [rows, setRows] = useAtom<RowSearchHistoryType[]>(rowSearchHistoryAtom)
  const [search, setSearch] = useAtom(searchHistorySearchTermAtom)
  const [filters, setFilters] = useAtom(searchHistoryFilterAtom)
  const debouncedSearchTerm = useDebouncedValue(search, { ms: 500 })
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [openPrint, setOpenPrint] = useState<openPrintSate>({
    type: '',
    bookingHistory: getInitialBookingHistory(),
  })

  const transactionTypes = Object.entries(TransactionTypes).map(
    ([value, key]) => ({
      value,
      key,
    })
  )
  const payChannels = config.PAY_TYPES.reduce(
    (acc: { value: number; label: string }[], info, index) =>
      acc.concat({ value: index + 1, label: info }),
    []
  )

  const bookingStatus = config.STATUS.reduce(
    (acc: { value: string; key: number }[], info, index) =>
      acc.concat({ value: config.STATUS[index], key: index + 1 }),
    []
  )

  const fetchBookings = async (
    filters: string[][],
    search: string = '',
    limit: number = 20,
    page: number
  ) => {
    if (mock) {
      const mockRows = createMockingBookingHistories(100)
      const startIndex = (page - 1) * limit
      const endIndex = startIndex + limit
      const paginatedMockRows = mockRows.slice(startIndex, endIndex)

      if (paginatedMockRows.length < limit) {
        setHasMore(false)
      }

      if (page === 1) {
        setRows(paginatedMockRows)
      } else {
        setRows(prevRows => [...prevRows, ...paginatedMockRows])
      }
      return
    }

    try {
      const newFilters = filters.concat([
        ['date', 'gte', `${dayjs(date.startDate).format('YYYY-MM-DD')}`],
        ['date', 'lte', `${dayjs(date.endDate).format('YYYY-MM-DD')}`],
      ])
      const { data } = await api.getBookingHistoryV2(
        limit,
        page,
        newFilters,
        search
      )

      if (!data.length || data.length < limit) {
        setHasMore(false)
      }

      const bookingHistories = data.map((info: BookingHistory) =>
        transformBookingHistory(info)
      )

      setBookingHistories(data)

      if (page === 1) {
        setRows(bookingHistories)
      } else {
        setRows(prevRows => [...prevRows, ...bookingHistories])
      }
    } catch (err) {
      console.error('Error fetching bookings:', err)
    }
  }

  const transformBookingHistory = (
    info: BookingHistory
  ): RowSearchHistoryType => {
    const {
      statusId,
      time,
      startTime,
      date,
      bookingCode,
      customers,
      name,
      phoneNumber,
      transactionType,
      bookingChannel,
      total,
      serviceDiscount,
      discount,
      payStatus,
      createBy,
      updateBy,
      payChannels: _payChannels,
      createdAt,
      updatedAt,
    } = info

    let bookTime = time
    let staffs: { key: number | string; value: string }[] = []
    let services: { key: number | string; value: string }[] = []
    let payChannels: {
      key: number
      value: string
    }[] = []

    if (statusId === 3 || statusId === 4) {
      bookTime = startTime
    }

    for (const payChannel of _payChannels) {
      const findPayChannel = payChannels.find(
        p => p.key === payChannel.payChannelTypeId
      )

      if (!findPayChannel && payChannel.payChannelTypeId !== -1) {
        payChannels.push({
          key: payChannel.payChannelTypeId,
          value: config.PAY_TYPES[payChannel.payChannelTypeId - 1],
        })
      }
    }

    for (const customer of customers) {
      for (const order of customer.orders) {
        services.push({
          key: order.serviceId,
          value: order.service,
        })

        const { staffs: _staffs } = order
        for (const staff of _staffs) {
          const findStaff = staffs.find(s => s.key === staff.staffId)
          if (!findStaff && staff.staffId !== -1) {
            staffs.push({
              key: staff.staffId,
              value: staff.staffName,
            })
          }
        }
      }
    }

    return {
      id: info._id,
      bookingCode: utils.formatBookingCode(bookingCode),
      dateTime: `${utils.formatDate(date, 'DD/MM/YY')} ${utils.formatTime(
        bookTime.hour + ''
      )}.${utils.formatTime(bookTime.minute + '')}`,
      transactionType: [
        {
          key: transactionTypes.find(t => t.key === transactionType)?.key || -1,
          value:
            transactionTypes.find(t => t.key === transactionType)?.value || '',
        },
      ],
      name: name,
      phoneNumber: phoneNumber,
      services: services,
      staffs: staffs,
      bookingChannel: [
        {
          key: bookingChannel ? bookingChannel.bookingChannelId : -1,
          value: bookingChannel ? bookingChannel.bookingChannel : '',
        },
      ],
      payChannels: payChannels,
      total:
        utils.formatNumber(
          (total || 0) - (discount || 0) - (serviceDiscount || 0),
          2
        ) + '',
      payStatus: [
        {
          key: payStatus,
          value: payStatus === 1 ? 'ชำระเงิน' : 'ยังไม่ชำระเงิน',
          variant: payStatus === 1 ? 'success' : 'danger',
        },
      ],
      status: [
        {
          key: statusId,
          value: config.STATUS[statusId - 1],
          variant: StatusVariant[statusId - 1],
        },
      ],
      createBy: createBy
        ? `${createBy.userName || ''}, ${utils.formatDate(
            createdAt!,
            'DD/MM/YY'
          )} ${utils.formatTime(
            new Date(createdAt!).getHours() + ''
          )}.${utils.formatTime(new Date(createdAt!).getMinutes() + '')}`
        : '',
      editBy: updateBy
        ? `${updateBy.userName || ''}, ${utils.formatDate(
            createdAt!,
            'DD/MM/YY'
          )} ${utils.formatTime(
            new Date(updatedAt!).getHours() + ''
          )}.${utils.formatTime(new Date(updatedAt!).getMinutes() + '')}`
        : '',
    }
  }

  const cancelBooking = async (bookingId: string | number) => {
    if (mock) {
      console.log(`Mock canceling booking with ID: ${bookingId}`)
      await fetchBookings(filters, debouncedSearchTerm, 20, page)
      return
    }

    try {
      await api.updateBookingHistory(bookingId, {
        statusId: config.STATUS.indexOf('cancel') + 1,
      })
      await fetchBookings(filters, debouncedSearchTerm, 20, page)
    } catch (err) {
      console.error('Error canceling booking:', err)
    }
  }

  const callApiStaffs = async () => {
    if (mock) {
      setStaffs([
        { key: 1, value: 'Mock Staff 1' },
        { key: 2, value: 'Mock Staff 2' },
        { key: 3, value: 'Mock Staff 3' },
      ])
      return
    }

    try {
      const { data } = await api.getByNonActiveBookingHistory()
      const staffs = data.reduce((acc: TypeState[], info: Staff) => {
        acc.push({ key: info._id, value: info.name })
        return acc
      }, [])
      setStaffs(staffs)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiBookingChannels = async () => {
    if (mock) {
      setBookingChannels([
        { key: 1, value: 'Mock Channel 1' },
        { key: 2, value: 'Mock Channel 2' },
        { key: 3, value: 'Mock Channel 3' },
      ])
      return
    }

    try {
      const { data } = await api.getBookingChannel()
      const bookingChannels = data.reduce(
        (acc: TypeState[], info: BookingChannel) => {
          acc.push({ key: info._id, value: info.bookingChannel })
          return acc
        },
        []
      )
      setBookingChannels(bookingChannels)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const search = async () => {
      await fetchBookings(filters, debouncedSearchTerm, 20, 1)
      setPage(1)
      setHasMore(true)
    }
    search()
  }, [debouncedSearchTerm])

  useEffect(() => {
    const initializePage = async () => {
      await fetchBookings([], search, 20, 1)
      await callApiStaffs()
      await callApiBookingChannels()
    }
    initializePage()
  }, [])

  useEffect(() => {
    fetchBookings(filters, search, 20, 1)
    setPage(1)
    setHasMore(true)
  }, [date])

  const onValueChange = async (values: Record<string, any>) => {
    try {
      // if (values?.headers && values?.headers.length) {
      //   const filteredHeader = headers.filter(item =>
      //     values.headers.includes(item.key)
      //   )
      //   setHeaderFilter([...filteredHeader])
      // }

      let filters = []

      if (values?.transactionTypes && values?.transactionTypes.length) {
        filters.push(['transactionType', 'in', values.transactionTypes])
      }

      if (values?.staffs && values?.staffs.length) {
        filters.push(['customers.orders.staffs.staffId', 'in', values.staffs])
      }

      if (values?.bookingChannels && values?.bookingChannels.length) {
        filters.push([
          'bookingChannel.bookingChannel',
          'in',
          values.bookingChannels,
        ])
      }

      if (values?.bookingStatus && values?.bookingStatus.length) {
        filters.push(['statusId', 'in', values.bookingStatus])
      }

      if (values?.payStatus && values?.payStatus.length) {
        filters.push(['payStatus', 'eq', values.payStatus])
      }
      setFilters(filters)
      setHasMore(true)
      setPage(1)
      await fetchBookings(filters, debouncedSearchTerm, 20, 1)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setHeaders([...(values?.headers || [])])
    onValueChange(values)
  }, [values])

  const onDateChange = (startDate: Date, endDate: Date) => {
    setDate({ startDate, endDate })
  }

  const onChange = (value: string) => {
    setSearch(value)
  }

  const onDownloadClick = async () => {
    if (mock) {
      console.log('Download functionality is not available in mock mode')
      return
    }

    try {
      const headers = headerFilter.map(item => item.key).join(',')
      const newFilter = filters.concat([
        ['date', 'gte', `${dayjs(date.startDate).format('YYYY-MM-DD')}`],
        ['date', 'lte', `${dayjs(date.endDate).format('YYYY-MM-DD')}`],
      ])
      const data = await api.ExportBookingHistoryV2(newFilter, search, headers)
      const blob = new Blob([new Uint8Array(data.data.data.data)], {
        type: 'text/csv;charset=utf8',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `bookingHistory.xlsx`
      link.click()
    } catch (err) {
      console.log(err)
    }
  }

  const loadMore = async () => {
    try {
      await fetchBookings(filters, search, 20, page + 1)
      setPage(page + 1)
    } catch (err) {
      console.error('Error loading more bookings:', err)
    }
  }

  const onPrintClick = async (type: string, _id: string | number) => {
    try {
      const { data } = await api.getBookingHistoryById(_id)
      if (data.length) setOpenPrint({ type: type, bookingHistory: data[0] })
    } catch (err) {
      console.log(err)
    }
  }

  const handleClose = () => {
    setOpenPrint({
      type: '',
      bookingHistory: getInitialBookingHistory(),
    })
  }

  return {
    search,
    date,
    transactionTypes,
    staffs,
    bookingChannels,
    payChannels,
    bookingStatus,
    headers,
    rows,
    headerFilter,
    hasMore,
    openPrint,
    onDateChange,
    onChange,
    onDownloadClick,
    loadMore,
    cancelBooking,
    onPrintClick,
    handleClose,
    openPopupBookingHistory,
    closePopupBookingHistory,
    popupBookingHistory,
    openPopupBookingInfo,
    closePopupBookingInfo,
    popupBookingInfo,
  }
}

export default useSearchHistory
