import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import TextLabel from '../../atoms/TextLabel'
import InputText from '../../atoms/InputText'
import SelectorAccount from './SelectorAccount'
import useOnClickOutside from '../../hook/useOnClickOutside'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardAddAccount = ({
  payChannel,
  onNameChange,
  onAccountNumberChange,
  onPromptPayChange,
  onSelectDefaultBankClick,
  defaultBank,
  checkAccountNumber,
}) => {
  const options = [
    { value: '1', label: 'ธนาคารกสิกรไทย' },
    { value: '2', label: 'ธนาคารไทยพานิช' },
    { value: '3', label: 'ธนาคารกรุงเทพ' },
    { value: '4', label: 'ธนาคารกรุงไทย' },
    { value: '5', label: 'ธนาคารออมสิน' },
    { value: '6', label: 'ธนาคารทหารไทย' },
    { value: '7', label: 'กรุงศรีอยุธยา' },
    { value: '8', label: 'เกียรตินาคิน' },
  ]
  const wrapperRef = useRef(null)
  const [openArrow, setOpenArrow] = useState(false)

  useOnClickOutside(wrapperRef, () => {
    setOpenArrow(false)
  })
  const onArrowClick = () => {
    setOpenArrow(!openArrow)
  }

  return (
    <Container>
      <div className='mb-3 mt-2' style={{ width: '100%' }}>
        <TextLabel fontWeight='600' fontSize='1.2rem'>
          เพิ่มบัญชีธนาคาร
        </TextLabel>
      </div>
      <TextLabel fontWeight='600'>ธนาคาร</TextLabel>
      <div className='mt-2'></div>
      <SelectorAccount
        wrapperRef={wrapperRef}
        defaultOption={defaultBank}
        options={options}
        onArrowClick={onArrowClick}
        openArrow={openArrow}
        onSelectDefaultOptionClick={onSelectDefaultBankClick}
      />
      <div className='mt-3'></div>
      <TextLabel fontWeight='600'>ชื่อบัญชี</TextLabel>
      <div className='mt-2'></div>
      <InputText
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
        padding='0.5rem 1rem'
        value={payChannel.payChannel}
        onChange={val => onNameChange(val.target.value)}
      />
      <div className='mt-3' style={{ display: 'flex', width: '100%' }}>
        <div className='mr-1' style={{ width: '50%' }}>
          <TextLabel fontWeight='600'>เลขที่บัญชี*</TextLabel>
        </div>
        <div className='ml-1' style={{ width: '50%' }}>
          <TextLabel fontWeight='600'>พร้อมเพย์</TextLabel>
        </div>
      </div>
      <div className='mt-2 mb-4' style={{ display: 'flex', width: '100%' }}>
        <div className='mr-1' style={{ width: '50%' }}>
          <InputText
            boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
            padding='0.5rem 1rem'
            border={checkAccountNumber ? '2px solid #DB2D1E' : ''}
            value={payChannel.account.accountNumber || ''}
            onChange={val => onAccountNumberChange(val.target.value)}
          />
        </div>
        <div className='ml-1' style={{ width: '50%' }}>
          <InputText
            boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
            padding='0.5rem 1rem'
            value={payChannel.account.promptPay || ''}
            onChange={val => onPromptPayChange(val.target.value)}
          />
        </div>
      </div>
    </Container>
  )
}

export default CardAddAccount
