import React from 'react'
import styled from 'styled-components'

// Props interface for the Icon components
interface IconProps {
  size?: number | string
  onClick?: () => void
  fill?: string
  stroke?: string
  className?: string
  style?: React.CSSProperties
}

const normalizeSize = (size: number | string): number => {
  if (typeof size === 'number') {
    return size
  }

  if (typeof size === 'string') {
    // Remove any non-numeric characters except for the decimal point
    const cleanedSize = size.replace(/[^\d.]/g, '')

    // Parse the cleaned string to a float
    const parsedSize = parseFloat(cleanedSize)

    // Check if the parsed value is a valid number
    if (!isNaN(parsedSize)) {
      return Math.round(parsedSize)
    }
  }

  // Default size if the input is invalid
  return 24
}

export const IconWrapper = styled.div<IconProps>`
  width: ${({ size }) => normalizeSize(size || 24)}px;
  height: ${({ size }) => normalizeSize(size || 24)}px;
  position: relative;
  div,
  span,
  svg {
    width: 100%;
    height: 100%;
  }
`

export const StyledIcon = styled.span<IconProps>`
  display: inline-block;
  width: ${({ size }) => normalizeSize(size || 24)}px;
  height: ${({ size }) => normalizeSize(size || 24)}px;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

// Function to create a dynamic icon component
const createDynamicIcon = (svgContent: string) => {
  const IconComponent: any = ({
    size = 18,
    onClick,
    className,
    style,
    fill,
  }: any) => (
    <IconWrapper style={style} className={className} size={size}>
      <StyledIcon
        dangerouslySetInnerHTML={{
          __html: fill
            ? svgContent.replace(/fill="[^"]*"/g, `fill="${fill}"`)
            : svgContent,
        }}
        size={size}
        onClick={onClick}
      />
    </IconWrapper>
  )
  return IconComponent
}

// Export the createDynamicIcon function
export { createDynamicIcon }
