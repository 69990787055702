import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { BreadCrumb } from '@/components/atoms/Breadcrumb'
import { Col, Row } from '@/components/atoms/Flex'
import { routes, sidebarRoutes } from '@/lib/routes'

interface PageHeaderProps extends PropsWithChildren<{}> {}

export const StyledPageHeaderContainer = styled(Row)``

export const StyledPageHeaderTitleWrapepr = styled(Col)`
  gap: 10px;
  align-items: start;
  justify-content: end;
`

export const StyledPageHeaderActionsWrapper = styled(Row)`
  margin-left: auto;
  gap: 10px;
  align-items: end;
  justify-content: flex-end;
`

export const StyledPageHeaderTitle = styled.h2`
  margin: 0;
`

export const PageHeader: React.FC<PageHeaderProps> = props => {
  const { t } = useTranslation()
  const location = useLocation()
  const route =
    routes.find(route => route.path === location?.pathname) ||
    sidebarRoutes.find(route => route.path === location?.pathname)
  const parent =
    routes?.find(r => r.id === route?.parent) ||
    sidebarRoutes?.find(r => r.id === route?.parent)
  return (
    <StyledPageHeaderContainer>
      <StyledPageHeaderTitleWrapepr>
        {!!parent && (
          <BreadCrumb
            paths={[
              { label: t(`nav.${parent?.id}`), href: parent?.path },
              {
                label: t(`nav.${route?.id}`),
                href: route?.path,
                disabled: true,
              },
            ]}
          />
        )}
        <StyledPageHeaderTitle>{t(`nav.${route?.id}`)}</StyledPageHeaderTitle>
      </StyledPageHeaderTitleWrapepr>
      <StyledPageHeaderActionsWrapper>
        {props.children}
      </StyledPageHeaderActionsWrapper>
    </StyledPageHeaderContainer>
  )
}
