import api from '../../api'
import { convertNationalitiesToOptions } from '../models/Nationality'
import { convertOccupationsToOptions } from '../models/Occupation'

export const GET_BRAND = 'GET_BRAND'

export const getBrand = data => {
  return {
    type: GET_BRAND,
    payload: { data },
  }
}

export const fetchBrand = brandId => async (dispatch, getState) => {
  try {
    if (!brandId) return
    const [brandResponse, nationalityResponse, occupationResponse] =
      await Promise.all([
        api.getBrandById(brandId),
        api.getNationalityByStoreId(),
        api.getOccupationByStoreId(),
      ])
    dispatch(
      getBrand({
        ...brandResponse.data,
        nationalities: nationalityResponse.data,
        occupations: occupationResponse.data,
        nationalityOptions: convertNationalitiesToOptions(
          nationalityResponse.data
        ),
        occupationOptions: convertOccupationsToOptions(occupationResponse.data),
      })
    )
  } catch (err) {
    console.log(err)
  }
}

export const fetchBrandData = brandId => async (dispatch, getState) => {
  try {
    if (!brandId) return
    const [nationalityResponse, occupationResponse] = await Promise.all([
      api.getNationalityByStoreId(),
      api.getOccupationByStoreId(),
    ])
    dispatch(
      getBrand({
        nationalities: nationalityResponse.data,
        occupations: occupationResponse.data,
        nationalityOptions: convertNationalitiesToOptions(
          nationalityResponse.data
        ),
        occupationOptions: convertOccupationsToOptions(occupationResponse.data),
      })
    )
  } catch (err) {
    console.log(err)
  }
}
