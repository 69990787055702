import React from 'react'
import styled from 'styled-components'
import TextLabel from '../../atoms/TextLabel'
import { useSelector } from 'react-redux'
import utils from '../../utils'
import config from '../../config'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f8f9fb;
  padding: 1rem;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
  width: ${props => props.width};
  padding: ${props => props.padding || '0'};
`
const Line = styled.div`
  border-top: 1px solid #e5eced;
  width: 100%;
`

const CardSummary = ({ totalSplitPayment }) => {
  const { t } = useTranslation()

  const bookingOrderState = useSelector(state => state.bookingHistoryReducers)
  const {
    total = 0,
    discount = 0,
    serviceDiscount = 0,
    charge = 0,
    commission = 0,
    totalTip = 0,
    payChannels: _payChannels = [],
    buyPackage,
  } = bookingOrderState || {}
  const payPackageState = useSelector(state => state.payPackageReducers)
  const { packageDiscount = 0, splitPackageDiscount = 0 } =
    payPackageState || {}

  return (
    <Container>
      <FlexBox justifyContent='space-between' padding='0 0.5rem'>
        <TextLabel fontWeight='500'>{t('totalAmount')}</TextLabel>
        <TextLabel fontWeight='500'>
          {utils.formatNumber(total || 0, 2)}
        </TextLabel>
      </FlexBox>
      <FlexBox justifyContent='space-between' padding='0 0.5rem'>
        <TextLabel color='#DB2D1E'>{t('discount')}</TextLabel>
        <TextLabel color='#DB2D1E'>
          -{utils.formatNumber(+(discount || 0) + +(serviceDiscount || 0), 2)}
        </TextLabel>
      </FlexBox>
      <FlexBox justifyContent='space-between' padding='0 0.5rem'>
        <TextLabel color='#DB2D1E'>{t('package')}</TextLabel>
        <TextLabel color='#DB2D1E'>
          -
          {utils.formatNumber(
            +(packageDiscount || 0) + +(splitPackageDiscount || 0),
            2
          )}
        </TextLabel>
      </FlexBox>
      <FlexBox justifyContent='space-between' padding='0 0.5rem'>
        <TextLabel color='#32D582'>{t('charge')}</TextLabel>
        <TextLabel color='#32D582'>
          {utils.formatNumber(
            _payChannels.reduce((acc, info) => {
              const { payChannelTypeId, charge } = info
              if (payChannelTypeId === 2 || payChannelTypeId === 3) {
                if (charge) {
                  acc += charge
                }
              }
              return acc
            }, 0) + (charge || 0),
            2
          )}
        </TextLabel>
      </FlexBox>
      {!buyPackage && (
        <FlexBox justifyContent='space-between' padding='0 0.5rem'>
          <TextLabel color='#32D582'>{t(`therapist'sTip`)}</TextLabel>
          <TextLabel color='#32D582'>
            {utils.formatNumber(totalTip || 0, 2)}
          </TextLabel>
        </FlexBox>
      )}
      <FlexBox justifyContent='space-between' padding='0 0.5rem'>
        <TextLabel color='#8C56D9' fontWeight='500'>
          {t('grandTotal')}
        </TextLabel>
        <TextLabel color='#8C56D9' fontWeight='500'>
          {utils.formatNumber(
            +total -
              (+discount || 0) -
              (+serviceDiscount || 0) -
              (+packageDiscount || 0) -
              (+splitPackageDiscount || 0) -
              (+commission || 0) +
              _payChannels.reduce((acc, info) => {
                const { payChannelTypeId, charge } = info
                if (payChannelTypeId === 2 || payChannelTypeId === 3) {
                  if (charge) {
                    acc += charge
                  }
                }
                return acc
              }, 0) +
              (charge || 0) +
              (totalTip || 0),
            2
          )}
        </TextLabel>
      </FlexBox>
      {!!_payChannels.length && (
        <>
          {_payChannels.map(({ price, payChannelTypeId, charge }, index) => (
            <FlexBox
              key={index}
              justifyContent='space-between'
              padding='0 0.5rem'
            >
              <TextLabel color='#D95656' fontWeight='500'>
                {`${t('installmentNo.')} ${index + 1} (${
                  config.PAY_TYPES[payChannelTypeId - 1]
                })`}
              </TextLabel>
              <TextLabel color='#D95656' fontWeight='500'>
                {utils.formatNumber(+price + (+charge || +0), 2)}
              </TextLabel>
            </FlexBox>
          ))}
        </>
      )}
      <FlexBox padding='0.5rem 0'>
        <Line />
      </FlexBox>
      <FlexBox justifyContent='space-between' width='100%' padding='0 0.5rem'>
        <TextLabel fontWeight='500' fontSize='1.25rem'>
          {t('remainingAmountUnpaid')}
        </TextLabel>
        <TextLabel color='#DB2D1E' fontWeight='500' fontSize='1.25rem'>
          {utils.formatNumber(
            +total -
              (+discount || 0) -
              (+serviceDiscount || 0) -
              (+packageDiscount || 0) -
              (+commission || 0) +
              (+charge || 0) +
              (+totalTip || 0) -
              (+totalSplitPayment || 0),
            2
          )}
        </TextLabel>
      </FlexBox>
    </Container>
  )
}

export default CardSummary
