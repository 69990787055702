import React from 'react'

import SettingHeader from '../../molecules/SettingHeader'

const Head = ({ activeMenu, onAddData, onAddGroupData }) => {
  const menus = [
    {
      name: 'เตียง',
      onClick: onAddData,
    },
    {
      name: 'ห้องนวด',
      onClick: onAddGroupData,
    },
  ]

  return <SettingHeader name='ห้องนวด' menuName='สร้างห้อง' menus={menus} />
}

export default Head
