import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import styled from 'styled-components'
import Button2 from '../../atoms/Button2'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import UploadImageIcon from '../../atoms/UploadImageIcon'
import TextLabel from '../../atoms/TextLabel'
import auth from '../../auth'
import config from '../../config'
import PopupChangePassUser from './PopupChangePassUser'
import PopupPin from './PopupPin'
import api from '../../api'
import utils from '../../utils'

import { LOGIN_URL } from '../../routes'

const CloseIcon = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 15px;
  width: 25px;
  height: 25px;
  z-index: 1000;
`
const Box = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
`
const BoxInside = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  flex: ${props => props.flex || '1 1 50%'};
  max-width: ${props => props.width || '50%'};
  min-width: ${props => props.width || '50%'};
  align-items: ${props => props.alignItem || 'none'};
`

const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  bottom: -5px;
  right: -5px;
`
const ImageHeader = styled.img`
  position: absolute;
  border-radius: 50%;
  bottom: 0.5px;
  width: 90px;
  height: 90px;
  z-index: 1;
  background: gray;
`
const Input = styled.input`
  background: #ece9f4;
  outline: none;
  width: 90%;
  border-radius: 5px;
  border: none;
  padding-left: 0.5rem;
  height: 2rem;
`
const BoxText = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  height: 2.5rem;
  background: white;
  border-radius: 5px;
  border: 1px solid #ababad;
  padding-left: 0.5rem;
`
const ConnectLineBox = styled.div`
  background-color: #f4f6fa;
  border-radius: 5px;
  width: 90%;
  /* position: relative; */
`
const PasswordLineBox = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b548;
  color: white;
  border-radius: 5px;
  width: 100%;
  height: 30%;
  text-align: center;
  border: none;
  padding: 0.5rem 0;
`

const PopupUser = ({ handleClose, left, user: _user }) => {
  const history = useHistory()
  const [user, setUser] = useState({})
  const [isOpenPin, setIsOpenPin] = useState(false)
  const [isOpenPass, setIsOpenPass] = useState(false)
  const [images, setImages] = useState([])
  const [fileImage, setFileImage] = useState('')

  useEffect(() => {
    setUser(_user)
    setImages(_user.image)
  }, [_user])

  const onFirstNameChange = val => {
    setUser({ ...user, firstName: val.target.value })
  }

  const onLastNameChange = val => {
    setUser({ ...user, lastName: val.target.value })
  }

  const onEmailChange = val => {
    setUser({ ...user, email: val.target.value })
  }

  const onPhonNumberChange = val => {
    setUser({ ...user, phone: val.target.value })
  }

  const onChangeImage = async event => {
    const file = event.target.files[0]
    setFileImage(file)
    const image = await utils.readImage(file)
    setImages(image.image)
    // const { data } = await api.uploadImageUser({
    //   token: auth.getToken(),
    //   image: file,
    // })
  }

  const onLogoutClick = () => {
    auth.logout()
    return history.replace(LOGIN_URL)
  }

  const onChangePinClick = () => {
    setIsOpenPin(!isOpenPin)
  }

  const onChangePassClick = () => {
    setIsOpenPass(!isOpenPass)
  }

  const onSaveClick = async () => {
    try {
      let update = { ...user }
      if (fileImage) {
        const { data } = await api.uploadImageUser({
          token: auth.getToken(),
          image: fileImage,
        })
        update = { ...user, image: data }
      }
      const { success } = await api.updateUser(user._id, user.storeId, update)
      if (success) {
        handleClose()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <PopupBox width='100%' left={left} height='100vh' top='0'>
      <PopupInsideBox width='40%' margin='auto'>
        <CloseIcon>
          <img
            src='images/btn_close_popup.png'
            style={{ width: '30px' }}
            alt='close-button'
            onClick={handleClose}
          />
        </CloseIcon>

        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '1rem 1rem',
            flexDirection: 'column',
          }}
        >
          <Box>
            <BoxInside alignItem='center'>
              <div
                style={{
                  position: 'relative',
                  borderRadius: '50%',
                  width: '90px',
                  height: '90px',
                  background: 'gray',
                }}
              >
                <ImageUploadIcon>
                  <label for='upload-image'>
                    <UploadImageIcon size='35' />
                  </label>
                  <input
                    id='upload-image'
                    type='file'
                    onChange={onChangeImage}
                    style={{
                      display: 'none',
                      visibility: 'none',
                    }}
                  />
                </ImageUploadIcon>

                {images.length !== 0 && <ImageHeader src={images} />}
              </div>
            </BoxInside>
            <BoxInside>
              <TextLabel fontWeight='bold'>User id</TextLabel>
              <Input value={user.userId} />
            </BoxInside>
          </Box>
          <Box>
            <BoxInside>
              <TextLabel fontWeight='bold'>ชื่อจริง*</TextLabel>
              <Input
                value={user.firstName}
                onChange={onFirstNameChange.bind(this)}
              />
            </BoxInside>
            <BoxInside>
              <TextLabel fontWeight='bold'>นามสกุล*</TextLabel>
              <Input
                value={user.lastName}
                onChange={onLastNameChange.bind(this)}
              />
            </BoxInside>
          </Box>
          <Box>
            <BoxInside>
              <TextLabel fontWeight='bold'>อีเมล*</TextLabel>
              <Input
                type='email'
                value={user.email}
                onChange={onEmailChange.bind(this)}
              />
            </BoxInside>
            <BoxInside>
              <TextLabel fontWeight='bold'>เบอร์โทร*</TextLabel>
              <Input
                value={user.phoneNumber}
                onChange={onPhonNumberChange.bind(this)}
              />
            </BoxInside>
          </Box>
          <Box>
            <BoxInside>
              <TextLabel fontWeight='bold'>สิทธิ์การเข้าใช้</TextLabel>
              <BoxText>{config.USER_TYPES[user.typeId]}</BoxText>
            </BoxInside>
            <BoxInside>
              <TextLabel fontWeight='bold'>ภาษา</TextLabel>
              <BoxText>ไทย</BoxText>
            </BoxInside>
          </Box>
          <div className='mt-4'>
            <Button2
              width='95%'
              backgroundColor='#C6CED5'
              fontWeight='bold'
              onClick={onLogoutClick.bind(this)}
            >
              ออกจากระบบ
            </Button2>
            <Button2
              width='95%'
              backgroundColor='#C6CED5'
              fontWeight='bold'
              onClick={onChangePinClick.bind(this)}
            >
              เปลี่ยนรหัส Pin ของท่าน
            </Button2>
            <Button2
              width='95%'
              backgroundColor='#C6CED5'
              fontWeight='bold'
              onClick={onChangePassClick.bind(this)}
            >
              เปลี่ยนรหัสผ่าน ของท่าน
            </Button2>
          </div>
          <div
            className='row mt-3'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <TextLabel>เชื่อมต่อการแจ้งเตือนกับ Line</TextLabel>
          </div>
          <div
            className='row mt-3'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <ConnectLineBox>
              <div>
                <CopyToClipboard text={user.lineUserId}>
                  <PasswordLineBox>
                    รหัสเชื่อมต่อกับ line
                    <div
                      className='ml-1 mr-1 pl-1 pr-1'
                      style={{
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        color: '#00B548',
                      }}
                    >
                      {` ${user.lineUserId} `}
                    </div>
                    คัดลอก
                  </PasswordLineBox>
                </CopyToClipboard>
              </div>
              <div>
                <div
                  className='mt-1'
                  style={{ textAlign: 'center', color: '#00B548' }}
                >
                  วิธีเชื่อมต่อกับ Line
                </div>
                <div className='row ml-3'>
                  <div className='col-xl-2 col-lg-2 col-md-2 pl-0 pb-2'>
                    <img
                      src='/images/AddLineIcon1.svg'
                      style={{ width: '50px', height: '50px' }}
                      alt='add-line-icon'
                    />
                  </div>
                  <div className='col-xl-3 col-lg-3 col-md-3 pl-0 pr-0'>
                    1. Add Line :@posstaff
                  </div>
                  <div
                    className='col-xl-1 col-lg-1 col-md-1 pl-0 pr-0'
                    style={{ borderLeft: '2px solid #00B548', height: '50px' }}
                  ></div>
                  <div className='col-xl-2 col-lg-2 col-md-2 pl-0 pb-2'>
                    <img
                      src='/images/AddLineIcon2.svg'
                      style={{ width: '50px', height: '50px' }}
                      alt='add-line-icon-2'
                    />
                  </div>
                  <div className='col-xl-4 col-lg-4 col-md-4 pl-0'>
                    2. พิมพ์รหัสเชื่อม ลงกล่องข้อความ
                  </div>
                </div>
              </div>
            </ConnectLineBox>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              color: '#00B548',
            }}
          >
            หรือ
          </div>
          <Button2>แสกน QR code ประจำตัวพนักงาน</Button2>
          <Box>
            <BoxInside flex='1 1 35%' width='35%'>
              <Button2
                margin='0.125rem 0.5rem 0.125rem 0'
                onClick={() => handleClose()}
              >
                ยกเลิก
              </Button2>
            </BoxInside>
            <BoxInside flex='1 1 63%' width='63%'>
              <Button2
                margin='0.125rem 0 0.125rem 0.5rem'
                onClick={onSaveClick.bind(this)}
              >
                บันทึก
              </Button2>
            </BoxInside>
          </Box>
        </div>
      </PopupInsideBox>
      {isOpenPass && (
        <PopupChangePassUser handleClose={onChangePassClick} user={user} />
      )}
      {isOpenPin && (
        <PopupPin
          handleClose={onChangePinClick}
          left='0px'
          firstName={user.firstName}
          phoneNumber={user.phoneNumber}
          id={user._id}
          image={user.image}
        />
      )}
    </PopupBox>
  )
}

export default PopupUser
