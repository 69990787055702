import React from 'react'

interface TimeIcon2Props {
  size?: string | number
  color?: string
}

const TimeIcon2 = ({ size, color }: TimeIcon2Props) => (
  <svg
    width={size || '24'}
    height={size || '24'}
    viewBox='0 0 24 24'
    fill={color || '#8C56D9'}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0.893073 11.1684H1.11307C1.55641 11.1684 1.92641 10.8417 1.99307 10.4017L1.99974 10.3617C2.07307 9.90171 1.76641 9.46504 1.30974 9.36504L1.09974 9.31837C0.606407 9.20837 0.10974 9.53171 0.02974 10.0317C0.0230733 10.075 0.0164066 10.115 0.00973996 10.1584C-0.07026 10.6917 0.353073 11.1684 0.893073 11.1684Z' />
    <path d='M1.97466 7.46212L2.19466 7.51212C2.57799 7.59545 2.96799 7.41878 3.16132 7.07878C3.18799 7.03212 3.21466 6.98878 3.24132 6.94212C3.47799 6.54212 3.34799 6.02545 2.96132 5.76545L2.78132 5.64545C2.36132 5.36212 1.78132 5.48212 1.52466 5.91878C1.47799 5.99878 1.43132 6.07878 1.38799 6.15878C1.09466 6.67878 1.39132 7.33545 1.97466 7.46545V7.46212Z' />
    <path d='M7.53392 2.16886C7.75392 2.53219 8.19726 2.68552 8.60059 2.54552C8.65059 2.52886 8.69726 2.51219 8.74726 2.49552C9.11726 2.37552 9.37059 2.03552 9.37059 1.64886V1.42219C9.37059 0.815524 8.77726 0.388857 8.19726 0.575524C8.10059 0.605524 8.00392 0.638857 7.90726 0.672191C7.36726 0.862191 7.13059 1.48886 7.42392 1.97886L7.53726 2.16552L7.53392 2.16886Z' />
    <path d='M1.35256 14.9474C1.82923 14.8541 2.14923 14.3941 2.05589 13.9141V13.8974C1.96923 13.4741 1.60923 13.1641 1.17589 13.1641H0.952561C0.389228 13.1641 -0.0274386 13.6807 0.0792281 14.2341L0.0892281 14.2941C0.182561 14.7741 0.655895 15.0807 1.13589 14.9874L1.35256 14.9441V14.9474Z' />
    <path d='M4.24234 4.2195L4.43234 4.34616C4.75901 4.56616 5.18901 4.54616 5.49234 4.29616C5.51234 4.2795 5.53567 4.2595 5.55567 4.24283C5.89901 3.96283 5.98567 3.47616 5.75901 3.09616L5.64567 2.90616C5.36567 2.4395 4.73567 2.33616 4.31234 2.6795C4.26567 2.71616 4.21901 2.75616 4.17234 2.79283C3.71567 3.17283 3.75234 3.88616 4.24567 4.2195H4.24234Z' />
    <path d='M11.8644 0C11.7844 0 11.7077 0 11.6277 0C11.5377 0 11.451 0.00666667 11.3644 0.0133333V2.01667C11.4644 2.01333 11.5644 2.00667 11.6677 2.00333C11.7344 2.00333 11.7977 2.00333 11.8644 2.00333C17.3777 2.00333 21.8644 6.49 21.8644 12.0033C21.8644 17.5167 17.3777 22.0033 11.8644 22.0033C8.03104 22.0033 4.69771 19.8367 3.02104 16.6633L0.984375 17.06C2.89438 21.16 7.04771 24.0033 11.8677 24.0033C18.4944 24.0033 23.8677 18.63 23.8677 12.0033C23.8677 5.37667 18.491 0 11.8644 0Z' />
    <path d='M5.22575 17.0893C5.42242 17.3593 5.72575 17.5026 6.03575 17.5026C6.23909 17.5026 6.44242 17.4426 6.61909 17.3126L12.6191 12.9793C12.8791 12.7926 13.0324 12.4893 13.0324 12.1693V5.83594C13.0324 5.2826 12.5858 4.83594 12.0324 4.83594C11.4791 4.83594 11.0324 5.2826 11.0324 5.83594V11.6593L5.44575 15.6926C4.99909 16.0159 4.89575 16.6426 5.22242 17.0893H5.22575Z' />
  </svg>
)

export default TimeIcon2
