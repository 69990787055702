import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Button2 from '../../atoms/Button2'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'
import ClosePopup from '../../atoms/ClosePopup'
import SaveIcon from '../../atoms/SaveIcon'
import InputText from '../../atoms/InputText'
import utils from '../../utils'

const Icon = styled.div`
  position: absolute;
  right: 1rem;
  transform: translateY(-50%);
  top: 50%;
`
const BoxText = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const PopupCashier = ({
  handleClose,
  openCashier = false,
  cashierData = '',
  onSave,
  loading,
}) => {
  const { t } = useTranslation()

  const [totalExpectedCash, setTotalExpectedCash] = useState(0)
  const [totalWithdrawOrDepositCash, setTotalWithdrawOrDepositCash] =
    useState(0)
  const [checkSave, setCheckSave] = useState(false)

  useEffect(() => {
    if (cashierData) {
      if (openCashier) {
        setTotalExpectedCash(cashierData.total)
      } else {
        setTotalExpectedCash(cashierData.cashEnd)
      }
    }
  }, [cashierData])

  const onTotalChange = value => {
    if (utils.checkNumber(value)) {
      setTotalExpectedCash(value)
    } else if (!value) {
      setTotalExpectedCash(value || 0)
    }
  }

  const onTotalWithdrawOrDepositCashChange = value => {
    if (utils.checkNumber(value)) {
      setTotalWithdrawOrDepositCash(value)
    } else if (!value) {
      setTotalWithdrawOrDepositCash(value || 0)
    }
  }

  const onCancelClick = () => {
    if (!checkSave) {
      handleClose()
    } else {
      setCheckSave(!checkSave)
    }
  }

  const onSaveClick = () => {
    let totalActualCash = 0
    if (openCashier) {
      totalActualCash =
        parseInt(totalExpectedCash || 0) -
        parseInt(totalWithdrawOrDepositCash || 0)
    } else {
      totalActualCash = totalActualCash =
        parseInt(totalExpectedCash || 0) +
        parseInt(totalWithdrawOrDepositCash || 0)
    }
    if (!checkSave) {
      setCheckSave(!checkSave)
    } else {
      onSave('cashier', totalActualCash || 0)
    }
  }

  return (
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='40%'
        height='auto'
        padding='1rem 1rem 2rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
        alignItems='flex-start'
        borderRadius='8px'
      >
        <div
          className='mr-2'
          style={{ marginLeft: 'auto' }}
          onClick={handleClose}
        >
          <ClosePopup />
        </div>

        <div style={{ margin: '0 auto' }}>
          <TextLabel color='#8C56D9' fontWeight='600' fontSize='1.2rem'>
            {openCashier ? t('closeCashierShift') : t('openCashierShift')}
          </TextLabel>
        </div>

        {!checkSave ? (
          <>
            <div className='mt-1 mb-2'>
              <TextLabel>{t('cashierBalance')}</TextLabel>
            </div>
            <div
              className='mb-3'
              style={{ width: '100%', position: 'relative' }}
            >
              <InputText
                background='#F8F9FB'
                fontSize='1.5rem'
                fontWeight='500'
                padding='0.5rem 1rem'
                color='#8C56D9'
                value={totalExpectedCash === 0 ? '' : totalExpectedCash}
                placeholder='0'
                onChange={val => onTotalChange(val.target.value)}
              />
              <Icon>บาท</Icon>
            </div>

            <div className='mb-2'>
              <TextLabel>
                {openCashier
                  ? t('moneyTakenOutOffCashier')
                  : t('addMoneyToCashierAmounted')}
              </TextLabel>
            </div>
            <div
              className='mb-3'
              style={{ width: '100%', position: 'relative' }}
            >
              <InputText
                background='#F8F9FB'
                fontSize='1.5rem'
                fontWeight='500'
                padding='0.5rem 1rem'
                color='#8C56D9'
                value={
                  totalWithdrawOrDepositCash === 0
                    ? ''
                    : totalWithdrawOrDepositCash
                }
                placeholder='0'
                onChange={val =>
                  onTotalWithdrawOrDepositCashChange(val.target.value)
                }
              />
              <Icon>บาท</Icon>
            </div>
          </>
        ) : (
          <>
            <BoxText style={{ marginTop: '1rem' }}>
              <TextLabel color='#98A1B2'>{t('cashierBalance')}</TextLabel>
              <TextLabel
                fontWeight='600'
                color='#32D582'
              >{`${utils.formatNumber(totalExpectedCash || 0)} บาท`}</TextLabel>
            </BoxText>
            <BoxText style={{ marginTop: '0.8rem' }}>
              <TextLabel color='#98A1B2'>
                {openCashier
                  ? t('moneyTakenOutOffCashier')
                  : t('addMoneyToCashierAmounted')}
              </TextLabel>
              <TextLabel
                fontWeight='600'
                color={openCashier ? '#DB2D1E' : '#32D582'}
              >{`${openCashier ? '-' : ''} ${utils.formatNumber(
                totalWithdrawOrDepositCash || 0
              )} บาท`}</TextLabel>
            </BoxText>
            <div
              className='mb-3'
              style={{
                width: '100%',
                borderTop: '1px solid #E4E8EB',
                marginTop: '1rem',
                paddingTop: '1rem',
              }}
            >
              <BoxText>
                <TextLabel fontWeight='600'>{t('totalCashSummary')}</TextLabel>
                <TextLabel fontWeight='600'>{`${
                  openCashier
                    ? utils.formatNumber(
                        +(totalExpectedCash || 0) -
                          +(totalWithdrawOrDepositCash || 0)
                      ) || 0
                    : utils.formatNumber(
                        +(totalExpectedCash || 0) +
                          +(totalWithdrawOrDepositCash || 0)
                      ) || 0
                } บาท`}</TextLabel>
              </BoxText>
            </div>
          </>
        )}

        <div className='mt-3' style={{ width: '100%', display: 'flex' }}>
          <div style={{ display: 'flex', width: '50%' }}>
            <Button2
              backgroundColor='#FFFFFF'
              backgroundColorHover='#E5E7EC'
              border='2px solid #98A1B2'
              color='#475467'
              fontWeight='600'
              margin='0.125rem 0.25rem 0.125rem 0'
              onClick={() => onCancelClick()}
            >
              {checkSave ? t('edit') : t('cancel')}
            </Button2>
          </div>
          <div style={{ display: 'flex', width: '50%' }}>
            <Button2
              border={
                loading
                  ? '2px solid gray'
                  : checkSave
                  ? '2px solid #32D582'
                  : '2px solid #53389E'
              }
              color='white'
              backgroundColor={
                loading
                  ? 'gray'
                  : checkSave
                  ? '#32D582'
                  : 'linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
              }
              backgroundColorHover={
                loading
                  ? 'gray'
                  : checkSave
                  ? '#52DB95'
                  : 'linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
              }
              fontWeight='600'
              margin='0.125rem 0 0.125rem 0.25rem'
              icon={<SaveIcon size='18' color='white' />}
              disable={loading}
              onClick={() => onSaveClick()}
            >
              {checkSave
                ? openCashier
                  ? t('confirmClosingCashierShift')
                  : t('confirmOpeningCashierShift')
                : t('save')}
            </Button2>
          </div>
        </div>
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupCashier
