import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

import CustomerData from './CustomerData'
import CustomerHistory from './CustomerHistory'
import CustomerRecord from './CustomerRecord'

//@ts-ignore
import LongArrow from '../../atoms/LongArrow'
import TextLabel from '../../atoms/TextLabel'

import { BookingHistory } from '../../redux/models/BookingHistory'
import { Customer } from '../../redux/models/Customer'
import CustomerPackage from '../../redux/models/CustomerPackage'

interface CustomerDetailStyle extends CSSProperties {}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
`
const Box = styled.div<CustomerDetailStyle>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const Circle = styled.div`
  border-radius: 50%;
  background-color: #98a1b2;
  width: 4px;
  height: 4px;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 0.5rem;
`

interface CustomerDetailProps {
  customer: Customer
  customerBookingHistories: BookingHistory[]
  customerPackages: CustomerPackage[]
  onGoBackClick: () => void
  onEditCustomer: () => void
  updateCustomerPackage: (customerId: number) => void
}

const CustomerDetail = ({
  customer,
  customerBookingHistories,
  customerPackages,
  onGoBackClick,
  onEditCustomer,
  updateCustomerPackage,
}: CustomerDetailProps) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Box>
        <HeaderContainer>
          <TextLabel color='#98A1B2' fontWeight='500'>
            {t('customer')}
          </TextLabel>
          &nbsp;<Circle></Circle>&nbsp;
          <TextLabel color='#475467' fontWeight='500'>
            {t('customerInformation')}
          </TextLabel>
        </HeaderContainer>
        <Box flexDirection='row' alignItems='center'>
          <LongArrow
            size='9px'
            border='3px solid #000'
            onClick={() => onGoBackClick()}
          />
          <TextLabel paddingLeft='1rem' fontWeight='600' fontSize='1.875rem'>
            {t('customerInformation')}
          </TextLabel>
        </Box>
      </Box>
      <GridBox>
        <CustomerData customer={customer} onEditCustomer={onEditCustomer} />
        <CustomerRecord
          customerBookingHistories={customerBookingHistories}
          customer={customer}
          customerPackages={customerPackages}
        />
      </GridBox>
      <CustomerHistory
        customerBookingHistories={customerBookingHistories}
        customer={customer}
        customerPackages={customerPackages}
        updateCustomerPackage={updateCustomerPackage}
      />
    </Container>
  )
}

export default CustomerDetail
