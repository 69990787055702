import React from 'react'

import SettingHeader from '../../molecules/SettingHeader'

export interface HeadProps {
  onCreateClick: () => void
}

const Head = ({ onCreateClick }: HeadProps) => {
  return (
    <SettingHeader
      name='แพ็คเกจ'
      menuName='สร้างแพ็คเกจ'
      onClick={onCreateClick}
    />
  )
}

export default Head
