import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import api from '../../api'
import Button2 from '../../atoms/Button2'
import EditIcon from '../../lib/icons/EditIcon'
import SaveIcon from '../../atoms/SaveIcon'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import OpenNorMal from './OpenNormal'
import ButtonSave from '../../atoms/ButtonSave'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100vh;
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 1rem;
  flex: 1;
  overflow: hidden;
`

const CardStyle = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const Left = styled.div`
  display: flex;
  flex: 1 0 50%;
  max-width: 50%;
  min-width: 50%;
`

const Right = styled.div`
  display: flex;
  flex: 1 0 50%;
  max-width: 50%;
  min-width: 50%;
`

const DataTimeOpenClose = () => {
  const [edit, setEdit] = useState(false)
  const [dataDateTime, setDataDateTime] = useState([])
  const [sameTime, setSameTime] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    callApiStoreDateTime()
  }, [])

  const callApiStoreDateTime = async () => {
    try {
      const { data } = await api.getStoreDateTime()

      setDataDateTime(data)
      setDataDateTime(data)
      setSameTime(data[0].sameTime)
    } catch (err) {
      console.log(err)
    }
  }

  const onSameTimeChange = () => {
    setSameTime(!sameTime)
    const newArrDateTime = [...dataDateTime]
    for (let i = 0; i < newArrDateTime.length; i++) {
      newArrDateTime[i] = { ...newArrDateTime[i], sameTime: !sameTime }
    }
    setDataDateTime(newArrDateTime)
  }

  const onRestDayChange = newArrDateTime => {
    setDataDateTime(newArrDateTime)
  }

  const onTimeChange = (hour, minute, activeType, activeIndex) => {
    const newArrDateTime = [...dataDateTime]
    if (activeType === 'open') {
      if (activeIndex === -1) {
        newArrDateTime[0] = {
          ...newArrDateTime[0],
          openTime: { hour: hour, minute: minute },
        }
      } else {
        newArrDateTime[activeIndex] = {
          ...newArrDateTime[activeIndex],
          openTime: { hour: hour, minute: minute },
        }
      }
    } else if (activeType === 'close') {
      if (activeIndex === -1) {
        newArrDateTime[0] = {
          ...newArrDateTime[0],
          closeTime: { hour: hour, minute: minute },
        }
      } else {
        newArrDateTime[activeIndex] = {
          ...newArrDateTime[activeIndex],
          closeTime: { hour: hour, minute: minute },
        }
      }
    }
    setDataDateTime(newArrDateTime)
  }

  const onCancelClick = async () => {
    setEdit(false)
    await callApiStoreDateTime()
  }

  const onSaveClick = async () => {
    try {
      const update = [...dataDateTime]
      if (sameTime) {
        for (let i = 0; i < update.length; i++) {
          update[i] = {
            ...update[i],
            openTime: update[0].openTime,
            closeTime: update[0].closeTime,
          }
        }
      }
      const { success } = await api.updateAllStoreTime(update)

      if (success) {
        setSuccess(true)
        setEdit(false)
        setTimeout(async () => {
          setSuccess(false)
          await callApiStoreDateTime()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        <Scrollbar id='setting-storeDateTime'>
          <CardStyle>
            <Left>
              <OpenNorMal
                dataDateTime={dataDateTime}
                edit={edit}
                onTimeChange={onTimeChange}
                sameTime={sameTime}
                onSameTimeChange={onSameTimeChange}
                success={success}
                onRestDayChange={onRestDayChange}
              />
            </Left>
            <div style={{ borderRight: '1px solid #E4E8EB' }}></div>
            <Right>{/* 123 */}</Right>
          </CardStyle>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0',
              zIndex: '2',
              background: '#FFFFFF',
            }}
          >
            {!edit ? (
              <Button2
                backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                color='#FFFFFF'
                icon={<EditIcon color='#FFFFFF' size='15' />}
                onClick={() => setEdit(true)}
              >
                แก้ไขวันและเวลา เปิด/ปิด ร้านค้า
              </Button2>
            ) : (
              <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
                <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                  <Button2
                    color='#98A1B2'
                    border='2px solid #98A1B2'
                    backgroundColor='white'
                    backgroundColorHover='#E5E7EC'
                    fontWeight='600'
                    onClick={() => onCancelClick()}
                  >
                    ยกเลิก
                  </Button2>
                </div>
                <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                  <ButtonSave
                    border='2px solid #53389E'
                    color='#FFFFFF'
                    backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                    backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                    fontWeight='600'
                    icon={<SaveIcon size='15' />}
                    onClick={() => onSaveClick()}
                  >
                    บันทึก
                  </ButtonSave>
                </div>
              </div>
            )}
          </div>
          {success && (
            <SuccessIcon2 position='absolute' height='calc(100% - 2rem)' />
          )}
        </Scrollbar>
      </Card>
    </Container>
  )
}

export default DataTimeOpenClose
