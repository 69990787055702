import React from 'react'

import { IconProps } from './IconPropsType'

const EditIcon = ({ size, color }: IconProps) => (
  <svg
    width={size || '12'}
    height={size || '13'}
    viewBox='0 0 12 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2888_2182)'>
      <path
        d='M11.7581 10.7515H6.12573V12.0031H11.7581V10.7515Z'
        fill={color || 'black'}
      />
      <path
        d='M9.61454 0.55064C9.14518 0.175151 8.59759 0.00305176 8.05 0.00305176C7.33031 0.00305176 6.61063 0.315959 6.10998 0.910483L0.352479 7.70058C0.149089 7.9509 0.0239258 8.26381 0.0239258 8.59236V10.2821C0.0239258 11.0487 0.649741 11.6745 1.41636 11.6745H3.12171C3.54413 11.6745 3.93527 11.4868 4.20124 11.1582L9.99003 4.08649C10.8818 3.02261 10.7097 1.42678 9.61454 0.55064ZM3.23123 10.3759C3.19994 10.4072 3.16865 10.4229 3.12171 10.4229H1.41636C1.33814 10.4229 1.27556 10.3603 1.27556 10.2821V8.59236C1.27556 8.56107 1.2912 8.52978 1.30685 8.49849L5.32771 3.77359L7.20515 5.52587L3.23123 10.3759ZM9.02001 3.30423L8.00307 4.55586L6.14127 2.81922L7.07999 1.72404C7.31467 1.42678 7.67451 1.25468 8.05 1.25468C8.25339 1.25468 8.55065 1.30162 8.83227 1.5363C9.09824 1.75533 9.2547 2.0526 9.30163 2.38115C9.34857 2.7097 9.23905 3.0539 9.02001 3.30423Z'
        fill={color || 'black'}
      />
    </g>
    <defs>
      <clipPath id='clip0_2888_2182'>
        <rect
          width='11.734'
          height='12'
          fill='white'
          transform='translate(0.0239258 0.00305176)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default EditIcon
