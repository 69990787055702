import {
  GET_FILTER,
  UPDATE_FILTER,
  CLEAR_FILTER,
  GET_SEARCH,
  GET_FILTER_DATE,
  GET_FILTER_STATUS,
  GET_FILTER_TYPE_BOOK,
  GET_FILTER_PAY,
  GET_FILTER_SERVICE,
  GET_FILTER_STAFF,
  GET_IS_OPEN_PAY,
} from '../actions/filterAction'

const initState = {
  date: [],
  status: [],
  typeBook: [],
  pay: { status: [], chanel: [] },
  service: [],
  staff: [],
  search: '',
  isOpenPay: false,
}

const filterReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_FILTER:
      return {
        ...state,
        ...action.payload.filters,
      }
    case GET_SEARCH:
      return {
        ...state,
        search: action.payload.search,
      }
    case GET_FILTER_DATE:
      return {
        ...state,
        date: action.payload.date,
      }
    case GET_FILTER_STATUS:
      return {
        ...state,
        status: action.payload.status,
      }
    case GET_FILTER_TYPE_BOOK:
      return {
        ...state,
        typeBook: action.payload.typeBook,
      }
    case GET_FILTER_PAY:
      return {
        ...state,
        pay: action.payload.pay,
      }
    case GET_FILTER_SERVICE:
      return {
        ...state,
        service: action.payload.service,
      }
    case GET_FILTER_STAFF:
      return {
        ...state,
        staff: action.payload.staff,
      }
    case GET_IS_OPEN_PAY:
      return {
        ...state,
        isOpenPay: action.payload.isOpenPay,
      }
    case UPDATE_FILTER:
      return {
        ...state,
        ...action.payload.filters,
      }
    case CLEAR_FILTER:
      return {
        ...initState,
      }
    default:
      return state
  }
}

export default filterReducers
