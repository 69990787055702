import { Dispatch } from 'redux'

import { StoreState } from '../models/Store'

//@ts-ignore
import api from '../../api'

export const GET_STORE = 'GET_STORE'

export const getStore = (data: Partial<StoreState>) => {
  return {
    type: GET_STORE,
    payload: { data },
  }
}

export const fetchStore =
  (storeId: number | undefined) =>
  async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      if (!storeId) return
      const storeResponse = await api.getStore(storeId)

      dispatch(
        getStore({
          ...storeResponse.data,
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

export const fetchNotification =
  () => async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      const { data: notificationsNRResponse } =
        await api.getNotificationByStatus(1)
      const { data: notificationsRResponse } =
        await api.getNotificationByStatus(2)

      const notificationsResponse = notificationsNRResponse.concat(
        notificationsRResponse
      )

      dispatch(
        getStore({
          notifications: notificationsResponse,
          notificationCount: notificationsResponse.length,
        })
      )
    } catch (err) {
      console.log(err)
    }
  }
