import React from 'react'

import CardAddRoom from './'

//@ts-ignore
import PopupBox from '../../../atoms/Popup'
//@ts-ignore
import PopupInsideBox from '../../../atoms/PopupInside'

import Room from '../../../redux/models/Room'
import RoomType from '../../../redux/models/RoomType'

interface PopupAddRoomTypeProps {
  handleClose: () => void
  currentIdEdit: number
  currentDataEdit: Room
  roomTypes: RoomType[]
}

const PopupAddRoomType = ({
  handleClose,
  currentIdEdit,
  currentDataEdit,
  roomTypes,
}: PopupAddRoomTypeProps) => {
  return (
    <PopupBox width='100%' height='100%' top='0px' left='0px'>
      <PopupInsideBox
        width='50%'
        height='auto'
        padding='2rem 1rem 1rem 1rem'
        marginTop='0'
        boxShadow='0px 24px 40px rgba(107, 107, 107, 0.08)'
      >
        <CardAddRoom
          handleClose={handleClose}
          currentIdEdit={currentIdEdit}
          currentDataEdit={currentDataEdit}
          roomTypes={roomTypes}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddRoomType
