import React, { useState } from 'react'
import styled from 'styled-components'

import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const Box = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const BoxStyle = styled.div`
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  width: calc(50% - 0.5rem);
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`

const Circle = styled.div`
  background: #98a1b2;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`

const BoxEdit = styled.div`
  display: flex;
  cursor: ${props => props.cursor || 'none'};
  position: relative;
`

const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.16);
  border-radius: 8px;
  padding: 0.5rem;
  top: 10px;
  right: 0px;
  z-index: 1;
`

const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`

const BodyOccupation = ({ occupations, onEditClick: _onEditClick }) => {
  const [activeEdit, setActiveEdit] = useState(-1)

  const onOpenEditClick = (e, _id) => {
    e.stopPropagation()
    if (_id === activeEdit) setActiveEdit(-1)
    else setActiveEdit(_id)
  }

  const onEditClick = (e, _id) => {
    _onEditClick(_id, 'occupation')
    setActiveEdit(-1)
  }

  return (
    <Container>
      <Scrollbar>
        <Box className='mt-3 mb-5'>
          {occupations.map(({ _id, occupation }, index) => (
            <BoxStyle
              className='mt-2'
              key={index}
              onClick={e => onEditClick(e, _id)}
            >
              {occupation}
              <BoxEdit onClick={e => onOpenEditClick(e, _id)}>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div className='mr-1' key={index}>
                    <Circle></Circle>
                  </div>
                ))}
                {activeEdit === _id && (
                  <DropDown>
                    <Option onClick={e => onEditClick(e, _id)}>แก้ไข</Option>
                    {/* <Option
                      // onClick={e => onDeleteClick(e, task._id)}
                      >
                        ลบ
                      </Option> */}
                  </DropDown>
                )}
              </BoxEdit>
            </BoxStyle>
          ))}
        </Box>
      </Scrollbar>
    </Container>
  )
}

export default BodyOccupation
