import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CustomerBookHistory from './CustomerBookHistory'
import CustomerPackageHistory from './CustomerPackageHistory'

//@ts-ignore
import Button3 from '../../atoms/Button3'
import TextLabel from '../../atoms/TextLabel'

import { BookingHistory } from '../../redux/models/BookingHistory'
import { Customer } from '../../redux/models/Customer'
import CustomerPackage from '../../redux/models/CustomerPackage'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

interface CustomerHistoryProps {
  customerBookingHistories: BookingHistory[]
  customer: Customer
  customerPackages: CustomerPackage[]
  updateCustomerPackage: (customerId: number) => void
}

const CustomerHistory = ({
  customerBookingHistories,
  customer,
  customerPackages,
  updateCustomerPackage,
}: CustomerHistoryProps) => {
  const { t } = useTranslation()

  const btnMenu = [t('reservation'), t('packageUsage')]
  const [activeBtnMenu, setActiveBtnMenu] = useState(t('reservation'))

  const BtnList = btnMenu.map((button, index) => (
    <div
      key={index}
      style={{ display: 'flex', marginRight: index !== 2 ? '0.5rem' : '0' }}
    >
      <Button3
        backgroundColor='white'
        backgroundColorActive='white'
        padding='0.5rem 0.5rem'
        size='0.9rem'
        border={activeBtnMenu === button ? '1px solid #8C56D9' : ''}
        active={activeBtnMenu === button}
        colorIcon='#8C56D9'
        colorActive='#8C56D9'
        color='#475467'
        onClick={() => setActiveBtnMenu(button)}
      >
        {button}
      </Button3>
    </div>
  ))

  return (
    <Container>
      <TextLabel fontWeight='500' fontSize='1.25rem'>
        {t('historyCustomer')}
      </TextLabel>
      <div style={{ display: 'flex' }}>{BtnList}</div>
      {activeBtnMenu === t('reservation') && (
        <CustomerBookHistory
          customerBookingHistories={customerBookingHistories}
          customer={customer}
        />
      )}
      {activeBtnMenu === t('packageUsage') && (
        <CustomerPackageHistory
          customerBookingHistories={customerBookingHistories}
          customer={customer}
          customerPackages={customerPackages}
          updateCustomerPackage={updateCustomerPackage}
        />
      )}
    </Container>
  )
}

export default CustomerHistory
