import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import dayjs from 'dayjs'

import PayIcon from '../../atoms/PayIcon'
import {
  clearOrderBooking,
  getOrderBooking,
} from '../../redux/actions/bookingHistoryAction'
import utils from '../../utils'
import Button3 from '../../atoms/Button3'
import Cash from './Cash'
import Button2 from '../../atoms/Button2'
import Credit from './Credit'
import Transfer from './Transfer'
import Online from './Online'
import api from '../../api'
import auth from '../../auth'
import PopupWaringCashier from '../popups/PopupWaringCashier'
import PopupBillPayMent from '../../molecules/Receipt/PopupBillPayMent'
import Agency from './Agency'
import PopupSelectPayChannel from '../popups/PopupSelectPayChannel'
import Package from './Package'
import CardSummary from './CardSummary'
import { clearPayPackage } from '../../redux/actions/payPackageActions'
import config from '../../config'
import { useTranslation } from 'react-i18next'
import { getPaymentChannelTypeName } from '../../redux/models/BookingHistory'
import { fetchNotification } from '../../redux/actions/storeAction'
import { PackageTypes } from '../../redux/models/BrandPackage'

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  overflow: auto;
`

const Line = styled.div`
  border-top: 1px solid #e5eced;
  width: 100%;
`

const BoxBtn = styled.div`
  display: flex;
`

const BoxBtnInside = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const Btn = styled.div`
  flex: ${props => props.flex || '1 0 20%'};
  max-width: ${props => props.width || '20%'};
  min-width: ${props => props.width || '20%'};
  padding: 0.15rem;
`

const RightSide = ({ bookingHistory: _bookingHistory }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [payChannel, setPayChannel] = useState({})
  const bookingOrderState = useSelector(state => state.bookingHistoryReducers)
  const {
    _id,
    total,
    discount,
    serviceDiscount,
    bookingCode,
    bookingType,
    charge,
    commission,
    totalTip,
    orders,
    payChannels: _payChannels,
    buyPackage,
    packages,
    instant,
    instantId,
  } = bookingOrderState || {}

  const payPackageState = useSelector(state => state.payPackageReducers)
  const {
    payCustomerPackages = [],
    splitPayCustomerPackages = [],
    packageDiscount = 0,
    splitPackageDiscount = 0,
  } = payPackageState || {}
  const [activeBtnPayChannelTypeIndex, setActiveBtnPayChannelTypeIndex] =
    useState(0)
  const [bookingHistory, setBookingHistory] = useState({})
  const [isOpenPopupWarning, setIsOpenPopupWarning] = useState(false)
  const [isOpenPopupDone, setIsOpenPopupDone] = useState(false)
  const [checkStaff, setCheckStaff] = useState(true)
  const [receiptNumber, setReceiptNumber] = useState('')
  const [openSplitPayment, setOpenSplitPayment] = useState(false)
  const [totalSplitPayment, setTotalSplitPayment] = useState(0)
  const [netPrice, setNetPrice] = useState(0)
  const [staffNotComing, setStaffNotComing] = useState([])
  const [customerPackages, setCustomerPackages] = useState([])

  const payChannelTypes = [
    {
      payChannelTypeId: 1,
      payChannelType: t('cash'),
    },
    {
      payChannelTypeId: 2,
      payChannelType: t('creditCard'),
    },
    {
      payChannelTypeId: 3,
      payChannelType: t('transfer'),
    },
    {
      payChannelTypeId: 4,
      payChannelType: t('eWallet'),
    },
    {
      payChannelTypeId: 5,
      payChannelType: t('agency'),
    },
    {
      payChannelTypeId: 6,
      payChannelType: t('package'),
    },
  ]

  useEffect(() => {
    const staffNotComing = []
    const checkStaff = orders.reduce((acc, info) => {
      const { services } = info
      for (const service of services) {
        const { staffId, workingStatus } = service
        if (staffId === -1) acc = false
        if (workingStatus === -1 && staffId !== -1) {
          staffNotComing.push({ _id: staffId, workingStatus: 1 })
        }
      }
      return acc
    }, true)
    setCheckStaff(checkStaff)
    setStaffNotComing(staffNotComing)
    callCustomerPackageByCustomerIds(orders)
  }, [])

  const callCustomerPackageByCustomerIds = async orders => {
    try {
      const { data } = await api.getCustomerPackageByCustomerIds(
        orders
          .map(({ customerId }) =>
            customerId && customerId !== -1 ? customerId : -1
          )
          .join(',')
      )

      let customerPackages = data.reduce((acc, info, index) => {
        if (info.length) {
          const findCustomer = orders.find(
            o => (o.customerId || -1) === info[0].customerId
          )

          if (findCustomer) {
            const expiryCustomerPackages = []

            acc.push({
              customer: index + 1,
              customerId: findCustomer.customerId,
              name: findCustomer.name,
              firstName: findCustomer.firstName,
              packages: info
                .reduce((acc, info) => {
                  const { buyDate, expiryDay, remainingValue } = info
                  const expiryDate = dayjs(buyDate).add(expiryDay, 'day')
                  if (
                    dayjs(new Date()).isBefore(expiryDate) &&
                    remainingValue !== 0
                  ) {
                    acc.push({
                      ...info,
                      quantity: 0,
                      select: false,
                      discount: 0,
                    })
                  } else {
                    expiryCustomerPackages.push({
                      ...info,
                      quantity: 0,
                      select: false,
                      discount: 0,
                    })
                  }
                  return acc
                }, [])
                .sort((a, b) => +a.allService - +b.allService)
                .sort((a, b) =>
                  a.type === PackageTypes.Percent ||
                  b.type === PackageTypes.Percent
                    ? -Number.MAX_SAFE_INTEGER
                    : 0
                )
                .concat(expiryCustomerPackages),
            })
          }
        }
        return acc
      }, [])
      setCustomerPackages(customerPackages)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (bookingType.bookingTypeId === 3) {
      setActiveBtnPayChannelTypeIndex(4)
    }
  }, [bookingType])

  useEffect(() => {
    let totalSplitPayment = 0
    for (const payChannel of _payChannels) {
      totalSplitPayment += +payChannel.price
    }
    for (const splitPayCustomerPackage of splitPayCustomerPackages) {
      totalSplitPayment += splitPayCustomerPackage.price
    }
    setNetPrice(
      +total -
        (+discount || 0) -
        (+serviceDiscount || 0) -
        (+totalSplitPayment || 0) +
        (+totalTip || 0)
    )
    setTotalSplitPayment(totalSplitPayment)
  }, [_payChannels, splitPayCustomerPackages])

  const BtnPayChannelType = () => {
    let customerPackageCount = 0
    customerPackages.map(c => (customerPackageCount += c.packages.length))

    return config.PAY_TYPES.map((_, index) => {
      let show = true
      if (bookingType.bookingTypeId !== 3 && index === 4) {
        show = false
      } else if (bookingType.bookingTypeId === 3 && index !== 4) {
        show = false
      } else if (buyPackage && (index === 5 || index === 4)) {
        show = false
      } else if (customerPackageCount === 0 && index === 5) {
        show = false
      }

      if (show)
        return (
          <Btn key={index}>
            <Button3
              margin='0'
              padding='0.25rem'
              backgroundColorActive='linear-gradient(264.23deg, #7B44C9 -45.63%, #44298C 99.47%)'
              active={activeBtnPayChannelTypeIndex === index}
              onClick={() => {
                setActiveBtnPayChannelTypeIndex(index)
                dispatch(
                  getOrderBooking({
                    charge: 0,
                    activeBtnPayChannelType: index,
                    commission: 0,
                  })
                )
              }}
            >
              {t(getPaymentChannelTypeName(index + 1))}
            </Button3>
          </Btn>
        )
    })
  }

  const onAddNumberClick = number => {
    // setPriceCash(number)
  }

  const onSelectPayChannel = payChannel => {
    setPayChannel({ ...payChannel })
  }

  const onChargeChange = (unit, value) => {
    let newPayChannel = { ...payChannel }
    const totalSplitPayment = _payChannels.reduce(
      (acc, info) => (acc += +info.price),
      0
    )

    const price =
      total - (discount || 0) - (commission || 0) - totalSplitPayment + totalTip

    if (unit === 1) {
      const percent = value / 100
      const _total = +price * +percent
      newPayChannel = {
        ...newPayChannel,
        creditCharge: `ชาร์จ ${value}${unit === 1 ? '%' : '฿'} ${_total} บาท`,
        charge: _total,
      }
      dispatch(getOrderBooking({ charge: _total }))
    } else {
      newPayChannel = {
        ...newPayChannel,
        creditCharge: `ชาร์จ ${value} บาท`,
        charge: value,
      }

      dispatch(getOrderBooking({ charge: parseInt(value) }))
    }
    setPayChannel(newPayChannel)
  }

  const callApiUpdateCustomerAll = async data => {
    try {
      await api.updateAllCustomer(data)
    } catch (err) {
      console.log(err)
    }
  }
  const callApiCreateCustomerAll = async data => {
    try {
      const { data: _data } = await api.createAllCustomer(data)
      return _data
    } catch (err) {
      console.log(err)
    }
  }
  const callApiCheckOpenCashier = async () => {
    try {
      let _date = dayjs(new Date())
        .startOf('day')
        .add(+new Date().getHours(), 'hour')
        .add(+new Date().getMinutes(), 'minute')
        .toDate()

      const { data, success } = await api.getStoreDateTimeByDateV2(
        new Date(_date)
      )

      let bookingDate = dayjs(_bookingHistory.date)
        .startOf('day')
        .add(+_bookingHistory.time.hour, 'hour')
        .add(+_bookingHistory.time.minute, 'minute')
        .toDate()

      let checkDate = false
      let currentDate = new Date()
      if (success) {
        const { openDateTime } = data

        currentDate = new Date(dayjs(openDateTime).toDate())
        checkDate = await utils.checkIsSameCurrentDate(
          new Date(bookingDate),
          currentDate
        )
      }

      let selectDate = new Date()

      if (checkDate) {
        selectDate = new Date(dayjs(currentDate))
      } else {
        if (dayjs(_bookingHistory.date).isBefore(currentDate, 'date')) {
          selectDate = new Date(dayjs(bookingDate))
        } else if (dayjs(_bookingHistory.date).isAfter(currentDate, 'date')) {
          selectDate = new Date(dayjs(currentDate))
        } else {
          // selectDate = new Date(dayjs(bookingDate))
          selectDate = new Date(dayjs(bookingDate))
        }
      }

      const { data: _cashier, success: cashierSuccess } =
        await api.getCashierByDate(new Date(selectDate), new Date(selectDate))
      if (cashierSuccess && _cashier.length) {
        let cashier = _cashier.sort((a, b) => b.number - a.number)
        if (dayjs(selectDate).isBefore(currentDate, 'date')) {
          return true
        } else if (cashier[0].open) {
          return true
        } else {
          await setIsOpenPopupWarning(true)
          return false
        }
      } else {
        await setIsOpenPopupWarning(true)
        return false
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onSaveClick = async () => {
    try {
      setLoading(true)
      if (loading) {
        return
      }
      const newArrPayChannel = [..._payChannels]
      if (!_payChannels.length && !splitPayCustomerPackages.length) {
        if (activeBtnPayChannelTypeIndex === 0) {
          const checkOpenCashier = await callApiCheckOpenCashier()
          if (!checkOpenCashier) {
            return checkOpenCashier
          }
        }
        if (activeBtnPayChannelTypeIndex !== 5) {
          const totalDiscount = +discount || 0 + +serviceDiscount || 0
          newArrPayChannel.push({
            payChannelTypeId:
              payChannelTypes[activeBtnPayChannelTypeIndex].payChannelTypeId,
            ...payChannel,
            price:
              total -
              (totalDiscount || 0) -
              (commission || 0) +
              (totalTip || 0),
          })
        } else {
          if (
            total -
              (discount || 0) -
              (serviceDiscount || 0) -
              (commission || 0) +
              (totalTip || 0) -
              packageDiscount -
              splitPackageDiscount !==
            0
          ) {
            alert(t('invalidAmount'))
            setLoading(false)
            return false
          }
        }
      } else {
        const totalSplitPayment = _payChannels.reduce(
          (acc, info) => (acc += +info.price),
          0
        )
        if (
          totalSplitPayment !==
            total - (discount || 0) - (commission || 0) + (charge || 0) &&
          activeBtnPayChannelTypeIndex !== 5
        ) {
          newArrPayChannel.push({
            ...payChannel,
            chargePercentage: payChannel.chargePercentage || 0,
            payChannelTypeId:
              payChannelTypes[activeBtnPayChannelTypeIndex].payChannelTypeId,
            price:
              total -
              (discount || 0) -
              (serviceDiscount || 0) -
              (splitPackageDiscount || 0) -
              (commission || 0) +
              (totalTip || 0) -
              (+totalSplitPayment || 0),
          })
        } else if (
          total -
            (discount || 0) -
            (serviceDiscount || 0) -
            (totalSplitPayment || 0) -
            (commission || 0) +
            (totalTip || 0) -
            packageDiscount -
            splitPackageDiscount !==
            0 &&
          activeBtnPayChannelTypeIndex === 5
        ) {
          alert(t('invalidAmount'))
          setLoading(false)
          return false
        }
      }

      let newBookingHistory = { ..._bookingHistory }
      let randomString = ''
      for (let i = 0; i < 4; i++) {
        randomString += Math.floor(Math.random() * 9) + 0
      }
      const { data: lengthReceiptNumber } =
        await api.getBookingHistoryByLengthReceiptNumber()
      let storeId = auth.getStoreId() + ''
      storeId = (storeId + '').padStart(4, '0')
      let _lengthReceiptNumber = lengthReceiptNumber + ''
      _lengthReceiptNumber = (_lengthReceiptNumber + '').padStart(5, '0')
      const receiptNumber =
        'R' +
        dayjs(new Date()).format('YY') +
        '' +
        storeId +
        _lengthReceiptNumber
      setReceiptNumber(receiptNumber)
      const billCode =
        dayjs(new Date()).format('DDMMYY') +
        '' +
        bookingCode +
        '' +
        auth.getStoreId() +
        '' +
        randomString
      let payChannels = newArrPayChannel.reduce((acc, info) => {
        const {
          payChannelTypeId,
          payChannelId,
          payChannel,
          note: _note,
          creditCharge,
          creditNumber,
          charge,
          price,
        } = info

        let note = _note
        let chargePercentage = 0
        if (payChannelTypeId === 2) {
          note = `creditNumber:${creditNumber} creditCharge:${
            creditCharge || 0
          }`
          chargePercentage = instant
            ? bookingHistory.bookingChannel.chargePercentage
            : charge || 0
        }

        acc.push({
          payChannelTypeId,
          payChannelId,
          payChannel,
          note,
          chargePercentage,
          price,
        })

        return acc
      }, [])
      const customerPackages = payCustomerPackages.concat(
        splitPayCustomerPackages
      )
      payChannels = payChannels.concat(customerPackages)
      newBookingHistory = { ...newBookingHistory, payChannels }
      if (buyPackage) {
        let customer = {
          ...orders[0],
          nationalityId: orders[0].nationality
            ? orders[0].nationality.value
            : -1,
          age: orders[0].age ? orders[0].age.value : -1,
        }
        await onSavePackage(payChannels, billCode, receiptNumber)
      } else {
        await onSaveBookingHistory(
          newBookingHistory,
          billCode,
          receiptNumber,
          customerPackages,
          'save'
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onSaveBookingHistory = async (
    newBookingHistory,
    billCode,
    receiptNumber,
    customerPackages,
    type = 'save'
  ) => {
    try {
      const newCustomers = []
      const oldCustomers = []
      let _newCustomers = []

      for (const order of newBookingHistory.customers) {
        const {
          name,
          firstName,
          lastName,
          customerId,
          phoneNumber,
          specialRequest,
          age,
          gender,
          nationalityId,
          birthDay,
        } = order
        if ((customerId === -1 || !customerId) && (name || phoneNumber)) {
          newCustomers.push({
            name: name || '',
            firstName: firstName || '',
            lastName: lastName || '',
            phoneNumber: phoneNumber || '',
            specialRequest: specialRequest || '',
            age: age,
            gender,
            nationalityId,
            birthDay,
            status: 1,
            storeId: auth.getStoreId(),
            brandId: auth.getBrandId(),
          })
        } else if (customerId !== -1) {
          oldCustomers.push({
            _id: customerId,
            name: name || '',
            firstName: firstName || '',
            lastName: lastName || '',
            phoneNumber: phoneNumber || '',
            specialRequest: specialRequest || '',
            age: age,
            gender,
            nationalityId: nationalityId,
            birthDay,
          })
        }
      }

      if (oldCustomers.length) {
        await callApiUpdateCustomerAll(oldCustomers)
      }
      if (newCustomers.length) {
        _newCustomers = await callApiCreateCustomerAll(newCustomers)
      }

      const newArrCustomer = []

      for (let i = 0; i < newBookingHistory.customers.length; i++) {
        const {
          phoneNumber,
          name,
          customerId,
          specialRequest,
          age,
          gender,
          nationalityId,
          orders: _orders,
        } = newBookingHistory.customers[i]
        const findCustomer =
          _newCustomers.find(
            c => c.phoneNumber === phoneNumber && c.name === name
          ) || {}

        const customerOrders = _orders.reduce((acc, info, index) => {
          let serviceDiscount = 0
          if (orders[i].services[index].unit === 1) {
            serviceDiscount =
              +orders[i].services[index].price *
              (+orders[i].services[index].serviceDiscount / 100)
          } else {
            serviceDiscount = +orders[i].services[index].serviceDiscount
          }
          acc.push({ ...info, serviceDiscount })
          return acc
        }, [])

        newArrCustomer.push({
          ...newBookingHistory.customers[i],
          customerId: customerId === -1 ? findCustomer._id || -1 : customerId,
          name: name || '',
          phoneNumber: phoneNumber || '',
          specialRequest: specialRequest || '',
          age: age,
          gender: gender,
          nationalityId: nationalityId,
          orders: customerOrders,
        })
      }
      newBookingHistory = {
        ...newBookingHistory,
        customers: newArrCustomer,
        transactionType:
          newBookingHistory.orderCount !== 0 &&
          newBookingHistory.productCount !== 0
            ? 3
            : newBookingHistory.productCount !== 0
            ? 2
            : 1,
        statusId:
          _bookingHistory.statusId === 4 || _bookingHistory.statusId === 3
            ? _bookingHistory.statusId
            : 2,
        time:
          +_bookingHistory.time.hour > 23
            ? {
                ..._bookingHistory.time,
                hour: +_bookingHistory.time.hour - 24,
              }
            : _bookingHistory.time,
        startTime:
          _bookingHistory.statusId !== 4 && _bookingHistory.statusId !== 3
            ? {}
            : +_bookingHistory.startTime.hour > 23
            ? {
                ..._bookingHistory.startTime,
                hour: +_bookingHistory.startTime.hour - 24,
              }
            : _bookingHistory.startTime,
        total,
        discount,
        serviceDiscount: serviceDiscount,
        packageDiscount: packageDiscount + splitPackageDiscount,
        totalDiscount:
          +discount +
          +serviceDiscount +
          +packageDiscount +
          +splitPackageDiscount,
        commission,
        totalTip,
        billCode,
        receiptNumber,
        payStatus: type === 'save' ? 1 : -1,
        payDate: dayjs(_bookingHistory.date).isSame(dayjs(new Date()), 'date')
          ? new Date()
          : dayjs(_bookingHistory.date).isBefore(dayjs(new Date()), 'date')
          ? _bookingHistory.date
          : new Date(),
      }

      // if (
      //   newBookingHistory.statusId === 3 ||
      //   newBookingHistory.statusId === 4
      // ) {
      //   await api.updateAllStaff(staffNotComing)
      // }

      if (_id === -1) {
        const products = newBookingHistory.products.reduce((acc, info) => {
          const { productId } = info
          acc.push({
            productId: productId,
            product: info.name,
            productImage: info.image,
            price: info.price,
            quantity: info.quantity,
          })
          return acc
        }, [])
        newBookingHistory = {
          ...newBookingHistory,
          products,
          productCount: products.length,
        }

        setBookingHistory(newBookingHistory)
        await callApiCreateBookingHistory(
          newBookingHistory,
          customerPackages,
          type
        )
      } else {
        const products = newBookingHistory.products.reduce((acc, info) => {
          const { productId } = info
          acc.push({
            productId: productId,
            product: info.product,
            productImage: info.productImage,
            price: info.price,
            quantity: info.quantity,
          })
          return acc
        }, [])
        newBookingHistory = {
          ...newBookingHistory,
          products,
          productCount: products.length,
        }

        setBookingHistory(newBookingHistory)
        // console.log({ newBookingHistory })
        await callApiUpdateBookingHistory(
          newBookingHistory,
          customerPackages,
          type
        )
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const onSavePackage = async (payChannels, billCode, receiptNumber) => {
    try {
      let customer = {
        ...orders[0],
        nationalityId: orders[0].nationality ? orders[0].nationality.value : -1,
        age: orders[0].age ? orders[0].age.value : -1,
      }
      if (customer.customerId === -1 || !customer.customerId) {
        const { data, success } = await api.createCustomer({
          ...customer,
          storeId: auth.getStoreId(),
          brandId: auth.getBrandId(),
        })
        if (success) customer = { ...data, customerId: data._id }
      } else {
        await api.updateCustomer(customer._id || customer.customerId, customer)
      }
      const _packages = []
      const customerPackages = packages.reduce((acc, info) => {
        const { _id, ...rest } = info
        const { _id: _customerId, customerId, ...restCustomer } = customer
        acc.push({
          ...restCustomer,
          ...rest,
          services: rest.allService ? [] : info.services,
          refPackageCode: info.packageCode,
          packageId: _id,
          customerId: customerId || _customerId,
          remainingValue: rest.totalValue,
        })
        _packages.push({
          packageId: info._id,
          name: info.name,
          price: info.price,
          quantity: info.quantity,
        })
        return acc
      }, [])

      await callApiCreateBookingHistory(
        {
          date: new Date(),
          time: {
            hour: new Date().getHours(),
            minute: new Date().getMinutes(),
          },
          customerId: customer.customerId || customer._id,
          name: customer.name || customer.firstName,
          email: customer.email,
          countryCode: '+66',
          phoneNumber: customer.phoneNumber,
          specialRequest: customer.specialRequest,
          customers: [
            {
              customerId: customer.customerId || customer._id,
              name: customer.name || customer.firstName,
              email: customer.email,
              countryCode: '+66',
              phoneNumber: customer.phoneNumber,
              specialRequest: customer.specialRequest,
              age: customer.age,
              gender: customer.gender,
              nationalityId: customer.nationalityId,
              orders: [],
            },
          ],
          products: [],
          packages: _packages,
          packageCount: _packages.length,
          transactionType: 4,
          statusId: 2,
          payStatus: 1,
          payChannels: payChannels,
          total: total,
          totalDiscount: discount,
          discount: discount,
          receiptNumber,
          billCode,
          bookingCode,
          orderCount: 0,
          productCount: 0,
          storeId: auth.getStoreId(),
        },
        customerPackages,
        'save'
      )
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const callApiCreateBookingHistory = async (
    create,
    customerPackages = [],
    type = 'save'
  ) => {
    try {
      if (type === 'deposit') {
        const { success } = await api.createBookingHistory(create)
        if (success) {
          dispatch(clearOrderBooking())
          dispatch(clearPayPackage())
        }
      } else if (type === 'save') {
        if (create.orderCount !== 0 || create.productCount !== 0) {
          const {
            success: checkOutSuccess,
            data,
            massage,
          } = await api.getPaymentCheckout({
            bookingHistoryId: -1,
            bookingHistory: create,
            customerPackages,
          })
          if (checkOutSuccess) {
            setIsOpenPopupDone(true)
          } else {
            setLoading(false)
            if (massage === 'Paid') {
              alert(t('thisBookingHasBeenPaid'))
            } else if (massage === 'cash close') {
              alert(t(`cashierShiftHasn'tStartedYet`))
            } else {
              alert(t('paymentUnsuccessful'))
            }
            alert(t('paymentUnsuccessful'))
          }
        } else {
          if (customerPackages.length) {
            const { data, success } = await api.getPaymentCheckoutPackage({
              bookingHistory: create,
              customerPackages,
            })
            if (success) {
              setBookingHistory(data)
              setIsOpenPopupDone(true)
            } else {
              setLoading(false)
              alert(t('paymentUnsuccessful'))
            }
          } else {
            setLoading(false)
            alert(t('paymentUnsuccessful'))
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiUpdateBookingHistory = async (
    update,
    customerPackages = [],
    type = 'save'
  ) => {
    try {
      let newBody = { ...update }
      if (update.statusId === 3 && update.startTime.hour) {
        const { startTime, customers } = update
        const bookingStart =
          parseInt(startTime.hour) * 60 + parseInt(startTime.minute)
        const timeNow =
          parseInt(new Date().getHours()) * 60 +
          parseInt(new Date().getMinutes())
        let max = -1
        for (const customer of customers) {
          const { orders } = customer
          let serviceTime = 0
          for (const order of orders) {
            const { time } = order
            serviceTime += time
          }
          if (max <= serviceTime) {
            max = serviceTime
          }
        }
        if (timeNow >= max + bookingStart) {
          newBody = { ...update, statusId: 4 }
        }
      }

      if (type === 'deposit') {
        const { data: bookingHistory, success } =
          await api.updateBookingHistory(newBody._id, newBody)
        if (success) {
          dispatch(clearOrderBooking())
          dispatch(clearPayPackage())
        }
      } else if (type === 'save') {
        if (newBody.orderCount !== 0 || newBody.productCount !== 0) {
          const {
            success: checkOutSuccess,
            data,
            massage,
          } = await api.getPaymentCheckout({
            bookingHistoryId: newBody._id,
            bookingHistory: newBody,
            customerPackages,
          })

          if (checkOutSuccess) {
            setIsOpenPopupDone(true)
            const { success } = await api.updateNotification(instantId, {
              status: -1,
            })
            if (success) dispatch(fetchNotification())
          } else {
            setLoading(false)
            if (massage === 'Paid') {
              alert(t('thisBookingHasBeenPaid'))
            } else if (massage === 'cash close') {
              alert(t(`cashierShiftHasn'tStartedYet`))
            } else {
              alert(t('paymentUnsuccessful'))
            }
          }
        } else {
          setIsOpenPopupDone(true)
        }
      } else {
        setLoading(false)
        alert(t('paymentUnsuccessful'))
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onDepositClick = async () => {
    try {
      let newBookingHistory = { ..._bookingHistory }
      let payChannels = _payChannels.reduce((acc, info) => {
        const {
          payChannelTypeId,
          payChannelId,
          payChannel,
          note: _note,
          creditCharge,
          creditNumber,
          charge,
          price,
        } = info

        let note = _note
        let chargePercentage = 0
        if (payChannelTypeId === 2) {
          note = `creditNumber:${creditNumber} creditCharge:${
            creditCharge || 0
          }`
          chargePercentage = charge || 0
        }

        acc.push({
          payChannelTypeId,
          payChannelId,
          payChannel,
          note,
          chargePercentage,
          price,
        })

        return acc
      }, [])
      const customerPackages = payCustomerPackages.concat(
        splitPayCustomerPackages
      )
      payChannels = payChannels.concat(customerPackages)
      newBookingHistory = { ...newBookingHistory, payChannels }
      onSaveBookingHistory(newBookingHistory, '', '', [], 'deposit')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <BoxBtn>
        <BoxBtnInside>{BtnPayChannelType()}</BoxBtnInside>
      </BoxBtn>
      <Line className='mt-2 mb-2'></Line>
      {activeBtnPayChannelTypeIndex === 0 && (
        <Cash
          total={total}
          discount={discount}
          commission={commission}
          charge={charge}
          totalTip={totalTip}
          payChannels={_payChannels}
          bookingHistory={_bookingHistory}
          onAddNumberClick={onAddNumberClick}
        />
      )}
      {activeBtnPayChannelTypeIndex === 1 && (
        <Credit
          bookingHistory={_bookingHistory}
          total={total}
          discount={discount}
          charge={charge}
          onSelectPayChannel={onSelectPayChannel}
          onChargeChange={onChargeChange}
        />
      )}
      {activeBtnPayChannelTypeIndex === 2 && (
        <Transfer
          bookingHistory={_bookingHistory}
          onSelectPayChannel={onSelectPayChannel}
        />
      )}
      {activeBtnPayChannelTypeIndex === 3 && (
        <Online
          bookingCode={bookingCode}
          total={total}
          discount={discount}
          bookingHistory={_bookingHistory}
          onSelectPayChannel={onSelectPayChannel}
          onChargeChange={onChargeChange}
        />
      )}
      {activeBtnPayChannelTypeIndex === 4 && (
        <Agency bookingHistory={_bookingHistory} />
      )}
      {activeBtnPayChannelTypeIndex === 5 && (
        <Package orders={orders} customerPackages={customerPackages} />
      )}

      <div
        className='mt-2'
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: '1',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          position: 'sticky',
          bottom: '0.5rem',
        }}
      >
        <CardSummary totalSplitPayment={totalSplitPayment} />

        <div style={{ width: '100%', display: 'flex' }}>
          {netPrice !== 0 && bookingType.bookingTypeId !== 3 && (
            <div
              className='mr-1'
              style={{
                display: 'flex',
                width:
                  checkStaff || (totalSplitPayment !== 0 && netPrice !== 0)
                    ? '50%'
                    : '100%',
              }}
            >
              <Button2
                color='#475467'
                border='2px solid #E5ECED'
                backgroundColor='white'
                fontWeight='600'
                onClick={() => setOpenSplitPayment(!openSplitPayment)}
              >
                {t('payInInstallment')}
              </Button2>
            </div>
          )}

          {((totalSplitPayment !== 0 && netPrice !== 0) || !checkStaff) && (
            <div
              className='ml-1'
              style={{
                display: 'flex',
                width:
                  totalSplitPayment !== 0 && netPrice !== 0 ? '50%' : '100%',
              }}
            >
              <Button2
                border='2px solid #8C56D9'
                color='#8C56D9'
                backgroundColor='#FFF'
                fontWeight='600'
                onClick={() => onDepositClick()}
              >
                {t('save')}
              </Button2>
            </div>
          )}
          {checkStaff && (
            <div
              className='ml-1'
              style={{
                display: 'flex',
                width: `${
                  netPrice !== 0 && bookingType.bookingTypeId !== 3
                    ? '50%'
                    : '100%'
                }`,
              }}
            >
              <Button2
                backgroundColor={!loading ? '#32D582' : '#979797'}
                backgroundColorHover={!loading ? '#52DB95' : '#979797'}
                color='white'
                disable={loading}
                icon={<PayIcon size='20' color='white' />}
                onClick={() => onSaveClick()}
              >
                {t('payNow')}
              </Button2>
            </div>
          )}
        </div>
      </div>
      {openSplitPayment && (
        <PopupSelectPayChannel
          handleClose={() => setOpenSplitPayment(false)}
          payChannels={_payChannels || []}
          total={total || 0}
          totalTip={totalTip || 0}
          discount={discount || 0}
          serviceDiscount={serviceDiscount || 0}
          packageDiscount={packageDiscount || 0}
          splitPackageDiscount={splitPackageDiscount || 0}
          charge={charge || 0}
          bookingHistory={_bookingHistory}
          customerPackages={customerPackages}
        />
      )}

      {isOpenPopupWarning && (
        <PopupWaringCashier
          handleClose={() => {
            setIsOpenPopupWarning(false)
            setLoading(false)
          }}
        />
      )}

      {isOpenPopupDone && bookingHistory && (
        <PopupBillPayMent
          bookingHistory={bookingHistory}
          receiptNumber={receiptNumber}
        />
      )}
    </Container>
  )
}

export default RightSide
