import React, { PropsWithChildren } from 'react'
import styled, { CSSProperties } from 'styled-components'

import TextLabel from './TextLabel'

const BoxContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e4e8eb;
  border-radius: 4px;
`

const BoxHeader = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e4e8eb;
`

const BoxBody = styled.div<{ bodyPadding: CSSProperties['padding'] }>`
  padding: ${props => props.bodyPadding || '1rem'};
`

export interface BoxProps {
  header: string
  color?: CSSProperties['color']
  bodyPadding?: CSSProperties['padding']
}

const Box = ({
  header,
  color,
  bodyPadding,
  children,
}: PropsWithChildren<BoxProps>) => {
  return (
    <BoxContainer>
      <BoxHeader>
        <TextLabel fontSize='0.875rem' fontWeight={500} color={color}>
          {header}
        </TextLabel>
      </BoxHeader>
      <BoxBody bodyPadding={bodyPadding}>{children}</BoxBody>
    </BoxContainer>
  )
}

export default Box
