import TextLabel from '../../atoms/TextLabel'
import PopupConfirmBase from '../../components/popups/PopupConfirmBase'

const PopupConfirmCustomer = ({ customer, onConfirm, handleClose }) => {
  const { name, firstName, lastName } = customer
  return (
    <PopupConfirmBase
      topic='ยืนยันลูกค้า'
      onConfirm={onConfirm}
      handleClose={handleClose}
    >
      <TextLabel>
        ลูกค้า {name} [ {firstName} {lastName} ]
      </TextLabel>
    </PopupConfirmBase>
  )
}

export default PopupConfirmCustomer
