import React, { PropsWithChildren } from 'react'
import styled, { CSSProperties } from 'styled-components'

import ClosePopup from '../../atoms/ClosePopup'
import Popup from '../../atoms/Popup'
import PopupInside from '../../atoms/PopupInside'
import TextLabel from '../../atoms/TextLabel'

const Card = styled.div<Pick<CSSProperties, 'padding'>>`
  width: 100%;
  padding: ${props => props.padding};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export interface PopupBaseProps {
  topic?: string
  width?: CSSProperties['width']
  maxWidth?: CSSProperties['maxWidth']
  onClose?: () => void
  overflow?: CSSProperties['overflow']
}

const PopupBase = ({
  topic,
  width,
  maxWidth = '500px',
  onClose,
  children,
  overflow,
}: PropsWithChildren<PopupBaseProps>) => {
  return (
    <Popup>
      <PopupInside height='auto' maxWidth={maxWidth} overflow={overflow}>
        <Card padding={topic ? '1.5rem' : '1.5rem 1.5rem 0 1.5rem'}>
          <Box>
            <TextLabel fontWeight='500' fontSize='1.25rem'>
              {topic}
            </TextLabel>
            {onClose && (
              <div className='pl-2 pr-2' onClick={onClose}>
                <ClosePopup />
              </div>
            )}
          </Box>
        </Card>
        {children}
      </PopupInside>
    </Popup>
  )
}

export default PopupBase
