import React from 'react'
import styled from 'styled-components'

import Label from '../../atoms/Label'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export interface DataItemProps {
  name: string
  value: number | string
}

const DataItem = ({ name, value }: DataItemProps) => {
  return (
    <Container>
      <Label size='1rem' weight={500} color='#475467'>
        {name}
      </Label>
      <Label size='1.25rem' weight={500} color='#000' lineCap>
        {value}
      </Label>
    </Container>
  )
}

export default DataItem
