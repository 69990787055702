import React from "react"
import Page from "@/lib/layout/Page/Page"
import { Col } from "../atoms/Flex"

export interface NestedLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
    path?: string
}

export const NestedLayout: React.FC<NestedLayoutProps> = (props) => {

    return(
        <Page>
            {props.children}
        </Page> 
    )
}