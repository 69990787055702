import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import PopupBase from './PopupBase'

import TextLabel from '../../atoms/TextLabel'
import auth from '../../auth'
import { DISABLED_ALL_URLS } from '../../routes'

const Container = styled.div`
  width: 100%;
  padding: 0 1.5rem 1.5rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StoreItemContainer = styled.div`
  padding-bottom: 0.5rem;
  padding-right: 2rem;
  border-bottom: 1px solid #e4e8eb;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:last-child {
    border-bottom: unset;
  }
`

const Arrow = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='10'
      cy='10'
      r='9.5'
      transform='rotate(180 10 10)'
      fill='white'
      stroke='#E4E8EB'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.3994 10L7.33594 13.0635L8.27243 14L11.8042 10.4682C12.0628 10.2096 12.0628 9.79036 11.8042 9.53175L8.27243 6L7.33594 6.9365L10.3994 10Z'
      fill='#8C56D9'
    />
  </svg>
)

const PopupSelectBranch = ({ handleClose }) => {
  const { stores, types } = useSelector(state => state.user)
  const brand = useSelector(state => state.brand)

  const location = useLocation()

  const { _id: brandId, branches } = brand

  const eligibleStores = branches
  // types.length && types[0].typeId === 0
  //   ? branches
  //   : stores.filter(store => store.brandId === brandId)

  const onStoreClick = id => {
    if (!id) {
      auth.setStoreId(0)
      window.location.reload()
      return
    }
    const store = eligibleStores.find(({ _id }) => _id === id)
    const { _id: storeId } = store
    auth.setStoreId(storeId)
    window.location.reload()
  }

  const disabled = DISABLED_ALL_URLS.includes(location.pathname)

  return (
    <PopupBase topic={'โปรดเลือกสาขาที่ต้องการดูข้อมูล'} onClose={handleClose}>
      <Container>
        {!disabled && (
          <StoreItemContainer onClick={() => onStoreClick()}>
            <TextLabel fontSize='1.25rem' fontWeight={600}>
              ทุกสาขา
            </TextLabel>
            <Arrow />
          </StoreItemContainer>
        )}
        {eligibleStores.map(({ _id, branch }, index) => (
          <StoreItemContainer key={index} onClick={() => onStoreClick(_id)}>
            <TextLabel fontSize='1.25rem' fontWeight={500}>
              สาขา {branch}
            </TextLabel>
            <Arrow />
          </StoreItemContainer>
        ))}
      </Container>
    </PopupBase>
  )
}

export default PopupSelectBranch
