export const EDIT_DETAIL_BOOKING = 'EDIT_DETAIL_BOOKING'

export const editDetailBooking = data => {
  return {
    type: EDIT_DETAIL_BOOKING,
    payload: { data },
  }
}

export const CLEAR_EDIT_DETAIL_BOOKING = 'CLEAR_EDIT_DETAIL_BOOKING'

export const clearEditDetailBooking = data => {
  return {
    type: CLEAR_EDIT_DETAIL_BOOKING,
    payload: { data },
  }
}
