import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

//@ts-ignore
import api from '../../api'
import config from '../../config'
import usePage from '../../lib/layout/Page/usePage'
import { BookingHistory, Order } from '../../redux/models/BookingHistory'
import { ServiceOrder } from '../../redux/models/BookOrder'
import { DataNotification } from '../../redux/models/Notifications'
import ReduxStore from '../../redux/models/ReduxStore'
import Service from '../../redux/models/Service'
import utils from '../../utils'

interface NotificationBookingHistory extends BookingHistory {
  notificationStatus: number
  orders: NotificationOrder[]
}

type NotificationOrder = Omit<Order, 'services'> & {
  services: ExtendedServiceOrder[]
}

interface ExtendedServiceOrder extends ServiceOrder {
  endTime: { hour: number; minute: number }
  startTime: {
    hour: number
    minute: number
  }
  activeTime: number
  timePriceId: number
  timePrices: any[]
  service: string
  _id: number
  staffId: number
  staffName: string
  name: string
  image: string
}

const useNotification = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { onToggle } = usePage()

  const { notifications } = useSelector((state: ReduxStore) => state.store)

  const [bookingHistories, setBookingHistories] = useState<
    NotificationBookingHistory[]
  >([])
  const [dataNotifications, setDataNotifications] = useState<
    DataNotification[]
  >([])

  useEffect(() => {
    const newNotifications = [...notifications]
    newNotifications.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    )

    const setData = async () => {
      // try {
      //   const { data: storeServices } = (await api.getServices()) as {
      //     data: Service[]
      //   }
      //   const bookingHistories: NotificationBookingHistory[] = []
      //   for (const notification of newNotifications) {
      //     const { body, _id, status } = notification as BookingNotification
      //     const {
      //       customers,
      //       time,
      //       _id: bookingId,
      //       bookingCode,
      //       bookingChannel,
      //       bookingType,
      //       date,
      //     } = body
      //     const newOrders: NotificationOrder[] = []
      //     for (const customer of customers) {
      //       const { orders } = customer
      //       let startTime = utils.convertTime(time)
      //       let endTime = startTime
      //       const services: ExtendedServiceOrder[] = []
      //       for (let i = 0; i < orders.length; i++) {
      //         const { time: serviceTime } = orders[i]
      //         endTime += serviceTime
      //         const service = storeServices.find(
      //           s => s._id === orders[i].serviceId
      //         )
      //         const startTimeHour = Math.floor(
      //           (i !== 0 ? endTime - serviceTime : startTime) / 60
      //         )
      //         const endTimeHour = Math.floor(endTime / 60)
      //         if (!service) return
      //         const findActiveTimePrice = service.timePrices.findIndex(
      //           t => t.time === serviceTime
      //         )
      //         if (findActiveTimePrice < 0) return
      //         services.push({
      //           endTime: {
      //             hour: endTimeHour >= 24 ? endTimeHour - 24 : endTimeHour,
      //             minute: endTime % 60,
      //           },
      //           startTime: {
      //             hour:
      //               startTimeHour >= 24 ? startTimeHour - 24 : startTimeHour,
      //             minute: (i !== 0 ? endTime - serviceTime : startTime) % 60,
      //           },
      //           activeTime: findActiveTimePrice,
      //           timePriceId:
      //             service.timePrices[findActiveTimePrice].timePriceId,
      //           _id: service._id,
      //           serviceId: service._id,
      //           service: service.name,
      //           name: service.name,
      //           price: orders[i].price,
      //           time: orders[i].time,
      //           timePrices: service.timePrices,
      //           staffId: -1,
      //           staffName: '',
      //           image: service.image,
      //           serviceMasterId: service.masterId,
      //           serviceDiscount: 0,
      //           unit: PromotionUnits.Amount,
      //         })
      //       }
      //       newOrders.push({
      //         customerId: customer.customerId,
      //         name: customer.name,
      //         phoneNumber: customer.phoneNumber,
      //         email: customer.email,
      //         gender: customer.gender,
      //         age: {
      //           value: customer.age,
      //           label: customer.age === -1 ? 'อายุ' : config.AGE[customer.age],
      //         },
      //         nationality: { value: -1, label: 'สัญชาติ' },
      //         specialRequest: customer.specialRequest,
      //         services: services,
      //         customer: 0,
      //         firstName: '',
      //         lastName: '',
      //         address: '',
      //         taxIDNumber: '',
      //       })
      //     }
      //     bookingHistories.push({
      //       ...getInitialBookingHistory(),
      //       _id: bookingId,
      //       orders: newOrders,
      //       instantId: _id,
      //       notificationStatus: status,
      //       bookingCode,
      //       bookingChannel: { ...bookingChannel, commission: 0 },
      //       bookingType,
      //       date,
      //       time,
      //       customerId: body.customerId,
      //       name: body.name,
      //       email: body.email,
      //       countryCode: body.countryCode,
      //       phoneNumber: body.phoneNumber,
      //       specialRequest: body.specialRequest,
      //     })
      //   }
      //   setBookingHistories(bookingHistories)
      // } catch (err) {
      //   console.log(err)
      // }
    }

    setData()
  }, [])

  const onEditBookingClick = async (_id: number) => {
    // const bookingHistory = bookingHistories.find(b => b._id === _id)
    // if (!bookingHistory) return
    // let max = -1
    // const orders = bookingHistory.orders.reduce(
    //   (acc, info: Order, index: number) => {
    //     const { services } = info
    //     let endTime = 0
    //     for (const service of services) {
    //       endTime += service.time
    //     }
    //     if (max < endTime) max = endTime
    //     acc.push({
    //       ...info,
    //       customer: index + 1,
    //     })
    //     return acc
    //   },
    //   [] as Order[]
    // )
    // const { success } = await api.updateNotification(bookingHistory.instantId, {
    //   status: 2,
    // })
    // if (success) {
    //   dispatch(
    //     getOrderBooking({
    //       _id: bookingHistory._id,
    //       bookingCode: bookingHistory.bookingCode,
    //       customerId: bookingHistory.customerId,
    //       name: bookingHistory.name,
    //       email: bookingHistory.email,
    //       countryCode: bookingHistory.countryCode,
    //       phoneNumber: bookingHistory.phoneNumber,
    //       specialRequest: bookingHistory.specialRequest,
    //       date: bookingHistory.date,
    //       time: bookingHistory.time,
    //       startTime: bookingHistory.time,
    //       endTime: {
    //         hour: Math.floor((max + +bookingHistory.time.hour) / 60),
    //         minute: max + (+bookingHistory.time.hour % 60),
    //       },
    //       orders: orders,
    //       bookingChannel: bookingHistory.bookingChannel,
    //       bookingType: bookingHistory.bookingType,
    //       edit: true,
    //       instant: true,
    //       instantId: bookingHistory.instantId,
    //     })
    //   )
    //   onToggle()
    //   history.replace(HOME_URL)
    // }
  }

  return {
    bookingHistories,
    dataNotifications,
    onEditBookingClick,
    onToggle,
  }
}

export default useNotification
