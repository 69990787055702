import api from '../../api'

export const GET_BREAK_TIME = 'GET_BREAK_TIME'

export const getBreakTime = breakTime => {
  return {
    type: GET_BREAK_TIME,
    payload: { breakTime },
  }
}

export const thunk_action_update_breakTime =
  (_id, _breakTime) => async (dispatch, getState) => {
    try {
      const { data } = await api.getStaffWorkScheduleByStaffIdAndDate(
        _id,
        new Date()
      )
      if (data.length) {
        data.sort((a, b) => b.number - a.number)
        const newArrBreakTime = [...data[0].breakTime]
        newArrBreakTime.push({ ..._breakTime })
        dispatch(getBreakTime(newArrBreakTime))
      }
    } catch (err) {
      console.log(err)
    }
  }
