import React, {
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import styled, { CSSProperties } from 'styled-components'

const Container = styled.div<Button3Props>`
  display: flex;
  justify-content: center;
  background: ${props =>
    props.active
      ? props.backgroundColorActive ||
        'linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
      : props.backgroundColor || '#f4f6fa'};
  color: ${props =>
    props.active ? props.colorActive || '#fff' : props.color || '#000'};
  border: ${props => props.border || 'none'};
  border-radius: ${props => props.borderRadius || '8px'};
  padding: ${props => props.padding || '0.5rem 0'};
  /* font-family: 'Kanit', sans-serif; */
  outline: 0;
  margin: ${props => props.margin || '0.125rem 0'};
  width: ${props => props.width || '100%'};
  font-size: ${props => props.size || '1rem'};
  font-weight: ${props => props.fontWeight || '500'};
  text-align: center;
  cursor: ${props => props.cursor || 'pointer'};
  transition: all 0.2s linear;
  /* font-family: 'Kanit', sans-serif; */
  &:hover {
    background: ${props =>
      props.backgroundColorHover ||
      'linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'};
    color: ${props => props.colorHover || 'white'};
  }
`
const BackgroundIcon = styled.div<Button3Props>`
  background: ${props => props.backgroundIcon || '#F8F9FB'};
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  width: fit-content;
  margin-left: ${props => props.marginLeft || '0'};
  margin-right: ${props => props.marginRight || '0'};
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`

export interface Button3Props extends CSSProperties {
  active?: boolean
  withIconBackground?: boolean
  size?: CSSProperties['fontSize']
  colorIcon?: CSSProperties['color']
  colorActive?: CSSProperties['color']
  backgroundColorActive?: CSSProperties['color']
  backgroundColorHover?: CSSProperties['color']
  colorHover?: CSSProperties['color']
  backgroundIcon?: CSSProperties['background']
  icon?: ReactNode
  iconBF?: ReactNode
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
}

const Button3 = ({
  icon,
  children,
  active: _active,
  onClick,
  backgroundColor,
  backgroundColorActive,
  backgroundColorHover,
  width,
  iconBF,
  border,
  color,
  colorActive,
  margin,
  cursor,
  padding,
  fontWeight,
  size,
  borderRadius,
  marginLeft,
  marginRight,
  withIconBackground = true,
  colorIcon,
}: PropsWithChildren<Button3Props>) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(!!_active)
  }, [_active])

  const onMouseEnter = () => {
    setActive(true)
  }

  const onMouseLeave = () => {
    if (_active) return
    setActive(false)
  }

  const renderedIcon = (
    <>
      {icon &&
        React.isValidElement(icon) &&
        React.cloneElement(icon as ReactElement<{ color: string }>, {
          color: active ? colorIcon : icon.props.color,
        })}
    </>
  )

  const renderedIconContainer = withIconBackground ? (
    <IconContainer>
      <BackgroundIcon marginLeft={marginLeft || '0.5rem'}>
        {renderedIcon}
      </BackgroundIcon>
    </IconContainer>
  ) : (
    renderedIcon
  )

  return (
    <Container
      active={active}
      backgroundColor={backgroundColor}
      backgroundColorActive={backgroundColorActive}
      backgroundColorHover={backgroundColorHover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      width={width}
      border={border}
      color={color}
      colorActive={colorActive}
      margin={margin}
      cursor={cursor}
      padding={padding}
      fontWeight={fontWeight}
      size={size}
      borderRadius={borderRadius}
      marginLeft={marginLeft}
      marginRight={marginRight}
      colorIcon={colorIcon}
    >
      {iconBF === 'after' ? (
        <IconContainer>
          {children}
          {icon && renderedIconContainer}
        </IconContainer>
      ) : (
        <IconContainer>
          {icon && renderedIconContainer}
          {children}
        </IconContainer>
      )}
    </Container>
  )
}

export default Button3
