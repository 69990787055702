import React from 'react'
import './App.css'
import { Route, Switch } from 'react-router-dom'

import 'dayjs/locale/th'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'

import Home from './components/home/Main'
import Book from './components/settingBook/Main'
import Rule from './components/settingRole/Main'
import Store from './components/settingStore/Main'
import GoogleMap from './components/googleMap/Main'
import PayMent from './components/payment/Main'
import Report from './components/report/Main'
import Cashier from './components/cashier/Main'
import Login from './components/login/Login'
import LoginUserPin from './components/login/LoginUserPin'
import ForgetPassword from './components/login/ForgetPassword'
import ForgetPin from './components/login/ForgetPin'
import Product from './components/settingProduct/Main'
import SettingStaffs from './components/settingStaff/Main'
import SettingRoom from './components/settingRoom/Main'
import Admin from './components/admin/Main'
import PayWage from './components/payWage/Main'
import Staffs from './components/staffs/Main'
import SettingCustomer from './components/settingCustomer/Main'
import Promotion from './components/settingPromotion/Main'
import ResetPassword from './components/login/ResetPassword'
import ResetPin from './components/login/ResetPin'
import CardProfile from './components/settingRole/CardProfile'
import Customer from './components/customer/index'
import Notifications from './components/notifications/index'
import SettingBill from './components/settingReceipt/Main'
import SettingPackage from './components/settingPackage/Main'
import BookPage from './components/booking/index'

// Refactor Pages
// import SettingsStaffsPage from "./components/components/settings/staffs"

import './lib/i18n'
import './lib/dayjs'
import useApp from './hook/useApp'
import { routes, sidebarRoutes } from './lib/routes'

import TableComponentPage from './components/pages/components/Table'
import FilterComponentPage from './components/pages/components/Filter'
import InputComponentPage from './components/pages/components/Input'
import SheetComponentPage from './components/pages/components/Sheet'
import SelectComponentPage from './components/pages/components/Select'
import SchedulerComponentPage from './components/pages/components/Scheduler'

import {
  ADMIN_URL,
  BOOKING_URL,
  CASHIER_URL,
  CUSTOMERS_URL,
  FORGET_PASSWORD_URL,
  FORGET_PIN_URL,
  GOOGLE_MAP_URL,
  HOME_URL,
  LOGIN_PIN_URL,
  LOGIN_URL,
  NOTIFICATIONS_URL,
  PAYMENT_URL,
  PAY_WAGE_URL,
  REPORT_URL,
  RESET_PASSWORD_URL,
  RESET_PIN_URL,
  SEARCH_HISTORY_URL,
  SETTING_BILL_URL,
  SETTING_BOOKING_TYPE_URL,
  SETTING_CUSTOMER_URL,
  SETTING_PACKAGE_URL,
  SETTING_PRODUCT_URL,
  SETTING_PROFILE_URL,
  SETTING_PROMOTION_URL,
  SETTING_ROLE_URL,
  SETTING_ROOM_URL,
  SETTING_STAFF_URL,
  SETTING_STORE_URL,
  STAFF_URL,
} from './routes'
import { NestedLayout } from '@/components/layouts/nested-layout'

const App = () => {
  useApp()

  return (
    <Switch>
      <Route exact path={LOGIN_URL} component={Login} />
      <Route exact path={LOGIN_PIN_URL} component={LoginUserPin} />
      <Route exact path={FORGET_PASSWORD_URL} component={ForgetPassword} />
      <Route exact path={FORGET_PIN_URL} component={ForgetPin} />
      <Route exact path={HOME_URL} component={Home} />
      <Route exact path={ADMIN_URL} component={Admin} />
      <Route exact path={SETTING_PRODUCT_URL} component={Product} />
      <Route exact path={SETTING_STAFF_URL} component={SettingStaffs} />
      <Route exact path={SETTING_BOOKING_TYPE_URL} component={Book} />
      <Route exact path={SETTING_ROLE_URL} component={Rule} />
      <Route exact path={SETTING_STORE_URL} component={Store} />
      <Route exact path={GOOGLE_MAP_URL} component={GoogleMap} />
      <Route exact path={PAYMENT_URL} component={PayMent} />
      <Route exact path={PAY_WAGE_URL} component={PayWage} />
      <Route exact path={STAFF_URL} component={Staffs} />
      <Route exact path={REPORT_URL} component={Report} />
      <Route exact path={CASHIER_URL} component={Cashier} />
      <Route exact path={SETTING_CUSTOMER_URL} component={SettingCustomer} />
      <Route exact path={SETTING_PROMOTION_URL} component={Promotion} />
      <Route exact path={RESET_PASSWORD_URL} component={ResetPassword} />
      <Route exact path={RESET_PIN_URL} component={ResetPin} />
      <Route exact path={SETTING_ROOM_URL} component={SettingRoom} />
      {/* <Route exact path={SEARCH_HISTORY_URL} component={SearchHistory} /> */}
      <Route exact path={SETTING_PROFILE_URL} component={CardProfile} />
      <Route exact path={SETTING_BILL_URL} component={SettingBill} />
      <Route exact path={SETTING_PACKAGE_URL} component={SettingPackage} />
      <Route exact path={CUSTOMERS_URL} component={Customer} />
      <Route exact path={NOTIFICATIONS_URL} component={Notifications} />
      <Route exact path={BOOKING_URL} component={BookPage} />

      <NestedLayout>
        <Route exact path='/components/table' component={TableComponentPage} />
        <Route
          exact
          path='/components/filter'
          component={FilterComponentPage}
        />
        <Route exact path='/components/input' component={InputComponentPage} />
        <Route exact path='/components/sheet' component={SheetComponentPage} />
        <Route
          exact
          path='/components/select'
          component={SelectComponentPage}
        />
        <Route
          exact
          path='/components/scheduler'
          component={SchedulerComponentPage}
        />
        {routes.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {sidebarRoutes.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
      </NestedLayout>
      <Route path={routes.settingsStaffs} component={NestedLayout} />
    </Switch>
  )
}

export default App
