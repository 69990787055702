import React from 'react'

import SelectorBase, { SelectorBaseProps } from './SelectorBase'

import Service from '../../redux/models/Service'

export interface SelectorServiceProps extends SelectorBaseProps<Service> {
  index: number
}

const SelectorService = ({
  name = 'service',
  open,
  index,
  onClick,
  option,
  options,
  onOptionClick,
  error = false,
  readOnly = false,
  height,
}: SelectorServiceProps) => {
  return (
    <SelectorBase
      name={name}
      option={option}
      options={options}
      open={open}
      onClick={value => onClick(value, index)}
      onOptionClick={(value, name) => onOptionClick(value, name, index)}
      error={error}
      readOnly={readOnly}
      height={height}
    />
  )
}

export default SelectorService
