import { Store } from 'redux'

import Brand from './Brand'

import { Enums } from '../../utilities/typing'

export default interface User {
  _id: number
  userId: string
  lineUserId: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  loginDate: Date
  logoutDate: Date
  loginTime: {
    startTime: { hour: string; minute: string }
    endTime: { hour: string; minute: string }
  }
  password: string
  salt: Buffer
  resetPasswordToken: string
  resetPinToken: string
  pinSalt: Buffer
  pin: string
  image: string
  type: string
  typeId: UserType
  types: UserTypeItem[]
  lineStaffId: string
  lineId: string
  status: number
  statuses: [
    {
      storeId: number
      status: number
    }
  ]
  storeId: number
  stores: number[] | Store[]
}

export interface UserState extends User {
  storeId: number
  brandId: number
  stores: Store[]
  brands: Brand[]
  error: boolean
}

export const UserTypes = {
  Admin: 0,
  Owner: 1,
  Manager: 2,
  Cashier: 3,
  General: 4,
} as const

export type UserType = Enums<typeof UserTypes>
export type UserTypeItem = {
  brandId: number
  storeId: number
  type: string
  typeId: UserType
}

export const getCurrentStoreRole = (user: UserState): UserType => {
  const { types, storeId, brandId } = user

  const isAdmin = !!types.find(t => t.typeId === UserTypes.Admin)

  if (isAdmin) {
    return UserTypes.Admin
  }

  if (storeId === 0) {
    const filteredRoles = types
      .filter(t => t.brandId === brandId)
      .map(({ typeId }) => typeId)
    return filteredRoles.length
      ? (Math.min(...filteredRoles) as UserType)
      : UserTypes.General
  }

  const role = types.find(t => t.storeId === storeId)

  if (!role) return UserTypes.General

  return role.typeId
}
