import React from 'react'

import DropdownButton from '@/components/atoms/DropdownButton'
import { FilterIcon } from '@/components/atoms/icons/FilterIcon'
import { FilterButton } from '@/components/molecules/filterButton/FilterButton'

const FilterComponentPage = () => {
  const [value, setValue] = React.useState<string[]>([])

  return (
    <>
      <p> Multiple Select </p>
      <FilterButton
        value={value}
        items={[
          { value: 'Somchai', key: '1' },
          { value: 'Somchai', key: '2' },
          { value: 'Somchai', key: '3' },
          { value: 'Somchai', key: '4' },
          { value: 'Somchai', key: '5' },
        ]}
        onValueChange={e => {
          setValue(e)
        }}
        title='Filter'
      />

      <p> Select All </p>
      <FilterButton
        selectAll
        value={value}
        items={[
          { value: 'Somchai', key: '1' },
          { value: 'Somchai', key: '2' },
          { value: 'Somchai', key: '3' },
          { value: 'Somchai', key: '4' },
          { value: 'Somchai', key: '5' },
        ]}
        onValueChange={e => {
          setValue(e)
        }}
        title='Filter'
      />

      <p> Single Select </p>
      <FilterButton
        trigger={<FilterIcon />}
        value={value}
        items={[
          { value: 'Somchai', key: '1' },
          { value: 'Somchai', key: '2' },
          { value: 'Somchai', key: '3' },
          { value: 'Somchai', key: '4' },
          { value: 'Somchai', key: '5' },
        ]}
        onValueChange={e => {
          setValue(e)
        }}
        title='Filter'
      />

      <p> Single Select with Trigger </p>
      <FilterButton
        single
        value={value}
        items={[
          { value: 'Somchai', key: '1' },
          { value: 'Somchai', key: '2' },
          { value: 'Somchai', key: '3' },
          { value: 'Somchai', key: '4' },
          { value: 'Somchai', key: '5' },
        ]}
        onValueChange={e => {
          setValue(e)
        }}
        title='Filter'
      />
    </>
  )
}

export default FilterComponentPage
