import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'

import BookingButton from './BookingButton'
import BoxDate from './BoxDate'
import Filter from './Filter'
import Table from './Table'
import TableStaff from './TableStaff'
import useSchedule from './useSchedule'

//@ts-ignore
import Scrollbar from '../../../atoms/Scrollbar'

interface ScheduleStyle extends CSSProperties {}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`

const GridBox = styled.div<ScheduleStyle>`
  display: grid;
  grid-template-columns: minmax(0, 50%) minmax(0, 50%);
  width: 100%;
`

const Schedule = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    date,
    currentDate,
    storeOpenCloseTime,
    staffOrders,
    roomOrders,
    startStaffLoading,
    tableActive,
    roomTypes,
    bookingPerStaff,
    unassignedStaffBooking,
    onCurrentDateClick,
    onDateChange,
    onStaffWorkStatusClick,
    onSwitchTable,
    onBookingClick,
  } = useSchedule()

  return (
    <Container>
      <Scrollbar id='Schedule' direction='xy' gap='1rem'>
        <GridBox>
          <BoxDate
            date={date}
            currentDate={currentDate}
            _onDateChange={onDateChange}
            onCurrentDateClick={onCurrentDateClick}
          />
          <BookingButton
            date={date}
            currentDate={currentDate}
            onBookingClick={onBookingClick}
          />
        </GridBox>
        <Filter tableActive={tableActive} onSwitchTable={onSwitchTable} />
        <Table
          bookingPerStaff={bookingPerStaff}
          unassignedStaffBooking={unassignedStaffBooking}
          date={date}
          currentDate={currentDate}
          storeOpenCloseTime={storeOpenCloseTime}
          staffOrders={staffOrders}
          roomOrders={roomOrders}
          startStaffLoading={startStaffLoading}
          tableActive={tableActive}
          roomTypes={roomTypes}
          onStaffWorkStatusClick={onStaffWorkStatusClick}
          onBookingClick={onBookingClick}
        />
      </Scrollbar>
    </Container>
  )
}

export default Schedule
