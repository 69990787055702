import React from 'react'

import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddCommissionFee from '../settingStaff/CardAddCommissionFee'

const PopupCommissionFee = ({
  handleClose,
  staffTypes,
  staffs,
  currentService,
  currentIdEdit,
  currentDataEdit,
  commissionFees,
}) => {
  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0'>
        <CardAddCommissionFee
          handleClose={handleClose}
          staffTypes={staffTypes}
          staffs={staffs}
          currentService={currentService}
          currentIdEdit={currentIdEdit}
          currentDataEdit={currentDataEdit}
          commissionFees={commissionFees}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupCommissionFee
