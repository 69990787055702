import React from 'react'

interface StaffUnavailableIcon {
  size?: string
  color?: string
}

const StaffUnavailableIcon = ({ size, color }: StaffUnavailableIcon) => (
  <svg
    width={size || '49'}
    height={size || '48'}
    viewBox='0 0 49 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      opacity='0.5'
      d='M24.6758 0C11.4193 0 0.675781 10.7435 0.675781 24C0.675781 37.2565 11.4193 48 24.6758 48C37.9323 48 48.6758 37.2565 48.6758 24C48.6758 10.7435 37.9323 0 24.6758 0ZM24.6758 44.4706C20.2075 44.4706 16.0711 43.0306 12.704 40.5882L16.4381 36.8541C17.1299 36.1624 17.1299 35.0471 16.4381 34.3553C15.7464 33.6635 14.6311 33.6635 13.9393 34.3553L10.0217 38.2729C6.42872 34.5812 4.20519 29.5482 4.20519 24C4.20519 12.7129 13.3887 3.52941 24.6758 3.52941C30.224 3.52941 35.257 5.75294 38.9487 9.34588L26.2075 22.0871C25.5158 22.7788 25.5158 23.8941 26.2075 24.5859C26.5534 24.9318 27.0052 25.1012 27.457 25.1012C27.9087 25.1012 28.3605 24.9318 28.7064 24.5859L41.264 12.0282C43.7064 15.3953 45.1464 19.5318 45.1464 24C45.1464 35.2871 35.9628 44.4706 24.6758 44.4706Z'
      fill={color || '#FF5152'}
    />
    <path
      d='M27.5974 22.9129C30.0609 21.7552 31.7833 19.1858 31.7833 16.1929C31.7833 12.1199 28.6068 8.81641 24.6821 8.81641C20.7574 8.81641 17.5809 12.1199 17.5809 16.1929C17.5809 19.1858 19.2962 21.7552 21.7668 22.9129C15.4986 23.8023 12.668 27.3317 12.668 27.3317V37.807H36.7033V27.3317C36.7033 27.3317 33.8727 23.7952 27.6044 22.9129H27.5974ZM24.675 12.3529C26.6444 12.3529 28.2468 14.0752 28.2468 16.1999C28.2468 18.3246 26.6444 20.047 24.675 20.047C22.7056 20.047 21.1033 18.3246 21.1033 16.1999C21.1033 14.0752 22.7056 12.3529 24.675 12.3529ZM33.1668 34.2846H16.1903V28.8423C17.4397 27.8541 20.1715 26.2446 24.6821 26.2446C29.1927 26.2446 31.9244 27.8541 33.1739 28.8423V34.2846H33.1668Z'
      fill={color || '#8C56D9'}
    />
    <path
      d='M35.9715 46.943C39.6751 46.943 42.6774 43.9407 42.6774 40.2371C42.6774 36.5336 39.6751 33.5312 35.9715 33.5312C32.268 33.5312 29.2656 36.5336 29.2656 40.2371C29.2656 43.9407 32.268 46.943 35.9715 46.943Z'
      fill={color || '#FF5152'}
    />
    <path
      d='M33.9498 42.9614C33.7663 42.9614 33.5898 42.8908 33.4487 42.7567C33.1734 42.4814 33.1734 42.0367 33.4487 41.7614L35.2628 39.9473V37.3426C35.2628 36.9544 35.5804 36.6367 35.9687 36.6367C36.3569 36.6367 36.6745 36.9544 36.6745 37.3426V40.2367C36.6745 40.4273 36.5969 40.6038 36.4698 40.7379L34.451 42.7567C34.3098 42.8979 34.1334 42.9614 33.9498 42.9614Z'
      fill={color || 'white'}
    />
  </svg>
)

export default StaffUnavailableIcon
