import React, { useEffect, useState } from 'react'

import Body from './Body'
import Head from './Head'

import PopupAddPackage from '../popups/PopupAddPackage'

//@ts-ignore
import api from '../../api'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  const [create, setCreate] = useState(false)
  const [packages, setPackages] = useState([])
  const [currentData, setCurrentData] = useState({ _id: -1 })

  useEffect(() => {
    callApiPackages()
  }, [])

  const callApiPackages = async () => {
    try {
      const { data } = await api.getPackageByStoreId()
      setPackages(data)
    } catch (err) {
      console.log(err)
    }
  }

  const onCreateClick = () => {
    setCreate(!create)
  }

  const onClose = async () => {
    setCreate(false)
    setCurrentData({ _id: -1 })
    await callApiPackages()
  }

  const onEditClick = (index: number) => {
    setCurrentData(packages[index])
  }

  return (
    <Page>
      <Head onCreateClick={onCreateClick} />
      <Body packages={packages} onEditClick={onEditClick} />
      {(create || currentData._id !== -1) && (
        <PopupAddPackage onClose={onClose} currentData={currentData} />
      )}
    </Page>
  )
}

export default Main
