import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import logicRoomOrder from '@/components/booking/logicRoomOrder'
import logicStaffOrder from '@/components/booking/logicStaffOrder'
import {
  filterGenderScheduleAtom,
  scheduleCurrentDate,
  scheduleDate,
  schedulerBookingHistoriesAtom,
  schedulerBookingHistoriesFnAtom,
  searchScheduleAtom,
} from '@/jotai/SchedulerAtom'
import { BookingHistory, TransactionTypes } from '@/redux/models/BookingHistory'
import {
  BookOrder,
  CustomerOrder,
  getInitialCustomerBookOrder,
  getInitialServiceBookOrder,
} from '@/redux/models/BookOrder'
import utils from '@/utils'

import { CLOSE_OFFSET } from './constants'
import { RoomOrderState, StaffOrderState } from './types'
import { ITableData } from './useTable'

//@ts-ignore
import api from '../../../api'
import auth from '../../../auth'
import {
  bookHistoryToBookOrder,
  getOrderBooking,
} from '../../../redux/actions/bookingHistoryAction'
import Room from '../../../redux/models/Room'
import RoomType from '../../../redux/models/RoomType'
import Staff from '../../../redux/models/Staff'
import StaffQueue from '../../../redux/models/StaffQueue'
import { BOOKING_URL } from '../../../routes'

export interface RoomsState extends RoomType {
  rooms: Room[]
}

const useSchedule = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [searchTerm, setSearchTerm] = useAtom(searchScheduleAtom)
  const [gender, setGender] = useAtom(filterGenderScheduleAtom)

  const [date, setDate] = useAtom(scheduleDate)

  const [bookingHistories, fetchBookingHistories] = useAtom(schedulerBookingHistoriesFnAtom)

  const [currentDate, setCurrentDate] = useAtom(scheduleCurrentDate)
  const [storeOpenCloseTime, setStoreOpenCloseTime] = useState({
    openTime: { hour: '', minute: '' },
    closeTime: { hour: '', minute: '' },
  })
  // const [currentStoreOpenCloseTime, setCurrentStoreOpenCloseTime] = useState({
  //   openTime: { hour: '', minute: '' },
  //   closeTime: { hour: '', minute: '' },
  // })

  const [tableActive, setTableActive] = useState<string>('staff')

  // const [staffs, setStaffs] = useState<Staff[]>([])
  const [staffOrders, setStaffOrders] = useState<StaffOrderState[]>([])
  const [roomOrders, setRoomOrders] = useState<RoomOrderState[]>([])
  const [startStaffLoading, setStartStaffLoading] = useState<boolean>(false)
  const [queueId, setQueueId] = useState<number>(-1)

  const [roomTypes, setRoomTypes] = useState<RoomsState[]>([])

  const [bookOrders, setBookOrders] = useState<BookOrder[]>([])

  useEffect(() => {
    // setBookOrders(createMockBookOrders(50))
    setDate(new Date())
  }, [])

  const user = useSelector((state: any) => state.user)

  useEffect(() => {
    if (user.storeId) {
      checkCurrentDate()
      callApiRooms()
    }
  }, [user.storeId])

  const checkCurrentDate = async () => {
    try {
      const { data, success } = await api.getStoreDateTimeByDateV2(new Date(), {
        withinDay: true,
      })
      if (success) {
        setCurrentDate(new Date(data.openDateTime))
        // setDate(new Date(data.openDateTime))
        setStoreOpenCloseTime({
          openTime: {
            hour: new Date(data.openDateTime).getHours() + '',
            minute: new Date(data.openDateTime).getMinutes() + '',
          },
          closeTime: {
            hour:
              new Date(data.closeDateTime).getHours() <
              new Date(data.openDateTime).getHours()
                ? new Date(data.closeDateTime).getHours() + 24 + ''
                : new Date(data.closeDateTime).getHours() + '',
            minute: new Date(data.closeDateTime).getMinutes() + '',
          },
        })
        // setCurrentStoreOpenCloseTime({
        //   openTime: {
        //     hour: new Date(data.openDateTime).getHours() + '',
        //     minute: new Date(data.openDateTime).getMinutes() + '',
        //   },
        //   closeTime: {
        //     hour:
        //       new Date(data.closeDateTime).getHours() <
        //       new Date(data.openDateTime).getHours()
        //         ? new Date(data.closeDateTime).getHours() + 24 + ''
        //         : new Date(data.closeDateTime).getHours() + '',
        //     minute: new Date(data.closeDateTime).getMinutes() + '',
        //   },
        // })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiRooms = async () => {
    try {
      const { data } = await api.getRoomTypeAndRoom()
      setRoomTypes(data)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiBookingHistories = async (date: Date, currentDate: Date) => {
    try {
      const { data: services } = await api.getServiceList()

      let data = await fetchBookingHistories()

      data = data.filter((d: BookingHistory) => d.statusId !== 6)

      const bookOrder = data.reduce(
        (acc: BookOrder[], info: BookingHistory) => {
          acc.push(bookHistoryToBookOrder(info, services))
          return acc
        },
        []
      )

      setBookOrders(bookOrder)
      createStaffOrder(date, currentDate, data)
      createRoomOrder(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const fetch = async () => {
      if (bookingHistories.length) {
        const { data: services } = await api.getServiceList()
        let data = bookingHistories.filter((d: BookingHistory) => d.statusId !== 6)
        const bookOrder = data.reduce(
          (acc: BookOrder[], info: BookingHistory) => {
            acc.push(bookHistoryToBookOrder(info, services))
            return acc
          },
          []
        )
        createStaffOrder(date, currentDate, data)
        setBookOrders(bookOrder)
        createRoomOrder(data)
      }
    }
    fetch()
  }, [bookingHistories])

  useEffect(() => {
    checkStoreOpenCloseTime(date, currentDate)
    callApiBookingHistories(date, currentDate)
  }, [date, currentDate])

  const checkStoreOpenCloseTime = async (date: Date, currentDate: Date) => {
    try {
      if (!dayjs(currentDate).isSame(date, 'date')) {
        const storeOpenCloseTime = await callApiStore(date)
        setStoreOpenCloseTime({
          openTime: storeOpenCloseTime.openTime,
          closeTime: {
            hour:
              +storeOpenCloseTime.closeTime.hour <
              +storeOpenCloseTime.openTime.hour
                ? +storeOpenCloseTime.closeTime.hour + 24 + ''
                : storeOpenCloseTime.closeTime.hour,
            minute: storeOpenCloseTime.closeTime.minute,
          },
        })
      } else {
        const storeOpenCloseTime = await callApiStore(currentDate)
        setStoreOpenCloseTime({
          openTime: storeOpenCloseTime.openTime,
          closeTime: {
            hour:
              +storeOpenCloseTime.closeTime.hour <
              +storeOpenCloseTime.openTime.hour
                ? +storeOpenCloseTime.closeTime.hour + 24 + ''
                : storeOpenCloseTime.closeTime.hour,
            minute: storeOpenCloseTime.closeTime.minute,
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiStore = async (date: Date) => {
    try {
      const { data, success } = await api.getStoreDateTimeByDate(
        dayjs(date).get('day') === 0 ? 6 : dayjs(date).get('day') - 1,
        dayjs(date).toDate()
      )
      if (success && data.length) return data[0]
    } catch (err) {
      console.log(err)
    }
  }

  const createStaffOrder = async (
    date: Date,
    currentDate: Date,
    bookingHistory?: BookingHistory[]
  ) => {
    try {
      let staffCome: Staff[] = []
      let staffNotCome: Staff[] = []

      if (!dayjs(currentDate).isSame(date, 'date')) {
        setQueueId(-1)

        const staffsQueue = await callApiStaffsByQueue(date)
        const day =
          dayjs(date).get('day') === 0 ? 6 : dayjs(date).get('day') - 1

        if (staffsQueue.length) {
          if (dayjs(date).isAfter(currentDate, 'date')) {
            const { queue }: StaffQueue = staffsQueue[0]

            staffCome = queue
              .filter(
                q =>
                  q.staff !== undefined &&
                  q.staff.workingDay.some(
                    workingDay =>
                      workingDay.day === day && workingDay.workingStatus !== -1
                  )
              )
              .map(s => ({ ...s.staff! }))
          } else {
            const { data: staffWorkSchedule } =
              await api.getStaffWorkScheduleByStaffAndDate(date)
            staffCome = staffWorkSchedule
          }
          // setStaffs(staffCome)
        } else {
          const staffsQueue = await callApiStaffsByQueue(currentDate)
          const { queue }: StaffQueue = staffsQueue[0]

          staffCome = queue
            .filter(q => q.staff !== undefined)
            .map(s => ({ ...s.staff! }))
        }
      } else {
        const staffsQueue = await callApiStaffsByQueue(currentDate)
        if (staffsQueue.length) {
          setQueueId(staffsQueue[0]._id)

          const { queue }: StaffQueue = staffsQueue[0]

          staffCome = queue
            .filter(q => q.staff !== undefined && q.staff.workingStatus === 1)
            .map(s => ({ ...s.staff! }))

          staffNotCome = queue
            .filter(q => q.staff !== undefined && q.staff.workingStatus === -1)
            .map(s => ({ ...s.staff! }))
          // setStaffs(staffCome.concat(staffNotCome))
        }
      }
      if (bookingHistory) {
        const staffOrders = logicStaffOrder.createStaffOrder(
          bookingHistory,
          staffCome.concat(staffNotCome)
        )
        setStaffOrders(staffOrders)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const createRoomOrder = async (bookingHistory: BookingHistory[]) => {
    try {
      const { data: roomTypes } = await api.getRoomTypeAndRoom()
      const roomOrders = logicRoomOrder.createRoomOrders(
        bookingHistory,
        roomTypes
      )
      setRoomOrders(roomOrders)
    } catch (err) {
      console.log(err)
    }
  }

  const callApiStaffsByQueue = async (date: Date) => {
    try {
      const { data: staffsQueue } = await api.getStaffDataByQueue(
        dayjs(date).toDate()
      )

      return staffsQueue
    } catch (err) {
      console.log(err)
    }
  }

  const onCurrentDateClick = (date: Date) => {
    setDate(currentDate)
    // setStoreOpenCloseTime(currentStoreOpenCloseTime)
  }

  const onDateChange = (date: Date) => {
    setDate(date)
  }

  const onStaffWorkStatusClick = async (
    staffId: number,
    workingStatus: number
  ) => {
    try {
      if (queueId !== -1) {
        setStartStaffLoading(true)
        const { success } = await api.manageStaffQueue({
          queueId,
          timestamp: new Date(),
          date: currentDate,
          staffId: staffId,
          storeId: auth.getStoreId(),
          workingStatus,
        })
        if (success) {
          setStartStaffLoading(false)
          createStaffOrder(date, currentDate, bookingHistories)
        } else {
          setStartStaffLoading(false)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onSwitchTable = () => {
    setTableActive(tableActive === 'staff' ? 'room' : 'staff')
  }

  const onBookingClick = async (
    transactionType: string,
    staffId?: number,
    time?: string
  ) => {
    try {
      const { data, success } = await api.createBookingCode()
      if (success) {
        let _time = {
          hour: new Date().getHours() + '',
          minute: new Date().getMinutes() + '',
        }
        let customers: CustomerOrder[] = [getInitialCustomerBookOrder()]
        if (staffId && time) {
          const [_hour, _minute] = time.split(':')
          if (+_hour > 23) {
            _time = {
              hour: utils.formatTime(+_hour - 24),
              minute: utils.formatTime(_minute),
            }
          } else {
            _time = {
              hour: _hour,
              minute: _minute,
            }
          }

          customers = [
            {
              ...getInitialCustomerBookOrder(),
              services: [
                {
                  ...getInitialServiceBookOrder(),
                  staffs: [
                    {
                      staffTypeId: -1,
                      staffId: staffId,
                      staffName: '',
                      staffImage: '',
                      requestStaff: false,
                      queueFree: false,
                      duration: 0,
                      tip: 0,
                    },
                  ],
                },
              ],
            },
          ]
          // return false
        }
        dispatch(
          getOrderBooking({
            date: date,
            time: _time,
            endTime: _time,
            bookingCode: data,
            transactionType:
              transactionType === 'product'
                ? TransactionTypes.Product
                : transactionType === 'package'
                ? TransactionTypes.Package
                : TransactionTypes.Service,
            buyProduct: transactionType === 'product',
            buyPackage: transactionType === 'package',
            customers,
            selectStaff: staffId ? true : false,
          })
        )
      }
    } catch (err) {
      console.log(err)
    }

    return history.replace(BOOKING_URL)
  }

  const bookingPerDate = useMemo(() => {
    // order between start and end store time
    const startStoreTime = dayjs(date)
      .hour(+storeOpenCloseTime.openTime.hour)
      .minute(+storeOpenCloseTime.openTime.minute)
    const endStoreTime = dayjs(date)
      .hour(+storeOpenCloseTime.closeTime.hour + CLOSE_OFFSET)
      .minute(+storeOpenCloseTime.closeTime.minute)

    const orders = bookOrders.filter(order => {
      const orderTime = dayjs(order.date)
      return (
        orderTime.isAfter(startStoreTime) && orderTime.isBefore(endStoreTime)
      )
    })

    return orders
  }, [date, bookOrders, storeOpenCloseTime])

  const bookingPerStaff: ITableData = useMemo(() => {
    let result = {} as ITableData
    for (let bookingIndex in bookingPerDate) {
      const booking = bookingPerDate[bookingIndex]

      for (const customer of booking.customers) {
        for (let serviceIndex in customer.services) {
          const service = customer.services[serviceIndex]
          if (!service.staffs || !service.staffs.length) {
            if (!result[-1]) {
              result[-1] = []
            }
            result[-1].push({
              booking,
              orders: {
                serviceOrders: [service],
              },
            })
          }
          for (let staffIndex in service.staffs) {
            const staff = service.staffs[staffIndex]
            if (!result[staff.staffId]) {
              result[staff.staffId] = []
            }
            if (
              result?.[staff.staffId].findIndex(
                (item: any) => item.booking._id === booking._id
              ) === -1
            ) {
              result[staff.staffId].push({
                booking,
                orders: {
                  staffOrder: staff,
                  serviceOrders: [service],
                },
              })
            } else {
              result[staff.staffId]
                .find((item: any) => item.booking._id === booking._id)
                ?.orders.serviceOrders.push(service)
            }
          }
          let lastResult = {} as ITableData
          for (let staffIndex in result) {
            if (result[staffIndex].length) {
              lastResult[staffIndex] = {
                ...result[staffIndex],
              }
            }
          }
        }
      }
    }
    return result
  }, [bookingPerDate])

  const unassignedStaffBooking = bookingPerStaff[-1] || []

  const filterStaffs = useMemo(() => {
    // filter for searchTerm and gender
    const result = staffOrders.filter(staff => {
      return (
        (searchTerm
          ? staff.staffName.toLowerCase().includes(searchTerm.toLowerCase())
          : true) && (gender === null ? true : staff.gender === gender)
      )
    })
    return result
  }, [gender, searchTerm, staffOrders])

  return {
    date,
    currentDate,
    storeOpenCloseTime,
    staffs: filterStaffs,
    startStaffLoading,
    tableActive,
    roomTypes,
    staffOrders: filterStaffs,
    roomOrders,
    onCurrentDateClick,
    onDateChange,
    onStaffWorkStatusClick,
    onSwitchTable,
    onBookingClick,
    bookOrders,
    bookingPerDate,
    bookingPerStaff,
    unassignedStaffBooking,
  }
}

export default useSchedule
