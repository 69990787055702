import React from 'react'

const RevenueIcon = ({ size, color }) => (
  <svg
    width={size || '26'}
    height={size || '24'}
    viewBox='0 0 26 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_1345_522)'>
      <path
        d='M16.6034 8.18005C16.6034 7.58988 16.21 7.03906 15.4231 7.03906H14.1641V9.32103H15.4624C16.1706 9.32103 16.6034 8.8489 16.6034 8.18005Z'
        fill={color || '#475467'}
      />
      <path
        d='M16.0526 10.3047H14.1641V12.5867H16.0526C16.8001 12.5867 17.1936 12.0358 17.1936 11.4457C17.1936 10.7768 16.7608 10.3047 16.0526 10.3047Z'
        fill={color || '#475467'}
      />
      <path
        d='M15.15 0C9.60243 0 5.11719 4.48525 5.11719 10.0328C5.11719 10.5049 5.15653 10.977 5.23522 11.4492C5.3926 11.4492 5.54997 11.4098 5.70735 11.4098C9.52374 11.4098 12.5926 14.4787 12.5926 18.2951C12.5926 18.7672 12.5533 19.2393 12.4352 19.7115C13.3008 19.9475 14.2057 20.0656 15.15 20.0656C20.6975 20.0656 25.1828 15.5803 25.1828 10.0328C25.1828 4.48525 20.6975 0 15.15 0ZM16.5664 13.8885H15.7795V14.7541C15.7795 15.0295 15.5434 15.2262 15.268 15.2262C14.9926 15.2262 14.7565 15.0295 14.7565 14.7541V13.8885H13.3008C12.8287 13.8885 12.4352 13.4951 12.4352 13.023V6.57049C12.4352 6.09836 12.8287 5.70492 13.3008 5.70492H14.7565V4.83934C14.7565 4.56393 14.9926 4.36721 15.268 4.36721C15.5434 4.36721 15.7795 4.56393 15.7795 4.83934V5.70492H15.9369C17.4713 5.70492 18.2975 6.57049 18.2975 7.9082C18.2975 8.65574 17.9434 9.20656 17.4319 9.6C18.2975 9.91475 18.8877 10.623 18.8877 11.6459C18.8877 12.9049 18.1401 13.8885 16.5664 13.8885Z'
        fill={color || '#475467'}
      />
      <path
        d='M5.70492 12.5938C2.55738 12.5938 0 15.1511 0 18.2987C0 21.4462 2.55738 24.0036 5.70492 24.0036C8.85246 24.0036 11.4098 21.4462 11.4098 18.2987C11.4098 15.1511 8.85246 12.5938 5.70492 12.5938ZM8.14426 18.5741C8.02623 18.6921 7.9082 18.7315 7.75082 18.7315C7.59344 18.7315 7.47541 18.6921 7.35738 18.5741L6.05902 17.2757V20.6987C6.05902 21.0134 5.82295 21.2495 5.5082 21.2495C5.19344 21.2495 4.95738 21.0134 4.95738 20.6987V17.2757L3.65902 18.5741C3.42295 18.8101 3.06885 18.8101 2.87213 18.5741C2.63607 18.338 2.63607 17.9839 2.87213 17.7872L5.11475 15.5446C5.1541 15.5052 5.23279 15.4659 5.31148 15.4265C5.35082 15.3478 5.42951 15.3478 5.5082 15.3478C5.58688 15.3478 5.66557 15.3478 5.70492 15.3872C5.78361 15.4265 5.82295 15.4659 5.90164 15.5052L8.14426 17.7478C8.38033 17.9839 8.38033 18.338 8.14426 18.5741Z'
        fill={color || '#475467'}
      />
    </g>
    <defs>
      <clipPath id='clip0_1345_522'>
        <rect width='25.1803' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default RevenueIcon
