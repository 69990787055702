import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// @ts-ignore
import api from '../../api'
import { AgeRanges, Genders } from '../../redux/models/Customer'
import ReduxStore from '../../redux/models/ReduxStore'

const ageMap = {
  [AgeRanges.NotSpecified]: 'ไม่ระบุ',
  [AgeRanges.Young]: 'น้อยกว่า 18',
  [AgeRanges.YoungAdult]: '18 - 30',
  [AgeRanges.Adult]: '30 - 50',
  [AgeRanges.Elder]: 'มากกว่า 50',
}

const genderMap = {
  [Genders.NotSpecified]: 'ไม่ระบุ',
  [Genders.Male]: 'ชาย',
  [Genders.Female]: 'หญิง',
  [Genders.Other]: 'อื่นๆ',
}

interface DataPoint {
  _id: number
  count: number
  value: number
  name: string
}

interface ReportResponse {
  cashPayment: number
  transferPayment: number
  creditPayment: number
  eWalletPayment: number
  agencyPayment: number
  packagePayment: number
  serviceIncome: number
  packageIncome: number
  productIncome: number
  productDiscount: number
  serviceDiscount: number
  packageDiscount: number
  totalTip: number
  specialRevenue: number
  totalCharge: number
  otherIncome: number
  totalIncome: number
  totalBill: number
  totalCustomer: number
  averageIncome: number
  totalCommissionFee: number
  totalCommission: number
  totalDiscount: number
  specialExpense: number
  totalCost: number
  daySummary: DataPoint[]
  monthSummary: DataPoint[]
  hourSummary: DataPoint[]
  genderSummary: DataPoint[]
  ageSummary: DataPoint[]
  nationalitySummary: DataPoint[]
  agencySummary: DataPoint[]
  serviceSummary: DataPoint[]
  bookingTypeSummary: DataPoint[]
  branchSummary: DataPoint[]
  packageSummary: DataPoint[]
}

interface State extends ReportResponse {
  startDate: Date
  endDate: Date
  isLoading: boolean
  isInitialized: boolean
  isShown: boolean
}

const useReport = () => {
  const [state, setState] = useState<State>({
    startDate: new Date(),
    endDate: new Date(),
    isLoading: true,
    isInitialized: false,
    isShown: false,
    cashPayment: 0,
    transferPayment: 0,
    creditPayment: 0,
    eWalletPayment: 0,
    agencyPayment: 0,
    packagePayment: 0,
    serviceIncome: 0,
    packageIncome: 0,
    productIncome: 0,
    productDiscount: 0,
    serviceDiscount: 0,
    packageDiscount: 0,
    totalTip: 0,
    specialRevenue: 0,
    totalCharge: 0,
    otherIncome: 0,
    totalIncome: 0,
    totalCommissionFee: 0,
    totalCommission: 0,
    totalDiscount: 0,
    specialExpense: 0,
    totalCost: 0,
    totalCustomer: 0,
    totalBill: 0,
    averageIncome: 0,
    daySummary: [],
    monthSummary: [],
    hourSummary: [],
    genderSummary: [],
    ageSummary: [],
    nationalitySummary: [],
    agencySummary: [],
    serviceSummary: [],
    bookingTypeSummary: [],
    branchSummary: [],
    packageSummary: [],
  })

  const {
    i18n: { language },
  } = useTranslation()

  const { startDate, endDate, isInitialized } = state

  const store = useSelector((state: ReduxStore) => state.store)
  const { nationalities, branches } = useSelector(
    (state: ReduxStore) => state.brand
  )

  useEffect(() => {
    const callApiServices = async () => {
      try {
        const { data } = await api.getServices()
        setState(prev => ({ ...prev, services: data }))
      } catch (err) {
        console.log(err)
      }
    }

    const callApiProduct = async () => {
      try {
        const { data } = await api.getProduct()
        setState(prev => ({ ...prev, products: data }))
      } catch (err) {
        console.log(err)
      }
    }

    const init = async () => {
      await Promise.all([callApiServices(), callApiProduct()])
      setState(prev => ({ ...prev, isInitialized: true }))
    }
    init()
  }, [])

  useEffect(() => {
    if (!isInitialized) return

    if (!nationalities.length || !branches.length) return

    const callApi = async (startDate: Date, endDate: Date) => {
      setState(prev => ({ ...prev, isLoading: true }))
      const result = await api.getBookingHistorySummary({
        dateStart: dayjs(startDate).format(),
        dateEnd: dayjs(endDate).format(),
      })
      const {
        ageSummary,
        genderSummary,
        nationalitySummary,
        serviceSummary,
        bookingTypeSummary,
        daySummary,
        monthSummary,
        branchSummary,
        agencySummary,
      } = result.data as ReportResponse
      setState(prev => ({
        ...prev,
        ...result.data,
        ageSummary: ageSummary.map(v => ({
          ...v,
          name: ageMap[v._id] || 'ไม่ระบุ',
        })),
        genderSummary: genderSummary.map(v => ({
          ...v,
          name: genderMap[v._id] || 'ไม่ระบุ',
        })),
        nationalitySummary: nationalitySummary.map(v => ({
          ...v,
          name:
            nationalities.find(({ _id }) => _id === v._id)?.nationality ||
            'ไม่ระบุ',
        })),
        serviceSummary: serviceSummary.map(v => ({
          ...v,
          name: v.name || 'ไม่ระบุ',
        })),
        bookingTypeSummary: bookingTypeSummary.map(v => ({
          ...v,
          name: v._id || 'Walk-in',
        })),
        daySummary: daySummary.map(v => ({
          ...v,
          name: dayjs()
            .set('day', v._id - 1)
            .locale(language)
            .format('dd'),
        })),
        monthSummary: monthSummary.map(v => ({
          ...v,
          name: dayjs()
            .set('month', v._id - 1)
            .locale(language)
            .format('MMM'),
        })),
        branchSummary: branchSummary.map(v => ({
          ...v,
          name: branches.find(({ _id }) => _id === v._id)?.branch || '-',
        })),
        agencySummary: agencySummary.map(v => ({
          ...v,
          name: v._id,
        })),
        isLoading: false,
      }))
    }

    callApi(startDate, endDate)
  }, [startDate, endDate, isInitialized, language, nationalities, branches])

  const onDateChange = async (startDate: Date, endDate: Date, type: number) => {
    if (type === 1) {
      setState(prev => ({ ...prev, startDate }))
    } else if (type === 2) {
      setState(prev => ({ ...prev, endDate }))
    } else {
      setState(prev => ({ ...prev, startDate, endDate }))
    }
  }

  const onDownloadClick = async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }))
      const data = await api.downloadReport(startDate, endDate)
      const blob = new Blob([new Uint8Array(data.data.data.data)], {
        type: 'text/csv;charset=utf8',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      const isSameDate = dayjs(startDate).isSame(endDate, 'day')
      const format = 'YYYY-MM-DD'
      const suffix = `${dayjs(startDate).format(format)}${
        isSameDate ? '' : `-${dayjs(endDate).format(format)}`
      }`
      link.download = `report_${store.name}-${store.branch}_${suffix}.xlsx`
      link.click()
    } catch (err) {
      console.log(err)
    } finally {
      setState(prev => ({ ...prev, isLoading: false }))
    }
  }

  const onToggle = () => {
    setState(prev => ({ ...prev, isShown: !prev.isShown }))
  }

  return {
    ...state,
    onDateChange,
    onDownloadClick,
    onToggle,
    filterData: {},
    onFilterClick: () => {},
  }
}

export default useReport
