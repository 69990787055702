import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

//@ts-ignore
import Button2 from '../../atoms/Button2'
import InputText from '../../atoms/InputText'
import SaveIcon from '../../atoms/SaveIcon'
//@ts-ignore
import SuccessIcon2 from '../../atoms/SuccessIcon2'
//@ts-ignore
import SwitchButton from '../../atoms/SwitchButton'
import TextLabel from '../../atoms/TextLabel'

//@ts-ignore
import api from '../../api'
import { getPackageType } from '../../redux/models/BrandPackage'
import utils from '../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  gap: 0.5rem;
`
const FlexBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${props => props.justifyContent || 'unset'};
`

const CardPackageDetail = ({ handleClose, currentPackage }) => {
  const { t } = useTranslation()

  const [customerPackage, setCustomerPackage] = useState({
    services: [],
    buyDate: new Date(),
  })
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setCustomerPackage(currentPackage)
  }, [currentPackage])

  const onChangeValue = async (value, name) => {
    try {
      if (utils.checkNumber(value)) {
        setCustomerPackage(prev => ({
          ...prev,
          [name]: value,
        }))
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onSaveClick = async () => {
    try {
      const { success } = await api.editCustomerPackage(currentPackage._id, {
        status: customerPackage.status,
        remainingValue: customerPackage.remainingValue,
        expiryDay: customerPackage.expiryDay,
      })
      if (success) {
        setSuccess(success)
        setTimeout(() => {
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <TextLabel fontSize='1.5rem' fontWeight='500'>
        {customerPackage.name}
      </TextLabel>
      <FlexBox>
        <TextLabel>{`${t('packageType')}:`}</TextLabel>
        <TextLabel>{getPackageType(customerPackage.type)}</TextLabel>
      </FlexBox>
      <FlexBox>
        <TextLabel>{`${t('service')}:`}</TextLabel>
        <TextLabel>
          {customerPackage
            ? customerPackage.allService
              ? 'ทุกบริการ'
              : customerPackage.services.map(({ name, timePrices }) => (
                  <div>
                    <TextLabel>{name} </TextLabel>
                    <TextLabel>
                      {timePrices.map(({ time }) => time).join(', ')}
                    </TextLabel>
                  </div>
                ))
            : ''}
        </TextLabel>
      </FlexBox>
      <FlexBox>
        <TextLabel>{`${t('startingAmount')}:`}</TextLabel>
        <TextLabel>{customerPackage.totalValue}</TextLabel>
      </FlexBox>
      <TextLabel>{t('remainingAmount')}</TextLabel>
      <InputText
        value={customerPackage.remainingValue}
        onChange={val => onChangeValue(val.target.value, 'remainingValue')}
      />
      <TextLabel>{t('expiryPeriod(Dates)FromPurchasingDate')}</TextLabel>
      <InputText
        value={customerPackage.expiryDay}
        onChange={val => onChangeValue(val.target.value, 'expiryDay')}
      />
      <FlexBox>
        <TextLabel>{t('expiryDate')}</TextLabel>
        <TextLabel>
          {utils.formatDate(
            dayjs(customerPackage.buyDate).add(
              customerPackage.expiryDay,
              'day'
            ),
            'DD/MM/YYYY'
          )}
        </TextLabel>
      </FlexBox>
      <FlexBox justifyContent='space-between'>
        <TextLabel fontWeight='500'>{`${t('status')} ${t(
          'on/Off'
        )}`}</TextLabel>
        <SwitchButton
          name='edit-customerPackage'
          toggled={customerPackage.status === 1}
          onChange={() =>
            setCustomerPackage(prev => ({
              ...prev,
              status: customerPackage.status === 1 ? -1 : 1,
            }))
          }
          backgroundColor={customerPackage.status === 1 ? '#8C56D9' : '#ABABAD'}
          width='18px'
          labelHeight='20px'
          labelWidth='40px'
        />
      </FlexBox>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          justifyContent: 'flex-end',
          position: 'sticky',
          bottom: '0rem',
          background: 'white',
          zIndex: '2',
        }}
      >
        <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
          <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
            <Button2
              color='#98A1B2'
              border='2px solid #98A1B2'
              backgroundColor='white'
              fontWeight='600'
              onClick={() => handleClose(true)}
            >
              {t('cancel')}
            </Button2>
          </div>
          <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
            <Button2
              border='2px solid #53389E'
              color='#FFFFFF'
              backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
              backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
              fontWeight='600'
              icon={<SaveIcon size='15' />}
              onClick={() => onSaveClick()}
            >
              {t('save')}
            </Button2>
          </div>
        </div>
      </div>
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardPackageDetail
