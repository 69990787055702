import { useDispatch, useSelector } from 'react-redux'

import { getOrderBooking } from '../../../../redux/actions/bookingHistoryAction'
import { BookOrder } from '../../../../redux/models/BookOrder'

const useShowProduct = () => {
  const dispatch = useDispatch()

  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { products }: BookOrder = bookingOrderState

  const onAddProductClick = (type: string, productIndex: number) => {
    const newArrProduct = [...products]

    const price = newArrProduct[productIndex].price

    if (type === 'add') {
      newArrProduct[productIndex].quantity += 1
    } else {
      if (newArrProduct[productIndex].quantity <= 1) {
        newArrProduct.splice(productIndex, 1)
      } else if (newArrProduct[productIndex].quantity > 1) {
        newArrProduct[productIndex].quantity -= 1
      }
    }

    dispatch(
      getOrderBooking({
        products: newArrProduct,
        productCount:
          type === 'add'
            ? bookingOrderState.productCount + 1
            : bookingOrderState.productCount - 1,
        total:
          type === 'add'
            ? bookingOrderState.total + price
            : bookingOrderState.total - price,
      })
    )
  }

  const onRemoveProductClick = (productIndex: number) => {
    const newArrProduct = [...products]
    const price = newArrProduct[productIndex].price
    const quantity = newArrProduct[productIndex].quantity

    newArrProduct.splice(productIndex, 1)

    dispatch(
      getOrderBooking({
        products: newArrProduct,
        productCount: bookingOrderState.productCount - quantity,
        total: bookingOrderState.total - price * quantity,
      })
    )
  }

  return { onAddProductClick, onRemoveProductClick }
}

export default useShowProduct
