import { useState } from 'react'

const useBody = () => {
  // const bookingOrderState = useSelector(
  //   (state: any) => state.bookingHistoryReducers
  // )
  // const { buyPackage } = bookingOrderState || {}
  // const { bookingCode } = bookingOrderState || {}
  // const [booking, setBooking] = useState(false)
  // const [staffId, setStaffId] = useState(-1)
  // const [bookingHistory, setBookingHistory] = useState({})

  // const { nationalityOptions } = useSelector((state: any) => state.brand)
  const [bookingHistories, setBookingHistories] = useState([])

  return { bookingHistories }
}

export default useBody
