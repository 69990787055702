import React from 'react'

interface SearchIconProps {
  size?: string
  color?: string
}

const SearchIcon = ({ size, color }: SearchIconProps) => (
  <svg
    width={size || '49'}
    height={size || '50'}
    viewBox='0 0 49 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 22C0 9.86953 9.64522 0 21.5 0C33.3554 0 43 9.86953 43 22C43 34.1312 33.3554 44 21.5 44C9.64522 44 0 34.1312 0 22ZM3.96923 22.0001C3.96923 31.8913 11.8336 39.9386 21.5 39.9386C31.1664 39.9386 39.0308 31.8912 39.0308 22C39.0308 12.1088 31.1664 4.06154 21.5 4.06154C11.8336 4.06154 3.96923 12.1089 3.96923 22.0001Z'
      fill={color || '#4E4E72'}
    />

    <path
      d='M48.3938 46.4683L36.5318 34.6062C35.7235 33.7979 34.4145 33.7979 33.6062 34.6062C32.7979 35.4138 32.7979 36.7242 33.6062 37.5318L45.4682 49.3938C45.8723 49.7979 46.4014 50 46.931 50C47.46 50 47.9896 49.7979 48.3938 49.3938C49.2021 48.5862 49.2021 47.2758 48.3938 46.4683Z'
      fill={color || '#4E4E72'}
    />
  </svg>
)

export default SearchIcon
