import React from 'react'

import Popup from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddPayChannel from '../settingBook/CardAddPayChannel'

const PopupAddPayChannel = ({
  handleClose,
  currentIdEdit,
  currentPayChannel,
  activeAdd,
  payChannels,
}) => {
  return (
    <Popup width='calc(100%)' height='100%' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0' height='auto'>
        <CardAddPayChannel
          handleClose={handleClose}
          currentIdEdit={currentIdEdit}
          currentPayChannel={currentPayChannel}
          activeAdd={activeAdd}
          payChannels={payChannels}
        />
      </PopupInsideBox>
    </Popup>
  )
}

export default PopupAddPayChannel
