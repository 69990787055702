import React from 'react'
import styled from 'styled-components'

import SwitchButton from '../../atoms/SwitchButton'
import TextLabel from '../../atoms/TextLabel'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const BoxSwitch = styled.div`
  display: flex;
  width: 100%;
`

const BoxSwitchStyle = styled.div`
  width: 50%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`

const SwitchStatus = ({ onSuspendChange, status }) => {
  return (
    <Container>
      <BoxSwitch>
        <BoxSwitchStyle flexDirection='column'>
          <TextLabel fontSize='0.875rem' fontWeight={500}>
            สถานะ
          </TextLabel>
          <TextLabel color='#98A1B2' fontSize='0.8rem'>
            ไม่ใช้งาน / ใช้งาน
          </TextLabel>
        </BoxSwitchStyle>
        <BoxSwitchStyle alignItems='center' justifyContent='flex-end'>
          <SwitchButton
            name='available'
            toggled={status}
            onChange={() => onSuspendChange()}
            backgroundColor={status ? '#8C56D9' : '#ABABAD'}
            labelHeight='20px'
            width='18px'
            labelWidth='45px'
          />
        </BoxSwitchStyle>
      </BoxSwitch>
    </Container>
  )
}

export default SwitchStatus
