import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import styled from "styled-components";

export interface InputGroupProps extends React.HTMLAttributes<HTMLDivElement> {
    label?: string | null
    description?: string | null
    error?: string | null
    required?: boolean
}

export const StyledInputGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    flex: 1;
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #333;
`;

export const RequiredIndicator = styled.span`
  color: var(--danger);
  margin-left: 4px;
`;

export const StyledDescription = styled.span`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const StyledErrorMessage = styled.span`
  font-size: 12px;
  color: var(--danger);
  margin-left: 4px;
`;

export const StyledErrorIcon = styled(FaExclamationCircle)`
    margin-right: 4px;
`;

export const InputGroup: React.FC<InputGroupProps> = ({label, description, error, children, required, id, ...props}) => {
    return (
        <StyledInputGroup {...props}>
            {label && (
          <StyledLabelWrapper>
            <Label htmlFor={id}>{label}</Label>
            {required && <RequiredIndicator>*</RequiredIndicator>}
          </StyledLabelWrapper>
        )}
            {description && <StyledDescription>{description}</StyledDescription>}
            {children}
            {error && (
          <StyledErrorWrapper>
            <StyledErrorIcon size={12} />
            <StyledErrorMessage id={`${id}-error`}>{error}</StyledErrorMessage>
          </StyledErrorWrapper>
        )}
        </StyledInputGroup>
    )
}