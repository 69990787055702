import styled from "styled-components";

interface StyledSpanProps {
    size: 's' | 'm' | 'l';
    weight?: '400' | '600' | '700' | '800' | '900';
    color?: string;
    lineHeight?: string;
}

export const StyledSpan = styled.span<StyledSpanProps>`
  font-size: ${({ size }) => {
    switch (size) {
      case 's':
        return '0.75rem';
      case 'm':
        return '1rem';
      case 'l':
        return '1.25rem';
      default:
        return '1rem';
    }
  }};
  font-weight: ${({ weight }) => weight || '400'};
  color: '#4E4E72';
  line-height: '1.5em';
`;