import React from 'react'

import { DataTable } from '@/components/molecules/dataTable/DataTable'

const TableComponentPage = () => {
  return (
    <DataTable
      headers={[
        { label: 'Name', key: 'name' },
        { label: 'Age', key: 'age' },
        { label: 'Status', key: 'status' },
        { label: 'Action', key: 'action' },
        { label: 'Date', key: 'date' },
        { label: 'Time', key: 'time' },
        { label: 'Balance', key: 'balance' },
        { label: 'Balance', key: 'balance-2' },
        { label: 'Balance', key: 'balance-3' },
        { label: 'Balance', key: 'balance-4' },
        { label: 'Balance', key: 'balance-5' },
        { label: 'Balance', key: 'balance-6' },
        { label: 'Balance', key: 'balance-7' },
      ]}
      columnOptions={{}}
      data={[
        {
          name: 'John Doe',
          age: 20,
          status: 'active',
          action: 'edit',
          date: new Date(),
          time: new Date(),
          balance: 1000,
          'balance-2': 1000,
          'balance-3': 1000,
          'balance-4': 1000,
          'balance-5': 1000,
          'balance-6': 1000,
          'balance-7': 10000000000000,
        },
        {
          name: 'John Doe',
          age: 20,
          status: 'active',
          action: 'edit',
          date: new Date(),
          time: new Date(),
          balance: 1000,
          'balance-2': 1000,
          'balance-3': 1000,
          'balance-4': 1000,
          'balance-5': 1000,
          'balance-6': 1000,
          'balance-7': 1000,
        },
        {
          name: 'John Doe',
          age: 20,
          status: 'active',
          action: 'edit',
          date: new Date(),
          time: new Date(),
          balance: 1000,
          'balance-2': 1000,
          'balance-3': 1000,
          'balance-4': 1000,
          'balance-5': 1000,
          'balance-6': 1000,
          'balance-7': 1000,
        },
        {
          name: 'John Doe',
          age: 20,
          status: 'active',
          action: 'edit',
          date: new Date(),
          time: new Date(),
          balance: 1000,
          'balance-2': 1000,
          'balance-3': 1000,
          'balance-4': 1000,
          'balance-5': 1000,
          'balance-6': 1000,
          'balance-7': 1000,
        },
        {
          name: 'John Doe',
          age: 20,
          status: 'active',
          action: 'edit',
          date: new Date(),
          time: new Date(),
          balance: 1000,
          'balance-2': 1000,
          'balance-3': 1000,
          'balance-4': 1000,
          'balance-5': 1000,
          'balance-6': 1000,
          'balance-7': 1000,
        },
      ]}
    />
  )
}

export default TableComponentPage
