import React, { useState } from 'react'
import styled from 'styled-components'
import RadioButton from '../../atoms/RadioButton'
import TextLabel from '../../atoms/TextLabel'

const Container = styled.div`
  width: 100%;
  display: flex;
`
const Box = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirections || 'column'};
  gap: ${props => props.gap || '0.25rem'};
`
const CancelRemark = ({ gap, remarks, onChangeRemark }) => {
  const [remark, setRemark] = useState(0)

  const onRemarkClick = index => {
    setRemark(index)
    onChangeRemark(index)
  }

  return (
    <Container>
      <Box>
        {remarks.map((r, index) => (
          <Box key={index} gap={gap}>
            <Box flexDirections='row' onClick={() => onRemarkClick(index)}>
              <RadioButton size='20px' checked={remark === index} />
              <TextLabel
                fontWeight={remark === index ? '600' : ''}
                color={remark === index ? '#8C56D9' : ''}
              >
                {r}
              </TextLabel>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  )
}

export default CancelRemark
