import styled from "styled-components/macro";


interface ContainerProps {
    w?: string
    h?: string
    px?: string
    py?: string
    mt?: string
    mb?: string
    ml?: string
    mr?: string
    maxWidth?: string
    display?: 'block' | 'flex' | 'grid'
    flexDirection?: 'row' | 'column'
    gap?: string
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
}

export const Container = styled.div<ContainerProps>`
    width: ${(props) => props.w || '100%'};
    height: ${(props) => props.h || 'auto'};
    padding: ${(props) => props.py || '0'} ${(props) => props.px || '0'};
    margin: ${(props) => props.mt || '0'} ${(props) => props.mr || '0'} ${(props) => props.mb || '0'} ${(props) => props.ml || '0'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    max-width: ${(props) => props.maxWidth || '100%'};
    position: relative;
    display: ${(props) => props.display || 'flex'};
    gap: ${(props) => props.gap || '0'};
    flex-direction: ${(props) => props.flexDirection || 'row'};
    align-items: center;
    justify-content: ${(props) => props.justifyContent || 'center'};
`
