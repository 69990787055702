import React, { useState } from 'react'
import styled from 'styled-components'

import Button3 from '../../atoms/Button3'
import CommissionFeeIcon from '../../atoms/CommissionFeeIcon'
import InputText from '../../atoms/InputText'
import ServiceIcon2 from '../../atoms/ServiceIcon2'
import SearchIcon from '../../atoms/SearchIcon'
import BottleIcon from '../../atoms/BottleIcon'

const Container = styled.div`
  width: calc(100%);
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
`

const IconStyle = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
`
const DivInputStyle = styled.div`
  position: relative;
  padding: 0;
  height: fit-content;
`

const ButtonMenu = ({ onMenuClick, activeMenu, onSearchChange, search }) => {
  const btnMenu = ['บริการ', 'สินค้า', 'จัดเรียง']
  const btnMenuIcon = [
    <ServiceIcon2 size='20' color='#475467' />,
    <BottleIcon size='20' color='#475467' />,
    <CommissionFeeIcon size='20' color='#475467' />,
  ]
  const [activeBtnMenu, setActiveBtnMenu] = useState('บริการ')

  const onButtonClick = menu => {
    setActiveBtnMenu(menu)
    onMenuClick(menu)
  }

  const BtnList = btnMenu.map((button, index) => (
    <div
      key={index}
      style={{ display: 'flex', marginRight: index !== 2 ? '0.5rem' : '0' }}
    >
      <Button3
        backgroundColor='white'
        backgroundColorActive='white'
        padding='0.5rem 0.5rem'
        size='0.9rem'
        border={activeBtnMenu === button ? '1px solid #8C56D9' : ''}
        icon={btnMenuIcon[index]}
        active={activeBtnMenu === button}
        colorIcon='#8C56D9'
        colorActive='#8C56D9'
        color='#475467'
        onClick={() => onButtonClick(button)}
      >
        {button}
      </Button3>
    </div>
  ))

  return (
    <Container>
      <div style={{ display: 'flex' }}>{BtnList}</div>
      {activeMenu !== 'จัดเรียง' && (
        <DivInputStyle>
          <InputText
            placeholder={`ค้นหา${
              activeMenu === 'บริการ' ? 'บริการ' : 'สินค้า'
            }`}
            height='100%'
            padding='0 0rem 0 2rem'
            boxShadow='none'
            value={search}
            onChange={val => onSearchChange(val.target.value)}
          />
          <IconStyle>
            <SearchIcon color='#475467' size='15' />
          </IconStyle>
        </DivInputStyle>
      )}
    </Container>
  )
}

export default ButtonMenu
