import React from 'react'
import styled from 'styled-components'
import TextLabel from '../../atoms/TextLabel'
import DateIcon5 from '../../atoms/DateIcon6'

const Container = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '20%'};
  margin: auto;
  position: fixed;
  z-index: ${props => props.zIndex || '10000'};
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  left: 0px;
`
const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  flex-direction: row;
  width: ${props => props.width || '25%'};
  margin: ${props => props.margin || '0 auto'};
  height: ${props => props.height || 'fit-content'};
  z-index: ${props => props.zIndex || '1000'};
  border-radius: 8px;
  border: 1px solid #db2d1e;
  border-left: 8px solid #db2d1e;
  background: #fff;
  box-shadow: 0px 16px 30px 0px rgba(110, 121, 140, 0.16);
  padding: 1rem;
  gap: 0.5rem;
`
const BoxIcon = styled.div`
  border-radius: 9px;
  background: #f8d5d2;
  padding: 0.35rem 0.5rem;
`

const PopupNotification = ({ notificationCount }) => {
  return (
    <Container>
      <ContainerStyled>
        <BoxIcon>
          <DateIcon5 />
        </BoxIcon>
        <TextLabel color='#DB2D1E' fontWeight='500'>
          {`${notificationCount} การจองใหม่`}
        </TextLabel>
      </ContainerStyled>
    </Container>
  )
}

export default PopupNotification
