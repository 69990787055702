import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Option, { SelectorOption } from '@/redux/models/Option'

//@ts-ignore
import api from '../../api'
import config from '../../config'
import useDebouncedValue from '../../hook/useDebouncedValue'
import { getOrderBooking } from '../../redux/actions/bookingHistoryAction'
//@ts-ignore
import { Customer, getDefaultCustomer } from '../../redux/models/Customer'
//@ts-ignore
import { RootState } from '../../redux/store'
import utils from '../../utils'

interface CustomerFormState {
  searchTerm: string
  hasMoreCustomer: boolean
  page: number
  optionCustomers: Customer[]
  editData: boolean
  customerOptionOpen: boolean
  ageOptionOpen: boolean
  nationalityOptionOpen: boolean
  confirmCustomerPopupOpen: boolean
  foundCustomer: Partial<Customer>
}

const getInitialState = (): Customer => ({
  _id: -1,
  phoneNumber: '',
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  countryCode: '+66',
  nationality: {
    value: -1,
    label: 'สัญชาติ',
  },
  age: {
    value: -1,
    label: 'อายุ',
  },
  gender: -1,
  specialRequest: '',
  image: '',
  birthDay: undefined,
  address: '',
  taxIDNumber: '',
})

const useCustomerForm = (index: number) => {
  const dispatch = useDispatch()
  const bookingOrderState = useSelector(
    (state: RootState) => state.bookingHistoryReducers
  )
  const { customers, focusedCustomerIndex } = bookingOrderState || {}

  const [customer, setCustomer] = useState<Customer>(getInitialState())

  const [formState, setFormState] = useState<CustomerFormState>({
    searchTerm: '',
    hasMoreCustomer: true,
    page: 0,
    optionCustomers: [],
    editData: false,
    customerOptionOpen: false,
    ageOptionOpen: false,
    nationalityOptionOpen: false,
    confirmCustomerPopupOpen: false,
    foundCustomer: {},
  })

  const { nationalityOptions } = useSelector((state: RootState) => state.brand)
  const { searchTerm, page, foundCustomer } = formState

  const debouncedSearchTerm = useDebouncedValue(searchTerm, { ms: 500 })

  useEffect(() => {
    setCustomer({ ...customers[index], _id: customers[index].customerId || -1 })
  }, [])

  useEffect(() => {
    const search = async () => {
      await callApiCustomer(debouncedSearchTerm, 1)
    }

    if (debouncedSearchTerm) {
      search()
    } else {
      setFormState(prev => ({ ...prev, customerOptionOpen: false }))
    }
  }, [debouncedSearchTerm])

  const callApiCustomer = async (
    search: string,
    page: number,
    background = false
  ) => {
    try {
      const { data } = await api.getSearchCustomerByNameAndPhoneNumber(
        search,
        page
      )

      const currentOptionCustomers = data.reduce(
        (acc: Customer[], info: any) => {
          acc.push({
            _id: info._id,
            phoneNumber: info.phoneNumber,
            image: info.image,
            name: info.name,
            firstName: info.firstName,
            lastName: info.lastName,
            birthDay: info.birthDay,
            address: info.address,
            taxIDNumber: info.taxIDNumber,
            email: info.email,
            specialRequest: info.specialRequest,
            gender: info.gender || -1,
            age: [
              { value: 1, label: 'น้อยกว่า 18' },
              { value: 2, label: '18 - 30' },
              { value: 3, label: '30 - 50' },
              { value: 4, label: 'มากกว่า 50' },
            ].find(a => a.value === (info.age || -1)) || {
              value: -1,
              label: 'อายุ',
            },
            nationality: nationalityOptions.find(
              (n: Option) => n.value === (info.nationalityId || -1)
            ) || {
              value: -1,
              label: 'สัญชาติ',
            },
          })
          return acc
        },
        []
      )

      if (background) {
        return currentOptionCustomers
      }

      setFormState(prev => {
        const newOptionCustomers = prev.optionCustomers.concat(
          currentOptionCustomers
        )
        return {
          ...prev,
          page: prev.page + 1,
          optionCustomers: newOptionCustomers,
          customerOptionOpen: background
            ? false
            : !!search && !!newOptionCustomers.length,
          hasMoreCustomer: data.length && data.length === 20,
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onSearchChange = (value: string) => {
    setFormState(prev => ({
      ...prev,
      searchTerm: value,
      page: 0,
      optionCustomers: [],
    }))
  }

  const onCustomerSelect = (customer: Customer) => {
    setCustomer(customer)
    setFormState(prev => ({
      ...prev,
      searchTerm: '',
      customerOptionOpen: false,
      confirmCustomerPopupOpen: false,
    }))
    const newArrCustomer = [...customers]
    newArrCustomer[focusedCustomerIndex] = {
      ...newArrCustomer[focusedCustomerIndex],
      customerId: customer._id,
      name: customer.name,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      countryCode: customer.countryCode,
      phoneNumber: customer.phoneNumber,
      age: customer.age,
      gender: customer.gender,
      nationality: customer.nationality,
      specialRequest: customer.specialRequest,
      image: customer.image,
      birthDay: customer.birthDay,
      address: customer.address,
      taxIDNumber: customer.taxIDNumber,
    }
    dispatch(
      getOrderBooking({
        customerId: customer._id || -1,
        name: customer.name || '',
        firstName: customer.firstName || '',
        lastName: customer.lastName || '',
        email: customer.email || '',
        countryCode: customer.countryCode || '+66',
        phoneNumber: customer.phoneNumber || '',
        specialRequest: customer.specialRequest || '',
        customers: newArrCustomer,
      })
    )
  }

  const onChange = async (value: any, name: keyof Customer) => {
    if (
      name === 'phoneNumber' &&
      value.length > config.MAX_PHONE_NUMBER_LENGTH &&
      !utils.checkNumber(value)
    ) {
      return
    }

    setCustomer((prev: Customer) => ({
      ...prev,
      [name]: value,
    }))

    setFormState(prev => ({
      ...prev,
      ageOptionOpen: false,
      nationalityOptionOpen: false,
      customerOptionOpen: false,
    }))
    const newArrOrder = [...customers]
    newArrOrder[focusedCustomerIndex] = {
      ...newArrOrder[focusedCustomerIndex],
      [name]: value,
    }
    dispatch(
      getOrderBooking({
        orders: newArrOrder,
      })
    )

    if (name === 'phoneNumber' && value.length === 10) {
      const options = await callApiCustomer(value, 1, true)
      if (options.length) {
        setFormState(prev => ({
          ...prev,
          confirmCustomerPopupOpen: true,
          foundCustomer: options[0],
        }))
      }
    }
  }

  const onOptionClick = (option: SelectorOption, name: string) => {
    setCustomer((prev: Customer) => ({
      ...prev,
      [name]: option,
    }))
  }

  const onToggle = (key: keyof CustomerFormState, value?: boolean) => {
    setFormState(prev => ({
      ...prev,
      [key]: value !== undefined ? value : !prev[key],
    }))
    if (key === 'editData') {
      dispatch(getOrderBooking({ focusedCustomerIndex: index }))
    }
  }

  const onClear = () => {
    setCustomer(getDefaultCustomer())
    const newArrOrder = [...customers]
    newArrOrder[focusedCustomerIndex] = {
      ...getInitialState(),
      customer: index,
      services: [],
    }
    dispatch(getOrderBooking({ orders: newArrOrder }))
  }

  const onConfirmCustomer = async () => {
    onCustomerSelect(foundCustomer as Customer)
  }

  const loadMoreCustomers = () => {
    callApiCustomer(searchTerm, page + 1)
  }

  return {
    ...formState,
    customer,
    orders: customers,
    onCustomerSelect,
    onSearchChange,
    onChange,
    onOptionClick,
    onToggle,
    onClear,
    onConfirmCustomer,
    loadMoreCustomers,
  }
}

export default useCustomerForm
