import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { FaCamera } from 'react-icons/fa'
import styled from 'styled-components'

import { InputGroup } from './InputGroup'

import Button from '../Button'

// Types
export type InputType = 'text' | 'password' | 'email' | 'number' | 'image'

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string | null
  description?: string
  error?: string | null
  fullWidth?: boolean
  type?: InputType
  imageSrc?: string
  onImageChange?: (file: File | null) => void
  required?: boolean
  icon?: {
    left?: React.ReactNode
    right?: React.ReactNode
  }
}

export const StyledInput = styled.input<{
  $hasError: boolean
  $isRequired: boolean
}>`
  padding: 8px 12px;
  border: 1px solid
    ${props => (props.$hasError ? 'var(--danger)' : 'var(--input)')};
  border-radius: var(--radius-xs);
  outline: none;
  caret-height: 5px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  font-size: 14px;
  box-shadow: var(--shadow-sm);
  width: 100%;

  &[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  :hover {
    border-color: ${props =>
      props.$hasError ? 'var(--danger)' : (
        props.readOnly ? 'var(--input)' : 'var(--primary)'
      )};
  }

  :focus {
    border-color: ${props =>
      props.$hasError ? 'var(--danger)' : (
        props.readOnly ? 'var(--input)' : 'var(--primary)'
      )};
  }

  :read-only {
    background-color: var(--input-disabled);
    color: var(--text-secondary);
    cursor: not-allowed;
    :hover {
      border-color: var(--input);
    }
  }

  ${props =>
    props.$isRequired &&
    `
    background-color: ${
      props.$hasError ? 'var(--danger-light)' : 'var(--primary-light)'
    };
  `}
`

export const ImagePreviewWrapper = styled.div`
  margin-top: 8px;
  overflow: hidden;
  border-radius: var(--radius);
  position: relative;
  height: auto;
  aspect-ratio: 1/1;
  max-width: 200px;
`

export const ImagePreview = styled.img`
  width: 100%;
  height: auto;
  display: block;
`

export const Skeleton = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ActionButton = styled(Button)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 2;
`

export const StyledInputWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`

export const StyledInputIconWrapper = styled.div<{
  $position?: 'left' | 'right'
  readOnly?: boolean
}>`
  position: absolute;
  ${props => props.$position}: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: ${props => props.readOnly ? 'not-allowed' : 'pointer'};
`

// Input component
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      description,
      error,
      fullWidth = false,
      type = 'text',
      imageSrc,
      onImageChange,
      required = false,
      style,
      readOnly,
      ...props
    },
    ref
  ) => {
    const id = props.id || `input-${Math.random().toString(36).substr(2, 9)}`
    const [preview, setPreview] = useState<string | null>(null)

    const imageInputRef = React.useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (imageSrc) {
        setPreview(imageSrc)
      }
    }, [imageSrc])

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          setPreview(reader.result as string)
        }
        reader.readAsDataURL(file)
        onImageChange?.(file)
      } else {
        setPreview(null)
        onImageChange?.(null)
      }
    }

    const handleClick = useCallback(() => {
      imageInputRef.current?.click()
    }, [])

    return (
      <InputGroup
        error={error}
        label={label}
        description={description}
        required={required}
        style={style}
      >
        {type === 'image' ? (
          <>
            <ImagePreviewWrapper>
              {preview ? (
                <ImagePreview
                  src={preview}
                  alt={label || ''}
                  onClick={handleClick}
                />
              ) : (
                <Skeleton onClick={handleClick}>
                  <FaCamera color='var(--muted)' size={32} />
                </Skeleton>
              )}
              <ActionButton variant='ghost' size='icon'>
                <FaCamera />
              </ActionButton>
              <StyledInput
              readOnly={readOnly}
                ref={imageInputRef}
                id={id}
                type='file'
                accept='image/*'
                $hasError={!!error}
                $isRequired={required}
                aria-describedby={`${id}-description ${id}-error`}
                required={required}
                {...props}
                onChange={e => {
                  e?.preventDefault()
                  handleImageChange(e as React.ChangeEvent<HTMLInputElement>)
                  props.onChange && props.onChange(e)
                }}
              />
            </ImagePreviewWrapper>
          </>
        ) : (
          <StyledInputWrapper>
            {
              props.icon?.left && (
                <StyledInputIconWrapper $position='left' readOnly={readOnly}>
                  {props.icon.left}
                </StyledInputIconWrapper>
              )
            }
            <StyledInput
              readOnly={readOnly}
              ref={ref}
              id={id}
              type={type}
              $hasError={!!error}
              $isRequired={required}
              aria-describedby={`${id}-description ${id}-error`}
              required={required}
              {...props}
            />
            {
              props.icon?.right && (
                <StyledInputIconWrapper $position='right' readOnly={readOnly}>
                  {props.icon.right}
                </StyledInputIconWrapper>
              )
            }
          </StyledInputWrapper>
        )}
      </InputGroup>
    )
  }
)

Input.displayName = 'Input'
