import React from 'react'

import SettingHeader from '../../molecules/SettingHeader'

const Head = ({ activeMenu, onAddData, onAddGroupData }) => {
  const menus = [
    {
      name: activeMenu === 'บริการ' ? 'บริการ' : 'สินค้า',
      onClick: onAddData,
    },
    {
      name: activeMenu === 'บริการ' ? 'กลุ่มบริการ' : 'กลุ่มสินค้า',
      onClick: onAddGroupData,
    },
  ]

  return (
    <SettingHeader
      name='บริการ'
      menuName={activeMenu === 'บริการ' ? 'สร้างบริการ' : 'สร้างสินค้า'}
      menus={menus}
      showMenu={activeMenu !== 'จัดเรียง'}
    />
  )
}

export default Head
