import React, {
  createContext,
  CSSProperties,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'
import styled from 'styled-components'

// Types
type AlertType = 'info' | 'success' | 'warning' | 'error' | 'error'
type ButtonVariant = 'primary' | 'secondary' | 'error'
interface AlertOptions {
  type?: AlertType
  variant?: ButtonVariant
  title?: string
  message: string
  className?: string
  style?: CSSProperties
}

interface AlertContextType {
  alert: (options: AlertOptions | string) => Promise<void>
  confirm: (options: AlertOptions | string) => Promise<boolean>
  prompt: (options: AlertOptions | string) => Promise<string | null>
}

// Styled Components
const AlertContainer = styled.div<{ type: AlertType; variant: ButtonVariant }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

const AlertContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: var(--radius-sm);
  max-width: 400px;
  width: 100%;
`

const DialogContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

const DialogContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: var(--radius-sm);
  max-width: 400px;
  width: 100%;
`

const Button = styled.button<{ variant: ButtonVariant; error?: boolean }>`
  margin: 0 5px;
  padding: 8px 15px;
  border: none;
  border-radius: var(--radius-sm);
  background-color: ${props => {
    switch (props.variant) {
      case 'error':
        return 'var(--error)'
      case 'primary':
        return 'var(--primary)'
      case 'secondary':
        return 'var(--popup)'
      default:
        return 'var(--primary)'
    }
  }};
  color: ${props =>
    props.variant === 'primary' || props.error
      ? 'white'
      : 'var(--text-primary)'};
  cursor: pointer;
  &:hover {
    background-color: ${props => {
      switch (props.variant) {
        case 'error':
          return 'var(--error-bg)'
        case 'primary':
          return 'var(--primary)'
        case 'secondary':
          return 'var(--muted)'
        default:
          return 'var(--primary)'
      }
    }};
  }
`

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

// Helper function to get color based on type
const getTypeColor = (type: AlertType): string => {
  switch (type) {
    case 'success':
      return '#4caf50'
    case 'warning':
      return '#ff9800'
    case 'error':
      return '#f44336'
    default:
      return '#2196f3'
  }
}

// Create context
const AlertContext = createContext<AlertContextType | undefined>(undefined)

// Provider component
export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alertState, setAlertState] = useState<AlertOptions | null>(null)
  const [confirmState, setConfirmState] = useState<AlertOptions | null>(null)
  const [promptState, setPromptState] = useState<AlertOptions | null>(null)
  const [promptValue, setPromptValue] = useState<string>('')

  const alert = useCallback((options: AlertOptions | string): Promise<void> => {
    return new Promise(resolve => {
      const alertOptions: AlertOptions =
        typeof options === 'string' ? { message: options } : options
      setAlertState(alertOptions)
      resolve()
    })
  }, [])

  const confirm = useCallback(
    (options: AlertOptions | string): Promise<boolean> => {
      return new Promise(resolve => {
        const confirmOptions: AlertOptions =
          typeof options === 'string' ? { message: options } : options
        setConfirmState(confirmOptions)
        const handleConfirm = (result: boolean) => {
          setConfirmState(null)
          resolve(result)
        }
        ;(confirmOptions as any).onConfirm = () => handleConfirm(true)
        ;(confirmOptions as any).onCancel = () => handleConfirm(false)
      })
    },
    []
  )

  const prompt = useCallback(
    (options: AlertOptions | string): Promise<string | null> => {
      return new Promise(resolve => {
        const promptOptions: AlertOptions =
          typeof options === 'string' ? { message: options } : options
        setPromptState(promptOptions)
        const handlePrompt = (result: string | null) => {
          setPromptState(null)
          setPromptValue('')
          resolve(result)
        }
        ;(promptOptions as any).onConfirm = () => handlePrompt(promptValue)
        ;(promptOptions as any).onCancel = () => handlePrompt(null)
      })
    },
    [promptValue]
  )

  // Render alert component
  const renderAlert = () => {
    if (!alertState) return null
    return (
      <AlertContainer
        type={alertState.type || 'info'}
        variant={alertState.variant || 'primary'}
        className={alertState.className}
        style={alertState.style}
      >
        <AlertContent>
          {alertState.title && <h4>{alertState.title}</h4>}
          <p>{alertState.message}</p>
          <ActionRow>
            <Button
              error={alertState.type === 'error'}
              variant={alertState.variant || 'primary'}
              onClick={() => setAlertState(null)}
            >
              OK
            </Button>
          </ActionRow>
        </AlertContent>
      </AlertContainer>
    )
  }

  // Render confirm component
  const renderConfirm = () => {
    if (!confirmState) return null
    const isError = confirmState.variant === 'error'
    return (
      <DialogContainer>
        <DialogContent
          className={confirmState.className}
          style={confirmState.style}
        >
          {confirmState.title && <h4>{confirmState.title}</h4>}
          <p>{confirmState.message}</p>
          <ActionRow>
            <Button
              id='confirm-yes-button'
              variant={isError ? 'error' : 'primary'}
              onClick={(confirmState as any).onConfirm}
              error={isError}
            >
              {isError ? 'Delete' : 'Yes'}
            </Button>
            <Button
              id='confirm-no-button'
              variant='secondary'
              onClick={(confirmState as any).onCancel}
            >
              {isError ? 'Cancel' : 'No'}
            </Button>
          </ActionRow>
        </DialogContent>
      </DialogContainer>
    )
  }

  // Render prompt component
  const renderPrompt = () => {
    if (!promptState) return null
    return (
      <DialogContainer>
        <DialogContent
          className={promptState.className}
          style={promptState.style}
        >
          {promptState.title && <h4>{promptState.title}</h4>}
          <p>{promptState.message}</p>
          <Input
            id='prompt-input'
            type='text'
            value={promptValue}
            onChange={e => setPromptValue(e.target.value)}
          />
          <ActionRow>
            <Button
              id='prompt-ok-button'
              variant='primary'
              onClick={(promptState as any).onConfirm}
            >
              OK
            </Button>
            <Button
              id='prompt-cancel-button'
              variant='secondary'
              onClick={(promptState as any).onCancel}
            >
              Cancel
            </Button>
          </ActionRow>
        </DialogContent>
      </DialogContainer>
    )
  }

  return (
    <AlertContext.Provider value={{ alert, confirm, prompt }}>
      {children}
      {renderAlert()}
      {renderConfirm()}
      {renderPrompt()}
    </AlertContext.Provider>
  )
}

// Custom hooks
export const useAlert = () => {
  const context = useContext(AlertContext)
  if (context === undefined) {
    throw new Error('useAlert must be used within an AlertProvider')
  }
  return context.alert
}

export const useConfirm = () => {
  const context = useContext(AlertContext)
  if (context === undefined) {
    throw new Error('useConfirm must be used within an AlertProvider')
  }
  return context.confirm
}

export const usePrompt = () => {
  const context = useContext(AlertContext)
  if (context === undefined) {
    throw new Error('usePrompt must be used within an AlertProvider')
  }
  return context.prompt
}
