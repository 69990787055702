import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { StyledAvailabilityDropdown } from './AvailabilityDropdown'

import {
  CLOSE_OFFSET,
  INDICATOR_WIDTH,
  PIXEL_PER_MINUTE,
  TABLE_LEFT_OFFSET,
  TIME_UPDATE_INTERVAL,
} from '../constants'
import { AvailabilityCategory, IAvailabilityItem, TimeState } from '../types'
import { ITableItemData } from '../useTable'

export const TimeIndicatorContainer = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  touch-action: none;
`

export const DateSeparator = styled.div`
  border-left: 2px dashed red;
  position: absolute;
  height: 100vh;
  top: 0;
`

export const TimeBox = styled.div<{
  start: number
  end: number
  storeStart: number
  storeEnd: number
  zIndex?: number
}>`
  position: absolute;
  left: ${props =>
    TABLE_LEFT_OFFSET + (props.start - props.storeStart) * PIXEL_PER_MINUTE}px;
  width: ${props => (props.end - props.start) * PIXEL_PER_MINUTE}px;
  height: 100%;
  z-index: ${props => props.zIndex || 4};
`

export const Tab = styled.div<{
  height?: string
  borderWidth?: string
  borderRadius?: string
}>`
  right: 25px;
  height: ${props => props.height || '55px'};
  z-index: 3;
  background: #ede9f8;
  border-width: ${props => props.borderWidth || '1px 0 1px 0'};
  border-style: solid;
  border-color: rgba(140, 86, 217, 0.3);
  margin-top: 0.35rem;
  width: 120px;
  border-radius: ${props => props.borderRadius || 'none'};
`

export const TimeIndicator = styled.div<{
  variant?: 'primary' | 'secondary'
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('data:image/svg+xml,<svg width="52" height="28" viewBox="0 0 52 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20.3795L25.9983 27.5234L31.6171 24H48C50.2091 24 52 22.2091 52 20V4C52 1.79086 50.2091 0 48 0H4Z" fill="%238C56D9"/></svg>');
  height: 28px;
  padding-bottom: 2px;
  width: ${INDICATOR_WIDTH}px;
  color: white;
  position: absolute;
  top: 14px;
  z-index: 6;
  cursor: pointer;
`

export const TimeIndicatorLine = styled.div`
  background: linear-gradient(
    180deg,
    var(--primary) 0%,
    rgba(140, 86, 217, 0) 100%
  );
  width: 3px;
  height: 424px;
  position: absolute;
  left: ${INDICATOR_WIDTH / 2 - 1}px;
  top: 40px;
  z-index: 5;
`

const ColumnHead = styled.div`
  width: 120px;
  text-align: center;
  border-right: 1px solid #e5eced;
  border-bottom: 1px solid #e5eced;
  background: #fff;
  flex: 0 0 auto;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  left: 0;
  z-index: 8;
`

export const CloseButton = styled.div`
  position: absolute;
  left: -24px;
  top: 16px;
  width: 20px;
  height: 20px;
  color: var(--text-primary);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  z-index: 7;

  &:hover {
    color: var(--primary);
  }
`

interface DraggableTimeBoxProps {
  start: number
  end: number
  storeStart: number
  storeEnd: number
  zIndex?: number
  isDraggable?: boolean
  onDrag?: (offset: number) => void
}

const DraggableTimeBox = styled(TimeBox)<DraggableTimeBoxProps>`
  cursor: ${props => (props.isDraggable ? 'grab' : 'default')};
  &:active {
    cursor: ${props => (props.isDraggable ? 'grabbing' : 'default')};
  }
`

export interface TimeIndicatorRowProps {
  date: Date
  time: number
  storeStart: TimeState
  storeEnd: TimeState
  availabilityItems?: IAvailabilityItem[]
  categories?: AvailabilityCategory[]
  times: string[]
  data?: ITableItemData[]
  selectedTimeOutside?: number | null
  onSelectedTimeChange?: (time: number | null, now: number) => void
  onCurrentTimeChange?: (time: null, now: number) => void
  onDrag?: (time: number, now: number) => void
  onDrop?: (time: number, now: number) => void
  onClose?: (time: number, now: number) => void
}

export const TimeIndicatorRow: React.FC<TimeIndicatorRowProps> = ({
  date,
  time,
  storeStart,
  storeEnd,
  times,
  categories,
  selectedTimeOutside,
  availabilityItems,
  onSelectedTimeChange, // ลากเปลี่ยนทุกครั้ง
  onCurrentTimeChange, // เวลาปัจจุบันเปลี่ยน
  onDrag, // กดที่เราลาก
  onDrop, // ตอนที่เราปล่อยตัวที่ลาก
  onClose, // ตอนที่เราปิดตัวที่ลาก
}) => {
  const [trigger, setTrigger] = useState(false)
  const [internalSelectedTime, setInternalSelectedTime] = useState<
    number | null
  >(null)
  const [isDragging, setIsDragging] = useState(false)
  const [dragStartPosition, setDragStartPosition] = useState<number | null>(
    null
  )
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const indicatorRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLDivElement>(null)
  const storeStartMinutes = useMemo(() => {
    if (!storeStart) return 0
    return +storeStart.hour * 60 + +storeStart.minute
  }, [storeStart])
  const storeEndMinutes = useMemo(() => {
    if (!storeEnd.hour || !storeEnd.minute) return 0
    return (+storeEnd.hour + CLOSE_OFFSET) * 60 + +storeEnd.minute
  }, [storeEnd])

  const nowString = useMemo(() => new Date().toISOString(), [date])

  const nowInMinutes = useMemo(() => {
    const now = dayjs(nowString)
    let _time = now.hour() * 60 + now.minute()
    if (_time < storeStartMinutes) {
      _time = _time + 24 * 60
    }
    if (!internalSelectedTime) {
      onCurrentTimeChange?.(null, _time)
    }
    // console.log('nowInMinutes', _time)
    return _time
  }, [internalSelectedTime, storeStartMinutes, trigger, nowString])

  const now = useMemo(() => dayjs(nowString).format('HH:mm'), [trigger])

  useEffect(() => {
    setTrigger(prev => !prev)
    const interval = setInterval(
      () => setTrigger(prev => !prev),
      TIME_UPDATE_INTERVAL
    )
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!isDragging) {
      internalSelectedTime && onDrop?.(internalSelectedTime, nowInMinutes)
    }
  }, [isDragging])

  useEffect(() => {
    if (selectedTimeOutside !== undefined) {
      setInternalSelectedTime(selectedTimeOutside)
    }
  }, [selectedTimeOutside])

  const updateSelectedTime = useCallback(
    (clientX: number) => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        const relativeX = clientX - containerRect.left - TABLE_LEFT_OFFSET
        const newTime =
          Math.round(relativeX / PIXEL_PER_MINUTE) + storeStartMinutes
        const clampedTime = Math.max(
          storeStartMinutes,
          Math.min(newTime, storeEndMinutes)
        )
        setInternalSelectedTime(clampedTime)
        onSelectedTimeChange?.(clampedTime, nowInMinutes)

        if (isDragging && dragStartPosition !== null) {
          const offset = clampedTime - dragStartPosition
          onDrag?.(clampedTime, nowInMinutes)
        }
      }
    },
    [
      storeStartMinutes,
      storeEndMinutes,
      onSelectedTimeChange,
      isDragging,
      dragStartPosition,
      onDrag,
    ]
  )

  const handleStart = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      if (
        containerRef.current &&
        containerRef.current.contains(e.target as Node) &&
        !(
          dropdownRef.current && dropdownRef.current.contains(e.target as Node)
        ) &&
        !(
          closeButtonRef.current &&
          closeButtonRef.current.contains(e.target as Node)
        )
      ) {
        // TODO: Fix this
        // setIsDragging(true)
        // const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX
        // updateSelectedTime(clientX)
        // setDragStartPosition(internalSelectedTime)
      }
    },
    [updateSelectedTime, internalSelectedTime]
  )

  const handleMove = useCallback(
    (e: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent) => {
      if (isDragging) {
        e.preventDefault()
        const clientX =
          'touches' in e
            ? (e as TouchEvent).touches[0].clientX
            : (e as MouseEvent).clientX
        updateSelectedTime(clientX)
      }
    },
    [isDragging, updateSelectedTime]
  )

  const handleEnd = useCallback(() => {
    // if (isDragging && dragStartPosition !== null && internalSelectedTime !== null) {
    //   const offset = internalSelectedTime - dragStartPosition
    //   onDrop?.(internalSelectedTime)
    // }
    setIsDragging(false)
    setDragStartPosition(null)
  }, [isDragging, dragStartPosition, internalSelectedTime, onDrop])

  const handleCloseClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setInternalSelectedTime(null)
      onSelectedTimeChange?.(null, nowInMinutes)
      setTrigger(prev => !prev)
    },
    [onSelectedTimeChange]
  )

  useEffect(() => {
    if (isDragging) {
      const mouseMoveHandler = (e: MouseEvent) => handleMove(e)
      const touchMoveHandler = (e: TouchEvent) => {
        e.preventDefault()
        handleMove(e)
      }

      window.addEventListener('mousemove', mouseMoveHandler)
      window.addEventListener('touchmove', touchMoveHandler, { passive: false })
      window.addEventListener('mouseup', handleEnd)
      window.addEventListener('touchend', handleEnd)

      return () => {
        window.removeEventListener('mousemove', mouseMoveHandler)
        window.removeEventListener('touchmove', touchMoveHandler)
        window.removeEventListener('mouseup', handleEnd)
        window.removeEventListener('touchend', handleEnd)
      }
    }
  }, [isDragging, handleMove, handleEnd])

  const selectedTimeString = useMemo(() => {
    if (internalSelectedTime === null) return null
    const hours =
      internalSelectedTime >= 24 * 60
        ? Math.floor(internalSelectedTime / 60) - 24
        : Math.floor(internalSelectedTime / 60)
    const minutes = internalSelectedTime % 60
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`
  }, [internalSelectedTime])
  const [initialized, setInitialized] = useState(false)

  // scroll to current time
  useEffect(() => {
    if (initialized) return
    if (nowInMinutes < storeStartMinutes || nowInMinutes > storeEndMinutes)
      return
    if (indicatorRef.current) {
      indicatorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest', inline: 'center'
      })
      setInitialized(true)
    }
  }, [nowInMinutes, storeStartMinutes, storeEndMinutes, initialized])

  return (
    <TimeIndicatorContainer
      ref={containerRef}
      onMouseDown={handleStart}
      onTouchStart={handleStart}
    >
      <Tab height='40px' borderWidth='0' />
      {times.map((time, index) => (
        <div key={index}>
          <Tab height='40px' borderWidth='0' />
        </div>
      ))}

      {/* Current time indicator */}
      <TimeBox
        start={
          nowInMinutes > 60 * 24
            ? nowInMinutes - 60 * 24 < storeStartMinutes
              ? storeStartMinutes - 7
              : nowInMinutes - 60 * 24
            : nowInMinutes - 7
        }
        end={
          nowInMinutes > 60 * 24
            ? nowInMinutes - 60 * 24 < storeStartMinutes
              ? storeStartMinutes
              : nowInMinutes - 60 * 24
            : nowInMinutes - 7
        }
        storeStart={storeStartMinutes}
        storeEnd={storeEndMinutes}
        zIndex={5}
      >
        <TimeIndicator
          ref={indicatorRef}
          style={{
            opacity: internalSelectedTime === null ? 1 : 0.3,
          }}
        >
          {now}
        </TimeIndicator>
        <TimeIndicatorLine
          style={{
            opacity: internalSelectedTime === null ? 1 : 0.3,
          }}
        />
        {!internalSelectedTime && (
          <div ref={dropdownRef}>
            <StyledAvailabilityDropdown
              items={availabilityItems}
              categories={categories}
            />
          </div>
        )}
      </TimeBox>
      {/* Selected time indicator */}
      {internalSelectedTime !== null && (
        <TimeBox
          start={(internalSelectedTime || 0) - 7}
          end={(internalSelectedTime || 0) + 10 - 7}
          storeStart={storeStartMinutes}
          storeEnd={storeEndMinutes}
          zIndex={6}
        >
          {/* <CloseButton ref={closeButtonRef} onClick={handleCloseClick}>
            ×
          </CloseButton> */}
          <TimeIndicator onMouseDown={handleStart} onTouchStart={handleStart}>
            {selectedTimeString}
          </TimeIndicator>
          <TimeIndicatorLine />
          <div ref={dropdownRef}>
            <StyledAvailabilityDropdown
              items={availabilityItems}
              categories={categories}
            />
          </div>
        </TimeBox>
      )}
    </TimeIndicatorContainer>
  )
}
