import React from 'react'
import styled from 'styled-components'
import TextLabel from '../../atoms/TextLabel'

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  top: 20%;
  left: 5%;
`
const ImageBox = styled.img`
  display: flex;
  margin-left: 3%;
  height: 40%;
  width: 80%;
  border-radius: ${props => props.borderRadius || '0px'};
`
const ImageBox2 = styled.div`
  display: flex;
  margin-left: 3%;
  height: 40%;
  width: 80%;
  border-radius: ${props => props.borderRadius || '0px'};
  background: gray;
  aspect-ratio: 1;
`

const LeftSidePopup = ({ img, title, detail, radius = '' }) => {
  return (
    <Container>
      {img === '' ? (
        <ImageBox2 borderRadius={radius}></ImageBox2>
      ) : (
        <ImageBox src={img} borderRadius={radius} />
      )}

      <div
        className='pt-2'
        style={{ fontSize: '1.3rem', fontWeight: 'bold', textAlign: 'center' }}
      >
        {/* <TextLabel fontWeight='bold' fontSize='1.3rem'> */}
        {title}
        {/* </TextLabel> */}
      </div>

      <div className='pt-2' /*style={{textAlign:'center'}}*/>
        <TextLabel fontSize='0.8rem' color='#6E798C'>
          {detail}
        </TextLabel>
      </div>
    </Container>
  )
}

export default LeftSidePopup
