import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import ButtonMenu from './ButtonMenu'
import BodyUser from './BodyUser'
import PopupAddUser from '../popups/PopupAddUser'
import BodyRole from './BodyRole'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const Body = ({
  onMenuClick: _onMenuClick,
  activeMenu,
  users: _users,
  update,
}) => {
  const [search, setSearch] = useState('')
  const [currentIdEdit, setCurrentIdEdit] = useState(-1)
  const [currentDataEdit, setCurrentDataEdit] = useState('')
  const [users, setUsers] = useState([])
  const [activeEdit, setActiveEdit] = useState(false)

  useEffect(() => {
    setUsers(_users)
  }, [_users])

  const onMenuClick = menu => {
    setCurrentIdEdit(-1)
    setCurrentDataEdit('')
    // callApi()
    _onMenuClick(menu)
  }

  const onSearchChange = value => {
    setSearch(value)
  }

  // const update = async () => {
  //   await callApiUsers()
  // }

  const onEditClick = (_id, type) => {
    if (type === 'ผู้มีสิทธิ์') {
      setCurrentIdEdit(_id)
      const findData = users.find(u => u._id === _id)
      if (findData) {
        setCurrentDataEdit(findData)
        setActiveEdit(true)
      }
    } else {
    }
  }

  const handleClose = () => {
    setCurrentDataEdit('')
    setCurrentIdEdit(-1)
    setActiveEdit(false)
  }

  return (
    <Container>
      <div
        className='mb-3'
        style={{
          display: 'flex',
        }}
      >
        <ButtonMenu
          onMenuClick={onMenuClick}
          onSearchUserChange={onSearchChange}
          search={search}
        />
      </div>
      {activeMenu === 'ผู้มีสิทธิ์' && (
        <BodyUser users={users} onEditClick={onEditClick} search={search} />
      )}
      {activeMenu === 'ประเภทสิทธิ์' && <BodyRole />}

      {activeEdit && activeMenu === 'ผู้มีสิทธิ์' && (
        <PopupAddUser
          handleClose={handleClose}
          currentIdEdit={currentIdEdit}
          currentDataEdit={currentDataEdit}
          update={update}
        />
      )}
    </Container>
  )
}

export default Body
