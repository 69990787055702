import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import LeftSide from './LeftSide'
import RightSide from './RightSide'
import SideNavBar from '../../lib/layout/SideNavBar/SideNavBar'

import api from '../../api'
import auth from '../../auth'

const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  min-height: 100vh;
`
const GridBox = styled.div`
  display: grid;
  /* grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column; */
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
  width: 100%;
`
const Main = () => {
  const history = useHistory()
  const bookingOrderState = useSelector(state => state.bookingHistoryReducers)
  const {
    _id,
    orders,
    date,
    startTime,
    bookingCode,
    bookingType,
    tipBox,
    buyPackage,
  } = bookingOrderState || {}

  const [bookingHistory, setBookingHistory] = useState('')
  const [currentBookingHistory, setCurrentBookingHistory] = useState('')
  const [requireCustomerDetail, setRequireCustomerDetail] = useState(true)

  useEffect(() => {
    callApiBookingHistory()
  }, [])

  const callApiBookingHistory = async () => {
    try {
      const { data } = await api.getBookingHistoryById(_id)
      if (data.length) {
        setCurrentBookingHistory(data[0])
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!bookingCode && !buyPackage) {
      history.goBack()
    } else {
      if (!buyPackage) {
        setData()
      } else {
        setBookingHistory({
          date: new Date(),
          time: {
            hour: new Date().getHours(),
            minute: new Date().getMinutes(),
          },
        })
      }
      setRequireCustomerDetail(true)
    }
  }, [bookingOrderState])

  const onCustomerRequire = () => {
    setRequireCustomerDetail(false)
  }

  const setData = async () => {
    let orderCount = 0
    let totalTip = 0

    const _customers = orders.reduce((acc, info, index) => {
      const {
        services,
        age,
        gender,
        nationality,
        roomId,
        roomTypeId,
        roomTypeName,
        customerId,
        name,
        firstName,
        lastName,
        birthDay,
        email,
        countryCode,
        phoneNumber,
        specialRequest,
      } = info
      let _orders = []
      for (const service of services) {
        if (_id === -1) {
          _orders.push({
            serviceId: service._id,
            serviceMasterId: service.masterId,
            service: service.name,
            image: service.image,
            time: service.time,
            price: service.price,
            tip: service.tip || 0,
            payChannelTypeTip: service.payChannelTypeTip || -1,
            payChannel: service.payChannel || '',
            timePriceId: service.timePriceId,
            cost: service.cost,
            staffTypeId: service.staffTypeId,
            staffId: service.staffId,
            staffImage: service.staffImage,
            staffName: service.staffName,
            queueFree: service.queueFree,
            requestStaff: service.requestStaff,
            choiceId: [],
            choicePrice: [],
          })
        } else {
          _orders.push({
            serviceId: service.serviceId || service._id,
            serviceMasterId: service.masterId,
            service: service.service || service.name,
            image: service.image,
            time: service.time,
            price: service.price,
            tip: service.tip || 0,
            payChannelTypeTip: service.payChannelTypeTip || -1,
            payChannel: service.payChannel || '',
            timePriceId: service.timePriceId,
            cost: service.cost,
            staffTypeId: service.staffTypeId,
            staffId: service.staffId,
            staffImage: service.staffImage,
            staffName: service.staffName,
            queueFree: service.queueFree,
            requestStaff: service.requestStaff,
            serviceDiscount: service.serviceDiscount,
            choiceId: [],
            choicePrice: [],
          })
        }
        totalTip += service.tip || 0
      }
      orderCount = _orders.length
      acc.push({
        customerId: customerId || -1,
        name: name || '',
        firstName: firstName || '',
        lastName: lastName || '',
        birthDay,
        email: email || '',
        countryCode: countryCode || '+66',
        phoneNumber: phoneNumber || '',
        specialRequest: specialRequest || '',
        orders: _orders,
        orderCount: _orders.length,
        roomId,
        roomTypeId,
        roomTypeName,
        age: age.value || age || -1,
        gender: gender || -1,
        nationalityId: nationality.value || nationality || -1,
      })
      return acc
    }, [])
    totalTip += tipBox || 0
    let create = {
      customerId: _customers[0].customerId || -1,
      name: _customers[0].name || '',
      firstName: _customers[0].firstName || '',
      lastName: _customers[0].lastName || '',
      birthDay: _customers[0].birthDay,
      email: _customers[0].email || '',
      countryCode: _customers[0].countryCode || '',
      phoneNumber: _customers[0].phoneNumber || '',
      specialRequest: _customers[0].specialRequest || '',
      age: _customers[0].age || -1,
      gender: _customers[0].gender || -1,
      nationalityId: _customers[0].nationalityId || -1,
      customers: _customers,
      customerCount: _customers.length,
      orderCount: orderCount,
      date: date,
      time: _id === -1 ? startTime : bookingOrderState.time,
      startTime:
        bookingOrderState.statusId === 3 || bookingOrderState.statusId === 4
          ? bookingOrderState.startTime
          : {},
      note: bookingOrderState.note,
      products: bookingOrderState.products,
      productCount: bookingOrderState.products.length,
      statusId:
        bookingOrderState.statusId !== 4 && bookingOrderState.statusId !== 3
          ? 1
          : bookingOrderState.statusId,
      bookingType: bookingType,
      bookingChannel: bookingOrderState.bookingChannel,
      promotions: bookingOrderState.promotions.length
        ? [{ promotionId: bookingOrderState.promotions[0].promotionId }]
        : [],
      totalTip,
      bookingCode: bookingCode,
      storeId: auth.getStoreId(),
    }
    if (bookingOrderState._id !== -1) {
      create = { ...create, _id: bookingOrderState._id }
    }
    setBookingHistory({ ...create })
  }

  return (
    <Container>
      <SideNavBar />
      <div
        style={{
          width: 'calc(100% - 1rem)',
          display: 'flex',
          marginLeft: '1rem',
          overflow: 'hidden',
          height: '100vh',
        }}
      >
        <GridBox>
          <LeftSide
            bookingHistory={bookingHistory}
            requireCustomerDetail={requireCustomerDetail}
          />
          <RightSide
            bookingHistory={bookingHistory}
            onCustomerRequire={onCustomerRequire}
          />
        </GridBox>
      </div>
    </Container>
  )
}

export default Main
