import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'

interface TooltipProps {
  text: string
  placement?: Placement
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

export const TooltipTrigger = styled.div`
  display: inline-block;
  position: relative;
`

const TooltipContent = styled.div<{ visible: boolean }>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.2s;
`

const ArrowElement = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  transform: rotate(45deg);
`

const Tooltip: React.FC<TooltipProps> = ({
  text,
  placement = 'top',
  children,
  style,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const {
    x,
    y,
    refs,
    strategy,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement: computedPlacement,
  } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(8),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context)
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ])

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[computedPlacement.split('-')[0]]

  return (
    <>
      <TooltipTrigger
        ref={refs.setReference}
        {...getReferenceProps()}
        style={style}
        className={className}
      >
        {children}
      </TooltipTrigger>
      <FloatingPortal>
        {isOpen && (
          <TooltipContent
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            visible={isOpen}
            {...getFloatingProps()}
          >
            {text}
            <ArrowElement
              ref={arrowRef}
              style={{
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                right: '',
                bottom: '',
                [staticSide as string]: '-4px',
              }}
            />
          </TooltipContent>
        )}
      </FloatingPortal>
    </>
  )
}

export default Tooltip
