import axios from 'axios'

import config from './config'
import auth from './auth'

const api = {}
const client = axios.create({ baseURL: `${config.API_POS}` })

client.interceptors.request.use(function (config) {
  const storeId = auth.getStoreId()
  const brandId = auth.getBrandId()
  const token = auth.getToken()

  config.headers.Authorization = token

  if (!config.params?.storeId) {
    config.params = { ...config.params, storeId }
  }
  if (!config.params?.brandId) {
    config.params = { ...config.params, brandId }
  }

  if (config.data) {
    if (Array.isArray(config.data)) {
      config.data = config.data.map(item => ({
        ...item,
        brandId: item.brandId ?? brandId,
      }))
    } else {
      config.data = { ...config.data, brandId: config.data.brandId ?? brandId }
    }
  }

  return config
})

let checkError = false

client.interceptors.response.use(
  function (config) {
    return config
  },
  function (error) {
    if (error?.response?.status === 401 && !checkError) {
      checkError = true
      auth.logout()
      if (!window.location.pathname.includes('login')) {
        window.location.reload()
      }
    }
    return error
  }
)
//login

api.login = async body => {
  const res = await client.post(`/signin`, body)
  return res.data
}

//store

api.getStoreAll = async () => {
  const res = await client.get(`/store/all`)
  return res.data
}

api.getStore = async storeId => {
  const res = await client.get(`/store/getById/${storeId || auth.getStoreId()}`)
  return res.data
}

api.getBrandById = async brandId => {
  const res = await client.get(`/brands/${brandId || auth.getBrandId()}`)
  return res.data
}

api.listBrands = async (q = '') => {
  const res = await client.get(`/brands`, { params: { q } })
  return res.data
}

api.uploadImageStore = async image => {
  const formData = new FormData()
  formData.append('image', image)
  const res = await client.post(`/store/upload-image`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.createStore = async body => {
  const res = await client.post(`/store/create`, body)
  return res.data
}

api.updateStore = async (storeId, body) => {
  const res = await client.patch(
    `/store/update/${storeId || auth.getStoreId()}`,
    body
  )
  return res.data
}

//store date time

api.getStoreDateTime = async () => {
  const res = await client.get(
    `/storeDateTime/getByStoreId/${auth.getStoreId()}`
  )
  return res.data
}

api.getStoreDateTimeByDate = async (day, date) => {
  const res = await client.get(
    `/storeDateTime/getByDate/${day}/${date}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStoreDateTimeByDateV2 = async (
  date,
  config = { withinDay: '', allDay: '' }
) => {
  const res = await client.get(
    `/store-date-times/${auth.getStoreId()}/by-date`,
    {
      params: { date, ...config },
    }
  )
  return res.data
}

api.getStoreDateTimeByDateRang = async (date, endDate) => {
  const res = await client.get(
    `/store-date-times/${auth.getStoreId()}/by-day-rang`,
    {
      params: { date, endDate },
    }
  )
  return res.data
}

api.createAllStoreTime = async (body, storeId) => {
  const res = await client.post(`/storeDateTime/createAll/${storeId}`, body)
  return res.data
}

api.updateAllStoreTime = async body => {
  const res = await client.patch(
    `/storeDateTime/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//customer

api.getCustomerAll = async () => {
  const res = await client.get(`/customer/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getCustomerLimit = async page => {
  const res = await client.get(
    `/customer/getLimit/${page}/${auth.getStoreId()}`
  )
  return res.data
}

api.getSearchCustomerByNameAndPhoneNumber = async (search = '', page) => {
  const res = await client.get(
    `/customer/search-by-nameAndPhoneNumber/${auth.getStoreId()}`,
    {
      params: { search, page },
    }
  )
  return res.data
}

api.getCustomerById = async _id => {
  const res = await client.get(
    `/customer/getCustomerById/${_id}/${auth.getStoreId()}`
  )
  return res.data
}

api.createAllCustomer = async body => {
  const res = await client.post(
    `/customer/createAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.createCustomer = async body => {
  const res = await client.post(`/customer/create`, body)
  return res.data
}

api.uploadImageUser = async body => {
  const formData = new FormData()
  formData.append('image', body.image)
  const res = await client.post(`/customer/upload-image`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.updateCustomer = async (id, body) => {
  const res = await client.patch(
    `/customer/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllCustomer = async body => {
  const res = await client.patch(
    `/customer/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.removeCustomer = async id => {
  const res = await client.delete(`/customer/remove/${id}/${auth.getStoreId()}`)
  return res.data
}

api.removeCustomerAll = async body => {
  const res = await client.delete(`/customer/removeAll/${auth.getStoreId()}`, {
    data: body,
  })
  return res.data
}

api.downloadCustomer = async () => {
  const res = await client.get(`/customer/download-data/${auth.getStoreId()}`)
  return res
}

//bookingHistory

api.getBookingHistoryAll = async () => {
  const res = await client.get(`/bookingHistory/all`)
  return res.data
}

api.getBookingHistory = async () => {
  const res = await client.get(
    `/bookingHistory/getByStore/${auth.getStoreId()}`
  )
  return res.data
}

api.getBookingHistoryById = async bookingId => {
  const res = await client.get(
    `/bookingHistory/getByBookingId/${bookingId}/${auth.getStoreId()}`
  )
  return res.data
}

api.createBookingCode = async () => {
  const res = await client.get(
    `/bookingHistory/create-bookingCode/${auth.getStoreId()}`
  )
  return res.data
}

api.checkBookingCode = async bookingCode => {
  const res = await client.get(
    `/bookingHistory/checkBookingCode/${bookingCode}/${auth.getStoreId()}`
  )
  return res.data
}

api.getBookingHistoryByDate = async (
  dateStart,
  dateEnd,
  config = { withinDay: '', allDay: '' }
) => {
  const res = await client.get(`/booking-histories`, {
    params: { dateStart, dateEnd, ...config },
  })

  return res.data
}

api.getBookingHistoryByPayStatus = async payStatus => {
  const res = await client.get(
    `/bookingHistory/getByPayStatus/${payStatus}/${auth.getStoreId()}`
  )
  return res.data
}

api.getBookingHistoryByBookingCode = async bookingCode => {
  const res = await client.get(
    `/bookingHistory/getByBookingCode/${bookingCode}/${auth.getStoreId()}`
  )
  return res.data
}

api.getBookingHistoryByCustomerId = async customerId => {
  const res = await client.get(
    `/bookingHistory/getByCustomerId/${customerId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getBookingHistoryByLengthReceiptNumber = async () => {
  const res = await client.get(
    `/bookingHistory/getByLengthReceiptNumber/${auth.getStoreId()}`
  )
  return res.data
}

api.getBookingHistoryByStaffOrders = async date => {
  const res = await client.get(
    `/booking-histories/get-staff-orders/${date}/${auth.getStoreId()}`
  )
  return res.data
}

api.getBookingHistoryV2 = async (limit, page, filters, search = '') => {
  const res = await client.get(`/v2/booking-histories`, {
    params: {
      limit,
      page,
      filters: JSON.stringify(filters),
      search,
    },
    paramsSerializer: params => {
      return new URLSearchParams(params).toString()
    },
  })

  return res.data
}

api.ExportBookingHistoryV2 = async (filters, search = '', headers) => {
  const res = await client.get(`/v2/booking-histories/export/${headers}`, {
    params: {
      filters: JSON.stringify(filters),
      search,
    },
    paramsSerializer: params => {
      return new URLSearchParams(params).toString()
    },
  })

  return res
}

api.createBookingHistory = async body => {
  const res = await client.post(`/bookingHistory/create`, body)
  return res.data
}

api.createBookingHistoryV2 = async body => {
  const res = await client.post(`/v2/booking-histories`, {
    bookingHistory: body,
  })
  return res.data
}

api.startBookingHistory = async body => {
  const res = await client.post(`/v2/booking-histories/start-massage`, body)
  return res.data
}

api.uploadPaySlip = async body => {
  const formData = new FormData()
  formData.append('image', body.image)
  const res = await client.post(`/uploadPaySlip`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.updateBookingHistory = async (id, body) => {
  const res = await client.patch(
    `/bookingHistory/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateBookingHistoryAll = async body => {
  const res = await client.patch(
    `/bookingHistory/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.payment = async (id, body) => {
  const res = await client.patch(
    `/bookingHistory/payment/pay/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.paymentSuccess = async orderNo => {
  const res = await client.get(
    `/bookingHistory/payment/success/${orderNo}/${auth.getStoreId()}`
  )
  return res.data
}

api.getBookingHistorySummary = async query => {
  const res = await client.get(`/booking-histories/summary`, { params: query })
  return res.data
}

//users

api.getUserAll = async () => {
  const res = await client.get(`/users/all`)
  return res.data
}

api.getUserByStoreId = async storeId => {
  const res = await client.get(`/users/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getUser = async id => {
  const res = await client.get(`/user/${id}/${auth.getStoreId()}`)
  return res.data
}

api.getSavedUsers = async (ids = []) => {
  const res = await client.get(`/users/saved-users`, { params: { ids } })
  return res.data
}

api.getUserById = async id => {
  const res = await client.get(`/user/${id}`)
  return res.data
}

api.getUserCheckPhoneNumber = async (phoneNumber, _id) => {
  const res = await client.get(`/user/checkPhoneNumber/${phoneNumber}/${_id}`)
  return res.data
}

api.getUserCheckUserId = async (userId, _id) => {
  const res = await client.get(`/user/checkUserId/${userId}/${_id}`)
  return res.data
}

api.getUserCheckEmail = async (email, _id) => {
  const res = await client.get(`/user/checkEmail/${email}/${_id}`)
  return res.data
}

api.getUserByRole = async typeId => {
  const res = await client.get(
    `/user/getUserByRole/${typeId}/${auth.getStoreId()}`
  )
  return res.data
}

api.createUser = async body => {
  const res = await client.post(`/user/create`, body)
  return res.data
}

api.uploadImageUser = async body => {
  const formData = new FormData()
  formData.append('image', body.image)
  const res = await client.post(`/user/upload-image`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.updateUser = async (id, storeId, body) => {
  const res = await client.patch(
    `/user/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.removeUser = async id => {
  const res = await client.delete(`/user/remove/${id}/${auth.getStoreId()}`)
  return res.data
}

api.renewPassword = async body => {
  const res = await client.post(
    `/renewPassword?storeId=${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.renewPin = async body => {
  const res = await client.post(`/renewPin?storeId=${auth.getStoreId()}`, body)
  return res.data
}

api.forgetPassword = async body => {
  const res = await client.post(`/forgetPassword`, body)
  return res.data
}

api.forgetPin = async body => {
  const res = await client.post(`/forgetPin`, body)
  return res.data
}

api.resetPassword = async body => {
  const res = await client.post(`/resetPassword`, body)
  return res.data
}

api.resetPin = async body => {
  const res = await client.post(`/resetPin`, body)
  return res.data
}

api.checkPassword = async body => {
  const res = await client.post(`/checkPassword`, body)
  return res.data
}

//product group

api.getProductGroup = async () => {
  const res = await client.get(
    `/productGroup/getByStoreId/${auth.getStoreId()}`
  )
  return res.data
}

api.getProductGroupByHeadGroup = async headGroupId => {
  const res = await client.get(
    `/productGroup/getByHeadGroupId/${headGroupId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getProductGroupAndService = async () => {
  const res = await client.get(
    `/productGroup/get-group-and-service/${auth.getStoreId()}`
  )
  return res.data
}

api.getProductGroupAndProduct = async () => {
  const res = await client.get(
    `/productGroup/get-group-and-product/${auth.getStoreId()}`
  )
  return res.data
}

api.createProductGroup = async body => {
  const res = await client.post(`/productGroup/create`, body)
  return res.data
}

api.updateProductGroup = async (id, body) => {
  const res = await client.patch(
    `/productGroup/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.removeProductGroup = async (id, headGroupId) => {
  const res = await client.delete(
    `/productGroup/remove/${id}/${headGroupId}/${auth.getStoreId()}`
  )
  return res.data
}

//product

api.getProduct = async () => {
  const res = await client.get(`/product/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getProductByGroup = async ProductGroupId => {
  const res = await client.get(
    `/product/getByGroup/${ProductGroupId}/${auth.getStoreId()}`
  )
  return res.data
}

api.uploadImageProduct = async body => {
  const formData = new FormData()
  formData.append('image', body.image)
  const res = await client.post(`/product/upload-image`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.createProduct = async body => {
  const res = await client.post(`/product/create`, body)
  return res.data
}

api.updateProduct = async (id, body) => {
  const res = await client.patch(
    `/product/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllProduct = async body => {
  const res = await client.patch(
    `/product/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.removeProduct = async id => {
  const res = await client.delete(`product/remove/${id}/${auth.getStoreId()}`)
  return res.data
}

//stock product
api.getStockProductByStoreId = async () => {
  const res = await client.get(
    `/stock-product/get-by-storeId/${auth.getStoreId()}`
  )
  return res.data
}

api.getStockProductById = async _id => {
  const res = await client.get(
    `/stock-product/get-by-id/${_id}/${auth.getStoreId()}`
  )
  return res.data
}

api.createStockProduct = async body => {
  const res = await client.post(`/stock-product/create`, body)
  return res.data
}

api.updateStockProduct = async (id, body) => {
  const res = await client.patch(
    `/product/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//services

api.getServiceList = async () => {
  const res = await client.get(`/service/getListByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getServices = async () => {
  const res = await client.get(`/service/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getServiceByIds = async id => {
  const res = await client.get(`/service/getByIds/${id}/${auth.getStoreId()}`)
  return res.data
}

api.getServiceById = async id => {
  const res = await client.get(`/service/getById/${id}/${auth.getStoreId()}`)
  return res.data
}

api.getServiceByGroup = async serviceGroupId => {
  const res = await client.get(
    `/service/getByGroup/${serviceGroupId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getServiceCommission = async () => {
  const res = await client.get(`/service/getByCommission/${auth.getStoreId()}`)
  return res.data
}

api.getServiceSpecificStaff = async () => {
  const res = await client.get(
    `/service/getServiceSpecificStaff/${auth.getStoreId()}`
  )
  return res.data
}

api.uploadImageService = async body => {
  const formData = new FormData()
  formData.append('image', body.image)
  const res = await client.post(`/service/upload-image`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.createService = async body => {
  const res = await client.post(`/service/create`, body)
  return res.data
}

api.updateService = async (id, body) => {
  const res = await client.patch(
    `/service/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllService = async body => {
  const res = await client.patch(
    `/service/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.removeService = async id => {
  const res = await client.delete(`/service/remove/${id}/${auth.getStoreId()}`)
  return res.data
}

//staff Type

api.getStaffType = async () => {
  const res = await client.get(`/staffType/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getStaffTypeById = async staffTypeId => {
  const res = await client.get(
    `/staffType/getById/${staffTypeId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffTypeByStaffTypeId = async staffTypeId => {
  const res = await client.get(
    `/staffType/getByStaffTypeId/${staffTypeId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffTypeAndStaff = async () => {
  const res = await client.get(
    `/staffType/getStaffTypeAndStaff/${auth.getStoreId()}`
  )
  return res.data
}

api.createStaffType = async body => {
  const res = await client.post(`/staffType/create`, body)
  return res.data
}

api.createAllStaffType = async body => {
  const res = await client.post(`/staffType/createAll`, body)
  return res.data
}

api.updateStaffType = async (id, body) => {
  const res = await client.patch(
    `/staffType/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.removeStaffType = async id => {
  const res = await client.delete(
    `/staffType/remove/${id}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffTypeAndStaff = async () => {
  const res = await client.get(
    `/staffType/getStaffTypeAndStaff/${auth.getStoreId()}`
  )
  return res.data
}

//staff

api.getStaff = async () => {
  const res = await client.get(`/staff/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getStaffIdArray = async id => {
  const res = await client.get(
    `/staff/getByStaffIdArray/${id}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffByStatus = async status => {
  const res = await client.get(
    `/staff/getByStatus/${status}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffById = async id => {
  const res = await client.get(`/staff/getStaffById/${id}/${auth.getStoreId()}`)
  return res.data
}

api.getByNonActiveBookingHistory = async (dateStart, dateEnd) => {
  const res = await client.get(
    `/staff/getByNonActiveBookingHistory/${dateStart}/${dateEnd}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffSortByQueue = async date => {
  const res = await client.get(
    `/staffQueue/getByStaff/${date}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffDataByQueue = async date => {
  const res = await client.get(
    `/staffQueue/getByQueueStaffData/${date}/${auth.getStoreId()}`
  )
  return res.data
}

api.uploadImageStaff = async body => {
  const formData = new FormData()
  formData.append('image', body.image)
  const res = await client.post(`/staff/upload-image`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.createStaff = async body => {
  const res = await client.post(`/staff/create`, body)
  return res.data
}

api.updateStaff = async (id, body) => {
  const res = await client.patch(
    `/staff/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllStaff = async body => {
  const res = await client.patch(`/staff/updateAll/${auth.getStoreId()}`, body)
  return res.data
}

api.removeStaff = async id => {
  const res = await client.delete(`/staff/remove/${id}/${auth.getStoreId()}`)
  return res.data
}

//staff queue

api.getCurrentStaffQueue = async () => {
  const res = await client.get(`/staff-queue/current`, { params: {} })
  return res.data
}

api.manageStaffQueue = async body => {
  const res = await client.post(`/staff-queue/manage`, body)
  return res.data
}

api.getStaffQueueByDate = async (dateStart, dateEnd) => {
  const res = await client.get(
    `/staffQueue/getByDate/${dateStart}/${dateEnd}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffQueueByDateTime = async (dateStart, dateEnd) => {
  const res = await client.get(
    `/staff-queue/getByDateTime/${dateStart}/${dateEnd}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffQueueByDateTimeAndStaffData = async (
  dateStart,
  dateEnd,
  config = { withinDay: '', allDay: '' }
) => {
  const res = await client.get(`/staff-queue/getByDateTimeAndStaffData`, {
    params: { dateStart, dateEnd, ...config },
  })
  return res.data
}

api.getStaffQueueByDateGreater = async date => {
  const res = await client.get(
    `/staffQueue/getByDateGreater/${date}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffLastCreateQueue = async () => {
  const res = await client.get(`/staffQueue/getLastCreate/${auth.getStoreId()}`)
  return res.data
}

api.createStaffQueue = async body => {
  const res = await client.post(`/staffQueue/create`, body)
  return res.data
}

// api.createCurrentDate = async () => {
//   const res = await client.patch(
//     `/staffCommissionFee/createCurrentDate/${
//       auth.getStoreId()
//     }`
//   )
//   return res.data
// }

api.updateStaffQueue = async (id, body) => {
  const res = await client.patch(
    `/staffQueue/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllStaffQueue = async body => {
  const res = await client.patch(
    `/staffQueue/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//staff work schedule

api.getStaffWorkScheduleByStaffIdAndDate = async (staffId, date) => {
  const res = await client.get(
    `/staffWorkSchedule/getByStaffIdAndDate/${staffId}/${date}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffWorkScheduleByStaffIdAndPayStatus = async (staffId, payStatus) => {
  const res = await client.get(
    `/staffWorkSchedule/getByStaffIdAndPayStatus/${staffId}/${payStatus}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffWorkScheduleByStaffAndDate = async date => {
  const res = await client.get(
    `/staffWorkSchedule/getByStaffAndDate/${date}/${auth.getStoreId()}`
  )
  return res.data
}

api.createStaffWorkSchedule = async body => {
  const res = await client.post(`/staffWorkSchedule/create`, body)
  return res.data
}

api.updateStaffWorkSchedule = async (id, body) => {
  const res = await client.patch(
    `/staffWorkSchedule/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllStaffWorkSchedule = async body => {
  const res = await client.patch(
    `/staffWorkSchedule/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//staff commissionFee

api.createStaffCommissionFee = async body => {
  const res = await client.post(`/staffCommissionFee/create`, body)
  return res.data
}

api.createAllStaffCommissionFee = async body => {
  const res = await client.post(
    `/staffCommissionFee/createAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.getStaffCommissionByStaffId = async staffId => {
  const res = await client.get(
    `/staffCommissionFee/getByStaffId/${staffId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffCommissionByPayStatusAndDate = async (
  payStatus,
  dateStart,
  dateEnd
) => {
  const res = await client.get(
    `/staffCommissionFee/getByPayStatusAndDate/${payStatus}/${dateStart}/${dateEnd}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffCommissionByDate = async (
  dateStart,
  dateEnd,
  config = { withinDay: '', allDay: '' }
) => {
  const res = await client.get(`/staff-commission-fees`, {
    params: { dateStart, dateEnd, ...config },
  })

  return res.data
}

api.getStaffCommissionSummaryByDate = async (
  dateStart,
  dateEnd,
  config = { withinDay: '', allDay: '' }
) => {
  const res = await client.get(`/staff-commission-fees/summary`, {
    params: { dateStart, dateEnd, ...config },
  })

  return res.data
}

api.getStaffCommissionByDateAndStaffId = async (
  staffId,
  dateStart,
  dateEnd,
  config = { withinDay: '', allDay: '' }
) => {
  const res = await client.get(`/staff-commission-fees/date-staffId`, {
    params: { staffId, dateStart, dateEnd, ...config },
  })

  return res.data
}

api.getStaffCommissionByBookingCode = async bookingCode => {
  const res = await client.get(
    `/staffCommissionFee/getByBookingCode/${bookingCode}/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffCommissionByOverdue = async () => {
  const res = await client.get(
    `/staffCommissionFee/overdue-commissionFee/${auth.getStoreId()}`
  )
  return res.data
}

api.getStaffCommissionByOverdueAndDate = async (dateStart, dateEnd) => {
  const res = await client.get(
    `/staffCommissionFee/overdue-commissionFee-date/${dateStart}/${dateEnd}/${auth.getStoreId()}`
  )
  return res.data
}

api.updateAllStaffCommissionFee = async body => {
  const res = await client.patch(
    `/staffCommissionFee/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllOverdue = async body => {
  const res = await client.patch(
    `/staffCommissionFee/updateAll/overdue/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.clearAllOverdue = async body => {
  const res = await client.patch(
    `/staffCommissionFee/clear-all-overdue/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.clearOverdueByStaff = async (body, staffId) => {
  const res = await client.patch(
    `/staffCommissionFee/clear-overdue-staff/${staffId}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.clearAllOverdueByDate = async (startDate, endDate, body) => {
  const res = await client.patch(
    `/staffCommissionFee/clear-all-overdue-by-date/${startDate}/${endDate}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.clearOverdueByStaffByDate = async (startDate, endDate, body, staffId) => {
  const res = await client.patch(
    `/staffCommissionFee/clear-overdue-staff-by-date/${startDate}/${endDate}/${staffId}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

// commission

api.createCommission = async body => {
  const res = await client.post(`/commissionFee/create`, body)
  return res.data
}

api.updateCommission = async (serviceId, body) => {
  const res = await client.patch(
    `/commissionFee/update/${serviceId}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllCommission = async body => {
  const res = await client.patch(
    `/commissionFee/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.getCommissionByStaffTypeId = async staffTypeId => {
  const res = await client.get(
    `/commissionFee/getByStaffTypeId/${staffTypeId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getCommissionByServiceId = async serviceId => {
  const res = await client.get(
    `/commissionFee/getByServiceId/${serviceId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getByCommissionMergeService = async serviceId => {
  const res = await client.get(
    `/commissionFee/getByCommissionMergeService/${serviceId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getCommission = async () => {
  const res = await client.get(
    `/commissionFee/getByStoreId/${auth.getStoreId()}`
  )
  return res.data
}

// service staff

api.getServiceStaffByStaffIdAndDate = async (staffId, dateStart, dateEnd) => {
  const res = await client.get(
    `/serviceStaff/getByStaffIdAndDate/${staffId}/${dateStart}/${dateEnd}/${auth.getStoreId()}`
  )
  return res.data
}

api.getServiceStaffByStaffId = async staffId => {
  const res = await client.get(
    `/serviceStaff/getByStaffId/${staffId}/${auth.getStoreId()}`
  )
  return res.data
}

api.createAllServiceStaff = async body => {
  const res = await client.post(`/serviceStaff/createAll`, body)
  return res.data
}

api.updateAllServiceStaff = async body => {
  const res = await client.patch(
    `/serviceStaff/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//booking channel

api.getBookingChannel = async () => {
  const res = await client.get(
    `/bookingChannel/getByStoreId/${auth.getStoreId()}`
  )
  return res.data
}

api.getBookingChannelByBookingTypeId = async bookingTypeId => {
  const res = await client.get(
    `/bookingChannel/getByBookingTypeId/${bookingTypeId}/${auth.getStoreId()}`
  )
  return res.data
}

api.createBookingChannel = async body => {
  const res = await client.post(`/bookingChannel/create`, body)
  return res.data
}

api.updateBookingChannel = async (id, body) => {
  const res = await client.patch(
    `/bookingChannel/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//pay channel

api.getPayChannel = async () => {
  const res = await client.get(`/payChannel/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getPayChannelByStatus = async () => {
  const res = await client.get(`/payChannel/getByStatus/${auth.getStoreId()}`)

  return res.data
}

api.getPayChannelById = async id => {
  const res = await client.get(`/payChannel/getById/${id}/${auth.getStoreId()}`)

  return res.data
}

api.getPayChannelByTypeId = async typeId => {
  const res = await client.get(
    `/payChannel/getByTypeId/${typeId}/${auth.getStoreId()}`
  )
  return res.data
}

api.createPayChannel = async body => {
  const res = await client.post(`/payChannel/create`, body)
  return res.data
}

api.uploadImagePayChannel = async body => {
  const formData = new FormData()
  formData.append('image', body.image)
  const res = await client.post(`/payChannel/upload-image`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.updatePayChannel = async (id, body) => {
  const res = await client.patch(
    `/payChannel/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//cashier

api.createCashier = async body => {
  const res = await client.post(`/cashier/create`, body)
  return res.data
}

api.getCashierByStoreId = async () => {
  const res = await client.get(`/cashier/getByStore/${auth.getStoreId()}`)
  return res.data
}

api.getCashierByLastOpen = async () => {
  const res = await client.get(`/cashier/last-open/${auth.getStoreId()}`)
  return res.data
}

api.getCashierByDate = async (
  dateStart,
  dateEnd,
  config = { withinDay: '', allDay: '' }
) => {
  const res = await client.get(`/cashiers`, {
    params: { dateStart, dateEnd, ...config },
  })

  return res.data
}

api.closeCashier = async (id, body) => {
  const res = await client.patch(
    `/cashier/close-cashier/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateCashier = async (id, body) => {
  const res = await client.patch(
    `/cashier/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

// income And Expense

api.getIncomeAndExpenseByStoreId = async () => {
  const res = await client.get(
    `/incomeAndExpense/getByStore/${auth.getStoreId()}`
  )
  return res.data
}

api.getIncomeAndExpenseByDate = async date => {
  const res = await client.get(
    `/incomeAndExpense/getByDate/${date}/${auth.getStoreId()}`
  )
  return res.data
}

api.createIncomeAndExpense = async body => {
  const res = await client.post(`/incomeAndExpense/create`, body)
  return res.data
}

api.updateIncomeAndExpense = async (id, body) => {
  const res = await client.patch(
    `/incomeAndExpense/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

// promotion

api.getPromotionByStoreId = async () => {
  const res = await client.get(`/promotion/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getPromotionByDate = async date => {
  const res = await client.get(
    `/promotion/getByDate/${date}/${auth.getStoreId()}`
  )
  return res.data
}

api.createPromotion = async body => {
  const res = await client.post(`/promotion/create`, body)
  return res.data
}

api.updatePromotion = async (id, body) => {
  const res = await client.patch(
    `/promotion/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.removePromotion = async id => {
  const res = await client.delete(
    `/promotion/remove/${id}/${auth.getStoreId()}`
  )
  return res.data
}

api.removePromotionAll = async body => {
  const res = await client.delete(`/promotion/removeAll/${auth.getStoreId()}`, {
    data: body,
  })
  return res.data
}

//room type

api.getRoomTypeByStoreId = async () => {
  const res = await client.get(`/roomType/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getRoomTypeById = async _id => {
  const res = await client.get(`/roomType/getById/${_id}/${auth.getStoreId()}`)
  return res.data
}

api.getRoomTypeByServiceId = async serviceId => {
  const res = await client.get(
    `/roomType/getBy-serviceId/${serviceId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getRoomTypeAndRoom = async () => {
  const res = await client.get(
    `/roomType/roomType-and-room/${auth.getStoreId()}`
  )
  return res.data
}

api.createRoomType = async body => {
  const res = await client.post(`/roomType/create`, body)
  return res.data
}

api.updateRoomType = async (id, body) => {
  const res = await client.patch(
    `/roomType/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllRoomType = async body => {
  const res = await client.patch(
    `/roomType/update-roomType-service/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.removeRoomType = async id => {
  const res = await client.delete(`/roomType/remove/${id}/${auth.getStoreId()}`)
  return res.data
}

//room

api.getRoomByStoreId = async () => {
  const res = await client.get(`/room/getByStoreId/${auth.getStoreId()}`)
  return res.data
}

api.getRoomById = async _id => {
  const res = await client.get(`/room/getById/${_id}/${auth.getStoreId()}`)
  return res.data
}

api.getRoomByStatusRoom = async statusRoom => {
  const res = await client.get(
    `/room/getByStatusRoom/${statusRoom}/${auth.getStoreId()}`
  )
  return res.data
}

api.getRoomByRoomTypeId = async roomTypeId => {
  const res = await client.get(
    `/room/get-by-roomType/${roomTypeId}/${auth.getStoreId()}`
  )
  return res.data
}

api.getRoomByServiceId = async serviceId => {
  const res = await client.get(
    `/room/getBy-serviceId/${serviceId}/${auth.getStoreId()}`
  )
  return res.data
}

api.uploadImageRoom = async body => {
  const formData = new FormData()
  formData.append('image', body.image)
  const res = await client.post(`/room/upload-image`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.createRoom = async body => {
  const res = await client.post(`/room/create`, body)
  return res.data
}

api.updateRoom = async (id, body) => {
  const res = await client.patch(
    `/room/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateAllRoom = async body => {
  const res = await client.patch(`/room/updateAll/${auth.getStoreId()}`, body)
  return res.data
}

api.updateAllRoom = async body => {
  const res = await client.patch(
    `/room/update-room-service/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.removeRoom = async id => {
  const res = await client.delete(`/room/remove/${id}/${auth.getStoreId()}`)
  return res.data
}

//pending schedule
api.getPendingScheduleByBookingCode = async bookingCode => {
  const res = await client.get(
    `/pendingSchedule/getByBookingCode/${bookingCode}/${auth.getStoreId()}`
  )
  return res.data
}

api.createPendingSchedule = async body => {
  const res = await client.post(`/pendingSchedule/create`, body)
  return res.data
}

api.createAllPendingSchedule = async body => {
  const res = await client.post(`/pendingSchedule/createAll`, body)
  return res.data
}

api.updateAllPendingSchedule = async body => {
  const res = await client.patch(
    `/pendingSchedule/updateAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

// nationality

api.getNationalityByStoreId = async body => {
  const res = await client.get(
    `/nationality/getByStoreId/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.createNationality = async body => {
  const res = await client.post(`/nationality/create`, body)
  return res.data
}

api.createAllNationality = async (body, storeId) => {
  const res = await client.post(`/nationality/createAll/${storeId}`, body)
  return res.data
}

api.updateNationality = async (id, body) => {
  const res = await client.patch(
    `/nationality/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}
//import data
api.uploadCustomerData = async file => {
  const formData = new FormData()
  formData.append('file', file)
  const url = `${config.API_POS}/import-data/customer/${auth.getStoreId()}`
  const res = await client.post(url, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

api.uploadBrandPackageData = async file => {
  const formData = new FormData()
  formData.append('file', file)
  const url = `${
    config.API_POS
  }/import-data/brand-customer/${auth.getStoreId()}`
  const res = await client.post(url, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

//logs
api.createEditHistory = async body => {
  const res = await client.post(`/edit-history/create`, body)
  return res.data
}

//export
api.downloadReport = async (dateStart, dateEnd) => {
  const res = await client.get(
    `/exportData/report/${dateStart}/${dateEnd}/${auth.getStoreId()}`
  )
  return res
}

api.downloadAdminReport = async () => {
  const res = await client.get(`/exportData/admin-report`)
  return res
}

// api.downloadCustomer = async () => {
//   const res = await client.get(
//     `/customer/download-data/${
//       auth.getStoreId()
//     }`
//   )
//   return res
// }

//notification

api.getNotificationByStatus = async status => {
  const res = await client.get(
    `/notification/get-by-status/${status}/${auth.getStoreId()}`
  )
  return res.data
}

api.getNotificationById = async _id => {
  const res = await client.get(
    `/notification/get-by-id/${_id}/${auth.getStoreId()}`
  )
  return res.data
}

api.getNotificationByRefId = async refId => {
  const res = await client.get(
    `/notification/get-by-refId/${refId}/${auth.getStoreId()}`
  )
  return res.data
}

api.updateNotification = async (_id, body) => {
  const res = await client.patch(
    `/notification/update/${_id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

export default api

// receipt
api.getReceiptByStoreId = async () => {
  const res = await client.get(`/receipt/get-by-storeId/${auth.getStoreId()}`)
  return res.data
}

api.createReceipt = async body => {
  const res = await client.post(`/receipt/create`, body)
  return res.data
}

api.updateReceipt = async (id, body) => {
  const res = await client.patch(
    `/receipt/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.uploadImageReceipt = async body => {
  const formData = new FormData()
  formData.append('image', body.image)
  const res = await client.post(`/receipt/upload-image`, formData, {
    transformRequest: () => formData,
  })
  return res.data
}

//payment
api.getPaymentCheckout = async body => {
  const res = await client.post(`/payment/checkout/${auth.getStoreId()}`, body)
  return res.data
}

api.getPaymentCheckoutPackage = async body => {
  const res = await client.post(
    `/payment/checkout-package/${auth.getStoreId()}`,
    body
  )
  return res.data
}

// brand package
api.getPackageByStoreId = async () => {
  const res = await client.get(
    `/brand-package/get-by-storeId/${auth.getStoreId()}`
  )
  return res.data
}

api.getPackageById = async id => {
  const res = await client.get(
    `/brand-package/get-by-id/${id}/${auth.getStoreId()}`
  )
  return res.data
}

api.getPackageByIds = async id => {
  const res = await client.get(
    `/brand-package/get-by-ids/${id}/${auth.getStoreId()}`
  )
  return res.data
}

api.getPackageGroupAndPackage = async id => {
  const res = await client.get(
    `/brand-package/get-group-package/${auth.getStoreId()}`
  )
  return res.data
}

api.createPackage = async body => {
  const res = await client.post(`/brand-package/create`, body)
  return res.data
}

api.updatePackage = async (id, body) => {
  const res = await client.patch(
    `/brand-package/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//customer package
api.createCustomerPackage = async body => {
  const res = await client.post(`/customer-package/create`, body)
  return res.data
}

api.getCustomerPackageById = async id => {
  const res = await client.get(
    `/customer-package/get-by-id/${id}/${auth.getStoreId()}`
  )
  return res.data
}

api.getCustomerPackageByCustomerId = async id => {
  const res = await client.get(
    `/customer-package/get-by-customer-id/${id}/${auth.getStoreId()}`
  )
  return res.data
}

api.getCustomerPackageByCustomerIds = async id => {
  const res = await client.get(
    `/customer-package/get-by-customer-ids/${id}/${auth.getStoreId()}`
  )
  return res.data
}

api.createAllCustomerPackage = async body => {
  const res = await client.post(
    `/customer-package/createAll/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.updateCustomerPackage = async (_id, body) => {
  const res = await client.patch(
    `/customer-package/update/${_id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.editCustomerPackage = async (_id, body) => {
  const res = await client.patch(
    `/customer-package/edit/${_id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//occupation
api.getOccupationByStoreId = async body => {
  const res = await client.get(
    `/occupation/getByStoreId/${auth.getStoreId()}`,
    body
  )
  return res.data
}

api.createOccupation = async body => {
  const res = await client.post(`/occupation/create`, body)
  return res.data
}

api.createAllOccupation = async (body, storeId) => {
  const res = await client.post(`/occupation/createAll/${storeId}`, body)
  return res.data
}

api.updateOccupation = async (id, body) => {
  const res = await client.patch(
    `/occupation/update/${id}/${auth.getStoreId()}`,
    body
  )
  return res.data
}

//tag
api.createTag = async body => {
  const res = await client.post(`/tag/create`, body)
  return res.data
}

api.updateTag = async (id, body) => {
  const res = await client.patch(`/tag/update/${id}/${auth.getStoreId()}`, body)
  return res.data
}

api.getTagByType = async type => {
  const res = await client.get(`/tag/get-by-type/${type}/${auth.getStoreId()}`)
  return res.data
}

//reject customer
api.createRejectCustomer = async body => {
  const res = await client.post(`/reject-customer/create`, body)
  return res.data
}
