import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, DraggableProvided, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'

import styled from 'styled-components';
import { DNDGroup } from './Group';
import { IDndGroup } from './type';

export const DropZone = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;
padding-bottom: 1rem;
`;

interface IDragItem {
  id: number;
  index: number;
  item: React.ReactNode;
  group: string;
  metadata?: any;
}

// Props for DropZoneTab
interface DropZoneTabProps {
  tabName: string;
  items: IDragItem[];
  groups: IDndGroup[];
  onDrop: (result: any, newList: any[]) => void;
}

// DropZoneTab component
const DropZoneTab: React.FC<DropZoneTabProps> = ({ tabName, items: _items, onDrop, groups: groupMapping }) => {
  const [items, setItems] = useState<IDragItem[]>(_items);
  const [initialized, setInitialized] = useState(false);
  const [isDragging, setIsDragging] = useState<{
    draggableId: string;
    source: {
      droppableId: string;
      index: number;
    };
  } | undefined>(undefined);

  useEffect(() => {
    if (!initialized && _items) {
      setItems(_items);
      setInitialized(true);
    }
  }, [_items, initialized]);

  const groups = groupMapping?.map((group: any) => group.id);

  const handleOnDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      setIsDragging(undefined);
  
      if (!result.destination) {
        return;
      }
  
      const sourceGroup = result.source.droppableId;
      const destinationGroup = result.destination.droppableId;
  
      const newItems = Array.from(items);
      const [reorderedItem] = newItems.splice(result.source.index, 1);
      
      // Update the group of the dragged item if it's moved to a different group
      if (sourceGroup !== destinationGroup) {
        reorderedItem.group = destinationGroup;
      }
  
      newItems.splice(result.destination.index, 0, reorderedItem);
  
      // Update indices for all items
      const finalItems = newItems.map((item, index) => ({
        ...item,
        index
      }));
  
      setItems(finalItems);
      onDrop(result, finalItems);
    },
    [items, setItems, onDrop]
  );


  const handleOnDragStart = useCallback((result: any) => {
    setIsDragging(result);
  }, []);

  if (!items || !groupMapping) {
    return null;
  }

  return (
    <div className="groups">
      <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
          {groups.map((group: any, index: number) => (
                    <Droppable droppableId={`${group}`} key={group}>
                    {(provided) => (
                      <DropZone
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                          <DNDGroup 
                          actions={groupMapping.find((groupItem) => groupItem.id === group)?.actions}
                          isDragging={isDragging}
                          key={group} group={group} items={
                              items.filter((item) => item.group === group)
                            } groupMapping={groupMapping} /> 
                        {provided.placeholder}
                      </DropZone>
                    )}
                  </Droppable>
          ))}
          
      </DragDropContext>
    </div>
  );
};

export default DropZoneTab;