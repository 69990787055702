import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import CardCustomer from './CardCustomer'
import CardOrder from './CardOrder'
import CArdSummary from './CardSummary'
import ReservationDetail from './ReservationDetail'
import useCardBook from './useCardBook'

import { RoomOrderState, StaffOrderState } from '../types'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

interface CardBookProps {
  staffOrders: StaffOrderState[]
  roomOrders: RoomOrderState[]
}

const CardBook = ({ staffOrders, roomOrders }: CardBookProps) => {
  const { currentDate } = useCardBook()
  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  return (
    <Container>
      <ReservationDetail />
      <CardOrder
        currentDate={currentDate}
        staffOrders={staffOrders}
        roomOrders={roomOrders}
      />
      <CArdSummary bookingOrderState={bookingOrderState} />
    </Container>
  )
}

export default CardBook
