import React from 'react'

interface SaveIconProps {
  size?: string
  color?: string
}

const SaveIcon = ({ size, color }: SaveIconProps) => (
  <svg
    width={size || '32'}
    height={size || '32'}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25.9515 0H3.26295C1.46361 0 0 1.46361 0 3.26295V28.737C0 30.5416 1.46361 32 3.26295 32H28.737C30.5416 32 32 30.5364 32 28.737V6.04852L25.9515 0ZM18.8852 3.14754V5.77049H13.1148V3.14754H18.8852ZM11.0164 28.8525V23.6066H20.9836V28.8525H11.0164ZM28.8525 28.737C28.8525 28.8 28.8 28.8525 28.737 28.8525H24.1311V22.4839C24.1311 21.3666 23.2236 20.459 22.1062 20.459H9.89377C8.77639 20.459 7.86885 21.3666 7.86885 22.4839V28.8525H3.26295C3.2 28.8525 3.14754 28.8 3.14754 28.737V3.26295C3.14754 3.2 3.2 3.14754 3.26295 3.14754H9.96721V6.89311C9.96721 8.01049 10.8748 8.91803 11.9921 8.91803H20.0079C21.1252 8.91803 22.0328 8.01049 22.0328 6.89311V3.14754H24.6505L28.8525 7.34951V28.737Z'
      fill={color || 'white'}
    />
  </svg>
)

export default SaveIcon
