import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import Button2 from '../../atoms/Button2'
import CameraIcon from '../../atoms/CameraIcon'
import InputText from '../../atoms/InputText'
import SaveIcon from '../../atoms/SaveIcon'
import TextLabel from '../../atoms/TextLabel'
import UploadImageIcon from '../../atoms/UploadImageIcon'
import utils from '../../utils'
import ConnectLine from '../settingStaff/ConnectLine'
import SwitchButton from '../../atoms/SwitchButton'
import PopupAddPin from '../popups/PopupAddPin'
import PinIcon from '../../atoms/PinIcon'
import Selector from './Selector'
import useOnClickOutside from '../../hook/useOnClickOutside'
import PopupAddPassword from '../popups/PopupAddPassword'
import auth from '../../auth'
import api from '../../api'
import PopupRole from '../popups/PopupRole'
import SuccessIcon2 from '../../atoms/SuccessIcon2'
import { fetchUser } from '../../redux/actions/userAction'
import ButtonSave from '../../atoms/ButtonSave'
import Scrollbar from '../../atoms/Scrollbar'
import config from '../../config'

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`
const Card = styled.div`
  width: 100%;
  /* max-height: calc(100vh - 6.5rem);
  min-height: calc(100vh - 6.5rem); */
  background: #ffffff;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
const ImageBox = styled.div`
  border-radius: 16px;
  background: #e4e8eb;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  margin-top: 1rem;
  /* margin: 0 auto; */
`
const ImageUploadIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -0.25rem;
`
const Image = styled.img`
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  object-fit: cover;
`
const Box = styled.div`
  display: flex;
  width: calc(100%);
  align-items: ${props => props.alignItem || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
`
const BoxStyle = styled.div`
  flex: ${props => props.flex || '1 0 50%'};
  width: ${props => props.width || '50%'};
`
const BoxText = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: space-between;
  align-items: ${props => props.alignItem || 'none'};
  font-weight: 600;
`
const Line = styled.div`
  width: 100%;
  margin: 1rem 0;
  border-top: 1px solid #e4e8eb;
`
const BoxPassword = styled.div`
  background: #f3eefb;
  border: ${props => props.border || '2px solid #f3eefb'};
  box-shadow: 0px 4px 4px rgba(71, 84, 103, 0.05);
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  color: #8c56d9;
  width: 100%;
  padding: 0.25rem;
`
const Circle = styled.div`
  border-radius: 50%;
  background-color: #db2d1e;
  width: 15px;
  height: 15px;
  position: relative;
  ::before {
    display: block;
    position: absolute;
    content: '!';
    border-radius: 50%;
    color: white;
    font-weight: normal;
    left: 6px;
    top: -4px;
  }
`

const CardAddUser = ({
  handleClose,
  currentIdEdit = -1,
  currentDataEdit = '',
  update,
}) => {
  const wrapperRef = useRef(null)
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user)
  const [image, setImage] = useState('')
  const [fileImage, setFileImage] = useState('')
  const [user, setUser] = useState({
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  })
  const [checkRequired, setCheckRequired] = useState([
    false,
    false,
    false,
    false,
    false,
  ])
  const [openAddPin, setOpenAddPin] = useState(false)
  const [openAddPassword, setOpenAddPassword] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')
  const [currentPin, setCurrentPin] = useState('')
  const [pin, setPin] = useState('')
  const [verifyPin, setVerifyPin] = useState('')
  const [status, setStatus] = useState(true)
  const userTypes = [
    { typeId: 1, type: 'Owner', typeThai: 'เจ้าของ' },
    { typeId: 2, type: 'Manager', typeThai: 'ผู้จัดการ' },
    { typeId: 4, type: 'Staff', typeThai: 'พนักงานทั่วไป' },
  ]
  const [defaultOption, setDefaultOption] = useState(userTypes[0])
  const [openArrow, setOpenArrow] = useState(false)
  const [openRole, setOpenRole] = useState(false)
  const [success, setSuccess] = useState(false)
  const [currentUserType, setCurrentUserType] = useState(false)
  const [samePhoneNumber, setSamePhoneNumber] = useState(false)
  const [sameUserId, setSameUserId] = useState(false)
  const [sameEmail, setSameEmail] = useState(false)
  const [token, setToken] = useState('')

  useEffect(() => {
    if (currentDataEdit && currentIdEdit !== -1) {
      const findUserType = userTypes.findIndex(u =>
        currentDataEdit.types.find(
          t => t.storeId === +auth.getStoreId() && u.typeId === t.typeId
        )
      )
      const findUserStatus = currentDataEdit.statuses.findIndex(
        s => s.storeId === auth.getStoreId()
      )
      // currentDataEdit.types.find(t => t.storeId === auth.getStoreId()) || ''
      if (findUserType !== -1) {
        dispatch(fetchUser())
        setDefaultOption(userTypes[findUserType])

        // setUserType(userTypes[findUserType])
      }
      if (findUserStatus !== -1) {
        setStatus(currentDataEdit.statuses[findUserStatus].status !== -1)
      }
      setUser(currentDataEdit)
      setImage(currentDataEdit.image || '')
    }
  }, [])

  useEffect(() => {
    if (currentUser._id !== 0) {
      if (currentUser.types[0].typeId === 0) {
        setCurrentUserType({
          storeId: auth.getStoreId(),
          brandId: auth.getBrandId(),
          type: 'Admin',
          typeId: 0,
        })
      } else {
        const findUserType =
          currentUser.types.find(
            t => t.storeId === parseInt(auth.getStoreId())
          ) || ''
        setCurrentUserType(findUserType)
      }
    }
  }, [currentUser])

  const onChangeImage = async event => {
    const file = event.target.files[0]
    if ('|png|jpeg|jpg'.indexOf(file.type.split('/')[1]) === -1) {
      alert('กรุณาใช้เป็นรูปภาพเท่านั้น')
    } else if (utils.isFileExceedLimit(file)) {
      alert('ขนาดรูปภาพของคุณใหญ่เกินไป')
    } else {
      setFileImage(file)
      const image = await utils.readImage(file)
      setImage(image.image)
    }
  }

  const onUserIdChange = value => {
    const newArrCheckRequired = [...checkRequired]
    const newUser = { ...user }
    newUser.userId = value
    newArrCheckRequired[0] = false
    setCheckRequired(newArrCheckRequired)
    setUser(newUser)
  }
  const onFirstNameChange = value => {
    const newUser = { ...user }
    newUser.firstName = value
    setUser(newUser)
  }
  const onLastNameChange = value => {
    const newUser = { ...user }
    newUser.lastName = value
    setUser(newUser)
  }
  const onEmailChange = value => {
    const newArrCheckRequired = [...checkRequired]
    const newUser = { ...user }
    newUser.email = value
    newArrCheckRequired[1] = false
    setCheckRequired(newArrCheckRequired)
    setUser(newUser)
  }
  const onPhoneNumberChange = value => {
    const newArrCheckRequired = [...checkRequired]
    const newUser = { ...user }
    if (value.length <= config.MAX_PHONE_NUMBER_LENGTH) {
      if (utils.checkNumber(value)) {
        newUser.phoneNumber = value
      } else if (!value) {
        newUser.phoneNumber = ''
      }
      newArrCheckRequired[2] = false
      setCheckRequired(newArrCheckRequired)
      setUser(newUser)
    }
  }
  const onSelectOptionClick = index => {
    setDefaultOption(userTypes[index])
    setOpenArrow(!openArrow)
  }
  useOnClickOutside(wrapperRef, () => {
    setOpenArrow(false)
  })
  const onArrowClick = () => {
    setOpenArrow(!openArrow)
  }
  const onPinChange = (currentPin, pin, verifyPin, token) => {
    const newArrCheckRequired = [...checkRequired]
    newArrCheckRequired[4] = false
    setCheckRequired(newArrCheckRequired)
    setCurrentPin(currentPin)
    setPin(pin)
    setVerifyPin(verifyPin)
    setToken(token)
    setOpenAddPin(false)
  }
  const onPasswordChange = (
    currentPassword,
    password,
    verifyPassword,
    token
  ) => {
    const newArrCheckRequired = [...checkRequired]
    newArrCheckRequired[3] = false
    setCheckRequired(newArrCheckRequired)
    setCurrentPassword(currentPassword)
    setPassword(password)
    setVerifyPassword(verifyPassword)
    setToken(token)
    setOpenAddPassword(false)
  }

  const onSaveClick = async () => {
    try {
      if (currentUserType.typeId !== 1 && currentUserType.typeId !== 0) {
        return false
      }
      const newArrCheckRequired = [...checkRequired]
      if (!user.userId) {
        newArrCheckRequired[0] = true
      }
      if (!user.email || !/.+@.+\..+/.test(user.email)) {
        newArrCheckRequired[1] = true
      }
      if (!user.phoneNumber) {
        newArrCheckRequired[2] = true
      }
      if (!password && currentIdEdit === -1) {
        newArrCheckRequired[3] = true
      }
      if (!pin && currentIdEdit === -1) {
        newArrCheckRequired[4] = true
      }

      if (newArrCheckRequired.includes(true)) {
        setCheckRequired(newArrCheckRequired)
        return false
      } else {
        let create = {
          ...user,
          type: defaultOption.type,
          typeId: defaultOption.typeId,
        }

        if (fileImage) {
          const { data } = await api.uploadImageUser({
            token: auth.getToken(),
            image: fileImage,
          })
          create = { ...create, image: data }
        }
        if (currentIdEdit === -1) {
          const { success: checkSamePhoneNumber } =
            await api.getUserCheckPhoneNumber(user.phoneNumber, -1)
          const { success: checkSameUserId } = await api.getUserCheckUserId(
            user.userId,
            -1
          )
          const { success: checkSameEmail } = await api.getUserCheckEmail(
            user.email,
            -1
          )
          if (!checkSamePhoneNumber || !checkSameUserId || !checkSameEmail) {
            setSamePhoneNumber(!checkSamePhoneNumber)
            setSameUserId(!checkSameUserId)
            setSameEmail(!checkSameEmail)
          } else {
            await callApiCreateUser(create)
          }
        } else {
          const { success: checkSamePhoneNumber } =
            await api.getUserCheckPhoneNumber(
              user.phoneNumber,
              currentDataEdit._id
            )
          const { success: checkSameUserId } = await api.getUserCheckUserId(
            user.userId,
            currentDataEdit._id
          )
          const { success: checkSameEmail } = await api.getUserCheckEmail(
            user.email,
            currentDataEdit._id
          )
          if (!checkSamePhoneNumber || !checkSameUserId || !checkSameEmail) {
            setSamePhoneNumber(!checkSamePhoneNumber)
            setSameUserId(!checkSameUserId)
            setSameEmail(!checkSameEmail)
          } else {
            await callApiUpdateUser(create)
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callApiCreateUser = async create => {
    try {
      const { success } = await api.createUser({
        ...create,
        password,
        pin,
        types: [
          {
            storeId: auth.getStoreId(),
            brandId: auth.getBrandId(),
            type: defaultOption.type,
            typeId: defaultOption.typeId,
          },
        ],
        statuses: { storeId: auth.getStoreId(), status: status ? 1 : -1 },
        stores: [auth.getStoreId()],
      })
      if (success) {
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          update()
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err.massage)
    }
  }
  const callApiUpdateUser = async create => {
    try {
      const newArrStore = [...user.stores]
      const newArrType = [...user.types]
      const newArrStatus = [...user.statuses]

      if (!user.stores.includes(parseInt(auth.getStoreId()))) {
        newArrStore.push(auth.getStoreId())
        newArrType.push({
          storeId: auth.getStoreId(),
          brandId: auth.getBrandId(),
          type: defaultOption.type,
          typeId: defaultOption.typeId,
        })
        newArrStatus.push({
          storeId: auth.getStoreId(),
          brandId: auth.getBrandId(),
          status: status ? 1 : -1,
        })
      } else {
        const findRole = newArrType.findIndex(
          r => r.storeId === +auth.getStoreId()
        )
        if (findRole !== -1) {
          newArrType[findRole] = {
            storeId: auth.getStoreId(),
            brandId: auth.getBrandId(),
            type: defaultOption.type,
            typeId: defaultOption.typeId,
          }
        }
        const findStatus = newArrStatus.findIndex(
          s => s.storeId === auth.getStoreId()
        )
        newArrStatus[findStatus] = {
          storeId: auth.getStoreId(),
          brandId: auth.getBrandId(),
          status: status ? 1 : -1,
        }
      }

      const body = {
        ...create,
        stores: newArrStore,
        types: newArrType,
        statuses: newArrStatus,
      }
      const { success } = await api.updateUser(
        user._id,
        auth.getStoreId(),
        body
      )

      if (success) {
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          update()
          handleClose()
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card>
        <Scrollbar id='setting-cardAddUser'>
          <Box alignItem='flex-end' gap='0'>
            <BoxStyle flex='1 0 20%' width='20%'>
              <ImageBox>
                <CameraIcon size='40' />
                <ImageUploadIcon>
                  <label for='upload-image'>
                    <UploadImageIcon size='30' colorCircle='#8C56D9' />
                  </label>
                  <input
                    id='upload-image'
                    type='file'
                    onChange={onChangeImage}
                    style={{ display: 'none', visibility: 'none' }}
                  />
                </ImageUploadIcon>
                {image && <Image src={image} />}
              </ImageBox>
            </BoxStyle>
            <BoxStyle>
              <BoxText className='mt-3 mb-2'>
                <TextLabel
                  fontWeight='600'
                  // color={checkName ? '#DB2D1E' : ''}
                >
                  User Id*
                </TextLabel>
              </BoxText>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                border={
                  checkRequired[0] || sameUserId ? '2px solid #DB2D1E' : ''
                }
                onChange={val => onUserIdChange(val.target.value)}
                value={user.userId}
              />
              {sameUserId && (
                <div
                  className='mt-1'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <Circle></Circle>
                  <TextLabel color='#DB2D1E' fontWeight='500'>
                    UserId นี้ถูกใช้งานแล้ว
                  </TextLabel>
                </div>
              )}
            </BoxStyle>
          </Box>
          <Box>
            <BoxStyle className='pr-2'>
              <BoxText className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>ชื่อจริง*</TextLabel>
              </BoxText>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                onChange={val => onFirstNameChange(val.target.value)}
                value={user.firstName}
              />
            </BoxStyle>
            <BoxStyle className='pl-2'>
              <BoxText className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>นามสกุล*</TextLabel>
              </BoxText>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                onChange={val => onLastNameChange(val.target.value)}
                value={user.lastName}
              />
            </BoxStyle>
          </Box>
          <Box>
            <BoxStyle className='pr-2'>
              <BoxText className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>อีเมล*</TextLabel>
              </BoxText>
              <InputText
                name='email'
                padding='0.25rem 0 0.25rem 1rem'
                border={
                  checkRequired[1] || sameEmail ? '2px solid #DB2D1E' : ''
                }
                type='email'
                placeholder='xxxxx@xxxx.com'
                onChange={val => onEmailChange(val.target.value)}
                value={user.email}
              />
              {sameEmail && (
                <div
                  className='mt-1'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <Circle></Circle>
                  <TextLabel color='#DB2D1E' fontWeight='500'>
                    Email นี้ถูกใช้งานแล้ว
                  </TextLabel>
                </div>
              )}
            </BoxStyle>
            <BoxStyle className='pl-2'>
              <BoxText className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>เบอร์โทร*</TextLabel>
              </BoxText>
              <InputText
                padding='0.25rem 0 0.25rem 1rem'
                border={
                  checkRequired[2] || samePhoneNumber ? '2px solid #DB2D1E' : ''
                }
                placeholder='0xxxxxxxxx'
                onChange={val => onPhoneNumberChange(val.target.value)}
                value={user.phoneNumber}
              />
              {samePhoneNumber && (
                <div
                  className='mt-1'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <Circle></Circle>
                  <TextLabel color='#DB2D1E' fontWeight='500'>
                    เบอร์โทรนี้ถูกใช้งานแล้ว
                  </TextLabel>
                </div>
              )}
            </BoxStyle>
          </Box>
          <Box>
            <BoxStyle className='pr-2' flex='1 0 85%' width='85%'>
              <BoxText className='mt-3 mb-2'>
                <TextLabel fontWeight='600'>สิทธิ์การเข้าใช้</TextLabel>
              </BoxText>
              <Selector
                wrapperRef={wrapperRef}
                options={userTypes}
                onSelectOptionClick={onSelectOptionClick}
                onArrowClick={onArrowClick}
                openArrow={openArrow}
                defaultOption={defaultOption}
              />
            </BoxStyle>
            <BoxStyle flex='1 0 auto' width='auto'>
              <BoxText className='mt-3 mb-2' style={{ visibility: 'hidden' }}>
                <TextLabel fontWeight='600'>{'asdasd'}</TextLabel>
              </BoxText>
              <Button2
                backgroundColor='#32D582'
                color='#FFF'
                onClick={() => setOpenRole(!openArrow)}
              >
                ดู
              </Button2>
            </BoxStyle>
          </Box>

          {currentIdEdit !== -1 && (
            <Box>
              <ConnectLine lineId={currentDataEdit.lineUserId} />
            </Box>
          )}
          <Box>
            <BoxText
              className='mt-3'
              flexDirection='column'
              style={{ width: '100%' }}
            >
              <TextLabel fontWeight='600'>รหัสผ่าน*</TextLabel>
              <BoxPassword
                className='mt-2'
                border={checkRequired[3] ? '2px solid #DB2D1E' : ''}
                onClick={() => setOpenAddPassword(!openAddPassword)}
              >
                <PinIcon size='28' color='#8C56D9' />
                &nbsp;&nbsp; {currentIdEdit === -1 ? 'ตั้ง' : 'เปลี่ยน'}รหัส
              </BoxPassword>
            </BoxText>
          </Box>
          {/* <DivInputStyle>
            <InputText
              type={seePassword ? 'text' : 'password'}
              padding='0.25rem 0 0.25rem 1rem'
              onChange={val => setPassword(val.target.value)}
              value={password}
              required={currentIdEdit === -1}
            />
            <IconStyle onClick={() => setSeePassword(!seePassword)}>
              {!seePassword ? (
                <EyeIcon size='20' />
              ) : (
                <EyeCloseIcon size='20' />
              )}
            </IconStyle>
          </DivInputStyle> */}
          <Box>
            <BoxText
              className='mt-3 mb-2'
              flexDirection='column'
              style={{ width: '100%' }}
            >
              <TextLabel fontWeight='600'>รหัส Pin*</TextLabel>
              <BoxPassword
                className='mt-2'
                border={checkRequired[4] ? '2px solid #DB2D1E' : ''}
                onClick={() => setOpenAddPin(!openAddPin)}
              >
                <PinIcon size='28' color='#8C56D9' />
                &nbsp;&nbsp; {currentIdEdit === -1 ? 'ตั้ง' : 'เปลี่ยน'}รหัส Pin
              </BoxPassword>
            </BoxText>
          </Box>
          <Line></Line>
          <Box alignItem='center' justifyContent='space-between'>
            <BoxText className='mt-3 mb-2' flexDirection='column'>
              <TextLabel fontWeight='600'>สถานะ</TextLabel>
              <TextLabel color='#98A1B2' fontSize='0.75rem'>
                ทำงานกับที่ร้าน / พักงานหรือเลิกจ้าง
              </TextLabel>
            </BoxText>
            <SwitchButton
              name='stats-user'
              toggled={status}
              onChange={() => setStatus(!status)}
              backgroundColor={status ? '#8C56D9' : '#ABABAD'}
              // labelHeight='20px'
              // width='40%'
              // labelWidth='30%'
              width='18px'
              labelHeight='20px'
              labelWidth='40px'
            />
          </Box>
          <div
            style={{
              width: '100%',
              // height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
              position: 'sticky',
              bottom: '0rem',
              zIndex: '2',
              background: '#FFFFFF',
            }}
          >
            <div className='mb-2' style={{ width: '100%', display: 'flex' }}>
              <div className='mr-1' style={{ display: 'flex', width: '50%' }}>
                <Button2
                  color='#98A1B2'
                  border='2px solid #98A1B2'
                  backgroundColor='white'
                  backgroundColorHover='#E5E7EC'
                  fontWeight='600'
                  onClick={() => handleClose()}
                >
                  ยกเลิก
                </Button2>
              </div>
              <div className='ml-1' style={{ display: 'flex', width: '50%' }}>
                {currentUserType.typeId === 1 ||
                currentUserType.typeId === 0 ? (
                  <Button2
                    border='2px solid #53389E'
                    color='#FFFFFF'
                    backgroundColor='linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%)'
                    backgroundColorHover='linear-gradient(266.17deg, #8D67CD 1.44%, #6E57AE 100%)'
                    fontWeight='600'
                    icon={<SaveIcon size='15' />}
                    onClick={() => onSaveClick()}
                  >
                    บันทึก
                  </Button2>
                ) : (
                  <ButtonSave
                    border='2px solid #F8F9FB'
                    color='#98A1B2'
                    backgroundColor='#F8F9FB'
                    backgroundColorHover='#F8F9FB'
                    fontWeight='600'
                    icon={<SaveIcon size='15' />}
                  >
                    บันทึก
                  </ButtonSave>
                )}
              </div>
            </div>
          </div>
        </Scrollbar>
      </Card>
      {openAddPin && (
        <PopupAddPin
          handleClose={() => setOpenAddPin(!openAddPin)}
          onPinChange={onPinChange}
          currentIdEdit={currentIdEdit}
          currentDataEdit={currentDataEdit}
        />
      )}
      {openAddPassword && (
        <PopupAddPassword
          handleClose={() => setOpenAddPassword(!openAddPassword)}
          currentIdEdit={currentIdEdit}
          currentDataEdit={currentDataEdit}
          onPasswordChange={onPasswordChange}
        />
      )}
      {openRole && (
        <PopupRole
          handleClose={() => setOpenRole(!openRole)}
          role={defaultOption}
        />
      )}
      {success && <SuccessIcon2 position='absolute' />}
    </Container>
  )
}

export default CardAddUser
