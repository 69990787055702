import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import CardStaff from './CardStaff'
import CardHistory from './CardHistory'
import CardListService from './CardListService'
import CardSummary from './CardSummary'
import Head from './Head'
import auth from '../../auth'

const Container = styled.div`
  width: calc(100%);
  flex: 1;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
`
const GridBox = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  width: 100%;
  gap: 0.75rem;
`

const CardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Body = ({
  staffTypes,
  staffs,
  activeMenu,
  date,
  updateStaffs,
  onDateStartChange,
  onDateEndChange,
  onMenuClick,
}) => {
  const currentUser = useSelector(state => state.user)
  const [activeStaff, setActiveStaff] = useState({ staffId: -1, date: [] })
  const [currentUserType, setCurrentUserType] = useState(-1)

  useEffect(() => {
    if (currentUser._id !== 0) {
      if (currentUser.types[0].typeId === 0) {
        setCurrentUserType({
          storeId: auth.getStoreId(),
          type: 'Admin',
          typeId: 0,
        })
      } else {
        const findUserType =
          currentUser.types.find(
            t => t.storeId === parseInt(auth.getStoreId())
          ) || ''
        setCurrentUserType(findUserType)
      }
    }
  }, [currentUser])

  useEffect(() => {
    if (activeStaff.staffId !== -1) {
      const findStaff = staffs.find(s => s.staffId === activeStaff.staffId)
      if (findStaff) setActiveStaff(findStaff)
    }
  }, [date, staffs])

  const onStaffClick = staffId => {
    if (activeStaff.staffId !== staffId) {
      const findStaff = staffs.find(s => s.staffId === staffId)
      if (findStaff) setActiveStaff(findStaff)
    } else {
      setActiveStaff({ staffId: -1, date: [] })
    }
  }

  return (
    <Container>
      <Head
        date={date}
        onDateStartChange={onDateStartChange}
        onDateEndChange={onDateEndChange}
        activeMenu={activeMenu}
        onMenuClick={onMenuClick}
      />
      <GridBox>
        <CardStaff
          staffTypes={staffTypes}
          staffs={staffs}
          onStaffClick={onStaffClick}
          activeStaff={activeStaff}
          activeMenu={activeMenu}
        />
        <CardHistory
          activeStaff={activeStaff}
          updateStaffs={updateStaffs}
          currentUserType={currentUserType}
        />
        <CardContainer>
          <CardListService activeStaff={activeStaff} />
          <CardSummary
            date={date}
            activeStaff={activeStaff}
            updateStaffs={updateStaffs}
            currentUserType={currentUserType}
            activeMenu={activeMenu}
          />
        </CardContainer>
      </GridBox>
    </Container>
  )
}

export default Body
