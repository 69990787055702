import {
  CLEAR_ORDER_BOOKING,
  ClearOrderBookingAction,
  GET_ORDER_BOOKING,
  GetOrderBookingAction,
} from '../actions/bookingHistoryAction'
import { BookOrder, getInitialBookOrder } from '../models/BookOrder'

type State = BookOrder

type Action = GetOrderBookingAction | ClearOrderBookingAction

const bookingHistoryReducers = (
  state: State = getInitialBookOrder(),
  action: Action
): State => {
  switch (action.type) {
    case GET_ORDER_BOOKING:
      return {
        ...state,
        ...action.payload.data,
      }
    case CLEAR_ORDER_BOOKING:
      return getInitialBookOrder()
    default:
      return state
  }
}

export default bookingHistoryReducers
