import React, { useState } from 'react'
import styled from 'styled-components'

import CheckBox from '../../atoms/CheckBox'
import ImageBackground from '../../atoms/ImageBackground'
import TextLabel from '../../atoms/TextLabel'
import DeleteIcon from '../../atoms/DeleteIcon'
import utils from '../../utils'
import Scrollbar from '../../atoms/Scrollbar'

const Components = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-top: 1rem;
`

const Table = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: ${props => props.flexDirection || 'column'};
  /* padding: ${props => props.padding || '1rem 0.5rem 1rem 1rem'}; */
`

const Tr = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: 100%;
  padding: ${props => props.padding || 0};
  background: ${props => props.backgroundColor || ''};
  margin-top: 0.4rem;
  border: ${props => props.border || 'none'};
  border-radius: 8px;
`

const Th = styled.div`
  align-items: center;
  justify-content: ${props => props.justifyContent || 'center'};
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex: ${props => props.flex || '1 1 auto'};
  margin: ${props => props.margin || '0'};
  max-width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};
  background: ${props => props.background || 'white'};
  padding: ${props => props.padding || '1rem 0'};
  font-weight: 500;
  :first-child {
    border-radius: 8px 0 0 8px;
  }
  :last-child {
    border-radius: 0 8px 8px 0;
  }
`

const Image = styled.img`
  border-radius: 8px;
  width: 30px;
  height: 30px;
  object-fit: cover;
`

const Circle = styled.div`
  background: #98a1b2;
  border-radius: 50%;
  width: 4px;
  height: 4px;
`

const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgb(107 107 107 / 16%);
  border-radius: 8px;
  padding: 0.5rem;
  top: 35px;
  left: -15px;
  z-index: 5;
`

const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`

const BodyCustomer = ({
  customers,
  onSelectCustomer,
  search = '',
  onDeleteClick,
}) => {
  const [checkId, setCheckId] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [customerId, setCustomerId] = useState('')
  const [edit, setEdit] = useState(false)

  const onCustomerClick = (e, _id) => {
    e.stopPropagation()
    if (_id === customerId) {
      setCustomerId('')
    } else {
      setCustomerId(_id)
    }
  }

  const onCheckClick = _id => {
    const newArrCheckId = [...checkId]
    const findCheckIndex = newArrCheckId.findIndex(c => c === _id)
    if (findCheckIndex !== -1) {
      newArrCheckId.splice(findCheckIndex, 1)
    } else {
      newArrCheckId.push(_id)
      setCheckAll(false)
    }
    setCheckId(newArrCheckId)
  }

  const onCheckAllClick = () => {
    setCheckAll(!checkAll)
    setCheckId([])
  }

  const onEditCustomerClick = _id => {
    onSelectCustomer(_id)
    setEdit(true)
  }

  const onDeleteCustomerClick = (e, _id) => {
    e.stopPropagation()
    onDeleteClick(_id)
    setEdit(true)
  }

  const onEditClick = (e, _id) => {
    onSelectCustomer(_id)
    setEdit(true)
  }

  const bySearch = (customer, search) => {
    if (search !== '') {
      const name = customer.name.toLowerCase()
      const email = customer.email.toLowerCase()
      return (
        name.includes(search.toLowerCase()) ||
        email.includes(search.toLowerCase()) ||
        customer.phoneNumber.includes(search)
      )
    } else return customer
  }

  const filteredList = (customers, search) => {
    return customers.filter(customer => bySearch(customer, search))
  }

  return (
    <Components>
      <Table>
        <Tr>
          <Th background='none' flex='1 1 5%' width='5%'>
            <CheckBox
              backgroundColor='#8C56D9'
              heightCheckMark='18px'
              widthCheckMark='18px'
              left='4.5px'
              top='1.5px'
              borderRadius='3px'
              paddingLeft='20px'
              marginTop='2.5px'
              marginBottom='0'
              onClick={onCheckAllClick.bind(this)}
              onChange={onCheckAllClick.bind(this)}
              checked={checkAll}
            />
          </Th>
          <Th
            background='none'
            flex='1 1 20%'
            width='20%'
            justifyContent='flex-start'
          >
            ลูกค้า
          </Th>
          <Th background='none' flex='1 1 10%' width='10%'>
            เพศ
          </Th>
          <Th background='none' flex='1 1 15%' width='15%'>
            เบอร์โทร
          </Th>
          <Th background='none' flex='1 1 15%' width='15%'>
            อีเมล
          </Th>
          <Th background='none' flex='1 1 10%' width='10%'>
            ไลน์ไอดี
          </Th>
          <Th background='none'>ความต้องการเฉพาะ</Th>
          <Th
            background='none'
            flex='1 1 5%'
            width='5%'
            onClick={onDeleteClick.bind(this, checkAll, checkId)}
          >
            {checkAll || checkId.length > 1 ? (
              <div
                style={{
                  background: '#FFFFFF',
                  borderRadius: '8px',
                  padding: '0.5rem 0.65rem',
                  cursor: 'pointer',
                }}
              >
                <DeleteIcon color='#DB2D1E' size='20' />
              </div>
            ) : (
              ''
            )}
          </Th>
        </Tr>
        <Scrollbar id='setting-BodyCustomer'>
          {filteredList(customers, search).map(
            (
              {
                _id,
                name,
                phoneNumber,
                email,
                image,
                gender,
                specialRequest,
                lineId,
              },
              index
            ) => (
              <Tr
                key={index}
                border={
                  checkAll
                    ? '1px solid #8C56D9'
                    : checkId.includes(_id)
                    ? '1px solid #8C56D9'
                    : ''
                }
                onClick={e => onEditClick(e, _id)}
              >
                <Th flex='1 1 5%' width='5%'>
                  <CheckBox
                    backgroundColor='#8C56D9'
                    heightCheckMark='18px'
                    widthCheckMark='18px'
                    left='4.5px'
                    top='1.5px'
                    borderRadius='3px'
                    paddingLeft='20px'
                    marginTop='2.5px'
                    marginBottom='0'
                    onClick={onCheckClick.bind(this, _id)}
                    onChange={onCheckClick.bind(this, _id)}
                    checked={checkAll ? true : checkId.includes(_id)}
                  />
                </Th>
                <Th flex='1 1 20%' width='20%' justifyContent='flex-start'>
                  {image ? (
                    <Image src={image} />
                  ) : (
                    <ImageBackground size='30' />
                  )}
                  <TextLabel paddingLeft='1rem'>{name}</TextLabel>
                </Th>
                <Th flex='1 1 10%' width='10%'>
                  {gender === 2
                    ? 'หญิง'
                    : gender === 1
                    ? 'ชาย'
                    : gender === 3
                    ? 'อื่นๆ'
                    : 'ไม่ระบุ'}
                </Th>
                <Th flex='1 1 15%' width='15%'>
                  {utils.formatPhoneNumber(phoneNumber)}
                </Th>
                <Th flex='1 1 15%' width='15%'>
                  {email || '-'}
                </Th>
                <Th flex='1 1 10%' width='10%'>
                  {lineId || '-'}
                </Th>
                <Th>{specialRequest || '-'}</Th>
                <Th
                  flex='1 1 5%'
                  width='5%'
                  onClick={e => onCustomerClick(e, _id)}
                  style={{ position: 'relative' }}
                >
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div className='mr-1' key={index}>
                      <Circle></Circle>
                    </div>
                  ))}
                  {_id === customerId && (
                    <DropDown>
                      <Option onClick={() => onEditCustomerClick(_id)}>
                        แก้ไข
                      </Option>
                      <Option onClick={e => onDeleteCustomerClick(e, _id)}>
                        ลบ
                      </Option>
                    </DropDown>
                  )}
                </Th>
              </Tr>
            )
          )}
        </Scrollbar>
      </Table>
    </Components>
  )
}

export default BodyCustomer
