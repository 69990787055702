import React from 'react'
import styled from 'styled-components'
import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'
import { useTranslation } from 'react-i18next'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: ${props => props.gap || '0.5rem'};
  padding: ${props => props.padding || '0'};
  width: 100%;
  align-items: center;
  padding: 0.25rem;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: 100%;
  border-right: 1px solid #e4e8eb;
  height: calc(100% + 0.5rem);
  align-items: center;
  justify-content: center;
  :last-child {
    border-right: 0px solid #e4e8eb;
  }
`
const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0.25rem'};
  align-items: ${props => props.alignItems || 'unset'};
  justify-content: ${props => props.justifyContent || 'unset'};
  background-color: ${props => props.backgroundColor || ''};
  padding: 0;
  border: 1px solid #e4e8eb;
`

const TableProduct = ({ products }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Scrollbar id='payment-table-product' maxHeight={`200px`}>
        <GridBox columns='1fr 1fr 1fr 1fr'>
          <TextLabel textAlign='center'>สินค้า</TextLabel>
          <TextLabel textAlign='center'>ราคาต่อชิ้น</TextLabel>
          <TextLabel textAlign='center'>จำนวน</TextLabel>
          <TextLabel textAlign='center'>ราคารวม</TextLabel>
        </GridBox>
        {products.map(({ name, product, quantity, price }, index) => (
          <Box key={index}>
            <GridBox columns='1fr 1fr 1fr 1fr'>
              <FlexBox flexDirection='column'>
                <TextLabel>{name || product}</TextLabel>
              </FlexBox>
              <FlexBox>
                <TextLabel>{utils.formatNumber(+(price || 0), 2)}</TextLabel>
              </FlexBox>
              <FlexBox>{quantity}</FlexBox>
              <FlexBox flexDirection='column'>
                {/* <TextLabel>{t('totalAmount')}</TextLabel> */}
                <TextLabel>
                  {utils.formatNumber(+(price || 0) * +(quantity || 0), 2)}
                </TextLabel>
              </FlexBox>
            </GridBox>
          </Box>
        ))}
      </Scrollbar>
    </Container>
  )
}

export default TableProduct
