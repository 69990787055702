import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import TextLabel from '../../atoms/TextLabel'
import Body from './Body'
import Head from './Head'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`

const Main = () => {
  const [loading, setLoading] = useState(true)
  const currentUser = useSelector(state => state.user)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if (currentUser._id !== 0) {
      setLoading(false)
      if (currentUser.types[0].typeId === 0) {
        setIsAdmin(true)
      }
    }
  }, [currentUser])

  if (loading) return null

  return (
    <Container>
      {isAdmin ? (
        <>
          <Head />
          <Body />
        </>
      ) : (
        <TextLabel fontSize='5rem' fontWeight='600'>
          Error : 404
        </TextLabel>
      )}
    </Container>
  )
}

export default Main
