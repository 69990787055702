import { GET_DATA } from '../actions/dataExportReportAction'

const initState = {}

const dataExportReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}

export default dataExportReducers
