import styled, { CSSProperties } from 'styled-components'

export interface ArrowProps extends CSSProperties {}

const Arrow = styled.div<ArrowProps>`
  border: ${props => props.border || 'solid #000000'};
  border-width: ${props => props.borderWidth || '0 2px 2px 0'};
  display: inline-block;
  padding: ${props => props.padding || '3px'};
  margin: ${props => props.margin || '0 0 0 1px'};
  cursor: pointer;
  transform: ${props => props.transform || 'rotate(-225deg)'};
  transition: all 0.2s;
`

export default Arrow
