import { GET_STAFF_TYPE } from '../actions/callApiStaffTypeAction'

const initState = {}

const callApiStaffTypeReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_STAFF_TYPE:
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

export default callApiStaffTypeReducers
