const initState = {
  _id: 0,
  name: '',
  lineStaffId: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  image: '',
  gender: '',
  staffTypeId: 0,
  ApplicableServiceType: '',
  salary: 0,
  commissionFee: 0,
  fixedFee: 0,
  number: 0,
  workingStatus: 0,
  startTime: {
    hour: '',
    minute: '',
  },
  endTime: {
    hour: '',
    minute: '',
  },
  breakTime: [
    {
      startTime: {
        hour: '',
        minute: '',
      },
      endTime: {
        hour: '',
        minute: '',
      },
    },
  ],
  status: 0,
  storeId: '',
  workingDay: [
    {
      work: true,
      day: 0,
      startTime: { hour: '', minute: '' },
      endTime: { hour: '', minute: '' },
    },
  ],
  dayOff: [
    {
      date: '',
    },
  ],
}

const staffQueueReducers = (state = initState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default staffQueueReducers
