import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { activeOpenState } from './useCardCustomer'

import utils from '../../../../utils'
import logicStaffOrder from '../../logicStaffOrder'
import { StaffOrderState } from '../../types'

interface useCardStaff {
  staffOrders: StaffOrderState[]
  activeOpen: activeOpenState
  currentDate: Date
}

const useCardStaff = ({
  staffOrders,
  activeOpen,
  currentDate,
}: useCardStaff) => {
  const [search, setSearch] = useState<string>('')
  const [gender, setGender] = useState<number>(-1)
  const [activeStaff, setActiveStaff] = useState<number>(-1)

  const [isSameDate, setIsSameDate] = useState<boolean | undefined>(true)
  const [staffs, setStaffs] = useState<StaffOrderState[]>([])

  const { customerIndex, serviceIndex, staffIndex } = activeOpen

  const bookingOrderState = useSelector(
    (state: any) => state.bookingHistoryReducers
  )

  const { date, customers, time, focusedCustomerIndex } = bookingOrderState

  useEffect(() => {
    if (staffOrders.length && customerIndex !== -1) {
      setActiveStaff(
        customers[customerIndex].services[serviceIndex].staffs[staffIndex]
          .staffId || -1
      )
      createStaffs()
    }
  }, [staffOrders, activeOpen, time])

  useEffect(() => {
    const checkIsSameDate = async () => {
      const result = await utils.checkIsSameCurrentDate(currentDate, date)
      setIsSameDate(result)
    }

    checkIsSameDate()
  }, [currentDate, date])

  const onChange = (value: string | number, name: string) => {
    if (name === 'search') {
      setSearch(value + '')
    } else if (name === 'gender') {
      setGender(+value)
    }
  }

  const createStaffs = () => {
    let filteredStaffOrders: StaffOrderState[] = []
    filteredStaffOrders = [...staffOrders]
    if (!customers[customerIndex].services[serviceIndex].applyToAll) {
      filteredStaffOrders = logicStaffOrder.checkServiceSpecificStaff(
        staffOrders,
        customers[customerIndex].services[serviceIndex].serviceId
      )
    }

    setStaffs(
      logicStaffOrder.checkListStaffAvailability(
        filteredStaffOrders,
        customers,
        time,
        serviceIndex,
        focusedCustomerIndex,
        customers[customerIndex].services[serviceIndex].time
      )
    )
  }

  return { search, gender, activeStaff, isSameDate, staffs, onChange }
}
export default useCardStaff
