import { Order, PackageOrder, PromotionUnits } from './BookOrder'
import { PackageType, PackageTypes } from './BrandPackage'

import utils from '../../utils'

export interface CustomerPackageService {
  serviceMasterId: string
  serviceId: number
  timePrices: [{ timePriceId: number; time: number }]
}

export default interface CustomerPackage {
  _id: number
  packageId: number
  refPackageCode: string
  customerId: number
  packageCode: string
  name: string
  type: number
  price: number
  buyDate: Date | null
  expiryDay: number
  totalValue: number
  remainingValue: number
  allService: boolean
  services: CustomerPackageService[]
  status: number
  storeId: number
  edits: [
    {
      remainingValue: number
      expiryDay: number
      changedDate: Date
      status: number
      updateBy: { userId: number; userName: string }
    }
  ]
}

interface CustomerPackageUsage extends CustomerPackage {
  quantity: number
  select: boolean
  discount: number
}

export type CustomerPackageUsageGroup = {
  packages: CustomerPackageUsage[]
} & Pick<Order, 'customer' | 'name' | 'firstName' | 'customerId'>

const isServiceUsable = (
  service: CustomerPackageService,
  order: PackageOrder
) =>
  (service.serviceId === order.serviceId ||
    service.serviceMasterId === order.serviceMasterId) &&
  service.timePrices.some(t => t.time === order.time)

export const isPackageUsableByServices = (
  services: CustomerPackageService[],
  orders: PackageOrder[]
): boolean => {
  let findServiceIndex = orders.findIndex(order =>
    services.some(service => isServiceUsable(service, order))
  )
  return findServiceIndex >= 0
}

export const filterOrdersUsableByPackage = (
  customerPackageUsage: CustomerPackageUsage,
  orders: PackageOrder[]
): PackageOrder[] => {
  const filteredOrders = orders.filter(order =>
    customerPackageUsage.services.some(service =>
      isServiceUsable(service, order)
    )
  )
  return filteredOrders
}

export type UsableCustomerPackage = {
  customer: number
} & CustomerPackageUsage

export const createUsableCustomerPackages = (
  customerPackageUsageGroups: CustomerPackageUsageGroup[],
  type: PackageType
): UsableCustomerPackage[] => {
  const usableCustomerPackages = customerPackageUsageGroups.reduce(
    (acc, info) => {
      const { customer, packages } = info
      for (const currentPackage of packages) {
        if (currentPackage.type === type) {
          acc.push({ customer, ...currentPackage })
        }
      }
      return acc
    },
    [] as UsableCustomerPackage[]
  )

  return usableCustomerPackages
}

export const calculatePackageDiscount = (
  customerPackageUsageGroups: CustomerPackageUsageGroup[],
  orders: PackageOrder[],
  discount = 0
) => {
  const newCustomerPackageUsageGroups: CustomerPackageUsageGroup[] = JSON.parse(
    JSON.stringify(customerPackageUsageGroups)
  )

  let remainingDiscount = discount
  const calculatedOrders = orders.map(order => {
    let { price, serviceDiscount: currentDiscount = 0, unit } = order
    let serviceDiscount = currentDiscount
    if (unit === PromotionUnits.Percent) {
      serviceDiscount = ((currentDiscount || 0) / 100) * price
    }
    const finalPrice = price - serviceDiscount
    const discount = Math.min(finalPrice, remainingDiscount)
    const discountedPrice = finalPrice - discount
    remainingDiscount -= discount
    return {
      ...order,
      serviceDiscount,
      discountedPrice: discountedPrice,
      remainingPrice: discountedPrice,
      remainingDuration: order.time,
    }
  })

  for (const customerPackageUsageGroup of newCustomerPackageUsageGroups) {
    const { customer, packages } = customerPackageUsageGroup
    for (const currentPackage of packages) {
      const {
        select,
        allService,
        services: packageService,
        type,
      } = currentPackage
      const calculateOrderIndex = orders.findIndex(c => c.customer === customer)
      if (calculateOrderIndex >= 0) {
        const temp = orders[0]
        orders[0] = orders[calculateOrderIndex]
        orders[calculateOrderIndex] = temp
      }

      currentPackage.discount = 0
      let totalQuantity = 0
      for (const order of calculatedOrders) {
        let { time, discountedPrice, remainingPrice, remainingDuration } = order
        if (select) {
          if (currentPackage.quantity === 0) {
            continue
          }

          if (
            [
              PackageTypes.Money,
              PackageTypes.Percent,
              PackageTypes.Times,
            ].includes(type) &&
            remainingPrice === 0
          ) {
            continue
          }

          if (
            [PackageTypes.Minutes].includes(type) &&
            (remainingDuration === 0 || remainingPrice === 0)
          ) {
            continue
          }

          const isPackageUsable = isPackageUsableByServices(packageService, [
            order,
          ])

          if (allService || isPackageUsable) {
            let discount = 0
            let quantity = 0
            if (type === PackageTypes.Money) {
              discount = +utils.roundToFixed(
                Math.min(remainingPrice, currentPackage.quantity),
                2
              )
              quantity = discount
            } else if (type === PackageTypes.Percent) {
              discount = +utils.roundToFixed(
                Math.min(
                  remainingPrice * (currentPackage.totalValue / 100),
                  discountedPrice * (currentPackage.totalValue / 100)
                ),
                2
              )
              quantity = discount
            } else if (type === PackageTypes.Times) {
              discount = +utils.roundToFixed(
                Math.min(remainingPrice, discountedPrice),
                2
              )
              quantity = 1
            } else if (type === PackageTypes.Minutes) {
              quantity = +utils.roundToFixed(
                Math.min(remainingDuration, currentPackage.quantity),
                2
              )
              discount = +utils.roundToFixed(
                Math.min(remainingPrice, (quantity / time) * discountedPrice),
                2
              )
            }

            order.remainingPrice -= discount
            if (type === PackageTypes.Minutes) {
              order.remainingDuration -= quantity
            } else {
              order.remainingDuration -= +utils.roundToFixed(
                (discount / order.discountedPrice) * order.time
              )
            }
            totalQuantity += quantity
            currentPackage.quantity -= quantity
            currentPackage.discount += discount
          }
        }
      }

      currentPackage.quantity = totalQuantity
      currentPackage.select = currentPackage.discount !== 0
    }
  }

  return newCustomerPackageUsageGroups
}
