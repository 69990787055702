import React from 'react'

const CircleStarIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 0C3.58167 0 0 3.58167 0 8C0 12.4183 3.58167 16 8 16C12.4183 16 16 12.4183 16 8C15.995 3.58374 12.4163 0.00500487 8 0ZM8 15C4.13403 15 1 11.866 1 8C1 4.13403 4.13403 1 8 1C11.866 1 15 4.13403 15 8C14.9957 11.8643 11.8643 14.9957 8 15Z'
        fill='#8C56D9'
      />
      <path
        d='M11.598 6.01001L9.338 5.55774L8.44725 3.77649C8.36253 3.60706 8.18944 3.5 7.99998 3.5C7.81053 3.5 7.63743 3.60706 7.55272 3.77649L6.66197 5.55774L4.40196 6.01001C4.23363 6.0437 4.09422 6.16138 4.03294 6.32178C3.97166 6.48218 3.99693 6.66284 4.09996 6.80029L5.46568 8.62146L5.00975 10.9022C4.97398 11.0819 5.03929 11.2667 5.18016 11.384C5.32091 11.5012 5.51451 11.5321 5.68468 11.4645L7.99998 10.5387L10.3142 11.4637C10.4845 11.5314 10.6781 11.5005 10.8188 11.3833C10.9596 11.266 11.025 11.0812 10.9892 10.9015L10.5332 8.62073L11.9 6.80029C12.003 6.66284 12.0283 6.48218 11.967 6.32178C11.9057 6.16138 11.7663 6.0437 11.598 6.01001ZM9.59996 8.2002C9.51463 8.31409 9.48179 8.45874 9.50975 8.59827L9.82872 10.1932L8.18577 9.53601C8.06651 9.48828 7.93346 9.48828 7.81419 9.53601L6.17125 10.1932L6.49022 8.59827C6.51817 8.45874 6.48533 8.31409 6.40001 8.2002L5.37498 6.83496L7.09703 6.49048C7.24852 6.46057 7.37779 6.36218 7.44701 6.224L7.99998 5.11829L8.55272 6.22522C8.62193 6.3634 8.7512 6.46179 8.90269 6.4917L10.625 6.83496L9.59996 8.2002Z'
        fill='#8C56D9'
      />
    </svg>
  )
}

export default CircleStarIcon
