import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import TextLabel from '../../atoms/TextLabel'
import PopupAddBookChannel from '../popups/PopupAddBookChannel'
import Scrollbar from '../../atoms/Scrollbar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const BoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`

const Box = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const BoxStyle = styled.div`
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  width: calc(50% - 0.5rem);
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`

const Circle = styled.div`
  background: #98a1b2;
  border-radius: 100%;
  width: 4px;
  height: 4px;
`

const BoxEdit = styled.div`
  display: flex;
  cursor: ${props => props.cursor || 'none'};
  position: relative;
`

const DropDown = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(107, 107, 107, 0.16);
  border-radius: 8px;
  padding: 0.5rem;
  top: 10px;
  right: 0px;
  z-index: 1;
`

const Option = styled.div`
  border-radius: 8px;
  padding: 0.25rem;
  :hover {
    background: #e4e8eb;
  }
  :focus {
    background: #e4e8eb;
  }
`

const BodyBookChannel = ({
  bookingChannels: _bookingChannels = [],
  update,
}) => {
  const bookingChannelGroup = [
    { bookingTypeId: 2, bookingType: 'Booking' },
    { bookingTypeId: 3, bookingType: 'Agency' },
    { bookingTypeId: 1, bookingType: 'walk-in' },
    { bookingTypeId: -1, bookingType: 'ช่องทางการจองที่ถูกปิดการใช้งาน' },
  ]
  const [bookingChannels, setBookingChannels] = useState([])
  const [activeEdit, setActiveEdit] = useState(-1)
  const [currentIdEdit, setCurrentIdEdit] = useState(-1)
  const [currentBookChannel, setCurrentBookChannel] = useState('')

  useEffect(() => {
    const bookDisabled = _bookingChannels.filter(d => d.status === -1)

    let bookingChannels = []
    for (let i = 0; i < bookingChannelGroup.length; i++) {
      const { bookingTypeId, bookingType, status } = bookingChannelGroup[i]
      if (bookingTypeId === 1 && status !== -1) {
        bookingChannels.push({
          bookingTypeId: bookingTypeId,
          bookingType: bookingType,
          bookingChannels: [
            {
              _id: 0,
              bookingChannel: 'walk-in',
            },
          ],
        })
      } else {
        bookingChannels.push({
          bookingTypeId: bookingTypeId,
          bookingType: bookingType,
          bookingChannels: [],
        })
      }

      for (let j = 0; j < _bookingChannels.length; j++) {
        const { bookingTypeId: _bookingTypeId, status } = _bookingChannels[j]
        if (_bookingTypeId === bookingTypeId && status !== -1) {
          bookingChannels[i].bookingChannels.push(_bookingChannels[j])
        }
      }
    }
    bookingChannels[bookingChannels.length - 1].bookingChannels = [
      ...bookDisabled,
    ]

    setBookingChannels(bookingChannels)
  }, [_bookingChannels])

  const onOpenEditClick = (e, _id) => {
    e.stopPropagation()
    if (_id === activeEdit) {
      setActiveEdit(-1)
    } else {
      setActiveEdit(_id)
    }
  }
  const onEditBookChannelClick = (e, _id) => {
    e.stopPropagation()
    const findBookingChannelTypeIndex = bookingChannels.findIndex(c =>
      c.bookingChannels.find(b => b._id === _id)
    )
    const findBookingChannel = bookingChannels[
      findBookingChannelTypeIndex
    ].bookingChannels.find(b => b._id === _id)
    if (findBookingChannel._id === 0) {
      return false
    } else if (findBookingChannel) {
      setCurrentIdEdit(_id)
      setCurrentBookChannel(findBookingChannel)
    }
    setActiveEdit(-1)
  }
  const handleClose = async () => {
    update()
    setCurrentIdEdit(-1)
    setActiveEdit(-1)
    setCurrentBookChannel('')
  }

  return (
    <Container>
      <Scrollbar id='setting-body-channel'>
        <BoxGroup>
          {bookingChannels.map(
            ({ bookingTypeId, bookingType, bookingChannels }, index) => (
              <div key={index}>
                <TextLabel fontWeight='500'>{bookingType}</TextLabel>
                <Box>
                  {bookingChannels.map(({ _id, bookingChannel }, index) => (
                    <BoxStyle
                      key={index}
                      onClick={e => onEditBookChannelClick(e, _id)}
                    >
                      {bookingChannel}
                      <BoxEdit onClick={e => onOpenEditClick(e, _id)}>
                        {bookingTypeId !== 1 &&
                          Array.from({ length: 3 }).map((_, index) => (
                            <div className='mr-1' key={index}>
                              <Circle></Circle>
                            </div>
                          ))}
                        {activeEdit === _id && (
                          <DropDown>
                            <Option
                              onClick={e => onEditBookChannelClick(e, _id)}
                            >
                              แก้ไข
                            </Option>
                            {/* <Option
                          // onClick={e => onDeleteServiceClick(e, task._id)}
                          >
                            ลบ
                          </Option> */}
                          </DropDown>
                        )}
                      </BoxEdit>
                    </BoxStyle>
                  ))}
                </Box>
              </div>
            )
          )}
        </BoxGroup>
      </Scrollbar>
      {currentIdEdit !== -1 && (
        <PopupAddBookChannel
          handleClose={handleClose}
          currentIdEdit={currentIdEdit}
          currentBookChannel={currentBookChannel}
        />
      )}
    </Container>
  )
}

export default BodyBookChannel
