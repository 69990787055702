import React from 'react'

const DiscountIcon = () => (
  <svg
    width='24'
    height='26'
    viewBox='0 0 24 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 2.2C1.47906 2.91736 2.37447 3.4 3.4 3.4C4.42553 3.4 5.32094 2.91736 5.8 2.2C6.15929 1.48264 6.83085 1 7.6 1C8.36915 1 9.04071 1.48264 9.4 2.2C9.87904 2.91736 10.7745 3.4 11.8 3.4C12.8255 3.4 13.721 2.91736 14.2 2.2C14.5593 1.48264 15.2308 1 16 1C16.7692 1 17.4407 1.48264 17.8 2.2C18.279 2.91736 19.1745 3.4 20.2 3.4C21.2255 3.4 22.121 2.91736 22.6 2.2V23.8C22.121 23.0826 21.2255 22.6 20.2 22.6C19.1745 22.6 18.279 23.0826 17.8 23.8C17.4407 24.5174 16.7692 25 16 25C15.2308 25 14.5593 24.5174 14.2 23.8C13.721 23.0826 12.8255 22.6 11.8 22.6C10.7745 22.6 9.87904 23.0826 9.4 23.8C9.04071 24.5174 8.36915 25 7.6 25C6.83085 25 6.15929 24.5174 5.8 23.8C5.32094 23.0826 4.42553 22.6 3.4 22.6C2.37447 22.6 1.47906 23.0826 1 23.8V2.2Z'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.59688 11.8031C8.59099 11.8031 9.39688 10.9972 9.39688 10.0031C9.39688 9.00901 8.59099 8.20312 7.59688 8.20312C6.60276 8.20312 5.79688 9.00901 5.79688 10.0031C5.79688 10.9972 6.60276 11.8031 7.59688 11.8031Z'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.0031 18.9984C16.9972 18.9984 17.8031 18.1926 17.8031 17.1984C17.8031 16.2043 16.9972 15.3984 16.0031 15.3984C15.009 15.3984 14.2031 16.2043 14.2031 17.1984C14.2031 18.1926 15.009 18.9984 16.0031 18.9984Z'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.8 8.20312L7 17.8031'
      stroke='#FC6514'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default DiscountIcon
