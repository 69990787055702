import React from 'react'
import styled, { CSSProperties } from 'styled-components'

interface ClosePopupProps extends CSSProperties {
  className?: string
}

const CloseRTL = styled.div<ClosePopupProps>`
  height: ${props => props.height || '15px'};
  width: ${props => props.width || '1.5px'};
  background-color: ${props => props.backgroundColor || '#23272a'};
  transform: rotate(-45deg);
  z-index: 1;
  cursor: pointer;
`
const CloseLTR = styled.div<ClosePopupProps>`
  height: ${props => props.height || '15px'};
  width: ${props => props.width || '1.5px'};
  background-color: ${props => props.backgroundColor || '#23272a'};
  transform: rotate(90deg);
  z-index: 2;
  cursor: pointer;
`
const ClosePopup = ({ height, backgroundColor, className }: ClosePopupProps) => {
  return (
    <CloseRTL height={height} backgroundColor={backgroundColor} className={className}>
      <CloseLTR height={height} backgroundColor={backgroundColor}></CloseLTR>
    </CloseRTL>
  )
}

export default ClosePopup
