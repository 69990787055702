import React from 'react'
import PopupBox from '../../atoms/Popup'
import PopupInsideBox from '../../atoms/PopupInside'
import CardAddNationality from '../settingCustomer/CardAddNationality'

const PopupAddNationality = ({ handleClose, currentNationality = '' }) => {
  return (
    <PopupBox width='calc(100%)' height='100vh' left='0rem' top='0'>
      <PopupInsideBox width='50%' marginTop='0' padding='0' height='auto'>
        <CardAddNationality
          handleClose={handleClose}
          currentNationality={currentNationality}
        />
      </PopupInsideBox>
    </PopupBox>
  )
}

export default PopupAddNationality
