import dayjs, { ManipulateType } from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import DownloadIcon from './icons/DownloadIcon'

// @ts-ignore
import Calendar2 from '../datePicker/Calendar2'

// @ts-ignore
import TimeIcon from '../../atoms/TimeIcon'

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Box = styled.div`
  display: flex;
  gap: 0.75rem;
`

const BoxCalendarStyle = styled.div<{ backgroundColor?: string }>`
  display: flex;
  background-color: ${props => props.backgroundColor || '#FFF'};
  border-radius: 8px;
  padding: 0.5rem;
  font-weight: 500;
  color: ${props => props.color || '#000'};
  align-items: center;
  cursor: pointer;
`

const BoxDay = styled.div<{ backgroundColor?: string }>`
  display: flex;
  background-color: ${props => props.backgroundColor || '#FFF'};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: ${props => props.color || '#000'};
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #f3eefb;
    color: #53389e;
  }
`

const Line = styled.div`
  color: #d9d9d9;
  border: 1px solid #d9d9d9;
`
const ExportButton = styled.div`
  width: fit-content;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: linear-gradient(221.86deg, #8c56d9 2.71%, #53389e 97.79%);
  cursor: pointer;
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

export interface FilterDateProps {
  date: { startDate: Date; endDate: Date }
  onDateChange: (startDate: Date, endDate: Date, type: number) => void
  onDownloadClick?: () => void
}

const FilterDate = ({
  date,
  onDateChange: _onDateChange,
  onDownloadClick,
}: FilterDateProps) => {
  const [openCalendar, setOpenCalendar] = useState(-1)
  const [selectedType, setSelectedType] = useState('')

  const { t } = useTranslation()

  const { startDate, endDate } = date

  const filterDay: { name: string; type: ManipulateType | 'isoWeek' }[] = [
    { name: t('thisN', { n: t('day') }), type: 'days' },
    { name: t('thisN', { n: t('week') }), type: 'isoWeek' },
    { name: t('thisN', { n: t('month') }), type: 'months' },
    { name: t('thisN', { n: t('year') }), type: 'years' },
  ]

  const onOpenCalendarClick = (type: number) => {
    if (type === openCalendar) setOpenCalendar(-1)
    else {
      setOpenCalendar(type)
    }
  }

  const onDateChange = (date: Date) => {
    if (date) {
      setSelectedType('')
      setOpenCalendar(-1)
      let s = startDate
      let e = endDate
      if (openCalendar === 1) {
        if (dayjs(date).isAfter(e)) {
          s = e
          e = date
        } else {
          s = date
        }
      } else if (openCalendar === 2) {
        if (dayjs(date).isBefore(s)) {
          e = s
          s = date
        } else {
          e = date
        }
      }
      _onDateChange(s, e, 3)
    }
  }

  const ShowCalender = ({ type }: { type: number }) => {
    return (
      <div
        style={{
          position: 'absolute',
          padding: '0.5rem 1rem',
          background: '#FFF',
          borderRadius: ' 8px',
          top: '130%',
          left: '1px',
          width: '250px',
          zIndex: '2',
          border: '1px solid #E4E8EB',
          boxShadow: ' 0px 16px 24px rgba(152, 161, 178, 0.08)',
        }}
      >
        <Calendar2
          date={type === 1 ? startDate : endDate}
          selectedDate={type === 1 ? startDate : endDate}
          onDateChange={onDateChange}
          highlightColor='#8C56D9'
          backwardable={true}
          fontwardable={true}
        />
      </div>
    )
  }

  const onSelectType = (type: ManipulateType | 'isoWeek') => {
    setSelectedType(type)
    _onDateChange(
      dayjs().startOf(type).toDate(),
      dayjs().endOf(type).toDate(),
      -1
    )
  }

  const formatDate = (date: Date) => {
    return <>{dayjs(date).format('DD MMMM YYYY')}</>
  }

  return (
    <Container>
      <Box>
        <div style={{ position: 'relative' }}>
          <BoxCalendarStyle
            backgroundColor={openCalendar === 1 ? '#F3EEFB' : ''}
            color={openCalendar === 1 ? '#53389E' : ''}
            onClick={() => onOpenCalendarClick(1)}
          >
            {formatDate(startDate)}
          </BoxCalendarStyle>
          {openCalendar === 1 && <ShowCalender type={1} />}
        </div>
        <div style={{ position: 'relative' }}>
          <BoxCalendarStyle
            backgroundColor={openCalendar === 2 ? '#F3EEFB' : ''}
            color={openCalendar === 2 ? '#53389E' : ''}
            onClick={() => onOpenCalendarClick(2)}
          >
            {formatDate(endDate)}
          </BoxCalendarStyle>
          {openCalendar === 2 && <ShowCalender type={2} />}
        </div>
        <Line />
        {filterDay.map(({ name, type }) => (
          <BoxDay
            key={type}
            backgroundColor={selectedType === type ? '#F3EEFB' : ''}
            color={selectedType === type ? '#53389e' : ''}
            onClick={() => {
              onSelectType(type)
              setOpenCalendar(-1)
            }}
          >
            <TimeIcon size='15' color='#000' />
            &nbsp; {name}
          </BoxDay>
        ))}
      </Box>
      {onDownloadClick && (
        <ExportButton onClick={() => onDownloadClick()}>
          <DownloadIcon />
          {t('downloadData')}
        </ExportButton>
      )}
    </Container>
  )
}

export default FilterDate
