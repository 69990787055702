import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Body from './Body'
import Head from './Head'
import { thunk_action_check_staff_queue } from '../../redux/actions/staffQueueAction'
import Page from '../../lib/layout/Page/Page'

const Main = () => {
  const dispatch = useDispatch()
  const [activeMenu, setActiveMenu] = useState('พนักงาน')
  const [activeAddData, setActiveAddData] = useState(false)
  const [activeAddGroupData, setActiveAddGroupData] = useState(false)

  useEffect(() => {
    dispatch(thunk_action_check_staff_queue())
  }, [])

  const onMenuClick = index => {
    setActiveMenu(index)
    setActiveAddData(false)
    setActiveAddGroupData(false)
    // setActiveAddData(false)
  }

  const onAddData = () => {
    setActiveAddData(!activeAddData)
  }

  const onAddGroupData = () => {
    setActiveAddGroupData(!activeAddGroupData)
  }

  const handleClose = () => {
    setActiveAddData(false)
    setActiveAddGroupData(false)
  }

  return (
    <Page>
      <Head
        activeMenu={activeMenu}
        onAddData={onAddData}
        onAddGroupData={onAddGroupData}
      />
      <Body
        onMenuClick={onMenuClick}
        activeMenu={activeMenu}
        activeAddData={activeAddData}
        activeAddGroupData={activeAddGroupData}
        handleClose={handleClose}
      />
    </Page>
  )
}

export default Main
