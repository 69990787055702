import React, { useState } from 'react'
import styled from 'styled-components'

import Button3 from '../../atoms/Button3'
import AgencyIcon from '../../atoms/AgencyIcon'
import BahtIcon from '../../atoms/BahtIcon'

const Container = styled.div`
  width: calc(100%);
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
`

const ButtonMenu = ({ onMenuClick, activeMenu }) => {
  const btnMenu = ['การจอง', 'การจ่ายเงิน']
  const btnMenuIcon = [
    <AgencyIcon size='20' color='#475467' />,
    <BahtIcon size='20' color='#475467' />,
  ]
  const [activeBtnMenu, setActiveBtnMenu] = useState('การจอง')

  const onButtonClick = menu => {
    setActiveBtnMenu(menu)
    onMenuClick(menu)
  }

  const BtnList = btnMenu.map((button, index) => (
    <div
      key={index}
      style={{ display: 'flex', marginRight: index !== 2 ? '0.5rem' : '0' }}
    >
      <Button3
        backgroundColor='white'
        backgroundColorActive='white'
        padding='0.5rem 0.5rem'
        size='0.9rem'
        border={activeBtnMenu === button ? '1px solid #8C56D9' : ''}
        icon={btnMenuIcon[index]}
        active={activeBtnMenu === button}
        colorIcon='#8C56D9'
        colorActive='#8C56D9'
        color='#475467'
        onClick={() => onButtonClick(button)}
      >
        {button}
      </Button3>
    </div>
  ))

  return (
    <Container>
      <div style={{ display: 'flex' }}>{BtnList}</div>
    </Container>
  )
}

export default ButtonMenu
