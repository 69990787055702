import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import TextLabel from '../../atoms/TextLabel'
import utils from '../../utils'
import InputText from '../../atoms/InputText'
import Scrollbar from '../../atoms/Scrollbar'
import SearchIcon from '../../atoms/SearchIcon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  padding: 0.5rem 1rem;
  overflow: hidden;
`

const BranchContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextStaffType = styled.div`
  background: #98a1b2;
  border-radius: 12px;
  font-size: 0.75rem;
  color: #ffffff;
  text-align: center;
  width: fit-content;
  padding: 0 1rem;
  margin-top: 1rem;
`

const BoxStaff = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const BoxStaffStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: ${props => (props.active ? '1px solid #53389E' : props.border || '')};
  border-bottom: ${props =>
    props.active ? '' : props.borderBottom || '1px solid #E5ECED'};
  padding: 0.5rem 0.25rem;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
`

const BoxImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  aspect-ratio: 1;
  background: ${props =>
    props.background ? `url(${props.background})` : '#f8f9fb'};
  background-position: center;
  background-size: cover;
`

const BoxStatus = styled.div`
  background: ${props => props.background || '#FF5152'};
  border-radius: 3px;
  color: #ffffff;
  font-size: 0.625rem;
  font-weight: 500;
  padding: 0.125rem 0.5rem;
  text-align: center;
`

const StaffTypeGroup = ({
  staffType,
  staffs = [],
  activeMenu,
  activeStaff,
  onStaffClick,
}) => {
  const { t } = useTranslation()

  return (
    <BoxStaff>
      <TextStaffType>{staffType}</TextStaffType>
      {staffs.map(({ staffId, staffName, staffImage, total }, staffIndex) => (
        <BoxStaffStyle
          key={staffIndex}
          active={activeStaff.staffId === staffId}
          onClick={() => onStaffClick(staffId)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BoxImage background={staffImage}></BoxImage>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TextLabel>{staffName}</TextLabel>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {activeMenu !== 'ประวัติค่ามือ' && (
              <>
                <BoxStatus background={total === 0 ? '#53389E' : ''}>
                  {total === 0 ? t('paid') : t('overdue')}
                </BoxStatus>
                <TextLabel fontWeight='500'>
                  {total === 0 ? '' : utils.formatNumber(total, 2)}
                </TextLabel>
              </>
            )}
          </div>
        </BoxStaffStyle>
      ))}
    </BoxStaff>
  )
}

const CardStaff = ({
  staffTypes,
  staffs,
  onStaffClick,
  activeStaff,
  activeMenu,
}) => {
  const { t } = useTranslation()

  const [search, setSearch] = useState('')

  const { storeId } = useSelector(state => state.user)
  const { ...store } = useSelector(state => state.store)
  const { branches } = useSelector(state => state.brand)

  const aggregateGroup = (list, storeId) => {
    const aggregatedGroup = staffTypes
      .filter(staffType => staffType.storeId === storeId)
      .reduce((acc, currentGroupName) => {
        const { staffTypeId, staffType } = currentGroupName
        const filteredServices = list.filter(
          n => n.staffTypeId === staffTypeId && n.storeId === storeId
        )
        if (!filteredServices.length) {
          return acc
        }
        const currentGroupIdx = acc.findIndex(
          g => g.staffTypeId === staffTypeId
        )
        if (currentGroupIdx === -1) {
          acc.push({
            staffType,
            open: true,
            staffs: [...filteredServices],
          })
        } else {
          acc[currentGroupIdx].staffs.push({
            ...filteredServices,
          })
        }
        return acc
      }, [])
    return aggregatedGroup
  }

  const bySearch = (staffs, search) => {
    if (search) {
      return staffs.staffName.includes(search)
    } else return staffs
  }

  const filteredList = (staffs, search) => {
    return staffs.filter(staffs => bySearch(staffs, search))
  }

  const currentStores = storeId === 0 ? branches : [store]

  return (
    <Container>
      <InputText
        icon={<SearchIcon size='15' />}
        placeholder={t('therapistSearch')}
        value={search}
        onChange={val => setSearch(val.target.value)}
      />
      <Card>
        <Scrollbar
          id='scrollbar-pay-wage-card'
          maxHeight='calc(100vh - 80px - 1rem - 20vh)'
        >
          {currentStores.map(store => {
            const groups = aggregateGroup(
              filteredList(staffs, search),
              store._id
            )
            return (
              <BranchContainer key={store._id}>
                <TextLabel fontSize='1rem' fontWeight='500'>
                  {`${t('staffType')} ${t('branch')} ${store.branch}`}
                </TextLabel>
                {groups.map(group => (
                  <StaffTypeGroup
                    key={group.staffType}
                    staffType={group.staffType}
                    staffs={group.staffs}
                    activeMenu={activeMenu}
                    activeStaff={activeStaff}
                    onStaffClick={onStaffClick}
                  />
                ))}
              </BranchContainer>
            )
          })}
        </Scrollbar>
      </Card>
    </Container>
  )
}

export default CardStaff
