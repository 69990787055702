import React from 'react'

const LanguageIcon = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 21C16.5228 21 21 16.5228 21 11C21 9.17519 20.5112 7.46454 19.6574 5.99183C19.6057 5.99723 19.5532 6 19.5 6C18.6716 6 18 5.32843 18 4.5C18 4.30912 18.0357 4.12657 18.1007 3.95865C16.2887 2.13155 13.7765 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21ZM18.7544 3.19813C16.7657 1.22145 14.0255 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 9.02073 21.4773 7.16356 20.5623 5.55902C20.8328 5.2877 21 4.91338 21 4.5C21 3.67157 20.3284 3 19.5 3C19.2286 3 18.974 3.07208 18.7544 3.19813ZM11 3H10.94H10.9H10.82C6.48 3.1 3 6.66 3 11C3 15.34 6.48 18.9 10.82 19H10.9H10.94H11C15.42 19 19 15.42 19 11C19 6.58 15.42 3 11 3ZM10.92 17.6H10.86C10.22 17.54 9.38 16.12 9 13.74C10.14 13.6 11.42 13.56 12.78 13.74C12.4 16.12 11.56 17.56 10.92 17.6ZM8.84 12.32C8.82 11.9 8.8 11.46 8.8 11C8.8 10.46 8.82 9.96 8.86 9.46C9.52 9.54 10.22 9.58 10.94 9.58C11.58 9.58 12.24 9.54 12.92 9.46C12.96 9.94 12.98 10.46 12.98 10.98C12.98 11.44 12.96 11.88 12.94 12.3C11.48 12.12 10.08 12.18 8.84 12.32ZM4.4 11C4.4 10.12 4.58 9.28 4.88 8.52C5.4 8.7 6.3 9 7.48 9.24C7.42 9.82 7.4 10.42 7.4 11C7.4 11.52 7.42 12.04 7.46 12.54C6.24 12.78 5.3 13.1 4.8 13.28C4.54 12.58 4.4 11.8 4.4 11ZM10.86 4.4H10.92C11.54 4.44 12.36 5.8 12.76 8.06C11.42 8.22 10.16 8.18 9.04 8.06C9.44 5.82 10.24 4.46 10.86 4.4ZM14.32 9.22C15.22 9.02 16.12 8.72 17.02 8.3C17.4 9.14 17.6 10.04 17.6 11C17.6 11.9 17.42 12.74 17.1 13.52C16.18 13.08 15.24 12.78 14.34 12.58C14.38 12.06 14.4 11.54 14.4 11.02C14.4 10.4 14.38 9.8 14.32 9.22ZM16.32 7.1C15.6 7.42 14.86 7.66 14.14 7.84C13.94 6.68 13.62 5.62 13.18 4.78C14.44 5.22 15.54 6.04 16.32 7.1ZM8.58 4.86C8.16 5.68 7.86 6.7 7.66 7.84C6.74 7.66 6.02 7.44 5.56 7.28C6.3 6.2 7.34 5.36 8.58 4.86ZM5.44 14.56C5.9 14.4 6.66 14.16 7.62 13.96C7.82 15.16 8.14 16.28 8.56 17.14C7.28 16.62 6.18 15.72 5.44 14.56ZM13.18 17.22C13.64 16.34 13.98 15.2 14.18 13.96C14.92 14.14 15.68 14.4 16.44 14.74C15.64 15.88 14.5 16.76 13.18 17.22Z'
      fill='black'
    />
  </svg>
)

export default LanguageIcon
