import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import Button2 from '../../atoms/Button2'
import Calendar from '../datePicker/Calendar2'
import useOnClickOutside from '../../hook/useOnClickOutside'

const Container = styled.div`
  display: flex;
  width: calc(100%);
  padding-bottom: 0.5rem;
`
const Box = styled.div`
  display: flex;
  flex: ${props => props.flex || '1 0 auto'};
  max-width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};
  justify-content: ${props => props.justifyContent || 'unset'};
  align-items: ${props => props.alignItems || 'unset'};
`
const GridBox = styled.div`
  display: flex;
  /* grid-template-columns: ${props => props.column || '1fr 1fr 1fr'}; */
  gap: 0.875rem;
  width: 100%;
`
const BoxCalendar = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 0 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`

const Head = ({
  date,
  activeMenu,
  onDateStartChange,
  onDateEndChange,
  onMenuClick,
}) => {
  const { t } = useTranslation()

  const ref = useRef(null)
  const refCalendarStart = useRef(null)
  const refCalendarEnd = useRef(null)
  const [openCalendarStart, setOpenCalendarStart] = useState(false)
  const [openCalendarEnd, setOpenCalendarEnd] = useState(false)

  useEffect(() => {
    setOpenCalendarStart(false)
    setOpenCalendarEnd(false)
  }, [date])

  useOnClickOutside(refCalendarStart, () => {
    setOpenCalendarStart(false)
  })
  useOnClickOutside(refCalendarEnd, () => {
    setOpenCalendarEnd(false)
  })

  useEffect(() => {}, [ref])

  return (
    <Container ref={ref}>
      <GridBox
        column={
          activeMenu === 'ค่ามือค้างชำระ'
            ? '0.2fr 0.2fr'
            : '0.25fr 0.25fr 0.25fr'
        }
      >
        <Button2
          backgroundColor='white'
          width='fit-content'
          padding='0.5rem 0.85rem'
          active={activeMenu === 'ค่ามือค้างชำระ'}
          onClick={() => onMenuClick('ค่ามือค้างชำระ')}
        >
          {t('payWageOverdue')}
        </Button2>
        <Button2
          backgroundColor='white'
          width='fit-content'
          padding='0 0.85rem'
          active={activeMenu === 'ประวัติค่ามือ'}
          onClick={() => onMenuClick('ประวัติค่ามือ')}
        >
          {t('payWageHistory')}
        </Button2>
        {activeMenu === 'ประวัติค่ามือ' && (
          <>
            <Box
              ref={refCalendarStart}
              width='fit-content'
              style={{ position: 'relative', cursor: 'pointer' }}
            >
              <BoxCalendar
                onClick={() => {
                  setOpenCalendarStart(!openCalendarStart)
                  setOpenCalendarEnd(false)
                }}
              >
                {`${dayjs(date.startDate).format('DD MMMM YYYY')}`}
              </BoxCalendar>
              {openCalendarStart && (
                <Box
                  style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    top: '4rem',
                    padding: '0.5rem',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 4px rgba(71, 84, 103, 0.05)',
                    zIndex: '3',
                  }}
                >
                  <Calendar
                    selectedDate={date.startDate}
                    labelFontSize='1rem'
                    backwardable={true}
                    fontwardable={false}
                    highlightColor='#8C56D9'
                    onDateChange={onDateStartChange}
                  />
                </Box>
              )}
            </Box>
            {/* <Box width='fit-content' alignItems='center'>{`-`}</Box> */}
            <Box
              ref={refCalendarEnd}
              width='fit-content'
              style={{ position: 'relative', cursor: 'pointer' }}
            >
              <BoxCalendar
                onClick={() => {
                  setOpenCalendarEnd(!openCalendarEnd)
                  setOpenCalendarStart(false)
                }}
              >
                {`${dayjs(date.endDate).format('DD MMMM YYYY')}`}
              </BoxCalendar>
              {openCalendarEnd && (
                <Box
                  style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    top: '4rem',
                    padding: '0.5rem',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 4px rgba(71, 84, 103, 0.05)',
                    zIndex: '3',
                  }}
                >
                  <Calendar
                    selectedDate={date.endDate}
                    labelFontSize='1rem'
                    backwardable={true}
                    fontwardable={false}
                    highlightColor='#8C56D9'
                    rangeColor='#EDE9F8'
                    onDateChange={onDateEndChange}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </GridBox>
    </Container>
  )
}

export default Head
