import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

import { activeOpenState } from './useCardCustomer'
import useCardStaff from './useCardStaff'

import FemaleIcon from '../../../../atoms/FemaleIcon'
import InputText from '../../../../atoms/InputText'
import LgbtIcon from '../../../../atoms/LgbtIcon'
import LongArrow from '../../../../atoms/LongArrow'
import MaleIcon from '../../../../atoms/MaleIcon'
//@ts-ignore
import Scrollbar from '../../../../atoms/Scrollbar'
import SearchIcon from '../../../../atoms/SearchIcon'
import TextLabel from '../../../../atoms/TextLabel'

import { StaffOrderState } from '../../types'

interface CardStaffStyle extends CSSProperties {
  active?: boolean
}

const Container = styled.div<CardStaffStyle>`
  position: ${props => props.position || 'absolute'};
  background: #ffffff;
  width: 100%;
  left: 0px;
  height: 100%;
  z-index: 1;
  top: 0px;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const GridBox = styled.div<CardStaffStyle>`
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: 0.5rem;
`
const Box = styled.div<CardStaffStyle>`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || '0.25rem'};
  padding: ${props => props.padding || '0'};
  overflow: hidden;
  align-items: ${props => props.alignItems || 'unset'};
`
const BoxInside = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const BoxStaff = styled.div<CardStaffStyle>`
  flex: ${props => props.flex || '1 0 50%'};
  max-width: ${props => props.width || '50%'};
  min-width: ${props => props.width || '50%'};
  padding: 0.15rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: ${props =>
    props.active
      ? 'linear-gradient(264.23deg, #8C56D9 -45.63%, #53389E 99.47%, #53389E 99.47%)'
      : 'none'};
`
const BoxGender = styled.div<CardStaffStyle>`
  background: #ffffff;
  border: ${props =>
    props.active ? '2px solid #8C56D9' : '1px solid #e4e8eb'};
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ImageBox = styled.div<CardStaffStyle>`
  border-radius: 50%;
  background: ${props =>
    props.background ? `url(${props.background})` : '#E4E8EB'};
  width: 30px;
  height: 30px;
  background-size: cover;
  border: ${props => props.border || '2px solid #32D582'};
`

interface CardStaffProps {
  staffOrders: StaffOrderState[]
  currentDate: Date
  activeOpen: activeOpenState
  onToggle: () => void
  onSelectStaff: (
    staffId: number,
    free: boolean,
    staffWorkingStatus: number
  ) => void
}

const CardStaff = ({
  staffOrders,
  currentDate,
  activeOpen,
  onToggle,
  onSelectStaff,
}: CardStaffProps) => {
  const { t } = useTranslation()

  const { search, gender, activeStaff, isSameDate, staffs, onChange } =
    useCardStaff({
      staffOrders,
      activeOpen,
      currentDate,
    })

  return (
    <Container>
      <LongArrow onClick={() => onToggle()} />
      <TextLabel fontWeight='500'>{t('selectTherapist')}</TextLabel>
      <GridBox columns='1.5fr 1fr'>
        <InputText
          icon={<SearchIcon color='#E4E8EB' size='15' />}
          placeholder={t('searchStaff') + ''}
          value={search}
          onChange={val => onChange(val.target.value, 'search')}
          // onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        />
        <Box>
          <BoxGender
            onClick={e => {
              e.stopPropagation()
              onChange(gender === 0 ? -1 : 0, 'gender')
            }}
            active={gender === 0}
          >
            <FemaleIcon size='20' />
          </BoxGender>
          <BoxGender
            onClick={e => {
              e.stopPropagation()
              onChange(gender === 1 ? -1 : 1, 'gender')
            }}
            active={gender === 1}
          >
            <MaleIcon size='18' />
          </BoxGender>
          <BoxGender
            onClick={e => {
              e.stopPropagation()
              onChange(gender === 2 ? -1 : 2, 'gender')
            }}
            active={gender === 2}
          >
            <LgbtIcon size='20' />
          </BoxGender>
        </Box>
      </GridBox>
      <Box>
        <Scrollbar id='customer-cardStaff'>
          <BoxInside>
            {staffs.map(
              (
                { staffId, staffName, staffImage, staffWorkingStatus, free },
                index
              ) => (
                <BoxStaff
                  key={index}
                  onClick={() =>
                    onSelectStaff(staffId, free, staffWorkingStatus)
                  }
                  active={activeStaff === staffId}
                >
                  <TextLabel
                    paddingRight='0.5rem'
                    paddingLeft='0.5rem'
                    color={
                      isSameDate
                        ? !free || staffWorkingStatus === -1
                          ? '#E4E8EB'
                          : activeStaff === staffId
                          ? '#FFFFFF'
                          : ''
                        : ''
                    }
                  >
                    {index + 1}.
                  </TextLabel>
                  <ImageBox
                    background={staffImage}
                    border={
                      isSameDate
                        ? staffWorkingStatus === 1
                          ? ''
                          : '1px solid #FF5152'
                        : 'none'
                    }
                  />
                  <TextLabel
                    fontWeight='500'
                    paddingLeft='0.25rem'
                    color={
                      isSameDate
                        ? !free || staffWorkingStatus === -1
                          ? '#E4E8EB'
                          : activeStaff === staffId
                          ? '#FFFFFF'
                          : ''
                        : ''
                    }
                  >
                    {staffName}
                  </TextLabel>
                </BoxStaff>
              )
            )}
          </BoxInside>
        </Scrollbar>
      </Box>
    </Container>
  )
}

export default CardStaff
