import { ClipLoader } from 'react-spinners'

// @ts-ignore
import PopupBox from '../atoms/Popup'
import styled from 'styled-components'
import usePage from '../lib/layout/Page/usePage'
import React from 'react'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoaderModal = ({ isLoading }: any) => {
  const { menuWidth } = usePage()
  return (
    <>
      {isLoading && (
        <PopupBox
          width={`calc(100% - ${menuWidth})`}
          left={menuWidth}
          top='80px'
        >
          <Container>
            <ClipLoader loading size={75} />
          </Container>
        </PopupBox>
      )}
    </>
  )
}

export default LoaderModal
