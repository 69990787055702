import { GET_COMMISSION } from '../actions/dataCommissionAction'

const initState = {
  groupName: '',
  groupId: '',
  _id: '',
  name: '',
  image: '',
  description: '',
  time: [],
  price: [],
  choice: [{ options: '', price: '' }],
  prepareTime: { before: '', after: '' },
  recommend: false,
  temporary: false,
  suspend: false,
  storeId: '',
  commission: [{ serviceCommission: [], choiceCommission: [] }],
}

const dataCommissionReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_COMMISSION:
      return {
        ...state,
        ...action.payload.serviceCommission,
      }
    default:
      return state
  }
}

export default dataCommissionReducers
